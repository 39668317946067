import React, { useEffect, useRef, useState, useReducer } from "react";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  Link,
  Chip,
  Checkbox,
  TableBody,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Button,
  TextField,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import moment from "moment";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";

import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import SearchIcon from "@material-ui/icons/Search";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import PropTypes from "prop-types";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import LaunchIcon from "@material-ui/icons/Launch";
import { startOfMonth, endOfMonth } from "date-fns";
import { getTeamTimesheetData } from "../redux/TimeSheet/actionTs";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Divider from "@material-ui/core/Divider";
import { styled } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 13,
    top: 31,
    border: `0.125rem solid ${theme.palette.background.paper}`,
    padding: "0 0.25rem",
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  {
    id: "icon",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "3.75rem",
  },

  {
    id: "employeeName",
    numeric: false,
    disablePadding: true,
    label: "Employee Name & ID",
    width: "3.5rem",
  },
  {
    id: "billableHours",
    numeric: true,
    disablePadding: true,
    label: "Bill Hours",
    width: "2.063rem",
    paddingRight: "1.563rem",
  },
  {
    id: "nonBillableHours",
    numeric: true,
    disablePadding: true,
    label: "Non- Bill Hours",
    width: "3.313rem",
  },
  // {
  //   id: "approvedHours",
  //   numeric: true,
  //   disablePadding: true,
  //   label: "Approved Hours",
  //   width: "245px",
  // },
  {
    id: "unapprovedHours",
    numeric: true,
    disablePadding: true,
    label: "Unapproved Hours",
    width: "5.563rem",
    paddingRight: "0.5rem",
  },
];
function TeamTimesheetTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    // rowCount,
    onRequestSort,
    checked,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  // const [checked, setChecked] = React.useState(false);
  // const handleChange = (event) => {
  //   setChecked(event.target.checked);
  // };
  // console.log("event", checked);
  return (
    <TableHead style={{ backgroundColor: "#E3E8F7" }}>
      <TableRow style={{ height: "3.125rem" }}>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell> */}
        {/* <TableCell
          component="th"
          scope="row"
          // component="td"
          padding="none"
          // scope="col-1"
          style={{
            width: "0px",
          }}
        /> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // component="th"
            scope="row"
            style={{
              //fontWeight: 600,
              padding: "0rem",
              // width: headCell.width,
              // fontSize: "0.625rem",
              // lineHeight: "0.813rem",
              // paddingRight: headCell.paddingRight,
              // textAlign:
              //   headCell.id == "unapprovedHours" ||
              //   headCell.id == "nonBillableHours" ||
              //   headCell.id == "billableHours"
              //     ? "center"
              //     : "",
            }}
            align={headCell.numeric ? "right" : "left"}
            // padding={headCell.disablePadding ? "none" : "normal"}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            <div
              style={{
                fontWeight: 600,
                padding: "0rem",
                width: headCell.width,
                fontSize: "0.625rem",
                lineHeight: "0.813rem",
                paddingRight: headCell.paddingRight,
                transform: headCell.id === "icon" ? "scale(0.8)" : "",
              }}
            >
              {headCell.id === "icon" ? (
                //somecode
                <>
                  <Checkbox
                    checked={checked}
                    style={{ paddingLeft: "0.75rem" }}
                    checkedIcon={
                      <CheckBoxIcon
                        fontSize="small"
                        className={classes.checkBoxRoot}
                        style={{ transform: "scale(0.8)" }}
                      />
                    }
                    //fontSize="small"
                    onChange={onSelectAllClick}
                    inputProps={{ "aria-label": "select all rows" }}
                    //className={classes.checkBoxRoot}
                    // style={{
                    //   background: "#0B1941",
                    //   color: "white",
                    //   "&$checked": {
                    //     background: "#0B1941",
                    //     color: "white",
                    //   },
                    // }}
                  />
                </>
              ) : (
                //somecode
                <>{headCell.label}</>
              )}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

TeamTimesheetTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  // rowCount: PropTypes.number.isRequired,
  checked: PropTypes.bool.isRequired,
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    boxShadow: "none",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  statusStyle: {
    borderRadius: "6.25rem",
    width: " 6.875rem",
    height: "1.5rem",
    flexShrink: 0,
  },
  statusStyleContent: {
    textAlign: "center",
    fontFamily: " Montserrat",
    fontSize: "0.625rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: " 0.031rem",
    textTransform: "uppercase",
  },
  tooltip: {
    paddingLeft: "0.125rem",
    color: "#9E9E9E",
    maxWidth: "1.25rem",
    maxHeight: "1.25rem",
  },

  tooltipBox: {
    minWidth: "7.5rem",
    left: "auto!important",
    right: 0,
    marginTop: "-0.688rem",
    // top:'-1.25rem!important'
  },
  arrow: {
    left: "auto!important",

    right: 0,
  },
  statusName: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "0.5rem",
    marginRight: "0rem",
    // alignItems: "center",
    // paddingLeft: "0.625rem",
    color: "rgba(255, 255, 255, 0.85)",
  },

  tableRow: {
    height: 58,
    "&.MuiTableRow-root.MuiTableRow-hover:hover": {
      backgroundColor: "#EFF2F8",
    },
    "&.MuiTableRow-root.Mui-selected": {
      background: "#EFF2F8",
    },
  },
  tableCell: {
    "&.MuiTableCell-root": {
      padding: "0rem",
    },
  },
  checkBoxRoot: {
    background: "#0B1941",
    color: "white",
    "&:checked": {
      background: "#0B1941",
      color: "white",
    },
    auditHistory: {
      fontFamily: "Montserrat",
      fontWeight: 800,
      fontSize: "0.875rem",
      color: "#008CE6",
      paddingTop: "1.25rem",
      textDecoration: "underline",
    },
  },
  //checked: {},
}));
function ResponsiveTeamTimesheet() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [checked, setChecked] = React.useState(false);
  const [multiDownload, setMultiDownload] = React.useState([]);
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [tempFromDate, setTempFromDate] = useState(null);
  const [tempToDate, setTempToDate] = useState(null);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [anchorFilter1, setAnchorFilter1] = useState("right");
  const [filteredRowsData, setFilteredRowsData] = React.useState([]);
  const [buttonState, setButtonState] = React.useState(false);
  const [filterShow, setFilterShow] = React.useState(false);
  const [systemLiveDate, setSystemLiveDate] = useState(null);
  const [futureLimitDate, setFutureLimitDate] = useState(null);
  const [employee, setEmployee] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [empSearch, setEmpSearch] = useState(false);
  const [empIdSearch, setEmpIdSearch] = useState(false);
  const [countShow, setCountShow] = React.useState(2);
  const history = useHistory();
  const [startDateMsg, setStartDateMsg] = useState("");
  const [endDateMsg, setEndDateMsg] = useState("");
  const [isLoader, isSetLoader] = useState(true);

  const inputValidation = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValidInput = /^[0-9]*$/.test(keyValue);

    if (!isValidInput) {
      event.preventDefault();
    }
  };
  const headers = [
    {
      label: "Employee ID",
      key: "employeeID",
    },
    {
      label: "Employee Name",
      key: "employeeName",
    },
    {
      label: "Billable Hours",
      key: "billableHours",
    },
    {
      label: "Non- Billable Hours",
      key: "nonBillableHours",
    },
    {
      label: "Approved Hours",
      key: "approvedHours",
    },
    {
      label: "Unapproved Hours",
      key: "unapprovedHours",
    },
  ];
  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );

  const tsDelEmpIdRedux = useSelector(
    (state) => state?.timesheetData?.tsDelEmpId
  );
  
  const teamTimesheet = useSelector(
    (state) => state.timesheetData.getTeamTimesheets
  );

  useEffect(() => {
    if(teamTimesheet){
      isSetLoader(false);
    }
    else{
      isSetLoader(true);
    }
  }, [teamTimesheet]);

  // console.log("team", teamTimesheet);
  const toggleDrawer = (anchor, open) => (event) => {
    // console.log("aa gaye");
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const csvLink = {
    headers: headers,
    data: multiDownload,
    filename: `MyTeamTimesheet_${moment(new Date()).format(
      "DDMMYYYY_HHmm"
    )}.csv`,
  };

  useEffect(() => {
    if (teamTimesheet && teamTimesheet?.length > 0) {
      setSystemLiveDate(new Date(teamTimesheet[0]?.systemLiveDate));
      setFutureLimitDate(new Date(teamTimesheet[0]?.futureWeekLimitFromDate));
    }
  }, [teamTimesheet])

  useEffect(() => {
    if (teamTimesheet && teamTimesheet.length > 0) {
      setSystemLiveDate(new Date(teamTimesheet[0]?.systemLiveDate));
      setFutureLimitDate(new Date(teamTimesheet[0]?.futureWeekLimitFromDate));
      
      if(fromDate === null){
        setFromDate((new Date(teamTimesheet[0]?.systemLiveDate)) < firstDayOfMonth
        ? firstDayOfMonth : (new Date(teamTimesheet[0]?.systemLiveDate)))
      }

      if(toDate === null){
        setToDate((new Date(teamTimesheet[0]?.systemLiveDate)) < firstDayOfMonth
        ? endOfMonth(new Date(firstDayOfMonth)) : endOfMonth(new Date(teamTimesheet[0]?.systemLiveDate)))
      }

      if(tempFromDate === null){
        setTempFromDate((new Date(teamTimesheet[0]?.systemLiveDate)) < firstDayOfMonth
        ? firstDayOfMonth : (new Date(teamTimesheet[0]?.systemLiveDate)))
      }

      if(tempToDate === null){
        setTempToDate((new Date(teamTimesheet[0]?.systemLiveDate)) < firstDayOfMonth
        ? endOfMonth(new Date(firstDayOfMonth)) : endOfMonth(new Date(teamTimesheet[0]?.systemLiveDate)))
      }
    }
    else{
      setFromDate(firstDayOfMonth ? firstDayOfMonth : "")
      setToDate(lastDayOfMonth ? lastDayOfMonth : "")
    }
  }, []);

  let currentDate = new Date();
  // let tempvalcount =2;
  let firstDayOfMonth = startOfMonth(currentDate);
  let lastDayOfMonth = endOfMonth(currentDate);


  const handleSelectAllClick = (event) => {
    // console.log("event", event);
    if (event.target.checked) {
      setChecked(true);
      // //console.log('pageNumber', page)
      let filterData = stableSort(teamTimesheet, getComparator(order, orderBy));
      // console.log("filterData", filterData);
      const newSelecteds = filterData.map((s) => s.empId);
      setSelected(newSelecteds);
      //console.log("selected value", newSelecteds);
      setMultiDownload(filterData);

      return;
    } else {
      setChecked(false);
    }
    setMultiDownload([]);

    setSelected([]);
  };
  const handleClick = (event, name) => {
    //console.log("name", name);
    const selectedIndex = selected.indexOf(name.empId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.empId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      setChecked(false);
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      setChecked(false);
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      setChecked(false);
    }

    if (newSelected.length == teamTimesheet.length) setChecked(true);
    setSelected(newSelected);
    //console.log("selected value", selected);

    const objIndex = multiDownload.findIndex((el) => el.empId == name.empId);
    let newMultiDownload = [];
    if (objIndex >= 0) {
      newMultiDownload = [
        ...multiDownload.filter((el) => el.empId != name.empId),
      ];
    } else {
      newMultiDownload = [...multiDownload, name];
    }
    setMultiDownload(newMultiDownload);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {

    if(employee.length === 0 && employeeId.length === 0)
    {
      let obj = {
        managerEmpId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
        fromDate: '',
        toDate: '',
        empName: [],
        empCode: [],
      };

    dispatch(getTeamTimesheetData(obj));
  }
  }, [ssoEmpIdDetails, tsDelEmpIdRedux, employee, employeeId]);


  // const handleFromDateChange = (type, date) => {
  //   setFromDate(date);
  //   console.log("Monitor:1", typeof date);
  //   const dateFormatRegex = /^[A-Z][a-z]{2} \d{1,2}, \d{2}$/; // Format: MM/dd/yyyy

  //   const parsedDate = new Date(date);

  //   if (!dateFormatRegex.test(date)) {
  //     setStartDateMsg("Invalid date format.");
  //     setButtonState(true);
  //   } else if (isNaN(parsedDate.getTime())) {
  //     setStartDateMsg("Invalid date. Please enter a valid date.");
  //     setButtonState(true);
  //   } else if (date) {
  //     setStartDateMsg("");
  //     if (toDate && endDateMsg.length == 0) {
  //       setStartDateMsg(""); 
  //       setEndDateMsg(""); 
  //       setButtonState(false);
  //     }
  //   }
  // };
  // const handleToDateChange = (type, date) => {
  //   setToDate(date);
  //   console.log("Monitor:2", typeof date);
  //   const dateFormatRegex = /^[A-Z][a-z]{2} \d{1,2}, \d{2}$/; // Format: MM/dd/yyyy
  //   const parsedDate = new Date(date);

  //   if (!dateFormatRegex.test(date)) {
  //     setEndDateMsg("Invalid date format.");
  //     setButtonState(true);
  //   } else if (isNaN(parsedDate.getTime())) {
  //     setEndDateMsg("Invalid date. Please enter a valid date.");
  //     setButtonState(true);
  //   } else if (date) {
  //     setEndDateMsg("");
  //     if (fromDate && startDateMsg.length == 0) {
  //       setEndDateMsg(""); // Clear the error message if the date is valid
  //       setStartDateMsg("");
  //       setButtonState(false);
  //     }
  //   }
  // };

  const handleEmployee = (event) => {
    const selectedValue = event.target.value.trimStart();
    // console.log("seleceted value", selectedValue);
    // console.log("in emplyee handle");
    setEmployee(selectedValue);
    //const selectedValue = event.target.value;
    // const handleEmployeeSubmit = (shouldUpdateState) => {
    //   if (shouldUpdateState) {
    //     setEmployee(selectedValue);
    //   }
    // };
    // if (
    //   (event.key === "Enter" && selectedValue.length > 0) ||
    //   (event.type === "blur" && selectedValue.length > 0)
    // ) {
    //   handleEmployeeSubmit(true);
    // } else {
    //   setEmployee(selectedValue);
    // }
    if (
      (fromDate != null && toDate != null) ||
      employee != "" ||
      employeeId != ""
    ) {
      setButtonState(false);
    }
    // if (
    //   (event.key === "Enter" || event.type === "blur") &&
    //   selectedValue.length > 0
    // ) {
    //   setEmployee(selectedValue);
    // }
  };
  // const handleButtonState = () => {
  //   if (
  //     (fromDate != null && toDate != null) ||
  //     employee != "" ||
  //     employeeId != ""
  //   ) {
  //     return true;
  //   } else return false;
  // };

  const handleEmployeeId = (event) => {
    const selectedValue = event.target.value.trimStart();

    // const ids = selectedValue.split(",").map((id) => id.trim());

    setEmployeeId(selectedValue);
    // console.log("in emplyee id handle", selectedValue);
    // const handleEmployeeID = (shouldUpdateState) => {
    //   if (shouldUpdateState) {
    //     setEmployeeId(selectedValue);
    //   }
    // };
    // // if (
    // //   (event.key === "Enter" || event.type === "blur") &&
    // //   selectedValue.length > 0
    // // ) {
    // //   handleEmployeeID(selectedValue);
    // // }
    // if (
    //   (event.key === "Enter" && selectedValue.length > 0) ||
    //   (event.type === "blur" && selectedValue.length > 0)
    // ) {
    //   handleEmployeeID(true);
    // } else {
    //   setEmployeeId(selectedValue);
    // }
    if (
      (fromDate != null && toDate != null) ||
      employee != "" ||
      employeeId != ""
    ) {
      setButtonState(false);
    }
  };

  useEffect(() => {
    if (fromDate && toDate && fromDate > toDate){
      setButtonState(true);
    }
    else{
      setButtonState(false);
    }
  }, [fromDate, toDate]);

  const handleApplyFilter = () => {
    if (fromDate && toDate && fromDate <= toDate) {  

      isSetLoader(true);
    var countVal1 = 0,
      countVal2 = 0,
      countVal3 = 0,
      countVal4 = 0;

      setTempFromDate(fromDate);
      setTempToDate(toDate);
      let obj = {
        managerEmpId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
        fromDate: fromDate ? moment(fromDate).format("yyyy-MM-DD") : "",
        toDate: toDate ? moment(toDate).format("yyyy-MM-DD") : "",
        empName: employee ? [employee] : [],
        empCode: employeeId ? employeeId : [],
      };
      dispatch(getTeamTimesheetData(obj));

    if (employee != "") {
      countVal1 = 1;
    }
    if (employeeId != "") {
      countVal2 = 1;
    }
    if (fromDate != null) {
      countVal3 = 1;
    }
    if (toDate != null) {
      countVal4 = 1;
    }
    let tempvalcount = countVal1 + countVal2 + countVal3 + countVal4;
    // console.log("count", countVal1, countVal2);
    setCountShow(tempvalcount);
    setState({
      ...state,
      bottom: false,
      top: false,
      right: false,
      left: false,
    });
    if (
      (fromDate != null && toDate != null) ||
      employee != "" ||
      employeeId != ""
    ) {
      setButtonState(false);
    }
    } 
  };

  const handleClearAll = () => {
    isSetLoader(true);
    firstDayOfMonth = startOfMonth(currentDate);
    lastDayOfMonth = endOfMonth(currentDate);

    setFromDate(firstDayOfMonth < systemLiveDate ? systemLiveDate : firstDayOfMonth)

    setToDate(firstDayOfMonth < systemLiveDate ? endOfMonth(new Date(systemLiveDate)) : endOfMonth(new Date(firstDayOfMonth)));


    setEmployee("");
    setEmployeeId("");
    setCountShow(2);

    if(firstDayOfMonth && lastDayOfMonth){
      const obj = {
        managerEmpId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
        fromDate: '',
        toDate: '',
        empName: [],
        empCode: [],
      };
      dispatch(getTeamTimesheetData(obj));
    }
    //setButtonState(true);
  };

  // useEffect(() => {
  //   if (firstDayOfMonth && lastDayOfMonth) {
  //     console.log("checking dates",firstDayOfMonth,lastDayOfMonth)
  //     const obj = {
  //       managerEmpId: ssoEmpIdDetails?.empId,
  //       fromDate: firstDayOfMonth
  //         ? moment(firstDayOfMonth).format("yyyy-MM-DD")
  //         : "",
  //       toDate: lastDayOfMonth
  //         ? moment(lastDayOfMonth).format("yyyy-MM-DD")
  //         : "",
  //       empName: employee ? [employee] : [],
  //       empCode: employeeId ? [employeeId] : [],
  //     };
  //     dispatch(getTeamTimesheetData(obj));
  //   }
  // }, [handleClearAll])

  const list = (anchor) => (
    <div
      style={{ overflowX: "hidden", overflowY: "hidden" }}
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      {" "}
      <ArrowBackIosIcon
        onClick={toggleDrawer(anchorFilter1, false)}
        style={{ paddingLeft: "0.75rem", marginTop: "1.188rem" }}
      />
      <span
        style={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: 600,
            lineHeight: "1.125rem",
            marginTop: "-1.563rem",
            marginLeft: "-14rem",
            // display:"flex",
            // justifyContent:"center"
          }}
        >
          Filter
        </Typography>
      </span>
      <Divider />
      <br />
      <Grid
        container
        spacing={3}
        style={{
          display: "flex",
          //marginBottom: "15px",
          alignItems: "baseline",
          //justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <Grid item xs={1} md={1}>
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            style={{ width: "8.5rem", padding: "1.063rem" }}
          >
            <KeyboardDatePicker
              disableToolbar
              inputVariant="outlined"
              fullWidth
              style={{
                //marginLeft: "0.625rem",
                width: "18.75rem",
              }}
              variant="inline"
              size="small"
              format="MMM d, yy"
              autoOk={true}
              minDate={systemLiveDate}
              maxDate={futureLimitDate}
              id="date-picker-inline"
              label={<span style={{ color: "#000" }}>From Date*</span>}
              value={fromDate}
              onChange={(date) =>
                setFromDate(date)
              }
              inputProps={{
                style: { fontSize: "0.875rem", fontWeight: "500" },
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
                style: { height: "1rem", width: "1rem" },
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={1} md={1} style={{ marginLeft: "1.875rem" }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              inputVariant="outlined"
              fullWidth
              style={{
                //marginLeft: "0.625rem",
                width: "18.75rem",
              }}
              variant="inline"
              minDate={systemLiveDate}
              maxDate={futureLimitDate}
              size="small"
              format="MMM d, yy"
              autoOk={true}
              id="date-picker-inline"
              label={<span style={{ color: "#000" }}>To Date*</span>}
              value={toDate}
              onChange={(date) =>
                setToDate(date)
              }
              inputProps={{
                style: { fontSize: "0.875rem", fontWeight: "500" },
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
                style: { height: "1rem", width: "1rem" },
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={2} md={2} style={{ marginLeft: "1.875rem" }}>
          <FormControl
            variant="outlined"
            fullWidth
            style={{
              //marginLeft: "0.625rem",
              width: "18.75rem",
            }}
          >
            <InputLabel
              htmlFor="search-input"
              style={{
                marginTop: "-0.313rem",
                fontSize: "0.75rem",
                fontWeight: "600",
                fontFamily: "Montserrat",
              }}
            >
              Search by Employee Name
            </InputLabel>
            <OutlinedInput
              id="search-input"
              size="small"
              style={{ paddingRight: "0.313rem", height: "2.5rem" }}
              value={employee}
              onChange={handleEmployee}
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon style={{ height: "1.25rem", width: "1.25rem" }} />
                </InputAdornment>
              }
              label="Search by Employee Name"
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} md={2} style={{ marginLeft: "1.875rem" }}>
          <FormControl
            variant="outlined"
            fullWidth
            style={{
              //marginLeft: "0.625rem",
              width: "18.75rem",
            }}
          >
            <InputLabel
              htmlFor="search-input"
              style={{
                marginTop: "-0.313rem",
                fontSize: "0.75rem",
                fontWeight: "600",
                fontFamily: "Montserrat",
              }}
            >
              Search by Employee Id{" "}
            </InputLabel>
            <OutlinedInput
              id="search-input"
              size="small"
              style={{ paddingRight: "0.313rem", height: "2.5rem" }}
              value={employeeId}
              onChange={handleEmployeeId}
              onKeyPress={inputValidation}
              inputProps={{
                maxLength: 6,
              }}
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon style={{ height: "1.25rem", width: "1.25rem" }} />
                </InputAdornment>
              }
              label="Search by Employee Id"
            />
          </FormControl>
        </Grid>

        {/* <Grid item xs={2} md={2} style={{ marginLeft: "-0.188rem" }}>
          <FormControl
            variant="outlined"
            fullWidth
            style={{ width: "18.75rem" }}
          >
            <InputLabel
              htmlFor="search-input"
              style={{
                marginTop: "-0.313rem",
                fontSize: "0.75rem",
                fontWeight: "600",
                fontFamily: "Montserrat",
              }}
            >
              Search by Employee Id
            </InputLabel>
            <OutlinedInput
              id="search-input"
              size="small"
              style={{ paddingRight: "0rem", height: "2.5rem" }}
              value={employeeId}
              // type="number"
              autofill="false"
              //onChange={(e) => setEmployeeIdfilter(e.target.value)}
              // onKeyDown={handleEmployeeId}
              // onBlur={handleEmployeeId}
              //onKeyPress={inputValidation}
              onChange={handleEmployeeId}
              // onKeyDown={handleEmployeeId}
              // onBlur={handleEmployeeId}
              onKeyPress={inputValidation}
              // inputProps={{
              //   maxLength: 6,
              // }}
              endAdornment={
                <InputAdornment position="start">
                  <SearchIcon style={{ height: "1.25rem", width: "1.25rem" }} />
                </InputAdornment>
              }
              label="Search by Employee Id"
            />
          </FormControl>
        </Grid> */}
        {/* <div>
          <Button
            variant="outlined"
            color="primary"
            //disabled={selected.length > 0 ? false : true}
            style={{
              whiteSpace: "nowrap",
              backgroundColor: "#0B1941",
              //backgroundColor: selected.length > 0 ? "#0B1941" : "#979797",
              textTransform: "unset",
              //  color: "#0B1941",
              border: "0.063rem solid #0B1941",
              color: "#0B1941",
              // border: "1px solid #979797",
              padding: "0.5rem",
            }}
            onClick={handleApplyFilter}
          >
            {console.log("data again", getTeamTimesheetData)}{" "}
            <Typography
              style={{
                fontSize: "0.625rem",
                fontWeight: 500,
                color: "#FFFFFF",
                backgroundColor: "#0B1941",
              }}
            >
              &nbsp;Apply Filters
            </Typography>
          </Button>
          <Button
          variant="outlined"
          size="small"
          // style={{ marginLeft: "37px", width: "121px" }}
          style={{
            marginLeft: "59px",
            width: "121px",
            textTransform: "inherit",
            fontSize: "14px",
          }}
         // onClick={handleRemoveFilter}
        >
          Reset
        </Button>
        </div> */}
        <Box>
          <Button
            variant="outlined"
            size="small"
            // style={{ marginLeft: "37px", width: "121px" }}
            style={{
              marginLeft: "2.438rem",
              width: "7.563rem",
              textTransform: "inherit",
              fontSize: "0.875rem",
            }}
            onClick={handleClearAll}
          >
            Reset
          </Button>
          <Button
            variant="outlined"
            disabled={buttonState}
            style={{
              background: buttonState ? "#9E9E9E" : "#0B1941",
              marginLeft: "1.25rem",
              marginBottom: "3.125rem",
              marginTop: "3.125rem",
              textTransform: "inherit",
              fontSize: "0.875rem",
            }}
            color="#fffff"
            size="small"
            onClick={() => handleApplyFilter()}
          >
            <Typography style={{ color: "white", margin: 0 }}>
              {" "}
              Apply Filter
            </Typography>
          </Button>
        </Box>
      </Grid>
    </div>
  );

  return (
    <div style={{ overflowX: "hidden" }}>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          //margin: "0.625rem",
        }}
      >
        {/* <IconButton> */}
        <StyledBadge badgeContent={countShow} color="secondary">
          <IconButton onClick={toggleDrawer(anchorFilter1, true)}>
            <span class="material-symbols-outlined">filter_alt</span>
          </IconButton>
        </StyledBadge>
        {/* </IconButton> */}
        {/* <FilterAltOutlinedIcon onClick={toggleDrawer(anchorFilter1, true)} /> */}
        <Drawer
          anchor={anchorFilter1}
          open={state[anchorFilter1]}
          onClose={toggleDrawer(anchorFilter1, false)}
          PaperProps={{
            style: { height: "100%", width: "100%" }, // This ensures the drawer covers the whole screen
          }}
        >
          {list(anchorFilter1)}
        </Drawer>
        <Grid
          style={{
            //   width: "3.438rem",
            //   height: "2.688rem",
            //   paddingRight: "0.188rem",
            paddingRight: "1rem",
            paddingTop: "0.75rem",
          }}
          //item xs={2} md={2}
        >
          <CSVLink
            {...csvLink}
            style={
              selected.length > 0
                ? {}
                : {
                    color: "transparent",
                    pointerEvents: "none",
                    cursor: "pointer",
                  }
            }
          >
            <LaunchIcon
              style={{
                // width: "2rem",
                height: "1.375rem",
                // padding: "0.75rem",
                // color: "#FFFFFF",
                color: selected.length > 0 ? "#043365" : "#979797",
                //backgroundColor: selected.length > 0 ? "#0B1941" : "#979797",
              }}
            />
          </CSVLink>

          {/* <Button
            variant="outlined"
            color="primary"
            disabled={selected.length > 0 ? false : true}
            style={{
              whiteSpace: "nowrap",
              //backgroundColor: selected.length > 0 ? "#0B1941" : "#979797",
              textTransform: "unset",
              //  color: "#0B1941",
              border: "1px solid #0B1941",
              color: selected.length > 0 ? "#0B1941" : "#979797",
              // border: "1px solid #979797",
              padding: "8px",
            }}
          > */}
          {/* <LaunchIcon
              style={{
                width: "17rem",
                height: "1.375rem",
                padding: "0.75rem",
                // color: "#FFFFFF",
                color: selected.length > 0 ? "#043365" : "#979797",
                //backgroundColor: selected.length > 0 ? "#0B1941" : "#979797",
              }}
            /> */}
          {/* &nbsp;Export Timesheet */}
          {/* <GetAppIcon
                style={{
                  width: "1rem",
                  height: "1rem",
                  color: "#FFFFFF",
                  backgroundColor: selected.length > 0 ? "#0B1941" : "#979797",
                }}
              /> */}
          {/* <Typography
                style={{
                  fontSize: "0.625rem",
                  fontWeight: 500,
                  color: "#FFFFFF",
                  backgroundColor: selected.length > 0 ? "#0B1941" : "#979797",
                }}
              >
                Export Timesheet
              </Typography> */}
          {/* </Button> */}
          {/* </CSVLink>
          </LaunchIcon> */}
        </Grid>
      </Grid>
      <Paper>
        <TableContainer>
          <Table>
            <TeamTimesheetTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              checked={checked}
            />
            <TableBody>
              {teamTimesheet?.length > 0 ? (
                stableSort(teamTimesheet, getComparator(order, orderBy))
                  //.slice(0, 3)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.empId);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        className={classes.tableRow}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.empId}
                        selected={isItemSelected}
                      >
                        <TableCell
                          padding="checkbox"
                          className={classes.tableCell}
                          style={{
                            position: "sticky",
                            left: 0,
                            background: "none",
                            zIndex: 800,
                            minWidth: "3.313rem",
                            padding: "0rem",
                            // color:"#EFF2F8",
                          }}
                        >
                          {" "}
                          <Checkbox
                            checked={isItemSelected}
                            style={{
                              paddingLeft: "0.75rem",
                              transform: "scale(0.8)",
                            }}
                            checkedIcon={
                              <CheckBoxIcon
                                fontSize="small"
                                className={classes.checkBoxRoot}
                              />
                            }
                            onClick={(event) => handleClick(event, row)}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          onClick={() => {
                            const text = "/timesheetviewpage/:id";
                            const result = text.replace(":id", row.empId);
                            history.push({
                              pathname: result,
                              state: {
                                empId: row.empId,
                                managerId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
                                delegateeId: ssoEmpIdDetails?.empId == tsDelEmpIdRedux ? null :ssoEmpIdDetails?.empId ,
                                fromDate: 
                                  tempFromDate
                                  ? moment(tempFromDate).format("yyyy-MM-DD")
                                  : "",
                                toDate: tempToDate
                                  ? moment(tempToDate).format("yyyy-MM-DD")
                                  : "",
                                  projId:"",
                                tab: "teamTimesheet",
                                compName: "DetailedView",
                              },
                            });
                          }}
                        >
                          {" "}
                          <Grid container direction="column" spacing={1}>
                            <Grid item>
                              <Typography
                                variant="body1"
                                style={{ fontSize: "0.625rem" }}
                              >
                                {row.employeeName}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="body1"
                                style={{ fontSize: "0.563rem" }}
                              >
                                {row.employeeID}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell
                          style={{
                            // paddingLeft: "40px",
                            textAlign: "right",
                            // paddingRight: "1rem",
                            fontSize: "0.625rem",
                            paddingRight: "1.625rem",
                          }}
                          className={classes.tableCell}
                          onClick={() => {
                            // console.log("hi table row clicked!");
                            const text = "/timesheetviewpage/:id";
                            const result = text.replace(":id", row.empId);
                            history.push({
                              pathname: result,
                              state: {
                                empId: row.empId,
                                managerId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
                                delegateeId: ssoEmpIdDetails?.empId == tsDelEmpIdRedux ? null :ssoEmpIdDetails?.empId ,
                                fromDate: tempFromDate
                                  ? moment(tempFromDate).format("yyyy-MM-DD")
                                  : "",
                                toDate: tempToDate
                                  ? moment(tempToDate).format("yyyy-MM-DD")
                                  : "",
                                  projId:"",
                                tab: "teamTimesheet",

                                compName: "DetailedView",
                              },
                            });
                          }}
                        >
                          {row?.billableHours?.toFixed(2)}
                        </TableCell>
                        <TableCell
                          style={{
                            // paddingLeft: "111px",
                            textAlign: "right",
                            // paddingRight: "1rem",
                            fontSize: "0.625rem",
                            paddingRight: "0.313rem",
                          }}
                          className={classes.tableCell}
                          onClick={() => {
                            // console.log("hi table row clicked!");
                            const text = "/timesheetviewpage/:id";
                            const result = text.replace(":id", row.empId);
                            history.push({
                              pathname: result,
                              state: {
                                empId: row.empId,
                                managerId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
                                delegateeId: ssoEmpIdDetails?.empId == tsDelEmpIdRedux ? null :ssoEmpIdDetails?.empId ,
                                fromDate: tempFromDate
                                  ? moment(tempFromDate).format("yyyy-MM-DD")
                                  : "",
                                toDate: tempToDate
                                  ? moment(tempToDate).format("yyyy-MM-DD")
                                  : "",
                                  projId:"",
                                tab: "teamTimesheet",

                                compName: "DetailedView",
                              },
                            });
                          }}
                        >
                          {" "}
                          {row?.nonBillableHours?.toFixed(2)}
                        </TableCell>
                        <TableCell
                          style={{
                            // paddingLeft: "114px",
                            textAlign: "right",
                            paddingRight: "0.75rem",
                            fontSize: "0.625rem",
                          }}
                          className={classes.tableCell}
                          onClick={() => {
                            // console.log("hi table row clicked!");
                            const text = "/timesheetviewpage/:id";
                            const result = text.replace(":id", row.empId);
                            history.push({
                              pathname: result,
                              state: {
                                empId: row.empId,
                                managerId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
                                delegateeId: ssoEmpIdDetails?.empId == tsDelEmpIdRedux ? null :ssoEmpIdDetails?.empId ,
                                fromDate: tempFromDate
                                  ? moment(tempFromDate).format("yyyy-MM-DD")
                                  : "",
                                toDate: tempToDate
                                  ? moment(tempToDate).format("yyyy-MM-DD")
                                  : "",
                                  projId:"",
                                tab: "teamTimesheet",

                                compName: "DetailedView",
                              },
                            });
                          }}
                        >
                          {row?.unapprovedHours?.toFixed(2)}
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {teamTimesheet?.length === 0 ? (
        <Grid>
          <Typography
            style={{
              fontSize: "1.25rem",
              lineHeight: "15rem",
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            No Timesheet entry exists!
          </Typography>
        </Grid>
      ) : (
        <></>
      )}
    <div>
      <Backdrop
        sx={{ color: '#092FED', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
    </div>
    
  );
}

export default ResponsiveTeamTimesheet;