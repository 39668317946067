import React, { useEffect, useState } from "react";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Typography,
  Box,
  makeStyles,
  Grid,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  TextField,
  Select,
  Link,
  Checkbox,
  Button,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeProjectsData } from "../redux/Expense/action";
import { NumericFormat } from "react-number-format";
import Divider from "@material-ui/core/Divider";
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "50%",
    backgroundColor: "#FCFDFF",
    marginRight: "20px",
    borderRadius: 4,
    notchedOutline: {
      borderColor: "#CB444A",
      borderRadius: 4,
    },
    "& .MuiFormLabel-root": {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "500",
      color: "#2B2D30",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "2px solid #162D6E",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#515961",
        ["@media (max-width:600px)"]: { fontSize: "6.5px" },
      },
      "& .MuiSelect-selectMenu": {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "500",
        color: "#2B2D30",
      },
      "& .makeStyles-formControl-104 .MuiInputBase-root .MuiSelect-selectMenu":
        {
          padding: "7px",
        },
      "& .MuiSelect-select:focus": {
        backgroundColor: "#FFFFFF",
      },
    },
  },
  formControl12: {
    width: "50%",
    backgroundColor: "#FCFDFF",
    marginRight: "20px",
    borderRadius: 4,
    notchedOutline: {
      borderColor: "red",
      borderRadius: 4,
    },

    "& .MuiFormLabel-root": {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "500",
      color: "#2B2D30",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "2px solid #162D6E",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
      },
      "& .MuiSelect-selectMenu": {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "500",
        color: "#2B2D30",
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#FFFFFF",
      },
    },
  },
  splitAmountBox: {
    minWidth: "25%",
    notchedOutline: {
      borderColor: "#CB444A",
      borderRadius: 4,
    },

    "& .MuiFormLabel-root": {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "500",
      color: "#2B2D30",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "2px solid #162D6E",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#515961",
        ["@media (max-width:600px)"]: { fontSize: "13px" },
      },
      "& .MuiSelect-selectMenu": {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "500",
        color: "#2B2D30",
      },
      "& .MuiOutlinedInput-input": {
        ["@media (max-width:600px)"]: { fontSize: "13px" },
      },
    },
  },
  splitAmountBoxMobile: {
    minWidth: "10%",
    notchedOutline: {
      borderColor: "#CB444A",
      borderRadius: 1,
    },

    "& .MuiFormLabel-root": {
      fontFamily: "Montserrat",
      fontSize: "13px",
      fontWeight: "600",
      color: "#2B2D30",
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "2px solid #162D6E",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#515961",
      },
      "& .MuiSelect-selectMenu": {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "500",
        color: "#2B2D30",
      },
    },
  },
  projectDetailsMainBox: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #9E9E9E",
    borderRadius: "5px",
    padding: "25px",
  },

  projectBoxControl: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  projectBoxProjectID: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  splitBox: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "20px",
  },
  billableAndDeleteBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "10px",
  },
  billableAndDeleteBoxMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "10px",
    flexDirection: "column",
  },
  checkBoxRoot: {
    background: "#0B1941",
    color: "white",
    "&$checked": {
      background: "#0B1941",
      color: "white",
    },
  },
  checkBoxRootMobile: {
    background: "#0B1941",
    color: "white",
    display: "flex",
    flexDirection: "column",
    "&$checked": {
      background: "#0B1941",
      color: "white",
    },
  },
}));

const SplitBill = (props) => {
  const classes = useStyles();
  const [toggle, setToggle] = useState(true);
  const matches = useMediaQuery("(max-width:600px)");
  const [selectedProjects, setSelectedProjects] = useState([
    {
      projectName: { projectID: "", projName: "", projId: "" },
      splitAmount: "",
      billable: 0,
    },
  ]);
  const [currencySB, setCurrencySB] = useState(null);


  const [projectIdList, setProjectIdList] = useState([]);
  const materialUITextFieldProps = {
    id: "filled-multiline-flexible",
    label: "Split Amount*",
    multiline: true,
    maxRows: 4,
    variant: "outlined",
  };
  //ayushi

  const [selectedItem, setSelectedItem] = useState(true);
  useEffect(() => {
    if (props.Edit) {
      setToggle(false);
      if (props.myExpenseList !== null) {
        setToggle(false);
        //console.log("in the split edit part",props?.myExpenseList);
        if (props?.myExpenseList?.ProjectDetails?.length == 1) {
          setToggle(true);
        } else {
          setToggle(false);
        }

        const dummyArray = [];
        props?.myExpenseList?.ProjectDetails?.map((value) => {
          //console.log("value", value);
          dummyArray.push({
            projectName: {
              projectID: value.ProjectId,
              projName: "",
              projId: value.ProjUniqueId,
            },
            splitAmount: value.ProjAmount,
            billable: value.Billable,
          });
        });
        setSelectedProjects([...dummyArray]);
        // console.log("ineditemployeeProjectsList splitbox1", dummyArray);
        let array = JSON.parse(JSON.stringify(dummyArray));
        props.handleProjectList(array);
      }
    }
  }, [props.myExpenseList]);

  useEffect(() => {
    // console.log("1 checking props.empProjectList data", props.empProjectList);
    if (props.empProjectList !== null && props.empProjectList !== undefined) {
      setProjectIdList(props.empProjectList);
    }
    if (props.empProjectList) {
      if (
        props.empProjectList[0]?.categoryId != 0 &&
        selectedProjects?.length > 1
      ) {
        setSelectedProjects([
          {
            projectName: { projectID: "", projName: "", projId: "" },
            splitAmount: "",
            billable: 0,
          },
        ]);
      }
    }
  }, [props.empProjectList]);
  useEffect(() => {
    //console.log("props.currency SB01 : ", props.currency);

    if (props.currency != null) {
      setCurrencySB(props.currency);
    }
  }, [props.currency]);

  useEffect(() => {
    // console.log(
    //   "ineditemployeeProjectsList splitBox2 props.claimAmt",
    //   props.claimAmt,
    //   "toggle: ",
    //   toggle
    // );
    let array = JSON.parse(JSON.stringify(selectedProjects));
    if (toggle == true) {
      array[0].splitAmount = props.claimAmt;
      setSelectedProjects(array);
      props.handleProjectList(array);
    }
  }, [props.claimAmt]);
  useEffect(() => {
    if (props.expenseCategoryChanged == true) {
      // console.log(
      //   "ineditemployeeProjectsList splitBox3 expenseCategoryChanged",
      //   props.expenseCategoryChanged,
      //   Number(props.claimAmt)
      // );
      setSelectedProjects([
        {
          projectName: { projectID: "", projName: "", projId: "" },
          splitAmount: props.claimAmt,
          billable: 0,
        },
      ]);

      props.toggleExpenseCategoryChanged(false);
    }
  }, [props.expenseCategoryChanged]);
  

  //Functions start

  const handleSelectProject = (e, index) => {
    const { name, value, checked } = e.target;
    if (e.target.value) {
      props.setProjectListSplitBoxError("");
      // props.setProjectError1("");
    }

    let array = JSON.parse(JSON.stringify(selectedProjects));
    //
    //console.log("projectIdList:---",projectIdList,"value:----",value,"name:----",name);
    //
    const element = projectIdList.find((ele) => ele.projCode === e.target.value);
    //
    // console.log("element:----", element);
    //
    let tempBillable = checked == true ? 1 : 0;
    //console.log("tempBillable", tempBillable);
    if (e.target.name === "billable") {
      array[index].billable = tempBillable;
    } else if (e.target.name === "splitAmount") {
      array[index].splitAmount = Number(e.target.value.replaceAll(",", ""));
    }

    if (element) {
      array[index].projectName.projectID = e.target.value;
      array[index].projectName.projName = element.projName;
      array[index].projectName.projId = element.projId;
    }
    setSelectedItem(false);
    setSelectedProjects(array);
    props.handleProjectList(array);
    // console.log("selectedItems",selectedItem )
  };

  const addProjectLine = () => {
    setToggle(false);
    let array = JSON.parse(JSON.stringify(selectedProjects));

    array.push({
      projectName: { projectID: "", projName: "", projId: "" },
      splitAmount: "",
      billable: 0,
    });
    setSelectedProjects(array);
    props.handleProjectList(array);
  };

  const handleRemove = (i, proj) => {
    const list = JSON.parse(JSON.stringify(selectedProjects));
    list.splice(i, 1);
    setSelectedProjects(list);
    props.handleProjectList(list);
  };
  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };
  const handlePadding = (matches) => {
    if (selectedItem) {
      return {
        paddingLeft: matches ? "11px" : "",
        paddingTop: matches ? "6px" : "",
        paddingRight: matches ? "32px" : "",
        paddingBottom: matches ? "7px" : "",
      };
    }
    return {};
  };
  return (
    <Box>
      <Box className={matches ? "" : classes.projectDetailsMainBox}>
        {selectedProjects.map((proj, index) => {
          let optionList = [...projectIdList];
          selectedProjects.forEach((element) => {
            if (proj.projectName.projectID !== element.projectName.projectID) {
              optionList = optionList.filter(
                (item) => item.projCode !== element.projectName.projectID
              );
            }
          });

          return (
            <>
              <Box key={index} className={classes.splitBox}>
                <FormControl
                  variant="outlined"
                  inputProps={{
                    style: handlePadding(matches),
                  }}
                  //style={{ marginTop: matches ? "5px" : "" }}
                  className={
                    props.projectListSplitBoxError != ""
                      ? classes.formControl12
                      : classes.formControl
                  }
                >
                  {matches ? (
                    <InputLabel
                      id="project-id-name"
                      style={{ fontSize: "13px" }}
                    >
                      Project Name*
                    </InputLabel>
                  ) : (
                    <InputLabel id="project-id-name">
                      Project Code/ Project Name*
                    </InputLabel>
                  )}
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    name="projectName"
                    style={{
                      minWidth: matches ? "120px" : "412px",
                      maxHeight: matches ? "200px" : "56px",
                    }}
                    defaultValue=""
                    onChange={(e) => handleSelectProject(e, index)}
                    label="Project CodeProject Name*"
                    MenuProps={{
                      getContentAnchorEl: null,
                    }}
                    value={proj?.projectName?.projectID}
                  >
                    {optionList.map((item) => {
                      return (
                        <MenuItem
                          key={item.projCode + index}
                          value={item.projCode}
                        >
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              style={{
                                fontFamily: "Montserrat",
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "12px",
                                letterSpacing: "0px",
                                textAlign: "left",
                                fontSize: matches ? "13px" : "",
                              }}
                            >
                              {item.projCode}
                            </Typography>
                            {matches ? (
                              ""
                            ) : (
                              <Typography
                                style={{
                                  fontFamily: "Montserrat",
                                  fontSize: "11px",
                                  fontWeight: 500,
                                  lineHeight: "18px",
                                  letterSpacing: "0px",
                                  textAlign: "left",
                                  //fontSize:matches ? "13px" :"",
                                  //paddingLeft: matches ? "11px" : "",
                                }}
                              >
                                {item.projName}
                              </Typography>
                            )}
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {(toggle == false || props.Edit == true ? (
                  <NumericFormat
                    style={{
                      minWidth: matches ? "140px" : "25%",
                      maxWidth: matches ? "1px" : "",
                      maxHeight: matches ? "56px" : "",
                    }}
                    id="outlined-basic"
                    name="splitAmount"
                    label="Split Amount*"
                    variant="outlined"
                    value={proj?.splitAmount}
                    // value={props.splitAmount}
                    className={classes.splitAmountBox}
                    onKeyPress={preventMinus}
                    onChange={(e) => handleSelectProject(e, index)}
                    thousandSeparator={matches ? false : true}
                    inputProps={{ maxLength: matches ? 10 : 12 }}
                    customInput={TextField}
                    {...materialUITextFieldProps}
                  />
                ) : (
                  <NumericFormat
                    style={{
                      minWidth: matches ? "150px" : "25%",
                      maxWidth: matches ? "1px" : "",
                      maxHeight: matches ? "56px" : "",
                      marginRight: "18px",
                    }}
                    id="outlined-basic"
                    disabled
                    name="splitAmount"
                    label="Split Amount*"
                    onKeyPress={preventMinus}
                    variant="outlined"
                    value={proj?.splitAmount}
                    className={classes.splitAmountBox}
                    onChange={(e) => handleSelectProject(e, index)}
                    thousandSeparator={matches ? false : true}
                    customInput={TextField}
                    inputProps={{ maxLength: matches ? 10 : 12 }}
                    {...materialUITextFieldProps}
                  />
                ))}

                {!matches && (
                  <Box
                    className={
                      matches
                        ? classes.billableAndDeleteBoxMobile
                        : classes.billableAndDeleteBox
                    }
                  >
                    <Checkbox
                      name="billable"
                      checked={proj?.billable}
                      style={{ marginRight: "9px" }}
                      checkedIcon={
                        <CheckBoxIcon
                          className={
                            matches
                              ? classes.checkBoxRootMobile
                              : classes.checkBoxRoot
                          }
                        />
                      }
                      onChange={(e) => handleSelectProject(e, index)}
                    />

                    <Typography
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: matches ? "12px" : "16px",
                        fontWeight: 500,
                        letterSpacing: "0px",
                        textAlign: "left",
                        marginLeft: matches?"":"-10px",
                        marginRight: matches?"":"-41px",

                        //marginRight:"44px"
                      }}
                    >
                      Client Billable
                    </Typography>
                    {index !== 0 ? (
                      <Box style={{ paddingLeft: "45px" }}>
                        <Button
                          style={{ minWidth: "24px" }}
                          onClick={() => handleRemove(index, proj)}
                        >
                          <span class="material-symbols-outlined">delete</span>
                        </Button>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                )}
              </Box>

              {matches && (
                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Box
                    style={{
                      fontSize: matches ? "13px" : "",
                      fontFamily: "Montserrat",
                      marginRight: matches ? (index == 0 ? "41px" : "") : "",
                    }}
                  >
                    <Checkbox
                      name="billable"
                      checked={proj?.billable}
                      checkedIcon={
                        <CheckBoxIcon
                          className={
                            matches
                              ? classes.checkBoxRootMobile
                              : classes.checkBoxRoot
                          }
                        />
                      }
                      onChange={(e) => handleSelectProject(e, index)}
                    />
                    Client Billable
                    {index !== 0 ? (
                      <Button
                        style={{ minWidth: "24px" }}
                        onClick={() => handleRemove(index, proj)}
                      >
                        <span class="material-symbols-outlined">delete</span>
                      </Button>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
              )}
            </>
          );
        })}

        <Divider />
        <br />

        <Box
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {(selectedProjects.length < projectIdList.length ? (
            <Box
              style={{
                width: "50%",
                marginRight: matches ? "5px" : "20px",
                borderRadius: "4px",
                //backgroundColor: "#FCFDFF",
              }}
            >
              <Link
                style={{
                  color: "#008CE6",
                  cursor: "pointer",
                  fontFamily: "Montserrat",
                  fontSize: matches ? "12px" : "16px",
                  fontWeight: matches ? "600" : "500",
                  lineHeight: "24px",
                  letterSpacing: "0px",
                  textAlign: "left",
                }}
                target="_blank"
                underline="always"
                onClick={addProjectLine}
              >
                + Add Project
              </Link>
            </Box>
          ) : (
            <Box
              style={{
                width: "50%",
                marginRight: "20px",
                borderRadius: "4px",
                //backgroundColor: "#FCFDFF",
              }}
            >
              <Typography> &nbsp;&nbsp; </Typography>
            </Box>
          ))}
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              color: "#162D6E",
              minWidth: "25%",
              justifyContent: "flex-end",
              marginRight: matches ? "14px" : "",
            }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontSize: matches ? "16px" : "16px",
                fontWeight: matches ? "bolder" : "500",
                textAlign: "Right",
                lineHieght: "24px",
                paddingRight: "2px",
                width: matches ? "120px" : "",
              }}
            >
             Total : {props?.totalClaimAmount}
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontSize: matches ? "16px" : "16px",
                fontWeight: matches ? "bolder" : "500",
                textAlign: "Right",
                lineHieght: "24px",
                paddingRight: "2px",
              }}
            >
              {currencySB != null ? currencySB : ""}
            </Typography>

            <Box
              style={{
                fontFamily: "Montserrat",
                fontSize: matches ? "16px" : "16px",
                fontWeight: "700",
                textAlign: "Right",
                lineHieght: "24px",
                marginTop: "1px",
              }}
            >
              {/* {console.log("abc2", props.claimAmt, selectedProjects)} */}
              {!props.Edit
                ? toggle == true
                  ? props.claimAmt
                    ? props.claimAmt
                    : 0
                  : selectedProjects
                      .reduce((acc, curr, i) => {
                        return (acc = acc + Number(curr.splitAmount));
                      }, 0)
                      .toLocaleString("en-US")
                : selectedProjects
                    .reduce((acc, curr, i) => {
                      return (acc = acc + Number(curr.splitAmount));
                    }, 0)
                    .toLocaleString("en-US")}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SplitBill;
