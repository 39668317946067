import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Box,
  Avatar,
  Typography,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Button,
  InputAdornment,
  Popover,
  Paper,
} from "@material-ui/core";
import { NumericFormat } from "react-number-format";
import Menu from "@material-ui/core/Menu";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import SearchIcon from "@material-ui/icons/Search";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CloseIcon from "@material-ui/icons/Close";
import Filterimg from "../images/filter.svg";
import zIndex from "@material-ui/core/styles/zIndex";

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  backdrop: {
    "& .MuiPaper-root": {
      width: "300px",
      height: "277px ",
      background: " #FFFFFF",
      boxShadow:
        "0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)",
      borderRadius: "4px",
      marginTop: "130px",
    },
  },

  box: {
    width: "173px",
    height: "126px",
  },

  icon: {
    color: "#666666",
    paddingLeft: "12px",
    paddingRight: "21px",
  },
  actionText: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "500",
    color: "#0B1941",
    alignItems: "center",
    marginRight: "30px",
  },
  boxHeading: {
    height: "56px",
    background: " #FFFFFF",
    borderBottom: "1px solid #AEAEB2",

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  inputHeading: {
    marginTop: "16px",
    marginBottom: "8px",
    marginLeft: "25px",
  },
  formControl: {
    width: "244px",
    height: "52px",
    marginLeft: "28px",
  },
  crossIcon: {
    marginRight: "12px",
  },

  formControlClaimeSelectFeild: {
    width: "75px",
    height: "52px",
    marginLeft: "24px",
  },
  formControlClaimTextFeild: {
    width: "150px",
    height: "52px",
    marginLeft: "16px",
  },
  filterImg: {
    width: "24px",
  },
}));

const ExpenseFilter = ({
  filterAnchor,
  filtersetting,
  filterType,
  handleStartDate,
  handleEndDate,
  handleApplyFilter,
  handleClearFilter,
  myExpenseData,
  multiDownload,
  handleFilterToggle,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [expenseId, setExpenseId] = React.useState(null);
  const [expenseName, setExpenseName] = React.useState(null);

  const [expenseCategory, setExpenseCategory] = React.useState(null);

  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [claimAmountDrop, setClaimAmountDrop] = React.useState("lessThanEqual");
  const [claimAmountText, setClaimAmountText] = React.useState(null);
  const [approvedAmountDrop, setApprovedAmountDrop] =
    React.useState("lessThanEqual");
  const [approvedAmountText, setApprovedAmountText] = React.useState(null);
  const [statusText, setStatusText] = React.useState(null);
  const [attachmentText, setAttachmentText] = React.useState(null);
  const [buttonState, setButtonState] = React.useState(true);
  const [projectId, setProjectId] = React.useState(null);
  useEffect(() => {
    //console.log("in the useeffect");
    setAnchorEl(null);
  }, [multiDownload]);

  const handleClose = () => {
    setAnchorEl(null);
    setExpenseId("");
    setExpenseName("");
    setExpenseCategory("");
    // setClaimAmountDrop("");
    setClaimAmountText("");
    // setApprovedAmountDrop("");
    setApprovedAmountText("");
    setStatusText("");
    setAttachmentText("");
    setProjectId("");
    setStartDate(null);
    setEndDate(null);
    handleFilterToggle();
  };

  const handleEndDateChange = (type, date) => {
    setEndDate(date);
    handleEndDate(date);
  };
  const handleStartDateChange = (type, date) => {
    setStartDate(date);
    handleStartDate(date);
  };

  //console.log("start date", startDate);
  //console.log("end date", endDate);

  useEffect(() => {
    if (filtersetting) {
      //console.log("value of filter is", filtersetting);
      //console.log("filter)", Boolean(filterAnchor));
      setAnchorEl(filterAnchor);
    } else {
      //console.log("in the filter popop comcponent");
      setAnchorEl(null);
      setButtonState(true);
      setExpenseId("");
      setExpenseName("");
      setExpenseCategory("");
      // setClaimAmountDrop("");
      setClaimAmountText("");
      // setApprovedAmountDrop("");
      setApprovedAmountText("");
      setStatusText("");
      setAttachmentText("");
      setProjectId("");
      setStartDate(null);
      setEndDate(null);
    }
  }, [filtersetting]);
  const preventMinus = (e) => {
    if (e.code === "Minus" || e.key === "-") {
      e.preventDefault();
    }
  };
  return (
    // <Box style={{ height: "150px" }}>
      <Box component="span" m={1} style={{ alignItems: "center" }}>
        {/* <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.backdrop}
        > */}
        <Popover

          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Paper
            style={{
              width: "300px",
              height : filterType == "Creation Date" ? "300px" : "270px"

            }}
          >
            <Box>
              {filterType == "Expense ID" && (
                <Box style={{ marginBottom: "19px" }}>
                  <Box className={classes.boxHeading}>
                    <Typography variant="div" className={classes.icon}>

                      <span class="material-symbols-outlined">filter_alt</span>
                    </Typography>
                    <Typography variant="p" className={classes.actionText} style={{paddingRight:"58px"}}>
                      
                      {filterType}
                    </Typography>
                    {/* <Typography variant="p" className={classes.crossIcon}>
                      <CloseIcon onClick={handleClose} />
                    </Typography> */}
                    <Button>
                              <CloseIcon onClick={handleClose} />
                            </Button>
                   
                  </Box>
                  <Box style={{ display: "grid", marginTop: "24px" }}>
                    {/* <Typography variant="p" className={classes.inputHeading}>
                    {filterType}
                  </Typography> */}
                    <TextField
                      id="outlined-basic"
                      label="Search the ID"
                      variant="outlined"
                      name={filterType}
                      value={expenseId}
                      style={{ marginLeft: "28px" }}
                      className={classes.formControl}
                      onChange={(e) => {
                        if (e.target.value.length >= 1) {
                          setButtonState(false);
                        }
                        if (e.target.value.length == 0) {
                          setButtonState(true);
                        }
                        setExpenseId(e.target.value.trimStart());
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box  style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            marginTop: "37px",
                          }}
                    
                  >
                    <Typography variant="p" className={classes.removeFilter}>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.removeFilterText}
                        onClick={() => {
                          handleClearFilter();
                          handleClose();
                        }}
                      >
                        Remove Filter
                      </Button>{" "}
                    </Typography>
                    <Typography variant="p" className={classes.applyFilter}>
                      <Button
                        variant="outlined"
                        disabled={buttonState}
                        style={{
                          background: buttonState ? "#9E9E9E" : "#0B1941",
                        }}
                        color="#FFFFFF"
                        size="small"
                        className={
                          expenseId?.length
                            ? classes.applyFilterText
                            : classes.applyFilterTextDisabled
                        }
                        onClick={(e) => {
                          handleApplyFilter(filterType, expenseId);
                          handleClose();
                        }}
                      >
                        <Typography style={{ color: "white", margin: 0 }}> Apply Filter</Typography>
                      </Button>{" "}
                    </Typography>
                  </Box>
                </Box>
              )}
              {filterType == "Expense Name" && (
                <Box style={{ marginBottom: "19px" }}>
                  <Box className={classes.boxHeading}>
                    <Typography variant="p" className={classes.icon}>

                      <span class="material-symbols-outlined">filter_alt</span>
                    </Typography>
                    <Typography variant="p" className={classes.actionText}  style={{paddingRight:"27px"}}>
                      {filterType}
                    </Typography>
                    {/* <Typography variant="p" className={classes.crossIcon}>
                      <CloseIcon onClick={handleClose} />
                    </Typography> */}
                    <Button>
                              <CloseIcon onClick={handleClose} />
                            </Button>
                  </Box>
                  <Box style={{ display: "grid", marginTop: "24px" }}>
                    {/* <Typography variant="p" className={classes.inputHeading}>
                    {filterType}
                  </Typography> */}
                    <TextField
                      id="outlined-basic"
                      label="Search Expense Name"
                      value={expenseName}
                      variant="outlined"
                      // type= "text"
                      name={filterType}
                      className={classes.formControl}
                      onChange={(e) => {
                        if (e.target.value.length >= 1) {
                          setButtonState(false);
                        }
                        if (e.target.value.length == 0) {
                          setButtonState(true);
                        }
                        setExpenseName(e.target.value.trimStart());
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box  style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            marginTop: "37px",
                          }}>
                    <Typography variant="p" className={classes.removeFilter}>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.removeFilterText}
                        onClick={() => {
                          handleClearFilter();
                          handleClose();
                        }}
                      >
                        Remove Filter
                      </Button>{" "}
                    </Typography>
                    <Typography variant="p" className={classes.applyFilter}>
                      <Button
                        variant="outlined"
                        disabled={buttonState}
                        style={{
                          background: buttonState ? "#9E9E9E" : "#0B1941",
                        }}
                        color="#FFFFFF"
                        size="small"
                        className={
                          expenseName?.length
                            ? classes.applyFilterText
                            : classes.applyFilterTextDisabled
                        }
                        onClick={(e) => {
                          handleApplyFilter(filterType, expenseName);
                          handleClose();
                        }}
                      >
                        <Typography style={{ color: "white", margin: 0 }}> Apply Filter</Typography>
                      </Button>{" "}
                    </Typography>
                  </Box>
                </Box>
              )}
              {filterType == "Expense Category" && (
                <Box style={{ marginBottom: "19px" }}>
                  <Box className={classes.boxHeading}>
                    <Typography variant="p" className={classes.icon}>
                      
                      <span class="material-symbols-outlined">filter_alt</span>
                    </Typography>
                    <Typography variant="p" className={classes.actionText}>
                      {filterType}
                    </Typography>
                    {/* <Typography variant="p" className={classes.crossIcon}>
                      <CloseIcon onClick={handleClose} />
                    </Typography> */}
                    <Button>
                    <CloseIcon onClick={handleClose} />
                    </Button>
                  </Box>
                  <Box style={{ display: "grid", marginTop: "24px" }}>
                   
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Select the Category
                      </InputLabel>
                      <Select
                        value={expenseCategory}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select the Category"
                        onChange={(e) => {
                          if (e.target.value.length >= 1) {
                            setButtonState(false);
                          }
                          if (e.target.value.length == 0) {
                            setButtonState(true);
                          }

                          setExpenseCategory(e.target.value);
                        }}
                      >
                        {[
                          ...new Map(
                            myExpenseData.map((item) => [
                              item["categoryName"],
                              item,
                            ])
                          ).values(),
                        ] .sort((a, b) => a.categoryName.localeCompare(b.categoryName)).map((value) => {
                          return (
                            <MenuItem value={value.categoryName}>
                              {value.categoryName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box  style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            marginTop: "37px",
                          }}>
                    <Typography variant="p" className={classes.removeFilter}>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.removeFilterText}
                        onClick={() => {
                          handleClearFilter();
                          handleClose();
                        }}
                      >
                        Remove Filter
                      </Button>{" "}
                    </Typography>
                    <Typography variant="p" className={classes.applyFilter}>
                      <Button
                        variant="outlined"
                        disabled={buttonState}
                        style={{
                          background: buttonState ? "#9E9E9E" : "#0B1941",
                        }}
                        color="#FFFFFF"
                        size="small"
                        className={
                          expenseCategory?.length
                            ? classes.applyFilterText
                            : classes.applyFilterTextDisabled
                        }
                        onClick={(e) => {
                          handleApplyFilter(filterType, expenseCategory);
                          handleClose();
                        }}
                      >
                        <Typography style={{ color: "white", margin: 0 }}> Apply Filter</Typography>
                      </Button>{" "}
                    </Typography>
                  </Box>
                </Box>
              )}
              {filterType == "Creation Date" && (
                <Box style={{ marginBottom: "19px" }}>
                  <Box className={classes.boxHeading}>
                    <Typography variant="p" className={classes.icon}>
                      {/* <img src={Filterimg} className={classes.filterImg} /> */}
                      <span class="material-symbols-outlined">filter_alt</span>
                    </Typography>
                    <Typography variant="p" className={classes.actionText}  style={{paddingRight:"38px"}}>
                      {filterType}
                    </Typography>
                    {/* <Typography variant="p" className={classes.crossIcon}>
                      <CloseIcon onClick={handleClose} />
                    </Typography> */}
                    <Button>
                              <CloseIcon onClick={handleClose} />
                            </Button>
                  </Box>
                  <Box style={{ display: "grid" }}>
                    {/* <Typography variant="p" className={classes.inputHeading}>
                    {filterType}
                  </Typography> */}
                    <Box>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          inputVariant="outlined"
                          fullWidth
                          className={classes.formControl}
                          variant="inline"
                          format="MM/dd/yyyy"
                          changeYear= {true}
                          changeMonth= {true}
                          margin="normal"
                          autoOk={true}
                          id="date-picker-inline"
                          label="Choose Start Date"
                          value={startDate}
                          disableFuture={true}
                          style={{ marginTop: "24px" }}
                          onChange={handleStartDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Box>
                    <Box>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          inputVariant="outlined"
                          fullWidth
                          className={classes.formControl}
                          variant="inline"
                          format="MM/dd/yyyy"
                          changeYear= {true}
                          changeMonth= {true}
                          margin="normal"
                          autoOk={true}
                          id="date-picker-inline"
                          label="Choose End Date"
                          minDate={startDate}
                          disableFuture={true}
                          value={endDate}
                          onChange={handleEndDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Box>
                  </Box>
                  <Box  style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            marginTop: "37px",
                          }}>
                    <Typography variant="p" className={classes.removeFilter}>
                      <Button
                        variant="outlined"
                        size="small"
                        
                        className={classes.removeFilterText}
                        onClick={() => {
                          handleClearFilter();
                          handleClose();
                        }}
                      >
                        Remove Filter
                      </Button>{" "}
                    </Typography>
                    <Typography variant="p" className={classes.applyFilter}>
                      <Button
                        variant="outlined"
                        disabled={!(startDate && endDate)}
                        size="small"
                        style={{
                          background: startDate && endDate ? "#0B1941" : "#9E9E9E",
                        }}
                        color="#FFFFFF"
                        
                        className={
                          startDate && endDate
                            ? classes.applyFilterText
                            : classes.applyFilterTextDisabled
                        }
                        onClick={(e) => {
                          handleApplyFilter(filterType, startDate, endDate);
                          handleClose();
                        }}
                      >
                        <Typography style={{ color: "white", margin: 0 }}> Apply Filter</Typography>
                      </Button>{" "}
                    </Typography>
                  </Box>
                </Box>
              )}

              {filterType == "Claim Amount" && (
                <Box style={{ marginBottom: "19px" }}>
                  <Box className={classes.boxHeading}>
                    <Typography variant="p" className={classes.icon}>
                      {/* <img src={Filterimg} className={classes.filterImg} /> */}
                      <span class="material-symbols-outlined">filter_alt</span>
                    </Typography>
                    <Typography variant="p" className={classes.actionText}  style={{paddingRight:"30px"}}>
                      {filterType}
                    </Typography>
                    {/* <Typography variant="p" className={classes.crossIcon}>
                      <CloseIcon onClick={handleClose} />
                    </Typography> */}
                    <Button>
                              <CloseIcon onClick={handleClose} />
                            </Button>
                  </Box>
                  <Box style={{ display: "grid" }}>
                    {/* <Typography variant="p" className={classes.inputHeading}>
                    {filterType}
                  </Typography> */}
                    <Box>
                      <FormControl
                        variant="outlined"
                        className={classes.formControlClaimeSelectFeild}
                      >
                        {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                        <Select
                          value={claimAmountDrop}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          // label="Age"
                          // name={headCell.id}
                          style={{ marginTop: "24px" }}
                          onChange={(e) => {
                            // if (e.target.value.length >= 1) {

                            //   setButtonState(false);
                            // }
                            // if (e.target.value.length == 0) {

                            //   setButtonState(true);
                            // }

                            setClaimAmountDrop(e.target.value);
                          }}
                        >
                          <MenuItem value={"greaterThanEqual"}>{">="}</MenuItem>
                          <MenuItem value={"lessThanEqual"}> {"<="}</MenuItem>
                        </Select>
                      </FormControl>
                      <NumericFormat
                        // style={{ marginLeft: "28px" }}
                        id="outlined-basic"
                        label="Enter Amount"
                        variant="outlined"
                        onKeyPress={preventMinus}
                        name={filterType}
                        value={claimAmountText}
                        customInput={TextField}

                        className={classes.formControlClaimTextFeild}
                        style={{ marginTop: "24px" }}
                        onChange={(e) => {
                          if (e.target.value.length >= 1 ) {
                            setButtonState(false);
                          }
                          if (e.target.value.length == 0) {
                            setButtonState(true);
                          }

                          setClaimAmountText(e.target.value);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {/* <SearchIcon /> */}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                  <Box  style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            marginTop: "37px",
                          }}>
                    <Typography variant="p" className={classes.removeFilter}>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.removeFilterText}
                        onClick={() => {
                          handleClearFilter();
                          handleClose();
                        }}
                      // onClick={handleClose}
                      >
                        Remove Filter
                      </Button>{" "}
                    </Typography>
                    <Typography variant="p" className={classes.applyFilter}>
                      <Button
                        variant="outlined"
                        disabled={buttonState}
                        style={{
                          background: buttonState ? "#9E9E9E" : "#0B1941",
                        }}
                        color="#FFFFFF"
                        size="small"
                        className={
                          claimAmountText && claimAmountDrop
                            ? classes.applyFilterText
                            : classes.applyFilterTextDisabled
                        }
                        onClick={(e) => {
                          handleApplyFilter(
                            filterType,
                            claimAmountDrop,
                            claimAmountText
                          );
                          handleClose();
                        }}
                      >
                        <Typography style={{ color: "white", margin: 0 }}> Apply Filter</Typography>
                      </Button>{" "}
                    </Typography>
                  </Box>
                </Box>
              )}

              {filterType == "Approved Amount" && (
                <Box style={{ marginBottom: "19px" }}>
                  <Box className={classes.boxHeading}>
                    <Typography variant="p" className={classes.icon}>
                      {/* <img src={Filterimg} className={classes.filterImg} /> */}
                      <span class="material-symbols-outlined">filter_alt</span>
                    </Typography>
                    <Typography variant="p" className={classes.actionText}>
                      {filterType}
                    </Typography>
                    {/* <Typography variant="p" className={classes.crossIcon}>
                      <CloseIcon onClick={handleClose} />
                    </Typography> */}
                    <Button>
                              <CloseIcon onClick={handleClose} />
                            </Button>
                  </Box>
                  <Box style={{ display: "grid" }}>
                    {/* <Typography variant="p" className={classes.inputHeading}>
                    {filterType}
                  </Typography> */}
                    <Box>
                      <FormControl
                        variant="outlined"
                        className={classes.formControlClaimeSelectFeild}
                      >
                        {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                        <Select
                          value={approvedAmountDrop}
                          // options={options}

                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          // label="Age"
                          // name={headCell.id}
                          style={{ marginTop: "24px" }}
                          onChange={(e) => {
                            // if (e.target.value.length >= 1) {

                            //   setButtonState(false);
                            // }
                            // if (e.target.value.length == 0) {

                            //   setButtonState(true);
                            // }

                            setApprovedAmountDrop(e.target.value);
                          }}
                        >
                          <MenuItem
                            value={"greaterThanEqual"}
                            selected="selected"
                          >
                            {">="}
                          </MenuItem>
                          <MenuItem value={"lessThanEqual"}> {"<="}</MenuItem>
                        </Select>
                      </FormControl>
                      <NumericFormat
                        // style={{ marginLeft: "28px" }}
                        id="outlined-basic"
                        label="Enter Amount"
                        variant="outlined"
                        onKeyPress={preventMinus}
                        name={filterType}
                        value={approvedAmountText}
                        customInput={TextField}
                        className={classes.formControlClaimTextFeild}
                        style={{ marginTop: "24px" }}
                        onChange={(e) => {
                          if (e.target.value.length >= 1) {
                            setButtonState(false);
                          }
                          if (e.target.value.length == 0) {
                            setButtonState(true);
                          }

                          setApprovedAmountText(e.target.value);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {/* <SearchIcon /> */}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                  <Box  style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            marginTop: "37px",
                          }} >
                    <Typography variant="p" className={classes.removeFilter}>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.removeFilterText}
                        onClick={() => {
                          handleClearFilter();
                          handleClose();
                        }}
                      // onClick={handleClose}
                      >
                        Remove Filter
                      </Button>{" "}
                    </Typography>
                    <Typography variant="p" className={classes.applyFilter}>
                      <Button
                        variant="outlined"
                        disabled={buttonState}
                        style={{
                          background: buttonState ? "#9E9E9E" : "#0B1941",
                        }}
                        color="#FFFFFF"
                        size="small"
                        className={
                          approvedAmountText && approvedAmountDrop
                            ? classes.applyFilterText
                            : classes.applyFilterTextDisabled
                        }
                        onClick={(e) => {
                          handleApplyFilter(
                            filterType,
                            approvedAmountDrop,
                            approvedAmountText
                          );
                          handleClose();
                        }}
                      >
                        <Typography style={{ color: "white", margin: 0 }}> Apply Filter</Typography>
                      </Button>{" "}
                    </Typography>
                  </Box>
                </Box>
              )}

              {filterType == "Status" && (
                <Box style={{ marginBottom: "19px" }}>
                  <Box className={classes.boxHeading}>
                    <Typography variant="p" className={classes.icon}>
                     
                      <span class="material-symbols-outlined">filter_alt</span>
                    </Typography>
                    <Typography variant="p" className={classes.actionText}  style={{paddingRight:"100px"}}>
                      {filterType}
                    </Typography>
                    {/* <Typography variant="p" className={classes.crossIcon}>
                      <CloseIcon onClick={handleClose} />
                    </Typography> */}
                    <Button>
                              <CloseIcon onClick={handleClose} />
                            </Button>
                  </Box>
                  <Box style={{ display: "grid", marginTop: "24px" }}>
                    {/* <Typography variant="p" className={classes.inputHeading}>
                    {filterType}
                  </Typography> */}
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                      <InputLabel id="demo-simple-select-label">
                        Select a Status
                      </InputLabel>
                      <Select
                        value={statusText}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select a Status"
                        onChange={(e) => {
                          if (e.target.value.length >= 1) {
                            setButtonState(false);
                          }
                          if (e.target.value.length == 0) {
                            setButtonState(true);
                          }
                          setStatusText(e.target.value);
                        }}
                      >
                        {[
                          ...new Map(
                            myExpenseData.map((item) => [item["status"], item])
                          ).values(),
                        ].sort((a, b) => a.status.localeCompare(b.status)).map((value) => {
                          return (
                            <MenuItem value={value.status}>
                              {value.status}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box  style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            marginTop: "37px",
                          }}>
                    <Typography variant="p" className={classes.removeFilter}>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.removeFilterText}
                        onClick={() => {
                          handleClearFilter();
                          handleClose();
                        }}
                      >
                        Remove Filter
                      </Button>{" "}
                    </Typography>
                    <Typography variant="p" className={classes.applyFilter}>
                      <Button
                        variant="outlined"
                        disabled={buttonState}
                        style={{
                          background: buttonState ? "#9E9E9E" : "#0B1941",
                        }}
                        color="#FFFFFF"
                        size="small"
                        className={
                          statusText?.length
                            ? classes.applyFilterText
                            : classes.applyFilterTextDisabled
                        }
                        onClick={(e) => {
                          handleApplyFilter(filterType, statusText);
                          handleClose();
                        }}
                      >
                        <Typography style={{ color: "white", margin: 0 }}> Apply Filter</Typography>
                      </Button>{" "}
                    </Typography>
                  </Box>
                </Box>
              )}

              {filterType == "Attachment" && (
                <Box style={{ marginBottom: "19px" }}>
                  <Box className={classes.boxHeading}>
                    <Typography variant="p" className={classes.icon}>
                      
                      <span class="material-symbols-outlined">filter_alt</span>
                    </Typography>
                    <Typography variant="p" className={classes.actionText}  style={{paddingRight:"58px"}}>
                      {filterType}
                    </Typography>
                    {/* <Typography variant="p" className={classes.crossIcon}>
                      <CloseIcon onClick={handleClose} />
                    </Typography> */}
                    <Button>
                              <CloseIcon onClick={handleClose} />
                            </Button>
                  </Box>
                  <Box style={{ display: "grid" }}>
                    {/* <Typography variant="p" className={classes.inputHeading}>
                    {filterType}
                  </Typography> */}
                    <TextField
                      // style={{ marginLeft: "28px" }}
                      id="outlined-basic"
                      label="Attachment"
                      variant="outlined"
                      name={filterType}
                      value={attachmentText}
                      className={classes.formControl}
                      style={{ marginTop: "24px" }}
                      onChange={(e) => {
                        if (e.target.value.length >= 1) {
                          setButtonState(false);
                        }
                        if (e.target.value.length == 0) {
                          setButtonState(true);
                        }
                        setAttachmentText(e.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box  style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            marginTop: "37px",
                          }}>
                    <Typography variant="p" className={classes.removeFilter}>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.removeFilterText}
                        onClick={() => {
                          handleClearFilter();
                          handleClose();
                        }}
                      >
                        Remove Filter
                      </Button>{" "}
                    </Typography>
                    <Typography variant="p" className={classes.applyFilter}>
                      <Button
                        variant="outlined"
                        disabled={buttonState}
                        style={{
                          background: buttonState ? "#9E9E9E" : "#0B1941",
                        }}
                        color="#FFFFFF"
                        size="small"
                        className={
                          attachmentText?.length
                            ? classes.applyFilterText
                            : classes.applyFilterTextDisabled
                        }
                        onClick={(e) => {
                          handleApplyFilter(filterType, attachmentText);
                          handleClose();
                        }}
                      >
                        <Typography style={{ color: "white", margin: 0 }}> Apply Filter</Typography>
                      </Button>{" "}
                    </Typography>
                  </Box>
                </Box>
              )}

                  {filterType == "Project Code" && (
                <Box style={{ marginBottom: "19px" }}>
                  <Box className={classes.boxHeading}>
                  
                     <Typography variant="p" className={classes.icon}>
                      
                      <span class="material-symbols-outlined">
                      filter_alt
                      </span>
                    </Typography>
                    <Typography variant="p" className={classes.actionText}  style={{paddingRight:"75px"}}>
                      {filterType}
                    </Typography>
                    {/* <Typography variant="p" className={classes.crossIcon}>
                      <CloseIcon onClick={handleClose} />
                    </Typography> */}
                    <Button>
                              <CloseIcon onClick={handleClose} />
                            </Button>
                  </Box>
                  <Box style={{ display: "grid" }}>
                    {/* <Typography variant="p" className={classes.inputHeading}>
                    {filterType}
                  </Typography> */}
                    <TextField
                     
                      id="outlined-basic"
                      label="Project code"
                      variant="outlined"
                      name={filterType}
                      value={projectId}
                      className={classes.formControl}
                      style={{ marginTop: "24px" }}
                      onChange={(e) => {
                        if (e.target.value.length >= 1) {
                          setButtonState(false);
                        }
                        if (e.target.value.length == 0) {
                          setButtonState(true);
                        }
                        setProjectId(e.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box  style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            marginTop: "37px",
                          }}>
                    <Typography variant="p" className={classes.removeFilter}>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.removeFilterText}
                        onClick={() => {
                          handleClearFilter();
                          handleClose();
                        }}
                      >
                        Remove Filter
                      </Button>{" "}
                    </Typography>
                    <Typography variant="p" className={classes.applyFilter}>
                      <Button
                        variant="outlined"
                        disabled={buttonState}
                        style={{
                          background: buttonState ? "#9E9E9E" : "#0B1941",
                        }}
                        color="#FFFFFF"
                        size="small"
                        className={
                          projectId?.length
                            ? classes.applyFilterText
                            : classes.applyFilterTextDisabled
                        }
                        onClick={(e) => {
                          handleApplyFilter(filterType, projectId);
                          handleClose();
                        }}
                      >
                        <Typography style={{ color: "white", margin: 0 }}> Apply Filter</Typography>
                      </Button>{" "}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Paper>
          {/* </Menu> */}
        </Popover>

      </Box>
    // </Box>
  );
};

export default ExpenseFilter;
