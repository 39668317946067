import React from 'react';
import '../css/gmBUPage.css';
import {AppBar, Toolbar, Typography} from '@material-ui/core';
import logo from '../images/incedo-logo.svg';

function GMNavBar() {
	return (
		<>
      <AppBar position="static" style={{ backgroundColor: '#fff', boxShadow: 'none', border:'1.5px solid #D5D5D9'}}>
        <Toolbar>
          <img src={logo} alt="Logo" caption="Incedo" style={{ height: '16px' }} />
          <Typography variant="h6" style={{ fontWeight:'bolder',flexGrow: 1, fontFamily: 'calibri', fontSize: '24px', color: '#1C3663', marginLeft:'30px'}} className='title'>
          Global Mobility Calculator
          </Typography>
        </Toolbar>
      </AppBar>
		</>
	);
}

export default GMNavBar;