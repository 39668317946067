import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import DOMPurify from "dompurify";
import {
  Box,
  Typography,
  Grid,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Slide,
  //makeStyles,
  IconButton,
  Popover,
  Divider,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  //Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { Link, useHistory } from "react-router-dom";
import { ContentCopy } from "@mui/icons-material";
import { NumericFormat } from "react-number-format";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import moment from "moment";
import MuiAlert from "@material-ui/lab/Alert";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {
  getAllProjectsData,
  // getTimesheetCategoryData,
  getTimesheetPurposeData,
  postCreateTimesheetData,
  updateReferenceDateFxn,
  getMyTimesheetData,
  getMissedWeekData,
  GetAutoApprovedAllocatedProjListData,
  postRatingReviewData,
  getRatingDescriptionData,
} from "../redux/TimeSheet/actionTs";
import { getEmpDetail } from "../redux/Expense/action";
import { useMsal, useAccount } from "@azure/msal-react";
import Encrypt from "../Utils/encryption";
import history from "../history";
import CustomCalendar from "./calendar";
import ProjectDialog from "./projectDialog.js";
import EventIcon from "@material-ui/icons/Event";
import dayjs from "dayjs";
import MenuList from "@mui/material/MenuList";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import Decrypt from "../Utils/decryption";
import { async } from "q";
import { Autocomplete, useMediaQuery, useTheme } from "@mui/material";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  snackbarContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "90%",
  },
  thirdOneBox: {
    color: "#0B1941",
    fontFamily: "Montserrat",
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "1.5rem",
    letterSpacing: "-0.01rem",
    textAlign: "left",
  },
  totalBox: {
    color: "#0B1941",
    fontFamily: "Montserrat",
    fontSize: "1.5rem",
    fontWeight: 600,
    lineHeight: "1.5rem",
    letterSpacing: "-0.01rem",
    textAlign: "left",
    marginLeft: "0.313rem",
  },
  successIcon: {
    color: theme.palette.success.main,
    fontSize: 40,
    minWidth: "100%",
  },
  thankYouText: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
    minWidth: "50px",
  },
  messageText: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    minWidth: "50px",
  },
}));

const smallBoxStyleHrs = {
  width: "5.313rem",
  height: "4.375rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingLeft: "0.938rem",
  paddingRight: "0.938rem",
};

function RespTSApp() {
  const [selectedTimesheet, setSelectedTimesheet] = useState([
    {
      projectDetails: { projId: "", projName: "", projType: "", projCode: "" },
      categoryName: "",
      dayHours: [
        { date: null, day: "", hrs: 0 },
        { date: null, day: "", hrs: 0 },
        { date: null, day: "", hrs: 0 },
        { date: null, day: "", hrs: 0 },
        { date: null, day: "", hrs: 0 },
        { date: null, day: "", hrs: 0 },
        { date: null, day: "", hrs: 0 },
      ],
    },
  ]);
  
  const [projects, setProjects] = useState([]);
  const [inputDayHours, setInputDayHours] = useState(Array(7).fill("0"));
  const [inputDayHoursVal, setInputDayHoursVal] = useState(Array(7).fill("0"));

  const [clientTimesheet, setClientTimesheet] = useState([]);
  const [internalTimesheet, setInternalTimesheet] = useState([]);
  const [othersTimesheet, setOthersTimesheet] = useState([]);
  const [combinedTimesheet, setCombinedTimesheet] = useState([]);
  const [dialogArray, setdialogArray] = useState([
    {
      projid: "",
      projectsection: "",
      category: "",
      dates: "",
      days: "",
      dayhours: "",
    },
  ]);
  const { instance, accounts, inProgress } = useMsal();
  const [systemLiveDate, setSystemLiveDate] = useState(null);
  const account = useAccount(accounts[0] || {});
  const [timesheetComment, setTimesheetComment] = useState("");
  const [timesheetId, setTimesheetId] = useState("");
  const [categoryArray, setCategoryArray] = useState([]);
  const [convertedCatArray, setConvertedCatArray] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [refDate, setRefDate] = React.useState(dayjs());
  const [weekStartDate, setWeekStartDate] = useState(null);
  const [weekEndDate, setWeekEndDate] = useState(null);
  const [inputWeek, setInputWeek] = React.useState(null);
  const [missedWeek, setMissedWeek] = useState("");
  const [missedWeekOption, setMissedWeekOptions] = useState([]);
  const [selectedMissedWeek, setSelectedMissedWeek] = useState('');
  const [projDialogOpen, setProjDialogOpen] = useState(false);
  const [countClick, setCountClick] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [comment, setComment] = useState("");
  const [countHolidayLeave, setCountHolidayLeave] = useState(0);
  const [timesheetProjectData, setTimesheetProjectData] = useState([
    {
      projid: "",
      projectsection: "",
      category: "",
      dates: "",
      days: "",
      dayhours: "",
    },
  ]);

  const [timesheetProjectFormData, setTimesheetProjectFormData] = useState([]);

  const [dayWiseautoapproval, setDayWiseautoapproval] = useState([]);

  const [selectedProject, setSelectedProject] = useState(null);
  const [snackbarCommentOpen, setSnackbarCommentOpen] = useState(false);
  const [linkClicked, setLinkClicked] = useState(false);
  const [projectClicked, setProjectClicked] = useState({});
  const [projList, setProjList] = useState([]);
  const [showAddProjectLink, setShowAddProjectLink] = useState(true);
  const [addCategoryCheck, setAddCategoryCheck] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [savedSnackbarOpen, setSavedSnackbarOpen] = useState(false);
  const [submittedSnackbarOpen, setSubmiteedSnackbarOpen] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedNavigation, setSelectedNavigation] = useState("");
  const [saveOrSubmitButton, setSaveOrSubmitButton] = useState("");
  const [finalDialogOpen, setFinalDialogOpen] = useState(false);
  const [formDataFinal, setFormDataFinal] = useState([]);
  const [EnterProjSnackbarOpen, setEnterProjSnackbarOpen] = useState(false);
  const [snackbarOpenForVali, setSnackbarOpenForVali] = useState(false);
  const [snackbarOpenForValiproj, setSnackbarOpenForValiproj] = useState(false);
  const [snackbarOpenForValicat, setSnackbarOpenForValicat] = useState(false);
  const [snackbarOpenForValiWeek, setSnackbarOpenForValiWeek] = useState(false);
  const [invalidDate1, setInvalidDate1] = useState("");
  let invalidDate = "";
  let invaliWeek = "";
  const [snackOpenForInvalidDay, setSnackOpenForInvalidDay] = useState(false);
  const [greatDay, setGreatDay] = useState("");
  const [allProjectsFiltered, setAllProjectsFiltered] = useState([]);
  const [newOthersTimesheet, setNewOthersTimesheet] = useState([]);
  // const classes = useStyle();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const totaldaywise = [];
  const totaldaywise1 = [];
  const [isDayHoursChanged, setIsDayHoursChanged] = useState(false);
  const [isBoxDisabled, setIsBoxDisabled] = useState(false);
  const [weekLimits, setweekLimits] = useState(null);
  const [openCopyLastWeekDialog, setOpenCopyLastWeekDialog] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [statusSnackbarOpen, setStatusSnackbarOpen] = useState(false);
  const { fromDate, toDate, empId, compName, edit } = location.state;
  const [comingFromEdit, setComingFromEdit] = useState(false);
  const [IsCopyLastWeekDisabled, setisCopyLastWeekDisabled] = useState(false);
  const RefDateRed = useSelector((state) => state.timesheetData.RefDateRedux);
  const history = useHistory();
  let categoryItem = ["Billable", "Non Billable"];
  const [expandedSections, setExpandedSections] = useState([]);
  const [expandedSections1, setExpandedSections1] = useState([]);
  const [expandedSections4, setExpandedSections4] = useState([]);
  const [expandedSections3, setExpandedSections3] = useState([]);
  const [finalTimesheetData, setFinalTimesheetData] = useState([]);
  const [employeeType, setEmployeeType] = useState("");
  const [entity, setEntity] = useState("");
  const [designationFlag, setDesignationFlag] = useState(0);
  const [isLoader, isSetLoader] = useState(false);
  const [openRating, setOpenRating] = useState(false);
  const [thankYouRatingOpen, setThankYouRatingOpen] = React.useState(false);
  const [pageModule, setPageModule] = useState("");
  const [ratingHeading, setRatingHeading] = useState("");
  const [isRatingSubmitDisabled, setIsRatingSubmitDisabled] = useState(false);
  const [value, setValue] = React.useState(-1);
  const [hover, setHover] = React.useState(-1);
  const [review, setReview] = React.useState("");
  const [isOkButtonDisabled, setIsOkButtonDisabled] = useState(false);
  const [delEntity, setDelEntity] = useState("");
  const [delEmpType, setDelEmpType] = useState("");
  const [delDesignation, setDelDesignation] = useState("");
  const [delJoiningStartDate, setDelJoiningStartDate] = useState(null);
  const [delEmpEndDate, setDelEmpEndDate] = useState(null);
  const [delDesignationFlag, setDelDesignationFlag] = useState(0);
  const [delEntityId, setDelEntityId] = useState("");
  const [tmAutoApprovalFlag, setTMAutoApprovalFlag] = useState("");
  const [delTMAutoApprovalFlag, setDelTMAutoApprovalFlag] = useState("");
  const [employeeCategory, setEmployeeCategory] = useState("");
  const [stopSubmission, setStopSubmission] = useState(false);
  const [stopSaving, setStopSaving] = useState(false);
  const [stopSubmissionSnackbarOpen, setStopSubmissionSnackbarOpen] = useState(false);
  const [allFixed, setAllFixed] = useState(true);
  const [filledWorkingDay, setFilledWorkingDay] = useState(false);
  const [totaldaywise3, setTotaldaywise3] = useState({});


  const array = [
    [
      { id: 1, name: "One", projId: 21 },
      { id: 2, name: "Two", projId: 21 },
    ],
    [
      { id: 3, name: "Three", projId: 22 },
      { id: 4, name: "Four", projId: 22 },
    ],
    [
      { id: 1, name: "One", projId: 23 },
      { id: 2, name: "Two", projId: 23 },
    ],
    [
      { id: 3, name: "Three", projId: 15 },
      { id: 2, name: "Two", projId: 15 },
      { id: 4, name: "Four", projId: 15 },
    ],
  ];

  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );

  const myTimesheetDelegateesList = useSelector(
    (state) => state?.timesheetData?.getMyTimesheetDelegateeData
  );
  
  const tsDelEmpIdRedux = useSelector(
    (state) => state?.timesheetData?.tsDelEmpId
  );

  const weeksLimit = useSelector(
    (state) => state.timesheetData?.getMyTimesheetList?.TimesheetWeeksLimit
  );

  const timeSheetLists = useSelector(
    (state) =>
      state.timesheetData?.getMyTimesheetList?.NewTimesheetDetails ??
      state.timesheetData?.getMyTimesheetList?.CLWTimesheetDetails ??
      state.timesheetData?.getMyTimesheetList?.ExTimesheetDetails ??
      state.timesheetData?.getMyTimesheetList?.TimesheetDetails
  );

  const missedWeekLists = useSelector(
    (state) => state.timesheetData.getMissedWeeks
  );

  const projectListTs = useSelector(
    (state) => state.timesheetData.getTimesheetPurposeList
  );

  const categoryListTs = useSelector(
    (state) => state.timesheetData.getTimesheetCategoryList
  );
  
  const allProjects = useSelector(
    (state) => state.timesheetData.getAllProjectsList
  );

  const allocatedProj = useSelector(
    (state)=> state.timesheetData.GetAutoapprovalAllocatedProjList

  );

  const ratingLabels = useSelector(
    (state) => state?.timesheetData?.getRatingDescriptionData
  );

  useEffect(() => {
    if(timeSheetLists && timeSheetLists?.length){
      isSetLoader(false);
    }
  }, [timeSheetLists])

  useEffect(() => {
    if(ssoEmpIdDetails){
      setEmployeeType(ssoEmpIdDetails?.employeeType);
      setEntity(ssoEmpIdDetails?.entity);
       if(ssoEmpIdDetails?.designation === "Intern"){
        setDesignationFlag(1);
      }
      setTMAutoApprovalFlag(ssoEmpIdDetails?.tmAutoApprovalflag);
      setEmployeeCategory(ssoEmpIdDetails?.employeeCategory);
    }
  }, [ssoEmpIdDetails])

  useEffect(() => {
    if((tsDelEmpIdRedux !== ssoEmpIdDetails?.empId) && myTimesheetDelegateesList){
      setDelEntity(myTimesheetDelegateesList?.find((item) => item?.empId === +tsDelEmpIdRedux)?.entity);
      setDelEmpType(myTimesheetDelegateesList?.find((item) => item?.empId === +tsDelEmpIdRedux)?.empType);
      setDelDesignation(myTimesheetDelegateesList?.find((item) => item?.empId === +tsDelEmpIdRedux)?.empDesignation);
      setDelJoiningStartDate(myTimesheetDelegateesList?.find((item) => item?.empId === +tsDelEmpIdRedux)?.joiningDate);
      setDelEmpEndDate(myTimesheetDelegateesList?.find((item) => item?.empId === +tsDelEmpIdRedux)?.employmentEndDate);
      setDelEntityId(myTimesheetDelegateesList?.find((item) => item?.empId === +tsDelEmpIdRedux)?.entityId);
      setDelTMAutoApprovalFlag(myTimesheetDelegateesList?.find((item) => item?.empId === +tsDelEmpIdRedux)?.tmAutoApprovalflag);

      if ((myTimesheetDelegateesList?.find((item) => item?.empId === +tsDelEmpIdRedux)?.empDesignation) === "Intern") {
        setDelDesignationFlag(1);
      }
    }
  }, [tsDelEmpIdRedux, ssoEmpIdDetails, myTimesheetDelegateesList]);

  useEffect(() => {
    if (ssoEmpIdDetails) {
      dispatch(getRatingDescriptionData());
    }
  }, [ssoEmpIdDetails]);

  useEffect(() => {
    if (location.state?.compName === "createTimesheet") {
      setIsSaveDisabled(false);
      setComingFromEdit(false);
      isSetLoader(true);
    }
  }, [location.state]);

  useEffect(() => {
    if(location.state?.edit && location.state?.edit === 1){
      setComingFromEdit(true);
      isSetLoader(true);
    }
  }, [location.state])

  useEffect(() => {
    dispatch(getEmpDetail(account?.username));
  }, []);

  useEffect(() => {
    dispatch(GetAutoApprovedAllocatedProjListData())
     }, [timeSheetLists])



  useEffect(() => {
    if (comingFromEdit && fromDate && toDate) {
      const tempStartDate = fromDate;
      const tempEndDate = toDate;
      setRefDate(dayjs(fromDate).startOf("week"));
      setWeekStartDate(tempStartDate);
      setWeekEndDate(tempEndDate);
      let obj = {
        empId: location.state?.empId,
        startDate: tempStartDate
          ? moment(tempStartDate, ["yyyy-MM-DD", "DD/MM/YYYY"]).format(
              "yyyy-MM-DD"
            )
          : "",
        endDate: tempEndDate
          ? moment(tempEndDate, ["yyyy-MM-DD", "DD/MM/YYYY"]).format(
              "yyyy-MM-DD"
            )
          : "",
        lastWeekFlag: 0,
      };
      setComment(""); //to set TmDescription to an empty string
      setInputWeek(generateDateArray(tempStartDate, tempEndDate));
      setClientTimesheet([]);
      setInternalTimesheet([]);
      setOthersTimesheet([]);
      setNewOthersTimesheet([]);
      dispatch(getMyTimesheetData(obj));
      setIsSaveDisabled(true);
      setComingFromEdit(false);
      setAddCategoryCheck([]); //client project : set additional category to be null
    }
  }, [location.state, comingFromEdit]);

  useEffect(() => {
    if (weeksLimit && weekStartDate && weekEndDate) {

      if (
        (weeksLimit?.TMStatusId !== 4 &&
          weeksLimit?.TMStatusId !== undefined && weeksLimit?.TMStatusId !== null ) ||
        (moment(weekEndDate, ["yyyy-MM-DD", "DD/MM/YYYY"]).format("yyyy-MM-DD") < joiningDate || 
          moment(weekEndDate, ["yyyy-MM-DD", "DD/MM/YYYY"]).format("yyyy-MM-DD") < systemLiveDate ||
          moment(weekStartDate, ["yyyy-MM-DD", "DD/MM/YYYY"]).format("yyyy-MM-DD") > empEndDate)
      ) {

        setIsSaveDisabled(true);
      } else {
        setIsSaveDisabled(false);
      }
      if (weeksLimit?.SubmitEnabledFlag === 0) {
        setIsSubmitDisabled(true);
        //setIsSaveDisabled(true);
        setisCopyLastWeekDisabled(true);
      } else {
        setIsSubmitDisabled(false);
        setisCopyLastWeekDisabled(false);
      }
    }
  }, [weeksLimit, timesheetProjectData, weekEndDate, weekStartDate]);


  useEffect(() => {
   if(weeksLimit){
    setweekLimits(weeksLimit ? weeksLimit?.weeklimitfromdate : "");
    setSystemLiveDate(weeksLimit?.UISysytemLiveDate ?? weeksLimit?.PartialWeekSystemLiveDate);
   }
  }, [weeksLimit])
  
  useEffect(() => {
    {
      weeksLimit?.TMDescription === "undefined" 
      ? setComment("")
      :weeksLimit?.TMDescription?.includes(("#~#~"))
      ? setComment(weeksLimit?.TMDescription?.replace(/#~#~/g, "\n"))
      :setComment(weeksLimit?.TMDescription)
    }
  }, [weeksLimit])

  useEffect(() => {
    if (timeSheetLists) {
      filterTimesheetListData();
      setTimesheetComment(
        timeSheetLists[0].Comment === undefined ? "" : timeSheetLists[0].Comment
      );
    }
  }, [timeSheetLists]);

  useEffect(() => {
    if (timeSheetLists && timeSheetLists.length > 0) {
      const newTimesheetId = timeSheetLists[0].TimesheetId;
      setTimesheetId(parseInt(newTimesheetId, 10));
    }
  }, [timeSheetLists]);

  useEffect(() => {
    if (timesheetComment.length > 0) {
      setSnackbarCommentOpen(true);
      setTimeout(() => {
        setSnackbarCommentOpen(false);
        handleCommentSnackClose();
      }, 10000);
    } else {
      setSnackbarCommentOpen(false);
    }
  }, [timesheetComment]);

  useEffect(() => {
    const arr = [];
    clientTimesheet?.forEach((item) => {
      arr.push(item.projectDetails.projId);
    });
    internalTimesheet?.forEach((item) => {
      arr.push(item.projectDetails.projId);
    });
    const filteredPrj = allProjects?.filter(
      (item) => !arr?.includes(item.projId)
    );
    setAllProjectsFiltered(filteredPrj);
    let c = 0;
    internalTimesheet[0]?.category[0]?.dayHours?.map((item)=>{
      if(item.type == 'Holiday' || item.type == 'Leave'){
        c = c+1
      }
    })
    setCountHolidayLeave(c);

  }, [clientTimesheet, internalTimesheet, allProjects]);

  useEffect(() => {
    if (timeSheetLists) {
      filterTimesheetListData();
    }
  }, [timeSheetLists]);

  useEffect(() => {
    dispatch(getAllProjectsData());
  }, []);

  useEffect(() => {
    if (categoryListTs) {
      let tempCat = [...categoryListTs];
      let tempArray = JSON.parse(JSON.stringify(categoryArray));
      tempArray.push(tempCat);
      setCategoryArray(tempArray);
    }
  }, [categoryListTs]);

  useEffect(() => {
    if (categoryArray?.length == selectedTimesheet?.length) {
      const convertedArray = categoryArray?.reduce((result, innerArray) => {
        const tmProjId = innerArray[0]?.tmProjId;
        const obj = { tmProjId, arr: innerArray };
        result.push(obj);
        return result;
      }, []);
      setConvertedCatArray(convertedArray);
    }
  }, [categoryArray]);

  useEffect(() => {
    const tempStart = refDate?.startOf("week");
    const tempEnd = refDate?.endOf("week");

    const tempInputWeek = [
      {
        date: dayjs(tempStart).format("DD/MM/YYYY"),
        day: "Sun",
      },
      {
        date: dayjs(tempStart).add(1, "days").format("DD/MM/YYYY"),
        day: "Mon",
      },
      {
        date: dayjs(tempStart).add(2, "days").format("DD/MM/YYYY"),
        day: "Tue",
      },
      {
        date: dayjs(tempStart).add(3, "days").format("DD/MM/YYYY"),
        day: "Wed",
      },
      {
        date: dayjs(tempStart).add(4, "days").format("DD/MM/YYYY"),
        day: "Thu",
      },
      {
        date: dayjs(tempStart).add(5, "days").format("DD/MM/YYYY"),
        day: "Fri",
      },
      {
        date: dayjs(tempStart).add(6, "days").format("DD/MM/YYYY"),
        day: "Sat",
      },
    ];
    setWeekStartDate(tempStart?.format("DD/MM/YYYY"));
    setWeekEndDate(tempEnd?.format("DD/MM/YYYY"));
    setInputWeek(tempInputWeek);
    setIsBoxDisabled(
      tempStart?.format("DD/MM/YYYY") ===
        moment(weekLimits, ["yyyy-MM-DD", "DD/MM/YYYY"]).format("DD/MM/YYYY")
    );
  }, [refDate]);



  useEffect(() => {
    if (ssoEmpIdDetails) {
      dispatch(getMissedWeekData(tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId));
    }
  }, [ssoEmpIdDetails, tsDelEmpIdRedux, weekStartDate, weekEndDate]);

  useEffect(() => {
    let missedWeekOptions = [];
    if (missedWeekLists) {
      missedWeekLists?.map((item) => missedWeekOptions.push(item?.timePeriod));
    }
    setMissedWeekOptions(missedWeekOptions);
  }, [missedWeekLists, weekStartDate, weekEndDate, ssoEmpIdDetails]);


  const preventInvalidInput = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const currentValue = event.target.value;
    const decimalSeparatorIndex = currentValue.indexOf(".");
    
    if (keyValue === ".") {
      if (decimalSeparatorIndex !== -1) {
        event.preventDefault();
        return;
      }
    } else if (keyValue === "0" && currentValue === "0") {
      event.preventDefault();
      return;
    }
  
    const newValue = currentValue.slice(0, event.target.selectionStart) + keyValue + currentValue.slice(event.target.selectionEnd);
    const isValidInput = /^(\d{0,2}(\.\d{0,2})?)?$/.test(newValue);
    const numericValue = parseFloat(newValue);
    
    if (!isValidInput || isNaN(numericValue) || numericValue > 23.75) {
      event.preventDefault();
    }
  };
  
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function getLabelText(value) {
    return (
      ratingLabels &&
      `${value} Star${value !== 1 ? "s" : ""}, ${
        ratingLabels[value - 1]?.ratingDescription
      }`
    );
  }

  var path = window.location.href;
  var directories = path.split("/");
  var lastDirecotry = directories[directories.length - 1];
  var firstDirectory = directories[directories.length - 2];

  useEffect(() => {
    if (directories && directories?.length === 4) {
      if (
        lastDirecotry === "timesheet-management" ||
        lastDirecotry === "create-Timesheet-Management"
      ) {
        setPageModule("Timesheet");
        setRatingHeading("Timesheet Application");
      } else if (
        lastDirecotry === "expense-Management" ||
        lastDirecotry === "create-Expense-Management" ||
        lastDirecotry === "allExpenseReports"
      ) {
        setPageModule("Expense");
        setRatingHeading("Expense Application");
      } else if (
        lastDirecotry === "gmdashboard" ||
        lastDirecotry === "gmmobility"
      ) {
        setPageModule("GM");
        setRatingHeading("Global Mobility Application");
      }
    } else if (directories && directories?.length === 5) {
      if (firstDirectory === "timesheetviewpage") {
        setPageModule("Timesheet");
        setRatingHeading("Timesheet Application");
      } else if (
        firstDirectory === "viewExpenseDetails" ||
        firstDirectory === "editExpenseDetails" ||
        firstDirectory === "editTravelExpenseDetails" ||
        firstDirectory === "deligation"
      ) {
        setPageModule("Expense");
        setRatingHeading("Expense Application");
      } else if (
        firstDirectory === "gmbupage" ||
        firstDirectory === "gmmobility"
      ) {
        setPageModule("GM");
        setRatingHeading("Global Mobility Application");
      }
    }
  }, [directories]);

  useEffect(() => {
    if (value && value === -1) {
      setIsRatingSubmitDisabled(true);
    }
    else{
      setIsRatingSubmitDisabled(false);
    }
  }, [value]);

  const handleRatingClose = async () => {
    setOpenRating(false);
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    const ratingFormData = new FormData();

    ratingFormData.append(
      "EmpId",
      Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
    ratingFormData.append("ApplicationType", pageModule ? pageModule : "");
    ratingFormData.append("ApplicationDiscription", "");
    ratingFormData.append("Review", "");
    ratingFormData.append("Skippedflag", 1);

    try {
      const response = await dispatch(postRatingReviewData(ratingFormData));

      if (response.status === 200) {
        isSetLoader(false);
        history.push("/timesheet-management");
      } else {
        setThankYouRatingOpen(false);
      }
    } catch {
      setThankYouRatingOpen(false);
    }
  };

  const handleRatingSkipClose = async () => {
    setOpenRating(false);

    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    const ratingFormData = new FormData();

    ratingFormData.append(
      "EmpId",
      Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
    ratingFormData.append("ApplicationType", pageModule ? pageModule : "");
    ratingFormData.append("ApplicationDiscription", "");
    ratingFormData.append("Review", "");
    ratingFormData.append("Skippedflag", 1);

    try {
      const response = await dispatch(postRatingReviewData(ratingFormData));

      if (response.status === 200) {
        isSetLoader(false);
        history.push("/timesheet-management");
      } else {
        setThankYouRatingOpen(false);
      }
    } catch {
      setThankYouRatingOpen(false);
    }
  };

  const handleRatingSubmit = async () => {
    setOpenRating(false);
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    const ratingFormData = new FormData();

    ratingFormData.append(
      "EmpId",
      Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
    ratingFormData.append("ApplicationType", pageModule ? pageModule : "");
    ratingFormData.append(
      "ApplicationDiscription",
      ratingLabels && value && ratingLabels[value - 1]?.ratingDescription
    );
    ratingFormData.append("Review", review ? review : "");
    ratingFormData.append("Skippedflag", 0);

    try {
      const response = await dispatch(postRatingReviewData(ratingFormData));
      if (response.status === 200) {
        isSetLoader(false);
        setTimeout(() => {
          // handleSubmittedClose();
          setThankYouRatingOpen(true);
        }, 500);

        setTimeout(() => {
          history.push("/timesheet-management");
          setThankYouRatingOpen(false);
        }, 3000);
      } else {
        setThankYouRatingOpen(false);
      }
    } catch {
      setThankYouRatingOpen(false);
    }
  };  

  const handleStatusSnackClose = () => {
    setIsSaveDisabled(false);
    setStatusSnackbarOpen(false);
    if (saveOrSubmitButton === "Submit") {
      setTimeout(() => {
        handleSubmittedClose();
        setStatusSnackbarOpen(false);
        history.push("/timesheet-management");
      }, 500);
    }
  };

  const handleSnackClose = () => {
    setSnackbarOpen(false);
    filterTimesheetListData(0);
  };

  const handleCloseEnterProj = () => {
    setEnterProjSnackbarOpen(false);
    setIsSubmitDisabled(false);
  };

  const handleSnackCloseVali = () => {
    setSnackbarOpenForVali(false);
    setIsSubmitDisabled(false);
  };

  const handleSnackCloseValiproj = () => {
    setSnackbarOpenForValiproj(false);
    invaliWeek = "";
    setSnackOpenForInvalidDay(false);
    setIsSubmitDisabled(false);
  };

  const handleSnackCloseValiprojWeek = () => {
    setSnackbarOpenForValiWeek(false);
    setIsSubmitDisabled(false);
  };

  const handleSnackCloseValiprojcat = () => {
    setSnackbarOpenForValicat(false);
    setIsSubmitDisabled(false);
  };

  const handleStopSubmissionClose = () => {
    setStopSubmissionSnackbarOpen(false);
  };

  const handleCommentSnackClose = () => {
    let obj = {
      empId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
      startDate: weekStartDate
        ? moment(weekStartDate, ["yyyy-MM-DD", "DD/MM/YYYY"]).format(
            "yyyy-MM-DD"
          )
        : "",
      endDate: weekEndDate
        ? moment(weekEndDate, ["yyyy-MM-DD", "DD/MM/YYYY"]).format("yyyy-MM-DD")
        : "",
      lastWeekFlag: 0,
    };
    dispatch(getMyTimesheetData(obj));
    setIsDayHoursChanged(false);
  };

  const handleBackdropClick = () => {
    setIsSubmitDisabled(false);
  };

  const handleStopSubmission = () => {
    handleDialogCloseCancel();
    setStopSubmissionSnackbarOpen(true);
  }

  // const handleStopSaving = () => {
  //   setStopSubmissionSnackbarOpen(true);
  // }

  const handleDialogClose = async () => {
    if(isOkButtonDisabled){
      return
    }
setIsOkButtonDisabled(true);
    isSetLoader(true);
    setIsSubmitDisabled(false);
    setFinalDialogOpen(false);
    try {

      const response = await dispatch(postCreateTimesheetData(formDataFinal));
      setIsSubmitDisabled(true);
      setIsSaveDisabled(true);

      if (response.status === 200) {
        isSetLoader(false);
        setIsSubmitted(true);
        // setSubmiteedSnackbarOpen(true);

        // setTimeout(() => {
        //   handleSubmittedClose();
        //   history.push("/timesheet-management");
        // }, 5000);
      } else {
        isSetLoader(false);
        setStatusSnackbarOpen(true);
        setSavedSnackbarOpen(true);
        setSubmiteedSnackbarOpen(false);
        setIsSubmitted(false);
        setIsSubmitDisabled(false);
        setIsSaveDisabled(true);

      }
    } catch (error) {
      isSetLoader(false);
      setIsSubmitted(false);
      setSubmiteedSnackbarOpen(false);
      setIsSubmitDisabled(false);
      setIsSaveDisabled(false);
    }
  };

  const handleDialogCloseCancel = () => {
    setFinalDialogOpen(false);
    setIsSubmitDisabled(false);
  };

  const handleConfirmClose = () => {
    setShowConfirmation(false);
  };

  const handleYesButton = () => {

    if(selectedNavigation === "Fill"){
      handleMissedWeek(selectedMissedWeek);
    } else if(selectedNavigation === "Previous"){
      handlePrevWeek();
    } else if(selectedNavigation === "Next"){
      handleNextWeek();
    }

    setIsDayHoursChanged(false);
    setShowConfirmation(false);
    setProjectClicked({});
  };

  const handleMissedWeek = (missedWeek) => {
    setProjDialogOpen(false);
    setShowAddProjectLink(true);
    setProjects([]);
    setComment("");
    let tempStartDate = missedWeekLists.find(
      (item) => missedWeek === item.timePeriod
    )?.fromDate;
    let tempEndDate = missedWeekLists.find(
      (item) => missedWeek === item.timePeriod
    )?.toDate;
    setRefDate(dayjs(tempStartDate).startOf("week"));
    setWeekStartDate(tempStartDate);

    setWeekEndDate(tempEndDate);

    let obj = {
      empId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
      startDate: tempStartDate
        ? moment(tempStartDate, ["yyyy-MM-DD", "DD/MM/YYYY"]).format(
            "yyyy-MM-DD"
          )
        : "",
      endDate: tempEndDate
        ? moment(tempEndDate, ["yyyy-MM-DD", "DD/MM/YYYY"]).format("yyyy-MM-DD")
        : "",
      lastWeekFlag: 0,
    };

    setInputWeek(generateDateArray(tempStartDate, tempEndDate));
    dispatch(getMyTimesheetData(obj));
    setProjectClicked({});
    setAddCategoryCheck([]);
    setExpandedSections([]);
    setExpandedSections1([]);
    setExpandedSections4([]);
    setExpandedSections3([]);
  };

  const generateDateArray = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const dateArray = [];

    while (startDate <= endDate) {
      const formattedDate = startDate.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });

      const dayOfWeek = startDate.toLocaleDateString("en-GB", {
        weekday: "short",
      });

      dateArray.push({
        date: formattedDate,
        day: dayOfWeek,
      });

      startDate.setDate(startDate.getDate() + 1);
    }

    return dateArray;
  };

  const handleCopyLastWeekClose = () => {
    setOpenCopyLastWeekDialog(false);
  };

  const okCopyLastWeek = async () => {
    isSetLoader(true);
    let obj = {
      empId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
      startDate: weekStartDate
        ? moment(weekStartDate, ["yyyy-MM-DD", "DD/MM/YYYY"]).format(
            "yyyy-MM-DD"
          )
        : "",
      endDate: weekEndDate
        ? moment(weekEndDate, ["yyyy-MM-DD", "DD/MM/YYYY"]).format("yyyy-MM-DD")
        : "",
      lastWeekFlag: 1,
    };
    try{
      const response = await dispatch(getMyTimesheetData(obj));
setNewOthersTimesheet([]);
      setProjects([]);
      setAddCategoryCheck([]);
      setProjectClicked({});
      setOpenCopyLastWeekDialog(false);
      setIsDayHoursChanged(true);
      
      if(response.status === 200){
        isSetLoader(false);
      }
      else{
        isSetLoader(false);
      }
    }
    catch{
      isSetLoader(false);
    }
  };

  const handleCopyLastWeek = () => {
    if(countHolidayLeave > 0){
    setOpenCopyLastWeekDialog(totalHours - (countHolidayLeave*8) > 0 ? true : false);
    }
    else{
    setOpenCopyLastWeekDialog(totalHours > 0 ? true : false);
    }

    if (totalHours === 0 || totalHours-(countHolidayLeave*8) == 0) {
      let obj = {
        empId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
        startDate: weekStartDate
          ? moment(weekStartDate, ["yyyy-MM-DD", "DD/MM/YYYY"]).format(
              "yyyy-MM-DD"
            )
          : "",
        endDate: weekEndDate
          ? moment(weekEndDate, ["yyyy-MM-DD", "DD/MM/YYYY"]).format(
              "yyyy-MM-DD"
            )
          : "",
        lastWeekFlag: 1,
      };
      setProjects([]);
      setAddCategoryCheck([]);
      setNewOthersTimesheet([]);
      dispatch(getMyTimesheetData(obj));
      setIsDayHoursChanged(true);
    }
    setProjectClicked({});
  };

  const filterTimesheetListData = () => {
    let inputArray = JSON.parse(JSON.stringify(timeSheetLists));
    setTimesheetId(inputArray[0].TimesheetId ? inputArray[0].TimesheetId : "");
    const outputArray = [];
    const projectMap = new Map();

    for (const object of inputArray) {
      if (!projectMap.has(object.ProjName)) {
        projectMap.set(object.ProjName, []);
      }
      projectMap.get(object.ProjName).push(object);
    }

    for (const [projName, objects] of projectMap) {
      const projectDetails = {
        projId: objects[0].ProjId,
        projName: projName,
        projType: objects[0].ProjType,
        projCode: objects[0].ProjCode,
        projPurp : objects[0].ProjPurpose != null ? objects[0].ProjPurpose : null,
      };

      const fixedProject = objects[0].FixedProj;
      const categories = new Map();

      for (const object of objects) {
        if (!categories.has(object.AllocationCategoryName)) {
          categories.set(
            object.AllocationCategoryName,
            Array(7).fill({
              date: null,
              day: "",
              hrs: 0,
              type: "",
              dayEnabledFlag: 0,
            })
          );
        }

        const dayHours = categories.get(object.AllocationCategoryName);
        const dayIndex = new Date(object.Dates).getDay();
        dayHours[dayIndex] = {
          date: object.Dates,
          day: object.Days,
          hrs: parseFloat(object.DayHours),
          type: object.Type,
          dayEnabledFlag: object.DayEnabledFlag,
        };
      }

      const categoryArray = [];

      for (const [categoryName, dayHours] of categories) {
        dayHours.sort((a, b) => {
          const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
          const dayIndexA = daysOfWeek.indexOf(a.day);
          const dayIndexB = daysOfWeek.indexOf(b.day);
          return dayIndexA - dayIndexB;
        });
        categoryArray.push({
          categoryName: categoryName,
          dayHours: dayHours,
        });
      }
      const outputObject = {
        projectDetails: projectDetails,
        fixedProject: fixedProject,
        category: categoryArray,
        ProjectSectionType: objects[0].ProjectSectionType,
      };
      outputArray.push(outputObject);
    }
    setClientTimesheet(
      outputArray?.filter((item) => item.ProjectSectionType === "Client")
    );
    setInternalTimesheet(
      outputArray?.filter((item) => item.ProjectSectionType === "Internal")
    );
    setOthersTimesheet(
      outputArray?.filter((item) => item.ProjectSectionType === "Others")
    );
  };

  const handleAddProj = () => {
    setCountClick(countClick + 1);
    setProjDialogOpen(true);
    setShowAddProjectLink(false);
    handleAddProject();
  };

  const handleAddProject = () => {
    const newProject = {
      projectDetails: null,
      categories: [
        {
          categoryName: "",
          hours: Array(7).fill("0"),
        },
      ],
    };
    setInputDayHours(Array(7).fill("0"));
    setProjects([...projects, newProject]);
    const c = projects.length;
    const check1 = [...addCategoryCheck];
    check1[c] = true;
    setAddCategoryCheck(check1);
  };

  const renderAutocomplete = () => {
    const handleCategoryName = (projectIndex, categoryIndex, categoryName) => {
      const updatedProjects = [...projects];
      updatedProjects[projectIndex].categories[categoryIndex].categoryName =
        categoryName;
      setProjects(updatedProjects);
    };

    const handleDayHourChange = (
      dayIndex,
      value,
      projectIndex,
      categoryIndex
    ) => {
      setIsDayHoursChanged(true);
      const updatedProjects = [...projects];
      const updatedInputDayHours = [...updatedProjects[projectIndex]?.categories[categoryIndex].hours]
      updatedInputDayHours[dayIndex] = {
        ... updatedInputDayHours[dayIndex] , hrs:parseFloat(value)
      }
      updatedProjects[projectIndex].categories[categoryIndex].hours = updatedInputDayHours;
      setInputDayHours(updatedInputDayHours);
      setProjects(updatedProjects);
     };

    const handleRemoveCategory = (projectIndex, categoryIndex) => {
      if (categoryIndex === 0) {
        const updatedProjects = [...projects];
        updatedProjects.splice(projectIndex, 1);
        setProjects(updatedProjects);
      } else {
        const updatedProjects = [...projects];
        updatedProjects[projectIndex].categories.splice(categoryIndex, 1);
        setProjects(updatedProjects);
      }
    };

    const handleRemoveProject = (projectIndex) => {
      const updatedProjects = [...projects];
      updatedProjects.splice(projectIndex, 1);
      setProjects(updatedProjects);
    };

    return (
      <>
        <Box>
          {projects?.map((project, projectIndex) =>
            projects[projectIndex].categories?.map(
              (category, categoryIndex) => (
                <>
                  <Box style={{marginBottom: "-0.563rem"}}>
                    <Box>
                      <Box style={{
                        display: "flex", 
                        justifyContent: "space-between", 
                        alignItems: "center", 
                        paddingLeft: "1.563rem",
                        paddingTop: "0.8rem",
                        paddingRight: "0.8rem"
                      }}
                        onClick={() => toggleSection4(projectIndex)}
                      >
                        {project?.projectDetails ? 
                          <Typography style={{color: "#2D2D2D", fontSize: "0.7rem", fontWeight: 600, fontFamily: "Montserrat"}}>
                          {project?.projectDetails?.projCode} {" "} - {" "} <span style={{color: "#2D2D2D", fontSize: "0.7rem", fontWeight: 600, fontFamily: "Montserrat"}}>{project?.projectDetails?.projName}</span>
                          <br/>
                       
                          {project?.projectDetails?.projPurpose!= null ?
                          <span  style={{ 
                            backgroundColor: "#E3E8F7",
                              width: "134px",
                              //padding: "2px",
                              height: "14px",
                              fontSize: "10px",
                              //paddingLeft: "22px",
                              padding:
                                " 1px 2px 2px 4px",
                             marginBottom:"8px",
                              
                           }}> {" "}  {project?.projectDetails?.projPurpose}</span>
                        :""}
                          </Typography>: <Typography></Typography>}
                        {expandedSections4.includes(projectIndex) ? (
                          <KeyboardArrowDownIcon
                            onClick={() => toggleSection4(projectIndex)}
                            style={{ cursor: 'pointer' }}
                          />
                        ) : (
                          <KeyboardArrowUpIcon
                            onClick={() => toggleSection4(projectIndex)}
                            style={{ cursor: 'pointer' }}
                          />
                        )}
                      </Box>
                      <hr/>
                    </Box>
                  </Box>

                  {!expandedSections4.includes(projectIndex) && (
                    <Box
                      style={{
                        //paddingLeft: "20px",
                        backgroundColor: "#F9F9F9",
                      }}
                      key={projectIndex}
                    >
                      <Box
                      >
                          <Grid item xs={12} sm={12}>
                            <Box
                              style={{minWidth: "86%", paddingLeft: "1.563rem", paddingTop: "1rem"}}
                            >
                              
                              <Autocomplete
                                freeSolo
                                id={`combo-box-demo-${projectIndex}`}
                                options={allProjectsFiltered}
                                getOptionLabel={(option) =>
                                  option.projPurpose == null ? `${option.projName} - ${option.projCode}`
                                  : `${option.projName} - ${option.projCode} ${" "}  ${option.projPurpose}`
                                }
                                value={project?.projectDetails}
                                style={{ width: "85%", fontSize: "0.875rem" }}
                                variant="outlined"
                                sx={{
                                  ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                                    top: "auto", 
                                  },
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Project*"
                                    variant="outlined"
                                    //className={classes.formControlProj}
                                  />
                                )}
                                onChange={(event, selectedProject) => {
                                  const updatedProjects = [
                                    ...projects,
                                  ];
                                  updatedProjects[
                                    projectIndex
                                  ].projectDetails = selectedProject;
                                  setProjects(updatedProjects);
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid>
                            <Box
                              key={categoryIndex}
                            >
                            <Grid item xs={12} sm={12}>
                            <Box
                              style={{minWidth: "86%", paddingLeft: "1.563rem", paddingTop: "1rem"}}
                            >
                              <FormControl
                                variant="outlined"
                                style={{ width: "85%", fontSize: "0.875rem" }}
                              >
                                <InputLabel
                                  id={`category-label-${projectIndex}-${categoryIndex}`}
                                >
                                  Category*
                                </InputLabel>
                                <Select
                                  labelId={`category-label-${projectIndex}-${categoryIndex}`}
                                  value={category.categoryName}
                                  onChange={(event) =>
                                    handleCategoryName(
                                      projectIndex,
                                      categoryIndex,
                                      event.target.value
                                    )
                                  }
                                  label="Category"
                                >
                                  {categoryItem.map((item) => (
                                    <MenuItem key={item} value={item}>
                                      {item}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>  
                            </Grid>
                              <Box style={{marginTop: "0.8rem", marginBottom: "-0.1rem"}}
                              >
                                {category.hours?.map((hours, dayIndex) => {
                                  const checkLeave =
                                    internalTimesheet[0]?.category[0]?.dayHours[
                                      dayIndex
                                    ];
                                  return (  
                                  <Grid container> 
                                  <Grid item xs={6} sm={6}>
                                  <Box style={{backgroundColor: "#DCDFE9", marginBottom: "-0.5rem"}}>
                                    <Typography style={{padding: "2rem"}}>
                                    {moment(inputWeek[dayIndex].date, "DD/MM/YYYY")
                                      .format("MMM D, YYYY")
                                      .slice(0, -6)} , {" "} {inputWeek[dayIndex].day}
                                    </Typography>
                                    <hr/>
                                  </Box>
                                </Grid>
                                  <Grid item xs={6} sm={6}>
                                    <Box 
                                      style={{
                                        background: checkLeave?.type === "Weekly Off"? "#EDEEF2": "",
                                        paddingBottom: "0.5rem" }}>
                                      <Box>
                                        <NumericFormat
                                        id={`hours-${projectIndex}-${categoryIndex}-${dayIndex}`}
                                          variant="outlined"
                                          name={`hoursName-${projectIndex}-${categoryIndex}-${dayIndex}`}
                                          customInput={TextField}
                                          value={hours.hrs || "0"}
                                          //readOnly
                                          onChange={(event) => {
                                              handleDayHourChange(dayIndex,event.target.value,projectIndex,
                                                categoryIndex
                                              );
                                            }}
                                          InputProps={{
                                            inputProps: {
                                              maxLength: 5,
                                              min: 0,
                                              step: 0.25,
                                              onKeyPress:
                                                preventInvalidInput,
                                            },
                                            style: {
                                              fontSize: 14,
                                            },
                                          }}
                                          style={{
                                            width: "80%",
                                            margin: "1rem",
                                            marginTop: "1.25rem",
                                            backgroundColor:
                                                checkLeave?.type === "Working"
                                                  ? "#FFFFFF"
                                                  : checkLeave?.type === "Holiday"
                                                  ? "rgba(246, 229, 208, 1)"
                                                  : checkLeave?.type === "Leave"
                                                  ? "rgba(199, 234, 234, 1)"
                                                  : "",

                                            border:
                                              checkLeave?.type === "Working"
                                                ? "0.063rem solid #BDBDBD"
                                                : checkLeave?.type ===
                                                  "Weekly Off"
                                                ? "0.063rem solid #BDBDBD"
                                                : checkLeave?.type === "Holiday"
                                                ? "0.063rem solid #E5BF90"
                                                : checkLeave?.type === "Leave"
                                                ? "0.063rem solid #629797"
                                                : "",
                                            borderRadius: "0.313rem",
                                          }}
                                          disabled={
                                            // checkLeave?.dayEnabledFlag === 0
                                            checkLeave?.date < joiningDate || checkLeave ?.date < systemLiveDate || checkLeave?.date > empEndDate
                                            // checkLeave?.dayEnabledFlag === 0
                                           
                                              ? true
                                              : false
                                          }
                                        />
                                      </Box>
                                    </Box>
                                    </Grid> 
                                  </Grid>
                                )})}
                                <Box
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end", 
                                    paddingRight: "1.563rem",
                                  }}
                                >
                                  <Button onClick={() => handleRemoveCategory(projectIndex, categoryIndex)}>                              
                                  <span class="material-symbols-outlined">
                                    delete
                                  </span>
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                      </Box>
                    </Box>
                  )}

                  <Divider
                    style={{
                      backgroundColor: "#E1E1E1",
                      width: "100%",
                      heigth: "0.031",
                    }}
                    orientation="horizontal"
                  />
                </>
              )
            )
          )}
        </Box>
        <Box
          style={{
            background: "#F9F9F9",
            height: "2.375rem",
            alignItems: "center",
            paddingTop: "0.813rem",
            paddingLeft: "1.625rem",
          }}
        >
          <Link
            style={{
              //paddingTop: "7px",
              color: "#008CE6",
              cursor: "pointer",
              fontFamily: "Montserrat",
              fontSize: "1rem",
              fontWeight: "500",
              lineHeight: "1.5rem",
              letterSpacing: "0rem",
              textAlign: "left",
            }}
            // target="_blank"
            underline="always"
            onClick={handleAddProject}
          >
            + Add Project
          </Link>
        </Box>
        <Divider
          style={{ backgroundColor: "#E1E1E1", width: "100%", heigth: "0.031rem" }}
          orientation="horizontal"
        />
      </>
    );
  };

  //copying the filled projects to othersTimesheet in the format of client and internal projects
  const copyAndModifyOtherProject = () => {
    if (projects) {
      const modifiedArray = projects?.map((project, projectIndex) => {

        const modifiedCategories = project?.categories?.map(
          (category, categoryIndex) => {
            const modifiedHours = category.hours.map((hour, dayIndex) => ({
              date: internalTimesheet[0]?.category[0].dayHours[dayIndex].date,
              day: internalTimesheet[0]?.category[0].dayHours[dayIndex].day,

              hrs:hour.hrs || 0,
              type: internalTimesheet[0]?.category[0].dayHours[dayIndex].type,
              dayEnabledFlag:
                 internalTimesheet[0]?.category[0].dayHours[dayIndex].date < joiningDate 
              || internalTimesheet[0]?.category[0].dayHours[dayIndex].date < systemLiveDate 
              || internalTimesheet[0]?.category[0].dayHours[dayIndex].date > empEndDate
              ? 0
              : 1,
            }));

            return {
              categoryName: category.categoryName,
              dayHours: modifiedHours,
            };
          }
        );

        return {
          projectDetails: { ...project.projectDetails }, // Copy projectDetails
          category: modifiedCategories,
        };
      });
      setOthersTimesheet(modifiedArray);
    }
  };

  useEffect(() => {
    let sumWeekWiseHours = 0;
    timesheetProjectData.forEach((item) => {
      const { dates, dayhours } = item;
      sumWeekWiseHours += item.dayhours;
    });
    setTotalHours(sumWeekWiseHours);
  }, [timesheetProjectData]);

  useEffect(() => {
    if (projects) {
      copyAndModifyOtherProject();
    }
  }, [projects]);

  //copy useeffect hai
  useEffect(() => {
    const arr = [];
    if (timesheetProjectData) {
      timesheetProjectData.map((item) => {
        arr.push({
          category: item.category,
          projid: item.projid,
          projectsection: item.projectsection,
          dates: item.dates,
          days: item.days,
          dayhours: item.dayhours,
        });

        // timesheetProjectFormData.push(arr);
      });
    }

    setTimesheetProjectFormData(arr);
    // console.log("check form data", arr, timesheetProjectFormData);
  }, [timesheetProjectData]);

  useEffect(() => {
    const arr = [];
    if (timesheetProjectData) {
      timesheetProjectData.map((item) => {
        arr.push({
          projid: item.projid,
          projectsection: item.projectsection,
          category: item.category,
          dates: item.dates,
          days: item.days,
          dayhours: item.dayhours,
        });

        // timesheetProjectFormData.push(arr);
      });
    }
    setTimesheetProjectFormData(arr);
    // console.log("check form data", arr, timesheetProjectFormData);
  }, [timesheetProjectData]);

  let joiningDateTemp = ssoEmpIdDetails?.empJoiningDate?.slice(0 , -9);
  let joiningDate  = moment(joiningDateTemp, ["yyyy-MM-DD", "DD/MM/YYYY"]).format("yyyy-MM-DD");
  let empEndDateTemp = ssoEmpIdDetails?.employmentEndDate?.slice(0,-9);
  let empEndDate = moment(empEndDateTemp, ["yyyy-MM-DD", "DD/MM/YYYY"]).format("yyyy-MM-DD");

  //submit button functionality

  let updatedTimesheetProjectData = [];
  const handleProceed = () => {
    setStopSubmission(false);
    combineTimesheetData();
    setIsSubmitDisabled(true);
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    let formData = new FormData();

    formData.append("TMid", +timesheetId);
    formData.append("TMStatus", "In Review");
    formData.append(
      "EmpId",
      Encrypt(key, JSON.stringify(tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId))
    );
    formData.append(
      "LoginBy",
      Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
    formData.append("EmpEntityId", tsDelEmpIdRedux && delEntityId ? delEntityId : ssoEmpIdDetails?.entityId);
    formData.append(
      "FromDate",
      moment(weekStartDate, ["yyyy-MM-DD", "DD/MM/YYYY"]).format("yyyy-MM-DD")
    );
    formData.append(
      "ToDate",
      moment(weekEndDate, ["yyyy-MM-DD", "DD/MM/YYYY"]).format("yyyy-MM-DD")
    );
    if (comment?.includes("\n")) {
      const formattedText = comment?.replace(/\n/g, "#~#~");
      formData.append("TMDescription", comment ? formattedText : "");
    } else {
      formData.append("TMDescription", comment ? comment : "");
    }

    // formData.append(
    //   "TimeSheetProjectLinks",
    //   JSON.stringify(timesheetProjectFormData)
    // );
    // formData.append(
    //   "TimeSheetProjectLinks",
    //   JSON.stringify(timesheetProjectData)
    // );

    //function to arrange data in date order
    const compareDates = (a, b) => {
      const dateA = new Date(a.dates);
      const dateB = new Date(b.dates);
      return dateA - dateB;
    };
    // Sort the array
    updatedTimesheetProjectData.sort(compareDates);

    let checkEntries = false;
    let sumOthers = 0;
    let counter = 0;

    updatedTimesheetProjectData.map((item) => {
      if (item.projectsection === "Others") {
        sumOthers += item.dayhours;
        counter++;
        if (!item.projid || !item.category) {
          checkEntries = true;
          return;
        }
      }
    });
    //this condition is to verify if other project has been added but no hours / project/category has been filled
    if (sumOthers === 0 && counter > 0) {
      checkEntries = true;
    }
    let sumWeekWiseHours = 0;
    let storeWeekOffHoli = [];
    let checkDupProj = false;
    let checkDupCat = false;
    let weekStart = internalTimesheet[0]?.category[0]?.dayHours[0].date;
    let weekEnd = internalTimesheet[0]?.category[0]?.dayHours[6].date;

    //check all validations and calculate the auto approval
    const addDayHours = () => {
      internalTimesheet[0]?.category[0]?.dayHours.forEach((item) => {
        const { date, type } = item;
        // if(type === "Working"){
        totaldaywise[date] = 0; //initializing all dates of totaldaywise = 0
        //totaldaywise stores total hrs for each day of the week
        // }
      });

      //calculating total hrs for each day
      updatedTimesheetProjectData.forEach((item) => {
        const { dates, dayhours, type } = item;
        totaldaywise[dates] = (totaldaywise[dates] || 0) + dayhours;
      });

      let invalidDateFound = false;

      internalTimesheet[0]?.category[0]?.dayHours.forEach((item) => {
        if (invalidDateFound) {
          return;
        }
        const { date, type } = item;

        // if the joining date of the employee lies in the current week
        if(ssoEmpIdDetails?.empId !== tsDelEmpIdRedux){
              if (delJoiningStartDate >= weekStart && delJoiningStartDate <= weekEnd) {
                if (
                  totaldaywise[date] > 23.75 && //24 hours/day check
                  date >= delJoiningStartDate &&
                  date >= systemLiveDate
                ) {
                  invaliWeek = date;
                  invalidDateFound = true;
                  return;
                }
                if (totaldaywise[date] != 0 && totaldaywise[date] % 0.25 !== 0) {
                  //hours should be a multiple of 0.24 hours
                  invalidDate = date;
                  invalidDateFound = true;
                  return;
                }
              }
              //if the employmentEnd date of the employee lies in the current week
              else if (delEmpEndDate >= weekStart && delEmpEndDate <= weekEnd) {
                if (
                  totaldaywise[date] > 23.75 &&
                  date < delEmpEndDate &&
                  date >= systemLiveDate
                ) {
                  invaliWeek = date;
                  invalidDateFound = true;
                  return;
                }
                if (totaldaywise[date] != 0 && totaldaywise[date] % 0.25 !== 0) {
                  invalidDate = date;
                  invalidDateFound = true;
                  return;
                }
              }

              //if the system live date lies in the current week
              else if (systemLiveDate >= weekStart && systemLiveDate <= weekEnd) {
                if (totaldaywise[date] > 23.75 && date >= systemLiveDate) {
                  invaliWeek = date;
                  invalidDateFound = true;
                  return;
                }
                if (totaldaywise[date] != 0 && totaldaywise[date] % 0.25 !== 0) {
                  invalidDate = date;
                  invalidDateFound = true;
                  return;
                }
              }

              //weeks with no special condition
              else {
                if (totaldaywise[date] != 0 && totaldaywise[date] % 0.25 !== 0) {
                  invalidDate = date;
                  invalidDateFound = true;
                  return;
                }
                if (totaldaywise[date] > 23.75) {
                  invaliWeek = date;
                  invalidDateFound = true;
                  return;
                }

                //calculating all the Holiday/weeklyOff and storing the date in storeWeekOffHoli
                if (type === "Holiday" || type === "Weekly Off") {
                  storeWeekOffHoli.push(date);
                }
              }
           } else {         
              if (joiningDate >= weekStart && joiningDate <= weekEnd) {
                // if (totaldaywise[date] === 0 && type === "Working" && (date >= joiningDate) ) {
                // if (totaldaywise[date] === 0 && type === "Working" && (date >= joiningDate) ) {
                //   invalidDate = date;
                //   invalidDateFound = true;
                //   return;
                // }
                // if(totaldaywise[date] < 8 && type !== "Weekly Off" && (date >= joiningDate)){
                //   invalidDate = date;
                //   invalidDateFound = true;
                //   return;
                // }
                // if (totaldaywise[date] > 23.75 && type !== "Weekly Off" && (date >= joiningDate)) {

                if (
                  totaldaywise[date] > 23.75 && //24 hours/day check
                  date >= joiningDate &&
                  date >= systemLiveDate
                ) {
                  invaliWeek = date;
                  invalidDateFound = true;
                  return;
                }
                if (totaldaywise[date] != 0 && totaldaywise[date] % 0.25 !== 0) {
                  //hours should be a multiple of 0.24 hours
                  invalidDate = date;
                  invalidDateFound = true;
                  return;
                }
              }
              //if the employmentEnd date of the employee lies in the current week
              else if (empEndDate >= weekStart && empEndDate <= weekEnd) {
                //console.log("check2")

                // if (totaldaywise[date] === 0 && type === "Working" && date < empEndDate ) {
                //   invalidDate = date;
                //   invalidDateFound = true;
                //   return;
                // }
                // if(totaldaywise[date] < 8 && type !== "Weekly Off" && date < empEndDate){
                //   invalidDate = date;
                //   invalidDateFound = true;
                //   return;
                // }
                // if (totaldaywise[date] > 23.75 && type !== "Weekly Off" && date < empEndDate) {

                if (
                  totaldaywise[date] > 23.75 &&
                  date < empEndDate &&
                  date >= systemLiveDate
                ) {
                  invaliWeek = date;
                  invalidDateFound = true;
                  return;
                }
                if (totaldaywise[date] != 0 && totaldaywise[date] % 0.25 !== 0) {
                  invalidDate = date;
                  invalidDateFound = true;
                  return;
                }
              }

              //if the system live date lies in the current week
              else if (systemLiveDate >= weekStart && systemLiveDate <= weekEnd) {
                //console.log("check3")
                // if (totaldaywise[date] === 0 && type === "Working" && date >= systemLiveDate ) {
                //   invalidDate = date;
                //   invalidDateFound = true;
                //   return;
                // }
                // if(totaldaywise[date] < 8 && type !== "Weekly Off" && date >= systemLiveDate){
                //   invalidDate = date;
                //   invalidDateFound = true;
                //   return;
                // }
                // if (totaldaywise[date] > 23.75 && type !== "Weekly Off"  && date >= systemLiveDate) {

                if (totaldaywise[date] > 23.75 && date >= systemLiveDate) {
                  invaliWeek = date;
                  invalidDateFound = true;
                  return;
                }
                if (totaldaywise[date] != 0 && totaldaywise[date] % 0.25 !== 0) {
                  invalidDate = date;
                  invalidDateFound = true;
                  return;
                }
              }

              //weeks with no special condition
              else {
                //console.log("check4")
                // if (totaldaywise[date] === 0 && type === "Working" ) {
                //   invalidDate = date;
                //   invalidDateFound = true;
                //   return;
                // } else
                if (totaldaywise[date] != 0 && totaldaywise[date] % 0.25 !== 0) {
                  invalidDate = date;
                  invalidDateFound = true;
                  return;
                }
                // else if (totaldaywise[date] < 8 && type !== "Weekly Off") {
                //   // setInvalidDate(dates);
                //   // console.log("print all day hours123", totaldaywise[date]);
                //   invalidDate = date;
                //   invalidDateFound = true;
                //   return;
                // }
                if (totaldaywise[date] > 23.75) {
                  invaliWeek = date;
                  invalidDateFound = true;
                  return;
                }

                //calculating all the Holiday/weeklyOff and storing the date in storeWeekOffHoli
                if (type === "Holiday" || type === "Weekly Off") {
                  storeWeekOffHoli.push(date);
                }
              }
        }
      });

      //calculate total hours of every week
      sumWeekWiseHours = Object.values(totaldaywise).reduce(
        (accumulator, value) => accumulator + value,
        0
      ); //checking weekly hours
    };

    //removing duplicates from newOthersTimesheet and other project
    const checkDuplicates = () => {
      if (
        newOthersTimesheet?.length > 0 &&
        projects?.length > 0 &&
        projects[0]?.projectDetails != null
      ) {
        // console.log("checkingdups inside newothers");
        for (let i = 0; i < newOthersTimesheet?.length; i++) {
          for (let j = 0; j < projects?.length; j++) {
            if (
              newOthersTimesheet[i]?.projectDetails?.projId ===
              projects[j]?.projectDetails?.projId
            ) {
              for (let k = 0; k < newOthersTimesheet[i]?.category.length; k++) {
                if (
                  newOthersTimesheet[i]?.category[k]?.categoryName ==
                    projects[j]?.categories[0]?.categoryName ||
                  newOthersTimesheet[i]?.category[k]?.categoryName ==
                    projects[j]?.categories[1]?.categoryName
                ) {
                  return true; //duplicates found
                }
              }
              //return true; // Duplicates found
            }
          }
        }
      }
      if (projects?.length > 0 && projects[0]?.projectDetails != null) {
        for (let i = 0; i < projects?.length; i++) {
          for (let j = i + 1; j < projects?.length; j++) {
            if (
              projects[i]?.projectDetails?.projId ===
              projects[j]?.projectDetails?.projId
            ) {
              for (let k = 0; k < projects[i]?.categories.length; k++) {
                if (
                  projects[i]?.categories[k].categoryName ==
                    projects[j]?.categories[0]?.categoryName ||
                  projects[i]?.categories[k].categoryName ==
                    projects[j]?.categories[1]?.categoryName
                ) {
                  return true; //duplicates found
                }
              }
              //return true; // Duplicates found
            }
          }
        }
      }

      //checking for any duplicate values in timesheet project data
      const otherProjectForCheck = updatedTimesheetProjectData.filter(
        (item) => item.projectsection === "Others"
      );
      const newArray = otherProjectForCheck.map((item) => ({
        category: item.category,
        dates: item.dates,
        projid: item.projid,
      }));
      for (let i = 0; i < newArray.length; i++) {
        for (let j = i + 1; j < newArray.length; j++) {
          if (
            newArray[i].category === newArray[j].category &&
            newArray[i].dates === newArray[j].dates &&
            newArray[i].projid === newArray[j].projid
          ) {
            // console.log("checkingdups inside timesheetproj");
            return true; // Duplicates found
          }
        }
      }
      return false;
    };

    const checkDupCategory = () => {
      projects.map((item, projIndex1) => {
        if (item.categories.length === 2) {
          if (
            item?.categories[0]?.categoryName ===
            item?.categories[1]?.categoryName
          ) {
            checkDupCat = true;
          } else {
            checkDupCat = false;
          }
        } else {
          checkDupCat = false;
        }
      });
    };

    addDayHours(); // to check for daywise
    checkDupCategory();

    let count = 0;
    setFinalTimesheetData([]);

    updatedTimesheetProjectData.map((item) => {
      const {
        dates,
        projid,
        dayhours,
        fixedProject,
        projectsection,
        type,
        dayEnabledFlag,
      } = item;
      if (count > 0) {
        //if count>0 that means an invalid date has already been found so stop this loop and jump to the validation msg
        return;
      }
      //all these checks will only be applied if that day is enabled
      if (
        totaldaywise[dates] > 0 &&
        // dates >= joiningDate && dates >= systemLiveDate && dates <= empEndDate &&
        dayEnabledFlag == 1
      ) {
        //not a multiple of 0.25 and hours>0
        if (dayhours > 0 && dayhours % 0.25 != 0) {
          invalidDate = dates;
          count++;
          return;
        }
        //the finalTimesheetData was made to integrate the change to allow even 0hours to be submitted by the user
        if (dayhours > 0) {
          finalTimesheetData.push(item);
        }
      } else if (
        (projectsection === "Client" || projectsection === "Internal") &&
        fixedProject === "" &&
        type !== "Weekly Off"
      ) {
        //pushing all the 0hour entries of client, internal projects that are non-fixed
        if (
          // dates >= joiningDate && dates >= systemLiveDate && dates <= empEndDate &&
          dayEnabledFlag === 1
        ) {
          finalTimesheetData.push(item);
        }
      }
    });
    let checkType = true;
    if(internalTimesheet){
      internalTimesheet?.map?.((item) => {
        if(item?.fixedProject != "Fixed"){
          checkType = false;
          return
        }
      })
    }
    let checkEnableFlag = false;
    if ((!checkType || clientTimesheet.length > 0) && totalHours == 0) {
      timesheetProjectData.map((item) => {
        if (
          item.type == "Working" &&
          item.dayEnabledFlag == 1 &&
          (item.projectsection == "Client" ||
            (item.projectsection == "Internal" && item.fixedProject == ""))
        ) {
          checkEnableFlag = true;
          return;
        }
      });
      if (!checkEnableFlag) {
        setStopSubmission(true);
      }
    }
    //checks for auto approval flag
    // console.log("data checking", clientTimesheet,othersTimesheet,timesheetProjectData,checkType,timeSheetLists,weeksLimit)
    if(clientTimesheet.length == 0 && othersTimesheet.length == 0){
      timesheetProjectData?.map((item)=>{
        if(totaldaywise[item?.dates] == 0 && item.type == "Working" && checkType){
          setStopSubmission(true);
        }
       })
    }
    
    //checks for auto approval flag
    const resultArray = [];
    // result array stores total projects in a single day that are tagged to an employee OR
    // total project against which the employee has filled hours in a single day
    finalTimesheetData.map((item) => {
      const { dates, projid } = item;
      if (!resultArray[dates]) {
        //resultArray[dates] = projid;
        const arr = [];
        arr.push(projid);
        resultArray[dates] = arr;
        //resultArray[dates]?.push(projid);
      } else {
        const arr = [...resultArray[dates]];
        arr.push(projid);
        resultArray[dates] = arr;
      }
    });
    //USING THE RESULT ARRAY TO POPULATE DAYWISEAUTOAPPROVAL STATE
    const updatedAutoApproval = [];
    let updatedAutoApprovalFinal = [];
    finalTimesheetData.map((item) => {
      const { dates, projid, dayhours, fixedProject, projectsection, type } =
        item;
      let x = 0;
      //here employeeType+Entity differentiation
      //if the user is an ITSL employee: then single project 8 hours/day will be auto approved
      //Fixed projects for all employees will be auto Approved
      allocatedProj?.some((item) => {
        //console.log("qwertyy" , projectsection , type , dayhours , totaldaywise[dates] , employeeType , x , projid , item.projId)

        if (projid == item.projId) {
          x = 1;
          return true;
        }
      });

      if(ssoEmpIdDetails?.empId !== tsDelEmpIdRedux){
        if (delEmpType === "Employee" || delDesignationFlag === 1) {
          if (x == 1) {
            updatedAutoApproval.push({
              date: dates,
              autoapproval: 1,
              projid: projid,
              syscomment: "",
            });
            x = 0;
          } else if (
            resultArray[dates]?.length == 1 &&
            totaldaywise[dates] === 8 &&
            projectsection != "Others" &&
            type === "Working" &&
            delEntity == "ITSL"
          ) {
            updatedAutoApproval.push({
              date: dates,
              autoapproval: 1,
              projid: projid,
              syscomment: "",
            });
          } else if (
            resultArray[dates]?.length == 2 && //this case points to a single project having 2 categories: if total hours each day = 8, the day will be autoApproved if the employee is ITSL
            resultArray[dates][0] === resultArray[dates][1] &&
            totaldaywise[dates] === 8 &&
            projectsection != "Others" &&
            type === "Working" &&
            delEntity == "ITSL"
          ) {
            updatedAutoApproval.push({
              date: dates,
              autoapproval: 1,
              projid: projid,
              syscomment: "",
            });
          } else if (
            fixedProject === "Fixed" &&
            totaldaywise[dates] > 0 &&
            dayhours > 0
          ) {
            updatedAutoApproval.push({
              date: dates,
              autoapproval: 1,
              projid: projid,
              syscomment: "",
            });
          } else if (
            type != "Working" &&
            totaldaywise[dates] > 0 &&
            dayhours > 0 &&
            projectsection != "Others"
          ) {
            updatedAutoApproval.push({
              date: dates,
              autoapproval: 0,
              projid: projid,
              syscomment:
                "Hours submitted against leave/ Holiday/ Weekly off need Managers approval.",
            });
          } else if (
            projectsection === "Others" &&
            totaldaywise[dates] > 0 &&
            dayhours > 0
          ) {
            updatedAutoApproval.push({
              date: dates,
              autoapproval: 0,
              projid: projid,
              syscomment:
                "Employee do not have allocation against this project, hence manager approval is required.",
            });
          } else if (delEntity != "ITSL") {
            updatedAutoApproval.push({
              date: dates,
              autoapproval: 0,
              projid: projid,
              syscomment: "Hours filled by this entity needs managers approval.",
            });
          } else {
            updatedAutoApproval.push({
              date: dates,
              autoapproval: 0,
              projid: projid,
              syscomment: "The hours filled on this day is not as per standard.",
            });
          }
        }
        //conditions for contractors start here:
        else {
          if (
            projectsection === "Internal" &&
            fixedProject === "Fixed" &&
            dayhours > 0
          ) {
            updatedAutoApproval.push({
              date: dates,
              autoapproval: 1,
              projid: projid,
              syscomment: "",
            });
          } else if (
            (projectsection === "Client" || projectsection === "Internal") &&
            fixedProject === ""
            // || (projectsection === "Internal" && fixedProject === "Fixed" && dayhours > 0)
          ) {
            //leave holiday fixed auto approved
            updatedAutoApproval.push({
              date: dates,
              autoapproval: 0,
              projid: projid,
              syscomment: "Hours filled by contractors need an approval.",
            });
          } else if (projectsection === "Others" && dayhours > 0)
            updatedAutoApproval.push({
              date: dates,
              autoapproval: 0,
              projid: projid,
              syscomment: "Hours filled by contractors need an approval.",
            });
        }
      } else {
        if (employeeType === "Employee" || designationFlag === 1) {
          if (x == 1) {
            updatedAutoApproval.push({
              date: dates,
              autoapproval: 1,
              projid: projid,
              syscomment: "",
            });
            x = 0;
          } else if (
            resultArray[dates]?.length == 1 &&
            totaldaywise[dates] === 8 &&
            projectsection != "Others" &&
            type === "Working" &&
            entity == "ITSL"
          ) {
            updatedAutoApproval.push({
              date: dates,
              autoapproval: 1,
              projid: projid,
              syscomment: "",
            });
          } else if (
            resultArray[dates]?.length == 2 && //this case points to a single project having 2 categories: if total hours each day = 8, the day will be autoApproved if the employee is ITSL
            resultArray[dates][0] === resultArray[dates][1] &&
            totaldaywise[dates] === 8 &&
            projectsection != "Others" &&
            type === "Working" &&
            entity == "ITSL"
          ) {
            updatedAutoApproval.push({
              date: dates,
              autoapproval: 1,
              projid: projid,
              syscomment: "",
            });
          } else if (
            fixedProject === "Fixed" &&
            totaldaywise[dates] > 0 &&
            dayhours > 0
          ) {
            updatedAutoApproval.push({
              date: dates,
              autoapproval: 1,
              projid: projid,
              syscomment: "",
            });
          } else if (
            type != "Working" &&
            totaldaywise[dates] > 0 &&
            dayhours > 0 &&
            projectsection != "Others"
          ) {
            updatedAutoApproval.push({
              date: dates,
              autoapproval: 0,
              projid: projid,
              syscomment:
                "Hours submitted against leave/ Holiday/ Weekly off need Managers approval.",
            });
          } else if (
            projectsection === "Others" &&
            totaldaywise[dates] > 0 &&
            dayhours > 0
          ) {
            updatedAutoApproval.push({
              date: dates,
              autoapproval: 0,
              projid: projid,
              syscomment:
                "Employee do not have allocation against this project, hence manager approval is required.",
            });
          } else if (entity != "ITSL") {
            updatedAutoApproval.push({
              date: dates,
              autoapproval: 0,
              projid: projid,
              syscomment: "Hours filled by this entity needs managers approval.",
            });
          } else {
            updatedAutoApproval.push({
              date: dates,
              autoapproval: 0,
              projid: projid,
              syscomment: "The hours filled on this day is not as per standard.",
            });
          }
        }
        //conditions for contractors start here:
        else {
          if (
            projectsection === "Internal" &&
            fixedProject === "Fixed" &&
            dayhours > 0
          ) {
            updatedAutoApproval.push({
              date: dates,
              autoapproval: 1,
              projid: projid,
              syscomment: "",
            });
          } else if (
            (projectsection === "Client" || projectsection === "Internal") &&
            fixedProject === ""
            // || (projectsection === "Internal" && fixedProject === "Fixed" && dayhours > 0)
          ) {
            //leave holiday fixed auto approved
            updatedAutoApproval.push({
              date: dates,
              autoapproval: 0,
              projid: projid,
              syscomment: "Hours filled by contractors need an approval.",
            });
          } else if (projectsection === "Others" && dayhours > 0)
            updatedAutoApproval.push({
              date: dates,
              autoapproval: 0,
              projid: projid,
              syscomment: "Hours filled by contractors need an approval.",
            });
        }
      }
      setDayWiseautoapproval(updatedAutoApproval);
    });

    //function to remove duplicates from auto approval array
    const removeDuplicatesforAutoApproval = () => {
      const uniqueObjects = [];
      const uniqueSet = new Set();
      // Loop through the original array and filter out duplicates
      for (const obj of updatedAutoApproval) {
        const key = obj.date + obj.autoapproval + obj.projid + obj.syscomment;
        if (!uniqueSet.has(key)) {
          uniqueSet.add(key);
          uniqueObjects.push(obj);
        }
      }
      // Set the unique array to state
      updatedAutoApprovalFinal = uniqueObjects;
    };
    removeDuplicatesforAutoApproval();

    formData.append(
      "TimeSheetProjectLinks",
      JSON.stringify(finalTimesheetData)
    );
    formData.append(
      "DaywiseAutoApproval",
      JSON.stringify(updatedAutoApprovalFinal)
    );

    checkDupProj = checkDuplicates();

    //validation checks to open snackbars start:
    if (checkEntries) {
      setEnterProjSnackbarOpen(true);
      return;
    }
    if (invaliWeek) {
      setGreatDay(invaliWeek);
      setSnackOpenForInvalidDay(true);
      return;
    }
    // if (
    //   (invalidDate && totaldaywise[invalidDate] < 8) ||
    //   (invalidDate && totaldaywise[invalidDate] % 0.25 !== 0)
    // )
    if (
      invalidDate ||
      (totaldaywise[invalidDate] % 0.25 !== 0 && totaldaywise[invalidDate] > 0)
    ) {
      //  console.log("control inside if date", invalidDate, totaldaywise);
      setInvalidDate1(invalidDate);
      setSnackbarOpenForVali(true);
      // setInvalidDate("");
      return;
    }
    // else if (sumWeekWiseHours < 40 && (joiningDate <= weekStart || joiningDate >= weekEnd ) && (empEndDate > weekEnd) && (systemLiveDate < weekStart) ) {
    //   setSnackbarOpenForValiWeek(true);
    //   return; // Terminate the function and exit
    // }
    else if (checkDupCat === true) {
      //console.log("control in side dup cat");
      setSnackbarOpenForValicat(true);
      return; // Terminate the function and exit
    } else if (checkDupProj === true) {
      //console.log("control in side dup catproj");
      setSnackbarOpenForValiproj(true);
      return; // Terminate the function and exit
    } else {
      setFinalDialogOpen(true);
      // console.log("check final form data", formData);
      setFormDataFinal(formData);
    }
    //dispatch(postCreateTimesheetData(formData));
  };

  const handleSave = async () => {
    setStopSaving(false);
    combineTimesheetData();
    setIsDayHoursChanged(false);
    isSetLoader(true);
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    let formData = new FormData();

    formData.append("TMid", +timesheetId);
    formData.append(
      "EmpId",
      Encrypt(key, JSON.stringify(tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId))
    );
    formData.append(
      "LoginBy",
      Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
    formData.append("EmpEntityId", tsDelEmpIdRedux && delEntityId ? delEntityId : ssoEmpIdDetails?.entityId);
    formData.append(
      "FromDate",
      moment(weekStartDate, "DD/MM/YYYY").format("yyyy-MM-DD")
    );
    formData.append(
      "ToDate",
      moment(weekEndDate, "DD/MM/YYYY").format("yyyy-MM-DD")
    );
    formData.append("TMStatus", "Draft");
    //formData.append("TotalHours", 40); //updateThis
    //formData.append("TMDescription", comment);
    if (comment?.includes("\n")) {
      const formattedText = comment?.replace(/\n/g, "#~#~");
      formData.append("TMDescription", comment ? formattedText : "");
    } else {
      formData.append("TMDescription", comment ? comment : "");
    }

    // formData.append(
    //   "TimeSheetProjectLinks",
    //   JSON.stringify(timesheetProjectFormData)
    // );
    // formData.append("DaywiseAutoApproval", dayWiseautoapproval ? JSON.stringify(dayWiseautoapproval) : {});

    const compareDates = (a, b) => {
      const dateA = new Date(a.dates);
      const dateB = new Date(b.dates);
      return dateA - dateB;
    };

    // Sort the array
    let storeWeekOffHoli = [];
    let sumOthers = 0;
    let counter = 0;
    let checkEntries = false;
    let checkDupProj = false;

    updatedTimesheetProjectData.map((item) => {
      if (item.projectsection === "Others") {
        sumOthers += item.dayhours;
        counter++;
        if (!item.projid || !item.category) {
          checkEntries = true;
          return;
        }
      }
    });
    if (sumOthers === 0 && counter > 0) {
      checkEntries = true;
    }
    //const updatedAutoApproval = [];
    const addDayHours = () => {
      updatedTimesheetProjectData.forEach((item) => {
        const { dates, dayhours, type } = item;
        totaldaywise[dates] = (totaldaywise[dates] || 0) + dayhours;
        if (totaldaywise[dates] < 8 && type === "Working") {
          // setInvalidDate(dates);
          invalidDate = dates;
        }
        if (type === "Holiday" || type === "Weekly Off") {
          storeWeekOffHoli.push(dates);
        }
      });

      setFinalTimesheetData([]);
      updatedTimesheetProjectData.map((item) => {
        const {
          dates,
          projid,
          dayhours,
          fixedProject,
          projectsection,
          type,
          dayEnabledFlag,
        } = item;

        if (
          totaldaywise[dates] > 0 &&
          // dates >= joiningDate && dates >= systemLiveDate && dates <= empEndDate &&
          dayEnabledFlag == 1
        ) {
          if (dayhours > 0) {
            finalTimesheetData.push(item);
          }
        } else if (
          (projectsection === "Client" || projectsection === "Internal") &&
          fixedProject === "" &&
          type !== "Weekly Off"
        ) {
          if (
            // dates >= joiningDate && dates >= systemLiveDate && dates <= empEndDate &&
            dayEnabledFlag === 1
          ) {
            finalTimesheetData.push(item);
          }
        }
      });
      formData.append(
        "TimeSheetProjectLinks",
        JSON.stringify(finalTimesheetData)
      );

      //checks for auto approval flag
      const resultArray = [];
      finalTimesheetData.map((item) => {
        // console.log("checkresultarray", item, totaldaywise);
        const { dates, projid } = item;
        if (!resultArray[dates]) {
          //resultArray[dates] = projid;
          const arr = [];
          arr.push(projid);
          resultArray[dates] = arr;
          //resultArray[dates]?.push(projid);
        } else {
          const arr = [...resultArray[dates]];
          arr.push(projid);
          resultArray[dates] = arr;
        }
      });
      //checkDupProj = checkDuplicates();
      //USING THE RESULT ARRAY TO POPULATE DAYWISEAUTOAPPROVAL STATE
      const updatedAutoApproval = [];
      let updatedAutoApprovalFinal = [];
      finalTimesheetData.map((item) => {
        const { dates, projid, dayhours, fixedProject, projectsection } = item;
        updatedAutoApproval.push({
          date: dates,
          autoapproval: 0,
          projid: projid,
          syscomment: "The hours filled on this day is not as per standard.",
        });
        setDayWiseautoapproval(updatedAutoApproval);
      });

      const removeDuplicatesforAutoApproval = () => {
        const uniqueObjects = [];
        const uniqueSet = new Set();

        // Loop through the original array and filter out duplicates
        for (const obj of updatedAutoApproval) {
          const key = obj.date + obj.autoapproval + obj.projid + obj.syscomment;

          if (!uniqueSet.has(key)) {
            uniqueSet.add(key);
            uniqueObjects.push(obj);
          }
        }

        // Set the unique array to state
        updatedAutoApprovalFinal = uniqueObjects;
      };
      removeDuplicatesforAutoApproval();
      // console.log("check for result array", updatedAutoApprovalFinal);
      formData.append(
        "DaywiseAutoApproval",
        JSON.stringify(updatedAutoApprovalFinal)
      );
    };

    const checkDuplicates = () => {
      if (
        newOthersTimesheet?.length > 0 &&
        projects?.length > 0 &&
        projects[0]?.projectDetails != null
      ) {
        // console.log("checkingdups inside newothers");
        for (let i = 0; i < newOthersTimesheet?.length; i++) {
          for (let j = 0; j < projects?.length; j++) {
            if (
              newOthersTimesheet[i]?.projectDetails?.projId ===
              projects[j]?.projectDetails?.projId
            ) {
              for (let k = 0; k < newOthersTimesheet[i]?.category.length; k++) {
                if (
                  newOthersTimesheet[i]?.category[k]?.categoryName ==
                    projects[j]?.categories[0]?.categoryName ||
                  newOthersTimesheet[i]?.category[k]?.categoryName ==
                    projects[j]?.categories[1]?.categoryName
                ) {
                  //console.log("control inside newothers")
                  return true;
                }
              }
              //return true; // Duplicates found
            }
          }
        }
      }
      if (projects?.length > 0) {
        // console.log("checkingdups inside others");

        for (let i = 0; i < projects.length; i++) {
          for (let j = i + 1; j < projects.length; j++) {
            if (
              projects[i]?.projectDetails?.projId ===
              projects[j]?.projectDetails?.projId
            ) {
              for (let k = 0; k < projects[i]?.categories.length; k++) {
                if (
                  projects[i]?.categories[k]?.categoryName ==
                    projects[j]?.categories[0]?.categoryName ||
                  projects[i]?.categories[k].categoryName ==
                    projects[j]?.categories[1]?.categoryName
                ) {
                  //console.log("control inside projects")
                  return true;
                }
              }
              //return true; // Duplicates found
            }
          }
        }
      }

      const otherProjectForCheck = timesheetProjectData.filter(
        (item) => item.projectsection === "Others"
      );
      const newArray = otherProjectForCheck.map((item) => ({
        category: item.category,
        dates: item.dates,
        projid: item.projid,
      }));
      for (let i = 0; i < newArray.length; i++) {
        for (let j = i + 1; j < newArray.length; j++) {
          if (
            newArray[i].category === newArray[j].category &&
            newArray[i].dates === newArray[j].dates &&
            newArray[i].projid === newArray[j].projid
          ) {
            return true; // Duplicates found
          }
        }
      }
      return false;
    };

    addDayHours();
    //checkDupCategory();
    checkDupProj = checkDuplicates();
    let checkType = true;
    if(internalTimesheet){
      internalTimesheet?.map?.((item) => {
        if(item?.fixedProject != "Fixed"){
          checkType = false;
          return
        }
      })
    }
    let checkEnableFlag = false;
    if ((!checkType || clientTimesheet.length > 0) && totalHours == 0) {
      timesheetProjectData.map((item) => {
        if (
          item.type == "Working" &&
          item.dayEnabledFlag == 1 &&
          (item.projectsection == "Client" ||
            (item.projectsection == "Internal" && item.fixedProject == ""))
        ) {
          checkEnableFlag = true;
          return;
        }
      });
      if (!checkEnableFlag) {
        setStopSaving(true);
        setStopSubmissionSnackbarOpen(true);
        isSetLoader(false);
        return;
      }
    }
    if(clientTimesheet.length == 0 && othersTimesheet.length == 0 && checkType && totalHours == 0){
      setStopSaving(true);
      setStopSubmissionSnackbarOpen(true);
      isSetLoader(false);
      return;
     }
     
    if (checkEntries) {
      setEnterProjSnackbarOpen(true);
      isSetLoader(false);
      return;
    }
    if (checkDupProj === true) {
      setSnackbarOpenForValiproj(true);
      return; // Terminate the function and exit
    }
    try {
      const response = await dispatch(postCreateTimesheetData(formData));

      if (response.status === 200) {
        isSetLoader(false);
        setIsSaved(true);
        setIsSaveDisabled(true);
setIsSubmitDisabled(true);
        setSavedSnackbarOpen(true);
        setTimeout(() => {
          handleSavedClose();
          history.push("/timesheet-management");
        }, 3000);
      } else {
        isSetLoader(false);
        setStatusSnackbarOpen(true);
        setSavedSnackbarOpen(false);
        setIsSaveDisabled(true);
        setIsSaved(false);
      }
    } catch (error) {
      isSetLoader(false);
      setIsSaved(false);
    }
  };

  const handleSavedClose = () => {
    setIsSaved(false);
    setSavedSnackbarOpen(false);
  };

  const handleSubmittedClose = () => {
    setIsSubmitted(false);

    if (
      pageModule &&
      ((ssoEmpIdDetails?.expenseRatingFlag === 1 && pageModule === "Expense") ||
        (ssoEmpIdDetails?.timesheetRatingFlag === 1 &&
          pageModule === "Timesheet") ||
        (ssoEmpIdDetails?.gmRatingFlag === 1 && pageModule === "GM"))
    ) {
      // setIsSubmitted(false);
      setSubmiteedSnackbarOpen(false);
      setOpenRating(true);
    } else {
      // isSetLoader(false);
      // setIsSubmitted(false);
      setSubmiteedSnackbarOpen(false);
      history.push("/timesheet-management");
    }
  };

  useEffect(() => {
    if (isSubmitted) {
      setTimeout(() => {
        handleSubmittedClose();
      }, 5000);
    }
  }, [isSubmitted]);

  const addCategoryLine = (project, index , len) => {
    // console.log("checkingprojectClicked", projectClicked[index]);
    if (projectClicked[index]) {
      return; // Return early if the link for the project has already been clicked
    }
    if(len == 1){
      setClientTimesheet((prevTimesheet) => {
        const updatedTimesheet = prevTimesheet.map((item) => {
          if (
            item.projectDetails.projId === project.projectDetails.projId &&
            project.category &&
            project.category[0]
          ) {
            const data = { ...project.category[0] };
            data.categoryName =
              data.categoryName === "Non Billable" ? "Billable" : "Non Billable";
            item.category.push(data);

            // Reset hrs property to 0 only if a new category is added
            if (item.category.length === 2) {
              const firstCategory = item.category[0];
              item.category[1].dayHours = firstCategory.dayHours.map(
                (dayHour) => ({
                  ...dayHour,
                  hrs: parseFloat(0),
                })
              );
            }
          }
          return item;
        });

        setProjectClicked((prevState) => ({
          ...prevState,
          [index]: true, // Update the clicked state for the specific project
        }));

        return updatedTimesheet;
      });
    }
        
      };

  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
    setComment("");
  };

  const handleClose = () => {
    setAnchorEl(null);
    setComment("");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleRefDate = (refDt) => {
    setRefDate(refDt);
    dispatch(updateReferenceDateFxn(refDt));
  };

  const handleWeekStartDate = (weStDt) => {
    setWeekStartDate(weStDt);
  };

  const handleWeekEndDate = (weEnDt) => {
    setWeekEndDate(weEnDt);
  };

  const handleInputWeek = (inWe) => {
    setInputWeek(inWe);
  };

  const handlePrevWeek = () => {
    isSetLoader(true);
    setComment("");
    let tempRefDate1 = dayjs(refDate).subtract(1, "week");
    setIsSaveDisabled(false);
    setRefDate(tempRefDate1);
    dispatch(updateReferenceDateFxn(tempRefDate1));

    setProjectClicked({});
    setProjDialogOpen(false);
    setShowAddProjectLink(true);
    setProjects([]);
    setExpandedSections([]);
    setExpandedSections1([]);
    setExpandedSections4([]);
    setExpandedSections3([]);
  };

  const handleNextWeek = () => {
    isSetLoader(true);
    setComment("");
    let tempRefDate2 = dayjs(refDate).add(1, "week");
    setIsSaveDisabled(false);
    setRefDate(tempRefDate2);
    dispatch(updateReferenceDateFxn(tempRefDate2));
    setProjectClicked({});
    setProjDialogOpen(false);
    setShowAddProjectLink(true);
    setProjects([]);
    setExpandedSections([]);
    setExpandedSections1([]);
    setExpandedSections4([]);
    setExpandedSections3([]);
  };

  const handleTimeSheetList = () => {

    setComment("");
    setProjectClicked({});
    setAddCategoryCheck([]);
    setProjects([]);
    setClientTimesheet([]);
    setInternalTimesheet([]);
    setOthersTimesheet([]);
    setNewOthersTimesheet([]);
    
    let obj = {
      empId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
      startDate: weekStartDate
        ? moment(weekStartDate, ["yyyy-MM-DD", "DD/MM/YYYY"]).format("yyyy-MM-DD")
        : "",
      endDate: weekEndDate
        ? moment(weekEndDate, ["yyyy-MM-DD", "DD/MM/YYYY"]).format("yyyy-MM-DD")
        : "",
      lastWeekFlag: 0,
    };
    dispatch(getMyTimesheetData(obj));
  };

  useEffect(() => {
    if(comingFromEdit) {
      
    } else if ( (ssoEmpIdDetails || tsDelEmpIdRedux) && weekEndDate && weekStartDate) {
      handleTimeSheetList();
    }
  }, [comingFromEdit, weekStartDate, weekEndDate,ssoEmpIdDetails,tsDelEmpIdRedux]);

  const setClientHoursValue = (
    projectIndex,
    categoryIndex,
    dateIndex,
    hours
  ) => {
    const updatedTimesheet = [...clientTimesheet];
    const updatedDayHours = [
      ...updatedTimesheet[projectIndex].category[categoryIndex].dayHours,
    ];

    const date = new Date(updatedDayHours[dateIndex].date);
    const formattedDate = `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}`;

    updatedDayHours[dateIndex] = {
      ...updatedDayHours[dateIndex],
      hrs: parseFloat(hours),
    };

    updatedTimesheet[projectIndex].category[categoryIndex].dayHours =
      updatedDayHours;

    setClientTimesheet(updatedTimesheet);
  };

  const setInternalHoursValue = (
    projectIndex,
    categoryIndex,
    dateIndex,
    hours
  ) => {
    const updatedTimesheet = [...internalTimesheet];
    const updatedDayHours = [
      ...updatedTimesheet[projectIndex].category[categoryIndex].dayHours,
    ];

    const date = new Date(updatedDayHours[dateIndex].date);
    const formattedDate = `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}`;

    updatedDayHours[dateIndex] = {
      ...updatedDayHours[dateIndex],
      hrs: parseFloat(hours),
    };

    updatedTimesheet[projectIndex].category[categoryIndex].dayHours =
      updatedDayHours;
    setInternalTimesheet(updatedTimesheet);
  };

  const setNewOthersHoursValue = (
    projectIndex,
    categoryIndex,
    dateIndex,
    hours
  ) => {
    setIsDayHoursChanged(false);
    const updatedTimesheet = [...newOthersTimesheet];
    const updatedDayHours = [
      ...updatedTimesheet[projectIndex].category[categoryIndex].dayHours,
    ];
    const date = new Date(updatedDayHours[dateIndex].date);
    const formattedDate = `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}`;
    updatedDayHours[dateIndex] = {
      ...updatedDayHours[dateIndex],
      hrs: parseFloat(hours),
    };
    updatedTimesheet[projectIndex].category[categoryIndex].dayHours =
      updatedDayHours;
    setNewOthersTimesheet(updatedTimesheet);
  };

  const combineTimesheetData = () => {
    let combinedTimesheet = [];
    let totalHoursData = [];

    totalHoursData = Array(7).fill(0);
    // Merge clientTimesheet and internalTimesheet data
    for (const clientTimesheetItem of clientTimesheet) {
      const combinedItem = {
        projectDetails: clientTimesheetItem.projectDetails,
        fixedProject: clientTimesheetItem.fixedProject,
        category: [],
        ProjectSectionType: clientTimesheetItem.ProjectSectionType,
      };

      for (const clientCategory of clientTimesheetItem.category) {
        const combinedCategory = {
          categoryName: clientCategory.categoryName,
          dayHours: [...clientCategory.dayHours],
        };

        combinedItem.category.push(combinedCategory);
      }

      combinedTimesheet.push(combinedItem);
    }
    // console.log("internaltimesheetoiuyi", internalTimesheet);
    for (const internalTimesheetItem of internalTimesheet) {
      const existingItemIndex = combinedTimesheet.findIndex(
        (item) =>
          item.projectDetails.projId ===
          internalTimesheetItem.projectDetails.projId
      );

      if (existingItemIndex !== -1) {
        for (const internalCategory of internalTimesheetItem.category) {
          const existingCategoryIndex = combinedTimesheet[
            existingItemIndex
          ].category.findIndex(
            (category) =>
              category.categoryName === internalCategory.categoryName
          );

          if (existingCategoryIndex !== -1) {
            combinedTimesheet[existingItemIndex].category[
              existingCategoryIndex
            ].dayHours = [...internalCategory.dayHours];
          } else {
            const combinedCategory = {
              categoryName: internalCategory.categoryName,
              dayHours: [...internalCategory.dayHours],
            };
            combinedTimesheet[existingItemIndex].category.push(
              combinedCategory
            );
          }
        }
      } else {
        const combinedItem = {
          projectDetails: internalTimesheetItem.projectDetails,
          fixedProject: internalTimesheetItem.fixedProject,
          category: [],
          ProjectSectionType: internalTimesheetItem.ProjectSectionType,
        };

        for (const internalCategory of internalTimesheetItem.category) {
          const combinedCategory = {
            categoryName: internalCategory.categoryName,
            dayHours: [...internalCategory.dayHours],
          };

          combinedItem.category.push(combinedCategory);
        }
        combinedTimesheet.push(combinedItem);
      }
    }

    //combining othersTimesheet in combinedTimesheet
    if (projects?.length > 0) {
      for (const otherTimesheetItem of othersTimesheet) {
        const combinedItem = {
          //projectDetails: otherTimesheetItem.projectDetails,
          projectDetails: {
            ...otherTimesheetItem.projectDetails,
            projId: otherTimesheetItem.projectDetails.projId,
          },
          category: [],
          ProjectSectionType: "Others",
        };

        for (const otherCategory of otherTimesheetItem.category) {
          const combinedCategory = {
            categoryName: otherCategory.categoryName,
            dayHours: [...otherCategory.dayHours],
          };

          combinedItem.category.push(combinedCategory);
        }
        // console.log("check combined item", combinedItem);

        combinedTimesheet.push(combinedItem);
      }
    }

    for (const otherTimesheetItem of newOthersTimesheet) {
      const combinedItem = {
        projectDetails: {
          ...otherTimesheetItem?.projectDetails,
          projId: otherTimesheetItem?.projectDetails?.projId,
        },
        category: [],
        ProjectSectionType: "Others",
      };
      for (const otherCategory of otherTimesheetItem?.category) {
        const combinedCategory = {
          categoryName: otherCategory?.categoryName,
          dayHours: [...otherCategory?.dayHours],
        };
        combinedItem.category.push(combinedCategory);
      }
      combinedTimesheet.push(combinedItem);
    }

    // Update the combined timesheet state or perform any required action

    // Update the timesheetProjectData state
     updatedTimesheetProjectData = [];
    // console.log("combinedTimesheetData1111", combinedTimesheet);
    combinedTimesheet.forEach((item) => {
      // console.log("combinedTimesheetData1111", item , item.fixedProject);
      const { projectDetails, category } = item;
      const { projId, projType } = projectDetails;
      // console.log("combinedTimesheetData11113", item);

      category.forEach((cat) => {
        const { categoryName, dayHours } = cat;

        dayHours.forEach((day, index) => {
          // if (day.hrs > 0) {
            // console.log("combinedTimesheetData11113",isNaN(day.hrs)?0:day.hrs);
            updatedTimesheetProjectData.push({
              projid: projId?.toString(),
              projectsection: item.ProjectSectionType,
              category: categoryName,
              dates: day.date,
              days: day.day,
              dayhours: isNaN(day.hrs) ? 0 : day.hrs,
              fixedProject: item.fixedProject,
              type: day.type,
              dayEnabledFlag:day.dayEnabledFlag
            });
          // }
        });
      });
    });
   

    //removing duplicates from project data

    const uniqueObjects = [];
    const uniqueSet = new Set();
    //Loop through the original array and filter out duplicates
    for (const obj of updatedTimesheetProjectData) {
      const key =
        obj.category +
        obj.dates +
        obj.projid +
        obj.dayhours +
        obj.days +
        obj.projectsection +
        obj.type;

      if (!uniqueSet.has(key)) {
        uniqueSet.add(key);
        uniqueObjects.push(obj);
      }
    }
   // Set the unique array to state

    updatedTimesheetProjectData = uniqueObjects;

    // console.log("combinedTimesheetData11112345", updatedTimesheetProjectData);
    setTimesheetProjectData(updatedTimesheetProjectData);
    // console.log("combinedTimesheetData11112", timesheetProjectData);
  };

  useEffect(() => {
    if (othersTimesheet.length > 0 && addCategoryCheck.length == 0) {
      setNewOthersTimesheet(othersTimesheet);
    } else if (othersTimesheet.length == 0 && addCategoryCheck.length == 0) {
      setNewOthersTimesheet([]);
    }
  }, [othersTimesheet, internalTimesheet]);

  useEffect(() => {
    combineTimesheetData();
  }, [
    clientTimesheet,
    internalTimesheet,
    othersTimesheet,
    projects,
    newOthersTimesheet,
    timeSheetLists,
  ]);

  const handleCategoryName = (projectIndex, categoryIndex, categoryName) => {
    const updatedProjects = [...newOthersTimesheet];
    updatedProjects[projectIndex].category[categoryIndex].categoryName =
      categoryName;
    setNewOthersTimesheet(updatedProjects);
  };
  const handleDayHourChange = (
    dayIndex,
    value,
    projectIndex,
    categoryIndex
  ) => {
    const updatedInputDayHours = [...inputDayHours];
    updatedInputDayHours[dayIndex] = value;
    const updatedProjects = [...newOthersTimesheet];
    updatedProjects[projectIndex].categories[categoryIndex].hours = [
      ...updatedInputDayHours,
    ];
    //setInputDayHours(updatedInputDayHours);
    setNewOthersTimesheet(updatedProjects);
  };

  const handleRemoveCategory = (projectIndex, categoryIndex) => {
    if (categoryIndex === 0) {
      const updatedProjects = [...projects];
      updatedProjects.splice(projectIndex, 1);
      setProjects(updatedProjects);
    } else {
      const updatedProjects = [...projects];
      updatedProjects[projectIndex].categories.splice(categoryIndex, 1);
      setProjects(updatedProjects);
    }
  };

  const handleRemoveCategoryValClient = (projectIndex, categoryIndex) => {
    const updatedProjects = [...clientTimesheet];
    updatedProjects[projectIndex].category.splice(categoryIndex, 1);
    setClientTimesheet(updatedProjects);
    setProjectClicked((prevState) => ({
      ...prevState,
      [projectIndex]: false,
    }));
  };

  const handleRemoveCategoryVal = (projectIndex, categoryIndex) => {
    if (categoryIndex === 0) {
      const updatedProjects = [...newOthersTimesheet];
      updatedProjects.splice(projectIndex, 1);
      setNewOthersTimesheet(updatedProjects);
      setOthersTimesheet(updatedProjects);
    } else {
      const updatedProjects = [...newOthersTimesheet];
      updatedProjects[projectIndex].category.splice(categoryIndex, 1);
      setNewOthersTimesheet(updatedProjects);
      setOthersTimesheet(updatedProjects);
    }
  };

  const toggleSection = (index) => {
    if (expandedSections.includes(index)) {
      setExpandedSections(expandedSections?.filter((item) => item !== index));
    } else {
      setExpandedSections([...expandedSections, index]);
    }
  };

  const toggleSection1 = (index) => {
    if (expandedSections1.includes(index)) {
      setExpandedSections1(expandedSections1?.filter((item) => item !== index));
    } else {
      setExpandedSections1([...expandedSections1, index]);
    }
  };

  const toggleSection3 = (index) => {
    if (expandedSections3.includes(index)) {
      setExpandedSections3(expandedSections3?.filter((item) => item !== index));
    } else {
      setExpandedSections3([...expandedSections3, index]);
    }
  };

  const toggleSection4 = (index) => {
    if (expandedSections4.includes(index)) {
      setExpandedSections4(expandedSections4?.filter((item) => item !== index));
    } else {
      setExpandedSections4([...expandedSections4, index]);
    }
  };

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <>
      <Box>
        <Box>
          <Typography
            style={{
              display: "flex",
              alignItems: "center",
              color: "#0B1941",
              fontSize: "1rem",
              fontWeight: 600,
              fontFamily: "Montserrat",
              paddingLeft: "1.563rem",
              backgroundColor: "#F8F9FF",
              lineHeight: "3.125rem",
              justifyContent: "flex-start",
            }}
          >
            <ChevronLeftIcon
              style={{
                color: "#0B1941",
                marginRight: "0.5rem",
                cursor: "pointer",
              }}
              onClick={() => history.push("/timesheet-management")}
            />
            Create Timesheet
          </Typography>
        </Box>
        {missedWeekOption.map((item) => (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#FFD5CB",
            paddingLeft: "1.563rem",
            paddingRight: "1.563rem",
            lineHeight: "3.125rem",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <ReportProblemOutlinedIcon
              style={{
                color: "#ED8853",
                marginRight: "0.5rem",
                cursor: "pointer",
              }}
              onClick={() => history.push("/timesheet-management")}
            />
            <Typography
              style={{
                color: "#2D2D2D",
                fontSize: "0.7rem",
                fontWeight: 600,
                fontFamily: "Montserrat",
              }}
            >
              TimeSheet for week {" "}{item.slice(0, -4)}
              { window.screen.availWidth > 400 ? " " : window.screen.availWidth < 308 ? " " : <br />}
              <span style={{ color: "#2D2D2D", fontWeight: 600, fontFamily: "Montserrat", fontSize: "0.7rem",}}>
                is yet to be filled
              </span>
            </Typography>
          </div>
          <Link
            style={{
              color: "#008CE6",
              cursor: "pointer",
              fontSize: "0.7rem",
              fontWeight: 600,
              fontFamily: "Montserrat",
              textDecoration: "underline",
            }}
            //target="_blank"
            onClick={() => 
              isDayHoursChanged ? (setShowConfirmation(true),
                 setSelectedMissedWeek(item), setSelectedNavigation("Fill"), 
                 setExpandedSections([]),
                 setExpandedSections1([]),
                 setExpandedSections4([]),
                 setExpandedSections3([])
                 ) 
               : (handleMissedWeek(item),
                 isSetLoader(true))
              }
          >
            Fill
          </Link>
        </Box>))}
        <Box>
          <Grid container>
            <Grid item xs={6} sm={6}>
              <Box style={{ display: "flex", alignItems: "center", paddingLeft: "20%", paddingTop: "1.4rem"}}>
                <Box
                  style={{ fontSize: "0.75rem", letterSpacing: "-0.1rem",
                    color: "#0B1941",
                    fontFamily: "Montserrat",
                    fontSize: "1rem",
                    fontWeight: 600,
                    lineHeight: "1.5rem",
                    letterSpacing: "-0.01rem",
                    textAlign: "left",
                    marginLeft: "0.1rem"
                }}
                >
                  Total Hrs:
                </Box>
                <Box
                  style={{
                    color: "#0B1941",
                    fontFamily: "Montserrat",
                    fontSize: "1.2rem",
                    fontWeight: 900,
                    lineHeight: "1.5rem",
                    letterSpacing: "-0.01rem",
                    textAlign: "left",
                    marginLeft: "0.1rem",
                }}
                >
                  {parseFloat(totalHours).toFixed(2)}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} style={{marginLeft: "-0.75rem"}}>
              <Box style={{marginTop: "0.938rem", marginLeft: "0.5rem"}}>
                <Button variant="outlined" style={{ borderColor: "#0B1941", color: (snackbarCommentOpen || IsCopyLastWeekDisabled) ?  "#00000042" : "#0B1941", display: "flex", alignItems: "center", }}
                  disabled={
                    snackbarCommentOpen === true
                      ? true
                      : IsCopyLastWeekDisabled
                      ? true
                      : false
                  }
                  onClick={() => handleCopyLastWeek()}
                >
                  <ContentCopy style={{ height: "1rem", width: "1rem", color: (snackbarCommentOpen || IsCopyLastWeekDisabled) ?  "#00000042" : "#0B1941", marginRight: "0.5rem" }} />
                  <span style={{ textTransform: "none" }}>Copy Last Week</span>
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}
                style={{
                  paddingTop: "1rem", 
                  paddingBottom: "1.5rem", 
                  // paddingLeft: "2rem", 
                  // paddingRight: "1rem"
                  textAlign: "-webkit-center"
                }}
            >
              <Box 
                style={{
                  color: "#0B1941",
                  width: '19.75rem',
                  display: "flex",
                  alignItems: "center",
                  border: "0.063rem solid #0B1941",
                  borderRadius: "0.313rem",
                  }}>
              <Box>
                <Button
                  variant="outlined"
                  style={{
                    width: '15.75rem',
                    display: 'flex',
                    alignItems: 'center',
                    border: 'none',
                    textTransform: 'none',
                    color: '#0B1941', 
                    justifyContent: "flex-start"
                  }}
                  onClick={handleClick}
                >
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Box style={{ height: '1.5rem', width: '1.5rem', marginRight: '0.5rem' }}>
                      <EventIcon />
                    </Box>
                    <Box>
                    <Typography>{`${moment( weekStartDate,["yyyy-MM-DD", "DD/MM/YYYY"])
                        .format("MMM D, YYYY").slice(0, -6)} - ${moment(weekEndDate, [ "yyyy-MM-DD","DD/MM/YYYY",])
                        .format("MMM D, YY")}`}
                      </Typography>
                    </Box>
                  </Box>
                </Button>
                </Box>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <CustomCalendar
                    refDate={refDate}
                    handleRefDate={handleRefDate}
                    isDayHoursChanged={isDayHoursChanged}
                    setIsDayHoursChanged={setIsDayHoursChanged}
                    setProjectClicked={setProjectClicked}
                    setIsSubmitDisabled={setIsSubmitDisabled}
                  />
                </Popover>  
                <Divider
                  style={{ backgroundColor: "#0B1941" , height:"auto", alignSelf:"stretch"}}
                  orientation="vertical"
                  flexItem
                />   
                  <Box
                    style={{width:"2.5rem"}}
                      onClick={() =>
                      isDayHoursChanged
                        ? (setShowConfirmation(true),
                          setSelectedNavigation("Previous"),
                          setExpandedSections([]),
                          setExpandedSections1([]),
                          setExpandedSections4([]),
                          setExpandedSections3([])
                          )
                        : handlePrevWeek()
                    }
                  >
                    <NavigateBeforeIcon /> 
                  </Box>
                  <Divider
                  style={{ backgroundColor: "#0B1941" }}
                  orientation="vertical"
                  flexItem
                />
                <Box
                  sx={{
                    width:"2.5rem",
                    pointerEvents: isBoxDisabled ? "none" : "auto",
                    opacity: isBoxDisabled ? 0.5 : 1,
                  }}
                  onClick={() =>
                    isDayHoursChanged
                      ? (setShowConfirmation(true),
                        setSelectedNavigation("Next"),
                        setExpandedSections([]),
                        setExpandedSections1([]),
                        setExpandedSections4([]),
                        setExpandedSections3([])
                        )
                      : handleNextWeek()
                  }
                >
                  <NavigateNextIcon/>
                  </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {
          <Box>
            {clientTimesheet.length > 0 && (
              <Box style={{
                  backgroundColor: "#E3E8F7", 
                  paddingLeft:"1.563rem", 
                  letterSpacing: "-0.1rem",
                  color: "#0B1941",
                  fontFamily: "Montserrat",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                  lineHeight: "1.5rem",
                  letterSpacing: "-0.01rem",
                  textAlign: "left"}}
                >
                Client Projects
              </Box>
            )}

            {
              clientTimesheet?.map(
              (proj, index) => (
              (
                <Box>
                  <Box>
                    <Box style={{
                      display: "flex", 
                      justifyContent: "space-between", 
                      alignItems: "center", 
                      paddingLeft: "1.563rem",
                      paddingTop: "0.8rem",
                      paddingRight: "0.8rem"}}
                      onClick={() => toggleSection1(index)}
                    >
                      <Typography style={{color: "#2D2D2D", fontSize: "0.7rem", fontWeight: 600, fontFamily: "Montserrat"}}>
                      {proj?.projectDetails?.projCode} {" "} - {" "} 
                      <span style={{color: "#2D2D2D", fontSize: "0.7rem", fontWeight: 600, fontFamily: "Montserrat"}}>{proj?.projectDetails?.projName}</span><br/>
                      {proj?.projectDetails?.projPurp == null || proj?.projectDetails?.projPurp == "" ?
                      ""
                    :<span  style={{ 
                      backgroundColor: "#E3E8F7",
                        width: "134px",
                        //padding: "2px",
                        height: "14px",
                        fontSize: "10px",
                        //paddingLeft: "22px",
                        padding:
                          " 1px 2px 2px 4px",
                       marginBottom:"8px",
                        
                     }}> {" "}  {proj?.projectDetails?.projPurp}</span>}
                      
                      </Typography>
                      {expandedSections1.includes(index) ? (
                        <KeyboardArrowUpIcon
                          onClick={() => toggleSection1(index)}
                          style={{ cursor: 'pointer' }}
                        />
                      ) : (
                        <KeyboardArrowDownIcon
                          onClick={() => toggleSection1(index)}
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                    </Box>
                    <hr/>
                  </Box>
                  {expandedSections1?.includes(index) && (
                    <>
                      {proj.category.map((item, catIndex) => {
                                        return (
                                          <>
                      <Box style={{backgroundColor: "#F6F6F6", marginTop: "-0.5rem"}}>
                        <Box>
                          <FormControl variant="outlined" style={{minWidth: "86%", paddingLeft: "1.563rem", paddingTop: "1rem"}}>
                            <TextField
                              id="outlined-basic"
                              label="Category"
                              variant="outlined"
                              name="categoryName"
                              value={item.categoryName}
                              readOnly
                              InputProps={{
                                style: {
                                  fontSize: 14,
                                  width: "100%",
                                },
                              }}
                            />
                          </FormControl>
                        </Box>
                        <Box style={{paddingTop: "2rem"}}>
                          <Box style={{marginBottom: "-0.563rem"}}>
                          {item.dayHours?.map((date, k) => (
                            <Grid container> 
                              <Grid item xs={6} sm={6}>
                                <Box style={{backgroundColor: "#DCDFE9", marginBottom: "-0.5rem"}}>
                                  <Typography style={{padding: "2rem"}}>
                                  {moment(inputWeek[k].date, "DD/MM/YYYY")
                                    .format("MMM D, YYYY")
                                    .slice(0, -6)} , {" "} {inputWeek[k].day}
                                  </Typography>
                                  <hr/>
                                </Box>
                              </Grid>
                              <Grid item xs={6} sm={6}>
                                <Box 
                                  style={{
                                      background: date.type === "Weekly Off"? "#EDEEF2": "",
                                      paddingBottom: "0.5rem" 
                                    }}>
                                  <NumericFormat
                                    id="outlined-basic"
                                    variant="outlined"
                                    name="HoursName"
                                    customInput={TextField}
                                    value={date.hrs}
                                    readOnly
                                    InputProps={{
                                      inputProps: {
                                        maxLength: 5,
                                        min: 0,
                                        step: 0.25,
                                        onKeyPress:
                                          preventInvalidInput,
                                      },
                                      style: {
                                        fontSize: 14,
                                      },
                                    }}
                                    style={{
                                      width: "80%",
                                      margin: "1rem",
                                      marginTop: "1.25rem",
                                      backgroundColor:
                                          date.type === "Working"
                                            ? "#FFFFFF"
                                            : date.type === "Holiday"
                                            ? "rgba(246, 229, 208, 1)"
                                            : date.type === "Leave"
                                            ? "rgba(199, 234, 234, 1)"
                                            : "",

                                      border:
                                        date.type === "Working"
                                          ? "0.063rem solid #BDBDBD"
                                          : date.type ===
                                            "Weekly Off"
                                          ? "0.063rem solid #BDBDBD"
                                          : date.type === "Holiday"
                                          ? "0.063rem solid #E5BF90"
                                          : date.type === "Leave"
                                          ? "0.063rem solid #629797"
                                          : "",
                                      borderRadius: "0.313rem",
                                    }}
                                    disabled={
                                      date.dayEnabledFlag === 0
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      const newDate = { ...date };
                                      newDate.hrs = e.target.value;
                                      setIsDayHoursChanged(true);
                                      setClientHoursValue(
                                        index,
                                        catIndex,
                                        k,
                                        newDate.hrs
                                      );
                                      // handleHoursFieldChange(e, index, proj, k)
                                    }}
                                  />
                                </Box> 
                              </Grid>
                            </Grid>
                            ))}
                          </Box>

                        </Box>
                      </Box>
                        
                      <Box>
                        <Box style={{
                          display: "flex", 
                          justifyContent: "space-between", 
                          alignItems: "center", 
                          paddingLeft: "2rem",
                          paddingTop: "1.5rem",
                          paddingBottom: "1.5rem",
                          backgroundColor: "#F6F6F6",
                          paddingRight: "1.5rem"
                        }}>
                          <Typography style={{
                            color: "#008CE6",
                            fontSize: "1rem",
                            fontWeight: 600,
                            fontFamily: "Montserrat"
                          }}>
                            {catIndex ===
                              clientTimesheet[index].category
                                .length -
                                1 ? (
                            <Link
                              style={{
                                color:
                                  proj.category.length == 2
                                    ? "#808080"
                                    : projectClicked[index]
                                    ? "#808080"
                                    : "#008CE6",
                                cursor:
                                  proj.category.length == 2
                                    ? "not-allowed"
                                    : projectClicked[index]
                                    ? "not-allowed"
                                    : "pointer",
                                backgroundColor: "#F6F6F6",
                                fontSize: "1rem",  
                                fontWeight: 600,
                                fontFamily: "Montserrat",
                                textDecoration: "underline"
                              }}
                              //target="_blank"
                              onClick={() =>
                                addCategoryLine(proj, index, proj.category.length)
                              }
                            >
                              Add Category
                            </Link>
                            ) : (
                              ""
                            )}
                          </Typography>
                          {catIndex != 0 ? (
                            <Button
                              style={{
                                minWidth: "1.5rem",
                                marginRight: "-2.1%",
                              }}
                              onClick={() =>
                                handleRemoveCategoryValClient(
                                  index,
                                  catIndex
                                )
                              }
                            >
                              <span class="material-symbols-outlined">
                                delete
                              </span>
                            </Button>
                          ) : (
                            ""
                          )}
                        </Box>
                        {/* <hr style={{margin: "0rem"}} /> */}
                      </Box>
                      </>
                      );
                      })}
                    </>
                  )}
                </Box>
              )
            )
          )}
          </Box>
        }
        <Box>
        {internalTimesheet.length > 0 && (
          <Box style={{
              backgroundColor: "#E3E8F7", 
              paddingLeft:"1.563rem", 
              letterSpacing: "-0.1rem",
              color: "#0B1941",
              fontFamily: "Montserrat",
              fontSize: "0.625rem",
              fontWeight: 600,
              lineHeight: "1.5rem",
              letterSpacing: "-0.01rem",
              marginTop: "-0.5rem",
              textAlign: "left"}}
            >
            Internal Projects
          </Box>
          )}
          {
              internalTimesheet?.map(
              (proj, index) => (
              (
                <Box style={{marginBottom: "-0.438rem"}}>
                  <Box>
                    <Box style={{
                      display: "flex", 
                      justifyContent: "space-between", 
                      alignItems: "center", 
                      paddingLeft: "1.563rem",
                      paddingTop: "0.8rem",
                      cursor: 'pointer',
                      paddingRight: "0.8rem"}}
                      onClick={() => toggleSection(index)}
                    >
                      <Typography style={{color: "#2D2D2D", fontSize: "0.7rem", fontWeight: 600, fontFamily: "Montserrat"}}>
                      {proj?.projectDetails?.projCode} {" "} - {" "} 
                      <span style={{color: "#2D2D2D", fontSize: "0.7rem", fontWeight: 600, fontFamily: "Montserrat"}}>{proj?.projectDetails?.projName}</span><br/>
                      {proj?.projectDetails?.projPurp == null || proj?.projectDetails?.projPurp == "" ?
                      ""
                    :<span  style={{ 
                      backgroundColor: "#E3E8F7",
                        width: "134px",
                        //padding: "2px",
                        height: "14px",
                        fontSize: "10px",
                        //paddingLeft: "22px",
                        padding:
                          " 1px 2px 2px 4px",
                       marginBottom:"8px",
                        
                     }}> {" "}  {proj?.projectDetails?.projPurp}</span>}
                      </Typography>
                      {expandedSections.includes(index) ? (
                        <KeyboardArrowUpIcon
                          onClick={() => toggleSection(index)}
                          style={{ cursor: 'pointer' }}
                        />
                      ) : (
                        <KeyboardArrowDownIcon
                          onClick={() => toggleSection(index)}
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                    </Box>
                    <hr/>
                  </Box>
                  {
                    expandedSections?.includes(index) && (
                    <>
                      {proj.category.map((item, catIndex) => {
                                        return (
                                          <>
                      <Box style={{backgroundColor: "#F6F6F6", marginTop: "-0.5rem"}}>
                        {/* <Box>
                          <FormControl variant="outlined" style={{minWidth: "86%", paddingLeft: "1.563rem", paddingTop: "1rem"}}>
                            <TextField
                              id="outlined-basic"
                              label="Category*"
                              variant="outlined"
                              name="categoryName"
                              value={item.categoryName}
                              readOnly
                              InputProps={{
                                style: {
                                  fontSize: 14,
                                  width: "100%",
                                },
                              }}
                            />
                          </FormControl>
                        </Box> */}
                        <Box style={{paddingTop: "2rem"}}>
                          <Box style={{marginBottom: "-0.563rem"}}>
                          {item.dayHours?.map((date, k) => (
                            <Grid container> 
                              <Grid item xs={6} sm={6}>
                                <Box style={{backgroundColor: "#DCDFE9", marginBottom: "-0.5rem"}}>
                                  <Typography style={{padding: "2rem"}}>
                                  {moment(inputWeek[k].date, "DD/MM/YYYY")
                                    .format("MMM D, YYYY")
                                    .slice(0, -6)} , {" "} {inputWeek[k].day}
                                  </Typography>
                                  <hr/>
                                </Box>
                              </Grid>
                              <Grid item xs={6} sm={6}>
                                <Box 
                                  style={{
                                    background: date.type === "Weekly Off"? "#EDEEF2": "",
                                    paddingBottom: "0.5rem" }}>
                                  <NumericFormat
                                    id="outlined-basic"
                                    variant="outlined"
                                    name="HoursName"
                                    customInput={TextField}
                                    value={date.hrs}
                                    readOnly
                                    InputProps={{
                                      style: {
                                        fontSize: 14,
                                      },
                                      inputProps: {
                                        maxLength: 5,
                                        min: 0,
                                        step: 0.25,
                                        onKeyPress: preventInvalidInput,
                                      },
                                    }}
                                    style={{
                                      width: "80%",
                                      margin: "1rem",
                                      marginTop: "1.25rem",
                                      backgroundColor:
                                      date.type === "Working"
                                        ? "#FFFFFF"
                                        : date.type === "Holiday"
                                        ? "rgba(246, 229, 208, 1)"
                                        : date.type === "Leave"
                                        ? "rgba(199, 234, 234, 1)"
                                        : "",

                                    border:
                                      date.type === "Working"
                                        ? "0.063rem solid #BDBDBD"
                                        : date.type ===
                                          "Weekly Off"
                                        ? "0.063rem solid #BDBDBD"
                                        : date.type === "Holiday"
                                        ? "0.063rem solid #E5BF90"
                                        : date.type === "Leave"
                                        ? "0.063rem solid #629797"
                                        : "",
                                      borderRadius: "0.313rem",
                                    }}
                                    disabled={
                                      date.dayEnabledFlag === 0
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      const newDate = {
                                        ...date,
                                      };
                                      newDate.hrs =
                                        e.target.value;
                                      setIsDayHoursChanged(
                                        true
                                      );
                                      setInternalHoursValue(
                                        index,
                                        catIndex,
                                        k,
                                        newDate.hrs
                                      );
                                    }}
                                  />
                                </Box> 
                              </Grid>
                            </Grid>
                            ))}
                          </Box>
                        </Box>
                      </Box>
                      </>
                      );
                      })}
                    </>
                  )}
                </Box>
              )
            )
          )}
          <>
          <Box style={{
            backgroundColor: "#E3E8F7", 
            paddingLeft:"1.563rem", 
            letterSpacing: "-0.1rem",
            color: "#0B1941",
            fontFamily: "Montserrat",
            fontSize: "0.625rem",
            fontWeight: 600,
            lineHeight: "1.5rem",
            letterSpacing: "-0.01rem",
            textAlign: "left",
            marginTop: "0.5rem"
          }}
          >
          Other Projects
          </Box>
          </>
          <Box>
            {newOthersTimesheet?.map((project, projectIndex) =>
              newOthersTimesheet[projectIndex]?.category?.map(
                (category, categoryIndex) => (
                  <>
                    <Box style={{marginBottom: "-0.563rem"}}>
                      <Box>
                        <Box style={{
                          display: "flex", 
                          justifyContent: "space-between", 
                          alignItems: "center", 
                          paddingLeft: "1.563rem",
                          paddingTop: "0.8rem",
                          paddingRight: "0.8rem"
                        }}
                          onClick={() => toggleSection3(projectIndex)}
                        >
                          {/* {console.log("projjjeectt" , project)} */}
                          {project?.projectDetails && newOthersTimesheet? 
                          <Typography style={{color: "#2D2D2D", fontSize: "0.7rem", fontWeight: 600, fontFamily: "Montserrat"}}>
                          {project?.projectDetails?.projCode} {" "} - {" "} <span style={{color: "#2D2D2D", fontSize: "0.7rem", fontWeight: 600, fontFamily: "Montserrat"}}>{project?.projectDetails?.projName}</span><br/>
                          {project?.projectDetails?.projPurp == null ?
                          ""
                        :<span  style={{ 
                          backgroundColor: "#E3E8F7",
                            width: "134px",
                            //padding: "2px",
                            height: "14px",
                            fontSize: "10px",
                            //paddingLeft: "22px",
                            padding:
                              " 1px 2px 2px 4px",
                           marginBottom:"8px",
                            
                         }}> {" "}  {project?.projectDetails?.projPurp}</span>}
                          </Typography>: <Typography></Typography>}
                          {expandedSections3.includes(projectIndex) ? (
                            <KeyboardArrowUpIcon
                              onClick={() => toggleSection3(projectIndex)}
                              style={{ cursor: 'pointer' }}
                            />
                          ) : (
                            <KeyboardArrowDownIcon
                              onClick={() => toggleSection3(projectIndex)}
                              style={{ cursor: 'pointer' }}
                            />
                          )}
                        </Box>
                        <hr/>
                      </Box>
                    </Box>

                    {expandedSections3.includes(projectIndex) && (
                      <Box
                        style={{
                          //paddingLeft: "20px",
                          backgroundColor: "#F9F9F9",
                        }}
                        key={projectIndex}
                      >
                        <Box
                        >
                            <Grid item xs={12} sm={12}>
                              <Box
                                style={{minWidth: "86%", paddingLeft: "1.563rem", paddingTop: "1rem"}}
                              >
                                <Autocomplete
                                  freeSolo
                                  id={`combo-box-demo-${projectIndex}`}
                                  options={allProjects}
                                  getOptionLabel={(option) =>
                                    option.projPurpose == null ? `${option.projName} - ${option.projCode}`
                                    : `${option.projName} - ${option.projCode} ${" "}  ${option.projPurpose}`
                                  }
                                  value={project?.projectDetails}
                                  style={{ width: "85%", fontSize: "0.875rem" }}
                                  variant="outlined"
                                  readOnly
                                  sx={{
                                    ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                                      top: "auto", 
                                    },
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Project"
                                      variant="outlined"
                                      //className={classes.formControlProj}
                                    />
                                  )}
                                  onChange={(event, selectedProject) => {
                                    const updatedProjects = [
                                      ...newOthersTimesheet,,
                                    ];
                                    updatedProjects[
                                      projectIndex
                                    ].projectDetails = selectedProject;
                                    setNewOthersTimesheet(updatedProjects);
                                  }}
                                />
                              </Box>
                            </Grid>
                            <Grid>
                              <Box
                                key={categoryIndex}
                              >
                              <Grid item xs={12} sm={12}>
                              <Box
                                style={{minWidth: "86%", paddingLeft: "1.563rem", paddingTop: "1rem"}}
                              >
                                <FormControl
                                  variant="outlined"
                                  style={{ width: "85%", fontSize: "0.875rem" }}
                                >
                                  {/* <InputLabel
                                    id={`category-label-${projectIndex}-${categoryIndex}`}
                                  >
                                    Category
                                  </InputLabel> */}
                                  <TextField
                                    labelId={`category-label-${projectIndex}-${categoryIndex}`}
                                    value={category.categoryName}
                                    onChange={(event) =>
                                      handleCategoryName(
                                        projectIndex,
                                        categoryIndex,
                                        event.target.value
                                      )
                                    }
                                    readOnly
                                    label="Category"
                                  />
                                    {/* {categoryItem.map((item) => (
                                      <MenuItem key={item} value={item}>
                                        {item}
                                      </MenuItem>
                                    ))} */}
                                </FormControl>
                              </Box>  
                              </Grid>
                                <Box style={{marginTop: "0.8rem", marginBottom: "-0.1rem"}}
                                >
                                  {category.dayHours?.map((hours, dayIndex) => {
                                    const checkLeave =
                                      internalTimesheet[0]?.category[0]?.dayHours[
                                        dayIndex
                                      ];
                                    return (  
                                    <Grid container> 
                                    <Grid item xs={6} sm={6}>
                                    <Box style={{backgroundColor: "#DCDFE9", marginBottom: "-0.5rem"}}>
                                      <Typography style={{padding: "2rem"}}>
                                      {moment(inputWeek[dayIndex].date, "DD/MM/YYYY")
                                        .format("MMM D, YYYY")
                                        .slice(0, -6)} , {" "} {inputWeek[dayIndex].day}
                                      </Typography>
                                      <hr/>
                                    </Box>
                                  </Grid>
                                    <Grid item xs={6} sm={6}>
                                      <Box 
                                        style={{
                                          background: checkLeave?.type === "Weekly Off"? "#EDEEF2": "",
                                          paddingBottom: "0.5rem" }}>
                                        <Box>
                                          <NumericFormat
                                          id={`hours-${projectIndex}-${categoryIndex}-${dayIndex}`}
                                            variant="outlined"
                                            name={`hoursName-${projectIndex}-${categoryIndex}-${dayIndex}`}
                                            customInput={TextField}
                                            value={hours.hrs || "0"}
                                            //readOnly
                                            // onChange={(event) => {
                                            //     handleDayHourChange(dayIndex,event.target.value,projectIndex,
                                            //       categoryIndex
                                            //     );
                                            //   }}
                                            onChange={(event) => {
                                              setIsDayHoursChanged(true);
                                              const newDate = { ...hours };
                                              newDate.hrs =
                                                event.target.value;
                                              setNewOthersHoursValue(
                                                projectIndex,
                                                categoryIndex,
                                                dayIndex,
                                                newDate.hrs
                                              );
                                            }}
                                            InputProps={{
                                              inputProps: {
                                                maxLength: 5,
                                                min: 0,
                                                step: 0.25,
                                                onKeyPress:
                                                  preventInvalidInput,
                                              },
                                              style: {
                                                fontSize: 14,
                                              },
                                            }}
                                            style={{
                                              width: "80%",
                                              margin: "1rem",
                                              marginTop: "1.25rem",
                                              backgroundColor:
                                                  checkLeave?.type === "Working"
                                                    ? "#FFFFFF"
                                                    : checkLeave?.type === "Holiday"
                                                    ? "rgba(246, 229, 208, 1)"
                                                    : checkLeave?.type === "Leave"
                                                    ? "rgba(199, 234, 234, 1)"
                                                    : "",

                                              border:
                                                checkLeave?.type === "Working"
                                                  ? "0.063rem solid #BDBDBD"
                                                  : checkLeave?.type ===
                                                    "Weekly Off"
                                                  ? "0.063rem solid #BDBDBD"
                                                  : checkLeave?.type === "Holiday"
                                                  ? "0.063rem solid #E5BF90"
                                                  : checkLeave?.type === "Leave"
                                                  ? "0.063rem solid #629797"
                                                  : "",
                                              borderRadius: "0.313rem",
                                            }}
                                            disabled={
                                              checkLeave?.date < joiningDate || checkLeave ?.date < systemLiveDate || checkLeave?.date > empEndDate
                                              // checkLeave?.dayEnabledFlag === 0
                                                ? true
                                                : false
                                            }
                                          />
                                        </Box>
                                      </Box>
                                      </Grid> 
                                    </Grid>
                                  )})}
                                  <Box
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end", 
                                      paddingRight: "1.563rem",
                                    }}
                                  >
                                    <Button onClick={() => handleRemoveCategory(projectIndex, categoryIndex)}>                              
                                    <span class="material-symbols-outlined">
                                      delete
                                    </span>
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                        </Box>
                      </Box>
                    )}

                    <Divider
                      style={{
                        backgroundColor: "#E1E1E1",
                        width: "100%",
                        heigth: "0.031",
                      }}
                      orientation="horizontal"
                    />
                  </>
                )
              )
            )}
          </Box>
          {projDialogOpen && renderAutocomplete()}
        </Box>
      
      {showAddProjectLink && (
        <>
        <Box>
          <Box>
            <Box style={{
              display: "flex", 
              justifyContent: "space-between", 
              alignItems: "center", 
              paddingLeft: "2rem",
              paddingTop: "1.5rem",
              paddingBottom: "1.5rem",
              backgroundColor: "#F6F6F6",
              paddingRight: "1.5rem",
              marginTop: "0rem"
            }}>
              <Typography style={{
                color: "#008CE6",
                fontSize: "1rem",  
                fontWeight: 600,
                fontFamily: "Montserrat"
              }}>
                <Link
                  style={{
                    color: "#008CE6",
                    backgroundColor: "#F6F6F6",
                    cursor: "pointer",
                    fontSize: "1rem",  
                    fontWeight: 600,
                    fontFamily: "Montserrat",
                    textDecoration: "underline"
                  }}
                  // target="_blank"
                  // underline="always"
                  //disabled={IsCopyLastWeekDisabled === true ? true : false}
                  onClick={() => {
                    handleAddProj();
                  }}
                >
                  + Add Project
                </Link>
              </Typography>
            </Box>
            {/* <hr style={{margin: "0rem"}} /> */}
          </Box>
        </Box>
       </>
        )}
        <Box>
          <Box>
            <Box style={{
              display: "flex", 
              justifyContent: "space-between", 
              alignItems: "center", 
              paddingLeft: "2rem",
              paddingTop: "1.5rem",
              paddingBottom: "1.5rem",
              backgroundColor: "#F6F6F6",
              paddingRight: "1.5rem"
            }}>
              <Typography style={{
                color: "#2B2D30",
                fontSize: "1rem",  
                fontWeight: 600,
                fontFamily: "Montserrat"
              }}>
                  Add Comments (if any)
              </Typography>
            </Box>
            <Box style={{backgroundColor: "#F6F6F6"}}>
              <TextField
                id="outlined-multiline-static"
                multiline
                fullWidth
                minRows={4}
                variant="outlined"
                inputProps={{
                  maxLength: 100,
                }}
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value);
                }}
                style={{backgroundColor: "#FFFFFF", marginLeft: "1rem", width : "90%", marginBottom: "1.5rem"}}
              />
            </Box>
          </Box>
        </Box>
        <Box style={{ textAlign: "center", backgroundColor: "#F6F6F6", paddingBottom: "1rem"}}>
          <Grid container>
            <Grid item sm={6} xs={6}>
              <Button size="large" variant="outlined" color="primary" style={{ 
                backgroundColor: isSaveDisabled ? "#9E9E9E" : "",
                color: isSaveDisabled ? "rgba(255, 255, 255, 0.9)" : "", 
                textTransform: "none", 
                border: isSaveDisabled ? "" : "0.063rem solid #0B1941",
                width: "70%" }}
                disabled={isSaveDisabled}
                onClick={() => {
                 handleSave();
                  setSaveOrSubmitButton("Save");
                }}>
                Save
              </Button>
            </Grid>
            <Grid item sm={6} xs={6}>
              <Button size="large" variant="contained" color="primary" style={{ 
                background: "#CCCCCC",
                backgroundColor: isSubmitDisabled ? "#9E9E9E" : "#043365",
                color: "rgba(255, 255, 255, 0.9)",
                textTransform: "none", 
                width: "70%" }} 
                disabled={isSubmitDisabled ? true : false}
                onClick={() => {
                  handleProceed();
                  setSaveOrSubmitButton("Submit");
                }}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <>
        <Dialog
          open={showConfirmation}
          TransitionComponent={Transition}
          BackdropProps={{ onClick: handleBackdropClick }}
          keepMounted
          onClose={handleConfirmClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Are you sure want to navigate?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              It looks like you have been editing something. If you leave before
              saving or submitting, your changes will be lost.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={handleYesButton}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleConfirmClose}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          style={{ paddingTop: "0.188rem", paddingBottom: "0.313rem" }}
          open={finalDialogOpen}
          onClose={!finalDialogOpen}
          BackdropProps={{ onClick: handleBackdropClick }}
        >
          <DialogTitle style={{ paddingTop: "0.188rem", paddingBottom: "0.313rem" }}>
            Timesheet Summary
          </DialogTitle>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "1.875rem",
            }}
          >
            <Box className={classes.thirdOneBox}>Total hours: </Box>
            <Box
              className={classes.totalBox}
              style={{
                display: "flex",
                justifyContent: "center",
                color: totalHours > 40 ? "#D40606" : "#0B1941",
                fontWeight: 600,
              }}
            >
              {parseFloat(totalHours).toFixed(2)}
            </Box>
          </Box>

          <DialogContent style={{ paddingTop: "0.188rem", paddingBottom: "0.313rem" }}>
            {/* {console.log("console for dialog check", timesheetProjectData)} */}
            {timesheetProjectData && (
              <TableContainer>
                <TableHead style={{ lineHeight: "1rem" }}>
                  <TableCell style={{ lineHeight: "1rem" }}>Day</TableCell>
                  <TableCell
                    style={{ lineHeight: "1rem", alignContent: "center", textAlign: "center" }}
                  >
                    Date
                  </TableCell>
                  <TableCell style={{ lineHeight: "1rem" }}>Hours</TableCell>
                </TableHead>
                <TableBody>
                  {/* {console.log("timesheet summary", timesheetProjectData)} */}
                  {timesheetProjectData.map((item, i) => {
                    const dayIndex = i; // Replace occurrences of timesheetProjectData[i] with dayIndex
                    const { days, dates, dayhours, type } = item;
                    let sumHours = 0;

                    if(dates >= joiningDate && dates >= systemLiveDate && dates <= empEndDate)
                    {
                      return !timesheetProjectData[i]?.days ||
                      totaldaywise1[timesheetProjectData[i]?.days] ? (
                      ""
                    ) 
                    : (
                      <TableRow style={{ lineHeight: "100%" }} key={i}>
                        {type === "Working" ? (
                          <TableCell style={{ lineHeight: "100%" }}>
                            {days}
                          </TableCell>
                        ) : (
                          <TableCell style={{ lineHeight: "100%" }}>
                            {days} ({type})
                          </TableCell>
                        )}
                    
                        {/* <TableCell>{dates}</TableCell> */}
                        <TableCell style={{ lineHeight: "100%" , width: "100%", textAlign: "center"}}>
                          {moment(dates, "yyyy-MM-DD")
                            .format("MMM D, YYYY")
                            .slice(0, -6)}
                        </TableCell>
                        <TableCell style={{ lineHeight: "100%" }}>
                          {timesheetProjectData.map((item1) => {
                            const { dates, dayhours } = item1;
                            sumHours +=
                              timesheetProjectData[i]?.dates === item1.dates
                                ? item1.dayhours
                                : 0;
                          })}
                          <Box
                            style={{
                              color:
                                (sumHours > 8 || sumHours < 8) && type === "Working" ? "red" : "",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {sumHours}
                          </Box>
                        </TableCell>
                        {(totaldaywise1[timesheetProjectData[i]?.days] = true)}
                      </TableRow>
                    );
                    }
                    
                  })}
                </TableBody>
              </TableContainer>
            )}
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "0.313rem",
              }}
            >
              Do you want to submit this timesheet?
            </Box>
          </DialogContent>
          <DialogActions style={{ marginRight: "0.5rem" }}>
            <Button
              style={{
                minWidth: "6rem",
                minHeight: "2.25",
                border: "0.063rem solid #0B1941",
                padding: "0.313rem",
                textTransform: "none",
              }}
              onClick={handleDialogCloseCancel}
            >
              Cancel
            </Button>
            <Button
              style={{
                minWidth: "6rem",
                minHeight: "2.25rem",
                background: "#CCCCCC",
                backgroundColor: "#043365",
                color: "rgba(255, 255, 255, 0.9)",
                padding: "0.313rem",
                textTransform: "none",
                marginRight: "1.25rem",
              }}
              onClick={stopSubmission ? handleStopSubmission : handleDialogClose}
              autoFocus
              disabled={isOkButtonDisabled}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </>
      <Snackbar
        className={classes.snackbarContainer}
        open={snackOpenForInvalidDay}
        onClose={() => handleSnackCloseValiproj(false)}
      >
        <div>
        <Alert
          onClose={() => handleSnackCloseValiproj(false)}
          severity="error"
          sx={{ width: "100%", backgroundColor: "red", color: "white" }}
        >
          Maximum hrs can be put in a day are 23.75. Please update the filled
          hrs for <strong>{greatDay}</strong>.
        </Alert>
        </div>
      </Snackbar>
      <div className={classes.snackbarContainer}>
        <Snackbar
          className={classes.snackbarContainer}
          open={EnterProjSnackbarOpen}
          BackdropProps={{ onClick: handleBackdropClick }}
          onClose={() => handleCloseEnterProj(false)}
          style={{ width: "100%" }}
        >
          <div>
          <Alert
            onClose={() => handleCloseEnterProj(false)}
            severity="error"
            sx={{ width: "100%", backgroundColor: "red", color: "white" }}
          >
            Please enter project name and category in other projects section. Also, hours should be greater than 0 and multiple of 0.25.
          </Alert>
          </div>
        </Snackbar>
      </div>
      <div className={classes.snackbarContainer}>
        <Snackbar
          className={classes.snackbarContainer}
          open={snackbarOpenForVali}
          onClose={() => handleSnackCloseVali(false)}
          style={{ width: "100%" }}
        >
          <div>
          <Alert
            onClose={() => handleSnackCloseVali(false)}
            severity="error"
            sx={{ width: "100%", backgroundColor: "red", color: "white" }}
          >
            Hours per day should be a multiple of 0.25 hours. You have an invalid entry for
            {"   "}
            {/* {console.log(
              "control inside if check invalid",
              invalidDate,
              invalidDate1
            )} */}
            <strong>
              {moment(invalidDate1, "yyyy-MM-DD")
                .format("MMM D, YYYY")
                .slice(0, -6)}
            </strong>
            .{/* <strong>{invalidDate1}</strong> */}
          </Alert>
          </div>
        </Snackbar>
      </div>
      <Snackbar
        className={classes.snackbarContainer}
        open={snackbarOpenForValiproj}
        onClose={() => handleSnackCloseValiproj(false)}
      >
        <div>
        <Alert
          onClose={() => handleSnackCloseValiproj(false)}
          severity="error"
          sx={{ width: "100%", backgroundColor: "red", color: "white" }}
        >
          Duplicate entries exist in project and category combination in other
          projects section.
        </Alert>
        </div>
      </Snackbar>

      <Snackbar
        className={classes.snackbarContainer}
        open={snackbarOpenForValiWeek}
        onClose={() => handleSnackCloseValiprojWeek(false)}
      >
        <div>
        <Alert
          onClose={() => handleSnackCloseValiprojWeek(false)}
          severity="error"
          sx={{ width: "100%", backgroundColor: "red", color: "white" }}
        >
          Minimum total hours per week should be 40 hours.
        </Alert>
        </div>
      </Snackbar>
      <Snackbar
        className={classes.snackbarContainer}
        open={snackbarOpenForValicat}
        onClose={() => handleSnackCloseValiprojcat(false)}
      >
        <div>
        <Alert
          onClose={() => handleSnackCloseValiprojcat(false)}
          severity="error"
          sx={{ width: "100%", backgroundColor: "red", color: "white" }}
        >
          Duplicate entries exist in project and category combination in other
          projects section.
        </Alert>
        </div>
      </Snackbar>

      <Snackbar
        open={timesheetComment.length > 0 && snackbarCommentOpen}
        onClose={() => handleCommentSnackClose(false)}
      >
        <div>
        <Alert
          onClose={() => handleCommentSnackClose(false)}
          severity="error"
          sx={{ width: "100%", backgroundColor: "red", color: "white" }}
        >
          {timesheetComment}
        </Alert>
        </div>
      </Snackbar>

      <Snackbar open={isSubmitted} onClose={() => handleSubmittedClose(false)}>
        <div>
        <Alert
          onClose={() => handleSubmittedClose(false)}
          severity="success"
          sx={{ width: "100%", backgroundColor: "green", color: "white" }}
        >
          Timesheet is submitted successfully.
        </Alert>
        </div>
      </Snackbar>

      <Snackbar open={isSaved} onClose={() => handleSavedClose(false)}>
        <div>
        <Alert
          onClose={() => handleSavedClose(false)}
          severity="success"
          sx={{ width: "100%", backgroundColor: "green", color: "white" }}
        >
          Timesheet is saved successfully.
        </Alert>
        </div>
      </Snackbar>

      <Snackbar
        open={statusSnackbarOpen}
        onClose={() => handleStatusSnackClose(false)}
      >
        <div>
        <Alert
          onClose={() => handleStatusSnackClose(false)}
          severity="error"
          sx={{ width: "100%", backgroundColor: "red", color: "white" }}
        >
          There is some problem with the application. Please connect with System
          Admin.
        </Alert>
        </div>
      </Snackbar>

      <Snackbar
        open={stopSubmissionSnackbarOpen}
        onClose={() => handleStopSubmissionClose(false)}
        autoHideDuration={8000}
      >
        <div>
          <Alert
            onClose={() => handleStopSubmissionClose(false)}
            severity="error"
            sx={{ width: "100%", backgroundColor: "red", color: "white" }}
          >
            Please enter hours in internal project for all working days, or connect with the respective PMO/HRBP to get project allocation done to submit time entry.
          </Alert>
        </div>
      </Snackbar>

      <Dialog
        open={openCopyLastWeekDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCopyLastWeekClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Copy Last Week?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure want to copy last week data? You'll lose your all the
            current week data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={okCopyLastWeek}>
            Ok
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCopyLastWeekClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <div>
      <Backdrop
        sx={{ color: '#092FED', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
    <div>
      <Dialog
        open={openRating}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleRatingClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <Box style={{ width: "300px", height: "500px" }}>
          <DialogTitle>
            <Typography
              style={{
                fontWeight: "600",
                fontSize: "20px",
                marginLeft: "10px",
                textAlignLast: "center",
              }}
            >
              Rate and Review <br></br> {ratingHeading}
            </Typography>
            <Divider
              style={{
                backgroundColor: "#E1E1E1",
                width: "100%",
                height: "0.5px",
                marginLeft: "2.5%",
                marginTop: "10px",
              }}
              orientation="horizontal"
            />
          </DialogTitle>
          <DialogContent style={{ paddingTop: "12px" }}>
            <DialogContentText id="alert-dialog-slide-description">
              <Box style={{ marginLeft: "-44px" }}>
                <Box style={{ width: "300px", marginLeft: "20px" }}>
                  <Typography
                    style={{ textAlign: "center", color: "#000", width: "18rem" }}
                  >
                    <span style={{ color: "#2CB0FE", fontWeight: "400"}}>
                      {ssoEmpIdDetails ? ssoEmpIdDetails?.empName : ""}
                    </span>
                    , we'd like to know your reviews on {ratingHeading}.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: 300,
                    display: "flex",
                    alignItems: "center",
                    paddingTop: "40px",
                    textAlignLast: "center",
                  }}
                >
                  <Box sx={{ width: 100 }}></Box>
                  <Box sx={{ width: 200 }}>
                    <Rating
                      name="hover-feedback"
                      value={value}
                      getLabelText={getLabelText}
                      size="large"
                      onChange={(event, newValue) => {
                        newValue === null ? setValue(-1) : setValue(newValue);
                      }}
                      onChangeActive={(event, newHover) => {
                        setHover(newHover);
                      }}
                      emptyIcon={
                        <StarOutlineIcon
                          style={{opacity: 0.8, color: "#2CB0FE" }}
                          fontSize="inherit"
                        />
                      }
                      style={{ textAlignLast: "center" }}
                    />
                  </Box>
                  <Box sx={{ width: 50 }}></Box>
                </Box>

                {value !== null && ratingLabels && (
                  <Box
                    style={{
                      textAlignLast: "center",
                      paddingTop: "5px",
                      marginLeft: "3rem",
                      height: "1rem",
                    }}
                  >
                    <Typography
                      style={{ fontSize: "14px", color: "#000" }}
                    >
                      {
                        ratingLabels[hover !== -1 ? hover - 1 : value - 1]
                          ?.ratingDescription
                      }
                    </Typography>
                  </Box>
                )}
              </Box>

              <Box>
                <Box
                  style={{ textAlignLast: "center", paddingTop: "25px" }}
                >
                  <Typography
                    style={{ fontWeight: "550", color: "#2CB0FE" }}
                  >
                    Write a Review
                  </Typography>
                </Box>
                <Box
                  style={{
                    paddingTop: "10px",
                    marginLeft: "10px",
                  }}
                >
                  <TextField
                    id="review"
                    value={review}
                    placeholder="What did you like or dislike? Write here your feedback about this application."
                    multiline
                    rows={3}
                    onChange={(e) => setReview(e.target.value)}
                    style={{
                      width: "100%",
                    }}
                    InputProps={{
                      inputProps: {
                        maxLength: 200,
                      }
                    }}
                  ></TextField>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: "6px",
                  }}
                >
                  <Box style={{ width: "50px" }}></Box>
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        width: "120px",
                        textTransform: "none",
                        backgroundColor: isRatingSubmitDisabled
                          ? "#9E9E9E"
                          : "#043365",
                      }}
                      onClick={handleRatingSubmit}
                      disabled={isRatingSubmitDisabled ? true : false}
                    >
                      Submit
                    </Button>
                  </Box>
                  <Box>
                    <Link underline="none" target="_blank">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          height: "30px",
                        }}
                      >
                        <Typography
                          onClick={handleRatingSkipClose}
                          style={{
                            cursor: "pointer",
                            fontWeight: "400",
                            fontSize: "14px",
                          }}
                        >
                          Skip {`>`}
                        </Typography>
                      </Box>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Box>
      </Dialog>
    </div>
    <div>
      <Dialog
        open={thankYouRatingOpen}
        // TransitionComponent={Transition}
        keepMounted
        // onClose={handleThankyou}
        aria-describedby="alert-dialog-slide-description"
      >
        <Box style={{ width: "300px", height: "250px" }}>
          <DialogTitle>
            <CheckCircleIcon className={classes.successIcon} />
            <Typography
              style={{
                fontWeight: "600",
                fontSize: "20px",
                marginLeft: "10px",
                textAlignLast: "center",
              }}
            >
              Thanks for <br></br>Rating and Review.
            </Typography>
          </DialogTitle>
          <DialogContent style={{ paddingTop: "12px" }}>
            <DialogContentText id="alert-dialog-slide-description">
              <Box style={{ marginLeft: "-44px" }}>
                <Box
                  sx={{
                    width: 300,
                    display: "flex",
                    alignItems: "center",
                    paddingTop: "40px",
                    textAlignLast: "center",
                  }}
                >
                  <Box sx={{ width: 100 }}></Box>
                  <Box sx={{ width: 200 }}>
                    <Rating
                      name="hover-feedback"
                      value={value}
                      getLabelText={getLabelText}
                      size="large"
                      emptyIcon={
                        <StarOutlineIcon
                          style={{opacity: 0.8, color: "#2CB0FE" }}
                          fontSize="inherit"
                        />
                      }
                      style={{ textAlignLast: "center" }}
                    />
                  </Box>
                  <Box sx={{ width: 50 }}></Box>
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Box>
      </Dialog>
    </div>    
    </>
  );
}

export default RespTSApp;
