import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Paper, makeStyles } from "@material-ui/core";
import { Button } from "antd";
import { useMsal, useAccount } from "@azure/msal-react";

import MobileViewNavbar from "../../components/mobileViewNavbar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import {
  getWeeklyPreviousTimesheetList,
  maintainState,
} from "../../redux/TimeSheet/actionTs";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Link, useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import ResponsiveMyTimesheetTable from "../../TimesheetComponents/responsiveMyTimesheetTable";
import ResponsiveTeamTimesheet from "../../TimesheetComponents/responsiveTeamTimesheet";
import ResponsiveMyTeamApproval from "../../TimesheetComponents/responsiveMyTeamApproval";
import ResponsivePMOAutomation from "../../TimesheetComponents/responsiveMyPMOApproval";
import { useTheme } from "@material-ui/styles";
import { getTimesheetAccessFlagData } from "../../redux/TimeSheet/actionTs";

import { getEmpDetail } from "../../redux/Expense/action";
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  tabText: {
    fontWeight: "600",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: "0.688rem",
    lineHeight: "1.25rem",
    letterSpacing: "-0.005rem",
    display: "flex",
    color: "#0B1941",
  },

  addExpenseButton: {
    height: "32px",
    width: "195px",
    background: " #0B1941",
    boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    borderRadius: "5px",
    "&:hover": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
    "&:focus": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
  },
  addExpenseButtonMac: {
    height: "32px",
    width: "195px",
    background: " #0B1941",
    boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    borderRadius: "5px",
    "&:hover": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
    "&:focus": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
    "& .MuiButton-root": {
      marginLeft: "630px",
    },
  },
  buttonText: {
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
  },
});

function MobileViewDashboardTs(props) {
  // console.log("mobileViewDashboard");
  const matches = useMediaQuery("(max-width:600px)");
  const theme = useTheme();
  const [nDay, setNDay] = useState(30);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { instance, accounts, inProgress } = useMsal(); //
  const account = useAccount(accounts[0] || {}); //

  const [value, setValue] = React.useState(0);
  const [indexing, setIndexing] = useState(3);

  const maintainStateVar = useSelector(
    (state) => state.timesheetData.maintainStateVariable
  );
  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );
  const tsDelEmpIdRedux = useSelector(
    (state) => state?.timesheetData?.tsDelEmpId
  );
  const timesheetAccessFlag = useSelector(
    (state) => state.timesheetData.getTimesheetFlag
  );
  const myTimesheetDelegateesList = useSelector(
    (state) => state?.timesheetData?.getMyTimesheetDelegateeData
  );

  useEffect(() => {
    if (ssoEmpIdDetails || tsDelEmpIdRedux) {
      const obj = {
        employeeId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
      };
      dispatch(getTimesheetAccessFlagData(obj));
    }
  }, [ssoEmpIdDetails, tsDelEmpIdRedux]);

  useEffect(() => {
    dispatch(getEmpDetail(account?.username));
  }, [account?.username]);
  useEffect(() => {
    if (
      timesheetAccessFlag?.teamTimesheetFlag === 0 &&
      timesheetAccessFlag?.invoicingTabFlag === 1 &&
      indexing !== 1
    ) {
      setIndexing(1);
      // dispatch(getEmpDetail(account?.username));
    } else if (timesheetAccessFlag?.teamTimesheetFlag == 1 && indexing == 1) {
      setIndexing(3);
      // dispatch(getEmpDetail(account?.username));
    }
  }, [
    timesheetAccessFlag?.teamTimesheetFlag,
    timesheetAccessFlag?.invoicingTabFlag,
    indexing,
  ]);
  useEffect(() => {
    // Reset the tab value to 0 when switching users
    // console.log("PMO11>>>>", maintainStateVar, tsDelEmpIdRedux);
    if (
      myTimesheetDelegateesList?.length > 0 &&
      maintainStateVar != null &&
      maintainStateVar != undefined
    ) {
      // console.log("PMO14>>>>", value, maintainStateVar, tsDelEmpIdRedux);

      setValue(0);
      dispatch(maintainState(0));
    }
  }, [tsDelEmpIdRedux]);
  useEffect(() => {
    //  if (maintainStateVar != null && maintainStateVar != undefined) {
    //   if (maintainStateVar == 2) {
    //     setValue(2);
    //   } else {
    //     setValue(maintainStateVar);
    //   }
    // }
    if (maintainStateVar != null && maintainStateVar != undefined) {
      //console.log("abcdjkj3");
      setValue(maintainStateVar);
      dispatch(maintainState(maintainStateVar));
    }
  }, []);
  const handleChange = (event, newValue) => {
    // console.log("TLAND1 newValue in handleChange", newValue);
    setValue(newValue);
    dispatch(maintainState(newValue));
  };

  return (
    <>
      <Box>
        <MobileViewNavbar ssoEmpId={ssoEmpIdDetails?.empId} />
        {/* {console.log("login details", ssoEmpIdDetails)} */}
        <Box>
          {timesheetAccessFlag?.teamTimesheetFlag == 1 && (
            <Tabs
              style={{ backgroundColor: "#EDF2FF " }}
              value={maintainStateVar}
              onChange={handleChange}
              variant="scrollable"
        scrollButtons="auto"
              // indicatorColor="primary"
              // textColor="primary"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#112664",
                  marginLeft: "5px",
                  // marginRight:"10px",
                  //paddingLeft: "10px",
                },
              }}
              aria-label="full width tabs example"
            >
              <Tab
                label={
                  <Typography className={classes.tabText}>
                    {" "}
                    My Timesheet
                  </Typography>
                }
                {...a11yProps(0)}
              />
              <Tab
                label={
                  <Typography className={classes.tabText}>
                    {" "}
                    Team Timesheet
                  </Typography>
                }
                {...a11yProps(1)}
              />
              <Tab
                label={
                  <Typography className={classes.tabText}>
                    {" "}
                    My Team Approval
                  </Typography>
                }
                {...a11yProps(2)}
              />
              {timesheetAccessFlag?.invoicingTabFlag == 1 && (
                <Tab
                  label={
                    <Typography className={classes.tabText}>
                      {" "}
                      PMO Approval
                    </Typography>
                  }
                  {...a11yProps(3)}
                />
              )}
            </Tabs>
          )}
          {timesheetAccessFlag?.teamTimesheetFlag == 0 && (
            <Tabs
              style={{ backgroundColor: "#EDF2FF " }}
              value={maintainStateVar}
              onChange={handleChange}
              variant="scrollable"
        scrollButtons="auto"
              // indicatorColor="primary"
              // textColor="primary"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#112664",
                  marginLeft: "5px",
                  // marginRight:"10px",
                  //paddingLeft: "10px",
                },
              }}
              aria-label="full width tabs example"
            >
              <Tab
                label={
                  <Typography className={classes.tabText}>
                    {" "}
                    My Timesheet
                  </Typography>
                }
                {...a11yProps(0)}
              />
              {timesheetAccessFlag?.invoicingTabFlag == 1 && (
                <Tab
                  label={
                    <Typography className={classes.tabText}>
                      {" "}
                      PMO Approval
                    </Typography>
                  }
                  {...a11yProps(1)}
                />
              )}
            </Tabs>
          )}
          <TabPanel
            value={maintainStateVar}
            index={0}
            dir={theme.direction}
            className={classes.enhanceTable1}
          >
            <Box className={classes.enhanceTable}>
              <ResponsiveMyTimesheetTable
                //  getMyExpenseList={getMyExpenseList}
                nDay={nDay}
                ssoEmpId={props.ssoEmpId}
              />
            </Box>
          </TabPanel>
          <TabPanel
            value={maintainStateVar}
            index={indexing == 1 ? 4 : 1}
            dir={theme.direction}
            className={classes.enhanceTable1}
          >
            <Box className={classes.enhanceTable}>
              <ResponsiveTeamTimesheet
                //  getMyExpenseList={getMyExpenseList}
                nDay={nDay}
                ssoEmpId={props.ssoEmpId}
              />
            </Box>
          </TabPanel>
          <TabPanel
            value={maintainStateVar}
            index={2}
            dir={theme.direction}
            className={classes.enhanceTable1}
          >
            <Box className={classes.enhanceTable}>
              <ResponsiveMyTeamApproval
                //  getMyExpenseList={getMyExpenseList}
                nDay={nDay}
                ssoEmpId={props.ssoEmpId}
              />
            </Box>
          </TabPanel>
          <TabPanel
            value={maintainStateVar}
            index={indexing}
            dir={theme.direction}
            className={classes.enhanceTable1}
          >
            <Box className={classes.enhanceTable}>
              <ResponsivePMOAutomation
                //  getMyExpenseList={getMyExpenseList}
                nDay={nDay}
                ssoEmpId={props.ssoEmpId}
              />
            </Box>
          </TabPanel>
        </Box>
      </Box>
    </>
  );
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          <Typography>{children}</Typography>
        </>
      )}
    </div>
  );
}
export default MobileViewDashboardTs;