import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "./header";
import AllExpensesTable from "./allExpenseTable.js";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useLocation, useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import { style } from "@material-ui/system";
import {
  getDownloadExpenseReportData,
  getDownloadExpenseReportPageWise,
  getExpenseReportCountData,
  resetAuditHistoryData,
  setFullFinalAllExpenseData,
} from "../redux/Expense/action";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import { useEffect } from "react";
import CameraApp from "./test";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container";
import LoadingScreen from "./loadingScreen";

const useStyles = makeStyles((theme) => ({
  addExpenseButton: {
    height: "42px",
    width: "180px",
    background: " #0B1941",
    boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    borderRadius: "5px",
    "&:hover": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
    "&:focus": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
  },
  buttonText: {
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 500,
  },
  addExpenseButton1: {
    height: "34px",
    width: "119px",
    background: " #0B1941",
    boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    borderRadius: "5px",
    "&:hover": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
    "&:focus": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
  },
  buttonText1: {
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Montserrat",
    fontSize: "10px",
    fontWeight: 500,
  },
}));
const AllExpenseReport = () => {
  const [formattedDownloadData, setFormattedDownloadData] = useState(null);
  const [fullFinalData, setFullFinalData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [firstIteration, setFirstIteration] = useState(true);
  const [finalIteration, setFinalIteration] = useState(false);
  const [fullData, setFullData] = useState([]);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width:600px)");

  //useSelectors start:
  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );
  const ssoEmpIdRedux = useSelector(
    (state) => state.createExpenseData.ssoEmpId
  );
  const delEmpIDRedux = useSelector(
    (state) => state.createExpenseData.delEmpId
  );
  const getDownloadAllExpensesReportData = useSelector(
    (state) => state.createExpenseData.getDownloadAllExpensesReport
  );
  const refreshModuleToggle = useSelector(
    (state) => state.createExpenseData.reloadModuleToggle
  );
  const totalPages = useSelector(
    (state) => state.createExpenseData.getExpenseReportCountData
  );
  const pageWiseData = useSelector(
    (state) => state.createExpenseData.getDownloadAllExpensesPageWise
  );
  const fullFinalAllExpenseRedux = useSelector(
    (state) => state.createExpenseData.setFullFinalAllExpenseData
  );
  // console.log("Pagination 1 totalPages:", totalPages);
  // console.log("Pagination 2 pageWiseData:", pageWiseData);

  // console.log("location.response 1 :", location?.state?.compName);

  //useSelectors end.
  //useEffects start:
  useEffect(() => {
    // console.log("allExpensesTest 1 - in useEffect()");
    let obj = {
      empId:
        window.screen.availWidth < 600 ? ssoEmpIdDetails?.empId : ssoEmpIdRedux,
    };
    dispatch(getExpenseReportCountData(obj));
    dispatch(getDownloadExpenseReportData(obj));
    dispatch(resetAuditHistoryData());
    if (window.performance && refreshModuleToggle == null) {
      //console.log("window.performance works fine on this browser RELOAD1");
      window.screen.availWidth > 600 && history.push("/expense-Management");
    }
  }, []);
  useEffect(() => {
    // console.log("firstIteration", firstIteration);
    if (
      totalPages != null &&
      totalPages != undefined &&
      firstIteration == true &&
      location?.state?.compName != "viewExpense"
      //fullFinalAllExpenseRedux == null
    ) {
      for (let i = 1; i <= totalPages?.pageSize; i++) {
        let obj2 = {
          empId:
            window.screen.availWidth < 600
              ? ssoEmpIdDetails?.empId
              : ssoEmpIdRedux,
          pageNo: i,
        };
        dispatch(getDownloadExpenseReportPageWise(obj2));
      }
      setFirstIteration(false);
    }
    if (
      fullFinalAllExpenseRedux != null &&
      location?.state?.compName == "viewExpense"
    ) {
      setLoading(false);
      setFullData(fullFinalAllExpenseRedux);
    }
  }, [totalPages, fullFinalAllExpenseRedux, location]);
  useEffect(() => {
    let recursiveData = JSON.parse(JSON.stringify(fullData));
    if (pageWiseData != null) {
      recursiveData = [...pageWiseData, ...recursiveData];
      setFullData(recursiveData);
    }
  }, [pageWiseData]);

  // console.log("Pagination4 fullData", fullData);
  // console.log("Pagination4 pageWiseData", pageWiseData);

  useEffect(() => {
    if (
      fullData.length === totalPages?.totalExpense &&
      fullData != null &&
      fullData != [] &&
      fullData != undefined
    ) {
      let tempCsvData = JSON.parse(JSON.stringify(fullData));

      tempCsvData?.map((element) => {
        element.createdOn = moment(element.createdOn).format("DD/MM/YYYY");
        if (
          element.financeApprovedOn != null &&
          element.financeApprovedOn != ""
        ) {
          element.financeApprovedOn = moment(element.financeApprovedOn).format(
            "DD/MM/YYYY"
          );
        }
        if (
          element.managerApprovedOn != null &&
          element.managerApprovedOn != ""
        ) {
          element.managerApprovedOn = moment(element.managerApprovedOn).format(
            "DD/MM/YYYY"
          );
        }
        if (element.vendor == null || element.vendor == "null") {
          element.vendor = "";
        }
        if (element.invoice == null || element.invoice == "null") {
          element.invoice = "";
        }
        if (element.gstNumber == null || element.gstNumber == "null") {
          element.gstNumber = "";
        }
        if (element.booksQty == null || element.booksQty == "null" || element.booksQty == 0) {
          element.booksQty = "";
        }
        return element;
      });
      //  console.log("tempCsvData", tempCsvData);
      setFormattedDownloadData(tempCsvData);
      setFullFinalData(fullData);
      dispatch(setFullFinalAllExpenseData(fullData));
      //console.log("loading is to be set to false now", loading);
      setTimeout(() => {
        setLoading(false);
      }, 50);
    }
  }, [fullData, totalPages]);
  //console.log("Pagination8 fullFinalData", fullFinalData);
  useEffect(() => {
    if (getDownloadAllExpensesReportData != null) {
      let tempCsvData = JSON.parse(
        JSON.stringify(getDownloadAllExpensesReportData)
      );
      //   getDownloadAllExpensesReportData.map((element) => ({
      //     createdOn: moment(element?.createdOn).format("DD/MM/YYYY"),
      //   }));
// console.log("tempCsvData",tempCsvData)
      tempCsvData?.map((element) => {
        element.createdOn = moment(element.createdOn).format("DD/MM/YYYY");
        if (
          element.financeApprovedOn != null &&
          element.financeApprovedOn != ""
        ) {
          element.financeApprovedOn = moment(element.financeApprovedOn).format(
            "DD/MM/YYYY"
          );
        }
        if (
          element.managerApprovedOn != null &&
          element.managerApprovedOn != ""
        ) {
          element.managerApprovedOn = moment(element.managerApprovedOn).format(
            "DD/MM/YYYY"
          );
        }
        if (element.vendor == null || element.vendor == "null") {
          element.vendor = "";
        }
        if (element.invoice == null || element.invoice == "null") {
          element.invoice = "";
        }
        if (element.gstNumber == null || element.gstNumber == "null") {
          element.gstNumber = "";
        }
        if (element.booksQty == null || element.booksQty == "null" || element.booksQty == 0) {
          element.booksQty = "";
        }
        return element;
      });
      // console.log("tempCsvData", tempCsvData);
      //setFormattedDownloadData(tempCsvData);
    }
  }, [getDownloadAllExpensesReportData]);
  // console.log("pagination4 formattedDownloadData", formattedDownloadData);
  useEffect(() => {
    return () => {
      //console.log("Pagination4 exitAllExpenseReport");
      dispatch(resetAuditHistoryData());
    };
  }, []);
  //useEffects end.
  const headers = [
    {
      label: "Expense Code",
      key: "expCode",
    },
    {
      label: "Purpose",
      key: "purpose",
    },
    {
      label: "Category Name",
      key: "categoryName",
    },
    {
      label: "To Location",
      key: "toLocation",
    },
    {
      label: "From Location",
      key: "fromLocation",
    },
    {
      label: "Distance (Km/Miles)",
      key: "distance",
    },
    {
      label: "From Date",
      key: "fromDate",
    },
    {
      label: "To Date",
      key: "toDate",
    },
    {
      label: "Claim Month",
      key: "forMonth",
    },
    {
      label: "Legal Entity",
      key: "empLegalEntity",
    },
    {
      label: "Employee ID",
      key: "empId",
    },
    {
      label: "Employee Name",
      key: "empName",
    },
    {
      label: "Vendor Name",
      key: "vendor",
    },
    {
      label: "Invoice Number",
      key: "invoice",
    },
    {
      label:"Relationship",
      key:"dependents",
    },
    {
      label: "GST Number",
      key: "gstNumber",
    },
    {
      label: "Books Quantity",
      key: "booksQty",
    },
    {
      label: "Currency",
      key: "currency",
    },
    {
      label: "Bill Amount",
      key: "billAmount",
    },
    {
      label: "Claimed Amount",
      key: "claimedAmount",
    },
    {
      label: "Approved Amount",
      key: "approvedAmount",
    },
    {
      label: "Expense Name",
      key: "description",
    },
    {
      label: "Invoice Date",
      key: "receiptDate",
    },
    {
      label: "Creation Date",
      key: "createdOn",
    },

    {
      label: "Expense Status",
      key: "status",
    },
    {
      label: "Manager Approval Date",
      key: "managerApprovedOn",
    },
    {
      label: "Approved By",
      key: "approvedBy",
    },
    {
      label: "Approved Date",
      key: "financeApprovedOn",
    },

    {
      label: "Project ID",
      key: "projectId",
    },
    {
      label: "Comments",
      key: "pendingWith",
    },
    {
      label: "Created By",
      key: "createdBy",
    },
  ];
  const csvLink = {
    headers: headers,
    data: formattedDownloadData || "",
    filename: `AllExpensesReport_${moment(new Date()).format(
      "DDMMYYYY_HHmm"
    )}.csv`,
  };
  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <Box>
            <Header
              heading={"All Expenses Report"}
              ssoEmpId={ssoEmpIdRedux ? ssoEmpIdRedux : null}
              disableAccOptions={1}
            />
          </Box>
          {!matches ? (
            <Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "25px 0px 25px 0px",
                }}
              >
                <Box
                  style={{
                    paddingLeft:
                      window.screen.availWidth == 1280
                        ? "10px"
                        : window.screen.availWidth == 1536
                        ? "30px"
                        : "10px",
                  }}
                >
                  <IconButton
                    style={{ color: "black" }}
                    onClick={() => history.push("/expense-Management")}
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                </Box>
                <Box
                  style={{
                    paddingRight:
                      window.screen.availWidth == 1280
                        ? "15px"
                        : window.screen.availWidth == 1536
                        ? "38px"
                        : "26px",
                  }}
                >
                  <CSVLink {...csvLink} style={{ color: "transparent" }}>
                    <Button className={classes.addExpenseButton}>
                      <Typography className={classes.buttonText}>
                        Download All
                      </Typography>
                    </Button>
                  </CSVLink>
                </Box>
              </Box>

              <Box>
                <AllExpensesTable
                  ssoEmpId={
                    window.screen.availWidth < 600
                      ? ssoEmpIdDetails?.empId
                      : ssoEmpIdRedux
                  }
                  fullData={fullData}
                />
                {/* <CameraApp /> */}
              </Box>
            </Box>
          ) : (
            <>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "25px",
                }}
              >
                <Box
                  style={{
                    marginTop: "30px",
                  }}
                >
                  <IconButton
                    style={{ color: "black", marginTop: "-30px" }}
                    onClick={() => history.push("/expense-Management")}
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                </Box>
                <Box
                  style={{
                    paddingRight:
                      window.screen.availWidth < 600
                        ? "1%"
                        : window.screen.availWidth < 415
                        ? "46%"
                        : window.screen.availWidth < 394
                        ? "1%"
                        : "",
                  }}
                >
                  <CSVLink {...csvLink} style={{ color: "transparent" }}>
                    <Button
                      className={classes.addExpenseButton1}
                      variant="contained"
                      color="primary"
                      style={{
                        marginLeft:
                          window.screen.availWidth < 600
                            ? ""
                            : window.screen.availWidth < 415
                            ? "46%"
                            : window.screen.availWidth < 394
                            ? ""
                            : "",
                      }}
                    >
                      <Typography className={classes.buttonText1}>
                        Download All
                      </Typography>
                    </Button>
                  </CSVLink>
                </Box>
              </Box>

              {/* <Container style={{ marginTop: "30px" }}>
                <IconButton
                  style={{ color: "black" }}
                  onClick={() => history.push("/expense-Management")}
                >
                  <ArrowBackIosIcon />
                </IconButton>
                <CSVLink {...csvLink} style={{ color: "transparent" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#0B1941",
                      marginLeft:
                        window.screen.availWidth < 600
                          ? "58%"
                          : window.screen.availWidth < 415
                          ? "46%"
                          : window.screen.availWidth < 394
                          ? "38%"
                          : "",
                    }}
                  >
                    Download All
                  </Button>
                </CSVLink>
              </Container> */}

              {/* <TableContainer component={Paper} sx={{ width: "max-content" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="right" style={{ padding: "0.4%" }}>
                    &nbsp;
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer> */}
              <AllExpensesTable
                style={{ marginTop: "30px" }}
                ssoEmpId={
                  window.screen.availWidth < 600
                    ? ssoEmpIdDetails?.empId
                    : ssoEmpIdRedux
                }
                fullData={fullData}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default AllExpenseReport;
