import axios from "axios";
import { useMsal, useAccount, useIsAuthenticated } from "@azure/msal-react";
import React from "react";
import { Redirect, Route } from "react-router";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { hslToRgb } from "@material-ui/core";

export const PrivateRoute = (props) => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  //console.log("isAuthenticated", isAuthenticated);
  const history = useHistory();
  // const location=useLocation()
  const account = useAccount(accounts[0] || {});
  const [auth, setAuth] = useState("");
  useEffect(() => {
    setAuth(account);
  });
  //  setTimeout(()=>{
  //     localStorage.removeItem("token");
  //     instance.logoutRedirect({
  //       postLogoutRedirectUri: "/",
  //     }); },60000)

  instance
    .handleRedirectPromise()
    .then((response) => {
      //console.log("in the button ", response);
      if (response !== null) {
        history.push("/");
      }
    })
    .catch((err) => {
      //console.error(err);
    });

  //console.log("checking he route", auth);
  const { isAuthUser, type, path } = props;
  if (!isAuthenticated) {
    history.push("/login");
  }
  // else if (type === "private" && auth=="") return <Redirect to="/login" />;
  return <Route {...props} />;
};
