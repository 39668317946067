import React, { useState, useEffect } from "react";
import GMNavBar from "./gmNavBar";
import Alert from "@material-ui/lab/Alert";
import MuiAlert from "@material-ui/lab/Alert";
import fileDownload from "js-file-download";

import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Toolbar,
  AppBar,
  makeStyles,
  TextField,
  Select,
  MenuItem,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Snackbar,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import GMStepper from "./gmStepper";
import Avatar from "@material-ui/core/Avatar";
import Decrypt from "../Utils/decryption";
import Encrypt from "../Utils/encryption";
import moment from "moment";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Chip from "@material-ui/core/Chip";
import "../css/gmBUPage.css";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  getBusinessUnitData,
  getEmployeeData,
  getSkillData,
  getVisaTypeData,
  postMobilityForm,
  postMobilityFormData,
  getViewDetailsData,
  getStatusData,
  getStepperData,
  stepperData,
  ispostGMStatusL1L2List,
  postGmStatusL1L2Data,
  viewDetailsData,
  statusData,
  // postGMStatusL1L2,
} from "../redux/GMCal/gmAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DialogActions from "@material-ui/core/DialogActions";
import { getEmployeeWiseData } from "./../redux/GMCal/gmAction";
import { timeout } from "q";
import { style } from "@material-ui/system";
import IconButton from "@mui/material/IconButton";
import GetAppIcon from "@mui/icons-material/GetApp";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import DescriptionIcon from "@mui/icons-material/Description";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";

var key = process.env.REACT_APP_ENCRYPTION_KEY;
const useStyles = makeStyles((theme) => ({
  successIcon: {
    color: theme.palette.success.main,
    fontSize: 40,
    minWidth: "100%",
  },
  dialogBody: {
    minHeight: "400px",
  },
  dialog: {
    backgroundColor: "#54B435",
    minHeight: "25px",
    padding: "12px",
    position: "fixed",
    top: "16px",
    right: "16px",
    borderRadius: "2px",
  },
  dialogAlert: {
    backgroundColor: "red",
    minHeight: "25px",
    padding: "12px",
    position: "fixed",
    top: "16px",
    right: "16px",
    borderRadius: "2px",
  },
  messageText: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    minWidth: "50px",
  },
  root: {
    backgroundColor: "#FFFFFF",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  purpose: {
    display: "flex",
    justifyContent: "center",
  },
  expenseHeading: {
    display: "inline-flex",
    margin: "30px 0px 30px 0px",
    height: "24px",
    maxWidth: "2000px",
    overflowWrap: "anywhere",
  },
  flexBox: {
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: "30px",
    paddingTop: "20px",
    width: "392px",
    height: "1100px",
  },
  mainBox: {
    background: "#FFFFFF",
    border: "1px solid #979797",
    borderRadius: "8px",
    justifyContent: "center",
    marginRight: "111px",
    marginLeft: "104px",
  },
  mainBox1: {
    background: "#FFFFFF",
    border: "1px solid #979797",
    borderRadius: "8px",
    justifyContent: "center",
    marginRight: "111px",
    marginLeft: "104px",
    marginBottom: "20px",
  },
  expenseId: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
    color: "#000000",
  },
  auditHistory: {
    fontFamily: "Montserrat",
    fontWeight: 800,
    fontSize: "14px",
    color: "#008CE6",
    paddingTop: "20px",
    textDecoration: "underline",
  },
  expenseIdBillable: {
    fontFamily: "Montserrat",
    fontWeight: 800,
    fontSize: "14px",
    color: "#000000",
  },
  projectId: {
    fontFamily: "Montserrat",
    fontWeight: 800,
    fontSize: "14px",
    color: "#000000",
    paddingTop: "14px",
  },
  textConditional: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "16px",
    color: "#008CE6",
  },
  textConditionalSplitAmount: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "16px",
    color: "#008CE6",
    paddingTop: "5px",
  },
  expenseDetail: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "15px",
    color: "#000000",
    paddingTop: "32px",
    paddingBottom: "12px",
    paddingLeft: "2px",
  },
  expenseIdHeading: {
    fontFamily: "Montserrat",
    fontWeight: 800,
    fontSize: "14px",
    color: " #77787F",
  },
  splitAmountHeading: {
    fontFamily: "Montserrat",
    fontWeight: 800,
    fontSize: "16px",
    color: "#000000",
    paddingTop: "12px",
  },
  attachmentHeading: {
    fontFamily: "Montserrat",
    fontWeight: 800,
    fontSize: "24px",
    color: "#000000",
    paddingTop: "12px",
  },
  projectInfo: {
    display: "flex",
    paddingTop: "31px",
  },
  expenseGrid: {
    borderRight: "1px solid #CCCCCC",
  },
  attachmentGrid: {},
  nOfAttachment: {
    fontFamily: "Montserrat",
    fontWeight: 800,
    fontSize: "10px",
    color: "#000000",
    paddingTop: "26px",
    paddingLeft: "8px",
  },
  tableCell: {
    "& .MuiTableCell-root": {
      borderBottom: "none",
      paddingTop: "0px",
      paddingLeft: "20px",
    },
    "& .MuiTableCell-alignLeft": {
      width: "177px",
    },
  },
  attendeesPaper: {
    minHeight: "300px",
  },
  papaerRoot: {
    background: "#FFFFFF",
    border: "1px solid #979797",
    borderRadius: "8px",
  },
  paperElivation: {
    boxShadow: "none",
  },
  statusHeading: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "8px",
    color: "rgba(255, 255, 255, 0.9)",
  },
  statusName: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "10px",
    marginRight: "2px",

    color: "rgba(255, 255, 255, 0.85)",
  },
  statusProjectName: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "8px",
    marginTop: "2px",
    color: "rgba(255, 255, 255, 0.9)",
    opacity: "0.6",
  },
  statusMessage: {
    fontFamily: "Montserrat",
    fontStyle: "italic",
    fontWeight: 400,
    fontSize: "8px",
    color: "#FFFFFF",
  },
  tooltip: {
    paddingLeft: "10px",
    color: "#9E9E9E",
    height: "20px",
    marginTop: "10px",
  },
  fileName: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "16px",
    color: "#4C4C4C",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "no-wrap",
    width: "450px",
  },
  fileName1: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "16px",
    color: "#4C4C4C",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "no-wrap",
    width: "337px",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "end ",
    marginRight: "60px",
  },
  removeFilter: {
    marginLeft: "24px",
    marginTop: "-56px",
  },
  applyFilter: {
    marginLeft: "24px",
    marginTop: "-56px",
  },
  applyFilterText: {
    background: "#0B1941",
    color: "red",
    borderRadius: "4px",
  },
  approvedConditional: {
    background: "#BDBDBD",
    color: "#FFFFFF",
    borderRadius: "4px",
    "&:hover": {
      background: "#BDBDBD",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
  },
  removeFilterText: {
    color: "#0B1941",
  },
  labelChip: {
    textOverflow: "inherit",
  },
}));

function GMDetailsPage() {
  const classes = useStyles();
  const [empId, setEmpId] = useState("");
  const [empCode, setEmpCode] = useState("");
  const [empName, setEmpName] = useState("");
  const [empBand, setEmpBand] = useState("");
  const [empDesignation, setEmpDesignation] = useState("");
  const [empSkillName, setEmpSkillName] = useState("");
  const [expertiseSkillName, setExpertiseSkillName] = useState("");
  const [empIndianSalary, setEmpIndianSalary] = useState("");
  const [empJoiningDate, setEmpJoiningDate] = useState("");
  const [empYearOfExp, setEmpYearOfExp] = useState("");
  const [empLatestPerformanceRating, setEmpLatestPerformance] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState();
  const [visaType, setVisaType] = useState("");
  const [visaId, setVisaTypeId] = useState("");
  const [numberOfDependents, setNumberOfDependents] = useState("");
  const [buId, setBUId] = useState(0);
  const [buName, setBUName] = useState("");
  const [clientName, setClientName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [bgvTypeName, setBgvTypeName] = useState("");
  const [stateName, setStateName] = useState("");
  const [cityName, setCityName] = useState("");
  const [status, setStatus] = useState("");
  const [workLocationName, setWorkLocationName] = useState("");
  const [expectedTravelDate, setExpectedTravelDate] = useState("");
  const [plannedBillingStartDate, setPlannedBilligStartDate] = useState("");
  const [recommendedSalaryRange, setRecommendedSalaryRange] = useState("");
  const [usciS_MinimumWage, setUscis_MinimumWage] = useState("");
  const [offeredCompensation, setOfferedCompensation] = useState("");
  const [fixed, setFixed] = useState("");
  const [variable, setVariable] = useState("");
  const [billRate, setBillRate] = useState("");
  const [firstYearGM, setFirstYearGM] = useState("");
  const [steadyStateGM, setSteadyStateGM] = useState("");
  const [error, setError] = useState(false);
  const [isApprovedDisabledval, setIsApprovedDisabledVal] = useState(false);
  const [isApproved, setisApproved] = useState(false);
  const [isRejected, setisRejected] = useState(false);
  const [isCommentEntered, setIsCommentEntered] = useState(false);
  const [rejectionComments, setRejectionComments] = useState("");
  const [approveComments, setApproveComments] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [snackbaarCondition, setSnackbaarCondition] = useState(false);
  const [comments, setComments] = React.useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarOpen1, setSnackbarOpen1] = useState(false);
  const [disableApprove, setDisableApprove] = useState(false);
  const [disableReject, setDisableReject] = useState(false);
  const [billingVisibility, setBillingVisibility] = useState();
  const [sowAvailable, setSOWAvailable] = useState("");
  const [expectedSignatureDate, setExpectedSignatureDate] = useState(null);
  const [conditionArray, setConditionArray] = useState([]);
  const [buCondition, setBuCondition] = useState(false);
  const [financeCondition, setFinanceCondition] = useState(false);
  const [chroCondition, setChroCondition] = useState(false);
  const [allCondition, setAllCondition] = useState(false);
  const [snackbarFailOpen, setSnackbarFailOpen] = useState(false);
  var count = 0;
  // var response ;
  const dispatch = useDispatch();
  const location = useLocation();
  // console.log("use this value", location.gmId);

  const history = useHistory();
  const dashboardDataDetails = useSelector((state) => state.gmCalData);
  const ssoEmpIdDetails = useSelector((state) => state.createExpenseData.getEmpDetailsData);
  const GMDelEmpIdRedux = useSelector((state) => state?.gmCalData?.GMDelEmpId);
  const postL1L2List = useSelector((state) => state?.gmCalData?.postGMStatusL1L2) || [];
  const isApprovedDisabled = useSelector((state) => state.gmCalData?.isApprovedDisabled);

  const handleName = (value) => {
    if (value) {
      const initArray = value.split(" ");
      let initial;
      if (initArray.length === 1) {
        initial = initArray[0][0];
      } else {
        initial = initArray[0][0];
      }
      return initial.toUpperCase();
    }
  };

  const viewDetailsLists = useSelector((state) => state?.gmCalData?.getViewDetailsList);
  const employeeWiseData = useSelector((state) => state?.gmCalData?.getEmployeeWiseData) || [];
  const statusData = useSelector((state) => state?.gmCalData?.getStatusData) || [];
  const gmStepper = useSelector((state) => state?.gmCalData?.getStepperData) || [];

  useEffect(() => {
    if (location?.gmId) {
      dispatch(getViewDetailsData(location?.gmId));
    } else {
      history.push("/gmdashboard");
    }
    dispatch(getStepperData(location?.gmId));
    dispatch(getEmployeeWiseData(ssoEmpIdDetails?.empId != GMDelEmpIdRedux ? GMDelEmpIdRedux : ssoEmpIdDetails?.empId));

  }, []);

  useEffect(() => {
    const buConditionArray = [];
    const financeConditionArray = [];
    const chroConditionArray = [];
    const allConditionArray = [];
  
    employeeWiseData.forEach((val) => {
      let buCondition = false;
      let financeCondition = false;
      let chroCondition = false;
      let allCondition = false;
  
      if (
        (val?.role === "BU Head" && val?.buId === viewDetailsLists?.buId) ||
        val?.role === "Super Admin"
      ) {
        buCondition = true;
      }
  
      if (val?.role === "Finance" || val?.role === "Super Admin") {
        financeCondition = true;
      }
  
      if (val?.role === "CHRO" || val?.role === "Super Admin") {
        chroCondition = true;
      }
  
      if (
        val?.role === "CHRO" ||
        val?.role === "Finance" ||
        val?.role === "Compensation & Benefit" ||
        val?.role === "BU Head" ||
        val?.role === "Super Admin" ||
        val?.role === "Mobility"
        ||val?.role === "Delivery Head"
      ) {
        allCondition = true;
      }
  
      buConditionArray.push(buCondition ? 1 : 0);
      financeConditionArray.push(financeCondition ? 1 : 0);
      chroConditionArray.push(chroCondition ? 1 : 0);
      allConditionArray.push(allCondition ? 1 : 0);
    });
  
    setBuCondition(buConditionArray.includes(1));
    setFinanceCondition(financeConditionArray.includes(1));
    setChroCondition(chroConditionArray.includes(1));
    setAllCondition(allConditionArray.includes(1));
  
    // const mergedConditionArray = [
    //   ...buConditionArray,
    //   ...financeConditionArray,
    //   ...chroConditionArray,
    //   ...allConditionArray,
    // ];
  
    // setConditionArray(mergedConditionArray);
  
    // console.log("inside useEffect condition array ", mergedConditionArray);
  }, [employeeWiseData, viewDetailsLists]);
  



  // useEffect(() => {
  //   console.log("inside useeffect");
  //   employeeWiseData.map((val) => {
  //     console.log("inside useeffect value", val);
  //     console.log("inside useeffect viewdetaillist", viewDetailsLists);
  //     if (
  //       (val?.role === "BU Head" && val?.buId == viewDetailsLists?.buId) ||
  //       val?.role === "Super Admin"
  //     ) {
  //       console.log("inside if 1");
  //       setBuCondition(true);
  //       setConditionArray((prevVal) => [...prevVal, 1]);
  //     } else {
  //       console.log("inside else 1");
  //       setBuCondition(false);

  //       setConditionArray((prevVal) => [...prevVal, 0]);
  //     }
  //     if (val?.role === "Finance" || val?.role === "Super Admin") {
  //       console.log("inside if 2");
  //       setFinanceCondition(true);
  //       setConditionArray((prevVal) => [...prevVal, 1]);
  //     } else {
  //       console.log("inside else2");
  //       setFinanceCondition(false);
  //       setConditionArray((prevVal) => [...prevVal, 0]);
  //     }
  //     if (val?.role === "CHRO" || val?.role === "Super Admin") {
  //       console.log("inside if 3");
  //       setChroCondition(true);
  //       setConditionArray((prevVal) => [...prevVal, 1]);
  //     } else {
  //       console.log("inside else3");
  //       setChroCondition(false);
  //       setConditionArray((prevVal) => [...prevVal, 0]);
  //     }
  //     if (
  //       val?.role === "CHRO" ||
  //       val?.role === "Finance" ||
  //       val?.role === "Compensation & Benefit" ||
  //       val?.role === "BU Head" ||
  //       val?.role === "Super Admin" ||
  //       val?.role === "Mobility"
  //     ) {
  //       setAllCondition(true);
  //       setConditionArray((prevVal) => [...prevVal, 1]);
  //     } else {
  //       setAllCondition(false);
  //       setConditionArray((prevVal) => [...prevVal, 0]);
  //     }
  //   });
  //   console.log("inside useeffect condition array ", conditionArray);
  // }, [employeeWiseData, viewDetailsLists]);
 
  
  const AWS = require("aws-sdk");

  const s3 = new AWS.S3({
    region: "ap-south-1",
  });
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  });

  /**
   * @param {string} key - Object path
   * @param {('getObject'|'putObject'|'deleteObject')} operation - HTTP Method
   * @returns {string} - A Temporar URL Endpoint
   */

  const handleDownload = (fileName) => {
    const s3 = new AWS.S3();
    const tempKey = `${fileName.toLowerCase()}`;
    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET_GM,
      Key: tempKey,
    };

    s3.getObject(params, (err, data) => {
      if (err) {
      } else {
        fileDownload(data.Body, fileName);
      }
    });
  };

  const handleComments = (data) => {
    setComments(data);
  };

  const handleRejectionComments = (value) => {
    setRejectionComments(value);
    handleComments(value);
  };

  const handleApproveComments = (value) => {
    setApproveComments(value);
    handleComments(value);
  };
  useEffect(() => {
    rejectionComments !== "" ? setBtnDisabled(false) : setBtnDisabled(true);
  }, [rejectionComments]);

  useEffect(() => {
    if (viewDetailsLists) {
      setBgvTypeName(viewDetailsLists?.bgvTypeName);
      setStatus(viewDetailsLists?.status);
      setEmpId(viewDetailsLists?.empId);
      setBillRate(viewDetailsLists?.billRate);
      setBUName(viewDetailsLists?.buName);
      setClientName(viewDetailsLists?.clientName);
      setProjectName(viewDetailsLists?.projectName);
      setEmpCode(viewDetailsLists?.empCode);
      setEmpName(viewDetailsLists?.empName);
      setEmpIndianSalary(viewDetailsLists?.empIndianSalary);
      setEmpBand(viewDetailsLists?.empBand);
      setEmpSkillName(viewDetailsLists?.empSkillName);
      setExpertiseSkillName(viewDetailsLists?.skillExpertiseName);
      setEmpDesignation(viewDetailsLists?.empDesignation);
      setEmpLatestPerformance(viewDetailsLists?.empLatestPerformanceRating);
      setEmpJoiningDate(viewDetailsLists?.empJoiningDate);
      setFirstYearGM(viewDetailsLists?.firstYearGM);
      setSteadyStateGM(viewDetailsLists?.steadyStateGM);
      setFixed(Decrypt(key, viewDetailsLists?.fixedOfferedComp));
      setVariable(Decrypt(key, viewDetailsLists?.variableOfferedComp));
      // setStateName(viewDetailsLists?.stateName);
      setCityName(viewDetailsLists?.cityName);
      setWorkLocationName(viewDetailsLists?.workLocationName);
      setCurrencySymbol(viewDetailsLists?.currencySymbol);
      setOfferedCompensation(
        Decrypt(key, viewDetailsLists?.offeredCompensation)
      );
      setVisaType(viewDetailsLists?.visaType);
      setEmpYearOfExp(viewDetailsLists?.empYearOfExp);
      setExpectedTravelDate(viewDetailsLists?.expectedTravelDate);
      setNumberOfDependents(viewDetailsLists?.numberOfDependents);
      setPlannedBilligStartDate(viewDetailsLists?.plannedBillingStartDate);
      setRecommendedSalaryRange(viewDetailsLists?.recommendedSalaryRange);
      setUscis_MinimumWage(viewDetailsLists?.usciS_MinimumWage);
      setSOWAvailable(viewDetailsLists?.sowAvailability);
      setExpectedSignatureDate(
        viewDetailsLists?.sowExpectedSignatureDate
          ? viewDetailsLists?.sowExpectedSignatureDate
          : null
      );
      setBillingVisibility(
        viewDetailsLists?.billingVisibility
          ? viewDetailsLists?.billingVisibility
          : ""
      );
    }
  }, [viewDetailsLists]);

  useEffect(() => {
    if (isApprovedDisabled) {
      setIsApprovedDisabledVal(isApprovedDisabled);
    }
  }, [isApprovedDisabled]);

  var decSalary = Decrypt(key, empIndianSalary);
  var decOffComp = Decrypt(key, offeredCompensation);
  var formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 0,
  });
  const handleSnackClose = () => {
    // setIsSubmitted(false);
    setSnackbarFailOpen(false);
    // setIsButtonDisabled(true);
    history.push("/gmdashboard");
  };
  const handleOpenApproveDialog = async (event) => {
    let formData = new FormData();
    formData.append("EmpId",Encrypt(key, JSON.stringify(GMDelEmpIdRedux ? GMDelEmpIdRedux : ssoEmpIdDetails?.empId)));
    formData.append("DelegateId", (ssoEmpIdDetails?.empId != GMDelEmpIdRedux) ? Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId)) : Encrypt(key, JSON.stringify(0)));
    formData.append("Status", Encrypt(key, JSON.stringify(3)));
    formData.append("GMId", Encrypt(key, JSON.stringify(location?.gmId)));
    formData.append("Comment", approveComments ? approveComments : "");
    {
      // console.log("TESTING5", status);
    }
    try {
      const response = await dispatch(postGmStatusL1L2Data(formData));

      if (response.status === 200) {
        // Successful response
        setSnackbarOpen(true);
      } else {
        setSnackbarFailOpen(true);

        //   <Snackbar open={snackbarOpen} onClose={() => handleSnackClose(false)}>
        //   <Alert
        //     onClose={() => handleSnackClose(false)}
        //     severity="error"
        //     sx={{ width: "100%", backgroundColor: "red", color: "white" }}
        //   >
        //     There is some problem with the application. Please connect with System
        //     Admin.
        //   </Alert>
        // </Snackbar>;
      }
    } catch {
      setSnackbarFailOpen(true);
    }
    // finally {
    //   console.log("not coming");
    //   setSnackbarFailOpen(true);
    // }
    //  response = dispatch(postGmStatusL1L2Data(formData));
    //   console.log("TESTING6", response.status, response);
  };

  const handleOpenRejectionDialog = async (event) => {
    // event.preventDefault();

    // console.log("Incedooooooooooo");
    let formData = new FormData();
    formData.append("EmpId",Encrypt(key, JSON.stringify((ssoEmpIdDetails?.empId != GMDelEmpIdRedux) ? GMDelEmpIdRedux : ssoEmpIdDetails?.empId)));
    formData.append("DelegateId", (ssoEmpIdDetails?.empId != GMDelEmpIdRedux) ? Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId)) : Encrypt(key, JSON.stringify(0)));
    formData.append("Status", Encrypt(key, JSON.stringify(4)));
    formData.append("GMId", Encrypt(key, JSON.stringify(location?.gmId)));
    formData.append("Comment", rejectionComments ? rejectionComments : "");
    {
      // console.log("TESTING5");
    }
    try {
      const response = await dispatch(postGmStatusL1L2Data(formData));

      if (response.status === 200) {
        // Successful response
        setSnackbarOpen1(true);
      } else {
        setSnackbarFailOpen(true);
      }
    } catch {
      setSnackbarFailOpen(true);
    }
    //  response = dispatch(postGmStatusL1L2Data(formData));
  };
  const handleReject = () => {
    setDisableReject(true);
    setTimeout(() => {
      history.push("/gmdashboard"); // Route to the gmdashboard location after 3 seconds
    }, 3000);
    // setSnackbarOpen1(true);
  };
  let snak = false;
  const handleApprove = () => {
    setDisableApprove(true);
    setTimeout(() => {
      history.push("/gmdashboard"); // Route to the gmdashboard location after 3 seconds
    }, 3000);
    // setSnackbarOpen(true);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const handleCancel = () => {
    // history.push("/detailviewpage");
    setisRejected(false);
  };
  const handleClose = (data) => {
    // Reset the state and close the pop-up
    if (isCommentEntered) {
      // Close the dialog or perform any desired action
      setisRejected(false);
      setTimeout(() => {
        history.push("/gmdashboard"); // Route to the gmdashboard location after 3 seconds
      }, 3000);
    }
    setisApproved(false);
    //snak = false;
    // setSnackbaarCondition(false);
  };
  return (
    <div className="body">
      <GMNavBar />
      <Box className={classes.root}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#FFFFFF",
          }}
        >
          {/* <Grid item xs={12}>
              {myExpenseList?.EmployeeDetails?.map((value) => {
                const colour = Math.random().toString(16).substr(-6);
                return (
                  <>
                    <Button
                      variant="contained"
                      style={{
                        borderRadius: "20px",
                        padding: "3px",
                        margin: "6px",
                      }}
                    >
                      <Avatar
                        style={{
                          backgroundColor: `#${colour}`,
                          color: "white",
                        }}
                      >
                        {handleName(value.EmpName)}
                      </Avatar>
                      {value.EmpName ? value.EmpName.split("(")[0] : ""}
                    </Button>
                  </>
                );
              })}
            </Grid> */}
          <Box
            style={{
              paddingRight: "20px",
              paddingTop: "5px",
            }}
            onClick={() => {
              history.push({
                pathname: "/gmdashboard",
                state: {
                  compName: "GMDashboard",
                },
              });
              // window.location.reload();
            }}
          >
            <ArrowBackIosIcon />
          </Box>
          <Box className={classes.expenseHeading}>
            <Typography
              style={{
                fontWeight: "600",
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontSize: "24px",
                lineHeight: "24px",
                letterSpacing: "0px",
                textAlign: "left",
                color: "#2B2D30",
                paddingBottom: "10px",
              }}
            >
              Detail View
            </Typography>
            <Chip
              label={viewDetailsLists?.status}
              classes={{ label: classes.labelChip }}
              style={{
                color: "white",
                width: "96px",
                height: "24px",
                marginLeft: "18px",
                // display: "block",
                // clear: "left",
                // float: "left",
                background:
                  viewDetailsLists?.status === "In Review"
                    ? "#E0B15C"
                    : viewDetailsLists?.status === "Approved"
                    ? " #349259"
                    : viewDetailsLists?.status === "Rejected"
                    ? "#D40606"
                    : viewDetailsLists?.status === "Initiated"
                    ? "#406086"
                    : "#9E9E9E",
              }}
            />
          </Box>
        </Box>
        <Snackbar
          open={snackbarFailOpen}
          autoHideDuration={300000}
          onClose={() => handleSnackClose()}
        >
          <Alert
            onClose={() => handleSnackClose()}
            severity="error"
            sx={{ width: "100%", backgroundColor: "red", color: "white" }}
          >
            There is some problem with the application. Please connect with
            System Admin.
          </Alert>
        </Snackbar>
        {viewDetailsLists?.statusId == 2 &&
        buCondition &&
        viewDetailsLists?.buApproverFlag == 0 ? (
          <Box className={classes.buttonBox}>
            <Typography variant="p" className={classes.removeFilter}>
              <Button
                variant="outlined"
                size="medium"
                disabled={viewDetailsLists?.buApproverFlag == 1 ? true : false}
                onClick={() => {
                  setisRejected(true);
                }}
                className={classes.removeFilterText}
              >
                Reject
              </Button>
            </Typography>
            <Snackbar
              open={snackbarOpen1}
              autoHideDuration={3000}
              onClose={() => setSnackbarOpen1(false)}
            >
              <Alert onClose={() => setSnackbarOpen1(false)} severity="error">
                Global Mobility Rejected Successfully!
              </Alert>
            </Snackbar>
            <>
              <div>
                <Dialog
                  open={isRejected}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    <Typography
                      style={{
                        display: "flex",

                        justifyContent: "left",
                        fontSize: "22px",
                        fontWeight: 500,
                        // paddingRight:"136px"
                      }}
                    >
                      Are you sure want to reject the request?
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <Box>
                      <TextField
                        margin="dense"
                        id="comments"
                        label="Add Comments"
                        variant="outlined"
                        multiline
                        inputProps={{
                          maxLength: 500,
                        }}
                        className={classes.normalText}
                        row={3}
                        fullWidth
                        onChange={(e) => {
                          handleRejectionComments(e.target.value);
                        }}
                      />
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="outlined"
                      style={{
                        minWidth: "120px",
                        minHeight: "36px",

                        backgroundColor: "#FFFFFF",
                        color: "#0B1941",
                      }}
                      disabled={disableReject ? true : false}
                      onClick={(e) => {
                        handleClose();
                        handleCancel();
                      }}
                      //disabled={!isCommentEntered}
                    >
                      Cancel
                    </Button>

                    <Button
                      style={{
                        minWidth: "120px",
                        minHeight: "36px",
                        marginRight: "15px",
                        backgroundColor: btnDisabled ? "#9E9E9E" : "#0B1941",
                        color: "#FFFFFF",
                      }}
                      disabled={btnDisabled ? true : false}
                      onClick={() => {
                        handleClose();
                        handleReject();
                        handleOpenRejectionDialog();
                      }}
                      // disabled={btnDisabled}
                      autoFocus
                    >
                      Reject
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </>

            <Typography variant="p" className={classes.applyFilter}>
              <Button
                variant="outlined"
                disabled={viewDetailsLists?.buApproverFlag == 1 ? true : false}
                onClick={() => {
                  // handleOpenApproveDialog();
                  setisApproved(true);
                }}
                size="medium"
                style={{
                  color: "#FFFFFF",
                  backgroundColor:
                    viewDetailsLists?.l1ApprovalFlag == 1
                      ? "#9e9e9e"
                      : "#0b1941",
                }}
              >
                Approve
              </Button>
            </Typography>

            <div>
              <Dialog
                open={isApproved}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  <Typography
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      fontSize: "22px",
                      fontWeight: 500,
                      // paddingRight:"136px"
                    }}
                  >
                    Are you sure want to approve the request?
                  </Typography>
                </DialogTitle>
                <DialogContent>
                  <Box>
                    <TextField
                      margin="dense"
                      id="comments"
                      label="Add Comments"
                      variant="outlined"
                      multiline
                      inputProps={{
                        maxLength: 500,
                      }}
                      className={classes.normalText}
                      row={3}
                      fullWidth
                      onChange={(e) => {
                        handleApproveComments(e.target.value);
                      }}
                    />
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="outlined"
                    style={{
                      minWidth: "120px",
                      minHeight: "36px",
                      backgroundColor: "#FFFFFF",
                      color: "#0B1941",
                    }}
                    disabled={disableApprove ? true : false}
                    onClick={(e) => {
                      handleClose();
                      handleCancel();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      minWidth: "120px",
                      minHeight: "36px",
                      marginRight: "15px",
                      backgroundColor: "#0B1941",
                      color: "#FFFFFF",
                    }}
                    disabled={disableApprove ? true : false}
                    onClick={() => {
                      handleApprove();
                      handleOpenApproveDialog();
                      snak = true;
                    }}
                    autoFocus
                  >
                    Approve
                  </Button>
                </DialogActions>
              </Dialog>
            </div>

            {/* {console.log("TESTING10", isApprovedDisabled)} */}
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={() => setSnackbarOpen(false)}
            >
              <Alert onClose={() => setSnackbarOpen(false)} severity="success">
                Global Mobility Approved Successfully!
              </Alert>
            </Snackbar>
          </Box>
        ) : (
          ""
        )}
        {viewDetailsLists?.statusId == 2 &&
        financeCondition &&
        viewDetailsLists?.buApproverFlag == 1 &&
        viewDetailsLists?.l1ApprovalFlag == 0 ? (
          <Box className={classes.buttonBox}>
            <Typography variant="p" className={classes.removeFilter}>
              <Button
                variant="outlined"
                size="medium"
                disabled={viewDetailsLists?.l1ApprovalFlag == 1 ? true : false}
                onClick={() => {
                  setisRejected(true);
                }}
                className={classes.removeFilterText}
              >
                Reject
              </Button>
            </Typography>
            <Snackbar
              open={snackbarOpen1}
              autoHideDuration={3000}
              onClose={() => setSnackbarOpen1(false)}
            >
              <Alert onClose={() => setSnackbarOpen1(false)} severity="error">
                Global Mobility Rejected Successfully!
              </Alert>
            </Snackbar>
            <>
              <div>
                <Dialog
                  open={isRejected}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    <Typography
                      style={{
                        display: "flex",

                        justifyContent: "left",
                        fontSize: "22px",
                        fontWeight: 500,
                        // paddingRight:"136px"
                      }}
                    >
                      Are you sure want to reject the request?
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <Box>
                      <TextField
                        margin="dense"
                        id="comments"
                        label="Add Comments"
                        variant="outlined"
                        multiline
                        inputProps={{
                          maxLength: 500,
                        }}
                        className={classes.normalText}
                        row={3}
                        fullWidth
                        onChange={(e) => {
                          handleRejectionComments(e.target.value);
                        }}
                      />
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="outlined"
                      style={{
                        minWidth: "120px",
                        minHeight: "36px",

                        backgroundColor: "#FFFFFF",
                        color: "#0B1941",
                      }}
                      disabled={disableReject ? true : false}
                      onClick={(e) => {
                        handleClose();
                        handleCancel();
                      }}
                      //disabled={!isCommentEntered}
                    >
                      Cancel
                    </Button>

                    <Button
                      style={{
                        minWidth: "120px",
                        minHeight: "36px",
                        marginRight: "15px",
                        backgroundColor: btnDisabled ? "#9E9E9E" : "#0B1941",
                        color: "#FFFFFF",
                      }}
                      disabled={btnDisabled ? true : false}
                      onClick={() => {
                        handleClose();
                        handleReject();
                        handleOpenRejectionDialog();
                      }}
                      // disabled={btnDisabled}
                      autoFocus
                    >
                      Reject
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </>

            <Typography variant="p" className={classes.applyFilter}>
              <Button
                variant="outlined"
                disabled={viewDetailsLists?.l1ApprovalFlag == 1 ? true : false}
                onClick={() => {
                  // handleOpenApproveDialog();
                  setisApproved(true);
                }}
                size="medium"
                style={{
                  color: "#FFFFFF",
                  backgroundColor:
                    viewDetailsLists?.l1ApprovalFlag == 1
                      ? "#9e9e9e"
                      : "#0b1941",
                }}
              >
                Approve
              </Button>
            </Typography>

            <div>
              <Dialog
                open={isApproved}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  <Typography
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      fontSize: "22px",
                      fontWeight: 500,
                      // paddingRight:"136px"
                    }}
                  >
                    Are you sure want to approve the request?
                  </Typography>
                </DialogTitle>
                <DialogContent>
                  <Box>
                    <TextField
                      margin="dense"
                      id="comments"
                      label="Add Comments"
                      variant="outlined"
                      multiline
                      inputProps={{
                        maxLength: 500,
                      }}
                      className={classes.normalText}
                      row={3}
                      fullWidth
                      onChange={(e) => {
                        handleApproveComments(e.target.value);
                      }}
                    />
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="outlined"
                    style={{
                      minWidth: "120px",
                      minHeight: "36px",
                      backgroundColor: "#FFFFFF",
                      color: "#0B1941",
                    }}
                    disabled={disableApprove ? true : false}
                    onClick={(e) => {
                      handleClose();
                      handleCancel();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      minWidth: "120px",
                      minHeight: "36px",
                      marginRight: "15px",
                      backgroundColor: "#0B1941",
                      color: "#FFFFFF",
                    }}
                    disabled={disableApprove ? true : false}
                    onClick={() => {
                      handleApprove();
                      handleOpenApproveDialog();
                      snak = true;
                    }}
                    autoFocus
                  >
                    Approve
                  </Button>
                </DialogActions>
              </Dialog>
            </div>

            {/* {console.log("TESTING10", isApprovedDisabled)} */}
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={() => setSnackbarOpen(false)}
            >
              <Alert onClose={() => setSnackbarOpen(false)} severity="success">
                Global Mobility Approved Successfully!
              </Alert>
            </Snackbar>
          </Box>
        ) : (
          ""
        )}
        {viewDetailsLists?.statusId == 2 &&
        chroCondition &&
        viewDetailsLists?.buApproverFlag == 1 &&
        viewDetailsLists?.l1ApprovalFlag == 1 &&
        viewDetailsLists?.l2ApprovalFlag == 0 ? (
          <Box className={classes.buttonBox}>
            <Typography variant="p" className={classes.removeFilter}>
              <Button
                variant="outlined"
                onClick={() => {
                  // handleOpenRejectionDialog();
                  setisRejected(true);
                }}
                size="medium"
                className={classes.removeFilterText}
              >
                Reject
              </Button>
            </Typography>
            {/* <Snackbar open={isRejected}>
              <Alert onClose={handleClose} severity="error">
                Global Mobility Rejected Successfully!
              </Alert>
            </Snackbar> */}
            <Snackbar
              open={snackbarOpen1}
              autoHideDuration={3000}
              onClose={() => setSnackbarOpen1(false)}
            >
              <Alert onClose={() => setSnackbarOpen1(false)} severity="error">
                Global Mobility Rejected Successfully!
              </Alert>
            </Snackbar>
            <>
              <div>
                <Dialog
                  open={isRejected}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    <Typography
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        fontSize: "22px",
                        fontWeight: 500,
                        // paddingRight:"136px"
                      }}
                    >
                      You are going to Reject this request.
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <Box>
                      <TextField
                        margin="dense"
                        id="comments"
                        label="Add Comments"
                        variant="outlined"
                        multiline
                        inputProps={{
                          maxLength: 500,
                        }}
                        className={classes.normalText}
                        row={3}
                        fullWidth
                        onChange={(e) => {
                          handleRejectionComments(e.target.value);
                        }}
                      />
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="outlined"
                      style={{
                        minWidth: "120px",
                        minHeight: "36px",
                        backgroundColor: "#FFFFFF",
                        color: "#0B1941",
                      }}
                      disabled={disableReject ? true : false}
                      onClick={(e) => {
                        handleClose();
                        handleCancel();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{
                        minWidth: "120px",
                        minHeight: "36px",
                        marginRight: "15px",
                        backgroundColor: btnDisabled ? "#9E9E9E" : "#0B1941",
                        color: "#FFFFFF",
                      }}
                      disabled={disableReject ? true : false}
                      onClick={() => {
                        setSnackbaarCondition(true);
                        handleOpenRejectionDialog();
                        handleReject();
                      }}
                      // disabled={btnDisabled}
                      autoFocus
                    >
                      Reject
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
              {/* {console.log("testing123", snackbaarCondition)} */}
            </>
            <Typography variant="p" className={classes.applyFilter}>
              <Button
                variant="outlined"
                onClick={() => {
                  setisApproved(true);
                }}
                size="medium"
                style={{
                  color: "#FFFFFF",
                  backgroundColor: "#0b1941",
                }}
              >
                Approve
              </Button>
            </Typography>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={() => setSnackbarOpen(false)}
            >
              <Alert onClose={() => setSnackbarOpen(false)} severity="success">
                Global Mobility Approved Successfully!
              </Alert>
            </Snackbar>
            <Dialog
              open={isApproved}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <Typography
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    fontSize: "22px",
                    fontWeight: 500,
                    // paddingRight:"136px"
                  }}
                >
                  Are you sure want to approve the request?
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Box>
                  <TextField
                    margin="dense"
                    id="comments"
                    label="Add Comments"
                    variant="outlined"
                    multiline
                    inputProps={{
                      maxLength: 500,
                    }}
                    className={classes.normalText}
                    row={3}
                    fullWidth
                    onChange={(e) => {
                      handleApproveComments(e.target.value);
                    }}
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  style={{
                    minWidth: "120px",
                    minHeight: "36px",
                    backgroundColor: "#FFFFFF",
                    color: "#0B1941",
                  }}
                  disabled={disableApprove ? true : false}
                  onClick={(e) => {
                    handleClose();
                    handleCancel();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    minWidth: "120px",
                    minHeight: "36px",
                    marginRight: "15px",
                    backgroundColor: "#0B1941",
                    color: "#FFFFFF",
                  }}
                  disabled={disableApprove ? true : false}
                  onClick={() => {
                    handleApprove();
                    handleOpenApproveDialog();
                  }}
                  autoFocus
                >
                  Approve
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        ) : (
          ""
        )}

        <Divider />
        <Grid
          item
          xs={12}
          style={{ color: "#979797", marginTop: "27px" }}
          className="stepper"
        >
          <GMStepper gmStepper={gmStepper} data={location.state} />
        </Grid>
        <Box className={classes.mainBox} style={{ marginTop: "14px" }}>
          <Grid container>
            <Grid item xs={6} md={6} className={classes.expenseGrid}>
              <TableContainer>
                <Table
                  aria-label="simple table"
                  classes={{ root: classes.tableCell }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseDetail}>
                          Employee Details
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"Employee ID:"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.expenseId}>
                          {empCode}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"Employee Name:"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.expenseId}>
                          {empName}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"Employee Grade:"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.expenseId}>
                          {empBand}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"Designation:"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.expenseId}>
                          {empDesignation}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"Employee L3 Skill:"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.expenseId}>
                          {empSkillName}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"Employee Role:"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.expenseId}>
                          {expertiseSkillName}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"Incedo Joining Date:"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.expenseId}>
                          {empJoiningDate &&
                            moment(empJoiningDate).format("MMM DD, yyyy")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"Year Of Experience:"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.expenseId}>
                          {empYearOfExp}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"Latest Performance Rating:"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.expenseId}>
                          {empLatestPerformanceRating}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item xs={6} md={6} className={classes.expenseGrid}>
              <TableContainer>
                <Table
                  aria-label="simple table"
                  classes={{ root: classes.tableCell }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseDetail}>
                          Visa Details
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"Visa Type:"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.expenseId}>
                          {visaType}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"No. of Dependents:"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.expenseId}>
                          {numberOfDependents}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseDetail}>
                          SOW Details
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"SOW Availibilty:"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          className={classes.expenseId}
                          style={{ textTransform: "capitalize" }}
                        >
                          {sowAvailable}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {sowAvailable === "no" && (
                      <>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography className={classes.expenseIdHeading}>
                              {"Expected Signature Date:"}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography className={classes.expenseId}>
                              {moment(expectedSignatureDate).format(
                                "MMM DD, YYYY"
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>{" "}
                      </>
                    )}
                    {sowAvailable === "yes" && (
                      <>
                        <TableRow style={{ verticalAlign: "baseline" }}>
                          <TableCell component="th" scope="row">
                            <Typography className={classes.expenseIdHeading}>
                              {"SOW Attachments:"}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ paddingRight: "7px", paddingTop: "2px" }}
                          >
                            {viewDetailsLists?.attachments?.map((item) => (
                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <IconButton
                                    aria-label="File"
                                    style={{ padding: "0px" }}
                                  >
                                    <InsertDriveFileIcon
                                      style={{ width: "18px", height: "18px" }}
                                    />
                                  </IconButton>
                                  <Box
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      fontSize: "12px",
                                      maxWidth: "120px",
                                      paddingLeft: "2px",
                                    }}
                                  >
                                    {item.fileName}
                                  </Box>
                                </Box>
                                <Box>
                                  <IconButton aria-label="Download">
                                    <GetAppOutlinedIcon
                                      onClick={() => {
                                        handleDownload(item.updatedFileName);
                                      }}
                                    />
                                  </IconButton>
                                </Box>
                              </Box>
                            ))}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.mainBox1} style={{ marginTop: "25px" }}>
          <Grid container>
            <Grid item xs={6} md={6} className={classes.expenseGrid}>
              <TableContainer>
                <Table
                  aria-label="simple table"
                  classes={{ root: classes.tableCell }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseDetail}>
                          Project Details
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"Destination Business Unit:"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.expenseId}>
                          {buName}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"Client:"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.expenseId}>
                          {clientName}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"Project Name:"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.expenseId}>
                          {projectName}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"Background Verification Required:"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.expenseId}>
                          {bgvTypeName}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {/* <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"Working At (State):"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.expenseId}>
                          {stateName}
                        </Typography>
                      </TableCell>
                    </TableRow> */}
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"Working In (City):"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.expenseId}>
                          {cityName}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"Work Location:"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.expenseId}>
                          {workLocationName}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"Expected Billing Start Date:"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.expenseId}>
                          {plannedBillingStartDate &&
                            moment(plannedBillingStartDate).format(
                              "MMM DD, yyyy"
                            )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"Expected Travel Date:"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.expenseId}>
                          {expectedTravelDate &&
                            moment(expectedTravelDate).format("MMM DD, yyyy")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"Billing Visibility (in months):"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.expenseId}>
                          {billingVisibility ? billingVisibility : ""}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item xs={6} md={6} className={classes.expenseGrid}>
              <TableContainer>
                <Table
                  aria-label="simple table"
                  classes={{ root: classes.tableCell }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseDetail}>
                          Salary & Billing Details
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"Recommended Salary Range:"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.expenseId}>
                          {recommendedSalaryRange
                            ? `${currencySymbol} ${recommendedSalaryRange
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                            : ""}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"USCIS Minimum Wage:"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.expenseId}>
                          {usciS_MinimumWage
                            ? `${currencySymbol} ${usciS_MinimumWage
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                            : ""}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {allCondition && (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography className={classes.expenseIdHeading}>
                            {"Offered Gross Compensation:"}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography className={classes.expenseId}>
                            {fixed
                              ? `${currencySymbol} ${offeredCompensation
                                  ?.toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                              : ""}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {allCondition && (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography className={classes.expenseIdHeading}>
                            {"Fixed Salary:"}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography className={classes.expenseId}>
                            {fixed
                              ? `${currencySymbol} ${fixed
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                              : ""}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {allCondition && (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography className={classes.expenseIdHeading}>
                            {"Variable Salary:"}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography className={classes.expenseId}>
                            {fixed && variable
                              ? `${currencySymbol} ${variable
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                              : fixed
                              ? `${currencySymbol} 0`
                              : ""}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"Bill Rate:"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.expenseId}>
                          {billRate
                            ? `${currencySymbol} ${billRate
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                            : ""}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"First Year Margin:"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.expenseId}>
                          <strong>
                            {" "}
                            {viewDetailsLists?.status == "Initiated"
                              ? ""
                              : parseFloat(firstYearGM).toFixed(1)}
                            {viewDetailsLists?.status !== "Initiated" &&
                            !isNaN(parseFloat(firstYearGM))
                              ? "%"
                              : ""}
                          </strong>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.expenseIdHeading}>
                          {"Steady State Margin:"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.expenseId}>
                          <strong>
                            {viewDetailsLists?.status == "Initiated"
                              ? ""
                              : parseFloat(steadyStateGM).toFixed(1)}
                            {viewDetailsLists?.status !== "Initiated" &&
                            !isNaN(parseFloat(steadyStateGM))
                              ? "%"
                              : ""}
                          </strong>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
}
export default GMDetailsPage;
