import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/header";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import LaunchIcon from "@material-ui/icons/Launch";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { format } from "date-fns";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { useHistory } from "react-router";
import { getDetailedView } from "../redux/TimeSheet/actionTs";
import { CSVLink } from "react-csv";
import moment from "moment";
import { useLocation } from "react-router";
import Encrypt from "../Utils/encryption";
import Snackbar from "@material-ui/core/Snackbar";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import ApproveRejectByManager from "./approveRejectByManager";
import {
  getMyTeamApprovalData,
  updateStatusByManagerData,
  getTimesheetReportAuditData,
  exportAsPdfData,
} from "../redux/TimeSheet/actionTs";
import AuditHistoryDialog from "./auditHistoryAllTimesheetReport";
import jsPDF from "jspdf";
import "jspdf-autotable";
const useStyles = makeStyles({
  table: {
    minWidth: "100%",
    "&.MuiContainer-maxWidthLg": {
      maxWidth: "1900px",
    },
  },
  colorCondition: {
    color: "#C4C4C4",
  },
  snackbarMessage: {
    color: "#333",
    fontFamily: "Montserrat",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "0.2px",
  },
  snackbarMessage1: {
    color: "#252733",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "0.2px",
    paddingLeft: "10px",
  },
  anchorStyle: {
    "&.MuiSnackbar-anchorOriginTopRight": {
      top: "51px",
      left: "auto",
      right: "52px",
    },
  },
  auditHistory: {
    fontFamily: "Montserrat",
    fontWeight: 800,
    fontSize: "14px",
    color: "#008CE6",
    paddingTop: "15px",
    textDecoration: "underline",
  },
});

function DetailedView() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  // const [rowsToShow, setRowsToShow] = useState(4); // Number of rows to show initially
  const [openAuditHistDialog, setOpenAuditHistDialog] = React.useState(false);
  const [auditHistoryData, setAuditHistoryData] = useState([]);
  const {
    empId,
    managerId,
    delegateeId,
    fromDate,
    toDate,
    projId,
    tmId,
    weekStatus,
    tab,
  } = location.state;
  const [open, setOpen] = useState(false);
  const [refreshTableToggle, setRefreshTableToggle] = useState(0);
  const [snackBarData, setSnackBarData] = useState({
    name: "",
    id: "",
  });
  const [snackBarDataFinal, setSnackBarDataFinal] = useState({
    name: "",
    id: "",
  });
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [firstAppRej, setFirstAppRej] = useState("");
  const [showApproveRejectDialog, setShowApproveRejectDialog] = useState(false);
  const [approveRejectStatus, setApproveRejectStatus] = useState("");
  const [approveRejectComment, setApproveRejectComment] = useState(null);
  const [isLoader, isSetLoader] = useState(true);
  const [tmid, setTmid] = useState("");
  // console.log("parameters", location.state,projId);

  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the default context menu behavior
  };

  const handleKeyDown = (e) => {
    if ((e.ctrlKey && e.shiftKey && e.key === "I") || e.key === "F12") {
      e.preventDefault(); // Prevent opening developer tools
    }
  };
  

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const formData = new FormData();
  const allViewList = useSelector(
    (state) => state.timesheetData.getDetailedViewLists
  );
  // console.log("check detail view", allViewList);

  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );

  const tsDelEmpIdRedux = useSelector(
    (state) => state?.timesheetData?.tsDelEmpId
  );
// console.log("tsDelEmpIdRedux detail",tsDelEmpIdRedux)
  const updatedStatusByManager = useSelector(
    (state) => state.timesheetData.isUpdateStatusByManager
  );

  var myTeamApproval = useSelector(
    (state) => state.timesheetData.getMyTeamsApproval
  );

  const auditHistoryList = useSelector(
    (state) => state.timesheetData.getAllTimesheetAudit
  );
  const outPutData = useSelector(
    (state) => state.timesheetData?.postExtractPDFData
  );
  // console.log("data of detail view", location?.state?.tmId);
  useEffect(() => {
    if (auditHistoryList != null) {
      setAuditHistoryData(auditHistoryList);
    }
  }, [auditHistoryList]);
  useEffect(() => {
    dispatch(getTimesheetReportAuditData(location?.state?.tmId));
    const tmIdObject = [{ tmid: location?.state?.tmId }];
    formData.append("tMId", JSON.stringify(tmIdObject));
    // console.log("checking many things together", formData,tmIdObject);
    dispatch(exportAsPdfData(formData));
  }, [location]);

  useEffect(() => {
    if (allViewList) {
      isSetLoader(false);
    }
  }, [allViewList]);

  useEffect(() => {
    if (tab === "teamTimesheet") {
      let obj = {
        empId: +empId,
        managerId: +managerId,
        delegateeId: +delegateeId,
        fromDate: fromDate,
        toDate: toDate,
        projId:projId,
      };
      dispatch(getDetailedView(obj));
    } else if (tab === "myTeamApproval") {
      let obj = {
        empId: +empId,
        managerId: +managerId,
        delegateeId: +delegateeId,
        fromDate: fromDate,
        toDate: toDate,
        projId:projId,
      };
      dispatch(getDetailedView(obj));
    } else if (tab === "allTimesheetReport") {
      let obj = {
        empId: +empId,
        managerId: +managerId,
        delegateeId: +delegateeId,
        fromDate: fromDate,
        toDate: toDate,
        projId:projId,
      };
      // console.log("object for allTimesheetReport", obj);
      dispatch(getDetailedView(obj));
    } else if (tab === "allTimesheetReport") {
      let obj = {
        empId: +empId,
        managerId: +managerId,
        delegateeId: +delegateeId,
        fromDate: fromDate,
        toDate: toDate,
        projId:projId,
      };
      // console.log("object for allTimesheetReport", obj);
      dispatch(getDetailedView(obj));
    }else if (tab === "myPMOApproval") {
      let obj = {
        empId: empId,
        managerId: managerId,
        delegateeId: delegateeId,
        fromDate: fromDate,
        toDate: toDate,
        projId:projId,
      };
      // console.log("object for allTimesheetReport", obj);
      dispatch(getDetailedView(obj));
    }
  }, []);
  useEffect(() => {
    if (tab === "teamTimesheet") {
      let obj = {
        empId: +empId,
        managerId: +managerId,
        delegateeId: +delegateeId,
        fromDate: fromDate ? fromDate : "",
        toDate: toDate ? toDate : "",
        projId:projId,
      };
      dispatch(getDetailedView(obj));
    } else if (tab === "myTeamApproval") {
      let obj = {
        empId: +empId,
        managerId: +managerId,
        delegateeId: +delegateeId,
        fromDate: fromDate,
        toDate: toDate,
        projId:projId,
      };
      dispatch(getDetailedView(obj));
    } else if (tab === "allTimesheetReport") {
      let obj = {
        empId: +empId,
        managerId: +managerId,
        delegateeId: +delegateeId,
        fromDate: fromDate ? fromDate : "",
        toDate: toDate ? toDate : "",
        projId:projId,
      };
      dispatch(getDetailedView(obj));
    } else if (tab === "allTimesheetReport") {
      let obj = {
        empId: +empId,
        managerId: +managerId,
        delegateeId: +delegateeId,
        fromDate: fromDate ? fromDate : "",
        toDate: toDate ? toDate : "",
        projId:projId,
      };
      dispatch(getDetailedView(obj));
    }else if (tab === "myPMOApproval") {
      let obj = {
        empId: empId,
        managerId: managerId,
        delegateeId: delegateeId,
        fromDate: fromDate,
        toDate: toDate,
        projId:projId,
      };
      // console.log("object for allTimesheetReport", obj);
      dispatch(getDetailedView(obj));
    }
  }, [refreshTableToggle, dispatch]);

  const handleApproveRejectByManager = async (value, status, comment) => {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    let formData = new FormData();
    const tmdetails = [
      {
        tmdetailrecid: value?.tmDetailUniqueId,
        tmid: value?.tmId,
      },
    ];
    formData.append(
      "ManagerEmpId",
      Encrypt(
        key,
        JSON.stringify(
          tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId
        )
      )
    );
    // formData.append(
    //   "delegateeId",
    //   Encrypt(key, JSON.stringify(tsDelEmpIdRedux ? tsDelEmpIdRedux :null))
    // );
    formData.append(
      "delegateeId",
      ssoEmpIdDetails?.empId === tsDelEmpIdRedux
        ? Encrypt(key, JSON.stringify(null))
        : Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
    formData.append("TmStatus", JSON.stringify(status));
    formData.append("Comment", JSON.stringify(comment));
    formData.append("TmDetails", JSON.stringify(tmdetails));
    try {
      setShowApproveRejectDialog(false);
      await dispatch(updateStatusByManagerData(formData));
      isSetLoader(false);

      setOpen(true);
      if (updatedStatusByManager) {
        myTeamApproval = myTeamApproval.filter(
          (item) =>
            item.timesheetDetailUniqueId != value?.timesheetDetailUniqueId
        );
      }
      setRefreshTableToggle(
        (prevRefreshTableToggle) => prevRefreshTableToggle + 1
      );
      setSnackBarDataFinal(snackBarData);
    } catch (error) {}
  };
  const handleApproveRejectDialog = (index, status) => {
    setCurrentIndex(index);
    setFirstAppRej(status);
    setShowApproveRejectDialog(true);
    //console.log("snackbar data" , snackBarData , allViewList)
    setSnackBarData({
      name: allViewList?.empDetail.empName,
      id: allViewList?.empDetail.empCode,
    });
  };

  const handleApproveRejectStatus = (stat) => {
    setApproveRejectStatus(stat);
  };

  const handleComment = (data) => {
    setApproveRejectComment(data);
  };

  const handleCloseApproveRejectDialog = () => {
    setShowApproveRejectDialog(false);
  };
  const handleSnackbar = () => {
    setOpen(true);
  };
  const handlecloseSnackbar = () => {
    setOpen(false);
  };
  const handleOpenAuditHistDialog = () => {
    // console.log("handleOpenAuditHistDialog");
    setOpenAuditHistDialog(true);
  };
  const handleCloseAuditHistDialog = () => {
    setOpenAuditHistDialog(false);
  };
  const executeExportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    const lengthLoopVal = outPutData ? outPutData?.length : "0";

    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    for (let i = 0; i <= lengthLoopVal - 1; i++) {
      const currentDate = new Date();

      const day = String(currentDate.getDate()).padStart(2, "0");
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const year = currentDate.getFullYear();

      const formattedDate = `${day}/${month}/${year}`;
      const formattedTime = currentDate.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });

      const TimesheetNumberVal =
        outPutData[i]?.TimesheetMasterData.TimesheetNumber;
      const TimesheetNameVal = outPutData[i]?.TimesheetMasterData.EmpName;
      const ApprovalStatusVal =
        outPutData[i]?.TimesheetMasterData.ApprovalStatus;
      const StartDateVal = outPutData[i]?.TimesheetMasterData.StartDate;
      const EndDateVal = outPutData[i]?.TimesheetMasterData.EndDate;
      const TotalHoursVal = outPutData[i]?.TimesheetMasterData.TotalHours;
      const TotalBillableHoursVal =
        outPutData[i]?.TimesheetMasterData.TotalBillableHours;
      const WorkWeekVal = outPutData[i]?.TimesheetMasterData.WorkWeek;
      const compName = outPutData[i]?.TimesheetMasterData.CompanyName;
      const day1Val = outPutData[i]?.TimesheetDetails[0]?.day1;
      const day2Val = outPutData[i]?.TimesheetDetails[0]?.day2;
      const day3Val = outPutData[i]?.TimesheetDetails[0]?.day3;
      const day4Val = outPutData[i]?.TimesheetDetails[0]?.day4;
      const day5Val = outPutData[i]?.TimesheetDetails[0]?.day5;
      const day6Val = outPutData[i]?.TimesheetDetails[0]?.day6;
      const day7Val = outPutData[i]?.TimesheetDetails[0]?.day7;
      const date1Val = outPutData[i]?.TimesheetDetails[0]?.date1;
      const date2Val = outPutData[i]?.TimesheetDetails[0]?.date2;
      const date3Val = outPutData[i]?.TimesheetDetails[0]?.date3;
      const date4Val = outPutData[i]?.TimesheetDetails[0]?.date4;
      const date5Val = outPutData[i]?.TimesheetDetails[0]?.date5;
      const date6Val = outPutData[i]?.TimesheetDetails[0]?.date6;
      const date7Val = outPutData[i]?.TimesheetDetails[0]?.date7;

      doc.setFont("helvetica", "bold");
      doc.setFontSize(19);
      doc.text("Timesheet sign-off report", 39, 40);

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(`${compName}`, 39, 60);

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(`Page ${i + 1} of ${lengthLoopVal}`, 750, 40);
      doc.text(`${formattedDate}`, 751, 60);
      doc.text(`${formattedTime}`, 758, 80);

      doc.text("Timesheet number", 39, 120);
      doc.text(`${TimesheetNumberVal}`, 180, 120);
      doc.text(`${TimesheetNameVal}`, 39, 140);
      doc.text("Approval status", 39, 160);
      doc.text(`${ApprovalStatusVal}`, 180, 160);

      doc.text("Start date", 380, 120);
      doc.text(`${StartDateVal}`, 540, 120);
      doc.text("End date", 380, 140);
      doc.text(`${EndDateVal}`, 540, 140);
      doc.text("Total hours", 380, 160);
      doc.text(`${TotalHoursVal.toFixed(2)}`, 540, 160);
      doc.text("Total billable hours", 380, 180);
      doc.text(`${TotalBillableHoursVal.toFixed(2)}`, 540, 180);

      doc.setFont("helvetica", "bold");
      doc.text(`Work week: ${WorkWeekVal}`, 39, 220);

      const headers = [
        [
          "Legal Entity",
          "Customer Name",
          "Project ID",
          "Project Name",
          "Project Manager",
          "Category",
          `${day1Val} ${date1Val}`,
          `${day2Val} ${date2Val}`,
          `${day3Val} ${date3Val}`,
          `${day4Val} ${date4Val}`,
          `${day5Val} ${date5Val}`,
          `${day6Val} ${date6Val}`,
          `${day7Val} ${date7Val}`,
          "Total",
        ],
      ];

      const data = outPutData?.map((obj) =>
        obj?.TimesheetDetails?.map((elt) => [
          elt.LegalEntity,
          elt.CustomerName,
          elt.ProjectID,
          elt.ProjectName,
          elt.ProjectManagerName,
          elt.Category,
          elt.dayhours1,
          elt.dayhours2,
          elt.dayhours3,
          elt.dayhours4,
          elt.dayhours5,
          elt.dayhours6,
          elt.dayhours7,
          elt.Total,
        ])
      );

      const dataVal = data[i];

      // const convertedArray = [].concat(...data[0]).map(item => (item === undefined ? '' : item));

      function formatData(data) {
        for (let i = 0; i < data?.length; i++) {
          for (let j = 0; j < data[i]?.length; j++) {
            if (typeof data[i][j] === "number") {
              if (data[i][j] % 1 !== 0) {
                data[i][j] = data[i][j].toFixed(2);
              } else {
                data[i][j] = data[i][j].toFixed(2);
              }
            }
          }
        }
        return data;
      }

      const formattedData = formatData(dataVal);

      let checkLength = data[i]?.length;
      let content = {
        startY: 250,
        head: headers,
        body: formattedData,
        theme: "plain",
        tableLineWidth: 1,
        headStyles: { fillColor: "#BDBDBD", textColor: "black" },
        didParseCell: function (data) {
          if (data.row.index === (checkLength ? checkLength : 1) - 1) {
            data.cell.styles.fontStyle = "bold";
            data.cell.styles.fillColor = "#EBECF0";
          }
        },
      };

      doc.autoTable(content);
      if (i < lengthLoopVal - 1) {
        doc.addPage("a4", "l");
      }
    }

    doc.save(
      `${moment(new Date()).format(
        "YYYY-MM-DD-HHmm"
      )}-Timesheet sign-off report.pdf`
    );
  };

  const empDetailsView = allViewList?.empDetail;
  const projDetailsView = allViewList?.projDetail;

  const headers = [
    {
      label: "Employee ID",
      key: "empCode",
    },
    {
      label: "Employee Name",
      key: "empName",
    },
    {
      label: "Employee Type",
      key: "employeeType",
    },
    {
      label: "Project Name",
      key: "projName",
    },
    {
      label: "Project ID",
      key: "projCode",
    },
    {
      label: "Date",
      key: "timesheetDate",
    },
    {
      label: "Billable Hours",
      key: "billableHours",
    },
    {
      label: "Non Billable Hours",
      key: "nonBillableHours",
    },
    {
      label: "Approved Hours",
      key: "approvedHours",
    },
    {
      label: "Unapproved Hours",
      key: "unapprovedHours",
    },
  ];

  const varVals = { ...allViewList?.empDetail };
  const varArrVal = allViewList?.projDetail;
  const combineDetailsView = projDetailsView?.map((obj) => ({
    ...empDetailsView,
    ...obj,
  }));
  const fromDateReplace = allViewList?.empDetail?.fromDate.replace(/ /g, "_");
  const fromDateReplaceComma = fromDateReplace?.replace(/,/g, "_");
  const toDateReplace = allViewList?.empDetail?.toDate.replace(/ /g, "_");
  const toDateReplaceComma = toDateReplace?.replace(/,/g, "_");
  const dateFormCSV = fromDateReplaceComma?.concat(toDateReplaceComma);

  const csvLink = {
    headers: headers,
    data:
      JSON.parse(
        JSON.stringify(
          combineDetailsView
            ? combineDetailsView
            : varVals
            ? [varVals]
            : varArrVal
            ? varArrVal
            : ""
        )
      ) || "",
    filename: `DetailedView_${dateFormCSV}_${moment(new Date()).format(
      "DDMMYYYY_HHmm"
    )}.csv`,
  };
  // const handleScroll = (e) => {
  //   console.log("handleScroll tiggered");
  //   const scrollContainer = e.target;
  //   const scrolledDistance = scrollContainer.scrollTop;
  //   const totalHeight = scrollContainer.scrollHeight;
  //   const containerHeight = scrollContainer.clientHeight;
  //   console.log("scrollTop", scrolledDistance);
  //   console.log("scrollHeight", totalHeight);
  //   console.log("innerHeight", containerHeight);

  //   if (scrolledDistance + containerHeight >= totalHeight - 200) {
  //     // const target = e.target;
  //     // if (target.scrollHeight - target.scrollTop === target.clientHeight) {
  //     // Load more rows
  //     setRowsToShow((prevRows) => prevRows + 5); // Increase rowsToShow by 10 (or any desired number)
  //   }
  // };
  return (
    <div onContextMenu={handleContextMenu}>
      <Header
        heading={""}
        ssoEmpId={ssoEmpIdDetails?.empId}
        disableAccOptions={1}
      />
      <Container className={classes.table}>
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={handlecloseSnackbar}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          className={classes.anchorStyle}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{
              background: "#FEF0F3",
            }}
          >
            <Grid style={{ height: "67px", width: "300px" }}>
              <div style={{ display: "flex" }}>
                <Grid
                  style={{
                    marginLeft: "43px",
                    paddingTop: "9px",
                    marginRight: "90px",
                  }}
                  className={classes.snackbarMessage}
                >
                  {" "}
                  {"Timesheet Rejected"}
                </Grid>
                <Grid style={{ marginleft: "90px", paddingTop: "5px" }}>
                  <CloseOutlinedIcon onClick={handlecloseSnackbar} />
                </Grid>
              </div>
              <div style={{ display: "flex" }}>
                <Grid style={{ marginLeft: "10px", marginTop: "-25px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M15 2L20 7M5 22H19C19.2652 22 19.5196 21.8946 19.7071 21.7071C19.8946 21.5196 20 21.2652 20 21V7H15V2H5C4.73478 2 4.48043 2.10536 4.29289 2.29289C4.10536 2.48043 4 2.73478 4 3V21C4 21.2652 4.10536 21.5196 4.29289 21.7071C4.48043 21.8946 4.73478 22 5 22Z"
                      stroke="#D50606"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9 11L15 17"
                      stroke="#D50606"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15 11L9 17"
                      stroke="#D50606"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Grid>
                {/* {console.log("check snackbar data" , snackBarDataFinal)} */}
                <Grid className={classes.snackbarMessage1}>
                  {snackBarData.name} {" | "}
                  ID : {snackBarData.id}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Snackbar>

        <Box style={{ paddingTop: "15px" }}>
          <Box>
            <Grid container spacing={2}>
              {/* <Grid item xs={3}>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Box>
                    <ArrowBackIosIcon
                      onClick={() =>
                        history.push(
                          tab === "allTimesheetReport"
                            ? "/allTimesheetReports"
                            : "/timesheet-management"
                        )
                      }
                      style={{ cursor: "pointer" }}
                    />
                  </Box>
                  <Box style={{ color: "#081F32" }}>
                    <b>Detail View</b>
                  </Box>
                </Box>
              </Grid> */}
              {tab === "allTimesheetReport" ? (
                <Grid container justify="space-between">
                  <Grid item>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Box>
                        <ArrowBackIosIcon
                          onClick={() =>
                            history.push(
                              tab === "allTimesheetReport"
                                ? "/allTimesheetReports"
                                : "/timesheet-management"
                            )
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </Box>
                      <Box style={{ color: "#081F32" }}>
                        <b>Detail View</b>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={2} style={{marginRight:"2px"}}>
                      <Grid item>
                        <Typography
                          className={classes.auditHistory}
                          onClick={handleOpenAuditHistDialog}
                        >
                          Audit History
                        </Typography>
                        <AuditHistoryDialog
                          open={openAuditHistDialog}
                          handleClose={handleCloseAuditHistDialog}
                          auditHistoryData={auditHistoryData}
                        />
                      </Grid>
                      {/* <Grid item>
                        <Button
                          variant="outlined"
                          style={{
                            textTransform: "unset",
                            color: "#0B1941",
                            border: "1px solid #0B1941",
                          }}
                          onClick={executeExportPDF}
                        >
                          <LaunchIcon style={{ height: "17px" }} />
                          &nbsp;Export as Pdf
                        </Button>
                      </Grid> */}
                      <Grid item>
                        <Button
                          variant="outlined"
                          style={{
                            textTransform: "unset",
                            color:
                              location?.state?.weekStatus === "Approved"
                                ? "#0B1941"
                                : "#808080", // Use #808080 for grey
                            border: "1px solid",
                            borderColor:
                              location?.state?.weekStatus === "Approved"
                                ? "#0B1941"
                                : "#808080",
                            cursor:
                              location?.state?.weekStatus === "Approved"
                                ? "pointer"
                                : "not-allowed",
                          }}
                          onClick={
                            location?.state?.weekStatus === "Approved"
                              ? executeExportPDF
                              : null
                          }
                          disabled={location?.state?.weekStatus !== "Approved"}
                        >
                          <LaunchIcon style={{ height: "17px" }} />
                          &nbsp;Export as Pdf
                        </Button>
                      </Grid>

                      <Grid item>
                        <CSVLink {...csvLink} style={{ color: "transparent" }}>
                          <Button
                            variant="outlined"
                            style={{
                              textTransform: "unset",
                              color: "#0B1941",
                              border: "1px solid #0B1941",
                            }}
                          >
                            <LaunchIcon style={{ height: "17px" }} />
                            &nbsp;Export Timesheet
                          </Button>
                        </CSVLink>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Grid item xs={6}>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Box>
                        <ArrowBackIosIcon
                          onClick={() =>
                            history.push("/timesheet-management",{loc:"detailview"})
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </Box>
                      <Box style={{ color: "#081F32" }}>
                        <b>Detail View</b>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6} align="right">
                    <CSVLink {...csvLink} style={{ color: "transparent" }}>
                      <Button
                        variant="outlined"
                        style={{
                          textTransform: "unset",
                          color: "#0B1941",
                          border: "1px solid #0B1941",
                        }}
                      >
                        <LaunchIcon style={{ height: "17px" }} />
                        &nbsp;Export Timesheet
                      </Button>
                    </CSVLink>
                  </Grid>
                </>
              )}

              {/* <Grid item xs={3} align="right">
                <CSVLink {...csvLink} style={{ color: "transparent" }}>
                  <Button
                    variant="outlined"
                    style={{
                      textTransform: "unset",
                      color: "#0B1941",
                      border: "1px solid #0B1941",
                    }}
                  >
                    <LaunchIcon style={{ height: "17px" }} />
                    &nbsp;Export Timesheet
                  </Button>
                </CSVLink>
              </Grid> */}

              <Grid item xs={12}>
                <Box
                  style={{
                    backgroundColor: "#E3E8F7",
                    padding: "10px",
                    paddingLeft: "25px",
                    paddingLeft:
                      window.screen.availWidth == 1920
                        ? "1%"
                        : window.screen.availWidth == 1536
                        ? "1%"
                        : window.screen.availWidth == 1098
                        ? "2%"
                        : "1%",
                  }}
                >
                  <small>
                    <b>Total Hours&nbsp;:&nbsp;</b>
                  </small>
                  <b style={{ fontSize: "21px" }}>
                    {empDetailsView?.totalHours}&nbsp;|&nbsp;
                    {empDetailsView?.fromDate} - {empDetailsView?.toDate}{" "}
                  </b>
                </Box>
              </Grid>
            </Grid>
            <Box style={{ paddingTop: "10px" }}>
              <Grid container>
                <Grid item xs={4}>
                  <Box
                    style={{
                      backgroundColor: "#DCDFE9",
                      padding: "24px",
                      color: "#424242",
                      paddingLeft:
                        window.screen.availWidth == 1920
                          ? "3%"
                          : window.screen.availWidth == 1536
                          ? "3%"
                          : window.screen.availWidth == 1098
                          ? "5%"
                          : "4%",
                      minHeight: "45%",
                    }}
                  >
                    <b>Employee ID:</b> {empDetailsView?.empCode}
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    style={{
                      backgroundColor: "#DCDFE9",
                      padding: "24px",
                      color: "#424242",
                      paddingLeft:
                        window.screen.availWidth == 1920
                          ? "30%"
                          : window.screen.availWidth == 1536
                          ? "20%"
                          : window.screen.availWidth == 1098
                          ? "6%"
                          : "4%",
                      minHeight: "45%",
                    }}
                  >
                    <b>Employee Name:</b> {empDetailsView?.empName}
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    style={{
                      backgroundColor: "#DCDFE9",
                      padding: "24px",
                      color: "#424242",
                      paddingLeft:
                        window.screen.availWidth == 1920
                          ? "36%"
                          : window.screen.availWidth == 1536
                          ? "31%"
                          : window.screen.availWidth == 1098
                          ? "12%"
                          : "16%",
                      minHeight: "45%",
                    }}
                  >
                    <b>Employee Type:</b> {empDetailsView?.employeeType}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <TableContainer
          component={Paper}
          // className="scroll-container"
          // style={{
          //   height: "320px",
          //   // overflowY: "scroll",
          //   // overflowX: "clip",
          //   // width: "100%",
          //   overflowY: "auto",
          //   maxHeight: "calc(100vh - 20px)",
          // }}
          //onScroll={handleScroll}
        >
          <Table className={classes.table} aria-label="simple table">
            <TableHead style={{ backgroundColor: "#E3E8F7" }}>
              <TableRow>
                <TableCell style={{ width: "10%" }}>
                  <b>Date</b>
                </TableCell>
                <TableCell style={{ width: "20%" }}>
                  <b>Project Name & ID</b>
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    width: "11%",
                    paddingRight:
                      window.screen.availWidth == 1920
                        ? "1%"
                        : window.screen.availWidth == 1536
                        ? "1%"
                        : window.screen.availWidth == 1098
                        ? "2%"
                        : "-1%",
                  }}
                >
                  <b>Billable Hours</b>
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    width: "14%",
                    paddingRight:
                      window.screen.availWidth == 1920
                        ? "1%"
                        : window.screen.availWidth == 1536
                        ? "1%"
                        : window.screen.availWidth == 1098
                        ? "2%"
                        : "-1%",
                  }}
                >
                  <b>Non Billable Hours</b>
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    width: "13%",
                    paddingRight:
                      window.screen.availWidth == 1920
                        ? "1%"
                        : window.screen.availWidth == 1536
                        ? "1%"
                        : window.screen.availWidth == 1098
                        ? "2%"
                        : "-1%",
                  }}
                >
                  <b>Approved Hours</b>
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    width: "14%",
                    paddingRight:
                      window.screen.availWidth == 1920
                        ? "1%"
                        : window.screen.availWidth == 1536
                        ? "1%"
                        : window.screen.availWidth == 1098
                        ? "2%"
                        : "-1%",
                  }}
                >
                  <b>Unapproved Hours</b>
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    width: "13%",
                    paddingLeft:
                      window.screen.availWidth == 1920
                        ? "3%"
                        : window.screen.availWidth == 1536
                        ? "3%"
                        : window.screen.availWidth == 1098
                        ? "2%"
                        : "1%",
                  }}
                >
                  <b>Status</b>
                </TableCell>
                {tab === "allTimesheetReport" || tab === "myPMOApproval" ? (
                  <TableCell></TableCell>
                ) : (
                  <TableCell align="left" style={{ width: "11%" }}>
                    <b>Reject</b>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {projDetailsView?.map((row, index) => (
                <TableRow
                  key={index}
                  style={
                    index % 2
                      ? { backgroundColor: "#F4F4F4" }
                      : { backgroundColor: "#FFFFFF" }
                  }
                >
                  <TableCell
                    className={
                      row.sameMangerBit == 0 ? classes.colorCondition : ""
                    }
                  >
                    {row?.tmDate}
                    <br /> <small>{row?.dayType}</small>
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ paddingLeft: "25px" }}
                    className={
                      row.sameMangerBit == 0 ? classes.colorCondition : ""
                    }
                  >
                    {row?.projName} <br /> <small>{row?.projCode}</small>
                    <br />
                    {row?.projPurpose == null || row?.projPurpose == "" ? (
                      ""
                    ) : (
                      <small
                        style={{
                          backgroundColor: "#E3E8F7",
                          width: "134px",
                          //padding: "2px",
                          height: "14px",
                          fontSize: "10px",
                          //paddingLeft: "22px",
                          padding: " 1px 2px 2px 4px",
                          marginBottom: "8px",
                        }}
                      >
                        {row?.projPurpose}
                      </small>
                    )}
                  </TableCell>
                  {/* {console.log("hjhj", row)} */}
                  <TableCell
                    align="right"
                    className={
                      row.sameMangerBit == 0 ? classes.colorCondition : ""
                    }
                  >
                    {row?.billableHours}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={
                      row.sameMangerBit == 0 ? classes.colorCondition : ""
                    }
                  >
                    {row?.nonBillableHours}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={
                      row.sameMangerBit == 0 ? classes.colorCondition : ""
                    }
                  >
                    {row?.approvedHours}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={
                      row.sameMangerBit == 0 ? classes.colorCondition : ""
                    }
                  >
                    {row?.unapprovedHours}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      paddingLeft:
                        window.screen.availWidth == 1920
                          ? "3%"
                          : window.screen.availWidth == 1536
                          ? "3%"
                          : window.screen.availWidth == 1098
                          ? "2%"
                          : "1%",
                    }}
                    className={
                      row.sameMangerBit == 0 ? classes.colorCondition : ""
                    }
                  >
                    {row?.status.split("|").map((part, partIndex) => (
                      <div className="status-part" key={partIndex}>
                        {part}
                      </div>
                    ))}
                  </TableCell>
                  {tab === "allTimesheetReport" ? (
                    <TableCell></TableCell>
                  ) : (
                    <TableCell
                      align="center"
                      style={{
                        paddingLeft:
                          window.screen.availWidth == 1920
                            ? "3%"
                            : window.screen.availWidth == 1536
                            ? "3%"
                            : window.screen.availWidth == 1098
                            ? "2%"
                            : "1%",
                        color: "#e02020",
                      }}
                      className={classes.iconStyle}
                    >
                      {row.rejectBTEnabledFlag == 1 ? (
                        <CancelOutlinedIcon
                          onClick={() =>
                            handleApproveRejectDialog(index, "Rejected")
                          }
                        />
                      ) : (
                        ""
                      )}
                    </TableCell>
                  )}

                  {index == currentIndex ? (
                    <ApproveRejectByManager
                      row={row}
                      loader={isSetLoader}
                      handleApproveRejectByManager={
                        handleApproveRejectByManager
                      }
                      handleComment={handleComment}
                      firstAppRej={firstAppRej}
                      handleApproveRejectStatus={handleApproveRejectStatus}
                      showApproveRejectDialog={showApproveRejectDialog}
                      handleCloseApproveRejectDialog={
                        handleCloseApproveRejectDialog
                      }
                    />
                  ) : (
                    <></>
                  )}
                </TableRow>
              ))}
              {projDetailsView && projDetailsView?.length == 0 && (
                <TableRow className={classes.tableRow}>
                  <TableCell
                    colSpan={12}
                    style={{ minWidth: "120px", paddingLeft: "530px" }}
                  >
                    No Data Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <div>
        <Backdrop
          sx={{ color: "#092FED", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </div>
  );
}

export default DetailedView;