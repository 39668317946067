import { Route, Router, Switch } from "react-router-dom";
import React, { lazy, Suspense } from "react";
import { Provider } from "react-redux";
import history from "./history";
import store from "./redux/store";
import Login from "./pages/login";
import MobileLogin from "./pages/mobileLogin";
import Dashboard from "./pages/Dashboard/dashboard";
import HomePage from "./pages/Home/homePage";
import CreateExpenseManagement from "./components/createExpense";
import { createTheme, ThemeProvider } from "@material-ui/core";
import ViewExpenseDetails from "./components/viewExpense";
import EditExpense from "./components/editExpense";
import EditTravelExpense from "./components/editTravelExpense";
import DelegationTable from "./components/delegationTable";
import DelegationTableTs from "./TimesheetComponents/delegationTableTs";
import DelegationTableGM from "./components/delegationTableGM.js"
import FilterComponent from "./components/filterComponent.js";
import AllExpenseReport from "./components/allExpenseReport.js";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import MobileViewDashboard from "./components/mobileViewDashboard";
import MobileViewTable from "./components/mobileViewTable";
import MobileViewDeligationTable from "./components/mobileViewdeligationTable";
import MobileViewDeligationTableTs from "./TimesheetComponents/responsiveDelegationTableTs";
import MobiliViewDeligationTableGM from "./components/mobileViewDeligationTableGM.js";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { PrivateRoute } from "./components/verification";
import ScrollToTop from "./components/scrolltotop";
import GMDashboard from "./components/gmCalDashboard";
import GMBUApproval from "./components/gmBUPage";
import GMMobility from "./components/gmMobilityPage";
import GMDetailsPage from "./components/gmDetailsPage";
import GMBUPage from "./components/gmBUPage";
import DashboardTs from "./pages/Dashboard/dashboardTs";
import DetailedView from "./TimesheetComponents/detailedView";
import MobileViewDashboardTs from "./pages/Dashboard/mobileViewDashboardTs";
import CreateTimesheet from "./TimesheetComponents/createTimesheet";
import resCreateTimesheet from "./TimesheetComponents/responsiveCreateTimesheet";
import CircularProgress from "@mui/material/CircularProgress";
import ResponsiveDetailedView from "./TimesheetComponents/responsiveDetailedView";
import TimesheetReport from "./TimesheetComponents/timesheetReport";
// const LazyDashboardTs = lazy(() => import('./pages/Dashboard/dashboardTs'));
// const LazyMobileViewDashboardTs = lazy(() => import('./pages/Dashboard/mobileViewDashboardTs'));
const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat", "sans-serif"],
  },
});

function App() {
  const matches = useMediaQuery("(max-width:600px)");
  return (
    <Provider store={store}>
      <div className="App">
        <Router history={history}>
          <Switch>
            <ThemeProvider theme={theme}>
              <AuthenticatedTemplate>
                <PrivateRoute exact path="/expense-Management" type="private">
                  {matches ? <MobileViewDashboard /> : <Dashboard />}
                  {/* {matches ? <FilterComponent /> : <Dashboard />} */}
                  {/* <Dashboard /> */}
                </PrivateRoute>
                <ScrollToTop />
                {/* <PrivateRoute exact path="/timesheet-management" type="private">
                  {matches ? <MobileViewDashboardTs /> : <DashboardTs />}
                </PrivateRoute> */}
                <Route
                  exact
                  path="/timesheet-management"
                  // component={
                  //   matches ? (
                  //     <MobileViewDashboardTs />
                  //   ) : (
                  //     <DashboardTs module="timesheetManagement" />
                  //   )
                  // }
                  // component={matches ? MobileViewDashboardTs : DashboardTs}
                >
                  {matches ? (
                    <MobileViewDashboardTs />
                  ) : (
                    <DashboardTs module="timesheetManagement" />
                  )}
                </Route>

                <Route exact path="/timesheetviewpage/:id">
                  {matches ? (
                    <ResponsiveDetailedView />
                  ) : (
                    <DetailedView module="timesheetManagement" />
                  )}
                </Route>
                <Route
                  exact
                  path="/allTimesheetReports"
                  component={TimesheetReport}
                />

                <Route exact path="/gmdashboard" component={Dashboard} />

                <Route exact path="/gmmobility" component={GMMobility} />

                <Route
                  exact
                  path="/detailviewpage/:id"
                  component={GMDetailsPage}
                />

                <Route exact path="/gmbupage/:id" component={GMBUPage} />

                <Route
                  exact
                  path="/allExpenseReports"
                  component={AllExpenseReport}
                />
                <Route
                  exact
                  path="/create-Expense-Management"
                  component={CreateExpenseManagement}
                />
                <Route
                  exact
                  path="/create-Timesheet-Management"
                  component={matches ? resCreateTimesheet : CreateTimesheet}
                />

                <Route
                  exact
                  path="/viewExpenseDetails/:id"
                  component={ViewExpenseDetails}
                  //component={matches ? MobileViewExpense : ViewExpenseDetails}
                />
                <Route
                  exact
                  path="/editExpenseDetails/:id"
                  component={EditExpense}
                />
                <Route
                exact 
                path="/editTravelExpenseDetails/:id"
                component={EditTravelExpense}
                />
                <Route
                  exact
                  path="/deligation/:id"
                  component={
                    matches ? MobileViewDeligationTable : DelegationTable
                  }
                />
                <Route
                  exact
                  path= "/timesheetDeligation/:id"
                  component={
                    matches ? MobileViewDeligationTableTs : DelegationTableTs
                  }
                />
                <Route
                  exact
                  path= "/globalMobilityDeligation/:id"
                  component={
                    matches ? MobiliViewDeligationTableGM : DelegationTableGM
                  }
                />
                <PrivateRoute exact path="/" type="guest">
                  <HomePage />
                </PrivateRoute>
                <PrivateRoute exact path="/login" type="private">
                  {matches ? <MobileLogin /> : <Login />}
                </PrivateRoute>
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <PrivateRoute exact path="/login" type="private">
                  {matches ? <MobileLogin /> : <Login />}
                </PrivateRoute>
              </UnauthenticatedTemplate>
            </ThemeProvider>
          </Switch>
        </Router>
      </div>
    </Provider>
  );
}

export default App;
