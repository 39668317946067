import React, { useState, useEffect } from "react";
import GMNavBar from "./gmNavBar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import { uploadFile } from "react-s3";
import DragDrope from "./gmDragDrop";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Slide,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Box,
  Toolbar,
  AppBar,
  TextField,
  Select,
  MenuItem,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Stepper,
  Step,
  StepLabel,
  FormHelperText,
  Snackbar,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Link from "@material-ui/core/Link";
import Alert from "@material-ui/lab/Alert";
import MuiAlert from "@material-ui/lab/Alert";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import Decrypt from "../Utils/decryption";
import Encrypt from "../Utils/encryption";
import DOMPurify from "dompurify";
import { NumericFormat } from "react-number-format";
import "../css/gmBUPage.css";
import { isValid, parse, set } from "date-fns";
import { FieldValidator } from "./gmValidation";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  getBusinessUnitData,
  getClientData,
  getVisaTypeData,
  getEmployeeData,
  getViewDetailsData,
  // getStateData,
  getCityData,
  getBGVTypeData,
  getWorkLocationData,
  getOtherChargesData,
  getGMIdWiseEmployeeData,
  getBUFormData,
  getDependentsData,
  getGetRecommendedSalaryRangeData,
  getEmployeeSkillExpertiseData,
  visaTypeData,
  getGetVariableData,
} from "../redux/GMCal/gmAction";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { getEmpDetail } from "../redux/Expense/action.js";
import { useDispatch, useSelector } from "react-redux";
import CompoundedSpace from "antd/es/space";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DialogActions from "@material-ui/core/DialogActions";
import { useLocation, useHistory } from "react-router-dom";
import Header from "../components/header";
import { file } from "@babel/types";
import { isE } from "@rc-component/mini-decimal/es/numberUtil";
import { Component } from "react";
import {
  getRatingDescriptionData,
  postRatingReviewData,
} from "../redux/TimeSheet/actionTs";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import Rating from "@mui/material/Rating";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  dialogBody: {
    minHeight: "400px",
  },
  dialog: {
    backgroundColor: "#54B435",
    minHeight: "25px",
    padding: "12px",
    position: "fixed",
    top: "16px",
    right: "16px",
    borderRadius: "2px",
  },
  dialogAlert: {
    backgroundColor: "red",
    minHeight: "25px",
    padding: "12px",
    position: "fixed",
    top: "16px",
    right: "16px",
    borderRadius: "2px",
  },
  contentText: {
    color: "white",
    fontWeight: 200,
    lineHeight: "25px",
  },
  successIcon: {
    color: theme.palette.success.main,
    fontSize: 40,
    minWidth: "100%",
  },
  thankYouText: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
    minWidth: "50px",
  },
  messageText: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    minWidth: "50px",
  },
  stepper: {
    marginBottom: theme.spacing(2),
    minWidth: "50px",
  },
  disabledInput: {
    backgroundColor:
      "#EEEEEE" /* Set the desired background color for disabled input */,
  },

  confirmDialogBody: {
    minHeight: "300px",
  },

  confirmationText: {
    // textAlign: 'left',
    textAlign: "start",
    marginTop: theme.spacing(2),
    // fontWeight: "bold",
    minWidth: "300px",
    minHeight: "50px",
  },

  noButton: {
    backgroundColor: "#fff",
    width: "200px",
    color: "#000",
    border: "1px solid #07073e",
  },

  yesButton: {
    backgroundColor: "#07073e",
    width: "200px",
    border: "1px solid #07073e",
  },

  expenseHeading: {
    display: "inline-flex",
    margin: "30px 0px 30px 0px",
    marginLeft: "25px",
    height: "24px",
    maxWidth: "2000px",
    overflowWrap: "anywhere",
  },
  errorText: {
    color: "#CB444A",
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: 0,
    //backgroundColor: "#F8F9FF",
    paddingRight: "99px",
  },
  errorRoot: {
    color: "#CB444A",
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: 0,
    //backgroundColor: "#F8F9FF",
  },
  formControl: {
    margin: "16px 0 8px 0",
    minWidth: "100%",
    borderRadius: 4,
    notchedOutline: {
      borderColor: "red",
      borderRadius: 4,
    },
    "& .MuiFormLabel-root": {
      // ["@media (max-width:600px)"]: { fontSize: "12px", fontWeight: "500" },
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "500",
      color: "#2B2D30",
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "2px solid #162D6E",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#515961",
      },
      "& .MuiSelect-selectMenu": {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "500",
        color: "#2B2D30",
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#FFFFFF",
      },
    },
  },

  formControl12: {
    margin: "16px 0 8px 0",
    minWidth: "100%",

    borderRadius: 4,
    notchedOutline: {
      borderColor: "red",
      borderRadius: 4,
    },
    "& .MuiFormLabel-root": {
      // ["@media (max-width:600px)"]: { fontSize: "12px", fontWeight: "500" },
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "500",
      color: "#2B2D30",
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "2px solid #162D6E",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
      },
      "& .MuiSelect-selectMenu": {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "500",
        color: "#2B2D30",
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#FFFFFF",
      },
    },
  },
}));

const performanceRatingOptions = [
  { value: "ME", label: "ME" },
  { value: "EE", label: "EE" },
  { value: "OP", label: "OP" },
];

var formatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 0,
});

function GMBUPage() {
  const classes = useStyles();
  const [gmID, setGMId] = useState("");
  const matches = useMediaQuery("(max-width:600px)");
  const [buHeadId, setBuHeadId] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [employeeGrade, setEmployeeGrade] = useState("");
  const [designation, setDesignation] = useState("");
  const [l3SkillFamily, setl3SkillFamily] = useState("");
  const [skillId, setSkillId] = useState("");
  const [employeeRole, setEmployeeRole] = useState("");
  const [employeeSkillExpertiseId, setEmployeeSkillExpertiseId] = useState("");
  const [indianSalary, setIndianSalary] = useState("");
  const [incedoJoiningDate, setIncedoJoiningDate] = useState(null);
  const [yearOfExperience, setYearOfExperience] = useState("");
  const [latestPerformanceRating, setLatestPerformanceRating] = useState("");
  const [visaType, setVisaType] = useState("");
  const [portalCharge, setPortalCharge] = useState("");
  const [portalCharges, setPortalCharges] = useState("");
  const [volumeDiscounts, setVolumeDiscounts] = useState("");
  const [numberofDependents, setNumberOfDependents] = useState("");
  const [businessUnit, setBusinessUnit] = useState("");
  const [buID, setBUId] = useState("");
  const [client, setClient] = useState("");
  const [projectName, setProjectName] = useState("");
  const [workingLocationState, setWorkingLocationState] = useState("");
  const [workingStateId, setWorkingStateId] = useState(""); //workingStateId
  const [workingLocationCity, setWorkingLocationCity] = useState("");
  const [workingCityId, setWorkingCityId] = useState(""); //workingCityId
  const [workingLocationOffice, setWorkingLocationOffice] = useState("");
  const [workLocationId, setWorkLocationId] = useState("");
  const [expectedTravelDate, setExpectedTravelDate] = useState(null);
  const [plannedBillingStartDate, setPlannedBillingStartDate] = useState(null);
  const [travelBillingDiff, setTravelBillingDiff] = useState("");
  const [expectedSignatureDate, setExpectedSignatureDate] = useState(null);
  const [recommendedSalaryRange, setRecommendedSalaryRange] = useState("");
  const [minRecommendedSalary, setMinRecommendedSalary] = useState("");
  const [maxRecommendedSalary, setMaxRecommendedSalary] = useState("");
  const [uscisMinimumWage, setUSCISMinimumWage] = useState("");
  const [offeredCompensation, setOfferedCompensation] = useState("");
  const [billRate, setBillRate] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [annualRevenue, setAnnualRevenue] = useState("");
  const [salaryCost, setSalaryCost] = useState("");
  const [occupancy, setOccupancy] = useState("");
  const [dpr, setDPR] = useState("");
  const [insurance, setInsurance] = useState("");
  const [insuranceCost, setInsuranceCost] = useState("");
  const [economyTicketPrice, setEconomyTicketPrice] = useState("");
  const [bgvNumberOfDays, setBgvNumberOfDays] = useState("");
  const [standardSeatCost, setStandardSeatCost] = useState("");
  const [billableHours, setBillableHours] = useState("");
  const [payrollCost, setPayrollCost] = useState("");
  const [payrollTax, setPayrollTax] = useState("");
  const [billingVisibility, setBillingVisibility] = useState("");
  const [sowAvailable, setSOWAvailable] = useState("");
  const [fileList, setFileList] = useState([]);
  const [prevAttachment, setPrevAttachment] = useState([]);
  const [deletedAttachment, setDeletedAttachment] = useState([]);
  const [dragDropFileError, setDragDropFileError] = useState("");
  const [validatonMsg, setValidationMsg] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const [firstYearRevenueLoss, setFirstYearRevenueLoss] = useState("");
  const [firstYearAdditionalCost, setFirstYearAdditionalCost] = useState("");
  const [backgroundVerification, setBackgroundVerification] = useState("");
  const [bgvId, setBGVId] = useState("");
  const [bgvCosts, setBGVCost] = useState("");

  const [attorneyUSCIS, setAttorneyUSCIS] = useState("");
  const [stamping, setStamping] = useState("");
  const [dependentAttorneyUSCIS, setDependentAttorneyUSCIS] = useState("");
  const [dependentStamping, setDependentStamping] = useState("");
  const [extraAttorneyUSCIS, setExtraAttorneyUSCIS] = useState("");
  const [extraStamping, setExtraStamping] = useState("");
  const [visaRelocationEmployee, setVisaRelocationEmployee] = useState("");
  const [visaRelocationDependent, setVisaRelocationDependent] = useState("");
  const [settlingAllowance, setSettlingAllowance] = useState("");
  const [maxDaysForBGV, setMaxDaysForBGV] = useState("");
  const [firstYearGM, setFirstYearGM] = useState("");
  const [steadyStateGM, setSteadyStateGM] = useState("");
  const [annualCost, setAnnualCost] = useState("");
  const [fixed, setFixed] = useState("");
  const [variable, setVariable] = useState("");

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [disableYesNo, setDisableYesNo] = useState(false);
  //const [isValidationError, setIsValidationError] = useState(false);
  const [validationErrorMsg, setvalidationErrorMsg] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    "Delivery Head Submission",
    "BU Head Approval",
    "Business Finance Approval",
    "CHRO Approval",
  ];
  const history = useHistory();
  const [clientMsg, setclientMsg] = useState("");
  const [sowMsg, setSowMsg] = useState("");
  const [projectNameMsg, setprojectNameMsg] = useState("");
  const [backgroundVerificationMsg, setbackgroundVerificationMsg] =
    useState("");
  const [salaryRangeFlag, setSalaryRangeFlag] = useState(false);
  const [minimumWageFlag, setMinimumWageFlag] = useState(false);
  const [workingAtMsg, setworkingAtMsg] = useState("");
  const [cityAtMsg, setcityAtMsg] = useState("");
  const [workingLocationMsg, setworkingLocationMsg] = useState("");
  const [billingStartDateMsg, setbillingStartDateMsg] = useState("");
  const [billingVisibilityMsg, setbillingVisibilityMsg] = useState("");
  const [expectedSignatureDateMsg, setExpectedSignatureDateMsg] = useState("");
  const [travelDateMsg, settravelDateMsg] = useState("");
  const [salaryRangeMsg, setsalaryRangeMsg] = useState("");
  const [minimumWageMsg, setminimumWageMsg] = useState("");
  const [compensationMsg, setcompensationMsg] = useState("");
  const [billRateMsg, setbillRateMsg] = useState("");
  const [bgvMsg, setBGVMsg] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  //const [businessUnit, setBusinessUnit] = useState('');
  const [businessUnitError, setBusinessUnitError] = useState("");
  const location = useLocation();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [bgvFlag, setBGVFlag] = useState(false);
  const [isOffered, setIsOfferedOpen] = useState(false);
  const [fixedOpen, setFixedOpen] = useState(false);
  const [openSowConfirmation, setSowConfirmation] = useState(false);
  const [noFlag, setNoFlag] = useState(false);
  const [openRating, setOpenRating] = useState(false);
  const [thankYouRatingOpen, setThankYouRatingOpen] = React.useState(false);
  const [pageModule, setPageModule] = useState("");
  const [ratingHeading, setRatingHeading] = useState("");
  const [value, setValue] = React.useState(-1);
  const [hover, setHover] = React.useState(-1);
  const [review, setReview] = React.useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState(false);

  const S3_BUCKET = process.env.REACT_APP_S3_BUCKET_GM;
  const REGION = "ap-south-1";
  const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
  const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

  const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  };

  const handleUpload = async (file) => {
    uploadFile(file, config)
      .then((data) => console.log(""))
      .catch((err) => console.error("handleError", err));
  };

  const preventNegativeAndDecimal = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValidInput = /^[0-9]*$/.test(keyValue);

    if (!isValidInput) {
      event.preventDefault();
    }
  };

  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData?.getEmpDetailsData
  );

  const GMDelEmpIdRedux = useSelector(
    (state) => state?.gmCalData?.GMDelEmpId
  );

  const getEmployeeDataList = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );

  const ratingLabels = useSelector(
    (state) => state?.timesheetData?.getRatingDescriptionData
  );

  var path = window.location.href;
  var directories = path.split("/");
  var lastDirecotry = directories[directories.length - 1];
  var firstDirectory = directories[directories.length - 2];

  useEffect(() => {
    if (directories && directories?.length === 4) {
      if (
        lastDirecotry === "timesheet-management" ||
        lastDirecotry === "create-Timesheet-Management"
      ) {
        setPageModule("Timesheet");
        setRatingHeading("Timesheet Application");
      } else if (
        lastDirecotry === "expense-Management" ||
        lastDirecotry === "create-Expense-Management" ||
        lastDirecotry === "allExpenseReports"
      ) {
        setPageModule("Expense");
        setRatingHeading("Expense Application");
      } else if (
        lastDirecotry === "gmdashboard" ||
        lastDirecotry === "gmmobility"
      ) {
        setPageModule("GM");
        setRatingHeading("Global Mobility Application");
      }
    } else if (directories && directories?.length === 5) {
      if (firstDirectory === "timesheetviewpage") {
        setPageModule("Timesheet");
        setRatingHeading("Timesheet Application");
      } else if (
        firstDirectory === "viewExpenseDetails" ||
        firstDirectory === "editExpenseDetails" ||
        firstDirectory === "editTravelExpenseDetails" ||
        firstDirectory === "deligation"
      ) {
        setPageModule("Expense");
        setRatingHeading("Expense Application");
      } else if (
        firstDirectory === "gmbupage" ||
        firstDirectory === "gmmobility"
      ) {
        setPageModule("GM");
        setRatingHeading("Global Mobility Application");
      }
    }
  }, [directories]);

  function getLabelText(value) {
    return (
      ratingLabels &&
      `${value} Star${value !== 1 ? "s" : ""}, ${
        ratingLabels[value - 1]?.ratingDescription
      }`
    );
  }

  const handleRatingClose = async () => {
    setOpenRating(false);

    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    const ratingFormData = new FormData();

    ratingFormData.append(
      "EmpId",
      Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
    ratingFormData.append("ApplicationType", pageModule ? pageModule : "");
    ratingFormData.append("ApplicationDiscription", "");
    ratingFormData.append("Review", "");
    ratingFormData.append("Skippedflag", 1);

    try {
      const response = await dispatch(postRatingReviewData(ratingFormData));

      if (response.status === 200) {
        history.push("/gmdashboard"); // Route to the gmdashboard location after 3 seconds
      } else {
        setThankYouRatingOpen(false);
      }
    } catch {
      setThankYouRatingOpen(false);
    }
  };

  const handleRatingSkipClose = async () => {
    setOpenRating(false);

    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    const ratingFormData = new FormData();

    ratingFormData.append(
      "EmpId",
      Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
    ratingFormData.append("ApplicationType", pageModule ? pageModule : "");
    ratingFormData.append("ApplicationDiscription", "");
    ratingFormData.append("Review", "");
    ratingFormData.append("Skippedflag", 1);

    try {
      const response = await dispatch(postRatingReviewData(ratingFormData));

      if (response.status === 200) {
        history.push("/gmdashboard"); // Route to the gmdashboard location after 3 seconds
      } else {
        setThankYouRatingOpen(false);
      }
    } catch {
      setThankYouRatingOpen(false);
    }
  };

  const handleRatingSubmit = async () => {
    setOpenRating(false);
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    const ratingFormData = new FormData();

    ratingFormData.append(
      "EmpId",
      Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
    ratingFormData.append("ApplicationType", pageModule ? pageModule : "");
    ratingFormData.append(
      "ApplicationDiscription",
      ratingLabels && value && ratingLabels[value - 1]?.ratingDescription
    );
    ratingFormData.append("Review", review ? review : "");
    ratingFormData.append("Skippedflag", 0);

    try {
      const response = await dispatch(postRatingReviewData(ratingFormData));
      if (response.status === 200) {
        setTimeout(() => {
          setThankYouRatingOpen(true);
        }, 500);
        setTimeout(() => {
          setThankYouRatingOpen(false);
          history.push("/gmdashboard");
        }, 5000);
      } else {
        setThankYouRatingOpen(false);
      }
    } catch {
      setThankYouRatingOpen(false);
    }
  };

  useEffect(() => {
    if (value && value === -1) {
      setIsSubmitDisabled(true);
    } else {
      setIsSubmitDisabled(false);
    }
  }, [value]);

  useEffect(() => {
    if((ssoEmpIdDetails && GMDelEmpIdRedux) && (ssoEmpIdDetails?.empId != GMDelEmpIdRedux)) {
      setBuHeadId(GMDelEmpIdRedux);
    } 
    else if((ssoEmpIdDetails && GMDelEmpIdRedux) && (ssoEmpIdDetails?.empId == GMDelEmpIdRedux)) {
      setBuHeadId(ssoEmpIdDetails?.empId);
    } 
    else {
      history.push("/gmdashboard");
    }
  }, [ssoEmpIdDetails, GMDelEmpIdRedux]);

  useEffect(() => {
    if (ssoEmpIdDetails) {
      dispatch(getRatingDescriptionData());
    }
    //console.log("accOpt : valueEmpId", props.employeeId);
  }, [ssoEmpIdDetails]);

  const handleBusinessUnitChange = (event) => {
    const value = event.target.value;
    setBusinessUnit(value);

    if (!value) {
      setBusinessUnitError("This field is required");
    } else {
      setBusinessUnitError("");
    }
  };
  // const dashboardDataDetails = useSelector(
  //   (state) => state.gmCalData
  // );
  const dispatch = useDispatch();

  // const dashboardDataDetails = useSelector(
  //   (state) => state.gmCalData?.gmCalCardList
  // );

  // useEffect(() => {
  //   dispatch(getEmpDetail({}));
  // })

  //UseEffect for Employee Skill Expertise Lists
  useEffect(() => {
    dispatch(getEmployeeSkillExpertiseData({}));
  }, []);

  const employeeSkillExpertiseLists =
    useSelector((state) => state?.gmCalData?.getEmployeeSkillExpertiseList) ||
    [];

  useEffect(() => {
    if (location?.gmId && ssoEmpIdDetails) {
      dispatch(getGMIdWiseEmployeeData(location?.gmId));
    }
  }, []);

  const gmIdWiseEmployeeLists =
    useSelector((state) => state?.gmCalData?.getGMIdWiseEmployeeList) || [];
  // console.log("gmbupage", gmIdWiseEmployeeLists);

  var key = process.env.REACT_APP_ENCRYPTION_KEY;

  useEffect(() => {
    if (gmIdWiseEmployeeLists && gmIdWiseEmployeeLists?.length > 0) {
      const employeeData = gmIdWiseEmployeeLists[0];
      setGMId(employeeData?.gmId);
      setBillRate(formatter.format(employeeData?.billRate));
      setEmployeeName(employeeData?.empName);
      setEmployeeId(employeeData?.empCode);
      setBusinessUnit(employeeData?.buName);
      setBUId(employeeData?.buId);
      setDesignation(employeeData?.empDesignation);
      setEmployeeGrade(employeeData?.empBand);
      setEmployeeRole(employeeData?.expertiseSkillName || "");
      setEmployeeSkillExpertiseId(employeeData?.skillExpertiseId);
      let salary = employeeData?.empIndianSalary;

      let decryptedSalary = Decrypt(key, salary);
      setIndianSalary(formatter.format(decryptedSalary));
      setIncedoJoiningDate(employeeData?.empJoiningDate);
      setLatestPerformanceRating(employeeData?.empLatestPerformanceRating);
      setl3SkillFamily(employeeData?.empSkillName);
      setSkillId(employeeData?.empSkillId);

      setYearOfExperience(employeeData?.empYearOfExp);
      setNumberOfDependents(employeeData?.numberOfDependents);
      setVisaType(employeeData?.visaType || ""); // Check if employeeData.visaType is defined
    }
  }, [gmIdWiseEmployeeLists]);

  //UseEffect for Dependents
  useEffect(() => {
    dispatch(getDependentsData({}));
  }, []);

  const dependentCostData =
    useSelector((state) => state?.gmCalData?.getDependentCost) || [];

  useEffect(() => {
    if (dependentCostData && dependentCostData.length > 0) {
      const dependentData = dependentCostData ? dependentCostData[0] : "";
      setDependentAttorneyUSCIS(dependentData?.attorneyUSCIS);
      setDependentStamping(dependentData?.stamping);
      setExtraAttorneyUSCIS(dependentData?.extra_AttorneyUSCIS);
      setExtraStamping(dependentData?.extra_Stamping);
    }
  }, [dependentCostData, gmIdWiseEmployeeLists]);

  useEffect(() => {
    if (expectedTravelDate && plannedBillingStartDate) {
      const millisecondsInADay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
      const date1 = new Date(expectedTravelDate);
      const date2 = new Date(plannedBillingStartDate);

      const differenceInDays = Math.abs(
        Math.floor((date1 - date2) / millisecondsInADay)
      );
      setTravelBillingDiff(differenceInDays);
    }
  }, [expectedTravelDate, plannedBillingStartDate, maxDaysForBGV]);

  useEffect(() => {
    let travelDateErrorMsg = "";
    let billingStartDateErrorMsg = "";

    if (expectedTravelDate) {
      if (new Date(expectedTravelDate) < new Date().setHours(0, 0, 0, 0)) {
        travelDateErrorMsg =
          "*Expected Travel date should not be smaller than today's date.";
      }

      if (
        plannedBillingStartDate &&
        new Date(expectedTravelDate) >= new Date(plannedBillingStartDate)
      ) {
        billingStartDateErrorMsg =
          "*Billing Start date should be greater than Travel date.";
      }

      if (maxDaysForBGV && travelBillingDiff > maxDaysForBGV) {
        travelDateErrorMsg =
          "*Difference between Travel date and Billing start date should not be greater than " +
          maxDaysForBGV +
          " days.";
      }
    }

    settravelDateMsg("");
    setbillingStartDateMsg("");

    if (travelDateErrorMsg !== "") {
      settravelDateMsg(travelDateErrorMsg);
    }

    if (billingStartDateErrorMsg !== "") {
      setbillingStartDateMsg(billingStartDateErrorMsg);
    }
  }, [
    expectedTravelDate,
    plannedBillingStartDate,
    travelBillingDiff,
    maxDaysForBGV,
  ]);

  //UseEffect for Visa Type

  useEffect(() => {
    dispatch(getBusinessUnitData({}));
  }, []);

  const businessUnitList =
    useSelector((state) => state?.gmCalData?.getBusinessUnitList) || [];

  //UseEffect for Visa Type

  useEffect(() => {
    dispatch(getVisaTypeData({}));
  }, []);

  const visaTypeLists =
    useSelector((state) => state?.gmCalData?.getVisaTypeList) || [];

  useEffect(() => {
    if (visaTypeLists) {
      const foundItem =
        visaTypeLists &&
        visaTypeLists.find((item) => visaType === item?.visaType);
      if (foundItem) {
        const { attorneyUSCIS, stamping } = foundItem;
        setAttorneyUSCIS(+attorneyUSCIS);
        setStamping(+stamping);
      }
    }
  }, [visaTypeLists, visaType, gmIdWiseEmployeeLists]);

  //UseEffect for State

  // useEffect(() => {
  //   dispatch(getStateData({}));
  // }, [])

  // const stateLists = useSelector(
  //   (state) => state?.gmCalData?.getStateList
  // ) || [];

  // useEffect(() => {
  //   if (stateLists && workingLocationState) {
  //     const foundItem = stateLists.find(item => item.state === workingLocationState);
  //     if (foundItem) {
  //       const { stateId } = foundItem;
  //       setWorkingStateId(stateId);
  //     }
  //   }
  // }, [stateLists, workingLocationState]);

  // const workStateHandler = (event) => {
  //   let name = event.target.value;
  //   // let id = stateLists.find((item) => name === item.state)?.stateId;
  //   setWorkingLocationState(name);
  //   // setWorkingStateId(id);
  // }

  //UseEffect for City

  useEffect(() => {
    dispatch(getCityData({}));
  }, []);

  const cityLists = useSelector((state) => state?.gmCalData?.getCityList) || [];

  useEffect(() => {
    if (cityLists && workingLocationCity) {
      const foundItem = cityLists?.find(
        (item) => item?.cityName === workingLocationCity
      );
      if (foundItem) {
        const { cityId } = foundItem;
        setWorkingCityId(cityId);
      }
    }
  }, [cityLists, workingLocationCity]);

  const workCityHandler = (event) => {
    let name = event.target.value;
    setSalaryRangeFlag(false);
    setMinimumWageFlag(false);
    setWorkingLocationCity(name);
  };

  const recomendedSalaryLists =
    useSelector((state) => state?.gmCalData?.getRecommendedSalaryRange) || [];

  //UseEffect for Recommended Salary Range

  useEffect(() => {
    const payload = {
      cityid: workingCityId,
      skillid: employeeSkillExpertiseId,
      band: employeeGrade,
      YOE: yearOfExperience,
    };

    if (payload?.cityid && payload?.skillid && payload?.band && payload?.YOE) {
      dispatch(getGetRecommendedSalaryRangeData(payload));
    }
  }, [
    workingCityId,
    employeeSkillExpertiseId,
    employeeGrade,
    yearOfExperience,
  ]);

  useEffect(() => {
    if (recomendedSalaryLists) {
      //  console.log("Testing:", recomendedSalaryLists)

      setRecommendedSalaryRange(
        recomendedSalaryLists?.mobilityRange != null
          ? recomendedSalaryLists?.mobilityRange
          : "0 - 0"
      );
      setUSCISMinimumWage(
        recomendedSalaryLists?.uscisMinWages
          ? recomendedSalaryLists?.uscisMinWages
          : 0
      );
      setMinRecommendedSalary(recomendedSalaryLists?.minMobility);
      setMaxRecommendedSalary(recomendedSalaryLists?.maxMobility);
    }
  }, [recomendedSalaryLists]);

  //UseEffect for Variable

  useEffect(() => {
    if (employeeGrade && employeeGrade.length > 0) {
      dispatch(getGetVariableData(employeeGrade));
    }
  }, [employeeGrade]);

  const variables =
    useSelector((state) => state?.gmCalData?.getVariableData) || [];

  useEffect(() => {
    if (variables) {
      let calVariable =
        (+offeredCompensation?.replace(/,/g, "") *
          variables?.variablePercentage) /
        100;
      let calFixed =
        (+offeredCompensation?.replace(/,/g, "") *
          (100 - variables?.variablePercentage)) /
        100;

      let tempVar = isNaN(calVariable) ? "" : calVariable;
      let tempFix = isNaN(calFixed) ? "" : calFixed;
      setVariable(tempVar);
      setFixed(tempFix);
    }
  }, [variables, offeredCompensation, employeeGrade]);

  useEffect(() => {
    if (isOffered) {
      setFixedOpen(false);
    } else if (fixed && uscisMinimumWage && +fixed < +uscisMinimumWage) {
      // console.log("Fixed&USCISMINIMUM", fixed, uscisMinimumWage);
      setFixedOpen(true);
    } else if (fixed && uscisMinimumWage && +fixed >= +uscisMinimumWage) {
      setFixedOpen(false);
    }
  }, [uscisMinimumWage, fixed, isOffered]);

  //UseEffect for Work Location
  useEffect(() => {
    dispatch(getWorkLocationData({}));
  }, []);

  const workLocationLists =
    useSelector((state) => state?.gmCalData?.getWorkLocationList) || [];

  useEffect(() => {
    if (workLocationLists) {
      const foundLocation = workLocationLists?.find(
        (item) => workingLocationOffice === item?.workLocation
      );

      if (foundLocation) {
        setWorkLocationId(foundLocation.wrkLocId);
      }
    }
  }, [workLocationLists, workingLocationOffice]);

  // const workLocationHandler = (event) => {
  //   //let name = event.target.value;
  //   //msgFunction(event.target.value)
  //   let name = event;
  //   // let id = workLocationLists.find((item) => name === item.workLocation)?.wrkLocId;
  //   setWorkingLocationOffice(name);
  //   // setWorkLocationId(id);
  // }

  // const planningDateHandler = (date) => {
  //   setPlannedBillingStartDate(date);
  // };

  const expectedSignatureDateHandler = (date) => {
    setExpectedSignatureDate(date);
  };

  // const expectedTravelDateHandler = (date) => {
  //   setExpectedTravelDate(date);
  // };

  const handleOfferedCompensation = (event) => {
    const selectedValue = event.target.value;

    if (
      (event.key === "Enter" && selectedValue.length > 0) ||
      (event.type === "blur" && selectedValue.length > 0)
    ) {
      setOfferedCompensation(selectedValue);
    } else if (
      (event.key === "Enter" || event.type === "blur") &&
      selectedValue.length === 0
    ) {
      setOfferedCompensation("");
    }
  };

  useEffect(() => {
    if (
      offeredCompensation &&
      (+offeredCompensation?.replace(/,/g, "") > +maxRecommendedSalary ||
        +offeredCompensation?.replace(/,/g, "") < +minRecommendedSalary)
    ) {
      setIsOfferedOpen(true);
      setcompensationMsg(
        "*Offered Gross Compensation must fall between Recommended Salary Range"
      );
    } else {
      setIsOfferedOpen(false);
    }
  }, [offeredCompensation, maxRecommendedSalary, minRecommendedSalary]);

  //UseEffect for BGV Type
  useEffect(() => {
    if (ssoEmpIdDetails) {
      dispatch(getBGVTypeData({}));
    }
  }, [ssoEmpIdDetails]);

  const bgvTypeLists = useSelector(
    (state) => state?.gmCalData?.getBGVTypeList || []
  );

  useEffect(() => {
    if (bgvTypeLists?.length > 0 && backgroundVerification) {
      setBGVId(
        bgvTypeLists?.find((item) => item?.bgvType === backgroundVerification)
          ?.bgVid
      );
      setBGVCost(
        bgvTypeLists?.find((item) => item?.bgvType === backgroundVerification)
          ?.bgvCost
      );
    }
  }, [bgvTypeLists, backgroundVerification]);

  const bgvTypeHandler = (event) => {
    //msgFunction(event.target.value)
    let name = event.target.value;
    setBackgroundVerification(name);
    // setBGVCost(bgvTypeLists.find((item) => name === item.bgvType)?.bgvCost);
    // setBGVId(bgvTypeLists.find((item) => name === item.bgvType)?.bgVid);
  };

  //UseEffect for Other Charges
  useEffect(() => {
    dispatch(getOtherChargesData({}));
  }, []);

  const otherChargesLists =
    useSelector((state) => state?.gmCalData?.getOtherChargesList) || [];

  useEffect(() => {
    if (otherChargesLists) {
      setBillableHours(
        otherChargesLists?.find((item) => 2 === item?.chargId)?.chargeValue
      );
      setBgvNumberOfDays(
        otherChargesLists?.find((item) => 3 === item?.chargId)?.chargeValue
      );
      setPayrollTax(
        otherChargesLists?.find((item) => 4 === item?.chargId)?.chargeValue
      );
      setInsuranceCost(
        otherChargesLists?.find((item) => 5 === item?.chargId)?.chargeValue
      );

      if (workingLocationOffice === "Incedo") {
        setStandardSeatCost(
          otherChargesLists?.find((item) => 6 === item?.chargId)?.chargeValue
        );
      } else {
        setStandardSeatCost(0);
      }

      setDPR(
        otherChargesLists?.find((item) => 7 === item.chargId)?.chargeValue
      );
      setEconomyTicketPrice(
        otherChargesLists?.find((item) => 1 === item?.chargId)?.chargeValue
      );
      setSettlingAllowance(
        otherChargesLists?.find((item) => 8 === item?.chargId)?.chargeValue
      );
      setMaxDaysForBGV(
        otherChargesLists?.find((item) => 9 === item?.chargId)?.chargeValue
      );
    }
  }, [otherChargesLists, workingLocationOffice]);

  //UseEffect for View Details

  const viewDetailsList = useSelector(
    (state) => state?.gmCalData?.getViewDetailsList
  );

  useEffect(() => {
    if (location?.gmId) {
      dispatch(getViewDetailsData(location?.gmId));
    }
  }, []);

  useEffect(() => {
    if (sowAvailable === "yes") {
      setExpectedSignatureDate(null);
      setExpectedSignatureDateMsg("");
    }
  }, [sowAvailable]);

  useEffect(() => {
    if (
      (prevAttachment?.length > 0 || fileList?.length > 0) &&
      sowAvailable === "no"
    ) {
      setSowConfirmation(true);
    }
  }, [sowAvailable, fileList, prevAttachment]);

  useEffect(() => {
    if (
      viewDetailsList?.length > 0 &&
      viewDetailsList?.sowAvailability === "yes" &&
      location?.gmId
    ) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [location?.gmId, viewDetailsList]);

  useEffect(() => {
    if (sowAvailable && sowAvailable === "no") {
      setDragDropFileError("");
    }
  }, [sowAvailable]);

  const Array = [
    {
      validationType: "GM Drag Drop File",
      value: fileList,
      gmPrevAttachment: prevAttachment,
      gmEdit: isEdit,
      sowAvail: sowAvailable ? sowAvailable : "",
    },
  ];

  useEffect(() => {
    // console.log("in bupage useEffect", viewDetailsList);
    if (viewDetailsList) {
      // console.log("in bupage if");
      setPrevAttachment(
        viewDetailsList?.attachments ? viewDetailsList?.attachments : []
      );
      setBackgroundVerification(viewDetailsList?.bgvTypeName);

      setBGVCost(
        bgvTypeLists?.find(
          (item) => viewDetailsList?.bgvTypeName === item.bgvType
        )?.bgvCost
      );

      setBillRate(
        viewDetailsList?.billRate === 0
          ? ""
          : formatter.format(viewDetailsList?.billRate)
      );

      setSOWAvailable(viewDetailsList?.sowAvailability);
      setExpectedSignatureDate(
        viewDetailsList?.sowExpectedSignatureDate
          ? viewDetailsList?.sowExpectedSignatureDate
          : null
      );
      setBillingVisibility(
        viewDetailsList?.billingVisibility
          ? viewDetailsList?.billingVisibility
          : ""
      );

      setClient(viewDetailsList?.clientName);
      setWorkingLocationOffice(viewDetailsList?.workLocationName);
      // setWorkingLocationState(viewDetailsList?.stateName);
      setWorkingCityId(viewDetailsList?.cityId);
      setWorkingLocationCity(viewDetailsList?.cityName);
      setCurrencySymbol(viewDetailsList?.currencySymbol);
      setCountryCode(viewDetailsList?.currencyCode);
      setRecommendedSalaryRange(
        viewDetailsList?.recommendedSalaryRange
          ? viewDetailsList?.recommendedSalaryRange
          : "0 - 0"
      );
      setFixed(Decrypt(key, viewDetailsList?.fixedOfferedComp));
      setVariable(Decrypt(key, viewDetailsList?.variableOfferedComp));

      setProjectName(viewDetailsList?.projectName);
      if (viewDetailsList?.plannedBillingStartDate) {
        setPlannedBillingStartDate(viewDetailsList?.plannedBillingStartDate);
      } else {
        setPlannedBillingStartDate(null);
      }

      if (viewDetailsList?.expectedTravelDate) {
        setExpectedTravelDate(viewDetailsList?.expectedTravelDate);
      } else {
        setExpectedTravelDate(null);
      }

      // setOfferedCompensation(formatter.format(Decrypt(key, viewDetailsList.offeredCompensation)));
      // if(offeredCompensation === NaN){
      //   setOfferedCompensation('');
      // }
      const decryptedCompensation = Decrypt(
        key,
        viewDetailsList?.offeredCompensation
      );
      const formattedCompensation =
        isNaN(decryptedCompensation) ||
        typeof decryptedCompensation === "undefined"
          ? ""
          : formatter.format(decryptedCompensation);
      setOfferedCompensation(formattedCompensation);

      // setUSCISMinimumWage(viewDetailsList?.usciS_MinimumWage);
      setUSCISMinimumWage(
        viewDetailsList?.usciS_MinimumWage
          ? viewDetailsList?.usciS_MinimumWage
          : 0
      );
      setFirstYearGM(
        viewDetailsList?.firstYearGM ? viewDetailsList?.firstYearGM : 0
      );
      setSteadyStateGM(
        viewDetailsList?.steadyStateGM ? viewDetailsList?.steadyStateGM : 0
      );
    }
  }, [viewDetailsList]);

  //UseEffect for Clients

  useEffect(() => {
    if (buID) {
      dispatch(getClientData(buID));
    }
  }, [buID]);

  const clientLists =
    useSelector((state) => state?.gmCalData?.getClientList) || [];

  const clientHandler = (event) => {
    //msgFunction(event.target.value)
    if (event.target.value) {
      setclientMsg("");
    } else {
      setclientMsg("*Please enter Client");
    }
    // if(event.target.value){
    //   setclientMsg('');
    // }
    // else{
    //   setclientMsg("*Please enter client");
    // }
    let name = event.target.value;
    setClient(name);
  };

  useEffect(() => {
    if (clientLists && clientLists?.length > 0) {
      setPortalCharge(
        clientLists?.find((item) => client === item?.clientName)?.portalCharges
      );
      setVolumeDiscounts(
        clientLists?.find((item) => client === item?.clientName)?.volumeDiscount
      );
    }
  }, [clientLists, client]);

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  const handleFileList = (data) => {
    setFileList(data);
  };

  useEffect(() => {
    setDragDropFileError("");
  }, [fileList]);

  //---------------------------------------------------CALCULATIONS----------------------------------------------------------------------------------------------

  useEffect(() => {
    // console.log("in bupage badawala useEffect");
    const formattedBillRate =
      billRate && typeof billRate === "string"
        ? Number(billRate?.replace(/,/g, ""))
        : 0;
    // console.log(
    //   "type of billRate",
    //   billRate,
    //   typeof billRate,
    //   typeof billRate === "string"
    // );
    const formattedOfferedCompensation = Number(
      offeredCompensation?.replace(/,/g, "")
    );
    const formattedPayrollTax = Number(payrollTax);
    const formattedStandardSeatCost = Number(standardSeatCost);
    const formattedPortalCharge = Number(portalCharge);
    const formattedInsuranceCost = Number(insuranceCost) * 12;
    const formattedBGVCosts = Number(bgvCosts);
    const formattedAttorneyUSCIS = Number(attorneyUSCIS);
    const formattedStamping = Number(stamping);

    const formattedEconomyTicketPrice = Number(economyTicketPrice);
    const formattedDependentStamping = Number(dependentStamping);
    const formattedDependentAttorneyUSCIS = Number(dependentAttorneyUSCIS);
    const formattedExtraStamping = Number(extraStamping);
    const formattedExtraAttorneyUSCIS = Number(extraAttorneyUSCIS);

    const formattedDPR = Number(dpr);
    const formattedBGVNumberOfDays =
      Number(travelBillingDiff) > Number(bgvNumberOfDays)
        ? Number(travelBillingDiff)
        : Number(bgvNumberOfDays);

    const formattedVolumeDiscounts = Number(volumeDiscounts);

    const formattedNumberofDependents = Number(numberofDependents);

    const formattedFirstYearRevenueLoss =
      (5 / 7) * formattedBillRate * 8 * formattedBGVNumberOfDays;
    const formattedVisaRelocationEmployee =
      formattedAttorneyUSCIS + formattedStamping + formattedEconomyTicketPrice;

    let result = 0;

    if (formattedNumberofDependents !== 0) {
      const baseTicketPrice = Number(formattedEconomyTicketPrice);
      const dependentStamping = Number(formattedDependentStamping);
      const dependentAttorneyUSCIS = Number(formattedDependentAttorneyUSCIS);
      const extraStamping = Number(formattedExtraStamping);
      const extraAttorneyUSCIS = Number(formattedExtraAttorneyUSCIS);
      const numberOfDependents = Number(formattedNumberofDependents);

      if (numberOfDependents <= 2) {
        result = (baseTicketPrice + dependentStamping) * numberOfDependents;
        result += dependentAttorneyUSCIS;
      } else {
        result =
          (baseTicketPrice + dependentStamping + extraStamping) *
          numberOfDependents;

        result += dependentAttorneyUSCIS;
        result += Number(extraAttorneyUSCIS * (numberOfDependents - 2));
      }
    }

    const formattedVisaRelocationDependent = result;

    const formattedFirstYearAdditionalCost = Number(
      formattedBGVCosts +
        formattedVisaRelocationEmployee +
        formattedVisaRelocationDependent +
        Number(settlingAllowance)
    );

    const formattedAnnualRevenue = Math.round(
      formattedBillRate *
        Number(billableHours) *
        12 *
        ((100 - formattedVolumeDiscounts) / 100)
    );
    // console.log(
    //   "checking values",
    //   formattedVolumeDiscounts,
    //   billableHours,
    //   formattedBillRate
    // );
    const formattedPayrollCost = Math.round(
      (formattedPayrollTax * formattedOfferedCompensation) / 100
    );
    const formattedPortalCharges = Math.round(
      (formattedPortalCharge * Number(formattedAnnualRevenue)) / 100
    );

    const formattedAnnualCost =
      formattedOfferedCompensation +
      formattedPayrollCost +
      formattedInsuranceCost +
      formattedStandardSeatCost +
      formattedPortalCharges +
      formattedDPR;
    const formattedFirstYearGM = Number(
      (
        (1 -
          (formattedAnnualCost + formattedFirstYearAdditionalCost) /
            (formattedAnnualRevenue - formattedFirstYearRevenueLoss)) *
        100
      ).toFixed(1)
    );
    const formattedSteadyStateGM = Number(
      ((1 - formattedAnnualCost / formattedAnnualRevenue) * 100).toFixed(1)
    );

    setAnnualRevenue(formattedAnnualRevenue);
    setSalaryCost(
      Number.isNaN(formattedOfferedCompensation) ||
        formattedOfferedCompensation === -Infinity
        ? 0
        : formattedOfferedCompensation
    );

    setPayrollCost(
      Number.isNaN(formattedPayrollCost) || formattedPayrollCost === -Infinity
        ? 0
        : formattedPayrollCost
    );

    setOccupancy(formattedStandardSeatCost);
    setPortalCharges(
      Number.isNaN(formattedPortalCharges) ||
        formattedPortalCharges === -Infinity
        ? 0
        : formattedPortalCharges
    );
    setInsurance(formattedInsuranceCost);
    setFirstYearRevenueLoss(formattedFirstYearRevenueLoss);
    setVisaRelocationEmployee(
      formattedAttorneyUSCIS + formattedStamping + formattedEconomyTicketPrice
    );
    setVisaRelocationDependent(formattedVisaRelocationDependent);

    setFirstYearAdditionalCost(
      Number.isNaN(formattedFirstYearAdditionalCost) ||
        formattedFirstYearAdditionalCost === -Infinity
        ? 0
        : formattedFirstYearAdditionalCost
    );

    setAnnualCost(
      Number.isNaN(formattedAnnualCost) || formattedAnnualCost === -Infinity
        ? 0
        : formattedAnnualCost
    );

    // const val =
    //   Number.isNaN(formattedFirstYearGM) || formattedFirstYearGM === -Infinity
    //     ? 0
    //     : formattedFirstYearGM;
    const val =
      Number.isNaN(formattedFirstYearGM) || formattedFirstYearGM === -Infinity
        ? 0
        : formattedFirstYearGM;

    // console.log(
    //   "margin value",
    //   formattedFirstYearGM,
    //   val,
    //   formattedAnnualCost,
    //   formattedFirstYearAdditionalCost,
    //   formattedAnnualRevenue,
    //   formattedFirstYearRevenueLoss
    // );
    setFirstYearGM(val);

    setSteadyStateGM(
      Number.isNaN(formattedSteadyStateGM) ||
        formattedSteadyStateGM === -Infinity
        ? 0
        : formattedSteadyStateGM
    );
  }, [
    billRate,
    travelBillingDiff,
    billableHours,
    volumeDiscounts,
    offeredCompensation,
    payrollTax,
    standardSeatCost,
    portalCharge,
    insuranceCost,
    bgvCosts,
    attorneyUSCIS,
    stamping,
    economyTicketPrice,
    dependentStamping,
    dependentAttorneyUSCIS,
    dpr,
    bgvNumberOfDays,
    numberofDependents,
    clientLists,
  ]);

 
  useEffect(() => {
    validatonMsg.map((item) => {
      if (item.type === "GM Drag Drop File") {
        setDragDropFileError(item.message);
      }
    });
  }, [validatonMsg]);

  //---------------------------------------------------CALCULATIONS----------------------------------------------------------------------------------------------

  const handleSubmit = async (event) => {
    event?.preventDefault();

    let hasError = false; // Flag to track if there are any validation errors

    const result = Array?.map((item) =>
      FieldValidator(
        item.validationType,
        item.value,
        item.gmPrevAttachment,
        item.gmEdit,
        item.sowAvail,
        item.claim,
        item.validateList,
        item.validateList2,
        item.noOfMonths,
        item.validateJoiningDate
      )
    );
    setValidationMsg(result);

    if (result && result[0]?.message?.length > 0) {
      hasError = true;
    }

    if (!sowAvailable) {
      setSowMsg("*Please select Scope of Work availability (Yes/No).");
      hasError = true;
    } else {
      setSowMsg("");
    }

    if (!client) {
      setclientMsg("*Please select Client");
      hasError = true;
    } else {
      setclientMsg("");
    }
    if (!projectName) {
      setprojectNameMsg("*Please enter Project Name");
      hasError = true;
    } else {
      setprojectNameMsg("");
    }
    if (!backgroundVerification) {
      setbackgroundVerificationMsg(
        "*Please select Background Verification Type"
      );
      hasError = true;
    } else {
      setbackgroundVerificationMsg("");
    }

    if (workingLocationCity && recommendedSalaryRange === "0 - 0") {
      setsalaryRangeMsg("*No data available.");
      hasError = true;
      setSalaryRangeFlag(true);
    } else {
      setsalaryRangeMsg("");
    }

    if (workingLocationCity && uscisMinimumWage === 0) {
      setminimumWageMsg("*No data available.");
      hasError = true;
      setMinimumWageFlag(true);
    } else {
      setminimumWageMsg("");
    }

    if (!workingLocationCity) {
      setcityAtMsg("*Please enter Working In (City)");
      hasError = true;
    } else {
      setcityAtMsg("");
    }
    if (!workingLocationOffice) {
      setworkingLocationMsg("*Please select Working Location (Incedo/Client)");
      hasError = true;
    } else {
      setworkingLocationMsg("");
    }

    if (!plannedBillingStartDate) {
      setbillingStartDateMsg("*Please select Expected Billing Start Date");
      hasError = true;
    } else if (
      expectedTravelDate &&
      new Date(expectedTravelDate) >= new Date(plannedBillingStartDate)
    ) {
      setbillingStartDateMsg(
        "*Billing Start date should be greater than Travel date."
      );
      hasError = true;
    } else {
      const parsedDate = Date.parse(plannedBillingStartDate);
      if (isNaN(parsedDate)) {
        setbillingStartDateMsg(
          "*Invalid date. Please enter in 'MM/DD/YYYY' format."
        );
        hasError = true;
      } else {
        setbillingStartDateMsg("");
      }
    }

    if (billingVisibility == null || billingVisibility == "" ||billingVisibility==0) {
      setbillingVisibilityMsg(
        "*Please enter Billing visibility or confirmation."
      );
      hasError = true;
    } else {
      setbillingVisibilityMsg("");
    }

    if (!expectedTravelDate) {
      settravelDateMsg("*Please select Expected Travel Date");
      hasError = true;
    } else if (
      plannedBillingStartDate &&
      new Date(plannedBillingStartDate) <= new Date(expectedTravelDate)
    ) {
      setbillingStartDateMsg(
        "*Billing Start date should be greater than Travel date."
      );
      hasError = true;
    } else if (new Date(expectedTravelDate) < new Date().setHours(0, 0, 0, 0)) {
      settravelDateMsg(
        "*Expected Travel date should not be smaller than today's date."
      );
      hasError = true;
    } else if (
      maxDaysForBGV &&
      travelBillingDiff &&
      travelBillingDiff > maxDaysForBGV
    ) {
      settravelDateMsg(
        "*Difference between Travel date and Billing start date should not be greater than " +
          maxDaysForBGV +
          " days."
      );
      hasError = true;
    } else {
      const parsedDate = Date.parse(expectedTravelDate);
      if (isNaN(parsedDate)) {
        settravelDateMsg("*Invalid date. Please enter in 'MM/DD/YYYY' format.");
        hasError = true;
      } else {
        settravelDateMsg("");
      }
    }

    if (sowAvailable && sowAvailable === "no" && !expectedSignatureDate) {
      setExpectedSignatureDateMsg("*Please select Expected Signature Date");
      hasError = true;
    } else {
      const parsedDate = Date.parse(expectedSignatureDate);
      if (expectedSignatureDate && isNaN(parsedDate)) {
        setExpectedSignatureDateMsg(
          "*Invalid date. Please enter in 'MM/DD/YYYY' format."
        );
        hasError = true;
      } else {
        setExpectedSignatureDateMsg("");
      }
    }

    if (!isOffered && fixed && uscisMinimumWage && +fixed < +uscisMinimumWage) {
      hasError = true;
      setFixedOpen(true);
    } else {
      setFixedOpen(false);
    }

    if (!offeredCompensation) {
      setcompensationMsg("*Please enter Offered Gross Compensation");
      hasError = true;
    } else if (offeredCompensation && isOffered) {
      setcompensationMsg(
        "*Offered Gross Compensation must fall between Recommended Salary Range"
      );
      hasError = true;
    } else {
      setcompensationMsg("");
    }

    if (billRate == null || billRate == "" ||billRate==0) {
      // console.log("billrate",billRate)
      setbillRateMsg("*Please enter Bill Rate/Hour");
      hasError = true;
    } else {
      setbillRateMsg("");
    }
    // if(!billRate){
    //   setbillRateMsg("*Please enter bill rate");
    // }
    // else{
    //   setbillRateMsg('');
    // }

    if (hasError) {
      // Exit the function and prevent form submission
      return;
    }

    setShowConfirmation(true);
    setIsButtonDisabled(true);
  };

  const handleSowOkButton = () => {
    const combinedAttachments = [...prevAttachment, ...deletedAttachment];
    setDeletedAttachment(combinedAttachments);
    setNoFlag(true);
    setFileList([]);
    setPrevAttachment([]);
    setSowConfirmation(false);
    setDragDropFileError("");
  };

  const handleSowConfirmationClose = () => {
    setNoFlag(false);
    setSowConfirmation(false);
    setSOWAvailable("yes");
  };

  const handleYesButton = async () => {
    setDisableYesNo(true);
    let buFormData = new FormData();

    buFormData.append("GMId", Encrypt(key, JSON.stringify(gmID)));
    buFormData.append("FhHeadId", Encrypt(key, JSON.stringify(buHeadId)));
    buFormData.append("DelegateId", (ssoEmpIdDetails?.empId != GMDelEmpIdRedux) ? Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId)) : "");
    buFormData.append("CountryId", Encrypt(key, JSON.stringify(2)));

    var clnt = Encrypt(key, client);
    buFormData.append("ClientName", clnt);

    var proj = Encrypt(key, projectName);
    buFormData.append("ProjectName", proj);

    buFormData.append("WorkLocationId", workLocationId);
    buFormData.append("WorkLocationName", workingLocationOffice);
    // buFormData.append("StateId", workingStateId);
    // buFormData.append("StateName", workingLocationState);
    buFormData.append("CityId", workingCityId);
    buFormData.append("CityName", workingLocationCity);
    buFormData.append("BgvTypeId", bgvId);
    buFormData.append("BgvTypeName", backgroundVerification);

    const offCompensation = offeredCompensation?.replace(/,/g, "");
    var offComp = Encrypt(key, JSON.stringify(offCompensation));
    buFormData.append("OfferedCompensation", offComp);
    buFormData.append("fixedOfferedComp", Encrypt(key, JSON.stringify(fixed)));
    buFormData.append("variableOfferedComp", Encrypt(key, JSON.stringify(variable)));
    buFormData.append("BillRate", billRate && typeof billRate === "string" ? Number(billRate?.replace(/,/g, "")) : 0);
    buFormData.append("FirstYearGm", Number(firstYearGM));
    buFormData.append("SteadyStateGm", Number(steadyStateGM));
    buFormData.append("AnnualRevenue", Number(annualRevenue));
    buFormData.append("AnnualCost", Number(annualCost));
    buFormData.append("SalaryCost", salaryCost);
    buFormData.append("PayrollTax", Number(payrollTax));
    buFormData.append("Insurance", Number(insurance));
    buFormData.append("Occupancy", Number(occupancy));
    buFormData.append("PortalCharges", Number(portalCharge));
    buFormData.append("Dpr", Number(dpr));
    buFormData.append("FirstYearRevenueLost", Number(firstYearRevenueLoss));
    buFormData.append("FirstYearAdditionalCost", Number(firstYearAdditionalCost));
    buFormData.append("Bgv", Number(bgvCosts));
    buFormData.append("VisaAndRelocationEmp", Number(visaRelocationEmployee));
    buFormData.append("VisaAndRelocationDep", Number(visaRelocationDependent));
    buFormData.append("PlannedBillingStartDate", moment(plannedBillingStartDate).format("MM/DD/yyyy"));
    buFormData.append("ExpectedTravelDate", moment(expectedTravelDate).format("MM/DD/yyyy"));
    buFormData.append("BillableVisibility", billingVisibility);
    buFormData.append("SowaVailability", sowAvailable);
    buFormData.append("SowExpectedSignatureDate", expectedSignatureDate ? moment(expectedSignatureDate).format("MM/DD/yyyy") : "");
    buFormData.append("SettlingAllowance", Number(settlingAllowance));
    let tempFileList = [];

    for (let i = 0; i < fileList?.length; i++) {
      if (!tempFileList.some((item) => item.filename === fileList[i].name)) {
        tempFileList.push({
          filename: fileList[i].name,
          attachmentsize: fileList[i].size,
        });
      }
    }

    for (let i = 0; i < prevAttachment?.length; i++) {
      if (
        !tempFileList.some(
          (item) => item.filename === prevAttachment[i].fileName
        )
      ) {
        tempFileList.push({
          filename: prevAttachment[i].fileName,
          attachmentsize: prevAttachment[i].fileSize,
        });
      }
    }

    buFormData.append("GmAttachments", JSON.stringify(tempFileList));
    buFormData.append("DeleteAttachments", JSON.stringify(deletedAttachment));
    buFormData.append("USCIS", Number(uscisMinimumWage?.toString().replace(/,/g, "")));

    // var recSal = Encrypt(key, JSON.stringify(recommendedSalaryRange));
    buFormData.append("RecommendedSalary", recommendedSalaryRange);

    try {
      const response = await Promise.all([dispatch(getBUFormData(buFormData))]);

      if (response[0].status === 200) {
        setIsSubmitted(true);
        setShowConfirmation(false);

        for (let i = 0; i < fileList?.length; i++) {
          const mynewFile = new File(
            [fileList[i]],
            `${gmID}_${fileList[i].name.toLowerCase()}`
          );
          handleUpload(mynewFile);
        }
      } else {
        setSnackbarOpen(true);
        setIsSubmitted(false);
        setShowConfirmation(false);
      }
    } catch (error) {
      setIsSubmitted(false);
    }
  };

  const handleClose = () => {
    // Reset the state and close the pop-up

    setIsSubmitted(false);
    setvalidationErrorMsg(false);
    // console.log("India", isInitiated);
    if (
      pageModule &&
      ((ssoEmpIdDetails?.expenseRatingFlag === 1 && pageModule === "Expense") ||
        (ssoEmpIdDetails?.timesheetRatingFlag === 1 &&
          pageModule === "Timesheet") ||
        (ssoEmpIdDetails?.gmRatingFlag === 1 && pageModule === "GM"))
    ) {
      setOpenRating(true);
      setShowConfirmation(false);
    } else {
      setShowConfirmation(false);
      history.push("/gmdashboard"); // Route to the gmdashboard location after 3 seconds
    }
  };

  useEffect(() => {
    if (isSubmitted) {
      setTimeout(() => {
        handleClose();
      }, 3000);
    }
  }, [isSubmitted]);

  const handleConfirmClose = () => {
    // Reset the state and close the pop-up
    setShowConfirmation(false);
    setIsButtonDisabled(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleSnackClose = () => {
    setIsSubmitted(false);
    setSnackbarOpen(false);
    setIsButtonDisabled(true);
    history.push("/gmdashboard");
  };

  const handleFixedClose = () => {
    setFixedOpen(false);
  };

  const handleBackdropMouseDown = (event) => {
    // Check if the event target is the dialog's backdrop
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };

  GMBUPage.propTypes = {
    classes: PropTypes.object,
  };

  return (
    <div className="body">
      <Header
        heading={""}
        ssoEmpId={ssoEmpIdDetails?.empId}
        disableAccOptions={1}
      />
      <Box style={{ display: "flex", alignItems: "center" }}>
        <Box
          style={{
            paddingLeft: "70px",
            paddingTop: "5px",
          }}
          onClick={() => {
            history.push({
              pathname: "/gmdashboard",
              state: {
                compName: "GMDashboard",
              },
            });
            // window.location.reload();
          }}
        >
          <ArrowBackIosIcon />
        </Box>

        <Box className={classes.expenseHeading}>
          <Typography
            style={{
              fontWeight: "600",
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontSize: "24px",
              lineHeight: "24px",
              letterSpacing: "0px",
              textAlign: "left",
              color: "#2B2D30",
              paddingBottom: "10px",
            }}
          >
            Update Mobility
          </Typography>
        </Box>
      </Box>
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <Grid
            variant="outlined"
            style={{
              height: "50px",
              color: "#1C3663",
              border: "1.5px solid #B9C3E0",
              borderRadius: "5px",
              backgroundColor: "#EDF2FF",
              marginBottom: "20px",
            }}
          >
            <Typography
              variant="h6"
              style={{
                marginLeft: "10px",
                lineHeight: "50px",
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Employee Details
            </Typography>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} className="custom-grid-outlined">
              <NumericFormat
                label={<span style={{ color: "#000" }}>Employee ID*</span>}
                name="employeeId"
                value={employeeId ? employeeId : ""}
                disabled
                // onChange={handleEmployeeChange}
                variant="outlined"
                fullWidth
                margin="normal"
                onKeyPress={preventMinus}
                min="0"
                customInput={TextField}
                className={classes.disabledInput}
                InputProps={{
                  inputProps: {
                    maxLength: 6,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled
                label={<span style={{ color: "#000" }}>Employee Name*</span>}
                name="employeeName"
                value={employeeName ? employeeName : ""}
                // onChange={handleEmployeeChange}
                variant="outlined"
                className={classes.disabledInput}
                fullWidth
                margin="normal"
                InputProps={{
                  inputProps: {
                    maxLength: 50,
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                label={<span style={{ color: "#000" }}>Employee Grade*</span>}
                name="employeeGrade"
                disabled
                value={employeeGrade ? employeeGrade : ""}
                // onChange={handleEmployeeChange}
                variant="outlined"
                fullWidth
                margin="normal"
                className={classes.disabledInput}
                InputProps={{
                  inputProps: {
                    maxLength: 50,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label={<span style={{ color: "#000" }}>Designation*</span>}
                name="designation"
                value={designation ? designation : ""}
                // onChange={handleEmployeeChange}
                variant="outlined"
                fullWidth
                className={classes.disabledInput}
                // helperText={
                //   designation == null || designation==""
                //   ? designationMsg
                //   : ""
                //   }
                //   FormHelperTextProps={{
                //     classes: {
                //       root: classes.errorRoot,
                //     },
                //   }}
                margin="normal"
                disabled
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <TextField
                label={
                  <span style={{ color: "#000" }}>Employee L3 Skills*</span>
                }
                name="l3SkillFamily"
                disabled
                value={l3SkillFamily ? l3SkillFamily : ""}
                // onChange={handleEmployeeChange}
                variant="outlined"
                fullWidth
                margin="normal"
                className={classes.disabledInput}
                // helperText={
                //   l3SkillFamily == null || l3SkillFamily==""
                //   ? employeeSkillsMsg
                //   : ""
                //   }
                //   FormHelperTextProps={{
                //     classes: {
                //       root: classes.errorRoot,
                //     },
                //   }}
                InputProps={{
                  inputProps: {
                    maxLength: 50,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                disabled
                label={<span style={{ color: "#000" }}>Employee Role*</span>}
                name="employeeRole"
                value={employeeRole ? employeeRole : ""}
                variant="outlined"
                className={classes.disabledInput}
                fullWidth
                margin="normal"
                InputProps={{
                  inputProps: {
                    maxLength: 50,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label={
                  <span style={{ color: "#000" }}>Salary in India (INR)*</span>
                }
                name="indianSalary"
                disabled
                value={indianSalary ? indianSalary : ""}
                thousandSeparator={true}
                // onChange={handleEmployeeChange}
                variant="outlined"
                fullWidth
                margin="normal"
                className={classes.disabledInput}
                InputProps={{
                  inputProps: {
                    maxLength: 50,
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel style={{ color: "#000" }}>
                  Destination Business Unit*
                </InputLabel>
                <Select
                  label="Destination Business Unit"
                  name="businessUnit"
                  value={businessUnit ? businessUnit : ""}
                  className={classes.disabledInput}
                  disabled
                  MenuProps={{
                    MenuListProps: {
                      disableOutline: true,
                    },
                  }}
                >
                  {businessUnitList?.map((item) => (
                    <MenuItem key={item?.buId} value={item?.buName}>
                      {item?.buName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  disabled
                  inputVariant="outlined"
                  fullWidth
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  autoOk
                  id="date-picker-inline"
                  label={
                    <span style={{ color: "#000" }}>Incedo Joining Date*</span>
                  }
                  className={classes.disabledInput}
                  disableFuture={true}
                  value={incedoJoiningDate ? incedoJoiningDate : null}
                  // onChange={handleJoiningDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                label={
                  <span style={{ color: "#000" }}>Year of Experience*</span>
                }
                name="yearOfExperience"
                value={yearOfExperience ? yearOfExperience : ""}
                // onChange={handleChange}
                variant="outlined"
                className={classes.disabledInput}
                disabled
                fullWidth
                margin="normal"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel style={{ color: "#000" }}>
                  Latest Performance Rating*
                </InputLabel>
                <Select
                  label="Latest Performance Rating*"
                  name="latestPerformanceRating"
                  value={latestPerformanceRating ? latestPerformanceRating : ""}
                  disabled
                  className={classes.disabledInput}
                  MenuProps={{
                    MenuListProps: {
                      disableOutline: true,
                    },
                  }}
                  // helperText={
                  //   latestPerformanceRating === null || latestPerformanceRating == ""
                  //     ? latestPerformanceRatingMsg
                  //     :""
                  // }
                  // FormHelperTextProps={{
                  //   classes: {
                  //     root: classes.errorRoot,
                  //   },
                  // }}
                >
                  {performanceRatingOptions.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            variant="outlined"
            style={{
              height: "50px",
              color: "#1C3663",
              border: "1.5px solid #B9C3E0",
              borderRadius: "5px",
              backgroundColor: "#EDF2FF",
              marginBottom: "20px",
              marginTop: "30px",
            }}
          >
            <Typography
              variant="h6"
              style={{
                marginLeft: "10px",
                lineHeight: "50px",
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Visa Information
            </Typography>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel style={{ color: "#000" }}>Visa Type*</InputLabel>
                <Select
                  label="Visa Type*"
                  name="visaType"
                  value={visaType ? visaType : ""}
                  disabled
                  className={classes.disabledInput}
                  MenuProps={{
                    getContentAnchorEl: null,
                  }}
                >
                  {visaTypeLists?.map((item) => (
                    <MenuItem key={item?.vsTypeId} value={item?.visaType}>
                      {item?.visaType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label={
                  <span style={{ color: "#000" }}>No. of Dependents*</span>
                }
                name="numberofDependents"
                // value={numberofDependents ? numberofDependents : ''}
                value={
                  numberofDependents !== undefined &&
                  numberofDependents !== null
                    ? numberofDependents
                    : ""
                }
                // onChange={handleChange}
                variant="outlined"
                className={classes.disabledInput}
                disabled
                fullWidth
                margin="normal"
              />
              {/* {console.log("Value of numberofDependents:", numberofDependents)} */}
            </Grid>
          </Grid>

          <Grid
            variant="outlined"
            style={{
              height: "50px",
              color: "#1C3663",
              border: "1.5px solid #B9C3E0",
              borderRadius: "5px",
              backgroundColor: "#EDF2FF",
              marginBottom: "20px",
              marginTop: "30px",
            }}
          >
            <Typography
              variant="h6"
              style={{
                marginLeft: "10px",
                lineHeight: "50px",
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Project Details
            </Typography>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel style={{ color: "#000" }}>
                  Destination Business Unit*
                </InputLabel>
                <Select
                  label="Destination Business Unit*"
                  name="businessUnit"
                  value={businessUnit ? businessUnit : ""}
                  disabled
                  className={classes.disabledInput}
                  MenuProps={{
                    MenuListProps: {
                      disableOutline: true,
                    },
                  }}
                >
                  {businessUnitList?.map((item) => (
                    <MenuItem key={item?.buId} value={item?.buName}>
                      {item?.buName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl
                className={
                  clientMsg != "" ? classes.formControl12 : classes.formControl
                }
                variant="outlined"
                fullWidth
                margin="normal"
              >
                <InputLabel>Client*</InputLabel>
                <Select
                  label="Client"
                  name="client"
                  value={client ? client : ""}
                  onChange={(e) => {
                    clientHandler(e);
                  }}
                  MenuProps={{
                    MenuListProps: {
                      disableOutline: true,
                    },
                  }}
                >
                  {clientLists?.map((item) => (
                    <MenuItem
                      key={businessUnitList?.buId}
                      value={item?.clientName}
                    >
                      {item?.clientName}
                    </MenuItem>
                  ))}
                </Select>
                {client == null || client == "" ? (
                  <FormHelperText className={classes.errorRoot}>
                    {clientMsg}
                  </FormHelperText>
                ) : (
                  <FormHelperText></FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                className={
                  projectNameMsg != ""
                    ? classes.formControl12
                    : classes.formControl
                }
                label="Project Name*"
                name="projectName"
                value={projectName ? projectName : ""}
                onChange={(e) => {
                  setProjectName(
                    DOMPurify.sanitize(e.target.value.trimStart())
                  );

                  //msgFunction(e.target.value);
                  if (e.target.value) {
                    setprojectNameMsg("");
                  } else {
                    setprojectNameMsg("*Please enter Project Name");
                  }
                }}
                variant="outlined"
                fullWidth
                margin="normal"
                InputProps={{
                  inputProps: {
                    maxLength: 100,
                  },
                }}
                helperText={
                  projectName == null || projectName == "" ? projectNameMsg : ""
                }
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorRoot,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl
                className={
                  backgroundVerificationMsg != ""
                    ? classes.formControl12
                    : classes.formControl
                }
                variant="outlined"
                fullWidth
                margin="normal"
              >
                <InputLabel>Background Verification Required*</InputLabel>
                <Select
                  label="Background Verification Required"
                  name="backgroundVerification"
                  value={backgroundVerification ? backgroundVerification : ""}
                  onChange={(e) => {
                    bgvTypeHandler(e);

                    if (e.target.value) {
                      setbackgroundVerificationMsg("");
                    } else {
                      setbackgroundVerificationMsg(
                        "*Please select Background Verification Type"
                      );
                    }
                  }}
                  MenuProps={{
                    MenuListProps: {
                      disableOutline: true,
                    },
                  }}
                  // helperText={
                  //   projectNameMsg
                  // }
                  // FormHelperTextProps={{
                  //   classes: {
                  //     root: classes.errorRoot,
                  //   },
                  // }}
                  // helperText={
                  //   backgroundVerification === null || backgroundVerification == ""
                  //     ? backgroundVerificationMsg
                  //     : ""
                  // }
                  // FormHelperTextProps={{
                  //   classes: {
                  //     root: classes.errorRoot,
                  //   },
                  // }}
                >
                  {bgvTypeLists?.map((item) => (
                    <MenuItem key={item?.bgVid} value={item?.bgvType}>
                      {item?.bgvType}
                    </MenuItem>
                  ))}
                </Select>
                {backgroundVerification == null ||
                backgroundVerification == "" ? (
                  <FormHelperText className={classes.errorRoot}>
                    {backgroundVerificationMsg}
                  </FormHelperText>
                ) : (
                  <FormHelperText></FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} md={6}>
              <FormControl className={workingAtMsg != ""
                ? classes.formControl12
                : classes.formControl} variant="outlined" fullWidth margin="normal">
                <InputLabel>Working At (State)*</InputLabel>
                <Select
                  label="Working In (State)*"
                  name="workingLocationState"
                  value={workingLocationState}
                  onChange={(e) => {
                    workStateHandler(e);
                    if (e.target.value) {
                      setworkingAtMsg('');
                    }
                    else {
                      setworkingAtMsg("*Please select Working In (State)")
                    }
                  }}
                >
                  {stateLists.map((item) => (
                    <MenuItem key={item.stateId} value={item.state}>
                      {item.state}
                    </MenuItem>
                  ))}
                </Select>
                {workingLocationState == null || workingLocationState == ""
                  ? <FormHelperText className={classes.errorRoot}>{workingAtMsg}</FormHelperText>
                  : <FormHelperText></FormHelperText>}
              </FormControl>
            </Grid> */}
            <Grid item xs={12} md={6}>
              <FormControl
                className={
                  cityAtMsg != "" ? classes.formControl12 : classes.formControl
                }
                variant="outlined"
                fullWidth
                margin="normal"
              >
                <InputLabel>Working In (City)*</InputLabel>
                <Select
                  label="Working In (City)*"
                  name="workingLocationCity"
                  value={workingLocationCity ? workingLocationCity : ""}
                  onChange={(e) => {
                    workCityHandler(e);
                    if (e.target.value) {
                      setcityAtMsg("");
                    } else {
                      setcityAtMsg("*Please select Working In (City)");
                    }
                  }}
                >
                  {cityLists?.map((item) => (
                    <MenuItem key={item?.cityId} value={item?.cityName}>
                      {item?.cityName}
                    </MenuItem>
                  ))}
                </Select>
                {workingLocationCity == null || workingLocationCity == "" ? (
                  <FormHelperText className={classes.errorRoot}>
                    {cityAtMsg}
                  </FormHelperText>
                ) : (
                  <FormHelperText></FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl
                className={
                  workingLocationMsg != ""
                    ? classes.formControl12
                    : classes.formControl
                }
                variant="outlined"
                fullWidth
                margin="normal"
              >
                <InputLabel>
                  Working Location(Client Office/Incedo Office)*
                </InputLabel>
                <Select
                  label="Working Location(Client Office/Incedo Office)*"
                  name="workingLocationOffice"
                  value={workingLocationOffice ? workingLocationOffice : ""}
                  onChange={(e) => {
                    setWorkingLocationOffice(e.target.value);
                    if (e.target.value) {
                      setworkingLocationMsg("");
                    } else {
                      setworkingLocationMsg("*Please select Working Location");
                    }
                  }}
                  MenuProps={{
                    MenuListProps: {
                      disableOutline: true,
                    },
                  }}
                  // helperText={
                  //   workingLocationOffice == null || workingLocationOffice == ""
                  //   ?projectNameMsg
                  //   :""
                  // }
                  // FormHelperTextProps={{
                  //   classes: {
                  //     root: classes.errorRoot,
                  //   },
                  // }}
                >
                  {workLocationLists?.map((item) => (
                    <MenuItem key={item?.wrkLocId} value={item?.workLocation}>
                      {item?.workLocation}
                    </MenuItem>
                  ))}
                </Select>
                {workingLocationOffice == null ||
                workingLocationOffice == "" ? (
                  <FormHelperText className={classes.errorRoot}>
                    {workingLocationMsg}
                  </FormHelperText>
                ) : (
                  <FormHelperText></FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={
                    billingStartDateMsg != ""
                      ? classes.formControl12
                      : classes.formControl
                  }
                  disableToolbar
                  inputVariant="outlined"
                  fullWidth
                  style={{
                    backgroundColor: "#FFFFFF",
                  }}
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  autoOk
                  id="date-picker-inline"
                  label="Expected Billing Start Date*"
                  value={
                    plannedBillingStartDate ? plannedBillingStartDate : null
                  }
                  onChange={(e) => {
                    setPlannedBillingStartDate(e);
                    if (e) {
                      const formattedDate = e.toLocaleDateString("en-US");
                      const parsedDate = parse(
                        formattedDate,
                        "MM/dd/yyyy",
                        new Date()
                      );
                      if (
                        !isValid(parsedDate) ||
                        parsedDate.getFullYear() < 1900
                      ) {
                        setbillingStartDateMsg(
                          "*Invalid date. Please enter in 'MM/DD/YYYY' format."
                        );
                      } else if (
                        expectedTravelDate &&
                        new Date(e) <= new Date(expectedTravelDate)
                      ) {
                        setbillingStartDateMsg(
                          "*Billing Start date should be greater than Travel date."
                        );
                      } else {
                        setbillingStartDateMsg("");
                      }
                    } else {
                      setbillingStartDateMsg(
                        "*Please enter Expected Billing Start Date"
                      );
                    }
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  helperText={
                    plannedBillingStartDate === null ||
                    plannedBillingStartDate == "" ||
                    billingStartDateMsg != null ||
                    billingStartDateMsg != "" ||
                    (expectedTravelDate &&
                      new Date(plannedBillingStartDate) <=
                        new Date(expectedTravelDate))
                      ? billingStartDateMsg
                      : ""
                  }
                  FormHelperTextProps={{
                    classes: {
                      root: classes.errorRoot,
                    },
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12} md={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={
                    travelDateMsg != ""
                      ? classes.formControl12
                      : classes.formControl
                  }
                  disableToolbar
                  inputVariant="outlined"
                  fullWidth
                  style={{
                    backgroundColor: "#FFFFFF",
                  }}
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  autoOk
                  id="date-picker-inline"
                  label="Expected Travel Date*"
                  value={expectedTravelDate ? expectedTravelDate : null}
                  onChange={(e) => {
                    setExpectedTravelDate(e);
                    // expectedTravelDateHandler(e);
                    if (e) {
                      const formattedDate = e.toLocaleDateString("en-US");
                      const parsedDate = parse(
                        formattedDate,
                        "MM/dd/yyyy",
                        new Date()
                      );

                      if (
                        !isValid(parsedDate) ||
                        parsedDate.getFullYear() < 1900
                      ) {
                        settravelDateMsg(
                          "*Invalid date! Please enter in 'MM/DD/YYYY' format."
                        );
                      } else if (
                        new Date(e) < new Date().setHours(0, 0, 0, 0)
                      ) {
                        settravelDateMsg(
                          "*Expected Travel date should not be smaller than today's date."
                        );
                      } else if (
                        plannedBillingStartDate &&
                        new Date(e) >= new Date(plannedBillingStartDate)
                      ) {
                        setbillingStartDateMsg(
                          "*Billing Start date should be greater than Travel date."
                        );
                      } else {
                        settravelDateMsg("");
                        setbillingStartDateMsg("");
                      }
                    } else {
                      settravelDateMsg("*Please enter Expected Travel Date");
                    }
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  helperText={
                    expectedTravelDate === null ||
                    expectedTravelDate === "" ||
                    travelDateMsg !== null ||
                    travelDateMsg !== "" ||
                    new Date(expectedTravelDate) <
                      new Date().setHours(0, 0, 0, 0) ||
                    (maxDaysForBGV &&
                      travelBillingDiff &&
                      travelBillingDiff > maxDaysForBGV) ||
                    new Date(plannedBillingStartDate) <=
                      new Date(expectedTravelDate)
                      ? travelDateMsg
                      : ""
                  }
                  FormHelperTextProps={{
                    classes: {
                      root: classes.errorRoot,
                    },
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <NumericFormat
                className={
                  billingVisibilityMsg != ""
                    ? classes.formControl12
                    : classes.formControl
                }
                label="Billing visibility or confirmation (in months)*"
                name="billingVisibility"
                value={billingVisibility ? billingVisibility : ""}
                onChange={(e) => {
                  setBillingVisibility(e.target.value);
                  //msgFunction(e.target.value);
                  if (e.target.value>0) {
                    setbillingVisibilityMsg("");
                  } else {
                    setbillingVisibilityMsg(
                      "*Please enter Billing visibility or confirmation"
                    );
                  }
                }}
                variant="outlined"
                fullWidth
                min="0"
                onKeyPress={preventMinus}
                customInput={TextField}
                margin="normal"
                InputProps={{
                  inputProps: {
                    maxLength: 2,
                    min: 0,
                    onKeyPress: preventNegativeAndDecimal,
                  },
                }}
                helperText={
                  billingVisibility == null || billingVisibility == ""||billingVisibility==0
                    ? billingVisibilityMsg
                    : ""
                }
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorRoot,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth>
                <RadioGroup
                  row
                  style={{
                    display: "initial",
                    height: "53px",
                    lineHeight: "50px",
                    border: "1px solid #000",
                    borderRadius: "4px",
                    marginTop: "3%",
                  }}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={sowAvailable ? sowAvailable : ""}
                  onChange={(e) => {
                    setSOWAvailable(e.target.value);
                    if (e.target.value) {
                      setSowMsg("");
                    } else {
                      setSowMsg(
                        "*Please select Scope of Work Availability (Yes/No)."
                      );
                    }
                  }}
                >
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    style={{
                      marginRight: "50px",
                      marginLeft: "2%",
                      color: "#000",
                    }}
                  >
                    Scope of Work (SOW) available
                  </FormLabel>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
                {sowMsg ? (
                  <FormHelperText className={classes.errorRoot}>
                    {sowMsg}
                  </FormHelperText>
                ) : (
                  <FormHelperText></FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box>
                <DragDrope
                  handleFileList={handleFileList}
                  sowAvail={sowAvailable}
                  lastAttachments={prevAttachment}
                  setPrevAttachment={setPrevAttachment}
                  setDeletedAttachment={setDeletedAttachment}
                  noFlag={noFlag}
                  setNoFlag={setNoFlag}
                />
                <Box>
                  <Typography
                    variant="p"
                    style={{
                      fontSize: "13px",
                      color: "#CB444A",
                      marginRight: "14px",
                      marginTop: 4,
                      fontFamily: "Montserrat",
                    }}
                  >
                    {dragDropFileError ? dragDropFileError : ""}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  inputVariant="outlined"
                  fullWidth
                  style={{
                    backgroundColor:
                      sowAvailable === "no" ? "#FFFFFF" : "#EEEEEE",
                  }}
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  autoOk
                  id="date-picker-inline"
                  label="Expected Signature Date*"
                  value={expectedSignatureDate ? expectedSignatureDate : null}
                  disabled={sowAvailable === "no" ? false : true}
                  onChange={(e) => {
                    expectedSignatureDateHandler(e);
                    if (e) {
                      const formattedDate = e.toLocaleDateString("en-US");
                      const parsedDate = parse(
                        formattedDate,
                        "MM/dd/yyyy",
                        new Date()
                      );
                      if (
                        !isValid(parsedDate) ||
                        parsedDate.getFullYear() < 1900
                      ) {
                        setExpectedSignatureDateMsg(
                          "*Invalid date. Please enter in 'MM/DD/YYYY' format."
                        );
                      } else {
                        setExpectedSignatureDateMsg("");
                      }
                    }
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  helperText={
                    expectedSignatureDate === null ||
                    expectedSignatureDate == "" ||
                    expectedSignatureDateMsg != null ||
                    expectedSignatureDateMsg != ""
                      ? expectedSignatureDateMsg
                      : ""
                  }
                  FormHelperTextProps={{
                    classes: {
                      root: classes.errorRoot,
                    },
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>

          <Grid
            variant="outlined"
            style={{
              height: "50px",
              color: "#1C3663",
              border: "1.5px solid #B9C3E0",
              borderRadius: "5px",
              backgroundColor: "#EDF2FF",
              marginBottom: "20px",
              marginTop: "30px",
            }}
          >
            <Typography
              variant="h6"
              style={{
                marginLeft: "10px",
                lineHeight: "50px",
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              Salary & Billing Details
            </Typography>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                // className={salaryRangeMsg != ""
                //   ? classes.formControl12
                //   : classes.formControl}
                className={classes.disabledInput}
                label={
                  <span style={{ color: "#000" }}>
                    Recommended Salary Range ({countryCode})*
                  </span>
                }
                name="recommendedSalaryRange"
                value={recommendedSalaryRange ? recommendedSalaryRange : ""}
                disabled
                min="0"
                variant="outlined"
                fullWidth
                margin="normal"
                InputProps={{
                  inputProps: {
                    maxLength: 15,
                  },
                }}
                helperText={
                  viewDetailsList?.recommendedSalaryRange === null &&
                  workingLocationCity &&
                  recomendedSalaryLists?.mobilityRange === null
                    ? "*No data available."
                    : salaryRangeFlag
                    ? salaryRangeMsg
                    : ""
                }
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorRoot,
                  },
                  style:
                    recommendedSalaryRange === "0 - 0"
                      ? { color: "#CB444A", background: "#fff" }
                      : {},
                }}
              />
              <span style={{ fontWeight: 600, fontSize: "12px" }}>
                Note: ME comp should be on the lower side of the range, OP on
                the higher while EE in between.
              </span>
            </Grid>

            <Grid item xs={12} md={6}>
              <NumericFormat
                className={classes.disabledInput}
                label={
                  <span style={{ color: "#000" }}>
                    USCIS Minimum Wage ({countryCode})*
                  </span>
                }
                name="uscisMinimumWage"
                value={uscisMinimumWage ? uscisMinimumWage : ""}
                thousandSeparator={true}
                disabled
                variant="outlined"
                fullWidth
                margin="normal"
                min="0"
                onKeyPress={preventMinus}
                customInput={TextField}
                InputProps={{
                  inputProps: {
                    maxLength: 12,
                  },
                }}
                helperText={
                  viewDetailsList?.usciS_MinimumWage === 0 &&
                  workingLocationCity &&
                  recomendedSalaryLists?.uscisMinWages === 0
                    ? "*No data available."
                    : minimumWageFlag
                    ? minimumWageMsg
                    : ""
                }
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorRoot,
                  },
                  style:
                    uscisMinimumWage === 0
                      ? { color: "#CB444A", background: "#fff" }
                      : {},
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <NumericFormat
                className={
                  compensationMsg != ""
                    ? classes.formControl12
                    : classes.formControl
                }
                // label="Offered Compensation ('${countryCode}')*"
                label={`Offered Gross Compensation (${countryCode})*`}
                name="offeredCompensation"
                value={offeredCompensation ? offeredCompensation : ""}
                thousandSeparator={true}
                onChange={(e) => {
                  if (e.target.value) {
                    setcompensationMsg("");
                  } else {
                    setcompensationMsg(
                      "*Please enter Offered Gross Compensation"
                    );
                  }
                }}
                onKeyDown={handleOfferedCompensation}
                onBlur={handleOfferedCompensation}
                variant="outlined"
                fullWidth
                margin="normal"
                min="0"
                // onKeyPress={preventMinus}
                customInput={TextField}
                InputProps={{
                  inputProps: {
                    maxLength: 12,
                    onKeyPress: preventNegativeAndDecimal,
                  },
                }}
                helperText={
                  offeredCompensation == null ||
                  isOffered ||
                  offeredCompensation == ""
                    ? compensationMsg
                    : ""
                }
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorRoot,
                  },
                }}
              />
              {/* <NumericFormat
               className={compensationMsg != ""
               ? classes.formControl12
               : classes.formControl}
                label="Offered Compensation (USD)*"
                name="offeredCompensation"
                value={offeredCompensation}
                thousandSeparator={true}
                onChange={(e) => {
                  setOfferedCompensation(e.target.value);
                  if(e.target.value){
                    setcompensationMsg('');
                  }
                  else{
                    setcompensationMsg("*Please enter Offered Compensation");
                  }
                }}
                variant="outlined"
                fullWidth
                margin="normal"
                min="0"
                onKeyPress={preventMinus}
                customInput={TextField}
                InputProps={{
                  inputProps: {
                    maxLength: 12,
                  }
                }}
                helperText={
                  offeredCompensation == null || offeredCompensation == ""
                    ? compensationMsg
                    : ""
                }
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorRoot,
                  },
                }}
              /> */}
              {!isOffered && offeredCompensation.length > 0 && (
                <Grid style={{ display: "flex" }}>
                  <Typography
                    style={{
                      fontWeight: 700,
                      color: "#08a332",
                      fontSize: "14px",
                    }}
                  >
                    Fixed Salary({currencySymbol}){" "}
                    {fixed !== undefined && fixed !== null
                      ? fixed.toLocaleString(undefined, {
                          maximumFractionDigits: 0,
                        })
                      : ""}
                  </Typography>

                  <Typography
                    style={{
                      marginLeft: "200px",
                      fontWeight: 700,
                      color: "#08a332",
                      fontSize: "14px",
                    }}
                  >
                    Variable Salary({currencySymbol}){" "}
                    {variable !== undefined && variable !== null
                      ? variable.toLocaleString(undefined, {
                          maximumFractionDigits: 0,
                        })
                      : ""}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <NumericFormat
                className={
                  billRateMsg != ""
                    ? classes.formControl12
                    : classes.formControl
                }
                label={`Bill Rate/Hour (${countryCode})*`}
                name="billRate"
                value={billRate ? billRate : ""}
                thousandSeparator={true}
                onChange={(e) => {
                  const input = e.target.value;

                  setBillRate(input);
                  // handleAllTask();
                  if (input>0) {
                    // console.log("input",input)
                    setbillRateMsg("");
                  } else {
                    setbillRateMsg("*Please enter Bill Rate/Hour");
                  }
                }}
                variant="outlined"
                fullWidth
                margin="normal"
                min="0"
                onKeyPress={preventMinus}
                customInput={TextField}
                InputProps={{
                  inputProps: {
                    maxLength: 12,
                    onKeyPress: preventNegativeAndDecimal,
                  },
                }}
                helperText={
                  billRate == null || billRate == "" ||billRate==0? billRateMsg : ""
                }
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorRoot,
                  },
                }}
              />
              <span style={{ fontWeight: 600, fontSize: "12px" }}>
                Note: Assuming on average 152 working hours.
              </span>
            </Grid>
          </Grid>

          <Accordion
            style={{
              border: "1px solid #B9C3E0",
              borderBottom: "none",
              backgroundColor: "#EDF2FF",
              color: "#000",
              borderRadius: "5px",
              marginTop: "30px",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography style={{ fontSize: "16px" }}>
                    First Year Gross Margin{" "}
                    <span
                      style={{
                        fontSize: "22px",
                        marginLeft: "100px",
                        fontWeight: "bold",
                        color: "#1C3663",
                      }}
                    >
                      {Number(firstYearGM).toFixed(1)}%
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography style={{ fontSize: "16px", marginLeft: "20px" }}>
                    Steady State Gross Margin{" "}
                    <span
                      style={{
                        fontSize: "22px",
                        marginLeft: "100px",
                        fontWeight: "bold",
                        color: "#1C3663",
                      }}
                    >
                      {/* {console.log("steadyStateGmVar", steadyStateGmVar)}
                      {steadyStateGmVar
                        ? steadyStateGmVar */}
                      {Number(steadyStateGM).toFixed(1)}%
                    </span>
                  </Typography>
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: "12px",
                      marginLeft: "20px",
                    }}
                  >
                    Note: Expected Gross Margin is 30%-35%
                  </span>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails
              style={{ backgroundColor: "#fff", color: "#fff" }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        style={{
                          fontSize: "16px",
                          color: "#000",
                          paddingTop: "10px",
                        }}
                      >
                        First Year Additional Cost ({currencySymbol})
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#000",
                          paddingTop: "5px",
                        }}
                      >
                        BGV ({currencySymbol})
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#000",
                          paddingTop: "5px",
                        }}
                      >
                        Visa & Relocation (Employee) ({currencySymbol})
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#000",
                          paddingTop: "5px",
                        }}
                      >
                        Visa & Relocation (Dependent) ({currencySymbol})
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#000",
                          paddingTop: "5px",
                        }}
                      >
                        Settling allowance ({currencySymbol})
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      align="right"
                      style={{ paddingRight: "190px" }}
                    >
                      <Typography
                        style={{
                          fontSize: "16px",
                          color: "#000",
                          paddingTop: "10px",
                        }}
                      >
                        {formatter.format(+firstYearAdditionalCost)}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#000",
                          paddingTop: "5px",
                        }}
                      >
                        {isNaN(bgvCosts)
                          ? 0
                          : formatter.format(Number(bgvCosts))}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#000",
                          paddingTop: "5px",
                        }}
                      >
                        {formatter.format(Number(visaRelocationEmployee))}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#000",
                          paddingTop: "5px",
                        }}
                      >
                        {formatter.format(+visaRelocationDependent)}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#000",
                          paddingTop: "5px",
                        }}
                      >
                        {formatter.format(+settlingAllowance)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        style={{
                          fontSize: "16px",
                          color: "#000",
                          paddingTop: "10px",
                        }}
                      >
                        Annual Cost ({currencySymbol}){" "}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#000",
                          paddingTop: "5px",
                        }}
                      >
                        Salary Cost ({currencySymbol}){" "}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#000",
                          paddingTop: "5px",
                        }}
                      >
                        Payroll Cost ({currencySymbol}){" "}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#000",
                          paddingTop: "5px",
                        }}
                      >
                        Insurance (Company Share) ({currencySymbol}){" "}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#000",
                          paddingTop: "5px",
                        }}
                      >
                        Occupancy ({currencySymbol}){" "}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#000",
                          paddingTop: "5px",
                        }}
                      >
                        Portal Charge ({currencySymbol}){" "}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#000",
                          paddingTop: "5px",
                        }}
                      >
                        Detailed Project Review ({currencySymbol}){" "}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      align="right"
                      style={{ paddingRight: "160px" }}
                    >
                      <Typography
                        style={{
                          fontSize: "16px",
                          color: "#000",
                          paddingTop: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        {formatter.format(+annualCost)}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#000",
                          paddingTop: "5px",
                        }}
                      >
                        {formatter.format(+salaryCost)}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#000",
                          paddingTop: "5px",
                        }}
                      >
                        {formatter.format(Number(payrollCost))}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#000",
                          paddingTop: "5px",
                        }}
                      >
                        {formatter.format(Number(insurance))}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#000",
                          paddingTop: "5px",
                        }}
                      >
                        {formatter.format(Number(occupancy))}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#000",
                          paddingTop: "5px",
                        }}
                      >
                        {formatter.format((+portalCharges).toFixed(1))}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#000",
                          paddingTop: "5px",
                        }}
                      >
                        {formatter.format(Number(dpr))}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {showConfirmation ? (
            <Dialog
              open={showConfirmation}
              onClose={handleConfirmClose}
              className={classes.confirmDialogBody}
            >
              {/* Dialog content */}
              <DialogContent>
                <Typography
                  variant="body1"
                  className={classes.confirmationText}
                >
                  Do you want to Submit this form for Approval?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.yesButton}
                  style={{
                    textTransform: "none",
                    fontSize: "16px",
                  }}
                  disabled={disableYesNo ? true : false}
                  onClick={() => {
                    handleYesButton();
                  }} // Handle user confirmation
                >
                  Yes
                </Button>
                <Button
                  variant="contained"
                  // color="secondary"
                  className={classes.noButton}
                  style={{
                    textTransform: "none",
                    fontSize: "16px",
                  }}
                  disabled={disableYesNo ? true : false}
                  onClick={handleConfirmClose} // Handle user cancellation
                >
                  No
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            <div className="button-container">
              <Button
                variant="contained"
                color="primary"
                className="button"
                type="submit"
                style={{
                  textTransform: "none",
                  fontSize: "16px",
                  backgroundColor: isButtonDisabled ? "#9E9E9E" : "#0B1941",
                }}
                onClick={() => {
                  handleSubmit();
                }}
                disabled={isButtonDisabled}
              >
                Submit for Approval
              </Button>
            </div>
          )}

          <Snackbar open={snackbarOpen} onClose={() => handleSnackClose(false)}>
            <Alert
              onClose={() => handleSnackClose(false)}
              severity="error"
              sx={{ width: "100%", backgroundColor: "red", color: "white" }}
            >
              There is some problem with the application. Please connect with
              System Admin.
            </Alert>
          </Snackbar>

          {/* {/* <Snackbar open={offeredOpen} onClose={() => handleOfferedClose(false)}>
          <Alert onClose={() => handleOfferedClose(false)} severity="error" sx={{width: '100%', backgroundColor: 'red', color: 'white' }}>
          Offered Gross Compensation must fall between Recommended Salary Range.
          </Alert>
        </Snackbar>    */}

          <Snackbar open={fixedOpen} onClose={() => handleFixedClose(false)}>
            <Alert
              onClose={() => handleFixedClose(false)}
              severity="error"
              sx={{ width: "100%", backgroundColor: "red", color: "white" }}
            >
              Fixed Salary should not be less than USCIS Minimum Wage.
            </Alert>
          </Snackbar>

          {/* <div className="button-container">
            <Button
              variant="contained"
              color="primary"
              className="button"
              type="submit"
              style={{
                textTransform: 'none',
                fontSize: '16px'
              }}
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit for Approval
            </Button>
          </div> */}

          {/* <Dialog open={isSubmitted} onBackdropClick={handleClose}>
            <DialogContent className={classes.dialog}>
              <DialogContentText className={classes.contentText}>
                The process has been successfully submitted.
              </DialogContentText>
            </DialogContent>
          </Dialog> */}

          <Dialog
            open={openSowConfirmation}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleSowConfirmationClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Switching SOW Availability?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure want to set availability of SOW as No? You'll lose
                all the uploaded attachments.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSowOkButton}
              >
                Ok
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSowConfirmationClose}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={isSubmitted}
            onClose={handleClose}
            className={classes.dialogBody}
          >
            <DialogContent>
              <CheckCircleIcon className={classes.successIcon} />
              <Typography variant="h6" className={classes.thankYouText}>
                Thank You!
              </Typography>
              <Typography variant="body1" className={classes.messageText}>
                Your request has been submitted to Business <br /> Finance for
                approval.
              </Typography>
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                className={classes.stepper}
              >
                {steps.map((item) => (
                  <Step key={item}>
                    <StepLabel>{item}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </DialogContent>
          </Dialog>

          {!matches ? (
            <div>
              <Dialog
                open={openRating}
                // TransitionComponent={Transition}
                keepMounted
                onClose={handleRatingClose}
                aria-describedby="alert-dialog-slide-description"
              >
                <Box style={{ width: "400px", height: "650px" }}>
                  <DialogTitle>
                    <Typography
                      style={{
                        fontWeight: "600",
                        fontSize: "20px",
                        marginLeft: "10px",
                        textAlignLast: "center",
                      }}
                    >
                      Rate and Review <br></br> {ratingHeading}
                    </Typography>
                    <Divider
                      style={{
                        backgroundColor: "#E1E1E1",
                        width: "100%",
                        height: "0.5px",
                        marginLeft: "2.5%",
                        marginTop: "10px",
                      }}
                      orientation="horizontal"
                    />
                  </DialogTitle>
                  <DialogContent style={{ paddingTop: "12px" }}>
                    <DialogContentText id="alert-dialog-slide-description">
                      <Box>
                        <Box style={{ width: "300px", marginLeft: "20px" }}>
                          <Typography
                            style={{ textAlign: "center", color: "#000" }}
                          >
                            <span
                              style={{ color: "#2CB0FE", fontWeight: "400" }}
                            >
                              {ssoEmpIdDetails ? ssoEmpIdDetails?.empName : ""}
                            </span>
                            , we'd like to know your reviews on {ratingHeading}.
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            width: 300,
                            display: "flex",
                            alignItems: "center",
                            paddingTop: "40px",
                            textAlignLast: "center",
                          }}
                        >
                          <Box sx={{ width: 100 }}></Box>
                          <Box sx={{ width: 200 }}>
                            <Rating
                              name="hover-feedback"
                              value={value}
                              getLabelText={getLabelText}
                              size="large"
                              onChange={(event, newValue) => {
                                newValue === null
                                  ? setValue(-1)
                                  : setValue(newValue);
                              }}
                              onChangeActive={(event, newHover) => {
                                setHover(newHover);
                              }}
                              emptyIcon={
                                <StarOutlineIcon
                                  style={{ opacity: 0.8, color: "#2CB0FE" }}
                                  fontSize="inherit"
                                />
                              }
                              style={{ textAlignLast: "center" }}
                            />
                          </Box>
                          <Box sx={{ width: 50 }}></Box>
                        </Box>

                        {value !== null && ratingLabels && (
                          <Box
                            style={{
                              textAlignLast: "center",
                              paddingTop: "5px",
                              height: "20px",
                            }}
                          >
                            <Typography
                              style={{ fontSize: "14px", color: "#000" }}
                            >
                              {
                                ratingLabels[
                                  hover !== -1 ? hover - 1 : value - 1
                                ]?.ratingDescription
                              }
                            </Typography>
                          </Box>
                        )}
                      </Box>

                      <Box>
                        <Box
                          style={{
                            textAlignLast: "center",
                            paddingTop: "25px",
                          }}
                        >
                          <Typography
                            style={{ fontWeight: "550", color: "#2CB0FE" }}
                          >
                            Write a Review
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            paddingTop: "10px",
                            border: "0.5px solid #000",
                            borderRadius: "5px",
                          }}
                        >
                          <TextField
                            id="review"
                            value={review}
                            placeholder="What did you like or dislike? Write here your feedback about this application."
                            multiline
                            rows={3}
                            onChange={(e) => setReview(e.target.value)}
                            style={{
                              width: "100%",
                            }}
                            InputProps={{
                              inputProps: {
                                maxLength: 200,
                              },
                            }}
                          ></TextField>
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingTop: "6px",
                          }}
                        >
                          <Box style={{ width: "50px" }}></Box>
                          <Box>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                width: "120px",
                                textTransform: "none",
                                backgroundColor: isSubmitDisabled
                                  ? "#9E9E9E"
                                  : "#043365",
                              }}
                              onClick={handleRatingSubmit}
                              disabled={isSubmitDisabled ? true : false}
                            >
                              Submit
                            </Button>
                          </Box>
                          <Box>
                            <Link underline="none" target="_blank">
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  height: "30px",
                                }}
                              >
                                <Typography
                                  onClick={handleRatingSkipClose}
                                  style={{
                                    cursor: "pointer",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                  }}
                                >
                                  Skip {`>`}
                                </Typography>
                              </Box>
                            </Link>
                          </Box>
                        </Box>
                      </Box>
                    </DialogContentText>
                  </DialogContent>
                </Box>
              </Dialog>
            </div>
          ) : (
            <div>
              <Dialog
                open={openRating}
                // TransitionComponent={Transition}
                keepMounted
                onClose={handleRatingClose}
                aria-describedby="alert-dialog-slide-description"
              >
                <Box style={{ width: "300px", height: "500px" }}>
                  <DialogTitle>
                    <Typography
                      style={{
                        fontWeight: "600",
                        fontSize: "20px",
                        marginLeft: "10px",
                        textAlignLast: "center",
                      }}
                    >
                      Rate and Review <br></br> {ratingHeading}
                    </Typography>
                    <Divider
                      style={{
                        backgroundColor: "#E1E1E1",
                        width: "100%",
                        height: "0.5px",
                        marginLeft: "2.5%",
                        marginTop: "10px",
                      }}
                      orientation="horizontal"
                    />
                  </DialogTitle>
                  <DialogContent style={{ paddingTop: "12px" }}>
                    <DialogContentText id="alert-dialog-slide-description">
                      <Box style={{ marginLeft: "-44px" }}>
                        <Box style={{ width: "300px", marginLeft: "20px" }}>
                          <Typography
                            style={{
                              textAlign: "center",
                              color: "#000",
                              width: "18rem",
                            }}
                          >
                            <span
                              style={{ color: "#2CB0FE", fontWeight: "400" }}
                            >
                              {ssoEmpIdDetails ? ssoEmpIdDetails?.empName : ""}
                            </span>
                            , we'd like to know your reviews on {ratingHeading}.
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            width: 300,
                            display: "flex",
                            alignItems: "center",
                            paddingTop: "40px",
                            textAlignLast: "center",
                          }}
                        >
                          <Box sx={{ width: 100 }}></Box>
                          <Box sx={{ width: 200 }}>
                            <Rating
                              name="hover-feedback"
                              value={value}
                              getLabelText={getLabelText}
                              size="large"
                              onChange={(event, newValue) => {
                                newValue === null
                                  ? setValue(-1)
                                  : setValue(newValue);
                              }}
                              onChangeActive={(event, newHover) => {
                                setHover(newHover);
                              }}
                              emptyIcon={
                                <StarOutlineIcon
                                  style={{ opacity: 0.8, color: "#2CB0FE" }}
                                  fontSize="inherit"
                                />
                              }
                              style={{ textAlignLast: "center" }}
                            />
                          </Box>
                          <Box sx={{ width: 50 }}></Box>
                        </Box>

                        {value !== null && ratingLabels && (
                          <Box
                            style={{
                              textAlignLast: "center",
                              paddingTop: "5px",
                              marginLeft: "3rem",
                              height: "1rem",
                            }}
                          >
                            <Typography
                              style={{ fontSize: "14px", color: "#000" }}
                            >
                              {
                                ratingLabels[
                                  hover !== -1 ? hover - 1 : value - 1
                                ]?.ratingDescription
                              }
                            </Typography>
                          </Box>
                        )}
                      </Box>

                      <Box>
                        <Box
                          style={{
                            textAlignLast: "center",
                            paddingTop: "25px",
                          }}
                        >
                          <Typography
                            style={{ fontWeight: "550", color: "#2CB0FE" }}
                          >
                            Write a Review
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            paddingTop: "10px",
                            border: "0.2px solid #000",
                            borderRadius: "5px",
                            marginLeft: "10px",
                          }}
                        >
                          <TextField
                            id="review"
                            value={review}
                            placeholder="What did you like or dislike? Write here your feedback about this application."
                            multiline
                            rows={3}
                            onChange={(e) => setReview(e.target.value)}
                            style={{
                              width: "100%",
                            }}
                            InputProps={{
                              inputProps: {
                                maxLength: 200,
                              },
                            }}
                          ></TextField>
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingTop: "6px",
                          }}
                        >
                          <Box style={{ width: "50px" }}></Box>
                          <Box>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                width: "120px",
                                textTransform: "none",
                                backgroundColor: isSubmitDisabled
                                  ? "#9E9E9E"
                                  : "#043365",
                              }}
                              onClick={handleRatingSubmit}
                              disabled={isSubmitDisabled ? true : false}
                            >
                              Submit
                            </Button>
                          </Box>
                          <Box>
                            <Link underline="none" target="_blank">
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  height: "30px",
                                }}
                              >
                                <Typography
                                  onClick={handleRatingSkipClose}
                                  style={{
                                    cursor: "pointer",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                  }}
                                >
                                  Skip {`>`}
                                </Typography>
                              </Box>
                            </Link>
                          </Box>
                        </Box>
                      </Box>
                    </DialogContentText>
                  </DialogContent>
                </Box>
              </Dialog>
            </div>
          )}
          {!matches ? (
            <div>
              <Dialog
                open={thankYouRatingOpen}
                // TransitionComponent={Transition}
                keepMounted
                // onClose={handleThankyou}
                aria-describedby="alert-dialog-slide-description"
              >
                <Box style={{ width: "400px", height: "350px" }}>
                  <DialogTitle>
                    <CheckCircleIcon className={classes.successIcon} />
                    <Typography
                      style={{
                        fontWeight: "600",
                        fontSize: "20px",
                        marginLeft: "10px",
                        textAlignLast: "center",
                      }}
                    >
                      Thanks for <br></br>Rating and Review.
                    </Typography>
                  </DialogTitle>
                  <DialogContent style={{ paddingTop: "12px" }}>
                    <DialogContentText id="alert-dialog-slide-description">
                      <Box>
                        <Box
                          sx={{
                            width: 300,
                            display: "flex",
                            alignItems: "center",
                            paddingTop: "40px",
                            textAlignLast: "center",
                          }}
                        >
                          <Box sx={{ width: 100 }}></Box>
                          <Box sx={{ width: 200 }}>
                            <Rating
                              name="hover-feedback"
                              value={value}
                              getLabelText={getLabelText}
                              size="large"
                              emptyIcon={
                                <StarOutlineIcon
                                  style={{ opacity: 0.8, color: "#2CB0FE" }}
                                  fontSize="inherit"
                                />
                              }
                              style={{ textAlignLast: "center" }}
                            />
                          </Box>
                          <Box sx={{ width: 50 }}></Box>
                        </Box>
                      </Box>
                    </DialogContentText>
                  </DialogContent>
                </Box>
              </Dialog>
            </div>
          ) : (
            <div>
              <Dialog
                open={thankYouRatingOpen}
                // TransitionComponent={Transition}
                keepMounted
                // onClose={handleThankyou}
                aria-describedby="alert-dialog-slide-description"
              >
                <Box style={{ width: "300px", height: "250px" }}>
                  <DialogTitle>
                    <CheckCircleIcon className={classes.successIcon} />
                    <Typography
                      style={{
                        fontWeight: "600",
                        fontSize: "20px",
                        marginLeft: "10px",
                        textAlignLast: "center",
                      }}
                    >
                      Thanks for <br></br>Rating and Review.
                    </Typography>
                  </DialogTitle>
                  <DialogContent style={{ paddingTop: "12px" }}>
                    <DialogContentText id="alert-dialog-slide-description">
                      <Box style={{ marginLeft: "-44px" }}>
                        <Box
                          sx={{
                            width: 300,
                            display: "flex",
                            alignItems: "center",
                            paddingTop: "40px",
                            textAlignLast: "center",
                          }}
                        >
                          <Box sx={{ width: 100 }}></Box>
                          <Box sx={{ width: 200 }}>
                            <Rating
                              name="hover-feedback"
                              value={value}
                              getLabelText={getLabelText}
                              size="large"
                              emptyIcon={
                                <StarOutlineIcon
                                  style={{ opacity: 0.8, color: "#2CB0FE" }}
                                  fontSize="inherit"
                                />
                              }
                              style={{ textAlignLast: "center" }}
                            />
                          </Box>
                          <Box sx={{ width: 50 }}></Box>
                        </Box>
                      </Box>
                    </DialogContentText>
                  </DialogContent>
                </Box>
              </Dialog>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default GMBUPage;