import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import FilterListIcon from "@material-ui/icons/FilterList";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { useState } from "react";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default function TemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {["Filter 1", "Filter 2", "Filter 3", "Filter 4"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? (
                <span class="material-symbols-outlined">filter_alt</span>
              ) : (
                <FilterListIcon />
              )}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {["Filter 5", "Filter 6", "Filter 7"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? (
                <span class="material-symbols-outlined">filter_alt</span>
              ) : (
                <FilterListIcon />
              )}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );
  const hamburgerList = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {["Home", "Expenses", "Timesheet", "All Expenses"].map(
          (text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>
                {index % 2 === 0 ? (
                  <span class="material-symbols-outlined">filter_alt</span>
                ) : (
                  <FilterListIcon />
                )}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          )
        )}
      </List>
    </div>
  );
  const [anchorFilter1, setAnchorFilter1] = useState("right");
  const [anchorHamburger, setAnchorHamburger] = useState("left");
  return (
    <React.Fragment key={anchorFilter1}>
      <Button onClick={toggleDrawer(anchorHamburger, true)}>
        {anchorHamburger}
      </Button>
      <Button onClick={toggleDrawer(anchorFilter1, true)}>
        {anchorFilter1}
      </Button>

      <Drawer
        anchor={anchorFilter1}
        open={state[anchorFilter1]}
        onClose={toggleDrawer(anchorFilter1, false)}
      >
        {list(anchorFilter1)}
      </Drawer>
      <Drawer
        anchor={anchorHamburger}
        open={state[anchorHamburger]}
        onClose={toggleDrawer(anchorHamburger, false)}
      >
        {hamburgerList(anchorHamburger)}
      </Drawer>
    </React.Fragment>
  );
}
