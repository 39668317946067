import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  Box,
  makeStyles,
  Grid,
  Button,
  Popover,
  TextField,
  InputAdornment,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import ApprovedIcon from "../images/Approved_Icon.svg";
import InProgressIcon from "../images/Inprogress_icon.svg";
import RejectedIcon from "../images/Rejected_icon.svg";
import TotalAppliedIcon from "../images/totalApplied_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardData,
  getDashboardData,
  getDashboardStatusData,
  getGMIdWiseEmployeeData,
  getBUDataList,
  getEmployeeRolesData,
  getDelete,
  resetGmResponse,
} from "../redux/GMCal/gmAction";
import Tooltip from "@material-ui/core/Tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import { useLocation, useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Chip } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import EditIcon from "@material-ui/icons/Edit";
import TablePagination from "@material-ui/core/TablePagination";
import { useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import GMNavBar from "./gmNavBar";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import { NumericFormat } from "react-number-format";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteDialog from "./deleteDialog";
import DeleteSnackbar from "../components/deleteSnackbar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Decrypt from "../Utils/decryption";

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
    //console.log("event1",event)
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootFooter}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: "32px",
    minWidth: "120px",
    "& .MuiFormLabel-root": {
      top: "0px",
      color: "#515961",
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "2px solid #439DD9",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#515961",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0B1941",
      },
    },
  },
  tableRow: {
    //height: 58,
    "&.MuiTableRow-root.MuiTableRow-hover:hover": {
      backgroundColor: "#EFF2F8",
    },
    "&.MuiTableRow-root.Mui-selected": {
      background: "#EFF2F8",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  checkBoxRoot: {
    background: "#0B1941",
    color: "white",
    "&$checked": {
      background: "#0B1941",
      color: "white",
    },
  },
  dropdownStyle: {
    "& ul": {
      padding: 0,
      border: "0.5px solid #515961",
      boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
      borderRadius: 5,
    },
    "& li": {
      height: 47,
      fontSize: 14,
      color: "#515961",
      "&:hover": {
        backgroundColor: "rgba(217, 235, 247, 0.4);",
        color: "#439DD9",
      },
    },
  },
  selectRoot: {
    borderRadius: 8,
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "#439DD9",
      },
    },
    "&:focus": {
      backgroundColor: "transparent",
      borderRadius: 8,
    },
  },
  statusHeading: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "8px",
    color: "rgba(255, 255, 255, 0.9)",
    // opacity: "0.6",
  },
  statusName: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "10px",
    marginRight: "2px",
    // alignItems: "center",
    // paddingLeft: "10px",
    color: "rgba(255, 255, 255, 0.85)",
  },
  statusProjectName: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "8px",
    // alignItems: "center",
    // paddingLeft: "10px",
    marginTop: "2px",
    color: "rgba(255, 255, 255, 0.9)",
    opacity: "0.6",
  },
  statusMessage: {
    fontFamily: "Montserrat",
    fontStyle: "italic",
    fontWeight: 400,
    fontSize: "8px",
    color: "#FFFFFF",
  },

  addExpenseButton: {
    height: "42px",
    width: "180px",
    background: " #0B1941",
    boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    borderRadius: "5px",
    "&:hover": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
    "&:focus": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
  },
  addExpenseButtonMac: {
    height: "42px",
    width: "180px",
    background: " #0B1941",
    boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    borderRadius: "5px",
    "&:hover": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
    "&:focus": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
    "& .MuiButton-root": {
      marginLeft: "630px",
    },
  },
  buttonText: {
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Montserrat",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: 500,
  },
  noteText: {
    color: "#ff0000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Montserrat",
    textTransform: "none",
    fontSize: "10px",
    fontWeight: 500,
  },
  buttonGrid: {
    display: "flex",
    justifyContent: "end",
    marginTop: "32px",
    paddingRight: "54px",
  },
  noteGrid: {
    display: "flex",
    justifyContent: "end",
    marginTop: "8px",
    paddingRight: "54px",
  },
  expenseCard: {
    height: "130px",
    background: "#FFFFFF",
    border: "1px solid #798BBC",
    borderRadius: "4px",
  },
  listCardTop: {
    height: "90px",
    background: "#E3E8F7",
    border: "1px solid #BCC6E4",
    //borderRadius: "4px",
    borderTopRightRadius: "4px",
    borderTopLeftRadius: "4px",
  },
  empId: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    color: "#252733",
  },
  clientHead: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    color: "#757575",
  },
  tooltip: {
    color: "#9E9E9E",
    maxWidth: "22px",
    maxHeight: "22px",
    //marginLeft: "5px"
  },
  empName: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    color: "#0B1941",
    fontSize: "20px",
  },
  clientName: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    color: "#0B1941",
    fontSize: "15px",
  },
  listCardBottom: {
    height: "200px",
    background: "#FFFFFF",
    border: "1px solid #BCC6E4",
    //borderRadius: "4px",
    borderBottomRightRadius: "4px",
    borderBottomLeftRadius: "4px",
  },
  approveText: {
    color: "#4C4C4C",
    alignItems: "center",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
  },
  approveText1: {
    color: "#4C4C4C",
    alignItems: "center",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
  },
  approveCount: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "10px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "28px",
  },
  approveCount1: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "10px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "28px",
  },
  approveCountRed: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "10px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "28px",
    color: "#008CE6",
  },
  approveCountBelowLabel: {
    position: "flex",
    justifyContent: "flex-start",
    width: "60px",
    height: "20px",
    left: "711px",
    top: "256px",

    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "15px",
    /* identical to box height, or 167% */

    display: "flex",
    alignItems: "center",
    color: "#818181",
  },
  approveIcon: {
    marginLeft: "10px",
  },
  approveIcon1: {
    marginLeft: "24px",
    paddingTop: "13px",
  },
  pendingText: {
    // marginLeft: "12px",
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginLeft: "30px",
    paddingRight: "90px",
    marginTop: "30px",
    border: "1px solid #BCC6E4",
  },
  tooltipBox: {
    minWidth: "250px",
    left: "auto!important",
    right: 0,
    // top:'-20px!important'
  },
  arrow: {
    left: "auto!important",

    right: 0,
  },
}));

function GMDashboard(props) {
  const [buhead, setBuhead] = React.useState(10);
  const [chro, setChro] = React.useState(10);
  const [businessFinance, setBusinessFinance] = React.useState(10);
  const [commentData, setCommentData] = React.useState([]);
  const [commentHeading, setCommentHeading] = React.useState("");
  const [toolAction, setToolAction] = React.useState("");
  const target = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState();
  const [billRate, setBillRate] = React.useState();
  const [grossMargin, setGrossMargin] = React.useState();
  const [statusVal, setStatusVal] = React.useState();
  const [locationVal, setLocationVal] = React.useState();
  const [selected1, setSelected1] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [showValu, setShowValu] = React.useState();
  const [showValu1, setShowValu1] = React.useState();
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [page, setPage] = React.useState(0);
  const [anchorEl0, setAnchorEl0] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const [anchorEl5, setAnchorEl5] = React.useState(null);
  const [anchorEl6, setAnchorEl6] = React.useState(null);
  const [filteredRowsData, setFilteredRowsData] = React.useState([]);
  const [filterOffToggle, setFilterOffToggle] = useState("");
  const [buttonState, setButtonState] = React.useState(true);
  const [criteriaClaimAmount, setCriteriaClaimAmount] = React.useState(0);
  const [passBUNamePara, setPassBUNamePara] = React.useState("");
  const [passEmpNamePara, setPassEmpNamePara] = React.useState("");
  const [passEmpIdPara, setPassEmpIdPara] = React.useState(null);
  const [passClientNamePara, setPassClientNamePara] = React.useState("");
  const [passBillRatePara, setBillRatePara] = React.useState("");
  const [passBillStartDatePara, setPassBillStartDatePara] =
    React.useState(null);
  const [passSteadyGrossMargPara, setPassSteadyGrossMargPara] =
    React.useState("");
  const [passStatusPara, setPassStatusPara] = React.useState("");
  //const [totalApprovedVal, setTotalApprovedVal] = useState('');
  const [buHeadVal, setBuHeadVal] = useState(false);
  const [businessFinanceVal, setBuinessFinanceVal] = useState(false);
  const [totalAppliedVal, setTotalAppliedVal] = useState(false);
  const [chroVal, setChroVal] = useState(false);
  const [deliveryHead, setDeliveryHead] = useState(false);
  const [totalApprovedVal, setTotalApprovedVal] = useState(false);
  const [rejectedVal, setRejectedVal] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState();
  const [gmIdVal, setGmIdVal] = React.useState();
  const [openSnackbar, setOpenSnackbar] = React.useState(true);
  const [openDelMsg, setOpenDelMsg] = React.useState(false);
  const [statusCardVal, setStatusCardVal] = React.useState(null);

  const dashboardDataDetails = useSelector(
    (state) => state.gmCalData.gmCalCardList
  );

  const dashboardStatusList = useSelector(
    (state) => state.gmCalData.gmStatusList
  );
  // console.log("status list", dashboardStatusList);
  const dashboardBUDataList = useSelector(
    (state) => state.gmCalData.getBUDataList
  );

  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );

  const GMDelEmpIdRedux = useSelector(
    (state) => state?.gmCalData?.GMDelEmpId
  );

  const employeeRolesDetails = useSelector(
    (state) => state.gmCalData.getEmpRolesList
  );

  const mobilityDeleteDetails = useSelector(
    (state) => state.gmCalData.isDeleteMoblity
  );

  useEffect(() => {

    if(GMDelEmpIdRedux && props.ssoEmpId){
      let obj = {
        empId: (GMDelEmpIdRedux != props.ssoEmpId) ? GMDelEmpIdRedux : props.ssoEmpId,
        
      };

    dispatch(resetGmResponse());
    dispatch(getEmployeeRolesData(obj));
    dispatch(getDashboardData(obj));
    dispatch(getDashboardStatusData(obj));
    dispatch(getBUDataList());

    setBuHeadVal(false);
    setChroVal(false);
    setBuinessFinanceVal(false);
    setDeliveryHead(false);
    setRejectedVal(false);
    setTotalAppliedVal(false);
    setTotalApprovedVal(false);
    setFilterOffToggle(false);
  }
  }, [GMDelEmpIdRedux]);

  useEffect(() => {
    setFilteredRowsData(dashboardBUDataList);
  }, [
    dashboardBUDataList,
    dashboardStatusList,
    dashboardBUDataList,
    dashboardDataDetails,
  ]);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = (comments, nextAction, index) => {
    setOpen(true);
    setCommentData(comments);
    setCommentHeading(nextAction);
    setCurrentIndex(index);
  };

  const handlToolAction = (event) => {
    setToolAction(event.currentTarget);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = (gmId) => {
    setOpenDelMsg(true);
    setGmIdVal(gmId);
  };

  const handleClose = () => {
    setOpenDelMsg(false);
  };

  const handleParentToolTip = () => {
    return (
      <>
        <OutsideClickHandler
          onOutsideClick={() => {
            handleTooltipClose();
          }}
        >
          <div style={{ position: "relative" }}>
            <Tooltip
              PopperProps={{
                disablePortal: true,
                anchorEl: { toolAction },
              }}
              arrow
              enterTouchDelay={0}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={handleToolTip(commentData, commentHeading)}
              classes={{
                popper: classes.tooltipBox,
                arrow: classes.arrow,
              }}
            >
              <span
                style={{ maxwidth: "20px", minWidth: "0px", height: "0" }}
              ></span>
            </Tooltip>
          </div>
        </OutsideClickHandler>
      </>
    );
  };

  const handleToolTip = (comment, heading) => {
    let commentNewVal = comment.split("|");
    return (
      <>
        <Box>
          <Typography
            className={classes.statusHeading}
            style={{ textAlign: "left", paddingTop: "1px" }}
          >
            {heading}
          </Typography>
        </Box>
        <>
          <Box
            style={{
              display: "flex",
              textAlign: "left",
              paddingTop: "3px",
              paddingBottom: "5px",
            }}
          >
            {commentNewVal[0] && (
              <Typography variant="p" className={classes.statusProjectName}>
                {commentNewVal[0]}
              </Typography>
            )}
          </Box>
          <Box>
            <Typography
              className={classes.statusName}
              style={{
                display: "block",
                textAlign: "left",
              }}
            >
              {" "}
              <span style={{ display: "block", paddingLeft: "5px" }}>
                {commentNewVal[0] == "Approved"
                  ? commentNewVal[0]
                  : commentNewVal[1]}{" "}
                {commentNewVal[2] ? "|" : ""} {commentNewVal[2]}
              </span>
            </Typography>{" "}
          </Box>
        </>
      </>
    );
  };

  function clearPayload() {
    setPassEmpIdPara(null);
    setPassEmpNamePara("");
    setPassBillStartDatePara(null);
    setPassSteadyGrossMargPara("");
    setPassStatusPara("");
    setBillRatePara("");
    setPassBUNamePara("");
    setPassClientNamePara("");
    setRejectedVal(false);
    setTotalAppliedVal(false);
    setTotalApprovedVal(false);
    setBuHeadVal(false);
    setBuinessFinanceVal(false);
    setChroVal(false);
    setDeliveryHead(false);
  }
  
  function handleFilterClaimAmount(name) {

    if(GMDelEmpIdRedux && ssoEmpIdDetails?.empId){
      let obj = {
        empId: (GMDelEmpIdRedux != ssoEmpIdDetails?.empId) ? GMDelEmpIdRedux : ssoEmpIdDetails?.empId,
        encryptedIDPara: passEmpIdPara,
        encryptedEmpNamePara: passEmpNamePara,
        encryptedBUNamePara: passClientNamePara,
        encryptedStateNamePara: "",
        encryptedBillStartDatePara: passBillStartDatePara
          ? moment(passBillStartDatePara).format("MM/DD/YYYY")
          : "",
        encryptedsteadyStateGMPara: passSteadyGrossMargPara,
        encryptedStatusPara: passStatusPara,
        encryptedBillRatePara: passBillRatePara,
        encryptedEmpCodePara: "",
      };
      dispatch(getDashboardData(obj));
    }
    handleFilteredArray(dashboardDataDetails);
    setFilterOffToggle(name);
    handleClosePopper();
    clearPayload();
  }

  const handleRemoveFilter = () => {
    handleFilteredArray(dashboardDataDetails);
    handleClosePopper();
  };

  const handleClosePopper = () => {
    setAnchorEl0(null);
    setAnchorEl1(null);
    setAnchorEl2(null);
    setAnchorEl3(null);
    setAnchorEl4(null);
    setAnchorEl5(null);
    setAnchorEl6(null);
    setButtonState(true);
    setCriteriaClaimAmount("");
    clearPayload();
  };

  const handleFilteredArray = (data) => {
    setFilteredRowsData(data);
    setPage(0);
  };

  const handleFilterOff = () => {
    if(GMDelEmpIdRedux && ssoEmpIdDetails?.empId){
      let obj = {
        empId: (GMDelEmpIdRedux != ssoEmpIdDetails?.empId) ? GMDelEmpIdRedux : ssoEmpIdDetails?.empId,
      };
      dispatch(getDashboardData(obj));
    }
    handleFilteredArray(dashboardDataDetails);
    handleClosePopper();
    setFilterOffToggle(false);
    clearPayload();
  };

  const handleFilterPopper0 = (event) => {
    setAnchorEl0(anchorEl0 ? null : event.currentTarget);
  };

  const handleFilterPopper1 = (event) => {
    setAnchorEl1(anchorEl1 ? null : event.currentTarget);
  };

  const handleFilterPopper2 = (event) => {
    setAnchorEl2(anchorEl2 ? null : event.currentTarget);
  };

  const handleFilterPopper3 = (event) => {
    setAnchorEl3(anchorEl3 ? null : event.currentTarget);
  };

  const handleFilterPopper4 = (event) => {
    setAnchorEl4(anchorEl4 ? null : event.currentTarget);
  };

  const handleFilterPopper5 = (event) => {
    setAnchorEl5(anchorEl5 ? null : event.currentTarget);
  };

  const handleFilterPopper6 = (event) => {
    setAnchorEl6(anchorEl6 ? null : event.currentTarget);
  };

  const handleStartDateChange = (date) => {
    //const pickedDate = date ? moment(date).format('DD/MM/YYYY') : '';
    setPassBillStartDatePara(moment(date).format("MM/DD/YYYY"));
    setButtonState(false);
  };

  const handleDelete = () => {
    if(GMDelEmpIdRedux && ssoEmpIdDetails?.empId){
      let obj = {
        empId: (GMDelEmpIdRedux != ssoEmpIdDetails?.empId) ? GMDelEmpIdRedux : ssoEmpIdDetails?.empId,
        gmId: gmIdVal ? gmIdVal : "",
      };
      dispatch(getDelete(obj));
      dispatch(getDashboardData(obj));
      dispatch(getDashboardStatusData(obj));
      dispatch(getBUDataList());
      setGmIdVal("");
      handleFilterOff();
      handleClose();
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const open0 = Boolean(anchorEl0);
  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);
  const open4 = Boolean(anchorEl4);
  const open5 = Boolean(anchorEl5);
  const open6 = Boolean(anchorEl6);

  const id0 = open0 ? "simple-popper" : undefined;
  const id1 = open1 ? "simple-popper" : undefined;
  const id2 = open2 ? "simple-popper" : undefined;
  const id3 = open3 ? "simple-popper" : undefined;
  const id4 = open4 ? "simple-popper" : undefined;
  const id5 = open5 ? "simple-popper" : undefined;
  const id6 = open6 ? "simple-popper" : undefined;

  return (
    <>
      {employeeRolesDetails && employeeRolesDetails[0]?.role  == "Mobility" && (
        <Grid item xs={12} sm={12} md={12} className={classes.buttonGrid}>
          <Button
            className={
              window.screen.availWidth == 1792
                ? classes.addExpenseButtonMac
                : classes.addExpenseButton
            }
            onClick={() => history.push("/gmmobility")}
          >
            <Typography className={classes.buttonText}>
              + Initiate Mobility
            </Typography>
          </Button>
        </Grid>
      )}
      {employeeRolesDetails && employeeRolesDetails[0]?.role  == "Mobility" && (
        <Grid item xs={12} sm={12} md={12} className={classes.noteGrid}>
          <Typography variant="p" className={classes.noteText}>
            *Applicable only for Billable Employees
          </Typography>
        </Grid>
      )}
      <Box>
        <Grid
          container
          style={{
            marginTop:
            employeeRolesDetails && employeeRolesDetails[0]?.role  == "Mobility" ? "10px" : "30px",
            paddingRight:
              window.screen.availWidth == 1536
                ? "224px"
                : window.screen.availWidth == 1920
                ? "272px"
                : "190px",
            paddingLeft: "30px",
            width: "113%",
          }}
          spacing={2}
        >
          <Grid item xs={3} sm={3} md={3}>
            <Grid item xs={3} sm={3} md={12} className={classes.expenseCard}>
              <Box>
                <Typography variant="p" className={classes.approveIcon}>
                  <img
                    src={TotalAppliedIcon}
                    style={{ width: "44px", marginTop: "42px" }}
                  />
                </Typography>
                <Box
                  style={{
                    display: "inline-block",
                    marginTop: "12px",
                    marginLeft: "20px",
                  }}
                >
                  <Typography variant="p" className={classes.approveText}>
                    {"Total Applied"}
                  </Typography>
                  {!totalAppliedVal ? (
                    <Typography
                      variant="p"
                      className={classes.approveCount}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        let obj = {
                          empId: ((GMDelEmpIdRedux && ssoEmpIdDetails?.empId) && (GMDelEmpIdRedux != ssoEmpIdDetails?.empId) ? GMDelEmpIdRedux : ssoEmpIdDetails?.empId),
                          encryptedStatusPara: "",
                          pendingBU: "",
                          pendingFinance: "",
                        };
                        dispatch(getDashboardData(obj));
                        setTotalAppliedVal(true);
                        setBuHeadVal(false);
                        setBuinessFinanceVal(false);
                        setRejectedVal(false);
                        setChroVal(false);
                        setDeliveryHead(false);
                        setTotalApprovedVal(false);
                        setFilterOffToggle(false);
                      }}
                    >
                      {dashboardStatusList?.totalApplied}
                    </Typography>
                  ) : (
                    <Typography
                      variant="p"
                      className={classes.approveCountRed}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        let obj = {
                          empId: ((GMDelEmpIdRedux && ssoEmpIdDetails?.empId) && (GMDelEmpIdRedux != ssoEmpIdDetails?.empId) ? GMDelEmpIdRedux : ssoEmpIdDetails?.empId),
                          encryptedStatusPara: "",
                        };
                        dispatch(getDashboardData(obj));
                        setTotalAppliedVal(false);
                      }}
                    >
                      {dashboardStatusList?.totalApplied}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>
            {/* ))} */}
          </Grid>

          <Grid item xs={9} sm={9} md={9} style={{ maxWidth: "100.666667%" }}>
            <Grid
              item
              xs={9}
              sm={9}
              md={9}
              className={classes.expenseCard}
              style={{ maxWidth: "100.666667%" }}
            >
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="p" className={classes.approveIcon}>
                    <img
                      src={ApprovedIcon}
                      style={{ width: "44px", marginTop: "20px" }}
                    />
                  </Typography>
                  <Box
                    style={{
                      display: "inline-block",
                      marginLeft: "20px",
                    }}
                  >
                    <Typography variant="p" className={classes.approveText}>
                      {"Total Approved"}
                    </Typography>
                    {!totalApprovedVal ? (
                      <Typography
                        variant="p"
                        className={classes.approveCount}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          let obj = {
                            empId: ((GMDelEmpIdRedux && ssoEmpIdDetails?.empId) && (GMDelEmpIdRedux != ssoEmpIdDetails?.empId) ? GMDelEmpIdRedux : ssoEmpIdDetails?.empId),
                            encryptedStatusPara: "Approved",
                          };
                          dispatch(getDashboardData(obj));
                          setTotalApprovedVal(true);
                          setBuHeadVal(false);
                          setBuinessFinanceVal(false);
                          setChroVal(false);
                          setDeliveryHead(false);
                          setRejectedVal(false);
                          setTotalAppliedVal(false);
                          setFilterOffToggle(false);
                        }}
                      >
                        {dashboardStatusList?.totalApproved}
                      </Typography>
                    ) : (
                      <Typography
                        variant="p"
                        className={classes.approveCountRed}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          let obj = {
                            empId: ((GMDelEmpIdRedux && ssoEmpIdDetails?.empId) && (GMDelEmpIdRedux != ssoEmpIdDetails?.empId) ? GMDelEmpIdRedux : ssoEmpIdDetails?.empId),
                            encryptedStatusPara: "",
                          };
                          dispatch(getDashboardData(obj));
                          setTotalApprovedVal(false);
                        }}
                      >
                        {dashboardStatusList?.totalApproved}
                      </Typography>
                    )}
                  </Box>
                  <Box>
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      style={{
                        height: "100px",
                        marginTop: "10px",
                        border: "1px solid #798BBC",
                        marginLeft:
                          window.screen.availWidth == 1536
                            ? "60px"
                            : window.screen.availWidth == 1920
                            ? "130px"
                            : "",
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: window.screen.availWidth == 1920 ? "32px" : "",
                  }}
                >
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="p" className={classes.pendingText}>
                      <img
                        src={InProgressIcon}
                        style={{ width: "44px", marginTop: "20px" }}
                      />
                    </Typography>
                    <Box
                      style={{
                        display: "inline-block",
                        marginTop: "22px",
                        marginLeft: "15px",
                      }}
                    >
                      <Typography variant="p" className={classes.approveText1}>
                        {"Pending"}
                      </Typography>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                      <Box>
                          {!deliveryHead ? (
                            <Typography
                              variant="p"
                              className={classes.approveCount}
                              style={{ 
                                // marginLeft: "2px",
                                 cursor: "pointer",
                                //  marginBottom:"9px"
                              marginRight:"48px", marginLeft:"20px"}}
                              onClick={() => {
                                let obj = {
                                  empId: ((GMDelEmpIdRedux && ssoEmpIdDetails?.empId) && (GMDelEmpIdRedux != ssoEmpIdDetails?.empId) ? GMDelEmpIdRedux : ssoEmpIdDetails?.empId),
                                  pendingBU: "",
                                   pendingDeliveryHead:"DH",
                                  pendingFinance: "",
                                  pendingCHRO: "",
                                };
                                dispatch(getDashboardData(obj));
                                setBuHeadVal(false);
                                setChroVal(false);
                                setBuinessFinanceVal(false);
                                setDeliveryHead(true);
                                setRejectedVal(false);
                                setTotalAppliedVal(false);
                                setTotalApprovedVal(false);
                                setFilterOffToggle(false);
                              }}
                            >
                              {dashboardStatusList?.pendingDeliveryHead}
                            </Typography>
                          ) : (
                            <Typography
                              variant="p"
                              className={classes.approveCountRed}
                              style={{ 
                                  // marginLeft: "2px",
                                  cursor: "pointer",
                                  //  marginBottom:"9px"
                                marginRight:"48px",marginLeft:"20px"  }}
                              onClick={() => {
                                let obj = {
                                  empId: ((GMDelEmpIdRedux && ssoEmpIdDetails?.empId) && (GMDelEmpIdRedux != ssoEmpIdDetails?.empId) ? GMDelEmpIdRedux : ssoEmpIdDetails?.empId),
                                    pendingDeliveryHead: "",
                                };
                                dispatch(getDashboardData(obj));
                                setDeliveryHead(false);
                              }}
                            >
                              {dashboardStatusList?.pendingDeliveryHead}
                            </Typography>
                          )}
                          <Typography
                            variant="p"
                            className={classes.approveCountBelowLabel}
                            style={{width:"92px"}}
                          >
                            {" "}
                           Delivery Head
                          </Typography>
                        </Box>
                        <Box>
                          {!buHeadVal ? (
                            <Typography
                              variant="p"
                              className={classes.approveCount1}
                              style={{ marginLeft: "5px", cursor: "pointer" }}
                              onClick={() => {
                                let obj = {
                                  empId:((GMDelEmpIdRedux && ssoEmpIdDetails?.empId) && (GMDelEmpIdRedux != ssoEmpIdDetails?.empId) ? GMDelEmpIdRedux : ssoEmpIdDetails?.empId),
                                  pendingBU: "BU",
                                  pendingDeliveryHead:"",
                                  pendingFinance: "",
                                  pendingCHRO: "",
                                };
                                dispatch(getDashboardData(obj));
                                setBuHeadVal(true);
                                setChroVal(false);
                                setBuinessFinanceVal(false);
                                setDeliveryHead(false);
                                setRejectedVal(false);
                                setTotalAppliedVal(false);
                                setTotalApprovedVal(false);
                                setFilterOffToggle(false);
                              }}
                            >
                              {dashboardStatusList?.pendingBU}
                            </Typography>
                          ) : (
                            <Typography
                              variant="p"
                              className={classes.approveCountRed}
                              style={{ marginLeft: "5px", cursor: "pointer" }}
                              onClick={() => {
                                let obj = {
                                  empId: ((GMDelEmpIdRedux && ssoEmpIdDetails?.empId) && (GMDelEmpIdRedux != ssoEmpIdDetails?.empId) ? GMDelEmpIdRedux : ssoEmpIdDetails?.empId),
                                  pendingBU: "",
                                };
                                dispatch(getDashboardData(obj));
                                setBuHeadVal(false);
                              }}
                            >
                              {dashboardStatusList?.pendingBU}
                            </Typography>
                          )}
                          <Typography
                            variant="p"
                            className={classes.approveCountBelowLabel}
                            style={{marginleft:'3px'}}
                          >
                            {" "}
                            BU Head
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            display: "inline-block",
                            marginLeft: "10px",
                          }}
                        >
                          {!businessFinanceVal ? (
                            <Typography
                              variant="p"
                              className={classes.approveCount1}
                              style={{ marginLeft: "24px", cursor: "pointer" }}
                              onClick={() => {
                                let obj = {
                                  empId: ((GMDelEmpIdRedux && ssoEmpIdDetails?.empId) && (GMDelEmpIdRedux != ssoEmpIdDetails?.empId) ? GMDelEmpIdRedux : ssoEmpIdDetails?.empId),
                                  pendingFinance: "Finance",
                                  pendingDeliveryHead:"",
                                  pendingBU: "",
                                  pendingCHRO: "",
                                };
                                dispatch(getDashboardData(obj));
                                setBuinessFinanceVal(true);
                                setBuHeadVal(false);
                                setChroVal(false);
                                setDeliveryHead(false);
                                setRejectedVal(false);
                                setTotalAppliedVal(false);
                                setTotalApprovedVal(false);
                                setFilterOffToggle(false);
                              }}
                            >
                              {dashboardStatusList?.pendingFinance}
                            </Typography>
                          ) : (
                            <Typography
                              variant="p"
                              className={classes.approveCountRed}
                              style={{ marginLeft: "24px", cursor: "pointer" }}
                              onClick={() => {
                                let obj = {
                                  empId: ((GMDelEmpIdRedux && ssoEmpIdDetails?.empId) && (GMDelEmpIdRedux != ssoEmpIdDetails?.empId) ? GMDelEmpIdRedux : ssoEmpIdDetails?.empId),
                                  pendingFinance: "",
                                };
                                dispatch(getDashboardData(obj));
                                setBuinessFinanceVal(false);
                              }}
                            >
                              {dashboardStatusList?.pendingFinance}
                            </Typography>
                          )}
                          <Typography
                            variant="p"
                            className={classes.approveCountBelowLabel}
                            style={{ width: "105px" }}
                          >
                            {" "}
                            Business Finance
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            display: "inline-block",
                            marginLeft: "10px",
                          }}
                        >
                          {!chroVal ? (
                            <Typography
                              variant="p"
                              className={classes.approveCount1}
                              style={{ marginLeft: "2px", cursor: "pointer" }}
                              onClick={() => {
                                let obj = {
                                  empId: ((GMDelEmpIdRedux && ssoEmpIdDetails?.empId) && (GMDelEmpIdRedux != ssoEmpIdDetails?.empId) ? GMDelEmpIdRedux : ssoEmpIdDetails?.empId),
                                  pendingCHRO: "CHRO",
                                  pendingDeliveryHead:"",
                                  pendingBU: "",
                                  pendingFinance: "",
                                };
                                dispatch(getDashboardData(obj));
                                setChroVal(true);
                                setBuHeadVal(false);
                                setBuinessFinanceVal(false);
                                setRejectedVal(false);
                                setDeliveryHead(false);
                                setTotalAppliedVal(false);
                                setTotalApprovedVal(false);
                                setFilterOffToggle(false);
                              }}
                            >
                              {dashboardStatusList?.pendingCHRO}
                            </Typography>
                          ) : (
                            <Typography
                              variant="p"
                              className={classes.approveCountRed}
                              style={{ marginLeft: "2px", cursor: "pointer" }}
                              onClick={() => {
                                let obj = {
                                  empId: ((GMDelEmpIdRedux && ssoEmpIdDetails?.empId) && (GMDelEmpIdRedux != ssoEmpIdDetails?.empId) ? GMDelEmpIdRedux : ssoEmpIdDetails?.empId),
                                  pendingCHRO: "",
                                };
                                dispatch(getDashboardData(obj));
                                setChroVal(false);
                              }}
                            >
                              {dashboardStatusList?.pendingCHRO}
                            </Typography>
                          )}
                          <Typography
                            variant="p"
                            className={classes.approveCountBelowLabel}
                          >
                            {" "}
                            CHRO
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        style={{
                          height: "100px",
                          marginTop: "10px",
                          border: "1px solid #798BBC",
                          marginLeft:
                            window.screen.availWidth == 1536
                              ? "37px"
                              : window.screen.availWidth == 1920
                              ? "80px"
                              : "-5px",
                          marginRight:
                            window.screen.availWidth == 1536 ? "34px" : "",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft:
                        window.screen.availWidth == 1920 ? "45px" : "10px",
                    }}
                  >
                    <Typography variant="p">
                      <img
                        src={RejectedIcon}
                        style={{ width: "44px", marginTop: "20px" }}
                      />
                    </Typography>
                    <Box
                      style={{
                        display: "inline-block",
                        marginTop: "2px",
                        marginLeft: "20px",
                      }}
                    >
                      <Typography variant="p" className={classes.approveText}>
                        {"Rejected"}
                      </Typography>
                      {!rejectedVal ? (
                        <Typography
                          variant="p"
                          className={classes.approveCount}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            let obj = {
                              empId:((GMDelEmpIdRedux && ssoEmpIdDetails?.empId) && (GMDelEmpIdRedux != ssoEmpIdDetails?.empId) ? GMDelEmpIdRedux : ssoEmpIdDetails?.empId),
                              encryptedStatusPara: "Rejected",
                            };
                            dispatch(getDashboardData(obj));
                            setRejectedVal(true);
                            setTotalAppliedVal(false);
                            setTotalApprovedVal(false);
                            setBuHeadVal(false);
                            setBuinessFinanceVal(false);
                            setChroVal(false);
                            setDeliveryHead(false);
                            setFilterOffToggle(false);
                          }}
                        >
                          {dashboardStatusList?.totalRejected}
                        </Typography>
                      ) : (
                        <Typography
                          variant="p"
                          className={classes.approveCountRed}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            let obj = {
                              empId: ((GMDelEmpIdRedux && ssoEmpIdDetails?.empId) && (GMDelEmpIdRedux != ssoEmpIdDetails?.empId) ? GMDelEmpIdRedux : ssoEmpIdDetails?.empId),
                              encryptedStatusPara: "",
                            };
                            dispatch(getDashboardData(obj));
                            setRejectedVal(false);
                          }}
                        >
                          {dashboardStatusList?.totalRejected}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        style={{ marginTop: "30px", marginLeft: "30px", marginRight: "54px" }}
      >
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead style={{ backgroundColor: "#E3E8F7" }}>
              <TableRow>
                <TableCell>
                  Emp Name
                  {filterOffToggle == "empName" ? (
                    <IconButton
                      aria-label="filter list"
                      onClick={handleFilterOff}
                      style={{ color: "#BB271A", padding: "5px" }}
                    >
                      <span
                        className="material-symbols-outlined"
                        color="#BB271A"
                      >
                        filter_alt_off
                      </span>
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="filter list"
                      onClick={handleFilterPopper0}
                      style={{
                        color: "#666666",
                        padding: "5px",
                        size: "small",
                      }}
                    >
                      <span className="material-symbols-outlined">
                        filter_alt
                      </span>
                    </IconButton>
                  )}
                  {
                    <Box>
                      <Popover
                        id={id0}
                        open={open0}
                        onClose={handleClosePopper}
                        anchorEl={anchorEl0}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Paper
                          style={{
                            width: "300px",
                            height: "277px",
                          }}
                        >
                          <Box>
                            <Box
                              boxShadow={1}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "15px",
                              }}
                            >
                              <Box>
                                <span className="material-symbols-outlined">
                                  filter_alt
                                </span>
                              </Box>
                              <Box>
                                <Typography
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    lineHeight: "28px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                    color: "#0B1941",
                                    paddingRight: "70px",
                                  }}
                                >
                                  Emp Name
                                </Typography>
                              </Box>
                              <Box>
                                <Button>
                                  <CloseIcon onClick={handleClosePopper} />
                                </Button>
                              </Box>
                            </Box>

                            <Box style={{ marginTop: "15px" }}>
                              <TextField
                                id="outlined-basic"
                                label="Search By Emp Name"
                                variant="outlined"
                                style={{
                                  width: "244px",
                                  height: "52px",
                                  marginLeft: "28px",
                                  // marginTop: "8px",
                                  color: "#0B1941",
                                  marginBottom: "15px",
                                }}
                                onChange={(e) => {
                                  if (e.target.value.length >= 1) {
                                    setButtonState(false);
                                    setPassEmpNamePara(e.target.value);
                                  }
                                  if (e.target.value.length == 0) {
                                    setButtonState(true);
                                  }
                                  setCriteriaClaimAmount(
                                    e.target.value.trimStart()
                                  );
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      style={{ marginRight: "-12px" }}
                                    >
                                      <IconButton>
                                        <SearchIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Box>
                            <Box>
                              <NumericFormat
                                id="outlined-basic"
                                label="Search By Employee ID"
                                variant="outlined"
                                customInput={TextField}
                                style={{
                                  width: "244px",
                                  height: "52px",
                                  marginLeft: "28px",
                                  color: "#0B1941",
                                }}
                                onChange={(e) => {
                                  if (e.target.value.length >= 1) {
                                    setButtonState(false);
                                    setPassEmpIdPara(e.target.value);
                                  }
                                  if (e.target.value.length == 0) {
                                    setButtonState(true);
                                  }
                                  setCriteriaClaimAmount(
                                    e.target.value.trimStart()
                                  );
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      style={{ marginRight: "-12px" }}
                                    >
                                      <IconButton>
                                        <SearchIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                marginTop: "25px",
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={handleRemoveFilter}
                                style={{
                                  background: "#FFFFFF",
                                  color: "#0B1941",
                                  borderRadius: "4px",
                                  border: "1px solid #0B1941",
                                }}
                              >
                                Remove Filter
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                disabled={buttonState}
                                style={{
                                  background: buttonState
                                    ? "#9E9E9E"
                                    : "#0B1941",
                                  color: "#FFFFFF",
                                  borderRadius: "4px",
                                }}
                                onClick={(e) =>
                                  handleFilterClaimAmount("empName")
                                }
                              >
                                Apply Filter
                              </Button>
                            </Box>
                          </Box>
                        </Paper>
                      </Popover>
                    </Box>
                  }
                </TableCell>
                <TableCell align="left">
                  Client
                  {filterOffToggle == "client" ? (
                    <IconButton
                      aria-label="filter list client"
                      onClick={handleFilterOff}
                      style={{ color: "#BB271A", padding: "5px" }}
                    >
                      <span
                        className="material-symbols-outlined"
                        color="#BB271A"
                      >
                        filter_alt_off
                      </span>
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="filter list client"
                      onClick={handleFilterPopper1}
                      style={{
                        color: "#666666",
                        padding: "5px",
                        size: "small",
                      }}
                    >
                      <span className="material-symbols-outlined">
                        filter_alt
                      </span>
                    </IconButton>
                  )}
                  {
                    <Box>
                      <Popover
                        id={id1}
                        open={open1}
                        onClose={handleClosePopper}
                        anchorEl={anchorEl1}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Paper
                          style={{
                            width: "300px",
                            height: "277px",
                          }}
                        >
                          <Box>
                            <Box
                              boxShadow={1}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "15px",
                              }}
                            >
                              <Box>
                                <span className="material-symbols-outlined">
                                  filter_alt
                                </span>
                              </Box>
                              <Box>
                                <Typography
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    lineHeight: "28px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                    color: "#0B1941",
                                    paddingRight: "70px",
                                  }}
                                >
                                  Client
                                </Typography>
                              </Box>
                              <Box>
                                <Button>
                                  <CloseIcon onClick={handleClosePopper} />
                                </Button>
                              </Box>
                            </Box>

                            <Box style={{ marginTop: "15px" }}>
                              <TextField
                                id="outlined-basic-client"
                                label="Search By Client Name"
                                variant="outlined"
                                style={{
                                  width: "244px",
                                  height: "52px",
                                  marginLeft: "28px",
                                  // marginTop: "8px",
                                  color: "#0B1941",
                                  marginBottom: "15px",
                                }}
                                onChange={(e) => {
                                  if (e.target.value.length >= 1) {
                                    setButtonState(false);
                                    setPassClientNamePara(e.target.value);
                                  }
                                  if (e.target.value.length == 0) {
                                    setButtonState(true);
                                  }
                                  setCriteriaClaimAmount(
                                    e.target.value.trimStart()
                                  );
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      style={{ marginRight: "-12px" }}
                                    >
                                      <IconButton>
                                        <SearchIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                marginTop: "25px",
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={handleRemoveFilter}
                                style={{
                                  background: "#FFFFFF",
                                  color: "#0B1941",
                                  borderRadius: "4px",
                                  border: "1px solid #0B1941",
                                }}
                              >
                                Remove Filter
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                disabled={buttonState}
                                style={{
                                  background: buttonState
                                    ? "#9E9E9E"
                                    : "#0B1941",
                                  color: "#FFFFFF",
                                  borderRadius: "4px",
                                }}
                                onClick={(e) =>
                                  handleFilterClaimAmount("client")
                                }
                              >
                                Apply Filter
                              </Button>
                            </Box>
                          </Box>
                        </Paper>
                      </Popover>
                    </Box>
                  }
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <Box style={{ width: "92px" }}>
                    Bill Rate
                    {filterOffToggle == "billRate" ? (
                      <IconButton
                        aria-label="filter list billRate"
                        onClick={handleFilterOff}
                        style={{ color: "#BB271A", padding: "5px" }}
                      >
                        <span
                          className="material-symbols-outlined"
                          color="#BB271A"
                        >
                          filter_alt_off
                        </span>
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="filter list bill rate"
                        onClick={handleFilterPopper2}
                        style={{
                          color: "#666666",
                          padding: "5px",
                          size: "small",
                        }}
                      >
                        <span className="material-symbols-outlined">
                          filter_alt
                        </span>
                      </IconButton>
                    )}
                    {
                      <Box>
                        <Popover
                          id={id2}
                          open={open2}
                          onClose={handleClosePopper}
                          anchorEl={anchorEl2}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <Paper
                            style={{
                              width: "300px",
                              height: "277px",
                            }}
                          >
                            <Box>
                              <Box
                                boxShadow={1}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  padding: "15px",
                                }}
                              >
                                <Box>
                                  <span className="material-symbols-outlined">
                                    filter_alt
                                  </span>
                                </Box>
                                <Box>
                                  <Typography
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "500",
                                      lineHeight: "28px",
                                      letterSpacing: "0px",
                                      textAlign: "left",
                                      color: "#0B1941",
                                      paddingRight: "70px",
                                    }}
                                  >
                                    Bill Rate
                                  </Typography>
                                </Box>
                                <Box>
                                  <Button>
                                    <CloseIcon onClick={handleClosePopper} />
                                  </Button>
                                </Box>
                              </Box>

                              <Box style={{ marginTop: "15px" }}>
                                <NumericFormat
                                  id="outlined-basic-bill-rate"
                                  label="Search By Bill Rate"
                                  variant="outlined"
                                  customInput={TextField}
                                  style={{
                                    width: "244px",
                                    height: "52px",
                                    marginLeft: "28px",
                                    // marginTop: "8px",
                                    color: "#0B1941",
                                    marginBottom: "15px",
                                  }}
                                  onChange={(e) => {
                                    if (e.target.value.length >= 1) {
                                      setButtonState(false);
                                      setBillRatePara(e.target.value);
                                    }
                                    if (e.target.value.length == 0) {
                                      setButtonState(true);
                                    }
                                    setCriteriaClaimAmount(
                                      e.target.value.trimStart()
                                    );
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        style={{ marginRight: "-12px" }}
                                      >
                                        <IconButton>
                                          <SearchIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Box>
                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                  alignItems: "center",
                                  marginTop: "25px",
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  onClick={handleRemoveFilter}
                                  style={{
                                    background: "#FFFFFF",
                                    color: "#0B1941",
                                    borderRadius: "4px",
                                    border: "1px solid #0B1941",
                                  }}
                                >
                                  Remove Filter
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  disabled={buttonState}
                                  style={{
                                    background: buttonState
                                      ? "#9E9E9E"
                                      : "#0B1941",
                                    color: "#FFFFFF",
                                    borderRadius: "4px",
                                  }}
                                  onClick={(e) =>
                                    handleFilterClaimAmount("billRate")
                                  }
                                >
                                  Apply Filter
                                </Button>
                              </Box>
                            </Box>
                          </Paper>
                        </Popover>
                      </Box>
                    }
                  </Box>
                </TableCell>
                <TableCell align="left">
                  <Box style={{ width: "155px" }}>
                    Billing Start Date
                    {filterOffToggle == "billingStartDate" ? (
                      <IconButton
                        aria-label="filter list"
                        onClick={handleFilterOff}
                        style={{ color: "#BB271A", padding: "5px" }}
                      >
                        <span
                          className="material-symbols-outlined"
                          color="#BB271A"
                        >
                          filter_alt_off
                        </span>
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="filter list"
                        onClick={handleFilterPopper3}
                        style={{
                          color: "#666666",
                          padding: "5px",
                          size: "small",
                        }}
                      >
                        <span className="material-symbols-outlined">
                          filter_alt
                        </span>
                      </IconButton>
                    )}
                    {
                      <Box>
                        <Popover
                          id={id3}
                          open={open3}
                          onClose={handleClosePopper}
                          anchorEl={anchorEl3}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <Paper
                            style={{
                              width: "300px",
                              height: "277px",
                            }}
                          >
                            <Box>
                              <Box
                                boxShadow={1}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  padding: "15px",
                                }}
                              >
                                <Box>
                                  <span className="material-symbols-outlined">
                                    filter_alt
                                  </span>
                                </Box>
                                <Box>
                                  <Typography
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "500",
                                      lineHeight: "28px",
                                      letterSpacing: "0px",
                                      textAlign: "left",
                                      color: "#0B1941",
                                      paddingRight: "44px",
                                    }}
                                  >
                                    Billing Start Date
                                  </Typography>
                                </Box>
                                <Box>
                                  <Button>
                                    <CloseIcon onClick={handleClosePopper} />
                                  </Button>
                                </Box>
                              </Box>

                              <Box
                                style={{
                                  marginTop: "10px",
                                  marginLeft: "20px",
                                }}
                              >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    disableToolbar
                                    inputVariant="outlined"
                                    style={{
                                      maxWidth: "251px",
                                      maxHeight: "53px",
                                    }}
                                    className={classes.formControl}
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    autoOk
                                    // changeYear={true}
                                    // changeMonth={true}
                                    // disableFuture={true}
                                    id="date-picker-inline"
                                    label="Choose Bill Start Date"
                                    value={passBillStartDatePara}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date",
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </Box>
                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                  alignItems: "center",
                                  marginTop: "25px",
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  onClick={handleRemoveFilter}
                                  style={{
                                    background: "#FFFFFF",
                                    color: "#0B1941",
                                    borderRadius: "4px",
                                    border: "1px solid #0B1941",
                                  }}
                                >
                                  Remove Filter
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  disabled={buttonState}
                                  style={{
                                    background: buttonState
                                      ? "#9E9E9E"
                                      : "#0B1941",
                                    color: "#FFFFFF",
                                    borderRadius: "4px",
                                  }}
                                  onClick={(e) =>
                                    handleFilterClaimAmount("billingStartDate")
                                  }
                                >
                                  Apply Filter
                                </Button>
                              </Box>
                            </Box>
                          </Paper>
                        </Popover>
                      </Box>
                    }
                  </Box>
                </TableCell>
                <TableCell align="left" style={{ padding: "8px" }}>
                  <Box style={{ width: "155px" }}>
                    Steady State GM
                    {filterOffToggle == "steadyStateGrossMargin" ? (
                      <IconButton
                        aria-label="filter list steadyStateGrossMargin"
                        onClick={handleFilterOff}
                        style={{ color: "#BB271A", padding: "5px" }}
                      >
                        <span
                          className="material-symbols-outlined"
                          color="#BB271A"
                        >
                          filter_alt_off
                        </span>
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="filter list steadyStateGrossMargin"
                        onClick={handleFilterPopper4}
                        style={{
                          color: "#666666",
                          padding: "5px",
                          size: "small",
                        }}
                      >
                        <span className="material-symbols-outlined">
                          filter_alt
                        </span>
                      </IconButton>
                    )}
                    {
                      <Box>
                        <Popover
                          id={id4}
                          open={open4}
                          onClose={handleClosePopper}
                          anchorEl={anchorEl4}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <Paper
                            style={{
                              width: "300px",
                              height: "277px",
                            }}
                          >
                            <Box>
                              <Box
                                boxShadow={1}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  padding: "15px",
                                }}
                              >
                                <Box>
                                  <span className="material-symbols-outlined">
                                    filter_alt
                                  </span>
                                </Box>
                                <Box>
                                  <Typography
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "500",
                                      lineHeight: "28px",
                                      letterSpacing: "0px",
                                      textAlign: "left",
                                      color: "#0B1941",
                                      paddingRight: "30px",
                                    }}
                                  >
                                    Steady State Gross Margin
                                  </Typography>
                                </Box>
                                <Box>
                                  <Button>
                                    <CloseIcon onClick={handleClosePopper} />
                                  </Button>
                                </Box>
                              </Box>

                              <Box style={{ marginTop: "15px" }}>
                                <NumericFormat
                                  id="outlined-basic steadyStateGrossMargin"
                                  label="Search Steady State GM"
                                  variant="outlined"
                                  customInput={TextField}
                                  style={{
                                    width: "244px",
                                    height: "52px",
                                    marginLeft: "28px",
                                    // marginTop: "8px",
                                    color: "#0B1941",
                                    marginBottom: "15px",
                                  }}
                                  onChange={(e) => {
                                    if (e.target.value.length >= 1) {
                                      setButtonState(false);
                                      setPassSteadyGrossMargPara(
                                        e.target.value
                                      );
                                    }
                                    if (e.target.value.length == 0) {
                                      setButtonState(true);
                                    }
                                    setCriteriaClaimAmount(
                                      e.target.value.trimStart()
                                    );
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        style={{ marginRight: "-12px" }}
                                      >
                                        <IconButton>
                                          <SearchIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Box>
                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                  alignItems: "center",
                                  marginTop: "25px",
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  onClick={handleRemoveFilter}
                                  style={{
                                    background: "#FFFFFF",
                                    color: "#0B1941",
                                    borderRadius: "4px",
                                    border: "1px solid #0B1941",
                                  }}
                                >
                                  Remove Filter
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  disabled={buttonState}
                                  style={{
                                    background: buttonState
                                      ? "#9E9E9E"
                                      : "#0B1941",
                                    color: "#FFFFFF",
                                    borderRadius: "4px",
                                  }}
                                  onClick={(e) =>
                                    handleFilterClaimAmount(
                                      "steadyStateGrossMargin"
                                    )
                                  }
                                >
                                  Apply Filter
                                </Button>
                              </Box>
                            </Box>
                          </Paper>
                        </Popover>
                      </Box>
                    }
                  </Box>
                </TableCell>
                <TableCell align="left">
                  Status
                  {filterOffToggle == "status" ? (
                    <IconButton
                      aria-label="filter list sttus"
                      onClick={handleFilterOff}
                      style={{ color: "#BB271A", padding: "5px" }}
                    >
                      <span
                        className="material-symbols-outlined"
                        color="#BB271A"
                      >
                        filter_alt_off
                      </span>
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="filter list status"
                      onClick={handleFilterPopper5}
                      style={{
                        color: "#666666",
                        padding: "5px",
                        size: "small",
                      }}
                    >
                      <span className="material-symbols-outlined">
                        filter_alt
                      </span>
                    </IconButton>
                  )}
                  {
                    <Box>
                      <Popover
                        id={id5}
                        open={open5}
                        onClose={handleClosePopper}
                        anchorEl={anchorEl5}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Paper
                          style={{
                            width: "300px",
                            height: "277px",
                          }}
                        >
                          <Box>
                            <Box
                              boxShadow={1}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "15px",
                              }}
                            >
                              <Box>
                                <span className="material-symbols-outlined">
                                  filter_alt
                                </span>
                              </Box>
                              <Box>
                                <Typography
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    lineHeight: "28px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                    color: "#0B1941",
                                    paddingRight: "30px",
                                  }}
                                >
                                  Status
                                </Typography>
                              </Box>
                              <Box>
                                <Button>
                                  <CloseIcon onClick={handleClosePopper} />
                                </Button>
                              </Box>
                            </Box>
                            <Box
                              style={{ marginTop: "15px", marginLeft: "52px" }}
                            >
                              <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                style={{ minWidth: "200px" }}
                              >
                                <InputLabel id="demo-simple-select-label">
                                  Select a Status
                                </InputLabel>
                                <Select
                                  value={passStatusPara}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label="Select a Status"
                                  onChange={(e) => {
                                    if (e.target.value.length >= 1) {
                                      setButtonState(false);
                                      setPassStatusPara(e.target.value);
                                    }
                                    if (e.target.value.length == 0) {
                                      setButtonState(true);
                                    }
                                    setCriteriaClaimAmount(
                                      e.target.value.trimStart()
                                    );
                                  }}
                                >
                                  {[
                                    "Initiated",
                                    "In Review",
                                    "Approved",
                                    "Rejected",
                                  ].map((value) => {
                                    return (
                                      <MenuItem value={value}>{value}</MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                marginTop: "25px",
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={handleRemoveFilter}
                                style={{
                                  background: "#FFFFFF",
                                  color: "#0B1941",
                                  borderRadius: "4px",
                                  border: "1px solid #0B1941",
                                }}
                              >
                                Remove Filter
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                disabled={buttonState}
                                style={{
                                  background: buttonState
                                    ? "#9E9E9E"
                                    : "#0B1941",
                                  color: "#FFFFFF",
                                  borderRadius: "4px",
                                }}
                                onClick={(e) =>
                                  handleFilterClaimAmount("status")
                                }
                              >
                                Apply Filter
                              </Button>
                            </Box>
                          </Box>
                        </Paper>
                      </Popover>
                    </Box>
                  }
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dashboardDataDetails &&
                dashboardDataDetails
                  //stableSort(dashboardDataDetails && dashboardDataDetails, getComparator(order, orderBy))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={row.gmId} className={classes.tableRow} hover>
                      <TableCell
                        component="th"
                        scope="row"
                        onClick={() => {
                          const text = "/detailviewpage/:id";
                          const result = text.replace(":id", row.gmId);
                          history.push({
                            pathname: result,
                            gmId: row.gmId,
                          });
                        }}
                      >
                        {row.empName}
                        {row.empCode && (
                          <Box style={{ fontSize: "0.675rem" }}>
                            ID : {row.empCode}
                          </Box>
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={() => {
                          const text = "/detailviewpage/:id";
                          const result = text.replace(":id", row.gmId);
                          history.push({
                            pathname: result,
                            gmId: row.gmId,
                          });
                        }}
                      >
                        {row?.clientName != "NA" ? row?.clientName : "-"}
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={() => {
                          const text = "/detailviewpage/:id";
                          const result = text.replace(":id", row.gmId);
                          history.push({
                            pathname: result,
                            gmId: row.gmId,
                          });
                        }}
                      >
                        {row?.billRate != 0 && row?.currencySymbol}
                        {row?.billRate != 0
                          ? row?.billRate
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : "-"}
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={() => {
                          const text = "/detailviewpage/:id";
                          const result = text.replace(":id", row.gmId);
                          history.push({
                            pathname: result,
                            gmId: row.gmId,
                          });
                        }}
                      >
                        {row?.plannedBillingStartDate != "NA"
                          ? moment(row.plannedBillingStartDate).format(
                              "MMM DD, YYYY"
                            )
                          : "-"}
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={() => {
                          const text = "/detailviewpage/:id";
                          const result = text.replace(":id", row.gmId);
                          history.push({
                            pathname: result,
                            gmId: row.gmId,
                          });
                        }}
                      >
                        {row?.steadyStateGM != "NA"
                          ? parseFloat(row.steadyStateGM).toFixed(1)
                          : "-"}
                        {row?.steadyStateGM != "NA" ? "%" : ""}
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={() => {
                          const text = "/detailviewpage/:id";
                          const result = text.replace(":id", row.gmId);
                          history.push({
                            pathname: result,
                            gmId: row.gmId,
                          });
                        }}
                      >
                        <Box
                          style={{ display: "flex", justifyContent: "left" }}
                        >
                          <Chip
                            label={row?.status}
                            style={{
                              color: "white",
                              width: "97px",
                              height: "24px",
                              marginRight: "0px",
                              background:
                                row?.status === "In Review"
                                  ? "#E0B15C"
                                  : row?.status === "Approved"
                                  ? " #349259"
                                  : row?.status === "Rejected"
                                  ? "#D40606"
                                  : row?.status === "Initiated"
                                  ? "#406086"
                                  : "#9E9E9E",
                              // "#349259"
                            }}
                          />
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box
                          style={{ display: "flex", justifyContent: "left" }}
                        >
                          {row?.status != "Approved" && (
                            <InfoOutlinedIcon
                              ref={target}
                              onClick={(event) => {
                                handleTooltipOpen(
                                  row.comments,
                                  row.nextAction,
                                  index
                                );
                                handlToolAction(event);
                              }}
                              className={classes.tooltip}
                            />
                          )}
                          {open &&
                            index === currentIndex &&
                            handleParentToolTip()}
                          {row?.editableFlag && (
                            <EditIcon
                              style={{ marginLeft: "4px" }}
                              onClick={() => {
                                const text = "/gmbupage/:id";
                                const result = text.replace(":id", row.gmId);
                                history.push({
                                  pathname: result,
                                  gmId: row.gmId,
                                });
                              }}
                            ></EditIcon>
                          )}
                          {row?.status === "Initiated" &&
                            employeeRolesDetails && employeeRolesDetails[0]?.role  == "Mobility" && (
                              <DeleteIcon
                                style={{ marginLeft: "4px" }}
                                onClick={() => {
                                  handleClickOpen(row?.gmId);
                                }}
                              ></DeleteIcon>
                            )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
              {dashboardDataDetails && dashboardDataDetails.length == 0 && (
                <TableRow className={classes.tableRow}>
                  <TableCell
                    colSpan={12}
                    style={{ minWidth: "120px", paddingLeft: "460px" }}
                  >
                    No Data Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="caption"
                style={{
                  paddingLeft: "8px",
                }}
              >
                <InfoOutlinedIcon />
              </Typography>
              <Typography
                variant="caption"
                style={{
                  paddingLeft: "8px",
                }}
              >
                <b>Click on any row to view details</b>
              </Typography>
            </Box>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="Box"
              count={dashboardDataDetails?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              //ActionsComponent={TablePaginationActions}
            />
          </Box>
        </TableContainer>
      </Box>
      <Dialog
        open={openDelMsg}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ alignContent: "center", display: "flex" }}
        >
          {"Delete Mobility"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this mobility ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {mobilityDeleteDetails && mobilityDeleteDetails ? (
        <DeleteSnackbar
          handleCloseSnackbar={handleCloseSnackbar}
          open={openSnackbar}
          gmCalDash={true}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default GMDashboard;