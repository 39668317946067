import React from "react";
import "../css/style.css";
import Typography from "@material-ui/core/Typography";
import Multiselect from "../components/multiSelect";
import { Box, Button, Grid, makeStyles, Paper } from "@material-ui/core";
import loginImage from "../images/sso.png";
import Vector1 from "../images/Vector1.svg";
import incedoLogo from "../images/incedo-logo.svg";
import { useMsal, useAccount } from "@azure/msal-react";
import { loginApiRequest } from "../Utils/authConfig";
import "../css/login.css";
import { hover } from "@testing-library/user-event/dist/hover";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import loginImage12pro from "../images/12pro.png";
import loginImageDuo from "../images/Duo.png";
import loginImageSE from "../images/SE.png";
import loginImagePixel from "../images/pixel.png";
import loginImageResponsive from "../images/responsive.png";
import loginImageS8plus from "../images/s8plus.png";
import loginImageUltra from "../images/ultra.png";
import loginImageXR from "../images/xr.png";


const useStyles = makeStyles((theme) => ({
  root: {},

  control: {
    padding: theme.spacing(2),
  },
  bgImage: {
    // background: `url(${loginImage}) no-repeat center fixed`,
    backgroundColor: "grey",
    backgroundSize: "cover",
    height: "100%",
    position: "absolute",
    zIndex: "3",
    top: "0",
    left: "0",
    width: "100%"
  },
  bgImage12pro: {
    background: `url(${loginImage12pro}) no-repeat center fixed`,
    backgroundSize: "cover",
    height: "100%",
    position: "absolute",
    zIndex: "3",
    top: "0",
    left: "0",
    width: "100%"
  },
  bgImageDuo: {
    background: `url(${loginImageDuo}) no-repeat center fixed`,
    backgroundSize: "cover",
    height: "100%",
    position: "absolute",
    zIndex: "3",
    top: "0",
    left: "0",
    width: "100%"
  },
  bgImageSE: {
    background: `url(${loginImageSE}) no-repeat center fixed`,
    backgroundSize: "cover",
    height: "100%",
    position: "absolute",
    zIndex: "3",
    top: "0",
    left: "0",
    width: "100%"
  },
  bgImageGrey: {
    backgroundColor: 'grey',
    backgroundSize: "cover",
    height: "100%",
    position: "absolute",
    zIndex: "3",
    top: "0",
    left: "0",
    width: "100%"
  },
  bgImagePixel: {
    background: `url(${loginImagePixel}) no-repeat center fixed`,
    backgroundSize: "cover",
    height: "100%",
    position: "absolute",
    zIndex: "3",
    top: "0",
    left: "0",
    width: "100%"
  },
  bgImageResponsive: {
    background: `url(${loginImageResponsive}) no-repeat center fixed`,
    backgroundSize: "cover",
    height: "100%",
    position: "absolute",
    zIndex: "3",
    top: "0",
    left: "0",
    width: "100%"
  },
  bgImageS8plus: {
    background: `url(${loginImageS8plus}) no-repeat center fixed`,
    backgroundSize: "cover",
    height: "100%",
    position: "absolute",
    zIndex: "3",
    top: "0",
    left: "0",
    width: "100%"
  },
  bgImageUltra: {
    background: `url(${loginImageUltra}) no-repeat center fixed`,
    backgroundSize: "cover",
    height: "100%",
    position: "absolute",
    zIndex: "3",
    top: "0",
    left: "0",
    width: "100%"
  },
  bgImageXR: {
    background: `url(${loginImageXR}) no-repeat center fixed`,
    backgroundSize: "cover",
    height: "100%",
    position: "absolute",
    zIndex: "3",
    top: "0",
    left: "0",
    width: "100%"
  },
  logo100: {
    maxHeight: "75px",
  },
  logo100OnePlus: {
    maxHeight: "54px",
  },
  logo125: {
    maxWidth: "200px",
    maxHeight: "53px",
  },
  logo150: {
    maxWidth: "200px",
    maxHeight: "53px",
  },
  box100: {
    minWidth: "700px",
    height: "570px",
  },
  box125: {
    maxWidth: "529px",
    height: "450px",
  },
  box150: {
    maxWidth: "529px",
    height: "450px",
  },
  title1_100: {
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "75px",
    letterSpacing: "0px",
    textAlign: "center",
  },
  title1_100OnePlus: {
    fontFamily: "Montserrat",
    fontSize: "17px",
    fontWeight: "700",
    lineHeight: "75px",
    letterSpacing: "0px",
    textAlign: "center",
  },
  title1_100SE: {
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "32px",
    letterSpacing: "0px",
    textAlign: "center",
  },
  title1_125: {
    fontFamily: "Montserrat",
    fontSize: "32px",
    fontWeight: "600",
    lineHeight: "40px",
    letterSpacing: "0px",
    textAlign: "center",
  },
  title1_150: {
    fontFamily: "Montserrat",
    fontSize: "32px",
    fontWeight: "600",
    lineHeight: "40px",
    letterSpacing: "0px",
    textAlign: "center",
  },
  title2_100: {
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "20px",
    letterSpacing: "0.20000000298023224px",
    textAlign: "center",
  },
  title2_125: {
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "20px",
    letterSpacing: "0.20000000298023224px",
    textAlign: "center",
  },
  title2_150: {
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "20px",
    letterSpacing: "0.20000000298023224px",
    textAlign: "center",
  },
  button100: {
    backgroundColor: "#0B1941",
    minWidth: "500px",
    minHeight: "70px",
    '&:hover':{
      backgroundColor:"#043365"
    }
  },
  button100OnePlus1: {
    backgroundColor: "#0B1941",
    minWidth: "345px",
    minHeight: "42px",
    '&:hover':{
      backgroundColor:"#043365"
    }
  },
  button100SE: {
    backgroundColor: "#0B1941",
    minWidth: "300px",
    minHeight: "42px",
    '&:hover':{
      backgroundColor:"#043365"
    }
  },
  button100SEOnePlus: {
    backgroundColor: "#0B1941",
    minWidth: "212px",
    minHeight: "42px",
    '&:hover':{
      backgroundColor:"#043365"
    }
  },
  button125: {
    backgroundColor: "##0B1941",
    minWidth: "455px",
    minHeight: "70px",
    '&:hover':{
      backgroundColor:"#043365"
    }
  },
  button150: {
    backgroundColor: "#0B1941",
    minWidth: "455px",
    minHeight: "58px",
    '&:hover':{
      backgroundColor:"#043365"
    }
  },
  text12:{
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "18px",
    letterSpacing: "0.20000000298023224px",
    // textAlign: "center",
    color: "#252733",
    marginLeft: "-9px",
    textAlign: "center"
  },
  text12One:{
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "18px",
    letterSpacing: "0.20000000298023224px",
    // textAlign: "center",
    color: "#252733",
    marginLeft: "0px",
    textAlign: "center",
    paddingLeft:"7px",
  },
  text12SE:{
    fontFamily: "Montserrat",
    fontSize: "10px",
    fontWeight: "600",
    lineHeight: "18px",
    letterSpacing: "0.20000000298023224px",
    // textAlign: "center",
    color: "#252733",
    paddingLeft:"7px",
    marginLeft: "-9px",
    textAlign: "center"
  },
  text12OnePlus:{
    fontFamily: "Montserrat",
    fontSize: "9px",
    fontWeight: "600",
    lineHeight: "18px",
    letterSpacing: "0.20000000298023224px",
    // textAlign: "center",
    color: "#252733",
    marginLeft: "5px",
    textAlign: "center"
  },
  text13:{
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "599",
    lineHeight: "18px",
    letterSpacing: "0.20000000298023224px",
    // textAlign: "center",
    color: "#252733",
  },
  // .click:hover{
  //   backgroundColor:"red"
  // },
  mobileBoxPosition: {
    position: "relative",
    zIndex: "4",
    width: "35rem",
    left: "0vw",
    top: "12vw",
    backgroundImage: 
 "url('../images/sso.png')",
  },
  mobileBoxPositionSE: {
    position: "relative",
    zIndex: "4",
    width: "22.5rem",
    left: "0vw",
    top: "12vw",
    backgroundImage: 
 "url('../images/sso.png')",
  },
  mobileBoxPositionXR: {
    position: "relative",
    zIndex: "4",
    width: "22.5rem",
    left: "5vw",
    top: "12vw",
    backgroundImage: 
 "url('../images/sso.png')",
  },
  mobileBoxPositionS8: {
    position: "relative",
    zIndex: "4",
    width: "22.5rem",
    left: "-2vw",
    top: "12vw",
    backgroundImage: 
 "url('../images/sso.png')",
  },
  mobileBoxPositionOnePlus: {
    position: "relative",
    zIndex: "4",
    width: "5.5rem",
    left: "-2vw",
    top: "12vw",
    backgroundColor: 'grey'
  },
  mobileBoxPositionOnePlus1: {
    position: "relative",
    zIndex: "4",
    width: "5.5rem",
    left: "6vw",
    top: "12vw",
    backgroundColor: 'grey'
  },
  mobileBoxPositionOnePlus2: {
    position: "relative",
    zIndex: "4",
    width: "25rem",
    left: "6vw",
    top: "12vw",
    backgroundColor: 'grey'
  },
  mobileBoxPositionDuo: {
    position: "relative",
    zIndex: "4",
    width: "33rem",
    left: "0vw",
    top: "12vw",
    backgroundImage: 
 "url('../images/sso.png')",
  },
  mobileBox100: {
    minWidth: "20rem",
    height: "35.625rem",
  },
  mobileBox100OnePlus: {
    minWidth: "17rem",
    height: "35.625rem",
  },
  mobleOverlay: {
    height: "100%",
    position: "absolute",
    zIndex: "3",
    top: "0",
    left: "0",
    width: "85%"
  }
}));

const MobileLogin = () => {
  const classes = useStyles();
  const { instance, accounts } = useMsal();
  const matches = useMediaQuery("(max-width:600px)");

  const handleClick = () => {
    //console.log("button click");
    localStorage.removeItem("token");
    instance.loginRedirect(loginApiRequest).catch((e) => {
      //console.log(e);
    });
  };
  return (
    <div id="hero" className={window.screen.availWidth < 600 && window.screen.availWidth > 540 ? 
    classes.bgImageResponsive : window.screen.availWidth == 375 ? classes.bgImageSE 
    : window.screen.availWidth == 414 ? classes.bgImageXR 
    : window.screen.availWidth == 390 ? classes.bgImage12pro 
    : window.screen.availWidth == 393 ? classes.bgImagePixel
    : window.screen.availWidth == 360 ? classes.bgImageS8plus
    : window.screen.availWidth == 412 ? classes.bgImageUltra
    : window.screen.availWidth == 540 ? classes.bgImageDuo
    : (window.screen.availWidth < 360 && window.screen.availWidth > 280 ) ? classes.bgImageGrey
    : (window.screen.availWidth < 414 && window.screen.availWidth > 393 ) ? classes.bgImageGrey
    : (window.screen.availWidth < 393 && window.screen.availWidth > 360 ) ? classes.bgImageGrey
    : classes.bgImage}>
      <div
        className={ window.screen.availWidth == 375 ? classes.mobileBoxPositionSE 
          : (window.screen.availWidth == 414 || window.screen.availWidth == 390
            || window.screen.availWidth == 393 || window.screen.availWidth == 412) ? classes.mobileBoxPositionXR
            : window.screen.availWidth == 360 ? classes.mobileBoxPositionS8
            : window.screen.availWidth == 540 ? classes.mobileBoxPositionDuo
            : (window.screen.availWidth < 360 && window.screen.availWidth > 315 ) ? classes.mobileBoxPositionOnePlus1
            : (window.screen.availWidth < 316 && window.screen.availWidth > 280 ) ? classes.mobileBoxPositionOnePlus
            : (window.screen.availWidth < 414 && window.screen.availWidth > 393 ) ? classes.mobileBoxPositionXR
            : (window.screen.availWidth < 393 && window.screen.availWidth > 360 ) ? classes.mobileBoxPositionS8
            : (window.screen.availWidth < 500 && window.screen.availWidth > 414 ) ? classes.mobileBoxPositionOnePlus2
            : matches ? classes.mobileBoxPosition :
          window.screen.availWidth == 1280
            ? "content150"
            : window.screen.availWidth == 1536
            ? "content125"
            : "content100"
        }
      >
        <Box
        border={4}
        borderLeft={0}
        borderRight={0}
          className={(window.screen.availWidth < 360 && window.screen.availWidth > 280) ? classes.mobileBox100OnePlus 
            : matches ? classes.mobileBox100 :
            window.screen.availWidth == 1280
              ? classes.box150
              : window.screen.availWidth == 1536
              ? classes.box125
              : classes.box100
          }
          //style={{backgroundColor: "red"}}
          style={{
            maxWidth: "37.25rem",
            height: "26.25rem",
            boxShadow:
              "-1px 0px 8px rgba(0, 0, 0, 0.13), 0px 4px 8px rgba(0, 0, 0, 0.17)",
            borderRadius: "8px 8px 8px 7px",
            backgroundColor: "white",
            marginTop: '5.625rem',
            marginLeft: '0.75rem'
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "22px",
              paddingTop: "15px",
            }}
          >
            <img
              src={incedoLogo}
              className={
                window.screen.availWidth == 1280
                  ? classes.logo150
                  : window.screen.availWidth == 1536
                  ? classes.logo125
                  : (window.screen.availWidth < 360 && window.screen.availWidth > 280 ) ? classes.logo100OnePlus
                  : classes.logo100
              }
              //style={{ maxWidth: "200px", maxHeight: "53px" }}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "8px",
            }}
          >
            <Typography
              className={
                window.screen.availWidth == 1280
                  ? classes.title1_150
                  : window.screen.availWidth == 1536
                  ? classes.title1_125
                  : (window.screen.availWidth == 375 || window.screen.availWidth == 414
                    || window.screen.availWidth == 390 || window.screen.availWidth == 393
                    || window.screen.availWidth == 360 || window.screen.availWidth == 412)
                  ? classes.title1_100SE 
                  : (window.screen.availWidth < 360 && window.screen.availWidth > 280 ) ? classes.title1_100SE
                  : (window.screen.availWidth < 414 && window.screen.availWidth > 393 ) ? classes.title1_100SE
                  : (window.screen.availWidth < 393 && window.screen.availWidth > 360 ) ? classes.title1_100SE
                  : (window.screen.availWidth < 500 && window.screen.availWidth > 414 ) ? classes.title1_100OnePlus
                  : classes.title1_100
              }
              // style={{
              //   fontFamily: "Montserrat",
              //   fontSize: "32px",
              //   fontWeight: "600",
              //   lineHeight: "40px",
              //   letterSpacing: "0px",
              //   textAlign: "center",
              // }}
            >
              Welcome to Expense and Timesheet Portal
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "24px",
            }}
          >
            <Typography
              className={
                window.screen.availWidth == 1280
                  ? classes.title2_150
                  : window.screen.availWidth == 1536
                  ? classes.title2_125
                  : classes.title2_100
              }
              // style={{
              //   fontFamily: "Montserrat",
              //   fontSize: "20px",
              //   fontWeight: "600",
              //   lineHeight: "20px",
              //   letterSpacing: "0.20000000298023224px",
              //   textAlign: "center",
              // }}
            >
              Sign In to Proceed
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "46px",
              maxHeight:"42px",
            }}
          >
            <Button
              className={
                window.screen.availWidth == 1280
                  ? classes.button150
                  : window.screen.availWidth == 1536
                  ? classes.button125
                  : (window.screen.availWidth == 375 || window.screen.availWidth == 414
                    || window.screen.availWidth == 390 || window.screen.availWidth == 393
                    || window.screen.availWidth == 360 || window.screen.availWidth == 412)
                  ? classes.button100SE
                  : (window.screen.availWidth < 360 && window.screen.availWidth > 281 ) ? classes.button100SEOnePlus
                  : (window.screen.availWidth < 414 && window.screen.availWidth > 393 ) ? classes.button100SE
                  : (window.screen.availWidth < 393 && window.screen.availWidth > 360 ) ? classes.button100SE
                  : (window.screen.availWidth < 500 && window.screen.availWidth > 414 ) ? classes.button100OnePlus1
                  : classes.button100
              }
              onClick={() => handleClick()}            
            >
              <Typography
                style={{
                  color: "#FFFFFFE5",
                  fontFamily: "Montserrat",
                  fontSize:
                    window.screen.availWidth == 1280
                      ? "16px"
                      : window.screen.availWidth == 1536
                      ? "16px"
                      : "21px",
                  fontWeight: "600",
                  lineHeight: "20px",
                  letterSpacing: "-0.08399999886751175px",
                  textAlign: "center",
                }}
              >
                Sign In{" "}
              </Typography>{" "}
            </Button>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "12px",
              marginLeft:
                window.screen.availWidth == 1280
                  ? "22px"
                  : window.screen.availWidth == 1536
                  ? "22px"
                  : "0px",
            }}
          >
            <Typography
              className={
                window.screen.availWidth == 1792
                  ? classes.text13
                  : (window.screen.availWidth == 375 || window.screen.availWidth == 414 
                    || window.screen.availWidth == 390 || window.screen.availWidth == 393
                    || window.screen.availWidth == 360 || window.screen.availWidth == 412)
                  ? classes.text12SE
                  : (window.screen.availWidth < 360 && window.screen.availWidth > 281 ) ? classes.text12OnePlus
                  : (window.screen.availWidth < 414 && window.screen.availWidth > 393 ) ? classes.text12SE
                  : (window.screen.availWidth < 393 && window.screen.availWidth > 360 ) ? classes.text12SE 
                  : (window.screen.availWidth < 500 && window.screen.availWidth > 414 ) ? classes.text12One
                  : classes.text12}
            >
              In case of any technical issue, please reach out to support team
              (IncedoInternalSystems@incedoinc.com)
            </Typography>
          </Box>
        </Box>
      </div>
      <div
        className={matches? 'mobleOverlay' :"overlay"}
        style={{ background: `url(${loginImage})`, backgroundSize: "cover" }}
      />
    </div>
    // <Box className={classes.root}>
    //   <Box className="bg_image"></Box>;
    //   <Box
    //     style={{
    //       maxWidth: "529px",
    //       height: "490px",
    //       boxShadow:
    //         "-1px 0px 8px rgba(0, 0, 0, 0.13), 0px 4px 8px rgba(0, 0, 0, 0.17)",
    //       borderRadius: "8px 8px 8px 7px",
    //     }}
    //   >
    //     <Box
    //       style={{
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         marginTop: "72px",
    //       }}
    //     >
    //       <img
    //         src={incedoLogo}
    //         style={{ maxWidth: "200px", maxHeight: "53px" }}
    //       />
    //     </Box>
    //     <Box
    //       style={{
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         marginTop: "72px",
    //       }}
    //     >
    //       <Typography
    //         style={{
    //           fontFamily: "Montserrat",
    //           fontSize: "32px",
    //           fontWeight: "600",
    //           lineHeight: "40px",
    //           letterSpacing: "0px",
    //           textAlign: "center",
    //         }}
    //       >
    //         Welcome to Expense and Timesheet Portal
    //       </Typography>
    //     </Box>
    //     <Box
    //       style={{
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         marginTop: "24px",
    //       }}
    //     >
    //       <Typography
    //         style={{
    //           fontFamily: "Montserrat",
    //           fontSize: "20px",
    //           fontWeight: "500",
    //           lineHeight: "20px",
    //           letterSpacing: "0.20000000298023224px",
    //           textAlign: "left",
    //         }}
    //       >
    //         Sign In to Proceed
    //       </Typography>
    //     </Box>
    //     <Box
    //       style={{
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         marginTop: "46px",
    //       }}
    //     >
    //       <Button
    //         style={{
    //           backgroundColor: "#043365",
    //           minWidth: "455px",
    //           minHeight: "58px",
    //         }}
    //         onClick={() => handleClick()}
    //       >
    //         <Typography
    //           style={{
    //             color: "#FFFFFFE5",
    //             fontFamily: "Montserrat",
    //             fontSize: "16px",
    //             fontWeight: "600",
    //             lineHeight: "20px",
    //             letterSpacing: "-0.08399999886751175px",
    //             textAlign: "center",
    //           }}
    //         >
    //           Sign In{" "}
    //         </Typography>{" "}
    //       </Button>
    //     </Box>
    //   </Box>
    // </Box>
  );
};
export default MobileLogin;
{
  /* <Box>
        <Grid container justifyContent="center" spacing={0}>
          <Grid
            key={1}
            item
            xs={6}
            sm={6}
            md={6}
            style={{ display: "contents" }}
          >
            <Box role="img">
              <img src={loginImage} className={classes.image} />
            </Box>
          </Grid>
          <Grid
            key={2}
            item
            xs={6}
            sm={6}
            md={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                maxWidth: "529px",
                height: "490px",
                boxShadow:
                  "-1px 0px 8px rgba(0, 0, 0, 0.13), 0px 4px 8px rgba(0, 0, 0, 0.17)",
                borderRadius: "8px 8px 8px 7px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "72px",
                }}
              >
                <img
                  src={incedoLogo}
                  style={{ maxWidth: "200px", maxHeight: "53px" }}
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "72px",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "32px",
                    fontWeight: "600",
                    lineHeight: "40px",
                    letterSpacing: "0px",
                    textAlign: "center",
                  }}
                >
                  Welcome to Expense and Timesheet Portal
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "24px",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "20px",
                    fontWeight: "500",
                    lineHeight: "20px",
                    letterSpacing: "0.20000000298023224px",
                    textAlign: "left",
                  }}
                >
                  Sign In to Proceed
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "46px",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#043365",
                    minWidth: "455px",
                    minHeight: "58px",
                  }}
                  onClick={() => handleClick()}
                >
                  <Typography
                    style={{
                      color: "#FFFFFFE5",
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "20px",
                      letterSpacing: "-0.08399999886751175px",
                      textAlign: "center",
                    }}
                  >
                    Sign In{" "}
                  </Typography>{" "}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box> */
}
