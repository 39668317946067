import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import CloseIcon from "@material-ui/icons/Close";
import "../css/dragDrope.css";
// import ImageConfig from "../../../config/imageConfig";
import { Box, Button, Typography, Grid, Chip } from "@material-ui/core";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { Edit } from "@material-ui/icons";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Paper from "@material-ui/core/Paper";
const useStyles = makeStyles((theme) => ({
  btnBox: {
    padding: "10px",
  },
  btnBoxMobile: {
    //margin:"0px",
    padding: "0px,16px",
    width: "270px",
    height: "42px",
    borderRadius: "4px",
    backgroundColor: "#0B1941",
    justifyContent: "center",
  },
  btnBoxMobile1: {
    //margin:"0px",
    //   padding: "0px,16px",
    //   width: "270px",
    //   height: "42px",
    //   borderRadius: "4px",
    //   backgroundColor: "#0B1941",
    //   justifyContent: "center",
  },
  rootMobile: {
    display: "flex",

    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  },
  rootMobile1: {
    display: "flex",
    justifyContent: "center",
    margin: "0px",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(11),
      height: theme.spacing(10),
      // padding:theme.spacing(1)
    },
  },
  cameraBox: {
    padding: "10px",
  },

  paper: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid #9E9E9E",
    borderRadius: "4px",
  },
  chip: {
    display: "flex",
    justifyContent: "space-between",
  },
  chip1: {
    display: "flex",
    justifyContent: "space-between",
    minWidth: "137px",
  },

  existingFile: {
    fontWeight: "600",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: "20px",
    lineHeight: "24.38px",
    letterSpacing: "0.3px",
    marginTop: "20px",
    marginLeft: " 12px",
    // textAlign: "left",
    color: "#252733",
  },
}));

const DropFileInput = (props) => {
  const classes = useStyles();
  const wrapperRef = useRef(null);

  const [fileList, setFileList] = useState([]);
  const [prePopulateList, setPrePopulatedList] = useState([]);
  const [deleteFileList, setDeleteFileList] = useState([]);

  useEffect(() => {
    if (props.Edit && props?.myExpenseList?.attachements?.length > 0) {
      const dummyArray = [];
      props.myExpenseList?.attachements?.forEach((element) => {
        dummyArray.push(element);
      });
      // props.handleFileList(dummyArray);

      setPrePopulatedList(dummyArray);
    }
  }, [props.myExpenseList]);
  useEffect(() => {
    if (props.Edit && props?.myExpenseList?.attachements?.length > 0) {
      props.handleEditDeleteValidateFile(prePopulateList);
    }
  }, [prePopulateList]);
  //console.log("set FIle List", prePopulateList);

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");
  // console.log("filelist", fileList);
  const onFileDrop = (e) => {
    const { files } = e.target;
    //console.log("files:      ", files);
    const newFile = [...files];
    //files.forEach((item) => newFile.push(item));
    //console.log("newFiles:      ", newFile);
    if (newFile.length > 0) {
      const updatedList = [...fileList, ...newFile];
      setFileList(updatedList);
      props.handleFileList(updatedList);
    }
  };

  const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    props.handleFileList(updatedList);
  };

  const prefileRemove = (file) => {
    //console.log("file", file);
    const updatedList = [...prePopulateList];
    updatedList.splice(prePopulateList.indexOf(file), 1);
    setPrePopulatedList(updatedList);
    const updatedDeleteFiles = [...deleteFileList, file];
    setDeleteFileList(updatedDeleteFiles);
    props.handleDeleteFileList(updatedDeleteFiles);
  };
  //console.log("deleted file", deleteFileList);
  const handleClick = () => {
    // console.info("You clicked the Chip.");
  };
  //console.log("fileList", fileList);
  const matches = useMediaQuery("(max-width:600px)");
  return (
    <>
      <div
        className="drop-file-container"
        style={{
          justifyContent: "center",
        }}
      >
        <Paper
          style={{
            boxShadow: "none",
            justifyContent: "center",
            // marginLeft:"-20px",
            marginRight: matches ? "20px" : "",
          }}
          className={classes.paper}
        >
          <div
            ref={wrapperRef}
            className="drop-file-input"
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            style={{
              justifyContent: matches ? "center" : "",
            }}
          >
            <div
              className="drop-file-input__label"
              style={{
                justifyContent: matches ? "center" : "",
              }}
            >
              {matches ? (
                // <Grid container spacing={1}>
                // <Grid item xs={11} sm={11} md={11} >
                <Box>
                  <div className={classes.rootMobile1}>
                    <input
                      accept=".jpg, .jpeg, .png, .pdf"
                      capture="environment"
                      multiple
                    ></input>
                    <Paper
                      variant="outlined"
                      style={{
                        border: "1px solid #9E9E9E",
                        width: "240px",
                        justifyItems: "center",
                      }}
                    >
                      <CameraAltOutlinedIcon
                        style={{ fontSize: 40 }}
                        color="red"
                      />
                      <Typography
                        style={{ fontSize: "10px", color: "#9E9E9E" }}
                      >
                        Take Photo / Upload the Reciepts/Invoice
                      </Typography>
                    </Paper>
                  </div>
                </Box>
              ) : (
                // </Grid>
                // </Grid>
                ""
              )}

              {/* <Grid item xs={6}>
                  {matches ? (
                    <div className={classes.rootMobile1}>
                      <Paper
                        variant="outlined"
                        style={{ border: "1px solid #9E9E9E" ,
                      marginRight:matches?"10px":""
                      }}
                      >
                        <BackupOutlinedIcon
                          style={{ fontSize: 40 }}
                          color="red"
                        />
                        <Typography
                          style={{ fontSize: "10px", color: "#9E9E9E" }}
                        >
                          Upload the Reciepts/Invoice
                        </Typography>
                      </Paper>
                    </div>
                  ) : (
                    ""
                  )}
                </Grid> */}

              {!matches && (
                <BackupOutlinedIcon style={{ fontSize: 40 }} color="disabled" />
              )}
              {matches ? (
                ""
              ) : (
                <Typography>Upload the Reciepts/Invoice</Typography>
              )}
              <Box className={matches ? "" : classes.btnBox}>
                {matches ? (
                  <Box className={classes.btnBoxMobile1}>
                    <Button
                      variant="contained"
                      component="span"
                      style={{
                        width: "270px",
                        fontSize: "14px",
                        fontWeight: "700px",
                        fontfamily: "Montserrat",
                        color: "white",
                        textAlign: "center",
                        paddingTop: "10px",
                        backgroundColor: "#0B1941",
                      }}
                    >
                      UPLOAD
                    </Button>
                  </Box>
                ) : (
                  <Button
                    variant="contained"
                    component="span"
                    style={{
                      backgroundColor: "#043365",
                      color: "rgba(255, 255, 255, 0.9)",
                    }}
                  >
                    Upload
                  </Button>
                )}
              </Box>
              <p className="drop-file-input-label-validationText">
                <Typography style={{ fontSize: matches ? "10px" : "12px" }}>
                  <strong>
                    Only JPEG, JPG, XLS, XLSX, PNG and PDF allowed with maximum
                    size of less than 10 MB.
                  </strong>
                </Typography>
              </p>
            </div>
            <input
              type="file"
              id="drag-drop-files"
              value=""
              onChange={(e) => onFileDrop(e)}
              multiple
            />
          </div>
        </Paper>
      </div>

      <div
        className="drop-file-preview-container"
        style={{ paddingTop: matches ? "7px" : "8px" }}
      >
        <Box>
          <Grid container spacing={3}>
            {fileList.length > 0
              ? fileList.map((item, index) => (
                  <Grid item xs={matches ? 4 : 1} sm={3}>
                    <Chip
                      key={index}
                      //   icon={<ImageConfig />}
                      label={item.name}
                      className={classes.chip}
                      clickable
                      onDelete={() => {
                        fileRemove(item);
                      }}
                      onClick={(e) => handleClick()}
                      deleteIcon={<CloseIcon />}
                    />
                  </Grid>
                ))
              : null}
            {props?.Edit &&
              prePopulateList?.map((item, index) => (
                <Grid item xs={matches ? 5 : 1} sm={2}>
                  <Chip
                    key={index}
                    //   icon={<ImageConfig />}
                    label={item.FileName}
                    className={matches ? classes.chip1 : classes.chip}
                    clickable
                    onDelete={() => {
                      prefileRemove(item);
                    }}
                    onClick={(e) => handleClick()}
                    deleteIcon={<CloseIcon />}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
      </div>
    </>
  );
};

DropFileInput.propTypes = {
  onFileChange: PropTypes.func,
};

export default DropFileInput;
