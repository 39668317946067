import CryptoJS from "crypto-js";

const Decrypt = (key, ciphertextB64) => {

   // Base64 encoded ciphertext, 32 bytes string as key

   try {

    var key = CryptoJS.enc.Utf8.parse(key); // Convert into WordArray (using Utf8)

    var iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]); // Use zero vector as IV

    var decrypted = CryptoJS.AES.decrypt(ciphertextB64, key, { iv: iv }); // By default: CBC, PKCS7
    let res = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));

    return res;
  } catch (error) {

    //console.log("getEmpDetailData error", error);

  }
};
export default Decrypt;
