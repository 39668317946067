//Timesheet Action Types:
//1) Get Timesheet Project List action type:
export const GET_TIMESHEET_PURPOSE_LIST = "GET_TIMESHEET_PURPOSE_LIST";
export const GET_TIMESHEET_PURPOSE_LIST_SUCCESS =
  "GET_TIMESHEET_PURPOSE_LIST_SUCCESS";
export const GET_TIMESHEET_PURPOSE_LIST_FAILED =
  "GET_TIMESHEET_PURPOSE_LIST_FAILED";



//2) Get Timesheet Categories List action type:
export const GET_TIMESHEET_CATEGORIES_LIST = "GET_TIMESHEET_CATEGORIES_LIST";
export const GET_TIMESHEET_CATEGORIES_LIST_SUCCESS =
  "GET_TIMESHEET_CATEGORIES_LIST_SUCCESS";
export const GET_TIMESHEET_CATEGORIES_LIST_FAILED =
  "GET_TIMESHEET_PURPOSE_LIST_FAILED";

//3) POST Create Timesheet action type:
export const POST_CREATE_TIMESHEET = "POST_CREATE_TIMESHEET";
export const POST_CREATE_TIMESHEET_SUCCESS = "POST_CREATE_TIMESHEET_SUCCESS";
export const POST_CREATE_TIMESHEET_FAILED = "POST_CREATE_TIMESHEET_FAILED";

//4) GET My Timesheet action type:
export const GET_MY_TIMESHEET_LIST = "GET_MY_TIMESHEET_LIST";
export const GET_MY_TIMESHEET_LIST_SUCCESS = "GET_MY_TIMESHEET_LIST_SUCCESS";
export const GET_MY_TIMESHEET_LIST_FAILED = "GET_MY_TIMESHEET_LIST_FAILED";

// GET ALL PROJECTS action type:

export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS";
export const GET_ALL_PROJECTS_SUCCESS = "GET_ALL_PROJECTS_SUCCESS";
export const GET_ALL_PROJECTS_FAILED = "GET_ALL_PROJECTS_FAILED";

//5) UPDATE Reference Date
export const UPDATED_REF_DATE = "UPDATED_REF_DATE";

//6) GET WEEKLY PREVIOUS TIMESHEET LIST action type:
export const GET_WEEKLY_PREVIOUS_TIMESHEET_LIST =
  "GET_WEEKLY_PREVIOUS_TIMESHEET_LIST";
export const GET_WEEKLY_PREVIOUS_TIMESHEET_LIST_SUCCESS =
  "GET_WEEKLY_PREVIOUS_TIMESHEET_LIST_SUCCESS";
export const GET_WEEKLY_PREVIOUS_TIMESHEET_LIST_FAILED =
  "GET_WEEKLY_PREVIOUS_TIMESHEET_LIST_FAILED";

//7) GET DAILY PREVIOUS TIMESHEET LIST action type:
export const GET_DAILY_PREVIOUS_TIMESHEET_LIST =
  "GET_DAILY_PREVIOUS_TIMESHEET_LIST";
export const GET_DAILY_PREVIOUS_TIMESHEET_LIST_SUCCESS =
  "GET_DAILY_PREVIOUS_TIMESHEET_LIST_SUCCESS";
export const GET_DAILY_PREVIOUS_TIMESHEET_LIST_FAILED =
  "GET_DAILY_PREVIOUS_TIMESHEET_LIST_FAILED";

//8) GET Missed Week action type:

export const GET_MISSED_WEEK = "GET_MISSED_WEEK";
export const GET_MISSED_WEEK_SUCCESS = "GET_MISSED_WEEK_SUCCESS";
export const GET_MISSED_WEEK_FAILED = "GET_MISSED_WEEK_FAILED";

//COUNT OF TEAM APPROVAL DATA
export const GET_TOTAL_COUNT_APPROVAL_DATA ="GET_TOTAL_COUNT_APPROVAL_DATA";
export const GET_TOTAL_COUNT_APPROVAL_DATA_SUCCESS="GET_TOTAL_COUNT_APPROVAL_DATA_SUCCESS";
export const GET_TOTAL_COUNT_APPROVAL_DATA_FAILED="GET_TOTAL_COUNT_APPROVAL_DATA_FAILED";

//GET MY TEAM APPROVAL PAGE WISE:
export const GET_MY_TEAM_APPROVAL_PAGEWISE ="GET_MY_TEAM_APPROVAL_PAGEWISE";
export const GET_MY_TEAM_APPROVAL_PAGEWISE_SUCCESS="GET_MY_TEAM_APPROVAL_PAGEWISE_SUCCESS";
export const GET_MY_TEAM_APPROVAL_PAGEWISE_FAILED="GET_MY_TEAM_APPROVAL_PAGEWISE_FAILED";

//AUTO APPROVAL ALLOCATED PROJECT LIST
export const GET_AUTOAPPROVAL_ALLOCATED_PROJ_LIST ="GET_AUTOAPPROVAL_ALLOCATED_PROJ_LIST";
export const GET_AUTOAPPROVAL_ALLOCATED_PROJ_LIST_SUCCESS="GET_AUTOAPPROVAL_ALLOCATED_PROJ_LIST_SUCCESS";
export const GET_AUTOAPPROVAL_ALLOCATED_PROJ_LIST_FAILED="GET_AUTOAPPROVAL_ALLOCATED_PROJ_LIST_FAILED";



//9)GET MY TEAM APPROVAL action type:
export const GET_MY_TEAM_APPROVAL ="GET_MY_TEAM_APPROVAL";
export const GET_MY_TEAM_APPROVAL_SUCCESS="GET_MY_TEAM_APPROVAL_SUCCESS";
export const GET_MY_TEAM_APPROVAL_FAILED="GET_MY_TEAM_APPROVAL_FAILED";

//10)UPDATE STATUS BY MANAGER action type:
export const UPDATE_STATUS_BY_MANAGER="UPDATE_STATUS_BY_MANAGER";
export const UPDATE_STATUS_BY_MANAGER_SUCCESS="UPDATE_STATUS_BY_MANAGER_SUCCESS";
export const UPDATE_STATUS_BY_MANAGER_FAILED="UPDATE_STATUS_BY_MANAGER_FAILED";

// 11)GET Calendar Date List action type:
export const GET_CALENDAR_DATE_LIST = "GET_CALENDAR_DATE_LIST";
export const GET_CALENDAR_DATE_LIST_SUCCESS = "GET_CALENDAR_DATE_LIST_SUCCESS";
export const GET_CALENDAR_DATE_LIST_FAILED = "GET_CALENDAR_DATE_LIST_FAILED";

// 12)Get Timesheet Dashboard Landing Page Detailed View
export const GET_VIEW_LIST = "GET_VIEW_LIST";
export const GET_VIEW_LIST_SUCCESS = "GET_VIEW_LIST_SUCCESS";
export const GET_VIEW_LIST_FAILED = "GET_VIEW_LIST_FAILED";
// 13)GET Team Timesheet action type:
export const GET_TEAM_TIMESHEET="GET_TEAM_TIMESHEET";
export const GET_TEAM_TIMESHEET_SUCCESS="GET_TEAM_TIMESHEET_SUCCESS";
export const GET_TEAM_TIMESHEET_FAIED="GET_TEAM_TIMESHEET_FAILED";

// 14)GET TIMESHEET ACCESS FLAG action type:
export const GET_TIMESHEET_ACCESS_FLAG="GET_TIMESHEET_ACCESS_FLAG";
export const GET_TIMESHEET_ACCESS_FLAG_SUCCESS="GET_TIMESHEET_ACCESS_FLAG_SUCCESS";
export const GET_TIMESHEET_ACCESS_FLAG_FAILED="GET_TIMESHEET_ACCESS_FLAG_FAILED";

//15) GET TIMESHEET REPORT ACCESS
export const GET_TIMESHEET_REPORT_ACCESS_FLAG="GET_TIMESHEET_REPORT_ACCESS_FLAG";
export const GET_TIMESHEET_REPORT_ACCESS_FLAG_SUCCESS="GET_TIMESHEET_REPORT_ACCESS_FLAG_SUCCESS";
export const GET_TIMESHEET_REPORT_ACCESS_FLAG_FAILED="GET_TIMESHEET_REPORT_ACCESS_FLAG_FAILED";

//16) DOWNLOAD PENDENCY REPORT
export const GET_PENDENCY_REPORT_DATA="GET_PENDENCY_REPORT_DATA";
export const GET_PENDENCY_REPORT_DATA_SUCCESS="GET_PENDENCY_REPORT_DATA_SUCCESS";
export const GET_PENDENCY_REPORT_DATA_FAILED="GET_PENDENCY_REPORT_DATA_FAILED";
//MAINTAIN STATE
export const MAINTAIN_STATE_VARIABLE = "MAINTAIN_STATE_VARIABLE";

//POST PDF ACTION type
export const POST_EXPORT_AS_PDF="POST_EXPORT_AS_PDF";
export const POST_EXPORT_AS_PDF_SUCCESS="POST_EXPORT_AS_PDF_SUCCESS";
export const POST_EXPORT_AS_PDF_FAILED="POST_EXPORT_AS_PDF_FAILED";

//6) GET TEMP WEEKLY PREVIOUS TIMESHEET LIST action type:
export const GET_TEMP_WEEKLY_PREVIOUS_TIMESHEET_LIST = "GET_TEMP_WEEKLY_PREVIOUS_TIMESHEET_LIST";
export const GET_TEMP_WEEKLY_PREVIOUS_TIMESHEET_LIST_SUCCESS = "GET_TEMP_WEEKLY_PREVIOUS_TIMESHEET_LIST_SUCCESS";
export const GET_TEMP_WEEKLY_PREVIOUS_TIMESHEET_LIST_FAILED = "GET_TEMP_WEEKLY_PREVIOUS_TIMESHEET_LIST_FAILED";



//POST Rating and Review action type:
export const POST_RATING_AND_REVIEW = "POST_RATING_AND_REVIEW";
export const POST_RATING_AND_REVIEW_SUCCESS = "POST_RATING_AND_REVIEW_SUCCESS";
export const POST_RATING_AND_REVIEW_FAILED = "POST_RATING_AND_REVIEW_FAILED";

//GET Rating Description action type:
export const GET_RATING_DESCRIPTION = "GET_RATING_DESCRIPTION";
export const GET_RATING_DESCRIPTION_SUCCESS = "GET_RATING_DESCRIPTION_SUCCESS";
export const GET_RATING_DESCRIPTION_FAILED = "GET_RATING_DESCRIPTION_FAILED";


//GET TimesheetSearchEmployee action type:
export const GET_TIMESHEET_SEARCH_EMPLOYEE = "GET_TIMESHEET_SEARCH_EMPLOYEE";
export const GET_TIMESHEET_SEARCH_EMPLOYEE_SUCCESS = "GET_TIMESHEET_SEARCH_EMPLOYEE_SUCCESS";
export const GET_TIMESHEET_SEARCH_EMPLOYEE_FAILED = "GET_TIMESHEET_SEARCH_EMPLOYEE_FAILED";

//GET ViewDelegateDetails action type:
export const GET_TIMESHEET_VIEW_DELEGATE_DETAILS = "GET_TIMESHEET_VIEW_DELEGATE_DETAILS";
export const GET_TIMESHEET_VIEW_DELEGATE_DETAILS_SUCCESS = "GET_TIMESHEET_VIEW_DELEGATE_DETAILS_SUCCESS";
export const GET_TIMESHEET_VIEW_DELEGATE_DETAILS_FAILED = "GET_TIMESHEET_VIEW_DELEGATE_DETAILS_FAILED";

//GET TimesheetSearchEmpAndDel action type:
export const GET_TIMESHEET_SEARCH_EMP_AND_DEL = "GET_TIMESHEET_SEARCH_EMP_AND_DEL";
export const GET_TIMESHEET_SEARCH_EMP_AND_DEL_SUCCESS = "GET_TIMESHEET_SEARCH_EMP_AND_DEL_SUCCESS";
export const GET_TIMESHEET_SEARCH_EMP_AND_DEL_FAILED = "GET_TIMESHEET_SEARCH_EMP_AND_DEL_FAILED";

//GET TimesheetGetEmpDetailsAsPerDelegatee action type:
export const GET_TIMESHEET_EMP_DETAILS_AS_PER_DELEGATEE = "GET_TIMESHEET_EMP_DETAILS_AS_PER_DELEGATEE";
export const GET_TIMESHEET_EMP_DETAILS_AS_PER_DELEGATEE_SUCCESS = "GET_TIMESHEET_EMP_DETAILS_AS_PER_DELEGATEE_SUCCESS";
export const GET_TIMESHEET_EMP_DETAILS_AS_PER_DELEGATEE_FAILED = "GET_TIMESHEET_EMP_DETAILS_AS_PER_DELEGATEE_FAILED";

//POST TimesheetAddDelegate action type: TIMESHEET_ADD_DELEGATE
export const POST_TIMESHEET_ADD_DELEGATE = "POST_TIMESHEET_ADD_DELEGATE";
export const POST_TIMESHEET_ADD_DELEGATE_SUCCESS = "POST_TIMESHEET_ADD_DELEGATE_SUCCESS";
export const POST_TIMESHEET_ADD_DELEGATE_FAILED = "POST_TIMESHEET_ADD_DELEGATE_FAILED";

//POST TimesheetEditDelegate action type: TIMESHEET_ADD_DELEGATE
export const POST_TIMESHEET_EDIT_DELEGATE = "POST_TIMESHEET_EDIT_DELEGATE";
export const POST_TIMESHEET_EDIT_DELEGATE_SUCCESS = "POST_TIMESHEET_EDIT_DELEGATE_SUCCESS";
export const POST_TIMESHEET_EDIT_DELEGATE_FAILED = "POST_TIMESHEET_EDIT_DELEGATE_FAILED";

//GET TimesheetGetDelAccess action type:
export const GET_TIMESHEET_DELEGATION_ACCESS = "GET_TIMESHEET_DELEGATION_ACCESS";
export const GET_TIMESHEET_DELEGATION_ACCESS_SUCCESS = "GET_TIMESHEET_DELEGATION_ACCESS_SUCCESS";
export const GET_TIMESHEET_DELEGATION_ACCESS_FAILED = "GET_TIMESHEET_DELEGATION_ACCESS_FAILED";

//RESET DeligationType
export const RESET_TIMESHEET_DELIGATION = "RESET_TIMESHEET_DELIGATION";

//TIMESHEET SSO EMPlOYEE ID
export const UPDATED_TIMESHEET_SSO_EMP_ID = "UPDATED_TIMESHEET_SSO_EMP_ID";

//UPDATE TIMESHEET DELEGATEE EMPLOYEE ID
export const UPDATE_TIMESHEET_DELEGATEE_EMP_ID = "UPDATE_TIMESHEET_DELEGATEE_EMP_ID";

//GET TimesheetSearchDelegate action type:
export const GET_TIMESHEET_SEARCH_DELEGATE = "GET_TIMESHEET_SEARCH_DELEGATE";
export const GET_TIMESHEET_SEARCH_DELEGATE_SUCCESS = "GET_TIMESHEET_SEARCH_DELEGATE_SUCCESS";
export const GET_TIMESHEET_SEARCH_DELEGATE_FAILED = "GET_TIMESHEET_SEARCH_DELEGATE_FAILED";

//timesheet report action type:
export const  GET_TIMESHEET_REPORT_DATA="GET_TIMESHEET_REPORT_DATA";
export const GET_TIMESHEET_REPORT_DATA_SUCCESS="GET_TIMESHEET_REPORT_DATA_SUCCESS";
export const GET_TIMESHEET_REPORT_DATA_FAILED="GET_TIMESHEET_REPORT_DATA_FAILED";

//all timesheet report action type:
export const  GET_All_TIMESHEET_REPORT_DATA="GET_All_TIMESHEET_REPORT_DATA";
export const GET_All_TIMESHEET_REPORT_DATA_SUCCESS="GET_All_TIMESHEET_REPORT_DATA_SUCCESS";
export const GET_All_TIMESHEET_REPORT_DATA_FAILED="GET_All_TIMESHEET_REPORT_DATA_FAILED";

//all timesheet report count action type:
export const GET_ALL_TIMESHEET_REPORT_VIEW="GET_ALL_TIMESHEET_REPORT_VIEW";
export const GET_ALL_TIMESHEET_REPORT_VIEW_SUCCESS="GET_ALL_TIMESHEET_REPORT_VIEW_SUCCESS";
export const GET_ALL_TIMESHEET_REPORT_VIEW_FAILED="GET_ALL_TIMESHEET_REPORT_VIEW_FAILED";

export const  GET_All_TIMESHEET_REPORT_DATA_NEW="GET_All_TIMESHEET_REPORT_DATA_NEW";
export const GET_All_TIMESHEET_REPORT_DATA_SUCCESS_NEW="GET_All_TIMESHEET_REPORT_DATA_SUCCESS_NEW";
export const GET_All_TIMESHEET_REPORT_DATA_FAILED_NEW="GET_All_TIMESHEET_REPORT_DATA_FAILED_NEW";

export const CLEAR_ALL_TIMESHEET_REPORT="CLEAR_ALL_TIMESHEET_REPORT";

//all timesheet report detail view audit history
export const GET_ALL_TIMESHEET_AUDIT="GET_ALL_TIMESHEET_AUDIT";
export const GET_ALL_TIMESHEET_AUDIT_SUCCESS="GET_ALL_TIMESHEET_AUDIT_SUCCESS";
export const GET_ALL_TIMESHEET_AUDIT_FAILED="GET_ALL_TIMESHEET_AUDIT_FAILED";

export const SET_CHIPS = 'SET_CHIPS';

//GET APPROVAL LIST FOR INVOICING
export const GET_APPROVAL_LIST_INVOICE="GET_APPROVAL_LIST_INVOICE";
export const GET_APPROVAL_LIST_INVOICE_SUCCESS="GET_APPROVAL_LIST_INVOICE_SUCCESS";
export const GET_APPROVAL_LIST_INVOICE_FAILED="GET_APPROVAL_LIST_INVOICE_FAILED"; 

// POST Import Invoicing Details action type:
export const POST_IMPORT_INVOICING_DETAILS = "POST_IMPORT_INVOICING_DETAILS";
export const POST_IMPORT_INVOICING_DETAILS_SUCCESS = "POST_IMPORT_INVOICING_DETAILS_SUCCESS";
export const POST_IMPORT_INVOICING_DETAILS_FAILED = "POST_IMPORT_INVOICING_DETAILS_FAILED";

//GET APPROVAL INVOICING REPORT
export const GET_APPROVAL_INVOICING_REPORT="GET_APPROVAL_INVOICING_REPORT";
export const GET_APPROVAL_INVOICING_REPORT_SUCCESS="GET_APPROVAL_INVOICING_REPORT_SUCCESS";
export const GET_APPROVAL_INVOICING_REPORT_FAILED="GET_APPROVAL_INVOICING_REPORT_FAILED"; 

//GET INVOICING AUDIT DETAILS
export const GET_INVOICING_AUDIT_DETAILS="GET_INVOICING_AUDIT_DETAILS";
export const GET_INVOICING_AUDIT_DETAILS_SUCCESS="GET_INVOICING_AUDIT_DETAILS_SUCCESS";
export const GET_INVOICING_AUDIT_DETAILS_FAILED="GET_INVOICING_AUDIT_DETAILS_FAILED"; 

//GET INVOICING EXPORT REPORT
export const GET_INVOICING_EXPORT_REPORT="GET_INVOICING_EXPORT_REPORT";
export const GET_INVOICING_EXPORT_REPORT_SUCCESS="GET_INVOICING_EXPORT_REPORT_SUCCESS";
export const GET_INVOICING_EXPORT_REPORT_FAILED="GET_INVOICING_EXPORT_REPORT_FAILED"; 

//INVOICE STATUS UPDATE
export const INVOICE_STATUS_UPDATE="INVOICE_STATUS_UPDATE";
export const INVOICE_STATUS_UPDATE_SUCCESS="INVOICE_STATUS_UPDATE_SUCCESS";
export const INVOICE_STATUS_UPDATE_FAILED="INVOICE_STATUS_UPDATE_FAILED";

export const SET_PMO_CHIPS= 'SET_PMO_CHIPS';
export const CLEAR_SET_PMO_CHIPS= 'CLEAR_SET_PMO_CHIPS';
export const CLEAR_MAINTAIN = 'CLEAR_MAINTAIN';


export const MY_TEAM_APPROVAL_FILTER_DROP_DOWN="MY_TEAM_APPROVAL_FILTER_DROP_DOWN";
export const MY_TEAM_APPROVAL_FILTER_DROP_DOWN_SUCCESS="MY_TEAM_APPROVAL_FILTER_DROP_DOWN_SUCCESS";
export const MY_TEAM_APPROVAL_FILTER_DROP_DOWN_FAILED="MY_TEAM_APPROVAL_FILTER_DROP_DOWN_FAILED";

//GET APPROVAL EXPORT INVOICING REPORT
export const GET_APPROVAL_INVOICING_EXPORT_REPORT="GET_APPROVAL_INVOICING_EXPORT_REPORT";
export const GET_APPROVAL_INVOICING_EXPORT_REPORT_SUCCESS="GET_APPROVAL_INVOICING_EXPORT_REPORT_SUCCESS";
export const GET_APPROVAL_INVOICING_EXPORT_REPORT_FAILED="GET_APPROVAL_INVOICING_EXPORT_REPORT_FAILED"; 