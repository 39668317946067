import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Box,
  Chip,
  Typography,
  makeStyles,
  Grid,
  Container,
  Button,
  Tooltip,
  Backdrop,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  InputLabel,
  Snackbar,
} from "@material-ui/core";
import SnackBar from "./snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { tooltipClasses } from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";
import MobileViewStepper from "./mobileViewStepper";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AuditHistoryDialog from "./auditHistoryDialog";
import {
  getAuditHistory,
  getEmpDetail,
  getMyViewExpenseData,
  postApproveRejectExpenseData,
  postFinancialApproveRejectExpenseData,
  resetExpenseResponse,
} from "../redux/Expense/action";
import moment from "moment";
import { useLocation, useHistory } from "react-router-dom";
import PhotoIcon from "@material-ui/icons/Photo";
import fileDownload from "js-file-download";
import Avatar from "@material-ui/core/Avatar";
import ApproveRejectDialog from "./approveRejectDialog";
import Encrypt from "../Utils/encryption";
// import {
//   getEmpDetail,
//   getMyViewExpenseData,
//   postApproveRejectExpenseData,
//   postFinancialApproveRejectExpenseData,
//   resetExpenseResponse
// } from "../redux/Expense/action";
import { useDispatch, useSelector } from "react-redux";
import Stepper from "./stepper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Link from "@material-ui/core/Link";
import MobileVerticalStepper from "./mobileVerticalStepper";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { NumericFormat } from "react-number-format";
import { style } from "@material-ui/system";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PhotoSizeSelectActualIcon from "@material-ui/icons/PhotoSizeSelectActual";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme) => ({
  statusHeading: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "8px",
    color: "rgba(255, 255, 255, 0.9)",
  },
  statusName: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "10px",
    marginRight: "2px",

    color: "rgba(255, 255, 255, 0.85)",
  },
  projectId: {},
  textConditional: {
    color: "#008CE6",
  },
  textConditionalSplitAmount: {
    color: "#008CE6",
  },
  expenseIdBillable: {},
  expenseId: {
    color: "#000000",
    fontWeight: "600",
  },
  statusMessage: {
    fontFamily: "Montserrat",
    fontStyle: "italic",
    fontWeight: 400,
    fontSize: "8px",
    color: "#FFFFFF",
  },
  statusProjectName: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "8px",
    marginTop: "2px",
    color: "rgba(255, 255, 255, 0.9)",
    opacity: "0.6",
  },
  detailsContainer: {
    marginTop: "18px",
  },
  auditHistory: {
    fontFamily: "Montserrat",
    fontWeight: 800,
    fontSize: "14px",
    color: "#008CE6",
    marginTop: "-43px",
    paddingLeft: "201px",
    textDecoration: "underline",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  fileName: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "16px",
    color: "#4C4C4C",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "no-wrap",
    width: "130px",
  },
  ExpandedAccordion: {
    backgroundColor: "#F6F6F6",
    borderBottom: "1px solid #979797",
    borderTop: "1px solid #979797",
    "&.MuiAccordionSummary-root": {
      minHeight: "48px",
      maxHeight: "48px",
      "&.Mui-expanded": {
        minHeight: "48px",
        maxHeight: "48px",
      },
    },
  },
  tableCell: {
    "&.MuiTableCell-root": {
      padding: "8px 3px",
      borderBottom: "none",
      fontSize: "12px",
      boxSizing: "border-box",
    },
  },
  numericFormat: {
    "& input#outlined-basic": { padding: "12px", fontSize: "12px" },
  },
  travelTooltip: {
    margin: "0px !important",
    marginTop: "-30px !important",
    "&.MuiTooltip-tooltipPlacementBottom": {
      // Your custom styles for tooltipPlacementBottom
      margin: "0px !important", // Example: change background color to red
    },
  },
  scroolEdit: {
    "&::-webkit-scrollbar": {
      width: "0px",
    },
  },
  // chipRoot: {
  //   "&.MuiChip-root": {
  //     background: "#fffff",
  //   },
  // },
  headerName: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "700",
    color: "#77787F",
    alignItems: "center",
  },
  summaryField: {
    display: "flex",
    alignItems: "center",
    padding: "6px 16px",
    justifyContent: "space-between",
    fontSize: "12px",
  },
}));

function EnhancedTable(props) {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow
        style={{
          background: "#f4f4f4",
          // border: "1px solid #979797",
          padding: "8px",
          borderBottom: "1px solid #e0e0e0",
        }}
      >
        <TableCell
          xs
          style={{
            fontWeight: "600",
            // fontSize: "13px",
            paddingLeft: "16px",
          }}
          className={classes.tableCell}
        >
          Category
        </TableCell>

        <TableCell
          xs
          className={classes.tableCell}
          style={{
            fontWeight: "600",
            // fontSize: "13px",
            maxWidth: "54px",
            minWidth: "40px",
            width: "40px",
            // paddingRight:
            //   props.myExpenseList?.ExpFinalStatus !== "Approved" ||
            //   props.myExpenseList?.ExpFinalStatus !== "Rejected"
            //     ? "16px"
            //     : "3px",
          }}
          id="cell1ref"
        >
          Amount
        </TableCell>
        {props.myExpenseList?.ExpFinalStatus == "Approved" ||
        props.myExpenseList?.ExpFinalStatus == "Rejected" ||
        props.location?.state?.compName === "financeApprovalListing" ? (
          <TableCell
            xs
            className={classes.tableCell}
            style={{
              fontWeight: "600",
              // fontSize: "13px",
              paddingRight: "14px",
              maxWidth:
                props.location?.state?.compName === "financeApprovalListing"
                  ? "76px"
                  : "64px",
              minWidth: "40px",
              width: "40px",
            }}
            id="cell2ref"
          >
            Approved
          </TableCell>
        ) : (
          <></>
        )}
        {/* ) : props.location?.state?.compName === "financeApprovalListing" ? (
          <TableCell
            className={classes.tableCell}
            style={{
              fontWeight: "600",
              // fontSize: "13px",
              paddingRight: "14px",
              width: "90px",
            }}
          >
            Approved
          </TableCell>
        ) : (
          ""
        )} */}
      </TableRow>
    </TableHead>
  );
}

EnhancedTable.propTypes = {
  myExpenseList: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

function MobileViewExpense(props) {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const { myExpenseList, approvedAmount } = props;
  const AWS = require("aws-sdk");
  const [buttonState, setButtonState] = React.useState(true);
  const [openBulkApproveDialog, setOpenBulkApproveDialog] = useState(false);
  const [singleExpense, setSingleExpense] = useState("");
  const [veiwDialog, setViewDialog] = React.useState(false);
  const [approveOrReject, setApproveOrReject] = useState(1);
  const [viewFinApproval, setViewFinApproval] = React.useState(false);
  const [empId, setEmpId] = React.useState(null);
  const [expStatus, setExpStatus] = React.useState("Approved");
  const [comments, setComments] = React.useState("");
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width:600px)");
  const [open, setOpen] = React.useState(false);
  const [approvedAmountVal, setApprovedAmountVal] = React.useState(0);
  const [openAuditHistDialog, setOpenAuditHistDialog] = React.useState(false);
  const [auditHistoryData, setAuditHistoryData] = useState([]);

  const [expanded, setExpanded] = useState(-1);
  const [travelTotalApproved, setTravelTotalApproved] = useState(0);
  const [travelFieldValue, setTravelFieldValue] = useState([]);
  const [errorFlag, setErrorFlag] = useState(false);
  // const [travelDownloadData, setTravelDownloadData] = useState({});
  // const [singleDownload, setSingleDownload] = useState([]);
  const [systemErrorSnackbar, setSystemErrorSnackbar] = useState(false);
  const [currencyError, setCurrencyError] = useState("");
  const [currency, setCurrency] = useState("");
  const [travelCardAmount, setTravelCardAmount] = useState(0);
  const [cash, setCash] = useState(0);
  const [advSummaryJson, setAdvSummaryJson] = useState({});
  const [advancesList, setAdvancesList] = useState([
    {
      ExpId: null,
      DepositDate: null,
      Currency: "",
      TotalAdvance: 0,
      AdvanceType: "",
    },
  ]);
  const AdvancesType = [
    {
      label: "Travel Card",
      value: "Travel Card",
    },
    {
      label: "Cash",
      value: "Cash",
    },
  ];

  const [actValue, setActValue] = useState([]);
  const [advValue, setAdvValue] = useState([]);
  const [totalAdv, setTotalAdv] = useState(0);
  const [totalAct, setTotalAct] = useState(0);

  const CurrencyList = useSelector(
    (state) => state.createExpenseData.getCurrencyList
  );

  const auditHistoryList = useSelector(
    (state) => state.createExpenseData.getAuditHistData
  );
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  useEffect(() => {
    dispatch(getMyViewExpenseData(location?.state));
    dispatch(getAuditHistory(location?.state?.response?.expId));
    setApprovedAmountVal(location?.state?.response?.claimAmount);
    props.setAmountNewValue(location?.state?.response?.approvedAmount);
    if (
      location?.state?.compName == "teamApprovalListing" &&
      (location?.state?.response?.expStatus == "Approved" ||
        location?.state?.response?.expStatus == "Rejected")
    ) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  }, [location]);

  useEffect(() => {
    if (auditHistoryList != null) {
      setAuditHistoryData(auditHistoryList);
    }
  }, [auditHistoryList]);

  useEffect(() => {
    if (
      myExpenseList?.ExpFinalStatus == "Approved" ||
      myExpenseList?.ExpFinalStatus == "Rejected"
    ) {
      const travelTotal = (myExpenseList?.ExpenseDetail?.reduce((prev, list) => {
        return prev + list.ApprovedAmount;
      }, 0))?.toFixed(2);

      setTravelTotalApproved(travelTotal);
    } else {
      const travelTotal =( myExpenseList?.ExpenseDetail?.reduce((prev, list) => {
        return prev + list.ClaimedAmount;
      }, 0))?.toFixed(2);
      //console.log("travel total", travelTotal);
      setTravelTotalApproved(travelTotal);
    }
    setTravelFieldValue([]);
    myExpenseList?.ExpenseDetail?.map((val, index) => {
      // console.log("travelFieldValue", val.ExpId);
      setTravelFieldValue((prevVal) => [
        ...prevVal,
        {
          ExpId: val.ExpId,
          ApprovedAmount: val.ClaimedAmount,
          ProjAmount: 0,
          ClaimedAmount: val.ClaimedAmount,
        },
      ]);
    });

    setAdvSummaryJson({});
    if (myExpenseList?.AdvSummaryDetails !== null) {
      setAdvSummaryJson({
        ...myExpenseList?.AdvSummaryDetails[0],
        ExpId: myExpenseList?.ExpenseDetail[0]?.ExpId,
      });
    } else {
      setAdvSummaryJson({
        ExpId: myExpenseList?.ExpenseDetail[0]?.ExpId,
        RefundReceived: "0",
        NetPayable: "0",
        TravelCardBal: "0",
        CashBal: "0",
      });
    }
    setTotalAct(
      myExpenseList?.ActualDetails?.reduce(
        (acc, item) => acc + item.ActualsTypeAmt,
        0
      )
    );
    if (location?.state?.compName == "financeApprovalListing") {
      setActValue(
        myExpenseList?.ActualDetails?.map((item) => ({
          ...item,
          ActualsTypeAppAmt: item.ActualsTypeAmt,
        }))
      );
    } else {
      setActValue(myExpenseList?.ActualDetails);
    }

    setAdvValue([]);
    setAdvancesList([]);
    setTotalAdv(0);
    if (
      myExpenseList?.AdvancesDetails != null &&
      myExpenseList?.AdvancesList != null
    ) {
      setAdvValue(myExpenseList?.AdvancesDetails);
      const tempAdvList = myExpenseList?.AdvancesList?.filter(
        (item) => item.TotalAdvance > 0
      );
      setAdvancesList(tempAdvList);
      setTotalAdv(
        myExpenseList?.AdvancesDetails?.reduce(
          (acc, item) => acc + item.AdvancesTypeAmt,
          0
        )
      );
    }
  }, [myExpenseList]);

  useEffect(() => {
    if (CurrencyList != null) {
      setCurrency(
        CurrencyList?.filter(
          (value) =>
            value.currencyId == myExpenseList?.ExpenseDetail[0]?.CurrencyId
        )[0]
      );
    }
  }, [CurrencyList, myExpenseList]);

  const [cell1tarwidth, setCell1tarwidth] = useState(null);
  const [cell2tarwidth, setCell2tarwidth] = useState(null);
  // const [cell3tarwidth, setCell3tarwidth] = useState(null);

  useEffect(() => {
    const syncWidths = () => {
      // console.log("cellwidth1", cell1tar?.current?.style.maxWidth);
      const cell1ref = document.getElementById("cell1ref");
      const cell2ref = document.getElementById("cell2ref");
      // const cell3ref = document.getElementById("cell3ref");

      if (cell1ref) {
        const widthToSet = cell1ref.clientWidth;
        if (cell1tarwidth !== widthToSet) {
          setCell1tarwidth(widthToSet);
        }
        // console.log("cellwidth", cell1tarwidth, widthToSet);
      }
      if (cell2ref) {
        const widthToSet = cell2ref.clientWidth;
        if (cell2tarwidth !== widthToSet) {
          setCell2tarwidth(widthToSet);
        }
        // console.log("cellwidth2", cell2tarwidth, widthToSet);
      }
      // if (cell3ref) {
      //   const widthToSet = cell3ref.clientWidth;
      //   if (cell3tarwidth !== widthToSet) {
      //     setCell3tarwidth(widthToSet);
      //   }
      // }
    };

    syncWidths();
  }, [myExpenseList]);

  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );
  const handleOpenAuditHistDialog = () => {
    setOpenAuditHistDialog(true);
  };
  const handleCloseAuditHistDialog = () => {
    setOpenAuditHistDialog(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  });

  const handleToolTip = (data) => {
    const string = data?.Comment?.split("|");
    return (
      <>
        <Box>
          <Typography
            className={classes.statusHeading}
            style={{ paddingTop: "5px" }}
          >
            {string[0]}
          </Typography>
        </Box>
        <>
          <Box
            style={{ display: "flex", paddingTop: "5px", paddingBottom: "5px" }}
          >
            <Typography className={classes.statusName}>
              {data.projectmanager}
            </Typography>
            {string[0] == "Rejected By " && (
              <Typography className={classes.statusName}> </Typography>
            )}
            <Typography
              className={classes.statusProjectName}
              style={{ paddingLeft: "5px" }}
            >
              {" "}
              {"|"}
              <span style={{ paddingLeft: "5px" }}> {data.ProjectId} </span>
            </Typography>{" "}
          </Box>
          <Typography className={classes.statusMessage}>
            {data.reason}
          </Typography>
        </>
      </>
    );
  };

  const handleDownload = (value, fileName) => {
    const s3 = new AWS.S3();
    const tempKey = `${value.ExpenseDetail[0].ExpId}_${fileName}`;
    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET,
      Key: tempKey,
    };

    // console.log("handledownload", value);

    s3.getObject(params, (err, data) => {
      if (err) {
        // console.log("err stack", err.stack);
      } else {
        fileDownload(data.Body, fileName);
      }
    });
  };

  const handleName = (value) => {
    if (value) {
      const initArray = value.split(" ");
      let initial;
      if (initArray.length === 1) {
        initial = initArray[0][0];
      } else {
        initial = initArray[0][0];
      }
      return initial.toUpperCase();
    }
  };

  const handleCloseBulkApproveDialog = () => {
    //console.log("openBulkDialog--- in fxn", openBulkApproveDialog);
    setOpenBulkApproveDialog(false);
    setViewDialog(false);
    setViewFinApproval(false);
    setComments("");

  };

  const handleSingleApproveRejectExpense = (value) => {
    let array = [];
    var key = process.env.REACT_APP_ENCRYPTION_KEY;

    if (
      viewFinApproval == true &&
      location?.state?.compName == "financeApprovalListing"
    ) {
      //console.log("value:  ====>  ", value);
      //console.log("empId:  ====>  ", empId);
      //console.log("expStatus:  ====>  ", expStatus);
      //console.log("comments:  ====>  ", comments);
      //console.log("amount:  ====>  ", value?.claimAmount);
      //console.log("value?.projectdetails:  ====>  ", value?.projdetails);
      //console.log("approveOrReject:  ====>  ", approveOrReject);
      //console.log( "location?.state?.response:  ====>  ", location?.state?.response);

      location?.state?.response?.projdetails?.map((item) => {
        let tempElement = myExpenseList.ProjectDetails.find(
          (ele) => ele.ProjUniqueId == item.projectUniqueID
        );
        //console.log("tempElement:=====>", tempElement);
        array.push({
          recid: Encrypt(key, JSON.stringify(Number(item.projectRecID))),
          expid: Encrypt(
            key,
            JSON.stringify(Number(location?.state?.response?.expId))
          ),
          projid: Encrypt(key, JSON.stringify(Number(item.projectUniqueID))),
          projamount: tempElement.ProjAmount,
          claimedamount: location?.state?.response?.claimAmount,
        });
      });

      //console.log("array:  ====>  ", array);
      //console.log("expStatusupdatedRejected:  ====>  ", expStatus);

      const filterAdvanceList = advancesList?.filter(
        (item) => item.TotalAdvance > 0
      );

      const approvedAmountTravel = myExpenseList?.TravelFlag
        ? travelFieldValue
        : [
            {
              ExpId: myExpenseList?.ProjectDetails[0]?.ExpId,
              ApprovedAmount: Number(approvedAmount),
              ProjAmount: location?.state?.response?.claimAmount,
              ClaimedAmount: location?.state?.response?.claimAmount,
            },
          ];

      let dataObject = {
        empId: Encrypt(key, JSON.stringify(location?.state?.empId)),
        expStatus: expStatus,
        comment: comments,
        Amount: approvedAmountTravel, //{"expid":"value"}
        projdetails: myExpenseList?.TravelFlag ? [] : array,
        expenseadvanceslist:
          myExpenseList?.ExpenseDetail[0]?.TravelType == "Domestic" ||
          !myExpenseList?.TravelFlag
            ? []
            : filterAdvanceList,
        expenseadvancesdetail: !myExpenseList?.TravelFlag
          ? []
          : [advSummaryJson],
      };

      // try{
      //   const res =  dispatch(postFinancialApproveRejectExpenseData(dataObject));
      //   if(res.status != 200){
      //     setSystemErrorSnackbar(true);
      //   }
      //   else{
      //     dispatch(resetExpenseResponse());
      //     history.push("/expense-Management");
      //   }
      // }catch{
      //   setSystemErrorSnackbar(true);
      // }

      dispatch(postFinancialApproveRejectExpenseData(dataObject))
        .then((resp) => {
          if (resp.status != 200) {
            setSystemErrorSnackbar(true);
          } else {
            // dispatch(resetExpenseResponse());
            history.push("/expense-Management");
          }
        })
        .catch((err) => setSystemErrorSnackbar(true));
    } else if (
      viewFinApproval == false &&
      location?.state?.compName == "financeApprovalListing"
    ) {
      location?.state?.response?.projdetails?.map((item) => {
        let tempElement = myExpenseList.ProjectDetails.find(
          (ele) => ele.ProjUniqueId == item.projectUniqueID
        );
        //console.log("tempElement:=====>VEJS1", tempElement);
        array.push({
          recid: Encrypt(key, JSON.stringify(Number(item.projectRecID))),
          expid: Encrypt(
            key,
            JSON.stringify(Number(location?.state?.response?.expId))
          ),
          projid: Encrypt(key, JSON.stringify(Number(item.projectUniqueID))),
          projamount: 0,
          claimedamount: location?.state?.response?.claimAmount,
        });
      });

      const approvedAmountTravel = myExpenseList?.TravelFlag
        ? travelFieldValue
        : [
            {
              ExpId: myExpenseList?.ProjectDetails[0]?.ExpId,
              ApprovedAmount: Number(location?.state?.response?.claimAmount),
              ProjAmount: 0,
              ClaimedAmount: Number(location?.state?.response?.claimAmount),
            },
          ];
      //console.log("array:  ====>  ", array);
      const filterAdvanceList = advancesList?.filter(
        (item) => item.TotalAdvance > 0
      );

      let dataObject = {
        empId: Encrypt(key, JSON.stringify(location?.state?.empId)),
        expStatus: expStatus,
        comment: comments,
        Amount: approvedAmountTravel,
        projdetails: myExpenseList?.TravelFlag ? [] : array,
        expenseadvanceslist:
          myExpenseList?.ExpenseDetail[0]?.TravelType == "Domestic" ||
          !myExpenseList?.TravelFlag
            ? []
            : filterAdvanceList,
        expenseadvancesdetail: !myExpenseList?.TravelFlag
          ? []
          : [advSummaryJson],
      };
      //console.log("dataObject2:  ====>  ", dataObject);
      // try {
      //   const res = dispatch(postFinancialApproveRejectExpenseData(dataObject));
      //   if (res.status != 200) {
      //     setSystemErrorSnackbar(true);
      //   } else {
      //     dispatch(resetExpenseResponse());
      //     history.push("/expense-Management");
      //   }
      // } catch {
      //   setSystemErrorSnackbar(true);
      // }

      dispatch(postFinancialApproveRejectExpenseData(dataObject))
        .then((resp) => {
          if (resp.status != 200) {
            setSystemErrorSnackbar(true);
          } else {
            // dispatch(resetExpenseResponse());
            history.push("/expense-Management");
          }
        })
        .catch((err) => setSystemErrorSnackbar(true));
    } else if (location?.state?.compName == "teamApprovalListing") {
      //console.log("value in TAL compname:  ====>  ", value);
      //console.log("empId:  ====>  ", empId);
      //console.log("comments TAL2:  ====>  ", comments);
      //console.log("location?.state?.response: TAL1  ",location?.state?.response);

      let array = [];
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      array.push({
        recid: Encrypt(
          key,
          JSON.stringify(Number(location?.state?.response?.uniqueId))
        ),
        expid: Encrypt(
          key,
          JSON.stringify(Number(location?.state?.response?.expId))
        ),
        projid: Encrypt(
          key,
          JSON.stringify(Number(location?.state?.response?.projUniqueId))
        ),
      });

      let dataObject = {
        empId: Encrypt(key, JSON.stringify(location?.state?.empId)),
        comments: comments,
        project: array,
      };

      // try {
      //   const res = dispatch(postApproveRejectExpenseData(dataObject));
      //   if (res.status != 200) {
      //     setSystemErrorSnackbar(true);
      //   } else {
      //     //dispatch(resetExpenseResponse());
      //     history.push("/expense-Management");
      //   }
      // } catch {
      //   setSystemErrorSnackbar(true);
      // }

      dispatch(postApproveRejectExpenseData(dataObject))
        .then((resp) => {
          if (resp.status != 200) {
            setSystemErrorSnackbar(true);
          } else {
            // console.log("response", resp);
            //dispatch(resetExpenseResponse());
            history.push("/expense-Management");
          }
        })
        .catch((err) => setSystemErrorSnackbar(true));
    }

    setComments("");
    setViewDialog(false);
    // setViewFinApproval(false);
  };

  const handleComments = (data) => {
    //console.log("handleComments: comments:", data);
    setComments(data);
  };

  const handleOpenRejectionDialog = (row) => {
    setOpenBulkApproveDialog(true);
    setSingleExpense(row);
    setApproveOrReject(0);
    setExpStatus("Rejected");
    setViewDialog(true);
    // if (location?.state?.compName == "financeApprovalListing") {
    //   setViewFinApproval(true);
    // }
  };

  const handleOpenBulkApproveDialog = (row) => {
    setOpenBulkApproveDialog(true);
    setSingleExpense(row);
    setApproveOrReject(1);
    setExpStatus("Approved");
    setViewDialog(true);
  };
  const preventMinus = (e) => {
    if (
      e.code === "Minus" ||
      e.key === "-" ||
      (e.key === "ArrowDown" && approvedAmount <= 0)
    ) {
      e.preventDefault();
    }
  };

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  // const handleTravelDataDownload = (value) => {
  //   //console.log("valueee in handleDownload: ---->  ", value);
  //   let tempProjectId = "";

  //   value.projdetails.map((item) => {
  //     tempProjectId = tempProjectId + item.projectId;
  //   });
  //   //console.log("valueee in handleDownload: ---->  ", tempProjectId);
  //   value.projectId = tempProjectId;
  //   // let tempApprovedDate = moment(value.l1ApprovedDate).format("DD/MM/YYYY");
  //   // value.l1ApprovedDate = tempApprovedDate;
  //   //console.log("final valueee in handleDownload: ---->  ", value);
  //   setSingleDownload([value]);
  // };

  const headersTeamManager = [
    {
      label: "Expense Code",
      key: "ExpCode",
    },
    {
      label: "Category Name",
      key: "CategoryName",
    },
    {
      label: "To Location",
      key: "ToLocation",
    },
    {
      label: "From Location",
      key: "FromLocation",
    },
    {
      label: "Distance (Km/Miles)",
      key: "Distance",
    },
    {
      label: "From Date",
      key: "BillingCycleFromDate",
    },
    {
      label: "To Date",
      key: "BillingCycleToDate",
    },
    {
      label: "Claim Month",
      key: "BillingCycleMonth",
    },
    {
      label: "Created On",
      key: "CreatedOn",
    },
    {
      label: "Currency",
      key: "CurrencySymbol",
    },
    {
      label: "Relationship",
      key: "Dependent",
    },
    {
      label: "Claimed Amount",
      key: "ClaimedAmount",
    },
    {
      label: "Policy Limit",
      key: "ApprovedAmount", // Update this key based on your data structure
    },
    {
      label: "Purpose",
      key: "Purpose",
    },
    {
      label: "Status",
      key: "ExpStatus",
    },
    {
      label: "Attachments",
      key: "attachments",
    },
    {
      label: "Project ID",
      key: "ProjectId", // Update this key based on your data structure
    },
    {
      label: "Employee",
      key: "CreatedBy",
    },
    // {
    //   label: "Created By",
    //   key: "CreatedBy",
    // },

    // {
    //   label: "Manager Approval Date",
    //   key: "Level2ApprovedDate",
    // },
  ];

  const headersFinance = [
    {
      label: "Expense Code",
      key: "ExpCode",
    },
    {
      label: "Expense Category",
      key: "CategoryName",
    },
    {
      label: "To Location",
      key: "ToLocation",
    },
    {
      label: "From Location",
      key: "FromLocation",
    },
    {
      label: "Distance (Km/Miles)",
      key: "Distance",
    },
    {
      label: "From Date",
      key: "BillingCycleFromDate",
    },
    {
      label: "To Date",
      key: "BillingCycleToDate",
    },
    {
      label: " Claim Month",
      key: "BillingCycleMonth",
    },
    {
      label: "Manager Approval Date",
      key: "l1ApprovedDate",
    },
    {
      label: "Relationship",
      key: "Dependent",
    },
    {
      label: "Currency",
      key: "CurrencySymbol",
    },
    {
      label: "Claimed Amount",
      key: "ClaimedAmount",
    },
    {
      label: "Policy Limit",
      key: "policyLimit",
    },

    {
      label: "Attachments",
      key: "attachments",
    },
    {
      label: "Project ID",
      key: "ProjectId",
    },
    {
      label: "Employee",
      key: "CreatedBy",
    },
  ];

  const expCodeValue = myExpenseList?.ExpenseDetail[0].ExpCode;
  const projId =
    myExpenseList?.ProjectDetails != null
      ? myExpenseList?.ProjectDetails[0].ProjectId
      : "";
  const csvData = myExpenseList?.ExpenseDetail.map((item) => {
    item["ExpCode"] = expCodeValue;
    item["ProjectId"] = projId;
    return item;
  });

  // console.log("edd csv data", location?.state?.compName);
  const csvLink = {
    headers:
      location?.state?.compName == "teamApprovalListing"
        ? headersTeamManager
        : headersFinance,
    data: csvData ? csvData : [[]],
    filename:
      location?.state?.compName == "teamApprovalListing"
        ? `TeamApproval_${moment(new Date()).format("DDMMYYYY_HHmm")}.csv`
        : `FinanceApproval_${moment(new Date()).format("DDMMYYYY_HHmm")}.csv`,
  };

  const addAdvancesLine = () => {
    let array = JSON.parse(JSON.stringify(advancesList));
    array.push({
      ExpId: myExpenseList?.ExpenseDetail[0].ExpId,
      DepositDate: null,
      Currency: currency?.currency,
      TotalAdvance: 0,
      AdvanceType: "Travel Card",
    });
    setAdvancesList(array);
  };

  const handleAdvancesList = (e, index, kind) => {
    // console.log("expense detail handleAdvancesList", e);
    let arr = [...advancesList];
    if (kind == "type") {
      arr[index].AdvanceType = e.target.value;
    }
    if (kind == "date") {
      arr[index].DepositDate = e;
      if (arr[index].AdvanceType == "") {
        arr[index].AdvanceType = "Travel Card";
      }
    }
    if (kind == "amt") {
      arr[index].TotalAdvance = Number(e.target.value.replaceAll(",", ""));
    }
    if (kind == "currency") {
      arr?.forEach((item, index) => {
        item.Currency = e.target.value.currency;
      });
    }
    // arr[index].Currency = currency.currency;
    // console.log("target comsole", e, index, kind, arr);

    setAdvancesList(arr);
    let dataCard = 0;
    let dataCash = 0;
    arr.map((item, index) => {
      if (item.AdvanceType == "Travel Card") {
        dataCard = dataCard + item.TotalAdvance;
      } else {
        dataCash = dataCash + item.TotalAdvance;
      }
    });
    setTravelCardAmount(dataCard);
    setCash(dataCash);

    setTotalAdv(dataCard + dataCash);
    let advDetail = [
      { AdvancesTypeAmt: 0, AdvanceType: "Cash" },
      { AdvancesTypeAmt: 0, AdvanceType: "Travel Card" },
    ];

    advDetail = advDetail.map((item) =>
      item.AdvanceType == "Travel Card"
        ? {
            ...item,
            AdvancesTypeAmt: Number(dataCard),
          }
        : {
            ...item,
            AdvancesTypeAmt: Number(dataCash),
          }
    );

    setAdvValue(advDetail);

    // console.log("advances details1", advDetail);
    // const advDetailJson = advSummaryJson;
    // advDetailJson.AdvCash = Number(dataCash);
    // advDetailJson.AdvTravelCard = Number(dataCard);
    // advDetailJson.AdvTotal = Number(dataCash + dataCard);
    // console.log("advJson1",advDetailJson);
    // setAdvancesJson(advDetailJson);
  };

  const handleRemoveAdvances = (i) => {
    const list = JSON.parse(JSON.stringify(advancesList));
    // console.log("list", list);let dataCard = 0;
    let advType = list[i].AdvanceType;

    list.splice(i, 1);

    let travelCard = 0;
    let travelCash = 0;
    list.map((item, index) => {
      if (item.AdvanceType == "Travel Card") {
        travelCard = travelCard + item.TotalAdvance;
      } else {
        travelCash = travelCash + item.TotalAdvance;
      }
    });

    setAdvancesList(list);

    setCash(travelCash);
    setTravelCardAmount(travelCard);

    setTotalAdv(travelCard + travelCash);

    const advDetail = advValue.map((item) =>
      item.AdvanceType == "Travel Card"
        ? {
            ...item,
            AdvancesTypeAmt: Number(travelCard),
          }
        : {
            ...item,
            AdvancesTypeAmt: Number(travelCash),
          }
    );

    setAdvValue(advDetail);
    // console.log("total amount2", travelCard, travelCash, advDetail, list);

    //props.handleProjectList(list);
  };

  const handleRefund = (e, type) => {
    // console.log("handleRefund", e.target.value);
    setAdvSummaryJson((prevVal) => {
      const advSummary = { ...prevVal };
      if (type == "Travel Card") {
        advSummary.TravelCardBal =
          e.target.value === "" ? "0" : e.target.value.replaceAll(",", "");
      } else if (type == "Cash") {
        advSummary.CashBal =
          e.target.value === "" ? "0" : e.target.value.replaceAll(",", "");
      }
      advSummary.RefundReceived = (
        Number(advSummary.CashBal) + Number(advSummary.TravelCardBal)
      ).toString();
      advSummary.NetPayable = ((
        Number(totalAdv) > 0
          ? totalAdv - travelTotalApproved - Number(advSummary?.RefundReceived)
          : travelTotalApproved - Number(advSummary?.RefundReceived)
      )?.toFixed(2)).toString();
      // console.log("refund travel", advSummary);
      return advSummary;
    });
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <Paper
        elevation={0}
        top={0}
        style={{
          backgroundColor: "#EDF2FF",
          width: "100%",
          marginTop: "-18px",
        }}
      >
        {
          <Typography
            style={{
              color: "#081F32",
              fontSize: "16px",
              fontWeight: 600,
              fontFamily: "Montserrat",
              paddingLeft: "25px",
              backgroundColor: "#F8F9FF",
              lineHeight: "50px",
              paddingTop: "10px",
              justifyContent: "flex-start",
            }}
          >
            <ChevronLeftIcon
              style={{
                marginBottom: "-6px",
                backgroundColor: "#F8F9FF",
                paddingRight: "2px",
                paddingLeft: "-5px",
                marginLeft: "-13px",
              }}
              onClick={() =>
                location?.state?.compName == "allExpenseTable"
                  ? history.push("/allExpenseReports")
                  : history.push("/expense-Management")
              }
            />
            Expense Details
          </Typography>
        }
        <Divider />
        <Container maxWidth="sm">
          <Grid container>
            <Grid
              item
              xs={7}
              style={{
                marginBottom: "10px",
                color: "#4C4C4C",
                fontSize: "12px",
                // paddingLeft:"25px",
                marginTop: "5px",
              }}
            >
              Expense ID: {myExpenseList?.ExpenseDetail[0]?.ExpCode}
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                textAlign: "center",
                color: "#4C4C4C",
                fontSize: "12px",
                marginTop: "3px",
              }}
            >
              {moment(myExpenseList?.ExpenseDetail[0]?.ReceiptDate).format(
                "DD/MM/YYYY"
              )}
            </Grid>
            <Grid item xs={7}>
              <b style={{ color: "#081F32", wordWrap: "break-word" }}>
                {myExpenseList?.ExpenseDetail[0]?.ExpName}
              </b>
            </Grid>
            <Grid item xs={5} style={{ textAlign: "center" }}>
              <Chip
                label={myExpenseList?.ExpFinalStatus}
                style={{
                  color: "white",
                  width: "97px",
                  height: "24px",
                  marginRight:
                    myExpenseList?.ExpFinalStatus === "Paid" ? "20px" : "0px",
                  background:
                    myExpenseList?.ExpFinalStatus === "In Progress"
                      ? "#E0B15C"
                      : myExpenseList?.ExpFinalStatus === "Approved"
                      ? " #349259"
                      : myExpenseList?.ExpFinalStatus === "Rejected"
                      ? "#D40606"
                      : myExpenseList?.ExpFinalStatus === "Paid"
                      ? "#406086"
                      : "#9E9E9E",
                }}
              />
            </Grid>
          </Grid>
          <hr
            style={{
              size: "0.2px",
              width: "100%",
              color: "#A3A3A3",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          />
          <b
            style={{ fontSize: "14px", color: "#353535", marginBottom: "10px" }}
          >
            {myExpenseList?.ExpenseDetail[0]?.EmpName} (
            {myExpenseList?.ExpenseDetail[0]?.EmpCode})
          </b>
        </Container>
        <hr style={{ size: "1px", width: "100%", color: "#A3A3A3" }} />
      </Paper>
      <Grid container>
        <Container>
          <h4>Expense Workflow</h4>
        </Container>
        <Grid
          item
          xs={12}
          style={{
            color: "#979797",
            // paddingBottom: "50px",
            overflow: "scroll",
            height: "95px",
          }}
          className={classes.scroolEdit}
        >
          <Stepper myExpenseList={myExpenseList} data={location?.state} />
        </Grid>
      </Grid>
      {/* <Container
        maxWidth="sm"
        className={matches ? classes.detailsContainer : ""}
      >
        <u style={{ color: "#008CE6" }}>
          <Link onClick={handleToggle}>View Details</Link>
        </u>
      </Container> */}
      <Backdrop className={classes.backdrop} open={open}>
        <Paper style={{ paddingBottom: "5px" }}>
          <Container>
            <h4>
              Expense Workflow{" "}
              <HighlightOffIcon
                style={{ marginLeft: "20px" }}
                onClick={handleClose}
              />
            </h4>
          </Container>
          <MobileVerticalStepper
            myExpenseList={myExpenseList}
            data={location?.state}
          />
        </Paper>
      </Backdrop>
      <hr style={{ size: "1px", width: "100%", color: "#A3A3A3" }} />
      {/* {console.log("edd non-travel", myExpenseList)} */}
      {!myExpenseList?.TravelFlag ? (
        <>
          <Container maxWidth="sm">
            <h4>Expense Details</h4>
            {/* <Typography style={{
          fontSize:"14px",
          fontWeight:600,
          fontFamily:"Montserrat",
          lineHeight:"24px",
          marginLeft:"-13px",
        }}
                          className={classes.auditHistory}
                          onClick={handleOpenAuditHistDialog}
                        >
                          Audit History
                        </Typography>
                        <AuditHistoryDialog
                          open={openAuditHistDialog}
                          handleClose={handleCloseAuditHistDialog}
                          auditHistoryData={auditHistoryData}
                        /> */}
            <Grid container spacing={2} style={{ fontSize: "13px" }}>
              <Grid item xs={5} style={{ color: "#979797" }}></Grid>
              <Grid
                item
                xs={7}
                style={{
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  paddingBottom: "8px",
                  paddingTop: "12px",
                  overflow: "hidden",
                  maxWidth: "440px",
                }}
                className={classes.auditHistory}
                onClick={handleOpenAuditHistDialog}
              >
                Audit history
              </Grid>
              <AuditHistoryDialog
                open={openAuditHistDialog}
                handleClose={handleCloseAuditHistDialog}
                auditHistoryData={auditHistoryData}
              />

              <Grid item xs={5} style={{ color: "#979797" }}>
                Expense Category:
              </Grid>
              <Grid
                item
                xs={7}
                style={{
                  padding: "8px",
                  overflow: "hidden",
                  maxWidth: "440px",
                }}
              >
                {myExpenseList?.ExpenseDetail[0]?.CategoryName}
              </Grid>

              {/* ayushi */}
              {myExpenseList?.ExpenseDetail[0]?.BooksQty != null &&
              myExpenseList?.ExpenseDetail[0]?.BooksQty != "null" &&
              myExpenseList?.ExpenseDetail[0]?.BooksQty != "" ? (
                <>
                  <Grid item xs={5} style={{ color: "#979797" }}>
                    Quantity:
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    style={{
                      padding: "8px",
                      overflow: "hidden",
                      maxWidth: "440px",
                    }}
                  >
                    {myExpenseList?.ExpenseDetail[0]?.BooksQty}
                  </Grid>
                </>
              ) : (
                ""
              )}
              {/* {myExpenseList?.ExpenseDetail[0]?.BillNumber != null &&
              myExpenseList?.ExpenseDetail[0]?.BillNumber != "null" &&
              myExpenseList?.ExpenseDetail[0]?.BillNumber != "" ? (
                <>
                  <Grid item xs={5} style={{ color: "#979797" }}>
                    Bill Number:
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    style={{
                      padding: "8px",
                      overflow: "hidden",
                      maxWidth: "440px",
                    }}
                  >
                    {myExpenseList?.ExpenseDetail[0]?.BillNumber}
                  </Grid>
                </>
              ) : (
                ""
              )} */}
              {myExpenseList?.ExpenseDetail[0]?.VendorName != null &&
              myExpenseList?.ExpenseDetail[0]?.VendorName != "null" &&
              myExpenseList?.ExpenseDetail[0]?.VendorName != "" ? (
                <>
                  <Grid item xs={5} style={{ color: "#979797" }}>
                    Vendor Name:
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    style={{
                      padding: "8px",
                      overflow: "hidden",
                      maxWidth: "440px",
                    }}
                  >
                    {myExpenseList?.ExpenseDetail[0]?.VendorName}
                  </Grid>
                </>
              ) : (
                ""
              )}
              {/* ayushi */}
              {myExpenseList?.ExpenseDetail[0]?.BillNumber != null &&
              myExpenseList?.ExpenseDetail[0]?.BillNumber != "null" &&
              myExpenseList?.ExpenseDetail[0]?.BillNumber != "" ? (
                <>
                  <Grid item xs={5} style={{ color: "#979797" }}>
                    Bill Number:
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    style={{
                      padding: "8px",
                      overflow: "hidden",
                      maxWidth: "440px",
                    }}
                  >
                    {myExpenseList?.ExpenseDetail[0]?.BillNumber}
                  </Grid>
                </>
              ) : (
                ""
              )}
              {/* ayushi */}
              {myExpenseList?.ExpenseDetail[0]?.GSTNumber != null &&
              myExpenseList?.ExpenseDetail[0]?.GSTNumber != "null" &&
              myExpenseList?.ExpenseDetail[0]?.GSTNumber != "" ? (
                <>
                  <Grid item xs={5} style={{ color: "#979797" }}>
                    GST Number:
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    style={{
                      padding: "8px",
                      overflow: "hidden",
                      maxWidth: "440px",
                    }}
                  >
                    {myExpenseList?.ExpenseDetail[0]?.GSTNumber}
                  </Grid>
                </>
              ) : (
                ""
              )}
              {/* ayushi */}
              {myExpenseList?.ExpenseDetail[0]?.BillingCycleFromDate != null ? (
                <>
                  <Grid item xs={5} style={{ color: "#979797" }}>
                    From Date:
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    style={{
                      padding: "8px",
                      overflow: "hidden",
                      maxWidth: "440px",
                    }}
                  >
                    {moment(
                      myExpenseList?.ExpenseDetail[0]?.BillingCycleFromDate
                    ).format("DD/MM/YYYY")}
                  </Grid>
                </>
              ) : (
                ""
              )}

              {/* ayushi */}
              {myExpenseList?.ExpenseDetail[0]?.BillingCycleToDate != null ? (
                <>
                  <Grid item xs={5} style={{ color: "#979797" }}>
                    To Date:
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    style={{
                      padding: "8px",
                      overflow: "hidden",
                      maxWidth: "440px",
                    }}
                  >
                    {moment(
                      myExpenseList?.ExpenseDetail[0]?.BillingCycleToDate
                    ).format("DD/MM/YYYY")}
                  </Grid>
                </>
              ) : (
                ""
              )}

              {/* ayushi */}
              {myExpenseList?.ExpenseDetail[0]?.BillingCycleMonth != null &&
              myExpenseList?.ExpenseDetail[0]?.BillingCycleMonth != "null" &&
              myExpenseList?.ExpenseDetail[0]?.BillingCycleMonth != "" ? (
                <>
                  <Grid item xs={5} style={{ color: "#979797" }}>
                    Claim Month:
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    style={{
                      padding: "8px",

                      overflow: "hidden",
                      maxWidth: "440px",
                    }}
                  >
                    {myExpenseList?.ExpenseDetail[0]?.BillingCycleMonth}
                  </Grid>
                </>
              ) : (
                ""
              )}

              {/* ayushi */}
              {myExpenseList?.ExpenseDetail[0]?.FromLocation != null &&
              myExpenseList?.ExpenseDetail[0]?.FromLocation != "null" ? (
                <>
                  <Grid item xs={5} style={{ color: "#979797" }}>
                    From Location:
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    style={{
                      padding: "8px",

                      overflow: "hidden",
                      maxWidth: "440px",
                    }}
                  >
                    {myExpenseList?.ExpenseDetail[0]?.FromLocation}
                  </Grid>
                </>
              ) : (
                ""
              )}

              {/* ayushi */}
              {myExpenseList?.ExpenseDetail[0]?.ToLocation != null &&
              myExpenseList?.ExpenseDetail[0]?.ToLocation != "null" ? (
                <>
                  <Grid item xs={5} style={{ color: "#979797" }}>
                    To Location:
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    style={{
                      padding: "8px",

                      overflow: "hidden",
                      maxWidth: "440px",
                    }}
                  >
                    {myExpenseList?.ExpenseDetail[0]?.ToLocation}
                  </Grid>
                </>
              ) : (
                ""
              )}
              {/* ayushi */}
              {myExpenseList?.ExpenseDetail[0]?.Distance != 0 ? (
                <>
                  <Grid item xs={5} style={{ color: "#979797" }}>
                    Distance (Km/Miles):
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    style={{
                      padding: "8px",

                      overflow: "hidden",
                      maxWidth: "440px",
                    }}
                  >
                    {myExpenseList?.ExpenseDetail[0]?.Distance}
                  </Grid>
                </>
              ) : (
                ""
              )}

              <Grid item xs={5} style={{ color: "#979797" }}>
                Purpose:
              </Grid>
              <Grid
                item
                xs={7}
                style={{
                  padding: "8px",

                  overflow: "hidden",
                  maxWidth: "440px",
                }}
              >
                {myExpenseList?.ExpenseDetail[0]?.Purpose}
              </Grid>
              <Grid item xs={5} style={{ color: "#979797" }}>
                Project ID:
              </Grid>
              <Grid item xs={7}>
                {myExpenseList?.ProjectDetails?.map((value) => {
                  return (
                    <>
                      <Typography
                        className={
                          (location?.state?.compName == "teamApprovalListing" ||
                            location?.state?.compName ==
                              "financeApprovalListing") &&
                          value.ProjectId == location?.state?.response.projectId
                            ? classes.textConditional
                            : classes.projectId
                        }
                        style={{
                          //fontFamily: "inherit",
                          font: "inherit",
                          marginBottom: "8px",
                        }}
                      >
                        {value.ProjectId} ({value?.ProjectName})
                      </Typography>

                      {/* {<br />} */}
                    </>
                  );
                })}
              </Grid>
              <Grid item xs={5} style={{ color: "#979797" }}>
                Payment Method:
              </Grid>
              <Grid
                item
                xs={7}
                style={{
                  padding: "8px",

                  overflow: "hidden",
                  maxWidth: "440px",
                }}
              >
                {myExpenseList?.ExpenseDetail[0]?.MethodName}
              </Grid>
              {myExpenseList?.ExpenseDetail[0]?.DependentId != 0 &&
              myExpenseList?.ExpenseDetail[0]?.Purpose == "Medical Expenses" ? (
                <>
                  <Grid item xs={5} style={{ color: "#979797" }}>
                    Relationship:
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    style={{
                      padding: "8px",
                      overflow: "hidden",
                      maxWidth: "440px",
                    }}
                  >
                    {myExpenseList?.ExpenseDetail[0]?.Dependent}
                  </Grid>
                </>
              ) : (
                ""
              )}
              <Grid item xs={5} style={{ color: "#979797" }}>
                Bill Amount:
              </Grid>
              <Grid item xs={7}>
                {myExpenseList?.ExpenseDetail[0]?.CurrencyChanged === 1 ? (
                  <Tooltip title="Default currency has been changed.">
                    <span style={{ color: "red" }}>
                      {myExpenseList?.ExpenseDetail[0]?.CurrencySymbol}
                      {myExpenseList?.ExpenseDetail[0]?.BillAmount.toString().replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )}
                    </span>
                  </Tooltip>
                ) : (
                  `${
                    myExpenseList?.ExpenseDetail[0]?.CurrencySymbol
                  }${myExpenseList?.ExpenseDetail[0]?.BillAmount.toString().replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                  )}`
                )}
              </Grid>
              <Grid item xs={5} style={{ color: "#979797" }}>
                Claim Amount:
              </Grid>
              <Grid item xs={7}>
                {myExpenseList?.ExpenseDetail[0]?.CurrencyChanged === 1 ||
                (location?.state?.response?.policyLimit &&
                  myExpenseList?.ExpenseDetail[0]?.ClaimedAmount >
                    location?.state?.response?.policyLimit &&
                  location?.state?.response?.exceptionalApproval == 1) ? (
                  <Tooltip
                    title={
                      myExpenseList?.ExpenseDetail[0]?.CurrencyChanged === 1 &&
                      location?.state?.response?.exceptionalApproval == 1 &&
                      myExpenseList?.ExpenseDetail[0]?.ClaimedAmount >
                        location?.state?.response?.policyLimit &&
                      location?.state?.response?.exceptionalApproval == 1
                        ? "Claim Amount Exceeds Policy limit and Default currency has been changed"
                        : myExpenseList?.ExpenseDetail[0]?.CurrencyChanged === 1
                        ? "Default currency has been changed"
                        : "Claim Amount exceeds policy limit"
                    }
                  >
                    <span style={{ color: "red" }}>
                      {myExpenseList?.ExpenseDetail[0]?.CurrencySymbol}
                      {myExpenseList?.ExpenseDetail[0]?.ClaimedAmount.toString().replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )}
                    </span>
                  </Tooltip>
                ) : (
                  <div>
                    {myExpenseList?.ExpenseDetail[0]?.CurrencySymbol}
                    {myExpenseList?.ExpenseDetail[0]?.ClaimedAmount.toString().replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    )}
                  </div>
                )}
              </Grid>
              {(myExpenseList?.ProjectDetails[0]?.ExpStatus !== "Approved" ||
                myExpenseList?.ProjectDetails[0]?.ExpStatus !== "Rejected") &&
              location?.state?.compName === "financeApprovalListing" ? (
                <>
                  <Grid item xs={5} style={{ color: "#979797" }}>
                    {"Approved Amount:"}
                  </Grid>
                  <Grid item xs={7}>
                    <NumericFormat
                      id="outlined-basic"
                      label="Approved amount"
                      onKeyPress={preventMinus}
                      variant="outlined"
                      value={approvedAmount}
                      customInput={TextField}
                      error={
                        approvedAmount >
                        myExpenseList?.ExpenseDetail[0]?.ClaimedAmount
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        if (
                          e.target.value !=
                            Number(location?.state?.response?.claimAmount) &&
                          location?.state?.compName == "financeApprovalListing"
                        ) {
                          //console.log("approvedAmount", approvedAmount,props.approvedAmount,location?.state?.response?.claimAmount,location?.state?.response,e.target.value);
                          setViewFinApproval(true);
                        } else if (
                          e.target.value ==
                            Number(location?.state?.response?.claimAmount) &&
                          location?.state?.compName == "financeApprovalListing"
                        ) {
                          //console.log("approvedAmount", approvedAmount);
                          setViewFinApproval(false);
                        }

                        //console.log('kjsadlkf', e.target.value);
                        setApprovedAmountVal(e.target.value);
                        props.setAmountNewValue(e.target.value);
                      }}
                    />

                    {approvedAmountVal >
                    myExpenseList?.ExpenseDetail[0]?.ClaimedAmount ? (
                      <span
                        style={{
                          color: "#E02020",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        Amount must be equal to or less than Claim Amount.
                      </span>
                    ) : (
                      ""
                    )}
                  </Grid>
                </>
              ) : (
                ""
              )}

              {myExpenseList?.ExpenseDetail[0]?.ApprovedAmount !== 0 &&
                myExpenseList?.ProjectDetails[0]?.ExpStatus ==
                  "In Progress" && (
                  <Grid item xs={5} style={{ color: "#979797" }}>
                    Approved Amount:
                  </Grid>
                )}
              {myExpenseList?.ExpenseDetail[0]?.ApprovedAmount !== 0 &&
                myExpenseList?.ProjectDetails[0]?.ExpStatus ==
                  "In Progress" && (
                  <Grid item xs={7}>
                    {myExpenseList?.ExpenseDetail[0]?.CurrencyChanged === 1 ? (
                      <Tooltip title="Default currency has been changed.">
                        <span style={{ color: "red" }}>
                          {myExpenseList?.ExpenseDetail[0]?.CurrencySymbol}
                          {myExpenseList?.ExpenseDetail[0]?.ApprovedAmount.toString().replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          )}
                        </span>
                      </Tooltip>
                    ) : (
                      `${
                        myExpenseList?.ExpenseDetail[0]?.CurrencySymbol
                      }${myExpenseList?.ExpenseDetail[0]?.ApprovedAmount.toString().replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )}`
                    )}
                  </Grid>
                )}
              {`${myExpenseList?.ExpenseDetail[0]?.ApproverLevel2Comment}` !==
                "null" && (
                <Grid item xs={5} style={{ color: "#979797" }}>
                  Comment:
                </Grid>
              )}
              {`${myExpenseList?.ExpenseDetail[0]?.ApproverLevel2Comment}` !==
                "null" && (
                <Grid item xs={7} style={{ wordBreak: "break-all" }}>
                  {`${myExpenseList?.ExpenseDetail[0]?.ApproverLevel2Comment}`}
                </Grid>
              )}
            </Grid>
            <h4 style={{ marginBottom: "0px" }}>Split Amount</h4>
            {myExpenseList?.ProjectDetails?.map((value) => {
              return (
                <>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    style={{ paddingTop: "8px" }}
                  >
                    <Grid
                      item
                      xs={4}
                      style={{
                        color: "#979797",
                        paddingTop: "8px",
                        fontSize: "13px",
                      }}
                    >
                      {value?.ProjectId}
                    </Grid>
                    <Grid
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "66%",
                        alignItems: "center",
                      }}
                    >
                      {/* ayushi */}
                      <Typography
                        className={
                          (location?.state?.compName == "teamApprovalListing" ||
                            location?.state?.compName ==
                              "financeApprovalListing") &&
                          value.ProjectId == location.state.response.projectId
                            ? classes.textConditionalSplitAmount
                            : classes.expenseIdBillable
                        }
                        style={{
                          paddingLeft: "9px",
                          marginTop: `${
                            //siddhant
                            value?.ApprovedAmount !== null &&
                            value?.ApprovedAmount != value?.ProjAmount
                              ? "-17px"
                              : value?.Billable === 1
                              ? "0px"
                              : "-17px"
                          }`,
                          //fontFamily: "inherit",
                          fontSize: "13px",
                          minWidth: "100px", //siddhant
                        }}
                      >
                        {value?.ApprovedAmount !== null &&
                        value?.ApprovedAmount != value?.ProjAmount ? (
                          <Grid item xs={2}>
                            <small>
                              <s>
                                {value?.CurrencySymbol}
                                {value.ProjAmount
                                  ? value.ProjAmount.toString().replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  : ""}
                              </s>
                            </small>
                            {<br />}
                            {value?.CurrencySymbol}
                            {value !== null &&
                            value.ApprovedAmount !=
                              location?.state?.response?.approvedAmount &&
                            value.ApprovedAmount >
                              location?.state?.response?.approvedAmount
                              ? location?.state?.response?.approvedAmount
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : value !== null && value.ApprovedAmount
                              ? value?.ApprovedAmount.toString().replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                              : ""}
                          </Grid>
                        ) : (
                          <>
                            <Typography
                              className={
                                (location?.state?.compName ==
                                  "teamApprovalListing" ||
                                  location?.state?.compName ==
                                    "financeApprovalListing") &&
                                value.ProjectId ==
                                  location.state.response.projectId
                                  ? classes.textConditionalSplitAmount
                                  : classes.expenseId
                              }
                            >
                              {/* {value.CurrencySymbol}
                                      {value.ProjAmount.toString().replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )} */}
                            </Typography>
                            {value?.Billable !== 1 ? <br /> : ""}
                            {value?.CurrencySymbol}
                            {value.ProjAmount
                              ? value.ProjAmount.toString().replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                              : ""}
                          </>
                        )}
                      </Typography>
                      <Grid
                        item
                        xs={4}
                        style={{
                          backgroundColor: "#DDDDDD",
                          fontSize: "10px",
                          textAlign: "center",
                          margin: "2px", //sid
                          textOverflow: "ellipsis",

                          padding: `4px ${
                            value?.Billable == 1 ? "14px" : "2px"
                          }`,
                        }}
                      >
                        {value?.Billable == 1 ? "Billable" : "Non-Billable"}
                      </Grid>
                      <Grid item /*xs={1}*/ style={{ padding: "0px" }}>
                        {value?.ExpStatus == "Approved" ? (
                          <FileCopyIcon
                            style={{ color: "#349259", padding: "0px" }} //sid
                          />
                        ) : value?.ExpStatus == "In Progress" ? (
                          <FileCopyIcon
                            style={{ color: "#E0B15C", padding: "0px" }}
                          />
                        ) : value?.ExpStatus == "Rejected" ? (
                          <FileCopyIcon
                            style={{ color: "#D40606", padding: "0px" }}
                          />
                        ) : value?.ExpStatus == "Paid" ? (
                          <FileCopyIcon
                            style={{ color: "#406086", padding: "0px" }}
                          />
                        ) : null}
                      </Grid>
                      <Grid item /*xs={1}*/ style={{ padding: "0px" }}>
                        {value?.ExpStatus !== "Draft" && (
                          <Tooltip
                            title={handleToolTip(value)}
                            arrow
                            enterTouchDelay={0}
                          >
                            <ErrorOutlineIcon
                              style={{ color: "#9E9E9E", paddingTop: "5px" }}
                            />
                            {/* Ayushi */}
                          </Tooltip>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              );
            })}
          </Container>
          <hr style={{ size: "1px", width: "100%", color: "#A3A3A3" }} />
          <Container maxWidth="sm">
            <h4>
              Attachments
              {`(${myExpenseList?.attachements?.length} Attachments)`}
            </h4>
            <Grid container spacing={2}>
              {myExpenseList?.attachements?.length > 0 &&
                myExpenseList?.attachements?.map((value) => {
                  return (
                    //<Grid item xs={12} style={{ paddingBottom: "15px" }}>
                    <>
                      <Grid itm xs={10}>
                        <Button
                          variant="contained"
                          style={{
                            borderRadius: "20px",
                            fontSize: "10px",
                            fontWeight: "bolder",
                            fontFamily: "Montserrat",
                            overflowWrap: "anywhere",
                            padding: "5px",
                            marginTop: "5px",
                            width: "84%",
                            justifyContent: "flex-start",
                            backgroundColor: "#EDF2FF",
                          }}
                        >
                          {value?.FileName &&
                          value?.FileName?.includes(".pdf") ? (
                            <FileCopyIcon />
                          ) : (
                            <PhotoIcon />
                          )}

                          {/* {value.FileName} */}
                          {value.FileName.length > 20
                            ? value.FileName.substring(0, 20) + "..."
                            : value.FileName}
                        </Button>
                      </Grid>

                      <Grid item xs={2}>
                        <Button
                          style={{ color: "#9e9e9e", padding: "5px" }}
                          onClick={() => {
                            handleDownload(myExpenseList, value.FileName);
                          }}
                        >
                          <GetAppOutlinedIcon />
                        </Button>
                      </Grid>
                      {/* </Grid> */}
                    </>
                  );
                })}
            </Grid>
          </Container>
          {myExpenseList?.EmployeeDetails?.length > 0 && (
            <hr style={{ size: "1px", width: "100%", color: "#A3A3A3" }} />
          )}
          {myExpenseList?.EmployeeDetails?.length > 0 && (
            <Container maxWidth="sm">
              <h4>
                Attendees Information{" "}
                {`(${myExpenseList?.EmployeeDetails?.length} Attendees)`}
              </h4>
              <Grid container>
                <Grid item xs={12}>
                  {myExpenseList?.EmployeeDetails?.map((value) => {
                    const colour = Math.random().toString(16).substr(-6);
                    return (
                      <>
                        <Button
                          variant="contained"
                          style={{
                            borderRadius: "20px",
                            padding: "3px",
                            margin: "6px",
                          }}
                        >
                          <Avatar
                            style={{
                              backgroundColor: `#${colour}`,
                              color: "white",
                            }}
                          >
                            {handleName(value.EmpName)}
                          </Avatar>{" "}
                          {value.EmpName ? value.EmpName.split("(")[0] : ""}
                        </Button>
                      </>
                    );
                  })}
                </Grid>
              </Grid>
            </Container>
          )}
          <hr />
          {location?.state?.compName == "teamApprovalListing" ||
          location?.state?.compName == "financeApprovalListing" ? (
            <>
              <Container>
                <Grid container>
                  <Grid
                    item
                    xs={5}
                    style={{
                      marginBottom: "4px", //Ayushi
                    }}
                  >
                    <Typography variant="p" className={classes.removeFilter}>
                      <Button
                        variant="outlined"
                        size="medium"
                        disabled={
                          (location?.state?.compName == "teamApprovalListing" &&
                            (myExpenseList?.ProjectDetails[0]?.ExpStatus ==
                              "Rejected" ||
                              myExpenseList?.ProjectDetails[0]?.ExpStatus ==
                                "Approved" ||
                              location?.state?.response?.expStatus ==
                                "Rejected" ||
                              location?.state?.response?.expStatus ==
                                "Approved")) ||
                          approvedAmountVal >
                            myExpenseList?.ExpenseDetail[0]?.ClaimedAmount
                            ? true
                            : false
                        }
                        style={{
                          backgroundColor: "#FFFFFF",
                          padding: "1% 28%", //Ayushi
                          fontSize: "18px",
                        }}
                        onClick={() => handleOpenRejectionDialog(myExpenseList)}
                      >
                        Reject
                      </Button>{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    &nbsp;
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="p" className={classes.applyFilter}>
                      <Button
                        variant="outlined"
                        disabled={
                          (location?.state?.compName == "teamApprovalListing" &&
                            (myExpenseList?.ProjectDetails[0]?.ExpStatus ==
                              "Rejected" ||
                              myExpenseList?.ProjectDetails[0]?.ExpStatus ==
                                "Approved" ||
                              location?.state?.response?.expStatus ==
                                "Rejected" ||
                              location?.state?.response?.expStatus ==
                                "Approved")) ||
                          approvedAmountVal >
                            myExpenseList?.ExpenseDetail[0]?.ClaimedAmount
                            ? true
                            : false
                        }
                        size="medium"
                        style={{
                          color: "#FFFFFF",
                          backgroundColor:
                            buttonState ||
                            approvedAmountVal >
                              myExpenseList?.ExpenseDetail[0]?.ClaimedAmount
                              ? "#BDBDBD"
                              : "#0B1941",
                          padding: "1% 30%",
                          fontSize: "18px",
                        }}
                        onClick={() =>
                          handleOpenBulkApproveDialog(myExpenseList)
                        }
                      >
                        Approve
                      </Button>{" "}
                    </Typography>
                    <ApproveRejectDialog
                      openBulkApproveDialog={openBulkApproveDialog}
                      singleExpense={singleExpense}
                      veiwDialog={veiwDialog}
                      approveOrReject={approveOrReject}
                      handleComments={handleComments}
                      handleSingleApproveRejectExpense={
                        handleSingleApproveRejectExpense
                      }
                      approvedAmountVal={approvedAmountVal}
                      handleCloseBulkApproveDialog={
                        handleCloseBulkApproveDialog
                      }
                      viewFinApproval={viewFinApproval}
                      mobileEmpId={ssoEmpIdDetails?.empName}
                    />
                  </Grid>
                </Grid>
              </Container>
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        //travel expense
        <>
          <Container maxWidth="sm" style={{ padding: "0px 0px 30px" }}>
            <Grid style={{ display: "flex", alignItems: "center" }}>
              <h4 style={{ margin: "16px" }}>Expense Detail</h4>
              <Grid align="left">
                <Typography
                  className={classes.auditHistory}
                  onClick={handleOpenAuditHistDialog}
                  style={{
                    margin: matches && "0px",
                    paddingLeft: matches && "0px",
                    fontSize: matches && "12px",
                  }}
                >
                  Audit History
                </Typography>
                <AuditHistoryDialog
                  open={openAuditHistDialog}
                  handleClose={handleCloseAuditHistDialog}
                  auditHistoryData={auditHistoryData}
                />
              </Grid>
            </Grid>
            <Grid container style={{ fontSize: "13px", padding: "0px 8px" }}>
              <Grid
                item
                xs={3}
                style={{
                  color: "#979797",
                  fontWeight: "600",
                  padding: "0px 8px 16px 8px",
                }}
              >
                Purpose:
              </Grid>
              <Grid
                item
                xs={9}
                style={{ fontWeight: "600", padding: "0px 8px 16px 8px" }}
              >
                {myExpenseList?.ExpenseDetail[0]?.Purpose}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {/* {myExpenseList?.ProjectDetails?.map((prdt, index) => {
                return ( */}
              <Accordion
                // key={index}
                expanded={expanded}
                // expanded={expanded?.includes(index)}
                onChange={() => setExpanded(!expanded)}
                // className={classes.projectDetailsMainBox}
                style={{
                  padding: "0px ",
                  borderRadius: "0px",
                }}
              >
                <AccordionSummary
                  className={classes.ExpandedAccordion}
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: "#E3E8F7",
                        backgroundColor: "#0B1941",
                        borderRadius: "50%",
                        width: "16.03px",
                        height: "16.03px",
                      }}
                    />
                  }
                  IconButtonProps={{
                    style: { position: "absolute", top: "28px" },
                  }}
                  // key={index}
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ fontSize: "13px", fontWeight: "600" }}
                  >
                    <Grid item>
                      <Typography
                        style={{ fontSize: "13px", fontWeight: "600" }}
                      >
                        Project ID :{" "}
                        {myExpenseList?.ProjectDetails != null
                          ? myExpenseList?.ProjectDetails[0].ProjectId
                          : ""}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        style={{ fontSize: "13px", fontWeight: "600" }}
                        // style={{ marginRight: "30px" }}
                        align="right"
                      >
                        {myExpenseList?.ExpenseDetail?.length} Line Items
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>

                <Grid>
                  {location?.state?.compName == "teamApprovalListing" ||
                  location?.state?.compName == "financeApprovalListing" ? (
                    <Grid>
                      <Grid
                        style={{
                          padding: "1.5rem 1rem",
                          display: "flex",
                          backgroundColor: "#F6F6F6",
                        }}
                      >
                        <Grid>
                          <Typography
                            variant="p"
                            // className={classes.removeFilter}
                          >
                            <Button
                              variant="outlined"
                              size="small"
                              disabled={
                                (location?.state?.compName ==
                                  "teamApprovalListing" &&
                                  myExpenseList?.ProjectDetails != null &&
                                  (myExpenseList?.ProjectDetails[0]
                                    ?.ExpStatus == "Rejected" ||
                                    myExpenseList?.ProjectDetails[0]
                                      ?.ExpStatus == "Approved" ||
                                    location?.state?.response?.expStatus ==
                                      "Rejected" ||
                                    location?.state?.response?.expStatus ==
                                      "Approved")) ||
                                myExpenseList?.ExpFinalStatus == "Rejected" ||
                                myExpenseList?.ExpFinalStatus == "Approved" ||
                                errorFlag
                              }
                              style={{
                                backgroundColor: "#FFFFFF",
                              }}
                              onClick={() =>
                                handleOpenRejectionDialog(myExpenseList)
                              }
                            >
                              Reject
                            </Button>{" "}
                          </Typography>
                        </Grid>

                        <Grid style={{ padding: "0px 8px" }}>
                          <Typography
                            variant="p"
                            // className={classes.applyFilter}
                          >
                            <Button
                              variant="outlined"
                              disabled={
                                (location?.state?.compName ==
                                  "teamApprovalListing" &&
                                  myExpenseList?.ProjectDetails != null &&
                                  (myExpenseList?.ProjectDetails[0]
                                    ?.ExpStatus == "Rejected" ||
                                    myExpenseList?.ProjectDetails[0]
                                      ?.ExpStatus == "Approved" ||
                                    location?.state?.response?.expStatus ==
                                      "Rejected" ||
                                    location?.state?.response?.expStatus ==
                                      "Approved")) ||
                                myExpenseList?.ExpFinalStatus == "Rejected" ||
                                myExpenseList?.ExpFinalStatus == "Approved" ||
                                errorFlag
                              }
                              size="small"
                              style={{
                                color: "#FFFFFF",
                                backgroundColor:
                                  buttonState || errorFlag
                                    ? "#BDBDBD"
                                    : "#0B1941",
                              }}
                              onClick={() =>
                                handleOpenBulkApproveDialog(myExpenseList)
                              }
                            >
                              Approve
                            </Button>{" "}
                          </Typography>
                          <ApproveRejectDialog
                            openBulkApproveDialog={openBulkApproveDialog}
                            singleExpense={singleExpense}
                            veiwDialog={veiwDialog}
                            approveOrReject={approveOrReject}
                            handleComments={handleComments}
                            handleSingleApproveRejectExpense={
                              handleSingleApproveRejectExpense
                            }
                            approvedAmountVal={approvedAmountVal}
                            handleCloseBulkApproveDialog={
                              handleCloseBulkApproveDialog
                            }
                            viewFinApproval={viewFinApproval}
                            mobileEmpId={ssoEmpIdDetails?.empName}
                          />
                        </Grid>
                        <Grid>
                          <CSVLink
                            {...csvLink}
                            style={{ color: "#666666", fontSize: "28px" }}
                          >
                            <Box>
                              <GetAppOutlinedIcon
                                style={{ fontSize: "28px" }}
                              />
                            </Box>
                          </CSVLink>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}

                  {/* travel Details */}
                  <Grid
                    // style={{ border: "1px solid #979797", background: "#fff" }}
                    style={{ borderBottom: "1px solid #e0e0e0" }}
                  >
                    <Grid
                      style={{
                        background: "#F4F4F4",
                        // borderRadius: "8px 8px 0px 0px",
                        maxWidth: "100%", //1126
                        maxHeight: "58px",
                        padding: "0px 16px",
                        borderBottom: "1px solid #e0e0e0",
                        borderTop: "1px solid #e0e0e0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        style={{
                          // background: "#F4F4F4",
                          // // borderRadius: "8px 8px 0px 0px",
                          // maxWidth: "100%", //1126
                          // maxHeight: "58px",
                          fontSize: "12px",
                          fontWeight: "600",
                          // padding: "11px 16px",
                          // borderBottom: "1px solid #e0e0e0",
                          // borderTop: "1px solid #e0e0e0",
                        }}
                      >
                        Travel Details
                      </Typography>
                      <Grid
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          // paddingRight: "16px",
                          alignItems: "center",
                          padding: "4px 0px",
                        }}
                      >
                        <Grid
                          item
                          // xs={4}
                          style={{
                            backgroundColor: "#DDDDDD",
                            fontSize: "10px",
                            // textAlign: "center",
                            margin: "2px", //sid
                            textOverflow: "ellipsis",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "6px 20px",
                            // padding: `4px ${
                            // value?.Billable == 1 ? "14px" : "2px"
                            // }`,
                          }}
                        >
                          {myExpenseList?.ProjectDetails != null
                            ? myExpenseList?.ProjectDetails[0]?.Billable == 1
                              ? "Billable"
                              : "Non-Billable"
                            : ""}
                        </Grid>
                        <Grid
                          item
                          /*xs={1}*/ style={{
                            display: "flex",
                            padding: "0px 10px",
                          }}
                        >
                          {myExpenseList?.ProjectDetails[0]?.ExpStatus ==
                          "Approved" ? (
                            <FileCopyIcon
                              style={{ color: "#349259", padding: "0px" }} //sid
                            />
                          ) : myExpenseList?.ProjectDetails[0]?.ExpStatus ==
                            "In Progress" ? (
                            <FileCopyIcon
                              style={{ color: "#E0B15C", padding: "0px" }}
                            />
                          ) : myExpenseList?.ProjectDetails[0]?.ExpStatus ==
                            "Rejected" ? (
                            <FileCopyIcon
                              style={{ color: "#D40606", padding: "0px" }}
                            />
                          ) : myExpenseList?.ProjectDetails[0]?.ExpStatus ==
                            "Paid" ? (
                            <FileCopyIcon
                              style={{ color: "#406086", padding: "0px" }}
                            />
                          ) : null}
                        </Grid>
                        <Grid item /*xs={1}*/ style={{ padding: "0px" }}>
                          {myExpenseList?.ProjectDetails[0]?.ExpStatus !==
                            "Draft" && (
                            <Tooltip
                              title={handleToolTip(
                                myExpenseList?.ProjectDetails[0]
                              )}
                              arrow
                              enterTouchDelay={0}
                            >
                              <ErrorOutlineIcon
                                style={{ color: "#9E9E9E", paddingTop: "5px" }}
                              />
                              {/* Ayushi */}
                            </Tooltip>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      // style={{ paddingTop: "1rem" }}
                    >
                      <Grid
                        item
                        xs={6}
                        style={{ display: "flex", padding: "8px 4px 8px 16px" }}
                      >
                        <Typography
                          className={classes.headerName}
                          style={{ width: "85px" }}
                        >
                          Destination:
                        </Typography>
                        {/* {console.log("advSummaryJson", advSummaryJson)} */}
                        <Typography
                          style={{ fontSize: "12px", fontWeight: "600" }}
                        >
                          {advSummaryJson?.Destination || ""}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{ display: "flex", padding: "8px 16px 8px 4px" }}
                      >
                        <Typography
                          className={classes.headerName}
                          style={{ width: "85px" }}
                        >
                          Start:
                        </Typography>
                        <Typography
                          style={{ fontSize: "12px", fontWeight: "600" }}
                        >
                          {(myExpenseList?.AdvSummaryDetails !== null &&
                            moment(advSummaryJson?.TravelStartDate).format(
                              "DD/MM/YYYY"
                            )) ||
                            ""}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{ display: "flex", padding: "8px 4px 8px 16px" }}
                      >
                        <Typography
                          className={classes.headerName}
                          style={{ width: "85px" }}
                        >
                          End:
                        </Typography>
                        <Typography
                          style={{ fontSize: "12px", fontWeight: "600" }}
                        >
                          {(myExpenseList?.AdvSummaryDetails !== null &&
                            moment(advSummaryJson?.TravelEndDate).format(
                              "DD/MM/YYYY"
                            )) ||
                            ""}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{ display: "flex", padding: "8px 16px 8px 4px" }}
                      >
                        <Typography
                          className={classes.headerName}
                          style={{ width: "85px" }}
                        >
                          Travel Type:
                        </Typography>
                        <Typography
                          style={{ fontSize: "12px", fontWeight: "600" }}
                        >
                          {myExpenseList?.ExpenseDetail[0]?.TravelType || ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Advances Details */}
                  {myExpenseList?.ExpenseDetail[0]?.TravelType ==
                  "International" ? (
                    <>
                      {/* {console.log("advancesList", advancesList)} */}
                      {location?.state?.compName == "financeApprovalListing" ? (
                        <Grid
                          style={{
                            // border: "1px solid #979797",
                            background: "#fff",
                            // marginTop: "10px",
                          }}
                        >
                          <Typography
                            style={{
                              background: "#F4F4F4",
                              // borderRadius: "8px 8px 0px 0px",
                              maxWidth: "100%", //1126
                              maxHeight: "58px",
                              fontSize: "12px",
                              fontWeight: "600",
                              padding: "11px 16px",
                              borderBottom: "1px solid #e0e0e0",
                            }}
                          >
                            Advances
                          </Typography>
                          {advancesList?.map((item, index) => {
                            //console.log("AdvancesList", item);
                            return (
                              <Grid
                                container
                                // spacing={5}
                                xs={12}
                                justifyContent="space-between"
                                // alignItems="center"
                                style={{
                                  padding: "16px",
                                  paddingBottom: "0px",
                                }}
                              >
                                <Grid
                                  item
                                  xs={6}
                                  md={3}
                                  style={{
                                    paddingRight: "8px",
                                  }}
                                >
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                      disableToolbar
                                      inputVariant="outlined"
                                      fullWidth
                                      // className={classes.advancesField}
                                      variant="inline"
                                      format="dd/MM/yyyy"
                                      autoOk={true}
                                      disableFuture={true}
                                      size="small"
                                      id={`date-picker-inline-${index}`}
                                      label="Deposit Date"
                                      value={item.DepositDate} // Use the receiptdate from the 'cat' object
                                      onChange={(e) =>
                                        handleAdvancesList(e, index, "date")
                                      } // Pass the index here
                                      // onChange={(date) => handleReceiptDate(selectedCategory[index], date, index)}
                                      KeyboardButtonProps={{
                                        "aria-label": "change date",
                                        style: { paddingRight: "3px" },
                                      }}
                                      InputLabelProps={{
                                        style: { fontSize: "12px" }, // Adjust the label font size as needed
                                      }}
                                      InputProps={{
                                        style: { fontSize: "12px" }, // Adjust the input font size as needed
                                      }}
                                    />
                                  </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  md={3}
                                  style={{ paddingLeft: "8px" }}
                                >
                                  <FormControl
                                    variant="outlined"
                                    fullWidth
                                    // className={classes.advancesField3}
                                    disabled
                                    size="small"
                                  >
                                    <InputLabel
                                      id={`select-currency-${index}`}
                                      style={{ fontSize: "12px" }}
                                    >
                                      Currency*
                                    </InputLabel>
                                    <Select
                                      labelId={`select-currency-${index}`}
                                      id={`select-currency-${index}`}
                                      // className={
                                      //   matches
                                      //     ? classes.inputLabels1
                                      //     : classes.inputLabels
                                      // // }
                                      // style={{
                                      //   marginRight: matches ? "px" : "0px",
                                      // }}
                                      style={{ fontSize: "12px" }}
                                      value={currency}
                                      onChange={(e) => {
                                        //  handleCurrency(e.target.value, index);
                                        // console.log("currency", e.target.value);
                                        setCurrency(
                                          e.target.value
                                            ? e.target.value
                                            : currency
                                        );
                                        // handleAdvancesList(e, index, "currency");
                                      }}
                                      label="Currency*"
                                    >
                                      {CurrencyList?.map((crItem) => {
                                        return (
                                          <MenuItem
                                            key={crItem.currencyId}
                                            value={crItem}
                                          >
                                            {crItem.currencyCode}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>{" "}
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  md={3}
                                  style={{
                                    paddingRight: "8px",
                                    paddingTop: "16px",
                                  }}
                                >
                                  <NumericFormat
                                    decimalScale={2}
                                    inputProps={{ maxLength: 12 }}
                                    id={`advances-amount-${index}`}
                                    label="Advances Amount"
                                    variant="outlined"
                                    onKeyPress={preventMinus}
                                    fullWidth
                                    size="small"
                                    // className={classes.advancesField2}
                                    value={item.TotalAdvance}
                                    onChange={(e) => {
                                      handleAdvancesList(e, index, "amt");
                                    }}
                                    thousandSeparator={true}
                                    customInput={TextField}
                                    FormHelperTextProps={{
                                      classes: {
                                        root: classes.errorRoot,
                                      },
                                    }}
                                    InputLabelProps={{
                                      style: { fontSize: "12px" }, // Adjust the label font size as needed
                                    }}
                                    InputProps={{
                                      style: { fontSize: "12px" }, // Adjust the label font size as needed
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  md={2}
                                  style={{
                                    paddingLeft: "8px",
                                    paddingTop: "16px",
                                  }}
                                >
                                  <FormControl
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    // className={classes.advancesField4}
                                  >
                                    <InputLabel
                                      id={`select-label2-${index}`}
                                      style={{ fontSize: "12px" }}
                                    >
                                      Advances Type
                                    </InputLabel>
                                    {/* {console.log("KUSH1111", currency)} */}
                                    <Select
                                      labelId={`select-label2-${index}`}
                                      id={`select-label2-${index}`}
                                      // className={
                                      //   matches
                                      //     ? classes.inputLabels1
                                      //     : classes.inputLabels
                                      // }
                                      style={{ fontSize: "12px" }}
                                      value={item.AdvanceType}
                                      onChange={(e) => {
                                        handleAdvancesList(e, index, "type");
                                      }}
                                      label="Advances Type"
                                    >
                                      {AdvancesType?.map((adv) => {
                                        return (
                                          <MenuItem
                                            key={adv.value}
                                            value={adv.value}
                                          >
                                            {adv.label}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>{" "}
                                </Grid>

                                {location?.state?.compName ==
                                "financeApprovalListing" ? (
                                  index != 0 ? (
                                    <Box
                                      display="flex"
                                      justifyContent="flex-start"
                                    >
                                      <Button
                                        style={{ minWidth: "24px" }}
                                        onClick={() =>
                                          handleRemoveAdvances(index)
                                        }
                                      >
                                        <span class="material-symbols-outlined">
                                          delete
                                        </span>
                                      </Button>
                                    </Box>
                                  ) : (
                                    <Box
                                      display="flex"
                                      justifyContent="flex-start"
                                    >
                                      <Button
                                        style={{ minWidth: "42px" }}
                                        //onClick={() => handleRemoveAdvances(index)}
                                      >
                                        <span class="material-symbols-outlined">
                                          {" "}
                                        </span>
                                      </Button>
                                    </Box>
                                  )
                                ) : (
                                  <></>
                                )}
                              </Grid>
                            );
                          })}
                          <Box style={{ height: "33px", paddingLeft: "24px" }}>
                            <Link
                              style={{
                                color: "#008CE6",
                                cursor: "pointer",
                                fontFamily: "Montserrat",
                                fontSize: "12px",
                                fontWeight: "500",
                                lineHeight: "32px",
                                letterSpacing: "0px",
                                textAlign: "left",
                                // padding:"25px"
                              }}
                              target="_blank"
                              underline="always"
                              onClick={() => {
                                addAdvancesLine();
                              }}
                            >
                              + Add Advances
                            </Link>
                          </Box>
                        </Grid>
                      ) : (
                        advancesList.length > 0 &&
                        advancesList[0]?.TotalAdvance != 0 && (
                          <Grid
                            style={{
                              // border: "1px solid #979797",
                              background: "#fff",
                              // marginTop: "10px",
                            }}
                          >
                            <Typography
                              style={{
                                background: "#F4F4F4",
                                // borderRadius: "8px 8px 0px 0px",
                                maxWidth: "100%", //1126
                                maxHeight: "58px",
                                fontSize: "12px",
                                fontWeight: "600",
                                padding: "11px 16px",
                                borderBottom: "1px solid #e0e0e0",
                              }}
                            >
                              Advances
                            </Typography>
                            <TableContainer>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    {/* <Box style={{ display: "flex" }}> */}
                                    <TableCell
                                      style={{
                                        fontWeight: "600",
                                        // flex: 1,
                                        padding: "8px 4px 8px 16px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Deposit Date
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontWeight: "600",
                                        // flex: 1,
                                        padding: "8px 4px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Currency
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontWeight: "600",
                                        // flex: 1,
                                        padding: "8px 4px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Amount
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontWeight: "600",
                                        // flex: 1,
                                        padding: "8px 16px 8px 4px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Type
                                    </TableCell>
                                    {/* </Box> */}
                                  </TableRow>
                                </TableHead>
                                {/* {console.log("advancesList", advancesList)} */}
                                <TableBody>
                                  {advancesList?.map((item, index) => (
                                    <>
                                      {item.TotalAdvance != 0 && (
                                        <TableRow>
                                          {/* <Box
                                        style={{ display: "flex" }}
                                        key={index}
                                      > */}
                                          <TableCell
                                            style={{
                                              // flex: 1,
                                              padding: "8px 4px 8px 16px",
                                              fontSize: "12px",
                                            }}
                                          >
                                            {(item.DepositDate !== null &&
                                              moment(item.DepositDate).format(
                                                "DD/MM/YYYY"
                                              )) ||
                                              ""}
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              // flex: 1,
                                              padding: "8px 4px",
                                              fontSize: "12px",
                                            }}
                                          >
                                            {currency?.currencyCode}
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              // flex: 1,
                                              padding: "8px 4px",
                                              fontSize: "12px",
                                            }}
                                          >
                                            {item.TotalAdvance}
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              // flex: 1,
                                              padding: "8px 16px 8px 4px",
                                              fontSize: "12px",
                                            }}
                                          >
                                            {item.AdvanceType}
                                          </TableCell>
                                          {/* </Box>{" "} */}
                                        </TableRow>
                                      )}
                                    </>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  <Grid item xs={12} md={12}>
                    {" "}
                    <TableContainer
                      style={{
                        maxWidth: "100% !important",
                        overflow: "hidden",
                      }}
                    >
                      <Table aria-label="purchases">
                        <EnhancedTable
                          myExpenseList={myExpenseList}
                          location={location}
                        />
                        <TableBody>
                          {/* {console.log("edd", myExpenseList)} */}
                          {myExpenseList?.ExpenseDetail?.map((cat, index) => {
                            return (
                              <>
                                <TableRow style={{ verticalAlign: "top" }}>
                                  <TableCell
                                    xs
                                    className={classes.tableCell}
                                    style={{
                                      maxWidth:
                                        props.myExpenseList?.ExpFinalStatus ==
                                          "Approved" ||
                                        props.myExpenseList?.ExpFinalStatus ==
                                          "Rejected" ||
                                        location?.state?.compName ===
                                          "financeApprovalListing"
                                          ? "10rem"
                                          : "14.5rem",
                                      minWidth: "3.8rem",
                                      width: "3.8rem",
                                      paddingLeft: "16px",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      fontSize: "12px",
                                      paddingBottom: "0px",
                                    }}
                                  >
                                    {cat.CategoryName}{" "}
                                    <Typography
                                      style={{
                                        fontSize: "11px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {moment(cat.ReceiptDate).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </Typography>
                                  </TableCell>
                                  {/* <TableCell
                                    className={classes.tableCell}
                                    style={{
                                      // fontSize: "13px",
                                      paddingLeft: "0px",
                                      paddingRight: "0px",
                                    }}
                                  >
                                    {moment(cat.ReceiptDate).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </TableCell> */}
                                  <TableCell
                                    xs
                                    className={classes.tableCell}
                                    style={{ paddingBottom: "0px" }}
                                  >
                                    {/* <Typography>
                                    </Typography> */}
                                    {currency?.currency}
                                    {cat.ClaimedAmount.toString().replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                    <Typography
                                      style={{
                                        fontSize: "11px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {cat.MethodName}
                                    </Typography>
                                  </TableCell>
                                  {myExpenseList?.ExpFinalStatus ==
                                    "Approved" ||
                                  myExpenseList?.ExpFinalStatus ==
                                    "Rejected" ? (
                                    //if expense has beed approved or rejected from finance
                                    <TableCell
                                      xs
                                      className={classes.tableCell}
                                      style={{
                                        paddingRight: "14px",
                                        paddingBottom: "0px",
                                      }}
                                    >
                                      {/* <Typography>
                                    </Typography> */}
                                      {currency?.currency}
                                      {cat.ApprovedAmount.toString().replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                    </TableCell>
                                  ) : //if expense has beed not approved/rejected from finance
                                  location?.state?.compName ===
                                    "financeApprovalListing" ? (
                                    <>
                                      {/* <Grid
                                        item
                                        xs={5}
                                        style={{ color: "#979797" }}
                                      >
                                        {"Approved Amount:"}
                                      </Grid> */}
                                      <TableCell
                                        className={classes.tableCell}
                                        style={{
                                          paddingRight: "14px",
                                          display: "flex",
                                          paddingBottom: "0px",
                                        }}
                                      >
                                        <Grid
                                          item
                                          xs={12}
                                          // style={{
                                          //   width: "100px",
                                          // }}
                                        >
                                          <NumericFormat
                                            // className={classes.numericFormat}
                                            id="outlined-basic"
                                            // label="Approved amount"
                                            onKeyPress={preventMinus}
                                            variant="outlined"
                                            // value={approvedAmount}
                                            inputProps={{ maxLength: 12 }}
                                            decimalScale={2}
                                            defaultValue={cat.ClaimedAmount.toString().replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )}
                                            size="small"
                                            style={{ fontSize: "12px" }}
                                            InputLabelProps={{
                                              style: { fontSize: "12px" }, // Adjust the label font size as needed
                                            }}
                                            InputProps={{
                                              style: { fontSize: "12px" }, // Adjust the label font size as needed
                                            }}
                                            customInput={TextField}
                                            error={
                                              travelFieldValue[index]
                                                ?.ApprovedAmount >
                                              cat?.ClaimedAmount
                                                ? true
                                                : false
                                            }
                                            onChange={(e) => {
                                              if (
                                                e.target.value !=
                                                  Number(
                                                    location?.state?.response
                                                      ?.claimAmount
                                                  ) &&
                                                location?.state?.compName ==
                                                  "financeApprovalListing"
                                              ) {
                                                //console.log("approvedAmount", approvedAmount,props.approvedAmount,location?.state?.response?.claimAmount,location?.state?.response,e.target.value);
                                                setViewFinApproval(true);
                                              } else if (
                                                e.target.value ==
                                                  Number(
                                                    location?.state?.response
                                                      ?.claimAmount
                                                  ) &&
                                                location?.state?.compName ==
                                                  "financeApprovalListing"
                                              ) {
                                                //console.log("approvedAmount", approvedAmount);
                                                setViewFinApproval(false);
                                              }

                                              if (
                                                e.target.value >
                                                cat?.ClaimedAmount
                                              )
                                                setErrorFlag(true);
                                              else setErrorFlag(false);
                                              setTravelTotalApproved(
                                                (Number(
                                                  Number(travelTotalApproved) -
                                                    Number(
                                                      travelFieldValue[index]
                                                        .ApprovedAmount
                                                    ) +
                                                    Number(
                                                      e.target.value.replaceAll(
                                                        ",",
                                                        ""
                                                      )
                                                    )
                                                ))?.toFixed(2)
                                              );

                                              setActValue((prevVal) =>
                                                prevVal.map((item) =>
                                                  item.PaymentMethodId ==
                                                  cat.PaymentMethodId
                                                    ? {
                                                        ...item,
                                                        ActualsTypeAppAmt:
                                                          Number(
                                                            Number(
                                                              item.ActualsTypeAppAmt
                                                            ) -
                                                              Number(
                                                                travelFieldValue[
                                                                  index
                                                                ].ApprovedAmount
                                                              ) +
                                                              Number(
                                                                e.target.value.replaceAll(
                                                                  ",",
                                                                  ""
                                                                )
                                                              )
                                                          ),
                                                      }
                                                    : item
                                                )
                                              );
                                              // const arr = [...travelFieldValue];
                                              // arr[cat.ExpId] = Number(e.target.value);
                                              // setTravelFieldValue(arr);
                                              setTravelFieldValue((prevVal) =>
                                                prevVal.map((item) =>
                                                  item.ExpId === cat.ExpId
                                                    ? {
                                                        ...item,
                                                        ApprovedAmount: Number(
                                                          e.target.value.replaceAll(
                                                            ",",
                                                            ""
                                                          )
                                                        ),
                                                        ProjAmount:
                                                          Number(
                                                            e.target.value.replaceAll(
                                                              ",",
                                                              ""
                                                            )
                                                          ) !=
                                                          cat?.ClaimedAmount
                                                            ? cat?.ClaimedAmount
                                                            : 0,
                                                      }
                                                    : item
                                                )
                                              );

                                              //console.log('kjsadlkf', e.target.value);
                                              // setApprovedAmountVal(
                                              //   e.target.value
                                              // );
                                              // props.setAmountNewValue(
                                              //   e.target.value
                                              // );
                                            }}
                                          />

                                          {/* {travelFieldValue[index]
                                            ?.ApprovedAmount >
                                          cat?.ClaimedAmount ? (
                                            <span
                                              style={{
                                                color: "#E02020",
                                                display: "flex",
                                                flexDirection: "column",
                                                fontSize: "13px",
                                              }}
                                            >
                                              Amount must be equal to or less
                                              than Claim Amount.
                                            </span>
                                          ) : (
                                            ""
                                          )} */}
                                          <Tooltip
                                            title={
                                              // <Box
                                              //   style={{
                                              //     color: "#fff",
                                              //     fontSize: "13px",
                                              //   }}
                                              // >
                                              "Amount must be equal to or less than Claim Amount."
                                              // </Box>
                                            }
                                            open={
                                              travelFieldValue[index]
                                                ?.ApprovedAmount >
                                              cat?.ClaimedAmount
                                                ? true
                                                : false
                                            }
                                            arrow
                                            // slotProps={{
                                            //   popper: {
                                            //     sx: {
                                            //       [`&.${tooltipClasses.popper}[x-placement="bottom"] .${tooltipClasses.tooltip}`]:
                                            //         {
                                            //           margin: "0px",
                                            //         },
                                            //       [`&.${tooltipClasses.popper}[x-placement="top"] .${tooltipClasses.tooltip}`]:
                                            //         {
                                            //           margin: "0px",
                                            //         },
                                            //     },
                                            //   },
                                            // }}
                                            classes={{
                                              popper: classes.travelTooltip,
                                            }}

                                            // disableFocusListener
                                            // disableHoverListener
                                            // disableTouchListener

                                            // enterTouchDelay={0}
                                          >
                                            <span style={{ opacity: "0" }}>
                                              a
                                            </span>
                                          </Tooltip>
                                        </Grid>
                                      </TableCell>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </TableRow>
                                <TableRow
                                  style={{
                                    borderBottom: "1px solid #e0e0e0",
                                  }}
                                >
                                  <TableCell
                                    colSpan={12}
                                    style={{
                                      padding: "0px 16px",
                                      borderBottom: "none",
                                    }}
                                  >
                                    <Box
                                      // className={classes.tableCell}
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        // justifyContent: "space-between",
                                      }}
                                    >
                                      {cat?.ExpenseAttachments?.map(
                                        (attach, index) => {
                                          return (
                                            <>
                                              <Chip
                                                onClick={() =>
                                                  handleDownload(
                                                    myExpenseList,
                                                    attach.FileName
                                                  )
                                                }
                                                style={{
                                                  backgroundColor: "#f0f6fb",
                                                  width: "110px",
                                                  marginBottom: "8px",
                                                  marginRight:
                                                    (index + 1) % 3 == 0
                                                      ? "0px"
                                                      : "6px",
                                                }}
                                                // className={classes.chipRoot}
                                                avatar={
                                                  <Avatar
                                                    variant="square"
                                                    style={{
                                                      backgroundColor: "unset",
                                                    }}
                                                  >
                                                    {attach?.FileName?.includes(
                                                      ".pdf"
                                                    ) ? (
                                                      <InsertDriveFileIcon
                                                        style={{
                                                          color: "#0B1941",
                                                        }}
                                                      />
                                                    ) : (
                                                      <PhotoSizeSelectActualIcon
                                                        style={{
                                                          color: "#0B1941",
                                                        }}
                                                      />
                                                    )}
                                                  </Avatar>
                                                }
                                                label={
                                                  <Typography
                                                    style={{
                                                      color: "#4C4C4C",
                                                      fontWeight: "600",
                                                      fontSize: "12px",
                                                      textOverflow: "ellipsis",
                                                      overflowX: "hidden",
                                                    }}
                                                  >
                                                    {attach.FileName}
                                                  </Typography>
                                                }
                                              />
                                            </>
                                          );
                                        }
                                      )}
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Grid>
                      <Grid
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                          padding: "10px 16px",
                          borderBottom: "1px solid #e0e0e0",
                        }}
                      >
                        <Typography
                          style={{
                            fontWeight: "600",
                            fontSize: "12px",
                            // width: "130px",
                            maxWidth:
                              props.myExpenseList?.ExpFinalStatus ==
                                "Approved" ||
                              props.myExpenseList?.ExpFinalStatus ==
                                "Rejected" ||
                              location?.state?.compName ===
                                "financeApprovalListing"
                                ? "10rem"
                                : "14.5rem",
                            minWidth: "3.8rem",
                            width: "3.8rem",
                          }}
                        >
                          Total
                        </Typography>
                        {myExpenseList?.ExpFinalStatus == "Approved" ||
                        myExpenseList?.ExpFinalStatus == "Rejected" ? (
                          // final status is approved or rejected

                          <>
                            <Box
                              // style={{
                              // width: `${cell1tarwidth}px`,
                              // minWidth:
                              //   window.screen.availWidth == 1280
                              //     ? "133px"
                              //     : window.screen.availWidth == 1536
                              //     ? "239px"
                              //     : "235px",
                              // }}
                              style={{
                                width: `${cell1tarwidth}px`,
                              }}
                            >
                              <Typography
                                className={
                                  (location?.state?.compName ==
                                    "teamApprovalListing" ||
                                    location?.state?.compName ==
                                      "financeApprovalListing") &&
                                  myExpenseList?.ProjectDetails[0]?.ProjectId ==
                                    location.state.response.projectId
                                    ? classes.textConditionalSplitAmount
                                    : classes.expenseId
                                }
                                style={{ fontSize: "12px" }}
                              >
                                {currency?.currency}
                                {myExpenseList?.totalamount?.ClaimedAmount?.toFixed(
                                  2
                                )
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </Typography>
                            </Box>
                            <>
                              {/* {travelTotalApproved !=
                              myExpenseList?.totalamount?.ClaimedAmount ? ( */}
                              <Box
                                style={{
                                  width: `${cell2tarwidth - 20}px`,
                                  // top: "-4px",
                                  position: " relative",
                                  // width: `${cell2tarwidth}px`,
                                  // width:
                                  //   window.screen.availWidth == 1280
                                  //     ? "255px"
                                  //     : window.screen.availWidth == 1536
                                  //     ? "268px"
                                  //     : "385px",
                                }}
                              >
                                {/* <Typography
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontSize: "10px",
                                      fontWeight: 500,
                                      lineHeight: "10px",
                                      letterSpacing: "0px",
                                      textAlign: "left",
                                    }}
                                  >
                                    <del>
                                      {
                                        myExpenseList?.ProjectDetails[0]
                                          ?.CurrencySymbol
                                      }
                                      {myExpenseList?.totalamount?.ClaimedAmount.toString().replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                    </del>
                                  </Typography> */}
                                <Typography
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "12px",
                                    fontWeight: 600,
                                    lineHeight: "24px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                  }}
                                >
                                  {currency?.currency}
                                  {travelTotalApproved
                                    ?.toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </Typography>
                              </Box>
                              {/* ) : (
                                <Box
                                  style={
                                    {
                                      // width: `${cell2tarwidth}px`,
                                      // width:
                                      //   window.screen.availWidth == 1280
                                      //     ? "255px"
                                      //     : window.screen.availWidth == 1536
                                      //     ? "268px"
                                      //     : "385px",
                                    }
                                  }
                                >
                                  <Typography
                                  // style={{
                                  //   fontFamily: "Montserrat",
                                  //   fontSize: "14px",
                                  //   fontWeight: 600,
                                  //   lineHeight: "24px",
                                  //   letterSpacing: "0px",
                                  //   textAlign: "left",
                                  // }}
                                  >
                                    {
                                      myExpenseList?.ProjectDetails[0]
                                        ?.CurrencySymbol
                                    }
                                    {myExpenseList?.totalamount?.ClaimedAmount.toString().replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </Typography>
                                </Box>
                              )} */}
                            </>
                          </>
                        ) : (
                          // final status is not approved or rejected
                          <>
                            <Box
                              // style={{
                              // width: `${cell1tarwidth}px`,
                              // minWidth:
                              //   window.screen.availWidth == 1280
                              //     ? location?.state?.compName ==
                              //       "financeApprovalListing"
                              //       ? "135px"
                              //       : "280px"
                              //     : window.screen.availWidth == 1536
                              //     ? location?.state?.compName ==
                              //       "financeApprovalListing"
                              //       ? "245px"
                              //       : "406px"
                              //     : location?.state?.compName ==
                              //       "financeApprovalListing"
                              //     ? "237px"
                              //     : "458px",
                              // }}
                              style={{
                                width: `${
                                  cell1tarwidth -
                                  (location?.state?.compName ==
                                  "financeApprovalListing"
                                    ? 0
                                    : 18)
                                }px`,
                                //   maxWidth: "54px",
                                // minWidth: "40px",
                                // width: "40px",
                              }}
                            >
                              <Typography
                                className={
                                  (location?.state?.compName ==
                                    "teamApprovalListing" ||
                                    location?.state?.compName ==
                                      "financeApprovalListing") &&
                                  myExpenseList?.ProjectDetails[0]?.ProjectId ==
                                    location.state.response.projectId
                                    ? classes.textConditionalSplitAmount
                                    : classes.expenseId
                                }
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                {currency?.currency}
                                {myExpenseList?.totalamount?.ClaimedAmount?.toFixed(
                                  2
                                )
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </Typography>
                            </Box>
                            {location?.state?.compName ==
                              "financeApprovalListing" && (
                              <Typography
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "600",
                                  width: `${cell2tarwidth - 20}px`,
                                  // maxWidth:
                                  //                   props.location?.state?.compName === "financeApprovalListing"
                                  //                     ? "76px"
                                  //                     : "64px",
                                  //                   minWidth: "40px",
                                  // width: "40px",
                                  // width: `${cell2tarwidth}px`,
                                  // width:
                                  //   window.screen.availWidth == 1280
                                  //     ? "247px"
                                  //     : window.screen.availWidth == 1536
                                  //     ? "262px"
                                  //     : "382px",
                                }}
                                className={classes.textConditionalSplitAmount}
                              >
                                {currency?.currency}
                                {travelTotalApproved
                                  ?.toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </Typography>
                            )}{" "}
                          </>
                        )}
                      </Grid>
                    </Grid>
                    {/* Summary Section */}
                    <>
                      <Box
                        style={{
                          //marginTop: "16px",
                          // border: "1px solid #979797",
                          background: "#fff",
                          height: "100%",
                          padding: "0px 0px 10px 0px",
                          // marginTop: "10px",
                        }}
                      >
                        <Grid
                          style={{
                            background: "#F4F4F4",
                            maxWidth: "100%", //1126
                            maxHeight: "58px",
                            padding: "11px 16px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #e0e0e0",
                          }}
                        >
                          <Typography
                            style={{
                              // borderRadius: "8px 8px 0px 0px",

                              fontSize: "12px",
                              fontWeight: "600",
                            }}
                          >
                            Summary
                          </Typography>
                          <Grid style={{ display: "flex" }}>
                            <Typography
                              style={{ fontWeight: "600", fontSize: "12px" }}
                            >
                              {Number(totalAdv) -
                                (myExpenseList?.ExpFinalStatus === "Rejected"
                                  ? Number(
                                      myExpenseList?.totalamount?.ClaimedAmount
                                    )
                                  : Number(travelTotalApproved)) -
                                Number(advSummaryJson?.RefundReceived) >
                              0
                                ? "*Net Payable :"
                                : "*Net Receivable :"}{" "}
                            </Typography>
                            <Typography
                              align="right"
                              style={{
                                fontWeight: "600",
                                paddingLeft: "10px",
                                fontSize: "12px",
                              }}
                            >
                              {currency?.currency}
                              {Math.abs(
                                Number(totalAdv) > 0
                                  ? Number(totalAdv) -
                                      (myExpenseList?.ExpFinalStatus ===
                                      "Rejected"
                                        ? Number(
                                            myExpenseList?.totalamount
                                              ?.ClaimedAmount
                                          )
                                        : Number(travelTotalApproved)) -
                                      Number(advSummaryJson?.RefundReceived)
                                  : (myExpenseList?.ExpFinalStatus ===
                                    "Rejected"
                                      ? Number(
                                          myExpenseList?.totalamount
                                            ?.ClaimedAmount
                                        )
                                      : Number(travelTotalApproved)) -
                                      Number(advSummaryJson?.RefundReceived)
                              )
                                .toFixed(2)
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                                "0.00"}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                        // container
                        // style={{ padding: "10px 0px 0px 16px" }}
                        >
                          <Grid
                            item
                            xs={12}
                            className={classes.summaryField}
                            style={{ fontSize: "14px" }}
                          >
                            <strong>Advances</strong>
                            <strong>
                              <Box>
                                {currency?.currency}
                                {totalAdv
                                  ?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                                  "0.00"}
                              </Box>
                            </strong>
                          </Grid>
                          <Grid className={classes.summaryField} item xs={12}>
                            <Box className={classes.headerName}>
                              Travel Card
                            </Box>
                            <Box>
                              {currency?.currency}
                              {advValue
                                ?.filter(
                                  (item) => item.AdvanceType == "Travel Card"
                                )[0]
                                ?.AdvancesTypeAmt.toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                                "0.00"}
                              {/* {advSummaryJson?.AdvTravelCard?.toString().replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )} */}
                            </Box>
                          </Grid>
                          <Grid className={classes.summaryField} item xs={12}>
                            <Box className={classes.headerName}>Cash</Box>
                            <Box>
                              {currency?.currency}
                              {advValue
                                ?.filter(
                                  (item) => item.AdvanceType == "Cash"
                                )[0]
                                ?.AdvancesTypeAmt?.toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                                "0.00"}
                              {/* {advSummaryJson?.AdvCash?.toString().replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )} */}
                            </Box>
                          </Grid>
                          <Divider
                            style={{
                              width: "100%",
                              // height: "1px",
                              // paddingTop: "4px",
                            }}
                          />
                          <Grid
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              // paddingRight: "100px",
                              padding: "4px 16px",
                              paddingTop: "10px",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "11px",
                              }}
                            >
                              (Claimed Amount)
                            </Typography>
                            {(location?.state?.compName ==
                              "financeApprovalListing" ||
                              myExpenseList?.ExpFinalStatus == "Rejected" ||
                              myExpenseList?.ExpFinalStatus == "Approved") && (
                              <Typography
                                style={{
                                  paddingLeft: "10px",
                                  width: "140px",
                                  textAlign: "right",
                                  fontSize: "11px",
                                }}
                              >
                                (Approved Amount)
                              </Typography>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className={classes.summaryField}
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            <strong>Actuals</strong>
                            <Grid style={{ display: "flex" }}>
                              <Box>
                                <strong>
                                  {currency?.currency}
                                  {totalAct
                                    ?.toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </strong>
                              </Box>
                              {(location?.state?.compName ==
                                "financeApprovalListing" ||
                                myExpenseList?.ExpFinalStatus == "Rejected" ||
                                myExpenseList?.ExpFinalStatus ==
                                  "Approved") && (
                                <Box
                                  style={{
                                    paddingLeft: "10px",
                                    width: "140px",
                                    textAlign: "right",
                                  }}
                                >
                                  <strong>
                                    {currency?.currency}
                                    {travelTotalApproved
                                      ?.toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </strong>
                                </Box>
                              )}
                            </Grid>
                          </Grid>

                          <Grid
                            className={classes.summaryField}
                            // style={{
                            //   paddingLeft: "0px",
                            // }}
                            item
                            xs={12}
                          >
                            <Box className={classes.headerName}>
                              Travel Card
                            </Box>
                            <Grid style={{ display: "flex" }}>
                              <Box>
                                {currency?.currency}
                                {myExpenseList?.ActualDetails?.filter(
                                  (item) => item.PaymentMethodId == 3
                                )[0]
                                  ?.ActualsTypeAmt?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                                  "0.00"}
                                {/* {advSummaryJson?.ActTravelCard?.toString().replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )} */}
                              </Box>
                              {(location?.state?.compName ==
                                "financeApprovalListing" ||
                                myExpenseList?.ExpFinalStatus == "Rejected" ||
                                myExpenseList?.ExpFinalStatus ==
                                  "Approved") && (
                                <Box
                                  style={{
                                    paddingLeft: "10px",
                                    width: "140px",
                                    textAlign: "right",
                                  }}
                                >
                                  {currency?.currency}
                                  {actValue
                                    ?.filter(
                                      (item) => item.PaymentMethodId == 3
                                    )[0]
                                    ?.ActualsTypeAppAmt?.toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                                    "0.00"}
                                </Box>
                              )}
                            </Grid>
                          </Grid>

                          <Grid
                            className={classes.summaryField}
                            // style={{
                            //   paddingLeft: "0px",
                            // }}
                            item
                            xs={12}
                          >
                            <Box className={classes.headerName}>Cash</Box>
                            <Grid style={{ display: "flex" }}>
                              <Box>
                                {currency?.currency}
                                {myExpenseList?.ActualDetails?.filter(
                                  (item) => item.PaymentMethodId == 35
                                )[0]
                                  ?.ActualsTypeAmt?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                                  "0.00"}
                              </Box>
                              {(location?.state?.compName ==
                                "financeApprovalListing" ||
                                myExpenseList?.ExpFinalStatus == "Rejected" ||
                                myExpenseList?.ExpFinalStatus ==
                                  "Approved") && (
                                <Box
                                  style={{
                                    paddingLeft: "10px",
                                    width: "140px",
                                    textAlign: "right",
                                  }}
                                >
                                  {currency?.currency}
                                  {actValue
                                    ?.filter(
                                      (item) => item.PaymentMethodId == 35
                                    )[0]
                                    ?.ActualsTypeAppAmt?.toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                                    "0.00"}
                                </Box>
                              )}
                            </Grid>
                          </Grid>
                          {/* <Grid
                            style={{
                              display: "flex",
                              direction: "row",
                              justifyContent: "space-between",
                              paddingRight: "150px",
                            }}
                            item
                            xs={6}
                          ></Grid> */}
                          <Grid
                            className={classes.summaryField}
                            // style={{
                            //   paddingLeft: "0px",
                            // }}
                            item
                            xs={12}
                          >
                            <Box className={classes.headerName}>Self</Box>
                            <Grid style={{ display: "flex" }}>
                              <Box>
                                {currency?.currency}
                                {myExpenseList?.ActualDetails?.filter(
                                  (item) => item.PaymentMethodId == 34
                                )[0]
                                  ?.ActualsTypeAmt?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                                  "0.00"}
                              </Box>
                              {(location?.state?.compName ==
                                "financeApprovalListing" ||
                                myExpenseList?.ExpFinalStatus == "Rejected" ||
                                myExpenseList?.ExpFinalStatus ==
                                  "Approved") && (
                                <Box
                                  style={{
                                    paddingLeft: "10px",
                                    width: "140px",
                                    textAlign: "right",
                                  }}
                                >
                                  {currency?.currency}
                                  {actValue
                                    ?.filter(
                                      (item) => item.PaymentMethodId == 34
                                    )[0]
                                    ?.ActualsTypeAppAmt?.toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                                    "0.00"}
                                </Box>
                              )}
                            </Grid>
                          </Grid>
                          <Divider
                            style={{
                              width: "100%",
                              // height: "1px",
                              // paddingTop: "4px",
                            }}
                          />
                          <Grid item xs={12}>
                            <Box style={{ padding: "10px 16px" }}>
                              <Typography style={{ fontSize: "12px" }}>
                                <u>To be filled by finance</u>
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid container style={{ display: "block" }}>
                            {/* <Grid
                            item
                            xs={6}
                            style={{
                              padding: "8px 24px",
                              display: "flex",
                              justifyContent: "space-between",
                              paddingRight: "100px",
                            }}
                          >
                            <strong>Per Diem</strong>
                            <strong>
                              <Box>
                                {currency?.currency}0
                                {Number(advSummaryJson?.RefundReceived)
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </Box>
                            </strong>
                          </Grid> */}
                            <Grid
                              item
                              xs={12}
                              className={classes.summaryField}
                              style={{
                                fontSize: "14px",
                                // padding: "8px 16px",
                                // display: "flex",
                                // justifyContent: "space-between",
                              }}
                            >
                              <strong>Refund Received</strong>
                              <strong>
                                {/* {console.log("refund travel1", advSummaryJson)} */}
                                <Box>
                                  {currency?.currency}
                                  {Number(advSummaryJson?.RefundReceived)
                                    ?.toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                                    "0.00"}
                                </Box>
                              </strong>
                            </Grid>

                            <Grid
                              // style={{
                              //   display: "flex",
                              //   alignItems: "center",
                              //   padding: "8px 0px",
                              // }}
                              className={classes.summaryField}
                              // style={{
                              //   padding: "8px 16px",
                              // }}
                              item
                              xs={12}
                            >
                              <Box className={classes.headerName}>
                                Travel Card Balance
                              </Box>
                              {location?.state?.compName ==
                              "financeApprovalListing" ? (
                                <NumericFormat
                                  id="outlined-basic-travel-card"
                                  // label="Per Diem - Taxable"
                                  onKeyPress={preventMinus}
                                  variant="outlined"
                                  defaultValue={0}
                                  inputProps={{ maxLength: 12 }}
                                  decimalScale={2}
                                  className={classes.advancesField}
                                  value={Number(advSummaryJson?.TravelCardBal)}
                                  // defaultValue={cat.ClaimedAmount.toString().replace(
                                  //   /\B(?=(\d{3})+(?!\d))/g,
                                  //   ","
                                  // )}
                                  // value="0"
                                  thousandSeparator={true}
                                  size="small"
                                  InputLabelProps={{
                                    style: { fontSize: "12px" }, // Adjust the label font size as needed
                                  }}
                                  InputProps={{
                                    style: { fontSize: "12px" }, // Adjust the label font size as needed
                                  }}
                                  customInput={TextField}
                                  // error={
                                  //   travelFieldValue[index]?.ApprovedAmount >
                                  //   cat?.ClaimedAmount
                                  //     ? true
                                  //     : false
                                  // }
                                  onChange={(e) => {
                                    handleRefund(e, "Travel Card");
                                  }}
                                />
                              ) : (
                                <>
                                  {currency?.currency}
                                  {Number(advSummaryJson?.TravelCardBal)
                                    ?.toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                                    "0.00"}
                                </>
                              )}
                            </Grid>

                            <Grid
                              // style={{
                              //   display: "flex",
                              //   alignItems: "center",
                              //   padding: "8px 0px",
                              // }}
                              className={classes.summaryField}
                              // style={{
                              //   padding: "8px 16px",
                              // }}
                              item
                              xs={12}
                            >
                              <Box className={classes.headerName}>
                                Cash Balance
                              </Box>
                              {location?.state?.compName ==
                              "financeApprovalListing" ? (
                                <NumericFormat
                                  id="outlined-basic-cash-balance"
                                  // label="Per Diem - Taxable"
                                  onKeyPress={preventMinus}
                                  variant="outlined"
                                  defaultValue={0}
                                  inputProps={{ maxLength: 12 }}
                                  decimalScale={2}
                                  className={classes.advancesField}
                                  value={Number(advSummaryJson?.CashBal)}
                                  // value={advSummaryJson?.CashBal}
                                  // defaultValue={cat.ClaimedAmount.toString().replace(
                                  //   /\B(?=(\d{3})+(?!\d))/g,
                                  //   ","
                                  // )}
                                  // value="0"
                                  thousandSeparator={true}
                                  size="small"
                                  InputLabelProps={{
                                    style: { fontSize: "12px" }, // Adjust the label font size as needed
                                  }}
                                  InputProps={{
                                    style: { fontSize: "12px" }, // Adjust the label font size as needed
                                  }}
                                  customInput={TextField}
                                  // error={
                                  //   travelFieldValue[index]?.ApprovedAmount >
                                  //   cat?.ClaimedAmount
                                  //     ? true
                                  //     : false
                                  // }
                                  onChange={(e) => {
                                    handleRefund(e, "Cash");
                                  }}
                                />
                              ) : (
                                <>
                                  {currency?.currency}
                                  {Number(advSummaryJson?.CashBal)
                                    ?.toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                                    "0.00"}
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          style={{
                            display: "flex",
                          }}
                        >
                          <Grid item xs={6}>
                            <Box
                              style={{
                                color: "rgba(0, 0, 0, 0.7)",
                                fontSize: "0.6rem",
                                padding: "16px",
                              }}
                            >
                              *Net Payable: To be refunded to the company.{" "}
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box
                              style={{
                                color: "rgba(0, 0, 0, 0.7)",
                                fontSize: "0.6rem",
                                padding: "16px",
                              }}
                            >
                              *Net Receivable: To be claimed from the company.{" "}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  </Grid>
                </Grid>
              </Accordion>
              {/* ); */}
              {/* })} */}
            </Grid>
          </Container>
        </>
      )}
      <Snackbar
        open={systemErrorSnackbar}
        onClose={() => setSystemErrorSnackbar(false)}
      >
        <Alert
          onClose={() => setSystemErrorSnackbar(false)}
          severity="error"
          sx={{ width: "100%", backgroundColor: "red", color: "white" }}
        >
          There is some problem with the application. Please connect with System
          Admin.
        </Alert>
      </Snackbar>
    </div>
  );
}

export default MobileViewExpense;
