import React, { useState, useEffect, useRef } from "react";
import InfoIcon from "@material-ui/icons/Info";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import { uploadFile } from "react-s3";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ShowMoreText from "react-show-more-text";
import CloseIcon from "@material-ui/icons/Close";
import Encrypt from "../Utils/encryption";
import Decrypt from "../Utils/decryption";
import Divider from "@material-ui/core/Divider";
import {
  Typography,
  Box,
  makeStyles,
  withStyles,
  Grid,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  TextField,
  Select,
  Link,
  Checkbox,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Popover,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DateFnsUtils from "@date-io/date-fns";
import ProjectId from "./multiSelectProject";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import SplitBox from "./splitBox";
import DragDrope from "./dragDrope";
import { FieldValidator } from "./validation";
import {
  getPurposeData,
  getCategoryData,
  getCurrencyData,
  getEmployeeProjectsData,
  getAttendeesData,
  getValidateAttendeesData,
  getPaymentData,
  postCreateExpenseData,
  getMyViewExpenseData,
  postEditExpenseData,
  resetExpenseResponse,
  getMyDelegateesData,
  getEmpDetail,
  getDependentsData,
  getBillNumberExistenceData,
} from "../redux/Expense/action";
import { useDispatch, useSelector } from "react-redux";
import Header from "./header";
import AttendeesBox from "./attendees";
import SnackBar from "./snackbar";
import history from "../history";
import { useLocation, useHistory } from "react-router-dom";
import EditSnackbars from "./editSnackBar";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import DOMPurify from "dompurify";
import { useAccount, useMsal } from "@azure/msal-react";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F8F9FF",
    paddingLeft: "100px",
    paddingRight: "100px",
  },
  rootMobile: {
    backgroundColor: "#F8F9FF",
    paddingLeft: "26px",
    paddingRight: "0",
    overflow: "hidden",
  },
  formControl: {
    margin: "16px 0 8px 0",
    minWidth: "100%",
    // backgroundColor: "#FCFDFF",
    borderRadius: 4,
    notchedOutline: {
      borderColor: "#CB444A",
      borderRadius: 4,
    },
    "& .MuiFormLabel-root": {
      ["@media (max-width:600px)"]: { fontSize: "12px" },
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "500",
      color: "#2B2D30",
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "2px solid #162D6E",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#515961",
        ["@media (max-width:600px)"]: { fontSize: "12px" },
      },
      "& .MuiSelect-selectMenu": {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "500",
        color: "#2B2D30",
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#FFFFFF",
      },
      "& .MuiOutlinedInput-input": {
        ["@media (max-width:600px)"]: { fontSize: "13px" },
      },
    },
  },
  formControl12: {
    margin: "16px 0 8px 0",
    minWidth: "100%",
    borderRadius: 4,
    notchedOutline: {
      borderColor: "red",
      borderRadius: 4,
    },

    "& .MuiFormLabel-root": {
      // ["@media (max-width:600px)"]: { fontSize: "12px", fontWeight: "500" },
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "500",
      color: "#2B2D30",
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "2px solid #162D6E",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
      },
      "& .MuiSelect-selectMenu": {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "500",
        color: "#2B2D30",
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#FFFFFF",
      },
      "& .MuiOutlinedInput-input": {
        ["@media (max-width:600px)"]: { fontSize: "13px" },
      },
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dropdownStyle: {
    "& ul": {
      padding: 0,
      border: "0.5px solid #515961",
      boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
      borderRadius: 4,
    },
    "& li": {
      height: 47,
      fontSize: 14,
      color: "#515961",
      "&:hover": {
        backgroundColor: "rgba(217, 235, 247, 0.4);",
        color: "#439DD9",
      },
    },
  },
  selectRoot: {
    borderRadius: 4,
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "#439DD9",
      },
    },
    "&:focus": {
      backgroundColor: "transparent",
      borderRadius: 4,
    },
  },
  purpose: {
    display: "flex",
    justifyContent: "center",
  },
  expenseCategory: {
    display: "flex",
    justifyContent: "center",
  },
  ExpenseName: {
    display: "flex",
    justifyContent: "center",
    borderColor: "#CB444A",
  },
  expenseNameTextField: {
    backgroundColor: "#FCFDFF",
    borderRadius: 4,
  },
  invoiceReciept: {
    display: "flex",
    justifyContent: "center",
  },
  invoiceRecieptDateField: {
    backgroundColor: "#FCFDFF",
    borderRadius: 4,
  },
  projectId: {
    display: "flex",
    justifyContent: "center",
  },
  infoBox: {
    display: "flex",
    justifyContent: "center",
    "& .MuiSvgIcon-root": {
      fill: "#008CE6",
    },
  },
  infoBox1: {
    display: "flex",
    justifyContent: "center",
    "& .MuiSvgIcon-root": {
      fill: "#FFAA33",
    },
  },
  infoBoxMobile: {
    display: "flex",
    justifyContent: "center",
    paddingRight: "27px",
    "& .MuiSvgIcon-root": {
      fill: "#008CE6",
    },
  },
  currency: {
    display: "flex",
    justifyContent: "center",
  },

  expenseHeading: {
    display: "inline-flex",
    margin: "30px 0px 30px 0px",
    width: "700px", //(id ke liye)
    height: "24px",
  },
  checkBox: {
    display: "flex",
    alignItems: "center",
    marginLeft: "-10px",
    marginTop: "-30px",
  },
  checkBoxRoot: {
    "& .MuiSvgIcon-root": {
      color: "#0B1941",
      // "&$checked": {
      //   background: "#0B1941",
      //   color: "white",
      // },
    },
  },
  saveSubmitButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
    paddingBottom: "35px",
  },
  saveSubmitButtonMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "35px",
    paddingRight: "70px",
    paddingLeft: "57px",
  },
  rowContainer: {
    display: "flex",
    justifyContent: "center",
  },
  rowContainerMobile: {
    justifyContent: "center",
    flexDirection: "column",
  },
  inputLabels: {
    backgroundColor: "#FFFFFF",
  },
  inputLabels1: {
    backgroundColor: "#FFFFFF",
    fontSize: "15px",
  },
  errorText: {
    color: "red",
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: 0,
    backgroundColor: "#F8F9FF",
    fontFamily: "Montserrat",
  },
  errorRoot: {
    color: "red",
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: 0,
    backgroundColor: "#F8F9FF",
  },
  projectDetailsText: {
    fontWeight: "600",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: "20px",
    lineHeight: "24.38px",
    letterSpacing: "0.3px",
    textAlign: "left",
    color: "#252733",
  },
  projectDetailsTextMobile: {
    fontWeight: "600",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "-0.16px",
    textAlign: "left",
    color: "#353535",
    display: "flex",
    flexDirection: "column",
  },
  customToolTip: {
    maxWidth: "265px",
    height: "300px",
    overflow: "auto",
  },
}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitleDescDialog = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <HighlightOffIcon style={{ color: "#162D6E" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContentDescDialog = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActionsDescDialog = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ViewReward = (props) => {
  const classes = useStyles();
  const location = useLocation();

  const theme = useTheme();
  const [purpose, setPurpose] = useState("");
  const [purposeError, setPurposeError] = useState("");
  const [expenseCategoryError, setExpenseCategoryError] = useState("");
  const [vendorNameError, setVendorNameError] = useState("");
  const [billNumberError, setBillNumberError] = useState("");
  const [gstError, setGstError] = useState("");
  const [expenseNameError, setExpenseNameError] = useState("");
  const [selectDateError, setSelectDateError] = useState("");
  const [paymentError, setPaymentError] = useState("");
  const [dependentsError, setDependentsError] = useState("");
  const [declarationError, setDeclarationError] = useState("");
  const [currencyError, setCurrencyError] = useState("");
  const [billAmountError, setBillAmountError] = useState("");
  const [claimAmountError, setClaimAmountError] = useState("");
  const [quantityError, setQuantityError] = useState("");

  const [projectError, setProjectError] = useState("");
  const [attendeesError, setAttendeesError] = useState("");
  const [dragDropFileError, setDragDropFileError] = useState("");
  const [projectListSplitBoxError, setProjectListSplitBoxError] = useState("");
  const [expenseCategory, setExpenseCategory] = useState("");
  const [expenseCategoryChanged, setExpenseCategoryChanged] = useState(false);
  const [vendorName, setVendorName] = useState(null);
  const [billNumber, setBillNumber] = useState(null);
  const [gst, setGST] = useState(null);
  const [expenseName, setExpenseName] = useState("");
  const [currency, setCurrency] = useState("");
  const [billAmount, setBillAmount] = useState("");
  const [claimAmount, setClaimAmount] = useState("");
  const [quantity, setQuantity] = useState("");

  const [splitClaimAmount, setSplitClaimAmount] = useState("");
  const [payment, setPayment] = useState("");
  const [project, setproject] = useState([]);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [attendees, setAttendees] = useState([]);
  const [empAmounts, setEmpAmounts] = useState("");
  const [openTeamOutingExceedDialog, setOpenTeamOutingExceedDialog] =
    useState(false);
  const [multipleAttendeesError, setMultipleAttendeesError] = useState("");
  const [toggleExpenseCategory, setToggleExpenseCategory] = useState(true);
  const [declarationCheckBox, setDeclarationCheckBox] = useState(false);
  const [valiDateDate, setValiDateDate] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [deleteFileList, setDeleteFileList] = useState([]);
  const [delEmpEntityId, setDelEmpEntityId] = useState(null);
  const [selectedProjects, setSelectedProjects] = useState([
    {
      projectName: { projectID: "", projName: "" },
      splitAmount: "",
      billable: 0,
    },
  ]);
  const [validatonMsg, setValidationMsg] = useState([]);
  const [openToolTip, setOpenToolTip] = useState(false);
  const [expand, setExpand] = useState(false);
  const [uniqueArray, setUniqueArray] = useState([]);
  const [openESB, setOpenESB] = useState(true);
  const [editValidateFileList, setEditValidateFileList] = useState([]);
  const [validateJoiningDate, setValidateJoiningDate] = useState(false);
  //Dialog update start
  const [openExceptionalDialog, setOpenExceptionalDialog] = useState(false);
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDescDialog, setOpenDescDialog] = React.useState(false);
  //new changes 0001
  const [fromDate, setFromDate] = useState(null);
  const [countExpCatClick, setCountExpCatClick] = useState(0);
  const [oldExpId, setOldExpid] = useState(0);
  const [toDate, setToDate] = useState(null);
  const [forMonth, setForMonth] = useState(null);
  const [fromLocation, setFromLocation] = useState(null);
  const [toLocation, setToLocation] = useState(null);
  const [inBetweenDistance, setInBetweenDistance] = useState(0);
  const [validateToDate, setValidateToDate] = useState(null);
  const [toDateError, setToDateError] = useState("");
  const [validateFromDate, setValidateFromDate] = useState(false);
  const [fromDateError, setFromDateError] = useState("");
  const [forMonthError, setForMonthError] = useState("");
  const [fromLocationError, setFromLocationError] = useState("");
  const [toLocationError, setToLocationError] = useState("");
  const [inBetweenDistanceError, setInBetweenDistanceError] = useState("");
  const [validateQuarter, setValidateQuarter] = useState(false);
  const [lastDate, setLastDate] = useState(null);
  const [fromType, setFromType] = useState(null);
  const [toType, setToType] = useState(null);
  const [noOfMon, setNoOfMon] = useState(null);
  const [togglePrevProjectList, setTogglePrevProjectList] = useState(0);

  const [dependents, setDependents] = useState("");
  const [storedExpenseCategory, setStoredExpenseCategory] = useState([]);
  const [toggleProjectListDispatch, setToggleProjectListDispatch] =
    useState(false);
  const [toggleFirstProjectListDispatch, setToggleFirstProjectListDispatch] =
    useState(0);
  const [projectChange, setProjectChange] = useState(0);
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const monthList = [
    { monthId: 1, monthName: "January" },
    { monthId: 1, monthName: "February" },
    { monthId: 1, monthName: "March" },
    { monthId: 1, monthName: "April" },
    { monthId: 1, monthName: "May" },
    { monthId: 1, monthName: "June" },
    { monthId: 1, monthName: "July" },
    { monthId: 1, monthName: "August" },
    { monthId: 1, monthName: "September" },
    { monthId: 1, monthName: "October" },
    { monthId: 1, monthName: "November" },
    { monthId: 1, monthName: "December" },
  ];
  const handleCloseEDB = () => {
    setOpenExceptionalDialog(false);
  };
  const handleOpenEDB = () => {
    setOpenExceptionalDialog(true);
  };
  const handleCloseTOB = () => {
    setOpenTeamOutingExceedDialog(false);
  };
  const handleOpenTOB = () => {
    setOpenTeamOutingExceedDialog(true);
    //  isAnonymous=(true);
  };
  //Dialog update end
  const handleCheckBox = (e) => {
    //console.log("setDeclarationCheckBox", e.target.checked);
    setDeclarationCheckBox(e.target.checked);
  };
  const onExpandClick = () => {
    setExpand(!expand);
  };
  const handleFileList = (data) => {
    setFileList(data);
  };
  //console.log("fileList: >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ", fileList);

  const handleEditDeleteValidateFile = (data) => {
    setEditValidateFileList(data);
  };
  //console.log(" setEditValidateFileList", editValidateFileList);

  const handleDeleteFileList = (data) => {
    //console.log("delted file data", data);
    setDeleteFileList(data);
  };
  const handleProjectList = (data) => {
    // console.log("ineditemployeeProjectsList handleProjectList data", data);
    setSelectedProjects(data);
  };

  const handleOpenDescDialog = () => {
    setOpenDescDialog(true);
  };
  const handleCloseDescDialog = () => {
    setOpenDescDialog(false);
  };

  const materialUITextFieldProps1 = {
    id: "filled-multiline-flexible",
    label: "Bill Amount*",
    multiline: true,
    maxRows: 4,
    variant: "outlined",
  };
  const materialUITextFieldProps2 = {
    id: "filled-multiline-flexible",
    label: "Claim Amount*",
    multiline: true,
    maxRows: 4,
    variant: "outlined",
  };

  //Adding the Redux Part
  const dispatch = useDispatch();
  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );
  const purposeList = useSelector(
    (state) => state.createExpenseData.getPurposeList
  );
  const CategoryList = useSelector(
    (state) => state.createExpenseData.getCategoryList
  );
  // console.log("category list", CategoryList);
  const employeeProjectsList = useSelector(
    (state) => state.createExpenseData.getEmployeeProjectsList
  );
  const CurrencyList = useSelector(
    (state) => state.createExpenseData.getCurrencyList
  );

  const AttendeesList = useSelector(
    (state) => state.createExpenseData.getAttendeesList
  );
  const validateAttendeesList = useSelector(
    (state) => state.createExpenseData.getValidateAttendeesList
  );
  const paymentList = useSelector(
    (state) => state.createExpenseData.getPaymentList
  );
  const isEditdPostSuccess = useSelector(
    (state) => state.createExpenseData.isEditdPost
  );

  const postEditDataList = useSelector(
    (state) => state.createExpenseData.postEditData
  );

  const myExpenseList = useSelector(
    (state) => state.createExpenseData.getViewExpenseList
  );
  // console.log("my expense list", myExpenseList);
  const delEmpIDRed = useSelector((state) => state.createExpenseData.delEmpId);
  const myDelegateesData = useSelector(
    (state) => state.createExpenseData.getMyDelegateesData
  );
  const updatedSSOEmpId = useSelector(
    (state) => state.createExpenseData.ssoEmpId
  );
  const refreshModuleToggle = useSelector(
    (state) => state.createExpenseData.reloadModuleToggle
  );
  const DependentsList = useSelector(
    (state) => state.createExpenseData.getDependentsList
  );
  const billNumberExistence = useSelector(
    (state) => state.createExpenseData.getBillNumberExistence
  );
  // console.log("billNumberExistence redux edit", billNumberExistence);

  useEffect(() => {
    setDragDropFileError("");
  }, [fileList]);
  useEffect(() => {
    return () => {
      dispatch(resetExpenseResponse());
    };
  }, []);
  // useEffect(() => {
  //   setOpenESB(true);
  // },
  // [isEditdPostSuccess ,postEditDataList]
  // );
  useEffect(() => {
    const obj = {
      expId: location?.state?.response?.expId,
      empId: ssoEmpIdDetails?.empId,
      categoryId: expenseCategory?.categoryId,
      billNumber: billNumber ? billNumber : null,
    };
    // console.log("bill number obj", obj);
    if (obj.expId !== null && obj.expId !== undefined &&
      obj.empId !== null && obj.empId !== undefined &&
      obj.categoryId !== null && obj.categoryId !== undefined &&
      obj.billNumber !== null && obj.billNumber !== undefined){
    dispatch(getBillNumberExistenceData(obj));
  }
  }, [billNumber,declarationCheckBox,selectedDate,payment,splitClaimAmount,expenseName,currency,billAmount,claimAmount,quantity]);

  useEffect(() => {
    dispatch(getMyViewExpenseData(location?.state));
    checkLocation();
  }, [location]);

  const checkLocation = () => {
    if (location?.state?.compName == "editTravel") {
      setPurpose(
        purposeList?.find((item) => item.purpose == location.state.purpose)
      );
      setExpenseName(location.state.response.expName);
      setExpenseCategory("");
      setPurposeError("");
      setDependents("");
      setToggleExpenseCategory(false);
      // setOldExpid(
      //   expenseCategory ? expenseCategory?.categoryId : 0
      // );
      setDeleteFileList(myExpenseList?.attachements);
      setBillAmount("");
      setClaimAmount("");
      setQuantity("");
      setSelectedDate(null);
      setFromDate(null);
      setToDate(null);
      setForMonth(null);
      setFromLocation("");
      setToLocation("");
      setInBetweenDistance(0);
      setVendorName(null);
      setGST(null);
      setBillNumber(null);
      setAttendees([]);
      setSelectedProjects([]);
      setSplitClaimAmount("");
      setFileList([]);
    }
  };

  const handleSnackbar = () => {
    setOpenESB(false);
  };
  // const handleOpenESBox = () => {
  //   setOpenESB(true);
  // };
  const handlecloseSnackbar = () => {
    setOpenESB(false);
  };

  const handlepurpose = (value) => {
    if (value.purpose == "Travel") {
      const text = "/editTravelExpenseDetails/:id";
      const result = text.replace(":id", location?.state?.response?.expId);
      history.push({
        pathname: result,
        state: {
          response: location?.state?.response,
          empId: location?.state?.empId,
          compName: "edit",
          purpose: value.purpose,
          check: "notTravel",
        },
      });
    }
  };

  //console.log("isEditdPostSuccess", isEditdPostSuccess);

  useEffect(() => {
    //console.log("in the attendeeses", selectedProjects, AttendeesList);
    if (AttendeesList?.length > 0) {
      let optionList = [];
      selectedProjects.map((value) => {
        AttendeesList?.forEach((element) => {
          if (element.projCode === value.projectName.projectID) {
            optionList.push({
              ...element,
              color: "#" + Math.random().toString(16).substr(-6),
            });
          }
        });
      });
      const uniqueArray = [
        ...new Map(optionList.map((item) => [item["empId"], item])).values(),
      ];
      //console.log("AttendeesList>>>", optionList);
      //console.log("uniqueArray>>>", uniqueArray);

      setUniqueArray(uniqueArray);
    }
  }, [selectedProjects, AttendeesList]);

  useEffect(() => {
    //console.log("storedExpenseCategory1",expenseCategory );
    const data = expenseCategory?.notes;
    const notesArray = data?.split("||");
    setStoredExpenseCategory(notesArray);
  }, [expenseCategory]);
  const prevProp = useRef({
    purposeList,
    CategoryList,
    paymentList,
    CurrencyList,
    DependentsList,
  }).current;
  useEffect(() => {
    //console.log("pm1", paymentList);
    if (paymentList !== null) {
      const filterdCurrency = paymentList?.filter(
        (value) => value.selected === 1
      );
      if (filterdCurrency?.length > 0) {
        setPayment(filterdCurrency[0]);
      }
    }
  }, [paymentList]);
  useEffect(() => {
    //console.log("useEffectmyExpenseList ", selectedDate);
    if (myExpenseList !== null) {
      if (prevProp.purposeList !== purposeList) {
        let filter = purposeList?.filter(
          (value) =>
            value?.purposeId == myExpenseList?.ExpenseDetail[0]?.PurposeId
        );
        if (filter?.length > 0) {
          // console.log("Edit PurpoeValue1", filter[0]);
          setPurpose(filter[0]);
        }
      }
      //new changes 0001
      setExpenseName(myExpenseList?.ExpenseDetail[0]?.ExpName);

      setSelectedDate(
        moment(myExpenseList?.ExpenseDetail[0]?.ReceiptDate).format("l")
      );
      // console.log(
      //   "11110000",
      //   moment(myExpenseList?.ExpenseDetail?.ReceiptDate).format("l"),
      //   "2",
      //   moment(myExpenseList?.ExpenseDetail?.ReceiptDate).toDate()
      // );

      setFromDate(
        myExpenseList?.ExpenseDetail[0]?.BillingCycleFromDate == null ||
          myExpenseList?.ExpenseDetail[0]?.BillingCycleFromDate == ""
          ? ""
          : moment(
              myExpenseList?.ExpenseDetail[0]?.BillingCycleFromDate
            ).format("l")
      );
      setFromType(
        myExpenseList?.ExpenseDetail[0]?.BillingCycleFromDate == null ||
          myExpenseList?.ExpenseDetail[0]?.BillingCycleFromDate == ""
          ? ""
          : moment(
              myExpenseList?.ExpenseDetail[0]?.BillingCycleFromDate
            ).toDate()
      );
      setToDate(
        myExpenseList?.ExpenseDetail[0]?.BillingCycleToDate == null ||
          myExpenseList?.ExpenseDetail[0]?.BillingCycleToDate == ""
          ? ""
          : moment(myExpenseList?.ExpenseDetail[0]?.BillingCycleToDate).format(
              "l"
            )
      );
      setToType(
        myExpenseList?.ExpenseDetail[0]?.BillingCycleToDate == null ||
          myExpenseList?.ExpenseDetail[0]?.BillingCycleToDate == ""
          ? ""
          : moment(myExpenseList?.ExpenseDetail[0]?.BillingCycleToDate).toDate()
      );
      setForMonth(
        myExpenseList?.ExpenseDetail[0]?.BillingCycleMonth == "null" ||
          myExpenseList?.ExpenseDetail[0]?.BillingCycleMonth == null ||
          myExpenseList?.ExpenseDetail[0]?.BillingCycleMonth == "" ||
          myExpenseList?.ExpenseDetail[0]?.BillingCycleMonth == undefined
          ? null
          : myExpenseList?.ExpenseDetail[0]?.BillingCycleMonth
      );
      setBillAmount(myExpenseList?.ExpenseDetail[0]?.BillAmount);
      setFromLocation(
        myExpenseList?.ExpenseDetail[0]?.FromLocation == "null" ||
          myExpenseList?.ExpenseDetail[0]?.FromLocation == null ||
          myExpenseList?.ExpenseDetail[0]?.FromLocation == ""
          ? ""
          : myExpenseList?.ExpenseDetail[0]?.FromLocation
      );
      setToLocation(
        myExpenseList?.ExpenseDetail[0]?.ToLocation == "null" ||
          myExpenseList?.ExpenseDetail[0]?.ToLocation == null ||
          myExpenseList?.ExpenseDetail[0]?.ToLocation == ""
          ? ""
          : myExpenseList?.ExpenseDetail[0]?.ToLocation
      );
      setInBetweenDistance(myExpenseList?.ExpenseDetail[0]?.Distance);
      setQuantity(myExpenseList?.ExpenseDetail[0]?.BooksQty);
      setClaimAmount(myExpenseList?.ExpenseDetail[0]?.ClaimedAmount);
      setSplitClaimAmount(myExpenseList?.ExpenseDetail[0]?.ClaimedAmount);
      setVendorName(myExpenseList?.ExpenseDetail[0]?.VendorName);
      setBillNumber(myExpenseList?.ExpenseDetail[0]?.BillNumber);
      setGST(myExpenseList?.ExpenseDetail[0]?.GSTNumber);
    }
    checkLocation();
  }, [myExpenseList, purposeList]);

  useEffect(() => {
    //console.log("in myExpenseList ");
    if (myExpenseList !== null) {
      if (prevProp.CurrencyList !== CurrencyList) {
        let filter = CategoryList?.filter(
          (value) =>
            value?.currencyId == myExpenseList?.ExpenseDetail[0]?.CurrencyId
        );

        let selectedCurrency = CurrencyList?.find(
          (s) => s.currencyId === myExpenseList?.ExpenseDetail[0]?.CurrencyId
        );

        // if (filter?.length > 0) {
        setCurrency(selectedCurrency);
        // }
      }
    }
  }, [myExpenseList, CurrencyList]);
  useEffect(() => {
    // console.log(
    //   "in myExpenseList EditPurpoeValue1 ",
    //   CategoryList,
    //   "prevProp.CategoryList",
    //   prevProp.CategoryList,
    //   "myExpenseList",
    //   myExpenseList
    // );
    if (myExpenseList != null && CategoryList != null) {
      // console.log("EditPurpoeValue2 filter[0]", CategoryList);
      if (prevProp.CategoryList != CategoryList) {
        let filter = CategoryList?.filter(
          (value) =>
            value?.categoryId == myExpenseList?.ExpenseDetail[0]?.CategoryId
        );
        if (filter?.length > 0) {
          // console.log("EditPurpoeValue3 filter[0]", filter[0]);
          if (toggleExpenseCategory == true) {
            setExpenseCategory(filter[0]);
          }
        }
      }
    }
    //checkLocation();
  }, [myExpenseList, CategoryList]);

  useEffect(() => {
    //console.log("pm2 ", myExpenseList);
    if (myExpenseList !== null) {
      if (prevProp.paymentList !== paymentList) {
        //console.log("pm4", CategoryList);
        let filter = paymentList?.filter(
          (value) =>
            value?.methodId == myExpenseList?.ExpenseDetail[0]?.PaymentMethodId
        );
        //console.log("pm3", filter);
        if (filter?.length > 0) {
          setPayment(filter[0]);
        }
      }
    }
  }, [myExpenseList, paymentList]);

  useEffect(() => {
    if (myExpenseList !== null) {
      if (DependentsList != null) {
        if (prevProp.DependentsList !== DependentsList) {
          let filterdDependents = DependentsList?.filter(
            (value) =>
              value?.dependentId == myExpenseList?.ExpenseDetail[0]?.DependentId
          );
          if (filterdDependents?.length > 0) {
            setDependents(filterdDependents[0]);
          }
        }
      }
    }
  }, [myExpenseList, DependentsList]);

  const Array = [
    {
      validationType: "purpose",
      value: purpose,
    },

    {
      validationType: "expense Category",
      value: expenseCategory,
    },
    {
      validationType: "expense Name",
      value: expenseName,
    },
    {
      validationType: "Reciept Date",
      value: selectedDate,
      claim: valiDateDate,
      validateList: validateQuarter,
      validateList2: lastDate,
      noOfMonths: ssoEmpIdDetails,
      validateJoiningDate: validateJoiningDate,
    },
    {
      validationType: "Drag Drop File",
      value: fileList,
      claim: editValidateFileList,
      validateList: "edit",
    },
    {
      validationType: "Project And Split Box",
      value: selectedProjects,
      claim: claimAmount,
    },
    {
      validationType: "Declaration CheckBox",
      value: declarationCheckBox,
    },
    {
      validationType: "Currency",
      value: currency,
    },
    {
      validationType: "Bill Amount",
      value: billAmount,
    },
    {
      validationType: "Claim Amount",
      value: claimAmount,
      claim: billAmount,
      validateList: validateAttendeesList,
      validateList2: expenseCategory,
      noOfMonths: noOfMon,
    },
    {
      validationType: "Payment",
      value: payment,
    },
    ...(expenseCategory?.multipleAttendes == 1
      ? [
          {
            validationType: "Attendees",
            value: attendees,
            claim: claimAmount,
            validateList: validateAttendeesList,
          },
        ]
      : []),
    ...(expenseCategory?.companyExpenseFlag === 1
      ? [
          {
            validationType: "Vendor Name",
            value: vendorName,
          },
          {
            validationType: "Bill Number",
            value: billNumber,
          },
          {
            validationType: "GST",
            value: gst,
          },
        ]
      : []),
    ...(expenseCategory?.qtyEnabledfl === "1"
      ? [
          {
            validationType: "Quantity",
            value: quantity,
          },
          {
            validationType: "Bill Number",
            value: billNumber,
          },
        ]
      : []),
    ...(expenseCategory?.billingCycle == 1
      ? [
          {
            validationType: "From Date",
            value: fromDate,
          },
          {
            validationType: "To Date",
            value: toDate,
          },
          {
            validationType: "For Month",
            value: forMonth,
          },
        ]
      : []),
    ...(expenseCategory?.distance == 1
      ? [
          {
            validationType: "From Location",
            value: fromLocation,
          },
          {
            validationType: "To Location",
            value: toLocation,
          },
          {
            validationType: "Distance In Between",
            value: inBetweenDistance,
          },
        ]
      : []),
  ];

  //console.log("stae of expense cate", expenseCategory);
  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  function isLeapYear(date) {
    var year = date.getFullYear();
    return year % 400 === 0 || (year % 4 === 0 && year % 100 !== 0);
  }
  function getLastDayOfExpense(lastDay, type) {
    if (lastDay.getMonth() == 0) {
      if (isLeapYear(lastDay) == false) {
        //console.log("lastday", lastDay.addDays(75));
        setLastDate(lastDay.addDays(75));
        return lastDay.addDays(75);
      } else if (isLeapYear(lastDay) == true) {
        //console.log("lastday", lastDay.addDays(76));
        setLastDate(lastDay.addDays(76));
        return lastDay.addDays(76);
      }
    } else if (type.getMonth() == 1) {
      //console.log("lastday", lastDay.addDays(47));
      setLastDate(lastDay.addDays(47));
      return lastDay.addDays(47);
    } else if (type.getMonth() == 2) {
      //console.log("lastday", lastDay.addDays(16));
      setLastDate(lastDay.addDays(16));
      return lastDay.addDays(16);
    } else if (type.getMonth() == 3) {
      //console.log("lastday", lastDay.addDays(77));
      setLastDate(lastDay.addDays(77));
      return lastDay.addDays(77);
    } else if (type.getMonth() == 4) {
      //console.log("lastday", lastDay.addDays(46));
      setLastDate(lastDay.addDays(46));
      return lastDay.addDays(46);
    } else if (type.getMonth() == 5) {
      //console.log("lastday", lastDay.addDays(16));
      setLastDate(lastDay.addDays(16));
      return lastDay.addDays(16);
    } else if (type.getMonth() == 6) {
      //console.log("lastday", lastDay.addDays(77));
      setLastDate(lastDay.addDays(77));
      return lastDay.addDays(77);
    } else if (type.getMonth() == 7) {
      //console.log("lastday", lastDay.addDays(46));
      setLastDate(lastDay.addDays(46));
      return lastDay.addDays(46);
    } else if (type.getMonth() == 8) {
      //console.log("lastday", lastDay.addDays(16));
      setLastDate(lastDay.addDays(16));
      return lastDay.addDays(16);
    } else if (type.getMonth() == 9) {
      //console.log("lastday", lastDay.addDays(77));
      setLastDate(lastDay.addDays(77));
      return lastDay.addDays(77);
    } else if (type.getMonth() == 10) {
      //console.log("lastday", lastDay.addDays(47));
      setLastDate(lastDay.addDays(47));
      return lastDay.addDays(47);
    } else if (type.getMonth() == 11) {
      //console.log("lastday", lastDay.addDays(16));
      setLastDate(lastDay.addDays(16));
      return lastDay.addDays(16);
    }
  }

  const handleDateChange = (type, date) => {
    setSelectDateError("");
    setSelectedDate(null);
    setValiDateDate(false);
    setValidateQuarter(false);
    setValidateJoiningDate(false);
    const presentDate = new Date();
    if (
      type < presentDate &&
      expenseCategory != null &&
      expenseCategory != "" &&
      expenseCategory != undefined &&
      ssoEmpIdDetails != null &&
      ssoEmpIdDetails != "" &&
      ssoEmpIdDetails != undefined
    ) {
      const difference = presentDate - type;
      let TotalDays = Math.ceil(difference / (1000 * 3600 * 24) - 1);
      const slectedDate = moment(date).format("l");

      // if(delEmpIDRed == ssoEmpIdDetails?.empId){}
      if (
        moment(type).toDate() < moment(ssoEmpIdDetails?.empJoiningDate).toDate()
      ) {
        setSelectedDate(slectedDate);
        setValidateJoiningDate(true);
        setSelectDateError(
          `Expense invoice date cannot be before joining date which is ${moment(
            ssoEmpIdDetails?.empJoiningDate
          ).format("l")}`
        );
      } else if (expenseCategory?.quaterlyBilling != 1) {
        // console.log(
        //   "11110000 TotalDays 0004",
        //   TotalDays,
        //   "claimWindow",
        //   expenseCategory?.claimWindow,
        //   "third",
        //   ssoEmpIdDetails?.empJoiningDate,
        //   "REACT_APP_GRACE_PERIOD",
        //   process.env.REACT_APP_GRACE_PERIOD
        // );
        if (
          location?.state?.compName == "edit" &&
          location?.state?.response?.status == "Rejected"
        ) {
          if (
            TotalDays <=
            expenseCategory?.claimWindow +
              Number(process.env.REACT_APP_GRACE_PERIOD)
          ) {
            setSelectedDate(slectedDate);
          } else {
            setSelectedDate(slectedDate);
            setValiDateDate(true);
            setSelectDateError("Days limit is expired for this Category");
          }
        } else {
          if (TotalDays <= expenseCategory?.claimWindow) {
            setSelectedDate(slectedDate);
          } else {
            setSelectedDate(slectedDate);
            setValiDateDate(true);
            setSelectDateError("Days limit is expired for this Category");
          }
        }
      } else if (expenseCategory?.quaterlyBilling == 1) {
        let lastDayOfMonth = new Date(
          type.getFullYear(),
          type.getMonth() + 1,
          0
        );

        let lastDayOfQuarter = getLastDayOfExpense(lastDayOfMonth, type);
        // console.log(
        //   "2222lastDayOfQuarteradddays",
        //   lastDayOfQuarter.addDays(Number(process.env.REACT_APP_GRACE_PERIOD)),
        //   "process.env.REACT_APP_GRACE_PERIOD",
        //   process.env.REACT_APP_GRACE_PERIOD
        // );
        if (
          location?.state?.compName == "edit" &&
          location?.state?.response?.status == "Rejected"
        ) {
          if (
            presentDate >=
            lastDayOfQuarter.addDays(Number(process.env.REACT_APP_GRACE_PERIOD))
          ) {
            // console.log(
            //   "22222 presentDate >= lastDayOfQuarter grace",
            //   presentDate
            // );
            setSelectedDate(slectedDate);
            setValidateQuarter(true);
            setSelectDateError(
              `Last date to submit the expense was ${moment(
                lastDayOfQuarter.setDate(lastDayOfQuarter.getDate() - 1)
              ).format("l")}`
            );
          } else setSelectedDate(slectedDate);
        } else if (presentDate >= lastDayOfQuarter) {
          // console.log(
          //   "2222222222 presentDate >= lastDayOfQuarter",
          //   presentDate
          // );
          setSelectedDate(slectedDate);
          setValidateQuarter(true);
          setSelectDateError(
            `Last date to submit the expense was ${moment(
              lastDayOfQuarter.setDate(lastDayOfQuarter.getDate() - 1)
            ).format("l")}`
          );
        } else setSelectedDate(slectedDate);
      }
    }
    // const obj = {
    //   empId: ssoEmpIdDetails?.empId,
    //   categoryId: expenseCategory?.categoryId,
    //   billNumber: billNumber ? billNumber : 0,
    // };
    // console.log("bill number obj", obj);
    // dispatch(getBillNumberExistenceData(obj));
  };
  //new changes 0001
  const handleFromDate = (type, date) => {
    setFromDate(null);
    setValidateToDate(false);
    setFromDateError("");

    if (toDate != null) {
      const diffInDates = (toType - type) / (1000 * 60 * 60 * 24);
      const noOfMonths = Math.round(diffInDates / 30);
      // console.log("diff2", diffInDates, noOfMonths);
      setNoOfMon(noOfMonths);
    }
    let tempFromDate = moment(date).format("l");
    setFromDate(tempFromDate);
    setFromType(type);
    setFromDateError("");
    setToDateError("");
  };
  const handleToDate = (type, date) => {
    // console.log("type 0021", type);
    // console.log("date 0021", date);
    // console.log("fromDate 0021", fromDate);

    setToDate(null);
    setValidateToDate(false);
    setToDateError("");
    let tempToDate = moment(date).format("l");
    setToDateError("");
    const diffInDates = (type - fromType) / (1000 * 60 * 60 * 24);
    const noOfMonths = Math.round(diffInDates / 30);
    //console.log("diff", diffInDates, noOfMonths);

    if (fromDate != null) {
      setToDate(tempToDate);
      setToType(type);
      setNoOfMon(noOfMonths);
    } else {
      setValidateToDate(true);
      setToDate(tempToDate);
      setToType(type);
      setToDateError("First enter From Date");
    }
  };
  // const handleFromDate = (type, date) => {
  //   setFromDate(null);
  //   let tempFromDate = moment(date).format("l");
  //   setFromDate(tempFromDate);
  // };

  // const handleToDate = (type, date) => {
  //   setToDate(null);
  //   setValidateToDate(false);
  //   let tempToDate = moment(date).format("l");

  //   if (fromDate != null) {
  //     setToDate(tempToDate);
  //   } else {
  //     setValidateToDate(true);
  //     setToDate(tempToDate);
  //     setToDateError("First enter From Date");
  //   }
  // };

  //console.log("validateToDate 0002", validateToDate);
  //console.log("toDate 0002", toDate);
  //console.log("valiDateDate", valiDateDate);
  const handlePerson = (data) => {
    setproject(data);
  };
  const handleAttendees2 = (attendees, claimAmount, validateAttendeesList) => {
    //console.log(" value--- in handleAttendees: ", attendees);
    //console.log("type--- in handleAttendees: ", claimAmount);
    //console.log("validate--- in handleAttendees: ", validateAttendeesList);
    let list = null;
    const perHead = Number(claimAmount / attendees?.length);
    //console.log("value of perhead--- in handleAttendees: ", perHead);

    let newArray = validateAttendeesList?.filter((array22) =>
      attendees.some((array11) => array22.empId === array11.empId)
    );
    //console.log("new Array--- in handleAttendees: ", newArray);

    list = newArray?.filter((check) => perHead > Number(check.remainingAmount));
    //console.log("new Array--- in handleAttendees2: ", validateAttendeesList);
    //console.log("list--- in handleAttendees2: ", list);

    return list;
  };
  useEffect(() => {
    setMultipleAttendeesError("");
    if (
      attendees != null &&
      attendees != "" &&
      claimAmount != null &&
      claimAmount != "" &&
      validateAttendeesList != null &&
      validateAttendeesList != "" &&
      expenseCategory?.multipleAttendes == 1 &&
      expenseCategory?.categoryId == 11
    ) {
      const tempError = handleAttendees2(
        attendees,
        claimAmount,
        validateAttendeesList
      );
      setMultipleAttendeesError(tempError);
    }
  }, [attendees, claimAmount, validateAttendeesList, expenseCategory]);
  //console.log("setMultipleAttendeesError NEW4", multipleAttendeesError);
  const handleClick = () => {
    if (expenseCategory?.qtyEnabledfl === "1") {
      if (billNumberExistence?.billExistFlag == 0) {
        if (purpose.purpose == "Medical Expenses") {
          if (dependents?.length == 0) {
            setDependentsError("*Please enter Relationship");
          } else {
            setDependentsError("");
          }
        } else {
          setDependentsError("");
        }
        var key = "E546C8DF278CD5931069B522E695D4F2";
        const result = Array.map((feild) =>
          FieldValidator(
            feild.validationType,
            feild.value,
            feild.claim,
            feild.validateList,
            feild.validateList2,
            feild.noOfMonths,
            feild.validateJoiningDate
          )
        );
        setValidationMsg(result);
        //console.log("field validator: ", attendees);
        //console.log("selectedProjects", selectedProjects);
        //console.log("delete file list", deleteFileList);

        let arr = result.map((i) => i.bool);
        let checker = arr.every((element) => element === true);
        if (purpose.purpose === "Medical Expenses" && !dependents) {
          checker = false;
        }
        if (checker) {
          // setValidationMsg(result);

          setDisableSubmit(true);
          const filterproject = [];
          const filterAttendees = [];
          let fileList1 = [];
          const FilterdeleteFileList = [];
          if (deleteFileList?.length > 0) {
            deleteFileList?.forEach((value) =>
              FilterdeleteFileList.push({
                attachmentid: value.AttachmentId,
                updatedFileName: value.UpdatedFileName,
              })
            );
          }

          if (selectedProjects?.length > 0) {
            selectedProjects?.map((value) =>
              filterproject.push({
                projid: Encrypt(key, JSON.stringify(value.projectName.projId)),
                // projid: "3",
                projamount: value.splitAmount,
                billable: Encrypt(key, JSON.stringify(value.billable)),
              })
            );
          }

          if (attendees?.length > 0) {
            attendees?.map((value) =>
              filterAttendees.push({
                empid: Encrypt(key, JSON.stringify(value.empId)),
                // empid: "5",
                empamount: claimAmount / attendees?.length,
              })
            );
          }

          let formData = new FormData();
          formData.append("ExpName", expenseName);

          formData.append("PurposeId", purpose.purposeId);
          formData.append("Purpose", purpose.purpose);
          // formData.append("CategoryCode", expenseCategory.categoryCode);
          formData.append("CategoryCode", expenseCategory.categoryCode);

          formData.append("CategoryId", expenseCategory.categoryId);
          formData.append("CategoryName", expenseCategory.categoryName);
          if (expenseCategory.autoApproval == 0) {
            formData.append("exceptionapplicationflag", 0);
            formData.append("autoapprovalflag", 0);
          } else if (
            expenseCategory.autoApproval == 1 &&
            claimAmount > expenseCategory?.policyLimit
          ) {
            formData.append("exceptionapplicationflag", 1);
            formData.append("autoapprovalflag", 0);
          } else if (
            expenseCategory.autoApproval == 1 &&
            claimAmount <= expenseCategory?.policyLimit
          ) {
            formData.append("exceptionapplicationflag", 0);
            formData.append("autoapprovalflag", 1);
          }
          formData.append(
            "BillNumber",
            billNumber != null && billNumber != "null"
              ? Encrypt(key, JSON.stringify(billNumber))
              : null
          );
          formData.append(
            "VendorName",
            vendorName != null && vendorName != "null"
              ? Encrypt(key, JSON.stringify(vendorName))
              : null
          );
          formData.append(
            "GSTNumber",
            gst != null && gst != "null"
              ? Encrypt(key, JSON.stringify(gst))
              : null
          );
          formData.append("booksqty", quantity);
          //new changes 0001
          formData.append("BillingCycleFromDate", fromDate || "");
          formData.append("BillingCycleToDate", toDate || "");
          formData.append("BillingCycleMonth", forMonth);
          formData.append("FromLocation", fromLocation || null);
          formData.append("ToLocation", toLocation || null);
          formData.append("Distance", inBetweenDistance || 0);

          formData.append("CurrencyId", currency.currencyId);
          formData.append("BillAmount", billAmount);
          formData.append("ClaimedAmount", claimAmount);
          formData.append("ExpStatus", "In Progress");
          formData.append(
            "EmpEntityId",
            delEmpEntityId != null ? delEmpEntityId : ssoEmpIdDetails?.entityId
          );
          // formData.append("CreatedBy", 1);
          formData.append("PaymentMethodId", payment.methodId);
          if (purpose.purpose == "Medical Expenses") {
            formData.append("DependentId", dependents?.dependentId);
          } else {
            formData.append("DependentId", 0);
          }
          formData.append("ReceiptDate", selectedDate);
          formData.append(
            "ExpId",
            Encrypt(
              key,
              JSON.stringify(
                location?.state?.response?.expId ||
                  location?.state?.response?.response?.expId
              )
            )
          );
          formData.append("DeleteExpenses", JSON.stringify([]));
          // let a=   Encrypt(key, JSON.stringify(location?.state?.response?.expId))
          //console.log("UpdatedBy", ssoEmpIdDetails);
          // formData.append("UpdatedBy", 1);
          formData.append(
            "UpdatedBy",
            Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
          );
          formData.append(
            "EmpId",
            delEmpIDRed != null
              ? Encrypt(key, JSON.stringify(delEmpIDRed))
              : Encrypt(key, JSON.stringify(location?.state?.empId))
          );
          formData.append(
            "deleteAttachments",
            JSON.stringify(FilterdeleteFileList)
          );

          formData.append("expenseProjLinks", JSON.stringify(filterproject));
          // formData.append(
          //   "expenseEmpLinks",
          //   expenseCategory?.multipleAttendes == 1
          //     ? JSON.stringify(filterAttendees)
          //     : []
          // );
          formData.append(
            "expenseEmpLinks",
            filterAttendees != [] ? JSON.stringify(filterAttendees) : []
          );
          let tempFileList = [];
          for (let i = 0; i < fileList?.length; i++) {
            tempFileList.push({
              fileName: fileList[i].name,
              attachmentsize: fileList[i].size,
            });
          }
          formData.append("files", JSON.stringify(tempFileList));
          formData.append("traveltype", JSON.stringify(""));

          formData.append("childExpenses", JSON.stringify([]));
          formData.append("expenseAdvancesLists", JSON.stringify([]));
          formData.append("expenseAdvancesDetails", JSON.stringify([]));
          dispatch(postEditExpenseData(formData));
        }
        handleCloseEDB();
      } else {
        setBillNumberError("Please enter unique bill number");
      }
    } else if (expenseCategory?.qtyEnabledfl === "0") {
      if (purpose.purpose == "Medical Expenses") {
        if (dependents?.length == 0) {
          setDependentsError("*Please enter Relationship");
        } else {
          setDependentsError("");
        }
      } else {
        setDependentsError("");
      }
      var key = "E546C8DF278CD5931069B522E695D4F2";
      const result = Array.map((feild) =>
        FieldValidator(
          feild.validationType,
          feild.value,
          feild.claim,
          feild.validateList,
          feild.validateList2,
          feild.noOfMonths,
          feild.validateJoiningDate
        )
      );
      setValidationMsg(result);
      //console.log("field validator: ", attendees);
      //console.log("selectedProjects", selectedProjects);
      //console.log("delete file list", deleteFileList);

      let arr = result.map((i) => i.bool);
      let checker = arr.every((element) => element === true);
      if (purpose.purpose === "Medical Expenses" && !dependents) {
        checker = false;
      }
      if (checker) {
        // setValidationMsg(result);

        setDisableSubmit(true);
        const filterproject = [];
        const filterAttendees = [];
        let fileList1 = [];
        const FilterdeleteFileList = [];
        if (deleteFileList?.length > 0) {
          deleteFileList?.forEach((value) =>
            FilterdeleteFileList.push({
              attachmentid: value.AttachmentId,
              updatedFileName: value.UpdatedFileName,
            })
          );
        }

        if (selectedProjects?.length > 0) {
          selectedProjects?.map((value) =>
            filterproject.push({
              projid: Encrypt(key, JSON.stringify(value.projectName.projId)),
              // projid: "3",
              projamount: value.splitAmount,
              billable: Encrypt(key, JSON.stringify(value.billable)),
            })
          );
        }

        if (attendees?.length > 0) {
          attendees?.map((value) =>
            filterAttendees.push({
              empid: Encrypt(key, JSON.stringify(value.empId)),
              // empid: "5",
              empamount: claimAmount / attendees?.length,
            })
          );
        }

        let formData = new FormData();
        formData.append("ExpName", expenseName);

        formData.append("PurposeId", purpose.purposeId);
        formData.append("Purpose", purpose.purpose);
        // formData.append("CategoryCode", expenseCategory.categoryCode);
        formData.append("CategoryCode", expenseCategory.categoryCode);

        formData.append("CategoryId", expenseCategory.categoryId);
        formData.append("CategoryName", expenseCategory.categoryName);
        if (expenseCategory.autoApproval == 0) {
          formData.append("exceptionapplicationflag", 0);
          formData.append("autoapprovalflag", 0);
        } else if (
          expenseCategory.autoApproval == 1 &&
          claimAmount > expenseCategory?.policyLimit
        ) {
          formData.append("exceptionapplicationflag", 1);
          formData.append("autoapprovalflag", 0);
        } else if (
          expenseCategory.autoApproval == 1 &&
          claimAmount <= expenseCategory?.policyLimit
        ) {
          formData.append("exceptionapplicationflag", 0);
          formData.append("autoapprovalflag", 1);
        }
        formData.append(
          "BillNumber",
          billNumber != null && billNumber != "null"
            ? Encrypt(key, JSON.stringify(billNumber))
            : null
        );
        formData.append(
          "VendorName",
          vendorName != null && vendorName != "null"
            ? Encrypt(key, JSON.stringify(vendorName))
            : null
        );
        formData.append(
          "GSTNumber",
          gst != null && gst != "null"
            ? Encrypt(key, JSON.stringify(gst))
            : null
        );
        formData.append("booksqty", quantity);
        //new changes 0001
        formData.append("BillingCycleFromDate", fromDate || "");
        formData.append("BillingCycleToDate", toDate || "");
        formData.append("BillingCycleMonth", forMonth);
        formData.append("FromLocation", fromLocation || null);
        formData.append("ToLocation", toLocation || null);
        formData.append("Distance", inBetweenDistance || 0);

        formData.append("CurrencyId", currency.currencyId);
        formData.append("BillAmount", billAmount);
        formData.append("ClaimedAmount", claimAmount);
        formData.append("ExpStatus", "In Progress");
        formData.append(
          "EmpEntityId",
          delEmpEntityId != null ? delEmpEntityId : ssoEmpIdDetails?.entityId
        );
        // formData.append("CreatedBy", 1);
        formData.append("PaymentMethodId", payment.methodId);
        if (purpose.purpose == "Medical Expenses") {
          formData.append("DependentId", dependents?.dependentId);
        } else {
          formData.append("DependentId", 0);
        }
        formData.append("ReceiptDate", selectedDate);
        formData.append(
          "ExpId",
          Encrypt(
            key,
            JSON.stringify(
              location?.state?.response?.expId ||
                location?.state?.response?.response?.expId
            )
          )
        );
        formData.append("DeleteExpenses", JSON.stringify([]));
        // let a=   Encrypt(key, JSON.stringify(location?.state?.response?.expId))
        //console.log("UpdatedBy", ssoEmpIdDetails);
        // formData.append("UpdatedBy", 1);
        formData.append(
          "UpdatedBy",
          Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
        );
        formData.append(
          "EmpId",
          delEmpIDRed != null
            ? Encrypt(key, JSON.stringify(delEmpIDRed))
            : Encrypt(key, JSON.stringify(location?.state?.empId))
        );
        formData.append(
          "deleteAttachments",
          JSON.stringify(FilterdeleteFileList)
        );

        formData.append("expenseProjLinks", JSON.stringify(filterproject));
        // formData.append(
        //   "expenseEmpLinks",
        //   expenseCategory?.multipleAttendes == 1
        //     ? JSON.stringify(filterAttendees)
        //     : []
        // );
        formData.append(
          "expenseEmpLinks",
          filterAttendees != [] ? JSON.stringify(filterAttendees) : []
        );
        let tempFileList = [];
        for (let i = 0; i < fileList?.length; i++) {
          tempFileList.push({
            fileName: fileList[i].name,
            attachmentsize: fileList[i].size,
          });
        }
        formData.append("files", JSON.stringify(tempFileList));
        formData.append("traveltype", JSON.stringify(""));

      formData.append("childExpenses", JSON.stringify([]));
      formData.append("expenseAdvancesLists", JSON.stringify([]));
      formData.append("expenseAdvancesDetails", JSON.stringify([]));
      dispatch(postEditExpenseData(formData));
    }
    handleCloseEDB();
} else {
      setBillNumberError("Please enter unique bill number");
    }
  };
  // const handleQuantity = (value, i) => {
  //   console.log("value in handleQuantity :====> ", value);
  //   setQuantity(value);
  //   // if (value === 0 || value === null) {
  //   //   setQuantityError("Please enter quantity");
  //   // }
  // };
  const handleQuantity = (value, i) => {
    // console.log("value in handleQuantity :====> ", value);
    const cleanedValue = value.replace(/,/g, "");

    setQuantity(Number(cleanedValue));
    // if (value === 0 || value === null) {
    //   setQuantityError("Please enter quantity");
    // }
  };
  const handleClaimAmount = (value) => {
    //console.log("value in handleClaimAmount :====> ", value);
    setSplitClaimAmount(Number(value.replaceAll(",", "")));
    setClaimAmount(Number(value.replaceAll(",", "")));

    if (parseInt(Number(value.replaceAll(",", ""))) > parseInt(billAmount)) {
      setClaimAmountError("Claim Amount is greater than Bill Amount");
    }
  };
  const handleBillAmount = (value) => {
    setBillAmount(Number(value.replaceAll(",", "")));
  };
  const handleSave = () => {
    if (billNumberExistence?.billExistFlag == 0) {
      if (purpose.purpose == "Medical Expenses") {
        if (dependents == "") {
          setDependentsError("*Please enter Relationship");
        } else {
          setDependentsError("");
        }
      } else {
        setDependentsError("");
      }
      var key = "E546C8DF278CD5931069B522E695D4F2";
      const result = Array.map((feild) =>
        FieldValidator(
          feild.validationType,
          feild.value,
          feild.claim,
          feild.validateList,
          feild.validateList2,
          feild.noOfMonths,
          feild.validateJoiningDate
        )
      );
      setValidationMsg(result);
      //console.log("field validator: ", attendees);
      //console.log("delete file list", deleteFileList);

      let arr = result.map((i) => i.bool);
      let checker = arr.every((element) => element === true);
      if (purpose.purpose === "Medical Expenses" && !dependents) {
        checker = false;
      }
      if (checker) {
        // setValidationMsg(result);
        setDisableSubmit(true);

        const filterproject = [];
        const filterAttendees = [];
        let fileList1 = [];
        const FilterdeleteFileList = [];
        if (deleteFileList?.length > 0) {
          deleteFileList?.forEach((value) =>
            FilterdeleteFileList.push({
              attachmentid: value.AttachmentId,
              updatedFileName: value.UpdatedFileName,
            })
          );
        }

        if (selectedProjects?.length > 0) {
          selectedProjects?.map((value) =>
            filterproject.push({
              projid: Encrypt(key, JSON.stringify(value.projectName.projId)),
              // projid: "3",
              projamount: value.splitAmount,
              billable: Encrypt(key, JSON.stringify(value.billable)),
            })
          );
        }

        if (attendees?.length > 0) {
          attendees?.map((value) =>
            filterAttendees.push({
              empid: Encrypt(key, JSON.stringify(value.empId)),
              // empid: "5",
              empamount: claimAmount / attendees?.length,
            })
          );
        }
        let formData = new FormData();
        formData.append("ExpName", expenseName);

        formData.append("PurposeId", purpose.purposeId);
        formData.append("Purpose", purpose.purpose);
        // formData.append("CategoryCode", expenseCategory.categoryCode);
        formData.append("CategoryCode", expenseCategory.categoryCode);

        formData.append("CategoryId", expenseCategory.categoryId);
        formData.append("CategoryName", expenseCategory.categoryName);
        if (expenseCategory.autoApproval == 0) {
          formData.append("exceptionapplicationflag", 0);
          formData.append("autoapprovalflag", 0);
        } else if (
          expenseCategory.autoApproval == 1 &&
          claimAmount > expenseCategory?.policyLimit
        ) {
          formData.append("exceptionapplicationflag", 1);
          formData.append("autoapprovalflag", 0);
        } else if (
          expenseCategory.autoApproval == 1 &&
          claimAmount <= expenseCategory?.policyLimit
        ) {
          formData.append("exceptionapplicationflag", 0);
          formData.append("autoapprovalflag", 1);
        }

        formData.append(
          "BillNumber",
          billNumber != null && billNumber != "null"
            ? Encrypt(key, JSON.stringify(billNumber))
            : null
        );
        formData.append(
          "VendorName",
          vendorName != null && vendorName != "null"
            ? Encrypt(key, JSON.stringify(vendorName))
            : null
        );
        formData.append(
          "GSTNumber",
          gst != null && gst != "null"
            ? Encrypt(key, JSON.stringify(gst))
            : null
        );
        formData.append("booksqty", quantity);
        //new changes 0001
        formData.append("BillingCycleFromDate", fromDate || "");
        formData.append("BillingCycleToDate", toDate || "");
        formData.append("BillingCycleMonth", forMonth);
        formData.append("FromLocation", fromLocation || "");
        formData.append("ToLocation", toLocation || "");
        formData.append("Distance", inBetweenDistance || 0);

        formData.append("CurrencyId", currency.currencyId);
        if (purpose.purpose == "Medical Expenses") {
          formData.append("DependentId", dependents?.dependentId);
        } else {
          formData.append("DependentId", 0);
        }
        formData.append("BillAmount", billAmount);
        formData.append("ClaimedAmount", claimAmount);
        formData.append("ExpStatus", "Draft");
        formData.append(
          "EmpEntityId",
          delEmpEntityId != null ? delEmpEntityId : ssoEmpIdDetails?.entityId
        );
        // formData.append("CreatedBy", 1);
        formData.append("PaymentMethodId", payment.methodId);
        formData.append("ReceiptDate", selectedDate);
        formData.append(
          "ExpId",
          Encrypt(key, JSON.stringify(location?.state?.response?.expId))
        );
        formData.append("DeleteExpenses", JSON.stringify([]));

        // formData.append("UpdatedBy", 1);
        formData.append(
          "UpdatedBy",
          Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
        );
        formData.append(
          "EmpId",
          delEmpIDRed != null
            ? Encrypt(key, JSON.stringify(delEmpIDRed))
            : Encrypt(key, JSON.stringify(location?.state?.empId))
        );

        formData.append(
          "deleteAttachments",
          JSON.stringify(FilterdeleteFileList)
        );

        formData.append("expenseProjLinks", JSON.stringify(filterproject));
        formData.append(
          "expenseEmpLinks",
          filterAttendees != [] ? JSON.stringify(filterAttendees) : []
        );
        let tempFileList = [];
        for (let i = 0; i < fileList?.length; i++) {
          tempFileList.push({
            fileName: fileList[i].name,
            attachmentsize: fileList[i].size,
          });
        }
        formData.append("files", JSON.stringify(tempFileList));
        formData.append("traveltype", JSON.stringify(""));

        formData.append("childExpenses", JSON.stringify([]));
        formData.append("expenseAdvancesLists", JSON.stringify([]));
        formData.append("expenseAdvancesDetails", JSON.stringify([]));
        dispatch(postEditExpenseData(formData));
      }
      handleCloseEDB();
    } else {
      setBillNumberError("Please enter unique bill number");
    }
  };

  //console.log("result", validatonMsg);
  //console.log("Claim Amount Error: outside ====> ", claimAmountError);
  const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
  const REGION = "ap-south-1";
  const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
  const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
  // console.log(
  //   "s3Bucket 0001",
  //   process.env.REACT_APP_S3_BUCKET,
  //   process.env.REACT_APP_ACCESS_KEY,
  //   process.env.REACT_APP_SECRET_ACCESS_KEY
  // );
  const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  };
  const handleUpload = async (file) => {
    //console.log("handleUpload01", file);
    uploadFile(file, config)
      .then((data) => console.log(""))
      .catch((err) => console.error("handleError", err));
  };
  useEffect(() => {
    if (isEditdPostSuccess == true) {
      // console.log("handleUpload00", fileList);
      // console.log("filelist", fileList);

      // https://fileuploadbucketdotnet.s3.ap-south-1.amazonaws.com/filename.jpg
      for (let i = 0; i < fileList?.length; i++) {
        const mynewFile = new File(
          [fileList[i]],
          `${location?.state?.response?.expId}_${fileList[
            i
          ].name.toLowerCase()}`
        );
        handleUpload(mynewFile);
        // let obj = {
        //   expenseId: postCreateExpenseFormData.expId,
        //   file: fileList[i],
        // };
        // dispatch(postFileUploadData(obj));
      }
      //   fileList.map((fil) =>
      //   {
      //     dispatch(postFileUploadData(obj))});
      // }
    }
  }, [isEditdPostSuccess]);
  useEffect(() => {
    // const obj = { entityId: ssoEmpIdDetails?.entityId };
    // dispatch(getPurposeData(obj));
    if (
      delEmpIDRed != null &&
      delEmpIDRed != "" &&
      delEmpIDRed != ssoEmpIdDetails?.empId &&
      ssoEmpIdDetails != null &&
      ssoEmpIdDetails != ""
    ) {
      let tempUser = myDelegateesData?.filter(
        (item) => item.empId == delEmpIDRed
      );
      //console.log("tempUser ABCD81", tempUser[0]?.entity);
      let obj = {
        // pId: purpose.purposeId,
        entityId: tempUser[0]?.entityId,
      };
      dispatch(getPurposeData(obj));
    } else {
      //console.log("in else ABCD82", ssoEmpIdDetails?.entityId);
      let obj = {
        //pId: purpose.purposeId,
        entityId: ssoEmpIdDetails?.entityId,
      };
      dispatch(getPurposeData(obj));
      // }
    }
    dispatch(
      getCurrencyData(
        delEmpIDRed != null &&
          delEmpIDRed != "" &&
          delEmpIDRed != ssoEmpIdDetails?.empId
          ? delEmpIDRed
          : ssoEmpIdDetails?.empId
      )
    );

    // dispatch(
    //   getEmployeeProjectsData(
    //     delEmpIDRed != null &&
    //       delEmpIDRed != "" &&
    //       delEmpIDRed != ssoEmpIdDetails?.empId
    //       ? delEmpIDRed
    //       : ssoEmpIdDetails?.empId
    //   )
    // );
    dispatch(
      getAttendeesData(
        delEmpIDRed != null &&
          delEmpIDRed != "" &&
          delEmpIDRed != ssoEmpIdDetails?.empId
          ? delEmpIDRed
          : ssoEmpIdDetails?.empId
      )
    );
    dispatch(getMyDelegateesData(ssoEmpIdDetails?.empId));
    dispatch(getEmpDetail(account?.username));
    //dispatch(getCategoryData());
    if (window.performance && refreshModuleToggle == null) {
      //console.log("window.performance works fine on this browser RELOAD1",window.screen.availWidth);
      window.screen.availWidth > 600 && history.push("/expense-Management");
    }
  }, []);
  useEffect(() => {
    if (CurrencyList !== null) {
      const filterdCurrency = CurrencyList?.filter(
        (value) => value.selected === 1
      );
      if (filterdCurrency?.length > 0) {
        setCurrency(filterdCurrency[0]);
      }
      // console.log("filterdcurrency-->",filterdCurrency)
      if (location?.state?.response?.currencyChanged) {
        let changedCurrency = CurrencyList?.find(
          (value) =>
            value.currencyCode === location?.state?.response?.currencyCode
        );
        //console.log("changedcurrency",changedCurrency)
        setCurrency(changedCurrency);
      }
    }
  }, [CurrencyList]);

  useEffect(() => {
    //console.log("fromDate", fromDate, "toDate", toDate);
    if (toDate != null && fromDate != null) {
      const diffInDates = (toType - fromType) / (1000 * 60 * 60 * 24);
      const noOfMonths = Math.round(diffInDates / 30);
      //console.log("diff2", diffInDates, noOfMonths);
      setNoOfMon(noOfMonths);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    if (myDelegateesData != null && myDelegateesData != undefined) {
      let tempDel = myDelegateesData.filter((del) => del.empId == delEmpIDRed);
      setDelEmpEntityId(tempDel[0]?.entityId);
    }
  }, [delEmpIDRed, myDelegateesData]);
  //console.log("delEmpEntityId EDITEXP1", delEmpEntityId);
  useEffect(() => {
    if (purpose !== "") {
      // console.log(
      //   "puprose1 ABCD80",
      //   ssoEmpIdDetails,
      //   purpose.purposeId,
      //   delEmpIDRed,
      //   myDelegateesData
      // );
      if (
        delEmpIDRed != null &&
        delEmpIDRed != "" &&
        delEmpIDRed != undefined &&
        delEmpIDRed != ssoEmpIdDetails?.empId &&
        ssoEmpIdDetails != "" &&
        ssoEmpIdDetails != undefined &&
        myDelegateesData != null &&
        myDelegateesData != "" &&
        myDelegateesData != undefined
      ) {
        let tempUser = myDelegateesData?.filter(
          (item) => item.empId == delEmpIDRed
        );
        //console.log("tempUser ABCD81", tempUser[0]?.entity);
        let obj = {
          pId: purpose?.purposeId,
          entity: tempUser[0]?.entity,
        };
        dispatch(getCategoryData(obj));
      } else {
        //console.log("in else ABCD82", ssoEmpIdDetails?.entity);
        let obj = {
          pId: purpose?.purposeId,
          entity: ssoEmpIdDetails?.entity,
        };
        dispatch(getCategoryData(obj));
      }
    }
    if (
      ssoEmpIdDetails != null &&
      ssoEmpIdDetails != undefined &&
      ssoEmpIdDetails != ""
    ) {
      //console.log("11110000-1 purpose, ssoEmpIdDetails");
      handleDateChange(
        moment(myExpenseList?.ExpenseDetail[0]?.ReceiptDate).toDate(),
        moment(myExpenseList?.ExpenseDetail[0]?.ReceiptDate).format("l")
      );
    }
  }, [purpose, ssoEmpIdDetails, myDelegateesData]);

  // useEffect(() => {
  //   console.log("cyrrenfcydshdfnm ");
  //   if (CurrencyList !== null) {
  //     const filterdCurrency = CurrencyList?.filter(
  //       (value) => value.selected === 1
  //     );
  //     if (filterdCurrency?.length > 0) {
  //       setCurrency(filterdCurrency[0]);
  //     }
  //   }
  // }, [CurrencyList]);

  useEffect(() => {
    if (validateAttendeesList) {
      setEmpAmounts(validateAttendeesList[0]);
    }
  }, [validateAttendeesList]);

  const [count, setCount] = useState(0);
  useEffect(() => {
    if (
      expenseCategory != null &&
      expenseCategory != "" &&
      expenseCategory != undefined
    ) {
      // console.log(
      //   "cyrrenfcydshdfnmbdm1,",
      //   expenseCategory,
      //   noOfMon,
      //   selectedDate
      // );

      // console.log("11110000-1 expenseCategory", count);
      if (count == 0) {
        handleDateChange(
          moment(myExpenseList?.ExpenseDetail[0]?.ReceiptDate).toDate(),
          moment(myExpenseList?.ExpenseDetail[0]?.ReceiptDate).format("l")
        );
        setCount(1);
      }
      //console.log("11110000-2 expenseCategory", count);
      const obj = {
        expenseID: expenseCategory?.categoryId,
        empId:
          delEmpIDRed != null &&
          delEmpIDRed != "" &&
          delEmpIDRed != ssoEmpIdDetails?.empId
            ? delEmpIDRed
            : ssoEmpIdDetails?.empId,
      };
      dispatch(getDependentsData(obj.expenseID));
      //newlychanged
      //dispatch(getValidateAttendeesData(obj));
      //newlychanged
      if (selectedDate != null) {
        const obj2 = {
          expenseID: expenseCategory?.categoryId,
          empId:
            delEmpIDRed != null &&
            delEmpIDRed != "" &&
            delEmpIDRed != ssoEmpIdDetails?.empId
              ? delEmpIDRed
              : ssoEmpIdDetails?.empId,
          expid: location?.state?.response?.expId,
          recieptDate: selectedDate ? selectedDate : null,
        };
        dispatch(getValidateAttendeesData(obj2));
      }

      dispatch(getPaymentData(obj));
    }
    if (
      expenseCategory != null &&
      expenseCategory != "" &&
      expenseCategory != undefined &&
      togglePrevProjectList == 0
    ) {
      if (
        delEmpIDRed != null &&
        delEmpIDRed != "" &&
        delEmpIDRed != ssoEmpIdDetails?.empId
      ) {
        // console.log(
        //   "2 inside the projectData delEmpIDRed",
        //   delEmpIDRed,
        //   expenseCategory?.categoryId
        // );

        const obj = { empId: delEmpIDRed, catId: expenseCategory?.categoryId };
        dispatch(getEmployeeProjectsData(obj));

        setTogglePrevProjectList(togglePrevProjectList + 1);
      } else if (ssoEmpIdDetails != null) {
        // console.log("3 inside the projectData delEmpIDRed");
        const obj = {
          empId: ssoEmpIdDetails?.empId,
          catId: expenseCategory?.categoryId,
        };
        dispatch(getEmployeeProjectsData(obj));
        dispatch(getDependentsData(obj.catId));
        setTogglePrevProjectList(togglePrevProjectList + 1);
      }
    }

    // if (
    // expenseCategory != null &&
    //   expenseCategory != "" &&
    //   expenseCategory != undefined;
    // ) {
    //   let obj = {
    //     empId: ssoEmpIdDetails?.empId,
    //     catId: expenseCategory?.categoryId,
    //   };
    //   dispatch(getEmployeeProjectsData(obj));
    // }
  }, [expenseCategory]);
  //newlychanged
  useEffect(() => {
    //console.log("cyrrenfcydshdfnm bdm2", expenseCategory);
    if (expenseCategory != "" && selectedDate != null) {
      // console.log(
      //   "cyrrenfcydshdfnmbdm1 bdm2",

      //   expenseCategory,
      //   selectedDate
      // );
      const obj = {
        expenseID: expenseCategory?.categoryId,
        empId:
          delEmpIDRed != null &&
          delEmpIDRed != "" &&
          delEmpIDRed != ssoEmpIdDetails?.empId
            ? delEmpIDRed
            : ssoEmpIdDetails?.empId,
        expid: location?.state?.response?.expId,
        recieptDate: selectedDate ? selectedDate : null,
      };
      dispatch(getValidateAttendeesData(obj));
    }
  }, [selectedDate]);
  //newlychanged
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    validatonMsg.map((value) => {
      if (value.type === "purpose") {
        setPurposeError(value.message);
      } else if (value.type === "expense Category") {
        setExpenseCategoryError(value.message);
      } else if (value.type === "expense Name") {
        setExpenseNameError(value.message);
      } else if (value.type === "Reciept Date") {
        setSelectDateError(value.message);
      } else if (value.type === "Project Id") {
        setProjectError(value.message);
      } else if (value.type === "Currency") {
        setCurrencyError(value.message);
      } else if (value.type === "Bill Amount") {
        setBillAmountError(value.message);
      } else if (value.type === "Claim Amount") {
        //console.log("Claim Amount Error:value.message: ====> ", value.message);
        setClaimAmountError(value.message);
      } else if (value.type === "Vendor Name") {
        setVendorNameError(value.message);
      } else if (value.type === "GST") {
        setGstError(value.message);
      } else if (value.type === "Bill Number") {
        setBillNumberError(value.message);
      } else if (value.type === "Attendees") {
        setAttendeesError(value.message);
      } else if (value.type === "Drag Drop File") {
        setDragDropFileError(value.message);
      } else if (value.type === "Project And Split Box") {
        setProjectListSplitBoxError(value.message);
      } else if (value.type === "Declaration CheckBox") {
        setDeclarationError(value.message);
      } else if (value.type === "Payment") {
        setPaymentError(value.message);
      } else if (value.type === "For Month") {
        setForMonthError(value.message);
      } else if (value.type === "From Date") {
        setFromDateError(value.message);
      } else if (value.type === "To Date") {
        setToDateError(value.message);
      } else if (value.type === "From Location") {
        setFromLocationError(value.message);
      } else if (value.type === "To Location") {
        setToLocationError(value.message);
      } else if (value.type === "Distance In Between") {
        setInBetweenDistanceError(value.message);
      } else if (value.type === "Quantity") {
        setQuantityError(value.message);
      }
    });
  }, [validatonMsg]);

  const handleAttendees = (value) => {
    setAttendeesError("");
    setAttendees(value);
  };
  //console.log("attenseed", attendees);
  // console.log(
  //   "getValidateAttendeesData attendees: >>>>>>>>>>>>",
  //   validateAttendeesList
  // );

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  const handleOpenToolTip = () => {
    setOpenToolTip(true);
  };
  const handleCloseToolTip = () => {
    setOpenToolTip(false);
  };
  const openPopOver = Boolean(anchorEl);
  function truncateString(str, num) {
    if (str?.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }

  const projectData = (e) => {
    // console.log("1 inside the projectData delEmpIDRed");
    // if (
    //   e.target.value.categoryId == 1 ||
    //   e.target.value.categoryId == 56 ||
    //   expenseCategory?.categoryId == 1 ||
    //   expenseCategory?.categoryId == 56 ||
    //   oldExpId == 1 ||
    //   oldExpId == 56
    // ) {
    // console.log(
    //   "2 inside the projectData delEmpIDRed",
    //   delEmpIDRed,
    //   e.target.value.categoryId
    // );
    if (
      delEmpIDRed != null &&
      delEmpIDRed != "" &&
      delEmpIDRed != ssoEmpIdDetails?.empId
    ) {
      const obj = { empId: delEmpIDRed, catId: e.target.value.categoryId };
      dispatch(getEmployeeProjectsData(obj));
      setCountExpCatClick(countExpCatClick + 1);
      setExpenseCategoryChanged(true);
      setToggleProjectListDispatch(true);
    } else if (ssoEmpIdDetails != null) {
      // console.log(
      //   "3 inside the projectData delEmpIDRed",
      //   delEmpIDRed,
      //   e.target.value.categoryId
      // );
      const obj = {
        empId: ssoEmpIdDetails.empId,
        catId: e.target.value.categoryId,
      };
      dispatch(getDependentsData(obj.catId));
      dispatch(getEmployeeProjectsData(obj));
      setCountExpCatClick(countExpCatClick + 1);
      setExpenseCategoryChanged(true);
      setToggleProjectListDispatch(true);
    }
    // }

    // console.log("employeeProjectsList", employeeProjectsList);
    // console.log("countExpCatClick1", countExpCatClick);
  };
  const preventMinus = (e) => {
    if (e.code === "Minus" || e.key === "-") {
      e.preventDefault();
    }
  };
  const preventMinus1 = (e) => {
    if (e.code === "Minus" || e.key === "-" || e.key == ".") {
      e.preventDefault();
    }
  };
  const handleKeyDown = (e) => {
    if ((e.key === "-" || e.key === " ") && e.target.selectionStart === 0) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (
      employeeProjectsList != null &&
      employeeProjectsList != undefined &&
      countExpCatClick != 0
    ) {
      //console.log("inedit 4444");
      setSelectedProjects([
        {
          projectName: { projectID: "", projName: "", projId: "" },
          splitAmount: claimAmount ? claimAmount : "",
          billable: 0,
        },
      ]);
    }
  }, [employeeProjectsList, countExpCatClick]);

  //console.log("expnscat 1", expenseCategory);
  const matches = useMediaQuery("(max-width:600px)");

  const gridCond = (
    <Grid container spacing={2}>
      {" "}
      <Grid item xs={5} sm={4} md={4}>
        <Grid item>
          <NumericFormat
            style={{
              margin: "16px 0 8px 0",
              backgroundColor: "#FFFFFF",
              marginLeft: "12px",
            }}
            inputProps={{ maxLength: 10 }}
            decimalScale={2}
            id="outlined-basic"
            label="Bill Amount*"
            onKeyPress={preventMinus}
            variant="outlined"
            fullWidth
            className={
              billAmountError != ""
                ? classes.formControl12
                : classes.formControl
            }
            value={billAmount}
            onChange={(e) => {
              setBillAmountError("");
              setBillAmount(
                // (DOMPurify.sanitize(e.target.value))
                DOMPurify.sanitize(Number(e.target.value.replaceAll(",", "")))
              );
            }}
            helperText={billAmountError !== "" ? billAmountError : ""}
            // thousandSeparator={true}
            customInput={TextField}
            FormHelperTextProps={{
              classes: {
                root: classes.errorRoot,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={5} sm={4} md={4}>
        <Grid item>
          <NumericFormat
            style={{
              margin: "16px 29px 8px 29px",
              backgroundColor: "#FFFFFF",
            }}
            inputProps={{ maxLength: 12 }}
            decimalScale={2}
            id="outlined-basic"
            label="Claim Amount*"
            onKeyPress={preventMinus}
            variant="outlined"
            fullWidth
            className={
              claimAmountError != ""
                ? classes.formControl12
                : classes.formControl
            }
            value={claimAmount}
            onChange={(e) => {
              setClaimAmountError("");
              handleClaimAmount(DOMPurify.sanitize(e.target.value));
            }}
            helperText={claimAmountError !== "" ? claimAmountError : ""}
            // thousandSeparator={true}
            customInput={TextField}
            {...materialUITextFieldProps2}
            FormHelperTextProps={{
              classes: {
                root: classes.errorRoot,
              },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
  const gridNormal = (
    <>
      {" "}
      <Grid item xs={4} sm={4} md={4}>
        <Grid item>
          <NumericFormat
            style={{
              margin: "16px 0 8px 0",
              backgroundColor: "#FFFFFF",
            }}
            inputProps={{ maxLength: 12 }}
            decimalScale={2}
            id="outlined-basic"
            label="Bill Amount*"
            onKeyPress={preventMinus}
            variant="outlined"
            fullWidth
            className={
              billAmountError != ""
                ? classes.formControl12
                : classes.formControl
            }
            value={billAmount}
            onChange={(e) => {
              setBillAmountError("");
              // setBillAmount(
              //   //(DOMPurify.sanitize(e.target.value))
              //   DOMPurify.sanitize(Number(e.target.value.replaceAll(",", "")))
              // );
              handleBillAmount(DOMPurify.sanitize(e.target.value));
            }}
            helperText={billAmountError !== "" ? billAmountError : ""}
            thousandSeparator={true}
            customInput={TextField}
            FormHelperTextProps={{
              classes: {
                root: classes.errorRoot,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={4} sm={4} md={4}>
        <Grid item>
          <NumericFormat
            style={{
              margin: "16px 0 8px 0",
              backgroundColor: "#FFFFFF",
            }}
            inputProps={{ maxLength: 12 }}
            decimalScale={2}
            id="outlined-basic"
            label="Claim Amount*"
            onKeyPress={preventMinus}
            variant="outlined"
            fullWidth
            className={
              claimAmountError != ""
                ? classes.formControl12
                : classes.formControl
            }
            value={claimAmount}
            onChange={(e) => {
              setClaimAmountError("");
              handleClaimAmount(DOMPurify.sanitize(e.target.value));
            }}
            helperText={claimAmountError !== "" ? claimAmountError : ""}
            thousandSeparator={true}
            customInput={TextField}
            {...materialUITextFieldProps2}
            FormHelperTextProps={{
              classes: {
                root: classes.errorRoot,
              },
            }}
          />
        </Grid>
      </Grid>{" "}
    </>
  );
  return (
    <>
      <Box>
        <Header
          heading={"Edit Expense"}
          ssoEmpId={updatedSSOEmpId ? updatedSSOEmpId : null}
          disableAccOptions={1}
        />
      </Box>
      {matches ? (
        <Box
          style={{
            marginBottom: "-47px",
            backgroundColor: "#F8F9FF",
          }}
        >
          <IconButton>
            <ArrowBackIosIcon
              onClick={() => {
                history.push("/expense-Management");
              }}
            />
          </IconButton>
        </Box>
      ) : (
        ""
      )}
      {matches ? (
        <Typography
          style={{
            color: "#081F32",
            fontSize: "16px",
            fontWeight: 600,
            fontFamily: "Montserrat",
            paddingLeft: "48px",
            backgroundColor: "#F8F9FF",
            lineHeight: "41px",
            paddingTop: "1px",
          }}
        >
          Edit Expense
        </Typography>
      ) : (
        ""
      )}
      <Divider />
      <Box className={matches ? classes.rootMobile : classes.root}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {matches ? (
            ""
          ) : (
            <Box
              style={{
                paddingRight: "20px",
                marginLeft: "-70px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton>
                <ArrowBackIosIcon
                  onClick={() => {
                    history.push("/expense-Management");
                  }}
                />
              </IconButton>
            </Box>
          )}
          <Divider />
          <Box className={classes.expenseHeading}>
            <Typography
              style={{
                fontWeight: "600",
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontSize: matches ? "14px" : "24px",
                lineHeight: matches ? "18px" : "24px",
                letterSpacing: "0px",
                textAlign: "left",
                color: "#2B2D30",
                marginLeft: matches ? "-2px" : "0px",
              }}
            >
              Expense Report ({location?.state?.response?.expCode})
            </Typography>
            {isEditdPostSuccess && postEditDataList?.expStatus == "Draft" ? (
              <EditSnackbars
                handleSnackbar={handleSnackbar}
                //handleOpenESBox={handleOpenESBox}
                handlecloseSnackbar={handlecloseSnackbar}
                expenseStatus={postEditDataList?.expStatus}
                open={openESB}
                expenseCode={postEditDataList?.expenseCode}
              />
            ) : isEditdPostSuccess &&
              postEditDataList?.expStatus == "In Progress" ? (
              <EditSnackbars
                handleSnackbar={handleSnackbar}
                // handleOpenESBox={handleOpenESBox}
                handlecloseSnackbar={handlecloseSnackbar}
                expenseStatus={postEditDataList?.expStatus}
                open={openESB}
                expenseCode={postEditDataList?.expenseCode}
              />
            ) : (
              ""
            )}
          </Box>
        </Box>
        <Box>
          <Box>
            <Grid
              container
              spacing={matches ? 2 : 4}
              className={
                matches ? classes.rowContainerMobile : classes.rowContainer
              }
            >
              <Grid item xs={matches ? 11 : 6} sm={6} md={6}>
                <Grid item>
                  <FormControl
                    variant="outlined"
                    className={
                      purposeError != ""
                        ? classes.formControl12
                        : classes.formControl
                    }
                    style={{ marginTop: matches ? "5px" : "" }}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Purpose*
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={purpose}
                      // || purposeList?.find((item)=> item.purpose == location.state.purpose)}
                      className={
                        matches ? classes.inputLabels1 : classes.inputLabels
                      }
                      onChange={(e) => {
                        setExpenseCategory("");
                        setPurposeError("");
                        setPurpose(e.target.value);
                        setDependents("");
                        setToggleExpenseCategory(false);
                        setOldExpid(
                          expenseCategory ? expenseCategory?.categoryId : 0
                        );
                        setSelectedDate(null);
                        setFromDate(null);
                        setToDate(null);
                        setForMonth(null);
                        setFromLocation("");
                        setToLocation("");
                        setInBetweenDistance(0);
                        setVendorName(null);
                        setGST(null);
                        setBillNumber(null);
                        setQuantity("");
                        setAttendees([]);
                        handlepurpose(e.target.value);
                      }}
                      label="Purpose*"
                      MenuProps={{
                        getContentAnchorEl: null,
                      }}
                    >
                      {purposeList?.map((item) => {
                        return (
                          <MenuItem
                            key={item.purposeId}
                            value={item}
                            style={{ fontSize: matches ? "13px" : "" }}
                          >
                            <Typography
                              style={{
                                fontFamily: "Montserrat",
                                fontSize: matches ? "13px" : "",
                                fontWeight: matches ? 500 : "",
                                letterSpacing: matches ? "-1px" : "",
                                textAlign: "left",
                              }}
                            >
                              {item.purpose}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText className={classes.errorText}>
                      {purpose === "" ? purposeError : ""}
                    </FormHelperText>
                  </FormControl>{" "}
                </Grid>
              </Grid>
              <Grid item xs={matches ? 11 : 6} sm={6} md={6}>
                <Grid item>
                  <FormControl
                    variant="outlined"
                    className={
                      expenseCategoryError != ""
                        ? classes.formControl12
                        : classes.formControl
                    }
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Expense Category*
                    </InputLabel>
                    <Select
                      style={{ borderRadius: 4 }}
                      className={
                        matches ? classes.inputLabels1 : classes.inputLabels
                      }
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      defaultValue=""
                      label={"Expense Category*"}
                      value={expenseCategory}
                      onChange={(e) => {
                        //console.log("catergry value", e.target);
                        setSelectDateError("");
                        setExpenseCategoryError("");
                        setExpenseCategory(e.target.value);
                        // setExpenseCategoryChanged(true);
                        setDependents("");
                        setSelectedDate(null);
                        setFromDate(null);
                        setToDate(null);
                        setForMonth(null);
                        setFromLocation("");
                        setToLocation("");
                        setInBetweenDistance(0);
                        setVendorName("");
                        setGST("");
                        setBillNumber("");
                        setQuantity("");
                        //setAttendees([]);
                        projectData(e);
                        // dispatch(
                        //   getEmployeeProjectsData(
                        //     delEmpIDRed != null &&
                        //       delEmpIDRed != "" &&
                        //       delEmpIDRed != ssoEmpIdDetails?.empId
                        //       ? delEmpIDRed
                        //       : ssoEmpIdDetails?.empId,  e.target? e.target.value.categoryId : ''
                        //   )
                        // );
                        // console.log(
                        //   "expenseCategory",
                        //   expenseCategory.categoryId
                        // );
                      }}
                    >
                      {CategoryList?.map((item) => {
                        return (
                          <MenuItem key={item.categoryId} value={item}>
                            <Typography
                              style={{
                                fontFamily: "Montserrat",
                                fontSize: matches ? "12px" : "",
                                fontWeight: matches ? 500 : "",
                                letterSpacing: matches ? "-1px" : "",
                                textAlign: "left",
                              }}
                            >
                              {item.categoryName}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText className={classes.errorText}>
                      {expenseCategory === "" ? expenseCategoryError : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "-64px",
              minWidth: "104%",
            }}
          >
            <Box
            // onMouseEnter={handlePopoverOpen}
            // onMouseLeave={handlePopoverClose}
            >
              <InfoOutlinedIcon
                style={{ paddingRight: matches ? "14px" : "" }}
                onClick={handlePopoverOpen}
              />
            </Box>
            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{
                paper: classes.paper,
              }}
              open={openPopOver}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              {expenseCategory.policyDescription ? (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    maxWidth: "325px",
                    maxHeight: "248px",
                  }}
                >
                  <Box
                    style={{
                      padding: "16px",
                    }}
                  >
                    <Box>
                      <Typography
                        color="inherit"
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                          fontWeight: "600",
                          lineHeight: "20px",
                          letterSpacing: "0em",
                          textAlign: "left",
                        }}
                      >
                        POLICY DESCRIPTION
                      </Typography>
                    </Box>

                    <Box
                      style={{
                        paddingTop: "4px",
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "16px",
                          letterSpacing: "0em",
                          textAlign: "justify",
                        }}
                      >
                        {truncateString(expenseCategory.policyDescription, 264)}
                        {/* {console.log(
                          "policy desc:====> ",
                          truncateString(expenseCategory.policyDescription, 8)
                        )} */}
                      </Typography>
                      <Box>
                        <Link
                          style={{
                            color: "#0B1941",
                            cursor: "pointer",
                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "50px",
                            letterSpacing: "0px",
                            textAlign: "left",
                            paddingTop: "20px",
                          }}
                          target="_blank"
                          underline="always"
                          onClick={handleOpenDescDialog}
                        >
                          View more
                        </Link>
                        <div>
                          <Dialog
                            onClose={handleCloseDescDialog}
                            aria-labelledby="customized-dialog-title"
                            open={openDescDialog}
                          >
                            <DialogTitleDescDialog
                              id="customized-dialog-title"
                              onClose={handleCloseDescDialog}
                            >
                              Policy Description
                            </DialogTitleDescDialog>
                            <DialogContentDescDialog dividers>
                              <Typography gutterBottom>
                                {expenseCategory.policyDescription}
                              </Typography>
                            </DialogContentDescDialog>
                            <DialogActionsDescDialog>
                              <Button
                                autoFocus
                                onClick={handleCloseDescDialog}
                                color="primary"
                              >
                                Close
                              </Button>
                            </DialogActionsDescDialog>
                          </Dialog>
                        </div>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <CloseIcon onClick={handlePopoverClose} />
                  </Box>
                </Box>
              ) : (
                ""
              )}
            </Popover>
          </Box>
        </Box>
        {expenseCategory?.qtyEnabledfl === "1" && (
          <Box>
            <Grid
              container
              spacing={matches ? 2 : 4}
              className={
                matches ? classes.rowContainerMobile : classes.rowContainer
              }
              style={{ marginTop: "30px" }} //siddhant
            >
              {/* <Grid
                item
                xs={matches ? 12 : 6}
                sm={6}
                md={6}
                style={{ paddingRight: matches ? "38px" : "" }}
              >
                <TextField
                  id="outlined-basic"
                  label="Expense Name*"
                  variant="outlined"
                  style={{
                    backgroundColor: "#FFFFFF",
                    margin: matches ? "12px 0px 8px" : "",
                  }}
                  fullWidth
                  className={
                    expenseNameError != ""
                      ? classes.formControl12
                      : classes.formControl
                  }
                  onChange={(e) => {
                    setExpenseNameError("");
                    setSelectDateError("");
                    setExpenseName(
                      DOMPurify.sanitize(e.target.value.trimStart())
                    );
                  }}
                  helperText={expenseNameError !== "" ? expenseNameError : ""}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.errorRoot,
                    },
                  }}
                />
              </Grid> */}
              {/* <Grid
                item
                xs={matches ? 12 : 6}
                sm={6}
                md={6}
                style={{ paddingRight: matches ? "38px" : "" }}
              >
                {/* <Grid item> */}
              {/* <TextField
                  id="outlined-basic"
                  label="Expense Name*"
                  value={expenseName}
                  variant="outlined"
                  style={{
                    backgroundColor: "#FFFFFF",
                    margin: matches ? "12px 0px 8px" : "",
                  }}
                  fullWidth
                  className={
                    expenseNameError != ""
                      ? classes.formControl12
                      : classes.formControl
                  }
                  onChange={(e) => {
                    setExpenseNameError("");
                    setExpenseName(
                      DOMPurify.sanitize(e.target.value.trimStart())
                    );
                  }}
                  helperText={expenseNameError !== "" ? expenseNameError : ""}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.errorRoot,
                    },
                  }}
                /> */}
              {/* </Grid> */}
              {/* </Grid> */}
              {/* <Grid
                item
                xs={matches ? 12 : 6}
                sm={6}
                md={3}
                style={{ padding: matches ? "0px 23px 0px 23px" : "" }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    inputVariant="outlined"
                    fullWidth
                    className={
                      selectDateError != ""
                        ? classes.formControl12
                        : classes.formControl
                    }
                    style={{
                      backgroundColor: "#FFFFFF",
                      marginLeft: matches ? "-15px" : "0px",
                    }}
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    autoOk={true}
                    disableFuture={true}
                    id="date-picker-inline"
                    label="Invoice/Receipt Date*"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    helperText={
                      selectedDate === null
                        ? selectDateError
                        : validateJoiningDate
                        ? selectDateError
                        : valiDateDate
                        ? selectDateError
                        : validateQuarter
                        ? selectDateError
                        : ""
                    }
                    FormHelperTextProps={{
                      classes: {
                        root: classes.errorRoot,
                      },
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid> */}
              {/* <Grid
                item
                xs={matches ? 12 : 6}
                sm={6}
                md={3}
                style={{ paddingRight: matches ? "38px" : "" }}
              >
                <NumericFormat
                  style={{
                    margin: "16px 0 8px 0",
                    backgroundColor: "#FFFFFF",
                  }}
                  inputProps={{ maxLength: 12 }}
                  decimalScale={2}
                  id="outlined-basic"
                  label="Quantity*"
                  onKeyPress={preventMinus}
                  variant="outlined"
                  fullWidth
                  className={
                    quantityError != ""
                      ? classes.formControl12
                      : classes.formControl
                  }
                  onChange={(e) => {
                    setQuantityError("");
                    handleQuantity(DOMPurify.sanitize(e.target.value));
                  }}
                  helperText={quantityError !== "" ? quantityError : ""}
                  thousandSeparator={true}
                  customInput={TextField}
                  // {...materialUITextFieldProps2}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.errorRoot,
                    },
                  }}
                />
              </Grid> */}
              {/* <Grid item>
          <NumericFormat
            style={{
              margin: "16px 0 8px 0",
              backgroundColor: "#FFFFFF",
            }}
            inputProps={{ maxLength: 12 }}
            decimalScale={2}
            id="outlined-basic"
            label="Quantity*"
            onKeyPress={preventMinus}
            variant="outlined"
            fullWidth
            className={
              quantityError != ""
                ? classes.formControl12
                : classes.formControl
            }
            value={quantity}
            onChange={(e) => {
              setQuantityError("");
              handleQuantity(DOMPurify.sanitize(e.target.value));
            }}
            helperText={quantityError !== "" ? quantityError : ""}
            thousandSeparator={true}
            customInput={TextField}
            // {...materialUITextFieldProps2}
            FormHelperTextProps={{
              classes: {
                root: classes.errorRoot,
              },
            }}
          />
        </Grid> */}
              {/* <Grid
                item
                xs={matches ? 11 : 6}
                sm={6}
                md={6}
                style={{ paddingLeft: matches ? "" : "17px" }}
              >
                <Grid item>
                  <TextField
                    style={{
                      margin: "16px 0 8px 0",
                      backgroundColor: "#FFFFFF",
                    }}
                    id="outlined-basic"
                    label="Bill Number*"
                    variant="outlined"
                    fullWidth
                    className={
                      billNumberError != ""
                        ? classes.formControl12
                        : classes.formControl
                    }
                    onChange={(e) => {
                      setBillNumberError("");
                      setBillNumber(DOMPurify.sanitize(e.target.value));
                    }}
                    helperText={billNumberError !== "" ? billNumberError : ""}
                    FormHelperTextProps={{
                      classes: {
                        root: classes.errorRoot,
                      },
                    }}
                  />
                </Grid>
              </Grid> */}
              <Grid
                item
                xs={matches ? 11 : 6}
                sm={6}
                md={6}
                style={{ paddingLeft: matches ? "" : "17px" }}
              >
                <Grid item>
                  <TextField
                    style={{
                      margin: "16px 0 8px 0",
                      backgroundColor: "#FFFFFF",
                    }}
                    id="outlined-basic"
                    label="Bill Number*"
                    variant="outlined"
                    value={billNumber}
                    // onKeyPress={preventMinus}
                    onKeyDown={handleKeyDown}
                    fullWidth
                    className={
                      billNumberError != ""
                        ? classes.formControl12
                        : classes.formControl
                    }
                    onChange={(e) => {
                      const inputBillNumber = e.target.value.trimStart();

                      setBillNumberError("");
                      setBillNumber(DOMPurify.sanitize(inputBillNumber));
                    }}
                    helperText={billNumberError !== "" ? billNumberError : ""}
                    FormHelperTextProps={{
                      classes: {
                        root: classes.errorRoot,
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={matches ? 11 : 6}
                sm={6}
                md={6}
                //  style={{ paddingLeft: matches ? "" : "17px" }}
                style={{ paddingRight: matches ? "7px" : "" }}
              >
                <NumericFormat
                  style={{
                    margin: "16px 0 8px 0",
                    backgroundColor: "#FFFFFF",
                  }}
                  inputProps={{ maxLength: 12 }}
                  decimalScale={2}
                  id="outlined-basic"
                  label="Quantity*"
                  value={quantity}
                  onKeyPress={preventMinus}
                  onKeyDown={handleKeyDown}
                  variant="outlined"
                  fullWidth
                  className={
                    quantityError != ""
                      ? classes.formControl12
                      : classes.formControl
                  }
                  onChange={(e) => {
                    setQuantityError("");
                    handleQuantity(DOMPurify.sanitize(e.target.value));
                  }}
                  helperText={quantityError !== "" ? quantityError : ""}
                  thousandSeparator={true}
                  customInput={TextField}
                  // {...materialUITextFieldProps2}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.errorRoot,
                    },
                  }}
                />
              </Grid>
              {/* <Grid item xs={matches ? 12 : 6} sm={6} md={3}>
                <Grid item>
                  <TextField
                    style={{
                      margin: "16px 0 8px 0",
                      backgroundColor: "#FFFFFF",
                    }}
                    id="outlined-basic"
                    label="Quantity*"
                    variant="outlined"
                    value={quantity}
                    fullWidth
                    className={
                      quantityError != ""
                        ? classes.formControl12
                        : classes.formControl
                    }
                    onChange={(e) => {
                      setQuantityError("");
                      handleQuantity(DOMPurify.sanitize(e.target.value));
                    }}
                    helperText={quantityError !== "" ? quantityError : ""}
                    FormHelperTextProps={{
                      classes: {
                        root: classes.errorRoot,
                      },
                    }}
                  />
                </Grid>
              </Grid> */}
            </Grid>
          </Box>
        )}
        {expenseCategory?.companyExpenseFlag === 1 && (
          <Box>
            <Grid
              container
              spacing={matches ? 2 : 4}
              className={
                matches ? classes.rowContainerMobile : classes.rowContainer
              }
              style={{ marginTop: "30px" }}
            >
              <Grid item xs={matches ? 11 : 6} sm={6} md={4}>
                <Grid item>
                  <TextField
                    style={{
                      margin: "16px 0 8px 0",
                      backgroundColor: "#FFFFFF",
                    }}
                    id="outlined-basic"
                    label="Vendor Name*"
                    variant="outlined"
                    fullWidth
                    className={
                      vendorNameError != ""
                        ? classes.formControl12
                        : classes.formControl
                    }
                    value={vendorName}
                    onChange={(e) => {
                      setVendorNameError("");
                      setVendorName(DOMPurify.sanitize(e.target.value));
                    }}
                    helperText={vendorNameError !== "" ? vendorNameError : ""}
                    FormHelperTextProps={{
                      classes: {
                        root: classes.errorRoot,
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={matches ? 11 : 6} sm={6} md={4}>
                <Grid item>
                  <TextField
                    style={{
                      margin: "16px 0 8px 0",
                      backgroundColor: "#FFFFFF",
                    }}
                    id="outlined-basic"
                    label="Bill Number*"
                    variant="outlined"
                    value={billNumber}
                    fullWidth
                    className={
                      billNumberError != ""
                        ? classes.formControl12
                        : classes.formControl
                    }
                    onChange={(e) => {
                      setBillNumberError("");
                      setBillNumber(DOMPurify.sanitize(e.target.value));
                    }}
                    helperText={billNumberError !== "" ? billNumberError : ""}
                    FormHelperTextProps={{
                      classes: {
                        root: classes.errorRoot,
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={matches ? 11 : 6} sm={6} md={4}>
                <Grid item>
                  <TextField
                    // style={{ marginLeft: "28px" }}
                    style={{
                      margin: "16px 0 8px 0",
                      backgroundColor: "#FFFFFF",
                    }}
                    id="outlined-basic"
                    label="GST*"
                    variant="outlined"
                    value={gst}
                    fullWidth
                    className={
                      gstError != ""
                        ? classes.formControl12
                        : classes.formControl
                    }
                    onChange={(e) => {
                      setGstError("");
                      setGST(DOMPurify.sanitize(e.target.value));
                    }}
                    helperText={gstError !== "" ? gstError : ""}
                    FormHelperTextProps={{
                      classes: {
                        root: classes.errorRoot,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
        {/* {console.log("checkid3",expenseCategory , purposeList)} */}
        {expenseCategory?.billingCycle == 1 && (
          <Box>
            <Grid
              container
              spacing={matches ? 2 : 4}
              className={
                matches ? classes.rowContainerMobile : classes.rowContainer
              }
              style={{ marginTop: "30px" }}
            >
              <Grid item xs={matches ? 11 : 6} sm={6} md={4}>
                <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      inputVariant="outlined"
                      fullWidth
                      className={
                        fromDateError != ""
                          ? classes.formControl12
                          : classes.formControl
                      }
                      style={{
                        backgroundColor: "#FFFFFF",
                      }}
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      autoOk={true}
                      disableFuture={true}
                      id="date-picker-inline"
                      label="From Date*"
                      value={fromDate}
                      onChange={handleFromDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      helperText={
                        fromDate === null || fromDate == ""
                          ? fromDateError
                          : validateFromDate
                          ? fromDateError
                          : ""
                      }
                      FormHelperTextProps={{
                        classes: {
                          root: classes.errorRoot,
                        },
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid item xs={matches ? 11 : 6} sm={6} md={4}>
                <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      inputVariant="outlined"
                      fullWidth
                      className={
                        toDateError != ""
                          ? classes.formControl12
                          : classes.formControl
                      }
                      style={{
                        backgroundColor: "#FFFFFF",
                      }}
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      autoOk={true}
                      minDate={fromDate}
                      // disableFuture={true}
                      id="date-picker-inline"
                      label="To Date*"
                      value={toDate}
                      onChange={handleToDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      helperText={
                        toDate === null || toDate == ""
                          ? toDateError
                          : validateToDate
                          ? toDateError
                          : ""
                      }
                      FormHelperTextProps={{
                        classes: {
                          root: classes.errorRoot,
                        },
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid item xs={matches ? 11 : 6} sm={6} md={4}>
                <Grid item>
                  <FormControl
                    variant="outlined"
                    className={
                      forMonthError != ""
                        ? classes.formControl12
                        : classes.formControl
                    }
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Claim Month*
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={forMonth}
                      className={
                        matches ? classes.inputLabels1 : classes.inputLabels
                      }
                      onChange={(e) => {
                        setForMonth(e.target.value);
                      }}
                      label="Claim Month*"
                      MenuProps={{
                        getContentAnchorEl: null,
                      }}
                    >
                      {monthList?.map((item) => {
                        return (
                          <MenuItem key={item.monthId} value={item.monthName}>
                            {item.monthName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText className={classes.errorText}>
                      {forMonth === null ? forMonthError : ""}
                    </FormHelperText>
                  </FormControl>{" "}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
        {expenseCategory?.distance == 1 && (
          <Box>
            <Grid
              container
              spacing={matches ? 2 : 4}
              className={
                matches ? classes.rowContainerMobile : classes.rowContainer
              }
              style={{ marginTop: "30px" }}
            >
              <Grid item xs={matches ? 11 : 6} sm={6} md={4}>
                <Grid item>
                  <TextField
                    style={{
                      margin: "16px 0 8px 0",
                      backgroundColor: "#FFFFFF",
                    }}
                    id="outlined-basic"
                    label="From Location*"
                    variant="outlined"
                    fullWidth
                    className={
                      fromLocationError != ""
                        ? classes.formControl12
                        : classes.formControl
                    }
                    value={fromLocation}
                    onChange={(e) => {
                      setFromLocationError("");
                      setFromLocation(
                        DOMPurify.sanitize(e.target.value.trimStart())
                      );
                    }}
                    helperText={
                      fromLocationError !== "" ? fromLocationError : ""
                    }
                    FormHelperTextProps={{
                      classes: {
                        root: classes.errorRoot,
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={matches ? 11 : 6} sm={6} md={4}>
                <Grid item>
                  <TextField
                    style={{
                      margin: "16px 0 8px 0",
                      backgroundColor: "#FFFFFF",
                    }}
                    id="outlined-basic"
                    label="To Location*"
                    variant="outlined"
                    fullWidth
                    value={toLocation}
                    className={
                      toLocationError != ""
                        ? classes.formControl12
                        : classes.formControl
                    }
                    onChange={(e) => {
                      setToLocationError("");
                      setToLocation(
                        DOMPurify.sanitize(e.target.value.trimStart())
                      );
                    }}
                    helperText={toLocationError !== "" ? toLocationError : ""}
                    FormHelperTextProps={{
                      classes: {
                        root: classes.errorRoot,
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={matches ? 11 : 6} sm={6} md={4}>
                <Grid item>
                  <NumericFormat
                    // style={{ marginLeft: "28px" }}
                    style={{
                      margin: "16px 0 8px 0",
                      backgroundColor: "#FFFFFF",
                    }}
                    id="outlined-basic"
                    label="Distance (Km/Miles)*"
                    min="0"
                    onKeyPress={preventMinus1}
                    customInput={TextField}
                    variant="outlined"
                    fullWidth
                    value={inBetweenDistance}
                    className={
                      inBetweenDistanceError != ""
                        ? classes.formControl12
                        : classes.formControl
                    }
                    onChange={(e) => {
                      setInBetweenDistanceError("");
                      setInBetweenDistance(DOMPurify.sanitize(e.target.value));
                    }}
                    helperText={
                      inBetweenDistanceError !== ""
                        ? inBetweenDistanceError
                        : ""
                    }
                    FormHelperTextProps={{
                      classes: {
                        root: classes.errorRoot,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
        {/* {expenseCategory?.qtyEnabledfl !== "1" && ( */}
        <>
          {" "}
          <Box>
            <Grid
              container
              spacing={matches ? 2 : 4}
              className={classes.rowContainer}
              style={{
                marginTop:
                  purpose === 1 && expenseCategory.categoryId === 1
                    ? "5px"
                    : "25px",
              }}
            >
              {/* {expenseCategory?.qtyEnabledfl !== "1" && ( */}
              <>
                {" "}
                <Grid item xs={matches ? 11 : 6} sm={6} md={6}>
                  <Grid item>
                    <TextField
                      id="outlined-basic"
                      label="Expense Name*"
                      value={expenseName}
                      variant="outlined"
                      style={{
                        backgroundColor: "#FFFFFF",
                        marginLeft: matches ? "-15px" : "0px",
                      }}
                      inputProps={{ maxLength: 80 }}
                      fullWidth
                      className={
                        expenseNameError != ""
                          ? classes.formControl12
                          : classes.formControl
                      }
                      onChange={(e) => {
                        setExpenseNameError("");
                        setExpenseName(
                          DOMPurify.sanitize(e.target.value.trimStart())
                        );
                      }}
                      helperText={
                        expenseNameError !== "" ? expenseNameError : ""
                      }
                      FormHelperTextProps={{
                        classes: {
                          root: classes.errorRoot,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </>
              {/* )} */}

              {DependentsList?.length > 0 &&
              DependentsList[0] != " " &&
              purpose?.purpose == "Medical Expenses" ? (
                <>
                  <Grid item xs={matches ? 11 : 3} sm={3} md={3}>
                    <Grid item>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          inputVariant="outlined"
                          fullWidth
                          className={
                            selectDateError != ""
                              ? classes.formControl12
                              : classes.formControl
                          }
                          style={{
                            backgroundColor: "#FFFFFF",
                            marginLeft: matches ? "-15px" : "0px",
                          }}
                          variant="inline"
                          format="MM/dd/yyyy"
                          margin="normal"
                          autoOk={true}
                          disableFuture={true}
                          id="date-picker-inline"
                          label="Invoice/Reciept Date*"
                          value={selectedDate}
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          helperText={
                            selectedDate === null
                              ? selectDateError
                              : validateJoiningDate
                              ? selectDateError
                              : valiDateDate
                              ? selectDateError
                              : validateQuarter
                              ? selectDateError
                              : ""
                          }
                          FormHelperTextProps={{
                            classes: {
                              root: classes.errorRoot,
                            },
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={matches ? 11 : 3}
                    sm={3}
                    md={3}
                    style={{
                      paddingLeft: matches ? "" : "17px",
                      marginRight: matches ? "27px" : "",
                    }}
                  >
                    <Grid item>
                      <FormControl
                        variant="outlined"
                        className={
                          dependentsError != ""
                            ? classes.formControl12
                            : classes.formControl
                        }
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Relationship*
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          className={
                            matches ? classes.inputLabels1 : classes.inputLabels
                          }
                          value={dependents}
                          onChange={(e) => {
                            setDependentsError("");
                            setDependents(e.target.value);
                          }}
                          label="Relationship*"
                          MenuProps={{
                            getContentAnchorEl: null,
                          }}
                        >
                          {DependentsList?.map((item) => {
                            return (
                              <MenuItem key={item.dependentId} value={item}>
                                {item.dependent}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText className={classes.errorText}>
                          {dependents == "" ? dependentsError : ""}
                        </FormHelperText>
                      </FormControl>{" "}
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid item xs={matches ? 11 : 6} sm={6} md={6}>
                  {/* {expenseCategory?.qtyEnabledfl !== "1" && ( */}
                  <>
                    {" "}
                    <Grid item>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          inputVariant="outlined"
                          fullWidth
                          className={
                            selectDateError != ""
                              ? classes.formControl12
                              : classes.formControl
                          }
                          style={{
                            backgroundColor: "#FFFFFF",
                            marginLeft: matches ? "-15px" : "0px",
                          }}
                          variant="inline"
                          format="MM/dd/yyyy"
                          margin="normal"
                          autoOk={true}
                          disableFuture={true}
                          id="date-picker-inline"
                          label="Invoice/Reciept Date*"
                          value={selectedDate}
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          helperText={
                            selectedDate === null
                              ? selectDateError
                              : validateJoiningDate
                              ? selectDateError
                              : valiDateDate
                              ? selectDateError
                              : validateQuarter
                              ? selectDateError
                              : ""
                          }
                          FormHelperTextProps={{
                            classes: {
                              root: classes.errorRoot,
                            },
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </>
                  {/* )} */}
                </Grid>
              )}
            </Grid>
          </Box>
        </>
        {/* )} */}

        {expenseCategory && selectedDate != null ? (
          expenseCategory?.policyApplicable == "Y" &&
          (expenseCategory?.exceptionalApproval == 1 ||
            expenseCategory?.frequency == "Monthly") ? (
            <Box>
              <Grid
                container
                className={classes.rowContainer}
                style={{
                  marginTop: "15px",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className={matches ? classes.infoBoxMobile : classes.infoBox}
                >
                  <Grid
                    item
                    xs={matches ? 12 : 9}
                    sm={matches ? 11 : 9}
                    md={matches ? 11 : 10}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        background: "#FFFFFF",
                        border: "1px solid #008CE6",
                        borderRadius: "4px",
                        padding: "8px",
                      }}
                    >
                      <InfoIcon />
                      <Typography
                        style={{
                          color: "#008CE6",
                          paddingLeft: "15px",
                          fontSize: "12px",
                        }}
                      >
                        {/* {console.log(
                          "validateAttendeesList:---------------2:",
                          empAmounts
                        )} */}
                        NOTE: Policy limit for this expense is{" "}
                        {expenseCategory?.currency}
                        {noOfMon != null && noOfMon != 0 && noOfMon != 1
                          ? `${
                              noOfMon * expenseCategory?.policyLimit
                            } ( ${noOfMon} months )`
                          : expenseCategory?.policyLimit}
                        .
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          ) : expenseCategory?.policyApplicable == "Y" &&
            expenseCategory.categoryId !== 11 ? (
            <Box>
              <Grid
                container
                className={classes.rowContainer}
                style={{
                  marginTop: "15px",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className={matches ? classes.infoBoxMobile : classes.infoBox}
                >
                  <Grid
                    xs={matches ? 12 : 9}
                    sm={matches ? 11 : 9}
                    md={matches ? 11 : 10}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        background: "#FFFFFF",
                        border: "1px solid #008CE6",
                        borderRadius: "4px",
                        padding: "8px",
                      }}
                    >
                      <InfoIcon />
                      <Typography
                        style={{
                          color: "#008CE6",
                          paddingLeft: "15px",
                          fontSize: "12px",
                        }}
                      >
                        {/* {console.log(
                          "validateAttendeesList:---------------2:",
                          empAmounts
                        )} */}
                        NOTE: Your eligible amount for the Category is{" "}
                        {expenseCategory?.currency}
                        {empAmounts?.remainingAmount} as you have availed{" "}
                        {expenseCategory?.currency}
                        {empAmounts?.totalAmount} from the policy limit{" "}
                        {expenseCategory?.currency}
                        {expenseCategory?.policyLimit}.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          ) : (
            ""
          )
        ) : (
          ""
        )}

        <Box>
          <Grid
            container
            spacing={matches ? 2 : 4}
            className={
              matches ? classes.rowContainerMobile : classes.rowContainer
            }
            style={{ marginTop: "10px", flexWrap: "nowrap" }}
          >
            <Grid item xs={matches ? 11 : 4} sm={4} md={4}>
              <Grid item>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Currency*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    className={
                      matches ? classes.inputLabels1 : classes.inputLabels
                    }
                    value={currency}
                    style={{ marginRight: matches ? "-6px" : "0px" }}
                    // disabled={true}
                    onChange={(e) => {
                      setCurrency(e.target.value);
                      // console.log(e.target.value);
                    }}
                    label="Currency*"
                  >
                    {CurrencyList?.map((item) => {
                      return (
                        <MenuItem
                          key={item.currencyId}
                          value={item}
                          style={{ fontSize: matches ? "13px" : "" }}
                        >
                          {item.currencyCode}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText className={classes.errorText}>
                    {currency === "" ? currencyError : ""}
                  </FormHelperText>
                </FormControl>{" "}
              </Grid>
            </Grid>
            {matches ? gridCond : gridNormal}
            <Grid item xs={matches ? 11 : 4} sm={4} md={4}>
              <Grid item>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Payment Method*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    className={
                      matches ? classes.inputLabels1 : classes.inputLabels
                    }
                    value={payment}
                    onChange={(e) => {
                      setPayment(e.target.value);
                    }}
                    label="Payment Method**"
                    MenuProps={{
                      getContentAnchorEl: null,
                    }}
                  >
                    {paymentList?.map((item) => {
                      return (
                        <MenuItem key={item.categoryId} value={item}>
                          {item.methodName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText className={classes.errorText}>
                    {payment === "" ? paymentError : ""}
                  </FormHelperText>
                </FormControl>{" "}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid
            container
            spacing={matches ? 2 : 4}
            className={classes.rowContainer}
            style={{
              marginTop: "10px",
            }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Grid item xs={9} sm={9} md={11}>
                <Typography
                  className={
                    matches
                      ? classes.projectDetailsTextMobile
                      : classes.projectDetailsText
                  }
                  style={{
                    fontFamily: matches ? "Montserrat" : "Montserrat",
                    fontSize: matches ? "14px" : "",
                    fontWeight: matches ? "600px" : "",
                  }}
                >
                  Project Details
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid
            container
            className={classes.rowContainer}
            style={{
              marginTop: "15px",
              paddingRight: matches ? "12px" : "",
            }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Grid item>
                <Box>
                  {location?.state?.compName == "editTravel" ? (
                    <SplitBox
                      empProjectList={employeeProjectsList}
                      handleProjectList={handleProjectList}
                      claimAmt={splitClaimAmount}
                      currency={currency?.currency}
                      projectListSplitBoxError={projectListSplitBoxError}
                      setProjectListSplitBoxError={setProjectListSplitBoxError}
                      expenseCategoryChanged={expenseCategoryChanged}
                      toggleExpenseCategoryChanged={setExpenseCategoryChanged}
                    />
                  ) : (
                    <SplitBox
                      empProjectList={employeeProjectsList}
                      handleProjectList={handleProjectList}
                      claimAmt={splitClaimAmount}
                      Edit
                      myExpenseList={myExpenseList}
                      currency={currency?.currency}
                      projectListSplitBoxError={projectListSplitBoxError}
                      setProjectListSplitBoxError={setProjectListSplitBoxError}
                      expenseCategoryChanged={expenseCategoryChanged}
                      toggleExpenseCategoryChanged={setExpenseCategoryChanged}
                    />
                  )}

                  <Box>
                    <Typography
                      variant="p"
                      style={{
                        fontSize: "13px",
                        color: "red",
                        marginLeft: "14px",
                        marginRight: "14px",
                        marginTop: 4,
                        fontFamily: "Montserrat",
                      }}
                    >
                      {projectListSplitBoxError ? projectListSplitBoxError : ""}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {storedExpenseCategory?.length > 0 && storedExpenseCategory[0] != "" ? (
          <Box>
            <Grid
              container
              className={classes.rowContainer}
              style={{
                marginTop: "15px",
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className={matches ? classes.infoBoxMobile : classes.infoBox}
              >
                <Grid
                  item
                  xs={matches ? 12 : 8}
                  sm={matches ? 11 : 8}
                  md={matches ? 11 : 9}
                >
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column", // Set flexDirection to "column"
                      alignItems: "flex-start",
                      //alignItems: "center",
                      background: "#FFFFFF",
                      border: "1px solid #ED8853",
                      borderRadius: "4px",
                      padding: "8px",
                      //color:"#ED8853"
                    }}
                  >
                    <InfoIcon
                      //className={classes.infoBox1}
                      style={{ fill: "#ED8853" }}
                    />
                    <Grid
                      style={{
                        color: "#ED8853",
                        marginLeft: "36px",
                        marginTop: "-26px",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        lineHeight: "16px",
                        letterSpacing: "0px",
                        fontSize: matches ? "9px" : "12px",
                        textAlign: "left",
                      }}
                    >
                      {storedExpenseCategory[0]}
                    </Grid>
                    {storedExpenseCategory.slice(1).map((notes, index) => (
                      <Grid
                        item
                        key={index}
                        style={{
                          color: "#ED8853",
                          paddingLeft: "50px",
                          paddingTop: "5px",
                          fontFamily: "Montserrat",
                          fontWeight: "600",
                          lineHeight: "16px",
                          letterSpacing: "0px",
                          fontSize: matches ? "9px" : "12px",
                          textAlign: "left",
                        }}
                      >
                        . {notes}
                      </Grid>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        ) : (
          " "
        )}

        {expenseCategory?.multipleAttendes == 1 && uniqueArray !== null ? (
          <Box>
            <Grid
              container
              // className={classes.rowContainer}
              // style={{
              //   marginTop: "15px",
              // }}
            >
              <Grid
                item
                xs={matches ? 11 : 12}
                style={{ justifyContent: "center" }}
              >
                <Grid item>
                  <Box>
                    {expenseCategory?.multipleAttendes == 1 &&
                      uniqueArray !== null && (
                        <AttendeesBox
                          AttendeesList={uniqueArray}
                          handleAttendees={handleAttendees}
                          selectedProjects={selectedProjects}
                          Edit
                          myExpenseList={myExpenseList}
                          attendeesError={attendeesError}
                        />
                      )}
                    <span>
                      <Typography
                        variant="p"
                        style={{ fontSize: "13px" }}
                        className={classes.errorText}
                      >
                        {attendees?.length === 0 &&
                        typeof attendeesError === "string"
                          ? attendeesError
                          : ""}
                      </Typography>
                    </span>
                    {attendeesError?.length > 0 &&
                    typeof attendeesError === "object" &&
                    attendeesError ? (
                      <span>
                        <Typography
                          variant="p"
                          style={{ fontSize: "13px" }}
                          className={classes.errorText}
                        >
                          Policy limit exceeded for:&nbsp;&nbsp;
                          {attendeesError.map((value, key) => {
                            return `${value.empName}(${
                              value.remainingAmount
                            }) ${
                              key + 1 < attendeesError?.length ? ", " : ""
                            } `;
                          })}
                        </Typography>
                      </span>
                    ) : (
                      ""
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        ) : (
          ""
        )}

        <Box style={{ marginTop: "15px" }}>
          <Grid
            container
            spacing={matches ? 2 : 4}
            className={classes.rowContainer}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Grid item>
                <Box>
                  {location.state.compName == "editTravel" ? (
                    <DragDrope handleFileList={handleFileList} />
                  ) : (
                    <DragDrope
                      handleFileList={handleFileList}
                      handleDeleteFileList={handleDeleteFileList}
                      Edit
                      myExpenseList={myExpenseList}
                      handleEditDeleteValidateFile={
                        handleEditDeleteValidateFile
                      }
                    />
                  )}

                  <Box>
                    <Typography
                      variant="p"
                      style={{
                        fontSize: "13px",
                        color: "red",
                        marginLeft: "14px",
                        marginRight: "14px",
                        marginTop: 4,
                        fontFamily: "Montserrat",
                      }}
                    >
                      {dragDropFileError ? dragDropFileError : ""}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid
            container
            className={classes.rowContainer}
            style={{
              marginTop: "40px",
            }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Grid item>
                <Box>
                  <div className={classes.checkBox}>
                    <Checkbox
                      // color="primary"
                      className={
                        declarationCheckBox !== false
                          ? classes.checkBoxRoot
                          : ""
                      }
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      onChange={(e) => {
                        handleCheckBox(e);
                      }}
                    />
                    <Typography
                      className={classes.checkBoxText}
                      style={{
                        fontSize: matches ? "10px" : "",
                        lineHeight: matches ? "12px" : "",
                      }}
                    >
                      I hereby declare that all information given above is true
                      as per my knowledge
                    </Typography>
                  </div>
                  <Box>
                    <Typography
                      variant="p"
                      style={{
                        fontSize: "13px",
                        color: "red",
                        marginLeft: "14px",
                        marginRight: "14px",
                        fontFamily: "Montserrat",
                      }}
                    >
                      {declarationError ? declarationError : ""}
                    </Typography>
                  </Box>
                  <Box
                    className={
                      matches
                        ? classes.saveSubmitButtonMobile
                        : classes.saveSubmitButton
                    }
                  >
                    <Box style={{ paddingRight: "10px" }}>
                      <Button
                        variant="outlined"
                        color="#0B1941"
                        style={{
                          minWidth: "140px",
                          minHeight: "36px",
                          border: "1px solid #0B1941",
                          marginRight: matches ? "15px" : "",
                        }}
                        disabled={disableSubmit}
                        onClick={() => {
                          handleSave();
                        }}
                      >
                        Save
                      </Button>
                    </Box>
                    {expenseCategory?.exceptionalApproval == 1 &&
                    claimAmount != "" &&
                    claimAmount > expenseCategory?.policyLimit ? (
                      <>
                        <Button
                          variant="outlined"
                          style={{
                            minWidth: "140px",
                            minHeight: "36px",
                            background: "#CCCCCC",
                            backgroundColor: "#043365",
                            color: "rgba(255, 255, 255, 0.9)",
                          }}
                          onClick={handleOpenEDB}
                        >
                          Submit
                        </Button>
                        {/* Dialog update start */}
                        <Dialog
                          open={openExceptionalDialog}
                          onClose={handleCloseEDB}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            <Typography
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "24px",
                                fontWeight: 700,
                              }}
                            >
                              Exceptional Approval
                            </Typography>
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              This expense requires exceptional approval. It
                              will go to PM for approval.
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleCloseEDB} color="primary">
                              CANCEL
                            </Button>
                            <Button
                              onClick={() => {
                                handleClick();
                              }}
                              color="primary"
                              autoFocus
                            >
                              OK
                            </Button>
                          </DialogActions>
                        </Dialog>
                        {/* Dialog update end */}
                      </>
                    ) : expenseCategory?.multipleAttendes == 1 &&
                      multipleAttendeesError != "" &&
                      multipleAttendeesError != null ? (
                      <>
                        <Button
                          variant="outlined"
                          style={{
                            minWidth: "140px",
                            minHeight: "36px",
                            background: "#CCCCCC",
                            backgroundColor: "#043365",
                            color: "rgba(255, 255, 255, 0.9)",
                          }}
                          onClick={handleOpenTOB}
                        >
                          Submit
                        </Button>
                        {/* Dialog update start */}
                        <Dialog
                          open={openTeamOutingExceedDialog}
                          onClose={handleCloseTOB}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            <Typography
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "24px",
                                fontWeight: 700,
                              }}
                            >
                              Team Outing Expense
                            </Typography>
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              As per the entered expense, policy limit has been
                              exceeded for the following:
                              {/* {
                                attendeesError.map((value, key) => {
                            return `${value.empName}(${
                              value.remainingAmount
                            }) ${key + 1 < attendeesError?.length ? ", " : ""} `;
                          })
                              } */}
                              {multipleAttendeesError
                                ? multipleAttendeesError.map((att, key) => {
                                    return `${att.empName}(${
                                      att.remainingAmount
                                    }) ${
                                      key + 1 < multipleAttendeesError?.length
                                        ? ", "
                                        : ""
                                    }
                                  `;
                                  })
                                : ""}
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleCloseTOB} color="primary">
                              CANCEL
                            </Button>
                            <Button
                              onClick={() => {
                                handleClick();
                                handleCloseTOB();
                              }}
                              color="primary"
                              autoFocus
                            >
                              OK
                            </Button>
                          </DialogActions>
                        </Dialog>
                        {/* Dialog update end */}
                      </>
                    ) : (
                      <Button
                        variant="outlined"
                        style={{
                          minWidth: "140px",
                          minHeight: "36px",
                          background: "#CCCCCC",
                          backgroundColor: "#043365",
                          color: "rgba(255, 255, 255, 0.9)",
                        }}
                        disabled={disableSubmit}
                        onClick={() => {
                          handleClick();
                        }}
                      >
                        Submit
                      </Button>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default ViewReward;
