import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Badge,
  IconButton,
  Box,
  Typography,
} from "@material-ui/core";
import logo from "../images/incedo-logo.svg";
import AccountOptions from "./accountOptions";
import NotificationsOptions from "./notificationsOptions";
import { useDispatch, useSelector } from "react-redux";
import { getMyDelegateesData } from "../redux/Expense/action";
import { getMyTimesheetDelegateeData } from "../redux/TimeSheet/actionTs";
import {getMyGlobalMobilityDelegateeData} from "../redux/GMCal/gmAction";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import history from "../history";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: "74px",
    height: "19px",
    marginTop: "8px",
  },
  logoMobile: {
    display: "none",
  },
  header: {
    backgroundColor: "white",
  },
  toolbar: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
  },
  logoBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  dashboardText: {
    color: "#252733",
    padding: 5,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "29px",
    letterSpacing: "0.3px",
    paddingLeft: "40px",
  },
  dashboardTextMobile: {
    color: "#252733",
    padding: 13,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "18px",
    letterSpacing: "0.3px",
    paddingLeft: "0px",
    marginLeft: "-30px",
  },
  nameText: {
    color: "Black",
    padding: 10,
  },
  nameText2: {
    color: "Black",
  },
  accountOptions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: 0,
  },
}));

const Header = ({ heading, ssoEmpId, disableAccOptions }) => {
  const classes = useStyles();
  const [employeeCode, setEmployeeCode] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [delEmpName, setDelEmpName] = useState("");
  const [tsDelEmpName, setTsDelEmpName] = useState("");
  const [gMDelEmpName, setGMDelEmpName] =  useState("");
  const [pageModule, setPageModule] = React.useState("");
  const matches = useMediaQuery("(max-width:600px)");
  const [delegateeList, setDelegateeList] = useState(null);
  const dispatch = useDispatch();

  const myDelegateesList = useSelector(
    (state) => state.createExpenseData.getMyDelegateesData
  );
  const myTimesheetDelegateesList = useSelector(
    (state) => state?.timesheetData?.getMyTimesheetDelegateeData
  );

  const myGlobalMobilityDelegateesList = useSelector(
    (state) => state?.gmCalData?.getGlobalMobilityDelegateeData
  );

  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );
  const delEmpIdRedux = useSelector(
    (state) => state.createExpenseData.delEmpId
  );
  const tsDelEmpIdRedux = useSelector(
    (state) => state?.timesheetData?.tsDelEmpId
  );
  const GMDelEmpIdRedux = useSelector(
    (state) => state?.gmCalData?.GMDelEmpId
  );

  //useEffects Start:
  useEffect(() => {
    if (ssoEmpId !== null) {
      dispatch(getMyDelegateesData(ssoEmpId));
      dispatch(getMyTimesheetDelegateeData(ssoEmpId));
      dispatch(getMyGlobalMobilityDelegateeData(ssoEmpId));
      if (ssoEmpIdDetails?.empId != 0 && ssoEmpIdDetails != null) {
        setEmployeeName(ssoEmpIdDetails?.empName);
        setEmployeeCode(ssoEmpIdDetails?.empCode);
      }
    }
  }, [ssoEmpId]);

  useEffect(() => {
    if (ssoEmpIdDetails?.empId != 0 && ssoEmpIdDetails != null) {
      setEmployeeName(ssoEmpIdDetails?.empName);
      setEmployeeCode(ssoEmpIdDetails?.empCode);
    }
  }, [ssoEmpIdDetails]);

  useEffect(() => {
    const tempDel = myDelegateesList?.find(
      (delg) => delg.empId == delEmpIdRedux
    );
    //console.log("in delEmpIdRedux change useEffect HEAD3",delEmpIdRedux,myDelegateesList ? myDelegateesList : "No list");
    setDelEmpName(tempDel?.empName);
  }, [delEmpIdRedux, myDelegateesList]);

  useEffect(() => {
    if(tsDelEmpIdRedux && myTimesheetDelegateesList){
      const tempDel = myTimesheetDelegateesList?.find(
        (delg) => delg?.empId == tsDelEmpIdRedux
      );
      setTsDelEmpName(tempDel?.empName);
    }
  }, [tsDelEmpIdRedux, myTimesheetDelegateesList]);

  useEffect(() => {
    if(GMDelEmpIdRedux && myGlobalMobilityDelegateesList){
      const tempDel = myGlobalMobilityDelegateesList?.find(
        (delg) => delg.empId == GMDelEmpIdRedux
      );
      setGMDelEmpName(tempDel?.empName)
    }
  }, [GMDelEmpIdRedux, myGlobalMobilityDelegateesList]);

  // useEffect(() => {
  //   //console.log("in first useEffectf1 HEAD3");
  //   dispatch(getMyDelegateesData(ssoEmpId));
  // }, []);

  //useEffects End.

  //Functions start:

  //Functions end.
  //console.log("HEAD3 : DelEmpName ", delEmpName);
  //console.log("HEAD1 : delEmpIdRedux ", delEmpIdRedux);
  //console.log("HEAD1 : ssoEmpIdDetails ", ssoEmpIdDetails);
  //console.log("myDelegateesList:==>", myDelegateesList);

  var path = window.location.href;
  var directories = path.split("/");
  var lastDirecotry = directories[directories.length - 1];
  var lastSecondDirectry = directories[directories.length - 2];
  var lastSecondDirectory = directories[directories.length - 2];
  var lastDirectoryBU = directories[directories.length - 2];
  var firstDirectory = directories[directories.length - 2];

  useEffect(() => {
    if (directories && directories?.length === 4) {
      if (
        lastDirecotry === "timesheet-management" ||
        lastDirecotry === "create-Timesheet-Management" ||
        lastDirecotry === "allTimesheetReports"
      ) {
        setPageModule("Timesheet");
      } else if (
        lastDirecotry === "expense-Management" ||
        lastDirecotry === "create-Expense-Management" ||
        lastDirecotry === "allExpenseReports"
      ) {
        setPageModule("Expense");
      } else if (
        lastDirecotry === "gmdashboard" ||
        lastDirecotry === "gmmobility"
      ) {
        setPageModule("GM");
      }
    } else if (directories && directories?.length === 5) {
      if (firstDirectory === "timesheetviewpage") {
        setPageModule("Timesheet");
      } else if (
        firstDirectory === "viewExpenseDetails" ||
        firstDirectory === "editExpenseDetails" ||
        firstDirectory === "editTravelExpenseDetails" ||
        firstDirectory === "deligation"
      ) {
        setPageModule("Expense");
      } else if (
        firstDirectory === "gmbupage" ||
        firstDirectory === "gmmobility"
      ) {
        setPageModule("GM");
      }
    }
  }, [directories]);

  return (
    <>
      {matches ? (
        <></>
      ) : (
        <div>
          <AppBar
            position="static"
            className={classes.header}
            elevation={0}
            variant="outlined"
          >
            <Toolbar className={classes.toolbar}>
              <div className={classes.logoBox}>
                {matches ? (
                  <Box
                    style={{
                      paddingRight: "20px",
                      marginLeft: "-70",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <IconButton>
                      <ArrowBackIosIcon
                        onClick={() => {
                          history.push("/expense-Management");
                        }}
                      />
                    </IconButton>
                  </Box>
                ) : (
                  ""
                )}
                <img
                  src={logo}
                  className={matches ? classes.logoMobile : classes.logo}
                  alt="logo"
                />

                <div
                  className={
                    matches
                      ? classes.dashboardTextMobile
                      : classes.dashboardText
                  }
                >
                  {/* {console.log(
                    "lastDirecotry",
                    lastDirecotry,
                    lastSecondDirectry,
                    typeof lastDirecotry
                  )} */}
                  {lastDirecotry == "gmdashboard" ||
                  lastDirecotry == "gmmobility" ||
                  lastDirectoryBU == "gmbupage"
                    ? "Global Mobility Calculator"
                    : lastDirecotry == "timesheet-management" ||
                      lastSecondDirectry == "timesheetviewpage" ||lastSecondDirectory=="timesheetReports"
                    ? "Timesheet"
                    : heading}
                </div>
              </div>
              <div className={classes.accountOptions}>
                {!matches &&
                ((pageModule === "Expense" && delEmpIdRedux == null || delEmpIdRedux == ssoEmpId) || (pageModule === "Timesheet" && tsDelEmpIdRedux == null || tsDelEmpIdRedux == ssoEmpId)
                  || (pageModule === "GM" && GMDelEmpIdRedux == null || GMDelEmpIdRedux == ssoEmpId)) ? (
                  <span className={classes.nameText}>
                    <Typography
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "20px",
                        letterSpacing: "0.20000000298023224px",
                        textAlign: "right",
                      }}
                    >
                      {employeeName}
                    </Typography>
                  </span>
                ) : (
                  !matches && (
                    <span className={classes.nameText2}>
                      <span className={classes.nameText2}>
                        <Typography
                          style={{
                            fontFamily: "Montserrat",
                            fontSize: "8px",
                            fontWeight: "500",
                            lineHeight: "10px",
                            letterSpacing: "0.20000000298023224px",
                            textAlign: "right",
                          }}
                        >
                          You are Co-accessing :
                        </Typography>
                      </span>
                      <span className={classes.nameText2}>
                        <Typography
                          style={{
                            fontFamily: "Montserrat",
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "20px",
                            letterSpacing: "0.20000000298023224px",
                            textAlign: "right",
                          }}
                        >
                          {delEmpName ? delEmpName : (tsDelEmpName ? tsDelEmpName : (gMDelEmpName ? gMDelEmpName : ""))}
                        </Typography>
                      </span>
                    </span>
                  )
                )}

                <AccountOptions
                  employeeId={ssoEmpId}
                  employeeCode={employeeCode}
                  employeeName={employeeName}
                  myDelegateesList={myDelegateesList}
                  myTimesheetDelegateesList={myTimesheetDelegateesList}
                  myGlobalMobilityDelegateesList={myGlobalMobilityDelegateesList}
                  disableAccOptions={disableAccOptions}
                  delEmpName={delEmpName ? delEmpName : (tsDelEmpName ? tsDelEmpName : (gMDelEmpName ? gMDelEmpName : ""))}
                />
              </div>
            </Toolbar>
          </AppBar>
        </div>
      )}
    </>
  );
};

export default Header;