import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles, styled } from "@material-ui/core/styles";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import Table from "@material-ui/core/Table";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import TableBody from "@material-ui/core/TableBody";
import Divider from "@material-ui/core/Divider";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { NumericFormat } from "react-number-format";
//import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import Pagination from "@material-ui/lab/Pagination";
import SearchIcon from "@material-ui/icons/Search";
import DelegationImage from "../images/delegation_image.png";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";
import Drawer from "@material-ui/core/Drawer";
import { Grid } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { isValid, parse, set } from "date-fns";
import {
  getMyExpenseData,
  deleteMyExpenseData,
  resetExpenseResponse,
  getEmpDetail,
  postApproveRejectExpenseData,
  postFinancialApproveRejectExpenseData,
  getMyExpenseDataTravel,
} from "../redux/Expense/action";
import {
  Box,
  Button,
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  validate,
} from "@material-ui/pickers";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import Tooltip from "@material-ui/core/Tooltip";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useMsal, useAccount } from "@azure/msal-react";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import DeleteDialog from "./deleteDialog";
import moment from "moment";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import MobileFloatingButton from "./mobileFloatingButton";
import ApproveRejectSnackbar from "../components/approveRejectSnackbar";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import OutsideClickHandler from "react-outside-click-handler";
import Badge from "@material-ui/core/Badge";
//import { button } from "aws-amplify";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 13,
    top: 31,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const [setting, setSetting] = React.useState(false);
  const [attachment, setAttachment] = React.useState(false);
  const [projectId, setProjectid] = React.useState(false);
  const [popUp, setPopup] = React.useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState();
  const [currentIndex, setCurrentIndex] = React.useState();
  const history = useHistory();
  const [selected, setSelected] = React.useState([]);

  // const [responsiveResult, setResponsiveResult]=React.useState("")

  const {
    classes,
    onSelectAllClick,
    numSelected,
    rowsPerPage,
    rowCount,
    onRequestSort,
    multiDownload,
    setMultiDownload,
    pageNumber,
    rowVal,
    handleRemoveFilter,
  } = props;

  const dispatch = useDispatch();

  const delEmpIDRedux = useSelector(
    (state) => state.createExpenseData.delEmpId
  );

  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );

  const headCells = [
    {
      id: "expName",
      numeric: false,
      disablePadding: false,
      label: "Expense Name",
    },
    {
      id: "claimedAmount",
      numeric: true,
      disablePadding: false,
      label: "Claim Amount",
    },
    {
      id: "approvedAmount",
      numeric: true,
      disablePadding: false,
      label: "Approved Amount",
    },
    {
      id: "expStatus",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
  ];

  const headCells1 = [
    {
      id: "expName",
      numeric: false,
      disablePadding: false,
      label: "Expense Name",
    },
    {
      id: "claimedAmount",
      numeric: true,
      disablePadding: false,
      label: "Claim Amount",
    },
    {
      id: "approvedAmount",
      numeric: true,
      disablePadding: false,
      label: "Approved Amount",
    },
    {
      id: "expStatus",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
  ];

  const headers = [
    {
      label: "Expense Code",
      key: "expCode",
    },
    {
      label: "Expense Category",
      key: "categoryName",
    },
    {
      label: "To Location",
      key: "toLocation",
    },
    {
      label: "From Location",
      key: "fromLocation",
    },
    {
      label: "Distance (Km/Miles)",
      key: "distance",
    },
    {
      label: "From Date",
      key: "fromDate",
    },
    {
      label: "To Date",
      key: "toDate",
    },
    {
      label: "Claim Month",
      key: "forMonth",
    },

    {
      label: "Expense Name",
      key: "expName",
    },
    {
      label: "Creation Date",
      key: "createdOn",
    },
    {
      label: "Created By",
      key: "createdBy",
    },
    {
      label: "Relationship",
      key: "dependent",
    },
    {
      label: "Currency",
      key: "currencyCode",
    },
    {
      label: "Claimed Amount",
      key: "claimedAmount",
    },
    {
      label: "Bill Amount",
      key: "billAmount",
    },
    {
      label: "Approved Amount",
      key: "approvedAmount",
    },
    {
      label: "Project Code",
      key: "projectId",
    },
    {
      label: "Attachments",
      key: "attachments",
    },
    {
      label: "Comments",
      key: "reportComments",
    },
    {
      label: "Status",
      key: "status",
    },
  ];

  const csvLink = {
    headers: headers,
    data: multiDownload,
    filename: `MyExpense_${moment(new Date()).format("DDMMYYYY_HHmm")}.csv`,
  };

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const handleSetting = (event) => {
    setSetting(!setting);
    setPopup(event.currentTarget);
  };

  const handlePopup = (name, value) => {
    props.getSetting(name, value);
    if (name === "attachment") {
      setAttachment(value);
    } else if (name === "projectid") {
      setProjectid(value);
    }
  };

  const handleDelete = (value) => {
    let tempArray = [];
    tempArray = multiDownload?.filter(
      (element) => element.expId != value.expId
    );
    let obj = {
      expID: value.expId,
      empId: delEmpIDRedux != null ? delEmpIDRedux : ssoEmpIdDetails?.empId,
    };
    dispatch(deleteMyExpenseData(obj));
    setMultiDownload(tempArray);
    dispatch(resetExpenseResponse());
    setSelected([]);
  };

  const handleOpenDeleteDialog = (row) => {
    setOpenDeleteDialog(true);
    setCurrentIndex(row);
  };

  const handleCloseDeleteDialog = (row) => {
    setOpenDeleteDialog(false);
  };

  const matches = useMediaQuery("(max-width:600px)");
  const headCellValue = matches && headCells;

  return (
    <TableHead>
      {multiDownload?.length > 0 && (
        <TableRow style={{ backgroundColor: "#F4F4F4" }}>
          <TableCell style={{ padding: "0px" }} />
          <TableCell
            align="left"
            style={{
              padding: "0px",
              width: "28px",
              minWidth: "28px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {numSelected === rowsPerPage ||
            (pageNumber === Math.ceil(rowCount / rowsPerPage) - 1 &&
              numSelected === rowCount % rowsPerPage) ? (
              <IconButton
                style={{
                  // maxWidth: "40px", maxHeight: "40px",
                  padding: "12px 0px 12.5px",
                }}
              >
                <CheckBoxIcon
                  fontSize="small"
                  onClick={onSelectAllClick}
                  className={classes.checkBoxRoot}
                />
              </IconButton>
            ) : (
              <Checkbox
                style={{ padding: "10px 0px 10.5px" }}
                checkedIcon={
                  <CheckBoxIcon
                    fontSize="small"
                    className={classes.checkBoxRoot}
                  />
                }
                checked={numSelected?.length >= 4}
                onChange={onSelectAllClick}
                inputProps={{ "aria-label": "select all desserts" }}
              />
            )}
          </TableCell>
          {multiDownload.length >= 0 && (
            <>
              {/* <TableRow style={{ backgroundColor: "#F4F4F4" }}> */}

              {/* <TableCell style={{ padding: "0.4%", maxWidth: "100%" }}>
                &nbsp;
              </TableCell> */}
              <TableCell
                align="left"
                style={{ padding: "2px", maxWidth: "100%" }}
              >
                {/* {multiDownload?.length} Selected */}
                <Typography
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    //textOverflow: 'ellipsis',
                    maxWidth: "100%",
                    fontSize: "10px",
                    fontWeight: "600",
                  }}
                >
                  {multiDownload?.length} Selected
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                style={{ padding: "2px", maxWidth: "100%" }}
              >
                {/* <CSVLink {...csvLink} style={{ color: "black" }}>
                  <GetAppIcon />
                </CSVLink> */}
              </TableCell>
              <TableCell
                align="right"
                style={{ padding: "2px", maxWidth: "100%" }}
              >
                {/* {(multiDownload.findIndex((item) => item.status != "In Progress") == -1) && (multiDownload?.length < 2) && 
                {(multiDownload.findIndex((item) => item.status == "Approved" || item.status == "Paid") == -1) &&
                  (multiDownload?.length < 2) &&
                  <EditIcon
                    onClick={() => {
                      const text = "/editExpenseDetails/:id";
                      const result = text.replace(":id", rowVal.expId);
                      history.push({
                        pathname: result,
                        state: {
                          response: rowVal,
                          empId: ssoEmpIdDetails ? ssoEmpIdDetails.empId : " ",
                          compName: "edit",
                        },
                      });
                    }} />} */}
              </TableCell>
              <TableCell
                // align="center"
                style={{ padding: "2px" }}
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  // spacing={3}
                >
                  <Grid item style={{ padding: "5px" }}>
                    <CSVLink {...csvLink} style={{ color: "black" }}>
                      <GetAppOutlinedIcon />
                    </CSVLink>
                  </Grid>
                  <Grid item style={{ padding: "5px" }}>
                    {multiDownload.findIndex(
                      (item) =>
                        item.status == "Approved" || item.status == "Paid"
                    ) == -1 &&
                      multiDownload?.length < 2 && (
                        <EditOutlinedIcon
                          onClick={() => {
                            const text = rowVal?.tFlag
                              ? "/editTravelExpenseDetails/:id"
                              : "/editExpenseDetails/:id";
                            const result = text.replace(":id", rowVal.expId);
                            history.push({
                              pathname: result,
                              state: {
                                response: rowVal,
                                empId: ssoEmpIdDetails
                                  ? ssoEmpIdDetails.empId
                                  : " ",
                                compName: "edit",
                              },
                            });
                          }}
                        />
                      )}
                  </Grid>
                  <Grid item style={{ padding: "5px" }}>
                    {multiDownload.findIndex(
                      (item) =>
                        item.status == "Approved" || item.status == "Paid"
                    ) == -1 &&
                      multiDownload?.length < 2 && (
                        <DeleteOutlinedIcon
                          onClick={() => handleOpenDeleteDialog(rowVal)}
                        />
                      )}
                    <DeleteDialog
                      openDeleteDialog={openDeleteDialog}
                      handleCloseDeleteDialog={handleCloseDeleteDialog}
                      handleDelete={handleDelete}
                      currentExpense={rowVal}
                      handleRemoveFilter={handleRemoveFilter}
                      responsiveResult="mobile"
                    />
                  </Grid>
                </Grid>
                {/* {(multiDownload.findIndex((item) => item.status != "In Progress") == -1) && (multiDownload?.length < 2) &&  */}
              </TableCell>

              {/* </TableRow> */}
            </>
          )}
        </TableRow>
      )}

      {multiDownload?.length == 0 && (
        <TableRow style={{ backgroundColor: "#F4F4F4" }}>
          <TableCell style={{ padding: "0px", width: "20px" }} />
          <TableCell
            padding="checkbox"
            style={{
              minWidth: "28px",
              width: "28px",
              padding: "0px",
              // paddingLeft: "2px",
              display: "flex",
              justifyContent: "center",
            }}
            className={
              attachment == true || projectId == true
                ? classes.stickyLeft
                : classes.nonSticky
            }
          >
            <Checkbox
              style={{ padding: "9px 0px 9.5px" }}
              checkedIcon={
                <CheckBoxIcon
                  fontSize="small"
                  className={classes.checkBoxRoot}
                />
              }
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
          {headCellValue &&
            headCellValue?.map((headCell) => (
              <TableCell
                align={
                  headCell.label === "Status"
                    ? // ||
                      // headCell.label === "Claim Amount"
                      "center"
                    : "left"
                }
                // align="left"
                style={
                  headCell.label === "Status"
                    ? {
                        paddingTop: "0.4%",
                        paddingBottom: "0.4%",
                        paddingLeft: "0.4%",
                        paddingRight: "21px",
                        fontSize: "10px",
                        fontWeight: "600",
                      }
                    : {
                        padding: "0.4%",
                        fontSize: "10px",
                        fontWeight: "600",
                        lineHeight: "1rem",
                      }
                }
              >
                {headCell.label}
              </TableCell>
            ))}
        </TableRow>
      )}
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  myExpenseData: PropTypes.number.isRequired,
  handleRemoveFilter: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  tableContainer: {
    borderRadius: "8px",
    "&.MuiTableCell-root": {
      padding: "0px",
    },
  },

  rootFooter: {
    flexShrink: 0,

    marginLeft: theme.spacing(2.5),
  },
  rootFooter: {
    flexShrink: 0,

    marginLeft: theme.spacing(2.5),
  },
  rootFooter: {
    flexShrink: 0,

    marginLeft: theme.spacing(2.5),
  },
  paper: {
    border: "1px solid #979797",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
  },
  paperZ150: {
    width: "1132px",
    border: "1px solid #979797",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
  },
  paperZ125: {
    width: "1390px",
    border: "1px solid #979797",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
  },
  paperZ100: {
    border: "1px solid #979797",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
  },
  table: {
    "&.MuiTableCell-root": {
      padding: "0px",
    },
  },
  rootMobile: {
    "& .MuiFormLabel-root": {
      //paddingLeft: "11px"
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
  },
  rootMobile1: {
    "& .MuiFormLabel-root": {
      marginLeft: "11px",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#515961",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-input": {
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  headerName: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "700",
    color: "rgba(9, 10, 11, 0.87)",
    alignItems: "center",
  },
  header: {
    background: "#F4F4F4",
    borderRadius: "8px 8px 0px 0px",
    maxWidth: "100%",
    maxHeight: "58px",
  },

  settingIcon: {
    color: "#0B1941",
  },
  dotIcon: {
    color: "#666666",
  },
  status: {
    borderRadius: "100px",
    background: "#349259",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "10px",
    height: "14px",
    textTransform: "uppercase",
    paddingLeft: "10px",
    paddingRight: "10px",
    color: "#FFFFFF",
  },
  filterImg: {
    width: "18px",
  },
  stickyRight: {
    position: "sticky",
    right: 0,
    background: "white",
    zIndex: 800,
  },
  stickyLeft: {
    position: "sticky",
    left: 0,
    background: "white",
    zIndex: 800,
  },
  nonSticky: {
    position: "sticky",
    left: 0,
    background: "#F4F4F4",
    zIndex: 800,
  },
  bulkDownloadText: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "10px",
    height: "16px",
    paddingLeft: "10px",
    color: "#2B2D30",
  },
  tableRow: {
    height: 58,
    "& .MuiTableCell-root": {
      fontSize: "10px",
    },
    "&.MuiTableRow-root.MuiTableRow-hover:hover": {
      backgroundColor: "#EFF2F8",
    },
    "&.MuiTableRow-root.Mui-selected": {
      background: "#EFF2F8",
    },
  },
  bulkDownload: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "10px",
    height: "16px",
    paddingLeft: "10px",
    textTransform: "capitalize",
    color: "#FFFFFF",
  },
  bulkDownloadbtn: {
    minWidth: "128px",
    maxHeight: "32px",
    whiteSpace: "nowrap",
    background: "#0B1941",
    color: "#FFFFFF",
    borderRadius: "4px",
    height: "32px",
    "&:hover": {
      background: "#0B1941",
      boxShadow:
        "0px -1px 5px rgba(89, 122, 105, 0.09), 0px 2px 2px rgba(44, 73, 59, 0.1), 0px 2px 4px rgba(138, 159, 149, 0.2), 0px 3px 5px rgba(89, 151, 118, 0.1)",
    },
  },
  csv: {
    color: "transparent",
  },
  statusBox: {
    display: "flex",
    justifyContent: "center",
  },
  tooltip: {
    paddingLeft: "2px",
    color: "#9E9E9E",
    maxWidth: "20px",
    maxHeight: "20px",
  },

  tooltipBox: {
    minWidth: "250px",
    left: "auto!important",
    right: 0,
    marginTop: "-63px",
  },
  arrow: {
    left: "auto!important",
    right: 0,
  },
  statusHeading: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "8px",
    color: "rgba(255, 255, 255, 0.9)",
  },
  formControl: {
    //width: "350px",
    width: "100%",
    height: "70px",
    marginLeft: "10px",
    "& .MuiFormLabel-root": {
      //paddingLeft: "11px"
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#515961",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-input": {
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
  },
  formControl1: {
    width: "100%",
    height: "70px",
    marginLeft: "9px",
    "& .MuiFormLabel-root": {
      //paddingLeft: "11px"
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#515961",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-input": {
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
  },
  formControl2: {
    width: "100%",
    height: "70px",
    marginLeft: "9px",
    "& .MuiFormLabel-root": {
      //paddingLeft: "11px"
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#515961",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-input": {
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
  },
  formControl3: {
    width: "100%",
    height: "70px",
    marginLeft: "9px",
    marginRight: "-11px",
    "& .MuiFormLabel-root": {
      //paddingLeft: "11px"
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#515961",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-input": {
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
  },
  errorRoot: {
    color: "red",
    marginLeft: "7px",
    marginRight: "0px",
    marginTop: 0,
    fontSize: "10px",
    lineHeight: "11px",
  },
  statusName: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "10px",
    marginRight: "2px",
    color: "rgba(255, 255, 255, 0.85)",
  },
  statusProjectName: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "8px",
    marginTop: "2px",
    color: "rgba(255, 255, 255, 0.9)",
    opacity: "0.6",
  },
  statusMessage: {
    fontFamily: "Montserrat",
    fontStyle: "italic",
    fontWeight: 400,
    fontSize: "8px",
    color: "#FFFFFF",
  },
  resetApplyFilterButtonMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "35px",
    paddingRight: "70px",
    paddingLeft: "57px",
  },
  checkBoxRoot: {
    background: "#0B1941",
    color: "white",
    "&$checked": {
      background: "#0B1941",
      color: "white",
    },
  },
  enhancedTable: {
    "& .MuiTablePagination-toolbar": {
      paddingRight: "28%",
    },
  },
  chipButton: {
    width: "110px",
    height: "24px",
  },
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  const history = useHistory();

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [attachment, setAttachment] = React.useState(false);
  const [projectId, setProjectid] = React.useState(false);
  const [empId, setEmpId] = React.useState(null);
  const [action, setAction] = React.useState(false);
  const [popUpAction, setPopupAction] = React.useState("");
  const [popRow, setPopRow] = React.useState(null);
  const [myExpenseData, setMyExpenseData] = React.useState([]);
  const [multiDownload, setMultiDownload] = React.useState([]);
  const [singleDownload, setSingleDownload] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [commentData, setCommentData] = React.useState([]);
  const [commentHeading, setCommentHeading] = React.useState("");
  const [toolAction, setToolAction] = React.useState("");
  const [currentIndex, setCurrentIndex] = React.useState();
  const [rowVal, setRowVal] = React.useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [expenseNamefilter, setExpenseNamefilter] = useState("");
  const [expenseCategoryfilter, setExpenseCategoryfilter] = useState("");
  const [endDatefilter, setEndDatefilter] = useState(null);
  const [startDatefilter, setStartDatefilter] = useState(null);
  const [startDateMsg, setStartDateMsg] = useState("");
  const [endDateMsg, setEndDateMsg] = useState("");
  const [minClaimAmountfilter, setMinClaimAmountfilter] = useState("");
  const [maxClaimAmountfilter, setMaxClaimAmountfilter] = useState("");
  const [minApprovedAmountfilter, setMinApprovedAmountfilter] = useState("");
  const [maxApprovedAmountfilter, setMaxApprovedAmountfilter] = useState("");
  const [statusFilter, setStatusfilter] = useState(null);
  const [anchorFilter1, setAnchorFilter1] = useState("right");
  const [buttonState, setButtonState] = React.useState(true);
  const [currExp, setCurrExp] = React.useState(null);
  const [filterShow, setFilterShow] = React.useState(false);
  const [countShow, setCountShow] = React.useState(0);
  const [minClaimAmountMsg, setMinClaimAmountMsg] = useState("");
  const [maxClaimAmountMsg, setMaxClaimAmountMsg] = useState("");
  const [minApprovedAmountMsg, setMinApprovedAmountMsg] = useState("");
  const [maxApprovedAmountMsg, setMaxApprovedAmountMsg] = useState("");
  //const buttonState1 = minApprovedAmountfilter === '' || maxApprovedAmountfilter === '';

  const [travelSelected, setTravelSelected] = useState([]);
  const [myExpenseTableTravelData, setMyExpenseTableTravelData] = useState([]);

  const preventMinus = (e) => {
    if (e.code === "Minus" || e.key === "-") {
      e.preventDefault();
    }
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleFieldValue = () => {
    if (
      startDatefilter === null &&
      endDatefilter === null &&
      statusFilter === null &&
      expenseCategoryfilter === "" &&
      expenseNamefilter === "" &&
      minApprovedAmountfilter === "" &&
      maxApprovedAmountfilter === "" &&
      minClaimAmountfilter === "" &&
      maxClaimAmountfilter === ""
    ) {
      return true;
    } else return false;
  };

  const handleEndDateChange = (type, date) => {
    setEndDatefilter(date);

    // if (date === null) {
    //   setEndDateMsg("Please select a start date");
    // } else {

    const dateFormatRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Format: MM/dd/yyyy
    const parsedDate = new Date(date);

    if (!dateFormatRegex.test(date)) {
      setEndDateMsg("Invalid date format.");
    } else if (isNaN(parsedDate.getTime())) {
      setEndDateMsg("Invalid date. Please enter a valid date.");
    } else if (date) {
      setEndDateMsg("");
      if (startDatefilter && startDateMsg.length == 0) {
        setEndDateMsg(""); // Clear the error message if the date is valid
        setStartDateMsg("");
        if (maxClaimAmountMsg.length == 0 && maxApprovedAmountMsg.length == 0) {
          setButtonState(false);
        } else {
          setButtonState(true);
        }
      }
    }
    if (startDatefilter && date && startDateMsg.length == 0) {
      setEndDateMsg(""); // Clear the error message if the date is valid
      setStartDateMsg("");
      if (maxClaimAmountMsg.length == 0 && maxApprovedAmountMsg.length == 0) {
        setButtonState(false);
      } else {
        setButtonState(true);
      }
    }
    // else {
    // }
    //   }
    // }
    // }
  };

  const handleStartDateChange = (type, date) => {
    setStartDatefilter(date);

    const dateFormatRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Format: MM/dd/yyyy

    const parsedDate = new Date(date);

    if (!dateFormatRegex.test(date)) {
      setStartDateMsg("Invalid date format.");
    } else if (isNaN(parsedDate.getTime())) {
      setStartDateMsg("Invalid date. Please enter a valid date.");
    } else if (date) {
      setStartDateMsg("");
      if (endDatefilter && endDateMsg.length == 0) {
        setStartDateMsg(""); // Clear the error message if the date is valid
        setEndDateMsg(""); // Clear the error message if the date is valid
        if (maxClaimAmountMsg.length == 0 && maxApprovedAmountMsg.length == 0)
          setButtonState(false);
        else setButtonState(true);
      }
    }

    //  else {
    // }
    // }
    // }
    // }
    // if (endDatefilter && date) {
    //   setButtonState(false);
    // } else {
    //   setButtonState(true);
    // }
    // if (
    //   startDateMsg.length > 0 ||
    //   endDateMsg.length > 0 ||
    //   maxApprovedAmountMsg.length > 0 ||
    //   maxClaimAmountMsg.length > 0
    // ) {
    //   setButtonState(true);
    // } else {
    //   setButtonState(false);
    // }
  };

  const handleStatus = (type, data1) => {
    setStatusfilter(data1);
    if (
      // (startDatefilter && endDatefilter) ||
      // (startDatefilter == null && endDatefilter == null)
      startDateMsg.length > 0 ||
      endDateMsg.length > 0 ||
      maxClaimAmountMsg.length > 0 ||
      maxApprovedAmountMsg.length > 0
    ) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  };
  const handleCategory = (type, data2) => {
    setExpenseCategoryfilter(data2);
    if (
      // (startDatefilter && endDatefilter) ||
      // (startDatefilter == null && endDatefilter == null)
      startDateMsg.length > 0 ||
      endDateMsg.length > 0 ||
      maxClaimAmountMsg.length > 0 ||
      maxApprovedAmountMsg.length > 0
    ) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  };
  const handleExpenseName = (type, data3) => {
    const trimmedInput = data3.trimStart();
    setExpenseNamefilter(trimmedInput);
    // if (
    //   (startDatefilter && endDatefilter) ||
    //   (startDatefilter == null && endDatefilter == null)
    // ) {
    //   setButtonState(false);
    // } else {
    //   setButtonState(true);
    // }
    if (trimmedInput === "") {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  };

  const handleMinClaimAmount = (type, data3) => {
    const trimmedInput = data3.trimStart();
    setMinClaimAmountfilter(trimmedInput);
    //if (minClaimAmountfilter ==null)
    // (startDatefilter && endDatefilter) ||
    // (startDatefilter == null && endDatefilter == null)
    if (
      trimmedInput === "" ||
      startDateMsg.length > 0 ||
      endDateMsg.length > 0 ||
      maxApprovedAmountMsg.length > 0 ||
      maxClaimAmountMsg.length > 0
    ) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  };
  const handleMaxClaimAmount = (type, data3) => {
    const trimmedInput = data3.trimStart();
    setMaxClaimAmountfilter(trimmedInput);

    if (
      trimmedInput === "" ||
      startDateMsg.length > 0 ||
      endDateMsg.length > 0 ||
      maxApprovedAmountMsg.length > 0 ||
      maxClaimAmountMsg.length > 0
    ) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  };

  const handleMinApprovedAmount = (type, data3) => {
    const trimmedInput = data3.trimStart();
    setMinApprovedAmountfilter(trimmedInput);

    if (
      trimmedInput === "" ||
      startDateMsg.length > 0 ||
      endDateMsg.length > 0 ||
      maxClaimAmountMsg.length > 0 ||
      maxApprovedAmountMsg.length > 0
    ) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  };
  const handleMaxApprovedAmount = (type, data3) => {
    const trimmedInput = data3.trimStart();
    setMaxApprovedAmountfilter(trimmedInput);

    if (
      trimmedInput === "" ||
      startDateMsg.length > 0 ||
      endDateMsg.length > 0 ||
      maxClaimAmountMsg.length > 0 ||
      maxApprovedAmountMsg.length > 0
    ) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  };

  useEffect(() => {
    if (startDatefilter && !endDatefilter) {
      setButtonState(true);
    } else if (endDatefilter && !startDatefilter) {
      setButtonState(true);
    }
  }, [
    minClaimAmountfilter,
    maxClaimAmountfilter,
    minApprovedAmountfilter,
    maxApprovedAmountfilter,
    startDatefilter,
    endDatefilter,
  ]);

  const handleRemoveFilter = () => {
    setMyExpenseData(getMyExpenseList);
    setExpenseNamefilter("");
    setExpenseCategoryfilter("");
    setStatusfilter(null);
    setStartDatefilter(null);
    setEndDatefilter(null);
    setMaxClaimAmountfilter("");
    setMinClaimAmountfilter("");
    setMinApprovedAmountfilter("");
    setMaxApprovedAmountfilter("");
    setFilterShow(false);
    setCountShow(0);
    setStartDateMsg("");
    setEndDateMsg("");
    setMinClaimAmountMsg("");
    setMaxClaimAmountMsg("");
    setMinApprovedAmountMsg("");
    setMaxApprovedAmountMsg("");
    setButtonState(true);
  };

  const handleApplyFilter = () => {
    let tempArray = JSON.parse(JSON.stringify(getMyExpenseList));
    var countVal1 = 0,
      countVal2 = 0,
      countVal3 = 0,
      countVal4 = 0,
      countVal5 = 0,
      countVal6 = 0,
      countVal7 = 0,
      countVal8 = 0;
    //console.log("count3" , countVal3);
    if (expenseNamefilter != "") {
      //console.log("filter123",expenseNamefilter,tempArray)
      tempArray = tempArray.filter((data) =>
        data?.expName?.includes(expenseNamefilter)
      );
      countVal1 = 1;
    }
    //console.log("filter123",tempArray)
    if (expenseCategoryfilter != "") {
      tempArray = tempArray.filter((data) =>
        data?.categoryName?.includes(expenseCategoryfilter)
      );
      countVal2 = 1;
    }
    if (statusFilter != null) {
      //   console.log("filter5",tempArray)
      tempArray = tempArray.filter((data) =>
        data?.status?.includes(statusFilter)
      );
      countVal3 = 1;
    }
    if (startDatefilter != null && endDatefilter != null) {
      var d1 = new Date(startDatefilter).toLocaleDateString();
      var d2 = new Date(endDatefilter).toLocaleDateString();
      //console.log(tempArray)
      tempArray = tempArray.filter(
        (data) =>
          new Date(data.createdOn).toLocaleDateString() >= d1 &&
          new Date(data.createdOn).toLocaleDateString() <= d2
      );
      countVal4 = 1;
    }
    if (minClaimAmountfilter != "") {
      tempArray = tempArray.filter(
        (data) => data.claimedAmount >= minClaimAmountfilter
      );
      countVal5 = 1;
    }
    // else{

    // }
    if (maxClaimAmountfilter != "") {
      tempArray = tempArray.filter(
        (data) => data.claimedAmount <= maxClaimAmountfilter
      );
      countVal6 = 1;
    }
    // else{

    // }
    if (minApprovedAmountfilter != "") {
      tempArray = tempArray.filter(
        (data) => data.approvedAmount >= minApprovedAmountfilter
      );
      countVal7 = 1;
    }
    if (maxApprovedAmountfilter != "") {
      tempArray = tempArray.filter(
        (data) => data.approvedAmount <= maxApprovedAmountfilter
      );
      countVal8 = 1;
    }
    let tempvalcount =
      countVal1 +
      countVal2 +
      countVal3 +
      countVal4 +
      countVal5 +
      countVal6 +
      countVal7 +
      countVal8;
    setCountShow(tempvalcount);
    setMyExpenseData(tempArray);
    setPage(0);
    setButtonState(true);
    setFilterShow(true);
    setState({
      ...state,
      bottom: false,
      top: false,
      right: false,
      left: false,
    });
  };
  const list = (anchor) => (
    <div
      style={{ overflowX: "hidden" }}
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <ArrowBackIosIcon
        onClick={toggleDrawer(anchorFilter1, false)}
        style={{ paddingLeft: "12px", marginTop: "19px" }}
      />
      <span
        style={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "18px",
            marginTop: "-25px",
            marginLeft: "-224px",
            // display:"flex",
            // justifyContent:"center"
          }}
        >
          Filter
        </Typography>
      </span>
      <Divider />
      <br />

      <Typography
        style={{
          paddingLeft: "11px",
          fontSize: "14px",
          fontWeight: 600,
          fontFamily: "Montserrat",
          lineHeight: "24px",
          color: "#353535",
          marginTop: "-9px",
          marginBottom: "3px",
        }}
      >
        By Expense Information
      </Typography>

      <List>
        {/* <ListItemIcon> */}
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{
              maxWidth: matches ? "101.66%" : "",
              marginBottom: matches ? "8px" : "",
            }}
          >
            <TextField
              id="outlined-name"
              className={classes.rootMobile1}
              label="Search Expense Name"
              value={expenseNamefilter}
              variant="outlined"
              style={{
                backgroundColor: "#FFFFFF",
                paddingLeft: "11px",
                maxWidth: matches ? "91.66%" : "",
              }}
              onChange={(e) => {
                handleExpenseName("", e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Grid>
        </Grid>
        {/* </ListItemIcon> */}

        <Grid item xs={11} sm={11} md={11}>
          {/* <Grid item xs={11} sm={11} md={11}> */}
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">
              Select the Category
            </InputLabel>
            <Select
              value={expenseCategoryfilter}
              labelId="demo-simple-select-label"
              autosize={true}
              id="demo-simple-select"
              label="Select the Category"
              onChange={(e) => {
                handleCategory("", e.target.value);
              }}
            >
              {[
                ...new Map(
                  getMyExpenseList?.map((item) => [item["categoryName"], item])
                ).values(),
              ]
                .sort((a, b) => a.categoryName.localeCompare(b.categoryName))
                .map((value) => {
                  return (
                    <MenuItem value={value.categoryName}>
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "13px",
                          fontWeight: 500,
                          // lineHeight: "24px",
                          letterSpacing: "-1px",
                          textAlign: "left",
                        }}
                      >
                        {value.categoryName}
                      </Typography>
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          {/* </Grid> */}
        </Grid>

        {/* </Grid> */}
        {/* </ListItemIcon> */}
        <Divider />
        <br />
        <Typography
          style={{
            paddingLeft: "11px",
            marginTop: "-12px",
            fontSize: "14px",
            fontWeight: 600,
            fontFamily: "Montserrat",
            lineHeight: "24px",
            color: "#353535",
            marginBottom: "6px",
          }}
        >
          By Status
        </Typography>
        <Grid item xs={11} sm={11} md={11}>
          {/* <Grid item xs={11} sm={11} md={11}> */}
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">
              Select a Status
            </InputLabel>
            <Select
              value={statusFilter}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Select a Status"
              autosize={true}
              onChange={(e) => {
                handleStatus("", e.target.value);
              }}
            >
              {[
                ...new Map(
                  getMyExpenseList?.map((item) => [item["status"], item])
                ).values(),
              ]
                ?.sort((a, b) => a.status.localeCompare(b.status))
                .map((value) => {
                  return (
                    <MenuItem style={{ fontSize: "13px" }} value={value.status}>
                      {value.status}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          {/* </Grid> */}
        </Grid>
        <Divider />
        <br />
        <Typography
          style={{
            paddingLeft: "11px",
            marginTop: "-12px",
            fontSize: "14px",
            fontWeight: 600,
            fontFamily: "Montserrat",
            lineHeight: "24px",
            color: "#353535",
            marginBottom: "-17px",
          }}
        >
          By Date
        </Typography>
        <Grid
          container
          spacing={2}
          // siddhant
          style={{
            padding: "8px",
            paddingRight: "30px",
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            xs={6} //siddhant
            sm={5}
            md={5}
            style={{ paddingLeft: "0px", paddingRight: "12px" }} //siddhant
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                fullWidth
                className={classes.formControl1}
                variant="inline"
                format="MM/dd/yyyy"
                changeYear={true}
                changeMonth={true}
                // style={{marginBottom:"-10px",marginTop:"-10px"}}
                margin="normal"
                autoOk={true}
                id="date-picker-inline"
                label="Start Date"
                value={startDatefilter}
                disableFuture={true}
                onChange={handleStartDateChange}
                // onChange={(e) => {
                //   handleStartDateChange(e);

                //   if (e) {
                //     const formattedDate = e.toLocaleDateString("en-US");

                //     const parsedDate = parse(
                //       formattedDate,
                //       "MM/dd/yyyy",
                //       new Date()
                //     );

                //     if (!isValid(parsedDate)) {
                //       setEndDateMsg(
                //         "*Invalid date. Please enter in 'MM/dd/YYYY' format."
                //       );
                //     } else {
                //       setEndDateMsg("");
                //     }
                //   } else {
                //     setStartDateMsg("*Please enter Start Date");
                //   }
                // }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                helperText={startDateMsg != "" ? startDateMsg : ""}
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorRoot,
                  },
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          {/* <Grid item xs={1} sm={1} md={1}>
            &nbsp;
          </Grid> */}
          <Grid item xs={6} sm={5} md={5} style={{ paddingLeft: "4px" }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                fullWidth
                className={classes.formControl2}
                disableFuture={true}
                variant="inline"
                format="MM/dd/yyyy"
                changeYear={true}
                changeMonth={true}
                margin="normal"
                autoOk={true}
                id="date-picker-inline"
                label="End Date"
                minDate={startDatefilter}
                value={endDatefilter}
                onChange={handleEndDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                helperText={endDateMsg != "" ? endDateMsg : ""}
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorRoot,
                  },
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Divider style={{ marginBottom: "-20px", marginTop: "-20px" }} />
        <br />
        <Typography
          style={{
            paddingLeft: "11px",
            marginTop: "5px",
            fontSize: "14px",
            fontWeight: 600,
            fontFamily: "Montserrat",
            lineHeight: "24px",
            color: "#353535",
            marginBottom: "6px",
          }}
        >
          Claim Amount Range
        </Typography>
        <Grid
          container
          spacing={2}
          style={{
            padding: "8px 30px 8px 8px",
            justifyContent: "space-between",
          }} //siddhant
        >
          <Grid
            item
            xs={6} //siddhant
            sm={6}
            md={6}
            style={{ padding: "8px 0px", paddingRight: "2px" }} //siddhant
          >
            <ListItemIcon>
              <NumericFormat
                id="outlined-basic"
                label="Min Amount"
                variant="outlined"
                value={minClaimAmountfilter}
                min="0"
                onKeyPress={preventMinus}
                customInput={TextField}
                className={classes.formControl1}
                inputProps={{ maxLength: 10 }}
                onChange={(e) => {
                  handleMinClaimAmount("", e.target.value);
                }}
                helperText={minClaimAmountMsg != "" ? minClaimAmountMsg : ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end"></InputAdornment>
                  ),
                }}
              />
            </ListItemIcon>
          </Grid>
          <Grid item xs={6} sm={5} md={5} style={{ paddingLeft: "6px" }}>
            <ListItemIcon>
              <NumericFormat
                id="outlined-basic"
                label="Max Amount"
                variant="outlined"
                value={maxClaimAmountfilter}
                inputProps={{ maxLength: 10 }}
                min="0"
                onKeyPress={preventMinus}
                customInput={TextField}
                className={classes.formControl3}
                onChange={(e) => {
                  handleMaxClaimAmount("", e.target.value);
                }}
                helperText={maxClaimAmountMsg != "" ? maxClaimAmountMsg : ""}
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorRoot,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end"></InputAdornment>
                  ),
                }}
              />
            </ListItemIcon>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: "-12px", marginBottom: "6px" }} />
        <br />
        <Typography
          style={{
            paddingLeft: "11px",
            marginTop: "-20px",
            fontSize: "14px",
            fontWeight: 600,
            fontFamily: "Montserrat",
            lineHeight: "24px",
            color: "#353535",
            marginBottom: "6px",
          }}
        >
          Approved Amount Range
        </Typography>
        <Grid
          container
          spacing={2}
          style={{
            padding: "8px 30px 8px 8px",
            justifyContent: "space-between",
          }} //siddhant
        >
          <Grid
            item
            xs={6} //siddhant
            sm={6}
            md={6}
            style={{ paddingLeft: "0px", paddingRight: "0px" }} //siddhant
          >
            <ListItemIcon>
              <NumericFormat
                id="outlined-basic"
                label="Min Amount"
                variant="outlined"
                value={minApprovedAmountfilter}
                min="0"
                onKeyPress={preventMinus}
                inputProps={{ maxLength: 10 }}
                customInput={TextField}
                className={classes.formControl1}
                onChange={(e) => {
                  handleMinApprovedAmount("", e.target.value);
                }}
                helperText={
                  minApprovedAmountMsg != "" ? minApprovedAmountMsg : ""
                }
                // FormHelperTextProps={{
                //   classes: {
                //     root: classes.errorRoot,
                //   },
                // }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end"></InputAdornment>
                  ),
                }}
              />
            </ListItemIcon>
          </Grid>
          <Grid item xs={6} sm={5} md={5}>
            <ListItemIcon>
              <NumericFormat
                id="outlined-basic"
                label="Max Amount"
                variant="outlined"
                value={maxApprovedAmountfilter}
                min="0"
                onKeyPress={preventMinus}
                inputProps={{ maxLength: 10 }}
                customInput={TextField}
                className={classes.formControl3}
                onChange={(e) => {
                  handleMaxApprovedAmount("", e.target.value);
                }}
                helperText={
                  maxApprovedAmountMsg != "" ? maxApprovedAmountMsg : ""
                }
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorRoot,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end"></InputAdornment>
                  ),
                }}
              />
            </ListItemIcon>
          </Grid>
        </Grid>
      </List>

      <Divider style={{ marginTop: "-10px" }} />
      <Box>
        <Button
          variant="outlined"
          size="small"
          style={{
            marginLeft: "51px",
            width: "121px",
            textTransform: "inherit",
          }}
          onClick={handleRemoveFilter}
        >
          Reset
        </Button>
        <Button
          variant="outlined"
          disabled={buttonState}
          style={{
            background: buttonState ? "#9E9E9E" : "#0B1941",
            marginLeft: "20px",
            marginBottom: "50px",
            marginTop: "50px",
            textTransform: "inherit",
          }}
          color="#fffff"
          size="small"
          //onClick={buttonState1 ? undefined : handleApplyFilter}
          onClick={() => {
            handleApplyFilter();
          }}
        >
          <Typography style={{ color: "white", margin: 0 }}>
            {" "}
            Apply Filter
          </Typography>
        </Button>
      </Box>
    </div>
  );
  const target = useRef(null);
  const dispatch = useDispatch();
  const getMyExpenseList = useSelector(
    (state) => state.createExpenseData.getMyExpense
  );

  const DeleteMyExpense = useSelector(
    (state) => state.createExpenseData.isDeleteMyExpense
  );

  const delEmpIDRedux = useSelector(
    (state) => state.createExpenseData.delEmpId
  );

  const myExpenseTravelData = useSelector(
    (state) => state.createExpenseData.getMyExpenseTravel
  );
  const getMyTeamApprovalFlagData = useSelector(
    (state) => state.createExpenseData.getMyTeamApprovalPageFlag
  );
  // console.log(
  //   "getMyTeamApprovalFlagData button table",
  //   getMyTeamApprovalFlagData
  // );

  useEffect(() => {
    if (delEmpIDRedux !== null) {
      setEmpId(delEmpIDRedux);
      let obj = {
        empid: delEmpIDRedux,
        nday: props.ndayValue,
      };
      dispatch(getMyExpenseData(obj));
    } else {
      if (props.ssoEmpId != undefined) {
        setEmpId(props.ssoEmpId);
        let obj = {
          empid: props.ssoEmpId,
          nday: props.ndayValue,
        };
        dispatch(getMyExpenseData(obj));
      }
    }
  }, [props.ssoEmpId, props.ndayValue, delEmpIDRedux]);

  useEffect(() => {
    //console.log("expTable : ssoEmpId", ssoEmpId);
  }, [props.ssoEmpId]);

  useEffect(() => {
    if (getMyExpenseList !== null) {
      setMyExpenseData(getMyExpenseList);
    }
    setMyExpenseTableTravelData([]);
  }, [getMyExpenseList]);

  useEffect(() => {
    // console.log("myExpenseTableTravelData2", myExpenseTravelData);
    // if (myExpenseTravelData?.length > 0)
    setMyExpenseTableTravelData((prevState) => [
      ...prevState,
      ...myExpenseTravelData,
    ]);
  }, [myExpenseTravelData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getSetting = (name, value) => {
    if (name === "attachment") {
      setAttachment(value);
    } else if (name === "projectid") {
      setProjectid(value);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      let filterData = stableSort(
        myExpenseData,
        getComparator(order, orderBy)
      )?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      const newSelecteds = filterData.map((s) => s.expId);
      setSelected(newSelecteds);
      setMultiDownload(filterData);
      return;
    }
    setMultiDownload([]);
    setSelected([]);
  };

  const handleClick = (event, name) => {
    setCurrExp(name);
    const selectedIndex = selected.indexOf(name.expId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.expId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    const objIndex = multiDownload.findIndex((el) => el.expId == name.expId);
    let newMultiDownload = [];
    if (objIndex >= 0) {
      newMultiDownload = [
        ...multiDownload.filter((el) => el.expId != name.expId),
      ];
    } else {
      newMultiDownload = [...multiDownload, name];
    }
    setMultiDownload(newMultiDownload);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onFilterArray = (value) => {
    setMyExpenseData(value);
    setPage(0);
  };

  const handlToolAction = (event) => {
    //console.log(event.currentTarget);
    setToolAction(event.currentTarget);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = (comments, nextAction, index) => {
    setOpen(true);
    setCommentData(comments);
    setCommentHeading(nextAction);
    setCurrentIndex(index);
  };

  const handleParentToolTip = () => {
    //console.log(":parent tool tip function");
    return (
      <>
        <OutsideClickHandler
          onOutsideClick={() => {
            handleTooltipClose();
          }}
        >
          <div style={{ position: "relative" }}>
            <Tooltip
              PopperProps={{
                disablePortal: true,
                anchorEl: { toolAction },
              }}
              arrow
              enterTouchDelay={0}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={handleToolTip(commentData, commentHeading)}
              classes={{
                popper: classes.tooltipBox,
                arrow: classes.arrow,
              }}
            >
              <span
                style={{ maxwidth: "20px", minWidth: "0px", height: "0" }}
              ></span>
            </Tooltip>
          </div>
        </OutsideClickHandler>
      </>
    );
  };

  const handleToolTip = (comment, heading) => {
    return (
      <>
        <Box>
          <Typography
            className={classes.statusHeading}
            style={{ textAlign: "left", paddingTop: "5px" }}
          >
            {heading}
          </Typography>
        </Box>
        {comment?.map((value) => {
          return (
            <>
              <Box
                style={{
                  display: "flex",
                  textAlign: "left",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                <Typography className={classes.statusName}>
                  {value.managerid}
                </Typography>
                {heading == "Rejected By " && (
                  <Typography className={classes.statusName}></Typography>
                )}
                <Typography
                  className={classes.statusProjectName}
                  style={{
                    display: "flex",
                    textAlign: "left",
                    paddingLeft: "5px",
                  }}
                >
                  {" "}
                  {value.managerid == "" ? "" : "|"}
                  <span style={{ paddingLeft: "5px" }}> {value.projCode} </span>
                </Typography>{" "}
              </Box>
              <Typography
                className={classes.statusMessage}
                style={{ textAlign: "left" }}
              >
                {value.reason}
              </Typography>
            </>
          );
        })}
      </>
    );
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const isTravelSelected = (name) => travelSelected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, myExpenseData?.length - page * rowsPerPage);
  const matches = useMediaQuery("(max-width:600px)");
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [openAppRejSB, setOpenAppRejSB] = useState(true);
  const [openFinAppRejSB, setOpenFinAppRejSB] = useState(true);

  const isAppRejPostSuccess = useSelector(
    (state) => state.createExpenseData.isApprovedPost
  );
  const AppRejPostResponse = useSelector(
    (state) => state.createExpenseData.postApproveData
  );
  const isFinAppRejPostSuccess = useSelector(
    (state) => state.createExpenseData.isFinancialApprovedRejectPost
  );
  const FinAppRejPostResponse = useSelector(
    (state) => state.createExpenseData.postFinancialApproveRejectData
  );
  const handleCloseAppRejSB = () => {
    setOpenAppRejSB(false);
  };
  const handleCloseFinAppRejSB = () => {
    setOpenFinAppRejSB(false);
  };

  // useEffect(() => {
  //   dispatch(getEmpDetail(account?.username));
  // }, [account?.username]);

  useEffect(() => {
    if (DeleteMyExpense) {
      if (props.ssoEmpId) {
        let obj = {
          empid: props.ssoEmpId,
          nday: props.ndayValue,
        };
        dispatch(getMyExpenseData(obj));
      }
    }
  }, [DeleteMyExpense]);

  // useEffect(() => {
  //   if (props.ssoEmpId !== null) {
  //     setEmpId(props.ssoEmpId);
  //     let obj = {
  //       empid: props.ssoEmpId,
  //       nday: props.ndayValue,
  //     };
  //     dispatch(getMyExpenseData(obj));
  //   }
  // }, [props.ndayValue]);

  // useEffect(() => {
  //   if (isAppRejPostSuccess) {
  //     dispatch(postApproveRejectExpenseData(null)).then((resp)=>{}).catch(err=>{});
  //   } else if (isFinAppRejPostSuccess) {
  //     dispatch(postFinancialApproveRejectExpenseData(null)).then((resp)=>{}).catch(err=>{});
  //   }
  // }, []);
  useEffect(() => {
    if (startDatefilter == null && endDatefilter != null) {
      setStartDateMsg("Please enter start date ");
      setButtonState(true);
    } else if (startDatefilter != null && endDatefilter == null) {
      setEndDateMsg("Please enter end date");
      setButtonState(true);
    } else if (new Date(startDatefilter) > new Date(endDatefilter)) {
      setEndDateMsg("End date should be greater than start date ");
      setStartDateMsg("");
      setButtonState(true);
    } else if (startDatefilter == null && endDatefilter == null) {
      setStartDateMsg("");
      setEndDateMsg("");
    }
  }, [startDatefilter, endDatefilter]);

  useEffect(() => {
    if (
      (minClaimAmountfilter == "" && maxClaimAmountfilter != "") ||
      (minClaimAmountfilter != "" && maxClaimAmountfilter == "")
    ) {
      if (
        startDateMsg.length == 0 &&
        endDateMsg.length == 0 &&
        maxApprovedAmountMsg.length == 0
      )
        setButtonState(false);
      setMaxClaimAmountMsg("");
    } else if (minClaimAmountfilter != "" && maxClaimAmountfilter != "") {
      if (Number(minClaimAmountfilter) > Number(maxClaimAmountfilter)) {
        setMaxClaimAmountMsg("Max Amount should be greater than Min Amount");
        setButtonState(true);
      } else if (Number(minClaimAmountfilter) <= Number(maxClaimAmountfilter)) {
        if (
          startDateMsg.length == 0 &&
          endDateMsg.length == 0 &&
          maxApprovedAmountMsg.length == 0
        )
          setButtonState(false);
        setMaxClaimAmountMsg("");
      }
    } else if (minClaimAmountfilter == "" && maxClaimAmountfilter == "") {
      setMaxClaimAmountMsg("");

      if (handleFieldValue()) setButtonState(true);
      else if (!handleFieldValue()) {
        if (
          startDateMsg.length == 0 &&
          endDateMsg.length == 0 &&
          maxApprovedAmountMsg.length == 0
        )
          setButtonState(false);
        else setButtonState(true);
      }
    }
  }, [minClaimAmountfilter, maxClaimAmountfilter]);

  useEffect(() => {
    if (
      (minApprovedAmountfilter == "" && maxApprovedAmountfilter != "") ||
      (minApprovedAmountfilter != "" && maxApprovedAmountfilter == "")
    ) {
      if (
        startDateMsg.length == 0 &&
        endDateMsg.length == 0 &&
        maxClaimAmountMsg.length == 0
      )
        setButtonState(false);
      setMaxApprovedAmountMsg("");
    } else if (minApprovedAmountfilter != "" && maxApprovedAmountfilter != "") {
      if (Number(minApprovedAmountfilter) > Number(maxApprovedAmountfilter)) {
        setMaxApprovedAmountMsg("Max Amount should be greater than Min Amount");
        setButtonState(true);
      } else if (
        Number(minApprovedAmountfilter) <= Number(maxApprovedAmountfilter)
      ) {
        if (
          startDateMsg.length == 0 &&
          endDateMsg.length == 0 &&
          maxClaimAmountMsg.length == 0
        )
          setButtonState(false);
        setMaxApprovedAmountMsg("");
      }
    } else if (minApprovedAmountfilter == "" && maxApprovedAmountfilter == "") {
      setMaxApprovedAmountMsg("");
      if (handleFieldValue()) setButtonState(true);
      else if (!handleFieldValue()) {
        if (
          startDateMsg.length == 0 &&
          endDateMsg.length == 0 &&
          maxClaimAmountMsg.length == 0
        )
          setButtonState(false);
        else setButtonState(true);
      }
    }
  }, [minApprovedAmountfilter, maxApprovedAmountfilter]);

  const [cell1tarwidth, setCell1tarwidth] = useState(null);
  const [cell2tarwidth, setCell2tarwidth] = useState(null);

  useEffect(() => {
    const syncWidths = () => {
      // console.log("cellwidth1", cell1tar?.current?.style.maxWidth);
      const cell1ref = document.getElementById("cell1ref");
      const cell2ref = document.getElementById("cell2ref");

      if (cell1ref) {
        const widthToSet = cell1ref.clientWidth;
        if (cell1tarwidth !== widthToSet) {
          setCell1tarwidth(widthToSet);
        }
        // console.log("cellwidth", cell1tarwidth, widthToSet);
      }
      if (cell2ref) {
        const widthToSet = cell2ref.clientWidth;
        if (cell2tarwidth !== widthToSet) {
          setCell2tarwidth(widthToSet);
        }
        // console.log("cellwidth2", cell2tarwidth, widthToSet);
      }
    };

    syncWidths();
  }, [myExpenseTravelData]);

  const handleExpand = (event, row) => {
    const selectedIndex = travelSelected.indexOf(row.expId);
    const travelExist = myExpenseTableTravelData.some(
      (obj) => obj.expId === row.expId
    );
    // console.log("Previous state travel", travelExist);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(travelSelected, row.expId);
      if (!travelExist) {
        let obj = {
          expId: row.expId,
          nday: props.ndayValue,
        };
        dispatch(getMyExpenseDataTravel(obj));
      }
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(travelSelected.slice(1));
    } else if (selectedIndex === travelSelected.length - 1) {
      newSelected = newSelected.concat(travelSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        travelSelected.slice(0, selectedIndex),
        travelSelected.slice(selectedIndex + 1)
      );
    }
    setTravelSelected(newSelected);
  };

  return (
    <>
      {/* {isAppRejPostSuccess && AppRejPostResponse.comments == "" ? (
        <ApproveRejectSnackbar
          handleCloseAppRejSB={handleCloseAppRejSB}
          openAppRejSB={openAppRejSB}
          approveOrReject={1}
        />
      ) : isAppRejPostSuccess && AppRejPostResponse.comments != "" ? (
        <ApproveRejectSnackbar
          handleCloseAppRejSB={handleCloseAppRejSB}
          openAppRejSB={openAppRejSB}
          approveOrReject={0}
        />
      ) : (
        ""
      )} */}
      {/* {isFinAppRejPostSuccess &&
      FinAppRejPostResponse.expStatus == "Approved" ? (
        <ApproveRejectSnackbar
          handleCloseAppRejSB={handleCloseFinAppRejSB}
          openAppRejSB={openFinAppRejSB}
          approveOrReject={1}
        />
      ) : isFinAppRejPostSuccess &&
        FinAppRejPostResponse.expStatus == "Rejected" ? (
        <ApproveRejectSnackbar
          handleCloseAppRejSB={handleCloseFinAppRejSB}
          openAppRejSB={openFinAppRejSB}
          approveOrReject={0}
        />
      ) : (
        ""
      )} */}
      {matches && (
        <TableContainer component={Paper} sx={{ width: "max-content" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="right" style={{ padding: "0.4%" }}>
                  &nbsp;
                </TableCell>
                <TableCell align="right" style={{ padding: "0.4%" }}>
                  &nbsp;
                </TableCell>
                <TableCell align="right" style={{ padding: "0.4%" }}>
                  {filterShow ? (
                    <IconButton
                      aria-label="cart"
                      style={{ marginTop: "-5px", marginBottom: "-5px" }}
                      onClick={toggleDrawer(anchorFilter1, true)}
                    >
                      <StyledBadge badgeContent={countShow} color="secondary">
                        <IconButton>
                          {/* {console.log("anchorFilter1", anchorFilter1)} */}
                          <span class="material-symbols-outlined">
                            filter_alt
                          </span>
                        </IconButton>
                      </StyledBadge>
                    </IconButton>
                  ) : (
                    <IconButton onClick={toggleDrawer(anchorFilter1, true)}>
                      <span class="material-symbols-outlined">filter_alt</span>
                    </IconButton>
                  )}
                  <Drawer
                    anchor={anchorFilter1}
                    open={state[anchorFilter1]}
                    onClose={toggleDrawer(anchorFilter1, false)}
                  >
                    {list(anchorFilter1)}
                  </Drawer>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      )}
      <TableContainer component={Paper} sx={{ width: "max-content" }}>
        <Table>
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            rowsPerPage={rowsPerPage}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={myExpenseData?.length}
            getSetting={getSetting}
            myExpenseData={myExpenseData}
            onFilterArray={onFilterArray}
            getMyExpenseList={getMyExpenseList}
            multiDownload={multiDownload}
            setMultiDownload={setMultiDownload}
            pageNumber={page}
            rowVal={currExp}
            handleRemoveFilter={handleRemoveFilter}
          />
          {/* {console.log("expense detail data", myExpenseData)} */}
          <TableBody>
            {stableSort(myExpenseData, getComparator(order, orderBy))
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.expId);
                const isTravelItemSelected = isTravelSelected(row.expId);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <>
                    <TableRow
                      hover
                      className={classes.tableRow}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.expId}
                      selected={isItemSelected}
                    >
                      <TableCell
                        style={{
                          paddingRight: "0px",
                          paddingLeft: "2px",
                          width: "20px",
                          // borderBottomWidth: "0px",
                        }}
                      >
                        {row.tFlag ? (
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            style={{ width: "24px" }}
                            // onClick={(event) => handleExpand(event, row)}
                            onClick={(event) => handleExpand(event, row)}
                          >
                            {isTravelItemSelected ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        ) : (
                          <></>
                        )}
                      </TableCell>
                      <TableCell
                        // padding="checkbox"
                        style={{
                          position: "sticky",
                          left: 0,
                          background: "white",
                          zIndex: 800,
                          minWidth: "28px",
                          width: "28px",
                          // padding: "2px",
                          // color:"#EFF2F8",
                          padding: "0px",
                          // paddingLeft: "2px",
                        }}
                      >
                        <Checkbox
                          style={{
                            paddingLeft: "0px",
                            paddingRight: "0px",
                            display: "flex",
                          }}
                          checked={isItemSelected}
                          checkedIcon={
                            <CheckBoxIcon
                              fontSize="small"
                              className={classes.checkBoxRoot}
                            />
                          }
                          onClick={(event) => handleClick(event, row)}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          padding: "2px",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          maxWidth: "1px",
                          // maxWidth: "80px",
                          // width: "80px",
                        }}
                        onClick={() => {
                          const text = "/viewExpenseDetails/:id";
                          const result = text.replace(":id", row.expId);
                          history.push({
                            pathname: result,
                            state: {
                              response: row,
                              empId: empId,
                              compName: "mobileViewExpense",
                            },
                          });
                        }}
                        id="cell1ref"
                      >
                        {row.expName}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ padding: "2px" }}
                        onClick={() => {
                          const text = "/viewExpenseDetails/:id";
                          const result = text.replace(":id", row.expId);
                          history.push({
                            pathname: result,
                            state: {
                              response: row,
                              empId: empId,
                              compName: "mobileViewExpense",
                            },
                          });
                        }}
                        id="cell2ref"
                      >
                        {row.currencyChanged == 1 && (
                          <Tooltip
                            enterTouchDelay={0}
                            title="User has changed it's default currency"
                          >
                            <span style={{ color: "red" }}>
                              {row?.currency}
                              {row.claimedAmount
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                            </span>
                          </Tooltip>
                        )}
                        {!row.currencyChanged && (
                          <>
                            {" "}
                            {row?.currency}
                            {row.claimedAmount
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </>
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ padding: "2px" }}
                        onClick={() => {
                          const text = "/viewExpenseDetails/:id";
                          const result = text.replace(":id", row.expId);
                          history.push({
                            pathname: result,
                            state: {
                              response: row,
                              empId: empId,
                              compName: "mobileViewExpense",
                            },
                          });
                        }}
                      >
                        {row.currencyChanged == 1 && (
                          <Tooltip
                            title=<strong>
                              "User has changed it's default currency"{" "}
                            </strong>
                          >
                            <span style={{ color: "red" }}>
                              {row?.currency}
                              {row.approvedAmount
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              {/* 69786996 */}
                            </span>
                          </Tooltip>
                        )}
                        {!row.currencyChanged && (
                          <>
                            {" "}
                            {row?.currency}
                            {row.approvedAmount
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </>
                        )}
                      </TableCell>
                      <TableCell
                        // onClick={() => {
                        //     const text = "/viewExpenseDetails/:id";
                        //     const result = text.replace(":id", row.expId);
                        //     history.push({
                        //       pathname: result,
                        //       state: {
                        //         response: row,
                        //         empId: empId,
                        //         compName: "mobileViewExpense",
                        //       },
                        //     });
                        //   }}
                        style={{ padding: "0px", width: "7rem" }}
                      >
                        <Box style={{ display: "flex", padding: "2px" }}>
                          <Chip
                            onClick={() => {
                              const text = "/viewExpenseDetails/:id";
                              const result = text.replace(":id", row.expId);
                              history.push({
                                pathname: result,
                                state: {
                                  response: row,
                                  empId: 1,
                                  compName: "mobileViewExpense",
                                },
                              });
                            }}
                            label={
                              selected.length <= 0
                                ? row.status
                                : row.status === "In Progress"
                                ? "In Progress"
                                : row.status === "Approved"
                                ? "Approved"
                                : row.status === "Rejected"
                                ? "Rejected"
                                : row.status === "Paid"
                                ? "Paid"
                                : "Draft"
                            }
                            className={classes.chipButton}
                            style={{
                              color: "white",
                              fontSize: "10px",
                              width: "90px",
                              marginRight:
                                row.status === "Paid" ? "20px" : "0px",
                              background:
                                row.status === "In Progress"
                                  ? "#E0B15C"
                                  : row.status === "Approved"
                                  ? " #349259"
                                  : row.status === "Rejected"
                                  ? "#D40606"
                                  : row.status === "Paid"
                                  ? "#406086"
                                  : "#9E9E9E",
                            }}
                          />

                          {(row.status == "In Progress" ||
                            row.status == "Approved" ||
                            row.status == "Rejected") && (
                            <div>
                              <InfoOutlinedIcon
                                ref={target}
                                onClick={(event) => {
                                  handleTooltipOpen(
                                    row.comments,
                                    row.nextAction,
                                    index
                                  );
                                  handlToolAction(event);
                                }}
                                className={classes.tooltip}
                              />
                              {open &&
                                index === currentIndex &&
                                handleParentToolTip()}
                            </div>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                    {isTravelItemSelected ? (
                      <TableRow>
                        <TableCell style={{ padding: 0 }} colSpan={12}>
                          <Collapse
                            in={isTravelItemSelected}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box>
                              <Table aria-label="purchases">
                                <TableHead>
                                  <TableRow
                                    style={{
                                      // display: "flex",
                                      background: "#f4f4f4",
                                    }}
                                  >
                                    <TableCell
                                      style={{
                                        width: "50px",
                                        padding: "0px",
                                        maxWidth: "50px",
                                        opacity: 0,
                                      }}
                                    >
                                      {/* S.No. */}
                                      {/* <div
                                      style={{
                                        width: "52px",
                                        maxWidth: "52px",
                                        opacity: 0,
                                      }}
                                    >
                                      
                                    </div> */}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "10px",
                                        width: `${
                                          cell1tarwidth + cell2tarwidth - 5
                                        }px`,
                                        maxWidth: `${
                                          cell1tarwidth + cell2tarwidth - 5
                                        }px`,
                                        padding: "0.7% 0.4%",
                                      }}
                                    >
                                      Expense Category
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "10px",
                                        width: `${cell2tarwidth}px`,
                                        minWidth: "82px",
                                        padding: "0.4%",
                                        boxSizing: "border-box",
                                      }}
                                    >
                                      Claim Amount
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "10px",
                                        // width: `${cell2tarwidth}px`,
                                        padding: "0.4%",
                                        // minWidth: "84px",
                                        // maxWidth: "100px",
                                        width: "98px",
                                      }}
                                    >
                                      Approved Amount
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {/* {console.log(
                                    "travel category data",
                                    myExpenseTableTravelData
                                  )} */}
                                  {myExpenseTableTravelData?.map(
                                    (travelRow, index) => {
                                      return Number(travelRow.parentExpId) ===
                                        row.expId ? (
                                        <TableRow
                                          key={index}
                                          style={{
                                            // display: "flex",
                                            borderBottom: "1px solid #e0e0e0",
                                          }}
                                        >
                                          {/* <TableCell /> */}
                                          <TableCell
                                            style={{
                                              // width: "22px",
                                              width: "50px",
                                              maxWidth: "50px",
                                              borderBottom: "unset",
                                              padding: "0px",
                                              opacity: 0,
                                            }}
                                          >
                                            {/* <div
                                            style={{
                                              width: "52px",
                                              maxWidth: "52px",
                                              opacity: 0,
                                            }}
                                          >
                                            S.No.
                                          </div> */}
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              width: `${
                                                cell1tarwidth +
                                                cell2tarwidth -
                                                5
                                              }px`,
                                              maxWidth: `${
                                                cell1tarwidth +
                                                cell2tarwidth -
                                                5
                                              }px`,
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              fontSize: "10px",
                                              padding: "12px 0.4%",
                                              borderBottom: "unset",
                                              boxSizing: "border-box",
                                            }}
                                          >
                                            {travelRow.categoryName}
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            style={{
                                              // minWidth: "74px",
                                              width: `${cell2tarwidth}px`,
                                              minWidth: "82px",
                                              fontSize: "10px",
                                              // padding: "12px 2px",
                                              padding: "0.4%",
                                              borderBottom: "unset",
                                              boxSizing: "border-box",
                                            }}
                                          >
                                            {row?.currency}
                                            {travelRow.claimedAmount
                                              .toString()
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            style={{
                                              // minWidth: "84px",
                                              // maxWidth: "4.5rem",
                                              width: "98px",
                                              // maxWidth: "100px",
                                              fontSize: "10px",
                                              // padding: "12px 2px",
                                              padding: "0.4%",
                                              borderBottom: "unset",
                                            }}
                                          >
                                            {" "}
                                            {row?.currency}
                                            {travelRow.approvedAmount
                                              .toString()
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}
                                          </TableCell>
                                        </TableRow>
                                      ) : (
                                        <></>
                                      );
                                    }
                                  )}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            {emptyRows > 0 && (
              <TableRow className={classes.tableRow}>
                <TableCell colSpan={12} style={{ padding: "0.4%" }} />
              </TableRow>
            )}
          </TableBody>
          {getMyTeamApprovalFlagData?.myExpenseTab === 1 && (
          <MobileFloatingButton />
           )}{" "}
        </Table>
        <Box
          style={{
            display: "flex",
            paddingLeft: "54px",
            marginTop: "-8px",
            justifyContent: "space-between",
            position: "fixed",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[]}
            component="Box"
            count={myExpenseData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          ></TablePagination>
        </Box>
      </TableContainer>
    </>
  );
}
