import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import Table from "@material-ui/core/Table";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import SettingsIcon from "@material-ui/icons/Settings";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ExpenseSetting from "./expenseSetting";
import ExpenseAction from "./expenseAction";
import ExpenseFilter from "./expenseFilter";
import rows from "../Utils/data.json";
import moment from "moment";
import {
  getMyExpenseData,
  deleteMyExpenseData,
  postDownloadFiles,
  resetExpenseResponse,
  getMyExpenseDataTravel,
} from "../redux/Expense/action";
import { useDispatch, useSelector } from "react-redux";
import Filterimg from "../images/filter.svg";
import { CSVLink } from "react-csv";
import { Chip } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import OutsideClickHandler from "react-outside-click-handler";
import { useHistory } from "react-router-dom";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { useTheme } from "@material-ui/core/styles";
import DeleteSnackbar from "./deleteSnackbar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Pagination from "@material-ui/lab/Pagination";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootFooter}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const [setting, setSetting] = React.useState(false);
  const [attachment, setAttachment] = React.useState(false);
  const [projectId, setProjectid] = React.useState(false);
  const [popUp, setPopup] = React.useState("");

  const [filterPopup, setFilterPopup] = React.useState("");
  const [filterAnchor, setFilterAnchor] = React.useState("");
  const [filtersetting, setFilterSetting] = React.useState(false);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  // const [filterOffToggle, setFilterOffToggle] = React.useState("");

  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowsPerPage,
    rowCount,
    onRequestSort,
    myExpenseData,
    onFilterArray,
    getMyExpenseList,
    multiDownload,
    pageNumber,
    handleFilterOff,
    setFilterOffToggleFunction,
    filterOffToggle,
  } = props;

  const headCells = [
    {
      id: "expId",
      numeric: false,
      disablePadding: true,
      label: "Expense ID",
    },
    {
      id: "categoryName",
      numeric: false,
      disablePadding: false,
      label: "Expense Category",
    },
    {
      id: "expName",
      numeric: false,
      disablePadding: false,
      label: "Expense Name",
    },

    {
      id: "createdOn",
      numeric: true,
      disablePadding: false,
      label: "Creation Date",
    },
    {
      id: "claimedAmount",
      numeric: true,
      disablePadding: false,
      label: "Claim Amount",
    },
    {
      id: "approvedAmount",
      numeric: true,
      disablePadding: false,
      label: "Approved Amount",
    },
    { id: "status", numeric: true, disablePadding: false, label: "Status" },
    ...(attachment === true
      ? [
          {
            id: "attachments",
            numeric: true,
            disablePadding: false,
            label: "Attachment",
          },
        ]
      : []),
    ...(projectId === true
      ? [
          {
            id: "Project Code",
            numeric: true,
            disablePadding: false,
            label: "Project Code",
          },
        ]
      : []),
    { id: "icon", numeric: false, disablePadding: false, label: "" },
  ];

  const headers = [
    {
      label: "Expense Code",
      key: "expCode",
    },
    {
      label: "Expense Category",
      key: "categoryName",
    },
    {
      label: "To Location",
      key: "toLocation",
    },
    {
      label: "From Location",
      key: "fromLocation",
    },
    {
      label: "Distance (Km/Miles)",
      key: "distance",
    },
    {
      label: "From Date",
      key: "fromDate",
    },
    {
      label: "To Date",
      key: "toDate",
    },
    {
      label: "Claim Month",
      key: "forMonth",
    },
    {
      label: "Expense Name",
      key: "expName",
    },
    {
      label: "Creation Date",
      key: "createdOn",
    },
    {
      label: "Created By",
      key: "createdBy",
    },
    {
      label: "Relationship",
      key: "dependent",
    },
    {
      label: "Currency",
      key: "currencyCode",
    },
    {
      label: "Claimed Amount",
      key: "claimedAmount",
    },
    {
      label: "Bill Amount",
      key: "billAmount",
    },
    {
      label: "Approved Amount",
      key: "approvedAmount",
    },
    {
      label: "Project Code",
      key: "projectId",
    },
    {
      label: "Attachments",
      key: "attachments",
    },
    {
      label: "Comments",
      key: "reportComments",
    },
    {
      label: "Status",
      key: "status",
    },
  ];

  const csvLink = {
    headers: headers,
    data: multiDownload,
    filename: `MyExpense_${moment(new Date()).format("DDMMYYYY_HHmm")}.csv`,
  };

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const handleSetting = (event) => {
    setSetting(!setting);
    setPopup(event.currentTarget);
  };

  const handlePopup = (name, value) => {
    props.getSetting(name, value);
    if (name === "attachment") {
      setAttachment(value);
    } else if (name === "projectid") {
      setProjectid(value);
    }
  };

  const handleFilterPopup = (event) => {
    setFilterSetting(!filtersetting);
    setFilterAnchor(event.currentTarget);
  };

  const handleFilterToggle = () => {
    setFilterSetting(false);
  };
  //console.log("filtersetting", filtersetting);
  //console.log("filterAnchor", filterAnchor);
  //console.log("filterPopup", filterPopup);

  const handleStartDate = (date) => {
    setStartDate(date);
  };
  const handleEndDate = (date) => {
    setEndDate(date);
  };

  //console.log("in the table attachment", attachment);
  //console.log("in the table projectId", projectId);

  const handleApplyFilter = (type, value1, value2) => {
    let dateArray = [];
    //console.log("type", type);
    if (type == "Creation Date") {
      //console.log("in the creation date");
      var d1 = new Date(value1).toLocaleDateString();
      var d2 = new Date(value2).toLocaleDateString();
      dateArray = myExpenseData?.filter(
        (item) =>
          new Date(item.createdOn).toLocaleDateString() >= d1 &&
          new Date(item.createdOn).toLocaleDateString() <= d2
      );
      //console.log("d1,d2", d1, d2);
      // //console.log(
      //   "myExpensedata",
      //   myExpenseData[0].createdOn,
      //   new Date(myExpenseData[0].createdOn)
      // );
      //console.log("dateArray", dateArray);
    } else if (type == "Claim Amount") {
      if (value1 === "greaterThanEqual") {
        dateArray = myExpenseData?.filter(
          (item) => item.claimedAmount >= value2
        );
      } else {
        dateArray = myExpenseData?.filter(
          (item) => item.claimedAmount <= value2
        );
      }
    } else if (type == "Expense Category") {
      dateArray = myExpenseData?.filter((item) => item.categoryName === value1);
    } else if (type == "Approved Amount") {
      if (value1 === "greaterThanEqual") {
        dateArray = myExpenseData?.filter(
          (item) => item.approvedAmount >= value2
        );
      } else {
        dateArray = myExpenseData?.filter(
          (item) => item.approvedAmount <= value2
        );
      }
    } else if (type == "Status") {
      dateArray = myExpenseData?.filter((item) => item.status === value1);
    } else if (type == "Attachment") {
      dateArray = myExpenseData?.filter(
        (item) => Number(item.attachments) === Number(value1)
      );
    } else if (type == "Expense Name") {
      dateArray = myExpenseData?.filter((item) =>
        item?.expName?.includes(value1)
      );
    } else if (type == "Expense ID") {
      //console.log("in Expense ID", value1);
      dateArray = myExpenseData?.filter(
        (item) =>
          item?.expCode.toLowerCase().includes(value1.toLowerCase()) == true
      );
      //console.log("in Expense ID", myExpenseData);
    } else if (type == "Project Code") {
      //console.log("value1,myExpenseData", value1, myExpenseData);
      dateArray = myExpenseData?.filter((item) =>
        item?.projectId?.includes(value1)
      );
    }

    //console.log("array", dateArray);
    onFilterArray(dateArray);
    // setFilterOffToggle(type);
    setFilterOffToggleFunction(type);
  };
  // const handleFilterOff = () => {
  //   //console.log("handleFilterOff", myExpenseData);
  //   onFilterArray(getMyExpenseList);
  //   setFilterOffToggle(false);
  // };

  const handleClearFilter = () => {
    //console.log("in thehandleClearFilter");
    onFilterArray(getMyExpenseList);
  };

  return (
    <TableHead className={classes.header}>
      {multiDownload?.length > 0 && (
        <TableRow style={{ height: 58 }}>
          <TableCell />
          <TableCell
            padding="checkbox"
            style={{
              position: "sticky",
              left: 0,
              background: "white",
              zIndex: 800,
              minWidth: "53px",
              // color:"#EFF2F8"
            }}
          >
            {numSelected === rowsPerPage ||
            (pageNumber === Math.ceil(rowCount / rowsPerPage) - 1 &&
              numSelected === rowCount % rowsPerPage) ? (
              <IconButton style={{ maxWidth: "40px", maxHeight: "40px" }}>
                {/* <span
                  //checked={rowCount > 0 && numSelected == rowCount}
                  onClick={onSelectAllClick}
                  class="material-symbols-outlined"
                >
                  indeterminate_check_box
                </span> */}
                <CheckBoxIcon
                  fontSize="small"
                  onClick={onSelectAllClick}
                  className={classes.checkBoxRoot}
                />
              </IconButton>
            ) : (
              <Checkbox
                // disabled={numSelected > 0 && numSelected < rowCount}
                checkedIcon={
                  <CheckBoxIcon
                    fontSize="small"
                    className={classes.checkBoxRoot}
                  />
                }
                checked={numSelected?.length >= 4}
                onChange={onSelectAllClick}
                inputProps={{ "aria-label": "select all desserts" }}
              />
            )}
          </TableCell>

          <TableCell>
            <CSVLink {...csvLink} style={{ color: "transparent" }}>
              <Button
                variant="outlined"
                color="primary"
                style={{
                  minWidth: "128px",
                  maxHeight: "32px",
                  whiteSpace: "nowrap",
                  backgroundColor: "#0B1941",
                  color: "#043365",
                  border: "1px solid #979797",
                  padding: "8px",
                }}
              >
                <GetAppIcon
                  style={{
                    width: "16px",
                    height: "16px",
                    color: "#FFFFFF",
                    backgroundColor: "#0B1941",
                  }}
                />
                <Typography
                  style={{
                    fontSize: "10px",
                    fontWeight: 500,
                    color: "#FFFFFF",
                    backgroundColor: "#0B1941",
                  }}
                >
                  Export Expense
                </Typography>
              </Button>
            </CSVLink>
          </TableCell>
          <TableCell>
            <Typography variant="p" style={{ maxWidth: "120px" }}>
              {multiDownload?.length}
              {" Selected"}
            </Typography>
          </TableCell>
        </TableRow>
      )}

      {multiDownload?.length == 0 && (
        <TableRow style={{ height: 58 }}>
          <TableCell />
          <TableCell
            padding="checkbox"
            style={{
              //   position: "sticky",
              //   left: 0,
              //   background: "white",
              //   zIndex: 800,
              minWidth: "53px",
            }}
            className={
              attachment == true || projectId == true
                ? classes.stickyLeft
                : classes.nonSticky
            }
          >
            <Checkbox
              // disabled={numSelected > 0 && numSelected < rowCount}
              checkedIcon={
                <CheckBoxIcon
                  fontSize="small"
                  className={classes.checkBoxRoot}
                  // className={attachment==true || projectId == true ? classes.stickyLeft : classes.nonSticky}
                />
              }
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
          {multiDownload?.length == 0 &&
            headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.id == "status" ? "center" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                style={{
                  position: headCell.id == "icon" ? "sticky" : "",
                  //   backgroundColor: headCell.id == "icon" ? "white" : "#F4F4F4",
                  right: headCell.id == "icon" ? 0 : "",

                  minWidth: headCell.id == "icon" ? "45px" : "135px",
                }}
                className={
                  (attachment == true || projectId == true) &&
                  headCell.id == "icon"
                    ? classes.stickyLeft
                    : classes.nonSticky
                }
              >
                <Box
                  style={{
                    display: "flex",
                    textAlign:
                      headCell.id == "claimedAmount" ||
                      headCell.id == "approvedAmount"
                        ? "left"
                        : "center" || headCell.id == "createdOn"
                        ? "left"
                        : "center",
                    justifyContent:
                      headCell.id == "icon" || headCell.id == "status"
                        ? "center"
                        : "",
                    // paddingRight:headCell.id=="status"?(window.screen.availWidth==1280?"":window.screen.availWidth==1536?"":"50px"):""
                  }}
                >
                  <Typography
                    className={classes.headerName}
                    style={{ display: "flex" }}
                  >
                    {headCell.id !== "icon" ? headCell.label : ""}
                    {headCell.id == "expId" ? (
                      filterOffToggle == "Expense ID" ? (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterOff}
                          style={{ color: "#BB271A", padding: "5px" }}
                        >
                          <span
                            class="material-symbols-outlined"
                            color="#BB271A"
                          >
                            filter_alt_off
                          </span>
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="filter list"
                          onClick={(event) => {
                            handleFilterPopup(event);
                            setFilterPopup(headCell.label);
                          }}
                          style={{
                            color: "#666666",
                            padding: "5px",
                            size: "small",
                          }}
                        >
                          <span class="material-symbols-outlined">
                            filter_alt
                          </span>
                        </IconButton>
                      )
                    ) : headCell.id == "categoryName" ? (
                      filterOffToggle == "Expense Category" ? (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterOff}
                          style={{ color: "#BB271A", padding: "5px" }}
                        >
                          <span
                            class="material-symbols-outlined"
                            color="#BB271A"
                          >
                            filter_alt_off
                          </span>
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="filter list"
                          onClick={(event) => {
                            handleFilterPopup(event);
                            setFilterPopup(headCell.label);
                          }}
                          style={{
                            color: "#666666",
                            padding: "5px",
                            size: "small",
                          }}
                        >
                          <span class="material-symbols-outlined">
                            filter_alt
                          </span>
                        </IconButton>
                      )
                    ) : headCell.id == "expName" ? (
                      filterOffToggle == "Expense Name" ? (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterOff}
                          style={{ color: "#BB271A", padding: "5px" }}
                        >
                          <span
                            class="material-symbols-outlined"
                            color="#BB271A"
                          >
                            filter_alt_off
                          </span>
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="filter list"
                          onClick={(event) => {
                            handleFilterPopup(event);
                            setFilterPopup(headCell.label);
                          }}
                          style={{
                            color: "#666666",
                            padding: "5px",
                            size: "small",
                          }}
                        >
                          <span class="material-symbols-outlined">
                            filter_alt
                          </span>
                        </IconButton>
                      )
                    ) : headCell.id == "createdOn" ? (
                      filterOffToggle == "Creation Date" ? (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterOff}
                          style={{ color: "#BB271A", padding: "5px" }}
                        >
                          <span
                            class="material-symbols-outlined"
                            color="#BB271A"
                          >
                            filter_alt_off
                          </span>
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="filter list"
                          onClick={(event) => {
                            handleFilterPopup(event);
                            setFilterPopup(headCell.label);
                          }}
                          style={{
                            color: "#666666",
                            padding: "5px",
                            size: "small",
                          }}
                        >
                          <span class="material-symbols-outlined">
                            filter_alt
                          </span>
                        </IconButton>
                      )
                    ) : headCell.id == "claimedAmount" ? (
                      filterOffToggle == "Claim Amount" ? (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterOff}
                          style={{ color: "#BB271A", padding: "5px" }}
                        >
                          <span
                            class="material-symbols-outlined"
                            color="#BB271A"
                          >
                            filter_alt_off
                          </span>
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="filter list"
                          onClick={(event) => {
                            handleFilterPopup(event);
                            setFilterPopup(headCell.label);
                          }}
                          style={{
                            color: "#666666",
                            padding: "5px",
                            size: "small",
                          }}
                        >
                          <span class="material-symbols-outlined">
                            filter_alt
                          </span>
                        </IconButton>
                      )
                    ) : headCell.id == "approvedAmount" ? (
                      filterOffToggle == "Approved Amount" ? (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterOff}
                          style={{ color: "#BB271A", padding: "5px" }}
                        >
                          <span
                            class="material-symbols-outlined"
                            color="#BB271A"
                          >
                            filter_alt_off
                          </span>
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="filter list"
                          onClick={(event) => {
                            handleFilterPopup(event);
                            setFilterPopup(headCell.label);
                          }}
                          style={{
                            color: "#666666",
                            padding: "5px",
                            size: "small",
                          }}
                        >
                          <span class="material-symbols-outlined">
                            filter_alt
                          </span>
                        </IconButton>
                      )
                    ) : headCell.id == "status" ? (
                      filterOffToggle == "Status" ? (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterOff}
                          style={{ color: "#BB271A", padding: "5px" }}
                        >
                          <span
                            class="material-symbols-outlined"
                            color="#BB271A"
                          >
                            filter_alt_off
                          </span>
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="filter list"
                          onClick={(event) => {
                            handleFilterPopup(event);
                            setFilterPopup(headCell.label);
                          }}
                          style={{
                            color: "#666666",
                            padding: "5px",
                            size: "small",
                          }}
                        >
                          <span class="material-symbols-outlined">
                            filter_alt
                          </span>
                        </IconButton>
                      )
                    ) : headCell.id == "attachments" ? (
                      filterOffToggle == "Attachment" ? (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterOff}
                          style={{ color: "#BB271A", padding: "5px" }}
                        >
                          <span
                            class="material-symbols-outlined"
                            color="#BB271A"
                          >
                            filter_alt_off
                          </span>
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="filter list"
                          onClick={(event) => {
                            handleFilterPopup(event);
                            setFilterPopup(headCell.label);
                          }}
                          style={{
                            color: "#666666",
                            padding: "5px",
                            size: "small",
                          }}
                        >
                          <span class="material-symbols-outlined">
                            filter_alt
                          </span>
                        </IconButton>
                      )
                    ) : headCell.id == "Project Code" ? (
                      filterOffToggle == "Project Code" ? (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterOff}
                          style={{ color: "#BB271A", padding: "5px" }}
                        >
                          <span
                            class="material-symbols-outlined"
                            color="#BB271A"
                          >
                            filter_alt_off
                          </span>
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="filter list"
                          onClick={(event) => {
                            handleFilterPopup(event);
                            setFilterPopup(headCell.label);
                          }}
                          style={{
                            color: "#666666",
                            padding: "5px",
                            size: "small",
                          }}
                        >
                          <span class="material-symbols-outlined">
                            filter_alt
                          </span>
                        </IconButton>
                      )
                    ) : (
                      ""
                    )}
                  </Typography>
                  {headCell.id == "icon" && (
                    <>
                      <Typography
                        className={classes.settingIcon}
                        onClick={handleSetting}
                      >
                        <SettingsIcon />
                      </Typography>
                      <ExpenseSetting
                        popUp={popUp}
                        setting={setting}
                        handlePopup={handlePopup}
                        closeSetting={() => setSetting(false)}
                      />
                    </>
                  )}
                </Box>
              </TableCell>
            ))}
          {multiDownload?.length == 0 && filterPopup !== "" && (
            <ExpenseFilter
              filtersetting={filtersetting}
              filterAnchor={filterAnchor}
              filterType={filterPopup}
              handleStartDate={handleStartDate}
              handleEndDate={handleEndDate}
              // handleItem={handleItem}
              handleApplyFilter={handleApplyFilter}
              handleClearFilter={handleClearFilter}
              myExpenseData={myExpenseData}
              multiDownload={multiDownload}
              handleFilterToggle={handleFilterToggle}
            />
          )}
        </TableRow>
      )}
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  myExpenseData: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  // paper: {
  //   width: "100%",
  //   marginBottom: theme.spacing(2),
  //   background: "#FFFFFF",
  //   border: "1px solid #979797",
  //   borderRadius: "8px",
  // },

  tableContainer: {
    //maxWidth: "100%", //1117

    borderRadius: "8px",
    "&.MuiTableCell-root": {
      padding: "0px",
    },
  },

  rootFooter: {
    flexShrink: 0,

    marginLeft: theme.spacing(2.5),
  },
  rootFooter: {
    flexShrink: 0,

    marginLeft: theme.spacing(2.5),
  },
  rootFooter: {
    flexShrink: 0,

    marginLeft: theme.spacing(2.5),
  },
  paper: {
    //width: "100%", //1117
    border: "1px solid #979797",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
  },
  paperZ150: {
    width: "1132px",
    border: "1px solid #979797",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
  },
  paperZ125: {
    width: "1390px",
    border: "1px solid #979797",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
  },
  paperZ100: {
    //width: "1117px",
    border: "1px solid #979797",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
  },
  table: {
    //maxWidth: "100%",
    "&.MuiTableCell-root": {
      padding: "0px",
      border: "unset",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  headerName: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "700",
    color: "rgba(9, 10, 11, 0.87)",
    alignItems: "center",
  },
  header: {
    background: "#F4F4F4",
    borderRadius: "8px 8px 0px 0px",
    maxWidth: "100%", //1126
    maxHeight: "58px",
  },

  settingIcon: {
    color: "#0B1941",
    // marginTop: "29px",
  },
  dotIcon: {
    color: "#666666",
  },
  status: {
    borderRadius: "100px",
    background: "#349259",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "10px",
    height: "14px",
    textTransform: "uppercase",
    paddingLeft: "10px",
    paddingRight: "10px",
    color: "#FFFFFF",
  },
  filterImg: {
    width: "18px",
    // height:"18px",
  },
  stickyRight: {
    position: "sticky",
    right: 0,
    background: "white",
    zIndex: 800,
  },
  stickyLeft: {
    position: "sticky",
    left: 0,
    background: "white",
    zIndex: 800,
  },
  nonSticky: {
    position: "sticky",
    left: 0,
    background: "#F4F4F4",
    zIndex: 800,
  },
  bulkDownloadText: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "10px",
    height: "16px",
    paddingLeft: "10px",
    color: "#2B2D30",
  },
  tableRow: {
    height: 58,
    "&.MuiTableRow-root.MuiTableRow-hover:hover": {
      backgroundColor: "#EFF2F8",
    },
    "&.MuiTableRow-root.Mui-selected": {
      background: "#EFF2F8",
    },
  },
  bulkDownload: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "10px",
    height: "16px",
    paddingLeft: "10px",
    textTransform: "capitalize",
    color: "#FFFFFF",
  },
  bulkDownloadbtn: {
    minWidth: "128px",
    maxHeight: "32px",
    whiteSpace: "nowrap",
    background: "#0B1941",
    color: "#FFFFFF",
    borderRadius: "4px",
    height: "32px",
    "&:hover": {
      background: "#0B1941",
      boxShadow:
        "0px -1px 5px rgba(89, 122, 105, 0.09), 0px 2px 2px rgba(44, 73, 59, 0.1), 0px 2px 4px rgba(138, 159, 149, 0.2), 0px 3px 5px rgba(89, 151, 118, 0.1)",
    },
  },
  csv: {
    color: "transparent",
  },
  statusBox: {
    display: "flex",
    justifyContent: "center",
  },
  tooltip: {
    paddingLeft: "2px",
    color: "#9E9E9E",
    maxWidth: "20px",
    maxHeight: "20px",
  },

  tooltipBox: {
    minWidth: "250px",
    left: "auto!important",
    right: 0,
    // top:'-20px!important'
  },
  arrow: {
    left: "auto!important",

    right: 0,
  },
  statusHeading: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "8px",
    color: "rgba(255, 255, 255, 0.9)",
    // opacity: "0.6",
  },
  statusName: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "10px",
    marginRight: "2px",
    // alignItems: "center",
    // paddingLeft: "10px",
    color: "rgba(255, 255, 255, 0.85)",
  },
  statusProjectName: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "8px",
    // alignItems: "center",
    // paddingLeft: "10px",
    marginTop: "2px",
    color: "rgba(255, 255, 255, 0.9)",
    opacity: "0.6",
  },
  statusMessage: {
    fontFamily: "Montserrat",
    fontStyle: "italic",
    fontWeight: 400,
    fontSize: "8px",
    color: "#FFFFFF",
  },
  checkBoxRoot: {
    background: "#0B1941",
    color: "white",
    "&$checked": {
      background: "#0B1941",
      color: "white",
    },
  },
  enhancedTable: {
    "& .MuiTablePagination-toolbar": {
      paddingRight: "28%",
    },
  },
}));

export default function EnhancedTable({ nDay, ssoEmpId }) {
  const classes = useStyles();
  const history = useHistory();

  const [filterOffToggle, setFilterOffToggle] = React.useState("");

  const setFilterOffToggleFunction = (type) => {
    setFilterOffToggle(type);
  };

  const handleFilterOff = () => {
    //console.log("handleFilterOff", myExpenseData);
    onFilterArray(getMyExpenseList);
    setFilterOffToggle(false);
  };

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [attachment, setAttachment] = React.useState(false);
  const [projectId, setProjectid] = React.useState(false);
  const [empId, setEmpId] = React.useState(null);
  const [action, setAction] = React.useState(false);

  const [popUpAction, setPopupAction] = React.useState("");

  const [popRow, setPopRow] = React.useState(null);

  const [myExpenseData, setMyExpenseData] = React.useState([]);

  const [multiDownload, setMultiDownload] = React.useState([]);
  const [singleDownload, setSingleDownload] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [commentData, setCommentData] = React.useState([]);
  const [commentHeading, setCommentHeading] = React.useState("");
  const [toolAction, setToolAction] = React.useState("");
  const [currentIndex, setCurrentIndex] = React.useState();

  const [expandTravel, setExpandTravel] = useState(false);
  const [travelSelected, setTravelSelected] = useState([]);
  const [myExpenseTableTravelData, setMyExpenseTableTravelData] = useState([]);

  const target = useRef(null);

  const dispatch = useDispatch();

  const getMyExpenseList = useSelector(
    (state) => state.createExpenseData.getMyExpense
  );

  const DeleteMyExpense = useSelector(
    (state) => state.createExpenseData.isDeleteMyExpense
  );

  const delEmpIDRedux = useSelector(
    (state) => state.createExpenseData.delEmpId
  );

  const myExpenseTravelData = useSelector(
    (state) => state.createExpenseData.getMyExpenseTravel
  );

  // console.log("myExpenseTravelData",myExpenseTravelData)
  //console.log("delEmpID in EXPT1", delEmpIDRedux);
  //console.log(">>>>>>>>>", getMyExpenseList);
  //console.log("isDeleteMyExpense AK1 ", DeleteMyExpense);

  //console.log("nday",nDay);
  // const a="Rejected By Piyush Shrivastva| IT-PROJ-00253| Attachement missing, Neha Garg| II-PROJ-01035| Please attched receipt,rohit yadav| II-PROJ-01035| Please attched receipt"
  // //console.log(a.split(","));
  useEffect(() => {
    if (delEmpIDRedux !== null) {
      //console.log("delEmpIDRedux in ET2", delEmpIDRedux);
      setEmpId(delEmpIDRedux);
      let obj = {
        empid: delEmpIDRedux,
        nday: nDay,
      };
      dispatch(getMyExpenseData(obj));
    } else {
      if (ssoEmpId !== null) {
        setEmpId(ssoEmpId);
        let obj = {
          empid: ssoEmpId,
          nday: nDay,
        };
        dispatch(getMyExpenseData(obj));
      }
    }

    //console.log("AppFin", empId);
    // let obj = {
    //   empid: 1,
    //   nday: nDay,
    // };
    // dispatch(getMyExpenseData(obj));
  }, [nDay, delEmpIDRedux]);
  useEffect(() => {
    //console.log("expTable : ssoEmpId", ssoEmpId);
  }, [ssoEmpId]);

  useEffect(() => {
    if (DeleteMyExpense) {
      if (delEmpIDRedux != null) {
        setEmpId(delEmpIDRedux);
        let obj = {
          empid: delEmpIDRedux,
          nday: nDay,
        };
        dispatch(getMyExpenseData(obj));
      } else {
        if (ssoEmpId !== null) {
          setEmpId(ssoEmpId);
          let obj = {
            empid: ssoEmpId,
            nday: nDay,
          };
          dispatch(getMyExpenseData(obj));
        }
      }
      //console.log("AppFin", empId);
      // let obj = {
      //   empid: 1,
      //   nday: nDay,
      // };
      // dispatch(getMyExpenseData(obj));
    }
  }, [DeleteMyExpense]);

  useEffect(() => {
    if (getMyExpenseList !== null) {
      setMyExpenseData(getMyExpenseList);
    }
    setMyExpenseTableTravelData([]);
  }, [getMyExpenseList]);

  useEffect(() => {
    // console.log("myExpenseTableTravelData2", myExpenseTravelData);
    // if (myExpenseTravelData?.length > 0)
    if (myExpenseTravelData) {
      setMyExpenseTableTravelData((prevState) => [
        ...prevState,
        ...myExpenseTravelData,
      ]);
    }
  }, [myExpenseTravelData]);

  // console.log("expense data", myExpenseData);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getSetting = (name, value) => {
    if (name === "attachment") {
      setAttachment(value);
    } else if (name === "projectid") {
      setProjectid(value);
    }
  };
  //console.log("in the table attachment", attachment);
  //console.log("in the table projectId", projectId);
  //console.log("?????", attachment);
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // //console.log('pageNumber', page)
      let filterData = stableSort(
        myExpenseData,
        getComparator(order, orderBy)
      )?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      // //console.log('filterData', filterData)
      const newSelecteds = filterData.map((s) => s.expId);
      setSelected(newSelecteds);
      //console.log("selected value", newSelecteds);
      setMultiDownload(filterData);

      return;
    }
    setMultiDownload([]);

    setSelected([]);
  };
  //console.log("multiDownload value", multiDownload);

  const handleClick = (event, name) => {
    //console.log("name", name);
    const selectedIndex = selected.indexOf(name.expId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.expId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    //console.log("selected value", selected);

    const objIndex = multiDownload.findIndex((el) => el.expId == name.expId);
    let newMultiDownload = [];
    if (objIndex >= 0) {
      newMultiDownload = [
        ...multiDownload.filter((el) => el.expId != name.expId),
      ];
    } else {
      newMultiDownload = [...multiDownload, name];
    }
    setMultiDownload(newMultiDownload);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    //console.log("newPage", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // //console.log('event',event)
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //   const handleChangeDense = (event) => {
  //     setDense(event.target.checked);
  //   };

  const onFilterArray = (value) => {
    setMyExpenseData(value);
    setPage(0);
  };

  const handleClickAction = (event) => {
    setAction(!action);
    setPopupAction(event.currentTarget);
  };
  //console.log("action", action);

  const handleDelete = (value) => {
    let obj = {
      expID: value.expId,
      empId: delEmpIDRedux != null ? delEmpIDRedux : ssoEmpId,
    };
    dispatch(deleteMyExpenseData(obj));
    //console.log("dispatch(resetExpenseResponse()); AK1 ");
    dispatch(resetExpenseResponse()); //-----------------------------------------

    // onFilterArray(getMyExpenseList);
  };

  const handleDownload = (value) => {
    setSingleDownload([value]);
  };
  //console.log("value is", singleDownload);

  const handlePopupAction = () => {};

  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handlToolAction = (event) => {
    //console.log(event.currentTarget);
    setToolAction(event.currentTarget);
  };
  const handleToolTip = (comment, heading) => {
    return (
      <>
        <Box>
          <Typography
            className={classes.statusHeading}
            style={{ textAlign: "left", paddingTop: "5px" }}
          >
            {heading}
          </Typography>
        </Box>
        {comment?.map((value) => {
          return (
            <>
              <Box
                style={{
                  display: "flex",
                  textAlign: "left",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                <Typography className={classes.statusName}>
                  {value.managerid}
                </Typography>
                {heading == "Rejected By " && (
                  <Typography className={classes.statusName}></Typography>
                )}
                <Typography
                  className={classes.statusProjectName}
                  style={{
                    display: "flex",
                    textAlign: "left",
                    paddingLeft: "5px",
                  }}
                >
                  {" "}
                  {/* {"|"} */}
                  {value.managerid == "" ? "" : "|"}
                  <span style={{ paddingLeft: "5px" }}> {value.projCode} </span>
                </Typography>{" "}
              </Box>
              <Typography
                className={classes.statusMessage}
                style={{ textAlign: "left" }}
              >
                {value.reason}
              </Typography>
            </>
          );
        })}
      </>
    );
  };

  const handleTooltipOpen = (comments, nextAction, index) => {
    setOpen(true);
    setCommentData(comments);
    setCommentHeading(nextAction);
    setCurrentIndex(index);
  };
  //console.log("checking the coomments", commentData);
  //console.log("checking the nest action", commentHeading);

  const handleParentToolTip = () => {
    // console.log(":parent tool tip function");
    return (
      <>
        <OutsideClickHandler
          onOutsideClick={() => {
            handleTooltipClose();
          }}
        >
          <div style={{ position: "relative" }}>
            <Tooltip
              PopperProps={{
                disablePortal: true,
                anchorEl: { toolAction },
              }}
              arrow
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={handleToolTip(commentData, commentHeading)}
              classes={{
                popper: classes.tooltipBox,
                arrow: classes.arrow,
              }}
            >
              <span
                style={{ maxwidth: "20px", minWidth: "0px", height: "0" }}
              ></span>
            </Tooltip>
          </div>
        </OutsideClickHandler>
      </>
    );
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const isTravelSelected = (name) => travelSelected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, myExpenseData?.length - page * rowsPerPage);
  const matches = useMediaQuery("(max-width:600px)");

  const handleExpand = (event, row) => {
    const selectedIndex = travelSelected.indexOf(row.expId);
    const travelExist = myExpenseTableTravelData.some(
      (obj) => obj.expId === row.expId
    );
    // console.log("Previous state travel", travelExist);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(travelSelected, row.expId);
      if (!travelExist) {
        let obj = {
          expId: row.expId,
          nday: nDay,
        };
        dispatch(getMyExpenseDataTravel(obj)); // api to get all the categories data for travel
      }
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(travelSelected.slice(1));
    } else if (selectedIndex === travelSelected.length - 1) {
      newSelected = newSelected.concat(travelSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        travelSelected.slice(0, selectedIndex),
        travelSelected.slice(selectedIndex + 1)
      );
    }
    setTravelSelected(newSelected);
  };
  return (
    <div className={matches ? classes.enhancedTable : classes.root}>
      {!matches && (
        <Paper
          elevation={0}
          className={
            window.screen.availWidth == 1280
              ? classes.paperZ150
              : window.screen.availWidth == 1536
              ? classes.paperZ125
              : classes.paperZ100
          }
        >
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer className={classes.tableContainer}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                rowsPerPage={rowsPerPage}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={myExpenseData?.length}
                getSetting={getSetting}
                myExpenseData={myExpenseData}
                onFilterArray={onFilterArray}
                getMyExpenseList={getMyExpenseList}
                multiDownload={multiDownload}
                pageNumber={page}
                handleFilterOff={handleFilterOff}
                setFilterOffToggleFunction={setFilterOffToggleFunction}
                filterOffToggle={filterOffToggle}
              />
              <TableBody>
                {stableSort(myExpenseData, getComparator(order, orderBy))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.expId);
                    const isTravelItemSelected = isTravelSelected(row.expId);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <>
                        <TableRow
                          hover
                          className={classes.tableRow}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.expId}
                          selected={isItemSelected}
                        >
                          <TableCell
                            style={{
                              paddingRight: "0px",
                              // paddingLeft: "12px",
                              // borderBottomWidth: "0px",
                            }}
                            // onClick={(event) => handleExpand(event, row)}
                          >
                            {row.tFlag ? (
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={(event) => handleExpand(event, row)}
                              >
                                {isTravelItemSelected ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            ) : (
                              <></>
                            )}
                          </TableCell>
                          <TableCell
                            padding="checkbox"
                            style={{
                              position: "sticky",
                              left: 0,
                              background: "white",
                              zIndex: 800,
                              minWidth: "53px",
                              // color:"#EFF2F8",
                            }}
                          >
                            <Checkbox
                              checked={isItemSelected}
                              checkedIcon={
                                <CheckBoxIcon
                                  fontSize="small"
                                  className={classes.checkBoxRoot}
                                />
                              }
                              onClick={(event) => handleClick(event, row)}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            align="left"
                            style={{ minWidth: "142px" }}
                            id={labelId}
                            scope="row"
                            padding="none"
                            onClick={() => {
                              //console.log("hi table roe clicked!");
                              const text = "/viewExpenseDetails/:id";
                              const result = text.replace(":id", row.expId);
                              history.push({
                                pathname: result,
                                state: {
                                  response: row,
                                  empId: 1,
                                  compName: "myExpenseListing",
                                },
                              });
                            }}
                          >
                            {row.expCode}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              maxWidth: "1px",
                            }}
                            onClick={() => {
                              //console.log("hi table roe clicked!");
                              const text = "/viewExpenseDetails/:id";
                              const result = text.replace(":id", row.expId);
                              history.push({
                                pathname: result,
                                state: {
                                  response: row,
                                  empId: 1,
                                  compName: "myExpenseListing",
                                },
                              });
                            }}
                          >
                            {row.categoryName}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              maxWidth: "1px",
                            }}
                            onClick={() => {
                              //console.log("hi table roe clicked!");
                              const text = "/viewExpenseDetails/:id";
                              const result = text.replace(":id", row.expId);
                              history.push({
                                pathname: result,
                                state: {
                                  response: row,
                                  empId: 1,
                                  compName: "myExpenseListing",
                                },
                              });
                            }}
                          >
                            {row.expName}
                          </TableCell>

                          <TableCell
                            align="left"
                            onClick={() => {
                              //console.log("hi table roe clicked!");
                              const text = "/viewExpenseDetails/:id";
                              const result = text.replace(":id", row.expId);
                              history.push({
                                pathname: result,
                                state: {
                                  response: row,
                                  empId: 1,
                                  compName: "myExpenseListing",
                                },
                              });
                            }}
                          >
                            {moment(row.createdOn).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              minWidth: "120px",
                              color: row?.currencyChanged ? "red" : "",
                              paddingRight:
                                window.screen.availWidth == 1280
                                  ? "36px"
                                  : window.screen.availWidth == 1536
                                  ? "44px"
                                  : "79px",
                            }}
                            onClick={() => {
                              //console.log("hi table roe clicked!");
                              const text = "/viewExpenseDetails/:id";
                              const result = text.replace(":id", row.expId);
                              history.push({
                                pathname: result,
                                state: {
                                  response: row,
                                  empId: 1,
                                  compName: "myExpenseListing",
                                },
                              });
                            }}
                          >
                            <div>
                              {row.currencyChanged == 1 && (
                                <Tooltip title="Default currency has been changed.">
                                  <span style={{ color: "red" }}>
                                    {row?.currency}
                                    {row.claimedAmount
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </span>
                                </Tooltip>
                              )}
                              {!row.currencyChanged && (
                                <>
                                  {" "}
                                  {row?.currency}
                                  {row.claimedAmount
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </>
                              )}
                            </div>
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              minWidth: "120px",
                              paddingRight:
                                window.screen.availWidth == 1280
                                  ? "32px"
                                  : window.screen.availWidth == 1536
                                  ? "44px"
                                  : "90px",
                            }}
                            onClick={() => {
                              //console.log("hi table roe clicked!");
                              const text = "/viewExpenseDetails/:id";
                              const result = text.replace(":id", row.expId);
                              history.push({
                                pathname: result,
                                state: {
                                  response: row,
                                  empId: 1,
                                  compName: "myExpenseListing",
                                },
                              });
                            }}
                          >
                            <div>
                              {row.currencyChanged == 1 && (
                                <Tooltip title="Default currency has been changed.">
                                  <span style={{ color: "red" }}>
                                    {row?.currency}
                                    {row.approvedAmount
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </span>
                                </Tooltip>
                              )}
                              {!row.currencyChanged && (
                                <>
                                  {" "}
                                  {row?.currency}
                                  {row.approvedAmount
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </>
                              )}
                            </div>
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              justifyContent: "center",
                              paddingRight: "0px",
                            }}
                          >
                            <Box className={classes.statusBox}>
                              <Chip
                                onClick={() => {
                                  //console.log("hi table roe clicked!");
                                  const text = "/viewExpenseDetails/:id";
                                  const result = text.replace(":id", row.expId);
                                  history.push({
                                    pathname: result,
                                    state: {
                                      response: row,
                                      empId: 1,
                                      compName: "myExpenseListing",
                                    },
                                  });
                                }}
                                label={row.status}
                                style={{
                                  color: "white",
                                  width: "97px",
                                  height: "24px",
                                  marginRight:
                                    row.status === "Paid" ||
                                    row.status === "Draft"
                                      ? "20px"
                                      : "0px",
                                  background:
                                    row.status === "In Progress"
                                      ? "#E0B15C"
                                      : row.status === "Approved"
                                      ? " #349259"
                                      : row.status === "Rejected"
                                      ? "#D40606"
                                      : row.status === "Paid"
                                      ? "#406086"
                                      : "#9E9E9E",
                                }}
                              />
                              {/* { handleTooltipOpen(row.comments, row.nextAction)} */}
                              {(row.status == "In Progress" ||
                                row.status == "Approved" ||
                                row.status == "Rejected") && (
                                <div>
                                  <InfoOutlinedIcon
                                    ref={target}
                                    onClick={(event) => {
                                      handleTooltipOpen(
                                        row.comments,
                                        row.nextAction,
                                        index
                                      );
                                      handlToolAction(event);
                                    }}
                                    className={classes.tooltip}
                                  />

                                  {open &&
                                    index === currentIndex &&
                                    handleParentToolTip()}
                                </div>
                              )}
                            </Box>
                          </TableCell>
                          {attachment && (
                            <TableCell
                              align="center"
                              onClick={() => {
                                //console.log("hi table roe clicked!");
                                const text = "/viewExpenseDetails/:id";
                                const result = text.replace(":id", row.expId);
                                history.push({
                                  pathname: result,
                                  state: {
                                    response: row,
                                    empId: 1,
                                    compName: "myExpenseListing",
                                  },
                                });
                              }}
                            >
                              {" "}
                              {row.attachments}
                            </TableCell>
                          )}
                          {projectId && (
                            <TableCell
                              align="left"
                              style={{ minWidth: "142px" }}
                              onClick={() => {
                                //console.log("hi table roe clicked!");
                                const text = "/viewExpenseDetails/:id";
                                const result = text.replace(":id", row.expId);
                                history.push({
                                  pathname: result,
                                  state: {
                                    response: row,
                                    empId: 1,
                                    compName: "myExpenseListing",
                                  },
                                });
                              }}
                            >
                              {row.projectId}
                            </TableCell>
                          )}
                          <TableCell
                            style={{
                              position: "sticky",
                              right: 0,
                              background: "white",
                              zIndex: 800,
                              width: "53px",
                              paddingLeft: "0px",
                            }}
                            align="center"
                          >
                            <MoreVertIcon
                              className={classes.dotIcon}
                              onClick={(event) => {
                                handleClickAction(event);

                                setPopRow(row);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                        {/* {myExpenseTravelData?.some(
                          (obj) => obj.expId === row.expId
                        ) && ( */}
                        {isTravelItemSelected ? (
                          <TableRow>
                            <TableCell style={{ padding: 0 }} colSpan={12}>
                              <Collapse
                                in={isTravelItemSelected}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Box>
                                  <Table aria-label="purchases">
                                    <TableHead>
                                      <TableRow
                                        style={{
                                          background: "#f4f4f4",
                                        }}
                                      >
                                        <TableCell
                                          style={{
                                            width:
                                              window.screen.availWidth == 1280
                                                ? "7.5rem"
                                                : window.screen.availWidth ==
                                                  1536
                                                ? "7.5rem"
                                                : "9.2rem",
                                            // maxWidth: "7.5rem",
                                          }}
                                        />
                                        <TableCell
                                          style={{
                                            width:
                                              window.screen.availWidth == 1280
                                                ? "7.5rem"
                                                : window.screen.availWidth ==
                                                  1536
                                                ? "8rem"
                                                : "10rem",

                                            // maxWidth: "7.5rem",
                                          }}
                                        />
                                        <TableCell
                                          style={{
                                            fontWeight: "600",
                                          }}
                                        >
                                          Expense Category
                                        </TableCell>
                                        {/* <TableCell /> */}
                                        <TableCell
                                          style={{
                                            fontWeight: "600",
                                            // paddingLeft: "16px",
                                            width:
                                              window.screen.availWidth == 1280
                                                ? "8.5rem"
                                                : window.screen.availWidth ==
                                                  1536
                                                ? "10.9rem"
                                                : "14.1rem",
                                            // textAlign:window.screen.availWidth == 1920:'left':'right'
                                          }}
                                        >
                                          Claim Amount
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            fontWeight: "600",
                                            width:
                                              window.screen.availWidth == 1280
                                                ? "362px"
                                                : window.screen.availWidth ==
                                                  1536
                                                ? "416px"
                                                : "523px",
                                          }}
                                        >
                                          Approved Amount
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    {/* {console.log(
                                    "myExpenseTableTravelData",
                                    myExpenseTableTravelData
                                  )} */}
                                    <TableBody>
                                      {myExpenseTableTravelData?.map(
                                        (travelRow, index) => {
                                          // console.log("travelRow",travelRow,row)
                                          return Number(travelRow.parentExpId) ===
                                            row.expId ? (
                                            <TableRow key={index}>
                                              <TableCell
                                              // style={{
                                              // width:
                                              //     window.screen.availWidth ==
                                              //     1280
                                              //       ? "7.5rem"
                                              //       : window.screen
                                              //           .availWidth == 1536
                                              //       ? "7.5rem"
                                              //       : "9.2rem",
                                              //   // maxWidth: "7.5rem",
                                              // }}
                                              />
                                              <TableCell
                                              // style={{
                                              //   width:
                                              //     window.screen.availWidth ==
                                              //     1280
                                              //       ? "7.5rem"
                                              //       : window.screen
                                              //           .availWidth == 1536
                                              //       ? "8rem"
                                              //       : "10rem",

                                              //   // maxWidth: "7.5rem",
                                              // }}
                                              />
                                              <TableCell
                                                style={{
                                                  maxWidth: "20rem",
                                                  // width: "90px",
                                                  textOverflow: "ellipsis",
                                                  textWrap: "nowrap",
                                                  overflow: "hidden",
                                                }}
                                              >
                                                {travelRow.categoryName}
                                              </TableCell>
                                              {/* <TableCell
                                              style={{
                                                width:
                                                  window.screen.availWidth ==
                                                  1280
                                                    ? "15.125rem"
                                                    : window.screen
                                                        .availWidth == 1536
                                                    ? "21rem"
                                                    : "25rem",

                                                // maxWidth: "15.125rem",
                                              }}
                                            /> */}
                                              <TableCell
                                                align="right"
                                                style={{
                                                  paddingRight:
                                                    window.screen.availWidth ==
                                                    1280
                                                      ? "1.2rem"
                                                      : window.screen
                                                          .availWidth == 1536
                                                      ? "2.8rem"
                                                      : "5rem",
                                                  width:
                                                    window.screen.availWidth ==
                                                    1280
                                                      ? "8.5rem"
                                                      : window.screen
                                                          .availWidth == 1536
                                                      ? "10.9rem"
                                                      : "14.1rem",
                                                }}
                                              >
                                                {row?.currency}
                                                {travelRow.claimedAmount
                                                  .toString()
                                                  .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}
                                              </TableCell>

                                              <TableCell
                                                style={{
                                                  width: "344px",
                                                  textAlign: "right",
                                                  paddingRight:
                                                    window.screen.availWidth ==
                                                    1280
                                                      ? "15rem"
                                                      : window.screen
                                                          .availWidth == 1536
                                                      ? "16rem"
                                                      : "21.6rem",
                                                }}
                                              >
                                                {/* {row?.currency}
                                              {travelRow.claimedAmount
                                                .toString()
                                                .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                )} */}
                                                {row?.currency}
                                                {travelRow.approvedAmount
                                                  .toString()
                                                  .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}
                                              </TableCell>
                                            </TableRow>
                                          ) : (
                                            <></>
                                          );
                                        }
                                      )}
                                    </TableBody>
                                  </Table>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <></>
                        )}
                        {/* )} */}
                      </>
                    );
                  })}

                {popRow !== null && (
                  <ExpenseAction
                    popUpAction={popUpAction}
                    action={action}
                    handlePopupAction={handlePopupAction}
                    row={popRow}
                    handleDelete={handleDelete}
                    handleDownload={handleDownload}
                    singleDownload={singleDownload}
                    DeleteMyExpense={DeleteMyExpense}
                    ssoEmpId={
                      delEmpIDRedux != null &&
                      delEmpIDRedux != "" &&
                      delEmpIDRedux != ssoEmpId
                        ? delEmpIDRedux
                        : ssoEmpId
                    }
                    handleFilterOff={handleFilterOff}
                  />
                )}
                {emptyRows > 0 && (
                  <TableRow className={classes.tableRow}>
                    <TableCell colSpan={12} style={{ minWidth: "120px" }} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="caption"
                style={{
                  paddingLeft: "8px",
                }}
              >
                <InfoOutlinedIcon />
              </Typography>

              <Typography
                variant="caption"
                style={{
                  paddingLeft: "8px",
                }}
              >
                <b>Click on any row to view details</b>
              </Typography>
            </Box>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="Box"
              count={myExpenseData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            ></TablePagination>
          </Box>
        </Paper>
      )}

      {matches && (
        <TableContainer component={Paper} sx={{ width: "max-content" }}>
          <Table>
            {/* <TableHead>
          <TableRow>
            <TableCell style={{ padding: "0.4%" }}>
              <IconButton onClick={toggleDrawer(anchorHamburger, true)}>
                <MenuIcon />
              </IconButton>
            </TableCell>
            <TableCell align="left" style={{ padding: "0.4%" }}>
              <Box>
                <BorderAllIcon />
                &nbsp; 5 Expenses
              </Box>
            </TableCell>
            <TableCell align="right" style={{ padding: "0.4%" }}>
              &nbsp;
            </TableCell>
            <TableCell align="right" style={{ padding: "0.4%" }}>
              &nbsp;
            </TableCell>
            <TableCell align="right" style={{ padding: "0.4%" }}>
              <IconButton onClick={toggleDrawer(anchorFilter1, true)}>
                <span class="material-symbols-outlined">filter_alt</span>
              </IconButton>
              <Drawer
                anchor={anchorFilter1}
                open={state[anchorFilter1]}
                onClose={toggleDrawer(anchorFilter1, false)}
              >
                {list(anchorFilter1)}
              </Drawer>
              <Drawer
                anchor={anchorHamburger}
                open={state[anchorHamburger]}
                onClose={toggleDrawer(anchorHamburger, false)}
              >
                {hamburgerList(anchorHamburger)}
              </Drawer>
            </TableCell>
          </TableRow>
        </TableHead> */}
            <TableHead>
              <TableRow style={{ backgroundColor: "#F4F4F4" }}>
                <TableCell align="left" style={{ padding: "0.4%" }}>
                  <Checkbox />
                </TableCell>
                <TableCell align="left" style={{ padding: "0.4%" }}>
                  Expense Name
                </TableCell>
                <TableCell align="center" style={{ padding: "0.4%" }}>
                  Claim Amount
                </TableCell>
                <TableCell align="center" style={{ padding: "0.4%" }}>
                  Approved Amount
                </TableCell>
                <TableCell align="center" style={{ padding: "0.4%" }}>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {myExpenseData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.expId);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow key={row.number}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ padding: "0.4%" }}
                      >
                        <Checkbox
                          checked={isItemSelected}
                          checkedIcon={
                            <CheckBoxIcon
                              fontSize="small"
                              className={classes.checkBoxRoot}
                            />
                          }
                          onClick={(event) => handleClick(event, row)}
                          // inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell align="left" style={{ padding: "0.4%" }}>
                        {row.expName}
                      </TableCell>
                      <TableCell align="right" style={{ padding: "0.4%" }}>
                        {row.currencyChanged == 1 && (
                          <Tooltip title="User has changed it's default currency">
                            <span style={{ color: "red" }}>
                              {row?.currency}
                              {row.claimedAmount
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                          </Tooltip>
                        )}
                        {!row.currencyChanged && (
                          <>
                            {" "}
                            {row?.currency}
                            {row.claimedAmount
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </>
                        )}
                      </TableCell>
                      <TableCell align="right" style={{ padding: "0.4%" }}>
                        {row.currencyChanged == 1 && (
                          <Tooltip
                            title=<strong>
                              "User has changed it's default currency"{" "}
                            </strong>
                          >
                            <span style={{ color: "red" }}>
                              {row?.currency}
                              {row.approvedAmount
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                          </Tooltip>
                        )}
                        {!row.currencyChanged && (
                          <>
                            {" "}
                            {row?.currency}
                            {row.approvedAmount
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                        <Box style={{ display: "flex" }}>
                          <Chip
                            onClick={() => {
                              //console.log("hi table roe clicked!");
                              const text = "/viewExpenseDetails/:id";
                              const result = text.replace(":id", row.expId);
                              history.push({
                                pathname: result,
                                state: {
                                  response: row,
                                  empId: 1,
                                  compName: "myExpenseListing",
                                },
                              });
                            }}
                            label={row.status}
                            style={{
                              color: "white",
                              width: "110px",
                              height: "24px",
                              marginRight:
                                row.status === "Paid" ? "20px" : "0px",
                              background:
                                row.status === "In Progress"
                                  ? "#E0B15C"
                                  : row.status === "Approved"
                                  ? " #349259"
                                  : row.status === "Rejected"
                                  ? "#D40606"
                                  : row.status === "Paid"
                                  ? "#406086"
                                  : "#9E9E9E",
                            }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              <TableRow>
                {/* <TableCell style={{ padding: "0.4%" }}>&nbsp;</TableCell>
            <TableCell align="right" style={{ padding: "0.4%" }}>
              <Button variant="outlined">{"<"}</Button>
            </TableCell>
            <TableCell align="center" style={{ padding: "0.4%" }}>
              <Button variant="outlined">{"1/1"}</Button>
            </TableCell>
            <TableCell align="left" style={{ padding: "0.4%" }}>
              <Button variant="outlined">{">"}</Button>
            </TableCell>
            <TableCell style={{ padding: "0.4%" }}>&nbsp;</TableCell> */}

                {/*  */}
              </TableRow>
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[]}
            component="Box"
            count={myExpenseData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            // ActionsComponent={TablePaginationActions}
          ></TablePagination>
        </TableContainer>
      )}
    </div>
  );
}
