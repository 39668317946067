import React, { useEffect, useReducer, useRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { lighten, makeStyles, styled } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Divider from "@material-ui/core/Divider";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import ApproveRejectDialog from "./approveRejectDialog.js";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { NumericFormat } from "react-number-format";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import rows from "../Utils/teamApprovalDummyData.json";
import GetAppIcon from "@material-ui/icons/GetAppOutlined";
import Filterimg from "../images/filter.svg";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import Drawer from "@material-ui/core/Drawer";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormGroup,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Popover,
  Popper,
  Select,
  TextField,
  Snackbar,
} from "@material-ui/core";
import SnackBar from "./snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import DateFnsUtils from "@date-io/date-fns";
import SettingsIcon from "@material-ui/icons/Settings";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Switch from "@material-ui/core/Switch";
import { Grid } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  getMyTeamApprovalData,
  postApproveRejectExpenseData,
  resetExpenseResponse,
  deleteMyExpenseData,
  getMyExpenseDataTravel,
} from "../redux/Expense/action";
import moment from "moment";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import { CustomSwitch } from "./customSwitch.js";
import OutsideClickHandler from "react-outside-click-handler";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { useTheme } from "@material-ui/core/styles";
import Encrypt from "../Utils/encryption.js";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import EditIcon from "@material-ui/icons/Edit";
import DeleteDialog from "./deleteDialog";
import List from "@material-ui/core/List";
import MobileFloatingButton from "./mobileFloatingButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ApproveRejectSnackbar from "../components/approveRejectSnackbar";
import Badge from "@material-ui/core/Badge";
//import { button } from "aws-amplify";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 13,
    top: 31,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    //console.log("descendingComparator: -1");
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    //console.log("descendingComparator: 1");
    return 1;
  }
  return 0;
}
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootFooter}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis?.map((el) => el[0]);
}

function TeamApprovalTableHead(props) {
  const [anchorEl0, setAnchorEl0] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const [anchorEl5, setAnchorEl5] = React.useState(null);
  const [anchorEl6, setAnchorEl6] = React.useState(null);
  const [anchorEl7, setAnchorEl7] = React.useState(null);
  const [anchorEl8, setAnchorEl8] = React.useState(null);
  const [anchorEl9, setAnchorEl9] = React.useState(null);
  const [anchorEl10, setAnchorEl10] = React.useState(null);

  const [criteriaClaimAmount, setCriteriaClaimAmount] = React.useState(0);
  const [operatorClaimAmount, setOperatorClaimAmount] = React.useState("");
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [teamAppPurpose, setTeamAppPurpose] = React.useState(false);
  const [teamAppCreationDate, setTeamAppCreationDate] = React.useState(false);
  const [teamAppAttachments, setTeamAppAttachments] = React.useState(false);
  const [teamAppProjectId, setTeamAppProjectId] = React.useState(false);
  const [openBulkApproveDialog, setOpenBulkApproveDialog] =
    React.useState(false);
  const [approveOrReject, setApproveOrReject] = React.useState(1);
  const [expenseId, setExpenseId] = React.useState(null);
  const [expenseCategory, setExpenseCategory] = React.useState(null);
  const [buttonState, setButtonState] = React.useState(true);
  const [selectedPolicyLimit, setselectedPolicyLimit] = useState("lteq");
  const [filterOffToggle, setFilterOffToggle] = useState("");
  const [rowVal, setRowVal] = React.useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState();
  const [currentIndex, setCurrentIndex] = React.useState();
  const [selected, setSelected] = React.useState([]);
  const [singleExpense, setSingleExpense] = useState("");
  const [comments, setComments] = React.useState("");
  const [refreshTableToggle, forceUpdate] = useReducer((x) => x + 1, 0);
  const [filterShow, setFilterShow] = React.useState(false);
  const [countShow, setCountShow] = React.useState(0);
  const [systemErrorSnackbar, setSystemErrorSnackbar] = useState(false);
  const [selectedOperatorClaimAmount, setslectedOperatorClaimAmount] =
    useState("lteq");
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowsPerPage,
    pageNumber,
    rowCount,
    onRequestSort,
    onClickFilter,
    getMyTeamApprovalList,
    bulkDownload,
    empId,
    resetBulkDownload,
  } = props;
  const dispatch = useDispatch();

  const delEmpIDRedux = useSelector(
    (state) => state.createExpenseData.delEmpId
  );
  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );

  const headCells = [
    {
      id: "categoryName",
      numeric: false,
      disablePadding: false,
      label: "Expense Category",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Employee",
    },
    {
      id: "claimedAmount",
      numeric: true,
      disablePadding: false,
      label: "Claim Amount",
    },
    {
      id: "expStatus",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
  ];

  const headCells1 = [
    {
      id: "categoryName",
      numeric: false,
      disablePadding: false,
      label: "Expense Category",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Employee",
    },
    {
      id: "claimedAmount",
      numeric: true,
      disablePadding: false,
      label: "Claim Amount",
    },
    {
      id: "expStatus",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
  ];

  const headers = [
    {
      label: "Expense Code",
      key: "expCode",
    },
    {
      label: "Category Name",
      key: "categoryName",
    },
    {
      label: "To Location",
      key: "toLocation",
    },
    {
      label: "From Location",
      key: "fromLocation",
    },
    {
      label: "Distance (Km/Miles)",
      key: "distance",
    },
    {
      label: "From Date",
      key: "fromDate",
    },
    {
      label: "To Date",
      key: "toDate",
    },
    {
      label: "Claim Month",
      key: "forMonth",
    },
    {
      label: "Created On",
      key: "createdOn",
    },
    {
      label: "Currency",
      key: "currencyCode",
    },
    {
      label: "Relationship",
      key: "dependent",
    },
    {
      label: "Claimed Amount",
      key: "claimedAmount",
    },
    {
      label: "Policy Limit",
      key: "policyLimit",
    },
    {
      label: "Purpose",
      key: "purpose",
    },
    {
      label: "Status",
      key: "expStatus",
    },
    {
      label: "Attachments",
      key: "attachments",
    },
    {
      label: "Project ID",
      key: "projectId",
    },
    {
      label: "Employee",
      key: "createdBy",
    },
  ];

  const csvLink = {
    headers: headers,
    data: bulkDownload,
    filename: `TeamApproval_${moment(new Date()).format("DDMMYYYY_HHmm")}.csv`,
  };

  useEffect(() => {
    if (delEmpIDRedux != null) {
      //setEmpId(delEmpIDRedux);
      let obj = {
        empId: delEmpIDRedux,
        nday: props.nDayVal,
      };
      dispatch(getMyTeamApprovalData(obj));
    } else {
      //setEmpId(props.ssoEmpId);
      let obj = {
        empid: props.ssoEmpId,
        nday: props.nDayVal,
      };
      dispatch(getMyTeamApprovalData(obj));
    }
  }, [props.ndayValue, refreshTableToggle, delEmpIDRedux]);

  const handleSingleApproveRejectExpense = async (value) => {
    // console.log('called')
    // console.log("value:  ====>  ", value);
    //console.log("empId:  ====>  ", empId);
    //console.log("comments:  ====>  ", comments);

    let array = [];
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    array.push({
      recid: Encrypt(key, JSON.stringify(Number(value.uniqueId))),
      expid: Encrypt(key, JSON.stringify(Number(value.expId))),
      projid: Encrypt(key, JSON.stringify(Number(value.projUniqueId))),
    });
    let dataObject = {
      empId: Encrypt(key, JSON.stringify(empId)),
      comments: comments,
      project: array,
    };
    // dispatch(postApproveRejectExpenseData(dataObject));
    // dispatch(resetExpenseResponse());
    // setComments("");
    // dispatch(getMyTeamApprovalData({ empId: empId, nday: props.nDayVal }));
    // forceUpdate();
    // props.resetBulkDownload();

    // try{
    //   const response = await dispatch(postApproveRejectExpenseData(dataObject));
    //   if(response.status != 200){
    //     setSystemErrorSnackbar(true);
    //   }
    //   else{
    //     dispatch(resetExpenseResponse());
    //   setComments("");
    //   dispatch(getMyTeamApprovalData({ empId: empId, nday: props.nDay }));
    //   forceUpdate();
    //   props.resetBulkDownload();
    //   }

    // }catch{
    //   setSystemErrorSnackbar(true);
    // }

    dispatch(postApproveRejectExpenseData(dataObject))
      .then((resp) => {
        if (resp.status != 200) {
          setSystemErrorSnackbar(true);
        } else {
          // dispatch(resetExpenseResponse());

          setComments("");
          dispatch(
            getMyTeamApprovalData({ empId: empId, nday: props.nDayVal })
          );
          props.resetBulkDownload();
          props.setOpenAppRejSB();
          forceUpdate();
        }
      })
      .catch((err) => setSystemErrorSnackbar(true));
  };

  const handleComments = (data) => {
    //console.log("data", data);
    setComments(data);
  };

  const createSortHandler = (property) => (event) => {
    //console.log("event", event);
    //console.log("property", property);
  };
  const handleOpenBulkApproveDialog = (row, index) => {
    setCurrentIndex(index);
    //console.log("handleOpenBulkApproveDialog");
    setOpenBulkApproveDialog(true);
    setSingleExpense(row);
    setApproveOrReject(1);
  };
  const handleCloseBulkApproveDialog = () => {
    //console.log("openBulkDialog--- in fxn", openBulkApproveDialog);
    setOpenBulkApproveDialog(false);
  };

  const handleOpenDeleteDialog = (row) => {
    //console.log("handleOpenDeleteDialog row", row);

    setOpenDeleteDialog(true);
    setCurrentIndex(row);
  };

  const handleOpenRejectionDialog = (row, index) => {
    setCurrentIndex(index);
    //console.log("handleOpenRejectionDialog");
    setOpenBulkApproveDialog(true);
    setSingleExpense(row);
    setApproveOrReject(0);
  };

  const handleCloseDeleteDialog = (row) => {
    //console.log("handleOpenDeleteDialog row", row);

    setOpenDeleteDialog(false);
  };

  const handleDelete = (value) => {
    let obj = {
      expID:
        value.expId != undefined && value.expId ? value.expId : rowVal.expId,
      empId: delEmpIDRedux != null ? delEmpIDRedux : ssoEmpIdDetails?.empId,
    };
    dispatch(deleteMyExpenseData(obj));
    // console.log("dispatch(resetExpenseResponse()); AK1 ");
    dispatch(resetExpenseResponse()); //-----------------------------------------
    setSelected([]);
    // onFilterArray(getMyExpenseList);
  };

  const handleBulkApprove = async () => {
    let array = [];
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    bulkDownload?.map((item) =>
      array.push({
        recid: Encrypt(key, JSON.stringify(Number(item.uniqueId))),
        expid: Encrypt(key, JSON.stringify(Number(item.expId))),
        projid: Encrypt(key, JSON.stringify(Number(item.projUniqueId))),
      })
    );
    //console.log("array:====> ", array);
    let dataObj = {
      empId: Encrypt(key, JSON.stringify(empId)),
      comments: "",
      project: array,
    };
    // dispatch(postApproveRejectExpenseData(dataObj));
    // dispatch(resetExpenseResponse());
    // // resetBulkDownload();
    // handleCloseBulkApproveDialog();
    // dispatch(getMyTeamApprovalData({ empId: empId, nday: props.nDayVal }));
    // forceUpdate();
    // props.resetBulkDownload();

    // try {
    //   const response = await dispatch(postApproveRejectExpenseData(dataObj));
    //   if (response.status != 200) {
    //     setSystemErrorSnackbar(true);
    //     handleCloseBulkApproveDialog();
    //   } else {
    //     dispatch(resetExpenseResponse());
    //     handleCloseBulkApproveDialog();
    //     dispatch(getMyTeamApprovalData({ empId: empId, nday: props.nDayVal }));
    //     forceUpdate();
    //     props.resetBulkDownload();
    //   }
    // } catch {
    //   setSystemErrorSnackbar(true);
    //   handleCloseBulkApproveDialog();
    // }

    dispatch(postApproveRejectExpenseData(dataObj))
      .then((resp) => {
        if (resp.status != 200) {
          setSystemErrorSnackbar(true);
          handleCloseBulkApproveDialog();
        } else {
          // dispatch(resetExpenseResponse());
          dispatch(
            getMyTeamApprovalData({ empId: empId, nday: props.nDayVal })
          );
          props.resetBulkDownload();
          handleCloseBulkApproveDialog();
          forceUpdate();
        }
      })
      .catch((err) => {
        setSystemErrorSnackbar(true);
        handleCloseBulkApproveDialog();
      });
  };

  //console.log("onClickFilter", onClickFilter);
  const handleEndDateChange = (date) => {
    setButtonState(!(startDate && date));

    setEndDate(date);
  };
  const handleStartDateChange = (date) => {
    setButtonState(!(endDate && date));
    setStartDate(date);
  };
  //console.log("bulkDownload: ---->  ", bulkDownload);
  //console.log("endDate", endDate);
  function handleFilterClaimAmount(name) {
    let tempFilterArray = [];
    if (name == "expCode") {
      tempFilterArray = getMyTeamApprovalList?.filter(
        (item) =>
          item.expCode
            .toLowerCase()
            .includes(criteriaClaimAmount.toLowerCase()) == true
      );
    } else if (name == "claimedAmount") {
      if (selectedOperatorClaimAmount == "gteq") {
        // //console.log(
        //   "gteq----------",
        //   name,
        //   selectedOperatorClaimAmount,
        //   getMyTeamApprovalList
        // );
        tempFilterArray = getMyTeamApprovalList?.filter(
          (item) => item.claimedAmount >= criteriaClaimAmount
        );
        //console.log("tempFilterArray23", tempFilterArray);
      } else if (selectedOperatorClaimAmount == "lteq") {
        tempFilterArray = getMyTeamApprovalList?.filter(
          (item) => item.claimedAmount <= criteriaClaimAmount
        );
      }
    } else if (name == "policyLimit") {
      // //console.log("getMyteamApprovalList",getMyTeamApprovalList)
      // //console.log("criteriaClaimAmount",criteriaClaimAmount)
      if (selectedPolicyLimit == "gteq") {
        tempFilterArray = getMyTeamApprovalList?.filter(
          (item) => item.policyLimit >= criteriaClaimAmount
        );
      } else if (selectedPolicyLimit == "lteq") {
        tempFilterArray = getMyTeamApprovalList?.filter(
          (item) => item.policyLimit <= criteriaClaimAmount
        );
      }
      //console.log("operatorClaimAmount", operatorClaimAmount);
    } else if (name == "categoryName") {
      tempFilterArray = getMyTeamApprovalList?.filter(
        (item) => item.categoryName === criteriaClaimAmount
      );
    } else if (name == "createdBy") {
      tempFilterArray = getMyTeamApprovalList?.filter(
        (item) => item.createdBy == criteriaClaimAmount
      );
    } else if (name == "expStatus") {
      tempFilterArray = getMyTeamApprovalList?.filter(
        (item) => item.expStatus == criteriaClaimAmount
      );
    } else if (name == "purpose") {
      tempFilterArray = getMyTeamApprovalList?.filter(
        (item) => item.purpose == criteriaClaimAmount
      );
    } else if (name == "createdOn") {
      // console.log("tAppr1 startDate", startDate);
      // console.log("tAppr1 endDate", endDate);

      var d1 = new Date(startDate).toLocaleDateString();
      var d2 = new Date(endDate).toLocaleDateString();
      // console.log("tAppr1 d1", d1);
      // console.log("tAppr1 d2", d2);
      // console.log("tAppr1 createdOn", getMyTeamApprovalList[0].createdOn);
      // console.log(
      //   "tAppr1 createdOn",
      //   new Date(getMyTeamApprovalList[0].createdOn).toLocaleDateString()
      // );

      tempFilterArray = getMyTeamApprovalList?.filter(
        (item) =>
          new Date(item.createdOn).toLocaleDateString() >= d1 &&
          new Date(item.createdOn).toLocaleDateString() <= d2
      );
    } else if (name == "attachments") {
      tempFilterArray = getMyTeamApprovalList?.filter(
        (item) => Number(item.attachments) == Number(criteriaClaimAmount)
      );
    } else if (name == "projectId") {
      tempFilterArray = getMyTeamApprovalList?.filter(
        (item) => item.projectId.includes(criteriaClaimAmount) == true
      );
    }
    //WTD-1 : Add filter for ALL settings columns
    //console.log("tempFilterArray", tempFilterArray);
    onClickFilter(tempFilterArray);
    setFilterOffToggle(name);
    handleClosePopper();
  }
  const handleAddColumn = (name, value) => {
    //console.log("handleAddColumn : name :", name, " value: ", value);
    props.handleSettingsParent(name, value);
    if (name == "purpose") {
      setTeamAppPurpose(value);
    } else if (name == "createdOn") {
      setTeamAppCreationDate(value);
    } else if (name == "attachments") {
      setTeamAppAttachments(value);
    } else if (name == "projectId") {
      setTeamAppProjectId(value);
    }
  };
  const handleClosePopper = () => {
    setAnchorEl0(null);
    setAnchorEl1(null);
    setAnchorEl2(null);
    setAnchorEl3(null);
    setAnchorEl4(null);
    setAnchorEl5(null);
    setAnchorEl6(null);
    setAnchorEl7(null);
    setAnchorEl8(null);
    setAnchorEl9(null);
    setAnchorEl10(null);
    setButtonState(true);
    setExpenseId("");
    setExpenseCategory("");
    setCriteriaClaimAmount("");
    setOperatorClaimAmount("");
    setOpenBulkApproveDialog("");
    setStartDate(null);
    setEndDate(null);
  };
  const handleRemoveFilter = () => {
    onClickFilter(getMyTeamApprovalList);
    handleClosePopper();
  };
  const handleFilterOff = () => {
    onClickFilter(getMyTeamApprovalList);
    handleClosePopper();
    setFilterOffToggle(false);
  };
  const handleFilterPopper0 = (event) => {
    setAnchorEl0(anchorEl0 ? null : event.currentTarget);
  };
  const handleFilterPopper1 = (event) => {
    setAnchorEl1(anchorEl1 ? null : event.currentTarget);
  };
  const handleFilterPopper2 = (event) => {
    setAnchorEl2(anchorEl2 ? null : event.currentTarget);
  };
  const handleFilterPopper3 = (event) => {
    setAnchorEl3(anchorEl3 ? null : event.currentTarget);
  };
  const handleFilterPopper4 = (event) => {
    setAnchorEl4(anchorEl4 ? null : event.currentTarget);
  };
  const handleFilterPopper5 = (event) => {
    setAnchorEl5(anchorEl5 ? null : event.currentTarget);
  };
  const handleFilterPopper7 = (event) => {
    setAnchorEl7(anchorEl7 ? null : event.currentTarget);
  };
  const handleFilterPopper8 = (event) => {
    setAnchorEl8(anchorEl8 ? null : event.currentTarget);
  };
  const handleFilterPopper9 = (event) => {
    setAnchorEl9(anchorEl9 ? null : event.currentTarget);
  };
  const handleFilterPopper10 = (event) => {
    setAnchorEl10(anchorEl10 ? null : event.currentTarget);
  };
  const handleSettingPopper = (event) => {
    setAnchorEl6(anchorEl6 ? null : event.currentTarget);
  };
  const open0 = Boolean(anchorEl0);
  const id0 = open0 ? "simple-popper" : undefined;
  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "simple-popper" : undefined;
  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? "simple-popper" : undefined;
  const open3 = Boolean(anchorEl3);
  const id3 = open3 ? "simple-popper" : undefined;
  const open4 = Boolean(anchorEl4);
  const id4 = open4 ? "simple-popper" : undefined;
  const open5 = Boolean(anchorEl5);
  const id5 = open5 ? "simple-popper" : undefined;
  const open6 = Boolean(anchorEl6);
  const id6 = open6 ? "simple-popper" : undefined;
  const open7 = Boolean(anchorEl7);
  const id7 = open7 ? "simple-popper" : undefined;
  const open8 = Boolean(anchorEl8);
  const id8 = open8 ? "simple-popper" : undefined;
  const open9 = Boolean(anchorEl9);
  const id9 = open9 ? "simple-popper" : undefined;
  const open10 = Boolean(anchorEl10);
  const id10 = open10 ? "simple-popper" : undefined;

  //console.log("criteriaClaimAmount:----", criteriaClaimAmount);
  //console.log("operatorClaimAmount:----", operatorClaimAmount);
  //console.log("numSelected---->", numSelected);
  //console.log("bulk download---->", bulkDownload);
  //console.log("rowCount---->", rowCount);

  const options = [
    {
      label: ">=",

      value: ">=",
    },

    {
      label: "<=",

      value: "<=",
    },
  ];

  const data = [
    {
      label: ">=",
      value: ">=",
    },
    {
      label: "<=",
      value: "<=",
    },
  ];
  // //console.log(" rowsPerPage",  rowsPerPage)
  const matches = useMediaQuery("(max-width:600px)");
  const headCellValue = matches && headCells;
  return (
    <TableHead>
      {/* ayushi */}
      {bulkDownload?.length > 0 && (
        <TableRow style={{ backgroundColor: "#F4F4F4" }}>
          <TableCell style={{ padding: "0px" }} />
          <TableCell
            align="left"
            style={{
              padding: "0px",
              width: "28px",
              minWidth: "28px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {numSelected === rowsPerPage ||
            (pageNumber === Math.ceil(rowCount / rowsPerPage) - 1 &&
              numSelected === rowCount % rowsPerPage) ? (
              <IconButton
                style={{
                  // maxWidth: "40px",
                  // maxHeight: "40px",
                  //padding:'0px'
                  padding: "12px 0px 12px",
                }}
              >
                <CheckBoxIcon
                  fontSize="small"
                  onClick={onSelectAllClick}
                  className={classes.checkBoxRoot}
                />
              </IconButton>
            ) : (
              <Checkbox
                style={{
                  padding: "9px 0px 10px",
                }}
                className={classes.checkBoxPadd}
                checkedIcon={
                  <CheckBoxIcon
                    fontSize="small"
                    className={classes.checkBoxRoot}
                  />
                }
                checked={numSelected?.length >= 4}
                onChange={onSelectAllClick}
                inputProps={{ "aria-label": "select all desserts" }}
              />
            )}
          </TableCell>
          {bulkDownload.length >= 0 && (
            // <TableRow style={{ backgroundColor: "#F4F4F4" }}>
            <>
              {/* <TableCell style={{ padding: "0.4%", maxWidth: "100%" }}>
                &nbsp;
              // </TableCell> */}
              <TableCell
                align="left"
                style={{ padding: "0.4%", maxWidth: "100%" }}
              >
                <Typography
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    //textOverflow: 'ellipsis',
                    maxWidth: "100%",
                    fontSize: "10px",
                    fontWeight: "600",
                  }}
                >
                  {bulkDownload?.length} Selected
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                style={{ padding: "0.4%", maxWidth: "100%" }}
              >
                &nbsp;
              </TableCell>
              <TableCell style={{ padding: "0.4%", maxWidth: "90px" }}>
                {bulkDownload.findIndex(
                  (item) => item.expStatus != "In Progress"
                ) == -1 && bulkDownload.length == 1 ? (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      alignItems: "right",
                    }}
                  >
                    <IconButton
                      style={{
                        maxHeight: "40px",
                        maxWidth: "40px",
                        padding: "5px",
                      }}
                      onClick={() =>
                        handleOpenBulkApproveDialog(
                          props.rowValue,
                          props.indexValue
                        )
                      }
                    >
                      <CheckCircleOutlineIcon
                        style={{
                          color: "#349259",
                          fontSize: "28px",
                        }}
                      />
                    </IconButton>
                    <IconButton
                      style={{
                        maxHeight: "40px",
                        maxWidth: "40px",
                        padding: "5px",
                      }}
                      onClick={() =>
                        handleOpenRejectionDialog(
                          props.rowValue,
                          props.indexValue
                        )
                      }
                    >
                      <CancelOutlinedIcon
                        style={{
                          color: "#E02020",
                          fontSize: "28px",
                        }}
                      />
                    </IconButton>
                  </Box>
                ) : (
                  ""
                )}
                <DeleteDialog
                  openDeleteDialog={openDeleteDialog}
                  handleCloseDeleteDialog={handleCloseDeleteDialog}
                  handleDelete={handleDelete}
                  currentExpense={currentIndex}
                />
                {bulkDownload.findIndex(
                  (item) => item.expStatus != "In Progress"
                ) == -1 &&
                  bulkDownload.length == 1 && (
                    <ApproveRejectDialog
                      openBulkApproveDialog={openBulkApproveDialog}
                      singleExpense={singleExpense}
                      approveOrReject={approveOrReject}
                      handleComments={handleComments}
                      handleSingleApproveRejectExpense={
                        handleSingleApproveRejectExpense
                      }
                      handleCloseBulkApproveDialog={
                        handleCloseBulkApproveDialog
                      }
                    />
                  )}
                <div className={classes.rootSnack}>
                  <Snackbar
                    open={systemErrorSnackbar}
                    onClose={() => setSystemErrorSnackbar(false)}
                  >
                    <Alert
                      onClose={() => setSystemErrorSnackbar(false)}
                      severity="error"
                      sx={{
                        width: "100%",
                        backgroundColor: "red",
                        color: "white",
                        textAlign: "left",
                      }}
                    >
                      There is some problem with the application. Please connect
                      with System Admin.
                    </Alert>
                  </Snackbar>
                </div>
                {bulkDownload.findIndex(
                  (item) => item.expStatus != "In Progress"
                ) == -1 && bulkDownload.length != 1 ? (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      alignItems: "right",
                      //paddingTop: "8px",
                    }}
                  >
                    <IconButton
                      style={{
                        maxHeight: "40px",
                        maxWidth: "40px",
                        paddingtop: "10px",
                      }}
                      onClick={() =>
                        handleOpenBulkApproveDialog(
                          props.rowValue,
                          props.indexValue
                        )
                      }
                    >
                      <CheckCircleOutlineIcon
                        style={{
                          color: "#349259",
                          fontSize: "28px",
                        }}
                      />
                    </IconButton>
                    <IconButton
                      style={{
                        maxHeight: "40px",
                        maxWidth: "40px",
                        padding: "5px",
                      }}
                      //  onClick={() =>
                      //    handleOpenRejectionDialog(props.rowValue, props.indexValue)
                      //  }
                    >
                      {/* <CancelOutlinedIcon
                        style={{
                          color: "#E02020",
                          fontSize: "28px",
                        }}
                      /> */}
                      &nbsp;
                    </IconButton>
                  </Box>
                ) : (
                  ""
                )}
                {bulkDownload.findIndex(
                  (item) => item.expStatus != "In Progress"
                ) == -1 &&
                  bulkDownload.length != 1 && (
                    <ApproveRejectDialog
                      openBulkApproveDialog={openBulkApproveDialog}
                      approveOrReject={approveOrReject}
                      bulkDownload={bulkDownload}
                      handleBulkApprove={handleBulkApprove}
                      handleCloseBulkApproveDialog={
                        handleCloseBulkApproveDialog
                      }
                    />
                  )}
              </TableCell>
              <TableCell style={{ padding: "2px", maxWidth: "100%" }}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "left",
                    paddingTop: "8px",
                  }}
                >
                  <CSVLink {...csvLink} style={{ color: "black" }}>
                    <GetAppOutlinedIcon />
                  </CSVLink>
                </Box>
              </TableCell>

              {/* </TableRow> */}
            </>
          )}
        </TableRow>
      )}

      {/* ayushi */}

      {bulkDownload?.length == 0 && (
        <TableRow style={{ backgroundColor: "#F4F4F4" }}>
          <TableCell
            style={{ padding: "0px" }}
            // style={{ padding: "0px", width: "20px" }}
          />
          <TableCell
            // padding="checkbox"
            style={{
              minWidth: "28px",
              padding: "0px",
              width: "28px",
              background: teamAppPurpose ? "white" : "EFF2F8",
              display: "flex",
              justifyContent: "center",
            }}
            className={
              teamAppPurpose == true ||
              teamAppCreationDate == true ||
              teamAppAttachments == true ||
              teamAppProjectId == true
                ? classes.stickyLeft
                : classes.nonSticky //copy this-Kush
            }
            onClick={() => {
              //console.log("hi table roe clicked!", teamAppPurpose);
            }}
          >
            <Checkbox
              className={classes.checkBoxPadd}
              // disabled={numSelected > 0 && numSelected < rowCount}
              checkedIcon={
                <CheckBoxIcon
                  fontSize="small"
                  className={classes.checkBoxRoot}
                />
              }
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>

          {headCellValue &&
            headCellValue?.map((headCell) => (
              //<TableCell align={headCell.label == 'Status' ? "center" : "left"} style={{ padding: "0.4%", minWidth: "4.99rem" }}>
              <TableCell
                align={
                  headCell.label === "Status"
                    ? // ||
                      // headCell.label === "Claim Amount"
                      "center"
                    : "left"
                  // headCell.label === "Status" ? "center"
                  //  : "left"
                }
                style={{
                  padding: "0.4%",
                  fontSize: "10px",
                  fontWeight: "600",
                  lineHeight: "1rem",
                  //textAlign:"left",
                }}
              >
                {headCell.label}
              </TableCell>
            ))}
        </TableRow>
      )}

      <div className={classes.rootSnack}>
        <Snackbar
          open={systemErrorSnackbar}
          onClose={() => setSystemErrorSnackbar(false)}
        >
          <Alert
            onClose={() => setSystemErrorSnackbar(false)}
            severity="error"
            sx={{
              width: "100%",
              backgroundColor: "red",
              color: "white",
              textAlign: "left",
            }}
          >
            There is some problem with the application. Please connect with
            System Admin.
          </Alert>
        </Snackbar>
      </div>
    </TableHead>
  );
}

TeamApprovalTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  onClickFilter: PropTypes.func.isRequired,
  handleSettingsParent: PropTypes.func.isRequired,
  nDayVal: PropTypes.number.isRequired,
  setOpenAppRejSB: PropTypes.func.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  tableHeading: {
    padding: "0.4%",
    minWidth: "4.99rem",
  },
  tableHeadingCond: {
    padding: "0.4%",
    minWidth: "4.99rem",
    paddingBottom: "20px",
  },
}));

const TeamApprovalTableToolbar = (props) => {
  const classes = useToolbarStyles();

  const { numSelected } = props;
  //console.log("number selected", numSelected);

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          All Expenses
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

TeamApprovalTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  rootFooter: {
    flexShrink: 0,

    marginLeft: theme.spacing(2.5),
  },
  tableContainer: {
    borderRadius: "8px",
    "&.MuiTableCell-root": {
      padding: "0px",
    },
  },
  cell: {
    width: "120px",
  },
  settingsPaper: {
    width: "188px",
    height: "164px",
    border: "1px solid #B7B7B7",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.03)",
  },
  // formControl: {
  //   width: "341px",
  //   height: "70px",
  //   marginLeft: "10px",
  // },
  // formControl1: {
  //   width: "112%",
  //   height: "70px",
  //   marginLeft: "9px",
  // },
  // formControl2: {
  //   width: "100%",
  //   height: "70px",
  //   marginLeft: "9px",
  // },
  // formControl3: {
  //   width: "100%",
  //   height: "70px",
  //   marginLeft: "9px",
  //   marginRight: "-11px",
  // },
  formControl: {
    width: "341px",
    height: "70px",
    marginLeft: "10px",
    "& .MuiFormLabel-root": {
      //marginLeft: "11px",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#515961",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-input": {
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    // "& .MuiSelect-selectMenu": {
    //   //   fontFamily: "Montserrat",
    //   //   fontSize: "16px",
    //   //   fontWeight: "500",
    //   //   color: "#2B2D30",
    //   ["@media (max-width:600px)"]: { fontSize: "13px" },
    // },
    // "& .MuiMenuItem-root": {
    //   ["@media (max-width:600px)"]: { fontSize: "13px" },
    // },
  },
  formControl1: {
    width: "100%",
    height: "70px",
    marginLeft: "9px",
    "& .MuiFormLabel-root": {
      //marginLeft: "11px",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#515961",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-input": {
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
  },
  formControl2: {
    width: "100%",
    height: "70px",
    marginLeft: "9px",
    "& .MuiFormLabel-root": {
      //marginLeft: "11px",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#515961",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-input": {
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
  },
  formControl3: {
    width: "100%",
    height: "70px",
    marginLeft: "9px",
    marginRight: "-11px",
    "& .MuiFormLabel-root": {
      //marginLeft: "11px",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#515961",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-input": {
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
  },
  errorRoot: {
    color: "red",
    marginLeft: "7px",
    marginRight: "0px",
    marginTop: 0,
    fontSize: "10px",
    lineHeight: "11px",
  },
  formControlAmount: {
    margin: theme.spacing(1),
    minWidth: "75px",
    height: "52px",
    marginLeft: "24px",
    marginTop: "8px",
  },
  paperZ150: {
    width: "1132px",
    border: "1px solid #979797",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
  },
  paperZ125: {
    width: "1390px",
    border: "1px solid #979797",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
  },
  paperZ100: {
    //width: "1117px",
    border: "1px solid #979797",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
  },
  table: {
    //maxWidth: "1117px",
    "&.MuiTableCell-root": {
      padding: "0px",
    },
  },
  table100: {
    "&.MuiTableCell-root": {
      padding: "0px",
    },
  },
  filterImg: {
    width: "24px",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  inReviewStatus: {
    backgroundColor: "#E0B15C",
    color: "white",
    width: "110px",
    height: "24px",
  },
  approvedStatus: {
    backgroundColor: "#349259",
    color: "white",
    width: "110px",
    height: "24px",
  },
  rejectedStatus: {
    backgroundColor: "#D40606",
    color: "white",
    width: "110px",
    height: "24px",
  },
  checkBoxRoot: {
    background: "#0B1941",
    color: "white",
    "&$checked": {
      background: "#0B1941",
      color: "white",
    },
  },
  checkBoxPadd: {
    padding: "9px 0px",
  },
  switchRoot: {
    ".MuiSwitch-colorSecondary.Mui-checked": {
      color: "#0B1941",
    },
  },
  tableRow: {
    height: 58,
    "&.MuiTableRow-root.MuiTableRow-hover:hover": {
      backgroundColor: "#EFF2F8",
    },
    "&.MuiTableRow-root.Mui-selected": {
      background: "#EFF2F8",
    },
    "& .MuiTableCell-root": {
      fontSize: "10px",
    },
  },
  stickyRight: {
    position: "sticky",
    right: 0,
    background: "white",
    zIndex: 800,
  },
  stickyLeft: {
    position: "sticky",
    left: 0,
    background: "white",
    zIndex: 800,
  },
  nonSticky: {
    position: "sticky",
    left: 0,
    background: "#F4F4F4",
    zIndex: 800,
  },
}));

export default function MobileViewTeamApproval(props) {
  const classes = useStyles();
  const history = useHistory();
  const matches = useMediaQuery("(max-width:600px)");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filteredRowsData, setFilteredRowsData] = React.useState([]);
  const [teamAppPurpose, setTeamAppPurpose] = React.useState(false);
  const [teamAppCreationDate, setTeamAppCreationDate] = React.useState(false);
  const [teamAppAttachments, setTeamAppAttachments] = React.useState(false);
  const [teamAppProjectId, setTeamAppProjectId] = React.useState(false);
  const [singleDownload, setSingleDownload] = useState([]);
  const [bulkDownload, setBulkDownload] = useState([]);
  const [empId, setEmpId] = React.useState(null);
  const [openBulkApproveDialog, setOpenBulkApproveDialog] = useState(false);
  const [singleExpense, setSingleExpense] = useState("");
  const [approveOrReject, setApproveOrReject] = useState(1);
  const [currentIndex, setCurrentIndex] = React.useState();
  const [screenWidth, setScreenWidth] = useState(1920);
  const [refreshTableToggle, forceUpdate] = useReducer((x) => x + 1, 0);
  const [open, setOpen] = React.useState(false);
  const [commentData, setCommentData] = React.useState([]);
  const [commentHeading, setCommentHeading] = React.useState("");
  const [toolAction, setToolAction] = React.useState("");
  const [rowVal, setRowVal] = React.useState({});
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [employeeNamefilter, setEmployeeNamefilter] = useState("");
  const [expenseCategoryfilter, setExpenseCategoryfilter] = useState("");
  const [statusFilter, setStatusfilter] = useState("");
  const [endDatefilter, setEndDatefilter] = useState(null);
  const [startDatefilter, setStartDatefilter] = useState(null);
  const [startDateMsg, setStartDateMsg] = useState("");
  const [endDateMsg, setEndDateMsg] = useState("");
  const [minClaimAmountfilter, setMinClaimAmountfilter] = useState("");
  const [maxClaimAmountfilter, setMaxClaimAmountfilter] = useState("");
  const [openAppRejSB, setOpenAppRejSB] = useState(true);
  const [anchorFilter1, setAnchorFilter1] = useState("right");
  const [buttonState, setButtonState] = React.useState(true);
  const [rowValue, setRowValue] = React.useState();
  const [indexValue, setIndexValue] = React.useState();
  const [filterShow, setFilterShow] = React.useState(false);
  const [countShow, setCountShow] = React.useState(0);
  const [minClaimAmountMsg, setMinClaimAmountMsg] = useState("");
  const [maxClaimAmountMsg, setMaxClaimAmountMsg] = useState("");
  const [systemErrorSnackbar, setSystemErrorSnackbar] = useState(false);
  const [travelSelected, setTravelSelected] = useState([]);
  const [myExpenseTableTravelData, setMyExpenseTableTravelData] = useState([]);

  const preventMinus = (e) => {
    if (e.code === "Minus" || e.key === "-") {
      e.preventDefault();
    }
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleFieldValue = () => {
    if (
      startDatefilter === null &&
      endDatefilter === null &&
      statusFilter === "" &&
      expenseCategoryfilter === "" &&
      employeeNamefilter === "" &&
      minClaimAmountfilter === "" &&
      maxClaimAmountfilter === ""
    ) {
      return true;
    } else return false;
  };
  const handleEndDateChange = (type, date) => {
    setEndDatefilter(date);

    // if (date === null) {
    //   setEndDateMsg("Please select a start date");
    // } else {

    const dateFormatRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Format: MM/dd/yyyy
    const parsedDate = new Date(date);

    if (!dateFormatRegex.test(date)) {
      setEndDateMsg("Invalid date format.");
    } else if (isNaN(parsedDate.getTime())) {
      setEndDateMsg("Invalid date. Please enter a valid date.");
    } else if (date) {
      setEndDateMsg("");
      if (startDatefilter && startDateMsg.length == 0) {
        setEndDateMsg(""); // Clear the error message if the date is valid
        setStartDateMsg("");
        if (maxClaimAmountMsg.length == 0) {
          setButtonState(false);
        } else {
          setButtonState(true);
        }
      }
    }
    // else {
    // }
    //   }
    // }
    // }
  };

  const handleStartDateChange = (type, date) => {
    setStartDatefilter(date);

    const dateFormatRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Format: MM/dd/yyyy

    const parsedDate = new Date(date);

    if (!dateFormatRegex.test(date)) {
      setStartDateMsg("Invalid date format.");
    } else if (isNaN(parsedDate.getTime())) {
      setStartDateMsg("Invalid date. Please enter a valid date.");
    } else if (date) {
      setStartDateMsg("");
      if (endDatefilter && endDateMsg.length == 0) {
        setStartDateMsg(""); // Clear the error message if the date is valid
        setEndDateMsg(""); // Clear the error message if the date is valid
        if (maxClaimAmountMsg.length == 0) setButtonState(false);
        else setButtonState(true);
      }
    }
  };

  const handleStatus = (type, data1) => {
    setStatusfilter(data1);
    if (
      // (startDatefilter && endDatefilter) ||
      // (startDatefilter == null && endDatefilter == null)
      startDateMsg.length > 0 ||
      endDateMsg.length > 0 ||
      maxClaimAmountMsg.length > 0
    ) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  };
  const handleCategory = (type, data2) => {
    setExpenseCategoryfilter(data2);
    if (
      // (startDatefilter && endDatefilter) ||
      // (startDatefilter == null && endDatefilter == null)
      startDateMsg.length > 0 ||
      endDateMsg.length > 0 ||
      maxClaimAmountMsg.length > 0
    ) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  };
  const handleEmployeeName = (type, data3) => {
    setEmployeeNamefilter(data3);
    if (
      // (startDatefilter && endDatefilter) ||
      // (startDatefilter == null && endDatefilter == null)
      startDateMsg.length > 0 ||
      endDateMsg.length > 0 ||
      maxClaimAmountMsg.length > 0
    ) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  };

  const handleMinClaimAmount = (type, data3) => {
    const trimmedInput = data3.trimStart();
    setMinClaimAmountfilter(trimmedInput);
    //if (minClaimAmountfilter ==null)
    // (startDatefilter && endDatefilter) ||
    // (startDatefilter == null && endDatefilter == null)
    if (
      // (startDatefilter && endDatefilter) ||
      // (startDatefilter == null && endDatefilter == null)
      startDateMsg.length > 0 ||
      endDateMsg.length > 0 ||
      maxClaimAmountMsg.length > 0
    ) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  };
  const handleMaxClaimAmount = (type, data3) => {
    const trimmedInput = data3.trimStart();
    setMaxClaimAmountfilter(trimmedInput);

    if (
      // (startDatefilter && endDatefilter) ||
      // (startDatefilter == null && endDatefilter == null)
      startDateMsg.length > 0 ||
      endDateMsg.length > 0 ||
      maxClaimAmountMsg.length > 0
    ) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  };

  const handleRemoveFilter = () => {
    setFilteredRowsData(getMyTeamApprovalList);
    setEmployeeNamefilter("");
    setExpenseCategoryfilter("");
    setStatusfilter("");
    setStartDatefilter(null);
    setEndDatefilter(null);
    setMaxClaimAmountfilter("");
    setMinClaimAmountfilter("");
    setFilterShow(false);
    setCountShow(0);
    setStartDateMsg("");
    setEndDateMsg("");
    setMinClaimAmountMsg("");
    setMaxClaimAmountMsg("");
    setButtonState(true);
  };
  const handleApplyFilter = () => {
    let tempArray = JSON.parse(JSON.stringify(getMyTeamApprovalList));
    var countVal1 = 0,
      countVal2 = 0,
      countVal3 = 0,
      countVal4 = 0,
      countVal5 = 0,
      countVal6 = 0;
    if (employeeNamefilter != "") {
      tempArray = tempArray.filter((data) =>
        data?.createdBy?.includes(employeeNamefilter)
      );
      countVal1 = 1;
    }
    if (expenseCategoryfilter != "") {
      // //console.log("filter4",tempArray)
      tempArray = tempArray.filter((data) =>
        data?.categoryName?.includes(expenseCategoryfilter)
      );
      countVal2 = 1;
    }
    if (statusFilter != "") {
      //   console.log("filter5",tempArray)
      tempArray = tempArray.filter((data) =>
        data?.expStatus?.includes(statusFilter)
      );
      countVal3 = 1;
    }

    if (startDatefilter != null && endDatefilter != null) {
      var d1 = new Date(startDatefilter).toLocaleDateString();
      var d2 = new Date(endDatefilter).toLocaleDateString();
      tempArray = tempArray.filter(
        (data) =>
          new Date(data.createdOn).toLocaleDateString() >= d1 &&
          new Date(data.createdOn).toLocaleDateString() <= d2
      );
      countVal4 = 1;
    }

    if (minClaimAmountfilter != "") {
      tempArray = tempArray.filter(
        (data) => data.claimedAmount >= minClaimAmountfilter
      );
      countVal5 = 1;
    }
    if (maxClaimAmountfilter != "") {
      tempArray = tempArray.filter(
        (data) => data.claimedAmount <= maxClaimAmountfilter
      );
      countVal6 = 1;
    }
    let tempvalcount =
      countVal1 + countVal2 + countVal3 + countVal4 + countVal5 + countVal6;
    setCountShow(tempvalcount);
    setFilteredRowsData(tempArray);
    setButtonState(true);
    setFilterShow(true);
    setPage(0);
    setState({
      ...state,
      bottom: false,
      top: false,
      right: false,
      left: false,
    });
  };
  const list = (anchor) => (
    <div
      style={{ overflowX: "hidden" }}
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <ArrowBackIosIcon
        onClick={toggleDrawer(anchorFilter1, false)}
        style={{ paddingLeft: "12px", marginTop: "19px" }}
      />
      <span
        style={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "18px",
            marginTop: "-25px",
            marginLeft: "-224px",
            // display:"flex",
            // justifyContent:"center"
          }}
        >
          Filter
        </Typography>
      </span>
      <Divider />
      <br />
      <Typography
        style={{
          paddingLeft: "11px",
          fontSize: "14px",
          fontWeight: 600,
          fontFamily: "Montserrat",
          lineHeight: "24px",
          color: "#353535",
          marginTop: "-9px",
          marginBottom: "3px",
        }}
      >
        By Expense Information
      </Typography>
      <List>
        {/* <ListItemIcon> */}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          style={{
            maxWidth: matches ? "101.66%" : "",
            //marginBottom: matches ? "8px" : "",
          }}
        >
          {/* <Grid item xs={11} sm={11} md={11}> */}
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{
              width: "90.57%",
            }}
          >
            <InputLabel id="demo-simple-select-label">
              Select the Category
            </InputLabel>
            <Select
              value={expenseCategoryfilter}
              labelId="demo-simple-select-label"
              autosize={true}
              id="demo-simple-select"
              label="Select the Category"
              onChange={(e) => {
                handleCategory("", e.target.value);

                // if (e.target.value.length >= 1) {
                //   setButtonState(false);
                // }
                // if (e.target.value.length == 0) {
                //   setButtonState(true);
                // }
                // setExpenseCategoryfilter(e.target.value);
              }}
            >
              {[
                ...new Map(
                  getMyTeamApprovalList?.map((item) => [
                    item["categoryName"],
                    item,
                  ])
                ).values(),
              ]
                .sort((a, b) => a.categoryName.localeCompare(b.categoryName))
                .map((value) => {
                  return (
                    <MenuItem value={value.categoryName}>
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "13px",
                          fontWeight: 500,
                          // lineHeight: "24px",
                          letterSpacing: "-1px",
                          textAlign: "left",
                        }}
                      >
                        {value.categoryName}
                      </Typography>
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          {/* </Grid> */}
        </Grid>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{
              maxWidth: matches ? "101.66%" : "",
              marginBottom: matches ? "8px" : "",
            }}
          >
            <FormControl
              variant="outlined"
              className={classes.formControl}
              // style={{
              //   maxWidth: matches ? "91.66%" : "",
              //   //marginBottom: matches ? "8px" : "",
              // }}
              style={{
                width: "90.57%",
              }}
            >
              <InputLabel id="demo-simple-select-label">
                Search Employee Name or ID
              </InputLabel>
              <Select
                value={employeeNamefilter}
                labelId="demo-simple-select-label"
                autosize={true}
                id="demo-simple-select"
                label="Search Employee Name or ID"
                onChange={(e) => {
                  handleEmployeeName("", e.target.value);
                  // if (e.target.value.length >= 1) {
                  //   setButtonState(false);
                  // }
                  // if (e.target.value.length == 0) {
                  //   setButtonState(true);
                  // }
                  // setEmployeeNamefilter(e.target.value);
                }}
              >
                {[
                  ...new Map(
                    getMyTeamApprovalList?.map((item) => [
                      item["createdBy"],
                      item,
                    ])
                  ).values(),
                ]
                  .sort((a, b) => a.createdBy.localeCompare(b.createdBy))
                  .map((value) => {
                    return (
                      <MenuItem value={value.createdBy}>
                        <Typography
                          style={{
                            fontFamily: "Montserrat",
                            fontSize: "13px",
                            fontWeight: 500,
                            // lineHeight: "24px",
                            letterSpacing: "-1px",
                            textAlign: "left",
                          }}
                        >
                          {value.createdBy}
                        </Typography>
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {/* </ListItemIcon> */}

        {/* </Grid> */}
        {/* </ListItemIcon> */}
        <Divider style={{ marginTop: "-15px" }} />
        <br />
        <Typography
          style={{
            paddingLeft: "11px",
            marginTop: "-13px",
            fontSize: "14px",
            fontWeight: 600,
            fontFamily: "Montserrat",
            lineHeight: "24px",
            color: "#353535",
            marginBottom: "8px",
          }}
        >
          By Status
        </Typography>
        <Grid item xs={12} sm={12} md={12}>
          {/* <Grid item xs={11} sm={11} md={11}> */}
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{
              width: "90.57%",
            }}
          >
            <InputLabel id="demo-simple-select-label">
              Select a Status
            </InputLabel>
            <Select
              value={statusFilter}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Select a Status"
              autosize={true}
              onChange={(e) => {
                handleStatus("", e.target.value);
                // if (e.target.value.length >= 1) {
                //   setButtonState(false);
                // }
                // if (e.target.value.length == 0) {
                //   setButtonState(true);
                // }
                // setStatusfilter(e.target.value);
              }}
            >
              {[
                ...new Map(
                  getMyTeamApprovalList?.map((item) => [
                    item["expStatus"],
                    item,
                  ])
                ).values(),
              ]
                .sort((a, b) => a.expStatus.localeCompare(b.expStatus))
                .map((value) => {
                  return (
                    <MenuItem
                      value={value.expStatus}
                      style={{ fontSize: "13px" }}
                    >
                      {value.expStatus}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          {/* </Grid> */}
        </Grid>
        <Divider />
        <br />
        <Typography
          style={{
            paddingLeft: "11px",
            marginTop: "-13px",
            fontSize: "14px",
            fontWeight: 600,
            fontFamily: "Montserrat",
            lineHeight: "24px",
            color: "#353535",
            marginBottom: "-16px",
          }}
        >
          By Date
        </Typography>
        <Grid
          container
          spacing={2}
          style={{
            padding: "8px",
            paddingRight: "30px",
            justifyContent: "space-between",
          }} //siddhant
        >
          <Grid
            item
            xs={6} //siddhant
            sm={5}
            md={5}
            style={{ paddingLeft: "0px", paddingRight: "12px" }} //siddhant
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                fullWidth
                className={classes.formControl1}
                variant="inline"
                format="MM/dd/yyyy"
                changeYear={true}
                changeMonth={true}
                // style={{marginBottom:"-10px",marginTop:"-10px"}}
                margin="normal"
                autoOk={true}
                id="date-picker-inline"
                label="Start Date"
                value={startDatefilter}
                disableFuture={true}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                helperText={startDateMsg != "" ? startDateMsg : ""}
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorRoot,
                  },
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          {/* <Grid item xs={1} sm={1} md={1}>
            &nbsp;
          </Grid> */}
          <Grid
            item
            xs={6}
            sm={5}
            md={5}
            style={{ paddingLeft: "4px", paddingRight: "13px" }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                fullWidth
                className={classes.formControl2}
                disableFuture={true}
                variant="inline"
                format="MM/dd/yyyy"
                changeYear={true}
                changeMonth={true}
                margin="normal"
                autoOk={true}
                id="date-picker-inline"
                label="End Date"
                minDate={startDatefilter}
                value={endDatefilter}
                onChange={handleEndDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                helperText={endDateMsg != "" ? endDateMsg : ""}
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorRoot,
                  },
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: "-18px" }} />
        <br />
        <Typography
          style={{
            paddingLeft: "11px",
            marginTop: "-11px",
            fontSize: "14px",
            fontWeight: 600,
            fontFamily: "Montserrat",
            lineHeight: "24px",
            color: "#353535",
            marginBottom: "2px",
          }}
        >
          Claim Amount Range
        </Typography>
        <Grid
          container
          spacing={2}
          style={{
            padding: "8px 34px 8px 8px",
            justifyContent: "space-between",
          }} //siddhant
        >
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            style={{ padding: "8px 0px", paddingRight: "2px" }}
          >
            <ListItemIcon>
              <NumericFormat
                id="outlined-basic"
                label="Min Amount"
                inputProps={{ maxLength: 10 }}
                variant="outlined"
                min="0"
                onKeyPress={preventMinus}
                value={minClaimAmountfilter}
                customInput={TextField}
                className={classes.formControl1}
                onChange={(e) => {
                  handleMinClaimAmount("", e.target.value);
                  // if (e.target.value.length >= 1) {
                  //   setButtonState(false);
                  // }
                  // if (e.target.value.length == 0) {
                  //   setButtonState(true);
                  // }

                  // setMinClaimAmountfilter(e.target.value);
                }}
                helperText={minClaimAmountMsg != "" ? minClaimAmountMsg : ""}
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorRoot,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end"></InputAdornment>
                  ),
                }}
              />
            </ListItemIcon>
          </Grid>
          <Grid item xs={6} sm={5} md={5} style={{ paddingLeft: "6px" }}>
            <ListItemIcon>
              <NumericFormat
                id="outlined-basic"
                label="Max Amount"
                inputProps={{ maxLength: 10 }}
                variant="outlined"
                value={maxClaimAmountfilter}
                min="0"
                onKeyPress={preventMinus}
                customInput={TextField}
                className={classes.formControl3}
                onChange={(e) => {
                  handleMaxClaimAmount("", e.target.value);
                  // if (e.target.value.length >= 1 && e.target.value > 0 && minClaimAmountfilter != "") {
                  //   setButtonState(false);
                  // }
                  // if (e.target.value.length == 0) {
                  //   setButtonState(true);
                  // }

                  // setMaxClaimAmountfilter(e.target.value);
                }}
                helperText={maxClaimAmountMsg != "" ? maxClaimAmountMsg : ""}
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorRoot,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end"></InputAdornment>
                  ),
                }}
              />
            </ListItemIcon>
          </Grid>
        </Grid>
      </List>

      {/* ayushi */}
      <Divider style={{ marginTop: "-9px" }} />
      <Box>
        <Button
          variant="outlined"
          size="small"
          // style={{ marginLeft: "37px", width: "121px" }}
          style={{
            marginLeft: "59px",
            width: "121px",
            textTransform: "inherit",
            fontSize: "14px",
          }}
          onClick={handleRemoveFilter}
        >
          Reset
        </Button>
        <Button
          variant="outlined"
          disabled={buttonState}
          style={{
            background: buttonState ? "#9E9E9E" : "#0B1941",
            marginLeft: "20px",
            marginBottom: "50px",
            marginTop: "50px",
            textTransform: "inherit",
            fontSize: "14px",
          }}
          color="#fffff"
          size="small"
          onClick={handleApplyFilter}
        >
          <Typography style={{ color: "white", margin: 0 }}>
            {" "}
            Apply Filter
          </Typography>
        </Button>
      </Box>
    </div>
  );
  const target = useRef(null);
  const dispatch = useDispatch();
  const getMyTeamApprovalList = useSelector(
    (state) => state.createExpenseData.getMyTeamApproval
  );
  const delEmpIDRedux = useSelector(
    (state) => state.createExpenseData.delEmpId
  );
  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );
  const isAppRejPostSuccess = useSelector(
    (state) => state.createExpenseData.isApprovedPost
  );
  const AppRejPostResponse = useSelector(
    (state) => state.createExpenseData.postApproveData
  );

  const myExpenseTravelData = useSelector(
    (state) => state.createExpenseData.getMyExpenseTravel
  );
  const getMyTeamApprovalFlagData = useSelector(
    (state) => state.createExpenseData.getMyTeamApprovalPageFlag
  );
  // console.log("getMyTeamApprovalFlagData button team approval", getMyTeamApprovalFlagData);

  const headers = [
    {
      label: "Expense Code",
      key: "expCode",
    },
    {
      label: "Category Name",
      key: "categoryName",
    },
    {
      label: "To Location",
      key: "toLocation",
    },
    {
      label: "From Location",
      key: "fromLocation",
    },

    {
      label: "Distance (Km/Miles)",
      key: "distance",
    },
    {
      label: "From Date",
      key: "fromDate",
    },
    {
      label: "To Date",
      key: "toDate",
    },
    {
      label: "Claim Month",
      key: "forMonth",
    },
    {
      label: "Created On",
      key: "createdOn",
    },
    {
      label: "Currency",
      key: "currencyCode",
    },
    {
      label: "Claimed Amount",
      key: "claimedAmount",
    },
    {
      label: "Relationship",
      key: "dependent",
    },
    {
      label: "Policy Limit",
      key: "policyLimit",
    },
    {
      label: "Purpose",
      key: "purpose",
    },
    {
      label: "Status",
      key: "expStatus",
    },
    {
      label: "Attachments",
      key: "attachments",
    },
    {
      label: "Project ID",
      key: "projectId",
    },
    {
      label: "Employee",
      key: "createdBy",
    },
  ];
  const csvLink = {
    headers: headers,
    data: singleDownload,
    filename: `TeamApproval_${moment(new Date()).format("DDMMYYYY_HHmm")}.csv`,
  };

  useEffect(() => {
    if (delEmpIDRedux != null) {
      setEmpId(delEmpIDRedux);
      let obj = {
        empId: delEmpIDRedux,
        nday: props.ndayValue,
      };
      dispatch(getMyTeamApprovalData(obj));
    } else {
      setEmpId(props.ssoEmpId);
      let obj = {
        empid: props.ssoEmpId,
        nday: props.ndayValue,
      };
      dispatch(getMyTeamApprovalData(obj));
    }
  }, [props.ndayValue, refreshTableToggle, delEmpIDRedux]);

  useEffect(() => {
    setScreenWidth(window.screen.availWidth);
  }, [window.screen.availWidth]);

  useEffect(() => {
    setFilteredRowsData(getMyTeamApprovalList);
    setMyExpenseTableTravelData([]);
  }, [getMyTeamApprovalList]);

  // useEffect(() => {
  //   if (isAppRejPostSuccess) {
  //     dispatch(postApproveRejectExpenseData(null))
  //       .then((resp) => {})
  //       .catch((err) => {});
  //   }
  // }, []);
  useEffect(() => {
    if (startDatefilter == null && endDatefilter != null) {
      setStartDateMsg("Please enter start date ");
      setButtonState(true);
    } else if (startDatefilter != null && endDatefilter == null) {
      setEndDateMsg("Please enter end date");
      setButtonState(true);
    } else if (new Date(startDatefilter) > new Date(endDatefilter)) {
      setEndDateMsg("End date should be greater than start date ");
      setStartDateMsg("");
      setButtonState(true);
    } else if (startDatefilter == null && endDatefilter == null) {
      setStartDateMsg("");
      setEndDateMsg("");
    }
  }, [startDatefilter, endDatefilter]);

  useEffect(() => {
    if (
      (minClaimAmountfilter == "" && maxClaimAmountfilter != "") ||
      (minClaimAmountfilter != "" && maxClaimAmountfilter == "")
    ) {
      if (startDateMsg.length == 0 && endDateMsg.length == 0)
        setButtonState(false);
      setMaxClaimAmountMsg("");
    } else if (minClaimAmountfilter != "" && maxClaimAmountfilter != "") {
      if (Number(minClaimAmountfilter) > Number(maxClaimAmountfilter)) {
        setMaxClaimAmountMsg("Max Amount should be greater than Min Amount");
        setButtonState(true);
      } else if (Number(minClaimAmountfilter) <= Number(maxClaimAmountfilter)) {
        if (startDateMsg.length == 0 && endDateMsg.length == 0)
          setButtonState(false);
        setMaxClaimAmountMsg("");
      }
    } else if (minClaimAmountfilter == "" && maxClaimAmountfilter == "") {
      setMaxClaimAmountMsg("");

      if (handleFieldValue()) setButtonState(true);
      else if (!handleFieldValue()) {
        if (startDateMsg.length == 0 && endDateMsg.length == 0)
          setButtonState(false);
        else setButtonState(true);
      }
    }
  }, [minClaimAmountfilter, maxClaimAmountfilter]);

  const [cell1tarwidth, setCell1tarwidth] = useState(null);
  const [cell2tarwidth, setCell2tarwidth] = useState(null);
  const [cell3tarwidth, setCell3tarwidth] = useState(null);

  useEffect(() => {
    const syncWidths = () => {
      // console.log("cellwidth1", cell1tar?.current?.style.maxWidth);
      const cell1ref = document.getElementById("cell1ref");
      const cell2ref = document.getElementById("cell2ref");
      const cell3ref = document.getElementById("cell3ref");

      if (cell1ref) {
        const widthToSet = cell1ref.clientWidth;
        if (cell1tarwidth !== widthToSet) {
          setCell1tarwidth(widthToSet);
        }
        // console.log("cellwidth", cell1tarwidth, widthToSet);
      }
      if (cell2ref) {
        const widthToSet = cell2ref.clientWidth;
        if (cell2tarwidth !== widthToSet) {
          setCell2tarwidth(widthToSet);
        }
        // console.log("cellwidth2", cell2tarwidth, widthToSet);
      }
      if (cell3ref) {
        const widthToSet = cell3ref.clientWidth;
        if (cell3tarwidth !== widthToSet) {
          setCell3tarwidth(widthToSet);
        }
        // console.log("cellwidth2", cell3tarwidth, widthToSet);
      }
    };

    syncWidths();
  }, [myExpenseTravelData]);

  useEffect(() => {
    // console.log("myExpenseTableTravelData2", myExpenseTravelData);
    // if (myExpenseTravelData?.length > 0)
    setMyExpenseTableTravelData((prevState) => [
      ...prevState,
      ...myExpenseTravelData,
    ]);
  }, [myExpenseTravelData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const resetBulkDownload = () => {
    setBulkDownload([]);
    setSelected([]);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      let filterData = stableSort(
        filteredRowsData,
        getComparator(order, orderBy)
      )?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      const newSelecteds = filterData.map((s) => s.uniqueId);
      setSelected(newSelecteds);
      setBulkDownload(filterData);
      return;
    }
    setBulkDownload([]);
    setSelected([]);
  };

  const handleClick = (event, name) => {
    setRowVal(name);
    const selectedIndex = selected.indexOf(name.uniqueId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.uniqueId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    const objIndex = bulkDownload.findIndex(
      (el) => el.uniqueId == name.uniqueId
    );
    let newBulkDownload = [];
    if (objIndex >= 0) {
      newBulkDownload = [
        ...bulkDownload.filter((el) => el.uniqueId != name.uniqueId),
      ];
    } else {
      newBulkDownload = [...bulkDownload, name];
    }
    setBulkDownload(newBulkDownload);
  };

  const handleFilteredArray = (data) => {
    setFilteredRowsData(data);
  };

  const handleSettingsParent = (name, value) => {
    if (name == "purpose") {
      setTeamAppPurpose(value);
    } else if (name == "createdOn") {
      setTeamAppCreationDate(value);
    } else if (name == "attachments") {
      setTeamAppAttachments(value);
    } else if (name == "projectId") {
      setTeamAppProjectId(value);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const isTravelSelected = (name) => travelSelected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredRowsData?.length - page * rowsPerPage);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handlToolAction = (event) => {
    setToolAction(event.currentTarget);
  };

  const handleCloseAppRejSB = () => {
    setOpenAppRejSB(false);
  };

  const handleToolTip = (comment, heading) => {
    return (
      <>
        <Box>
          <Typography className={classes.statusHeading}>{heading}</Typography>
        </Box>
        {comment?.map((value) => {
          return (
            <>
              <Box style={{ display: "flex" }}>
                <Typography className={classes.statusName}>
                  {value.managerid}
                </Typography>
                {heading == "Rejected By " && (
                  <Typography className={classes.statusName}>{"|"}</Typography>
                )}
                <Typography className={classes.statusProjectName}>
                  {value.projCode}
                </Typography>{" "}
              </Box>
              <Typography className={classes.statusMessage}>
                {value.reason}
              </Typography>
            </>
          );
        })}
      </>
    );
  };

  const handleTooltipOpen = (comments, index) => {
    setOpen(true);
    setCommentData(comments);
    setCommentHeading("Rejected");
    setCurrentIndex(index);
  };

  const handleParentToolTip = (index) => {
    return (
      <>
        <OutsideClickHandler
          onOutsideClick={() => {
            handleTooltipClose();
          }}
        >
          <div style={{ position: "relative" }}>
            <Tooltip
              PopperProps={{
                disablePortal: true,
                anchorEl: { toolAction },
              }}
              arrow
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={handleToolTip(commentData, commentHeading)}
              classes={{
                popper: classes.tooltipBox,
                arrow: classes.arrow,
              }}
            >
              <span
                style={{ width: "0px", minWidth: "0px", height: "0" }}
              ></span>
            </Tooltip>
          </div>
        </OutsideClickHandler>
      </>
    );
  };

  const handleExpand = (event, row) => {
    const selectedIndex = travelSelected.indexOf(row.expId);
    const travelExist = myExpenseTableTravelData.some(
      (obj) => obj.expId === row.expId
    );
    // console.log("Previous state travel", travelExist);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(travelSelected, row.expId);
      if (!travelExist) {
        let obj = {
          expId: row.expId,
          nday: props.ndayValue,
        };
        dispatch(getMyExpenseDataTravel(obj));
      }
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(travelSelected.slice(1));
    } else if (selectedIndex === travelSelected.length - 1) {
      newSelected = newSelected.concat(travelSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        travelSelected.slice(0, selectedIndex),
        travelSelected.slice(selectedIndex + 1)
      );
    }
    setTravelSelected(newSelected);
  };

  return (
    <>
      {isAppRejPostSuccess && AppRejPostResponse.comments == "" ? (
        <ApproveRejectSnackbar
          key={empId}
          handleCloseAppRejSB={handleCloseAppRejSB}
          openAppRejSB={openAppRejSB}
          approveOrReject={1}
        />
      ) : isAppRejPostSuccess && AppRejPostResponse.comments != "" ? (
        <ApproveRejectSnackbar
          key={empId}
          handleCloseAppRejSB={handleCloseAppRejSB}
          openAppRejSB={openAppRejSB}
          approveOrReject={0}
        />
      ) : (
        ""
      )}
      {matches && (
        <TableContainer component={Paper} sx={{ width: "max-content" }}>
          <Table>
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell align="right" style={{ padding: "0.4%" }}>
                  &nbsp;
                </TableCell>
                <TableCell align="right" style={{ padding: "0.4%" }}>
                  &nbsp;
                </TableCell>
                <TableCell align="right" style={{ padding: "0.4%" }}>
                  {filterShow ? (
                    <IconButton
                      aria-label="cart"
                      style={{ marginTop: "-5px", marginBottom: "-5px" }}
                    >
                      <StyledBadge badgeContent={countShow} color="secondary">
                        <IconButton onClick={toggleDrawer(anchorFilter1, true)}>
                          <span class="material-symbols-outlined">
                            filter_alt
                          </span>
                        </IconButton>
                      </StyledBadge>
                    </IconButton>
                  ) : (
                    <IconButton onClick={toggleDrawer(anchorFilter1, true)}>
                      <span class="material-symbols-outlined">filter_alt</span>
                    </IconButton>
                  )}
                  <Drawer
                    anchor={anchorFilter1}
                    open={state[anchorFilter1]}
                    onClose={toggleDrawer(anchorFilter1, false)}
                  >
                    {list(anchorFilter1)}
                  </Drawer>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      )}
      <TableContainer component={Paper} sx={{ width: "max-content" }}>
        <Table>
          <TeamApprovalTableHead
            classes={classes}
            numSelected={selected.length}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={filteredRowsData?.length}
            onClickFilter={handleFilteredArray}
            pageNumber={page}
            handleSettingsParent={handleSettingsParent}
            getMyTeamApprovalList={getMyTeamApprovalList}
            bulkDownload={bulkDownload}
            empId={empId}
            resetBulkDownload={resetBulkDownload}
            rowValue={rowValue}
            indexValue={indexValue}
            nDayVal={props.ndayValue}
            setOpenAppRejSB={() => setOpenAppRejSB(true)}
          />
          <TableBody>
            {stableSort(filteredRowsData, getComparator(order, orderBy))
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, index) => {
                const isItemSelected = isSelected(row.uniqueId);
                const isTravelItemSelected = isTravelSelected(row.expId);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <>
                    <TableRow
                      hover
                      className={classes.tableRow}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.uniqueId}
                      selected={isItemSelected}
                    >
                      <TableCell
                        style={{
                          paddingRight: "0px",
                          paddingLeft: "2px",
                          width: "20px",
                          // borderBottomWidth: "0px",
                        }}
                        onClick={(event) => handleExpand(event, row)}
                      >
                        {row.tFlag ? (
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            style={{ width: "24px" }}
                            // onClick={(event) => handleExpand(event, row)}
                          >
                            {isTravelItemSelected ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        ) : (
                          <></>
                        )}
                      </TableCell>
                      <TableCell
                        // padding="checkbox"
                        style={{
                          // minWidth: "53px",
                          minWidth: "28px",
                          width: "28px",
                          padding: "0px",
                        }}
                        className={classes.stickyLeft}
                      >
                        <Checkbox
                          style={{ display: "flex" }}
                          className={classes.checkBoxPadd}
                          checked={isItemSelected}
                          checkedIcon={
                            <CheckBoxIcon
                              fontSize="small"
                              className={classes.checkBoxRoot}
                            />
                          }
                          onClick={(event) => {
                            handleClick(event, row);
                            setRowValue(row);
                            setIndexValue(index);
                          }}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          padding: "0.4%",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          maxWidth: "90px",
                        }}
                        onClick={() => {
                          const text = "/viewExpenseDetails/:id";
                          const result = text.replace(":id", row.expId);
                          history.push({
                            pathname: result,
                            state: {
                              response: row,
                              empId: empId,
                              compName: "teamApprovalListing",
                            },
                          });
                        }}
                        id="cell1ref"
                      >
                        {row.categoryName}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          padding: "0.4%",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          maxWidth: "80px",
                        }}
                        onClick={() => {
                          const text = "/viewExpenseDetails/:id";
                          const result = text.replace(":id", row.expId);
                          history.push({
                            pathname: result,
                            state: {
                              response: row,
                              empId: empId,
                              compName: "teamApprovalListing",
                            },
                          });
                        }}
                        id="cell2ref"
                      >
                        {row.createdBy}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          padding: "0.4%",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          // maxWidth: "90px",
                          minWidth: "30px",
                          width: "90px",
                        }}
                        //style={{ padding: "0.4%", maxWidth: "90px" }}
                        onClick={() => {
                          const text = "/viewExpenseDetails/:id";
                          const result = text.replace(":id", row.expId);
                          history.push({
                            pathname: result,
                            state: {
                              response: row,
                              empId: empId,
                              compName: "teamApprovalListing",
                            },
                          });
                        }}
                        id="cell3ref"
                      >
                        <div
                        // style={{ width: "70px" }}
                        >
                          {row.currencyChanged == 1 ||
                          (row.policyLimit != 0 &&
                            row.claimedAmount > row.policyLimit &&
                            row.exceptionalApproval == 1) ? (
                            <Tooltip
                              title={
                                row.currencyChanged == 1 &&
                                row.exceptionalApproval == 1 &&
                                row.claimedAmount > row.policyLimit &&
                                row.exceptionalApproval == 1
                                  ? "Claim Amount Exceeds Policy limit and Default currency has been changed"
                                  : row.currencyChanged == 1
                                  ? "Default currency has been changed"
                                  : "Claim Amount exceeds policy limit"
                              }
                            >
                              <span style={{ color: "red" }}>
                                {row?.currency}{" "}
                                {row.claimedAmount
                                  ?.toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </span>
                            </Tooltip>
                          ) : (
                            <div>
                              {row?.currency}{" "}
                              {row.claimedAmount
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </div>
                          )}
                        </div>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ padding: "0.4%" }}
                        onClick={() => {
                          const text = "/viewExpenseDetails/:id";
                          const result = text.replace(":id", row.expId);
                          history.push({
                            pathname: result,
                            state: {
                              response: row,
                              empId: empId,
                              compName: "teamApprovalListing",
                            },
                          });
                        }}
                      >
                        <Box
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Chip
                            style={{ fontSize: "10px", width: "90px" }}
                            label={row.expStatus}
                            className={
                              row.expStatus == "Rejected"
                                ? classes.rejectedStatus
                                : row.expStatus == "Approved"
                                ? classes.approvedStatus
                                : classes.inReviewStatus
                            }
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ padding: 0 }} colSpan={12}>
                        <Collapse
                          in={isTravelItemSelected}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box>
                            <Table aria-label="purchases">
                              <TableHead>
                                <TableRow
                                  style={{
                                    // display: "flex",
                                    background: "#f4f4f4",
                                  }}
                                >
                                  <TableCell
                                    style={{
                                      width: "55px",
                                      padding: "0px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "55px",
                                      }}
                                    ></div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "10px",
                                      maxWidth: `${
                                        cell1tarwidth +
                                        cell2tarwidth +
                                        cell3tarwidth
                                      }px`,
                                      width: `${
                                        cell1tarwidth +
                                        cell2tarwidth +
                                        cell3tarwidth
                                      }px`,
                                      padding: "4px 0px",
                                    }}
                                  >
                                    Expense Category
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "10px",
                                      // width: "11rem",
                                      padding: "0.4%",
                                      maxWidth: `${cell3tarwidth}px`,
                                      width: `${cell3tarwidth}px`,
                                    }}
                                  >
                                    Claim Amount
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {myExpenseTableTravelData?.map(
                                  (travelRow, index) => {
                                    return Number(travelRow.parentExpId) ===
                                      row.expId ? (
                                      <TableRow
                                        key={index}
                                        style={
                                          {
                                            // display: "flex",
                                          }
                                        }
                                      >
                                        {/* <TableCell /> */}
                                        <TableCell
                                          style={{
                                            width: "23px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "23px",
                                            }}
                                          ></div>
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            maxWidth: `${
                                              cell1tarwidth +
                                              cell2tarwidth +
                                              cell3tarwidth
                                            }px`,
                                            width: `${
                                              cell1tarwidth +
                                              cell2tarwidth +
                                              cell3tarwidth
                                            }px`,
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            fontSize: "10px",
                                            padding: "12px 6px 12px 0px",
                                            boxSizing: "border-box",
                                          }}
                                        >
                                          {travelRow.categoryName}
                                        </TableCell>

                                        <TableCell
                                          align="left"
                                          style={{
                                            maxWidth: `${cell3tarwidth}px`,
                                            width: `${cell3tarwidth}px`,
                                            fontSize: "10px",
                                            padding: "12px 0.4%",
                                          }}
                                        >
                                          {row?.currency}
                                          {travelRow.claimedAmount
                                            .toString()
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )}
                                        </TableCell>
                                      </TableRow>
                                    ) : (
                                      <></>
                                    );
                                  }
                                )}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            {emptyRows > 0 && (
              <TableRow className={classes.tableRow}>
                <TableCell colSpan={12} style={{ minWidth: "120px" }} />
              </TableRow>
            )}
          </TableBody>
          {getMyTeamApprovalFlagData?.myExpenseTab === 1 && (
          <MobileFloatingButton />
           )}{" "}
        </Table>
        <Box
          style={{
            display: "flex",
            marginLeft: "54px",
            justifyContent: "space-between",
            position: "fixed",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[]}
            component="Box"
            count={filteredRowsData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          ></TablePagination>
        </Box>
      </TableContainer>
    </>
  );
}
