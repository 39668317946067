import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Box,
  Avatar,
  Popover,
  Paper,
  Typography,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Button,
} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import {
  getMyDelegateesData,
  updateDelegateeEmpId,
  getDelegataionFlag,
} from "../redux/Expense/action";
import {
  getTimesheetDelAccessData,
  updateTimesheetDelegateeEmpId,
  getTimesheetAccessFlagData,
} from "../redux/TimeSheet/actionTs";
import {
  getGlobalMobilityDelAccessData,
  updateGlobalMobilityDelegateeEmpId,
  // getGlobalMobilityAccessFlagData,
} from "../redux/GMCal/gmAction";
import { useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import LandingPage from "../pages/Expense/landingPage";
import { useHistory, useLocation } from "react-router";
import Dashboard from "../pages/Dashboard/dashboard";
import { useMsal } from "@azure/msal-react";
import DelegationImage from "../images/delegation_image.png";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  accountOptionsBox: {
    "& .MuiPaper-root": {
      minWidth: "300px",
      minHeight: "331px ",
      background: " #FFFFFF",
      boxShadow:
        "0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)",
      borderRadius: "4px",
      marginTop: "50px",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const AccountOptions = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [valueEmpId, setValueEmpId] = React.useState(null);
  const [openBackStrap, setOpenBackStrap] = React.useState(false);
  const matches = useMediaQuery("(max-width:600px)");
  const location = useLocation();
  const [pageModule, setPageModule] = React.useState("");
  const history = useHistory();

  const dispatch = useDispatch();
  const myDelegateesList = useSelector(
    (state) => state?.createExpenseData?.getMyDelegateesData
  );

  const myTimesheetDelegateesList = useSelector(
    (state) => state?.timesheetData?.getMyTimesheetDelegateeData
  );

  const myGlobalMobilityDelegateesList = useSelector(
    (state) => state?.gmCalData?.getGlobalMobilityDelegateeData
  )

  const { instance } = useMsal();

  const delegationFlag = useSelector(
    (state) => state.createExpenseData.getDelegationFlag
  );
  // console.log("delegationFlag",delegationFlag,myDelegateesList)
  const delEmpIdRedux = useSelector(
    (state) => state.createExpenseData.delEmpId
  );
  const tsDelEmpIdRedux = useSelector(
    (state) => state?.timesheetData?.tsDelEmpId
  );
  const GMDelEmpIdRedux = useSelector(
    (state) => state?.gmCalData?.GMDelEmpId
  );

  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );

  const timesheetDelegationFlag = useSelector(
    (state) => state.timesheetData.getTimesheetDelegationFlag
  );

  const globalMobilityDelegationFlag = useSelector(
    (state) => state?.gmCalData?.getGlobalMobilityDelegationFlag
  )

  const handleToggle = () => {
    setOpenBackStrap(!openBackStrap);
  };

  const handleClose = () => {
    setOpenBackStrap(false);
  };

  //Functions Start:
  const handlePopover = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    if (matches) {
      handleToggle();
    }
  };
  const handlePopover2 = (event) => {
    setAnchorEl2(anchorEl2 ? null : event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    if (matches) {
      handleClose();
    }
  };
  const handleClosePopover2 = () => {
    setAnchorEl2(null);
  };

  function handleChange(event) {
    setValueEmpId(event.target.value);
    handleClosePopover();
  }
  const handleLogout = (event) => {
    setAnchorEl(event.currentTarget);
    localStorage.removeItem("token");
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  //Functions End.

  //useEffects Start:

  var path = window.location.href;
  var directories = path.split("/");
  var lastDirecotry = directories[directories.length - 1];
  var firstDirectory = directories[directories.length - 2];
  var lastDirecotrySE = directories[directories.length - 2];

  useEffect(() => {
    if (directories && directories?.length === 4) {
      if (
        lastDirecotry === "timesheet-management" ||
        lastDirecotry === "create-Timesheet-Management"
      ) {
        setPageModule("Timesheet");
      } else if (
        lastDirecotry === "expense-Management" ||
        lastDirecotry === "create-Expense-Management" ||
        lastDirecotry === "allExpenseReports"
      ) {
        setPageModule("Expense");
      } else if (
        lastDirecotry === "gmdashboard" ||
        lastDirecotry === "gmmobility"
      ) {
        setPageModule("GM");
      }
    } else if (directories && directories?.length === 5) {
      if (firstDirectory === "timesheetviewpage") {
        setPageModule("Timesheet");
      } else if (
        firstDirectory === "viewExpenseDetails" ||
        firstDirectory === "editExpenseDetails" ||
        firstDirectory === "editTravelExpenseDetails" ||
        firstDirectory === "deligation"
      ) {
        setPageModule("Expense");
      } else if (
        firstDirectory === "gmbupage" ||
        firstDirectory === "gmmobility"
      ) {
        setPageModule("GM");
      }
    }
  }, [directories]);

  // useEffect(() => {
  // if (matches && ssoEmpIdDetails?.empId) {
  //     setValueEmpId(ssoEmpIdDetails?.empId.toString());
  //   }
  // }, [matches, ssoEmpIdDetails]);

  useEffect(() => {
      if (delEmpIdRedux != null && delEmpIdRedux != undefined && pageModule === "Expense") {
        setValueEmpId(delEmpIdRedux.toString());
      } else if (tsDelEmpIdRedux != null && tsDelEmpIdRedux != undefined && pageModule === "Timesheet"){
        setValueEmpId(tsDelEmpIdRedux.toString());
      } else if (GMDelEmpIdRedux != null && GMDelEmpIdRedux != undefined && pageModule === "GM"){
        setValueEmpId(GMDelEmpIdRedux.toString());
      } else if (valueEmpId == null) {
        setValueEmpId(delEmpIdRedux?.toString() ? delEmpIdRedux?.toString() : 
                     (tsDelEmpIdRedux?.toString() ? tsDelEmpIdRedux?.toString() : 
                     (GMDelEmpIdRedux?.toString() ? GMDelEmpIdRedux?.toString() : props?.employeeId?.toString())));
      }
    //setValueEmpId(props?.employeeId?.toString());
  }, [pageModule]);


  useEffect(() => {
    //console.log("accOpt : valueEmpId", valueEmpId);
    if ((valueEmpId !== null && valueEmpId !== undefined) && pageModule === "Expense") {
      dispatch(updateDelegateeEmpId(Number(valueEmpId)));
    } else if ((valueEmpId !== null && valueEmpId !== undefined) && pageModule === "Timesheet") {
      dispatch(updateTimesheetDelegateeEmpId(Number(valueEmpId)));
    } else if ((valueEmpId !== null && valueEmpId !== undefined) && pageModule === "GM") {
      dispatch(updateGlobalMobilityDelegateeEmpId(Number(valueEmpId)));
    }

    // if (valueEmpId) {
    //   if ((lastDirecotry == "gmdashboard") && valueEmpId != props?.employeeId) {
    //     // history.push("/");
    //     setValueEmpId(props?.employeeId?.toString());
    //   }
    // }
  }, [valueEmpId, pageModule]);

  // useEffect(() => {
  //   if (delEmpIdRedux != null && delEmpIdRedux != undefined) {
  //     setValueEmpId(delEmpIdRedux.toString());
  //   } else if (valueEmpId == null) {
  //     setValueEmpId(props?.employeeId?.toString());
  //   }
  // }, []);

  useEffect(() => {
    if (props.employeeId !== null && props.employeeId !== undefined) {
      dispatch(getDelegataionFlag(props.employeeId));
      dispatch(getTimesheetDelAccessData(props.employeeId));
      dispatch(getGlobalMobilityDelAccessData(props.employeeId));
    }
    //console.log("accOpt : valueEmpId", props.employeeId);
  }, [props.employeeId]);



  useEffect(() => {
    if(ssoEmpIdDetails || tsDelEmpIdRedux)
      {    
        const obj = {
        employeeId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
          };
      dispatch(getTimesheetAccessFlagData(obj));
      }
  }, [ssoEmpIdDetails, tsDelEmpIdRedux]);

  // useEffect(() => {
  //   if(ssoEmpIdDetails || GMDelEmpIdRedux){
  //     const obj = {
  //       employeeId: GMDelEmpIdRedux ? GMDelEmpIdRedux : ssoEmpIdDetails?.empId,
  //       };
  //       dispatch(getGlobalMobilityAccessFlagData(obj))
  //     }
  //   }, [ssoEmpIdDetails, GMDelEmpIdRedux]);

  //useEffects End.
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const id = open ? "simple-popper" : undefined;
  const id2 = open2 ? "simple-popper" : undefined;
  //console.log("anchorEl:====>", anchorEl);

  //console.log("ACCOPT1 props?.employeeId?.toString() : ", props?.employeeId?.toString());
  //console.log("ACCOPT1 valueEmpId : ", valueEmpId);
  //console.log("history:====>", history);
  //console.log("location:====>", location);

  // var delCondition = ((lastDirecotry == "gmdashboard" ? false : lastDirecotry == "gmmobility"
  //   ? false : lastDirecotrySE == "detailviewpage" ? false : lastDirecotrySE == "gmbupage" ? false : true ))

  // var delCondition =
  //   lastDirecotry == "gmdashboard"
  //     ? false
  //     : lastDirecotry == "gmmobility"
  //     ? false
  //     : lastDirecotrySE == "detailviewpage"
  //     ? false
  //     : lastDirecotrySE == "gmbupage"
  //     ? false
  //     // : lastDirecotry == "timesheet-management"
  //     // ? false
  //     : true;

  var delCondition =  true;
  return (
    <>
      {matches ? (
        <>
          <Box component="span" m={1}>
            {props.disableAccOptions == 0 ? (
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handlePopover2}
              >
                <Avatar
                  alt={
                    props.delEmpName !== undefined
                      ? props.delEmpName
                      : props.employeeName
                  }
                  src="/static/images/avatar/1.jpg"
                  className={classes.small}
                >
                  {props?.delEmpName
                    ? Array.from(props?.delEmpName)[0]
                    : Array.from(props.employeeName)[0]}
                </Avatar>
              </IconButton>
            ) : (
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handlePopover}
              >
                <Avatar
                  alt={
                    props.delEmpName !== undefined
                      ? props.delEmpName
                      : props.employeeName
                  }
                  src="/static/images/avatar/1.jpg"
                  className={classes.small}
                >
                  {props?.delEmpName
                    ? Array.from(props?.delEmpName)[0]
                    : Array.from(props.employeeName)[0]}
                </Avatar>
              </IconButton>
            )}
            <Backdrop className={classes.backdrop} open={openBackStrap}>
              <Popover
                id={id2}
                open={open2}
                onClose={handleClosePopover2}
                anchorEl={anchorEl2}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <Box
                  style={{
                    width: "300px",
                    // height: "450px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingLeft: "24px",
                      paddingRight: "28px",
                      paddingBottom: "8px",
                    }}
                  >
                    <Box>
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "12px",
                          fontWeight: 700,
                          lineHeight: "24px",
                          letterSpacing: "0px",
                          textAlign: "left",
                        }}
                      >
                        &nbsp;&nbsp;
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        onClick={handleClosePopover2}
                        style={{ maxWidth: "5px", maxHeight: "5px" }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingBottom: "20px",
                      paddingTop: "8px",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#043365",
                        minWidth: "250px",
                      }}
                      onClick={handleLogout}
                    >
                      <Typography
                        style={{
                          color: "#FFFFFFE5",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          fontWeight: "600",
                          lineHeight: "20px",
                          letterSpacing: "-0.08399999886751175px",
                          textAlign: "center",
                        }}
                      >
                        Logout{" "}
                      </Typography>{" "}
                    </Button>
                  </Box>
                </Box>
              </Popover>
            </Backdrop>
            <Popover
              id={id}
              open={open}
              onClose={handleClosePopover}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {/* <Paper
          style={{
            width: "300px",
            height: "450px",
          }}
        > */}
              <Box
                style={{
                  width: "300px",
                  // height: "450px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingLeft: "24px",
                    paddingRight: "28px",
                    paddingBottom: "8px",
                  }}
                >
                  <Box>
                    <Typography
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                        fontWeight: 700,
                        lineHeight: "24px",
                        letterSpacing: "0px",
                        textAlign: "left",
                      }}
                    >
                      Select Employee
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      onClick={handleClosePopover}
                      style={{ maxWidth: "5px", maxHeight: "5px" }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>
                {/* <Box>
            <Typography
              style={{
                paddingLeft: "24px",
                paddingBottom: "5px",
                fontFamily: "Montserrat",
                fontSize: "12px",
                fontWeight: "600",
                lineHeight: "24px",
                letterSpacing: "0px",
                textAlign: "left",
              }}
            >
              Self
            </Typography>
          </Box> */}
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="delegation"
                      name="delegation"
                      //defaultValue={props?.employeeId?.toString()}
                      onChange={handleChange}
                      value={valueEmpId}
                    >
                      <FormControlLabel
                        value={props?.employeeId?.toString()}
                        control={<Radio color="primary" />}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          minWidth: "265px",
                          paddingBottom: "8px",
                        }}
                        label={
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Box style={{ paddingRight: "15px" }}>
                              <Avatar
                                style={{ width: "40px", height: "40px" }}
                              />
                            </Box>

                            <Box>
                              <Typography
                                style={{
                                  fontFamily: "Montserrat",
                                  fontSize: "12px",
                                  fontWeight: "600",
                                  lineHeight: "15px",
                                  letterSpacing: "0px",
                                  textAlign: "left",
                                }}
                              >
                                {props.employeeName}
                              </Typography>
                              <Typography
                                style={{
                                  fontFamily: "Montserrat",
                                  fontSize: "8px",
                                  fontWeight: "500",
                                  lineHeight: "10px",
                                  letterSpacing: "0.20000000298023224px",
                                  textAlign: "left",
                                }}
                              >{`Employee ID: ${props.employeeCode}`}</Typography>
                            </Box>
                          </Box>
                        }
                        labelPlacement="start"
                      ></FormControlLabel>

                      {(delCondition && pageModule === "Expense")
                        ? delegationFlag?.delegationFlag == 1 && (
                            <>
                              <FormControlLabel
                                value="DelegationButton"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  minWidth: "265px",
                                  paddingBottom: "8px",
                                }}
                                control={
                                  <Button
                                    style={{
                                      minHeight: "52px",
                                      minWidth: "265px",
                                      marginLeft: "-265px",
                                    }}
                                    color="transparent"
                                  />
                                }
                                label={
                                  <Box
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {/* {console.log(
                                      "checking something",
                                      delCondition
                                    )} */}
                                    <Button
                                      onClick={() => {
                                        //console.log("DC1 : Delegation clicked!expId:");
                                        const text = "/deligation/:id";
                                        const result = text.replace(
                                          ":id",
                                          props.employeeId
                                        );
                                        history.push({
                                          pathname: result,
                                          state: {
                                            empId: props.employeeId,
                                            compName: "addDelegationListing",
                                          },
                                        });
                                      }}
                                    >
                                      <Box style={{ paddingRight: "15px" }}>
                                        <img
                                          src={DelegationImage}
                                          style={{
                                            width: "24px",
                                            height: "24px",
                                          }}
                                        />
                                      </Box>
                                      <Box>
                                        <Typography
                                          style={{
                                            fontFamily: "Montserrat",
                                            fontSize: "10px",
                                            fontWeight: "600",
                                            lineHeight: "15px",
                                            letterSpacing: "0px",
                                            textAlign: "left",
                                            paddingRight: "127px",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          Delegation
                                        </Typography>
                                      </Box>
                                    </Button>
                                  </Box>
                                }
                                labelPlacement="start"
                              ></FormControlLabel>

                              {myDelegateesList?.length > 0 ? (
                                <Box>
                                  <Typography
                                    style={{
                                      paddingLeft: "24px",
                                      paddingBottom: "5px",
                                      fontFamily: "Montserrat",
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      lineHeight: "24px",
                                      letterSpacing: "0px",
                                      textAlign: "left",
                                    }}
                                  >
                                    Others
                                  </Typography>
                                </Box>
                              ) : (
                                ""
                              )}
                              {myDelegateesList?.map((del, index) => (
                                <FormControlLabel
                                  key={index}
                                  value={del?.empId?.toString()}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    minWidth: "265px",
                                    paddingBottom: "10px",
                                  }}
                                  control={<Radio color="primary" />}
                                  label={
                                    <Box
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Box style={{ paddingRight: "15px" }}>
                                        <Avatar
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                          }}
                                        />
                                      </Box>
                                      <Box>
                                        <Typography
                                          style={{
                                            fontFamily: "Montserrat",
                                            fontSize: "12px",
                                            fontWeight: "600",
                                            lineHeight: "15px",
                                            letterSpacing: "0px",
                                            textAlign: "left",
                                          }}
                                        >
                                          {del?.empName}
                                        </Typography>
                                        <Typography
                                          style={{
                                            fontFamily: "Montserrat",
                                            fontSize: "8px",
                                            fontWeight: "500",
                                            lineHeight: "10px",
                                            letterSpacing:
                                              "0.20000000298023224px",
                                            textAlign: "left",
                                          }}
                                        >
                                          Employee ID: {del?.empCode}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  }
                                  labelPlacement="start"
                                ></FormControlLabel>
                              ))}
                            </>
                          )
                        : ""}

                      {(delCondition && pageModule === "Timesheet")
                        ? timesheetDelegationFlag?.timesheetDelegationFlag == 1 && (
                            <>
                              <FormControlLabel
                                value="DelegationButton"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  minWidth: "265px",
                                  paddingBottom: "8px",
                                }}
                                control={
                                  <Button
                                    style={{
                                      minHeight: "52px",
                                      minWidth: "265px",
                                      marginLeft: "-265px",
                                    }}
                                    color="transparent"
                                  />
                                }
                                label={
                                  <Box
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Button
                                      onClick={() => {
                                        //console.log("DC1 : Delegation clicked!expId:");
                                        const text = "/timesheetDeligation/:id";
                                        const result = text.replace(
                                          ":id",
                                          props.employeeId
                                        );
                                        history.push({
                                          pathname: result,
                                          state: {
                                            empId: props.employeeId,
                                            compName: "addDelegationListing",
                                          },
                                        });
                                      }}
                                    >
                                      <Box style={{ paddingRight: "15px" }}>
                                        <img
                                          src={DelegationImage}
                                          style={{
                                            width: "24px",
                                            height: "24px",
                                          }}
                                        />
                                      </Box>
                                      <Box>
                                        <Typography
                                          style={{
                                            fontFamily: "Montserrat",
                                            fontSize: "10px",
                                            fontWeight: "600",
                                            lineHeight: "15px",
                                            letterSpacing: "0px",
                                            textAlign: "left",
                                            paddingRight: "127px",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          Delegation
                                        </Typography>
                                      </Box>
                                    </Button>
                                  </Box>
                                }
                                labelPlacement="start"
                              ></FormControlLabel>
                            </>
                          )
                        : ""}  
                      
                      {(delCondition && pageModule === "GM")
                        ? globalMobilityDelegationFlag?.delegationFlag == 1 && (
                            <>
                              <FormControlLabel
                                value="DelegationButton"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  minWidth: "265px",
                                  paddingBottom: "8px",
                                }}
                                control={
                                  <Button
                                    style={{
                                      minHeight: "52px",
                                      minWidth: "265px",
                                      marginLeft: "-265px",
                                    }}
                                    color="transparent"
                                  />
                                }
                                label={
                                  <Box
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Button
                                      onClick={() => {
                                        //console.log("DC1 : Delegation clicked!expId:");
                                        const text = "/globalMobilityDeligation/:id";
                                        const result = text.replace(
                                          ":id",
                                          props.employeeId
                                        );
                                        history.push({
                                          pathname: result,
                                          state: {
                                            empId: props.employeeId,
                                            compName: "addDelegationListing",
                                          },
                                        });
                                      }}
                                    >
                                      <Box style={{ paddingRight: "15px" }}>
                                        <img
                                          src={DelegationImage}
                                          style={{
                                            width: "24px",
                                            height: "24px",
                                          }}
                                        />
                                      </Box>
                                      <Box>
                                        <Typography
                                          style={{
                                            fontFamily: "Montserrat",
                                            fontSize: "10px",
                                            fontWeight: "600",
                                            lineHeight: "15px",
                                            letterSpacing: "0px",
                                            textAlign: "left",
                                            paddingRight: "127px",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          Delegation
                                        </Typography>
                                      </Box>
                                    </Button>
                                  </Box>
                                }
                                labelPlacement="start"
                              ></FormControlLabel>
                            </>
                          )
                        : ""}
                        
                      {myTimesheetDelegateesList && myTimesheetDelegateesList?.length > 0 ? (
                              <Box>
                                <Typography
                                  style={{
                                    paddingLeft: "24px",
                                    paddingBottom: "5px",
                                    fontFamily: "Montserrat",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    lineHeight: "24px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                  }}
                                >
                                  Others
                                </Typography>
                              </Box>
                            ) : (
                              ""
                            )}
                      {myTimesheetDelegateesList?.length > 0 && myTimesheetDelegateesList?.map((del, index) => (
                        <FormControlLabel
                          key={index}
                          value={del?.empId?.toString()}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            minWidth: "265px",
                            paddingBottom: "10px",
                          }}
                          control={<Radio color="primary" />}
                          label={
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Box style={{ paddingRight: "15px" }}>
                                <Avatar
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                  }}
                                />
                              </Box>
                              <Box>
                                <Typography
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    lineHeight: "15px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                  }}
                                >
                                  {del?.empName}
                                </Typography>
                                <Typography
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "8px",
                                    fontWeight: "500",
                                    lineHeight: "10px",
                                    letterSpacing:
                                      "0.20000000298023224px",
                                    textAlign: "left",
                                  }}
                                >
                                  Employee ID: {del?.empCode}
                                </Typography>
                              </Box>
                            </Box>
                          }
                          labelPlacement="start"
                        ></FormControlLabel>
                      ))}

                      {myGlobalMobilityDelegateesList && myGlobalMobilityDelegateesList?.length > 0 ? (
                              <Box>
                                <Typography
                                  style={{
                                    paddingLeft: "24px",
                                    paddingBottom: "5px",
                                    fontFamily: "Montserrat",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    lineHeight: "24px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                  }}
                                >
                                  Others
                                </Typography>
                              </Box>
                            ) : (
                              ""
                            )}
                      {myGlobalMobilityDelegateesList?.length > 0 && myGlobalMobilityDelegateesList?.map((del, index) => (
                        <FormControlLabel
                          key={index}
                          value={del?.empId?.toString()}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            minWidth: "265px",
                            paddingBottom: "10px",
                          }}
                          control={<Radio color="primary" />}
                          label={
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Box style={{ paddingRight: "15px" }}>
                                <Avatar
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                  }}
                                />
                              </Box>
                              <Box>
                                <Typography
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    lineHeight: "15px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                  }}
                                >
                                  {del?.empName}
                                </Typography>
                                <Typography
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "8px",
                                    fontWeight: "500",
                                    lineHeight: "10px",
                                    letterSpacing:
                                      "0.20000000298023224px",
                                    textAlign: "left",
                                  }}
                                >
                                  Employee ID: {del?.empCode}
                                </Typography>
                              </Box>
                            </Box>
                          }
                          labelPlacement="start"
                        ></FormControlLabel>
                      ))}  
                    </RadioGroup>
                  </FormControl>
                </Box>
            
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "20px",
                    paddingTop: "8px",
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: "#043365",
                      minWidth: "250px",
                    }}
                    onClick={handleLogout}
                  >
                    <Typography
                      style={{
                        color: "#FFFFFFE5",
                        fontFamily: "Montserrat",
                        fontSize: "16px",
                        fontWeight: "600",
                        lineHeight: "20px",
                        letterSpacing: "-0.08399999886751175px",
                        textAlign: "center",
                      }}
                    >
                      Logout{" "}
                    </Typography>{" "}
                  </Button>
                </Box>
              </Box>
              {/* </Paper> */}
            </Popover>
          </Box>
        </>
      ) : (
        <Box component="span" m={1}>
          {props.disableAccOptions == 1 ? (
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handlePopover2}
            >
              <Avatar
                alt={
                  props.delEmpName !== undefined
                    ? props.delEmpName
                    : props.employeeName
                }
                src="/static/images/avatar/1.jpg"
                className={classes.small}
              >
                {props?.delEmpName
                  ? Array.from(props?.delEmpName)[0]
                  : Array.from(props.employeeName)[0]}
              </Avatar>
            </IconButton>
          ) : (
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handlePopover}
            >
              <Avatar
                alt={
                  props.delEmpName !== undefined
                    ? props.delEmpName
                    : props.employeeName
                }
                src="/static/images/avatar/1.jpg"
                className={classes.small}
              >
                {props?.delEmpName
                  ? Array.from(props?.delEmpName)[0]
                  : Array.from(props.employeeName)[0]}
              </Avatar>
            </IconButton>
          )}
          <Popover
            id={id2}
            open={open2}
            onClose={handleClosePopover2}
            anchorEl={anchorEl2}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Box
              style={{
                width: "300px",
                // height: "450px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: "10px",
                  paddingLeft: "24px",
                  paddingRight: "28px",
                  paddingBottom: "8px",
                }}
              >
                <Box>
                  <Typography
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      fontWeight: 700,
                      lineHeight: "24px",
                      letterSpacing: "0px",
                      textAlign: "left",
                    }}
                  >
                    &nbsp;&nbsp;
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    onClick={handleClosePopover2}
                    style={{ maxWidth: "5px", maxHeight: "5px" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "20px",
                  paddingTop: "8px",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#043365",
                    minWidth: "250px",
                  }}
                  onClick={handleLogout}
                >
                  <Typography
                    style={{
                      color: "#FFFFFFE5",
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "20px",
                      letterSpacing: "-0.08399999886751175px",
                      textAlign: "center",
                    }}
                  >
                    Logout{" "}
                  </Typography>{" "}
                </Button>
              </Box>
            </Box>
          </Popover>
          <Popover
            id={id}
            open={open}
            onClose={handleClosePopover}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {/* <Paper
          style={{
            width: "300px",
            height: "450px",
          }}
        > */}
            <Box
              style={{
                width: "300px",
                // height: "450px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: "10px",
                  paddingLeft: "24px",
                  paddingRight: "28px",
                  paddingBottom: "8px",
                }}
              >
                {delCondition == true && (
                  <Box>
                    <Typography
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                        fontWeight: 700,
                        lineHeight: "24px",
                        letterSpacing: "0px",
                        textAlign: "left",
                      }}
                    >
                      Select Employee
                    </Typography>
                  </Box>
                )}
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {delCondition == true && (
                    <IconButton
                      onClick={handleClosePopover}
                      style={{ maxWidth: "5px", maxHeight: "5px" }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>
              {/* <Box>
            <Typography
              style={{
                paddingLeft: "24px",
                paddingBottom: "5px",
                fontFamily: "Montserrat",
                fontSize: "12px",
                fontWeight: "600",
                lineHeight: "24px",
                letterSpacing: "0px",
                textAlign: "left",
              }}
            >
              Self
            </Typography>
          </Box> */}
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="delegation"
                    name="delegation"
                    //defaultValue={props?.employeeId?.toString()}
                    onChange={handleChange}
                    value={valueEmpId}
                  >
                    <FormControlLabel
                      value={props?.employeeId?.toString()}
                      control={<Radio color="primary" />}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        minWidth: "265px",
                        paddingBottom: "8px",
                      }}
                      label={
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Box style={{ paddingRight: "15px" }}>
                            <Avatar style={{ width: "40px", height: "40px" }} />
                          </Box>

                          <Box>
                            <Typography
                              style={{
                                fontFamily: "Montserrat",
                                fontSize: "12px",
                                fontWeight: "600",
                                lineHeight: "15px",
                                letterSpacing: "0px",
                                textAlign: "left",
                              }}
                            >
                              {props.employeeName}
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: "Montserrat",
                                fontSize: "8px",
                                fontWeight: "500",
                                lineHeight: "10px",
                                letterSpacing: "0.20000000298023224px",
                                textAlign: "left",
                              }}
                            >{`Employee ID: ${props.employeeCode}`}</Typography>
                          </Box>
                        </Box>
                      }
                      labelPlacement="start"
                    ></FormControlLabel>
                    {delegationFlag?.delegationFlag == 1 && delCondition && pageModule === "Expense" && (
                      <FormControlLabel
                        value="DelegationButton"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          minWidth: "265px",
                          paddingBottom: "8px",
                        }}
                        control={
                          <Button
                            style={{
                              minHeight: "52px",
                              minWidth: "265px",
                              marginLeft: "-265px",
                            }}
                            color="transparent"
                          />
                        }
                        label={
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              onClick={() => {
                                //console.log("DC1 : Delegation clicked!expId:");
                                const text = "/deligation/:id";
                                const result = text.replace(
                                  ":id",
                                  props.employeeId
                                );
                                history.push({
                                  pathname: result,
                                  state: {
                                    empId: props.employeeId,
                                    compName: "addDelegationListing",
                                  },
                                });
                              }}
                            >
                              <Box style={{ paddingRight: "15px" }}>
                                <img
                                  src={DelegationImage}
                                  style={{ width: "24px", height: "24px" }}
                                />
                              </Box>
                              <Box>
                                <Typography
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "10px",
                                    fontWeight: "600",
                                    lineHeight: "15px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                    paddingRight: "127px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Delegation
                                </Typography>
                              </Box>
                            </Button>
                          </Box>
                        }
                        labelPlacement="start"
                      ></FormControlLabel>
                    )}

                    {timesheetDelegationFlag?.timesheetDelegationFlag == 1 && delCondition && pageModule === "Timesheet" && (
                      <FormControlLabel
                        value="DelegationButton"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          minWidth: "265px",
                          paddingBottom: "8px",
                        }}
                        control={
                          <Button
                            style={{
                              minHeight: "52px",
                              minWidth: "265px",
                              marginLeft: "-265px",
                            }}
                            color="transparent"
                          />
                        }
                        label={
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              onClick={() => {
                                //console.log("DC1 : Delegation clicked!expId:");
                                const text = "/timesheetDeligation/:id";
                                const result = text.replace(
                                  ":id",
                                  props.employeeId
                                );
                                history.push({
                                  pathname: result,
                                  state: {
                                    empId: props.employeeId,
                                    compName: "addDelegationListing",
                                  },
                                });
                              }}
                            >
                              <Box style={{ paddingRight: "15px" }}>
                                <img
                                  src={DelegationImage}
                                  style={{ width: "24px", height: "24px" }}
                                />
                              </Box>
                              <Box>
                                <Typography
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "10px",
                                    fontWeight: "600",
                                    lineHeight: "15px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                    paddingRight: "127px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Delegation
                                </Typography>
                              </Box>
                            </Button>
                          </Box>
                        }
                        labelPlacement="start"
                      ></FormControlLabel>
                    )} 

                    {globalMobilityDelegationFlag?.delegationFlag == 1 && delCondition && pageModule === "GM" && (
                      <FormControlLabel
                        value="DelegationButton"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          minWidth: "265px",
                          paddingBottom: "8px",
                        }}
                        control={
                          <Button
                            style={{
                              minHeight: "52px",
                              minWidth: "265px",
                              marginLeft: "-265px",
                            }}
                            color="transparent"
                          />
                        }
                        label={
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              onClick={() => {
                                //console.log("DC1 : Delegation clicked!expId:");
                                const text = "/globalMobilityDeligation/:id";
                                const result = text.replace(
                                  ":id",
                                  props.employeeId
                                );
                                history.push({
                                  pathname: result,
                                  state: {
                                    empId: props.employeeId,
                                    compName: "addDelegationListing",
                                  },
                                });
                              }}
                            >
                              <Box style={{ paddingRight: "15px" }}>
                                <img
                                  src={DelegationImage}
                                  style={{ width: "24px", height: "24px" }}
                                />
                              </Box>
                              <Box>
                                <Typography
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "10px",
                                    fontWeight: "600",
                                    lineHeight: "15px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                    paddingRight: "127px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Delegation
                                </Typography>
                              </Box>
                            </Button>
                          </Box>
                        }
                        labelPlacement="start"
                      ></FormControlLabel>
                    )} 

                    {myDelegateesList?.length > 0 && delCondition && pageModule === "Expense" ? (
                      <Box>
                        <Typography
                          style={{
                            paddingLeft: "24px",
                            paddingBottom: "5px",
                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            fontWeight: "600",
                            lineHeight: "24px",
                            letterSpacing: "0px",
                            textAlign: "left",
                          }}
                        >
                          Others
                        </Typography>
                      </Box>
                    ) : (
                      ""
                    )}
                    
                    {myTimesheetDelegateesList?.length > 0 && delCondition && pageModule === "Timesheet" ? (
                      <Box>
                        <Typography
                          style={{
                            paddingLeft: "24px",
                            paddingBottom: "5px",
                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            fontWeight: "600",
                            lineHeight: "24px",
                            letterSpacing: "0px",
                            textAlign: "left",
                          }}
                        >
                          Others
                        </Typography>
                      </Box>
                    ) : (
                      ""
                    )}

                    {myGlobalMobilityDelegateesList?.length > 0 && delCondition && pageModule === "GM" ? (
                      <Box>
                        <Typography
                          style={{
                            paddingLeft: "24px",
                            paddingBottom: "5px",
                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            fontWeight: "600",
                            lineHeight: "24px",
                            letterSpacing: "0px",
                            textAlign: "left",
                          }}
                        >
                          Others
                        </Typography>
                      </Box>
                    ) : (
                      ""
                    )}

                    {delCondition && pageModule === "Expense" &&
                      myDelegateesList?.map((del, index) => (
                        <FormControlLabel
                          key={index}
                          value={del?.empId?.toString()}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            minWidth: "265px",
                            paddingBottom: "10px",
                          }}
                          control={<Radio color="primary" />}
                          label={
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Box style={{ paddingRight: "15px" }}>
                                <Avatar
                                  style={{ width: "40px", height: "40px" }}
                                />
                              </Box>
                              <Box>
                                <Typography
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    lineHeight: "15px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                  }}
                                >
                                  {del?.empName}
                                </Typography>
                                <Typography
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "8px",
                                    fontWeight: "500",
                                    lineHeight: "10px",
                                    letterSpacing: "0.20000000298023224px",
                                    textAlign: "left",
                                  }}
                                >
                                  Employee ID: {del?.empCode}
                                </Typography>
                              </Box>
                            </Box>
                          }
                          labelPlacement="start"
                        ></FormControlLabel>
                      ))}

                    {delCondition && pageModule === "Timesheet" && myTimesheetDelegateesList?.length > 0 && 
                      myTimesheetDelegateesList?.map((del, index) => (
                        <FormControlLabel
                          key={index}
                          value={del?.empId?.toString()}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            minWidth: "265px",
                            paddingBottom: "10px",
                          }}
                          control={<Radio color="primary" />}
                          label={
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Box style={{ paddingRight: "15px" }}>
                                <Avatar
                                  style={{ width: "40px", height: "40px" }}
                                />
                              </Box>
                              <Box>
                                <Typography
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    lineHeight: "15px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                  }}
                                >
                                  {del?.empName}
                                </Typography>
                                <Typography
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "8px",
                                    fontWeight: "500",
                                    lineHeight: "10px",
                                    letterSpacing: "0.20000000298023224px",
                                    textAlign: "left",
                                  }}
                                >
                                  Employee ID: {del?.empCode}
                                </Typography>
                              </Box>
                            </Box>
                          }
                          labelPlacement="start"
                        ></FormControlLabel>
                      ))}

                    {delCondition && pageModule === "GM" && myGlobalMobilityDelegateesList?.length > 0 && 
                      myGlobalMobilityDelegateesList?.map((del, index) => (
                        <FormControlLabel
                          key={index}
                          value={del?.empId?.toString()}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            minWidth: "265px",
                            paddingBottom: "10px",
                          }}
                          control={<Radio color="primary" />}
                          label={
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Box style={{ paddingRight: "15px" }}>
                                <Avatar
                                  style={{ width: "40px", height: "40px" }}
                                />
                              </Box>
                              <Box>
                                <Typography
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    lineHeight: "15px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                  }}
                                >
                                  {del?.empName}
                                </Typography>
                                <Typography
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "8px",
                                    fontWeight: "500",
                                    lineHeight: "10px",
                                    letterSpacing: "0.20000000298023224px",
                                    textAlign: "left",
                                  }}
                                >
                                  Employee ID: {del?.empCode}
                                </Typography>
                              </Box>
                            </Box>
                          }
                          labelPlacement="start"
                        ></FormControlLabel>
                      ))}             

                  </RadioGroup>
                </FormControl>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "20px",
                  paddingTop: "8px",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#043365",
                    minWidth: "250px",
                  }}
                  onClick={handleLogout}
                >
                  <Typography
                    style={{
                      color: "#FFFFFFE5",
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "20px",
                      letterSpacing: "-0.08399999886751175px",
                      textAlign: "center",
                    }}
                  >
                    Logout{" "}
                  </Typography>{" "}
                </Button>
              </Box>
            </Box>
            {/* </Paper> */}
          </Popover>
        </Box>
      )}
    </>
  );
};

export default AccountOptions;