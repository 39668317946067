import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import LaunchIcon from "@material-ui/icons/Launch";
import Drawer from "@material-ui/core/Drawer";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import clsx from "clsx";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SearchIcon from "@material-ui/icons/Search";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Box, Paper, makeStyles } from "@material-ui/core";
import { Button } from "antd";
import Tooltip from "@material-ui/core/Tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import moment from "moment";
import { Chip } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Collapse from "@mui/material/Collapse";
import Grid from "@material-ui/core/Grid";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Link, useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import {
  exportAsPdf,
  exportAsPdfData,
  getDailyPreviousTimesheetList,
  getWeeklyPreviousTimesheetList,
  getTempWeeklyPreviousTimesheetList,
  getTimesheetPurposeData,
} from "../redux/TimeSheet/actionTs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { FormControlLabel } from "@material-ui/core";
import MobileFloatingButton from "../components/mobileFloatingButton";
import { styled } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { useTheme } from "@material-ui/core/styles";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 13,
    top: 31,
    border: `0.125rem solid ${theme.palette.background.paper}`,
    padding: "0 0.25rem",
  },
}));
const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    boxShadow: "none",
  },
  tooltip: {
    paddingRight: "0.313rem",
    color: "#9E9E9E",
    maxWidth: "1.25rem",
    maxHeight: "1.25rem",
  },
  tooltipBox: {
    minWidth: "7.5rem",
    left: "auto!important",
    right: 0,
    marginTop: "-1.375rem",
    marginRight: "0.125rem",
    // top:'-1.25rem!important'
  },
  arrow: {
    left: "auto!important",

    right: 0,
  },
  rootFooter: {
    flexShrink: 0,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    
  },
  paginationStyle:{
    "& .MuiTablePagination-toolbar ":{
      paddingLeft:"6px",
      paddingRight:"0px"
    }
  }
}));
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  //   { width: "46px" },
  { width: "0.938rem" },
  {
    id: "timePeriod",
    numeric: false,
    disablePadding: true,
    label: "Time Period",
    width: "100%",
    lineHeight: "0rem",
    textAlign: "left",
  },
  {
    id: "billedHours",
    numeric: true,
    disablePadding: true,
    label: "Billed Hours",
    width: "96%",
    lineHeight: "0.813rem",
    textAlign: "right",
  },
  {
    id: "nonBilledHours",
    numeric: true,
    disablePadding: true,
    label: "Non Billed Hours",
    width: "3.75rem",
    lineHeight: "0.813rem",
    textAlign: "right",
  },
  {
    id: "pendingApprovals",
    numeric: true,
    disablePadding: true,
    label: "Pending Approvals",
    width: "4.375rem",
    lineHeight: "0.813rem",
    textAlign: "right",
  },
];
function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
    //console.log("event1",event)
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  return (
    <div className={classes.rootFooter}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        style={{padding:"3px"}}
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        style={{padding:"3px"}}
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        style={{padding:"3px"}}
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        style={{padding:"3px"}}
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
function MyTimesheetTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ backgroundColor: "#E3E8F7" }}>
      <TableRow style={{ height: "2.813rem" }}>
        {/* <TableCell
          component="th"
          scope="row"
          // component="td"
          padding="none"
          // scope="col-1"
          style={{
            width: "0 rem",
          }}
        /> */}
        {headCells.map((headCell) => (
          <TableCell
            component="th"
            scope="row"
            style={{
              fontWeight: 600,
              padding: "0rem",
              //width: headCell.width,
              fontSize: "0.625rem",
            }}
            key={headCell.id}
          >
            <div
              style={{
                width: headCell.width,
                lineHeight: headCell.lineHeight,
                textAlign: headCell.textAlign,
              }}
            >
              {headCell.label}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

MyTimesheetTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  //   onRequestSort: PropTypes.func.isRequired,
  //   onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  //rowCount: PropTypes.number.isRequired,
};
function ResponsiveMyTimesheetTable(props) {
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [order, setOrder] = React.useState("asc");
  const matches = useMediaQuery("(max-width:600px)");
  const target = useRef(null);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [projID, setProjID] = React.useState([]);
  const [statusFilter, setStatusFilter] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [commentData, setCommentData] = React.useState([]);
  const [toolAction, setToolAction] = React.useState("");
  const [currentIndex, setCurrentIndex] = React.useState();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [toDate, setToDate] = useState(null);

  const [anchorFilter1, setAnchorFilter1] = useState("right");
  const [buttonState, setButtonState] = React.useState(true);
  const statusOptions = [
    "In Review",
    "Auto Approved",
    "Approved",
    "Rejected",
    "Draft",
  ];
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState([]);
  const [countShow, setCountShow] = React.useState(0);
  const [isLoader, isSetLoader] = useState(true);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(parseInt(15, 10));
  
  const formData = new FormData();

  let tempvalcount = 0;
  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );
  const weeklyTimesheetList = useSelector(
    (state) => state.timesheetData.getWeeklyPreviousTimesheetList
  );
  const tsDelEmpIdRedux = useSelector((state) => state?.timesheetData?.tsDelEmpId);
  const tempWeeklyTimesheetList = useSelector((state) => state.timesheetData.getTempWeeklyPreviousTimesheetList);

  const dailyPreviousTimesheetList = useSelector(
    (state) => state?.timesheetData?.getDailyPreviousTimesheetList?.undefined
  );
  const outPutData = useSelector(
    (state) => state.timesheetData?.postExtractPDFData
  );
  const projectListTs = useSelector(
    (state) => state.timesheetData.getTimesheetPurposeList
  );

  useEffect(() => {
    if(dailyPreviousTimesheetList && weeklyTimesheetList){
      isSetLoader(false);
    }
    else{
      isSetLoader(true);
    }
  }, [dailyPreviousTimesheetList, weeklyTimesheetList])

  // useEffect(() => {
  //   if (Array.isArray(weeklyTimesheetList)) {
  //     weeklyTimesheetList.forEach((item, index) => {
  //       let obj = {
  //         empId: ssoEmpIdDetails?.empId,
  //         tmId: item?.timesheetId,
  //         projId: projID ? projID : [],
  //         status: selectedStatus ? selectedStatus : [],
  //         // status: props.filterData.statusChip
  //         //   ? props.filterData.statusChip
  //         //   : [],
  //       };
  //       dispatch(getDailyPreviousTimesheetList(obj));
  //       console.log("prontingweeklytimesheet123", dailyPreviousTimesheetList);
  //       console.log("inside initial useeffect", obj);
  //     });
  //   } else {
  //     console.log("weeklyTimesheetList is not an array:", weeklyTimesheetList);
  //   }
  // }, [weeklyTimesheetList]);

  // useEffect(() => {
  //   if (ssoEmpIdDetails) {
  //     let obj = {
  //       empId: ssoEmpIdDetails?.empId,
  //       tmId: null,
  //       projId: [],
  //       status: [],
  //     };
  //     console.log("inside initial useeffect 2", obj);
  //     dispatch(getDailyPreviousTimesheetList(obj));
  //   }
  // }, [ssoEmpIdDetails]);

  // useEffect(() => {
  //   if (ssoEmpIdDetails) {
  //     let obj = {
  //       empId: ssoEmpIdDetails?.empId,
  //       date: moment(new Date()).format("yyyy-MM-DD"),
  //       tmId: null,
  //       projId: [],
  //       status: [],
  //     };
  //     // console.log("object", obj);

  //     dispatch(getWeeklyPreviousTimesheetList(obj));
  //   }
  //   // console.log("week ", weeklyTimesheetList);
  // }, [ssoEmpIdDetails]);

  // useEffect(() => {
  //   if (weeklyTimesheetList && weeklyTimesheetList.length > 0) {
  //     handleApplyFilter();
  //     console.log("inside useeffect of applyfilter");
  //   }
  //   console.log("inside useeffect outside if");
  // }, []);

  useEffect(() => {
    if (
      (ssoEmpIdDetails || tsDelEmpIdRedux) &&
      selectedTimePeriod.length === 0 &&
      selectedProjectId.length === 0 &&
      selectedStatus.length === 0
    ) {
      setButtonState(true);
      let daily = {
        empId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
        tmId: null,
        projId: [],
        status: [],
      };

      let weekly = {
        empId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
        date: moment(new Date()).format("yyyy-MM-DD"),
        tmId: null,
        projId: [],
        status: [],
      };
      // console.log("inside initial useeffect when no data",daily);
      dispatch(getDailyPreviousTimesheetList(daily));
      dispatch(getWeeklyPreviousTimesheetList(weekly));
    }
  }, [ssoEmpIdDetails, tsDelEmpIdRedux, selectedTimePeriod, selectedProjectId, selectedStatus]);

  useEffect(() => {
    if (tempWeeklyTimesheetList?.length > 0 && selectedProjectId?.length > 0) {

        const keyValue =  Array.isArray(tempWeeklyTimesheetList) ? tempWeeklyTimesheetList?.map((item) => item?.projectUniqueID?.split(", "))
              .flat()
              .filter((projectUniqueID, index, self) => self.indexOf(projectUniqueID) === index): []

              const extractedValues = [].concat.apply(
                [],
                selectedProjectId?.map((selectedId) => {
                  if (selectedId) {
                    return keyValue?.filter((item) => item?.startsWith(selectedId + ':'))
                      .map((item) => item?.split(':')[1]);
                  }
                  return [];
                })
              );
              setProjID(extractedValues);
    }
  }, [tempWeeklyTimesheetList, selectedProjectId]);


  useEffect(() => {
      let weekly = {
        empId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
        date: moment(new Date()).format("yyyy-MM-DD"),
        tmId: null,
        projId: [],
        status: [],
      };
      dispatch(getTempWeeklyPreviousTimesheetList(weekly));
      dispatch(getTimesheetPurposeData(tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId));
  }, [ssoEmpIdDetails, tsDelEmpIdRedux, dispatch]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    //console.log("value of rows", event.target.value);
  };

  const handleTooltipClose = () => {
    // console.log("close");
    setOpen(false);
  };
  const handleToolTip = (comment) => {
    // console.log("commentsss", comment);
    const arr = comment.split("|");

    return (
      <>
        <Box>
          {arr?.map((value) => {
            return (
              <>
                <Typography
                  style={{ fontSize: "0.625rem" }}
                  className={classes.statusName}
                >
                  {value}
                </Typography>
              </>
            );
          })}
        </Box>
      </>
    );
  };
  const handlToolAction = (event) => {
    // console.log(event);
    // console.log("action");
    setToolAction(event.currentTarget);
  };
  const handleTooltipOpen = (comments, index) => {
    // console.log("comment", comments);

    setOpen(true);
    setCommentData(comments);
    // setCommentHeading(nextAction);
    setCurrentIndex(index);
  };
  const handleParentToolTip = () => {
    // console.log(":parent tool tip function");
    return (
      <>
        <OutsideClickHandler
          onOutsideClick={() => {
            handleTooltipClose();
          }}
        >
          <div style={{ position: "relative" }}>
            <Tooltip
              PopperProps={{
                disablePortal: true,
                anchorEl: { toolAction },
              }}
              arrow
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={handleToolTip(commentData)}
              classes={{
                popper: classes.tooltipBox,
                arrow: classes.arrow,
              }}
            >
              <span
                style={{ maxwidth: "1.25rem", minWidth: "0 rem", height: "0" }}
              ></span>
            </Tooltip>
          </div>
        </OutsideClickHandler>
      </>
    );
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row.timePeriod);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.timePeriod);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    // console.log("newSelected", newSelected);

    // const obj = {
    //   empId: ssoEmpIdDetails?.empId,
    //   tmId: row.timesheetId,
    //   projId: projID ? projID : [],
    //   status: props.filterData.statusChip ? props.filterData.statusChip : [],
    // };

    //dispatch(getDailyPreviousTimesheetList(obj));
    setSelected(newSelected);
  };
  const handleCreateTimesheet = () => {
    history.push({
      pathname: "/create-Timesheet-Management",
      state: {
        ssoEmpId: ssoEmpIdDetails.empId,
        compName: "createTimesheet",
        projListProp: projectListTs,
      },
    });
  };
  const handleTimePeriodChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedTimePeriod(selectedValue);
    if (
      selectedTimePeriod != "" ||
      selectedProjectId != [] ||
      selectedStatus != []
    ) {
      setButtonState(false);
    }
  };
  const handleStatusChange = (event, values) => {
    if (values) {
      setSelectedStatus(values);
    } else {
      setSelectedStatus([]);
    }
    if (
      selectedTimePeriod != "" ||
      selectedProjectId != [] ||
      selectedStatus != []
    ) {
      setButtonState(false);
    }
  };

  const handleProjectIdChange = (event, value) => {
    if (value) {
      const selectedValue = value;

      if (!selectedProjectId.includes(selectedValue)) {
        setSelectedProjectId((prevIds) => [...prevIds, selectedValue]);
      }
    } else {
      setSelectedProjectId([]);
    }
    if (
      selectedTimePeriod != "" ||
      selectedProjectId != [] ||
      selectedStatus != []
    ) {
      setButtonState(false);
    }
  };
  const handleApplyFilter = () => {
    let tempTMID = tempWeeklyTimesheetList.find(
      (item) => selectedTimePeriod === item.timePeriod
    )?.timesheetId;

    let weeklyObj = {
      empId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
      date: moment(new Date()).format("yyyy-MM-DD"),
      tmId: tempTMID ? tempTMID : null,
      projId: projID ? projID : [],
      status: selectedStatus ? selectedStatus : [],
    };

    let dailyObj = {
      empId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
      tmId: tempTMID ? tempTMID : null,
      projId: projID ? projID : [],
      status: selectedStatus ? selectedStatus : [],
    };

    dispatch(getWeeklyPreviousTimesheetList(weeklyObj));
    dispatch(getDailyPreviousTimesheetList(dailyObj));
    setState({
      ...state,
      bottom: false,
      top: false,
      right: false,
      left: false,
    });
    if (selectedTimePeriod != "") {
      tempvalcount++;
      // console.log("countshowfirst", tempvalcount, selectedTimePeriod);
    }
    if (selectedStatus.length > 0) {
      tempvalcount++;
      // console.log("countshowsecond", tempvalcount, selectedStatus);
    }
    if (selectedProjectId.length > 0) {
      tempvalcount++;
      // console.log("countshowthird", tempvalcount, selectedProjectId);
    }
    //let tempvalcount = countVal1 + countVal2+countVal3;
    setCountShow(tempvalcount);
    // console.log("countshow", tempvalcount);
  };

  const handleClearAll = () => {
    setSelectedTimePeriod("");
    setSelectedProjectId([]);
    setSelectedStatus([]);
    setButtonState(true);
    setCountShow(0);
    let weeklyObj = {
      empId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
      date: moment(new Date()).format("yyyy-MM-DD"),
      tmId: null,
      projId: [],
      status: [],
    };
    let daily = {
      empId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
      tmId: null,
      projId: [],
      status: [],
    };
    // console.log("inside initial useeffect clearall", daily);
    dispatch(getDailyPreviousTimesheetList(daily));

    dispatch(getWeeklyPreviousTimesheetList(weeklyObj));
  };

  const handleCheckboxChange = (item) => {
    let newSelectedItems = [];
    if (selectedItems.includes(item)) {
      newSelectedItems = selectedItems.filter((i) => i !== item);
      //   setSelectedItems(selectedItems.filter((i) => i !== item));
    } else {
      newSelectedItems = selectedItems;
      newSelectedItems.push(item);
      //   setSelectedItems([...selectedItems, item]);
    }
    setSelectedItems(newSelectedItems);
    const convertedArray = newSelectedItems?.map((value) => ({ tmid: value }));
    const data3 = convertedArray;
    const stringifiedData = JSON.stringify(data3);
    formData.append("tMId", stringifiedData);
    dispatch(exportAsPdfData(formData));
    //seValFun();
  };
  const toggleDrawer = (anchor, open) => (event) => {
    // console.log("aa gaye");
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  //   useEffect(() => {
  //     const convertedArray = selectedItems?.map((value) => ({ tmid: value }));
  //     props.onClickSetVal(convertedArray);
  //   }, [selectedItems]);

    const handleEditClick = (row) => {
      history.push({
        pathname: "/create-Timesheet-Management",
        state: {
          fromDate: moment(row.fromDate).format("yyyy-MM-DD"),
          toDate: moment(row.toDate).format("yyyy-MM-DD"),
          // empId: row.empId,
          // response: row,
          empId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
          edit: 1,
        },
      });
    };
  const executeExportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    const lengthLoopVal = outPutData ? outPutData?.length : "0";

    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    for (let i = 0; i <= lengthLoopVal - 1; i++) {
      const currentDate = new Date();

      const day = String(currentDate.getDate()).padStart(2, "0");
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const year = currentDate.getFullYear();

      const formattedDate = `${day}/${month}/${year}`;
      const formattedTime = currentDate.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });

      const TimesheetNumberVal =
        outPutData[i]?.TimesheetMasterData.TimesheetNumber;
      const TimesheetNameVal = outPutData[i]?.TimesheetMasterData.EmpName;
      const ApprovalStatusVal =
        outPutData[i]?.TimesheetMasterData.ApprovalStatus;
      const StartDateVal = outPutData[i]?.TimesheetMasterData.StartDate;
      const EndDateVal = outPutData[i]?.TimesheetMasterData.EndDate;
      const TotalHoursVal = outPutData[i]?.TimesheetMasterData.TotalHours;
      const TotalBillableHoursVal =
        outPutData[i]?.TimesheetMasterData.TotalBillableHours;
      const WorkWeekVal = outPutData[i]?.TimesheetMasterData.WorkWeek;
      const compName = outPutData[i]?.TimesheetMasterData.CompanyName;
      const day1Val = outPutData[i]?.TimesheetDetails[0]?.day1;
      const day2Val = outPutData[i]?.TimesheetDetails[0]?.day2;
      const day3Val = outPutData[i]?.TimesheetDetails[0]?.day3;
      const day4Val = outPutData[i]?.TimesheetDetails[0]?.day4;
      const day5Val = outPutData[i]?.TimesheetDetails[0]?.day5;
      const day6Val = outPutData[i]?.TimesheetDetails[0]?.day6;
      const day7Val = outPutData[i]?.TimesheetDetails[0]?.day7;
      const date1Val = outPutData[i]?.TimesheetDetails[0]?.date1;
      const date2Val = outPutData[i]?.TimesheetDetails[0]?.date2;
      const date3Val = outPutData[i]?.TimesheetDetails[0]?.date3;
      const date4Val = outPutData[i]?.TimesheetDetails[0]?.date4;
      const date5Val = outPutData[i]?.TimesheetDetails[0]?.date5;
      const date6Val = outPutData[i]?.TimesheetDetails[0]?.date6;
      const date7Val = outPutData[i]?.TimesheetDetails[0]?.date7;

      doc.setFont("helvetica", "bold");
      doc.setFontSize(19);
      doc.text("Timesheet sign-off report", 39, 40);

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(`${compName}`, 39, 60);

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(`Page ${i + 1} of ${lengthLoopVal}`, 750, 40);
      doc.text(`${formattedDate}`, 751, 60);
      doc.text(`${formattedTime}`, 758, 80);

      doc.text("Timesheet number", 39, 120);
      doc.text(`${TimesheetNumberVal}`, 180, 120);
      doc.text(`${TimesheetNameVal}`, 39, 140);
      doc.text("Approval status", 39, 160);
      doc.text(`${ApprovalStatusVal}`, 180, 160);

      doc.text("Start date", 380, 120);
      doc.text(`${StartDateVal}`, 540, 120);
      doc.text("End date", 380, 140);
      doc.text(`${EndDateVal}`, 540, 140);
      doc.text("Total hours", 380, 160);
      doc.text(`${TotalHoursVal.toFixed(2)}`, 540, 160);
      doc.text("Total billable hours", 380, 180);
      doc.text(`${TotalBillableHoursVal.toFixed(2)}`, 540, 180);

      doc.setFont("helvetica", "bold");
      doc.text(`Work week: ${WorkWeekVal}`, 39, 220);

      const headers = [
        [
          "Legal Entity",
          "Customer Name",
          "Project ID",
          "Project Name",
          "Project Manager",
          "Category",
          `${day1Val} ${date1Val}`,
          `${day2Val} ${date2Val}`,
          `${day3Val} ${date3Val}`,
          `${day4Val} ${date4Val}`,
          `${day5Val} ${date5Val}`,
          `${day6Val} ${date6Val}`,
          `${day7Val} ${date7Val}`,
          "Total",
        ],
      ];

      const data = outPutData?.map((obj) =>
        obj?.TimesheetDetails?.map((elt) => [
          elt.LegalEntity,
          elt.CustomerName,
          elt.ProjectID,
          elt.ProjectName,
          elt.ProjectManagerName,
          elt.Category,
          elt.dayhours1,
          elt.dayhours2,
          elt.dayhours3,
          elt.dayhours4,
          elt.dayhours5,
          elt.dayhours6,
          elt.dayhours7,
          elt.Total,
        ])
      );

      const dataVal = data[i];

      // const convertedArray = [].concat(...data[0]).map(item => (item === undefined ? '' : item));

      function formatData(data) {
        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < data[i].length; j++) {
            if (typeof data[i][j] === "number") {
              if (data[i][j] % 1 !== 0) {
                data[i][j] = data[i][j].toFixed(2);
              } else {
                data[i][j] = data[i][j].toFixed(2);
              }
            }
          }
        }
        return data;
      }

      const formattedData = formatData(dataVal);

      let checkLength = data[i]?.length;
      let content = {
        startY: 250,
        head: headers,
        body: formattedData,
        theme: "plain",
        tableLineWidth: 1,
        headStyles: { fillColor: "#BDBDBD", textColor: "black" },
        didParseCell: function (data) {
          if (data.row.index === (checkLength ? checkLength : 1) - 1) {
            data.cell.styles.fontStyle = "bold";
            data.cell.styles.fillColor = "#EBECF0";
          }
        },
      };

      doc.autoTable(content);
      if (i < lengthLoopVal - 1) {
        doc.addPage("a4", "l");
      }
    }

    doc.save(
      `${moment(new Date()).format(
        "YYYY-MM-DD-HHmm"
      )}-Timesheet sign-off report.pdf`
    );
  };
  const list = (anchor) => (
    <div
      style={{ overflowX: "hidden", overflowY: "hidden" }}
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      {" "}
      <ArrowBackIosIcon
        onClick={toggleDrawer(anchorFilter1, false)}
        style={{ paddingLeft: "0.75rem", marginTop: "1.188rem" }}
      />
      <span
        style={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: 600,
            lineHeight: "1.125rem",
            marginTop: "-1.563rem",
            marginLeft: "-14rem",
            // display:"flex",
            // justifyContent:"center"
          }}
        >
          Filter
        </Typography>
      </span>
      <Divider />
      <br />
      {/* <Typography
        style={{
          paddingLeft: "11px",
          fontSize: "0.875rem",
          fontWeight: 600,
          fontFamily: "Montserrat",
          lineHeight: "24px",
          color: "#353535",
          marginTop: "-0.563rem",
          marginBottom: "3px",
        }}
      >
        By Expense Information
      </Typography> */}
      <Grid
        container
        spacing={3}
        style={{
          display: "flex",
          //marginBottom: "0.938rem",
          alignItems: "baseline",
          //justifyContent: "space-between",
          flexDirection: "column",
          marginLeft: "1.688rem",
        }}
      >
        <Grid item xs={1} md={1}>
          <FormControl sx={{ width: "18.75rem" }} size="small">
            <InputLabel>Select Time Period</InputLabel>
            <Select
              label="Select Time period"
              name="selectedTimePeriod"
              value={selectedTimePeriod}
              onChange={handleTimePeriodChange}
              SelectDisplayProps={{
                style: { color: "black" },
              }}
              MenuProps={{
                getContentAnchorEl: null,
              }}
            >
              {tempWeeklyTimesheetList?.length > 0 &&
                tempWeeklyTimesheetList.map((item) => (
                  <MenuItem key={item.timePeriod} value={item.timePeriod}>
                    {item.timePeriod}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1} md={1}>
          <Autocomplete
            options={
              Array.isArray(tempWeeklyTimesheetList)
                ? tempWeeklyTimesheetList
                    ?.map((item) => item.projectId.split(", "))
                    .flat()
                    .filter(
                      (projectId, index, self) =>
                        self.indexOf(projectId) === index
                    )
                : []
            }
            sx={{
              ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                top: "auto",
              },
              ".MuiIconButton-root": {
                padding: "0.125rem",
              },
              width: "18.75rem",
            }}
            value={selectedProjectId}
            onChange={handleProjectIdChange}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ height: "2.5rem" }}
                label="Project ID"
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={1} md={1}>
          <Autocomplete
            multiple
            sx={{
              ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                top: "auto",
              },
              ".MuiIconButton-root": {
                padding: "0.125rem",
              },
              width: "18.75rem",
            }}
            options={statusOptions}
            value={selectedStatus}
            onChange={handleStatusChange}
            disableCloseOnSelect
            getOptionLabel={(option) => option} // Make sure option is a string
            getOptionSelected={(option, value) =>
              selectedStatus.indexOf(option) !== -1
            }
            renderOption={(props, option, { selected }) => (
              <li
                style={{ paddingTop: "0rem", paddingBottom: "0rem" }}
                {...props}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: selected ? "#0B1941" : "" }}
                      checked={selected}
                    />
                  }
                  label={<span style={{ fontSize: "0.875rem" }}>{option}</span>}
                />
              </li>
            )}
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ height: "2.5rem" }}
                label="Status"
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>

        <Box>
          <Button
            variant="outlined"
            size="small"
            // style={{ marginLeft: "37px", width: "121px" }}
            style={{
              marginLeft: "2.438rem",
              width: "7.563rem",
              textTransform: "inherit",
              fontSize: "0.875rem",
              color:"#9E9E9E",
              borderColor:"#9E9E9E",
              height:"2.188rem"
            }}
            onClick={handleClearAll}
          >
            Reset
          </Button>
          <Button
            variant="outlined"
            disabled={buttonState}
            style={{
              background: buttonState ? "#9E9E9E" : "#0B1941",
              marginLeft: "1.25rem",
              marginBottom: "3.125rem",
              marginTop: "3.125rem",
              textTransform: "inherit",
              fontSize: "0.875rem",
              height:"2.188rem"

            }}
            color="#fffff"
            size="small"
            onClick={() => handleApplyFilter()}
          >
            <Typography style={{ color: "white", margin: 0 }}>
              {" "}
              Apply Filter
            </Typography>
          </Button>
        </Box>
      </Grid>
    </div>
  );
  return (
    <div>
      {" "}
      {/* {selectedItems && selectedItems.length > 0 && (
        <Box
          style={{
            paddingLeft:
              window.screen.availWidth == 1920
                ? "78.5%"
                : window.screen.availWidth == 1536
                ? "72%"
                : window.screen.availWidth == 1098
                ? "60%"
                : "42.4%",
            paddingTop: "0.625rem",
            paddingBottom: "0.625rem",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            style={{
              whiteSpace: "nowrap",
              textTransform: "unset",
              color: "#0B1941",
              border: "1px solid #0B1941",
            }}
            onClick={executeExportPDF}
          >
            <LaunchIcon
              style={{
                height: "1rem",
                width: "1rem",
                color: "#043365",
                verticalAlign: "text-bottom",
              }}
            />
            &nbsp;Export PDF
          </Button>
        </Box>
      )} */}
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          //margin: "0.938rem",
        }}
      >
        {/* <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "0.938rem",
          }}
        >
          <IconButton onClick={toggleDrawer(anchorFilter1, true)}>
            <span class="material-symbols-outlined">filter_alt</span>
          </IconButton>
          {/* <FilterAltOutlinedIcon onClick={toggleDrawer(anchorFilter1, true)} /> */}
        {/* <Drawer
            anchor={anchorFilter1}
            open={state[anchorFilter1]}
            onClose={toggleDrawer(anchorFilter1, false)}
            PaperProps={{
              style: { height: "100%", width: "100%" }, // This ensures the drawer covers the whole screen
            }}
          >
            {list(anchorFilter1)}
          </Drawer>{" "} */}
        {/* <Box style={{ paddingRight: "8px" }}>
            <Button
              className={
                window.screen.availWidth == 1792
                  ? classes.addExpenseButtonMac
                  : classes.addExpenseButton
              }
              onClick={handleCreateTimesheet}
            >
              <Typography className={classes.buttonText}>
                + Create Timesheet
              </Typography>
            </Button>
          </Box> */}
        {/* </Box> */}
        {/* <Divider /> */}

        <Box>
          <Typography
            style={{
              marginLeft: "0.75rem",
              fontWeight: 500,
              paddignTop: "1rem",
              lineHeight: 2.5,
              fontSize: "0.875rem",
            }}
          >
            Previous Timesheets
          </Typography>
        </Box>
        <Box>
          {" "}
          {selectedItems && selectedItems.length > 0 && (
            <Box
              style={{
                // paddingLeft:
                //   window.screen.availWidth == 1920
                //     ? "78.5%"
                //     : window.screen.availWidth == 1536
                //     ? "72%"
                //     : window.screen.availWidth == 1098
                //     ? "60%"
                //     : "8.5rem",
                marginLeft:"8.5rem",
                paddingTop: "0.625rem",
                paddingBottom: "0.625rem",
                transform: "scale(1.2)",
              }}
              onClick={executeExportPDF}
            >
              {/* <Button
              // variant="outlined"
              // color="primary"
              // style={{
              //   whiteSpace: "nowrap",
              //   textTransform: "unset",
              //   color: "#0B1941",
              //   border: "0.063rem solid #0B1941",
              // }}
              onClick={executeExportPDF}
            > */}
              <LaunchIcon
                style={{
                  height: "1rem",
                  width: "1rem",
                  color: "#043365",
                  verticalAlign: "text-bottom",
                  //onClick={executeExportPDF}
                }}
              />
              {/* &nbsp;Export PDF */}
              {/* </Button> */}
            </Box>
          )}
        </Box>
        <Box>
          
          <StyledBadge badgeContent={countShow} color="secondary">
            <IconButton onClick={toggleDrawer(anchorFilter1, true)}>
              <span class="material-symbols-outlined">filter_alt</span>
            </IconButton>
          </StyledBadge>
          {/* <FilterAltOutlinedIcon onClick={toggleDrawer(anchorFilter1, true)} /> */}
          <Drawer
            anchor={anchorFilter1}
            open={state[anchorFilter1]}
            onClose={toggleDrawer(anchorFilter1, false)}
            PaperProps={{
              style: { height: "100%", width: "100%" }, // This ensures the drawer covers the whole screen
            }}
          >
            {list(anchorFilter1)}
          </Drawer>{" "}
        </Box>
      </Box>
      <Paper>
        <TableContainer>
          <Table>
            <MyTimesheetTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              // onRequestSort={handleRequestSort}
              // rowCount={rows.length}
            />
            <TableBody>
              {weeklyTimesheetList?.length > 0 ? (
                stableSort(weeklyTimesheetList, getComparator(order, orderBy))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    // console.log("row1", row);
                    const isItemSelected = isSelected(row.timePeriod);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <React.Fragment>
                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row)}
                          //   role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.timePeriod}
                          selected={isItemSelected}
                          style={{
                            backgroundColor:
                              row.status == "Rejected" ? "#FFE7E7" : "#ffffff",
                            //padding: "0.625rem",
                          }}
                        >
                          <TableCell
                            style={{ padding: "0rem" , borderBottomWidth:"0px" }}
                            onClick={(event) => handleClick(event, row)}
                          >
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={(event) => handleClick(event, row)}
                            >
                              {isItemSelected ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell
                            style={{
                              fontWeight: 500,
                              fontSize: "0.625rem",
                              width:"100%",
                              borderBottomWidth:"0px"
                            }}
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            onClick={(event) => handleClick(event, row)}
                          >
                            {row.timePeriod}
                          </TableCell>
                          <TableCell
                            padding="none"
                            style={{
                              fontWeight: 600,
                              //paddingLeft: "8px",
                              fontSize: "0.625rem",
                              textAlign: "right",
                              borderBottomWidth:"0px"
                            }}
                            onClick={(event) => handleClick(event, row)}
                          >
                            {row.billable.toFixed(2)}
                          </TableCell>
                          <TableCell
                            padding="none"
                            style={{
                              fontWeight: 600,
                              //paddingLeft: "1.875rem",
                              fontSize: "0.625rem",
                              textAlign: "right",
                              borderBottomWidth:"0px"
                            }}
                            onClick={(event) => handleClick(event, row)}
                          >
                            {row.nonBillable.toFixed(2)}
                          </TableCell>
                          <TableCell
                            padding="none"
                            style={{
                              fontWeight: 600,
                              display: "flex",
                              // padding: "0.313rem 0 rem 0.313rem 0 rem",
                              alignItems: "center",
                              justifyContent: "space-between",
                              fontSize: "0.625rem",
                              textAlign: "right",
                              borderBottomWidth:"0px"
                            }}
                          >
                            <div
                              style={{
                                paddingLeft: "2.25rem",
                                textAlign: "right",
                              }}
                              onClick={(event) => handleClick(event, row)}
                            >
                              {row.pendingDayHours.toFixed(2)}
                            </div>
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                //paddingRight: "1.875rem",
                              }}
                            >
                              {row.editableFlag === 1 && (
                                <Box className={classes.box} id="1">
                                  <Box
                                    style={{
                                      display: "flex",
                                      marginBottom: "-0.375rem",
                                    }}
                                    onClick={() => handleEditClick(row)}
                                  >
                                    <div style={{ transform: "scale(0.7)" }}>
                                      <EditOutlinedIcon />
                                    </div>
                                  </Box>
                                </Box>
                              )}
                              <Box
                                style={{
                                  marginLeft:
                                    row.editableFlag != 1
                                      ? "2.063rem"
                                      : "0 rem",
                                }}
                              >
                                <Checkbox
                                  checked={selectedItems.includes(
                                    row.timesheetId
                                  )}
                                  style={{
                                    color:
                                      row.approvalFlag === 0
                                        ? "#BDBDBD"
                                        : "#0B1941",
                                    pointerEvents:
                                      row.approvalFlag === 0 ? "none" : "auto",
                                    paddingLeft: "0 rem",
                                    paddingRight: "0.125rem",
                                    transform: "scale(0.7)",
                                  }}
                                  onChange={() =>
                                    handleCheckboxChange(row.timesheetId)
                                  }
                                  disabled={row.approvalFlag === 0}
                                />
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ padding: 0 }} colSpan={6}>
                            {/* {console.log("show some", isItemSelected)} */}
                            <Collapse
                              in={isItemSelected}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box>
                                <Table size="small" aria-label="purchases">
                                  <TableHead
                                    style={{
                                      height: "3.188rem",
                                      backgroundColor: "#E3E8F7",
                                    }}
                                  >
                                    <TableRow>
                                      {/* <TableCell
                                        style={{
                                          width: "76px",
                                          padding: "0 rem",
                                        }}
                                      ></TableCell> */}
                                      <TableCell
                                        style={{
                                          padding: "0 rem",
                                          // width: "245px",

                                          //width: "27%",
                                          fontWeight: 600,
                                          paddingLeft: "0.625rem",
                                          fontSize: "0.625rem",
                                        }}
                                      >
                                        Dates
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          padding: "0 rem",
                                          // width: "348px",
                                          //width: "28%",
                                          fontWeight: 600,
                                          fontSize: "0.625rem",
                                        }}
                                      >
                                        Projects
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          //padding: "0 rem",
                                          fontWeight: 600,
                                          //width: "211px",
                                          paddingLeft: "0.938rem",
                                          fontSize: "0.625rem",
                                        }}
                                      >
                                        Hours
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          padding: "0 rem",
                                          fontWeight: 600,
                                          paddingLeft: "1.25rem",
                                          fontSize: "0.625rem",
                                        }}
                                      >
                                        Status
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                  {dailyPreviousTimesheetList ? (
                                      stableSort(
                                        dailyPreviousTimesheetList?.filter(
                                          (item) =>
                                            // item.timesheetId ===
                                            //   row.timesheetId ||
                                            (moment(
                                              item?.date,
                                              "MMM DD, YY"
                                            ).format("YYYY-MM-DD") >=
                                              row?.fromDate?.slice(0, -9) &&
                                              moment(
                                                item?.date,
                                                "MMM DD, YY"
                                              ).format("YYYY-MM-DD") <=
                                                row?.toDate?.slice(0, -9))
                                        ),
                                        getComparator(order, orderBy)
                                      )?.map((dailyRow, index) => (
                                        <TableRow
                                          key={dailyRow.date}
                                          //className={classes.tableRow}
                                          style={{ height: "4.01rem" }}
                                        >
                                          {/* <TableCell padding="none"></TableCell> */}
                                          <TableCell
                                            padding="none"
                                            style={{
                                              width: "27%",
                                              paddingLeft: "0.625rem",
                                            }}
                                          >
                                            <Grid
                                              container
                                              direction="column"
                                              //spacing={1}
                                              style={{ width: "3.75rem" }}
                                            >
                                              <Grid
                                                item
                                                style={{
                                                  paddingBottom: "0 rem",
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <Typography
                                                  variant="body1"
                                                  style={{
                                                    fontSize: "0.625rem",
                                                  }}
                                                >
                                                  {dailyRow.date}
                                                </Typography>{" "}
                                                {/* <Typography
                                                  variant="body1"
                                                  style={{ fontSize: "0.75rem" }}
                                                >
                                                  { dailyRow.type }
                                                </Typography> */}
                                              </Grid>
                                              <Grid
                                                item
                                                style={{ paddingTop: "0 rem" }}
                                              >
                                                <Typography
                                                  variant="body1"
                                                  style={{
                                                    fontSize: "0.563rem",
                                                  }}
                                                >
                                                  {dailyRow.type}
                                                </Typography>
                                              </Grid>
                                            </Grid>

                                            {/* {dailyRow.date} */}
                                          </TableCell>
                                          <TableCell
                                            padding="none"
                                            style={{
                                              textAlign: "left",
                                              width: "28%",
                                            }}
                                          >
                                            <Grid
                                              container
                                              direction="column"
                                              spacing={1}
                                              style={{ width: "6.875rem" }}
                                            >
                                              <Grid
                                                item
                                                style={{
                                                  paddingBottom: "0 rem",
                                                }}
                                              >
                                                <Typography
                                                  variant="body1"
                                                  style={{
                                                    fontSize: "0.625rem",
                                                  }}
                                                >
                                                  {dailyRow.projectName}
                                                </Typography>
                                              </Grid>
                                              <Grid
                                                item
                                                style={{ paddingTop: "0 rem" }}
                                              >
                                                <Typography
                                                  variant="body1"
                                                  style={{
                                                    fontSize: "0.563rem",
                                                  }}
                                                >
                                                  {dailyRow.projectID}
                                                </Typography>
                                              </Grid>
                                              
                                            </Grid>
                                          </TableCell>
                                          <TableCell padding="none">
                                          {dailyRow?.category != "" && (
                                              <>
                                                <div
                                                  style={{
                                                    fontWeight: 600,
                                                    paddingLeft: "1.438rem",
                                                    fontSize: "0.625rem",
                                                  }}
                                                >
                                                  {dailyRow?.dayHours?.toFixed(
                                                    2
                                                  )}
                                                </div>
                                                <div
                                                  style={{
                                                    backgroundColor: "#E3E8F7",
                                                    width: "4rem",
                                                    //padding: "0.125rem",
                                                    height: "1rem",
                                                    fontSize: "0.563rem",
                                                    //paddingLeft: "22px",
                                                    //paddingLeft: "1.125rem",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {dailyRow?.category}
                                                </div>
                                              </>
                                            )}
                                          </TableCell>
                                          <TableCell
                                            padding="none"
                                            // style={{ paddingLeft: "2%" }}
                                          >
                                            <Box
                                              style={{
                                                display: "flex",
                                                marginTop: "0.25rem",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  //marginRight: "27px",
                                                }}
                                              >
                                                <Chip
                                                  label={dailyRow.status}
                                                  className={
                                                    classes.statusStyle
                                                  }
                                                  style={{
                                                    background:
                                                      dailyRow.status ===
                                                      "In Review"
                                                        ? "#E0B15C"
                                                        : dailyRow.status ===
                                                          "Approved"
                                                        ? " #349259"
                                                        : dailyRow.status ===
                                                          "Auto Approved"
                                                        ? " #349259"
                                                        : dailyRow.status ===
                                                          "Rejected"
                                                        ? "#D40606"
                                                        : dailyRow.status ===
                                                          "Draft"
                                                        ? "#808080"
                                                        : "",
                                                    display:
                                                      dailyRow.status ===
                                                        "In Review" ||
                                                      dailyRow.status ===
                                                        "Approved" ||
                                                      dailyRow.status ===
                                                        "Auto Approved" ||
                                                      dailyRow.status ===
                                                        "Rejected" ||
                                                      dailyRow.status ===
                                                        "Draft"
                                                        ? "inline-flex"
                                                        : "none",
                                                    color: "#ffffff",
                                                    height: "1.125rem",
                                                    width: "6.25rem",
                                                    fontSize: "0.625rem",
                                                  }}
                                                />
                                                {/* {(dailyRow.status ==
                                                  "In Review" ||
                                                  dailyRow.status ==
                                                    "Rejected" ||
                                                  dailyRow.status ==
                                                    "Approved") && (
                                                  <>
                                                    <div
                                                    //   style={{
                                                    //     marginTop: "-4px",
                                                    //     //fontSize: "0.625rem !important",
                                                    //   }}
                                                    >
                                                      {/* <InfoOutlinedIcon /> */}

                                                {/* <InfoOutlinedIcon
                                                        ref={target}
                                                        onClick={(event) => {
                                                          handleTooltipOpen(
                                                            dailyRow.comments,
                                                            index
                                                          );
                                                          handlToolAction(
                                                            event
                                                          );
                                                        }}
                                                        className={
                                                          classes.tooltip
                                                        }
                                                      /> */}

                                                {/* {open &&
                                                        index ===
                                                          currentIndex &&
                                                        handleParentToolTip()}
                                                    </div> */}
                                                {/* </>
                                                )}  */}
                                                {dailyRow.status ===
                                                  "In Review" ||
                                                dailyRow.status ===
                                                  "Rejected" ||
                                                dailyRow.status ===
                                                  "Approved" ? (
                                                  <div>
                                                    <InfoOutlinedIcon
                                                      ref={target}
                                                      onClick={(event) => {
                                                        handleTooltipOpen(
                                                          dailyRow.comments,
                                                          index
                                                        );
                                                        handlToolAction(event);
                                                      }}
                                                      className={
                                                        classes.tooltip
                                                      }
                                                    />

                                                    {open &&
                                                      index === currentIndex &&
                                                      handleParentToolTip()}
                                                  </div>
                                                ) : (
                                                  <div
                                                    style={{ width: "1rem" }}
                                                  />
                                                )}
                                              </div>
                                            </Box>
                                          </TableCell>
                                        </TableRow>
                                      ))
                                    ) : (
                                      <></>
                                    )}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })
              ) : (
                <></>
              )}
            </TableBody>
            <MobileFloatingButton />
          </Table>
        </TableContainer>
      </Paper>
      <Box>
        {weeklyTimesheetList?.length === 0 ? (
          <Grid>
            <Typography
              style={{
                fontSize: "1rem",
                lineHeight: "6.25rem",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              No Timesheet entry exists!
            </Typography>
          </Grid>
        ) : (
          <></>
        )}
         <TablePagination
          rowsPerPageOptions={[15,30,45]}
          component="div"
          count={weeklyTimesheetList?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          className={classes.paginationStyle}

          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Box>
      <div>
      <Backdrop
        sx={{ color: '#092FED', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
    </div>
  );
}

export default ResponsiveMyTimesheetTable;