import { ConsoleView } from "react-device-detect";
import * as types from "./actionTypeTs.js";
import { myTeamApprovalFilterDropDown } from "./actionTs.js";

const initialState = {
  isTimesheetPurposeGet: false,
  getTimesheetPurposeList: null,
  isTimesheetCategoryGet: false,
  getTimesheetCategoryList: null,
  isPostCreateTimesheet: false,
  postCreateTimesheetDate: null,
  isMyTimesheetListGet: false,
  getMyTimesheetList: null,
  isGetAllProjects: false,
  getAllProjectsList: null,
  isGetMissedWeeks: false,
  getMissedWeeks: null,
  RefDateRedux: null,
  isTempGetWeeklyPreviousTimesheet: false,
  getTempWeeklyPreviousTimesheetList: null,
  isGetWeeklyPreviousTimesheet: false,
  getWeeklyPreviousTimesheetList: null,
  isGetDailyPreviousTimesheet: false,
  getDailyPreviousTimesheetList: null,
  isGetMyTeamApproval: false,
  getMyTeamsApproval: null,
  isUpdateStatusByManager: false,
  updateStatusByManagers: null,
  isGetCalendarDate: false,
  getCalendarDateLists: null,
  isGetDetailedView: false,
  getDetailedViewLists: null,
  isGetTeamTimesheet: false,
  getTeamTimesheets: null,
  isGetTimesheetAccessFlag: false,
  getTimesheetFlag: null,
  maintainStateVariable: 0,
  postExtractPDFData: null,
  ispostExtractPDFData: false,
  getTimesheetAccessReportList: null,
  isTimesheetAccessReportGet: false,
  getPendencyReportData: null,
  isPendencyReportData: false,
  getTotalCountApprovalData: null,
  isGetTotalCountApprovalData: false,
  getMyTeamsApprovalPagewise: null,
  isGetMyTeamApprovalPagewise: false,
  isPostRatingReview: false,
  postRatingReview: null,
  isRatingDescription: false,
  getRatingDescriptionData: null,
  getTimesheetReport: null,
  isTimesheetReport: false,
  getAllTimesheetReport: null,
  isAllTimesheetReport: false,
  getAllTimesheetReportView: null,
  isAllTimesheetReportView: false,
  getAllTimesheetReport1: null,
  isAllTimesheetReport1: false,
  isTimesheetSearchEmployee: false,
  getTimesheetSearchEmployeeData: null,
  isTimesheetSearchDelegate: false,
  getTimesheetSearchDelegateData: null,
  isTimesheetViewDelegateData: false,
  getTimesheetViewDelegateData: null,
  isTimesheetSearchEmpAndDel: false,
  getTimesheetSearchEmpAndDelData: null,
  isMyTimesheetDelegateeData: false,
  getMyTimesheetDelegateeData: null,
  isGetTimesheetDelegationFlag: false,
  getTimesheetDelegationFlag: null,
  isPostTimesheetAddDelegate: false,
  postTimesheetAddDelegate: null,
  isPostTimesheetEditDelegate: false,
  postTimesheetEditDelegate: null,
  tsDelEmpId: null,
  isAllTimesheetAudit: false,
  getAllTimesheetAudit: null,
  isSetChips: false,
  getSetChips: null,
  isGetApprovalInvoice: false,
  getApprovalListInvoice: null,
  isPostImportInvoicingDetails: false,
  postImportInvoicingDetails: null,
  isGetApprovalInvoicingReport: false,
  getApprovalInvoicingReport: null,
  isGetInvoicingAuditDetails: false,
  getInvoicingAuditDetails: null,
  isGetInvoicingExportReport: false,
  getInvoicingExportReport: null,
  isPostInvoicingStatusUpdate: false,
  postInvoicingStatusUpdate: null,
  isSetPMOChips: false,
  getSetPMOChips: null,
  isMyTeamApprovalFilterDropList: false,
  getMyTeamApprovalFilterDropDownList: null,
  isGetApprovalInvoicingExportReport: false,
  getApprovalInvoicingExportReport: null,
};

function TimeSheetReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_PENDENCY_REPORT_DATA:
      return {
        ...state,
        getPendencyReportData: null,
        isPendencyReportData: false,
      };
    case types.GET_PENDENCY_REPORT_DATA_SUCCESS:
      return {
        ...state,
        isPendencyReportData: true,
        getPendencyReportData: action.payload,
      };
    case types.GET_PENDENCY_REPORT_DATA_FAILED:
      return {
        ...state,
        isPendencyReportData: false,
        getPendencyReportData: action.payload,
      };
    case types.GET_TIMESHEET_REPORT_ACCESS_FLAG:
      return {
        ...state,
        isTimesheetAccessReportGet: false,
        getTimesheetAccessReportList: null,
      };
    case types.GET_TIMESHEET_REPORT_ACCESS_FLAG_SUCCESS:
      return {
        ...state,
        isTimesheetAccessReportGet: true,
        getTimesheetAccessReportList: action.payload,
      };
    case types.GET_TIMESHEET_REPORT_ACCESS_FLAG_FAILED:
      return {
        ...state,
        isTimesheetAccessReportGet: false,
        getTimesheetAccessReportList: action.payload,
      };
    case types.GET_TIMESHEET_PURPOSE_LIST:
      return {
        ...state,
        isTimesheetPurposeGet: false,
        getTimesheetPurposeList: null,
      };
    case types.GET_TIMESHEET_PURPOSE_LIST_SUCCESS:
      return {
        ...state,
        isTimesheetPurposeGet: true,
        getTimesheetPurposeList: action.payload,
      };
    case types.GET_TIMESHEET_PURPOSE_LIST_FAILED:
      return {
        ...state,
        isTimesheetPurposeGet: false,
        getTimesheetPurposeList: action.payload,
      };
    case types.GET_TIMESHEET_CATEGORIES_LIST:
      return {
        ...state,
        isTimesheetCategoryGet: false,
        getTimesheetCategoryList: null,
      };
    case types.GET_TIMESHEET_CATEGORIES_LIST_SUCCESS:
      return {
        ...state,
        isTimesheetCategoryGet: true,
        getTimesheetCategoryList: action.payload,
      };
    case types.GET_TIMESHEET_CATEGORIES_LIST_FAILED:
      return {
        ...state,
        isTimesheetCategoryGet: false,
        getTimesheetCategoryList: action.payload,
      };
    case types.POST_CREATE_TIMESHEET:
      return {
        ...state,
        isPostCreateTimesheet: false,
        postCreateTimesheetDate: null,
      };
    case types.POST_CREATE_TIMESHEET_SUCCESS:
      return {
        ...state,
        isPostCreateTimesheet: true,
        postCreateTimesheetDate: action.payload,
      };
    case types.POST_CREATE_TIMESHEET_FAILED:
      return {
        ...state,
        isPostCreateTimesheet: false,
        postCreateTimesheetDate: action.payload,
      };
    case types.GET_MY_TIMESHEET_LIST:
      return {
        ...state,
        isMyTimesheetListGet: false,
        getMyTimesheetList: null,
      };
    case types.GET_MY_TIMESHEET_LIST_SUCCESS:
      return {
        ...state,
        isMyTimesheetListGet: true,
        getMyTimesheetList: action.payload,
      };
    case types.GET_MY_TIMESHEET_LIST_FAILED:
      return {
        ...state,
        isMyTimesheetListGet: false,
        getMyTimesheetList: action.payload,
      };
    case types.GET_ALL_PROJECTS:
      return {
        ...state,
        isGetAllProjects: false,
        getAllProjectsList: null,
      };
    case types.GET_ALL_PROJECTS_SUCCESS:
      return {
        ...state,
        isGetAllProjects: true,
        getAllProjectsList: action.payload,
      };
    case types.GET_ALL_PROJECTS_FAILED:
      return {
        ...state,
        isGetAllProjects: false,
        getAllProjectsList: action.payload,
      };
    case types.GET_WEEKLY_PREVIOUS_TIMESHEET_LIST:
      return {
        ...state,
        isGetWeeklyPreviousTimesheet: false,
        getWeeklyPreviousTimesheetList: null,
      };
    case types.GET_WEEKLY_PREVIOUS_TIMESHEET_LIST_SUCCESS:
      return {
        ...state,
        isGetWeeklyPreviousTimesheet: true,
        getWeeklyPreviousTimesheetList: action.payload,
      };
    case types.GET_WEEKLY_PREVIOUS_TIMESHEET_LIST_FAILED:
      return {
        ...state,
        isGetWeeklyPreviousTimesheet: false,
        getWeeklyPreviousTimesheetList: action.payload,
      };

    case types.GET_TEMP_WEEKLY_PREVIOUS_TIMESHEET_LIST:
      return {
        ...state,
        isTempGetWeeklyPreviousTimesheet: false,
        getTempWeeklyPreviousTimesheetList: null,
      };
    case types.GET_TEMP_WEEKLY_PREVIOUS_TIMESHEET_LIST_SUCCESS:
      return {
        ...state,
        isTempGetWeeklyPreviousTimesheet: true,
        getTempWeeklyPreviousTimesheetList: action.payload,
      };
    case types.GET_TEMP_WEEKLY_PREVIOUS_TIMESHEET_LIST_FAILED:
      return {
        ...state,
        isTempGetWeeklyPreviousTimesheet: false,
        getTempWeeklyPreviousTimesheetList: action.payload,
      };

    case types.GET_DAILY_PREVIOUS_TIMESHEET_LIST:
      return {
        ...state,
        isGetDailyPreviousTimesheet: false,
        getDailyPreviousTimesheetList: {
          ...state.getDailyPreviousTimesheetList,
        },
      };
    case types.GET_DAILY_PREVIOUS_TIMESHEET_LIST_SUCCESS:
      // console.log("GET_DAILY_PREVIOUS_TIMESHEET_LIST_success", action.tmId);
      // console.log();
      return {
        ...state,
        isGetDailyPreviousTimesheet: true,
        getDailyPreviousTimesheetList: {
          ...state.getDailyPreviousTimesheetList,
          [action.tmId]: action.payload,
        },
      };
    case types.GET_DAILY_PREVIOUS_TIMESHEET_LIST_FAILED:
      // console.log("GET_DAILY_PREVIOUS_TIMESHEET_LIST_FAILED");
      return {
        ...state,
        isGetDailyPreviousTimesheet: false,
        getDailyPreviousTimesheetList: action.payload,
      };
    case types.GET_MISSED_WEEK:
      return {
        ...state,
        isGetMissedWeeks: false,
        getMissedWeeks: null,
      };
    case types.GET_MISSED_WEEK_SUCCESS:
      return {
        ...state,
        isGetMissedWeeks: true,
        getMissedWeeks: action.payload,
      };
    case types.GET_MISSED_WEEK_FAILED:
      return {
        ...state,
        isGetMissedWeeks: false,
        getMissedWeeks: action.payload,
      };
    case types.GET_TOTAL_COUNT_APPROVAL_DATA:
      // console.log("GET_MY_TEAM_APPROVAL");
      return {
        ...state,
        isGetTotalCountApprovalData: false,
        getTotalCountApprovalData: null,
      };
    case types.GET_TOTAL_COUNT_APPROVAL_DATA_SUCCESS:
      //console.log("GET_MY_TEAM_APPROVAL_SUCCESS");
      return {
        ...state,
        isGetTotalCountApprovalData: true,
        getTotalCountApprovalData: action.payload,
      };
    case types.GET_TOTAL_COUNT_APPROVAL_DATA_FAILED:
      // console.log("GET_MY_TEAM_APPROVAL_FAILED");
      return {
        ...state,
        isGetTotalCountApprovalData: false,
        getTotalCountApprovalData: action.payload,
      };

    case types.GET_MY_TEAM_APPROVAL_PAGEWISE:
      // console.log("GET_MY_TEAM_APPROVAL");
      return {
        ...state,
        isGetMyTeamApprovalPagewise: false,
        getMyTeamsApprovalPagewise: null,
      };
    case types.GET_MY_TEAM_APPROVAL_PAGEWISE_SUCCESS:
      //console.log("GET_MY_TEAM_APPROVAL_SUCCESS");
      return {
        ...state,
        isGetMyTeamApprovalPagewise: true,
        getMyTeamsApprovalPagewise: action.payload,
      };
    case types.GET_MY_TEAM_APPROVAL_PAGEWISE_FAILED:
      // console.log("GET_MY_TEAM_APPROVAL_FAILED");
      return {
        ...state,
        isGetMyTeamApprovalPagewise: false,
        getMyTeamsApprovalPagewise: action.payload,
      };

    case types.GET_AUTOAPPROVAL_ALLOCATED_PROJ_LIST:
      // console.log("GET_MY_TEAM_APPROVAL");
      return {
        ...state,
        isGetAutoapprovalAllocatedProjList: false,
        GetAutoapprovalAllocatedProjList: null,
      };
    case types.GET_AUTOAPPROVAL_ALLOCATED_PROJ_LIST_SUCCESS:
      return {
        ...state,
        isGetAutoapprovalAllocatedProjList: true,
        GetAutoapprovalAllocatedProjList: action.payload,
      };
    case types.GET_AUTOAPPROVAL_ALLOCATED_PROJ_LIST_FAILED:
      // console.log("GET_MY_TEAM_APPROVAL_FAILED");
      return {
        ...state,
        isGetAutoapprovalAllocatedProjList: false,
        GetAutoapprovalAllocatedProjList: action.payload,
      };

    case types.GET_MY_TEAM_APPROVAL:
      // console.log("GET_MY_TEAM_APPROVAL");
      return {
        ...state,
        isGetMyTeamApproval: false,
        getMyTeamsApproval: null,
      };
    case types.GET_MY_TEAM_APPROVAL_SUCCESS:
      //console.log("GET_MY_TEAM_APPROVAL_SUCCESS");
      return {
        ...state,
        isGetMyTeamApproval: true,
        getMyTeamsApproval: action.payload,
      };
    case types.GET_MY_TEAM_APPROVAL_FAILED:
      // console.log("GET_MY_TEAM_APPROVAL_FAILED");
      return {
        ...state,
        isGetMyTeamApproval: false,
        getMyTeamsApproval: action.payload,
      };
    case types.UPDATE_STATUS_BY_MANAGER:
      // console.log("UPDATE_STATUS_BY_MANAGER");

      return {
        ...state,
        isUpdateStatusByManager: false,
        updateStatusByManagers: null,
      };
    case types.UPDATE_STATUS_BY_MANAGER_SUCCESS:
      // console.log("UPDATE_STATUS_BY_MANAGER_SUCCESS");

      return {
        ...state,
        isUpdateStatusByManager: true,
        updateStatusByManagers: action.payload,
      };
    case types.UPDATE_STATUS_BY_MANAGER_FAILED:
      // console.log("UPDATE_STATUS_BY_MANAGER_FAILED");

      return {
        ...state,
        isUpdateStatusByManager: false,
        updateStatusByManagers: action.payload,
      };
    case types.UPDATED_REF_DATE:
      // console.log("updatedRefDate2: ", action.payload);
      return {
        ...state,
        RefDateRedux: action.payload,
      };
    case types.GET_CALENDAR_DATE_LIST:
      return {
        ...state,
        isGetCalendarDate: false,
        getCalendarDateLists: null,
      };
    case types.GET_CALENDAR_DATE_LIST_SUCCESS:
      return {
        ...state,
        isGetCalendarDate: true,
        getCalendarDateLists: action.payload,
      };
    case types.GET_CALENDAR_DATE_LIST_FAILED:
      return {
        ...state,
        isGetCalendarDate: false,
        getCalendarDateLists: action.payload,
      };
    case types.GET_VIEW_LIST:
      return {
        ...state,
        isGetDetailedView: false,
        getDetailedViewLists: null,
      };
    case types.GET_VIEW_LIST_SUCCESS:
      return {
        ...state,
        isGetDetailedView: true,
        getDetailedViewLists: action.payload,
      };
    case types.GET_VIEW_LIST_FAILED:
      return {
        ...state,
        isGetDetailedView: false,
        getDetailedViewLists: action.payload,
      };
    case types.GET_TEAM_TIMESHEET:
      // console.log("GET_TEAM_TIMESHEET");
      return {
        ...state,
        isGetTeamTimesheet: false,
        getTeamTimesheets: null,
      };
    case types.GET_TEAM_TIMESHEET_SUCCESS:
      // console.log("GET_TEAM_TIMESHEET_SUCCESS");
      return {
        ...state,
        isGetTeamTimesheet: true,
        getTeamTimesheets: action.payload,
      };
    case types.GET_TEAM_TIMESHEET_FAIED:
      // console.log("GET_TEAM_TIMESHEET_FAIED");
      return {
        ...state,
        isGetTeamTimesheet: false,
        getTeamTimesheets: action.payload,
      };
    case types.GET_TIMESHEET_ACCESS_FLAG:
      // console.log("GET_TIMESHEET_ACCESS_FLAG");

      return {
        ...state,
        isGetTimesheetAccessFlag: false,
        getTimesheetFlag: null,
      };
    case types.GET_TIMESHEET_ACCESS_FLAG_SUCCESS:
      // console.log("GET_TIMESHEET_ACCESS_FLAG_SUCCESS");
      return {
        ...state,
        isGetTimesheetAccessFlag: true,
        getTimesheetFlag: action.payload,
      };
    case types.GET_TIMESHEET_ACCESS_FLAG_FAILED:
      // console.log("GET_TIMESHEET_ACCESS_FLAG_FAILED");
      return {
        ...state,
        isGetTimesheetAccessFlag: false,
        getTimesheetFlag: action.payload,
      };
    case types.MAINTAIN_STATE_VARIABLE:
      return {
        ...state,
        maintainStateVariable: action.payload,
      };
    case types.POST_EXPORT_AS_PDF:
      return {
        ...state,
        ispostExtractPDFData: false,
        postExtractPDFData: null,
      };
    case types.POST_EXPORT_AS_PDF_SUCCESS:
      return {
        ...state,
        ispostExtractPDFData: true,
        postExtractPDFData: action.payload,
      };
    case types.POST_EXPORT_AS_PDF_FAILED:
      return {
        ...state,
        ispostExtractPDFData: false,
        postExtractPDFData: action.payload,
      };
    case types.POST_RATING_AND_REVIEW:
      return {
        ...state,
        isPostRatingReview: false,
        postRatingReview: null,
      };
    case types.POST_RATING_AND_REVIEW_SUCCESS:
      return {
        ...state,
        isPostRatingReview: true,
        postRatingReview: action.payload,
      };
    case types.POST_RATING_AND_REVIEW_FAILED:
      return {
        ...state,
        isPostRatingReview: false,
        postRatingReview: action.payload,
      };
    case types.GET_RATING_DESCRIPTION:
      return {
        ...state,
        isRatingDescription: false,
        getRatingDescriptionData: null,
      };
    case types.GET_RATING_DESCRIPTION_SUCCESS:
      return {
        ...state,
        isRatingDescription: true,
        getRatingDescriptionData: action.payload,
      };
    case types.GET_RATING_DESCRIPTION_FAILED:
      return {
        ...state,
        isRatingDescription: false,
        getRatingDescriptionData: action.payload,
      };
    case types.GET_TIMESHEET_REPORT_DATA:
      // console.log("GET_TEAM_TIMESHEET");
      return {
        ...state,
        isTimesheetReport: false,
        getTimesheetReport: null,
      };
    case types.GET_TIMESHEET_REPORT_DATA_SUCCESS:
      // console.log("GET_TEAM_TIMESHEET_SUCCESS");
      return {
        ...state,
        isTimesheetReport: true,
        getTimesheetReport: action.payload,
      };
    case types.GET_TIMESHEET_REPORT_DATA_FAILED:
      // console.log("GET_TEAM_TIMESHEET_FAIED");
      return {
        ...state,
        isTimesheetReport: false,
        getTimesheetReport: action.payload,
      };
    case types.GET_All_TIMESHEET_REPORT_DATA:
      // console.log("GET_TEAM_TIMESHEET");
      return {
        ...state,
        isAllTimesheetReport: false,
        getAllTimesheetReport: null,
      };
    case types.GET_All_TIMESHEET_REPORT_DATA_SUCCESS:
      // console.log("GET_TEAM_TIMESHEET_SUCCESS");
      return {
        ...state,
        isAllTimesheetReport: true,
        getAllTimesheetReport: action.payload,
      };
    case types.GET_All_TIMESHEET_REPORT_DATA_FAILED:
      // console.log("GET_TEAM_TIMESHEET_FAIED");
      return {
        ...state,
        isAllTimesheetReport: false,
        getAllTimesheetReport: action.payload,
      };

    case types.GET_All_TIMESHEET_REPORT_DATA_NEW:
      // console.log("GET_TEAM_TIMESHEET");
      return {
        ...state,
        isAllTimesheetReport1: false,
        getAllTimesheetReport1: null,
      };
    case types.GET_All_TIMESHEET_REPORT_DATA_SUCCESS_NEW:
      // console.log("GET_TEAM_TIMESHEET_SUCCESS");
      return {
        ...state,
        isAllTimesheetReport1: true,
        getAllTimesheetReport1: action.payload,
      };
    case types.GET_All_TIMESHEET_REPORT_DATA_FAILED_NEW:
      return {
        ...state,
        isAllTimesheetReport1: false,
        getAllTimesheetReport1: action.payload,
      };
    case types.GET_ALL_TIMESHEET_REPORT_VIEW:
      // console.log("GET_TEAM_TIMESHEET");
      return {
        ...state,
        isAllTimesheetReportView: false,
        getAllTimesheetReportView: null,
      };
    case types.GET_ALL_TIMESHEET_REPORT_VIEW_SUCCESS:
      // console.log("GET_TEAM_TIMESHEET_SUCCESS");
      return {
        ...state,
        isAllTimesheetReportView: true,
        getAllTimesheetReportView: action.payload,
      };
    case types.GET_ALL_TIMESHEET_REPORT_VIEW_FAILED:
      // console.log("GET_TEAM_TIMESHEET_FAIED");
      return {
        ...state,
        isAllTimesheetReportView: false,
        getAllTimesheetReportView: action.payload,
      };
    case types.CLEAR_ALL_TIMESHEET_REPORT:
      // console.log("GET_TEAM_TIMESHEET");
      return {
        ...state,
        isAllTimesheetReportView: false,
        getAllTimesheetReportView: null,
      };
    case types.GET_TIMESHEET_SEARCH_EMPLOYEE:
      return {
        ...state,
        isTimesheetSearchEmployee: false,
        getTimesheetSearchEmployeeData: null,
      };
    case types.GET_TIMESHEET_SEARCH_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isTimesheetSearchEmployee: true,
        getTimesheetSearchEmployeeData: action.payload,
      };
    case types.GET_TIMESHEET_SEARCH_EMPLOYEE_FAILED:
      return {
        ...state,
        isTimesheetSearchEmployee: false,
        getTimesheetSearchEmployeeData: action.payload,
      };
    case types.GET_TIMESHEET_SEARCH_DELEGATE:
      return {
        ...state,
        isTimesheetSearchDelegate: false,
        getTimesheetSearchDelegateData: null,
      };
    case types.GET_TIMESHEET_SEARCH_DELEGATE_SUCCESS:
      return {
        ...state,
        isTimesheetSearchDelegate: true,
        getTimesheetSearchDelegateData: action.payload,
      };
    case types.GET_TIMESHEET_SEARCH_DELEGATE_FAILED:
      return {
        ...state,
        isTimesheetSearchDelegate: false,
        getTimesheetSearchDelegateData: action.payload,
      };
    case types.GET_TIMESHEET_VIEW_DELEGATE_DETAILS:
      return {
        ...state,
        isTimesheetViewDelegateData: false,
        getTimesheetViewDelegateData: null,
      };
    case types.GET_TIMESHEET_VIEW_DELEGATE_DETAILS_SUCCESS:
      return {
        ...state,
        isTimesheetViewDelegateData: true,
        getTimesheetViewDelegateData: action.payload,
      };
    case types.GET_TIMESHEET_VIEW_DELEGATE_DETAILS_FAILED:
      return {
        ...state,
        isTimesheetViewDelegateData: false,
        getTimesheetViewDelegateData: action.payload,
      };
    case types.GET_TIMESHEET_SEARCH_EMP_AND_DEL:
      return {
        ...state,
        isTimesheetSearchEmpAndDel: false,
        getTimesheetSearchEmpAndDelData: null,
      };
    case types.GET_TIMESHEET_SEARCH_EMP_AND_DEL_SUCCESS:
      return {
        ...state,
        isTimesheetSearchEmpAndDel: true,
        getTimesheetSearchEmpAndDelData: action.payload,
      };
    case types.GET_TIMESHEET_SEARCH_EMP_AND_DEL_FAILED:
      return {
        ...state,
        isTimesheetSearchEmpAndDel: false,
        getTimesheetSearchEmpAndDelData: action.payload,
      };
    case types.GET_TIMESHEET_EMP_DETAILS_AS_PER_DELEGATEE:
      return {
        ...state,
        isMyTimesheetDelegateeData: false,
        getMyTimesheetDelegateeData: null,
      };
    case types.GET_TIMESHEET_EMP_DETAILS_AS_PER_DELEGATEE_SUCCESS:
      return {
        ...state,
        isMyTimesheetDelegateeData: true,
        getMyTimesheetDelegateeData: action.payload,
      };
    case types.GET_TIMESHEET_EMP_DETAILS_AS_PER_DELEGATEE_FAILED:
      return {
        ...state,
        isMyTimesheetDelegateeData: false,
        getMyTimesheetDelegateeData: action.payload,
      };
    case types.GET_TIMESHEET_DELEGATION_ACCESS:
      return {
        ...state,
        isGetTimesheetDelegationFlag: false,
        getTimesheetDelegationFlag: null,
      };
    case types.GET_TIMESHEET_DELEGATION_ACCESS_SUCCESS:
      return {
        ...state,
        isGetTimesheetDelegationFlag: true,
        getTimesheetDelegationFlag: action.payload,
      };
    case types.GET_TIMESHEET_DELEGATION_ACCESS_FAILED:
      return {
        ...state,
        isGetTimesheetDelegationFlag: false,
        getTimesheetDelegationFlag: action.payload,
      };
    case types.POST_TIMESHEET_ADD_DELEGATE:
      return {
        ...state,
        isPostTimesheetAddDelegate: false,
        postTimesheetAddDelegate: null,
      };
    case types.POST_TIMESHEET_ADD_DELEGATE_SUCCESS:
      return {
        ...state,
        isPostTimesheetAddDelegate: true,
        postTimesheetAddDelegate: action.payload,
      };
    case types.POST_TIMESHEET_ADD_DELEGATE_FAILED:
      return {
        ...state,
        isPostTimesheetAddDelegate: false,
        postTimesheetAddDelegate: action.payload,
      };
    case types.POST_TIMESHEET_EDIT_DELEGATE:
      return {
        ...state,
        isPostTimesheetEditDelegate: false,
        postTimesheetEditDelegate: null,
      };
    case types.POST_TIMESHEET_EDIT_DELEGATE_SUCCESS:
      return {
        ...state,
        isPostTimesheetEditDelegate: true,
        postTimesheetEditDelegate: action.payload,
      };
    case types.POST_TIMESHEET_EDIT_DELEGATE_FAILED:
      return {
        ...state,
        isPostTimesheetEditDelegate: false,
        postTimesheetEditDelegate: action.payload,
      };
    case types.RESET_TIMESHEET_DELIGATION:
      return {
        ...state,
        isPostTimesheetAddDelegate: false,
        isPostTimesheetEditDelegate: false,
      };
    case types.UPDATE_TIMESHEET_DELEGATEE_EMP_ID:
      return {
        ...state,
        tsDelEmpId: action.payload,
      };
    case types.GET_ALL_TIMESHEET_AUDIT:
      return {
        ...state,
        isAllTimesheetAudit: false,
        getAllTimesheetAudit: null,
      };
    case types.GET_ALL_TIMESHEET_AUDIT_SUCCESS:
      return {
        ...state,
        isAllTimesheetAudit: true,
        getAllTimesheetAudit: action.payload,
      };
    case types.GET_ALL_TIMESHEET_AUDIT_FAILED:
      return {
        ...state,
        isAllTimesheetAudit: false,
        getAllTimesheetAudit: action.payload,
      };
    case types.SET_CHIPS:
      // console.log("chips in reducer", action.payload);
      return {
        ...state,
        getSetChips: action.payload,
        getAllTimesheetReportView: null,
      };
    case types.GET_APPROVAL_LIST_INVOICE:
      return {
        ...state,
        isGetApprovalInvoice: false,
        getApprovalListInvoice: null,
      };
    case types.GET_APPROVAL_LIST_INVOICE_SUCCESS:
      return {
        ...state,
        isGetApprovalInvoice: true,
        getApprovalListInvoice: action.payload,
      };
    case types.GET_APPROVAL_LIST_INVOICE_FAILED:
      return {
        ...state,
        isGetApprovalInvoice: false,
        getApprovalListInvoice: action.payload,
      };
    case types.POST_IMPORT_INVOICING_DETAILS:
      return {
        ...state,
        isPostImportInvoicingDetails: false,
        postImportInvoicingDetails: null,
      };
    case types.POST_IMPORT_INVOICING_DETAILS_SUCCESS:
      return {
        ...state,
        isPostImportInvoicingDetails: true,
        postImportInvoicingDetails: action.payload,
      };
    case types.POST_IMPORT_INVOICING_DETAILS_FAILED:
      return {
        ...state,
        isPostImportInvoicingDetails: false,
        postImportInvoicingDetails: action.payload,
      };
    case types.GET_APPROVAL_INVOICING_REPORT:
      return {
        ...state,
        isGetApprovalInvoicingReport: false,
        getApprovalInvoicingReport: null,
      };
    case types.GET_APPROVAL_INVOICING_REPORT_SUCCESS:
      return {
        ...state,
        isGetApprovalInvoicingReport: true,
        getApprovalInvoicingReport: action.payload,
      };
    case types.GET_APPROVAL_INVOICING_REPORT_FAILED:
      return {
        ...state,
        isGetApprovalInvoicingReport: false,
        getApprovalInvoicingReport: action.payload,
      };
    case types.GET_INVOICING_AUDIT_DETAILS:
      return {
        ...state,
        isGetInvoicingAuditDetails: false,
        getInvoicingAuditDetails: null,
      };
    case types.GET_INVOICING_AUDIT_DETAILS_SUCCESS:
      return {
        ...state,
        isGetInvoicingAuditDetails: true,
        getInvoicingAuditDetails: action.payload,
      };
    case types.GET_INVOICING_AUDIT_DETAILS_FAILED:
      return {
        ...state,
        isGetInvoicingAuditDetails: false,
        getInvoicingAuditDetails: action.payload,
      };
    case types.GET_INVOICING_EXPORT_REPORT:
      return {
        ...state,
        isGetInvoicingExportReport: false,
        getInvoicingExportReport: null,
      };
    case types.GET_INVOICING_EXPORT_REPORT_SUCCESS:
      return {
        ...state,
        isGetInvoicingExportReport: true,
        getInvoicingExportReport: action.payload,
      };
    case types.GET_INVOICING_EXPORT_REPORT_FAILED:
      return {
        ...state,
        isGetInvoicingExportReport: false,
        getInvoicingExportReport: action.payload,
      };
    case types.INVOICE_STATUS_UPDATE:
      return {
        ...state,
        isPostInvoicingStatusUpdate: false,
        postInvoicingStatusUpdate: null,
      };
    case types.INVOICE_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        isPostInvoicingStatusUpdate: true,
        postInvoicingStatusUpdate: action.payload,
      };
    case types.INVOICE_STATUS_UPDATE_FAILED:
      return {
        ...state,
        isPostInvoicingStatusUpdate: false,
        postInvoicingStatusUpdate: action.payload,
      };
    case types.SET_PMO_CHIPS:
      // console.log("chips in reducer", action.payload);
      return {
        ...state,
        getSetPMOChips: action.payload,
        // getApprovalListInvoice: null,
      };
    case types.CLEAR_SET_PMO_CHIPS:
      // console.log("GET_TEAM_TIMESHEET");
      return {
        ...state,
        isSetPMOChips: false,
        getSetPMOChips: null,
      };

    case types.MY_TEAM_APPROVAL_FILTER_DROP_DOWN:
      return {
        ...state,
        isMyTeamApprovalFilterDropDownList: false,
        getMyTeamApprovalFilterDropDownList: null,
      };
    case types.MY_TEAM_APPROVAL_FILTER_DROP_DOWN_SUCCESS:
      return {
        ...state,
        isMyTeamApprovalFilterDropDownList: true,
        getMyTeamApprovalFilterDropDownList: action.payload,
      };
    case types.MY_TEAM_APPROVAL_FILTER_DROP_DOWN_FAILED:
      return {
        ...state,
        isMyTeamApprovalFilterDropDownList: false,
        getMyTeamApprovalFilterDropDownList: action.payload,
      };
      case types.GET_APPROVAL_INVOICING_EXPORT_REPORT:
      return {
        ...state,
        isGetApprovalInvoicingExportReport: false,
        getApprovalInvoicingExportReport: null,
      };
    case types.GET_APPROVAL_INVOICING_EXPORT_REPORT_SUCCESS:
      return {
        ...state,
        isGetApprovalInvoicingExportReport: true,
        getApprovalInvoicingExportReport: action.payload,
      };
    case types.GET_APPROVAL_INVOICING_EXPORT_REPORT_FAILED:
      return {
        ...state,
        isGetApprovalInvoicingExportReport: false,
        getApprovalInvoicingExportReport: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}

export default TimeSheetReducer;