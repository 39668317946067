import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Autocomplete, TextField } from "@mui/material";
import { useState, useEffect } from "react";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);



export default function CustomizedDialogs(props) {
  const [project, setProject] = useState(null);

  const handleClose = () => {
    props.setProjDialogOpen(false);
    if(project){
    props.handleSelectedProjDialog(project);
    }
  };
  const handleClose1 = () =>{
    props.setProjDialogOpen(false);
  }
    const removeDuplicates = (array, key) => {
    if (!Array.isArray(array)) {
      // console.log('Invalid input. Expected an array.');
      return [];
    }
  
    if (array.length === 0) {
      // console.log('Array is empty.');
      return [];
    }
    // console.log("remove" , key , array);
    const uniqueIds = new Set();
    return array.filter(item => {
      if (!uniqueIds.has(item[key])) {
        uniqueIds.add(item[key]);
        return true;
      }
      return false;
    });
  };
  const uniqueProjects = removeDuplicates(props.allProjects, 'projId');
  const handleChange = (event, value) => {
    //console.log("PROJD2 value", value);
    setProject(value);
  };

  // console.log("PROJD1 project:", project , props.allProjects , uniqueProjects);


  return (
    
    <div>
      <Dialog
        onClose={()=>{handleClose1()}}
        aria-labelledby="customized-dialog-title"
        open={props.projDialogOpen}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose1}>
          Project Selection
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Select any project from all the projects in the dropdown:
          </Typography>
          <Autocomplete
            id="combo-box-demo"
            options={uniqueProjects}
            getOptionLabel={(option) => option.projName}
            value={project}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Combo box"
                variant="outlined"
              />
            )}
            onChange={handleChange}
          />
          {/* <Autocomplete
          id="combo-box-demo"
          value={project}
          onChange={(event , newValue)=> setProject(newValue)}
          inputValue={enteredVal}
          onInputChange={handleTextFieldChange}
          options={uniqueProjects}

          //options={filteredOptions}
          //options={allProjName}
          getOptionLabel={uniqueProjects.projName}
          renderInput={(params) => (
            <TextField {...params} label="Search" variant="outlined" />
          )}
          /> */} 
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={()=>{handleClose()}} color="primary">
            Select Project
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
