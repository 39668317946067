import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  Box,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import Encrypt from "../Utils/encryption.js";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  getMyTeamApprovalData,
  postApproveRejectExpenseData,
  resetExpenseResponse,
  postFinancialApproveRejectExpenseData
} from "../redux/Expense/action";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  mobileResH: {
    width: "15.875rem"
  },
  mobileRes: {
    width: "18.875rem"
  },
  mobileText: {
    width: "14.875rem",
  },
  normalText: {
    width: "25rem",
  },
}));

const ApproveRejectDialog = (props) => {
  const [rejectionComments, setRejectionComments] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width:600px)");
  const [comments, setComments] = React.useState("");
  const {
    bulkDownload,
  } = props;
  const history = useHistory();
  const dataValue = {comments};
  const [appValue, setAppValue] = React.useState(false);
  const classes = useStyles();

  const handleComments = (data) => {
    setComments(data);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function handleSingleExpense() {
    //console.log("postApproveExpenseData handleSingleExpense");
    props.handleSingleApproveRejectExpense(props.singleExpense);
    setRejectionComments("");
    props.handleCloseBulkApproveDialog();
  }
  const handleRejectionComments = (value) => {
    setRejectionComments(value);
    props.handleComments(value);
    handleComments(value)
  }; 
  //console.log("props single expense:=====> ", props.singleExpense);
  //console.log("location.state.compName in ARDB1", location?.state?.compName);
  //console.log("location.state.response in ARDB1", location?.state?.response);
  useEffect(() => {
    rejectionComments != "" ? setBtnDisabled(false) : setBtnDisabled(true);
  }, [rejectionComments]);

  // const handleBulkApproveResult = () => {
  //   let array = [];
  //   var key = process.env.REACT_APP_ENCRYPTION_KEY;
  //       array.push({
  //         recid: Encrypt(key, JSON.stringify(Number(location?.state?.response?.uniqueId))),
  //         expid: Encrypt(
  //           key,
  //           JSON.stringify(Number(location?.state?.response?.expId))
  //         ),
  //         projid: Encrypt(key, JSON.stringify(Number(location?.state?.response?.projUniqueId)))
  //       });
  //   let dataObj = {
  //     empId: Encrypt(key, JSON.stringify(Number(location?.state?.empId))),
  //     comments: comments,
  //     project: array,
  //   };
  //   dispatch(postApproveRejectExpenseData(dataObj));
  //   dispatch(resetExpenseResponse());
  //   //resetBulkDownload();
  //   //props.handleCloseBulkApproveDialog();
  //   history.push('/expense-Management', dataValue)
  //   setComments('');
  //   };

    // const handleSingleApproveRejectExpense = (value) => {
    //   let array = [];
    //   var key = process.env.REACT_APP_ENCRYPTION_KEY;
    //   location?.state?.response?.projdetails?.map((item) => {
    //     array.push({
    //       projid: Encrypt(key, JSON.stringify(Number(item.projectUniqueID))),
    //       recid: Encrypt(key, JSON.stringify(Number(item.projectRecID))),
    //       expid: Encrypt(key, JSON.stringify(Number(location?.state?.response?.expId))),
    //       projamount: 0,
    //       claimedamount: location?.state?.response?.claimAmount,
    //     });
    //   }); 
  
    //   let dataObject = {
    //     empId: Encrypt(key, JSON.stringify(location?.state?.empId)),
    //     expStatus: value,
    //     comment: comments,
    //     amount: props.approvedAmountVal,
    //     projdetails: array,
    //   };  
    //   dispatch(postFinancialApproveRejectExpenseData(dataObject));
    //   dispatch(resetExpenseResponse());
    //   //resetBulkDownload();
    //   //props.handleCloseBulkApproveDialog();
    //   history.push('/expense-Management')
    //   setComments("");
    //   // dispatch(getFinanceApprovalData({ empId: empId, nday: props.nDay }));
    //   // forceUpdate();
    // };

  return (
    <div>
      <Dialog
        open={props.openBulkApproveDialog}
        onClose={props.handleCloseBulkApproveDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.approveOrReject == 1 ? (
            <Typography
              style={{
                display: "flex",
                // alignItems: "center",
                justifyContent: "left",
                fontSize: "22px",
                fontWeight: 500,
              }}
            >
              Approve Expense
            </Typography>
          ) : (
            <Typography
              style={{
                display: "flex",

                justifyContent: "left",
                fontSize: "22px",
                fontWeight: 500,
                // paddingRight:"136px"
              }}
            >
              Reject Expense
            </Typography>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {matches ? "Are you sure you want to ":"You are about to"} {props.bulkDownload?.length > 1 ? "bulk" : ""}{" "}
            {props.approveOrReject == 1 ? "approve" : "reject"} the{" "}
            {props.viewDialog == true ? "following" : ""}
            expenses.
            {/* {console.log("check",props.bulkDownload,props.bulkDownload?.length)} */}
            {(props.approveOrReject == 1 && props.bulkDownload?.length <1 || props.approveOrReject == 0 && props.bulkDownload?.length <1 ) && props.bulkDownload?.length > 0 ? (
              props.bulkDownload?.map((item) => (
                <div>
                     {/* {console.log("Check2",props.singleExpense?.expCode,props.singleExpense?.projectId)} */}
                  Expense ID:{" "}
                  {props.finAppToggle == true ? item.expId : item.expCode},
                  Project ID:{" "}
                  {props.finAppToggle == true
                    ? item.projdetails.map((item) => `${item.projectId}, `)
                    : item.expCode}
                </div>
              ))
            ) : props.viewDialog == true ? (
              <div>
                {/* {console.log("Check1",props.singleExpense?.expCode,props.singleExpense?.projectId)} */}
                Expense ID: {props.singleExpense?.expCode}, Project ID:{" "}
                {props.singleExpense?.projectId}
              </div>
            ) : (
              ""
            )}
          </DialogContentText>
          {props.approveOrReject == 0 || props.viewFinApproval == true ? (
            <Box >
            <TextField 
              margin="dense"
              id="comments"
              label={matches ? "Add Comments" : "Comments*"}
              variant="outlined"
              multiline
              inputProps={{ maxLength: matches ? 100 : 500
               }}
              className={ window.screen.availWidth > 400 ? classes.mobileResH : 
                (window.screen.availWidth > 400 && window.screen.availWidth < 600) ? 
                classes.mobileRes : matches ? classes.mobileText : classes.normalText}
              value={rejectionComments}
              row={3}
              fullWidth
              onChange={(e) => {
                handleRejectionComments(e.target.value);
              }}
            />
            </Box>
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            style={{
              minWidth: "120px",
              minHeight: "36px",

              backgroundColor: "#FFFFFF",
              color: "#0B1941",
            }}
            onClick={props.handleCloseBulkApproveDialog}
          >
            Cancel
          </Button>
          {props.approveOrReject == 1 ? (
            <Button
            variant="outlined"
            style={{
              minWidth: "120px",
              minHeight: "36px",
              
              backgroundColor: "#0B1941",
              color:"#FFFFFF",
              marginRight:"10px",
              
            }}
            
              onClick={ 
                // matches && location?.state?.compName == "teamApprovalListing" ? () => {handleBulkApproveResult()} :
                // matches && location?.state?.compName == "financeApprovalListing" ? () => {handleSingleApproveRejectExpense('Approved')} :
                props.bulkDownload?.length > 0
                  ? props.handleBulkApprove
                  : handleSingleExpense
              }
              disabled={props.viewFinApproval == true ? btnDisabled : false}
              autoFocus
            >
              Approve
            </Button>
          ) : (
            <Button
            style={{
              minWidth: "120px",
              minHeight: "36px",
              marginRight:"15px",
              backgroundColor: btnDisabled ?"#9E9E9E":"#0B1941",
              color:"#FFFFFF",
            }}
              onClick={ 
                // matches && location?.state?.compName == "teamApprovalListing" ? () => {handleBulkApproveResult()} :
                // matches && location?.state?.compName == "financeApprovalListing" ? () => {handleSingleApproveRejectExpense('Rejected')} :
                props.bulkDownload?.length > 0
                  ? props.handleBulkApprove
                  : handleSingleExpense
              }
              disabled={btnDisabled}
              autoFocus
            >
              Reject
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ApproveRejectDialog;