import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import Typography from "@material-ui/core/Typography";
import {
  StepButton,
  Box,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import useMediaQuery from "@material-ui/core/useMediaQuery";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    color: "green",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  fill: {
    border: "3px solid #349259",
    borderRadius: "29px",
    fill: "white",
  },
  steps: {
    position: "absolute",
    top: -4,
    left: -5,
    width: "120%",
    height: "100%",
    textAlign: "center",
    lineHeight: "24px",
    border: "3px solid #349259",
    borderRadius: "35px",
  },
  stepperInfo: {
    marginLeft: "-37px",
  },
  statusHeading: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "8px",
    color: "rgba(255, 255, 255, 0.9)",
  },
  statusName: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "10px",
    marginRight: "2px",
    color: "rgba(255, 255, 255, 0.85)",
  },
  statusProjectName: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "8px",
    marginTop: "2px",
    color: "rgba(255, 255, 255, 0.9)",
    opacity: "0.6",
  },
  statusMessage: {
    fontFamily: "Montserrat",
    fontStyle: "italic",
    fontWeight: 400,
    fontSize: "8px",
    color: "#FFFFFF",
  },
  lines: {
    borderColor: "#bdbdbdab",
  },
  rootStepper: {
    backgroundColor: " #F8F9FF",
    "& .MuiStepConnector-lineVertical": {
      marginTop: "-24px",
      minHeight: "23px !important",
      marginLeft: "24px",
      borderLeftStyle: "solid",
      borderLeftWidth: "1px"
    }
  },
  chipLabel: {
    overflow: "visible",
  },
  statusHeading: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "8px",
    color: "rgba(255, 255, 255, 0.9)",
  },
  statusName: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "10px",
    marginRight: "2px",
    color: "rgba(255, 255, 255, 0.85)",
  },
  statusProjectName: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "8px",
    marginTop: "2px",
    color: "rgba(255, 255, 255, 0.9)",
    opacity: "0.6",
  },
  stepperResponsive: {
      fontFamily: 'Montserrat',
      fontSize: '0.44rem',
      fontWeight: '600',
      lineHeight: '1rem',
      letterSpacing: '0rem',
      fontSize: "0.4rem",
      textAlign: "center",
      fontFamily: "Montserrat",
      fontWeight: "600",
      lineHeight: "1rem",
      letterSpacing: "0rem",
      marginLeft: "119px",
      marginBottom: "32px",
      marginTop: "-40px",
  }
}));

export default function StepperExpense({ myExpenseList, data }) {
  const classes = useStyles();
  const status = "Draft";
  const step = 1;
  const matches = useMediaQuery("(max-width:600px)");

  const handleText = (value) => {
    return (
      <>
        <Box>
          <Typography className={matches ? classes.stepperResponsive: ''}>{value.status}</Typography>
          <Typography className={matches ? classes.stepperResponsive: ''}>{value.date}</Typography>
        </Box>
      </>
    );
  };

  const LensIcon = (props) => {
    return (
      <svg style={{ width: "20px", height: "20px" }}>
        <path
          className={classes.fill}
          d="M 12 0 a 12 12 0 1 0 0 24 a 12 12 0 0 0 0 -24 Z m -2 17 l -5 -5 l 1.4 -1.4 l 3.6 3.6 l 7.6 -7.6 L 19 8 l -9 9 Z"
        />
      </svg>
    );
  };

  const handleToolTip = (value) => {
    const dummyArray = [];
    const array = value?.tooltip_msg?.split("##");
    if (array !== undefined) {
      array.map((item, key) => {
        const a = array[key]?.split("|");
        const obj2 = { ...a };
        if(value.stage!=3){dummyArray.push({
          managerid: obj2["0"],
          projCode: obj2["1"],
          reason: obj2["2"],
        })}else{
          dummyArray.push({
            managerid: obj2["0"],
            
            reason: obj2["1"],
          });
        }
      });
    }

    return (
      <>
        <Box>
          <Typography
            className={classes.statusHeading}
            style={{ textAlign: "left", paddingTop: "5px", marginLeft: "5px" }}
          >
            {value?.prefix_msg}
          </Typography>
        </Box>
        {dummyArray?.map((value) => {
          return (
            <>
              <Box style={{ display: "flex" }}>
                <Typography
                  className={classes.statusName}
                  style={{
                    display: "flex",
                    textAlign: "left",
                    paddingLeft: "5px",
                  }}
                >
                  {value.managerid}
                </Typography>
                {value.projCode !== undefined && (
                  <Typography className={classes.statusName}>{"|"} </Typography>
                )}
                <Typography className={classes.statusProjectName}>
                  <span style={{ paddingLeft: "5px" }}> {value.projCode} </span>
                </Typography>{" "}
              </Box>
            
              <Typography  className={classes.statusMessage}
              
              style={{paddingBottom:"5px",marginLeft:"5px",textAlign:"left",}}>
                {value.reason}
              </Typography>
             
            </>
          );
        })}
      </>
    );
  };

  const StepIcon = ({ label, value }) => (
    <div style={{ position: "relative" }}>
      <LensIcon />
      <div
        className={classes.steps}
        style={{
          border:
            label === parseInt(myExpenseList?.currentstage) &&
            myExpenseList?.ExpFinalStatus == "Rejected"
              ? "3px solid #D40606"
              : (label === parseInt(myExpenseList?.currentstage) &&
                  myExpenseList?.ExpFinalStatus == "In Progress") ||
                (label === parseInt(myExpenseList?.currentstage) &&
                  myExpenseList?.ExpFinalStatus == "Approved")
              ? "3px solid #E0B15C"
              : label === parseInt(myExpenseList?.currentstage) &&
                myExpenseList?.ExpFinalStatus == "Paid"
              ? "3px solid #349259"
              : label < parseInt(myExpenseList?.currentstage)
              ? "3px solid #349259"
              : "3px solid #bdbdbd",
        }}
      >
        
        <Tooltip
          title={handleToolTip(value)}
          disableHoverListener={label == 1 || label == 4 || label == 5 ? true : false}
          arrow
          enterTouchDelay={0}
        >
          <Typography
            style={{
              color:
                label === parseInt(myExpenseList?.currentstage) &&
                myExpenseList?.ExpFinalStatus == "Rejected"
                  ? " #D40606"
                  : (label === parseInt(myExpenseList?.currentstage) &&
                      myExpenseList?.ExpFinalStatus == "In Progress") ||
                    (label === parseInt(myExpenseList?.currentstage) &&
                      myExpenseList?.ExpFinalStatus == "Approved")
                  ? " #E0B15C"
                  : label === parseInt(myExpenseList?.currentstage) &&
                    myExpenseList?.ExpFinalStatus == "Paid"
                  ? " #349259"
                  : label < parseInt(myExpenseList?.currentstage)
                  ? "#349259"
                  : "#bdbdbd",
            }}
          >
            {label}
          </Typography>
        </Tooltip>
        <Chip
          classes={{ label: classes.chipLabel }}
          label={handleText(value)}
          style={{
            backgroundColor: " #F8F9FF",
            marginTop: "12px",
            display: "flex",
          }}
        />
      </div>
    </div>
  );

  return (
    <Stepper 
      activeStep={parseInt(myExpenseList?.currentstage) - 1}
      classes={{ line: classes.lines, root: classes.rootStepper }}
      alternativeLabel
      linear={false}
      orientation="vertical"
    >
      {myExpenseList?.stepperdetails?.map((value, key) => {
        return (
          <Step key={1}>
            <StepButton style={{fontSize: "0.54rem", margin: "10px"}}
              icon={<StepIcon label={key + 1} value={value} />}
              onClick={() => console.log(" ")}
            />
          </Step>
        );
      })}
    </Stepper>
  );
}
