import React, { useState, useEffect, useRef } from "react";
import InfoIcon from "@material-ui/icons/Info";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import { uploadFile } from "react-s3";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ShowMoreText from "react-show-more-text";
import CloseIcon from "@material-ui/icons/Close";
import Encrypt from "../Utils/encryption";
import Decrypt from "../Utils/decryption";
import Divider from "@material-ui/core/Divider";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAlert from "@material-ui/lab/Alert";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  makeStyles,
  withStyles,
  Grid,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  TextField,
  Select,
  Link,
  Checkbox,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Popover,
  Snackbar,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DateFnsUtils from "@date-io/date-fns";
import ProjectId from "./multiSelectProject";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import SplitBox from "./splitBox";
import DragDropeTravel from "./dragDropeTravel";
import { FieldValidator } from "./validation";
import {
  getPurposeData,
  getCategoryData,
  getCurrencyData,
  getEmployeeProjectsData,
  getAttendeesData,
  getValidateAttendeesData,
  getPaymentData,
  postCreateExpenseData,
  getMyViewExpenseData,
  postEditExpenseData,
  resetExpenseResponse,
  getMyDelegateesData,
  getEmpDetail,
  getDependentsData,
} from "../redux/Expense/action";
import { useDispatch, useSelector } from "react-redux";
import Header from "./header";
import AttendeesBox from "./attendees";
import SnackBar from "./snackbar";
import history from "../history";
import { useLocation, useHistory } from "react-router-dom";
import EditSnackbars from "./editSnackBar";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import DOMPurify from "dompurify";
import { useAccount, useMsal } from "@azure/msal-react";
import { isValid } from "date-fns";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F8F9FF",
    paddingLeft: "100px",
    paddingRight: "100px",
  },
  rootMobile: {
    backgroundColor: "#F8F9FF",
    // paddingLeft: "26px",
    // paddingRight: "0",
    // overflow: "hidden",
  },
  advancesField: {
    // width: "300px",
    "& .MuiOutlinedInput-input": {
      padding: "14.5px 16px",
    },
    "& .MuiInputLabel-formControl": {
      top: "-3px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -3px) scale(0.75)",
    },
    "& .Mui-disabled": {
      color: "#000000de",
    },
    "& .MuiSelect-icon.Mui-disabled": {
      color: "#0000008a",
    },
  },
  formControl: {
    margin: "16px 0 8px 0",
    minWidth: "100%",
    // backgroundColor: "#FCFDFF",
    borderRadius: 4,
    notchedOutline: {
      borderColor: "#CB444A",
      borderRadius: 4,
    },
    "& .MuiFormLabel-root": {
      ["@media (max-width:600px)"]: { fontSize: "12px" },
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "500",
      color: "#2B2D30",
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "2px solid #162D6E",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#515961",
        ["@media (max-width:600px)"]: { fontSize: "12px" },
      },
      "& .MuiSelect-selectMenu": {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "500",
        color: "#2B2D30",
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#FFFFFF",
      },
      "& .MuiOutlinedInput-input": {
        ["@media (max-width:600px)"]: { fontSize: "13px" },
      },
    },
  },
  formControl12: {
    margin: "16px 0 8px 0",
    minWidth: "100%",
    borderRadius: 4,
    notchedOutline: {
      borderColor: "red",
      borderRadius: 4,
    },

    "& .MuiFormLabel-root": {
      // ["@media (max-width:600px)"]: { fontSize: "12px", fontWeight: "500" },
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "500",
      color: "#2B2D30",
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "2px solid #162D6E",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
      },
      "& .MuiSelect-selectMenu": {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "500",
        color: "#2B2D30",
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#FFFFFF",
      },
      "& .MuiOutlinedInput-input": {
        ["@media (max-width:600px)"]: { fontSize: "13px" },
      },
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dropdownStyle: {
    "& ul": {
      padding: 0,
      border: "0.5px solid #515961",
      boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
      borderRadius: 4,
    },
    "& li": {
      height: 47,
      fontSize: 14,
      color: "#515961",
      "&:hover": {
        backgroundColor: "rgba(217, 235, 247, 0.4);",
        color: "#439DD9",
      },
    },
  },
  selectRoot: {
    borderRadius: 4,
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "#439DD9",
      },
    },
    "&:focus": {
      backgroundColor: "transparent",
      borderRadius: 4,
    },
  },
  purpose: {
    display: "flex",
    justifyContent: "center",
  },
  expenseCategory: {
    display: "flex",
    justifyContent: "center",
  },
  ExpenseName: {
    display: "flex",
    justifyContent: "center",
    borderColor: "#CB444A",
  },
  expenseNameTextField: {
    backgroundColor: "#FCFDFF",
    borderRadius: 4,
  },
  invoiceReciept: {
    display: "flex",
    justifyContent: "center",
  },
  invoiceRecieptDateField: {
    backgroundColor: "#FCFDFF",
    borderRadius: 4,
  },
  projectId: {
    display: "flex",
    justifyContent: "center",
  },
  infoBox: {
    display: "flex",
    justifyContent: "center",
    "& .MuiSvgIcon-root": {
      fill: "#008CE6",
    },
  },
  infoBox1: {
    display: "flex",
    justifyContent: "center",
    "& .MuiSvgIcon-root": {
      fill: "#FFAA33",
    },
  },
  infoBoxMobile: {
    display: "flex",
    justifyContent: "center",
    // paddingRight: "27px",
    "& .MuiSvgIcon-root": {
      fill: "#008CE6",
    },
  },
  currency: {
    display: "flex",
    justifyContent: "center",
  },

  expenseHeading: {
    display: "inline-flex",
    margin: "30px 0px",
    width: "700px", //(id ke liye)
    height: "24px",
  },
  checkBox: {
    display: "flex",
    alignItems: "center",
    // marginLeft: "-10px",
    // marginTop: "-30px",
  },
  checkBoxRoot: {
    background: "#0B1941",
    color: "white",
    "&$checked": {
      background: "#0B1941",
      color: "white",
    },
  },
  checkBoxRoot1: {
    "& .MuiSvgIcon-root": {
      color: "#0B1941",
      // "&$checked": {
      //   background: "#0B1941",
      //   color: "white",
      // },
    },
  },
  saveSubmitButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
    paddingBottom: "35px",
  },
  saveSubmitButtonMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "35px",
    paddingRight: "70px",
    paddingLeft: "57px",
  },
  rowContainer: {
    display: "flex",
    justifyContent: "center",
  },
  rowContainerMobile: {
    justifyContent: "center",
    flexDirection: "column",
    padding: "0px 20px",
  },
  inputLabels: {
    backgroundColor: "#FFFFFF",
  },
  inputLabels1: {
    backgroundColor: "#FFFFFF",
    fontSize: "15px",
  },
  errorText: {
    color: "red",
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: 0,
    backgroundColor: "#F8F9FF",
    fontFamily: "Montserrat",
  },
  errorRoot: {
    color: "red",
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: 0,
    backgroundColor: "#F8F9FF",
    ["@media (max-width:600px)"]: { fontSize: "10px" },
  },
  projectDetailsText: {
    fontWeight: "600",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: "20px",
    lineHeight: "24.38px",
    letterSpacing: "0.3px",
    textAlign: "left",
    color: "#252733",
  },
  projectDetailsTextMobile: {
    fontWeight: "600",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "-0.16px",
    textAlign: "left",
    color: "#353535",
    display: "flex",
    flexDirection: "column",
  },
  customToolTip: {
    maxWidth: "265px",
    height: "300px",
    overflow: "auto",
  },
  projectDetailsMainBox: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #9E9E9E",
    borderRadius: "5px",
    padding: "25px",
    // marginTop: "-20px",
  },
  ExpandedAccordionTravel: {
    backgroundColor: "#F6F6F6",
    border: "1px solid #979797",
    "&.MuiAccordionSummary-root": {
      minHeight: "55px",
      maxHeight: "55px",
      "&.Mui-expanded": {
        minHeight: "55px",
        maxHeight: "55px",
      },
    },
  },
  checkBoxRootMobile: {
    background: "#0B1941",
    color: "white",
    display: "flex",
    flexDirection: "column",
    "& $checked": {
      background: "#0B1941",
      color: "white",
    },
  },
}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitleDescDialog = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <HighlightOffIcon style={{ color: "#162D6E" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContentDescDialog = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActionsDescDialog = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ViewReward = (props) => {
  const classes = useStyles();
  const location = useLocation();

  const theme = useTheme();
  const [purpose, setPurpose] = useState("");
  const [purposeError, setPurposeError] = useState("");
  const [expenseCategoryError, setExpenseCategoryError] = useState("");
  const [vendorNameError, setVendorNameError] = useState("");
  const [billNumberError, setBillNumberError] = useState("");
  const [gstError, setGstError] = useState("");
  const [expenseNameError, setExpenseNameError] = useState("");
  const [selectDateError, setSelectDateError] = useState("");
  const [paymentError, setPaymentError] = useState("");
  const [dependentsError, setDependentsError] = useState("");
  const [declarationError, setDeclarationError] = useState("");
  const [currencyError, setCurrencyError] = useState("");
  const [billAmountError, setBillAmountError] = useState("");
  const [claimAmountError, setClaimAmountError] = useState("");
  const [projectError, setProjectError] = useState("");
  const [attendeesError, setAttendeesError] = useState("");
  const [dragDropFileError, setDragDropFileError] = useState("");
  const [projectListSplitBoxError, setProjectListSplitBoxError] = useState("");
  const [expenseCategory, setExpenseCategory] = useState("");
  const [expenseCategoryChanged, setExpenseCategoryChanged] = useState(false);
  const [vendorName, setVendorName] = useState(null);
  const [billNumber, setBillNumber] = useState(null);
  const [gst, setGST] = useState(null);
  const [expenseName, setExpenseName] = useState("");
  const [currency, setCurrency] = useState("");
  const [billAmount, setBillAmount] = useState("");
  const [claimAmount, setClaimAmount] = useState("");
  const [splitClaimAmount, setSplitClaimAmount] = useState("");
  const [payment, setPayment] = useState("");
  const [project, setproject] = useState([]);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [attendees, setAttendees] = useState([]);
  const [empAmounts, setEmpAmounts] = useState("");
  const [openTeamOutingExceedDialog, setOpenTeamOutingExceedDialog] =
    useState(false);
  const [multipleAttendeesError, setMultipleAttendeesError] = useState("");
  const [toggleExpenseCategory, setToggleExpenseCategory] = useState(true);
  const [declarationCheckBox, setDeclarationCheckBox] = useState(false);
  const [valiDateDate, setValiDateDate] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [deleteFileList, setDeleteFileList] = useState([]);
  const [delEmpEntityId, setDelEmpEntityId] = useState(null);
  const [selectedProjects, setSelectedProjects] = useState([
    {
      projectName: { projectID: "", projName: "" },
      splitAmount: "",
      billable: 0,
    },
  ]);
  const [validatonMsg, setValidationMsg] = useState([]);
  const [openToolTip, setOpenToolTip] = useState(false);
  const [expand, setExpand] = useState(false);
  const [uniqueArray, setUniqueArray] = useState([]);
  const [openESB, setOpenESB] = useState(true);
  const [editValidateFileList, setEditValidateFileList] = useState([]);
  const [validateJoiningDate, setValidateJoiningDate] = useState(false);
  //Dialog update start
  const [openExceptionalDialog, setOpenExceptionalDialog] = useState(false);
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDescDialog, setOpenDescDialog] = React.useState(false);
  //new changes 0001
  const [fromDate, setFromDate] = useState(null);
  const [countExpCatClick, setCountExpCatClick] = useState(0);
  const [oldExpId, setOldExpid] = useState(0);
  const [toDate, setToDate] = useState(null);
  const [forMonth, setForMonth] = useState(null);
  const [fromLocation, setFromLocation] = useState(null);
  const [toLocation, setToLocation] = useState(null);
  const [inBetweenDistance, setInBetweenDistance] = useState(0);
  const [validateToDate, setValidateToDate] = useState(null);
  const [toDateError, setToDateError] = useState("");
  const [validateFromDate, setValidateFromDate] = useState(false);
  const [fromDateError, setFromDateError] = useState("");
  const [forMonthError, setForMonthError] = useState("");
  const [fromLocationError, setFromLocationError] = useState("");
  const [toLocationError, setToLocationError] = useState("");
  const [inBetweenDistanceError, setInBetweenDistanceError] = useState("");
  const [validateQuarter, setValidateQuarter] = useState(false);
  const [lastDate, setLastDate] = useState(null);
  const [fromType, setFromType] = useState(null);
  const [toType, setToType] = useState(null);
  const [noOfMon, setNoOfMon] = useState(null);
  const [togglePrevProjectList, setTogglePrevProjectList] = useState(0);
  const [totalClaimAmount, setTotalClaimAmount] = useState(0);
  const [projectError1, setProjectError1] = useState("");
  const [projectIdList, setProjectIdList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(true);
  const [currencySB, setCurrencySB] = useState(null);
  const [expanded, setExpanded] = useState(-1);
  const [expanded1, setExpanded1] = useState(true);
  const [expanded2, setExpanded2] = useState(true);
  const [expanded3, setExpanded3] = useState(true);
  const [checkErrEntries, setCheckErrEntries] = useState(false);
  const [checkCategoryError, setCheckCategoryError] = useState(-1);
  const [statusSnackbarOpen, setStatusSnackbarOpen] = useState(false);
  const [snackbarAlert, setSnackbarAlert] = useState(false);
  const [finalFileList, setFinalFileList] = useState([]);
  const [deleteExpData, setDeleteExpData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([
    {
      expid: 0,
      editFlag: 0,
      categoryCode: "",
      categoryId: "",
      categoryName: "",
      currencyId: "",
      billamount: "",
      claimedamount: "",
      receiptdate: null,
      paymentmethodid: "",
      expenseattachements: [
        {
          filename: "",
          attachmentsize: "",
        },
      ],
      deleteattachments: [],
    },
  ]);
  const [validationArray, setValidationArray] = useState([
    {
      categoryMsg: "",
      currencyMsg: "",
      billAmtMsg: "",
      claimAmtMsg: "",
      recieptMsg: "",
      paymentMsg: "",
      attachmentMsg: "",
    },
  ]);
  const traveltype = [
    {
      label: "International",
      value: "International",
    },
    {
      label: "Domestic",
      value: "Domestic",
    },
  ];
  const AdvancesType = [
    {
      label: "Travel Card",
      value: "Travel Card",
    },
    {
      label: "Cash",
      value: "Cash",
    },
  ];
  const [advancesList, setAdvancesList] = useState([
    {
      depositdate: null,
      currency: "",
      totaladvance: 0,
      advancetype: "Travel Card",
    },
  ]);
  const [advancesListTemp, setAdvancesListTemp] = useState([
    {
      depositdate: null,
      currency: "",
      totaladvance: 0,
      advancetype: "Travel Card",
    },
  ]);
  const [theData, setTheData] = useState([]);
  const [travelDestinationMsg, setTravelDestinationMsg] = useState("");
  const [travelstdtMsg, setTravelstdtMsg] = useState("");
  const [travelendtMsg, setTravelendtMsg] = useState("");
  const [travelTypeMsg, setTravelTypeMsg] = useState("");
  const [advancesJson, setAdvancesJson] = useState([{}]);
  const [travelDestination, setTravelDestination] = useState("");
  const [travelStartDate, setTravelStartDate] = useState(null);
  const [travelEndDate, setTravelEndDate] = useState(null);
  const [travelDetailType, setTravelDetailType] = useState("");
  const [travelCardAmount, setTravelCardAmount] = useState(0);
  const [cash, setCash] = useState(0);
  const [cashBalance, setCashBalance] = useState(0);
  const [travelCardBalance, setTravelCardBalance] = useState(0);
  const [actualsTravelCard, setActualsTravelCard] = useState(0);
  const [actualsCash, setActualsCash] = useState(0);
  const [actualsSelf, setActualsSelf] = useState(0);
  const [dependents, setDependents] = useState("");
  const [fileDisplayForDD, setFileDisplayForDD] = useState([]);
  const [storedExpenseCategory, setStoredExpenseCategory] = useState([]);
  const [toggleProjectListDispatch, setToggleProjectListDispatch] =
    useState(false);
  const [toggleFirstProjectListDispatch, setToggleFirstProjectListDispatch] =
    useState(0);
  const [projectChange, setProjectChange] = useState(0);
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const monthList = [
    { monthId: 1, monthName: "January" },
    { monthId: 1, monthName: "February" },
    { monthId: 1, monthName: "March" },
    { monthId: 1, monthName: "April" },
    { monthId: 1, monthName: "May" },
    { monthId: 1, monthName: "June" },
    { monthId: 1, monthName: "July" },
    { monthId: 1, monthName: "August" },
    { monthId: 1, monthName: "September" },
    { monthId: 1, monthName: "October" },
    { monthId: 1, monthName: "November" },
    { monthId: 1, monthName: "December" },
  ];

  const handleCloseEDB = () => {
    setOpenExceptionalDialog(false);
  };
  const handleOpenEDB = () => {
    setOpenExceptionalDialog(true);
  };
  const handleCloseTOB = () => {
    setOpenTeamOutingExceedDialog(false);
  };
  const handleOpenTOB = () => {
    setOpenTeamOutingExceedDialog(true);
    //  isAnonymous=(true);
  };
  //Dialog update end
  const handleCheckBox = (e) => {
    setDeclarationCheckBox(e.target.checked);
  };
  const onExpandClick = () => {
    setExpand(!expand);
  };
  const handleFileList = (data) => {
    setFileList(data);
  };

  const handleEditDeleteValidateFile = (data, index) => {
    if (index == -1) {
      setEditValidateFileList(data);
    } else {
      let arr = editValidateFileList;
      arr[index] = data;
      setEditValidateFileList(arr);
    }
  };


  const handleDeleteFileList = (data, index) => {
    let arr = [...selectedCategory];
    arr[index]?.deleteattachments?.push(data);
    arr[index].editFlag = 1;
    setSelectedCategory(arr);
    const temp = [...deleteFileList];
    temp.push(data);
    setDeleteFileList(temp);
    
  };

  const handleProjectList = (data) => {
    //console.log("ineditemployeeProjectsList handleProjectList data", data);
    setSelectedProjects(data);
  };

  const handleOpenDescDialog = () => {
    setOpenDescDialog(true);
  };
  const handleCloseDescDialog = () => {
    setOpenDescDialog(false);
  };

  const materialUITextFieldProps1 = {
    id: "filled-multiline-flexible",
    label: "Bill Amount*",
    multiline: true,
    maxRows: 4,
    variant: "outlined",
  };
  const materialUITextFieldProps2 = {
    id: "filled-multiline-flexible",
    label: "Claim Amount*",
    // multiline: true,
    // maxRows: 4,
    variant: "outlined",
  };

  //Adding the Redux Part
  const dispatch = useDispatch();
  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );
  const purposeList = useSelector(
    (state) => state.createExpenseData.getPurposeList
  );
  const CategoryList = useSelector(
    (state) => state.createExpenseData.getCategoryList
  );

  // console.log("mapping changes",travelDetailType,selectedCategory,CategoryList,expenseCategory)

  const employeeProjectsList = useSelector(
    (state) => state.createExpenseData.getEmployeeProjectsList
  );
  const CurrencyList = useSelector(
    (state) => state.createExpenseData.getCurrencyList
  );

  const AttendeesList = useSelector(
    (state) => state.createExpenseData.getAttendeesList
  );
  const validateAttendeesList = useSelector(
    (state) => state.createExpenseData.getValidateAttendeesList
  );
  const paymentList = useSelector(
    (state) => state.createExpenseData.getPaymentList
  );
  const isEditdPostSuccess = useSelector(
    (state) => state.createExpenseData.isEditdPost
  );

  const postEditDataList = useSelector(
    (state) => state.createExpenseData.postEditData
  );

  const myExpenseList = useSelector(
    (state) => state.createExpenseData.getViewExpenseList
  );

  //  console.log("edit data", myExpenseList);
  //console.log("checkwerty", myExpenseList);

  const delEmpIDRed = useSelector((state) => state.createExpenseData.delEmpId);
  const myDelegateesData = useSelector(
    (state) => state.createExpenseData.getMyDelegateesData
  );
  const updatedSSOEmpId = useSelector(
    (state) => state.createExpenseData.ssoEmpId
  );
  const refreshModuleToggle = useSelector(
    (state) => state.createExpenseData.reloadModuleToggle
  );
  const DependentsList = useSelector(
    (state) => state.createExpenseData.getDependentsList
  );
  // console.log("refreshModuleToggleVExp", DependentsList);
 
  useEffect(() => {
    setDragDropFileError("");
  }, [fileList]);
  useEffect(() => {
    return () => {
      dispatch(resetExpenseResponse());
    };
  }, []);
  // useEffect(() => {
  //   setOpenESB(true);
  // },
  // [isEditdPostSuccess ,postEditDataList]
  // );

  useEffect(() => {
    dispatch(getMyViewExpenseData(location?.state));
    if (
      delEmpIDRed != null &&
      delEmpIDRed != "" &&
      delEmpIDRed != ssoEmpIdDetails?.empId
    ) {
      const obj = { empId: delEmpIDRed, catId: null };
      dispatch(getEmployeeProjectsData(obj));
    } else if (ssoEmpIdDetails != null) {
      const obj = {
        empId: ssoEmpIdDetails.empId,
        catId: null,
      };
      dispatch(getEmployeeProjectsData(obj));
    }
    // console.log("location", location, billAmount, claimAmount);
    checkLocation();
  }, [location]);

  const checkLocation = () => {
    if (location?.state?.check == "notTravel") {
      setPurpose(purposeList?.find((item) => item.purpose == "Travel"));
      setExpenseName(location.state.response.expName);
      setExpenseCategory("");
      setPurposeError("");
      setDependents("");
      setToggleExpenseCategory(false);
      // setOldExpid(
      //   expenseCategory ? expenseCategory?.categoryId : 0
      // );
      setDeleteFileList(myExpenseList?.attachements);
      setBillAmount("");
      setClaimAmount("");
      setSelectedDate(null);
      setFromDate(null);
      setToDate(null);
      setForMonth(null);
      setFromLocation("");
      setToLocation("");
      setInBetweenDistance(0);
      setVendorName(null);
      setGST(null);
      setBillNumber(null);
      setAttendees([]);
      setSelectedProjects([
        {
          projectName: { projectID: "", projName: "" },
          splitAmount: "",
          billable: 0,
        },
      ]);
      setSplitClaimAmount("");
      setFileList([]);
      setFinalFileList([]);
      setTotalClaimAmount(0);
      setSelectedCategory([
        {
          expid: null,
          editFlag: 0,
          categoryCode: "",
          categoryId: "",
          categoryName: "",
          currencyId: "",
          billamount: "",
          claimedamount: "",
          receiptdate: null,
          paymentmethodid: "",
          expenseattachements: [
            {
              filename: "",
              attachmentsize: "",
            },
          ],
          deleteattachments: [],
        },
      ]);
      setValidationArray([
        {
          categoryMsg: "",
          currencyMsg: "",
          billAmtMsg: "",
          claimAmtMsg: "",
          recieptMsg: "",
          paymentMsg: "",
          attachmentMsg: "",
        },
      ]);
    }
  };
  const handleSnackbar = () => {
    setOpenESB(false);
  };
  // const handleOpenESBox = () => {
  //   setOpenESB(true);
  // };

  const handleCloseSnackbarVali = () => {
    setStatusSnackbarOpen(false);
    setCheckCategoryError(-1);
    setCheckErrEntries(false);
    setDisableSubmit(false);
    setSnackbarAlert(true);
    checkErrorInside = false;
  };
  const handlecloseSnackbar = () => {
    setOpenESB(false);
  };

  //console.log("isEditdPostSuccess", isEditdPostSuccess);

  useEffect(() => {
    //console.log("in the attendeeses", selectedProjects, AttendeesList);
    if (AttendeesList?.length > 0) {
      let optionList = [];
      selectedProjects.map((value) => {
        AttendeesList?.forEach((element) => {
          if (element.projCode === value.projectName.projectID) {
            optionList.push({
              ...element,
              color: "#" + Math.random().toString(16).substr(-6),
            });
          }
        });
      });
      const uniqueArray = [
        ...new Map(optionList.map((item) => [item["empId"], item])).values(),
      ];

      setUniqueArray(uniqueArray);
    }
  }, [selectedProjects, AttendeesList]);

  useEffect(() => {
    //console.log("storedExpenseCategory1",expenseCategory );
    const data = expenseCategory?.notes;
    const notesArray = data?.split("||");
    setStoredExpenseCategory(notesArray);
  }, [expenseCategory]);

  const prevProp = useRef({
    purposeList,
    CategoryList,
    paymentList,
    CurrencyList,
    DependentsList,
  }).current;

  useEffect(() => {
    //console.log("pm1", paymentList);
    if (paymentList !== null) {
      const filterdCurrency = paymentList?.filter(
        (value) => value.selected === 1
      );
      if (filterdCurrency?.length > 0) {
        setPayment(filterdCurrency[0]);
      }
      let array = [...selectedCategory];
      if (filterdCurrency?.length > 0) {
        array.map((item) => {
          item.paymentmethodid =
            item.paymentmethodid == ""
              ? filterdCurrency[0]?.methodId
              : item.paymentmethodid;
        });
      }
      setSelectedCategory(array);
    }
  }, [paymentList]);

  useEffect(() => {
    dispatch(getEmpDetail(account?.username));
    if (myExpenseList !== null) {
      filterExpenseData();
    }
  }, [myExpenseList]);

  useEffect(() => {
    //console.log("useEffectmyExpenseList ", selectedDate);
    if (myExpenseList !== null) {
      if (prevProp.purposeList !== purposeList) {
        let filter = purposeList?.filter(
          (value) =>
            value?.purposeId == myExpenseList?.ExpenseDetail[0]?.PurposeId
        );
        if (filter?.length > 0) {
          setPurpose(filter[0]);
        }
      }
      //new changes 0001
      setExpenseName(myExpenseList?.ExpenseDetail[0]?.ExpName);
      setSelectedDate(
        moment(myExpenseList?.ExpenseDetail[0]?.ReceiptDate).format("l")
      );

      setFromDate(
        myExpenseList?.ExpenseDetail[0]?.BillingCycleFromDate == null ||
          myExpenseList?.ExpenseDetail[0]?.BillingCycleFromDate == ""
          ? ""
          : moment(
              myExpenseList?.ExpenseDetail[0]?.BillingCycleFromDate
            ).format("l")
      );
      setFromType(
        myExpenseList?.ExpenseDetail[0]?.BillingCycleFromDate == null ||
          myExpenseList?.ExpenseDetail[0]?.BillingCycleFromDate == ""
          ? ""
          : moment(
              myExpenseList?.ExpenseDetail[0]?.BillingCycleFromDate
            ).toDate()
      );
      setToDate(
        myExpenseList?.ExpenseDetail[0]?.BillingCycleToDate == null ||
          myExpenseList?.ExpenseDetail[0]?.BillingCycleToDate == ""
          ? ""
          : moment(myExpenseList?.ExpenseDetail[0]?.BillingCycleToDate).format(
              "l"
            )
      );
      setToType(
        myExpenseList?.ExpenseDetail[0]?.BillingCycleToDate == null ||
          myExpenseList?.ExpenseDetail[0]?.BillingCycleToDate == ""
          ? ""
          : moment(myExpenseList?.ExpenseDetail[0]?.BillingCycleToDate).toDate()
      );
      setForMonth(
        myExpenseList?.ExpenseDetail[0]?.BillingCycleMonth == "null" ||
          myExpenseList?.ExpenseDetail[0]?.BillingCycleMonth == null ||
          myExpenseList?.ExpenseDetail[0]?.BillingCycleMonth == "" ||
          myExpenseList?.ExpenseDetail[0]?.BillingCycleMonth == undefined
          ? null
          : myExpenseList?.ExpenseDetail[0]?.BillingCycleMonth
      );
      setBillAmount(myExpenseList?.ExpenseDetail[0]?.BillAmount);
      setFromLocation(
        myExpenseList?.ExpenseDetail[0]?.FromLocation == "null" ||
          myExpenseList?.ExpenseDetail[0]?.FromLocation == null ||
          myExpenseList?.ExpenseDetail[0]?.FromLocation == ""
          ? ""
          : myExpenseList?.ExpenseDetail[0]?.FromLocation
      );
      setToLocation(
        myExpenseList?.ExpenseDetail[0]?.ToLocation == "null" ||
          myExpenseList?.ExpenseDetail[0]?.ToLocation == null ||
          myExpenseList?.ExpenseDetail[0]?.ToLocation == ""
          ? ""
          : myExpenseList?.ExpenseDetail[0]?.ToLocation
      );
      setInBetweenDistance(myExpenseList?.ExpenseDetail?.Distance);

      setClaimAmount(myExpenseList?.ExpenseDetail[0]?.ClaimedAmount);
      setSplitClaimAmount(myExpenseList?.ExpenseDetail[0]?.ClaimedAmount);
      setVendorName(myExpenseList?.ExpenseDetail[0]?.VendorName);
      setBillNumber(myExpenseList?.ExpenseDetail[0]?.BillNumber);
      setGST(myExpenseList?.ExpenseDetail[0]?.GSTNumber);

      if (myExpenseList?.ActualDetails != null) {
        setActualsCash(
          myExpenseList?.ActualDetails?.find(
            (item) => item.PaymentMethodId == 35
          )?.ActualsTypeAmt || 0
        );
        setActualsSelf(
          myExpenseList?.ActualDetails?.find(
            (item) => item.PaymentMethodId == 34
          )?.ActualsTypeAmt || 0
        );
        setActualsTravelCard(
          myExpenseList?.ActualDetails?.find(
            (item) => item.PaymentMethodId == 3
          )?.ActualsTypeAmt || 0
        );
      }
      if (myExpenseList?.AdvancesDetails != null) {
        setCash(
          myExpenseList?.AdvancesDetails?.find(
            (item) => item.AdvanceType == "Cash"
          )?.AdvancesTypeAmt || 0
        );
        setTravelCardAmount(
          myExpenseList?.AdvancesDetails?.find(
            (item) => item.AdvanceType == "Travel Card"
          )?.AdvancesTypeAmt || 0
        );
      }

      if (myExpenseList?.AdvSummaryDetails != null) {
        setTravelDestination(myExpenseList?.AdvSummaryDetails[0]?.Destination);
        setTravelEndDate(myExpenseList?.AdvSummaryDetails[0]?.TravelEndDate);
        setTravelStartDate(
          myExpenseList?.AdvSummaryDetails[0]?.TravelStartDate
        );
        setTravelDetailType(myExpenseList?.ExpenseDetail[0]?.TravelType);
        setTravelCardBalance(
          myExpenseList?.AdvSummaryDetails[0]?.TravelCardBal
        );
        setCashBalance(myExpenseList?.AdvSummaryDetails[0]?.CashBal);
      }
      setTheData(myExpenseList?.ExpenseDetail);
    }
    checkLocation();
  }, [myExpenseList, purposeList]);

  // useEffect(() => {
  //  console.log("check values" , selectedCategory , myExpenseList)
  // }, [selectedCategory , myExpenseList])

  // console.log(myExpenseList);
  const filterExpenseData = () => {
    let tempcat = [];
    let tempAdv = [];
    let total = 0;
    var key = "E546C8DF278CD5931069B522E695D4F2";
    myExpenseList?.ExpenseDetail?.map((item, index) => {
      // if (index == 0) {
      //   //handleDeleteFileList(item?.ExpenseAttachments);
      //   setDeleteFileList(item.ExpenseAttachments);
      // }
      total += item.ClaimedAmount;
      tempcat.push({
        expid: item.ExpId,
        editFlag: 0,
        categoryCode: item.CategoryCode,
        categoryId: item.CategoryId,
        categoryName: item.CategoryName,
        currencyId: item.CurrencyId,
        billamount: item.BillAmount,
        claimedamount: item.ClaimedAmount,
        receiptdate: item.ReceiptDate,
        paymentmethodid: item.PaymentMethodId,
        expenseattachements: [],
        deleteattachments: [],
      });
    });
    if (myExpenseList?.AdvancesList != null) {
      myExpenseList?.AdvancesList?.map((item, index) => {
        tempAdv.push({
          depositdate: item.DepositDate,
          currency: item.currency,
          totaladvance: item.TotalAdvance,
          advancetype: item.AdvanceType,
        });
      });
      setAdvancesList(tempAdv);
    }
    setSelectedCategory(tempcat);
    setTotalClaimAmount(total);
    let tempvaliarray = [];
    myExpenseList?.ExpenseDetail?.map((value) => {
      tempvaliarray.push({
        categoryMsg: "",
        currencyMsg: "",
        billAmtMsg: "",
        claimAmtMsg: "",
        recieptMsg: "",
        paymentMsg: "",
        attachmentMsg: "",
      });
    });
    setValidationArray(tempvaliarray);
    const dummyArray = [];
    myExpenseList?.ProjectDetails?.map((value) => {
      //console.log("value", value);
      dummyArray.push({
        projectName: {
          projectID: value.ProjectId,
          projName: value.ProjectName,
          projId: value.ProjUniqueId,
        },
        splitAmount: value.ProjAmount,
        billable: value.Billable,
      });
    });
    setSelectedProjects([...dummyArray]);
  };

  useEffect(() => {
    //console.log("in myExpenseList ");
    if (myExpenseList !== null) {
      if (prevProp.CurrencyList !== CurrencyList) {
        let filter = CategoryList?.filter(
          (value) =>
            value?.currencyId == myExpenseList?.ExpenseDetail[0]?.CurrencyId
        );

        let selectedCurrency = CurrencyList?.find(
          (s) => s.currencyId === myExpenseList?.ExpenseDetail[0]?.CurrencyId
        );
        // let x = {
        //   currencyId: selectedCurrency?.currencyId,
        //   currencyCode: selectedCurrency?.currencyCode,
        //   currency: selectedCurrency?.currency,
        //   selected: selectedCurrency?.selected,
        // };

        // if (filter?.length > 0) {
        if (CurrencyList != null && selectedCurrency != undefined) {
          setCurrency(selectedCurrency);
        }
        // }
      }
    }
  }, [myExpenseList, CurrencyList]);

  useEffect(() => {
    if (myExpenseList != null && CategoryList != null) {
      // console.log("EditPurpoeValue2 filter[0]", CategoryList);
      if (prevProp.CategoryList != CategoryList) {
        let filter = CategoryList?.filter(
          (value) =>
            value?.categoryId == myExpenseList?.ExpenseDetail[0]?.CategoryId
        );
        if (filter?.length > 0) {
          // console.log("EditPurpoeValue3 filter[0]", filter[0]);
          if (toggleExpenseCategory == true) {
            setExpenseCategory(filter[0]);
          }
        }
      }
    }
  }, [myExpenseList, CategoryList]);

  useEffect(() => {
    //console.log("pm2 ", myExpenseList);
    if (myExpenseList !== null) {
      if (prevProp.paymentList !== paymentList) {
        //console.log("pm4", CategoryList);
        let filter = paymentList?.filter(
          (value) =>
            value?.methodId == myExpenseList?.ExpenseDetail[0]?.PaymentMethodId
        );
        //console.log("pm3", filter);
        if (filter?.length > 0) {
          setPayment(filter[0]);
        }
      }
    }
  }, [myExpenseList, paymentList]);

  useEffect(() => {
    if (myExpenseList !== null) {
      if (DependentsList != null) {
        if (prevProp.DependentsList !== DependentsList) {
          let filterdDependents = DependentsList?.filter(
            (value) =>
              value?.dependentId == myExpenseList?.ExpenseDetail[0]?.DependentId
          );
          if (filterdDependents?.length > 0) {
            setDependents(filterdDependents[0]);
          }
        }
      }
    }
  }, [myExpenseList, DependentsList]);

  const Array = [
    {
      validationType: "purpose",
      value: purpose,
    },

    {
      validationType: "expense Category",
      value: expenseCategory,
    },
    {
      validationType: "expense Name",
      value: expenseName,
    },
    {
      validationType: "Reciept Date",
      value: selectedDate,
      claim: valiDateDate,
      validateList: validateQuarter,
      validateList2: lastDate,
      noOfMonths: ssoEmpIdDetails,
      validateJoiningDate: validateJoiningDate,
    },
    {
      validationType: "Drag Drop File",
      value: fileList,
      claim: editValidateFileList,
      validateList: "edit",
    },
    {
      validationType: "Project And Split Box",
      value: selectedProjects,
      claim: claimAmount,
    },
    {
      validationType: "Declaration CheckBox",
      value: declarationCheckBox,
    },
    {
      validationType: "Currency",
      value: currency,
    },
    {
      validationType: "Bill Amount",
      value: billAmount,
    },
    {
      validationType: "Claim Amount",
      value: claimAmount,
      claim: billAmount,
      validateList: validateAttendeesList,
      validateList2: expenseCategory,
      noOfMonths: noOfMon,
    },
    {
      validationType: "Payment",
      value: payment,
    },
    ...(expenseCategory?.multipleAttendes == 1
      ? [
          {
            validationType: "Attendees",
            value: attendees,
            claim: claimAmount,
            validateList: validateAttendeesList,
          },
        ]
      : []),
    ...(expenseCategory?.companyExpenseFlag === 1
      ? [
          {
            validationType: "Vendor Name",
            value: vendorName,
          },
          {
            validationType: "Bill Number",
            value: billNumber,
          },
          {
            validationType: "GST",
            value: gst,
          },
        ]
      : []),
    ...(expenseCategory?.billingCycle == 1
      ? [
          {
            validationType: "From Date",
            value: fromDate,
          },
          {
            validationType: "To Date",
            value: toDate,
          },
          {
            validationType: "For Month",
            value: forMonth,
          },
        ]
      : []),
    ...(expenseCategory?.distance == 1
      ? [
          {
            validationType: "From Location",
            value: fromLocation,
          },
          {
            validationType: "To Location",
            value: toLocation,
          },
          {
            validationType: "Distance In Between",
            value: inBetweenDistance,
          },
        ]
      : []),
  ];

  //console.log("stae of expense cate", expenseCategory);
  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  function isLeapYear(date) {
    var year = date.getFullYear();
    return year % 400 === 0 || (year % 4 === 0 && year % 100 !== 0);
  }
  function getLastDayOfExpense(lastDay, type) {
    if (lastDay.getMonth() == 0) {
      if (isLeapYear(lastDay) == false) {
        //console.log("lastday", lastDay.addDays(75));
        setLastDate(lastDay.addDays(75));
        return lastDay.addDays(75);
      } else if (isLeapYear(lastDay) == true) {
        //console.log("lastday", lastDay.addDays(76));
        setLastDate(lastDay.addDays(76));
        return lastDay.addDays(76);
      }
    } else if (type.getMonth() == 1) {
      //console.log("lastday", lastDay.addDays(47));
      setLastDate(lastDay.addDays(47));
      return lastDay.addDays(47);
    } else if (type.getMonth() == 2) {
      //console.log("lastday", lastDay.addDays(16));
      setLastDate(lastDay.addDays(16));
      return lastDay.addDays(16);
    } else if (type.getMonth() == 3) {
      //console.log("lastday", lastDay.addDays(77));
      setLastDate(lastDay.addDays(77));
      return lastDay.addDays(77);
    } else if (type.getMonth() == 4) {
      //console.log("lastday", lastDay.addDays(46));
      setLastDate(lastDay.addDays(46));
      return lastDay.addDays(46);
    } else if (type.getMonth() == 5) {
      //console.log("lastday", lastDay.addDays(16));
      setLastDate(lastDay.addDays(16));
      return lastDay.addDays(16);
    } else if (type.getMonth() == 6) {
      //console.log("lastday", lastDay.addDays(77));
      setLastDate(lastDay.addDays(77));
      return lastDay.addDays(77);
    } else if (type.getMonth() == 7) {
      //console.log("lastday", lastDay.addDays(46));
      setLastDate(lastDay.addDays(46));
      return lastDay.addDays(46);
    } else if (type.getMonth() == 8) {
      //console.log("lastday", lastDay.addDays(16));
      setLastDate(lastDay.addDays(16));
      return lastDay.addDays(16);
    } else if (type.getMonth() == 9) {
      //console.log("lastday", lastDay.addDays(77));
      setLastDate(lastDay.addDays(77));
      return lastDay.addDays(77);
    } else if (type.getMonth() == 10) {
      //console.log("lastday", lastDay.addDays(47));
      setLastDate(lastDay.addDays(47));
      return lastDay.addDays(47);
    } else if (type.getMonth() == 11) {
      //console.log("lastday", lastDay.addDays(16));
      setLastDate(lastDay.addDays(16));
      return lastDay.addDays(16);
    }
  }

  const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
  const REGION = "ap-south-1";
  const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
  const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

  const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  };
  const handleUpload = async (file) => {
    //console.log("handleUpload01", file);
    uploadFile(file, config)
      .then((data) => console.log(""))
      .catch((err) => console.error("handleError", err));
  };
  useEffect(() => {
    if (isEditdPostSuccess == true) {
      for (let i = 0; i < finalFileList?.length; i++) {
        const mynewFile = new File(
          [finalFileList[i]],
          `${location?.state?.response?.expId}_${finalFileList[
            i
          ].name.toLowerCase()}`
        );
        handleUpload(mynewFile);
      }
      // console.log("handleUpload00", fileList);
      // https://fileuploadbucketdotnet.s3.ap-south-1.amazonaws.com/filename.jpg
      // for (let i = 0; i < fileList?.length; i++) {
      //   const mynewFile = new File(
      //     [fileList[i]],
      //     `${location?.state?.response?.expId}_${fileList[
      //       i
      //     ].name.toLowerCase()}`
      //   );
      //   handleUpload(mynewFile);
      // }
      setSnackbarAlert(true);
    }
  }, [isEditdPostSuccess, finalFileList]);
  useEffect(() => {
    if (
      delEmpIDRed != null &&
      delEmpIDRed != "" &&
      delEmpIDRed != ssoEmpIdDetails?.empId &&
      ssoEmpIdDetails != null &&
      ssoEmpIdDetails != ""
    ) {
      let tempUser = myDelegateesData?.filter(
        (item) => item.empId == delEmpIDRed
      );
      let obj = {
        entityId: tempUser[0]?.entityId,
      };
      dispatch(getPurposeData(obj));
    } else {
      let obj = {
        entityId: ssoEmpIdDetails?.entityId,
      };
      dispatch(getPurposeData(obj));
    }
    dispatch(
      getCurrencyData(
        delEmpIDRed != null &&
          delEmpIDRed != "" &&
          delEmpIDRed != ssoEmpIdDetails?.empId
          ? delEmpIDRed
          : ssoEmpIdDetails?.empId
      )
    );
    dispatch(getMyDelegateesData(ssoEmpIdDetails?.empId));
    dispatch(getEmpDetail(account?.username));
    if (window.performance && refreshModuleToggle == null) {
      //console.log("window.performance works fine on this browser RELOAD1",window.screen.availWidth);
      window.screen.availWidth > 600 && history.push("/expense-Management");
    }
  }, []);
  useEffect(() => {
    if (CurrencyList !== null) {
      const filterdCurrency = CurrencyList?.filter(
        (value) => value.selected === 1
      );
      let array = [...selectedCategory];
      if (location?.state?.response?.currencyChanged) {
        let changedCurrency = CurrencyList?.find(
          (value) =>
            value.currencyCode === location?.state?.response?.currencyCode
        );
        if (changedCurrency != undefined) {
          setCurrency(changedCurrency);
          if (changedCurrency) {
            array.map((item) => {
              item.currencyId = changedCurrency.currencyId;
            });
          }
        }
      }
      setSelectedCategory(array);
    }
  }, [CurrencyList]);

  useEffect(() => {
    if (toDate != null && fromDate != null) {
      const diffInDates = (toType - fromType) / (1000 * 60 * 60 * 24);
      const noOfMonths = Math.round(diffInDates / 30);
      setNoOfMon(noOfMonths);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    if (myDelegateesData != null && myDelegateesData != undefined) {
      let tempDel = myDelegateesData.filter((del) => del.empId == delEmpIDRed);
      setDelEmpEntityId(tempDel[0]?.entityId);
    }
  }, [delEmpIDRed, myDelegateesData]);
  //console.log("delEmpEntityId EDITEXP1", delEmpEntityId);
  useEffect(() => {
    if (purpose !== "") {
      if (
        delEmpIDRed != null &&
        delEmpIDRed != "" &&
        delEmpIDRed != undefined &&
        delEmpIDRed != ssoEmpIdDetails?.empId &&
        ssoEmpIdDetails != "" &&
        ssoEmpIdDetails != undefined &&
        myDelegateesData != null &&
        myDelegateesData != "" &&
        myDelegateesData != undefined
      ) {
        let tempUser = myDelegateesData?.filter(
          (item) => item.empId == delEmpIDRed
        );
        //console.log("tempUser ABCD81", tempUser[0]?.entity);
        let obj = {
          pId: purpose?.purposeId,
          entity: tempUser[0]?.entity,
          traveltype: travelDetailType?travelDetailType:null,
        };
        dispatch(getCategoryData(obj));
      } else {
        //console.log("in else ABCD82", ssoEmpIdDetails?.entity);
        let obj = {
          pId: purpose?.purposeId,
          entity: ssoEmpIdDetails?.entity,
          traveltype: travelDetailType?travelDetailType:null,
        };
        dispatch(getCategoryData(obj));
      }
    }
    if (
      ssoEmpIdDetails != null &&
      ssoEmpIdDetails != undefined &&
      ssoEmpIdDetails != ""
    ) {
      //console.log("11110000-1 purpose, ssoEmpIdDetails");
      handleDateChange(
        moment(myExpenseList?.ExpenseDetail[0]?.ReceiptDate).toDate(),
        moment(myExpenseList?.ExpenseDetail[0]?.ReceiptDate).format("l")
      );
    }
  }, [purpose, ssoEmpIdDetails, myDelegateesData]);

  useEffect(() => {
    if(CategoryList!=null && CategoryList!="" ){
      const updatedSelectedCategory = selectedCategory?.map(category => {
        const isMatched = CategoryList?.some(listItem => listItem.categoryId === category.categoryId);
        if (isMatched) {
          return category;
        } else {
          return {
            ...category,
            categoryCode: "",
            categoryId: "",
            categoryName: "",
          };
        }
      });
      // console.log("mapping",updatedSelectedCategory)
      setSelectedCategory(updatedSelectedCategory);
    }
    const obj1= {
      expenseID: selectedCategory[0]?.categoryId,
      empId:
        delEmpIDRed != null &&
        delEmpIDRed != "" &&
        delEmpIDRed != ssoEmpIdDetails?.empId
          ? delEmpIDRed
          : ssoEmpIdDetails?.empId,
    };
    dispatch(getPaymentData(obj1));
   
  }, [CategoryList,travelDetailType]);


  useEffect(() => {
    if (validateAttendeesList) {
      setEmpAmounts(validateAttendeesList[0]);
    }
  }, [validateAttendeesList]);

  const [count, setCount] = useState(0);
  useEffect(() => {
    if (
      expenseCategory != null &&
      expenseCategory != "" &&
      expenseCategory != undefined
    ) {
      if (count == 0) {
        handleDateChange(
          moment(myExpenseList?.ExpenseDetail[0]?.ReceiptDate).toDate(),
          moment(myExpenseList?.ExpenseDetail[0]?.ReceiptDate).format("l")
        );
        setCount(1);
      }
      let val = CategoryList?.find(
        (item) => item.categoryName === expenseCategory
      )?.categoryId;
      const obj = {
        expenseID: selectedCategory[0]?.categoryId,
        empId:
          delEmpIDRed != null &&
          delEmpIDRed != "" &&
          delEmpIDRed != ssoEmpIdDetails?.empId
            ? delEmpIDRed
            : ssoEmpIdDetails?.empId,
      };
      dispatch(getDependentsData(obj.expenseID));
      //newlychanged
      //dispatch(getValidateAttendeesData(obj));
      //newlychanged
      if (selectedDate != null) {
        const obj2 = {
          expenseID: expenseCategory?.categoryId,
          empId:
            delEmpIDRed != null &&
            delEmpIDRed != "" &&
            delEmpIDRed != ssoEmpIdDetails?.empId
              ? delEmpIDRed
              : ssoEmpIdDetails?.empId,
          expid: location?.state?.response?.expId,
          recieptDate: selectedDate ? selectedDate : null,
        };
        dispatch(getValidateAttendeesData(obj2));
      }

      dispatch(getPaymentData(obj));
    }
    if (
      expenseCategory != null &&
      expenseCategory != "" &&
      expenseCategory != undefined &&
      togglePrevProjectList == 0
    ) {
      if (
        delEmpIDRed != null &&
        delEmpIDRed != "" &&
        delEmpIDRed != ssoEmpIdDetails?.empId
      ) {
        const obj = { empId: delEmpIDRed, catId: expenseCategory?.categoryId };
        // dispatch(getEmployeeProjectsData(obj));

        setTogglePrevProjectList(togglePrevProjectList + 1);
      } else if (ssoEmpIdDetails != null) {
        // console.log(
        //   "3 inside the projectData delEmpIDRed",
        //   delEmpIDRed,
        //   expenseCategory?.categoryId
        // );
        const obj = {
          empId: ssoEmpIdDetails?.empId,
          catId: expenseCategory?.categoryId,
        };
        //dispatch(getEmployeeProjectsData(obj));
        dispatch(getDependentsData(obj.catId));
        setTogglePrevProjectList(togglePrevProjectList + 1);
      }
    }
  }, [expenseCategory]);

  //newlychanged
  useEffect(() => {
    if (expenseCategory != "" && selectedDate != null) {
      const obj = {
        expenseID: expenseCategory?.categoryId,
        empId:
          delEmpIDRed != null &&
          delEmpIDRed != "" &&
          delEmpIDRed != ssoEmpIdDetails?.empId
            ? delEmpIDRed
            : ssoEmpIdDetails?.empId,
        expid: location?.state?.response?.expId,
        recieptDate: selectedDate ? selectedDate : null,
      };
      dispatch(getValidateAttendeesData(obj));
    }
  }, [selectedDate]);
  //newlychanged
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    validatonMsg.map((value) => {
      if (value.type === "purpose") {
        setPurposeError(value.message);
      } else if (value.type === "expense Category") {
        setExpenseCategoryError(value.message);
      } else if (value.type === "expense Name") {
        setExpenseNameError(value.message);
      } else if (value.type === "Reciept Date") {
        setSelectDateError(value.message);
      } else if (value.type === "Project Id") {
        setProjectError(value.message);
      } else if (value.type === "Currency") {
        setCurrencyError(value.message);
      } else if (value.type === "Bill Amount") {
        setBillAmountError(value.message);
      } else if (value.type === "Claim Amount") {
        //console.log("Claim Amount Error:value.message: ====> ", value.message);
        setClaimAmountError(value.message);
      } else if (value.type === "Vendor Name") {
        setVendorNameError(value.message);
      } else if (value.type === "GST") {
        setGstError(value.message);
      } else if (value.type === "Bill Number") {
        setBillNumberError(value.message);
      } else if (value.type === "Attendees") {
        setAttendeesError(value.message);
      } else if (value.type === "Drag Drop File") {
        setDragDropFileError(value.message);
      } else if (value.type === "Project And Split Box") {
        setProjectListSplitBoxError(value.message);
      } else if (value.type === "Declaration CheckBox") {
        setDeclarationError(value.message);
      } else if (value.type === "Payment") {
        setPaymentError(value.message);
      } else if (value.type === "For Month") {
        setForMonthError(value.message);
      } else if (value.type === "From Date") {
        setFromDateError(value.message);
      } else if (value.type === "To Date") {
        setToDateError(value.message);
      } else if (value.type === "From Location") {
        setFromLocationError(value.message);
      } else if (value.type === "To Location") {
        setToLocationError(value.message);
      } else if (value.type === "Distance In Between") {
        setInBetweenDistanceError(value.message);
      }
    });
  }, [validatonMsg]);
  // console.log("list", selectedCategory,fileList);
  const handleRemove = (i, cat) => {
    const list = JSON.parse(JSON.stringify(selectedCategory));
    let var1 = totalClaimAmount - selectedCategory[i]?.claimedamount;
    setTotalClaimAmount(var1);
    if (
      selectedCategory[i].expid != null &&
      selectedCategory[i].expenseattachements.length == 0
    ) {
      let temp1 = [...theData];
      temp1.splice(i, 1);
      setTheData(temp1);
      handleEditDeleteValidateFile(
        myExpenseList?.ExpenseDetail[i].ExpenseAttachments,
        i
      );
    }

    let temp = [...deleteExpData];
    temp.push({
      expid: selectedCategory[i].expid,
      categoryid: selectedCategory[i].categoryId,
    });
    setDeleteExpData(temp);
    let old = selectedCategory[i].claimedamount;
    if (selectedCategory[i].paymentmethodid == 34) {
      let x = Number(actualsSelf);
      x = x - old;
      setActualsSelf(x);
    }
    if (selectedCategory[i].paymentmethodid == 3) {
      let x = Number(actualsTravelCard);
      x = x - old;
      setActualsTravelCard(x);
    }
    if (selectedCategory[i].paymentmethodid == 35) {
      let x = Number(actualsCash);
      x = x - old;
      setActualsCash(x);
    }
    list.splice(i, 1);
    setSelectedCategory(list);
    const list1 = JSON.parse(JSON.stringify(validationArray));
    list1.splice(i, 1);
    setValidationArray(list1);
    setFileList([]);
    let list2 = [...fileDisplayForDD];
    list2.splice(i, 1);
    setFileDisplayForDD(list2);
    //props.handleProjectList(list);
  };
  

  const handleAccordionChange = (index, isExpanded) => (event) => {
    setExpanded(isExpanded ? null : index);
  };
  const handleAccordionChangeFroAdvances = (isExpanded) => (event) => {
    setExpanded1(isExpanded ? false : true);
  };
  const handleAccordionChangeForSummary = (isExpanded) => (event) => {
    setExpanded2(isExpanded ? false : true);
  };
  const handleAccordionChangeForTravelDetails = (isExpanded) => (event) => {
    setExpanded3(isExpanded ? false : true);
  };
  const handleAdvancesList = (e, index, kind) => {
    if (kind == "cardBal") {
      setTravelCardBalance(e.target.value);
    }
    if (kind == "cashBal") {
      setCashBalance(e.target.value);
    }
    let arr = [...advancesList];
    if (kind == "type") {
      arr[index].advancetype = e.target.value;
    }
    if (kind == "date") {
      arr[index].depositdate = e;
    }
    if (kind == "curr") {
      // console.log("check e" ,e)
      arr[index].currency = e.target.value.currency;
    }
    if (kind == "amt") {
      arr[index].totaladvance = Number(e.target.value.replaceAll(",", ""));
    }
    arr[index].currency = currency.currency;
    // console.log("target comsole", e, index, kind,arr);

    setAdvancesList(arr);
    let dataCard = 0;
    let dataCash = 0;
    arr.map((item, index) => {
      if (item.advancetype == "Travel Card") {
        dataCard = dataCard + item.totaladvance;
      } else {
        dataCash = dataCash + item.totaladvance;
      }
    });
    setTravelCardAmount(dataCard);
    setCash(dataCash);
  };
  const addAdvancesLine = () => {
    let array = JSON.parse(JSON.stringify(advancesList));
    array.push({
      depositdate: null,
      currency: "",
      totaladvance: 0,
      advancetype: "Travel Card",
    });
    setAdvancesList(array);
  };
  const handleRemoveAdvances = (i) => {
    const list = [...advancesList];
    // console.log("list" , list);
    let advType = list[i].advancetype;
    if (advType == "Cash") {
      let x = cash;
      x = x - list[i].totaladvance;
      setCash(x);
    }
    if (advType == "Travel Card") {
      let x = travelCardAmount;
      x = x - list[i].totaladvance;
      setTravelCardAmount(x);
    }
    list.splice(i, 1);
    setAdvancesList(list);

    //props.handleProjectList(list);
  };
  let netPayable = 0;

  useEffect(() => {
    netPayable = Number(
      Number(cash) +
        Number(travelCardAmount) -
        (Number(actualsCash) +
          Number(actualsSelf) +
          Number(actualsTravelCard)) -
        (Number(travelCardBalance) + Number(cashBalance))
    );
    let arr = [...advancesJson];
    arr[0] = [
      {
        // advcash: cash,
        // advtravelcard: travelCardAmount,
        // advtotal:Number(cash+travelCardAmount),
        // actcash: actualsCash || 0,
        // actself:actualsSelf || 0,
        // acttravelcard:actualsTravelCard || 0,
        // acttotal:Number(Number(actualsCash) + Number(actualsSelf) + Number(actualsTravelCard)),

        destination: travelDestination,
        travelstartdate: travelStartDate,
        travelenddate: travelEndDate,
        //travelType:travelDetailType,
        travelcardbal: Number(travelCardBalance)?.toFixed(2) || 0,
        cashbal: Number(cashBalance)?.toFixed(2) || 0,
        refundreceived: Number(Number(travelCardBalance) + Number(cashBalance))?.toFixed(2),
        netpayable:
         ( Number(Number(cash) + Number(travelCardAmount)) -
          Number(
            Number(actualsCash) +
              Number(actualsSelf) +
              Number(actualsTravelCard)
          ) -
          Number(Number(travelCardBalance) + Number(cashBalance)))?.toFixed(2),
      },
    ];

    setAdvancesJson(arr);
  }, [
    advancesList,
    selectedCategory,
    travelCardBalance,
    cashBalance,
    travelDestination,
    travelStartDate,
    travelEndDate,
    travelDetailType,
  ]);
  // console.log("advances outside" ,travelCardBalance , cashBalance)

  const addCategoryLine = () => {
    //setToggle(false);

    let array = JSON.parse(JSON.stringify(selectedCategory));
    array.push({
      expid: 0,
      editFlag: 0,
      categoryCode: "",
      categoryId: "",
      categoryName: "",
      currencyId: "",
      billamount: "",
      claimedamount: "",
      receiptdate: null,
      paymentmethodid: "",
      expenseattachements: [
        {
          filename: "",
          attachmentsize: "",
        },
      ],
      deleteattachments: [],
    });
    setSelectedCategory(array);
    setExpanded(selectedCategory.length);
    let array1 = [...validationArray];
    array1.push({
      categoryMsg: "",
      currencyMsg: "",
      billAmtMsg: "",
      claimAmtMsg: "",
      recieptMsg: "",
      paymentMsg: "",
      attachmentMsg: "",
    });
    setValidationArray(array1);
    let x = [...fileDisplayForDD];
    x[selectedCategory.length] = [];
    setFileDisplayForDD(x);
  };
  const handleSelectProject = (e, index) => {
    const { name, value, checked } = e.target;
    if (e.target.value) {
      setProjectListSplitBoxError("");
      setProjectError1("");
    }

    let array = JSON.parse(JSON.stringify(selectedProjects));

    const element = employeeProjectsList.find((ele) => ele.projCode === value);

    let tempBillable = e.target.checked === true ? 1 : 0;

    if (e.target.name === "billable") {
      array[index].billable = tempBillable;
    } else if (e.target.name === "splitAmount") {
      array[index].splitAmount = Number(e.target.value.replaceAll(",", ""));
    }

    if (element) {
      array[index].projectName.projectID = e.target.value;
      array[index].projectName.projName = element?.projName;
      array[index].projectName.projId = element?.projId;
    }

    setSelectedItem(false);
    setSelectedProjects(array);
    // console.log(
    //   "vheck project",
    //   e,
    //   index,
    //   employeeProjectsList,
    //   selectedProjects,
    //   array
    // );

    // Assuming you have a handleProjectList function to update the parent component state with the selected projects
    handleProjectList(array);
  };

  function editDragDropFileValidationUniqueFiles(fileTypes, fileValues) {
    // console.log("INSIDE EDIT DUPLICATE", fileTypes, fileValues);
    if (fileValues?.length != 0 && fileTypes?.length != 0) {
      let uniqueValues1 = new Set(fileValues?.map((v) => v.FileName));
      let uniqueValues2 = new Set(fileTypes?.map((v) => v.filename));
      const haveCommonValue = [...uniqueValues1].some((value) =>
        uniqueValues2.has(value)
      );
      // console.log(
      //   "fileValues duplicates",
      //   fileValues,
      //   "fileTypes",
      //   fileTypes,
      //   uniqueValues1,
      //   uniqueValues2,
      //   haveCommonValue
      // );
      if (
        uniqueValues1.size < fileValues?.length ||
        uniqueValues2.size < fileTypes?.length ||
        haveCommonValue == true
      ) {
        //console.log("duplicates found1");
        return true;
      } else return false;
    } else if (fileValues?.length == 0 && fileTypes?.length != 0) {
      let uniqueValues2 = new Set(fileTypes?.map((v) => v.filename));
      if (uniqueValues2.size < fileTypes?.length) {
        //console.log("duplicates found2");
        return true;
      } else return false;
    } else if (fileValues?.length != 0 && fileTypes?.length == 0) {
      let uniqueValues1 = new Set(fileValues?.map((v) => v.FileName));
      if (uniqueValues1.size < fileTypes?.length) {
        //console.log("duplicates found3");
        return true;
      } else return false;
    }
  }
  function editDragDropFileValidationTotalSize(fileTypes, fileValues) {
    if (
      fileTypes?.reduce(
        (acc, curr) => (acc = acc + Number(curr.attachmentsize)),
        0
      ) +
        fileValues?.reduce((acc, curr) => (acc = acc + Number(curr.Size)), 0) >
      10485760
    ) {
      //console.log("size>10mb");
      return true;
    } else return false;
  }
  function dragDropFileValidationFileType(fileValues) {
    // console.log("fileValues: <<<<<<<<<<< ", fileValues[0].type.split("/")[1]);
    for (let i = 0; i < fileValues.length; i++) {
      if (
        fileValues[i].filename.split(".")[1] !== "pdf" &&
        fileValues[i].filename.split(".")[1] !== "png" &&
        fileValues[i].filename.split(".")[1] !== "jpg" &&
        fileValues[i].filename.split(".")[1] !== "jpeg" &&
        fileValues[i].filename.split(".")[1] !== "xlsx" &&
        fileValues[i].filename.split(".")[1] !== "xls" &&
        fileValues[i].filename.split(".")[1] !== "vnd.ms-excel" &&
        fileValues[i].filename.split(".")[1] !==
          "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        // //console.log(
        //   "fileValues2: <<<<<<<<<<< ",
        //   fileValues[i].type.split("/")[1]
        // );
        return true;
      }
    }
    return false;
  }

  function dragDropFileValidationUniqueFiles(fileValues) {
    // console.log("INSIDE CREATE DUPLICATE", fileValues);

    const uniqueValues = new Set(fileValues?.map((v) => v.filename));

    if (uniqueValues.size < fileValues.length) {
      //console.log("duplicates found");
      return true;
    } else return false;
  }
  function dragDropFileValidationTotalSize(fileValues) {
    if (
      fileValues?.reduce(
        (acc, curr) => (acc = acc + Number(curr.attachmentsize)),
        0
      ) > 10485760
    ) {
      //console.log("size>10mb");
      return true;
    } else return false;
  }
  function uploadFileNameSpecialCharacterValidation(fileValues) {
    // console.log("control in special character validation");
    let tempFileList = [];
    for (let i = 0; i < fileValues.length; i++) {
      tempFileList.push({ fileName: fileValues[i].filename });
    }
    if (typeof tempFileList !== "undefined" && tempFileList.length > 0) {
      var newArrayVal = [];

      for (var i = 0; i <= tempFileList.length; i++) {
        var fileCheck =
          tempFileList && tempFileList[i] !== undefined
            ? tempFileList[i].fileName
            : "";
        if (fileCheck?.substr(fileCheck?.length - 4) == (".png" || ".jpg")) {
          newArrayVal.push(fileCheck?.substring(0, fileCheck?.length - 4));
        } else if (
          fileCheck?.substr(fileCheck?.length - 5) == ".jpeg" ||
          ".xlsx"
        ) {
          newArrayVal.push(fileCheck?.substring(0, fileCheck?.length - 5));
        }
      }
      var format = /[`!@#$%^&*+\=\[\]{};':"\\|,.<>\/?~]/;
      var lname = newArrayVal?.map((obj) => {
        return format.test(obj);
      });
      if (lname && lname.includes(true)) {
        return true;
      } else return false;
    }
  }
  function uploadFileNameCharLengthValidation(fileValues) {
    //console.log("lkjdfs1", fileValues);
    let tempFileList = [];
    for (let i = 0; i < fileValues.length; i++) {
      tempFileList.push({ fileName: fileValues[i].filename });
    }
    if (typeof tempFileList !== "undefined" && tempFileList.length > 0) {
      //console.log("lkjdfs2", tempFileList);
      var newArrayVal = [];
      for (var i = 0; i <= tempFileList.length; i++) {
        var fileCheck =
          tempFileList && tempFileList[i] !== undefined
            ? tempFileList[i].fileName
            : "";
        //console.log("lkjdfs3", fileCheck);
        if (fileCheck?.substr(fileCheck?.length - 4) == (".png" || ".jpg")) {
          newArrayVal.push(fileCheck?.substring(0, fileCheck?.length - 4));
        } else if (
          fileCheck?.substr(fileCheck?.length - 5) == ".jpeg" ||
          ".xlsx"
        ) {
          newArrayVal.push(fileCheck?.substring(0, fileCheck?.length - 5));
        }
      }

      for (var i = 0; i <= newArrayVal.length; i++) {
        if (newArrayVal[i]?.length > 100) {
          return true;
        } else {
          return false;
        }
      }
    }
  }
  const handleDateChange = (type, date) => {
    setSelectDateError("");
    setSelectedDate(null);
    setValiDateDate(false);
    setValidateQuarter(false);
    setValidateJoiningDate(false);
    const presentDate = new Date();
    if (type < presentDate) {
      const difference = presentDate - type;
      let TotalDays = Math.ceil(difference / (1000 * 3600 * 24) - 1);
      const slectedDate = moment(date).format("l");
      if (
        moment(type).toDate() < moment(ssoEmpIdDetails?.empJoiningDate).toDate()
      ) {
        //console.log("ty1pe2 validDate",validateJoiningDate, moment(selectedDate).toDate() < moment(ssoEmpIdDetails?.empJoiningDate).toDate());
        setSelectedDate(slectedDate);
        setValidateJoiningDate(true);
        setSelectDateError(
          `Expense invoice date cannot be before joining date which is ${moment(
            ssoEmpIdDetails?.empJoiningDate
          ).format("l")}`
        );
      } else if (expenseCategory?.quaterlyBilling != 1) {
        if (TotalDays <= expenseCategory?.claimWindow) {
          setSelectedDate(slectedDate);
        } else {
          setSelectedDate(slectedDate);
          setValiDateDate(true);
          setSelectDateError("Days limit is expired for this Category");
        }
      } else if (expenseCategory?.quaterlyBilling == 1) {
        let lastDayOfMonth = new Date(
          type.getFullYear(),
          type.getMonth() + 1,
          0
        );

        let lastDayOfQuarter = getLastDayOfExpense(lastDayOfMonth, type);
        //console.log("lastDayOfQuarter", lastDayOfQuarter);
        if (presentDate >= lastDayOfQuarter) {
          //console.log("presentDate >= lastDayOfQuarter", presentDate);
          setSelectedDate(slectedDate);
          setValidateQuarter(true);
          setSelectDateError(
            `Last date to submit the expense was ${moment(
              lastDayOfQuarter.setDate(lastDayOfQuarter.getDate() - 1)
            ).format("l")}`
          );
        } else setSelectedDate(slectedDate);
      }
    }
  };
  //new changes 0001
  const handleFromDate = (type, date) => {
    setFromDate(null);
    setValidateToDate(false);
    setFromDateError("");
    setToDateError("");
    if (toDate != null) {
      const diffInDates = (toType - type) / (1000 * 60 * 60 * 24);
      const noOfMonths = Math.round(diffInDates / 30);
      //console.log("diff2", diffInDates, noOfMonths);
      setNoOfMon(noOfMonths);
    }
    let tempFromDate = moment(date).format("l");
    //console.log("8881", moment(tempFromDate).toDate(), type);
    setFromDate(tempFromDate);
    setFromType(type);
  };

  const handleToDate = (type, date) => {
    setToDate(null);
    setValidateToDate(false);
    setToDateError("");
    let tempToDate = moment(date).format("l");
    const diffInDates = (type - fromType) / (1000 * 60 * 60 * 24);
    const noOfMonths = Math.round(diffInDates / 30);
    //console.log("diff", diffInDates, noOfMonths);

    if (fromDate != null) {
      setToDate(tempToDate);
      setToType(type);
      setNoOfMon(noOfMonths);
    } else {
      setValidateToDate(true);
      setToDate(tempToDate);
      setToType(type);
      setToDateError("First enter From Date");
    }
  };

  // }
  const handleExpCategory = (e, index) => {
   
    const catid = CategoryList?.find(
      (item) => item?.categoryName === e.target.value
    )?.categoryId;
    const catcode = CategoryList?.find(
      (item) => item?.categoryName === e.target.value
    )?.categoryCode;
    if (index == 0) {
      setExpenseCategory(
        CategoryList?.find((item) => item.categoryName === e.target.value)
      );
    }
    // console.log("checking", index, e, CategoryList, catid, catcode);

    let array = JSON.parse(JSON.stringify(selectedCategory));
    array.map((item, i) => {
      if (i === index) {
        item.categoryId = catid;
        item.categoryName = e.target.value;
        item.categoryCode = catcode;
        item.editFlag = 1;
      }
    });
    const filterdCurrency = paymentList?.filter(
      (value) => value.selected === 1
    );
    if (filterdCurrency?.length > 0) {
      setPayment(filterdCurrency[0]);
    }
    if (filterdCurrency?.length > 0) {
      array.map((item, i) => {
        if (i == index) {
          item.paymentmethodid = filterdCurrency[0]?.methodId;
        }
      });
    }
    setSelectedCategory(array);
    const obj1= {
      expenseID: catid,
      empId:
        delEmpIDRed != null &&
        delEmpIDRed != "" &&
        delEmpIDRed != ssoEmpIdDetails?.empId
          ? delEmpIDRed
          : ssoEmpIdDetails?.empId,
    };
    // {console.log("obj1",obj1)}
    dispatch(getPaymentData(obj1));
  };
  //console.log("selected123" , selectedCategory)
  const handlePayment = (id, i) => {
    let array = JSON.parse(JSON.stringify(selectedCategory));
    let oldMethodId = selectedCategory[i].paymentmethodid;

    array.map((item, index) => {
      if (index == i) {
        item.paymentmethodid = id;
        item.editFlag = 1;
        if (id == 34) {
          let x = Number(actualsSelf);
          x = x + Number(selectedCategory[i].claimedamount);
          setActualsSelf(x);
        }
        if (id == 3) {
          let x = Number(actualsTravelCard);
          x = x + Number(selectedCategory[i].claimedamount);
          setActualsTravelCard(x);
        }
        if (id == 35) {
          let x = Number(actualsCash);
          x = x + Number(selectedCategory[i].claimedamount);
          setActualsCash(x);
        }
      }
    });
    if (oldMethodId == 34) {
      let x = Number(actualsSelf);
      x = x - Number(selectedCategory[i].claimedamount);
      setActualsSelf(x);
    }
    if (oldMethodId == 3) {
      let x = Number(actualsTravelCard);
      x = x - Number(selectedCategory[i].claimedamount);
      setActualsTravelCard(x);
    }
    if (oldMethodId == 35) {
      let x = Number(actualsCash);
      x = x - Number(selectedCategory[i].claimedamount);
      setActualsCash(x);
    }
    setSelectedCategory(array);
  };

  const handleCurrency = (id, i) => {
    let array = JSON.parse(JSON.stringify(selectedCategory));
    array = array.map((item, index) => {
      return {
        ...item,
        currencyId: id.currencyId,
        editFlag: 1,
        // currencyId: currencyObject.currencyId,
        // currencyId: selectedCurrency.currencyId,
      };
    });
    setSelectedCategory(array);
    setCurrency(id);
  };


  const handleBillAmount = (value, i) => {
    //console.log("checking bill amount", value, i);
    validationArray[i].billAmtMsg = "";
    let array = JSON.parse(JSON.stringify(selectedCategory));
    if (selectedCategory[i].claimedamount != "") {
      if (
        selectedCategory[i].claimedamount >
        DOMPurify.sanitize(Number(value?.replaceAll(",", "")))
      ) {
        validationArray[i].claimAmtMsg =
          "Claim Amount is greater than Bill Amount";
      } else {
        validationArray[i].claimAmtMsg = "";
      }
    }
    array.map((item, index) => {
      if (index == i) {
        item.billamount = DOMPurify.sanitize(Number(value.replaceAll(",", "")));
        item.editFlag = 1;
      }
    });
    setSelectedCategory(array);
  };
  const handlePadding = (matches) => {
    if (selectedItem) {
      return {
        paddingLeft: matches ? "11px" : "",
        paddingTop: matches ? "6px" : "",
        paddingRight: matches ? "32px" : "",
        paddingBottom: matches ? "7px" : "",
      };
    }
    return {};
  };
  const handleClaimAmount1 = (value, i) => {
    setSplitClaimAmount(Number(value.replaceAll(",", "")));
    const val1 = selectedCategory[i]?.billamount.toString();
    const val2 = Number(val1.replaceAll(",", ""));
    const val = Number(value.replaceAll(",", ""));
    let array = JSON.parse(JSON.stringify(selectedCategory));
    if (val2 < val) {
      validationArray[i].claimAmtMsg =
        "Claim Amount is greater than Bill Amount";
    } else {
      validationArray[i].claimAmtMsg = "";
    }
    array.map((item, index) => {
      if (index === i) {
        item.claimedamount =  DOMPurify.sanitize(Number(value.replaceAll(",", "")));
        item.editFlag = 1;
        let old = selectedCategory[i].claimedamount;

        if (item.paymentmethodid == 34) {
          let x = Number(actualsSelf);
          x = x - old + val;
          setActualsSelf(x);
        }
        if (item.paymentmethodid == 3) {
          let x = Number(actualsTravelCard);
          x = x - old + val;
          setActualsTravelCard(x);
        }
        if (item.paymentmethodid == 35) {
          let x = Number(actualsCash);
          x = x - old + val;
          setActualsCash(x);
        }
      }
    });
  
    setSelectedCategory(array);
    let old1 = selectedCategory[i]?.claimedamount.toString();
    const oldClaimedAmount = Number(old1.replaceAll(",", ""));
    const newClaimedAmount = Number(value.replaceAll(",", ""));
    const newTotalClaimAmount = Number(
      totalClaimAmount - oldClaimedAmount + newClaimedAmount
    ).toFixed(2);
    setTotalClaimAmount(newTotalClaimAmount);
  };

useEffect(() => {
  const updatedFileDisplayForDD = [];
        if(fileDisplayForDD.length == 0){
          myExpenseList?.ExpenseDetail?.map((item, index) => {
            updatedFileDisplayForDD[index] = item.ExpenseAttachments.map(
              (file) => ({
                name: file.FileName,
                size: file.Size,
                AttachmentId: file.AttachmentId,
              })
            );
          }
          )
        }
        // console.log("pudagshd" , updatedFileDisplayForDD, myExpenseList?.ExpenseDetail, fileDisplayForDD);
        if(updatedFileDisplayForDD != []){
          setFileDisplayForDD(updatedFileDisplayForDD);
        }
      let arr = [];
      myExpenseList?.ExpenseDetail?.map((item, index) => {
        arr.push(item.ExpenseAttachments);
      });
     handleEditDeleteValidateFile(arr, -1);
    
}, [myExpenseList])



  const handleFileList1 = (fileList1, index) => {
   
    if (fileList1?.length > 0 ) {
      const updatedCategories = JSON.parse(JSON.stringify(selectedCategory));
      validationArray[index].attachmentMsg = "";
      let lastCategory = updatedCategories[index];
      const attachmentsToAdd = fileList1.map((file) => ({
        filename: file.name,
        attachmentsize: file.size,
      }));
      // console.log("bhujinkl", lastCategory, attachmentsToAdd);
      //Append attachmentsToAdd to lastCategory.expenseattachements
      if (
        lastCategory.expenseattachements[0]?.filename == "" ||
        lastCategory?.expenseattachements?.length == 0
      ) {
        updatedCategories[index].expenseattachements = attachmentsToAdd;
      } else {
        updatedCategories[index].expenseattachements =
          lastCategory.expenseattachements.concat(attachmentsToAdd);
      }
      updatedCategories[index].editFlag = 1;
      setSelectedCategory(updatedCategories);
      setFileList(fileList1);
      setFinalFileList(finalFileList.concat(fileList1));


      const updatedFileDisplayForDD = [...fileDisplayForDD];
      if (updatedFileDisplayForDD[index]) {
        // If an array already exists, push fileList1 into it
          updatedFileDisplayForDD[index] = [...updatedFileDisplayForDD[index], ...fileList1];
      } 
      // console.log("ljfdugke" , updatedFileDisplayForDD)
      setFileDisplayForDD(updatedFileDisplayForDD);
    }
  };

  const [IndexForDate, setIndexForDate] = useState(0);

  const handleReceiptDate = (date, index, cat) => {
    if (isValid(date)) {
      const presentDate = new Date();
      let validationError = "";
      let array = [...selectedCategory];
      let catArray = CategoryList.find(
        (item) => item.categoryName == cat.categoryName
      );
      if (!date) {
        validationError = "Please fill in the invoice date";
      } else if (date < presentDate) {
        const difference = presentDate - date;
        const totalDays = Math.ceil(difference / (1000 * 3600 * 24) - 1);
        const selectedDate = moment(date).format("l");

        if (
          moment(date).toDate() <
          moment(ssoEmpIdDetails?.empJoiningDate).toDate()
        ) {
          array[index] = { ...array[index], receiptdate: selectedDate };
          setValidateJoiningDate(true);
          validationError = `Expense invoice date cannot be before joining date which is ${moment(
            ssoEmpIdDetails?.empJoiningDate
          ).format("l")}`;
        } else if (catArray?.quaterlyBilling !== 1) {
          if (
            location?.state?.compName == "edit" &&
            location?.state?.response?.status == "Rejected"
          ) {
            if (
              totalDays <=
              catArray?.claimWindow + Number(process.env.REACT_APP_GRACE_PERIOD)
            ) {
              array[index] = { ...array[index], receiptdate: selectedDate };
              validationError = "";
            } else {
              array[index] = { ...array[index], receiptdate: selectedDate };
              setValiDateDate(true);
              setSelectDateError("Days limit is expired for this Category");
              validationError = "Days limit is expired for this Category";
            }
          } else {
            if (totalDays <= catArray?.claimWindow) {
              array[index] = { ...array[index], receiptdate: selectedDate };
              validationError = "";
            } else {
              array[index] = { ...array[index], receiptdate: selectedDate };
              setValiDateDate(true);
              setSelectDateError("Days limit is expired for this Category");
              validationError = "Days limit is expired for this Category";
            }
          }
        } else if (catArray?.quaterlyBilling === 1) {
          const lastDayOfMonth = new Date(
            date.getFullYear(),
            date.getMonth() + 1,
            0
          );

          const lastDayOfQuarter = getLastDayOfExpense(lastDayOfMonth, date);
          if (
            location?.state?.compName == "edit" &&
            location?.state?.response?.status == "Rejected"
          ) {
            if (
              presentDate >=
              lastDayOfQuarter.addDays(
                Number(process.env.REACT_APP_GRACE_PERIOD)
              )
            ) {
              setValidateQuarter(true);
              validationError = `Last date to submit the expense was ${moment(
                lastDayOfQuarter.setDate(lastDayOfQuarter.getDate() - 1)
              ).format("l")}`;
            } else {
              array[index] = { ...array[index], receiptdate: selectedDate };
              validationError = "";
            }
          } else if (presentDate >= lastDayOfQuarter) {
            validationError = `Last date to submit the expense was ${moment(
              lastDayOfQuarter.setDate(lastDayOfQuarter.getDate() - 1)
            ).format("l")}`;
          }
        }
      }

      const newArr = [...validationArray];
      newArr.map((item, i) => {
        if (i == index) {
          item.recieptMsg = validationError;
        }
      });
      setValidationArray(newArr);
      setSelectedCategory(array);
    } else {
      const newArr = [...validationArray];
      newArr.map((item, i) => {
        if (i == index) {
          item.recieptMsg = "*Invalid date format.";
        }
      });
      setValidationArray(newArr);
    }
  };

  const handleAttendees = (value) => {
    setAttendeesError("");
    setAttendees(value);
  };
  const handlepurpose = (value) => {
    if (value.purpose != "Travel") {
      const text = "/editExpenseDetails/:id";
      const result = text.replace(":id", location?.state?.response?.expId);
      history.push({
        pathname: result,
        state: {
          response: location?.state?.response,
          empId: location?.state?.empId,
          compName: "editTravel",
          purpose: value.purpose,
        },
      });
    }
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  let checkErrorInside = false;

  const handleClick = () => {
    if (purpose.purpose == "Travel") {
      if (!selectedProjects[0]?.projectName?.projectID) {
        setProjectError1("*Please select the Project");
        // return; // Stop the submission if there's an error
      } else {
        setProjectError1("");
      }
      if (expenseName == "") {
        setExpenseNameError("*Please enter expense name.");
      } else {
        setExpenseNameError("");
      }
      var key = "E546C8DF278CD5931069B522E695D4F2";
      const result = Array.map((feild) =>
        FieldValidator(
          feild.validationType,
          feild.value,
          feild.claim,
          feild.validateList,
          feild.validateList2,
          feild.noOfMonths,
          feild.validateJoiningDate
        )
      );
      let arr = result.map((i) => i.bool);
      let checker = arr.every((element) => element === true);
      let boxMsg = result?.find(
        (item) => item.type == "Declaration CheckBox"
      )?.bool;
      //console.log("check validation",selectedCategory);
      // console.log("card cash total" , travelCardBalance , cashBalance , travelCardAmount , cash)

      setValidationMsg(result);
      if (travelDestination == "") {
        setTravelDestinationMsg("*Please enter travel destination.");
      } else {
        setTravelDestinationMsg("");
      }
      if (travelStartDate == null) {
        setTravelstdtMsg("*Please enter start date");
      } else {
        setTravelstdtMsg("");
      }
      if (travelEndDate == null) {
        setTravelendtMsg("*Please enter travel end date.");
      } else {
        setTravelendtMsg("");
      }
      if (travelDetailType == "") {
        setTravelTypeMsg("*Please select travel type");
      } else {
        setTravelTypeMsg("");
      }
      selectedCategory.forEach((item, index) => {
        let arr = [...validationArray];
        arr[index].categoryMsg =
          item.categoryId === "" ? "*Please enter category" : "";
        arr[index].recieptMsg = !item.receiptdate
          ? "*Please select Invoice date"
          : "";
        arr[index].paymentMsg =
          item.paymentmethodid === "" ? "*Please select Payment Method" : "";
        arr[index].billAmtMsg =
          item.billamount === "" ? "*Please enter Bill Amount" : "";
        arr[index].claimAmtMsg =
          item.claimedamount === "" || item.claimedamount == 0  ? "*Please enter Claim Amount" : item.claimedamount > item?.billamount ?"Claim Amount is greater than Bill Amount" :  "";
        arr[index].attachmentMsg = getAttachmentMessage(item, index);
        setValidationArray(arr);
      });
      function getAttachmentMessage(item, index) {
        // console.log("chcek", item, editValidateFileList);
        if (
          item.expid == 0 &&
          (!item.expenseattachements.length ||
            item.expenseattachements[0]?.filename === "")
        ) {
          return "*Please attach at least one receipt";
        }
        if (
          item.expid != 0 &&
          editDragDropFileValidationUniqueFiles(
            item.expenseattachements,
            editValidateFileList[index]
          )
        ) {
          return "*Duplicate files are not allowed";
        }
        if (
          item.expid != 0 &&
          editDragDropFileValidationTotalSize(
            item.expenseattachements,
            editValidateFileList[index]
          )
        ) {
          return "*Total size of attached reciepts has exceeded 10 mb. Please remove some files, or add compressed files.";
        }
        if (
          item.expid != 0 &&
          (!item.expenseattachements.length ||
            item.expenseattachements[0]?.filename === "") &&
          editValidateFileList[index]?.length == 0
        ) {
          return "*Please attach at least one receipt";
        }
        if (
          uploadFileNameSpecialCharacterValidation(item.expenseattachements)
        ) {
          return "*Please enter a valid file name (no special characters allowed other than (,),-,_ )";
        }
        if (uploadFileNameCharLengthValidation(item.expenseattachements)) {
          return "*Please enter a valid file name (file name cannot be greater than 100 characters)";
        }
        if (dragDropFileValidationFileType(item.expenseattachements)) {
          return "*Only JPEG, JPG, XLS, XLSX, PNG and PDF allowed";
        }
        if (dragDropFileValidationUniqueFiles(item.expenseattachements)) {
          return "*Duplicate files are not allowed";
        }
        if (dragDropFileValidationTotalSize(item.expenseattachements)) {
          return "*Total size of attached receipts has exceeded 10 mb. Please remove some files, or add compressed files.";
        }
        return "";
      }
      const filterAttendees = [];
      let fileList1 = [];
      const FilterdeleteFileList = [];
      if (deleteFileList?.length > 0) {
        deleteFileList?.forEach((value) =>
          FilterdeleteFileList.push({
            attachmentid: value.AttachmentId,
            updatedFileName: value.UpdatedFileName,
          })
        );
      }

      const filterproject = [];
      if (selectedProjects?.length > 0) {
        selectedProjects?.map((value) =>
          filterproject.push({
            projid: Encrypt(key, JSON.stringify(value.projectName.projId)),
            projamount: value.splitAmount || 0,
            billable: Encrypt(key, JSON.stringify(value.billable)),
          })
        );
      }
      let checkError = false;
      //let checkErrorInside = false;
      if (
        projectError1 != "" ||
        expenseNameError != "" ||
        expenseName == "" ||
        travelDestination == "" ||
        travelStartDate == null ||
        travelEndDate == null ||
        travelDetailType == ""
      ) {
        setCheckErrEntries(true);
        checkErrorInside = true;
      }

      validationArray.forEach((item, index) => {
        if (checkError) {
          return;
        }
        const errorTypes = [
          "attachmentMsg",
          "billAmtMsg",
          "categoryMsg",
          "claimAmtMsg",
          "currencyMsg",
          "paymentMsg",
          "recieptMsg",
        ];

        const hasError = errorTypes.some(
          (errorType) => item?.[errorType] != ""
        );
        if (hasError) {
          setCheckCategoryError(index);
          checkError = true;
          return;
        }
      });
      //console.log("error" , checkError, checkCategoryError,checkErrorInside);
      let expenses = [...selectedCategory];

      let updatedExpenses = expenses
        .filter(
          (expense, index) =>
            expense.editFlag !== 0 || index == 0 || expense.expid == ""||expense.expid == 0
        )
        .map(({ editFlag, ...rest }) => rest);

      updatedExpenses.forEach((item) => {
        // console.log("poiuytrewsdfg", typeof(item.expid))
        try {
          const decryptedExpId = Decrypt(key, item.expid); // Assuming Decrypt is a function to decrypt the value
          const parsedExpId = JSON.parse(decryptedExpId);
          // If parsing succeeds, it means it was encrypted before, so skip encryption
          return;
        } catch (error) {
          // If parsing fails, it means it's not encrypted, so encrypt it
          item.expid = Encrypt(key, JSON.stringify(item.expid));
        }
        //item.expid = Encrypt(key, JSON.stringify(item.expid));
      });
      let updatedDeleteExpData = deleteExpData.filter(item => item.expid != 0);
      updatedDeleteExpData.forEach((item) => {
        //item.expid = Encrypt(key, JSON.stringify(item.expid));
        try {
          const decryptedExpId = Decrypt(key, item.expid); // Assuming Decrypt is a function to decrypt the value
          const parsedExpId = JSON.parse(decryptedExpId);
          // If parsing succeeds, it means it was encrypted before, so skip encryption
          return;
        } catch (error) {
          // If parsing fails, it means it's not encrypted, so encrypt it
          item.expid = Encrypt(key, JSON.stringify(item.expid));
        }
      });
      setDeleteExpData(updatedDeleteExpData);

      // console.log(updatedExpenses);

      let formData = new FormData();
      let currencyVal = currency.currencyId;
      formData.append("ExpName", expenseName);
      formData.append("PurposeId", purpose.purposeId);
      formData.append("Purpose", purpose.purpose);
      formData.append("booksqty", 0);

      formData.append("CategoryCode", updatedExpenses[0]?.categoryCode);
      formData.append("CategoryId", updatedExpenses[0]?.categoryId);
      formData.append("CategoryName", updatedExpenses[0]?.categoryName);
      // console.log("update expenses" , expenseCategory)
      if (expenseCategory.autoApproval == 0) {
        formData.append("exceptionapplicationflag", 0);
        formData.append("autoapprovalflag", 0);
      } else if (
        expenseCategory.autoApproval == 1 &&
        claimAmount > expenseCategory?.policyLimit
      ) {
        formData.append("exceptionapplicationflag", 1);
        formData.append("autoapprovalflag", 0);
      } else if (
        expenseCategory.autoApproval == 1 &&
        claimAmount <= expenseCategory?.policyLimit
      ) {
        formData.append("exceptionapplicationflag", 0);
        formData.append("autoapprovalflag", 1);
      }
      formData.append(
        "BillNumber",
        billNumber ? Encrypt(key, JSON.stringify(billNumber)) : null
      );
      formData.append(
        "VendorName",
        vendorName ? Encrypt(key, JSON.stringify(vendorName)) : null
      );
      formData.append(
        "GSTNumber",
        gst ? Encrypt(key, JSON.stringify(gst)) : null
      );
      formData.append("ExpStatus", "In Progress");
      formData.append("CurrencyId", currencyVal);
      formData.append("BillAmount", updatedExpenses[0]?.billamount);
      formData.append("ClaimedAmount", updatedExpenses[0]?.claimedamount);
      formData.append("BillingCycleToDate", toDate || "");
      formData.append("BillingCycleMonth", forMonth);
      formData.append("FromLocation", fromLocation);
      formData.append("ToLocation", toLocation);
      formData.append("Distance", inBetweenDistance || 0);
      formData.append("PaymentMethodId", updatedExpenses[0]?.paymentmethodid);
      formData.append("ReceiptDate", updatedExpenses[0]?.receiptdate);
      formData.append(
        "EmpEntityId",
        delEmpEntityId != null ? delEmpEntityId : ssoEmpIdDetails?.entityId
      );
      formData.append(
        "ExpId",
        Encrypt(key, JSON.stringify(location?.state?.response?.expId))
      );
      formData.append("DeleteExpenses", JSON.stringify(updatedDeleteExpData));
      // console.log("expid" ,location?.state?.response?.expId )

      formData.append(
        "UpdatedBy",
        Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
      );
      formData.append(
        "EmpId",
        delEmpIDRed != null
          ? Encrypt(key, JSON.stringify(delEmpIDRed))
          : Encrypt(key, JSON.stringify(location?.state?.ssoEmpId))
      );
      formData.append(
        "deleteAttachments",
        JSON.stringify(FilterdeleteFileList)
      );
      formData.append("DependentId", 0);

      formData.append("expenseProjLinks", JSON.stringify(filterproject));
      formData.append(
        "expenseEmpLinks",
        JSON.stringify(filterAttendees ? filterAttendees : [])
      );
      let tempFileList = updatedExpenses[0]?.expenseattachements;
      formData.append("files", JSON.stringify(tempFileList));
      formData.append("traveltype", travelDetailType);

      {
        updatedExpenses.map((item) => {
          if (item.currencyId == "") {
            item.currencyId = currencyVal;
          }
        });
      }
      if (updatedExpenses?.length == 1) {
        formData.append("childExpenses", JSON.stringify([]));
      } else {
        let arr123 = [...updatedExpenses];
        arr123.splice(0, 1);
        formData.append("childExpenses", JSON.stringify(arr123));
      }
      formData.append(
        "expenseAdvancesLists",
        JSON.stringify(travelDetailType == "Domestic" ? [] : advancesList)
      );
      formData.append(
        "expenseAdvancesDetails",
        JSON.stringify(advancesJson[0])
      );

      if (!checkError && boxMsg && !checkErrorInside) {
        try {
          const response = dispatch(postEditExpenseData(formData));
          handleCloseEDB();
          setDisableSubmit(true);

          if (response.status == 200) {
            history.push("/expense-Management");
          }
        } catch {}
      } else {
        setStatusSnackbarOpen(true);
        setDisableSubmit(true);
      }
      // console.log("control is before dispatch1", formData);
    }
    // travel expense form submission ends here
  };

  const handleSave = () => {
    // all the code from here is for travel expense form submission
    if (purpose.purpose == "Travel") {
      if (!selectedProjects[0]?.projectName?.projectID) {
        setProjectError1("*Please select the Project");
        // return; // Stop the submission if there's an error
      } else {
        setProjectError1("");
      }
      if (expenseName == "") {
        setExpenseNameError("*Please enter expense name.");
      } else {
        setExpenseNameError("");
      }
      var key = "E546C8DF278CD5931069B522E695D4F2";
      const result = Array.map((feild) =>
        FieldValidator(
          feild.validationType,
          feild.value,
          feild.claim,
          feild.validateList,
          feild.validateList2,
          feild.noOfMonths,
          feild.validateJoiningDate
        )
      );
      let arr = result.map((i) => i.bool);
      let checker = arr.every((element) => element === true);
      let boxMsg = result?.find(
        (item) => item.type == "Declaration CheckBox"
      )?.bool;
      //console.log("check validation",selectedCategory);
      // console.log("card cash total" , travelCardBalance , cashBalance , travelCardAmount , cash)

      setValidationMsg(result);
      if (travelDestination == "") {
        setTravelDestinationMsg("*Please enter travel destination.");
      } else {
        setTravelDestinationMsg("");
      }
      if (travelStartDate == null) {
        setTravelstdtMsg("*Please enter start date");
      } else {
        setTravelstdtMsg("");
      }
      if (travelEndDate == null) {
        setTravelendtMsg("*Please enter travel end date.");
      } else {
        setTravelendtMsg("");
      }
      if (travelDetailType == "") {
        setTravelTypeMsg("*Please select travel type");
      } else {
        setTravelTypeMsg("");
      }
      selectedCategory.forEach((item, index) => {
        let arr = [...validationArray];
        arr[index].categoryMsg =
          item.categoryId === "" ? "*Please enter category" : "";
        arr[index].recieptMsg = !item.receiptdate
          ? "*Please select Invoice date"
          : "";
        arr[index].paymentMsg =
          item.paymentmethodid === "" ? "*Please select Payment Method" : "";
        arr[index].billAmtMsg =
          item.billamount === "" ? "*Please enter Bill Amount" : "";
        arr[index].claimAmtMsg =
        item.claimedamount === "" || item.claimedamount == 0  ? "*Please enter Claim Amount" : item.claimedamount > item?.billamount ?"Claim Amount is greater than Bill Amount" :  "";
       
        arr[index].attachmentMsg = getAttachmentMessage(item, index);
        setValidationArray(arr);
      });
      function getAttachmentMessage(item, index) {
        if (
          item.expid == 0 &&
          (!item.expenseattachements.length ||
            item.expenseattachements[0]?.filename === "")
        ) {
          return "*Please attach at least one receipt";
        }
        if (
          item.expid != 0 &&
          editDragDropFileValidationUniqueFiles(
            item.expenseattachements,
            editValidateFileList[index]
          )
        ) {
          return "*Duplicate files are not allowed";
        }
        if (
          item.expid != 0 &&
          editDragDropFileValidationTotalSize(
            item.expenseattachements,
            editValidateFileList[index]
          )
        ) {
          return "*Total size of attached reciepts has exceeded 10 mb. Please remove some files, or add compressed files.";
        }
        if (
          item.expid != 0 &&
          (!item.expenseattachements.length ||
            item.expenseattachements[0]?.filename === "") &&
          editValidateFileList[index]?.length == 0
        ) {
          return "*Please attach at least one receipt";
        }
        if (
          uploadFileNameSpecialCharacterValidation(item.expenseattachements)
        ) {
          return "*Please enter a valid file name (no special characters allowed other than (,),-,_ )";
        }
        if (uploadFileNameCharLengthValidation(item.expenseattachements)) {
          return "*Please enter a valid file name (file name cannot be greater than 100 characters)";
        }
        if (dragDropFileValidationFileType(item.expenseattachements)) {
          return "*Only JPEG, JPG, XLS, XLSX, PNG and PDF allowed";
        }
        if (dragDropFileValidationUniqueFiles(item.expenseattachements)) {
          return "*Duplicate files are not allowed";
        }
        if (dragDropFileValidationTotalSize(item.expenseattachements)) {
          return "*Total size of attached receipts has exceeded 10 mb. Please remove some files, or add compressed files.";
        }
        return "";
      }
      const filterAttendees = [];
      let fileList1 = [];
      const FilterdeleteFileList = [];
      if (deleteFileList?.length > 0) {
        deleteFileList?.forEach((value) =>
          FilterdeleteFileList.push({
            attachmentid: value.AttachmentId,
            updatedFileName: value.UpdatedFileName,
          })
        );
      }

      const filterproject = [];
      if (selectedProjects?.length > 0) {
        selectedProjects?.map((value) =>
          filterproject.push({
            projid: Encrypt(key, JSON.stringify(value.projectName.projId)),
            projamount: value.splitAmount || 0,
            billable: Encrypt(key, JSON.stringify(value.billable)),
          })
        );
      }

      let checkError = false;
      checkErrorInside = false;

      if (
        projectError1 != "" ||
        expenseNameError != "" ||
        expenseName == "" ||
        travelDestination == "" ||
        travelStartDate == null ||
        travelEndDate == null ||
        travelDetailType == ""
      ) {
        setCheckErrEntries(true);
        checkErrorInside = true;
      }

      validationArray.forEach((item, index) => {
        if (checkError) {
          return;
        }
        const errorTypes = [
          "attachmentMsg",
          "billAmtMsg",
          "categoryMsg",
          "claimAmtMsg",
          "currencyMsg",
          "paymentMsg",
          "recieptMsg",
        ];

        const hasError = errorTypes.some(
          (errorType) => item?.[errorType] != ""
        );
        if (hasError) {
          setCheckCategoryError(index);
          checkError = true;
          return;
        }
      });

      let expenses = [...selectedCategory];

      let updatedExpenses = expenses
        .filter(
          (expense, index) =>
            expense.editFlag !== 0 || index == 0 || expense.expid == 0 || expense.expid == ""
        )
        .map(({ editFlag, ...rest }) => rest);
      updatedExpenses.forEach((item) => {
        try {
          const decryptedExpId = Decrypt(key, item.expid); // Assuming Decrypt is a function to decrypt the value
          const parsedExpId = JSON.parse(decryptedExpId);
          return;
        } catch (error) {
          // If parsing fails, it means it's not encrypted, so encrypt it
          item.expid = Encrypt(key, JSON.stringify(item.expid));
        }
      });
      let updatedDeleteExpData = deleteExpData.filter(item => item.expid != 0);

      updatedDeleteExpData.forEach((item) => {
        // console.log("lkjiol" , item);
        try {
          const decryptedExpId = Decrypt(key, item.expid); // Assuming Decrypt is a function to decrypt the value
          const parsedExpId = JSON.parse(decryptedExpId);
        // console.log("lkjiol inside decrypt" , item);

          // If parsing succeeds, it means it was encrypted before, so skip encryption
          return;
        } catch (error) {
        // console.log("lkjiol inside encrypt" , item);

          // If parsing fails, it means it's not encrypted, so encrypt it
          item.expid = Encrypt(key, JSON.stringify(item.expid));
        }
      });
      setDeleteExpData(updatedDeleteExpData);
      let formData = new FormData();
      let currencyVal = currency.currencyId;
      formData.append("ExpName", expenseName);
      formData.append("PurposeId", purpose.purposeId);
      formData.append("Purpose", purpose.purpose);
      formData.append("booksqty", 0);

      formData.append("CategoryCode", updatedExpenses[0]?.categoryCode);
      formData.append("CategoryId", updatedExpenses[0]?.categoryId);
      formData.append("CategoryName", updatedExpenses[0]?.categoryName);
      if (expenseCategory.autoApproval == 0) {
        formData.append("exceptionapplicationflag", 0);
        formData.append("autoapprovalflag", 0);
      } else if (
        expenseCategory.autoApproval == 1 &&
        claimAmount > expenseCategory?.policyLimit
      ) {
        formData.append("exceptionapplicationflag", 1);
        formData.append("autoapprovalflag", 0);
      } else if (
        expenseCategory.autoApproval == 1 &&
        claimAmount <= expenseCategory?.policyLimit
      ) {
        formData.append("exceptionapplicationflag", 0);
        formData.append("autoapprovalflag", 1);
      }
      formData.append(
        "BillNumber",
        billNumber ? Encrypt(key, JSON.stringify(billNumber)) : null
      );
      formData.append(
        "VendorName",
        vendorName ? Encrypt(key, JSON.stringify(vendorName)) : null
      );
      formData.append(
        "GSTNumber",
        gst ? Encrypt(key, JSON.stringify(gst)) : null
      );
      formData.append("ExpStatus", "Draft");
      formData.append("CurrencyId", currencyVal);
      formData.append("BillAmount", updatedExpenses[0]?.billamount);
      formData.append("ClaimedAmount", updatedExpenses[0]?.claimedamount);
      formData.append("BillingCycleToDate", toDate || "");
      formData.append("BillingCycleMonth", forMonth);
      formData.append("FromLocation", fromLocation);
      formData.append("ToLocation", toLocation);
      formData.append("Distance", inBetweenDistance || 0);
      formData.append("PaymentMethodId", updatedExpenses[0]?.paymentmethodid);
      formData.append("ReceiptDate", updatedExpenses[0]?.receiptdate);
      formData.append(
        "EmpEntityId",
        delEmpEntityId != null ? delEmpEntityId : ssoEmpIdDetails?.entityId
      );
      formData.append(
        "ExpId",
        Encrypt(key, JSON.stringify(location?.state?.response?.expId))
      );
      formData.append("DeleteExpenses", JSON.stringify(updatedDeleteExpData));
      formData.append(
        "UpdatedBy",
        Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
      );
      formData.append(
        "EmpId",
        delEmpIDRed != null
          ? Encrypt(key, JSON.stringify(delEmpIDRed))
          : Encrypt(key, JSON.stringify(location?.state?.ssoEmpId))
      );
      formData.append(
        "deleteAttachments",
        JSON.stringify(FilterdeleteFileList)
      );
      formData.append("DependentId", 0);

      formData.append("expenseProjLinks", JSON.stringify(filterproject));
      formData.append(
        "expenseEmpLinks",
        JSON.stringify(filterAttendees ? filterAttendees : [])
      );
      let tempFileList = updatedExpenses[0]?.expenseattachements;
      formData.append("files", JSON.stringify(tempFileList));
      formData.append("traveltype", travelDetailType);

      {
        updatedExpenses.map((item) => {
          if (item.currencyId == "") {
            item.currencyId = currencyVal;
          }
        });
      }
      if (updatedExpenses?.length == 1) {
        formData.append("childExpenses", JSON.stringify([]));
      } else {
        let arr123 = [...updatedExpenses];
        arr123.splice(0, 1);
        formData.append("childExpenses", JSON.stringify(arr123));
      }
      formData.append(
        "expenseAdvancesLists",
        JSON.stringify(travelDetailType == "Domestic" ? [] : advancesList)
      );
      formData.append(
        "expenseAdvancesDetails",
        JSON.stringify(advancesJson[0])
      );
      // console.log("vjbgsmad" , checkErrorInside , boxMsg,checkError )

      if (!checkError && boxMsg && !checkErrorInside) {
        try {
          const response = dispatch(postEditExpenseData(formData));
          handleCloseEDB();
          setDisableSubmit(true);

          if (response.status == 200) {
            history.push("/expense-Management");
          }
        } catch {}
      } else {
        setStatusSnackbarOpen(true);
        setDisableSubmit(true);
      }
      // console.log("control is before dispatch1", formData);
    }
    // travel expense form submission ends here
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  const handleOpenToolTip = () => {
    setOpenToolTip(true);
  };
  const handleCloseToolTip = () => {
    setOpenToolTip(false);
  };
  const openPopOver = Boolean(anchorEl);
  function truncateString(str, num) {
    if (str?.length <= num) {
      return str;
    }
    return str?.slice(0, num) + "...";
  }
  const handleClaimAmount = (value) => {
    //console.log("value in handleClaimAmount :====> ", value);
    setSplitClaimAmount(Number(value.replaceAll(",", "")));
    setClaimAmount(Number(value.replaceAll(",", "")));
    if (parseInt(Number(value.replaceAll(",", ""))) > parseInt(billAmount)) {
      setClaimAmountError("Claim Amount is greater than Bill Amount");
    }
  };
  const projectData = (e) => {
    if (
      e.target.value.categoryId == 1 ||
      e.target.value.categoryId == 56 ||
      expenseCategory?.categoryId == 1 ||
      expenseCategory?.categoryId == 56 ||
      oldExpId == 1 ||
      oldExpId == 56
    ) {
      // console.log(
      //   "2 inside the projectData delEmpIDRed",
      //   delEmpIDRed,
      //   e.target.value.categoryId
      // );
      if (
        delEmpIDRed != null &&
        delEmpIDRed != "" &&
        delEmpIDRed != ssoEmpIdDetails?.empId
      ) {
        const obj = { empId: delEmpIDRed, catId: e.target.value.categoryId };
        //dispatch(getEmployeeProjectsData(obj));
        setCountExpCatClick(countExpCatClick + 1);
        setExpenseCategoryChanged(true);
        setToggleProjectListDispatch(true);
      } else if (ssoEmpIdDetails != null) {
        // console.log(
        //   "3 inside the projectData delEmpIDRed",
        //   delEmpIDRed,
        //   e.target.value.categoryId
        // );
        const obj = {
          empId: ssoEmpIdDetails.empId,
          catId: e.target.value.categoryId,
        };
        dispatch(getDependentsData(obj.catId));
        //dispatch(getEmployeeProjectsData(obj));
        setCountExpCatClick(countExpCatClick + 1);
        setExpenseCategoryChanged(true);
        setToggleProjectListDispatch(true);
      }
    }

    // console.log("employeeProjectsList", employeeProjectsList);
    // console.log("countExpCatClick1", countExpCatClick);
  };
  const preventMinus = (e) => {
    if (e.code === "Minus" || e.key === "-") {
      e.preventDefault();
    }
  };
  const preventMinus1 = (e) => {
    if (e.code === "Minus" || e.key === "-" || e.key == ".") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (
      employeeProjectsList != null &&
      employeeProjectsList != undefined &&
      countExpCatClick != 0
    ) {
      //console.log("inedit 4444");
      setSelectedProjects([
        {
          projectName: { projectID: "", projName: "", projId: "" },
          splitAmount: claimAmount ? claimAmount : "",
          billable: 0,
        },
      ]);
    }
  }, [employeeProjectsList, countExpCatClick]);

  //console.log("expnscat 1", expenseCategory);
  const matches = useMediaQuery("(max-width:600px)");

  const gridCond = (index) => {
    return (
      <Grid
        container
        // spacing={2}
      >
        {" "}
        <Grid item xs={6} sm={4} md={4} style={{ paddingRight: "0.5rem" }}>
          <Grid item>
            <NumericFormat
              style={{
                // margin: "16px 0 8px 0",
                backgroundColor: "#FFFFFF",
                // marginLeft: "12px",
              }}
              inputProps={{ maxLength: 10 }}
              decimalScale={2}
              id="outlined-basic"
              label="Bill Amount*"
              onKeyPress={preventMinus}
              variant="outlined"
              fullWidth
              className={
                billAmountError != ""
                  ? classes.formControl12
                  : classes.formControl
              }
              value={selectedCategory[index]?.billamount}
              onChange={(e) => {
                handleBillAmount(e.target.value, index);
                // if(selectedCategory[index]?.claimedamount != ""){
                //   handleClaimAmount1(selectedCategory[index]?.claimedamount, index);
                // }
                setBillAmount(
                  DOMPurify.sanitize(Number(e.target.value.replaceAll(",", "")))
                );
              }}
              helperText={
                selectedCategory[index]?.billamount === ""
                  ? validationArray[index]?.billAmtMsg
                  : ""
              }
              // thousandSeparator={true}
              customInput={TextField}
              FormHelperTextProps={{
                classes: {
                  root: classes.errorRoot,
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={6} sm={4} md={4} style={{ paddingLeft: "0.5rem" }}>
          <Grid item>
            <NumericFormat
              style={{
                // margin: "16px 29px 8px 29px",
                backgroundColor: "#FFFFFF",
              }}
              inputProps={{ maxLength: 10 }}
              decimalScale={2}
              id="outlined-basic"
              label="Claim Amount*"
              onKeyPress={preventMinus}
              variant="outlined"
              fullWidth
              className={
                validationArray[index].claimAmtMsg != ""
                  ? classes.formControl12
                  : classes.formControl
              }
              value={selectedCategory[index]?.claimedamount}
              onChange={(e) => {
                // setClaimAmountError("");
                handleClaimAmount1(e.target.value, index);
              }}
              helperText={
                validationArray[index].claimAmtMsg != "" 
                // ||  selectedCategory[index]?.claimedamount == ""
                  ? validationArray[index]?.claimAmtMsg
                  : ""
                // claimAmountError !== "" ? claimAmountError : ""
              }
              customInput={TextField}
              {...materialUITextFieldProps2}
              FormHelperTextProps={{
                classes: {
                  root: classes.errorRoot,
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const gridNormal = (index) => {
    return (
      <>
        {" "}
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          style={{ padding: purpose?.purpose == "Travel" ? "0px 16px" : "" }}
        >
          <Grid item>
            <NumericFormat
              style={{
                margin: "16px 0 8px 0",
                backgroundColor: "#FFFFFF",
              }}
              inputProps={{ maxLength: 12 }}
              decimalScale={2}
              id="outlined-basic"
              label="Bill Amount*"
              variant="outlined"
              onKeyPress={preventMinus}
              fullWidth
              className={
                validationArray[index]?.billAmtMsg != ""
                  ? classes.formControl12
                  : classes.formControl
              }
              value={selectedCategory[index]?.billamount}
              onChange={(e) => {
                handleBillAmount(e.target.value, index);
                // setBillAmountError("");
                setBillAmount(
                  DOMPurify.sanitize(Number(e.target.value.replaceAll(",", "")))
                );
              }}
              helperText={
                selectedCategory[index]?.billamount === ""
                  ? validationArray[index]?.billAmtMsg
                  : ""
              }
              thousandSeparator={true}
              customInput={TextField}
              FormHelperTextProps={{
                classes: {
                  root: classes.errorRoot,
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          style={{ padding: purpose?.purpose == "Travel" ? "0px 16px" : "" }}
        >
          <Grid item>
            <NumericFormat
              style={{
                margin: "16px 0 8px 0",
                backgroundColor: "#FFFFFF",
              }}
              inputProps={{ maxLength: 12 }}
              decimalScale={2}
              id="outlined-basic"
              label="Claim Amount*"
              onKeyPress={preventMinus}
              variant="outlined"
              fullWidth
              className={
                validationArray[index]?.claimAmtMsg != ""
                  ? classes.formControl12
                  : classes.formControl
              }
              value={selectedCategory[index]?.claimedamount}
              onChange={(e) => {
                handleClaimAmount1(e.target.value, index);
              }}
              //helperText={claimAmountError !== "" ? claimAmountError : ""}
              helperText={
                // selectedCategory[index]?.claimedamount === "" ||
                validationArray[index]?.claimAmtMsg != ""
                  ? validationArray[index]?.claimAmtMsg
                  : ""
              }
              thousandSeparator={true}
              customInput={TextField}
              {...materialUITextFieldProps2}
              FormHelperTextProps={{
                classes: {
                  root: classes.errorRoot,
                },
              }}
            />
          </Grid>
        </Grid>{" "}
      </>
    );
  };

  return (
    <>
      <Box>
        <Header
          heading={"Edit Expense"}
          ssoEmpId={updatedSSOEmpId ? updatedSSOEmpId : null}
          disableAccOptions={1}
        />
      </Box>
      {matches ? (
        <Box
          style={{
            marginBottom: "-47px",
            backgroundColor: "#F8F9FF",
          }}
        >
          <IconButton>
            <ArrowBackIosIcon
              onClick={() => {
                history.push("/expense-Management");
              }}
            />
          </IconButton>
        </Box>
      ) : (
        ""
      )}
      {matches ? (
        <Typography
          style={{
            color: "#081F32",
            fontSize: "16px",
            fontWeight: 600,
            fontFamily: "Montserrat",
            paddingLeft: "48px",
            backgroundColor: "#F8F9FF",
            lineHeight: "41px",
            paddingTop: "1px",
          }}
        >
          Edit Expense
        </Typography>
      ) : (
        ""
      )}
      <Divider />
      <Box className={matches ? classes.rootMobile : classes.root}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {matches ? (
            ""
          ) : (
            <Box
              style={{
                paddingRight: "20px",
                marginLeft: "-70px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton>
                <ArrowBackIosIcon
                  onClick={() => {
                    history.push("/expense-Management");
                  }}
                />
              </IconButton>
            </Box>
          )}
          <Divider />
          <Box
            className={classes.expenseHeading}
            style={{ margin: matches && "20px 0px", padding: "0px 20px" }}
          >
            <Typography
              style={{
                fontWeight: "600",
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontSize: matches ? "14px" : "24px",
                lineHeight: matches ? "18px" : "24px",
                letterSpacing: "0px",
                textAlign: "left",
                color: "#2B2D30",
                marginLeft: matches ? "-2px" : "0px",
              }}
            >
              Expense Report ({location?.state?.response?.expCode})
            </Typography>
            {/* {console.log("editissuee openESB ",  isEditdPostSuccess, postEditDataList?.expStatus)} */}
            {snackbarAlert &&
            isEditdPostSuccess &&
            postEditDataList?.expStatus == "Draft" ? (
              <EditSnackbars
                handleSnackbar={handleSnackbar}
                //handleOpenESBox={handleOpenESBox}
                handlecloseSnackbar={handlecloseSnackbar}
                expenseStatus={postEditDataList?.expStatus}
                open={openESB}
                expenseCode={postEditDataList?.expenseCode}
              />
            ) : isEditdPostSuccess &&
              snackbarAlert &&
              postEditDataList?.expStatus == "In Progress" ? (
              <EditSnackbars
                handleSnackbar={handleSnackbar}
                // handleOpenESBox={handleOpenESBox}
                handlecloseSnackbar={handlecloseSnackbar}
                expenseStatus={postEditDataList?.expStatus}
                open={openESB}
                expenseCode={postEditDataList?.expenseCode}
              />
            ) : (
              ""
            )}
          </Box>
        </Box>
        <Box>
          <Box>
            <Grid
              container
              spacing={matches ? "" : 4}
              className={
                matches ? classes.rowContainerMobile : classes.rowContainer
              }
            >
              <Grid item xs={matches ? 12 : 6} sm={6} md={6}>
                <Grid item>
                  <FormControl
                    variant="outlined"
                    className={
                      purposeError != ""
                        ? classes.formControl12
                        : classes.formControl
                    }
                    style={{
                      margin: matches ? "4px 0px 6px" : "",
                    }}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Purpose*
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={purpose}
                      className={
                        matches ? classes.inputLabels1 : classes.inputLabels
                      }
                      style={{ height: matches ? "52px" : "56px" }}
                      onChange={(e) => {
                        setExpenseCategory("");
                        setPurposeError("");
                        setPurpose(e.target.value);
                        setSelectedDate(null);
                        e.target.value === "Travel" && setSelectedProjects([]);
                        handlepurpose(e.target.value);
                      }}
                      label="Purpose*"
                      MenuProps={{
                        getContentAnchorEl: null,
                      }}
                    >
                      {purposeList?.map((item) => {
                        return (
                          <MenuItem
                            key={item.purposeId}
                            value={item}
                            style={{ fontSize: matches ? "13px" : "" }}
                          >
                            <Typography
                              style={{
                                fontFamily: "Montserrat",
                                fontSize: matches ? "13px" : "",
                                fontWeight: matches ? 500 : "",
                                letterSpacing: matches ? "-1px" : "",
                                textAlign: "left",
                              }}
                            >
                              {item.purpose}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText className={classes.errorText}>
                      {purpose === "" ? purposeError : ""}
                    </FormHelperText>
                  </FormControl>{" "}
                </Grid>
              </Grid>
              <Grid
                item
                xs={matches ? 12 : 6}
                sm={6}
                md={6}
                // style={{ paddingRight: matches ? "" : "17px" }}
              >
                <Grid item>
                  <TextField
                    id="outlined-basic"
                    label="Expense Name*"
                    value={expenseName}
                    variant="outlined"
                    style={{
                      backgroundColor: "#FFFFFF",
                      // marginLeft: matches ? "-15px" : "0px",
                    }}
                    inputProps={{ maxLength: 80 }}
                    fullWidth
                    className={
                      expenseNameError != ""
                        ? classes.formControl12
                        : classes.formControl
                    }
                    onChange={(e) => {
                      setExpenseNameError("");
                      setSelectDateError("");
                      setExpenseName(
                        DOMPurify.sanitize(e.target.value.trimStart())
                      );
                    }}
                    helperText={expenseNameError !== "" ? expenseNameError : ""}
                    FormHelperTextProps={{
                      classes: {
                        root: classes.errorRoot,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box>
          <Grid
            container
            className={!matches && classes.rowContainer}
            style={{
              marginTop: !matches && "15px",
              // paddingRight: matches ? "12px" : "",
              marginBottom: "10px",
              padding: matches && "12px 20px 20px",
            }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Grid item>
                <Box
                  style={{
                    height: matches ? " " : "100%",
                    backgroundColor: matches ? "" : "white",
                    border: !matches && "1px solid #9E9E9E",
                    // borderRadius: matches && "1.5rem 1.5rem 0px 0px",
                  }}
                >
                  <Box
                    className={matches ? "" : classes.projectDetailsMainBox}
                    style={{
                      padding: matches ? "5px 16px 10px" : "10px 25px 4px 25px",
                      borderRadius: matches ? "" : "0px",
                      margin: matches && "0px 0px 5px",
                      border: matches && "1px solid #9E9E9E",
                    }}
                  >
                    <Grid
                      // container alignItems="center"
                      style={{
                        display: !matches && "flex",
                        alignItems: !matches && "center",
                      }}
                    >
                      {/* Project Name Form Control */}
                      <Grid
                        item
                        align="left"
                        style={{ width: !matches && "48.30%" }}
                      >
                        <FormControl
                          variant="outlined"
                          inputProps={{
                            style: handlePadding(matches),
                          }}
                          className={
                            projectError1 !== ""
                              ? classes.formControl12
                              : classes.formControl
                          }
                          style={{ backgroundColor: matches ? "white" : "" }}
                        >
                          {matches ? (
                            <InputLabel
                              id="project-id-name"
                              style={{ fontSize: "13px" }}
                            >
                              Project Name*
                            </InputLabel>
                          ) : (
                            <InputLabel id="project-id-name">
                              Project Code/ Project Name*
                            </InputLabel>
                          )}
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            name="projectName"
                            style={{
                              minWidth: matches ? "120px" : "412px",
                              maxHeight: matches ? "200px" : "56px",
                              color: "#008CE6",
                              // "&.makeStyles-formControl-531.MuiInputBase-root .MuiSelect-selectMenu ":
                              //   {
                              //     padding: "13px 32px 2px 14px",
                              //   },
                            }}
                            optionList={employeeProjectsList}
                            getOptionLabel={(option) =>
                              `${option.projName} - ${option.projCode}`
                            }
                            value={selectedProjects[0]?.projectName?.projectID}
                            onChange={(e) => handleSelectProject(e, 0)}
                            label="Project CodeProject Name*"
                            MenuProps={{
                              getContentAnchorEl: null,
                            }}
                          >
                            {employeeProjectsList?.map((item) => (
                              <MenuItem
                                key={item.projCode}
                                value={item.projCode}
                              >
                                <Box
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontSize: "14px",
                                      fontWeight: 500,
                                      lineHeight: "12px",
                                      letterSpacing: "0px",
                                      textAlign: "left",
                                      fontSize: matches ? "13px" : "",
                                      color: "#0B1941",
                                    }}
                                  >
                                    {item.projCode}
                                  </Typography>
                                  {!matches && (
                                    <Typography
                                      style={{
                                        fontFamily: "Montserrat",
                                        fontSize: "11px",
                                        fontWeight: 800,
                                        lineHeight: "18px",
                                        letterSpacing: "0px",
                                        textAlign: "left",
                                        color: "#0B1941",
                                      }}
                                    >
                                      {item.projName}
                                    </Typography>
                                  )}
                                </Box>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {projectError1 && (
                          <Typography variant="body2" color="error">
                            {projectError1}
                          </Typography>
                        )}
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* Client Billable Checkbox */}
                        <Grid
                          item
                          style={{ marginLeft: !matches && "24px" }}
                          align="center"
                        >
                          <Box
                            style={{
                              display: "flex",
                              // flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "end",
                            }}
                          >
                            <Checkbox
                              name="billable"
                              //style={{ marginRight: "9px" }}
                              checked={
                                // myExpenseList?.ProjectDetails[0]?.Billable == 1
                                selectedProjects[0]?.billable
                              }
                              checkedIcon={
                                <CheckBoxIcon
                                  className={
                                    matches
                                      ? classes.checkBoxRootMobile
                                      : classes.checkBoxRoot
                                  }
                                />
                              }
                              onChange={(e) => handleSelectProject(e, 0)}
                              style={{
                                padding: matches && "0px 5px 0px 0px",
                              }}
                            />
                            <Typography
                              style={{
                                fontFamily: "Montserrat",
                                fontSize: matches ? "12px" : "16px",
                                fontWeight: 500,
                                letterSpacing: "0px",
                                textAlign: "left",
                                width: matches ? "" : "157px",
                                marginTop: matches ? "" : "1px",
                              }}
                            >
                              Client Billable
                            </Typography>
                          </Box>
                        </Grid>

                        {/* Total Typography */}
                        <Grid
                          item
                          style={{ marginLeft: !matches && "98px" }}
                          align="right"
                        >
                          <Typography
                            style={{
                              fontFamily: "Montserrat",
                              fontSize: matches ? "12px" : "16px",
                              fontWeight: matches ? "bolder" : "500",
                              textAlign: "Right",
                              lineHieght: "24px",
                              paddingRight: !matches && "2px",
                              width: matches ? "" : "150px",
                            }}
                          >
                            Total :{" "}
                            {currency?.currency + " " + Number(totalClaimAmount)?.toFixed(2)}
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: "Montserrat",
                              fontSize: matches ? "16px" : "16px",
                              fontWeight: matches ? "bolder" : "500",
                              textAlign: "Right",
                              lineHieght: "24px",
                              paddingRight: "2px",
                            }}
                          >
                            {currencySB != null ? currencySB : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box>
                    <Typography
                      variant="p"
                      // variant="span"
                      style={{
                        fontSize: "13px",
                        color: "red",
                        marginRight: "14px",
                        marginTop: 4,
                        fontFamily: "Montserrat",
                      }}
                    >
                      {projectError1 ? projectError1 : ""}
                    </Typography>
                  </Box>

                  <Box>
                    <Accordion
                      key={0}
                      expanded={expanded3}
                      // expanded={expanded?.includes(index)}
                      // onChange={handleAccordionChange(indexForAdvances , )}
                      className={classes.projectDetailsMainBox}
                      style={{
                        marginTop: "-20px",
                        padding: "0px ",
                        marginBottom: "30px",
                        borderRadius: "0px",
                        backgroundColor: "#F8F9FF",
                        height: expanded3 == true && !matches ? "160px" : "",
                        "&.MuiPaper-elevation1": {
                          boxShadow: "0 0 0 0",
                        },
                        "&.MuiAccordionSummary-content.Mui-expanded": {
                          margin: "7px 0px",
                          minHeight: "27px",
                        },
                        "&.MuiCollapse-wrapper": {
                          backgroundColor: "#F8F9FF",
                          height: "90px",
                        },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            style={{
                              color: "#E3E8F7",
                              backgroundColor: "#0B1941",
                              borderRadius: "50%",
                              width: "16.03px",
                              height: "16.03px",
                            }}
                            isExpanded={expanded3}
                            onClick={handleAccordionChangeForTravelDetails(
                              expanded3
                            )}
                          />
                        }
                        // id={index}
                        // key={index}
                        style={{
                          height: "10px",
                          boxShadow: "0px",
                          backgroundColor:
                            // selectedCategory[index].categoryName != ""
                            "#F6F6F6",

                          height: "10px",
                          "&.MuiAccordionSummary-root.Mui-expanded": {
                            minHeight: "27px",
                          },
                          ".MuiAccordionSummary-root": {
                            height: "38px",
                          },
                        }}
                      >
                        <Grid
                          container
                          justify="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography>
                              {" "}
                              <strong> Travel Details </strong>
                            </Typography>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <Grid
                        container
                        spacing={4}
                        xs={12}
                        justifyContent="space-between"
                        alignItems="center"
                        style={{
                          marginBottom: matches ? "10px" : "40px",
                          marginLeft: "1.5px",
                        }}
                      >
                        <Grid
                          item
                          xs={matches ? 12 : 3}
                          style={{ padding: matches ? "2px 16px" : "16px" }}
                        >
                          <TextField
                            style={{
                              margin: "16px 0 8px 0",
                              backgroundColor: "#FFFFFF",
                            }}
                            inputProps={{ maxLength: 20 }}
                            id="outlined-basic"
                            label="Destination*"
                            variant="outlined"
                            fullWidth
                            className={
                              travelDestinationMsg != ""
                                ? classes.formControl12
                                : classes.formControl
                            }
                            value={travelDestination}
                            onChange={(e) => {
                              setTravelDestination(e.target.value);
                              setTravelDestinationMsg("");
                            }}
                            thousandSeparator={true}
                            customInput={TextField}
                            helperText={
                              travelDestinationMsg != "" &&
                              travelDestination == ""
                                ? travelDestinationMsg
                                : ""
                            }
                            FormHelperTextProps={{
                              classes: {
                                root: classes.errorRoot,
                              },
                              style: {
                                fontSize: "10px",
                              },
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={matches ? 12 : 3}
                          style={{ padding: matches ? "2px 16px" : "16px" }}
                        >
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              disableToolbar
                              inputVariant="outlined"
                              fullWidth
                              className={
                                travelstdtMsg != ""
                                  ? classes.formControl12
                                  : classes.formControl
                              }
                              style={{
                                backgroundColor: "#FFFFFF",
                                //marginLeft: matches ? "-15px" : "0px",
                              }}
                              variant="inline"
                              format="MM/dd/yyyy"
                              margin="normal"
                              autoOk={true}
                              disableFuture={true}
                              id="date-picker-inline"
                              label="Travel Start Date*"
                              value={travelStartDate} // Use the receiptdate from the 'cat' object
                              onChange={(e) => {
                                if (isValid(e)) {
                                  setTravelStartDate(e);
                                  setTravelstdtMsg("");
                                  if (travelDetailType === "") {
                                    setTravelDetailType("Domestic");
                                    let obj = {
                                      pId: purpose.purposeId,
                                      entity: ssoEmpIdDetails?.entity,
                                      traveltype: "Domestic",
                                    };
                                    dispatch(getCategoryData(obj));
                                  }
                                } else {
                                  setTravelStartDate(null);
                                  setTravelstdtMsg("*Invalid date format.");
                                }
                              }} // Pass the index here
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                                style: { paddingRight: "3px" },
                              }}
                              helperText={
                                travelstdtMsg != "" &&
                                (travelStartDate == null ||
                                  travelStartDate == "")
                                  ? travelstdtMsg
                                  : ""
                              }
                              FormHelperTextProps={{
                                classes: {
                                  root: classes.errorRoot,
                                },
                                style: {
                                  fontSize: "10px",
                                },
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid
                          item
                          xs={matches ? 12 : 3}
                          style={{ padding: matches ? "2px 16px" : "16px" }}
                        >
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              disableToolbar
                              inputVariant="outlined"
                              fullWidth
                              className={
                                travelendtMsg != ""
                                  ? classes.formControl12
                                  : classes.formControl
                              }
                              style={{
                                backgroundColor: "#FFFFFF",
                                // marginLeft: matches ? "-15px" : "0px",
                              }}
                              variant="inline"
                              format="MM/dd/yyyy"
                              margin="normal"
                              autoOk={true}
                              disableFuture={true}
                              id="date-picker-inline"
                              label="Travel End Date*"
                              value={travelEndDate || null} // Use the receiptdate from the 'cat' object
                              onChange={(e) => {
                                if (isValid(e)) {
                                  setTravelEndDate(e);
                                  setTravelendtMsg("");
                                } else {
                                  setTravelEndDate(null);
                                  setTravelendtMsg("*Invalid date format.");
                                }
                              }} // Pass the index here
                              // onChange={(date) => handleReceiptDate(selectedCategory[index], date, index)}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                                style: { paddingRight: "3px" },
                              }}
                              helperText={
                                travelendtMsg != "" &&
                                (travelEndDate == null || travelEndDate == "")
                                  ? travelendtMsg
                                  : ""
                              }
                              FormHelperTextProps={{
                                classes: {
                                  root: classes.errorRoot,
                                },
                                style: {
                                  fontSize: "10px",
                                },
                              }}
                              // disabled={isDatePickerDisabled}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid
                          item
                          xs={matches ? 12 : 3}
                          style={{ padding: matches ? "2px 16px" : "16px" }}
                        >
                          <FormControl
                            variant="outlined"
                            className={
                              travelTypeMsg != ""
                                ? classes.formControl12
                                : classes.formControl
                            }
                          >
                            <InputLabel id="demo-simple-select-outlined-label">
                              Travel Type*
                            </InputLabel>
                            {/* {console.log("KUSH1111", currency)} */}
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              className={
                                matches
                                  ? classes.inputLabels1
                                  : classes.inputLabels
                              }
                              value={travelDetailType}
                              onChange={(e) => {
                                let obj = {
                                  pId: purpose.purposeId,
                                  entity: ssoEmpIdDetails?.entity,
                                  traveltype: e.target.value,
                                };
                                dispatch(getCategoryData(obj));
                                setSelectedCategory((prevState) =>
                                  prevState.map((category) => ({
                                    ...category,
                                    categoryCode: "",
                                    categoryId: "",
                                    categoryName: "",
                                    paymentmethodid: "",
                                  }))
                                );
                                setTravelDetailType(e.target.value);
                                setAdvancesList(advancesListTemp);
                                setTravelCardAmount(0);
                                setCash(0);
                                setTravelTypeMsg("");
                              }}
                              label="Travel Type*"
                            >
                              {traveltype?.map((item) => {
                                return (
                                  <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>

                            <FormHelperText
                              className={classes.errorText}
                              style={{ fontSize: "10px" }}
                            >
                              {travelTypeMsg != "" &&
                              (travelDetailType == "" ||
                                travelDetailType == null ||
                                travelDetailType == [])
                                ? travelTypeMsg
                                : ""}
                            </FormHelperText>
                          </FormControl>{" "}
                        </Grid>
                      </Grid>
                    </Accordion>
                  </Box>

                  {travelDetailType === "International" ? (
                    <Box>
                      <Accordion
                        key={0}
                        expanded={expanded1}
                        // expanded={expanded?.includes(index)}
                        // onChange={handleAccordionChange(indexForAdvances , )}
                        className={classes.projectDetailsMainBox}
                        style={{
                          marginTop: "-20px",
                          padding: "0px ",
                          marginBottom: "30px",
                          borderRadius: "0px",
                          backgroundColor: "#F8F9FF",
                          height: expanded1 == true ? "100%" : "",
                          "&.MuiPaper-elevation1": {
                            boxShadow: "0 0 0 0",
                          },
                          "&.MuiAccordionSummary-content.Mui-expanded": {
                            margin: "7px 0px",
                            minHeight: "27px",
                          },
                          "&.MuiCollapse-wrapper": {
                            backgroundColor: "#F8F9FF",
                            height: "90px",
                          },
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon
                              style={{
                                color: "#E3E8F7",
                                backgroundColor: "#0B1941",
                                borderRadius: "50%",
                                width: "16.03px",
                                height: "16.03px",
                              }}
                              isExpanded={expanded1}
                              onClick={handleAccordionChangeFroAdvances(
                                expanded1
                              )}
                            />
                          }
                          // id={index}
                          // key={index}
                          style={{
                            height: "10px",
                            boxShadow: "0px",
                            backgroundColor:
                              // selectedCategory[index].categoryName != ""
                              "#F6F6F6",

                            height: "10px",
                            "&.MuiAccordionSummary-root.Mui-expanded": {
                              minHeight: "27px",
                            },
                            ".MuiAccordionSummary-root": {
                              height: "38px",
                            },
                          }}
                        >
                          <Grid
                            container
                            justify="space-between"
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography>
                                {" "}
                                <strong> Advances </strong>
                              </Typography>
                            </Grid>

                            <Grid item>
                              <Typography
                                style={{ marginRight: "30px" }}
                                align="right"
                              >
                                Total Advances: {currency.currency}
                                {(Number(cash) + Number(travelCardAmount)).toFixed(2)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        {advancesList.map((item, index) => {
                          return (
                            <Grid
                              container
                              spacing={5}
                              xs={12}
                              justifyContent="space-between"
                              alignItems="center"
                              style={{
                                // marginBottom: "40px",
                                marginLeft: "1.5px",
                                marginBottom: matches ? "1px" : "0px",
                                //height:advancesList.length>1 ? "86px" : ""
                              }}
                            >
                              <Grid
                                item
                                xs={matches ? 6 : 3}
                                style={{
                                  padding: matches ? "2px 16px" : "16px",
                                }}
                              >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    disableToolbar
                                    inputVariant="outlined"
                                    fullWidth
                                    className={classes.formControl}
                                    style={{
                                      backgroundColor: "#FFFFFF",
                                    }}
                                    InputLabelProps={{
                                      style: { fontSize: "14px" },
                                    }}
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    autoOk={true}
                                    disableFuture={true}
                                    id="date-picker-inline"
                                    label={
                                      matches
                                        ? "Deposit Date"
                                        : "Advance Deposit Date"
                                    }
                                    value={item.depositdate} // Use the receiptdate from the 'cat' object
                                    onChange={(e) =>
                                      handleAdvancesList(e, index, "date")
                                    } // Pass the index here
                                    KeyboardButtonProps={{
                                      "aria-label": "change date",
                                      style: {
                                        paddingRight: "3px",
                                        padding: matches ? "0px" : "3px",
                                        marginRight: matches ? "-10px" : "",
                                      },
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                              <Grid
                                item
                                xs={matches ? 6 : 3}
                                style={{
                                  padding: matches ? "2px 16px" : "16px",
                                }}
                              >
                                <FormControl
                                  variant="outlined"
                                  className={classes.formControl}
                                >
                                  <InputLabel id="demo-simple-select-outlined-label">
                                    Currency*
                                  </InputLabel>
                                  {/* {console.log("KUSH1111", currency)} */}
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    className={
                                      matches
                                        ? classes.inputLabels1
                                        : classes.inputLabels
                                    }
                                    // value={selectedCategory?.currency}
                                    value={currency}
                                    style={{
                                      marginRight: matches ? "-6px" : "0px",
                                    }}
                                    onChange={(e) => {
                                      handleAdvancesList(e, index, "curr");
                                      setCurrency(
                                        e.target.value
                                          ? e.target.value
                                          : currency
                                      );
                                    }}
                                    label="Currency*"
                                    //disabled={index !== 0}
                                  >
                                    {CurrencyList?.map((item) => {
                                      return (
                                        <MenuItem
                                          key={item.currencyId}
                                          value={item}
                                        >
                                          {item.currencyCode}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>

                                  <FormHelperText className={classes.errorText}>
                                    {currency === "" ? currencyError : ""}
                                  </FormHelperText>
                                </FormControl>{" "}
                              </Grid>
                              <Grid
                                item
                                xs={matches ? 6 : 3}
                                style={{
                                  padding: matches ? "2px 16px" : "16px",
                                }}
                              >
                                <NumericFormat
                                  style={{
                                    margin: "16px 0 8px 0",
                                    backgroundColor: "#FFFFFF",
                                  }}
                                  inputProps={{ maxLength: 12 }}
                                  id="outlined-basic"
                                  label="Advances Amount"
                                  variant="outlined"
                                  onKeyPress={preventMinus}
                                  fullWidth
                                  className={classes.formControl}
                                  value={item.totaladvance}
                                  onChange={(e) => {
                                    handleAdvancesList(e, index, "amt");
                                  }}
                                  thousandSeparator={true}
                                  customInput={TextField}
                                  FormHelperTextProps={{
                                    classes: {
                                      root: classes.errorRoot,
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={matches ? 6 : 2}
                                style={{
                                  padding: matches ? "2px 16px" : "16px",
                                }}
                              >
                                <FormControl
                                  variant="outlined"
                                  className={classes.formControl}
                                  style={{ width: "107%" }}
                                >
                                  <InputLabel
                                    style={{ fontSize: "14px" }}
                                    id="demo-simple-select-outlined-label"
                                  >
                                    Advances Type
                                  </InputLabel>
                                  {/* {console.log("KUSH1111", currency)} */}
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    className={
                                      matches
                                        ? classes.inputLabels1
                                        : classes.inputLabels
                                    }
                                    value={item.advancetype}
                                    onChange={(e) => {
                                      handleAdvancesList(e, index, "type");
                                    }}
                                    label="Advances Type"
                                  >
                                    {AdvancesType?.map((adv) => {
                                      return (
                                        <MenuItem
                                          key={adv.value}
                                          value={adv.value}
                                        >
                                          {adv.label}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>{" "}
                              </Grid>
                              {index !== 0 ? (
                                <Box display="flex" justifyContent="flex-start">
                                  <Button
                                    style={{ minWidth: "24px" }}
                                    onClick={() => handleRemoveAdvances(index)}
                                  >
                                    <span class="material-symbols-outlined">
                                      delete
                                    </span>
                                  </Button>
                                </Box>
                              ) : (
                                <Box display="flex" justifyContent="flex-start">
                                  <Button
                                    style={{ minWidth: "42px" }}
                                    //onClick={() => handleRemoveAdvances(index)}
                                  >
                                    <span class="material-symbols-outlined">
                                      {" "}
                                    </span>
                                  </Button>
                                </Box>
                              )}
                              {/* {matches ? <Divider 
                                  style={{
                                    width:"100%",
                                    height:"1.5px",
                                    backgroundColor:"rgba(0,0,0,0.2)",
                                    marginTop:"-20px",
                                  }}
                                  /> : ""} */}
                            </Grid>
                          );
                        })}
                        <Box style={{ height: "33px" }}>
                          <Link
                            style={{
                              color: "#008CE6",
                              cursor: "pointer",
                              fontFamily: "Montserrat",
                              fontSize: "16px",
                              fontWeight: "500",
                              lineHeight: "32px",
                              letterSpacing: "0px",
                              textAlign: "left",
                              marginLeft: "10px",
                              marginTop: matches ? "15px" : "",
                              // padding:"25px"
                            }}
                            target="_blank"
                            underline="always"
                            onClick={() => {
                              addAdvancesLine();
                            }}
                          >
                            + Add Advances
                          </Link>
                        </Box>
                      </Accordion>
                    </Box>
                  ) : (
                    ""
                  )}

                  <Grid item xs={12} sm={12} md={12}>
                    {selectedCategory?.map((cat, index) => {
                      const payment = paymentList?.find(
                        (payment) =>
                          payment.methodId === parseInt(cat?.paymentmethodid)
                      );

                      return (
                        <>
                          <Accordion
                            key={index}
                            expanded={expanded === index}
                            // expanded={expanded?.includes(index)}
                            // onChange={handleAccordionChange(index)}
                            className={classes.projectDetailsMainBox}
                            style={{
                              marginTop: "-20px",
                              padding: "0px ",
                              marginBottom: "30px",
                              borderRadius: "0px",
                              backgroundColor: "#F8F9FF",
                              "&.MuiPaper-elevation1": {
                                boxShadow: "0 0 0 0",
                              },
                              "&.MuiAccordionSummary-content.Mui-expanded": {
                                margin: "7px 0px",
                                minHeight: "27px",
                              },
                            }}
                          >
                            <AccordionSummary
                              className={
                                matches && classes.ExpandedAccordionTravel
                              }
                              expandIcon={
                                <ExpandMoreIcon
                                  style={{
                                    color: "#E3E8F7",
                                    backgroundColor: "#0B1941",
                                    borderRadius: "1rem",
                                    height: "1rem",
                                    width: "1rem",
                                  }}
                                  isExpanded={expanded === index}
                                  onClick={handleAccordionChange(
                                    index,
                                    expanded === index
                                  )}
                                />
                              }
                              IconButtonProps={{
                                style: {
                                  position: matches && "absolute",
                                  top: matches && "35px",
                                },
                              }}
                              // id={index}
                              key={index}
                              style={{
                                padding: matches ? "0px 20px" : "0px 25px",
                                boxShadow: "0px",
                                backgroundColor:
                                  selectedCategory[index].categoryName != ""
                                    ? "#F6F6F6"
                                    : "",
                                height:
                                  selectedCategory[index].categoryName != ""
                                    ? ""
                                    : "10px",
                                "&.MuiAccordionSummary-root.Mui-expanded": {
                                  minHeight: "27px",
                                },
                              }}
                            >
                              <Grid
                                container
                                justify="space-between"
                                alignItems="center"
                              >
                                <Grid
                                  item
                                  // display="flex"
                                  // direction="column"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontSize: matches ? "12px" : "16px",
                                      textWrap: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      // width: matches && "290px",
                                      maxWidth: matches && "170px",
                                    }}
                                  >
                                    <strong> {cat?.categoryName}</strong>
                                  </Typography>
                                  {cat.categoryName != "" ? (
                                    <Box
                                      style={{
                                        // display: "flex",
                                        // justifyContent: "flex-end",
                                        // // minWidth: "104%",
                                        // marginTop: "-24px",
                                        // marginRight: "-30px",
                                        height: "24px",
                                        paddingLeft: "1rem",
                                      }}
                                    >
                                      <InfoOutlinedIcon
                                        style={{
                                          // paddingRight: matches ? "14px" : "",
                                          color: "#9E9E9E",
                                        }}
                                        onClick={handlePopoverOpen}
                                      />
                                      <Popover
                                        id="mouse-over-popover"
                                        className={classes.popover}
                                        classes={{
                                          paper: classes.paper,
                                        }}
                                        open={openPopOver}
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "left",
                                        }}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "left",
                                        }}
                                        onClose={handlePopoverClose}
                                        disableRestoreFocus
                                      >
                                        {CategoryList?.find(
                                          (item) =>
                                            item.categoryName ==
                                            cat.categoryName
                                        )?.policyDescription ? (
                                          <Box
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              maxWidth: "325px",
                                              maxHeight: "248px",
                                            }}
                                          >
                                            <Box
                                              style={{
                                                padding: "16px",
                                              }}
                                            >
                                              <Box>
                                                <Typography
                                                  color="inherit"
                                                  style={{
                                                    fontFamily: "Montserrat",
                                                    fontSize: "14px",
                                                    fontWeight: "600",
                                                    lineHeight: "20px",
                                                    letterSpacing: "0em",
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  POLICY DESCRIPTION
                                                </Typography>
                                              </Box>

                                              <Box
                                                style={{
                                                  paddingTop: "4px",
                                                }}
                                              >
                                                <Typography
                                                  style={{
                                                    fontFamily: "Montserrat",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                    lineHeight: "16px",
                                                    letterSpacing: "0em",
                                                    textAlign: "justify",
                                                  }}
                                                >
                                                  {truncateString(
                                                    CategoryList?.find(
                                                      (item) =>
                                                        item.categoryName ==
                                                        cat.categoryName
                                                    )?.policyDescription,
                                                    264
                                                  )}
                                                </Typography>
                                                <Box>
                                                  <Link
                                                    style={{
                                                      color: "#0B1941",
                                                      cursor: "pointer",
                                                      fontFamily: "Montserrat",
                                                      fontSize: "16px",
                                                      fontWeight: "500",
                                                      lineHeight: "50px",
                                                      letterSpacing: "0px",
                                                      textAlign: "left",
                                                      paddingTop: "20px",
                                                    }}
                                                    target="_blank"
                                                    underline="always"
                                                    onClick={
                                                      handleOpenDescDialog
                                                    }
                                                  >
                                                    View more
                                                  </Link>
                                                  <div>
                                                    <Dialog
                                                      onClose={
                                                        handleCloseDescDialog
                                                      }
                                                      aria-labelledby="customized-dialog-title"
                                                      open={openDescDialog}
                                                    >
                                                      <DialogTitleDescDialog
                                                        id="customized-dialog-title"
                                                        onClose={
                                                          handleCloseDescDialog
                                                        }
                                                      >
                                                        Policy Description
                                                      </DialogTitleDescDialog>
                                                      <DialogContentDescDialog
                                                        dividers
                                                      >
                                                        <Typography
                                                          gutterBottom
                                                        >
                                                          {
                                                            CategoryList?.find(
                                                              (item) =>
                                                                item.categoryName ==
                                                                cat.categoryName
                                                            )?.policyDescription
                                                          }
                                                        </Typography>
                                                      </DialogContentDescDialog>
                                                      <DialogActionsDescDialog>
                                                        <Button
                                                          autoFocus
                                                          onClick={
                                                            handleCloseDescDialog
                                                          }
                                                          color="primary"
                                                        >
                                                          Close
                                                        </Button>
                                                      </DialogActionsDescDialog>
                                                    </Dialog>
                                                  </div>
                                                </Box>
                                              </Box>
                                            </Box>
                                            <Box>
                                              <CloseIcon
                                                onClick={handlePopoverClose}
                                              />
                                            </Box>
                                          </Box>
                                        ) : (
                                          ""
                                        )}
                                      </Popover>
                                    </Box>
                                  ) : (
                                    ""
                                  )}
                                </Grid>
                                {/* <Grid item style={{marginLeft:"-274px"}}>
                                      
                                    </Grid> */}
                                <Grid item>
                                  <Typography
                                    style={{
                                      marginRight: !matches && "30px",
                                      fontSize: matches ? "12px" : "",
                                    }}
                                    align="right"
                                  >
                                    {/* {console.log("cat", cat)} */}
                                    <strong>
                                      {!matches && " Claimed amount :"}{" "}
                                      {cat?.claimedamount
                                        ? currency?.currency +
                                          cat?.claimedamount
                                        : ""}
                                    </strong>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </AccordionSummary>

                            <Grid
                              container
                              // spacing={matches ? 2 : 2}
                              // className={
                              //   matches
                              //     ? classes.rowContainerMobile
                              //     : classes.rowContainer
                              // }
                              className={
                                !matches && classes.rowContainer
                                // ? classes.rowContainerMobile
                                // :
                              }
                              style={{
                                padding: matches ? "0px 20px" : "0px 25px",
                                height: !matches && "89px",
                              }}
                            >
                              <Grid
                                item
                                xs={matches ? 12 : 6}
                                sm={6}
                                md={6}
                                style={{
                                  paddingRight: matches ? "" : "17px",
                                }}
                              >
                                <Grid
                                  item
                                  // style={{ marginLeft: "-85px", width: "119%" }}
                                >
                                  <FormControl
                                    fixed
                                    variant="outlined"
                                    className={
                                      validationArray[index]?.categoryMsg != ""
                                        ? classes.formControl12
                                        : classes.formControl
                                    }
                                    style={{ width: "100%" }}
                                  >
                                    {/* {console.log("check category", cat)} */}
                                    <InputLabel id="demo-simple-select-outlined-label">
                                      Expense Category*
                                    </InputLabel>
                                    <Select
                                      style={{ borderRadius: 4 }}
                                      className={
                                        matches
                                          ? classes.inputLabels1
                                          : classes.inputLabels
                                      }
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      defaultValue={
                                        selectedCategory[index].categoryName
                                      }
                                      label={"Expense Category*"}
                                      //   value={[cat?.CategoryName]}
                                      // value={CategoryList?.categoryName}
                                      // disable
                                      value={cat?.categoryName || ""}
                                      onChange={(e) => {
                                        setSelectDateError("");
                                        validationArray[index].categoryMsg = "";
                                        setIndexForDate(index);
                                        handleExpCategory(
                                          //   e.target.value.categoryId,
                                          //   e.target.value.categoryName,
                                          //   e.target.value.categoryCode,
                                          e,
                                          index
                                        );
                                        //setExpenseCategory(e.target.value);
                                        setSelectedDate(null);
                                        // if (index === 0) {
                                        //   projectData(e);
                                        // }
                                      }}
                                    >
                                      {CategoryList?.map((item) => {
                                        const displayText =
                                          item.categoryName.length > 50
                                            ? item.categoryName.substring(
                                                0,
                                                50
                                              ) + "..."
                                            : item.categoryName;
                                        return (
                                          <MenuItem
                                            key={item.categoryId}
                                            value={item.categoryName}
                                          >
                                            <Typography
                                              style={{
                                                fontFamily: "Montserrat",
                                                fontSize: matches ? "12px" : "",
                                                fontWeight: matches ? 500 : "",
                                                letterSpacing: matches
                                                  ? "-1px"
                                                  : "",
                                                textAlign: "left",
                                                lineHeight: !matches && "1",
                                              }}
                                            >
                                              {displayText}
                                            </Typography>
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                    <FormHelperText
                                      className={classes.errorText}
                                    >
                                      {selectedCategory[index]?.categoryName ===
                                      ""
                                        ? validationArray[index]?.categoryMsg
                                        : ""}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              </Grid>
                              <Popover
                                id="mouse-over-popover"
                                className={classes.popover}
                                classes={{
                                  paper: classes.paper,
                                }}
                                open={openPopOver}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                              >
                                {CategoryList?.find(
                                  (item) =>
                                    item.categoryName == cat.categoryName
                                )?.policyDescription ? (
                                  <Box
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      maxWidth: "325px",
                                      maxHeight: "248px",
                                    }}
                                  >
                                    <Box
                                      style={{
                                        padding: "16px",
                                      }}
                                    >
                                      <Box>
                                        <Typography
                                          color="inherit"
                                          style={{
                                            fontFamily: "Montserrat",
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            lineHeight: "20px",
                                            letterSpacing: "0em",
                                            textAlign: "left",
                                          }}
                                        >
                                          POLICY DESCRIPTION
                                        </Typography>
                                      </Box>

                                      <Box
                                        style={{
                                          paddingTop: "4px",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            fontFamily: "Montserrat",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            lineHeight: "16px",
                                            letterSpacing: "0em",
                                            textAlign: "justify",
                                          }}
                                        >
                                          {truncateString(
                                            CategoryList?.find(
                                              (item) =>
                                                item.categoryName ==
                                                cat.categoryName
                                            )?.policyDescription,
                                            264
                                          )}
                                        </Typography>
                                        <Box>
                                          <Link
                                            style={{
                                              color: "#0B1941",
                                              cursor: "pointer",
                                              fontFamily: "Montserrat",
                                              fontSize: "16px",
                                              fontWeight: "500",
                                              lineHeight: "50px",
                                              letterSpacing: "0px",
                                              textAlign: "left",
                                              paddingTop: "20px",
                                            }}
                                            target="_blank"
                                            underline="always"
                                            onClick={handleOpenDescDialog}
                                          >
                                            View more
                                          </Link>
                                          <div>
                                            <Dialog
                                              onClose={handleCloseDescDialog}
                                              aria-labelledby="customized-dialog-title"
                                              open={openDescDialog}
                                            >
                                              <DialogTitleDescDialog
                                                id="customized-dialog-title"
                                                onClose={handleCloseDescDialog}
                                              >
                                                Policy Description
                                              </DialogTitleDescDialog>
                                              <DialogContentDescDialog dividers>
                                                <Typography gutterBottom>
                                                  {
                                                    expenseCategory.policyDescription
                                                  }
                                                </Typography>
                                              </DialogContentDescDialog>
                                              <DialogActionsDescDialog>
                                                <Button
                                                  autoFocus
                                                  onClick={
                                                    handleCloseDescDialog
                                                  }
                                                  color="primary"
                                                >
                                                  Close
                                                </Button>
                                              </DialogActionsDescDialog>
                                            </Dialog>
                                          </div>
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box>
                                      <CloseIcon onClick={handlePopoverClose} />
                                    </Box>
                                  </Box>
                                ) : (
                                  ""
                                )}
                              </Popover>
                              <Grid
                                item
                                xs={matches ? 12 : 6}
                                sm={6}
                                md={6}
                                style={{
                                  paddingLeft: matches ? "" : "17px",
                                }}
                              >
                                <Grid
                                  item
                                  // style={{ marginRight: "-85px" }}
                                >
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                      disableToolbar
                                      inputVariant="outlined"
                                      fullWidth
                                      // className={
                                      //   selectDateError != ""
                                      //     ? classes.formControl12
                                      //     : classes.formControl
                                      // }
                                      className={
                                        validationArray[index]?.recieptMsg != ""
                                          ? classes.formControl12
                                          : classes.formControl
                                      }
                                      style={{
                                        backgroundColor: "#FFFFFF",
                                        // marginLeft: matches ? "-15px" : "0px",
                                      }}
                                      variant="inline"
                                      format="MM/dd/yyyy"
                                      margin="normal"
                                      autoOk={true}
                                      disableFuture={true}
                                      id="date-picker-inline"
                                      label="Invoice/Reciept Date*"
                                      value={cat?.receiptdate || null} // Use the receiptdate from the 'cat' object
                                      onChange={(date) =>
                                        handleReceiptDate(date, index, cat)
                                      } // Pass the index here
                                      // onChange={(date) => handleReceiptDate(selectedCategory[index], date, index)}
                                      KeyboardButtonProps={{
                                        "aria-label": "change date",
                                        style: { paddingRight: "3px" },
                                      }}
                                      helperText={
                                        // selectedCategory[index].receiptdate ===
                                        // null
                                        //   ? validationArray[index].recieptMsg
                                        //   : ""
                                        validationArray[index]?.recieptMsg != ""
                                          ? validationArray[index]?.recieptMsg
                                          : ""
                                      }
                                      FormHelperTextProps={{
                                        classes: {
                                          root: classes.errorRoot,
                                        },
                                      }}
                                      // disabled={isDatePickerDisabled}
                                    />
                                  </MuiPickersUtilsProvider>
                                </Grid>
                              </Grid>
                            </Grid>

                            {cat?.categoryName && cat?.receiptdate != null ? (
                              CategoryList?.find(
                                (item) => item.categoryName == cat.categoryName
                              )?.policyApplicable == "Y" &&
                              (CategoryList?.find(
                                (item) => item.categoryName == cat.categoryName
                              )?.exceptionalApproval == 1 ||
                                CategoryList?.find(
                                  (item) =>
                                    item.categoryName == cat.categoryName
                                )?.frequency == "Monthly") ? (
                                <Box style={{ marginBottom: "10px" }}>
                                  <Grid
                                    container
                                    className={classes.rowContainer}
                                    style={{
                                      marginTop: "15px",
                                      padding: matches && "0px 20px",
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      className={
                                        matches
                                          ? classes.infoBoxMobile
                                          : classes.infoBox
                                      }
                                    >
                                      <Grid
                                        item
                                        xs={matches ? 12 : 9}
                                        sm={matches ? 11 : 9}
                                        md={matches ? 11 : 10}
                                      >
                                        <Box
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            background: "#FFFFFF",
                                            border: "1px solid #008CE6",
                                            borderRadius: "4px",
                                            padding: "8px",
                                          }}
                                        >
                                          <InfoIcon />
                                          <Typography
                                            style={{
                                              color: "#008CE6",
                                              paddingLeft: "15px",
                                              fontFamily: "Montserrat",
                                              fontWeight: "600",
                                              lineHeight: "16px",
                                              letterSpacing: "0px",
                                              fontSize: matches
                                                ? "9px"
                                                : "12px",
                                              textAlign: "left",
                                            }}
                                          >
                                            NOTE: Policy limit for this expense
                                            is{" "}
                                            {
                                              CategoryList?.find(
                                                (item) =>
                                                  item.categoryName ==
                                                  cat.categoryName
                                              )?.currency
                                            }
                                            {noOfMon !== null &&
                                            noOfMon != 0 &&
                                            noOfMon != 1
                                              ? `${
                                                  noOfMon *
                                                  CategoryList?.find(
                                                    (item) =>
                                                      item.categoryName ==
                                                      cat.categoryName
                                                  )?.policyLimit
                                                } ( ${noOfMon} months )`
                                              : CategoryList?.find(
                                                  (item) =>
                                                    item.categoryName ==
                                                    cat.categoryName
                                                )?.policyLimit}
                                            .
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Box>
                              ) : CategoryList?.find(
                                  (item) =>
                                    item.categoryName == cat.categoryName
                                )?.policyApplicable == "Y" &&
                                CategoryList?.find(
                                  (item) =>
                                    item.categoryName == cat.categoryName
                                )?.categoryId !== 11 ? (
                                <Box style={{ marginBottom: "10px" }}>
                                  <Grid
                                    container
                                    className={classes.rowContainer}
                                    style={{
                                      marginTop: "15px",
                                      padding: matches && "0px 20px",
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      className={
                                        matches
                                          ? classes.infoBoxMobile
                                          : classes.infoBox
                                      }
                                    >
                                      <Grid
                                        xs={matches ? 12 : 9}
                                        sm={matches ? 11 : 9}
                                        md={matches ? 11 : 10}
                                      >
                                        <Box
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            background: "#FFFFFF",
                                            border: "1px solid #008CE6",
                                            borderRadius: "4px",
                                            padding: "8px",
                                          }}
                                        >
                                          <InfoIcon />
                                          <Typography
                                            style={{
                                              color: "#008CE6",
                                              paddingLeft: "15px",
                                              fontSize: matches
                                                ? "8px"
                                                : "12px",
                                              textAlign: matches ? "left" : "",
                                            }}
                                          >
                                            <strong>
                                              {" "}
                                              NOTE: Your eligible amount for the
                                              Category is{" "}
                                              {
                                                CategoryList?.find(
                                                  (item) =>
                                                    item.categoryName ==
                                                    cat.categoryName
                                                )?.currency
                                              }
                                              {empAmounts?.remainingAmount} as
                                              you have availed{" "}
                                              {
                                                CategoryList?.find(
                                                  (item) =>
                                                    item.categoryName ==
                                                    cat.categoryName
                                                )?.currency
                                              }
                                              {empAmounts?.totalAmount} from the
                                              policy limit{" "}
                                              {
                                                CategoryList?.find(
                                                  (item) =>
                                                    item.categoryName ==
                                                    cat.categoryName
                                                )?.currency
                                              }
                                              {
                                                CategoryList?.find(
                                                  (item) =>
                                                    item.categoryName ==
                                                    cat.categoryName
                                                )?.policyLimit
                                              }{" "}
                                              .
                                            </strong>
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Box>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                            <Grid
                              container
                              // spacing={matches ? 2 : 4}
                              className={
                                matches
                                  ? classes.rowContainerMobile
                                  : classes.rowContainer
                              }
                              style={{
                                // marginTop: "10px",
                                flexWrap: "nowrap",
                                padding: matches
                                  ? "5px 20px"
                                  : "0px 9px 8px 9px",
                              }}
                            >
                              <Grid
                                item
                                xs={matches ? 12 : 4}
                                sm={4}
                                md={4}
                                style={{ padding: !matches && "0px 16px" }}
                              >
                                <Grid item>
                                  <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                  >
                                    <InputLabel id="demo-simple-select-outlined-label">
                                      Currency*
                                    </InputLabel>

                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      className={
                                        matches
                                          ? classes.inputLabels1
                                          : classes.inputLabels
                                      }
                                      // value={selectedCategory?.currency}
                                      value={currency}
                                      // || CurrencyList.find((value)=>value.currencyId === selectedCategory[index].currencyId)}
                                      // style={{
                                      //   marginRight: matches ? "-6px" : "0px",
                                      // }}
                                      onChange={(e) => {
                                        handleCurrency(e.target.value, index);
                                        setCurrency(
                                          e.target.value
                                            ? e.target.value
                                            : currency
                                        );
                                      }}
                                      label="Currency*"
                                      disabled={index !== 0}
                                    >
                                      {CurrencyList?.map((item) => {
                                        return (
                                          <MenuItem
                                            key={item.currencyId}
                                            value={item}
                                          >
                                            {item.currencyCode}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>

                                    <FormHelperText
                                      className={classes.errorText}
                                    >
                                      {currency === "" ? currencyError : ""}
                                    </FormHelperText>
                                  </FormControl>{" "}
                                </Grid>
                              </Grid>
                              {matches ? gridCond(index) : gridNormal(index)}
                              <Grid
                                item
                                xs={matches ? 12 : 4}
                                sm={4}
                                md={4}
                                style={{ padding: !matches && "0px 16px" }}
                              >
                                <Grid item>
                                  <FormControl
                                    variant="outlined"
                                    className={
                                      validationArray[index]?.paymentMsg != ""
                                        ? classes.formControl12
                                        : classes.formControl
                                    }
                                  >
                                    <InputLabel id="demo-simple-select-outlined-label">
                                      Payment Method*
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      className={
                                        matches
                                          ? classes.inputLabels1
                                          : classes.inputLabels
                                      }
                                      // value={paymentList?.methodName}
                                      value={
                                        paymentList?.find(
                                          (item) =>
                                            item?.methodId ===
                                            cat?.paymentmethodid
                                        ) || ""
                                      }
                                      onChange={(e) => {
                                        setPayment(e.target.value);
                                        handlePayment(
                                          e.target.value.methodId,
                                          index
                                        );
                                        validationArray[index].paymentMsg = "";
                                      }}
                                      label="Payment Method**"
                                      MenuProps={{
                                        getContentAnchorEl: null,
                                      }}
                                    >
                                      {paymentList?.map((item) => {
                                        return (
                                          <MenuItem
                                            key={item.categoryId}
                                            value={item}
                                          >
                                            {item.methodName}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                    <FormHelperText
                                      className={classes.errorText}
                                    >
                                      {/* {payment === "" ? paymentError : ""} */}
                                      {selectedCategory[index]
                                        .paymentmethodid === ""
                                        ? validationArray[index].paymentMsg
                                        : ""}
                                    </FormHelperText>
                                  </FormControl>{" "}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Box style={{ margin: "15px 0px" }}>
                              <Grid
                                container
                                // spacing={matches ? 2 : 4}
                                // className={classes.rowContainer}
                              >
                                <Grid item xs={12} sm={12} md={12}>
                                  <Grid item>
                                    <Box>
                                      <DragDropeTravel
                                        handleFileList={handleFileList1}
                                        handleDeleteFileList={
                                          handleDeleteFileList
                                        }
                                        theData={theData[index]}
                                        selectedCategory={selectedCategory}
                                        setSelectedCategory={
                                          setSelectedCategory
                                        }
                                        fileDisplayForDD={fileDisplayForDD}
                                        setFileDisplayForDD={
                                          setFileDisplayForDD
                                        }
                                        Edit
                                        index={index}
                                        myExpenseList={
                                          location?.state?.check == "notTravel"
                                            ? []
                                            : myExpenseList
                                        }
                                        handleEditDeleteValidateFile={
                                          handleEditDeleteValidateFile
                                        }
                                        setFinalFileList={setFinalFileList}
                                        finalFileList={finalFileList}
                                      />
                                      {validationArray[index]?.attachmentMsg !=
                                      "" ? (
                                        <Box
                                          style={{
                                            paddingBottom: "20px",
                                            paddingLeft: "26px",
                                          }}
                                        >
                                          <Typography
                                            variant="p"
                                            style={{
                                              fontSize: "13px",
                                              color: "red",
                                              marginRight: "14px",
                                              marginTop: 4,
                                              fontFamily: "Montserrat",
                                            }}
                                          >
                                            {
                                              validationArray[index]
                                                ?.attachmentMsg
                                            }
                                          </Typography>
                                        </Box>
                                      ) : (
                                        <></>
                                      )}
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                            {index !== 0 ? (
                              <Box display="flex" justifyContent="flex-end">
                                <Button
                                  style={{ minWidth: "24px" }}
                                  onClick={() => handleRemove(index, cat)}
                                >
                                  <span class="material-symbols-outlined">
                                    delete
                                  </span>
                                </Button>
                              </Box>
                            ) : (
                              ""
                            )}
                            {/* </Box> */}
                          </Accordion>
                          {index == selectedCategory.length - 1 ? (
                            <Box
                            // style={{ marginBottom: "-12px" }}
                            >
                              {/* <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  marginTop: "-64px",
                                  minWidth: "104%",
                                }}
                              ></Box> */}
                              <Box
                                style={{
                                  // padding: "25px 0px 0px 0px"
                                  marginTop: "-35px",
                                  height: "50px",
                                  paddingLeft: matches ? "" : "25px",
                                }}
                              >
                                <Link
                                  style={{
                                    color: "#008CE6",
                                    cursor: "pointer",
                                    fontFamily: "Montserrat",
                                    fontSize: matches ? "12px" : "16px",
                                    fontWeight: "500",
                                    lineHeight: "58px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                    // marginLeft: "10px",
                                    // padding:"25px"
                                  }}
                                  target="_blank"
                                  underline="always"
                                  onClick={() => {
                                    addCategoryLine();
                                    setSelectedDate(null);
                                    // setPayment("");
                                  }}
                                >
                                  + Add Expense
                                </Link>
                              </Box>
                            </Box>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Accordion
          key={0}
          expanded={expanded2}
          // expanded={expanded?.includes(index)}
          // onChange={handleAccordionChange(indexForAdvances , )}
          className={classes.projectDetailsMainBox}
          style={{
            margin: "16px 0px",
            padding: "0px ",
            //marginBottom: "30px",
            borderRadius: "0px",
            backgroundColor: "#F8F9FF",
            height: expanded1 == true ? "100%px" : "",
            "&.MuiPaper-elevation1": {
              boxShadow: "0 0 0 0",
            },
            "&.MuiAccordionSummary-content.Mui-expanded": {
              margin: "7px 0px",
              minHeight: "27px",
            },
            "&.MuiCollapse-wrapper": {
              backgroundColor: "#F8F9FF",
              height: "90px",
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                style={{
                  color: "#E3E8F7",
                  backgroundColor: "#0B1941",
                  borderRadius: "50%",
                  width: "16.03px",
                  height: "16.03px",
                }}
                isExpanded={expanded2}
                onClick={handleAccordionChangeForSummary(expanded2)}
              />
            }
            // id={index}
            // key={index}
            style={{
              height: "10px",
              boxShadow: "0px",
              backgroundColor:
                // selectedCategory[index].categoryName != ""
                "#F6F6F6",

              height: "10px",
              "&.MuiAccordionSummary-root.Mui-expanded": {
                minHeight: "27px",
              },
              ".MuiAccordionSummary-root": {
                height: "38px",
              },
            }}
          >
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Typography>
                  {" "}
                  <strong> Summary </strong>
                </Typography>
              </Grid>

              <Grid item>
                <Typography style={{ marginRight: "30px" }} align="right">
                  {Number(
                    Number((cash || "").toString().replaceAll(",", "")) +
                      Number(
                        (travelCardAmount || "").toString().replaceAll(",", "")
                      ) -
                      (Number(
                        (actualsCash || "").toString().replaceAll(",", "")
                      ) +
                        Number(
                          (actualsSelf || "").toString().replaceAll(",", "")
                        ) +
                        Number(
                          (actualsTravelCard || "")
                            .toString()
                            .replaceAll(",", "")
                        )) -
                      (Number(
                        (travelCardBalance || "").toString().replaceAll(",", "")
                      ) +
                        Number(
                          (cashBalance || "").toString().replaceAll(",", "")
                        ))
                  ) > 0
                    ? " *Net Payable:"
                    : " *Net Recievable:"}{" "}
                  {currency.currency}
                  {Math.abs(
                    Number(
                      Number((cash || "").toString().replaceAll(",", "")) +
                        Number(
                          (travelCardAmount || "")
                            .toString()
                            .replaceAll(",", "")
                        ) -
                        (Number(
                          (actualsCash || "").toString().replaceAll(",", "")
                        ) +
                          Number(
                            (actualsSelf || "").toString().replaceAll(",", "")
                          ) +
                          Number(
                            (actualsTravelCard || "")
                              .toString()
                              .replaceAll(",", "")
                          )) -
                        (Number(
                          (travelCardBalance || "")
                            .toString()
                            .replaceAll(",", "")
                        ) +
                          Number(
                            (cashBalance || "").toString().replaceAll(",", "")
                          ))
                    )
                  ).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <Box
            style={{
              //marginTop: "16px",
              backgroundColor: "#fff",
              border: "0.5px solid rgba(0,0,0,0.2)",
              height: "100%",
              padding: "0px 0px 10px 0px",
            }}
          >
            <Grid
              container
              spacing={3}
              style={{ padding: "10px 0px 0px 16px" }}
            >
              <Grid
                style={{
                  display: "flex",
                  direction: "row",
                  justifyContent: "space-between",
                  paddingRight: matches ? "30px" : "100px",
                }}
                item
                xs={6}
              >
                <Box>
                  <strong>Advances</strong>
                </Box>
                <Box>
                  <strong>
                    {" "}
                    {currency.currency}
                    {Number(Number(travelCardAmount) + Number(cash))?.toFixed(2) || 0}
                  </strong>
                </Box>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  direction: "row",
                  justifyContent: "space-between",
                  paddingRight: matches ? "30px" : "100px",
                }}
                item
                xs={6}
              >
                <Box>
                  <strong>Actuals</strong>
                </Box>
                <Box>
                  <strong>
                    {" "}
                    {currency.currency}
                    {Number(actualsSelf + actualsCash + actualsTravelCard).toFixed(2) || 0}
                  </strong>
                </Box>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  direction: "row",
                  justifyContent: "space-between",
                  paddingRight: matches ? "30px" : "100px",
                }}
                item
                xs={6}
              >
                <Box>Travel Card:</Box>
                <Box>
                  {" "}
                  {currency.currency}
                  {travelCardAmount?.toFixed(2) || 0}
                </Box>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  direction: "row",
                  justifyContent: "space-between",
                  paddingRight: matches ? "30px" : "100px",
                }}
                item
                xs={6}
              >
                <Box>Travel Card:</Box>
                <Box>
                  {" "}
                  {currency?.currency}
                  {actualsTravelCard?.toFixed(2) || 0}
                </Box>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  direction: "row",
                  justifyContent: "space-between",
                  paddingRight: matches ? "30px" : "100px",
                }}
                item
                xs={6}
              >
                <Box>Cash:</Box>
                <Box>
                  {currency.currency}
                  {cash?.toFixed(2) || 0}
                </Box>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  direction: "row",
                  justifyContent: "space-between",
                  paddingRight: matches ? "30px" : "100px",
                }}
                item
                xs={6}
              >
                <Box>Cash:</Box>
                <Box>
                  {currency.currency}
                  {actualsCash?.toFixed(2) || 0}
                </Box>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  direction: "row",
                  justifyContent: "space-between",
                  paddingRight: matches ? "30px" : "100px",
                }}
                item
                xs={6}
              >
                <Box>{""}</Box>
                <Box>
                  {/* {currency.currency}
                   {actualsSelf || 0} */}{" "}
                </Box>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  direction: "row",
                  justifyContent: "space-between",
                  paddingRight: matches ? "30px" : "100px",
                }}
                item
                xs={6}
              >
                <Box>Self:</Box>
                <Box>
                  {currency.currency}
                  {actualsSelf?.toFixed(2) || 0}
                </Box>
              </Grid>
              <Divider
                style={{ width: "99.2%", marginLeft: "-4px", height: "1px" }}
              />
              <Grid item xs={12}>
                <Box>
                  <Typography>
                    <u>To be filled by finance</u>
                  </Typography>
                </Box>
              </Grid>

              <Grid
                style={{
                  display: "flex",
                  direction: "row",
                  justifyContent: "space-between",
                  paddingRight: matches ? "30px" : "100px",
                  marginTop: "7px",
                }}
                item
                xs={6}
              >
                <Box>
                  <strong>Refund Received</strong>
                </Box>
                <Box>
                  <strong>
                    {currency.currency}
                    {Number(
                      Number(cashBalance.toString().replaceAll(",", "")) +
                        Number(travelCardBalance.toString().replaceAll(",", ""))
                    ).toFixed(2)}
                  </strong>
                </Box>
              </Grid>
              <Grid item xs={6}>
                {/* <strong>Per Diem</strong> */}
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  direction: "row",
                  justifyContent: "space-between",
                  paddingRight: matches ? "30px" : "100px",
                }}
                item
                xs={6}
              >
                <Box>Travel Card Balance:</Box>
                <Box style={{}}>
                  {currency.currency}
                  {Number(travelCardBalance)}
                </Box>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  direction: "row",
                  justifyContent: "space-between",
                  paddingRight: matches ? "30px" : "100px",
                }}
                item
                xs={6}
              >
                <Box>{/* Per Diem with Bills - Non Taxable: */}</Box>
                <Box>
                  {/* <NumericFormat
                     variant="outlined"
                     id="outlined-basic"
                     label="Enter balance"
                     value={0}
                     style={{ width: "50%" }}
                     //onChange={}
                     fullWidth
                     onKeyPress={preventMinus}
                     thousandSeparator={true}
                     customInput={TextField}
                     className={classes.formControl}
                   /> */}
                </Box>
              </Grid>

              <Grid
                style={{
                  display: "flex",
                  direction: "row",
                  justifyContent: "space-between",
                  paddingRight: matches ? "30px" : "100px",
                }}
                item
                xs={6}
              >
                <Box>Cash Balance:</Box>
                <Box>
                  {currency.currency}
                  {Number(cashBalance)}
                </Box>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  direction: "row",
                  justifyContent: "space-between",
                  paddingRight: matches ? "30px" : "100px",
                }}
                item
                xs={6}
              >
                <Box>{/* Per Diem without Bills - Taxable: */}</Box>
                <Box>
                  {/* <NumericFormat
                     variant="outlined"
                     id="outlined-basic"
                     label="Enter balance"
                     value={0}
                     style={{ width: "50%" }}
                     //onChange={}
                     onKeyPress={preventMinus}
                     thousandSeparator={true}
                     fullWidth
                     customInput={TextField}
                     className={classes.formControl}
                   /> */}
                </Box>
              </Grid>

              <Grid
                style={{
                  display: "flex",
                  direction: "row",
                  justifyContent: "space-between",
                  paddingRight: matches ? "30px" : "100px",
                }}
                item
                xs={6}
              >
                <Box>{""}</Box>
                <Box></Box>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  direction: "row",
                  justifyContent: "space-between",
                  paddingRight: matches ? "30px" : "100px",
                }}
                item
                xs={6}
              ></Grid>

              <Grid item xs={6}>
                <Box
                  style={{
                    color: "rgba(0, 0, 0, 0.7)",
                    fontSize: matches ? "12px" : "14px",
                  }}
                >
                  *Net Payable: To be refunded to the company.{" "}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  style={{
                    color: "rgba(0, 0, 0, 0.7)",
                    fontSize: matches ? "12px" : "14px",
                  }}
                >
                  *Net Receivable: To be claimed from the company.{" "}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Accordion>

        <Box>
          <Grid
            container
            className={classes.rowContainer}
            style={{
              // marginTop: "30px",
              padding: matches && "0px 20px",
            }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Grid item>
                <Box>
                  <div
                    className={classes.checkBox}
                    style={{
                      margin: matches
                        ? "10px 0px 30px 0px"
                        : "50px 0px 30px 0px",
                    }}
                  >
                    <Checkbox
                      style={{
                        // marginTop: "30px",
                        padding: "0px",
                      }}
                      // color="primary"
                      className={
                        declarationCheckBox !== false
                          ? classes.checkBoxRoot1
                          : ""
                      }
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      onChange={(e) => {
                        handleCheckBox(e);
                      }}
                    />
                    <Typography
                      className={classes.checkBoxText}
                      style={{
                        fontSize: matches ? "10px" : "",
                        lineHeight: matches ? "12px" : "",
                        paddingLeft: matches ? "8px" : "16px",

                        // paddingTop: "90px",
                      }}
                    >
                      I hereby declare that all information given above is true
                      as per my knowledge.
                    </Typography>
                  </div>
                  <Box>
                    <Typography
                      variant="p"
                      style={{
                        fontSize: "13px",
                        color: "red",
                        // marginLeft: "14px",
                        marginRight: "14px",
                        fontFamily: "Montserrat",
                      }}
                    >
                      {declarationError ? declarationError : ""}
                    </Typography>
                  </Box>
                  <Box
                    className={
                      matches
                        ? classes.saveSubmitButtonMobile
                        : classes.saveSubmitButton
                    }
                  >
                    <Box style={{ paddingRight: "10px" }}>
                      <Button
                        variant="outlined"
                        color="#0B1941"
                        style={{
                          minWidth: "140px",
                          minHeight: "36px",
                          border: "1px solid #0B1941",
                          marginRight: matches ? "15px" : "",
                        }}
                        disabled={disableSubmit}
                        onClick={() => {
                          handleSave();
                        }}
                      >
                        Save
                      </Button>
                    </Box>
                    {expenseCategory?.exceptionalApproval == 1 &&
                    claimAmount != "" &&
                    claimAmount > expenseCategory?.policyLimit ? (
                      <>
                        <Button
                          variant="outlined"
                          style={{
                            minWidth: "140px",
                            minHeight: "36px",
                            background: "#CCCCCC",
                            backgroundColor: "#043365",
                            color: "rgba(255, 255, 255, 0.9)",
                          }}
                          onClick={handleOpenEDB}
                        >
                          Submit
                        </Button>
                        {/* Dialog update start */}
                        <Dialog
                          open={openExceptionalDialog}
                          onClose={handleCloseEDB}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            <Typography
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "24px",
                                fontWeight: 700,
                              }}
                            >
                              Exceptional Approval
                            </Typography>
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              This expense requires exceptional approval. It
                              will go to PM for approval.
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleCloseEDB} color="primary">
                              CANCEL
                            </Button>
                            <Button
                              onClick={() => {
                                handleClick();
                              }}
                              color="primary"
                              autoFocus
                            >
                              OK
                            </Button>
                          </DialogActions>
                        </Dialog>
                        {/* Dialog update end */}
                      </>
                    ) : (
                      <Button
                        variant="outlined"
                        style={{
                          minWidth: "140px",
                          minHeight: "36px",
                          background: "#CCCCCC",
                          backgroundColor: "#043365",
                          color: "rgba(255, 255, 255, 0.9)",
                        }}
                        disabled={disableSubmit}
                        onClick={() => {
                          handleClick();
                        }}
                      >
                        Submit
                      </Button>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Snackbar
        open={statusSnackbarOpen}
        onClose={() => handleCloseSnackbarVali(false)}
      >
        {/* {console.log("value of category error" , checkCategoryError,checkErrorInside)} */}
        <Alert
          onClose={() => handleCloseSnackbarVali(false)}
          severity="error"
          sx={{ width: "100%", backgroundColor: "red", color: "white" }}
        >
          {checkErrorInside == true || checkCategoryError == -1
            ? "There might be some unfilled entries."
            : `There might be some unfilled entries in ${selectedCategory[checkCategoryError]?.categoryName} category.`}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ViewReward;