import React, { useEffect, useReducer, useRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import ApproveRejectDialog from "./approveRejectDialog.js";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { NumericFormat } from "react-number-format";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import rows from "../Utils/teamApprovalDummyData.json";
import GetAppIcon from "@material-ui/icons/GetApp";
import Filterimg from "../images/filter.svg";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormGroup,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Popover,
  Popper,
  Select,
  TextField,
  Snackbar,
} from "@material-ui/core";
import SnackBar from "./snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import DateFnsUtils from "@date-io/date-fns";
import SettingsIcon from "@material-ui/icons/Settings";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Switch from "@material-ui/core/Switch";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  getMyExpenseDataTravel,
  getMyTeamApprovalData,
  postApproveRejectExpenseData,
  resetExpenseResponse,
} from "../redux/Expense/action";
import moment from "moment";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import { CustomSwitch } from "./customSwitch.js";
import OutsideClickHandler from "react-outside-click-handler";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { useTheme } from "@material-ui/core/styles";
import Encrypt from "../Utils/encryption.js";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ApproveRejectSnackbar from "./approveRejectSnackbar.js";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    //console.log("descendingComparator: -1");
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    //console.log("descendingComparator: 1");
    return 1;
  }
  return 0;
}

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootFooter}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis?.map((el) => el[0]);
}

function TeamApprovalTableHead(props) {
  const [anchorEl0, setAnchorEl0] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const [anchorEl5, setAnchorEl5] = React.useState(null);
  const [anchorEl6, setAnchorEl6] = React.useState(null);
  const [anchorEl7, setAnchorEl7] = React.useState(null);
  const [anchorEl8, setAnchorEl8] = React.useState(null);
  const [anchorEl9, setAnchorEl9] = React.useState(null);
  const [anchorEl10, setAnchorEl10] = React.useState(null);

  const [criteriaClaimAmount, setCriteriaClaimAmount] = React.useState(0);
  const [operatorClaimAmount, setOperatorClaimAmount] = React.useState("");
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [teamAppPurpose, setTeamAppPurpose] = React.useState(false);
  const [teamAppCreationDate, setTeamAppCreationDate] = React.useState(false);
  const [teamAppAttachments, setTeamAppAttachments] = React.useState(false);
  const [teamAppProjectId, setTeamAppProjectId] = React.useState(false);
  const [openBulkApproveDialog, setOpenBulkApproveDialog] =
    React.useState(false);
  const [systemErrorSnackbar, setSystemErrorSnackbar] = useState(false);

  const [approveOrReject, setApproveOrReject] = React.useState(1);
  const [expenseId, setExpenseId] = React.useState(null);
  const [expenseCategory, setExpenseCategory] = React.useState(null);
  const [buttonState, setButtonState] = React.useState(true);
  const [selectedPolicyLimit, setselectedPolicyLimit] = useState("lteq");
  const [filterOffToggle, setFilterOffToggle] = useState("");
  const [selectedOperatorClaimAmount, setslectedOperatorClaimAmount] =
    useState("lteq");
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowsPerPage,
    pageNumber,
    rowCount,
    onRequestSort,
    onClickFilter,
    getMyTeamApprovalList,
    bulkDownload,
    empId,
    resetBulkDownload,
  } = props;
  const dispatch = useDispatch();

  const headCells = [
    {
      id: "expCode",
      numeric: false,
      disablePadding: true,
      label: "Expense ID",
    },
    {
      id: "categoryName",
      numeric: false,
      disablePadding: false,
      label: "Expense Category",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Employee",
    },
    {
      id: "claimedAmount",
      numeric: true,
      disablePadding: false,
      label: "Claim Amount",
    },
    {
      id: "policyLimit",
      numeric: true,
      disablePadding: false,
      label: "Policy Limit",
    },
    {
      id: "expStatus",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },

    ...(teamAppPurpose == true
      ? [
          {
            id: "purpose",
            numeric: false,
            disablePadding: false,
            label: "Purpose",
          },
        ]
      : []),
    ...(teamAppCreationDate == true
      ? [
          {
            id: "createdOn",
            numeric: false,
            disablePadding: false,
            label: "Creation Date",
          },
        ]
      : []),
    ...(teamAppAttachments == true
      ? [
          {
            id: "attachments",
            numeric: false,
            disablePadding: false,
            label: "Attachment",
          },
        ]
      : []),
    ...(teamAppProjectId == true
      ? [
          {
            id: "projectId",
            numeric: false,
            disablePadding: false,
            label: "Project Code",
          },
        ]
      : []),
    {
      id: "action",
      numeric: true,
      disablePadding: false,
      label: "Actions",
    },
    {
      id: "setting",
      numeric: false,
      disablePadding: false,
    },
  ];

  const headers = [
    {
      label: "Expense Code",
      key: "expCode",
    },
    {
      label: "Category Name",
      key: "categoryName",
    },
    {
      label: "To Location",
      key: "toLocation",
    },
    {
      label: "From Location",
      key: "fromLocation",
    },
    {
      label: "Distance (Km/Miles)",
      key: "distance",
    },
    {
      label: "From Date",
      key: "fromDate",
    },
    {
      label: "To Date",
      key: "toDate",
    },
    {
      label: "Claim Month",
      key: "forMonth",
    },
    {
      label: "Created On",
      key: "createdOn",
    },
    {
      label: "Relationship",
      key: "dependent",
    },
    {
      label: "Currency",
      key: "currencyCode",
    },

    {
      label: "Claimed Amount",
      key: "claimedAmount",
    },
    {
      label: "Policy Limit",
      key: "policyLimit",
    },
    {
      label: "Relationship",
      key: "dependent",
    },
    {
      label: "Purpose",
      key: "purpose",
    },
    {
      label: "Status",
      key: "expStatus",
    },
    {
      label: "Attachments",
      key: "attachments",
    },
    {
      label: "Project ID",
      key: "projectId",
    },
    {
      label: "Employee",
      key: "createdBy",
    },

    // {
    //   label: "expStatusId",
    //   key: "expStatusId",
    // },
  ];

  const csvLink = {
    headers: headers,
    data: bulkDownload,
    filename: `TeamApproval_${moment(new Date()).format("DDMMYYYY_HHmm")}.csv`,
  };

  const createSortHandler = (property) => (event) => {
    //console.log("event", event);
    //console.log("property", property);
  };
  const handleOpenBulkApproveDialog = () => {
    setOpenBulkApproveDialog(true);
  };
  const handleCloseBulkApproveDialog = () => {
    //console.log("openBulkDialog--- in fxn", openBulkApproveDialog);
    setOpenBulkApproveDialog(false);
    // setComments("");
  };

  const handleBulkApprove = async () => {
    let array = [];
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    bulkDownload?.map((item) =>
      array.push({
        recid: Encrypt(key, JSON.stringify(Number(item.uniqueId))),
        expid: Encrypt(key, JSON.stringify(Number(item.expId))),
        projid: Encrypt(key, JSON.stringify(Number(item.projUniqueId))),
      })
    );
    //console.log("array:====> ", array);
    let a = bulkDownload?.map((item) => console.log(" "));
    let dataObj = {
      empId: Encrypt(key, JSON.stringify(empId)),
      comments: "",
      project: array,
    };
    //dispatch(postApproveRejectExpenseData(dataObj));


    dispatch(postApproveRejectExpenseData(dataObj))
      .then((resp) => {
        if (resp.status != 200) {
          setSystemErrorSnackbar(true);
          handleCloseBulkApproveDialog();
        } else {
          // dispatch(resetExpenseResponse());
          resetBulkDownload();
          handleCloseBulkApproveDialog();
        }
      })
      .catch((err) => {
        setSystemErrorSnackbar(true);
        handleCloseBulkApproveDialog();
      });
  };

  //console.log("onClickFilter", onClickFilter);
  const handleEndDateChange = (date) => {
    setButtonState(!(startDate && date));

    setEndDate(date);
  };
  const handleStartDateChange = (date) => {
    setButtonState(!(endDate && date));
    setStartDate(date);
  };
  //console.log("bulkDownload: ---->  ", bulkDownload);
  //console.log("endDate", endDate);
  function handleFilterClaimAmount(name) {
    let tempFilterArray = [];
    if (name == "expCode") {
      tempFilterArray = getMyTeamApprovalList?.filter(
        (item) =>
          item.expCode
            .toLowerCase()
            .includes(criteriaClaimAmount.toLowerCase()) == true
      );
    } else if (name == "claimedAmount") {
      if (selectedOperatorClaimAmount == "gteq") {
        tempFilterArray = getMyTeamApprovalList?.filter(
          (item) => item.claimedAmount >= criteriaClaimAmount
        );
        //console.log("tempFilterArray23", tempFilterArray);
      } else if (selectedOperatorClaimAmount == "lteq") {
        tempFilterArray = getMyTeamApprovalList?.filter(
          (item) => item.claimedAmount <= criteriaClaimAmount
        );
      }
    } else if (name == "policyLimit") {
      // //console.log("getMyteamApprovalList",getMyTeamApprovalList)
      // //console.log("criteriaClaimAmount",criteriaClaimAmount)
      if (selectedPolicyLimit == "gteq") {
        tempFilterArray = getMyTeamApprovalList?.filter(
          (item) => item.policyLimit >= criteriaClaimAmount
        );
      } else if (selectedPolicyLimit == "lteq") {
        tempFilterArray = getMyTeamApprovalList?.filter(
          (item) => item.policyLimit <= criteriaClaimAmount
        );
      }
      //console.log("operatorClaimAmount", operatorClaimAmount);
    } else if (name == "categoryName") {
      // console.log(" ");
      tempFilterArray = getMyTeamApprovalList?.filter(
        (item) => item.categoryName === criteriaClaimAmount
      );
    } else if (name == "createdBy") {
      // tempFilterArray = getMyTeamApprovalList?.filter(
      //   (item) => item.createdBy == criteriaClaimAmount

      // );
      // console.log("Filtering by Employee:", criteriaClaimAmount);
      tempFilterArray = getMyTeamApprovalList?.filter((item) =>
        item.createdBy.toLowerCase().includes(criteriaClaimAmount.toLowerCase())
      );
      // console.log("Filtered Employee Array:", tempFilterArray);
    } else if (name == "expStatus") {
      tempFilterArray = getMyTeamApprovalList?.filter(
        (item) => item.expStatus == criteriaClaimAmount
      );
    } else if (name == "purpose") {
      tempFilterArray = getMyTeamApprovalList?.filter(
        (item) => item.purpose == criteriaClaimAmount
      );
    } else if (name == "createdOn") {
      // console.log("tAppr1 startDate", startDate);
      // console.log("tAppr1 endDate", endDate);

      var d1 = new Date(startDate).toLocaleDateString();
      var d2 = new Date(endDate).toLocaleDateString();
      // console.log("tAppr1 d1", d1);
      // console.log("tAppr1 d2", d2);
      // console.log("tAppr1 createdOn", getMyTeamApprovalList[0].createdOn);
      // console.log(
      //   "tAppr1 createdOn",
      //   new Date(getMyTeamApprovalList[0].createdOn).toLocaleDateString()
      // );

      tempFilterArray = getMyTeamApprovalList?.filter(
        (item) =>
          new Date(item.createdOn).toLocaleDateString() >= d1 &&
          new Date(item.createdOn).toLocaleDateString() <= d2
      );
    } else if (name == "attachments") {
      tempFilterArray = getMyTeamApprovalList?.filter(
        (item) => Number(item.attachments) == Number(criteriaClaimAmount)
      );
    } else if (name == "projectId") {
      tempFilterArray = getMyTeamApprovalList?.filter(
        (item) => item.projectId.includes(criteriaClaimAmount) == true
      );
    }
    //WTD-1 : Add filter for ALL settings columns
    //console.log("tempFilterArray", tempFilterArray);
    onClickFilter(tempFilterArray);
    setFilterOffToggle(name);
    handleClosePopper();
  }
  const handleAddColumn = (name, value) => {
    //console.log("handleAddColumn : name :", name, " value: ", value);
    props.handleSettingsParent(name, value);
    if (name == "purpose") {
      setTeamAppPurpose(value);
    } else if (name == "createdOn") {
      setTeamAppCreationDate(value);
    } else if (name == "attachments") {
      setTeamAppAttachments(value);
    } else if (name == "projectId") {
      setTeamAppProjectId(value);
    }
  };
  const handleClosePopper = () => {
    setAnchorEl0(null);
    setAnchorEl1(null);
    setAnchorEl2(null);
    setAnchorEl3(null);
    setAnchorEl4(null);
    setAnchorEl5(null);
    setAnchorEl6(null);
    setAnchorEl7(null);
    setAnchorEl8(null);
    setAnchorEl9(null);
    setAnchorEl10(null);
    setButtonState(true);
    setExpenseId("");
    setExpenseCategory("");
    setCriteriaClaimAmount("");
    setOperatorClaimAmount("");
    setOpenBulkApproveDialog("");
    setStartDate(null);
    setEndDate(null);
  };
  const handleRemoveFilter = () => {
    onClickFilter(getMyTeamApprovalList);
    handleClosePopper();
  };
  const handleFilterOff = () => {
    onClickFilter(getMyTeamApprovalList);
    handleClosePopper();
    setFilterOffToggle(false);
  };
  const handleFilterPopper0 = (event) => {
    setAnchorEl0(anchorEl0 ? null : event.currentTarget);
  };
  const handleFilterPopper1 = (event) => {
    setAnchorEl1(anchorEl1 ? null : event.currentTarget);
  };
  const handleFilterPopper2 = (event) => {
    setAnchorEl2(anchorEl2 ? null : event.currentTarget);
  };
  const handleFilterPopper3 = (event) => {
    setAnchorEl3(anchorEl3 ? null : event.currentTarget);
  };
  const handleFilterPopper4 = (event) => {
    setAnchorEl4(anchorEl4 ? null : event.currentTarget);
  };
  const handleFilterPopper5 = (event) => {
    setAnchorEl5(anchorEl5 ? null : event.currentTarget);
  };
  const handleFilterPopper7 = (event) => {
    setAnchorEl7(anchorEl7 ? null : event.currentTarget);
  };
  const handleFilterPopper8 = (event) => {
    setAnchorEl8(anchorEl8 ? null : event.currentTarget);
  };
  const handleFilterPopper9 = (event) => {
    setAnchorEl9(anchorEl9 ? null : event.currentTarget);
  };
  const handleFilterPopper10 = (event) => {
    setAnchorEl10(anchorEl10 ? null : event.currentTarget);
  };
  const handleSettingPopper = (event) => {
    setAnchorEl6(anchorEl6 ? null : event.currentTarget);
  };
  const open0 = Boolean(anchorEl0);
  const id0 = open0 ? "simple-popper" : undefined;
  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "simple-popper" : undefined;
  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? "simple-popper" : undefined;
  const open3 = Boolean(anchorEl3);
  const id3 = open3 ? "simple-popper" : undefined;
  const open4 = Boolean(anchorEl4);
  const id4 = open4 ? "simple-popper" : undefined;
  const open5 = Boolean(anchorEl5);
  const id5 = open5 ? "simple-popper" : undefined;
  const open6 = Boolean(anchorEl6);
  const id6 = open6 ? "simple-popper" : undefined;
  const open7 = Boolean(anchorEl7);
  const id7 = open7 ? "simple-popper" : undefined;
  const open8 = Boolean(anchorEl8);
  const id8 = open8 ? "simple-popper" : undefined;
  const open9 = Boolean(anchorEl9);
  const id9 = open9 ? "simple-popper" : undefined;
  const open10 = Boolean(anchorEl10);
  const id10 = open10 ? "simple-popper" : undefined;

  //console.log("criteriaClaimAmount:----", criteriaClaimAmount);
  //console.log("operatorClaimAmount:----", operatorClaimAmount);
  //console.log("numSelected---->", numSelected);
  //console.log("bulk download---->", bulkDownload);
  //console.log("rowCount---->", rowCount);

  const options = [
    {
      label: ">=",

      value: ">=",
    },

    {
      label: "<=",

      value: "<=",
    },
  ];

  const data = [
    {
      label: ">=",
      value: ">=",
    },
    {
      label: "<=",
      value: "<=",
    },
  ];

  const [openAppRejSB, setOpenAppRejSB] = useState(true);

  const isAppRejPostSuccess = useSelector(
    (state) => state.createExpenseData.isApprovedPost
  );
  const AppRejPostResponse = useSelector(
    (state) => state.createExpenseData.postApproveData
  );
  // useEffect(() => {
  //   console.log(
  //     "isAppRejPostSuccess,AppRejPostResponse",
  //     isAppRejPostSuccess,
  //     AppRejPostResponse
  //   );
  // }, [isAppRejPostSuccess]);

  const handleCloseAppRejSB = () => {
    setOpenAppRejSB(false);
  };
  const preventMinus = (e) => {
    if (e.code === "Minus" || e.key === "-") {
      e.preventDefault();
    }
  };
  // //console.log(" rowsPerPage",  rowsPerPage)
  return (
    <>
      {isAppRejPostSuccess && AppRejPostResponse.comments == "" ? (
        <ApproveRejectSnackbar
          handleCloseAppRejSB={handleCloseAppRejSB}
          openAppRejSB={openAppRejSB}
          approveOrReject={1}
        />
      ) : isAppRejPostSuccess && AppRejPostResponse.comments != "" ? (
        <ApproveRejectSnackbar
          handleCloseAppRejSB={handleCloseAppRejSB}
          openAppRejSB={openAppRejSB}
          approveOrReject={0}
        />
      ) : (
        ""
      )}
      <Snackbar
        open={systemErrorSnackbar}
        onClose={() => setSystemErrorSnackbar(false)}
      >
        <Alert
          onClose={() => setSystemErrorSnackbar(false)}
          severity="error"
          sx={{ width: "100%", backgroundColor: "red", color: "white" }}
        >
          There is some problem with the application. Please connect with System
          Admin.
        </Alert>
      </Snackbar>
      <TableHead>
        {bulkDownload?.length > 0 && (
          <TableRow style={{ height: 58 }}>
            <TableCell style={{ background: "#F4F4F4" }} />
            <TableCell
              padding="checkbox"
              style={{
                minWidth: "53px",
              }}
              className={classes.stickyLeft}
            >
              {numSelected === rowsPerPage ||
              (pageNumber === Math.ceil(rowCount / rowsPerPage) - 1 &&
                numSelected === rowCount % rowsPerPage) ? (
                <IconButton style={{ maxWidth: "40px", maxHeight: "40px" }}>
                  {/* <span
                 
                  onClick={onSelectAllClick}
                  class="material-symbols-outlined"
                >
                  indeterminate_check_box
                </span> */}
                  <CheckBoxIcon
                    fontSize="small"
                    onClick={onSelectAllClick}
                    className={classes.checkBoxRoot}
                  />
                </IconButton>
              ) : (
                <Checkbox
                  // disabled={numSelected > 0 && numSelected < rowCount}
                  checkedIcon={
                    <CheckBoxIcon
                      fontSize="small"
                      className={classes.checkBoxRoot}
                    />
                  }
                  checked={numSelected?.length >= 4}
                  onChange={onSelectAllClick}
                  inputProps={{ "aria-label": "select all desserts" }}
                />
              )}
            </TableCell>
            {bulkDownload.findIndex(
              (item) => item.expStatus != "In Progress"
            ) == -1 ? (
              <TableCell style={{ paddingRight: "0px" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    minWidth: "128px",
                    maxHeight: "32px",
                    whiteSpace: "nowrap",
                    backgroundColor: "#349259",
                    color: "white",
                    marginLeft: "-16px",
                    padding: "8px",
                  }}
                  // onClick={() => handleBulkApprove()}
                  onClick={() => handleOpenBulkApproveDialog()}
                >
                  <CheckCircleOutlineIcon
                    style={{
                      maxWidth: "13.33px",
                      maxHeight: "13.33px",
                      backgroundColor: "#349259",
                      color: "white",
                    }}
                  />
                  <Typography style={{ fontSize: "10px", fontWeight: 500 }}>
                    Approve Expenses
                  </Typography>
                </Button>
                <ApproveRejectDialog
                  openBulkApproveDialog={openBulkApproveDialog}
                  approveOrReject={approveOrReject}
                  bulkDownload={bulkDownload}
                  handleBulkApprove={handleBulkApprove}
                  handleCloseBulkApproveDialog={handleCloseBulkApproveDialog}
                />
              </TableCell>
            ) : (
              ""
            )}

            <TableCell>
              <CSVLink {...csvLink} style={{ color: "transparent" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    minWidth: "128px",
                    maxHeight: "32px",
                    whiteSpace: "nowrap",
                    backgroundColor: "#0B1941",
                    color: "#043365",
                    border: "1px solid #979797",
                    padding: "8px",
                  }}
                >
                  <GetAppIcon
                    style={{
                      width: "16px",
                      height: "16px",
                      color: "#FFFFFF",
                      backgroundColor: "#0B1941",
                    }}
                  />

                  <Typography
                    style={{
                      fontSize: "10px",
                      fontWeight: 500,
                      color: "#FFFFFF",
                      backgroundColor: "#0B1941",
                    }}
                  >
                    Export Expense
                  </Typography>
                </Button>
              </CSVLink>
            </TableCell>
            <TableCell>
              <Typography variant="p" style={{ maxWidth: "120px" }}>
                {bulkDownload?.length}
                {" Selected"}
              </Typography>
            </TableCell>
          </TableRow>
        )}
        {bulkDownload?.length == 0 && (
          <TableRow style={{ height: 58 }}>
            <TableCell style={{ background: "#F4F4F4" }} />
            <TableCell
              padding="checkbox"
              style={{
                minWidth: "53px",
                background: teamAppPurpose ? "white" : "EFF2F8",
              }}
              className={
                teamAppPurpose == true ||
                teamAppCreationDate == true ||
                teamAppAttachments == true ||
                teamAppProjectId == true
                  ? classes.stickyLeft
                  : classes.nonSticky //copy this-Kush
              }
              onClick={() => {
                //console.log("hi table roe clicked!", teamAppPurpose);
              }}
            >
              <Checkbox
                // disabled={numSelected > 0 && numSelected < rowCount}
                checkedIcon={
                  <CheckBoxIcon
                    fontSize="small"
                    className={classes.checkBoxRoot}
                  />
                }
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ "aria-label": "select all desserts" }}
              />
            </TableCell>

            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={"left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                style={{
                  // position:
                  //   headCell.id == "action" || headCell.id == "setting"
                  //     ? "sticky"
                  //     : "",
                  right:
                    headCell.id == "setting"
                      ? 0
                      : headCell.id == "action"
                      ? "55px"
                      : "",
                  // backgroundColor:
                  //   headCell.id == "action" || headCell.id == "setting"
                  //     ? "#FFFFFF"
                  //     : "#F4F4F4",

                  minWidth:
                    headCell.id == "setting"
                      ? "53px"
                      : headCell.id == "expCode"
                      ? "135px"
                      : headCell.id == "action"
                      ? "80px"
                      : "120px",
                }}
                className={
                  (headCell.id == "action" || headCell.id == "setting") &&
                  (teamAppPurpose == true ||
                    teamAppCreationDate == true ||
                    teamAppAttachments == true ||
                    teamAppProjectId == true)
                    ? classes.stickyLeft
                    : classes.nonSticky
                }
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent:
                      headCell.id == "action" ||
                      headCell.id == "expStatus" ||
                      headCell.id == "policyLimit"
                        ? "center"
                        : "",
                    // paddingRight:headCell.id=="expStatus"?(window.screen.availWidth==1280?"":window.screen.availWidth==1536?"":"30px"):""
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      lineHeight: "17px",
                      letterSpacing: "0em",
                      // textAlign: headCell.id =="claimedAmount" || headCell.id=="policyLimit"?"right":"left",
                    }}
                  >
                    {headCell.label}
                  </Typography>
                  {headCell.id === "expCode" ? (
                    filterOffToggle == "expCode" ? (
                      <IconButton
                        aria-label="filter list"
                        onClick={handleFilterOff}
                        style={{ color: "#BB271A", padding: "5px" }}
                      >
                        <span class="material-symbols-outlined" color="#BB271A">
                          filter_alt_off
                        </span>
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="filter list"
                        onClick={handleFilterPopper0}
                        style={{
                          color: "#666666",
                          padding: "5px",
                          size: "small",
                        }}
                      >
                        <span class="material-symbols-outlined">
                          filter_alt
                        </span>
                      </IconButton>
                    )
                  ) : headCell.id === "categoryName" ? (
                    filterOffToggle == "categoryName" ? (
                      <IconButton
                        aria-label="filter list"
                        onClick={handleFilterOff}
                        style={{ color: "#BB271A", padding: "5px" }}
                      >
                        <span class="material-symbols-outlined" color="#BB271A">
                          filter_alt_off
                        </span>
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="filter list"
                        onClick={handleFilterPopper1}
                        style={{
                          color: "#666666",
                          padding: "5px",
                          size: "small",
                        }}
                      >
                        <span class="material-symbols-outlined">
                          filter_alt
                        </span>
                      </IconButton>
                    )
                  ) : headCell.id === "createdBy" ? (
                    filterOffToggle == "createdBy" ? (
                      <IconButton
                        aria-label="filter list"
                        onClick={handleFilterOff}
                        style={{ color: "#BB271A", padding: "5px" }}
                      >
                        <span class="material-symbols-outlined" color="#BB271A">
                          filter_alt_off
                        </span>
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="filter list"
                        onClick={handleFilterPopper2}
                        style={{
                          color: "#666666",
                          padding: "5px",
                          size: "small",
                        }}
                      >
                        <span class="material-symbols-outlined">
                          filter_alt
                        </span>
                      </IconButton>
                    )
                  ) : headCell.id === "claimedAmount" ? (
                    filterOffToggle == "claimedAmount" ? (
                      <IconButton
                        aria-label="filter list"
                        onClick={handleFilterOff}
                        style={{ color: "#BB271A", padding: "5px" }}
                      >
                        <span class="material-symbols-outlined" color="#BB271A">
                          filter_alt_off
                        </span>
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="filter list"
                        onClick={handleFilterPopper3}
                        style={{
                          color: "#666666",
                          padding: "5px",
                          size: "small",
                        }}
                      >
                        <span class="material-symbols-outlined">
                          filter_alt
                        </span>
                      </IconButton>
                    )
                  ) : headCell.id === "policyLimit" ? (
                    filterOffToggle == "policyLimit" ? (
                      <IconButton
                        aria-label="filter list"
                        onClick={handleFilterOff}
                        style={{ color: "#BB271A", padding: "5px" }}
                      >
                        <span class="material-symbols-outlined" color="#BB271A">
                          filter_alt_off
                        </span>
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="filter list"
                        onClick={handleFilterPopper4}
                        style={{
                          color: "#666666",
                          padding: "5px",
                          size: "small",
                        }}
                      >
                        <span class="material-symbols-outlined">
                          filter_alt
                        </span>
                      </IconButton>
                    )
                  ) : headCell.id === "expStatus" ? (
                    filterOffToggle == "expStatus" ? (
                      <IconButton
                        aria-label="filter list"
                        onClick={handleFilterOff}
                        style={{ color: "#BB271A", padding: "5px" }}
                      >
                        <span class="material-symbols-outlined" color="#BB271A">
                          filter_alt_off
                        </span>
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="filter list"
                        onClick={handleFilterPopper5}
                        style={{
                          color: "#666666",
                          padding: "5px",
                          size: "small",
                        }}
                      >
                        <span class="material-symbols-outlined">
                          filter_alt
                        </span>
                      </IconButton>
                    )
                  ) : headCell.id === "purpose" ? (
                    filterOffToggle == "purpose" ? (
                      <IconButton
                        aria-label="filter list"
                        onClick={handleFilterOff}
                        style={{ color: "#BB271A", padding: "5px" }}
                      >
                        <span class="material-symbols-outlined" color="#BB271A">
                          filter_alt_off
                        </span>
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="filter list"
                        onClick={handleFilterPopper7}
                        style={{
                          color: "#666666",
                          padding: "5px",
                          size: "small",
                        }}
                      >
                        <span class="material-symbols-outlined">
                          filter_alt
                        </span>
                      </IconButton>
                    )
                  ) : headCell.id === "createdOn" ? (
                    filterOffToggle == "createdOn" ? (
                      <IconButton
                        aria-label="filter list"
                        onClick={handleFilterOff}
                        style={{ color: "#BB271A", padding: "5px" }}
                      >
                        <span class="material-symbols-outlined" color="#BB271A">
                          filter_alt_off
                        </span>
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="filter list"
                        onClick={handleFilterPopper8}
                        style={{
                          color: "#666666",
                          padding: "5px",
                          size: "small",
                        }}
                      >
                        <span class="material-symbols-outlined">
                          filter_alt
                        </span>
                      </IconButton>
                    )
                  ) : headCell.id === "attachments" ? (
                    filterOffToggle == "attachments" ? (
                      <IconButton
                        aria-label="filter list"
                        onClick={handleFilterOff}
                        style={{ color: "#BB271A", padding: "5px" }}
                      >
                        <span class="material-symbols-outlined" color="#BB271A">
                          filter_alt_off
                        </span>
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="filter list"
                        onClick={handleFilterPopper9}
                        style={{
                          color: "#666666",
                          padding: "5px",
                          size: "small",
                        }}
                      >
                        <span class="material-symbols-outlined">
                          filter_alt
                        </span>
                      </IconButton>
                    )
                  ) : headCell.id === "projectId" ? (
                    filterOffToggle == "projectId" ? (
                      <IconButton
                        aria-label="filter list"
                        onClick={handleFilterOff}
                        style={{ color: "#BB271A", padding: "5px" }}
                      >
                        <span class="material-symbols-outlined" color="#BB271A">
                          filter_alt_off
                        </span>
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="filter list"
                        onClick={handleFilterPopper10}
                        style={{
                          color: "#666666",
                          padding: "5px",
                          size: "small",
                        }}
                      >
                        <span class="material-symbols-outlined">
                          filter_alt
                        </span>
                      </IconButton>
                    )
                  ) : headCell.id === "setting" ? (
                    <SettingsIcon onClick={handleSettingPopper} />
                  ) : (
                    ""
                  )}
                </Box>
                {/* {//console.log("headcell.id: ", headCell.id)} */}

                {headCell.id === "expCode" ? (
                  <Box>
                    <Popover
                      id={id0}
                      open={open0}
                      onClose={handleClosePopper}
                      anchorEl={anchorEl0}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Paper
                        style={{
                          width: "300px",
                          height: "277px",
                        }}
                      >
                        <Box>
                          <Box
                            boxShadow={1}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "15px",
                            }}
                          >
                            <Box>
                              <span class="material-symbols-outlined">
                                filter_alt
                              </span>
                            </Box>
                            <Box>
                              <Typography
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  lineHeight: "28px",
                                  letterSpacing: "0px",
                                  textAlign: "left",
                                  color: "#0B1941",
                                  paddingRight: "70px",
                                }}
                              >
                                Expense ID
                              </Typography>
                            </Box>
                            <Box>
                              <Button>
                                <CloseIcon onClick={handleClosePopper} />
                              </Button>
                            </Box>
                          </Box>

                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "24px",
                            }}
                          >
                            <TextField
                              id="outlined-basic"
                              label="Search the ID"
                              variant="outlined"
                              style={{
                                width: "244px",
                                height: "52px",
                                marginLeft: "28px",
                                // marginTop: "8px",
                                color: "#0B1941",
                              }}
                              onChange={(e) => {
                                if (e.target.value.length >= 1) {
                                  setButtonState(false);
                                }
                                if (e.target.value.length == 0) {
                                  setButtonState(true);
                                }
                                setCriteriaClaimAmount(
                                  e.target.value.trimStart()
                                );
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    style={{ marginRight: "-12px" }}
                                  >
                                    <IconButton>
                                      <SearchIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                              marginTop: "37px",
                            }}
                          >
                            <Button
                              variant="outlined"
                              onClick={handleRemoveFilter}
                              style={{
                                background: "#FFFFFF",
                                color: "#0B1941",
                                borderRadius: "4px",
                                border: "1px solid #0B1941",
                              }}
                            >
                              Remove Filter
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              disabled={buttonState}
                              style={{
                                background: buttonState ? "#9E9E9E" : "#0B1941",
                                color: "#FFFFFF",
                                borderRadius: "4px",
                              }}
                              onClick={() =>
                                handleFilterClaimAmount(headCell.id)
                              }
                            >
                              Apply Filter
                            </Button>
                          </Box>
                        </Box>
                      </Paper>
                    </Popover>
                  </Box>
                ) : (
                  ""
                )}
                {headCell.id === "categoryName" ? (
                  <Box>
                    <Popover
                      id={id1}
                      open={open1}
                      onClose={handleClosePopper}
                      anchorEl={anchorEl1}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Paper
                        style={{
                          width: "300px",
                          height: "277px",
                        }}
                      >
                        <Box>
                          <Box
                            boxShadow={1}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "15px",
                            }}
                          >
                            <Box>
                              {/* <FilterListIcon /> */}
                              {/* <img src={Filterimg} className={classes.filterImg} /> */}
                              <span class="material-symbols-outlined">
                                filter_alt
                              </span>
                            </Box>
                            <Box>
                              <Typography
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  lineHeight: "28px",
                                  letterSpacing: "0px",
                                  textAlign: "left",
                                }}
                              >
                                Expense Category
                              </Typography>
                            </Box>
                            <Box>
                              <Button>
                                <CloseIcon onClick={handleClosePopper} />
                              </Button>
                            </Box>
                          </Box>

                          <Box style={{ display: "grid", marginTop: "24px" }}>
                            <FormControl
                              variant="outlined"
                              className={classes.formControl}
                            >
                              <InputLabel id="demo-simple-select-outlined-label">
                                Search a Category
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                label="Expense Category"
                                onChange={(e) => {
                                  if (e.target.value.length >= 1) {
                                    setButtonState(false);
                                  }
                                  if (e.target.value.length == 0) {
                                    setButtonState(true);
                                  }

                                  setCriteriaClaimAmount(e.target.value);
                                }}
                              >
                                {[
                                  ...new Map(
                                    getMyTeamApprovalList?.map((item) => [
                                      item[headCell.id],
                                      item,
                                    ])
                                  ).values(),
                                ]
                                  .sort((a, b) =>
                                    a.categoryName.localeCompare(b.categoryName)
                                  )
                                  .map((cat) => {
                                    return (
                                      <MenuItem
                                        key={cat.id}
                                        value={cat.categoryName}
                                      >
                                        {cat.categoryName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                              marginTop: "37px",
                            }}
                          >
                            <Button
                              variant="outlined"
                              onClick={handleRemoveFilter}
                              style={{
                                background: "#FFFFFF",
                                color: "#0B1941",
                                borderRadius: "4px",
                                border: "1px solid #0B1941",
                              }}
                            >
                              Remove Filter
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              disabled={buttonState}
                              style={{
                                background: buttonState ? "#9E9E9E" : "#0B1941",
                                color: "#FFFFFF",
                                borderRadius: "4px",
                              }}
                              onClick={() =>
                                handleFilterClaimAmount(headCell.id)
                              }
                            >
                              Apply Filter
                            </Button>
                          </Box>
                        </Box>
                      </Paper>
                    </Popover>
                  </Box>
                ) : (
                  ""
                )}
                {headCell.id === "createdBy" ? (
                  <Box>
                    <Popover
                      id={id2}
                      open={open2}
                      onClose={handleClosePopper}
                      anchorEl={anchorEl2}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Paper
                        style={{
                          width: "300px",
                          height: "277px",
                        }}
                      >
                        <Box>
                          <Box
                            boxShadow={1}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "15px",
                            }}
                          >
                            <Box>
                              {/* <FilterListIcon /> */}
                              {/* <img src={Filterimg} className={classes.filterImg} /> */}
                              <span class="material-symbols-outlined">
                                filter_alt
                              </span>
                            </Box>
                            <Box>
                              <Typography
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  lineHeight: "28px",
                                  letterSpacing: "0px",
                                  textAlign: "left",
                                  paddingRight: "70px",
                                }}
                              >
                                Employee
                              </Typography>
                            </Box>
                            <Box>
                              <Button onClick={handleClosePopper}>
                                <CloseIcon />
                              </Button>
                            </Box>
                          </Box>
                          {/* <Box>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "24px",
                              letterSpacing: "0px",
                              textAlign: "left",
                              marginLeft: "25px",
                              marginTop: "16px",
                            }}
                          >
                            Employee
                          </Typography>
                        </Box> */}
                          {/* <Box style={{ display: "grid", marginTop: "24px" }}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <InputLabel id="demo-simple-select-outlined-label">
                              Employee
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              label="Employee"
                              onChange={(e) => {
                                if (e.target.value.length >= 1) {
                                  setButtonState(false);
                                }
                                if (e.target.value.length == 0) {
                                  setButtonState(true);
                                }

                                  setCriteriaClaimAmount(e.target.value);
                                }}
                              >
                                {[
                                  ...new Map(
                                    getMyTeamApprovalList?.map((item) => [
                                      item[headCell.id],
                                      item,
                                    ])
                                  ).values(),
                                ]
                                  .sort((a, b) =>
                                    a.createdBy.localeCompare(b.createdBy)
                                  )
                                  .map((cat) => {
                                    return (
                                      <MenuItem
                                        key={cat.id}
                                        value={cat.createdBy}
                                      >
                                        {cat.createdBy}
                                      </MenuItem>
                                    );
                                  })}

                              </Select>
                            </FormControl>
                          </Box> */}{" "}
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "24px",
                            }}
                          >
                            <TextField
                              id="outlined-basic"
                              label="Search Employee"
                              variant="outlined"
                              style={{
                                width: "244px",
                                height: "52px",
                                marginLeft: "28px",
                                // marginTop: "8px",
                                color: "#0B1941",
                              }}
                              onChange={(e) => {
                                if (e.target.value.length >= 1) {
                                  setButtonState(false);
                                }
                                if (e.target.value.length == 0) {
                                  setButtonState(true);
                                }
                                setCriteriaClaimAmount(
                                  e.target.value.trimStart()
                                );
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    style={{ marginRight: "-12px" }}
                                  >
                                    <IconButton>
                                      <SearchIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                              marginTop: "37px",
                            }}
                          >
                            <Button
                              variant="outlined"
                              onClick={handleRemoveFilter}
                              style={{
                                background: "#FFFFFF",
                                color: "#0B1941",
                                borderRadius: "4px",
                                border: "1px solid #0B1941",
                              }}
                            >
                              Remove Filter
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              disabled={buttonState}
                              style={{
                                background: buttonState ? "#9E9E9E" : "#0B1941",
                                color: "#FFFFFF",
                                borderRadius: "4px",
                              }}
                              onClick={() =>
                                handleFilterClaimAmount(headCell.id)
                              }
                            >
                              Apply Filter
                            </Button>
                          </Box>
                        </Box>
                      </Paper>
                    </Popover>
                  </Box>
                ) : (
                  ""
                )}
                {headCell.id === "claimedAmount" ? (
                  <Box>
                    <Popover
                      id={id3}
                      open={open3}
                      onClose={handleClosePopper}
                      anchorEl={anchorEl3}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Paper
                        style={{
                          width: "300px",
                          height: "277px",
                        }}
                      >
                        <Box>
                          <Box
                            boxShadow={1}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "15px",
                            }}
                          >
                            <Box>
                              {/* <FilterListIcon /> */}
                              {/* <img src={Filterimg} className={classes.filterImg} /> */}
                              <span class="material-symbols-outlined">
                                filter_alt
                              </span>
                            </Box>
                            <Box>
                              <Typography
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  lineHeight: "28px",
                                  letterSpacing: "0px",
                                  textAlign: "left",
                                  paddingRight: "42px",
                                }}
                              >
                                Claim Amount
                              </Typography>
                            </Box>
                            <Box>
                              <Button>
                                <CloseIcon onClick={handleClosePopper} />
                              </Button>
                            </Box>
                          </Box>
                          {/* <Box>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "24px",
                              letterSpacing: "0px",
                              textAlign: "left",
                              marginLeft: "25px",
                              marginTop: "16px",
                            }}
                          >
                            Claim Amount
                          </Typography> 
                        </Box>*/}
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "24px",
                            }}
                          >
                            <FormControl
                              variant="outlined"
                              className={classes.formControlAmount}
                            >
                              <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                options={options}
                                value={selectedOperatorClaimAmount}
                                onChange={(e) => {
                                  setslectedOperatorClaimAmount(e.target.value);
                                }}
                              >
                                <MenuItem
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                  }}
                                  value={"gteq"}
                                >
                                  {">="}
                                </MenuItem>
                                <MenuItem
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                  }}
                                  value={"lteq"}
                                >
                                  {" "}
                                  {"<="}{" "}
                                </MenuItem>
                              </Select>
                            </FormControl>
                            <NumericFormat
                              id="outlined-basic"
                              label="Enter Amount"
                              customInput={TextField}
                              onKeyPress={preventMinus}
                              variant="outlined"
                              style={{
                                width: "153px",
                                height: "52px",
                                marginLeft: "16px",
                                color: "#0B1941",
                              }}
                              onChange={(e) => {
                                if (e.target.value.length >= 1) {
                                  setButtonState(false);
                                }
                                if (e.target.value.length == 0) {
                                  setButtonState(true);
                                }

                                setCriteriaClaimAmount(e.target.value);
                              }}
                            />
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                              marginTop: "37px",
                            }}
                          >
                            <Button
                              variant="outlined"
                              onClick={handleRemoveFilter}
                              style={{
                                background: "#FFFFFF",
                                color: "#0B1941",
                                borderRadius: "4px",
                                border: "1px solid #0B1941",
                              }}
                            >
                              Remove Filter
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              disabled={buttonState}
                              style={{
                                background: buttonState ? "#9E9E9E" : "#0B1941",
                                color: "#FFFFFF",
                                borderRadius: "4px",
                              }}
                              onClick={() =>
                                handleFilterClaimAmount(headCell.id)
                              }
                            >
                              Apply Filter
                            </Button>
                          </Box>
                        </Box>
                      </Paper>
                    </Popover>
                  </Box>
                ) : (
                  ""
                )}
                {headCell.id === "policyLimit" ? (
                  <Box>
                    <Popover
                      id={id4}
                      open={open4}
                      onClose={handleClosePopper}
                      anchorEl={anchorEl4}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Paper
                        style={{
                          width: "300px",
                          height: "277px",
                        }}
                      >
                        <Box>
                          <Box
                            boxShadow={1}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "15px",
                            }}
                          >
                            <Box>
                              {/* <FilterListIcon /> */}
                              {/* <img src={Filterimg} className={classes.filterImg} /> */}
                              <span class="material-symbols-outlined">
                                filter_alt
                              </span>
                            </Box>
                            <Box>
                              <Typography
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  lineHeight: "28px",
                                  letterSpacing: "0px",
                                  textAlign: "left",
                                  paddingRight: "60px",
                                }}
                              >
                                Policy Limit
                              </Typography>
                            </Box>
                            <Box>
                              <Button>
                                <CloseIcon onClick={handleClosePopper} />
                              </Button>
                            </Box>
                          </Box>
                          {/* <Box>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "24px",
                              letterSpacing: "0px",
                              textAlign: "left",
                              marginLeft: "25px",
                              marginTop: "16px",
                            }}
                          >
                            Policy Limit
                          </Typography>
                        </Box> */}
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "24px",
                            }}
                          >
                            <FormControl
                              variant="outlined"
                              className={classes.formControlAmount}
                            >
                              <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                options={data}
                                value={selectedPolicyLimit}
                                onChange={(e) => {
                                  setselectedPolicyLimit(e.target.value);
                                }}
                              >
                                <MenuItem
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                  }}
                                  value={"gteq"}
                                >
                                  {">="}
                                </MenuItem>
                                <MenuItem
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                  }}
                                  value={"lteq"}
                                >
                                  {" "}
                                  {"<="}{" "}
                                </MenuItem>
                              </Select>
                            </FormControl>
                            <NumericFormat
                              id="outlined-basic"
                              label="Policy Limit"
                              customInput={TextField}
                              onKeyPress={preventMinus}
                              variant="outlined"
                              style={{
                                width: "153px",
                                height: "52px",
                                marginLeft: "28px",
                                color: "#0B1941",
                              }}
                              onChange={(e) => {
                                if (e.target.value.length >= 1) {
                                  setButtonState(false);
                                }
                                if (e.target.value.length == 0) {
                                  setButtonState(true);
                                }

                                setCriteriaClaimAmount(e.target.value);
                              }}
                            />
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                              marginTop: "37px",
                            }}
                          >
                            <Button
                              variant="outlined"
                              onClick={handleRemoveFilter}
                              style={{
                                background: "#FFFFFF",
                                color: "#0B1941",
                                borderRadius: "4px",
                                border: "1px solid #0B1941",
                              }}
                            >
                              Remove Filter
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              disabled={buttonState}
                              style={{
                                background: buttonState ? "#9E9E9E" : "#0B1941",
                                color: "#FFFFFF",
                                borderRadius: "4px",
                              }}
                              onClick={() =>
                                handleFilterClaimAmount(headCell.id)
                              }
                            >
                              Apply Filter
                            </Button>
                          </Box>
                        </Box>
                      </Paper>
                    </Popover>
                  </Box>
                ) : (
                  ""
                )}
                {headCell.id === "expStatus" ? (
                  <Box>
                    <Popover
                      id={id5}
                      open={open5}
                      onClose={handleClosePopper}
                      anchorEl={anchorEl5}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Paper
                        style={{
                          width: "300px",
                          height: "277px",
                        }}
                      >
                        <Box>
                          <Box
                            boxShadow={1}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "15px",
                            }}
                          >
                            <Box>
                              {/* <FilterListIcon /> */}
                              {/* <img src={Filterimg} className={classes.filterImg} /> */}
                              <span class="material-symbols-outlined">
                                filter_alt
                              </span>
                            </Box>
                            <Box>
                              <Typography
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  lineHeight: "28px",
                                  letterSpacing: "0px",
                                  textAlign: "left",
                                  paddingRight: "100px",
                                }}
                              >
                                Status
                              </Typography>
                            </Box>
                            <Box>
                              <Button>
                                <CloseIcon onClick={handleClosePopper} />
                              </Button>
                            </Box>
                          </Box>
                          {/* <Box>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "24px",
                              letterSpacing: "0px",
                              textAlign: "left",
                              marginLeft: "25px",
                              marginTop: "16px",
                            }}
                          >
                            Status
                          </Typography>
                        </Box> */}
                          <Box style={{ display: "grid", marginTop: "24px" }}>
                            <FormControl
                              variant="outlined"
                              className={classes.formControl}
                            >
                              <InputLabel id="demo-simple-select-outlined-label">
                                STATUS
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                label="Status"
                                onChange={(e) => {
                                  if (e.target.value.length >= 1) {
                                    setButtonState(false);
                                  }
                                  if (e.target.value.length == 0) {
                                    setButtonState(true);
                                  }

                                  setCriteriaClaimAmount(e.target.value);
                                }}
                              >
                                {[
                                  ...new Map(
                                    getMyTeamApprovalList?.map((item) => [
                                      item[headCell.id],
                                      item,
                                    ])
                                  ).values(),
                                ]
                                  .sort((a, b) =>
                                    a.expStatus.localeCompare(b.expStatus)
                                  )
                                  .map((cat) => {
                                    return (
                                      <MenuItem
                                        key={cat.id}
                                        value={cat.expStatus}
                                      >
                                        {cat.expStatus}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                              marginTop: "37px",
                            }}
                          >
                            <Button
                              variant="outlined"
                              onClick={handleRemoveFilter}
                              style={{
                                background: "#FFFFFF",
                                color: "#0B1941",
                                borderRadius: "4px",
                                border: "1px solid #0B1941",
                              }}
                            >
                              Remove Filter
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              disabled={buttonState}
                              style={{
                                background: buttonState ? "#9E9E9E" : "#0B1941",
                                color: "#FFFFFF",
                                borderRadius: "4px",
                              }}
                              onClick={() =>
                                handleFilterClaimAmount(headCell.id)
                              }
                            >
                              Apply Filter
                            </Button>
                          </Box>
                        </Box>
                      </Paper>
                    </Popover>
                  </Box>
                ) : (
                  ""
                )}
                {headCell.id === "purpose" ? (
                  <Box>
                    <Popover
                      id={id7}
                      open={open7}
                      onClose={handleClosePopper}
                      anchorEl={anchorEl7}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Paper
                        style={{
                          width: "300px",
                          height: "277px",
                        }}
                      >
                        <Box>
                          <Box
                            boxShadow={1}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "15px",
                            }}
                          >
                            <Box>
                              {/* <FilterListIcon /> */}
                              {/* <img src={Filterimg} className={classes.filterImg} /> */}
                              <span class="material-symbols-outlined">
                                filter_alt
                              </span>
                            </Box>
                            <Box>
                              <Typography
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  lineHeight: "28px",
                                  letterSpacing: "0px",
                                  textAlign: "left",
                                  paddingRight: "95px",
                                }}
                              >
                                Purpose
                              </Typography>
                            </Box>
                            <Box>
                              <Button>
                                <CloseIcon onClick={handleClosePopper} />
                              </Button>
                            </Box>
                          </Box>
                          {/* <Box>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "24px",
                              letterSpacing: "0px",
                              textAlign: "left",
                              marginLeft: "25px",
                              marginTop: "16px",
                            }}
                          >
                            Purpose
                          </Typography> 
                        </Box>*/}
                          <Box style={{ display: "grid", marginTop: "24px" }}>
                            <FormControl
                              variant="outlined"
                              className={classes.formControl}
                            >
                              <InputLabel id="demo-simple-select-outlined-label">
                                Purpose
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                label="Purpose"
                                onChange={(e) => {
                                  if (e.target.value.length >= 1) {
                                    setButtonState(false);
                                  }
                                  if (e.target.value.length == 0) {
                                    setButtonState(true);
                                  }

                                  setCriteriaClaimAmount(e.target.value);
                                }}
                              >
                                {[
                                  ...new Map(
                                    getMyTeamApprovalList?.map((item) => [
                                      item[headCell.id],
                                      item,
                                    ])
                                  ).values(),
                                ].map((cat) => {
                                  return (
                                    <MenuItem key={cat.id} value={cat.purpose}>
                                      {cat.purpose}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                              marginTop: "37px",
                            }}
                          >
                            <Button
                              variant="outlined"
                              onClick={handleRemoveFilter}
                              style={{
                                background: "#FFFFFF",
                                color: "#0B1941",
                                borderRadius: "4px",
                                border: "1px solid #0B1941",
                              }}
                            >
                              Remove Filter
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              disabled={buttonState}
                              style={{
                                background: buttonState ? "#9E9E9E" : "#0B1941",
                                color: "#FFFFFF",
                                borderRadius: "4px",
                              }}
                              onClick={() =>
                                handleFilterClaimAmount(headCell.id)
                              }
                            >
                              Apply Filter
                            </Button>
                          </Box>
                        </Box>
                      </Paper>
                    </Popover>
                  </Box>
                ) : (
                  ""
                )}
                {headCell.id === "createdOn" ? (
                  <Box>
                    <Popover
                      id={id8}
                      open={open8}
                      onClose={handleClosePopper}
                      anchorEl={anchorEl8}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Paper
                        style={{
                          width: "300px",
                          height: "334px",
                        }}
                      >
                        <Box>
                          <Box
                            boxShadow={1}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "15px",
                            }}
                          >
                            <Box>
                              {/* <FilterListIcon /> */}
                              {/* <img src={Filterimg} className={classes.filterImg} /> */}
                              <span class="material-symbols-outlined">
                                filter_alt
                              </span>
                            </Box>
                            <Box>
                              <Typography
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  lineHeight: "28px",
                                  letterSpacing: "0px",
                                  textAlign: "left",
                                  paddingRight: "60px",
                                }}
                              >
                                Creation Date
                              </Typography>
                            </Box>
                            <Box>
                              <Button>
                                <CloseIcon onClick={handleClosePopper} />
                              </Button>
                            </Box>
                          </Box>
                          {/* <Box>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "24px",
                              letterSpacing: "0px",
                              textAlign: "left",
                              marginLeft: "25px",
                              marginTop: "16px",
                            }}
                          >
                            Creation Date
                          </Typography>
                        </Box> */}

                          <Box style={{ display: "grid", marginTop: "24px" }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disableToolbar
                                inputVariant="outlined"
                                style={{
                                  maxWidth: "251px",
                                  maxHeight: "53px",
                                }}
                                className={classes.formControl}
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                autoOk={true}
                                changeYear={true}
                                changeMonth={true}
                                disableFuture={true}
                                id="date-picker-inline"
                                label="Choose Start Date"
                                value={startDate}
                                onChange={handleStartDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </Box>
                          <Box>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disableToolbar
                                inputVariant="outlined"
                                style={{
                                  maxWidth: "251px",
                                  maxHeight: "53px",
                                }}
                                className={classes.formControl}
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                changeYear={true}
                                changeMonth={true}
                                autoOk={true}
                                id="date-picker-inline"
                                label="Choose End Date"
                                value={endDate}
                                minDate={startDate}
                                disableFuture={true}
                                onChange={handleEndDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                              marginTop: "37px",
                            }}
                          >
                            <Button
                              variant="outlined"
                              onClick={handleRemoveFilter}
                              style={{
                                background: "#FFFFFF",
                                color: "#0B1941",
                                borderRadius: "4px",
                                border: "1px solid #0B1941",
                              }}
                            >
                              Remove Filter
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              disabled={buttonState}
                              style={{
                                background: buttonState ? "#9E9E9E" : "#0B1941",
                                color: "#FFFFFF",
                                borderRadius: "4px",
                              }}
                              onClick={() =>
                                handleFilterClaimAmount(headCell.id)
                              }
                            >
                              Apply Filter
                            </Button>
                          </Box>
                        </Box>
                      </Paper>
                    </Popover>
                  </Box>
                ) : (
                  ""
                )}
                {headCell.id === "attachments" ? (
                  <Box>
                    <Popover
                      id={id9}
                      open={open9}
                      onClose={handleClosePopper}
                      anchorEl={anchorEl9}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Paper
                        style={{
                          width: "300px",
                          height: "277px",
                        }}
                      >
                        <Box>
                          <Box
                            boxShadow={1}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "15px",
                            }}
                          >
                            <Box>
                              {/* <FilterListIcon /> */}
                              {/* <img src={Filterimg} className={classes.filterImg} /> */}
                              <span class="material-symbols-outlined">
                                filter_alt
                              </span>
                            </Box>
                            <Box>
                              <Typography
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  lineHeight: "28px",
                                  letterSpacing: "0px",
                                  textAlign: "left",
                                  color: "#0B1941",
                                  paddingRight: "65px",
                                }}
                              >
                                Attachment
                              </Typography>
                            </Box>
                            <Box>
                              <Button>
                                <CloseIcon onClick={handleClosePopper} />
                              </Button>
                            </Box>
                          </Box>
                          {/* <Box>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "24px",
                              letterSpacing: "0px",
                              textAlign: "left",
                              marginLeft: "25px",
                              marginTop: "16px",
                              color: "#4C4C4C",
                            }}
                          >
                            Attachment
                          </Typography>
                        </Box> */}
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "24px",
                            }}
                          >
                            <TextField
                              id="outlined-basic"
                              label="Attachment"
                              variant="outlined"
                              style={{
                                width: "244px",
                                height: "52px",
                                marginLeft: "28px",
                                // marginTop: "8px",
                                color: "#0B1941",
                              }}
                              onChange={(e) => {
                                if (e.target.value.length >= 1) {
                                  setButtonState(false);
                                }
                                if (e.target.value.length == 0) {
                                  setButtonState(true);
                                }

                                setCriteriaClaimAmount(e.target.value);
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    style={{ marginRight: "-12px" }}
                                  >
                                    <IconButton>
                                      <SearchIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                              marginTop: "37px",
                            }}
                          >
                            <Button
                              variant="outlined"
                              onClick={handleRemoveFilter}
                              style={{
                                background: "#FFFFFF",
                                color: "#0B1941",
                                borderRadius: "4px",
                                border: "1px solid #0B1941",
                              }}
                            >
                              Remove Filter
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              disabled={buttonState}
                              style={{
                                background: buttonState ? "#9E9E9E" : "#0B1941",
                                color: "#FFFFFF",
                                borderRadius: "4px",
                              }}
                              onClick={() =>
                                handleFilterClaimAmount(headCell.id)
                              }
                            >
                              Apply Filter
                            </Button>
                          </Box>
                        </Box>
                      </Paper>
                    </Popover>
                  </Box>
                ) : (
                  ""
                )}
                {headCell.id === "projectId" ? (
                  <Box>
                    <Popover
                      id={id10}
                      open={open10}
                      onClose={handleClosePopper}
                      anchorEl={anchorEl10}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Paper
                        style={{
                          width: "300px",
                          height: "277px",
                        }}
                      >
                        <Box>
                          <Box
                            boxShadow={1}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "15px",
                            }}
                          >
                            <Box>
                              {/* <FilterListIcon /> */}
                              {/* <img src={Filterimg} className={classes.filterImg} /> */}
                              <span class="material-symbols-outlined">
                                filter_alt
                              </span>
                            </Box>
                            <Box>
                              <Typography
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  lineHeight: "28px",
                                  letterSpacing: "0px",
                                  textAlign: "left",
                                  color: "#0B1941",
                                  paddingRight: "60px",
                                }}
                              >
                                Project Code
                              </Typography>
                            </Box>
                            <Box>
                              <Button>
                                <CloseIcon onClick={handleClosePopper} />
                              </Button>
                            </Box>
                          </Box>
                          {/* <Box>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "24px",
                              letterSpacing: "0px",
                              textAlign: "left",
                              marginLeft: "25px",
                              marginTop: "16px",
                              color: "#4C4C4C",
                            }}
                          >
                            Project Code
                          </Typography>
                        </Box> */}
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "24px",
                            }}
                          >
                            <TextField
                              id="outlined-basic"
                              label="Project Code"
                              variant="outlined"
                              style={{
                                width: "244px",
                                height: "52px",
                                marginLeft: "28px",
                                // marginTop: "8px",
                                color: "#0B1941",
                              }}
                              onChange={(e) => {
                                if (e.target.value.length >= 1) {
                                  setButtonState(false);
                                }
                                if (e.target.value.length == 0) {
                                  setButtonState(true);
                                }

                                setCriteriaClaimAmount(e.target.value);
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    style={{ marginRight: "-12px" }}
                                  >
                                    <IconButton>
                                      <SearchIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                              marginTop: "37px",
                            }}
                          >
                            <Button
                              variant="outlined"
                              onClick={handleRemoveFilter}
                              style={{
                                background: "#FFFFFF",
                                color: "#0B1941",
                                borderRadius: "4px",
                                border: "1px solid #0B1941",
                              }}
                            >
                              Remove Filter
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              disabled={buttonState}
                              style={{
                                background: buttonState ? "#9E9E9E" : "#0B1941",
                                color: "#FFFFFF",
                                borderRadius: "4px",
                              }}
                              onClick={() =>
                                handleFilterClaimAmount(headCell.id)
                              }
                            >
                              Apply Filter
                            </Button>
                          </Box>
                        </Box>
                      </Paper>
                    </Popover>
                  </Box>
                ) : (
                  ""
                )}
                {headCell.id === "setting" ? (
                  <Box style={{ borderRadius: "10px" }}>
                    <Popover
                      id={id6}
                      open={open6}
                      style={{ borderRadius: "20px", marginLeft: "-62px" }}
                      onClose={handleClosePopper}
                      anchorEl={anchorEl6}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Paper className={classes.settingsPaper}>
                        <Box>
                          <FormGroup>
                            <FormControlLabel
                              style={{ paddingLeft: "10px" }}
                              control={
                                <CustomSwitch
                                  checked={teamAppPurpose}
                                  onChange={(e) => {
                                    setTeamAppPurpose(e.target.checked);
                                    handleAddColumn(
                                      "purpose",
                                      e.target.checked
                                    );
                                  }}
                                />
                              }
                              label="Purpose"
                            />
                            <FormControlLabel
                              style={{ paddingLeft: "10px" }}
                              control={
                                <CustomSwitch
                                  checked={teamAppCreationDate}
                                  onChange={(e) => {
                                    setTeamAppCreationDate(e.target.checked);
                                    handleAddColumn(
                                      "createdOn",
                                      e.target.checked
                                    );
                                  }}
                                />
                              }
                              label="Creation Date"
                            />
                            <FormControlLabel
                              style={{ paddingLeft: "10px" }}
                              control={
                                <CustomSwitch
                                  checked={teamAppAttachments}
                                  onChange={(e) => {
                                    setTeamAppAttachments(e.target.checked);
                                    handleAddColumn(
                                      "attachments",
                                      e.target.checked
                                    );
                                  }}
                                />
                              }
                              label="Attachment"
                            />
                            <FormControlLabel
                              style={{ paddingLeft: "10px" }}
                              className={classes.switchRoot}
                              control={
                                <CustomSwitch
                                  checked={teamAppProjectId}
                                  onChange={(e) => {
                                    setTeamAppProjectId(e.target.checked);
                                    handleAddColumn(
                                      "projectId",
                                      e.target.checked
                                    );
                                  }}
                                />
                              }
                              label="Project Code"
                            />
                          </FormGroup>
                        </Box>
                      </Paper>
                    </Popover>
                  </Box>
                ) : (
                  ""
                )}
              </TableCell>
            ))}
          </TableRow>
        )}
      </TableHead>
    </>
  );
}

TeamApprovalTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  onClickFilter: PropTypes.func.isRequired,
  handleSettingsParent: PropTypes.func.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const TeamApprovalTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;
  //console.log("number selected", numSelected);

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          All Expenses
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

TeamApprovalTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  rootFooter: {
    flexShrink: 0,

    marginLeft: theme.spacing(2.5),
  },
  tableContainer: {
    //maxWidth: "1117px",

    borderRadius: "8px",
    "&.MuiTableCell-root": {
      padding: "0px",
    },
  },
  cell: {
    width: "120px",
  },
  settingsPaper: {
    width: "188px",
    height: "164px",
    border: "1px solid #B7B7B7",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.03)",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "244px",
    height: "52px",
    marginLeft: "24px",
    marginTop: "8px",
  },
  formControlAmount: {
    margin: theme.spacing(1),
    minWidth: "75px",
    height: "52px",
    marginLeft: "24px",
    marginTop: "8px",
  },
  paperZ150: {
    width: "1132px",
    border: "1px solid #979797",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
  },
  paperZ125: {
    width: "1390px",
    border: "1px solid #979797",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
  },
  paperZ100: {
    //width: "1117px",
    border: "1px solid #979797",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
  },
  table: {
    //maxWidth: "1117px",
    "&.MuiTableCell-root": {
      padding: "0px",
    },
  },
  table100: {
    "&.MuiTableCell-root": {
      padding: "0px",
    },
  },
  filterImg: {
    width: "24px",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  inReviewStatus: {
    backgroundColor: "#E0B15C",
    color: "white",
    width: "110px",
    height: "24px",
    marginLeft: "-14px",
  },
  approvedStatus: {
    backgroundColor: "#349259",
    color: "white",
    width: "110px",
    height: "24px",
    marginLeft: "-14px",
  },
  rejectedStatus: {
    backgroundColor: "#D40606",
    color: "white",
    width: "110px",
    height: "24px",
  },
  checkBoxRoot: {
    background: "#0B1941",
    color: "white",
    "&$checked": {
      background: "#0B1941",
      color: "white",
    },
  },
  switchRoot: {
    ".MuiSwitch-colorSecondary.Mui-checked": {
      color: "#0B1941",
    },
  },
  tableRow: {
    height: 58,
    "&.MuiTableRow-root.MuiTableRow-hover:hover": {
      backgroundColor: "#EFF2F8",
    },
    "&.MuiTableRow-root.Mui-selected": {
      background: "#EFF2F8",
    },
  },
  stickyRight: {
    position: "sticky",
    right: 0,
    background: "white",
    zIndex: 800,
  },
  stickyLeft: {
    position: "sticky",
    left: 0,
    background: "white",
    zIndex: 800,
  },
  nonSticky: {
    position: "sticky",
    left: 0,
    background: "#F4F4F4",
    zIndex: 800,
  },
}));

export default function TeamApprovalTable(props) {
  const classes = useStyles();
  const history = useHistory();

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [myExpenseData, setMyExpenseData] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filteredRowsData, setFilteredRowsData] = React.useState([]);
  const [teamAppPurpose, setTeamAppPurpose] = React.useState(false);
  const [teamAppCreationDate, setTeamAppCreationDate] = React.useState(false);
  const [teamAppAttachments, setTeamAppAttachments] = React.useState(false);
  const [teamAppProjectId, setTeamAppProjectId] = React.useState(false);
  const [singleDownload, setSingleDownload] = useState([]);
  const [bulkDownload, setBulkDownload] = useState([]);
  const [systemErrorSnackbar, setSystemErrorSnackbar] = useState(false);
  const [empId, setEmpId] = React.useState(null);
  const [comments, setComments] = React.useState("");
  const [openBulkApproveDialog, setOpenBulkApproveDialog] = useState(false);
  const [singleExpense, setSingleExpense] = useState("");
  const [approveOrReject, setApproveOrReject] = useState(1);
  const [currentIndex, setCurrentIndex] = React.useState();
  const [screenWidth, setScreenWidth] = useState(1920);
  const [refreshTableToggle, forceUpdate] = useReducer((x) => x + 1, 0);
  const [open, setOpen] = React.useState(false);
  const [commentData, setCommentData] = React.useState([]);
  const [commentHeading, setCommentHeading] = React.useState("");
  const [toolAction, setToolAction] = React.useState("");

  const [travelSelected, setTravelSelected] = useState([]);
  const [teamApprovalTableTravelData, setTeamApprovalTableTravelData] =
    useState([]);
  const matches = useMediaQuery("(max-width:600px)");

  const target = useRef(null);
  const dispatch = useDispatch();
  const getMyTeamApprovalList = useSelector(
    (state) => state.createExpenseData.getMyTeamApproval
  );
  const teamTableTravelData = useSelector(
    (state) => state.createExpenseData.getMyExpenseTravel
  );
  // console.log("getMyTeamApprovalList", getMyTeamApprovalList);
  const delEmpIDRedux = useSelector(
    (state) => state.createExpenseData.delEmpId
  );
  //console.log("delEmpID in TAK1", delEmpIDRedux);
  const headers = [
    {
      label: "Expense Code",
      key: "expCode",
    },
    {
      label: "Category Name",
      key: "categoryName",
    },
    {
      label: "To Location",
      key: "toLocation",
    },
    {
      label: "From Location",
      key: "fromLocation",
    },

    {
      label: "Distance (Km/Miles)",
      key: "distance",
    },
    {
      label: "From Date",
      key: "fromDate",
    },
    {
      label: "To Date",
      key: "toDate",
    },
    {
      label: "Claim Month",
      key: "forMonth",
    },
    {
      label: "Created On",
      key: "createdOn",
    },
    {
      label: "Currency",
      key: "currencyCode",
    },
    {
      label: "Claimed Amount",
      key: "claimedAmount",
    },
    {
      label: "Relationship",
      key: "dependent",
    },
    {
      label: "Policy Limit",
      key: "policyLimit",
    },
    {
      label: "Purpose",
      key: "purpose",
    },
    {
      label: "Status",
      key: "expStatus",
    },
    {
      label: "Attachments",
      key: "attachments",
    },
    {
      label: "Project ID",
      key: "projectId",
    },
    {
      label: "Employee",
      key: "createdBy",
    },
  ];
  const csvLink = {
    headers: headers,
    data: singleDownload,
    filename: `TeamApproval_${moment(new Date()).format("DDMMYYYY_HHmm")}.csv`,
  };

  //console.log("TA- ssoEmpId-->", props.ssoEmpId);
  //console.log("TA- delEmpId-->", props.delEmpId);
  useEffect(() => {
    if (delEmpIDRedux != null) {
      setEmpId(delEmpIDRedux);
      let obj = {
        empId: delEmpIDRedux,
        nday: props.nDay,
      };
      dispatch(getMyTeamApprovalData(obj));
    } else {
      setEmpId(props.ssoEmpId);
      let obj = {
        empId: props.ssoEmpId,
        nday: props.nDay,
      };
      dispatch(getMyTeamApprovalData(obj));
    }
    //console.log("team", empId);
  }, [props.nDay, refreshTableToggle, delEmpIDRedux]);
  useEffect(() => {
    setScreenWidth(window.screen.availWidth);
    //console.log("setScreenWidth", screenWidth);
  }, [window.screen.availWidth]);

  useEffect(() => {
    setFilteredRowsData(getMyTeamApprovalList);
    setTeamApprovalTableTravelData([]);
  }, [getMyTeamApprovalList]);

  useEffect(() => {
    setTeamApprovalTableTravelData((prevState) => [
      ...prevState,
      ...teamTableTravelData,
    ]);
  }, [teamTableTravelData]);

  const handleRequestSort = (event, property) => {
    //console.log("handleRequestSort:-----------event-1-:-- ", event);
    //console.log("handleRequestSort:-----------property-2-:-- ", property);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const resetBulkDownload = () => {
    setBulkDownload([]);
    setSelected([]);
  };

  //console.log("comments====>:", comments);

  const handleOpenBulkApproveDialog = (row, index) => {
    setCurrentIndex(index);
    //console.log("handleOpenBulkApproveDialog");
    setOpenBulkApproveDialog(true);
    setSingleExpense(row);
    setApproveOrReject(1);
  };
  const handleOpenRejectionDialog = (row, index) => {
    setCurrentIndex(index);
    //console.log("handleOpenRejectionDialog");
    setOpenBulkApproveDialog(true);
    setSingleExpense(row);
    setApproveOrReject(0);
  };
  const handleCloseBulkApproveDialog = () => {
    //console.log("openBulkDialog--- in fxn", openBulkApproveDialog);
    setOpenBulkApproveDialog(false);
    matches && history.push("/expense-Management");
    setComments("");
  };
  const handleRejectionCommentsParent = () => {
    //console.log("handleRejectionCommentsParent");
  };
  const handleSelectAllClick = (event) => {
    //1222
    //console.log("handleSelectAllClick----1", filteredRowsData);
    if (event.target.checked) {
      // //console.log('pageNumber', page)
      let filterData = stableSort(
        filteredRowsData,
        getComparator(order, orderBy)
      )?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      // //console.log('filterData', filterData)
      const newSelecteds = filterData.map((s) => s.uniqueId);
      setSelected(newSelecteds);
      // //console.log("selected value", newSelecteds);
      setBulkDownload(filterData);
      //console.log("bulk download value", bulkDownload);
      //console.log("handleSelectAllClick----2", selected);

      return;
    }
    setBulkDownload([]);
    setSelected([]);
  };
  //console.log("selected:----------- ", selected);
  const handleClick = (event, name) => {
    //console.log("name", name);
    const selectedIndex = selected.indexOf(name.uniqueId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.uniqueId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    //console.log("newSelected value", newSelected);
    setSelected(newSelected);
    //console.log("selected value", selected);
    //Multiple download logic

    const objIndex = bulkDownload.findIndex(
      (el) => el.uniqueId == name.uniqueId
    );
    let newBulkDownload = [];
    if (objIndex >= 0) {
      newBulkDownload = [
        ...bulkDownload.filter((el) => el.uniqueId != name.uniqueId),
      ];
    } else {
      newBulkDownload = [...bulkDownload, name];
    }
    setBulkDownload(newBulkDownload);
  };

  const handleFilteredArray = (data) => {
    setFilteredRowsData(data);
  };
  const handleSettingsParent = (name, value) => {
    if (name == "purpose") {
      setTeamAppPurpose(value);
    } else if (name == "createdOn") {
      setTeamAppCreationDate(value);
    } else if (name == "attachments") {
      setTeamAppAttachments(value);
    } else if (name == "projectId") {
      setTeamAppProjectId(value);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownload = (value) => {
    //console.log("value in handleDownload: ---->  ", value);
    setSingleDownload([value]);
  };
  const handleSingleApproveRejectExpense = (value) => {
    //console.log("value:  ====>  ", value);
    //console.log("empId:  ====>  ", empId);
    //console.log("comments:  ====>  ", comments);

    let array = [];
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    array.push({
      recid: Encrypt(key, JSON.stringify(Number(value.uniqueId))),
      expid: Encrypt(key, JSON.stringify(Number(value.expId))),
      projid: Encrypt(key, JSON.stringify(Number(value.projUniqueId))),
    });

    let dataObject = {
      empId: Encrypt(key, JSON.stringify(empId)),
      comments: comments,
      project: array,
    };
    // try{
    dispatch(postApproveRejectExpenseData(dataObject))
      .then((resp) => {
        if (resp.status != 200) {
          setSystemErrorSnackbar(true);
        } else {
          // setTimeout(() => {
          //   dispatch(resetExpenseResponse());
          // }, 1500);
          setComments("");
          dispatch(getMyTeamApprovalData({ empId: empId, nday: props.nDay }));
          forceUpdate();
        }
      })
      .catch((err) => setSystemErrorSnackbar(true));

    // }catch{
    //   setSystemErrorSnackbar(true);
    // }
  };
  //console.log("comments value: ---->  ", comments);
  //console.log("selected value: ---->  ", selected);

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const isTravelSelected = (name) => travelSelected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredRowsData?.length - page * rowsPerPage);
  const handleComments = (data) => {
    //console.log("data", data);
    setComments(data);
  };
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handlToolAction = (event) => {
    //console.log(event.currentTarget);
    setToolAction(event.currentTarget);
  };
  const handleToolTip = (comment, heading) => {
    return (
      <>
        <Box>
          <Typography className={classes.statusHeading}>{heading}</Typography>
        </Box>
        {comment?.map((value) => {
          return (
            <>
              <Box style={{ display: "flex" }}>
                <Typography className={classes.statusName}>
                  {value.managerid}
                </Typography>
                {heading == "Rejected By " && (
                  <Typography className={classes.statusName}>{"|"}</Typography>
                )}
                <Typography className={classes.statusProjectName}>
                  {value.projCode}
                </Typography>{" "}
              </Box>
              <Typography className={classes.statusMessage}>
                {value.reason}
              </Typography>
            </>
          );
        })}
      </>
    );
  };

  const handleTooltipOpen = (comments, index) => {
    //console.log("comments:====> ", comments, "index", index);
    setOpen(true);
    setCommentData(comments);
    setCommentHeading("Rejected");
    setCurrentIndex(index);
  };
  //console.log("checking the coomments", commentData);
  //console.log("checking the nest action", commentHeading);
  //console.log("window.screen.availWidth", window.screen.availWidth);
  const handleParentToolTip = (index) => {
    //console.log(":parent tool tip function", index);
    return (
      <>
        <OutsideClickHandler
          onOutsideClick={() => {
            handleTooltipClose();
          }}
        >
          <div style={{ position: "relative" }}>
            <Tooltip
              PopperProps={{
                disablePortal: true,
                anchorEl: { toolAction },
                // anchorOrigin:{
                //   vertical: "bottom",
                //   horizontal: "left",
                // },
                // transformOrigin:{
                //   vertical: "top",
                //   horizontal: "left",
                // }
              }}
              arrow
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={handleToolTip(commentData, commentHeading)}
              classes={{
                popper: classes.tooltipBox,
                arrow: classes.arrow,
              }}
            >
              {/* <ErrorOutlineIcon
              onClick={handleTooltipOpen}
              className={classes.tooltip}
              /> */}
              <span
                style={{ width: "0px", minWidth: "0px", height: "0" }}
              ></span>
            </Tooltip>
          </div>
        </OutsideClickHandler>
      </>
    );
  };

  const handleExpand = (event, row) => {
    const selectedIndex = travelSelected.indexOf(row.expId);
    const travelExist = teamApprovalTableTravelData.some(
      (obj) => obj.expId === row.expId
    );
    // console.log("Previous state travel", travelExist);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(travelSelected, row.expId);
      if (!travelExist) {
        let obj = {
          expId: row.expId,
          nday: props.nDay,
        };
        dispatch(getMyExpenseDataTravel(obj)); // api to get all the categories data for travel
      }
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(travelSelected.slice(1));
    } else if (selectedIndex === travelSelected.length - 1) {
      newSelected = newSelected.concat(travelSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        travelSelected.slice(0, selectedIndex),
        travelSelected.slice(selectedIndex + 1)
      );
    }
    setTravelSelected(newSelected);
  };
  return (
    <div className={classes.root}>
      <Paper
        elevation={0}
        className={
          window.screen.availWidth == 1280
            ? classes.paperZ150
            : window.screen.availWidth == 1536
            ? classes.paperZ125
            : classes.paperZ100
        }
      >
        {/* <TeamApprovalTableToolbar numSelected={selected.length} /> */}
        <TableContainer className={classes.tableContainer}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <TeamApprovalTableHead
              classes={classes}
              numSelected={selected.length}
              rowsPerPage={rowsPerPage}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={filteredRowsData?.length}
              onClickFilter={handleFilteredArray}
              pageNumber={page}
              handleSettingsParent={handleSettingsParent}
              getMyTeamApprovalList={getMyTeamApprovalList}
              bulkDownload={bulkDownload}
              empId={empId}
              resetBulkDownload={resetBulkDownload}
            />
            <TableBody>
              {stableSort(filteredRowsData, getComparator(order, orderBy))
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) => {
                  const isItemSelected = isSelected(row.uniqueId);
                  const isTravelItemSelected = isTravelSelected(row.expId);
                  //console.log("isItemSelected", isItemSelected);
                  //console.log("row12",row)
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <>
                      <TableRow
                        className={classes.tableRow}
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.uniqueId}
                        selected={isItemSelected}
                      >
                        <TableCell
                          style={{
                            paddingRight: "0px",
                            // paddingLeft: "12px",
                            // borderBottomWidth: "0px",
                          }}
                          onClick={(event) => handleExpand(event, row)}
                        >
                          {row.tFlag ? (
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              // onClick={(event) => handleExpand(event, row)}
                            >
                              {isTravelItemSelected ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          ) : (
                            <></>
                          )}
                        </TableCell>
                        <TableCell
                          padding="checkbox"
                          style={{
                            minWidth: "53px",
                          }}
                          className={classes.stickyLeft}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            checkedIcon={
                              <CheckBoxIcon
                                fontSize="small"
                                className={classes.checkBoxRoot}
                              />
                            }
                            onClick={(event) => handleClick(event, row)}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          style={{ minWidth: "142px" }}
                          onClick={() => {
                            //console.log("hi table roe clicked!");
                            const text = "/viewExpenseDetails/:id";
                            const result = text.replace(":id", row.expId);
                            history.push({
                              pathname: result,
                              state: {
                                response: row,
                                empId: empId,
                                compName: "teamApprovalListing",
                              },
                            });
                          }}
                        >
                          {row.expCode}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            maxWidth: "1px",
                          }}
                          onClick={() => {
                            //console.log("hi table roe clicked!");
                            const text = "/viewExpenseDetails/:id";
                            const result = text.replace(":id", row.expId);
                            history.push({
                              pathname: result,
                              state: {
                                response: row,
                                empId: empId,
                                compName: "teamApprovalListing",
                              },
                            });
                          }}
                        >
                          {row.categoryName}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ minWidth: "120px" }}
                          onClick={() => {
                            //console.log("hi table roe clicked!");
                            const text = "/viewExpenseDetails/:id";
                            const result = text.replace(":id", row.expId);
                            history.push({
                              pathname: result,
                              state: {
                                response: row,
                                empId: empId,
                                compName: "teamApprovalListing",
                              },
                            });
                          }}
                        >
                          {row.createdBy}
                          <br />
                          <span style={{ fontSize: "10px" }}> ID: </span>{" "}
                          <span style={{ fontSize: "10px" }}>{row.empId}</span>
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            minWidth: "120px",
                            color: row?.currencyChanged ? "red" : "",
                            paddingRight:
                              window.screen.availWidth == 1280
                                ? "40px"
                                : window.screen.availWidth == 1536
                                ? "64px"
                                : "150px",
                          }}
                          onClick={() => {
                            //console.log("hi table roe clicked!");
                            const text = "/viewExpenseDetails/:id";
                            const result = text.replace(":id", row.expId);
                            history.push({
                              pathname: result,
                              state: {
                                response: row,
                                empId: empId,
                                compName: "teamApprovalListing",
                              },
                            });
                          }}
                        >
                          {/* <div>
                          {row.currencyChanged === 1 ||
                          (row.policyLimit &&
                            row.claimedAmount > row.policyLimit) ? (
                            <Tooltip
                              title={
                                row.exceptionalApproval === 1
                                  ? row.currencyChanged === 1
                                    ? "Claim Amount Exceeds Policy limit and Default currency has been changed"
                                    : "Claim Amount exceeds policy limit"
                                  : "Default currency has been changed."
                              }
                            >
                              <span style={{ color: "red" }}>
                                {row?.currency}
                                {row.claimedAmount
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </span>
                            </Tooltip>
                          ) : (
                            <div>
                              {row?.currency}
                              {row.claimedAmount
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </div>
                          )}
                        </div> */}
                          <div>
                            {row.currencyChanged == 1 ||
                            (row.policyLimit != 0 &&
                              row.claimedAmount > row.policyLimit &&
                              row.exceptionalApproval == 1) ? (
                              <Tooltip
                                title={
                                  row.currencyChanged == 1 &&
                                  row.exceptionalApproval == 1 &&
                                  row.claimedAmount > row.policyLimit &&
                                  row.exceptionalApproval == 1
                                    ? "Claim Amount Exceeds Policy limit and Default currency has been changed"
                                    : row.currencyChanged == 1
                                    ? "Default currency has been changed"
                                    : "Claim Amount exceeds policy limit"
                                }
                              >
                                <span style={{ color: "red" }}>
                                  {row?.currency}{" "}
                                  {row.claimedAmount
                                    ?.toString()
                                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </span>
                              </Tooltip>
                            ) : (
                              <div>
                                {row?.currency}{" "}
                                {row.claimedAmount
                                  ?.toString()
                                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </div>
                            )}
                          </div>
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            minWidth: "120px",

                            paddingRight:
                              window.screen.availWidth == 1280
                                ? "34px"
                                : window.screen.availWidth == 1536
                                ? "43px"
                                : "63px",
                          }}
                          onClick={() => {
                            //console.log("hi table roe clicked!");
                            const text = "/viewExpenseDetails/:id";
                            const result = text.replace(":id", row.expId);
                            history.push({
                              pathname: result,
                              state: {
                                response: row,
                                empId: empId,
                                compName: "teamApprovalListing",
                              },
                            });
                          }}
                        >
                          <div>
                            {row?.policyCurrency}
                            {row.policyLimit
                              ?.toString()
                              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </div>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ minWidth: "120px" }}
                          onClick={() => {
                            //console.log("hi table roe clicked!");
                            const text = "/viewExpenseDetails/:id";
                            const result = text.replace(":id", row.expId);
                            history.push({
                              pathname: result,
                              state: {
                                response: row,
                                empId: empId,
                                compName: "teamApprovalListing",
                              },
                            });
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Chip
                              label={row.expStatus}
                              className={
                                row.expStatus == "Rejected"
                                  ? classes.rejectedStatus
                                  : row.expStatus == "Approved"
                                  ? classes.approvedStatus
                                  : classes.inReviewStatus
                              }
                            />
                            {/* {row.expStatus == "Rejected" ? (
                            <div>
                              <InfoOutlinedIcon
                                style={{
                                  color: "#9E9E9E",
                                  paddingLeft: "4px",
                                  width: "20px",
                                }}
                                ref={target}
                                onClick={(event) => {
                                  handleTooltipOpen(row.comments, index);
                                  handlToolAction(event);
                                }}
                                className={classes.tooltip}
                              />

                              {open &&
                                index === currentIndex &&
                                handleParentToolTip(index)}
                            </div>
                          ) : (
                            ""
                          )} */}
                          </Box>
                        </TableCell>
                        {teamAppPurpose && (
                          <TableCell
                            align="left"
                            style={{ minWidth: "135px" }}
                            onClick={() => {
                              //console.log("hi table roe clicked!");
                              const text = "/viewExpenseDetails/:id";
                              const result = text.replace(":id", row.expId);
                              history.push({
                                pathname: result,
                                state: {
                                  response: row,
                                  empId: empId,
                                  compName: "teamApprovalListing",
                                },
                              });
                            }}
                          >
                            {" "}
                            {row.purpose}
                          </TableCell>
                        )}
                        {teamAppCreationDate && (
                          <TableCell
                            align="left"
                            style={{ minWidth: "120px" }}
                            onClick={() => {
                              //console.log("hi table roe clicked!");
                              const text = "/viewExpenseDetails/:id";
                              const result = text.replace(":id", row.expId);
                              history.push({
                                pathname: result,
                                state: {
                                  response: row,
                                  empId: empId,
                                  compName: "teamApprovalListing",
                                },
                              });
                            }}
                          >
                            {" "}
                            {moment(row.createdOn).format("DD/MM/YYYY")}
                          </TableCell>
                        )}
                        {teamAppAttachments && (
                          <TableCell
                            align="center"
                            style={{ minWidth: "120px" }}
                            onClick={() => {
                              //console.log("hi table roe clicked!");
                              const text = "/viewExpenseDetails/:id";
                              const result = text.replace(":id", row.expId);
                              history.push({
                                pathname: result,
                                state: {
                                  response: row,
                                  empId: empId,
                                  compName: "teamApprovalListing",
                                },
                              });
                            }}
                          >
                            {" "}
                            {row.attachments}
                          </TableCell>
                        )}
                        {teamAppProjectId && (
                          <TableCell
                            align="center"
                            style={{ minWidth: "130px" }}
                            onClick={() => {
                              //console.log("hi table roe clicked!");
                              const text = "/viewExpenseDetails/:id";
                              const result = text.replace(":id", row.expId);
                              history.push({
                                pathname: result,
                                state: {
                                  response: row,
                                  empId: empId,
                                  compName: "teamApprovalListing",
                                },
                              });
                            }}
                          >
                            {" "}
                            {row.projectId}
                          </TableCell>
                        )}
                        {row.expStatus != "Rejected" &&
                        row.expStatus != "Approved" ? (
                          <TableCell
                            align="left"
                            style={{
                              right: "55px",
                              minWidth: "120px",
                            }}
                            className={classes.stickyRight}
                          >
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <IconButton
                                style={{
                                  maxHeight: "40px",
                                  maxWidth: "40px",
                                }}
                                onClick={() =>
                                  handleOpenBulkApproveDialog(row, index)
                                }
                              >
                                <CheckCircleOutlineIcon
                                  style={{
                                    color: "#349259",
                                    fontSize: "28px",
                                  }}
                                  // onClick={() =>
                                  //   handleSingleApproveRejectExpense(row)
                                  // }
                                />
                              </IconButton>
                              <IconButton
                                style={{
                                  maxHeight: "40px",
                                  maxWidth: "40px",
                                }}
                                onClick={() =>
                                  handleOpenRejectionDialog(row, index)
                                }
                              >
                                <CancelOutlinedIcon
                                  style={{
                                    color: "#E02020",
                                    fontSize: "28px",
                                  }}
                                />
                              </IconButton>
                            </Box>
                            {index === currentIndex && (
                              <ApproveRejectDialog
                                openBulkApproveDialog={openBulkApproveDialog}
                                singleExpense={singleExpense}
                                approveOrReject={approveOrReject}
                                handleComments={handleComments}
                                handleSingleApproveRejectExpense={
                                  handleSingleApproveRejectExpense
                                }
                                handleCloseBulkApproveDialog={
                                  handleCloseBulkApproveDialog
                                }
                                handleRejectionCommentsParent={
                                  handleRejectionCommentsParent
                                }
                              />
                            )}
                          </TableCell>
                        ) : (
                          <TableCell
                            align="left"
                            style={{
                              right: "55px",

                              minWidth: "120px",
                            }}
                            className={classes.stickyRight}
                          >
                            <Box></Box>
                          </TableCell>
                        )}
                        <TableCell
                          align="left"
                          style={{
                            width: "53px",
                          }}
                          className={classes.stickyRight}
                        >
                          <CSVLink {...csvLink} style={{ color: "#666666" }}>
                            <Box onClick={() => handleDownload(row)}>
                              <GetAppOutlinedIcon
                                style={{ fontSize: "30px" }}
                              />
                            </Box>
                          </CSVLink>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ padding: 0 }} colSpan={12}>
                          <Collapse
                            in={isTravelItemSelected}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box>
                              <Table aria-label="purchases">
                                <TableHead>
                                  <TableRow
                                    style={{
                                      background: "#f4f4f4",
                                    }}
                                  >
                                    <TableCell
                                      style={{
                                        width:
                                          window.screen.availWidth == 1280
                                            ? "7.5rem"
                                            : window.screen.availWidth == 1536
                                            ? "8.4rem"
                                            : "10rem",
                                        // maxWidth: "7.5rem",
                                      }}
                                    />
                                    <TableCell
                                      style={{
                                        width:
                                          window.screen.availWidth == 1280
                                            ? "7.5rem"
                                            : window.screen.availWidth == 1536
                                            ? "8.3rem"
                                            : "10.2rem",

                                        // maxWidth: "7.5rem",
                                      }}
                                    />
                                    <TableCell
                                      style={{
                                        fontWeight: "600",
                                      }}
                                    >
                                      Expense Category
                                    </TableCell>
                                    {/* <TableCell /> */}
                                    <TableCell
                                      style={{
                                        fontWeight: "600",
                                        // paddingLeft: "16px",
                                        width:
                                          window.screen.availWidth == 1280
                                            ? "9.9rem"
                                            : window.screen.availWidth == 1536
                                            ? "13rem"
                                            : "21.2rem",
                                      }}
                                    >
                                      Claim Amount
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontWeight: "600",
                                        width:
                                          window.screen.availWidth == 1280
                                            ? "442px"
                                            : window.screen.availWidth == 1536
                                            ? "516px"
                                            : "614px",
                                      }}
                                    >
                                      Policy Limit
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                {/* {console.log(
                                  "teamTravelData",
                                  teamTableTravelData
                                )} */}
                                <TableBody>
                                  {teamApprovalTableTravelData?.map(
                                    (travelRow, index) => {
                                      // console.log("travelmap", travelRow);
                                      return Number(travelRow.parentExpId) ===
                                        row.expId ? (
                                        <TableRow key={index}>
                                          <TableCell
                                          // style={{
                                          // width:
                                          //     window.screen.availWidth ==
                                          //     1280
                                          //       ? "7.5rem"
                                          //       : window.screen
                                          //           .availWidth == 1536
                                          //       ? "7.5rem"
                                          //       : "9.2rem",
                                          //   // maxWidth: "7.5rem",
                                          // }}
                                          />
                                          <TableCell
                                          // style={{
                                          //   width:
                                          //     window.screen.availWidth ==
                                          //     1280
                                          //       ? "7.5rem"
                                          //       : window.screen
                                          //           .availWidth == 1536
                                          //       ? "8rem"
                                          //       : "10rem",

                                          //   // maxWidth: "7.5rem",
                                          // }}
                                          />
                                          <TableCell
                                            style={{
                                              maxWidth:
                                                window.screen.availWidth == 1280
                                                  ? "18rem"
                                                  : window.screen.availWidth ==
                                                    1536
                                                  ? "10rem"
                                                  : "17.3rem",
                                              // width: "90px",
                                              textOverflow: "ellipsis",
                                              textWrap: "nowrap",
                                              overflow: "hidden",
                                            }}
                                          >
                                            {travelRow.categoryName}
                                          </TableCell>
                                          {/* <TableCell
                                              style={{
                                                width:
                                                  window.screen.availWidth ==
                                                  1280
                                                    ? "15.125rem"
                                                    : window.screen
                                                        .availWidth == 1536
                                                    ? "21rem"
                                                    : "25rem",

                                                // maxWidth: "15.125rem",
                                              }}
                                            /> */}
                                          <TableCell
                                            align="right"
                                            style={{
                                              paddingRight:
                                                window.screen.availWidth == 1280
                                                  ? "2.5rem"
                                                  : window.screen.availWidth ==
                                                    1536
                                                  ? "5rem"
                                                  : "11.7rem",
                                              width:
                                                window.screen.availWidth == 1280
                                                  ? "9.9rem"
                                                  : window.screen.availWidth ==
                                                    1536
                                                  ? "12.5rem"
                                                  : "21.2rem",
                                            }}
                                          >
                                            {row?.currency}
                                            {travelRow.claimedAmount
                                              .toString()
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}
                                          </TableCell>

                                          <TableCell
                                            style={{
                                              width: "294px",
                                              textAlign: " right",
                                              paddingRight:
                                                window.screen.availWidth == 1280
                                                  ? "21.5rem"
                                                  : window.screen.availWidth ==
                                                    1536
                                                  ? "25.3rem"
                                                  : "31.2rem",
                                            }}
                                          >
                                            {row?.currency}
                                            {travelRow.policyLimit
                                              .toString()
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}
                                          </TableCell>
                                        </TableRow>
                                      ) : (
                                        <></>
                                      );
                                    }
                                  )}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow className={classes.tableRow}>
                  <TableCell colSpan={12} style={{ minWidth: "120px" }} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="caption"
              style={{
                paddingLeft: "8px",
              }}
            >
              <InfoOutlinedIcon />
            </Typography>

            <Typography
              variant="caption"
              style={{
                paddingLeft: "8px",
              }}
            >
              <b>Click on any row to view details</b>
            </Typography>
          </Box>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredRowsData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Box>
      </Paper>

      <Snackbar
        open={systemErrorSnackbar}
        onClose={() => setSystemErrorSnackbar(false)}
      >
        <Alert
          onClose={() => setSystemErrorSnackbar(false)}
          severity="error"
          sx={{ width: "100%", backgroundColor: "red", color: "white" }}
        >
          There is some problem with the application. Please connect with System
          Admin.
        </Alert>
      </Snackbar>
    </div>
  );
}
