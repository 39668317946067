import React, { useEffect, useRef, useState, useReducer } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import LastPageIcon from "@material-ui/icons/LastPage";
import { useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import EmpCommentIcon from "../images/EmployeeCommentsIcon.png";
import {
  getMyTeamApprovalData,
  updateStatusByManagerData,
  getFilterTeamTimesheetData,
  getTimesheetReportData,
  getMyTeamApprovalFilterDropDownData,
  // getTotalCountApprovalData,
  // getMyTeamApprovalPageWiseData
} from "../redux/TimeSheet/actionTs";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import Grid from "@material-ui/core/Grid";
import Encrypt from "../Utils/encryption";
import { TextField, FormControlLabel } from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";

import ApproveRejectByManager from "./approveRejectByManager";
import { useHistory } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";
import Card from "@mui/material/Card";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { getTeamTimesheetData } from "../redux/TimeSheet/actionTs";
import moment from "moment";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import CommentIcon from "@mui/icons-material/Comment";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from "@mui/material/MenuItem";
// import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from "@mui/material/Select";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { Link, Button, Divider, Chip, Checkbox } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { CSVLink } from "react-csv";
import ClearIcon from "@mui/icons-material/Clear";
import { startOfMonth, endOfMonth } from "date-fns";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { te } from "date-fns/locale";
import { employeeProjectsData } from "../redux/Expense/action";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  {
    id: "icon",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "60px",
  },
  {
    id: "Date",
    numeric: false,
    disablePadding: true,
    label: "Date",
    width: "100px",
    //paddingLeft: "21px",
  },
  {
    id: "Employee Name & ID",
    numeric: false,
    disablePadding: true,
    label: "Employee Name & ID",
    width: "170px",
    //paddingLeft: "21px",
  },
  {
    id: "Type & Category",
    numeric: false,
    disablePadding: true,
    label: "Type & Category",
    width: "94px",
    //paddingLeft: "21px",
  },
  {
    id: "Project Name & ID",
    numeric: false,
    disablePadding: true,
    label: "Project Name & ID",
    width: "250px",
    //paddingLeft: "21px",
  },
  {
    id: "Hours",
    numeric: true,
    disablePadding: true,
    label: "Hours",
    width: "60px",
    //paddingLeft: "21px",
  },
  {
    id: "Comments",
    numeric: false,
    disablePadding: true,
    label: "Comments",
    width: "100px",
    //paddingLeft: "21px",
  },
  {
    id: "System Comments",
    numeric: false,
    disablePadding: true,
    label: "",
    width: "100px",
    paddingRight: "20px",
  },
];
function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    count,
    page,
    rowsPerPage,
    onPageChange,
    tsDelEmpIdRedux,
    ssoEmpIdDetails,
  } = props;
  // console.log("props on change", props);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
    //console.log("event1",event)
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  return (
    <div className={classes.rootFooter}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
function MyTeamApprovalTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    checked,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead style={{ backgroundColor: "#E3E8F7" }}>
      <TableRow style={{ height: "45px" }}>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell> */}
        {/* <TableCell
          component="th"
          scope="row"
          // component="td"
          padding="none"
          // scope="col-1"
          style={{
            width: "0px",
          }}
        /> */}
        {headCells.map((headCell) => (
          <TableCell
            //component="th"
            scope="row"
            className={classes.headerStyle}
            style={{
              fontWeight: 600,
              padding: "0px",
              width: headCell.width,
              paddingLeft: "10px",
              paddingLeft: headCell.paddingLeft,
              paddingRight: headCell.id == "System Comments" ? "102px" : "0px",
            }}
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            // padding={headCell.disablePadding ? "none" : "normal"}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* {headCell.label} */}

            {headCell.id === "icon" ? (
              //somecode
              <>
                {/* {  console.log("qwer" , props.checked)} */}
                <Checkbox
                  checked={checked}
                  style={{ paddingLeft: "12px" }}
                  checkedIcon={
                    <CheckBoxIcon
                      fontSize="small"
                      className={classes.checkBoxRoot}
                    />
                  }
                  //fontSize="small"
                  onChange={onSelectAllClick}
                  inputProps={{ "aria-label": "select all rows" }}
                />
              </>
            ) : (
              //somecode
              <>{headCell.label}</>
            )}

            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
        {/* <TableCell /> */}
        {/* <TableCell /> */}
      </TableRow>
    </TableHead>
  );
}
MyTeamApprovalTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  //onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  //rowCount: PropTypes.number.isRequired,
  checked: PropTypes.bool,
};
const useStyles = makeStyles((theme) => ({
  // root: {
  //   width: "100%",
  //   //overflow:"hidden",
  // },
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rootFooter: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    boxShadow: "none",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tooltip: {
    paddingLeft: "2px",
    color: "#9E9E9E",
    maxWidth: "20px",
    maxHeight: "20px",
  },

  tooltipBox: {
    minWidth: "225px",
    left: "auto!important",
    right: 0,
    marginTop: "-11px",
    // top:'-20px!important'
  },
  headerStyle: {
    color: " #424242",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    height: "20px",
    paddingLeft: "2px",
  },
  iconStyle: {
    width: "32px",
    height: "32px",
    flexShrink: "0",
  },
  snackbarMessage: {
    color: "#333",
    fontFamily: "Montserrat",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "0.2px",
  },
  snackbarMessage1: {
    color: "#252733",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "0.2px",
    paddingLeft: "10px",
  },
  anchorStyle: {
    "&.MuiSnackbar-anchorOriginTopRight": {
      top: "51px",
      left: "auto",
      right: "52px",
    },
  },
  checkBoxRoot: {
    background: "#0B1941",
    color: "white",
    "&$checked": {
      background: "#0B1941",
      color: "white",
    },
  },
  formControlDrop: {
    width: "100%",
    "& .MuiFormLabel-root": {
      top: "-4px",
      color: "#515961",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "white",
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "2px solid #439DD9",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        // borderColor: "#515961",
      },
      "&.Mui-focused fieldset": {
        // borderColor: "#0B1941",
      },
    },
  },
  boxForEmpType: {
    marginLeft: "4px",
    width: "146px",
  },
  inputCss: {
    fontSize: "14px",
    marginTop: "-2px",
  },
  selectCss: {
    height: "40px",
  },
}));

// const snackBarContent = () => {
//   return <Typography>some message</Typography>;
// };

export default function MyTeamApproval() {
  const [selected, setSelected] = React.useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0);
  const [projectNameId, setProjectNameId] = useState([]);
  const [tmDetails, setTmDetails] = useState([
    {
      tmdetailrecid: "",
      tmid: "",
      projId: "",
    },
  ]);
  const [snackBarData, setSnackBarData] = useState({
    name: "",
    id: "",
  });

  const [currentIndex, setCurrentIndex] = useState(-1);
  const [approveRejectStatus, setApproveRejectStatus] = useState("");
  const [approveRejectComment, setApproveRejectComment] = useState(null);
  const [showApproveRejectDialog, setShowApproveRejectDialog] = useState(false);
  const [refreshTable, forceUpdate] = useReducer((x) => x + 1, 0);
  const [refreshTableToggle, setRefreshTableToggle] = useState(0);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [firstAppRej, setFirstAppRej] = useState("");
  const [systemLiveDate, setSystemLiveDate] = useState(null);
  const [futureLimitDate, setFutureLimitDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [tempFromDate, setTempFromDate] = useState(null);
  const [tempToDate, setTempToDate] = useState(null);
  const [empIdSearch, setEmpIdSearch] = useState(false);
  const [tempChips, setTempChips] = useState({
    employeeChip: [],
    employeeIdChip: [],
    fromDate: null,
    toDate: null,
    empType: "",
    empCat: [],
  });
  const [isNewChip, setIsNewChip] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [empCategory, setEmpCategory] = useState([]);
  const [applyFilterClicked, setApplyFilterClicked] = useState(false);
  const [filteredTeamTimesheet, setFilteredTeamTimesheet] = useState([]);
  const [chips, setChips] = useState({
    employeeChip: [],
    employeeIdChip: [],
    fromDate: null,
    toDate: null,
    empType: "",
    empCat: [],
  });
  const remarkRef = useRef(null);
  const [multiDownload, setMultiDownload] = React.useState([]);
  const [checked, setChecked] = React.useState(false);
  const [filteredRowsData, setFilteredRowsData] = React.useState([]);
  const [isLoader, isSetLoader] = useState(true);
  // const [rowsToShow, setRowsToShow] = useState(5); // Number of rows to show initially
  const [noDataInFilterFlag, setNoDataInFilterFlag] = useState(0);
  const [noTeamApprovalData, setnoTeamApprovalData] = useState(0);
  const [displayFromDate, setDisplayFromDate] = useState(null);
  const [displayToDate, setdisplayToDate] = useState(null);
  const [error, setError] = useState(false);
  const [employeeType, setEmployeeType] = useState([]);
  const [remarkOpen, setRemarkOpen] = useState(false);
  const [remarkText, setRemarkText] = useState("");
  const [tooltipIndex, setTooltipIndex] = useState(-1);
  const [remarkOpenSys, setRemarkOpenSys] = useState(false);
  const [remarkTextSys, setRemarkTextSys] = useState("");
  const [tooltipIndexSys, setTooltipIndexSys] = useState(-1);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setChecked(true);
      let filterData =
        applyFilterClicked && teamTimesheet?.length > 0
          ? teamTimesheet
          : myTeamApproval;
      // stableSort(
      //   applyFilterClicked && teamTimesheet?.length > 0
      //     ? teamTimesheet
      //     : myTeamApproval,
      //   getComparator(order, orderBy)
      // );
      const newSelecteds = filterData.map((s) => s.timesheetDetailUniqueId);
      setSelected(newSelecteds);
      setMultiDownload(filterData);
      return;
    } else {
      setChecked(false);
    }
    setMultiDownload([]);
    setSelected([]);
  };

  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the default context menu behavior
  };

  const handleKeyDown = (e) => {
    if ((e.ctrlKey && e.shiftKey && e.key === "I") || e.key === "F12") {
      e.preventDefault(); // Prevent opening developer tools
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );
  const tsDelEmpIdRedux = useSelector(
    (state) => state?.timesheetData?.tsDelEmpId
  );
  var myTeamApproval = useSelector(
    (state) => state.timesheetData.getMyTeamsApproval
  );
  // console.log("myTeamApproval", myTeamApproval);

  const updatedStatusByManager = useSelector(
    (state) => state.timesheetData.isUpdateStatusByManager
  );

  const teamTimesheet = useSelector(
    (state) => state.timesheetData.getTeamTimesheets
  );

  const filterDropDown = useSelector(
    (state) => state?.timesheetData?.getMyTeamApprovalFilterDropDownList
  );
  const filterDataJson = filterDropDown ? JSON.parse(filterDropDown) : [];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  useEffect(() => {
    // console.log(ssoEmpIdDetails);
    const obj = {
      managerEmpId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
      pagenumber: 1,
      pagesize: rowsPerPage,
      //pageNo: 1,
    };
    dispatch(getMyTeamApprovalData(obj));
    dispatch(getMyTeamApprovalFilterDropDownData(obj));
  }, [dispatch, ssoEmpIdDetails, tsDelEmpIdRedux]);

  let IDs = [];

  const x = (chipList) => {
    return chipList.map((chip) => {
      const parts = chip.split("-");
      // Get the part after the third last '-'
      const id = parts.slice(-1)[0];
      return id;
    });
  };

  useEffect(() => {
    if (
      myTeamApproval &&
      (myTeamApproval?.length > 0 || myTeamApproval?.length === 0)
    ) {
      isSetLoader(false);
      setSystemLiveDate(new Date(myTeamApproval[0]?.systemLiveDate));
      setFutureLimitDate(new Date(myTeamApproval[0]?.futureWeekLimitFromDate));
    } else {
      isSetLoader(true);
    }
    if (
      myTeamApproval &&
      myTeamApproval?.length == 1 &&
      myTeamApproval[0]?.timesheetDetailUniqueId == ""
    ) {
      //  console.log("team approval data ", myTeamApproval);
      setnoTeamApprovalData(1);
    } else {
      setnoTeamApprovalData(0);
    }
  }, [myTeamApproval]);

  const handleApprove = (row, index, bulkVal) => {
    setChecked(false);
    isSetLoader(true);
    setApproveRejectStatus("Approved");
    setFirstAppRej("Approved");
    setCurrentIndex(index);
    handleApproveRejectByManager(row, "Approved", null, bulkVal);
    setSelected([]);
    setMultiDownload([]);
    setChecked(false);
  };

  const handleApproveRejectByManager = async (
    value,
    status,
    comment,
    bulkVal
  ) => {
    // console.log("here8", multiDownload);
    // console.log("here9", value);
    setChecked(false);

    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    let formData = new FormData();
    const tmdetails = [
      {
        tmdetailrecid: value?.timesheetDetailUniqueId,
        tmid: value?.timesheetID,
      },
    ];
    const tmdetails1 = multiDownload?.map((item) => ({
      tmdetailrecid: item.timesheetDetailUniqueId,
      tmid: item.timesheetID,
    }));
    // console.log("here10", tmdetails1);
    formData.append(
      "ManagerEmpId",
      Encrypt(
        key,
        JSON.stringify(
          tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId
        )
      )
    );
    // formData.append("delegateeId" , Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId == tsDelEmpIdRedux ? null :tsDelEmpIdRedux)))
    formData.append(
      "delegateeId",
      ssoEmpIdDetails?.empId === tsDelEmpIdRedux
        ? JSON.stringify(null)
        : Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
    formData.append("TmStatus", JSON.stringify(status));
    formData.append("Comment", JSON.stringify(comment));
    if (bulkVal) {
      formData.append("TmDetails", JSON.stringify(tmdetails1));
    } else {
      formData.append("TmDetails", JSON.stringify(tmdetails));
    }

    try {
      setShowApproveRejectDialog(false);
      const response = await dispatch(updateStatusByManagerData(formData));
      if (response.status == 200) {
        isSetLoader(false);
        setOpen(true);
        if (updatedStatusByManager) {
          myTeamApproval = myTeamApproval.filter(
            (item) =>
              item.timesheetDetailUniqueId != value?.timesheetDetailUniqueId
          );
        }

        setRefreshTableToggle(
          (prevRefreshTableToggle) => prevRefreshTableToggle + 1
        );

        setSnackBarData({
          name: value.employeeName,
          id: value.employeeID,
        });
      }
      isSetLoader(false);
    } catch (error) {
      // console.error("Post request failed:", error);
    }
    handleApplyFilter();

    // forceUpdate();
  };
  // useEffect(() => {
  //   console.log("show open useeffect", open);
  // }, [open]);

  const handleApproveRejectDialog = (index, status) => {
    setCurrentIndex(index);
    setFirstAppRej(status);
    setShowApproveRejectDialog(true);
  };

  const handleApproveRejectStatus = (stat) => {
    setApproveRejectStatus(stat);
  };

  const handleComment = (data) => {
    setApproveRejectComment(data);
  };

  const handleCloseApproveRejectDialog = () => {
    setShowApproveRejectDialog(false);
  };

  useEffect(() => {
    dispatch(
      getMyTeamApprovalData({
        managerEmpId: tsDelEmpIdRedux
          ? tsDelEmpIdRedux
          : ssoEmpIdDetails?.empId,
        pagenumber: 1,
        pagesize: rowsPerPage,
      })
    );
  }, [refreshTableToggle, dispatch]);

  useEffect(() => {
    if (
      isNewChip ||
      fromDate != null ||
      toDate != null ||
      employeeType != [] ||
      empCategory != [] ||
      projectNameId != []
    ) {
      setChips({
        employeeChip:
          tempChips?.employeeChip?.length > 0
            ? [...tempChips.employeeChip]
            : [],
        employeeIdChip:
          tempChips?.employeeIdChip?.length > 0
            ? [...tempChips.employeeIdChip]
            : [],
        fromDate: tempChips.fromDate,
        toDate: tempChips.toDate,
        empType: tempChips.empType,
        empCat: tempChips?.empCat?.length > 0 ? [...tempChips.empCat] : [],
      });
    }
    // console.log("employeeType Chip", tempChips);
  }, [isNewChip, fromDate, toDate, employeeType, empCategory, projectNameId]);

  useEffect(() => {
    if (employeeId === "") {
      setEmpIdSearch(false);
    }
  }, [employeeId, empIdSearch, filteredTeamTimesheet, teamTimesheet]);

  const handleSnackbar = () => {
    setOpen(true);
  };

  const headers = [
    {
      label: "Date",
      key: "date",
    },
    {
      label: "Employee Name",
      key: "employeeName",
    },
    {
      label: "Employee Id",
      key: "employeeID",
    },
    {
      label: "Hours",
      key: "dayHours",
    },
    {
      label: "Project Name",
      key: "projName",
    },
    {
      label: "Project Id",
      key: "projectId",
    },
    {
      label: "Employee Comment",
      key: "employeeComments",
    },
    {
      label: "System Comment",
      key: "systemComments",
    },
  ];

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const csvLink = {
    headers: headers,
    data: multiDownload,
    filename: `MyTeamTimesheet_${moment(new Date()).format(
      "DDMMYYYY_HHmm"
    )}.csv`,
  };

  const handleClick = (event, name) => {
    // console.log("name", name , event , selected);
    var selectedIndex = -1;
    if (selected == null) {
      selectedIndex = -1;
    } else {
      selectedIndex = selected.indexOf(name.timesheetDetailUniqueId);
    }
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.timesheetDetailUniqueId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      setChecked(false);
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      setChecked(false);
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      setChecked(false);
    }

    if (newSelected.length == myTeamApproval.length) setChecked(true);
    setSelected(newSelected);
    // console.log("selected value", checked);

    const objIndex = multiDownload.findIndex(
      (el) => el.timesheetDetailUniqueId == name.timesheetDetailUniqueId
    );
    let newMultiDownload = [];
    if (objIndex >= 0) {
      newMultiDownload = [
        ...multiDownload.filter(
          (el) => el.timesheetDetailUniqueId != name.timesheetDetailUniqueId
        ),
      ];
    } else {
      newMultiDownload = [...multiDownload, name];
    }
    setMultiDownload(newMultiDownload);
  };

  const handlesysCommentOpen = (row, index) => {
    // console.log("checking", row, index);
    setTooltipIndexSys(index);
    setRemarkTextSys(row.systemComments);
    setRemarkOpenSys(true);
  };
  const handleRemarkOpen = (row, index) => {
    // console.log("checking", row, index);
    setTooltipIndex(index);
    let x = row?.employeeComments?.includes("#~#~")
      ? row?.employeeComments?.replace(/#~#~/g, " ")
      : row?.employeeComments;
    setRemarkText(x);
    setRemarkOpen(true);
  };

  const handleRemarkClose = () => {
    setRemarkOpen(false);
    setRemarkOpenSys(false);
  };
  useEffect(() => {
    // Close the tooltip when user clicks outside
    function handleClickOutside(event) {
      if (remarkRef.current && !remarkRef.current.contains(event.target)) {
        setRemarkOpen(false);
        setRemarkOpenSys(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleApplyFilter = () => {
    //console.log("here12", tempChips, tempChips.employeeChip, tempChips.employeeIdChip);

    if (
      (tempChips?.fromDate &&
        tempChips?.toDate &&
        tempChips?.fromDate > tempChips?.toDate) ||
      tempChips?.fromDate > toDate ||
      fromDate > tempChips?.toDate
    ) {
    } else if (
      fromDate ||
      toDate ||
      tempChips?.employeeChip ||
      tempChips?.employeeIdChip ||
      tempChips?.empType ||
      tempChips?.empCat
    ) {
      setTempFromDate(fromDate ? fromDate : tempFromDate ? tempFromDate : null);
      setTempToDate(toDate ? toDate : tempToDate ? tempToDate : null);
      setApplyFilterClicked(true);
      setIsNewChip(true);
      // setFromDate(null);
      // setToDate(null);
      // setProjectNameId([]);
      // setEmployeeId("");
      // setEmployeeType([]);
      // setEmpCategory([]);

      if (tempChips?.employeeChip.length > 0) {
        IDs = x(tempChips?.employeeChip);
      }
      let obj = {
        managerEmpId: tsDelEmpIdRedux
          ? tsDelEmpIdRedux
          : ssoEmpIdDetails?.empId,
        fromDate: tempChips?.fromDate ? tempChips?.fromDate : "",
        toDate: tempChips?.toDate ? tempChips?.toDate : "",
        projNameId: tempChips.employeeChip ? IDs : [],
        empNameId: tempChips.employeeIdChip ? tempChips.employeeIdChip : [],
        empType: tempChips?.empType ? tempChips?.empType : "",
        empCat: tempChips.empCat ? tempChips.empCat : [],
        pagenumber: 1,
        pagesize: rowsPerPage,
      };
      dispatch(getFilterTeamTimesheetData(obj));
    }
    setPage(0);
  };

  useEffect(() => {
    if (
      teamTimesheet &&
      teamTimesheet?.length == 1 &&
      teamTimesheet[0]?.timesheetDetailUniqueId == ""
    ) {
      // console.log("ertyu", noDataInFilterFlag);
      setNoDataInFilterFlag(1);
    } else {
      setNoDataInFilterFlag(0);
    }
  }, [teamTimesheet]);

  const handleChangePage = (event, newPage) => {
    if (tempChips?.employeeChip.length > 0) {
      IDs = x(tempChips?.employeeChip);
    }
    // console.log("props on change 0 ", page);
    isSetLoader(true);
    if (applyFilterClicked) {
      let obj = {
        managerEmpId: tsDelEmpIdRedux
          ? tsDelEmpIdRedux
          : ssoEmpIdDetails?.empId,
        fromDate: tempChips?.fromDate ? tempChips?.fromDate : "",
        toDate: tempChips?.toDate ? tempChips?.toDate : "",
        projNameId: tempChips.employeeChip ? IDs : [],
        empNameId: tempChips.employeeIdChip ? tempChips.employeeIdChip : [],
        empType: tempChips?.empType ? tempChips?.empType : "",
        empCat: tempChips.empCat ? tempChips.empCat : [],
        pagenumber: newPage + 1,
        pagesize: rowsPerPage,
      };
      // dispatch(getMyTeamApproval());
      dispatch(getFilterTeamTimesheetData(obj)).then(() => isSetLoader(false));
    } else {
      dispatch(
        getMyTeamApprovalData({
          managerEmpId: tsDelEmpIdRedux
            ? tsDelEmpIdRedux
            : ssoEmpIdDetails?.empId,
          fromDate: tempChips?.fromDate ? tempChips?.fromDate : "",
          toDate: tempChips?.toDate ? tempChips?.toDate : "",
          projNameId: tempChips.employeeChip ? IDs : [],
          empNameId: tempChips.employeeIdChip ? tempChips.employeeIdChip : [],
          empType: tempChips?.empType ? tempChips?.empType : "",
          pagenumber: newPage + 1,
          pagesize: rowsPerPage,
        })
      ).then(() => isSetLoader(false));
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    if (tempChips?.employeeChip.length > 0) {
      IDs = x(tempChips?.employeeChip);
    }
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    isSetLoader(true);
    if (applyFilterClicked) {
      let obj = {
        managerEmpId: tsDelEmpIdRedux
          ? tsDelEmpIdRedux
          : ssoEmpIdDetails?.empId,
        fromDate: tempChips?.fromDate ? tempChips?.fromDate : "",
        toDate: tempChips?.toDate ? tempChips?.toDate : "",
        projNameId: tempChips.employeeChip ? IDs : [],
        empNameId: tempChips.employeeIdChip ? tempChips.employeeIdChip : [],
        empType: tempChips?.empType ? tempChips?.empType : "",
        empCat: tempChips.empCat ? tempChips.empCat : [],
        pagenumber: 1,
        pagesize: parseInt(event.target.value, 10),
      };
      // dispatch(getMyTeamApproval());
      dispatch(getFilterTeamTimesheetData(obj)).then(() => isSetLoader(false));
    } else {
      dispatch(
        getMyTeamApprovalData({
          managerEmpId: tsDelEmpIdRedux
            ? tsDelEmpIdRedux
            : ssoEmpIdDetails?.empId,
          pagenumber: 1,
          pagesize: parseInt(event.target.value, 10),
        })
      ).then(() => isSetLoader(false));
    }
  };

  const handleFromDate = (event) => {
    const selectedValue = moment(event).format("yyyy-MM-DD");
    setFromDate(selectedValue);
    if (tempChips.toDate != null && tempChips.toDate < selectedValue) {
      setTempChips((prevChips) => ({
        ...prevChips,
        fromDate: selectedValue,
      }));
      setIsNewChip(false);
      setError(true);
      return;
    } else {
      setTempChips((prevChips) => ({
        ...prevChips,
        fromDate: selectedValue,
      }));
      setIsNewChip(false);
      setError(false);
    }
  };
  const handleToDate = (event) => {
    const selectedValue = moment(event).format("yyyy-MM-DD");
    setToDate(selectedValue);

    if (tempChips.fromDate != null && tempChips.fromDate > selectedValue) {
      setError(true);

      return;
    } else {
      setTempChips((prevChips) => ({
        ...prevChips,
        toDate: selectedValue,
      }));
      setIsNewChip(false);
      setError(false);
    }
  };

  const handleProjectNameId = (event, value) => {
    const selectedValue = value;
    // const isDuplicate = tempChips?.empCat?.includes(selectedValue.map((item) => item));
    const originalValues = value.map((x) =>
      x.replace(/@\$\@/g, "-")
    );
    // console.log("duplicate", tempChips, value, event, empCategory);
    if (value) {
      setProjectNameId(value);
      setTempChips((prevChips) => ({
        ...prevChips,
        employeeChip: originalValues,
      }));
      setIsNewChip(true);
    } else {
      setProjectNameId([]);
      setTempChips((prevChips) => ({
        ...prevChips,
        employeeChip: [],
      }));
    }
  };
  const handleEmpCatChange = (event, value) => {
    const selectedValue = value;
    // const isDuplicate = tempChips?.empCat?.includes(selectedValue.map((item) => item));

    // console.log("duplicate", tempChips, value, event, empCategory);
    if (value) {
      setEmpCategory(value);
      setTempChips((prevChips) => ({
        ...prevChips,
        empCat: value,
      }));
      setIsNewChip(true);
    } else {
      setEmpCategory([]);

      setTempChips((prevChips) => ({
        ...prevChips,
        empCat: [],
      }));
    }
  };
  const handleEmployeeId = (event) => {
    const selectedValue = event.target.value;
    const isDuplicate = tempChips.employeeIdChip.includes(selectedValue);

    const handleEmployeeID = (shouldUpdateState) => {
      if (shouldUpdateState && !isDuplicate) {
        setEmployeeId(selectedValue);
        setTempChips((prevChips) => ({
          ...prevChips,
          employeeIdChip: [...prevChips.employeeIdChip, selectedValue],
        }));
        setIsNewChip(false);
      }
    };

    if (
      (event.key === "Enter" && selectedValue.length > 0) ||
      (event.type === "blur" && selectedValue.length > 0)
    ) {
      handleEmployeeID(true);
    } else {
      setEmployeeId(selectedValue);
    }
  };

  const handleEmpTypeChange = (event, value) => {
    // const selectedValue = event.target.textContent;
    const selectedValue = [value[value.length - 1]];

    const isDuplicate = tempChips.empType == selectedValue;

    const handleEmployeeID = (shouldUpdateState) => {
      if (shouldUpdateState && !isDuplicate) {
        setEmployeeType(selectedValue);
        setTempChips((prevChips) => ({
          ...prevChips,
          empType: selectedValue[0] != undefined ? selectedValue : "",
        }));

        setIsNewChip(false);
      }
    };

    if (
      (event.key === "Enter" && selectedValue.length > 0) ||
      (event.type === "click" && selectedValue.length > 0)
    ) {
      handleEmployeeID(true);
    } else {
      setEmployeeType(selectedValue);
      setTempChips((prevChips) => ({
        empType: [],
      }));
    }
  };

  const handleChipDelete = (chipToDelete, chipType) => {
    // console.log("tyurhkef",projectNameId,chipToDelete,tempChips,chipType)
    setTempChips((prevChips) => {
      const updatedChips = { ...prevChips };

      updatedChips.employeeChip = updatedChips.employeeChip.filter(
        (chip) => chip !== chipToDelete
      );
      if(chipType == "employeeChip"){
        let val = projectNameId.filter((value) => value.replace(/@\$\@/g,"-") !== chipToDelete);
        setProjectNameId(val);
      }
      updatedChips.employeeIdChip = updatedChips.employeeIdChip.filter(
        (chip) => chip !== chipToDelete
      );
      updatedChips.empCat = updatedChips.empCat.filter(
        (chip) => chip !== chipToDelete
      );

      updatedChips.fromDate =
        updatedChips.fromDate == chipToDelete && chipType == "fromDate"
          ? null
          : updatedChips.fromDate;
      updatedChips.toDate =
        updatedChips.toDate == chipToDelete && chipType == "toDate"
          ? null
          : updatedChips.toDate;
      updatedChips.empType =
        updatedChips.empType == chipToDelete && chipType == "empType"
          ? null
          : updatedChips.empType;
      setEmpCategory(updatedChips?.empCat);
      // setProjectNameId(updatedChips?.employeeChip);
      if (tempChips?.employeeChip.length > 0) {
        IDs = x(tempChips?.employeeChip);
      }
      // console.log("updatedchips" , updatedChips)
      if (
        updatedChips.employeeChip ||
        updatedChips.employeeIdChip ||
        updatedChips.toDate ||
        updatedChips.fromDate ||
        updatedChips.empType ||
        updatedChips.empCat
      ) {
        const obj = {
          managerEmpId: tsDelEmpIdRedux
            ? tsDelEmpIdRedux
            : ssoEmpIdDetails?.empId,
          fromDate: updatedChips.fromDate ? updatedChips.fromDate : "",
          toDate: updatedChips.toDate ? updatedChips.toDate : "",
          empNameId: updatedChips.employeeIdChip
            ? updatedChips.employeeIdChip
            : [],
          projNameId: IDs ? updatedChips.employeeChip : [],
          empType: updatedChips?.empType ? updatedChips?.empType : "",
          empCat: updatedChips.empCat ? updatedChips.empCat : [],
          pagenumber: 1,
          pagesize: rowsPerPage,
        };
        dispatch(getFilterTeamTimesheetData(obj));
      } else {
        const obj = {
          managerEmpId: tsDelEmpIdRedux
            ? tsDelEmpIdRedux
            : ssoEmpIdDetails?.empId,
          fromDate: "",
          toDate: "",
          projNameId: [],
          empNameId: [],
          empType: "",
          empCat: [],
          pagenumber: 1,
          pagesize: rowsPerPage,
        };
        dispatch(getFilterTeamTimesheetData(obj));
      }
      return updatedChips;
    });

    setChips((prevChips) => {
      const updatedChips = { ...prevChips };

      updatedChips.employeeChip = updatedChips.employeeChip.filter(
        (chip) => chip !== chipToDelete
      );
      updatedChips.employeeIdChip = updatedChips.employeeIdChip.filter(
        (chip) => chip !== chipToDelete
      );
      updatedChips.empCat = updatedChips.empCat.filter(
        (chip) => chip !== chipToDelete
      );
      updatedChips.fromDate =
        updatedChips.fromDate == chipToDelete && chipType == "fromDate"
          ? null
          : updatedChips.fromDate;
      updatedChips.toDate =
        updatedChips.toDate == chipToDelete && chipType == "toDate"
          ? null
          : updatedChips.toDate;
      updatedChips.empType =
        updatedChips.empType == chipToDelete && chipType == "empType"
          ? null
          : updatedChips.empType;

      return updatedChips;
    });

    if (projectNameId === chipToDelete) {
      setProjectNameId([]);
    }
    if (employeeId === chipToDelete) {
      setEmployeeId("");
    }
    if (employeeType == chipToDelete) {
      setEmployeeType([]);
    }
    // if (empCategory == chipToDelete) {
    //   setEmpCategory([]);
    // }
  };

  let currentDate = new Date();
  let firstDayOfMonth = startOfMonth(currentDate);
  let lastDayOfMonth = endOfMonth(currentDate);

  const handleClearAll = () => {
    setFromDate(null);
    setToDate(null);
    setTempFromDate(null);
    setTempToDate(null);
    IDs = [];
    setTempChips({
      employeeChip: [],
      employeeIdChip: [],
      fromDate: null,
      toDate: null,
      empType: "",
      empCat: [],
    });

    setChips({
      employeeChip: [],
      employeeIdChip: [],
      fromDate: null,
      toDate: null,
      empType: "",
      empCat: [],
    });
    setProjectNameId([]);
    setEmployeeId("");
    setEmployeeType([]);
    setEmpCategory([]);
    setNoDataInFilterFlag(0);
    if (firstDayOfMonth && lastDayOfMonth) {
      const obj = {
        managerEmpId: tsDelEmpIdRedux
          ? tsDelEmpIdRedux
          : ssoEmpIdDetails?.empId,
        fromDate: "",
        toDate: "",
        projNameId: [],
        empNameId: [],
        empType: "",
        empCat: [],
        pagenumber: 1,
        pagesize: rowsPerPage,
      };
      dispatch(getFilterTeamTimesheetData(obj));
      setPage(0);
    }
  };
  const handleList1 = (option) => {
    //console.log("option", option);
    return (
      <>
        <Box style={{ display: "flex", marginTop: "16px" }}>
          <div className={classes.description}>
            <Typography
              component="div"
              className={classes.designation}
              style={{ fontSize: "12px" }}
            >
              {option}
            </Typography>
          </div>
        </Box>
      </>
    );
  };
  // const filterOptions = createFilterOptions({
  //   matchFrom: "any",
  //   limit: 400,
  // });
  const handlecloseSnackbar = () => {
    setOpen(false);
  };

  return (
    <div className="outer-container" onContextMenu={handleContextMenu}>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handlecloseSnackbar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.anchorStyle}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          style={{
            background:
              approveRejectStatus === "Approved" ? "#F0FFF6" : "#FEF0F3",
          }}
        >
          {approveRejectStatus == "Approved" ? (
            <Grid style={{ height: "67px", width: "300px" }}>
              <div style={{ display: "flex" }}>
                <Grid
                  style={{
                    marginLeft: "43px",
                    paddingTop: "9px",
                    marginRight: "90px",
                  }}
                  className={classes.snackbarMessage}
                >
                  {" "}
                  {"Timesheet Approved"}
                </Grid>
                <Grid style={{ marginleft: "90px", paddingTop: "5px" }}>
                  <CloseOutlinedIcon onClick={handlecloseSnackbar} />
                </Grid>
              </div>
              <div style={{ display: "flex" }}>
                <Grid style={{ marginLeft: "10px", marginTop: "-25px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M15 2L20 7M5 22H19C19.2652 22 19.5196 21.8946 19.7071 21.7071C19.8946 21.5196 20 21.2652 20 21V7H15V2H5C4.73478 2 4.48043 2.10536 4.29289 2.29289C4.10536 2.48043 4 2.73478 4 3V21C4 21.2652 4.10536 21.5196 4.29289 21.7071C4.48043 21.8946 4.73478 22 5 22Z"
                      stroke="#349259"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.5 14.5L11.5 17L16 11.5"
                      stroke="#349259"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Grid>
                {snackBarData?.name == "" ||
                snackBarData?.name == undefined ||
                snackBarData?.id == " " ||
                snackBarData?.id == undefined ? (
                  <Grid className={classes.snackbarMessage1}>
                    {/* {" "}

                  {snackBarData?.name} {" | "}

                  ID : {snackBarData?.id} */}
                  </Grid>
                ) : (
                  <Grid className={classes.snackbarMessage1}>
                    {" "}
                    {snackBarData?.name} {" | "}
                    ID : {snackBarData?.id}
                  </Grid>
                )}
              </div>
            </Grid>
          ) : (
            <Grid style={{ height: "67px", width: "300px" }}>
              <div style={{ display: "flex" }}>
                <Grid
                  style={{
                    marginLeft: "43px",
                    paddingTop: "9px",
                    marginRight: "90px",
                  }}
                  className={classes.snackbarMessage}
                >
                  {" "}
                  {"Timesheet Rejected"}
                </Grid>
                <Grid style={{ marginleft: "90px", paddingTop: "5px" }}>
                  <CloseOutlinedIcon onClick={handlecloseSnackbar} />
                </Grid>
              </div>
              <div style={{ display: "flex" }}>
                <Grid style={{ marginLeft: "10px", marginTop: "-25px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M15 2L20 7M5 22H19C19.2652 22 19.5196 21.8946 19.7071 21.7071C19.8946 21.5196 20 21.2652 20 21V7H15V2H5C4.73478 2 4.48043 2.10536 4.29289 2.29289C4.10536 2.48043 4 2.73478 4 3V21C4 21.2652 4.10536 21.5196 4.29289 21.7071C4.48043 21.8946 4.73478 22 5 22Z"
                      stroke="#D50606"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9 11L15 17"
                      stroke="#D50606"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15 11L9 17"
                      stroke="#D50606"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Grid>
                <Grid className={classes.snackbarMessage1}>
                  {snackBarData?.name} {" | "}
                  {snackBarData?.id}
                </Grid>
              </div>
            </Grid>
          )}
        </Grid>
      </Snackbar>
      <Box display="flex" flexDirection="column">
        <Box
          // container
          // spacing={1}
          style={{
            display: "flex",
            marginBottom: "6px",
            height: "40px",
            // alignItems: "center",
            // justifyContent: "space-between",
          }}
        >
          <Box style={{ width: "148px" }}>
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              // style={{ width: "136px" }}
            >
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                fullWidth
                style={{
                  marginLeft: "12px",
                  width: "132px",
                }}
                variant="inline"
                size="small"
                format="MMM d, yy"
                autoOk={true}
                // minDate={}
                minDate={systemLiveDate}
                maxDate={futureLimitDate}
                id="date-picker-inline"
                label={
                  <span style={{ color: "#000", fontSize: "14px" }}>
                    From Date
                  </span>
                }
                value={fromDate}
                onChange={handleFromDate}
                // onChange={(date) =>{ setFromDate(date);
                //  setTempFromDate(date);
                // }}
                inputProps={{
                  style: { fontSize: "14px", fontWeight: "500" },
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                  style: { height: "16px", width: "16px" },
                }}
                helperText={
                  (toDate != null || tempChips.toDate != null) &&
                  (toDate < fromDate ||
                    toDate < tempChips?.fromDate ||
                    tempChips?.toDate < fromDate)
                    ? "*To Date should be greater than From Date"
                    : ""
                }
                FormHelperTextProps={{
                  style: {
                    color: "red",
                    paddingTop: "5px",
                    marginLeft: "0px",
                    marginRight: "0px",
                    marginTop: 0,
                    marginBottom: "-24px",
                    width: "max-content",
                  },
                }}
              />
            </MuiPickersUtilsProvider>
          </Box>
          <Box
            style={{
              // marginLeft: "-12px",
              width: "128px",
            }}
          >
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              style={{ height: "40px" }}
            >
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                fullWidth
                style={{
                  // marginLeft: "10px",
                  // width: "132px",
                  "& .MuiOutlinedInput-adornedEnd": {
                    height: "40px",
                  },
                }}
                variant="inline"
                minDate={systemLiveDate}
                maxDate={futureLimitDate}
                size="small"
                format="MMM d, yy"
                autoOk={true}
                id="date-picker-inline"
                label={
                  <span style={{ color: "#000", fontSize: "14px" }}>
                    To Date
                  </span>
                }
                value={toDate}
                onChange={handleToDate}
                // onChange={(date) => {setToDate(date) ;
                // setTempToDate(date)}}
                inputProps={{
                  style: { fontSize: "14px", fontWeight: "500" },
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                  style: { height: "16px", width: "16px" },
                }}
              />
            </MuiPickersUtilsProvider>
          </Box>
          <Box
            //  style={{  }}
            className={classes.boxForEmpType}
          >
            <Autocomplete
              multiple
              options={
                Array.isArray(filterDataJson?.EntityCode)
                  ? filterDataJson?.EntityCode
                  : []
              }
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  height: "44px",
                },
                ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                  top: "auto",
                },
                ".MuiIconButton-root": {
                  padding: "2px",
                  marginBottom: "14px",
                },
              }}
              value={employeeType}
              onChange={handleEmpTypeChange}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              getOptionSelected={(option, value) =>
                employeeType.indexOf(option) !== -1
              }
              renderOption={(props, option, { selected }) => (
                <li
                  style={{ paddingTop: "0px", paddingBottom: "0px" }}
                  {...props}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: selected ? "#0B1941" : "" }}
                        checked={selected}
                      />
                    }
                    label={<span style={{ fontSize: "12px" }}>{option}</span>}
                  />
                </li>
              )}
              renderTags={() => null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ height: "40px" }}
                  label="Employee Type"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{
                    style: {
                      fontSize: "12px",
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                    }, // Set the font size here
                  }}
                />
              )}
            />
          </Box>
          <Box style={{ width: "15%" }} className={classes.boxForEmpType}>
            <Autocomplete
              multiple
              options={
                Array.isArray(filterDataJson?.Horizontal)
                  ? filterDataJson?.Horizontal
                  : []
              }
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  height: "44px",
                },
                ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                  top: "auto",
                },
                ".MuiIconButton-root": {
                  padding: "2px",
                  marginBottom: "14px",
                },
              }}
              value={empCategory}
              onChange={handleEmpCatChange}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              getOptionSelected={(option, value) =>
                empCategory.indexOf(option) !== -1
              }
              renderOption={(props, option, { selected }) => (
                <li
                  style={{ paddingTop: "0px", paddingBottom: "0px" }}
                  {...props}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: selected ? "#0B1941" : "" }}
                        checked={selected}
                      />
                    }
                    label={<span style={{ fontSize: "12px" }}>{option}</span>}
                  />
                </li>
              )}
              renderTags={() => null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    style: {
                      fontSize: "12px",
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                    }, // Set the font size here
                  }}
                  label="Employee Category"
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </Box>
          <Box style={{ marginLeft: "3px", width: "208px" }}>
            <Autocomplete
              multiple
              options={
                Array.isArray(filterDataJson?.ProjectName)
                  ? filterDataJson?.ProjectName
                  : []
              }
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  height: "44px",
                },
                ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                  top: "auto",
                },
                ".MuiIconButton-root": {
                  padding: "2px",
                  marginBottom: "14px",
                },
              }}
              value={projectNameId}
              onChange={handleProjectNameId}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              getOptionSelected={(option, value) =>
                projectNameId.indexOf(option) !== -1
              }
              renderOption={(props, option, { selected }) => (
                <li
                  style={{ paddingTop: "0px", paddingBottom: "0px" }}
                  {...props}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: selected ? "#0B1941" : "" }}
                        checked={selected}
                      />
                    }
                    label={
                      <span style={{ fontSize: "12px" }}>
                        {option.replace(/@\$\@/g, "-")}
                      </span>
                    }
                  />
                </li>
              )}
              renderTags={() => null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    style: {
                      fontSize: "12px",
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                    }, // Set the font size here
                  }}
                  label="Project Name/Id"
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </Box>

          <Box style={{ marginLeft: "3px", width: "230px" }}>
            <FormControl
              variant="outlined"
              fullWidth
              style={
                {
                  // width: "230px",
                  // marginLeft:"-51px"
                }
              }
            >
              <InputLabel
                htmlFor="search-input"
                style={{
                  marginTop: "-5px",
                  fontSize: "12px",
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                }}
              >
                Search by Employee Name / Id
              </InputLabel>
              <OutlinedInput
                id="search-input"
                size="small"
                style={{ paddingRight: "0px", height: "40px" }}
                value={employeeId}
                onChange={handleEmployeeId}
                onKeyDown={handleEmployeeId}
                onBlur={handleEmployeeId}
                //onKeyPress= {inputValidation}
                // inputProps={{
                //   maxLength: 6,
                // }}
                endAdornment={
                  <InputAdornment position="start">
                    <SearchIcon style={{ height: "20px", width: "20px" }} />
                  </InputAdornment>
                }
                label="Search by Employee Name / Id"
              />
            </FormControl>
          </Box>

          <Box style={{ marginLeft: "10px", marginTop: "5px" }}>
            <Box style={{ width: "100px" }}>
              <Link
                style={{
                  color: "#008CE6",
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "24px",
                  letterSpacing: "0px",
                  textAlign: "left",
                  cursor: "pointer",
                  // marginLeft:"10px"
                }}
                underline="always"
                onClick={handleApplyFilter}
              >
                Apply Filter
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>

      {
        <div
          style={{
            paddingTop: "10px",
            // paddingBottom: "10px",
          }}
        >
          {Object.entries(chips).map(([chipType, chipValue], index) => (
            <React.Fragment key={index}>
              {Array.isArray(chipValue)
                ? chipValue.map((value, valueIndex) => (
                    <Chip
                      key={`${chipType}-${valueIndex}`}
                      label={value}
                      onDelete={() => handleChipDelete(value, chipType)}
                      style={{
                        margin: "5px",
                        backgroundColor: "#fff",
                        color: "#000",
                        fontWeight: "600",
                        border: "1px solid #000",
                      }}
                      deleteIcon={
                        <ClearIcon
                          style={{
                            color: "#000",
                            height: "18px",
                            width: "18px",
                          }}
                        />
                      }
                    />
                  ))
                : chipValue && (
                    <Chip
                      key={`${chipType}-${chipValue}`}
                      label={
                        chipType == "fromDate"
                          ? `From Date : ${chipValue}`
                          : chipType == "toDate"
                          ? `To Date : ${chipValue}`
                          : chipType == "empType"
                          ? `${chipValue}`
                          : `${chipType}-${chipValue}`
                      }
                      onDelete={() => handleChipDelete(chipValue, chipType)}
                      style={{
                        margin: "5px",
                        backgroundColor: "#fff",
                        color: "#000",
                        fontWeight: "600",
                        border: "1px solid #000",
                      }}
                      deleteIcon={
                        <ClearIcon
                          style={{
                            color: "#000",
                            height: "18px",
                            width: "18px",
                          }}
                        />
                      }
                    />
                  )}
            </React.Fragment>
          ))}

          {Object.values(chips).some((chipValue) =>
            Array.isArray(chipValue) ? chipValue.length > 0 : chipValue
          ) ? (
            // || displayToDate!= null || displayFromDate != null

            <Link
              style={{
                color: "#008CE6",
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                letterSpacing: "0px",
                textAlign: "left",
                cursor: "pointer",
              }}
              underline="always"
              onClick={handleClearAll}
            >
              Clear All
            </Link>
          ) : (
            <></>
          )}
        </div>
      }
      <Box
        display="flex"
        justifyContent="flex-end"
        style={{
          paddingBottom: "5px",
          paddingLeft: "22px",
        }}
      >
        {/* <CSVLink
              {...csvLink}
              style={
                selected.length > 0
                  ? {}
                  : {
                      color: "transparent",
                      pointerEvents: "none",
                      cursor: "pointer",
                    }
              }
            > */}
        {selected?.length > 0 && selected[0] !== "" && (
          <Button
            variant="outlined"
            color="primary"
            //disabled={selected.length > 0 ? false : true}
            style={{
              whiteSpace: "nowrap",
              textTransform: "unset",
              padding: "4px",
              backgroundColor: "#349259",
              color: "#043365",
              border: "1px solid #979797",
              marginRight: "8px",
            }}
            onClick={() => handleApprove("", "", "Yes")}
          >
            <CheckCircleOutlineIcon
              style={{
                width: "12px",
                height: "12px",
                color: "#FFFFFF",
              }}
            />
            <Typography
              style={{
                color: "#FFFFFF",
                fontSize: "14px",
              }}
            >
              &nbsp;Bulk Approval
            </Typography>
          </Button>
        )}
        {/* </CSVLink> */}
      </Box>

      <Paper className={classes.paper}>
        {/* <MyTimesheetTableToolbar numSelected={selected.length} /> */}
        <TableContainer
          style={{ overflowX: "clip" }}
          // className="scroll-container"
          // style={{
          //   height: "450px",
          //   // overflowY: "scroll",
          //   // overflowX: "clip",
          //   // width: "100%",
          //   overflowY: "auto",
          //   maxHeight: "calc(100vh - 20px)",
          // }}
          //onScroll={handleScroll}
          // style={{
          //   // overflowX: "clip",
          //   width: "100%",
          // }}
        >
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            //size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <MyTeamApprovalTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              checked={checked}

              //rowCount={rows.length}
            />
            {/* {console.log("hjkloiu"  , checked )} */}
            <TableBody>
              {myTeamApproval?.length > 0 && !isLoader ? (
                // stableSort(
                (applyFilterClicked && teamTimesheet?.length > 0
                  ? teamTimesheet
                  : myTeamApproval
                )
                  // getComparator(order, orderBy)
                  // )
                  ?.map((row, index) => {
                    const isItemSelected = isSelected(
                      row.timesheetDetailUniqueId
                    );
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return row?.timesheetDetailUniqueId != "" ? (
                      <TableRow
                        hover
                        className={classes.tableRow}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.timesheetDetailUniqueId}
                        selected={isItemSelected}
                        backgroundColor={isItemSelected ? "" : ""}
                      >
                        <TableCell
                          padding="checkbox"
                          className={classes.tableCell}
                          style={{
                            position: "sticky",
                            left: 0,
                            //background: "none",
                            backgroundColor: isItemSelected ? "" : "",
                            zIndex: 800,
                            minWidth: "53px",
                            padding: "0px",
                            // color:"#EFF2F8",
                          }}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            style={{ paddingLeft: "12px" }}
                            checkedIcon={
                              <CheckBoxIcon
                                fontSize="small"
                                className={classes.checkBoxRoot}
                              />
                            }
                            onClick={(event) => handleClick(event, row)}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell
                          style={{
                            paddingLeft: "0px",
                            paddingRight: "0px",
                            backgroundColor: isItemSelected ? "" : "",
                            minWidth: "70px",
                          }}
                          onClick={() => {
                            // console.log("hi table row clicked!");

                            const text = "/timesheetviewpage/:id";

                            const result = text.replace(
                              ":id",
                              row.timesheetDetailUniqueId
                            );

                            history.push({
                              pathname: result,

                              state: {
                                empId: row.empId,

                                managerId: tsDelEmpIdRedux
                                  ? tsDelEmpIdRedux
                                  : ssoEmpIdDetails?.empId,
                                delegateeId:
                                  ssoEmpIdDetails?.empId == tsDelEmpIdRedux
                                    ? null
                                    : ssoEmpIdDetails?.empId,
                                fromDate: moment(row.fromDate).format(
                                  "yyyy-MM-DD"
                                ),

                                toDate: moment(row.toDate).format("yyyy-MM-DD"),
                                projId: "",

                                tab: "myTeamApproval",

                                compName: "DetailedView",
                              },
                            });
                          }}
                        >
                          <Grid container direction="column" spacing={1}>
                            <Grid item>
                              <Typography
                                variant="body1"
                                style={{ fontSize: "14px" }}
                              >
                                {row.date}
                              </Typography>{" "}
                            </Grid>

                            <Grid item style={{ paddingTop: "0px" }}>
                              <Typography
                                variant="body1"
                                style={{ fontSize: "12px" }}
                              >
                                {row.dayType}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell
                          padding="none"
                          style={{
                            textAlign: "left",
                            paddingLeft: "2px",
                            backgroundColor: isItemSelected ? "" : "",
                          }}
                          onClick={() => {
                            // console.log("hi table row clicked!");
                            const text = "/timesheetviewpage/:id";
                            const result = text.replace(":id", row.empId);
                            history.push({
                              pathname: result,
                              state: {
                                empId: row.empId,
                                managerId: tsDelEmpIdRedux
                                  ? tsDelEmpIdRedux
                                  : ssoEmpIdDetails?.empId,
                                delegateeId:
                                  ssoEmpIdDetails?.empId == tsDelEmpIdRedux
                                    ? null
                                    : ssoEmpIdDetails?.empId,
                                fromDate: moment(row.fromDate).format(
                                  "yyyy-MM-DD"
                                ),
                                toDate: moment(row.toDate).format("yyyy-MM-DD"),
                                projId: "",
                                tab: "myTeamApproval",
                                compName: "DetailedView",
                              },
                            });
                          }}
                        >
                          <Grid
                            container
                            direction="column"
                            spacing={1}
                            style={{ width: "100%" }}
                          >
                            <Grid item>
                              <Typography
                                variant="body1"
                                style={{ fontSize: "14px" }}
                              >
                                {row.employeeName}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="body1"
                                style={{ fontSize: "12px" }}
                              >
                                {row.employeeID}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>{" "}
                        <TableCell
                          padding="none"
                          style={{
                            textAlign: "left",
                            paddingLeft: "5px",
                            backgroundColor: isItemSelected ? "" : "",
                          }}
                          onClick={() => {
                            // console.log("hi table row clicked!");
                            const text = "/timesheetviewpage/:id";
                            const result = text.replace(":id", row.empId);
                            history.push({
                              pathname: result,
                              state: {
                                empId: row.empId,
                                managerId: tsDelEmpIdRedux
                                  ? tsDelEmpIdRedux
                                  : ssoEmpIdDetails?.empId,
                                delegateeId:
                                  ssoEmpIdDetails?.empId == tsDelEmpIdRedux
                                    ? null
                                    : ssoEmpIdDetails?.empId,
                                fromDate: moment(row.fromDate).format(
                                  "yyyy-MM-DD"
                                ),
                                toDate: moment(row.toDate).format("yyyy-MM-DD"),
                                projId: "",
                                tab: "myTeamApproval",
                                compName: "DetailedView",
                              },
                            });
                          }}
                        >
                          <Grid
                            container
                            direction="column"
                            spacing={1}
                            style={{ width: "100%" }}
                          >
                            <Grid item>
                              <Typography
                                variant="body1"
                                style={{ fontSize: "12px" }}
                              >
                                {row.employeeType}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="body1"
                                style={{ fontSize: "12px" }}
                              >
                                {row.employeeCategory}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>{" "}
                        <TableCell
                          padding="none"
                          style={{
                            textAlign: "left",
                            paddingLeft: "6px",
                            backgroundColor: isItemSelected ? "" : "",
                          }}
                          onClick={() => {
                            // console.log("hi table row clicked!");
                            const text = "/timesheetviewpage/:id";
                            const result = text.replace(":id", row.empId);
                            history.push({
                              pathname: result,
                              state: {
                                empId: row.empId,
                                managerId: tsDelEmpIdRedux
                                  ? tsDelEmpIdRedux
                                  : ssoEmpIdDetails?.empId,
                                delegateeId:
                                  ssoEmpIdDetails?.empId == tsDelEmpIdRedux
                                    ? null
                                    : ssoEmpIdDetails?.empId,
                                fromDate: moment(row.fromDate).format(
                                  "yyyy-MM-DD"
                                ),
                                toDate: moment(row.toDate).format("yyyy-MM-DD"),
                                projId: "",
                                tab: "myTeamApproval",
                                compName: "DetailedView",
                              },
                            });
                          }}
                        >
                          <Grid
                            container
                            direction="column"
                            spacing={1}
                            style={{ width: "130%" }}
                          >
                            <Grid item style={{ paddingBottom: "0px" }}>
                              <Typography
                                variant="body1"
                                style={{ fontSize: "14px" }}
                              >
                                {row.projName}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "2px",
                              }}
                            >
                              <Typography
                                variant="body1"
                                style={{ fontSize: "12px" }}
                              >
                                {row.projectId}
                              </Typography>
                            </Grid>
                            {row.projPurpose != "" ? (
                              <Grid
                                item
                                style={{
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  style={{
                                    backgroundColor: "#E3E8F7",
                                    width: "134px",
                                    //padding: "2px",
                                    height: "14px",
                                    fontSize: "10px",
                                    //paddingLeft: "22px",
                                    padding: " 1px 2px 2px 4px",
                                    marginBottom: "8px",
                                  }}
                                >
                                  {row.projPurpose}
                                </Typography>
                              </Grid>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </TableCell>
                        <TableCell
                          padding="none"
                          style={{
                            textAlign: "left",
                            backgroundColor: isItemSelected ? "" : "",
                          }}
                          onClick={() => {
                            // console.log("hi table row clicked!");
                            const text = "/timesheetviewpage/:id";
                            const result = text.replace(":id", row.empId);
                            history.push({
                              pathname: result,
                              state: {
                                empId: row.empId,
                                managerId: tsDelEmpIdRedux
                                  ? tsDelEmpIdRedux
                                  : ssoEmpIdDetails?.empId,
                                delegateeId:
                                  ssoEmpIdDetails?.empId == tsDelEmpIdRedux
                                    ? null
                                    : ssoEmpIdDetails?.empId,
                                fromDate: moment(row.fromDate).format(
                                  "yyyy-MM-DD"
                                ),
                                toDate: moment(row.toDate).format("yyyy-MM-DD"),
                                projId: "",
                                tab: "myTeamApproval",
                                compName: "DetailedView",
                              },
                            });
                          }}
                        >
                          <Grid
                            container
                            direction="column"
                            spacing={1}
                            style={{ width: "100%" }}
                          >
                            <Grid item>
                              <Typography
                                variant="body1"
                                style={{
                                  fontSize: "14px",
                                  textAlign: "center",
                                  // paddingRight: "20px",
                                }}
                              >
                                {row.dayHours}
                                {/* {row.dayHours.toFixed(2)} */}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="body1"
                                style={{
                                  backgroundColor: "#E3E8F7",
                                  width: "85px",
                                  //padding: "2px",
                                  height: "19px",
                                  marginLeft: "28px",
                                  //paddingLeft: "22px",
                                  padding:
                                    row.categoryName == "Billable"
                                      ? "1px 2px 2px 24px"
                                      : "1px 2px 2px 13px",
                                  //padding: "1px 2px 2px 13px",
                                  height: " 15px",
                                  fontSize: " 10px",
                                }}
                              >
                                {row.categoryName}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>{" "}
                        <TableCell
                          style={{
                            paddingLeft: "17px",
                            backgroundColor: isItemSelected ? "" : "",
                          }}
                        >
                          <Box display="flex" flexDirection="row">
                            <Box style={{ width: "50%" }}>
                              {/* {row.employeeComments? */}

                              {row.employeeComments != "" ? (
                                <>
                                  <img
                                    src={EmpCommentIcon}
                                    onClick={() => handleRemarkOpen(row, index)}
                                    style={{
                                      height: "29px",
                                      width: "31px",
                                      // marginLeft: "20px",
                                      cursor: "pointer",
                                    }}
                                  />

                                  {tooltipIndex == index && (
                                    <Tooltip
                                      key={index}
                                      open={remarkOpen}
                                      title={remarkText}
                                      onClose={handleRemarkClose}
                                      leaveDelay={2000}
                                      arrow
                                      placement="top-start"
                                    >
                                      <div ref={remarkRef} />
                                    </Tooltip>
                                  )}
                                </>
                              ) : (
                                ""
                              )}

                              {/* : ""} */}
                            </Box>
                            <Box style={{ width: "30%", marginRight: "7px" }}>
                              {/* {row.systemComments} */}
                              <MessageOutlinedIcon
                                style={{
                                  color: "rgb(0 0 11 / 65%)",
                                  cursor:
                                    row.systemComments != ""
                                      ? "pointer"
                                      : "not-allowed",
                                }}
                                onClick={() => handlesysCommentOpen(row, index)}
                              />
                              {tooltipIndexSys == index && (
                                <Tooltip
                                  key={index}
                                  open={remarkOpenSys}
                                  title={remarkTextSys}
                                  onClose={handleRemarkClose}
                                  leaveDelay={2000}
                                  arrow
                                  placement="top-start"
                                >
                                  <div ref={remarkRef} />
                                </Tooltip>
                              )}
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell
                          style={{
                            paddingLeft: "6px",
                            display: "flex",
                            alignItems: "center",
                            minHeight: "75px",
                            backgroundColor: isItemSelected ? "" : "",
                          }}
                        >
                          <Box
                            className={classes.iconStyle}
                            style={{ color: "#349259", marginRight: "10px" }}
                          >
                            <CheckCircleOutlinedIcon
                              // onClick={() =>
                              //   handleApproveRejectByManager(row, "Approved", "")
                              // }
                              onClick={() => handleApprove(row, index)}
                            />
                          </Box>

                          <Box
                            className={classes.iconStyle}
                            style={{ color: "#e02020" }}
                          >
                            <CancelOutlinedIcon
                              // onClick={() =>
                              //   handleApproveRejectByManager(
                              //     row,
                              //     "Rejected",
                              //     "rejection comments"
                              //   )
                              // }
                              onClick={() =>
                                handleApproveRejectDialog(index, "Rejected")
                              }
                            />
                          </Box>
                        </TableCell>
                        {/* <TableCell
                          className={classes.iconStyle}
                          style={{ color: "#349259" }}
                        >
                          <CheckCircleOutlinedIcon
                            // onClick={() =>
                            //   handleApproveRejectByManager(row, "Approved", "")
                            // }
                            onClick={() =>
                              handleApproveRejectDialog(index, "Approved")
                            }
                          />
                        </TableCell> */}
                        {/* <TableCell
                          className={classes.iconStyle}
                          style={{ color: "#e02020" }}
                        >
                          <CancelOutlinedIcon
                            // onClick={() =>
                            //   handleApproveRejectByManager(
                            //     row,
                            //     "Rejected",
                            //     "rejection comments"
                            //   )
                            // }
                            onClick={() =>
                              handleApproveRejectDialog(index, "Rejected")
                            }
                          />
                        </TableCell> */}
                        {/* <TableCell /> */}
                        {/* <TableCell /> */}
                        {index == currentIndex ? (
                          <ApproveRejectByManager
                            loader={isSetLoader}
                            row={row}
                            handleApproveRejectByManager={
                              handleApproveRejectByManager
                            }
                            handleComment={handleComment}
                            //approveRejectStatus={approveRejectStatus}
                            firstAppRej={firstAppRej}
                            handleApproveRejectStatus={
                              handleApproveRejectStatus
                            }
                            // approveRejectComment={approveRejectComment}
                            showApproveRejectDialog={showApproveRejectDialog}
                            handleCloseApproveRejectDialog={
                              handleCloseApproveRejectDialog
                            }
                            // handleSnackbar={handleSnackbar}
                          />
                        ) : (
                          <></>
                        )}
                        {/* {console.log("show open", open)} */}
                      </TableRow>
                    ) : (
                      //   <Snackbar
                      //   // handleSnackbar={handleSnackbar}
                      //   // handlecloseSnackbar={handlecloseSnackbar}
                      //   // expenseStatus={props.approveRejectStatus}
                      //   open={open}
                      //   // anchorOrigin={("top", "right")}
                      //   // expenseCode={postCreateExpenseFormData?.expenseCode}
                      // />
                      <></>
                    );
                  })
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {myTeamApproval?.length > 0 &&
        myTeamApproval[0]?.empId !== "" &&
        !isLoader ? (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="caption"
                style={{
                  paddingLeft: "8px",
                }}
              >
                <InfoOutlinedIcon />
              </Typography>

              <Typography
                variant="caption"
                style={{
                  paddingLeft: "8px",
                }}
              >
                <b>Click on any row to view details</b>
              </Typography>
            </Box>
            <TablePagination
              rowsPerPageOptions={[25, 50, 75]}
              component="div"
              count={
                applyFilterClicked && noDataInFilterFlag == 1
                  ? teamTimesheet?.length - 1
                  : applyFilterClicked &&
                    noDataInFilterFlag == 0 &&
                    teamTimesheet
                  ? teamTimesheet[0]?.totalRecords
                  : myTeamApproval?.length == 1 && noTeamApprovalData == 1
                  ? myTeamApproval?.length - 1
                  : myTeamApproval
                  ? myTeamApproval[0]?.totalRecords
                  : 0
                // myTeamApproval && myTeamApproval[0]?.totalRecords
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Box>
        ) : (
          <></>
        )}
      </Paper>
      {myTeamApproval?.length === 1 && myTeamApproval[0]?.empId === "" ? (
        <Grid>
          <Typography
            style={{
              fontSize: "20px",
              lineHeight: "320px",
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            No Timesheet entry exists!
          </Typography>
        </Grid>
      ) : (
        <></>
      )}
      <div>
        <Backdrop
          sx={{ color: "#092FED", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </div>
  );
}