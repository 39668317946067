import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Grid from "@material-ui/core/Grid";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { SSOTokenProvider, SsoCredentials } from "aws-sdk";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getDailyPreviousTimesheetList,
  getWeeklyPreviousTimesheetList,
} from "../redux/TimeSheet/actionTs";
//import { reloadModuleToggleData } from "../redux/Expense/action";
import moment from "moment";
import { Chip } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import OutsideClickHandler from "react-outside-click-handler";
import { useHistory } from "react-router-dom";
import { LegendToggleOutlined } from "@mui/icons-material";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { useTheme } from "@material-ui/core/styles";
function createData(timePeriod, billedHours, nonBilledHours, pendingApprovals) {
  return {
    timePeriod,
    billedHours,
    nonBilledHours,
    pendingApprovals,
    history: [
      {
        date: "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
  };
}

const rows = [
  createData("Cupcake", 305, 3.7, 67),
  createData("Donut", 452, 25.0, 51),
  createData("Eclair", 262, 16.0, 24),
  createData("Frozen yoghurt", 159, 6.0, 24),
  createData("Gingerbread", 356, 16.0, 49),
  createData("Honeycomb", 408, 3.2, 87),
  createData("Ice cream sandwich", 237, 9.0, 37),
  createData("Jelly Bean", 375, 0.0, 94),
  createData("KitKat", 518, 26.0, 65),
  createData("Lollipop", 392, 0.2, 98),
  createData("Marshmallow", 318, 0, 81),
  createData("Nougat", 360, 19.0, 9),
  createData("Oreo", 437, 18.0, 63),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { width: "46px" },
  {
    id: "timePeriod",
    numeric: false,
    disablePadding: true,
    label: "Time Period",
    width: "260px",
  },
  {
    id: "billedHours",
    numeric: true,
    disablePadding: true,
    label: "Billed Hours",
    width: "260px",
  },
  {
    id: "nonBilledHours",
    numeric: true,
    disablePadding: true,
    label: "Non Billed Hours",
    width: "260px",
  },
  {
    id: "pendingApprovals",
    numeric: true,
    disablePadding: true,
    label: "Pending Approvals",
    width: "260px",
  },
];
function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
    //console.log("event1",event)
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  return (
    <div className={classes.rootFooter}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
function MyTimesheetTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ backgroundColor: "#E3E8F7" }}>
      <TableRow style={{ height: "60px" }}>
        {/* <TableCell
          component="th"
          scope="row"
          // component="td"
          padding="none"
          // scope="col-1"
          style={{
            width: "0px",
          }}
        /> */}
        {headCells.map((headCell) => (
          <TableCell
            component="th"
            scope="row"
            style={{ fontWeight: 600, padding: "0px", width: headCell.width }}
            key={headCell.id}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

MyTimesheetTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const MyTimesheetTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

MyTimesheetTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    boxShadow: "none",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  statusStyle: {
    borderRadius: "100px",
    width: " 126px",
    height: "24px",
    flexShrink: 0,
  },
  statusStyleContent: {
    textAlign: "center",
    fontFamily: " Montserrat",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: " 0.5px",
    textTransform: "uppercase",
  },
  tooltip: {
    paddingLeft: "2px",
    color: "#9E9E9E",
    maxWidth: "20px",
    maxHeight: "20px",
  },

  tooltipBox: {
    minWidth: "225px",
    left: "auto!important",
    right: 0,
    marginTop: "-11px",
    // top:'-20px!important'
  },
  arrow: {
    left: "auto!important",

    right: 0,
  },
  statusName: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "8px",
    marginRight: "0px",
    // alignItems: "center",
    // paddingLeft: "10px",
    color: "rgba(255, 255, 255, 0.85)",
  },
  tableRow: {
    height: "50px",
  },
  rootFooter: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
  },
}));

// function myTimesheetTableRow() {}

export default function MyTimesheetTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(parseInt(15, 10));
  const [toolAction, setToolAction] = React.useState("");
  const target = useRef(null);
  const [commentData, setCommentData] = React.useState([]);
  const [commentHeading, setCommentHeading] = React.useState("");
  const [currentIndex, setCurrentIndex] = React.useState();
  const [applyFilterClicked, setApplyFilterClicked] = React.useState(false);
  const history = useHistory();
  const [selectedItems, setSelectedItems] = React.useState([]);

  const [open, setOpen] = React.useState(false);
  const [isLoader, isSetLoader] = useState(true);

  const dispatch = useDispatch();

  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the default context menu behavior
  };

  const handleKeyDown = (e) => {
    if ((e.ctrlKey && e.shiftKey && e.key === "I") || e.key === "F12") {
      e.preventDefault(); // Prevent opening developer tools
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );

  const tsDelEmpIdRedux = useSelector(
    (state) => state?.timesheetData?.tsDelEmpId
  ); 

  const weeklyTimesheetList = useSelector(
    (state) => state?.timesheetData?.getWeeklyPreviousTimesheetList
  );

  const dailyPreviousTimesheetList = useSelector(
    (state) => state?.timesheetData?.getDailyPreviousTimesheetList?.undefined
  );

  useEffect(() => {
    if (dailyPreviousTimesheetList && weeklyTimesheetList) {
      isSetLoader(false);
      setPage(0);
    } else {
      isSetLoader(true);
    }
  }, [dailyPreviousTimesheetList, weeklyTimesheetList])
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.timePeriod);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  useEffect(() => {
    if (
      ssoEmpIdDetails &&
      props.filterData.timePeriodChip.length === 0 &&
      props.filterData.projectIdChip.length === 0 &&
      props.filterData.statusChip.length === 0
    ) {
      let daily = {
        empId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
        tmId: null,
        projId: [],
        status: [],
      };

      let weekly = {
        empId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
        date: moment(new Date()).format("yyyy-MM-DD"),
        tmId: null,
        projId: [],
        status: [],
      };

      dispatch(getDailyPreviousTimesheetList(daily));
      dispatch(getWeeklyPreviousTimesheetList(weekly));
    }
  }, [ssoEmpIdDetails, tsDelEmpIdRedux, props.filterData]);


  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row.timePeriod);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.timePeriod);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleEditClick = (row) => {
    // console.log("hi edit Box clicked!");

    // const emp = props.ssoEmpId;
    // console.log("checking empid", ssoEmpIdDetails.empId);

    history.push({
      pathname: "/create-Timesheet-Management",
      state: {
        fromDate: moment(row.fromDate).format("yyyy-MM-DD"),
        toDate: moment(row.toDate).format("yyyy-MM-DD"),
        // empId: row.empId,
        // response: row,
        empId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
        edit: 1,
      },
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    //console.log("value of rows", event.target.value);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  const handleTooltipClose = () => {
    // console.log("close");
    setOpen(false);
  };
  const handleToolTip = (comment) => {
    const arr = comment.split("|");
    return (
      <>
        {arr?.map((value) => {
          return (
            <>
              <Typography className={classes.statusName}>
                {value?.replace(/\\n/g, "\n")}
              </Typography>
            </>
          );
        })}
      </>
    );
  };
  const handlToolAction = (event) => {
    // console.log(event);
    // console.log("action");
    setToolAction(event.currentTarget);
  };
  const handleTooltipOpen = (comments, index) => {
    // console.log("comment", comments);

    setOpen(true);
    setCommentData(comments);
    // setCommentHeading(nextAction);
    setCurrentIndex(index);
  };
  const handleParentToolTip = () => {
    // console.log(":parent tool tip function");
    return (
      <>
        <OutsideClickHandler
          onOutsideClick={() => {
            handleTooltipClose();
          }}
        >
          <div style={{ position: "relative" }}>
            <Tooltip
              PopperProps={{
                disablePortal: true,
                anchorEl: { toolAction },
              }}
              arrow
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={handleToolTip(commentData)}
              classes={{
                popper: classes.tooltipBox,
                arrow: classes.arrow,
              }}
            >
              <span
                style={{ maxwidth: "20px", minWidth: "0px", height: "0" }}
              ></span>
            </Tooltip>
          </div>
        </OutsideClickHandler>
      </>
    );
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleCheckboxChange = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((i) => i !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
    //seValFun();
  };

  // const seValFun = () => {
  //   props.onClickSetVal(selectedItems);
  // }

  useEffect(() => {
    const convertedArray = selectedItems?.map((value) => ({ tmid: value }));
    props.onClickSetVal(convertedArray);
  }, [selectedItems]);

  // useEffect(() => {
  //   setToggleReload(false);
  //   dispatch(reloadModuleToggleData(0));
  // }, []);
  /* useEffect(() => {

    
    const handleApproveRejectStatus = (event) => {
      // If the custom event is received, refresh the page
      if (event.detail.changes) {
        window.location.reload();
      }
    };

    window.addEventListener('changesMadeEvent', handleApproveRejectStatus);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('changesMadeEvent', handleApproveRejectStatus);
    };
  }, []);


  }, []);*/

  return (
    <div className={classes.root} onContextMenu={handleContextMenu}>
      <Paper className={classes.paper}>
        {/* <MyTimesheetTableToolbar numSelected={selected.length} /> */}
        <TableContainer style={{ overflowX: "clip" }}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <MyTimesheetTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {weeklyTimesheetList?.length > 0 ? (
                stableSort(weeklyTimesheetList, getComparator(order, orderBy))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    // console.log("row1", row);
                    const isItemSelected = isSelected(row.timePeriod);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <React.Fragment>
                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row)}
                          //   role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.timePeriod}
                          selected={isItemSelected}
                          style={{
                            backgroundColor:
                              row.status == "Rejected" ? "#FFE7E7" : "#ffffff",
                            padding: "10px",
                          }}
                        >
                          <TableCell
                            style={{
                              paddingRight: "0px",
                              borderBottomWidth: "0px",
                            }}
                            onClick={(event) => handleClick(event, row)}
                          >
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={(event) => handleClick(event, row)}
                            >
                              {isItemSelected ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell
                            style={{
                              fontWeight: 500,
                              borderBottomWidth: "0px",
                            }}
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            onClick={(event) => handleClick(event, row)}
                          >
                            {row.timePeriod}
                          </TableCell>
                          <TableCell
                            padding="none"
                            style={{
                              fontWeight: 600,
                              paddingLeft: "52px",
                              borderBottomWidth: "0px",
                            }}
                            onClick={(event) => handleClick(event, row)}
                          >
                            {row.billable.toFixed(2)}
                          </TableCell>
                          <TableCell
                            padding="none"
                            style={{
                              fontWeight: 600,
                              paddingLeft: "85px",
                              borderBottomWidth: "0px",
                            }}
                            onClick={(event) => handleClick(event, row)}
                          >
                            {row.nonBillable.toFixed(2)}
                          </TableCell>
                          <TableCell
                            padding="none"
                            style={{
                              fontWeight: 600,
                              display: "flex",
                              padding: "10px 0px 10px 93px",
                              alignItems: "center",
                              justifyContent: "space-between",
                              borderBottomWidth: "0px",
                            }}
                          >
                            <div onClick={(event) => handleClick(event, row)}>
                              {row.pendingDayHours.toFixed(2)}
                            </div>
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingRight: "30px",
                              }}
                            >
                              {row.editableFlag === 1 && (
                                <Box className={classes.box} id="1">
                                  <Box
                                    style={{
                                      display: "flex",
                                      marginBottom: "-6px",
                                    }}
                                    onClick={() => handleEditClick(row)}
                                  >
                                    <div>
                                      <EditOutlinedIcon />
                                    </div>
                                  </Box>
                                </Box>
                              )}
                              <Box
                                style={{
                                  marginLeft:
                                    row.editableFlag != 1 ? "33px" : "10px",
                                }}
                              >
                                <Checkbox
                                  checked={selectedItems.includes(
                                    row.timesheetId
                                  )}
                                  style={{
                                    color:
                                      row.approvalFlag === 0
                                        ? "#BDBDBD"
                                        : "#0B1941",
                                    pointerEvents:
                                      row.approvalFlag === 0 ? "none" : "auto",
                                  }}
                                  onChange={() =>
                                    handleCheckboxChange(row.timesheetId)
                                  }
                                  disabled={row.approvalFlag === 0}
                                />
                              </Box>
                            </Box>
                          </TableCell>
                          {/* <TableCell> */}
                          {/* <Box
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {row.editableFlag === 1 && (
                                <Box className={classes.box} id="1">
                                  <Box
                                    style={{
                                      display: "flex",
                                      marginBottom: "-6px",
                                    }}
                                    onClick={() => handleEditClick(row)}
                                  >
                                    <div>
                                      <EditOutlinedIcon />
                                    </div>
                                  </Box>
                                </Box>
                              )}
                              <Box
                                style={{
                                  marginLeft:
                                    row.editableFlag != 1 ? "33px" : "10px",
                                }}
                              >
                                <Checkbox
                                  checked={selectedItems.includes(
                                    row.timesheetId
                                  )}
                                  style={{
                                    color:
                                      row.approvalFlag === 0
                                        ? "#BDBDBD"
                                        : "#0B1941",
                                    pointerEvents:
                                      row.approvalFlag === 0 ? "none" : "auto",
                                  }}
                                  onChange={() =>
                                    handleCheckboxChange(row.timesheetId)
                                  }
                                  disabled={row.approvalFlag === 0}
                                />
                              </Box>
                            </Box> */}
                          {/* </TableCell> */}
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ padding: 0 }} colSpan={6}>
                            <Collapse
                              in={isItemSelected}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box>
                                <Table size="small" aria-label="purchases">
                                  <TableHead
                                    style={{
                                      height: "51px",
                                      backgroundColor: "#E3E8F7",
                                    }}
                                  >
                                    <TableRow>
                                      {/* <TableCell
                                        style={{
                                          width: "76px",
                                          padding: "0px",
                                        }}
                                      ></TableCell> */}
                                      <TableCell
                                        style={{
                                          padding: "0px",
                                          // width: "245px",

                                          width: "27%",
                                          fontWeight: 600,
                                          paddingLeft: "60px",
                                        }}
                                      >
                                        Dates
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          padding: "0px",
                                          // width: "348px",
                                          width: "28%",
                                          fontWeight: 600,
                                        }}
                                      >
                                        Project ID & Name
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          padding: "0px",
                                          fontWeight: 600,
                                          width: "211px",
                                          paddingLeft: "25px",
                                        }}
                                      >
                                        Hours
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          padding: "0px",
                                          fontWeight: 600,
                                          paddingLeft: "11%",
                                        }}
                                      >
                                        Status
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {dailyPreviousTimesheetList ? (
                                      stableSort(
                                        dailyPreviousTimesheetList.filter(
                                          (item) =>
                                            // item.timesheetId ===
                                            //   row.timesheetId ||
                                            (moment(
                                              item?.date,
                                              "MMM DD, YY"
                                            ).format("YYYY-MM-DD") >=
                                              row?.fromDate?.slice(0, -9) &&
                                              moment(
                                                item?.date,
                                                "MMM DD, YY"
                                              ).format("YYYY-MM-DD") <=
                                                row?.toDate?.slice(0, -9))
                                        ),
                                        getComparator(order, orderBy)
                                      )?.map((dailyRow, index) => (
                                        <TableRow
                                          key={dailyRow.date}
                                          className={classes.tableRow}
                                        >
                                          {/* <TableCell padding="none"></TableCell> */}
                                          <TableCell
                                            padding="none"
                                            style={{
                                              width: "27%",
                                              paddingLeft: "60px",
                                            }}
                                          >
                                            <Grid
                                              container
                                              direction="column"
                                              spacing={1}
                                            >
                                              <Grid
                                                item
                                                style={{
                                                  paddingBottom: "0px",
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <Typography
                                                  variant="body1"
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {dailyRow.date}
                                                </Typography>{" "}
                                                {/* <Typography
                                                  variant="body1"
                                                  style={{ fontSize: "12px" }}
                                                >
                                                  { dailyRow.type }
                                                </Typography> */}
                                              </Grid>
                                              <Grid
                                                item
                                                style={{ paddingTop: "0px" }}
                                              >
                                                <Typography
                                                  variant="body1"
                                                  style={{ fontSize: "12px" }}
                                                >
                                                  {dailyRow.type}
                                                </Typography>
                                              </Grid>
                                            </Grid>

                                            {/* {dailyRow.date} */}
                                          </TableCell>
                                          <TableCell
                                            padding="none"
                                            style={{
                                              textAlign: "left",
                                              width: "28%",
                                            }}
                                          >
                                            <Grid
                                              container
                                              direction="column"
                                              spacing={1}
                                            >
                                              <Grid
                                                item
                                                style={{ paddingBottom: "0px" }}
                                              >
                                                <Typography
                                                  variant="body1"
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {dailyRow.projectName}
                                                </Typography>
                                              </Grid>
                                              <Grid
                                                item
                                                style={{
                                                  paddingTop: "0px",
                                                  paddingBottom: "2px",
                                                }}
                                              >
                                                <Typography
                                                  variant="body1"
                                                  style={{ fontSize: "12px" }}
                                                >
                                                  {dailyRow.projectID}
                                                </Typography>
                                              </Grid>

                                              {dailyRow?.projPurpose != "" ? (
                                                <Grid
                                                  item
                                                  style={{
                                                    paddingTop: "0px",
                                                    paddingBottom: "0px",
                                                  }}
                                                >
                                                  <Typography
                                                    variant="body1"
                                                    style={{
                                                      backgroundColor:
                                                        "#E3E8F7",
                                                      width: "134px",
                                                      //padding: "2px",
                                                      height: "14px",
                                                      fontSize: "10px",
                                                      //paddingLeft: "22px",
                                                      padding:
                                                        " 1px 2px 2px 4px",
                                                      marginBottom: "8px",
                                                    }}
                                                  >
                                                    {dailyRow.projPurpose}
                                                  </Typography>
                                                </Grid>
                                              ) : (
                                                ""
                                              )}
                                            </Grid>
                                          </TableCell>
                                          <TableCell padding="none">
                                            {dailyRow?.category != "" && (
                                              <>
                                                <div
                                                  style={{
                                                    fontWeight: 600,
                                                    paddingLeft: "29px",
                                                  }}
                                                >
                                                  {dailyRow?.dayHours?.toFixed(
                                                    2
                                                  )}
                                                </div>
                                                <div
                                                  style={{
                                                    backgroundColor: "#E3E8F7",
                                                    width: "93px",
                                                    //padding: "2px",
                                                    height: "19px",
                                                    fontSize: "10px",
                                                    //paddingLeft: "22px",
                                                    padding:
                                                      " 1px 2px 2px 24px",
                                                    height: " 15px",
                                                    fontSize: " 10px",
                                                  }}
                                                >
                                                  {dailyRow?.category}
                                                </div>
                                              </>
                                            )}
                                          </TableCell>
                                          <TableCell
                                            padding="none"
                                            style={{ paddingLeft: "8%" }}
                                          >
                                            <Box
                                              style={{
                                                display: "flex",
                                                marginTop: "4px",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  marginRight: "27px",
                                                }}
                                              >
                                                <Chip
                                                  label={dailyRow.status}
                                                  className={
                                                    classes.statusStyle
                                                  }
                                                  style={{
                                                    background:
                                                      dailyRow.status ===
                                                      "In Review"
                                                        ? "#E0B15C"
                                                        : dailyRow.status ===
                                                          "Approved"
                                                        ? " #349259"
                                                        : dailyRow.status ===
                                                          "Auto Approved"
                                                        ? " #349259"
                                                        : dailyRow.status ===
                                                          "Rejected"
                                                        ? "#D40606"
                                                        : dailyRow.status ===
                                                          "Draft"
                                                        ? "#808080"
                                                        : "",
                                                    display:
                                                      dailyRow.status ===
                                                        "In Review" ||
                                                      dailyRow.status ===
                                                        "Approved" ||
                                                      dailyRow.status ===
                                                        "Auto Approved" ||
                                                      dailyRow.status ===
                                                        "Rejected" ||
                                                      dailyRow.status ===
                                                        "Draft"
                                                        ? "inline-flex"
                                                        : "none",
                                                    color: "#ffffff",
                                                  }}
                                                />
                                                {(dailyRow.status ==
                                                  "In Review" ||
                                                  dailyRow.status ==
                                                    "Rejected" ||
                                                  dailyRow.status ==
                                                    "Approved") && (
                                                  <>
                                                    <div
                                                      style={{
                                                        marginTop: "2px",
                                                      }}
                                                    >
                                                      {/* <InfoOutlinedIcon /> */}

                                                      <InfoOutlinedIcon
                                                        ref={target}
                                                        onClick={(event) => {
                                                          handleTooltipOpen(
                                                            dailyRow.comments,
                                                            index
                                                          );
                                                          handlToolAction(
                                                            event
                                                          );
                                                        }}
                                                        className={
                                                          classes.tooltip
                                                        }
                                                      />

                                                      {open &&
                                                        index ===
                                                          currentIndex &&
                                                        handleParentToolTip()}
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            </Box>
                                          </TableCell>
                                        </TableRow>
                                      ))
                                    ) : (
                                      <></>
                                    )}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
          <Grid container spacing={1} style={{ height: "50px" }}>
            &nbsp;
          </Grid>
        </TableContainer>
        {weeklyTimesheetList?.length === 0 ? 
        <Grid>
          <Typography
             style={{
              fontSize:"20px",
              lineHeight:"200px",
              textAlign:"center",
              fontWeight: "600",
              }}>
            No Timesheet entry exists!
          </Typography>
        </Grid> : <></>
        }
        <TablePagination
          rowsPerPageOptions={[15, 30, 45]}
          component="div"
          count={weeklyTimesheetList?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
        
      </Paper>
      <div>
        <Backdrop
          sx={{ color: "#092FED", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </div>
  );
}
