import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import CloseIcon from "@material-ui/icons/Close";
import "../css/gmDragDrop.css";
import { Box, Button, Typography, Grid, Chip } from "@material-ui/core";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { Edit, NoEncryption } from "@material-ui/icons";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Paper from "@material-ui/core/Paper";
const useStyles = makeStyles((theme) => ({
  btnBox: {
    padding: "0px",
  },
  btnBoxMobile: {
    //margin:"0px",
    padding: "0px,16px",
    width: "270px",
    height: "42px",
    borderRadius: "4px",
    backgroundColor: "#0B1941",
    justifyContent: "center",
  },
  btnBoxMobile1: {
    //margin:"0px",
  //   padding: "0px,16px",
  //   width: "270px",
  //   height: "42px",
  //   borderRadius: "4px",
  //   backgroundColor: "#0B1941",
  //   justifyContent: "center",
  },
  rootMobile: {
    display: "flex",

    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  },
  rootMobile1: {
    display: "flex",
    justifyContent: "center",
    margin: "0px",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(11),
      height: theme.spacing(10),
      // padding:theme.spacing(1)
    },
  },
  cameraBox: {
    padding: "10px",
  },

  paper: {
    // padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid #9E9E9E",
    borderRadius: "4px",
  },
  chip: {
    display: "flex",
    justifyContent: "space-between",
  },
  chip1: {
    display: "flex",
    justifyContent: "space-between",
    minWidth: "137px",
  },

  existingFile: {
    fontWeight: "600",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: "20px",
    lineHeight: "24.38px",
    letterSpacing: "0.3px",
    marginTop: "20px",
    marginLeft: " 12px",
    // textAlign: "left",
    color: "#252733",
  },
}));

const DropFileInput = (props) => {
  const classes = useStyles();
  const wrapperRef = useRef(null);

  const [fileList, setFileList] = useState([]);
  const [prePopulateList, setPrePopulatedList] = useState([]);
  const [deleteFileList, setDeleteFileList] = useState([]);

  useEffect(() => {
    if (props?.lastAttachments?.length > 0) {
      const dummyArray = [];
      props?.lastAttachments?.forEach((element) => {
        dummyArray.push(element);
      });

      setPrePopulatedList(dummyArray);
    }
  }, [props?.lastAttachments]);

  useEffect(() => {
    if(props.noFlag){
      setPrePopulatedList([]);
      setFileList([]);
      props.setNoFlag(false);
    }
  }, [props.noFlag])

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (e) => {
    const { files } = e.target;
    const newFile = [...files];
    if (newFile.length > 0) {
      const updatedList = [...fileList, ...newFile];
      setFileList(updatedList);
      props.handleFileList(updatedList);
    }
  };

  const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    props.handleFileList(updatedList);
  };

  const prefileRemove = (file) => {
    const updatedList = [...prePopulateList];
    updatedList.splice(prePopulateList.indexOf(file), 1);
    setPrePopulatedList(updatedList);
    props.setPrevAttachment(updatedList);
    const updatedDeleteFiles = [...deleteFileList, file];
    setDeleteFileList(updatedDeleteFiles);
    props.setDeletedAttachment(updatedDeleteFiles);
    // props.handleDeleteFileList(updatedDeleteFiles);
  };
  //console.log("deleted file", deleteFileList);
  const handleClick = () => {
    // console.info("You clicked the Chip.");
  };
  const matches = useMediaQuery("(max-width:600px)");

  return (
    <>
      <div>
        
        <Paper
          style={{
            boxShadow: "none",
            // justifyContent: "center",
            marginTop:"15px",
            backgroundColor: props.sowAvail === "yes" ? "#FFFFFF" : "#EEEEEE",
            // marginRight: matches ? "20px" : "",
          }}
          aria-disabled
          className={classes.paper}
        >
          <Box
            ref={wrapperRef}
            className="gmdrop-file-input"
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
          >

              {/* {matches ? (
                // <Grid container spacing={1}>
                // <Grid item xs={11} sm={11} md={11} >
                <Box>
                  <div className={classes.rootMobile1}>
                    <input
                      accept=".pdf"
                      capture="environment"
                      multiple
                    ></input>
                    <Paper
                      variant="outlined"
                      style={{
                        border: "1px solid #9E9E9E",
                        width: "240px",
                        justifyItems: "center",
                      }}
                    >
                      <CameraAltIcon style={{ fontSize: 40 }} color="red" />
                      <Typography
                        style={{ fontSize: "10px", color: "#9E9E9E" }}
                      >
                        Take Photo / Upload the Reciepts/Invoice
                      </Typography>
                    </Paper>
                  </div>
                </Box>
              ) : (
                ""
              )} */}

              <Box className={matches ? "" : classes.btnBox} style={{width:"30%", textAlign: "left", marginLeft: "2%"}}>
                {/* {matches ? (
                  <Box className={classes.btnBoxMobile1}>
                  <Button
                    variant="contained"
                    component="span"
                    style={{
                      width:"270px",
                      fontSize: "14px",
                      fontWeight: "700px",
                      fontfamily: "Montserrat",
                      color: "white",
                      textAlign: "center",
                      paddingTop: "10px",
                      backgroundColor: "#0B1941",
                      textTransform: 'none',
                    }}
                  >
                    Upload SoW
                  </Button>
                  </Box>

                ) : ( */}

                  <Button
                    variant="contained"
                    component="span"
                    style={{
                      backgroundColor: props.sowAvail === "yes" ? "#043365" : "grey",
                      color: "rgba(255, 255, 255, 0.9)",
                      textTransform: 'none',
                    }}
                    
                  >
                    Upload SOW
                  </Button>
                {/* // )} */} 

              </Box>
              <Box style={{width:"70%", textAlignLast: "start"}}>
              <Typography style={{ fontSize: matches ? "10px" : "12px", }} className="gmdrop-file-input-label-validationText">
                  <strong style={{color: props.sowAvail === "yes" ? "" : "grey"}}>
                    Only PDF allowed with maximum size of less than 5 MB.
                  </strong>
                </Typography>
              </Box>
            <input
              type="file"
              id="drag-drop-files"
              value=""
              style={{
                display: props.sowAvail === "yes" ? "" : "none",
              }}
              onChange={(e) => onFileDrop(e)}
              multiple
            />
          </Box>
        </Paper>
      </div>

      <div className="gmdrop-file-preview-container">
        <Box>
          <Grid container spacing={1}>
            {fileList.length > 0
              ? fileList.map((item, index) => (
                <Grid item xs={matches ? 4 : 1} sm={3}>
                  <Chip
                    key={index}
                    //   icon={<ImageConfig />}
                    label={item.name}
                    className={classes.chip}
                    clickable
                    onDelete={() => {
                      fileRemove(item);
                    }}
                    onClick={(e) => handleClick()}
                    deleteIcon={<CloseIcon />}
                  />
                </Grid>
              ))
              : null}
            {prePopulateList && !props.noFlag &&
              prePopulateList?.map((item, index) => (
                <Grid item xs={matches ? 5 : 1} sm={2}>
                  <Chip
                    key={index}
                    //   icon={<ImageConfig />}
                    label={item.fileName}
                    className={matches ? classes.chip1 : classes.chip}
                    clickable
                    onDelete={() => {
                      prefileRemove(item);
                    }}
                    onClick={(e) => handleClick()}
                    deleteIcon={<CloseIcon />}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
      </div>
    </>
  );
};

DropFileInput.propTypes = {
  onFileChange: PropTypes.func,
};

export default DropFileInput;