import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Box,
  makeStyles,
  Grid,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  TextField,
  Select,
  Button,
  Tabs,
  Tab,
} from "@material-ui/core";
import { Carousel } from "antd";
import ApprovedIcon from "../images/Approved_Icon.svg";
import InProgressIcon from "../images/Inprogress_icon.svg";
import RejectedIcon from "../images/Rejected_icon.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: "2rem",
    minWidth: "7.5rem",
    "& .MuiFormLabel-root": {
      top: "--0.25rem",
      color: "#515961",
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "0.125 solid #439DD9",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#515961",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0B1941",
      },
    },
  },
  expenseCard: {
    height: "5.875rem",
    background: "#ECFFFB",
    border: "0.125rem solid #FFFFFF",
    borderRadius: "0.5rem",
    maxWidth: "100%",
  },
  approveExpenseCard: {
    height: "5.875rem",
    background: "#FFF2DF",
    border: "0.125rem solid #FFFFFF",
    borderRadius: "0.5rem",
    maxWidth: "100%",
  },
  rejectedExpenseCard: {
    height: "5.875rem",
    background: "#FFE9ED",
    border: "0.125rem solid #FFFFFF",
    borderRadius: "0.5rem",
    maxWidth: "100%",
  },
  approveText: {
    color: "#4C4C4C",
    alignTtems: "center",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "74%",
  },
  approveCount: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "0.625rem",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "100%",
  },
  approveIcon: {
    marginLeft: "1.5rem",
  },
  pendingText: {
    marginLeft: "1.5rem",
  },
  rejectIcon: {
    marginLeft: "1.5rem",
  },
  boxClass: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "nowrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
    overflow: "auto",
    background: "#EDF2FF",
    minWidth: "98%",
  },
  respMaxSize: {
    padding: "22px",
  },
  respMinSize: {
    padding: "5px",
  },
}));

export default function MobileViewReportListBox({
  myExpenseReportList,
  handleDropeDown,
  ndayValue,
}) {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:470px)");

  const delEmpIDRedux = useSelector(
    (state) => state.createExpenseData.delEmpId
  );

  useEffect(() => {
    handleDropeDown(ndayValue, delEmpIDRedux);
  }, [ndayValue, delEmpIDRedux]);

  return (
    <>
      <Box className={classes.boxClass}>
        <Grid
          container
          style={{ maxWidth: "100%", margin: "0rem" }}
          spacing={4}
        >
          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            style={matches ? { padding: "5px" } : { padding: "23px" }}
          >
            {myExpenseReportList?.map((value) => (
              <Grid
                item
                xs={4}
                sm={4}
                md={12}
                className={classes.expenseCard}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Box /*style={{paddingBottom:"12px"}}*/>
                  <Typography
                    variant="p"
                    className={classes.approveIcon}
                    style={{ marginLeft: "0rem" }}
                  >
                    <img
                      src={ApprovedIcon}
                      style={{
                        /*marginLeft: '-0.3rem', marginTop: '0.188rem', */ height:
                          "2.7rem",
                        width: "2.7rem",
                      }}
                    />
                  </Typography>
                  <Box
                    style={{
                      display: "inline-block",
                      paddingBottom: "12px",
                      //marginTop: "-1.125rem",
                      //marginLeft: "2.125rem",
                    }}
                  >
                    <Typography
                      variant="p"
                      className={classes.approveCount}
                      style={{ fontSize: "20px" }}
                    >
                      {value.approvedExpense}
                    </Typography>
                    <Typography variant="p" className={classes.approveText}>
                      {"Approved"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            style={matches ? { padding: "5px" } : { padding: "23px" }}
          >
            {myExpenseReportList?.map((value) => (
              <Grid
                item
                xs={4}
                sm={4}
                md={12}
                className={classes.approveExpenseCard}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Box /*style={{display:"flex",alignItems:"center"}}*/>
                  <Typography
                    variant="p"
                    className={classes.pendingText}
                    style={{ marginLeft: "0rem" }}
                  >
                    <img
                      src={InProgressIcon}
                      style={{
                        /*marginLeft: '-0.3rem', marginTop: '0.188rem', */
                         height: "2.5rem",
                        width: "2.5rem",
                      }}
                    />
                  </Typography>
                  <Box
                    style={{
                      display: "inline-block",
                      marginLeft: "-6px",
                      paddingBottom: "12px",
                      //marginTop: "-1.125rem",
                      //marginLeft: "2.125rem",
                    }}
                  >
                    <Typography
                      variant="p"
                      className={classes.approveCount}
                      style={{ fontSize: "20px" }}
                    >
                      {value.pendingExpenses}
                     
                    </Typography>
                    <Typography variant="p" className={classes.approveText}>
                      {"In Progress"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            style={matches ? { padding: "5px" } : { padding: "26px" }}
          >
            {myExpenseReportList?.map((value) => (
              <Grid
                item
                xs={4}
                sm={4}
                md={12}
                className={classes.rejectedExpenseCard}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Box>
                  <Typography
                    variant="p"
                    className={classes.rejectIcon}
                    style={{ marginLeft: "0rem" }}
                  >
                    <img
                      src={RejectedIcon}
                      style={{
                        /*marginLeft: '-0.3rem', marginTop: '0.188rem',*/ height:
                          "2.5rem",
                        width: "2.5rem",
                      }}
                    />
                  </Typography>
                  <Box
                    style={{
                      display: "inline-block",
                      paddingBottom: "12px",
                      //marginTop: "-1.125rem",
                      //marginLeft: "2.125rem",
                    }}
                  >
                    <Typography
                      variant="p"
                      className={classes.approveCount}
                      style={{ fontSize: "20px" }}
                    >
                      {value.rejectedExpenses}
                      
                    </Typography>
                    <Typography variant="p" className={classes.approveText}>
                      {"Rejected"}
                    </Typography>
                  </Box>
                </Box>{" "}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
