import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  makeStyles,
  Grid,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  TextField,
  Select,
  Button,
  Tabs,
  Tab,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import ApprovedIcon from "../images/Approved_Icon.svg";
import InProgressIcon from "../images/Inprogress_icon.svg";
import RejectedIcon from "../images/Rejected_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { reloadModuleToggleData } from "../redux/Expense/action";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: "32px",
    minWidth: "120px",

    //height: "24px",
    // notchedOutline: {
    //   borderColor: "#CB444A",
    //   borderRadius: 8,
    // },
    "& .MuiFormLabel-root": {
      top: "-4px",
      color: "#515961",
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "2px solid #439DD9",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#515961",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0B1941",
      },
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dropdownStyle: {
    "& ul": {
      padding: 0,
      border: "0.5px solid #515961",
      boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
      borderRadius: 5,
    },
    "& li": {
      height: 47,
      fontSize: 14,
      color: "#515961",
      "&:hover": {
        backgroundColor: "rgba(217, 235, 247, 0.4);",
        color: "#439DD9",
      },
    },
  },
  selectRoot: {
    borderRadius: 8,
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "#439DD9",
      },
    },
    "&:focus": {
      backgroundColor: "transparent",
      borderRadius: 8,
    },
  },

  addExpenseButton: {
    height: "42px",
    width: "180px",
    background: " #0B1941",
    boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    borderRadius: "5px",
    "&:hover": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
    "&:focus": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
  },
  addExpenseButtonMac: {
    height: "42px",
    width: "180px",
    background: " #0B1941",
    boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    borderRadius: "5px",
    "&:hover": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
    "&:focus": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
    "& .MuiButton-root": {
      marginLeft: "630px",
    },
  },
  buttonText: {
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 500,
  },
  formControlDrop: {
    marginTop: "32px",

    minWidth: "120px",

    "& .MuiFormLabel-root": {
      top: "-4px",

      color: "#515961",
    },

    "& .MuiSelect-select:focus": {
      backgroundColor: "#F8F9FF",
    },

    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "2px solid #439DD9",
      },

      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#515961",
      },

      "&.Mui-focused fieldset": {
        borderColor: "#0B1941",
      },
    },
  },
  buttonGrid: {
    display: "flex",
    justifyContent: "end",
    marginTop: "32px",
    paddingRight: "32px",
  },
  expenseCard: {
    height: "110px",
    background: "#FFFFFF",
    border: "1px solid #979797",
    borderRadius: " 8px",
  },
  approveText: {
    color: "#4C4C4C",
    alignTtems: "center",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
  },
  approveCount: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "10px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "42px",
  },
  approveIcon: {
    marginLeft: "24px",
  },
  pendingText: {
    marginLeft: "24px",
  },

  rejectIcon: {
    marginLeft: "24px",
  },
  table: {
    minWidth: 650,
  },
}));

export default function MyExpenseReoprt({
  handleDropeDown,
  myExpenseReportList,
  ssoEmpId,
}) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [day, setDay] = React.useState(90);
  const [toggleReload, setToggleReload] = useState(true);
  const dispatch = useDispatch();
  //console.log("myExpenseReportList", myExpenseReportList);

  const delEmpIDRedux = useSelector(
    (state) => state.createExpenseData.delEmpId
  );
  const getMyTeamApprovalFlagData = useSelector(
    (state) => state.createExpenseData.getMyTeamApprovalPageFlag
  );
  // console.log("getMyTeamApprovalFlagData button", getMyTeamApprovalFlagData);
  //console.log("delEmpID in MERK1", delEmpIDRedux);
  useEffect(() => {
    handleDropeDown(day, delEmpIDRedux);
  }, [day, delEmpIDRedux]);
  useEffect(() => {
    setToggleReload(false);
    dispatch(reloadModuleToggleData(0));
  }, []);

  const handleClick = () => {
    //console.log("int the landing page");

    history.push({
      pathname: "/create-Expense-Management",
      state: {
        ssoEmpId: ssoEmpId,
        compName: "createExpense",
      },
    });
  };

  return (
    <>
      <Box style={{ marginTop: "-30px" }}>
        <Grid container>
          <Grid item xs={6} sm={6} md={6}>
            <FormControl variant="outlined" className={classes.formControlDrop}>
              <InputLabel id="demo-simple-select-outlined-label">
                Select*
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                style={{ maxHeight: "38px" }}
                value={day}
                onChange={(e) => {
                  setDay(e.target.value);
                }}
                label="Select*"
                MenuProps={{
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem value={90} style={{ padding: "10px" }}>
                  90 Days
                </MenuItem>
                <MenuItem value={180} style={{ padding: "10px" }}>
                  180 Days
                </MenuItem>
                <MenuItem value={365} style={{ padding: "10px" }}>
                  Year Till Date
                </MenuItem>
              </Select>
            </FormControl>{" "}
          </Grid>
          {getMyTeamApprovalFlagData?.myExpenseTab === 1 && (
            <Grid item xs={6} sm={6} md={6} className={classes.buttonGrid}>
              <Button
                className={
                  window.screen.availWidth === 1792
                    ? classes.addExpenseButtonMac
                    : classes.addExpenseButton
                }
                onClick={handleClick}
              >
                <Typography className={classes.buttonText}>
                  + Add New Expense
                </Typography>
              </Button>
            </Grid>
           )} 
        </Grid>
      </Box>
      <Box>
        <Grid
          container
          style={{
            marginTop: "15px",
            paddingRight: "30px",
            marginBottom: "-30px",
          }}
          spacing={4}
        >
          <Grid item xs={4} sm={4} md={4}>
            {myExpenseReportList?.map((value) => (
              <Grid item xs={4} sm={4} md={12} className={classes.expenseCard}>
                <Box>
                  <Typography variant="p" className={classes.approveIcon}>
                    <img src={ApprovedIcon} />
                  </Typography>
                  <Box
                    style={{
                      display: "inline-block",
                      marginTop: "12px",
                      marginLeft: "60px",
                    }}
                  >
                    <Typography variant="p" className={classes.approveText}>
                      {"Approved Expense"}
                    </Typography>
                    <Typography variant="p" className={classes.approveCount}>
                      {value.approvedExpense}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Grid item xs={4} sm={4} md={4}>
            {myExpenseReportList?.map((value) => (
              <Grid item xs={4} sm={4} md={12} className={classes.expenseCard}>
                <Box>
                  <Typography variant="p" className={classes.pendingText}>
                    <img src={InProgressIcon} />
                  </Typography>
                  <Box
                    style={{
                      display: "inline-block",
                      marginTop: "12px",
                      marginLeft: "60px",
                    }}
                  >
                    <Typography variant="p" className={classes.approveText}>
                      {"In Progress Expense"}
                    </Typography>
                    <Typography variant="p" className={classes.approveCount}>
                      {value.pendingExpenses}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            {myExpenseReportList?.map((value) => (
              <Grid item xs={4} sm={4} md={12} className={classes.expenseCard}>
                <Box>
                  <Typography variant="p" className={classes.rejectIcon}>
                    <img src={RejectedIcon} />
                  </Typography>
                  <Box
                    style={{
                      display: "inline-block",
                      marginTop: "12px",
                      marginLeft: "60px",
                    }}
                  >
                    <Typography variant="p" className={classes.approveText}>
                      {"Rejected Expense"}
                    </Typography>
                    <Typography variant="p" className={classes.approveCount}>
                      {value.rejectedExpenses}
                    </Typography>
                  </Box>
                </Box>{" "}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
