import React, { useEffect } from "react";
//import {useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Box,
  Avatar,
  Typography,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Button,
  InputAdornment,
} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import SearchIcon from "@material-ui/icons/Search";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CloseIcon from "@material-ui/icons/Close";
import Filterimg from "../images/filter.svg";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  backdrop: {
    "& .MuiPaper-root": {
      width: "300px",
      height: "277px ",
      background: " #FFFFFF",
      boxShadow:
        "0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)",
      borderRadius: "4px",
      marginTop: "130px",
    },
  },

  box: {
    width: "173px",
    height: "126px",
  },

  icon: {
    color: "#666666",
    paddingLeft: "12px",
    paddingRight: "21px",
  },
  actionText: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "500",
    color: "#0B1941",
    alignItems: "center",
    marginRight: "30px",
  },
  boxHeading: {
    height: "56px",
    background: " #FFFFFF",
    borderBottom: "1px solid #AEAEB2",

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  inputHeading: {
    marginTop: "16px",
    marginBottom: "8px",
    marginLeft: "25px",
  },
  formControl: {
    width: "244px",
    height: "52px",
    marginLeft: "24px",
  },
  crossIcon: {
    marginRight: "29px",
  },
  buttonBox: {
    display: "flex",
    marginTop: "46px",
  },
  removeFilter: {
    marginLeft: "24px",
  },
  removeFilterText: {
    color: "#0B1941",
  },
  formControlClaimeSelectFeild: {
    width: "75px",
    height: "52px",
    marginLeft: "24px",
  },
  formControlClaimTextFeild: {
    width: "150px",
    height: "52px",
    marginLeft: "16px",
  },
  filterImg: {
    width: "24px",
  },
  description: {
    marginLeft: "8px",
  },
  fullName: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    color: "#000000",
  },
  designation: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "8px",
    color: "#000000",
  },
}));


const ExpenseFilter = ({
  filterAnchor,
  filtersetting,
  filterType,
  dropeDownList,
  handleClearFilter,
  handleApplyFilter,
  handleFilterToggle,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [employeeName, setEmployeeName] = React.useState("");
  const [deligateName, setDeligateName] = React.useState("");
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [buttonState, setButtonState] = React.useState(true);

  const handleClose = () => {
    setAnchorEl(null);
    setEmployeeName("");
    setDeligateName("");
    setStartDate(null);
    setEndDate(null);
    handleFilterToggle();
  };

  useEffect(() => {
    if (filtersetting) {
      //console.log("value of filter is", filtersetting);
      //console.log("filter)", Boolean(filterAnchor));
      setAnchorEl(filterAnchor);
    } else {
      //console.log("in the filter popop comcponent");
      setAnchorEl(null);
      setButtonState(true);
    }
  }, [filtersetting]);

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 400,
  });

  const handleChange2 = (event, value) => {
    //console.log("value", value);
    setButtonState(false);
    setEmployeeName(value);
  };
  const handleChange = (event, value) => {
    //console.log("value", value);
    setButtonState(false);
    setDeligateName(value);
  };
  const handleList = (option) => {
    //console.log("option", option);
    return (
      <>
        <Box style={{ display: "flex", marginTop: "16px" }}>
          <div className={classes.description}>
            <Typography component="div" className={classes.fullName}>
              {option.empName}
            </Typography>
            <Typography component="div" className={classes.designation}>
              {option.designation} | {option.empCode}
            </Typography>
            <Typography component="div" className={classes.designation}>
              {option.emailId}
            </Typography>
          </div>
        </Box>
      </>
    );
  };
  const handleStartDateChange = (type, date) => {
    setButtonState(false);
    setStartDate(date);
    // handleStartDate(date);
  };
  const handleEndDateChange = (type, date) => {
    setButtonState(false);
    setEndDate(date);
    // handleStartDate(date);
  };
  const sortedDropDownList = [...dropeDownList].sort((a, b) =>
  a.empName.localeCompare(b.empName)
);
  return (
    <Box component="span" m={1} style={{ alignItems: "center" }}>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.backdrop}
        disableScrollLock={true}

      >
        <Box>
          {filterType == "Employee" && (
            <Box style={{ marginBottom: "19px" }}>
              <Box className={classes.boxHeading}>
                <Typography variant="div" className={classes.icon}>
                  {/* <img src={Filterimg} className={classes.filterImg} /> */}
                  <span class="material-symbols-outlined">filter_alt</span>
                </Typography>
                <Typography variant="p" className={classes.actionText}>
                  {filterType}
                </Typography>
                <Typography variant="p" className={classes.crossIcon}>
                  <CloseIcon onClick={handleClose} />
                </Typography>
              </Box>
              <Box style={{ display: "grid", marginTop: "24px" }}>
                {/* <Typography variant="p" className={classes.inputHeading}>
                    {filterType}
                  </Typography> */}
                <Autocomplete
                  // multiple
                  className={classes.formControl}
                  filterOptions={filterOptions}
                  id="checkboxes-tags-demo2"
                  options={sortedDropDownList}
                  value={employeeName}
                  debug
                  onChange={handleChange2}
                  getOptionLabel={(option) => option.empName}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>{handleList(option)}</React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Employee"
                      style={{ backgroundColor: "white" }}
                      placeholder="Search Employee"
                    />
                  )}
                  
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  marginTop: "37px",
                }}
              >
                <Typography variant="p" className={classes.removeFilter}>
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.removeFilterText}
                    onClick={() => {
                      handleClearFilter();
                      handleClose();
                    }}
                  >
                    Remove Filter
                  </Button>{" "}
                </Typography>
                <Typography variant="p" className={classes.applyFilter}>
                  <Button
                    variant="outlined"
                    disabled={buttonState}
                    style={{
                      background: buttonState ? "#9E9E9E" : "#0B1941",
                    }}
                    color="#FFFFFF"
                    size="small"
                    className={
                      employeeName?.length
                        ? classes.applyFilterText
                        : classes.applyFilterTextDisabled
                    }
                    onClick={(e) => {
                      handleApplyFilter(filterType, employeeName);
                      handleClose();
                    }}
                  >
                    <Typography style={{ color: "white", margin: 0 }}>
                      {" "}
                      Apply Filter
                    </Typography>
                  </Button>{" "}
                </Typography>
              </Box>
            </Box>
          )}
          {filterType == "Delegate" && (
            <Box style={{ marginBottom: "19px" }}>
              <Box className={classes.boxHeading}>
                <Typography variant="div" className={classes.icon}>
                  {/* <img src={Filterimg} className={classes.filterImg} /> */}
                  <span class="material-symbols-outlined">filter_alt</span>
                </Typography>
                <Typography variant="p" className={classes.actionText}>
                  {filterType}
                </Typography>
                <Typography variant="p" className={classes.crossIcon}>
                  <CloseIcon onClick={handleClose} />
                </Typography>
              </Box>
              <Box style={{ display: "grid", marginTop: "24px" }}>
                {/* <Typography variant="p" className={classes.inputHeading}>
                    {filterType}
                  </Typography> */}
                <Autocomplete
                  // multiple
                  className={classes.formControl}
                  filterOptions={filterOptions}
                  id="checkboxes-tags-demo2"
                  options={sortedDropDownList}
                  value={deligateName}
                  debug
                  onChange={handleChange}
                  getOptionLabel={(option) => option.empName}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>{handleList(option)}</React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Delegate"
                      style={{ backgroundColor: "white" }}
                      placeholder="Search Delegate"
                    />
                  )}
                />
              </Box>
             
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  marginTop: "37px",
                }}
              >
                <Typography variant="p" className={classes.removeFilter}>
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.removeFilterText}
                    onClick={() => {
                      handleClearFilter();
                      handleClose();
                    }}
                  >
                    Remove Filter
                  </Button>{" "}
                </Typography>
                <Typography variant="p" className={classes.applyFilter}>
                  <Button
                    variant="outlined"
                    disabled={buttonState}
                    style={{
                      background: buttonState ? "#9E9E9E" : "#0B1941",
                    }}
                    color="#FFFFFF"
                    size="small"
                    className={
                      deligateName?.length
                        ? classes.applyFilterText
                        : classes.applyFilterTextDisabled
                    }
                    onClick={(e) => {
                      handleApplyFilter(filterType, deligateName);
                      handleClose();
                    }}
                  >
                    <Typography style={{ color: "white", margin: 0 }}>
                      {" "}
                      Apply Filter
                    </Typography>
                  </Button>{" "}
                </Typography>
              </Box>
            </Box>
          )}
          {filterType == "Start Date" && (
            <Box style={{ marginBottom: "19px" }}>
              <Box className={classes.boxHeading}>
                <Typography variant="div" className={classes.icon}>
                  {/* <img src={Filterimg} className={classes.filterImg} /> */}
                  <span class="material-symbols-outlined">filter_alt</span>
                </Typography>
                <Typography variant="p" className={classes.actionText}>
                  {filterType}
                </Typography>
                <Typography variant="p" className={classes.crossIcon}>
                  <CloseIcon onClick={handleClose} />
                </Typography>
              </Box>
              <Box style={{ display: "grid", marginTop: "24px" }}>
                {/* <Typography variant="p" className={classes.inputHeading}>
                    {filterType}
                  </Typography> */}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    inputVariant="outlined"
                    fullWidth
                    autoOk={true}
                    className={classes.formControl}
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Choose Start Date"
                    value={startDate}
                    style={{ marginTop: "24px" }}
                    onChange={handleStartDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  marginTop: "37px",
                }}
              >
                <Typography variant="p" className={classes.removeFilter}>
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.removeFilterText}
                    onClick={() => {
                      handleClearFilter();
                      handleClose();
                    }}
                  >
                    Remove Filter
                  </Button>{" "}
                </Typography>
                <Typography variant="p" className={classes.applyFilter}>
                  <Button
                    variant="outlined"
                    disabled={buttonState}
                    style={{
                      background: buttonState ? "#9E9E9E" : "#0B1941",
                    }}
                    color="#FFFFFF"
                    size="small"
                    className={
                      startDate !== null
                        ? classes.applyFilterText
                        : classes.applyFilterTextDisabled
                    }
                    onClick={(e) => {
                      handleApplyFilter(filterType, startDate);
                      handleClose();
                    }}
                  >
                    <Typography style={{ color: "white", margin: 0 }}>
                      {" "}
                      Apply Filter
                    </Typography>
                  </Button>{" "}
                </Typography>
              </Box>
            </Box>
          )}

          {filterType == "End Date" && (
            <Box style={{ marginBottom: "19px" }}>
              <Box className={classes.boxHeading}>
                <Typography variant="div" className={classes.icon}>
                  {/* <img src={Filterimg} className={classes.filterImg} /> */}
                  <span class="material-symbols-outlined">filter_alt</span>
                </Typography>
                <Typography variant="p" className={classes.actionText}>
                  {filterType}
                </Typography>
                <Typography variant="p" className={classes.crossIcon}>
                  <CloseIcon onClick={handleClose} />
                </Typography>
              </Box>
              <Box style={{ display: "grid", marginTop: "24px" }}>
                {/* <Typography variant="p" className={classes.inputHeading}>
                    {filterType}
                  </Typography> */}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    inputVariant="outlined"
                    fullWidth
                    className={classes.formControl}
                    variant="inline"
                    autoOk={true}
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Choose End Date"
                    value={endDate}
                    style={{ marginTop: "24px" }}
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              {/* <Box className={classes.buttonBox}>
                  <Typography variant="p" className={classes.removeFilter}>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.removeFilterText}
                      onClick={() => {
                        handleClearFilter();
                        handleClose();
                      }}
                    >
                      Remove Filter
                    </Button>{" "}
                  </Typography>
                  <Typography variant="p" className={classes.applyFilter}>
                    <Button
                      variant="outlined"
                      //   disabled={buttonState}
                      color="primary"
                      size="small"
                      className={
                        endDate !== null
                          ? classes.applyFilterText
                          : classes.applyFilterTextDisabled
                      }
                      onClick={(e) => {
                        handleApplyFilter(filterType, endDate);
                        handleClose();
                      }}
                    >
                      <p style={{ color: "white", margin: 0 }}> Apply Filter</p>
                    </Button>{" "}
                  </Typography>
                </Box> */}
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  marginTop: "37px",
                }}
              >
                <Typography variant="p" className={classes.removeFilter}>
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.removeFilterText}
                    onClick={() => {
                      handleClearFilter();
                      handleClose();
                    }}
                  >
                    Remove Filter
                  </Button>{" "}
                </Typography>
                <Typography variant="p" className={classes.applyFilter}>
                  <Button
                    variant="outlined"
                    disabled={buttonState}
                    style={{
                      background: buttonState ? "#9E9E9E" : "#0B1941",
                    }}
                    color="#FFFFFF"
                    size="small"
                    className={
                      endDate !== null
                        ? classes.applyFilterText
                        : classes.applyFilterTextDisabled
                    }
                    onClick={(e) => {
                      handleApplyFilter(filterType, endDate);
                      handleClose();
                    }}
                  >
                    <Typography style={{ color: "white", margin: 0 }}>
                      {" "}
                      Apply Filter
                    </Typography>
                  </Button>{" "}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Menu>
    </Box>
  );
};

export default ExpenseFilter;
