import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Box, Avatar, Typography } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import DeleteDialog from "./deleteDialog";
import DeleteSnackbar from "./deleteSnackbar";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  box: {
    width: "173px",
    height: "126px",
  },
  icon: {
    color: "#666666",
    paddingLeft: "12px",
    paddingRight: "21px",
  },
  actionText: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "500",
    color: "#2E2F2F",
    alignItems: "left",
  },
  backdrop: {
    "& .MuiPaper-root": {
      width: "173px",
      height: "138px ",
      background: " #FFFFFF",
      boxShadow:
        "0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)",
      borderRadius: "4px",
      overflowY: "hidden",
    },
  },
  backdropApprove: {
    "& .MuiPaper-root": {
      width: "173px",
      height: "56px ",
      background: " #FFFFFF",
      boxShadow:
        "0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)",
      borderRadius: "4px",
      overflowY: "hidden",
    },
  },
  backdropProgress: {
    "& .MuiPaper-root": {
      width: "173px",
      height: "90px ",
      background: " #FFFFFF",
      boxShadow:
        "0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)",
      borderRadius: "4px",
      overflowY: "hidden",
    },
  },
}));

const AccountOptions = ({
  popUpAction,
  action,
  handlePopupAction,
  row,
  handleDelete,
  handleDownload,
  singleDownload,
  DeleteMyExpense,
  ssoEmpId,
  handleFilterOff,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentIndex, setCurrentIndex] = React.useState();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState();

  const headers = [
    {
      label: "Expense Code",
      key: "expCode",
    },
    {
      label: "Expense Category",
      key: "categoryName",
    },
    {
      label: "To Location",
      key: "toLocation",
    },
    {
      label: "From Location",
      key: "fromLocation",
    },
    {
      label: "Distance (Km/Miles)",
      key: "distance",
    },
    {
      label: "From Date",
      key: "fromDate",
    },
    {
      label: "To Date",
      key: "toDate",
    },
    {
      label: "Claim Month",
      key: "forMonth",
    },
    {
      label: "Expense Name",
      key: "expName",
    },
    {
      label: "Creation Date",
      key: "createdOn",
    },
    {
      label: "Created By",
      key: "createdBy",
    },
    {
      label: "Currency",
      key: "currencyCode",
    },
    {
      label: "Claimed Amount",
      key: "claimedAmount",
    },
    {
      label: "Relationship",
      key: "dependent",
    },
    {
      label: "Bill Amount",
      key: "billAmount",
    },
    {
      label: "Approved Amount",
      key: "approvedAmount",
    },
    {
      label: "Project ID",
      key: "projectId",
    },
    {
      label: "Attachments",
      key: "attachments",
    },
    {
      label: "Comments",
      key: "reportComments",
    },
    {
      label: "Status",
      key: "status",
    },
  ];

  const csvLink = {
    headers: headers,
    data: singleDownload,
    filename: `MyExpense_${moment(new Date()).format("DDMMYYYY_HHmm")}.csv`,
  };

  useEffect(() => {
    if (DeleteMyExpense) {
      handleClose();
    }
  }, [DeleteMyExpense]);

  const handleClose = () => {
    setAnchorEl(null);
    handlePopupAction("llll");
  };

  useEffect(() => {
    //console.log("Boolean(popUpAction)", Boolean(popUpAction));
    setAnchorEl(popUpAction);
  }, [action]);
  const handleOpenDeleteDialog = (row) => {
    //console.log("handleOpenDeleteDialog row", row);
    
    setOpenDeleteDialog(true);
    setCurrentIndex(row);
  };
  const handleCloseDeleteDialog = (row) => {
    //console.log("handleOpenDeleteDialog row", row);

    setOpenDeleteDialog(false);
  };

  //console.log("anchorEl", Boolean(anchorEl));
  // console.log("action", row);

  return (
    <Box component="span" m={1} style={{ alignItems: "center" }}>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={
          row.status == "Draft"
            ? classes.backdrop
            : row.status == "Approved" || row.status == "Paid"
            ? classes.backdropApprove
            : ""
        }
      >
        {(row.status == "Draft" ||
          row.status == "Rejected" ||
          row.status == "In Progress") && (
          <Box className={classes.box} id="1">
            <Box
              style={{ display: "flex", marginBottom: "19px" }}
              onClick={() => {
                //console.log("hi edit Box  clicked!");
                // const text = "/editExpenseDetails/:id";
                const text = row.tFlag == 1 ?"/editTravelExpenseDetails/:id" :"/editExpenseDetails/:id" ;
                const result = text.replace(":id", row.expId);
                history.push({
                  pathname: result,
                  state: {
                    response: row,
                    empId: ssoEmpId,
                    compName: "edit",
                  },
                });
              }}
            >
              <>
                <Typography variant="p" className={classes.icon}>
                  <EditOutlinedIcon />
                </Typography>
                <Typography variant="p" className={classes.actionText}>
                  Edit
                </Typography>
              </>
            </Box>
            <CSVLink {...csvLink} style={{ color: "transparent" }}>
              <Box
                style={{ display: "flex", marginBottom: "19px" }}
                onClick={() => handleDownload(row)}
              >
                <>
                  <Typography variant="p" className={classes.icon}>
                    <GetAppOutlinedIcon />
                  </Typography>

                  <Typography variant="p" className={classes.actionText}>
                    Download
                  </Typography>
                </>{" "}
              </Box>
            </CSVLink>
            <Box
              style={{ display: "flex", marginBottom: "19px" }}
              onClick={() => handleOpenDeleteDialog(row)}
              // onClick={() => handleDelete(row)}
            >
              <>
                <Typography variant="p" className={classes.icon}>
                  <DeleteOutlinedIcon />
                </Typography>
                <Typography variant="p" className={classes.actionText}>
                  Delete
                </Typography>
              </>
            </Box>

            <DeleteDialog
              openDeleteDialog={openDeleteDialog}
              handleCloseDeleteDialog={handleCloseDeleteDialog}
              handleDelete={handleDelete}
              currentExpense={currentIndex}
              handleFilterOff={handleFilterOff}
              //pass state of filter
            />
          </Box>
        )}
        {(row.status == "Approved" || row.status == "Paid") && (
          <Box className={classes.box} id="1">
            <CSVLink {...csvLink} style={{ color: "transparent" }}>
              <Box
                style={{ display: "flex", marginBottom: "19px" }}
                onClick={() => handleDownload(row)}
              >
                <>
                  <Typography variant="p" className={classes.icon}>
                    <GetAppOutlinedIcon />
                  </Typography>

                  <Typography variant="p" className={classes.actionText}>
                    Download
                  </Typography>
                </>{" "}
              </Box>
            </CSVLink>
          </Box>
        )}
      </Menu>
    </Box>
  );
};

export default AccountOptions;
