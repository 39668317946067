import * as React from "react";
import {
  Box,
} from "@material-ui/core";
import dayjs from "dayjs";
import moment from "moment";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import { styled } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { Divider } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCalendarDateData
} from "../redux/TimeSheet/actionTs";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";

dayjs.extend(isBetweenPlugin);
const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: "#E3E8F7",
    color: theme.palette.common.black,
    "&:hover, &:focus": {
      backgroundColor: "#E3E8F7",
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
  maxHeight: "100px",
}));
function Day(props) {
  // console.log("check colour" , props);

  const { day, selectedDay, calendarDateLists, ...other } = props;
  const dateString = day.format("YYYY-MM-DD");
  const matchingDate = calendarDateLists?.find(
    (item) => item?.dates === dateString
  );
  const type = matchingDate ? matchingDate.type : null;
  // console.log("check colour1" ,selectedDay , day);

  if (selectedDay == null) {
    return <PickersDay day={day} {...other} />;
  }
  const start = selectedDay?.startOf("week");
  const end = selectedDay?.endOf("week");
  const dayIsBetween = day?.isBetween(start, end, null, "[]");
  const isFirstDay = day?.isSame(start, "day");
  const isLastDay = day?.isSame(end, "day");
  return (
    // console.log("check colour" , dayIsBetween,isFirstDay,isLastDay),
    <CustomPickersDay
      {...other}
      day={day}
      sx={dayIsBetween ? { px: 2.5, mx: 0 } : {}}
      dayIsBetween={dayIsBetween}
      isFirstDay={isFirstDay}
      isLastDay={isLastDay}
      // Add style to change the background color based on the type (Holiday or Leave)
      style={{
        backgroundColor: type === "Holiday" ? "#E5BF90" : type === "Leave" ? "#629797" : undefined,
        color: type === "Holiday" ? "#fff" : type === "Leave" ? "#fff" : undefined,
        // fontWeight: type === "Holiday" || type === "Leave" ? 'bold' : '',
      }}
    />
  );
}
export default function CustomCalendar(props) {
  const [refDateCal, setRefDateCal] = React.useState(null);
  //let refDateCal = null;
  const [weekStartDate, setWeekStartDate] = React.useState(null);
  const [weekEndDate, setWeekEndDate] = React.useState(null);
  const [inputWeek, setInputWeek] = React.useState(null);
  const [firstDay, setFirstDay] = React.useState(null);
  const [lastDay, setLastDay] = React.useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const dispatch = useDispatch();
  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );
  const RefDateRed = useSelector((state) => state.timesheetData.RefDateRedux);
  const calendarDateLists = useSelector(
    (state) => state.timesheetData.getCalendarDateLists
  );
  const tsDelEmpIdRedux = useSelector(
    (state) => state?.timesheetData?.tsDelEmpId
  );
  React.useEffect(() => {
    // console.log("checking inside calender" , props.refDate)
    setRefDateCal(props.refDate);
  //refDateCal = props.refDate;
  }, []);
// useEffect(() => {
//   console.log("checking inside calender1" ,refDateCal)
  
// }, [refDateCal])

  // useEffect(() => {
  //   if(props.refDate){
  //     console.log("Checking Props in Calendar:", props.refDate)
  //   }
  // }, [])


  useEffect(() => {
    const initialDate = dayjs().startOf("month");
    handleMonthChange(initialDate);
  }, []);

  useEffect(() => {
    if (firstDay && (ssoEmpIdDetails || tsDelEmpIdRedux)) {
      let payload = {
        empId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
        date: moment(firstDay, ["DD/MM/YYYY", "yyyy-MM-DD"]).format("yyyy-MM-DD"),
      };
      dispatch(getCalendarDateData(payload));
      // console.log("Payload Date", payload);
    }
  }, [firstDay, ssoEmpIdDetails, weekStartDate, tsDelEmpIdRedux]);

  const handleYesButton =() =>{
    props.handleRefDate(refDateCal);
    let tempStart = refDateCal?.startOf("week");
    let tempEnd = refDateCal?.endOf("week");
    let tempInputWeek = [
      {
        date: dayjs(tempStart).add(1, "days").format("DD/MM/YYYY"),
        day: "Mon",
      },
      {
        date: dayjs(tempStart).add(2, "days").format("DD/MM/YYYY"),
        day: "Tue",
      },
      {
        date: dayjs(tempStart).add(3, "days").format("DD/MM/YYYY"),
        day: "Wed",
      },
      {
        date: dayjs(tempStart).add(4, "days").format("DD/MM/YYYY"),
        day: "Thu",
      },
      {
        date: dayjs(tempStart).add(5, "days").format("DD/MM/YYYY"),
        day: "Fri",
      },
      {
        date: dayjs(tempStart).add(6, "days").format("DD/MM/YYYY"),
        day: "Sat",
      },
      {
        date: dayjs(tempStart).add(7, "days").format("DD/MM/YYYY"),
        day: "Sun",
      },
    ];
    setWeekStartDate(tempStart?.format("DD/MM/YYYY"));
    setWeekEndDate(tempEnd?.format("DD/MM/YYYY"));
    setInputWeek(tempInputWeek);
    props.setIsDayHoursChanged(false);
    props.setProjectClicked({});
    setShowConfirmation(false);
  }
  const handleConfirmClose = () =>{
    setShowConfirmation(false);
  }
  const handleBackdropClick = () => {
    props.setIsSubmitDisabled(false);
  };
  const handleDateChange = (newValue) => {
    // console.log("show confirm" , props.isDayHoursChanged)
    setShowConfirmation(props.isDayHoursChanged ? true : false)
    // console.log("checking inside calender3" , newValue)
    setRefDateCal(newValue);
    if(!props.isDayHoursChanged){
      props.handleRefDate(newValue);
      let tempStart = newValue?.startOf("week");
      let tempEnd = newValue?.endOf("week");
      let tempInputWeek = [
        {
          date: dayjs(tempStart).add(1, "days").format("DD/MM/YYYY"),
          day: "Mon",
        },
        {
          date: dayjs(tempStart).add(2, "days").format("DD/MM/YYYY"),
          day: "Tue",
        },
        {
          date: dayjs(tempStart).add(3, "days").format("DD/MM/YYYY"),
          day: "Wed",
        },
        {
          date: dayjs(tempStart).add(4, "days").format("DD/MM/YYYY"),
          day: "Thu",
        },
        {
          date: dayjs(tempStart).add(5, "days").format("DD/MM/YYYY"),
          day: "Fri",
        },
        {
          date: dayjs(tempStart).add(6, "days").format("DD/MM/YYYY"),
          day: "Sat",
        },
        {
          date: dayjs(tempStart).add(7, "days").format("DD/MM/YYYY"),
          day: "Sun",
        },
      ];
      setWeekStartDate(tempStart?.format("DD/MM/YYYY"));
      setWeekEndDate(tempEnd?.format("DD/MM/YYYY"));
      setInputWeek(tempInputWeek);
    }
    //refDateCal = newValue;
    // console.log("checking inside calender2" ,refDateCal)
  };
  
  const handleMonthChange = (newMonth) => {
   
    setFirstDay(
      dayjs(newMonth?.startOf("month").startOf("day"))
        .add(1, "days")
        .toISOString()
        .slice(0, 10)
    );
    setLastDay(newMonth?.endOf("month").toISOString().slice(0, 10));
   
  };
  return (
    <>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        showDaysOutsideCurrentMonth
        value={refDateCal}
        onMonthChange={handleMonthChange}
        onChange={handleDateChange}
        slots={{ day: Day }}
        slotProps={{
          day: {
            selectedDay: refDateCal,
            calendarDateLists: calendarDateLists,
          },
        }}
      />
      <Divider style={{ margin: 0, marginBottom: "5px" }} />
      <Box display="flex" alignItems="center" sx={{ padding: "10px" }}>
        <Box
          width="10px"
          height="10px"
          borderRadius="50%"
          bgcolor="#629797"
          marginRight="8px"
        />
        <Box marginRight="20px">Leaves</Box>
        <Box
          width="10px"
          height="10px"
          borderRadius="50%"
          bgcolor="#E5BF90"
          marginRight="8px"
        />
        <Box>Holidays</Box>
      </Box>
    </LocalizationProvider>
    <>
    <Dialog
      open={showConfirmation}
      //TransitionComponent={Transition}
      BackdropProps={{ onClick: handleBackdropClick }}
      keepMounted
      onClose={handleConfirmClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Are you sure want to navigate?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          It looks like you have been editing something. If you leave before
          saving, your changes will be lost.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleYesButton}
        >
          Yes
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleConfirmClose}
        >
          No
        </Button>
      </DialogActions>
    </Dialog>

    
  </>
  </>
  );
}
