import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/header";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { format } from 'date-fns';
import Container from "@material-ui/core/Container";
import LaunchIcon from "@material-ui/icons/Launch";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router";
import { getDetailedView } from "../redux/TimeSheet/actionTs";
import { CSVLink } from "react-csv";
import moment from "moment";

import { useLocation } from "react-router";
import Encrypt from "../Utils/encryption";
import Snackbar from "@material-ui/core/Snackbar";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import ApproveRejectByManager from "./approveRejectByManager";
import {
  getMyTeamApprovalData,
  updateStatusByManagerData,
} from "../redux/TimeSheet/actionTs";
const useStyles = makeStyles({
  table: {
    minWidth: "100%",
    "&.MuiContainer-maxWidthLg": {
      maxWidth: "1900px",
    },
  },
  colorCondition: {
    color: "#C4C4C4",
  },
  snackbarMessage: {
    color: "#333",
    fontFamily: "Montserrat",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "0.2px",
  },
  snackbarMessage1: {
    color: "#252733",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "0.2px",
    paddingLeft: "10px",
  },
  anchorStyle: {
    "&.MuiSnackbar-anchorOriginTopRight": {
      top: "51px",
      left: "auto",
      right: "52px",
    },
  },
});
function ResponsiveDetailedView() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  // const [rowsToShow, setRowsToShow] = useState(4); // Number of rows to show initially

  const { empId, managerId, delegateeId, fromDate, toDate,projId, tab } = location.state;
  const [open, setOpen] = useState(false);
  const [refreshTableToggle, setRefreshTableToggle] = useState(0);
  const [snackBarData, setSnackBarData] = useState({
    name: "",
    id: "",
  });
  const [snackBarDataFinal, setSnackBarDataFinal] = useState({
    name: "",
    id: "",
  });
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [firstAppRej, setFirstAppRej] = useState("");
  const [showApproveRejectDialog, setShowApproveRejectDialog] = useState(false);
  const [approveRejectStatus, setApproveRejectStatus] = useState("");
  const [approveRejectComment, setApproveRejectComment] = useState(null);
  const [isLoader, isSetLoader] = useState(false);

  const allViewList = useSelector(
    (state) => state.timesheetData.getDetailedViewLists
  );

  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );
  const tsDelEmpIdRedux = useSelector(
    (state) => state?.timesheetData?.tsDelEmpId
  );
  const updatedStatusByManager = useSelector(
    (state) => state.timesheetData.isUpdateStatusByManager
  );
  var myTeamApproval = useSelector(
    (state) => state.timesheetData.getMyTeamsApproval
  );

  useEffect(() => {
    if(allViewList?.empDetail && allViewList?.projDetail){
      isSetLoader(false);
    }
    else{
      isSetLoader(true);
    }
  }, [allViewList]);
  
  useEffect(() => {
    if (tab === "teamTimesheet") {
      let obj = {
        empId: +empId,
        managerId: +managerId,
        delegateeId: +delegateeId,
        fromDate: fromDate ? fromDate : "",
        toDate: toDate ? toDate : "",
        projId:projId,
      };
      if(empId && managerId ){
        dispatch(getDetailedView(obj));
      }
    } else if (tab === "myTeamApproval") {
      let obj = {
        empId: +empId,
        managerId: +managerId,
        delegateeId: +delegateeId,
        fromDate: fromDate ? fromDate : "",
        toDate: toDate ? toDate : "",
        projId:projId,
      };
      if(empId && managerId && fromDate && toDate){
        dispatch(getDetailedView(obj));
      }
    }else if (tab === "myPMOApproval") {
      let obj = {
        empId: empId,
        managerId: managerId,
        delegateeId: delegateeId,
        fromDate: fromDate,
        toDate: toDate,
        projId:projId,
      };
      // console.log("object for allTimesheetReport", obj);
      dispatch(getDetailedView(obj));
    }
  }, []);
  useEffect(() => {
    if (tab === "teamTimesheet") {
      let obj = {
        empId: +empId,
        managerId: +managerId,
        delegateeId: +delegateeId,
        fromDate: fromDate ? fromDate : "",
        toDate: toDate ? toDate : "",
        projId:projId,
      };
      if(empId && managerId && fromDate && toDate){
        dispatch(getDetailedView(obj));
      }
    } else if (tab === "myTeamApproval") {
      let obj = {
        empId: +empId,
        managerId: +managerId,
        delegateeId: +delegateeId,
        fromDate: fromDate ? fromDate : "",
        toDate: toDate ? toDate : "",
        projId:projId,
      };
      if(empId && managerId && fromDate && toDate){
        dispatch(getDetailedView(obj));
      }
    }
    else if (tab === "myPMOApproval") {
      let obj = {
        empId: empId,
        managerId: managerId,
        delegateeId: delegateeId,
        fromDate: fromDate,
        toDate: toDate,
        projId:projId,
      };
      // console.log("object for allTimesheetReport", obj);
      dispatch(getDetailedView(obj));
    }
  }, [refreshTableToggle, dispatch, updateStatusByManagerData]);

  const handleApproveRejectByManager = async (value, status, comment) => {
    // console.log("inside handle of appp rej");
    // console.log("value of approve/reject", value);
    // console.log("value of approve/reject", status);
    // console.log("value of approve/reject", comment);
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    let formData = new FormData();
    const tmdetails = [
      {
        tmdetailrecid: value?.tmDetailUniqueId,
        tmid: value?.tmId,
      },
    ];
    formData.append("ManagerEmpId", Encrypt(key, JSON.stringify(tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId)));
    formData.append(
      "delegateeId",
      ssoEmpIdDetails?.empId === tsDelEmpIdRedux
        ? Encrypt(key, JSON.stringify(null))
        : Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
        formData.append("TmStatus", JSON.stringify(status));
    formData.append("Comment", JSON.stringify(comment));
    formData.append("TmDetails", JSON.stringify(tmdetails));
    try {
      setShowApproveRejectDialog(false);
      await dispatch(updateStatusByManagerData(formData));
      isSetLoader(false);

      setOpen(true);
      if (updatedStatusByManager) {
        myTeamApproval = myTeamApproval.filter(
          (item) =>
            item.timesheetDetailUniqueId != value?.timesheetDetailUniqueId
        );
      }
      setRefreshTableToggle(
        (prevRefreshTableToggle) => prevRefreshTableToggle + 1
      );
      setSnackBarData({
        name: allViewList?.empDetail.empName,
        id: allViewList?.empDetail.empCode,
      });
    } catch (error) {}
  };
  const handleApproveRejectDialog = (index, status) => {
    setCurrentIndex(index);
    setFirstAppRej(status);
    setShowApproveRejectDialog(true);
    // console.log("snackbar data", snackBarData, allViewList);
    // setSnackBarData({
    //   name: allViewList?.empDetail.empName,
    //   id: allViewList?.empDetail.empCode,
    // });
  };

  const handleApproveRejectStatus = (stat) => {
    setApproveRejectStatus(stat);
  };

  const handleComment = (data) => {
    setApproveRejectComment(data);
  };

  const handleCloseApproveRejectDialog = () => {
    setShowApproveRejectDialog(false);
  };
  const handleSnackbar = () => {
    setOpen(true);
  };
  const handlecloseSnackbar = () => {
    setOpen(false);
  };

  const empDetailsView = allViewList?.empDetail;
  const projDetailsView = allViewList?.projDetail;

  const headers = [
    {
      label: "Employee ID",
      key: "empCode",
    },
    {
      label: "Employee Name",
      key: "empName",
    },
    {
      label: "Employee Type",
      key: "employeeType",
    },
    {
      label: "Project Name",
      key: "projName",
    },
    {
      label: "Project ID",
      key: "projCode",
    },
    {
      label: "Date",
      key: "timesheetDate",
    },
    {
      label: "Billable Hours",
      key: "billableHours",
    },
    {
      label: "Non Billable Hours",
      key: "nonBillableHours",
    },
    {
      label: "Approved Hours",
      key: "approvedHours",
    },
    {
      label: "Unapproved Hours",
      key: "unapprovedHours",
    },
  ];

  const varVals = { ...allViewList?.empDetail };
  const varArrVal = allViewList?.projDetail;
  const combineDetailsView = projDetailsView?.map((obj) => ({
    ...empDetailsView,
    ...obj,
  }));
  const fromDateReplace = allViewList?.empDetail?.fromDate.replace(/ /g, "_");
  const fromDateReplaceComma = fromDateReplace?.replace(/,/g, "_");
  const toDateReplace = allViewList?.empDetail?.toDate.replace(/ /g, "_");
  const toDateReplaceComma = toDateReplace?.replace(/,/g, "_");
  const dateFormCSV = fromDateReplaceComma?.concat(toDateReplaceComma);

  const csvLink = {
    headers: headers,
    data:
      JSON.parse(
        JSON.stringify(
          combineDetailsView
            ? combineDetailsView
            : varVals
            ? [varVals]
            : varArrVal
            ? varArrVal
            : ""
        )
      ) || "",
    filename: `DetailedView_${dateFormCSV}_${moment(new Date()).format(
      "DDMMYYYY_HHmm"
    )}.csv`,
  };
  return (
    <>
      <Header
        heading={""}
        ssoEmpId={ssoEmpIdDetails?.empId}
        disableAccOptions={1}
      />
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handlecloseSnackbar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.anchorStyle}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          style={{
            background: "#FEF0F3",
          }}
        >
          {approveRejectStatus == "Rejected" ? (
            <Grid style={{ height: "67px", width: "300px" }}>
              <div style={{ display: "flex" }}>
                <Grid
                  style={{
                    marginLeft: "43px",
                    paddingTop: "9px",
                    marginRight: "90px",
                  }}
                  className={classes.snackbarMessage}
                >
                  {" "}
                  {"Timesheet Rejected"}
                </Grid>
                <Grid style={{ marginleft: "90px", paddingTop: "5px" }}>
                  <CloseOutlinedIcon onClick={handlecloseSnackbar} />
                </Grid>
              </div>
              <div style={{ display: "flex" }}>
                <Grid style={{ marginLeft: "10px", marginTop: "-25px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M15 2L20 7M5 22H19C19.2652 22 19.5196 21.8946 19.7071 21.7071C19.8946 21.5196 20 21.2652 20 21V7H15V2H5C4.73478 2 4.48043 2.10536 4.29289 2.29289C4.10536 2.48043 4 2.73478 4 3V21C4 21.2652 4.10536 21.5196 4.29289 21.7071C4.48043 21.8946 4.73478 22 5 22Z"
                      stroke="#D50606"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9 11L15 17"
                      stroke="#D50606"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15 11L9 17"
                      stroke="#D50606"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Grid>
                {/* {console.log("check snackbar data" , snackBarDataFinal)} */}
                <Grid className={classes.snackbarMessage1}>
                  {snackBarData.name} {" | "}
                  ID : {snackBarData.id}
                </Grid>
              </div>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Snackbar>
      <div>
        {/* <Container> */}{" "}
        <Box style={{ paddingTop: "15px" }}>
          <Box
          // style={{
          //   display: "flex",
          //   justifyContent: "space-between",
          //   margin: "20px",
          //   marginLeft: "10px",
          // }}
          >
            <Grid
              // container
              // spacing={2}
              style={{
                display: "flex",
                justifyContent: "space-between",
                // margin: "20px",
                // marginLeft: "10px",
              }}
            >
              <Grid item xs={6}>
                <Box style={{ display: "flex", alignItems: "center", marginLeft: "5px"}}>
                  <Box>
                    <ArrowBackIosIcon
                      onClick={() => history.push("/timesheet-management")}
                      style={{ cursor: "pointer" }}
                    />
                  </Box>
                  <Box style={{ color: "#081F32" }}>
                    <b>Detail View</b>
                  </Box>
                </Box>
              </Grid>{" "}
              <Grid align="right">
                <CSVLink {...csvLink} style={{ color: "transparent" }}>
                  {/* <Button
                variant="outlined"
                style={{
                  textTransform: "unset",
                  color: "#0B1941",
                  border: "1px solid #0B1941",
                }}
              > */}
                  <LaunchIcon
                    style={{
                      height: "20px",
                      color: "#0B1941",
                      marginTop: "4px",
                      marginRight: "5px",
                    }}
                  />
                  {/* &nbsp;Export Timesheet */}
                  {/* </Button> */}
                </CSVLink>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box
                style={{
                  backgroundColor: "#E3E8F7",
                  //padding: "10px",
                  padding: "2px 2px 2px 6px",
                  //paddingLeft: window.screen.availWidth == 1920 ? "1%" :
                  // window.screen.availWidth == 1536 ? "1%"
                  // : window.screen.availWidth == 1098 ? "2%"
                  // :"1%"
                }}
              >
                <small>
                  <b>Total Hours&nbsp;:&nbsp;</b>
                </small>
                <b style={{ fontSize: "12px" }}>
                  {empDetailsView?.totalHours}&nbsp;|&nbsp;
                  {empDetailsView?.fromDate} - {empDetailsView?.toDate}{" "}
                </b>
              </Box>
            </Grid>

            <Box style={{ paddingTop: "2px"}}>
              <Grid container style={{justifyContent: "space-between"}}>
                {/* <Grid item xs={12}>
                  <Box
                    style={{
                      backgroundColor: "#DCDFE9",
                      padding: "3px 2px 3px 6px",
                      color: "#424242",
                      minHeight: "45%",
                      display: "flex",
                      flexDirection: "column",
                      //alignItems: "center",
                    }}
                  >
                    <div style={{ fontSize: "12px" }}>
                    <b>Employee ID:</b> {empDetailsView?.empCode}
                    </div>
                    <div style={{ fontSize: "10px" }}>
                    <b>Employee Name:</b> {empDetailsView?.empName}
                    </div>
                    <div style={{ fontSize: "9px" }}>
                    <b>Employee Type:</b> {empDetailsView?.employeeType}
                    </div>
                  </Box>
                </Grid> */}
                <Grid item xs={2}
                    style={{
                      backgroundColor: "#DCDFE9",
                      // padding: "24px",
                      color: "#424242",
                      paddingLeft : "2%",
                      minHeight: "40%",
                      fontSize: "10px",
                      paddingBottom: "1%",
                      paddingTop: "1%",
                    }}
                  >
                    <b style={{ fontSize: "11px" }}>Emp ID</b><br />{" "}
                    <div style={{ fontSize: "10px" }}>
                      {empDetailsView?.empCode}
                    </div>
                </Grid>
                <Grid item xs={6}
                    style={{
                      backgroundColor: "#DCDFE9",
                      //padding: "24px",
                      color: "#424242",
                      paddingLeft: "15%",
                      minHeight: "40%",
                      fontSize: "10px",
                      paddingBottom: "1%",
                      paddingTop: "1%",
                    }}
                  >
                    <b style={{ fontSize: "11px" }}>Name</b><br />{" "}
                    {empDetailsView?.empName}
                </Grid>
                <Grid item xs={4}
                    style={{
                      backgroundColor: "#DCDFE9",
                      //padding: "24px",
                      color: "#424242",
                      paddingLeft:"10%",
                      minHeight: "40%",
                      fontSize: "10px",
                      paddingBottom: "1%",
                      paddingTop: "1%",
                    }}
                  >
                    <b style={{ fontSize: "11px" }}>Type</b><br />{" "}
                    {empDetailsView?.employeeType}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <TableContainer component={Paper} style={{ paddingTop: "2px" }}>
          <Table className={classes.table} aria-label="simple table">
            {" "}
            <TableHead
              style={{
                backgroundColor: "#E3E8F7",
                height: "30px",
              }}
            >
              <TableRow>
                <TableCell
                  style={{
                    fontSize: "10px",
                    padding: "0px",
                    width: "17%",
                    paddingLeft: "6px",
                  }}
                >
                  <b>Date</b>
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "10px",
                    padding: "0px",
                    width: "22%",
                    paddingLeft: "6px",
                  }}
                >
                  <b>Project ID</b>
                </TableCell>

                <TableCell
                  style={{
                    fontSize: "10px",
                    padding: "0px",
                    width: "5%",
                    paddingLeft: "17px",
                  }}
                >
                  <b>Hours</b>
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "10px",
                    padding: "0px",
                    width: "21%",
                    paddingLeft: "6px",
                  }}
                >
                  <b>Status</b>
                </TableCell>
                <TableCell
                  style={{ width: "10%", fontSize: "10px", padding: "0px" }}
                >
                  <b>Reject</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projDetailsView?.map((row, index) => (
                <TableRow>
                  <TableCell style={{ fontSize: "10px", padding: "5px" }}>
                    {row?.tmDate}
                    <br /> <small>{row?.dayType}</small>
                  </TableCell>
                  <TableCell style={{ fontSize: "10px", padding: "5px" }}>
                    {" "}
                    {row?.projName} <br /> <small>{row?.projCode}</small>
                    {/* <br/> <small>{row?.projPurpose}</small> */}
                  </TableCell>
                  <TableCell style={{ fontSize: "10px", padding: "5px" }}>
                    {" "}
                    {row?.categoryName === "Billable" && (
                      <>
                        <div
                          style={{
                            fontSize: "0.625rem",
                            paddingLeft: "1.25rem",
                          }}
                        >
                          {parseFloat(row?.billableHours).toFixed(2)}
                        </div>
                        <div
                          style={{
                            backgroundColor: "#E3E8F7",
                            width: "3.25rem",
                            // padding: "0.125rem",
                            height: "1rem",
                            fontSize: "0.563rem",
                            paddingLeft: "0.813rem",
                            //padding: " 0.125rem 0.125rem 0.125rem 0.375rem",
                          }}
                        >
                          {row?.categoryName}
                        </div>
                      </>
                    )}
                    {row?.categoryName === "Non Billable" && (
                      <>
                        <div
                          style={{
                            fontSize: "0.625rem",
                            paddingLeft: "1.25rem",
                          }}
                        >
                          {parseFloat(row?.nonBillableHours).toFixed(2)}
                        </div>
                        <div
                          style={{
                            backgroundColor: "#E3E8F7",
                            width: "3.75rem",
                            // padding: "0.125rem",
                            height: "1rem",
                            fontSize: "0.563rem",
                            paddingLeft: "0.313rem",
                            //padding: " 0.125rem 0.125rem 0.125rem 0.375rem",
                          }}
                        >
                          {row?.categoryName}
                        </div>
                      </>
                    )}
                    {/* {(
                      parseFloat(row?.billableHours) +
                      parseFloat(row?.nonBillableHours)
                    ).toFixed(2)} */}
                  </TableCell>
                  <TableCell style={{ fontSize: "10px", padding: "5px" }}>
                    {" "}
                    {row?.status.split("|").map((part, partIndex) => (
                      <div className="status-part" key={partIndex}>
                        {part}
                      </div>
                    ))}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      paddingLeft:
                        window.screen.availWidth == 1920
                          ? "3%"
                          : window.screen.availWidth == 1536
                          ? "3%"
                          : window.screen.availWidth == 1098
                          ? "2%"
                          : "1%",
                      color: "#e02020",
                    }}
                    className={classes.iconStyle}
                  >
                    {row.rejectBTEnabledFlag == 1 ? (
                      <CancelOutlinedIcon
                        onClick={() =>
                          handleApproveRejectDialog(index, "Rejected")
                        }
                      />
                    ) : (
                      ""
                    )}
                  </TableCell>
                  {index == currentIndex ? (
                    <ApproveRejectByManager
                      row={row}
                      loader={isSetLoader}
                      handleApproveRejectByManager={
                        handleApproveRejectByManager
                      }
                      handleComment={handleComment}
                      firstAppRej={firstAppRej}
                      handleApproveRejectStatus={handleApproveRejectStatus}
                      showApproveRejectDialog={showApproveRejectDialog}
                      handleCloseApproveRejectDialog={
                        handleCloseApproveRejectDialog
                      }
                    />
                  ) : (
                    <></>
                  )}
                </TableRow>
              ))}
              {projDetailsView && projDetailsView?.length == 0 && (
                <TableRow className={classes.tableRow}>
                  <TableCell
                    colSpan={12}
                    style={{ minWidth: "120px", paddingLeft: "530px" }}
                  >
                    No Data Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* </Container> */}
        <div>
          <Backdrop
            sx={{
              color: "#092FED",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={isLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      </div>
    </>
  );
}

export default ResponsiveDetailedView;