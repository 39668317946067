import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import Chip from "@material-ui/core/Chip";
import CloseIcon from "@material-ui/icons/Close";
import Avatar from "@material-ui/core/Avatar";
import { useDispatch, useSelector } from "react-redux";
import GlobalMobilityFilter from "./delegationFilterGM.js"
import {
  Typography,
  Box,
  Grid,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  TextField,
  Select,
  Button,
  Tabs,
  Tab,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  getGlobalMobilitySearchEmployeeData,
  getGlobalMobilityViewDelegateData,
  getGlobalMobilitySearchDelegateData,
  postGlobalMobilityAddDelegateData,
  postGlobalMobilityEditDelegateData,
  ResetDeligatinSnakbarGM,
} from "../redux/GMCal/gmAction.js";
import moment from "moment";
import { FieldValidator } from "./validationDelegationGM.js";
import DeligationSnackbars from "./delegationSnackbarGM.js";
import { useLocation } from "react-router";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import history from "../history";
import Encrypt from "../Utils/encryption";
import { useState } from "react";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
  { id: "Employee", numeric: true, disablePadding: false, label: "Employee" },
  { id: "Delegate", numeric: true, disablePadding: false, label: "Delegate" },
  {
    id: "StartDate",
    numeric: true,
    disablePadding: false,
    label: "Start Date",
  },
  { id: "EndDate", numeric: true, disablePadding: false, label: "End Date" },
  { id: "button", numeric: true, disablePadding: false, label: "" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    dropeDownList,
    deligationTableData,
    onFilterArray,
    deligationTableDataList,
  } = props;
  const [filterPopup, setFilterPopup] = React.useState("");
  const [filterAnchor, setFilterAnchor] = React.useState("");
  const [filtersetting, setFilterSetting] = React.useState(false);
  const [filterOffToggle, setFilterOffToggle] = React.useState("");

  const createSortHandler = (property) => (event) => {
    ////console.log("event", event);
    //console.log("event", property);

    onRequestSort(event, property);
  };

  const handleFilterPopup = (event) => {
    //console.log("in the filter function");
    setFilterSetting(!filtersetting);
    setFilterAnchor(event.currentTarget);
  };

  const handleFilterToggle = () => {
    setFilterSetting(false);
  };
  const handleClearFilter = () => {
    //console.log("in thehandleClearFilter");
    onFilterArray(deligationTableDataList);
  };
  const handleApplyFilter = (type, value1, value2) => {
    let dateArray = [];
    if (type == "Employee") {
      //console.log("in the Employee filter");

      dateArray = deligationTableData?.filter((item) =>
        item?.empName?.includes(value1?.empName)
      );
      //console.log("filtered array", dateArray);
    } else if (type == "Delegate") {
      //console.log("deligate value", value1);
      dateArray = deligationTableData?.filter((item) =>
        item?.delName?.includes(value1?.empName)
      );
    } else if (type == "Start Date") {
      const date = moment(value1).format();
      const date2 = date.split("+");
      //console.log("Start Date", date2[0]);
      dateArray = deligationTableData?.filter((item) =>
        item?.startDate?.includes(date2[0])
      );
    } else if (type == "End Date") {
      if (value1 !== null) {
        const date = moment(value1).format();
        const date2 = date.split("+");
        //console.log(" End Date", date2[0]);
        dateArray = deligationTableData?.filter((item) =>
          item?.endDate?.includes(date2[0])
        );
      }
    }

    //console.log("array", dateArray);
    onFilterArray(dateArray);
    setFilterOffToggle(type);
  };
  const handleFilterOff = () => {
    onFilterArray(deligationTableDataList);
    setFilterOffToggle(false);
  };

  return (
    <TableHead className={classes.header}>
      <TableRow style={{ height: 58 }}>
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            className={classes.nonSticky}
            style={{
              minWidth: "135px",
            }}
          >
            {/* {headCell.label} */}
            <Box>
              <Typography
                className={classes.headerName}
                style={{ display: "flex" }}
              >
                {headCell.id !== "button" ? headCell.label : ""}
                {headCell.id == "Employee" ? (
                  filterOffToggle == "Employee" ? (
                    <IconButton
                      aria-label="filter list"
                      onClick={handleFilterOff}
                      style={{ color: "#BB271A", padding: "5px" }}
                    >
                      <span class="material-symbols-outlined" color="#BB271A">
                        filter_alt_off
                      </span>
                    </IconButton>
                  ) : (
                    <Box>
                      <IconButton
                        aria-label="filter list"
                        onClick={(event) => {
                          handleFilterPopup(event);
                          setFilterPopup(headCell.label);
                        }}
                        style={{
                          color: "#666666",
                          padding: "5px",
                          size: "small",
                        }}
                      >
                        <span class="material-symbols-outlined">
                          filter_alt
                        </span>
                      </IconButton>
                    </Box>
                  )
                ) : headCell.id == "Delegate" ? (
                  filterOffToggle == "Delegate" ? (
                    <IconButton
                      aria-label="filter list"
                      onClick={handleFilterOff}
                      style={{ color: "#BB271A", padding: "5px" }}
                    >
                      <span class="material-symbols-outlined" color="#BB271A">
                        filter_alt_off
                      </span>
                    </IconButton>
                  ) : (
                    <Box>
                      <IconButton
                        aria-label="filter list"
                        onClick={(event) => {
                          handleFilterPopup(event);
                          setFilterPopup(headCell.label);
                        }}
                        style={{
                          color: "#666666",
                          padding: "5px",
                          size: "small",
                        }}
                      >
                        <span class="material-symbols-outlined">
                          filter_alt
                        </span>
                      </IconButton>
                    </Box>
                  )
                ) : headCell.id == "StartDate" ? (
                  filterOffToggle == "Start Date" ? (
                    <IconButton
                      aria-label="filter list"
                      onClick={handleFilterOff}
                      style={{ color: "#BB271A", padding: "5px" }}
                    >
                      <span class="material-symbols-outlined" color="#BB271A">
                        filter_alt_off
                      </span>
                    </IconButton>
                  ) : (
                    <Box>
                      <IconButton
                        aria-label="filter list"
                        onClick={(event) => {
                          handleFilterPopup(event);
                          setFilterPopup(headCell.label);
                        }}
                        style={{
                          color: "#666666",
                          padding: "5px",
                          size: "small",
                        }}
                      >
                        <span class="material-symbols-outlined">
                          filter_alt
                        </span>
                      </IconButton>
                    </Box>
                  )
                ) : headCell.id == "EndDate" ? (
                  filterOffToggle == "End Date" ? (
                    <IconButton
                      aria-label="filter list"
                      onClick={handleFilterOff}
                      style={{ color: "#BB271A", padding: "5px" }}
                    >
                      <span class="material-symbols-outlined" color="#BB271A">
                        filter_alt_off
                      </span>
                    </IconButton>
                  ) : (
                    <Box>
                      <IconButton
                        aria-label="filter list"
                        onClick={(event) => {
                          handleFilterPopup(event);
                          setFilterPopup(headCell.label);
                        }}
                        style={{
                          color: "#666666",
                          padding: "5px",
                          size: "small",
                        }}
                      >
                        <span class="material-symbols-outlined">
                          filter_alt
                        </span>
                      </IconButton>
                    </Box>
                  )
                ) : null}
              </Typography>
            </Box>
          </TableCell>
        ))}
        {filterPopup !== "" && (
          <GlobalMobilityFilter
            filtersetting={filtersetting}
            filterAnchor={filterAnchor}
            filterType={filterPopup}
            // handleStartDate={handleStartDate}
            // handleEndDate={handleEndDate}
            handleApplyFilter={handleApplyFilter}
            handleClearFilter={handleClearFilter}
            // myExpenseData={myExpenseData}
            dropeDownList={dropeDownList}
            handleFilterToggle={handleFilterToggle}
          />
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "hidden",
    "& .MuiTableCell-root": {
      paddingRight: "6px",
    },
  },
  paper: {
    width: "100%",
    border: "1px solid #979797",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    // maxWidth: "1200px",
    borderRadius: "8px",
    "&.MuiTableCell-root": {
      padding: "0px",
    },
  },
  table: {
    // maxWidth: "1200px",
    "&.MuiTableCell-root": {
      padding: "0px",
    },
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  formControl: {
    margin: theme.spacing(1),

    width: "170px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  avatar: {
    maxWidth: theme.spacing(3),
    maxHeight: theme.spacing(3),
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  shortName: {
    borderRadius: "45px",
    background: "rgba(79, 163, 125, 0.2)",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    width: "29px",
    height: "23px",
    textAlign: "center",
    color: "#FFFFFF",
    marginLeft: "6px",
  },
  description: {
    marginLeft: "8px",
  },
  fullName: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    color: "#000000",
  },
  designation: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "9px",
    color: "#000000",
  },
  addExpenseButton: {
    height: "42px",
    width: "180px",
    background: " #0B1941",
    boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    borderRadius: "5px",
    "&:hover": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
    "&:focus": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
  },
  saveButton: {
    height: "36px",
    width: "70px",
    background: " #0B1941",
    boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    borderRadius: "6px",
    "&:hover": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
    "&:focus": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
  },
  buttonText: {
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 500,
  },
  addButtonGrid: {
    display: "flex",
    justifyContent: "end",
    paddingRight: "60px",
  },
  addDelText: {
    color: "rgb(43, 45, 48)",
    display: "flex",
    marginTop: "5px",
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 600,
  },
  buttonGrid: {
    display: "flex",
    justifyContent: "end",
    marginTop: "32px",
    paddingRight: "32px",
  },
  tableRow: {
    height: 58,
    "&.MuiTableRow-root.MuiTableRow-hover:hover": {
      backgroundColor: "#EFF2F8",
    },
    "&.MuiTableRow-root.Mui-selected": {
      background: "#EFF2F8",
    },
  },
  inputTabeRow: {
    background: "#F0F6FB",
    border: "2px solid #71BBE8",
    borderRadius: "0px",
    height: 58,
    "&.MuiTableRow-root.MuiTableRow-hover:hover": {
      backgroundColor: "#EFF2F8",
    },
    "&.MuiTableRow-root.Mui-selected": {
      background: "#EFF2F8",
    },
  },
  header: {
    background: "#F4F4F4",
    borderRadius: "8px 8px 0px 0px",
    maxWidth: "1200px",
    maxHeight: "58px",
  },
  headerName: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "700",
    color: "rgba(9, 10, 11, 0.87)",
    alignItems: "center",
  },
  nonSticky: {
    position: "sticky",
    left: 0,
    background: "#F4F4F4",
    zIndex: 800,
  },
  DropeDown: {
    width: "284px",
  },
  DropeDown2: {
    width: "284px",
  },
  errorText: {
    color: "#CB444A",
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: 0,
    backgroundColor: "#F8F9FF",
    //paddingRight: "99px",
    marginRight:"99px",
  },
  errorRoot: {
    color: "#CB444A",
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: 0,
    backgroundColor: "#F8F9FF",
  },
}));

export default function EnhancedTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [personName, setPersonName] = React.useState("");
  const [deligationName, setDeligationName] = React.useState("");
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [toggleRow, setToggleRow] = React.useState(false);
  const [deligationTableData, setDeligationTableData] = React.useState([]);
  const [deligationDropeDownTableData, setDeligationDropeDownTableData] =
    React.useState([]);
  const [employeeDropeDownTableData, setEmployeeDropeDownTableData] =
    React.useState([]);
  const [validatonMsg, setValidationMsg] = React.useState([]);
  const [employeeMsg, setEmployeeMsg] = React.useState("");
  const [deligateMsg, setDeligateMsg] = React.useState("");
  const [startDateMsg, setStartDateMsg] = React.useState("");
  const [endDateMsg, setEndDateMsg] = React.useState("");

  const [valiDateDate, setValiDateDate] = React.useState(false);
  const [valiDateEndDate, setValiDateEndDate] = React.useState(false);

  const [editData, setEditData] = React.useState("");
  const [isSnakbar, setIsSnakbar] = React.useState(false);
  const [isSnakbarMsg, setIsSnakbarMsg] = React.useState("");
  const [currentIndex, setCurrentIndex] = React.useState();
  const [toggleEditRow, setToggleEditRow] = React.useState(false);
  const [firstIteration, setFirstIteration] = useState(true);

  const dispatch = useDispatch();

  const getDeligation = useSelector(
    (state) => state?.gmCalData?.getGlobalMobilityViewDelegateData
  );
  const getDeligationDropeDown = useSelector(
    (state) => state?.gmCalData?.getGlobalMobilitySearchDelegateData
  );

  const getEmployeeDropeDown = useSelector(
    (state) => state?.gmCalData?.getGlobalMobilitySearchEmployeeData
  );

  const isPostGlobalMobilityAddDelegateCheck = useSelector(
    (state) => state?.gmCalData?.isPostGlobalMobilityAddDelegate
  );

  const isPostGlobalMobilityEditDelegateListCheck = useSelector(
    (state) => state?.gmCalData?.isPostGlobalMobbilityEditDelegate
  );

  const location = useLocation();
  useEffect(() => {
    dispatch(ResetDeligatinSnakbarGM());
  }, []);

  useEffect(() => {
    if (isPostGlobalMobilityAddDelegateCheck) {
      setIsSnakbar(true);
      setIsSnakbarMsg("Deligate added successfully");
      dispatch(ResetDeligatinSnakbarGM());
      dispatch(getGlobalMobilityViewDelegateData());
    }
  }, [isPostGlobalMobilityAddDelegateCheck]);

  useEffect(() => {
    //getGlobalMobilityViewDelegateData
    if (isPostGlobalMobilityEditDelegateListCheck) {
      setIsSnakbar(true);
      setIsSnakbarMsg("Deligate updated successfully");
      dispatch(ResetDeligatinSnakbarGM());
      dispatch(getGlobalMobilityViewDelegateData());
      dispatch(getGlobalMobilitySearchEmployeeData());
    }
  }, [isPostGlobalMobilityEditDelegateListCheck]);


  useEffect(() => {
    dispatch(getGlobalMobilitySearchDelegateData());
    dispatch(getGlobalMobilityViewDelegateData());
    dispatch(getGlobalMobilitySearchEmployeeData());
  }, []);

  useEffect(() => {
    if (getDeligation !== null && getDeligation?.length > 0) {
      setDeligationTableData(getDeligation);
    }
  }, [getDeligation]);

  useEffect(() => {
    if (getDeligationDropeDown !== null) {
      setDeligationDropeDownTableData(getDeligationDropeDown);
    }
  }, [getDeligationDropeDown]);

  useEffect(() => {
    if (getEmployeeDropeDown !== null) {
      setEmployeeDropeDownTableData(getEmployeeDropeDown);
    }
  }, [getEmployeeDropeDown]);

  const handleStartDateChange = (type, date) => {
    setValiDateDate(false);
    const presentDate = new Date();
    const difference = presentDate - type;
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    if (TotalDays > 1) {
      setValiDateDate(true);
      setStartDate(date);
    } else {
      setStartDate(date);
    }
  };

  const handleEndDateChange = (type, date) => {
    //console.log("test", personName === deligationName);
    setValiDateEndDate(false);
    setEndDateMsg("");
    if (startDate !== null) {
      var edy = date.split("/");
      // let TotalDays = Math.ceil((endDate-StartDate) / (1000 * 60 * 60 * 24));
      const eDate = new Date(edy[2], edy[0] - 1, edy[1]);
      var sdy = startDate.split("/");
      const sDate = new Date(sdy[2], sdy[0] - 1, sdy[1]);
      let result = Math.round((eDate - sDate) / (1000 * 60 * 60 * 24));
      //console.log("TOtalDayssss", result);
      if (result < 0) {
        setValiDateEndDate(true);
        setEndDate(date);
      } else {
        setEndDate(date);
      }
    } else {
      setEndDate(date);
    }

  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = deligationTableData?.map((n) => n.id);
      setSelected(...selected, newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //   const handleChangeDense = (event) => {
  //     setDense(event.target.checked);
  //   };

  const Array = [
    {
      validationType: "Employee",
      value: personName,
    },

    {
      validationType: "Deligation",
      value: deligationName,
    },
    {
      validationType: "StartDate",
      value: startDate,
      claim: valiDateDate,
    },
    {
      validationType: "EndDate",
      value: endDate,
      claim: valiDateEndDate,
      validateList: startDate,
    },
  ];

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, deligationTableData?.length - page * rowsPerPage);

  const handleChange = (event, value) => {
    if (value == null) {
      setPersonName("");
    } else {
      setPersonName(value);
    }
  };

  const handleList = (option) => {
    return (
      <>
        <Box style={{ display: "flex", marginTop: "16px" }}>
          <div className={classes.description}>
            <Typography component="div" className={classes.fullName}>
              {option.empName}
            </Typography>
            <Typography component="div" className={classes.designation}>
              {option.designation} | {option.empCode}
            </Typography>
            <Typography component="div" className={classes.designation}>
              {option.emailId}
            </Typography>
          </div>
        </Box>
      </>
    );
  };
  const handleDelete = (name) => {
    setPersonName("");
  };
  const handleChange2 = (event, value) => {
    if (value == null) {
      setDeligationName("");
    } else {
      setDeligationName(value);
    }
  };

  const handleList2 = (option) => {
    return (
      <>
        <Box style={{ display: "flex", marginTop: "16px" }}>
          <div className={classes.description}>
            <Typography component="div" className={classes.fullName}>
              {option.empName}
            </Typography>
            <Typography component="div" className={classes.designation}>
              {option.empCode} | {option.empDesignation} | RM :{" "}
              {option.reportingManager} | {option.horizontal}
            </Typography>
          </div>
        </Box>
      </>
    );
  };

  //console.log("delegatePerson", deligationName);
  //console.log("PersonName", personName);

  const handleRowDelete = () => {
    setToggleRow(false);
    setToggleEditRow(false);
    setPersonName("");
    setDeligationName("");
    setStartDate(null);
    setEndDate(null);
    setEndDateMsg("");
    setStartDateMsg("");
    setEmployeeMsg("");
    setDeligateMsg("");
  };

  const handleAddDeligation = () => {
    setToggleRow(true);
    setPersonName("");
    setDeligationName("");
    setStartDate(null);
    setEndDate(null);
    setEditData("");
    setToggleEditRow(false);
  };

  useEffect(() => {
    // console.log("StartEndDate", startDate, endDate);
    if(endDate == undefined){
      setStartDate(null);
      setEndDate(null);
    }
  }, [endDate])

  const displayEmployeeRow = (option) => {
    //console.log("option of employee row", option);
    return (
      <>
        <Box style={{ display: "flex", marginTop: "16px" }}>
          <div className={classes.description}>
            <Typography component="div" className={classes.fullName}>
              {option.empName}
            </Typography>
            <Typography component="div" className={classes.designation}>
              {option.empDesignation}| {option.empCode}
              {/* | RM :{" "}
              {option.reportingManager} | {option.horizontal} */}
            </Typography>
          </div>
        </Box>
      </>
    );
  };

  const displayDeligateRow = (option) => {
    //console.log("option of employee row", option);
    return (
      <>
        <Box style={{ display: "flex", marginTop: "16px" }}>
          <div className={classes.description}>
            <Typography component="div" className={classes.fullName}>
              {option.delName}
            </Typography>
            <Typography component="div" className={classes.designation}>
              {option.delDesignation}| {option.delCode}
            </Typography>
          </div>
        </Box>
      </>
    );
  };
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 400,
  });
  //console.log("location?.state?.empId: DT1  ", location?.state?.empId);
  useEffect(() => {
    validatonMsg.map((value) => {
      if (value.type === "Employee") {
        setEmployeeMsg(value.message);
      } else if (value.type === "Deligation") {
        setDeligateMsg(value.message);
      } else if (value.type === "StartDate") {
        setStartDateMsg(value.message);
      } else if (value.type === "EndDate") {
        setEndDateMsg(value.message);
      }
    });
  }, [validatonMsg]);

  const handleSave = () => {
    const result = Array?.map((feild) =>
      FieldValidator(
        feild.validationType,
        feild.value,
        feild.claim,
        feild.validateList,
        feild.validateList2
      )
    );

    setValidationMsg(result);
    let arr = result.map((i) => i.bool);
    let checker = arr.every((element) => element === true);
    const nameValidate = deligationName?.empName === personName?.empName;
    if (checker && !nameValidate) {

      let formData = new FormData();
      var key = process.env.REACT_APP_ENCRYPTION_KEY;

      formData.append("EmpId", Encrypt(key, JSON.stringify(personName?.empId)));
      formData.append("DelId", Encrypt(key, JSON.stringify(deligationName?.empId)));
      formData.append("StartDate", startDate);
      formData.append("EndDate", endDate == null ? ""  : endDate)
      formData.append("CreatedBy", location?.state?.empId);

      dispatch(postGlobalMobilityAddDelegateData(formData));
      setToggleRow(false);
      setToggleEditRow(false);
    }
  };

  const handleUpdate = () => {
    const result = Array?.map((feild) =>
      FieldValidator(
        feild.validationType,
        feild.value,
        feild.claim,
        feild.validateList,
        feild.validateList2
      )
    );
    //console.log("validate result", result);

    setValidationMsg(result);
    let arr = result.map((i) => i.bool);
    let checker = arr.every((element) => element === true);
    if (checker) {
      let formData = new FormData();
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      //let encryptedPara1 = Encrypt(key, payload?.pId);

      let empDelUniqueId = Number(editData?.empDelUniqueId);
      // console.log(
      //   "diff2 EmpDelUniqueId",
      //   editData?.empDelUniqueId,
      //   location?.state?.empId,
      //   empDelUniqueId
      // );
      formData.append("RecId", Encrypt(key, JSON.stringify(empDelUniqueId)));
      formData.append("StartDate", startDate ? startDate : "");
      formData.append("EndDate", endDate ? endDate : "");
      formData.append("UpdatedBy", location?.state?.empId);

      dispatch(postGlobalMobilityEditDelegateData(formData));
      // setDeligationTableData(getDeligation);

      // setPage(0);
      setToggleRow(false);
      setToggleEditRow(false);
    }
  };

  const onFilterArray = (value) => {
    setDeligationTableData(value);
    setPage(0);
  };

  const handleEdit = (data, index) => {
    //handleRowDelete
    setFirstIteration(false);
    //console.log("rowdata1handleEdit", data , index ,toggleRow, editData,toggleEditRow,currentIndex );
    setToggleRow(false);
    setEditData(data);
    setToggleEditRow(true);
    setCurrentIndex(index);
  };
  //const [firstIteration , setFirstIteration] = useState()
  useEffect(() => {
    if (editData !== "" || firstIteration == false) {
    //console.log("editdata", editData);
      
      const employeEdit = deligationDropeDownTableData?.filter(
        (val) => val.empName === editData?.empName
      );
      //console.log("employeEdit1", employeEdit);
      setPersonName(employeEdit[0]);
      const deligateEdit = deligationDropeDownTableData?.filter(
        (val) => val.empName === editData?.delName
      );
      //console.log("employeEdit", deligateEdit);
      setDeligationName(deligateEdit[0]);
      setStartDate(moment(editData?.startDate).format("l"));
      if (editData?.endDate == null) {
        setEndDate(editData?.endDate);
      } else {
        setEndDate(moment(editData?.endDate).format("l"));
      }
    }

    //setEditData(""); 
    // setPersonName("");
    // setDeligationName("");
    // setEndDate(null);
    // setStartDate(null);
  }, [toggleEditRow , editData, firstIteration]);

  return (
    <div className={classes.root}>
      {/* <Grid item xs={6} sm={12} md={12} className={classes.buttonGrid}> */}
      <Grid container style={{ paddingTop: "40px" }}>
        <Grid item xs={6} sm={6} md={6}>
          <Box style={{ paddingLeft: "30px", display: "flex" }}>
            <IconButton>
              <ArrowBackIosIcon
                onClick={() => {
                  history.push("/gmdashboard");
                }}
              />
            </IconButton>
            <Typography className={classes.addDelText}>
              Add Delegation
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} md={6} className={classes.addButtonGrid}>
          <Box>
            <Button
              className={classes.addExpenseButton}
              onClick={() => handleAddDeligation()}
            >
              <Typography className={classes.buttonText}>
                + Add Delegate
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
      {isSnakbar && (
        <Box>
          {" "}
          <DeligationSnackbars
            open={isSnakbar}
            msg={isSnakbarMsg}
            onClose={() => {
              setIsSnakbar(false);
              setIsSnakbarMsg("");
            }}
          />
        </Box>
      )}

      <Box
        style={{ marginLeft: "44px", marginTop: "24px", marginRight: "44px" }}
      >
        <Paper elevation={0} className={classes.paper}>
          <TableContainer className={classes.tableContainer}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected?.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={deligationTableData?.length}
                dropeDownList={deligationDropeDownTableData}
                deligationTableData={deligationTableData}
                deligationTableDataList={getDeligation}
                onFilterArray={onFilterArray}
              />
              <TableBody>
                {toggleRow && (
                  <TableRow className={classes.inputTabeRow}>
                    <TableCell align="right">
                      
                      {employeeDropeDownTableData && (
                        <Autocomplete
                          className={classes.DropeDown}
                          filterOptions={filterOptions}
                          //   multiple
                          disabled={editData !== "" ? true : false}
                          id="checkboxes-tags-demo"
                          options={employeeDropeDownTableData}
                          value={personName}
                          debug
                          onChange={handleChange}
                          getOptionLabel={(option) => option.empName}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              {handleList(option)}
                            </React.Fragment>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Employee"
                              style={{ backgroundColor: "white" }}
                              placeholder="Search Employee"
                            />
                          )}
                        />
                      )}
                      <span>
                        <Typography
                          variant="p"
                          style={{ fontSize: "13px" }}
                          className={classes.errorText}
                        >
                          {(personName == "" &&
                            typeof personName === "string") ||
                            personName == null
                            ? employeeMsg
                            : ""}
                        </Typography>
                      </span>
                    </TableCell>
                    <TableCell align="right">
                      <Autocomplete
                        // multiple
                        disabled={editData !== "" ? true : false}
                        className={classes.DropeDown2}
                        filterOptions={filterOptions}
                        id="checkboxes-tags-demo2"
                        options={deligationDropeDownTableData}
                        value={deligationName}
                        debug
                        onChange={handleChange2}
                        getOptionLabel={(option) => option.empName}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>{handleList(option)}</React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Delegate"
                            style={{ backgroundColor: "white" }}
                            placeholder="Search Delegate"
                          />
                        )}
                      />
                      <span>
                        <Typography
                          variant="p"
                          style={{ fontSize: "13px" }}
                          className={classes.errorText}
                        >
                          {(deligationName == "" &&
                            typeof deligationName === "string") ||
                            deligationName == null
                            ? deligateMsg
                            : deligationName.empName === personName.empName
                              ? "Delegate name shouldn't same as Employee"
                              : ""}
                        </Typography>
                      </span>
                    </TableCell>
                    <TableCell style={{ paddingTop: "0px" }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          disabled={editData !== "" ? true : false}
                          inputVariant="outlined"
                          className={classes.formControl}
                          style={{
                            backgroundColor: "#FFFFFF",
                            //   width:"80px"
                          }}
                          variant="inline"
                          autoOk={true}
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          label="Start Date*"
                          value={startDate}
                          onChange={handleStartDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          helperText={
                            startDate === null
                              ? startDateMsg
                              : valiDateDate
                                ? startDateMsg
                                : ""
                          }
                          FormHelperTextProps={{
                            classes: {
                              root: classes.errorRoot,
                            },
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </TableCell>

                    <TableCell style={{ paddingTop: "0px" }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          inputVariant="outlined"
                          className={classes.formControl}
                          style={{
                            backgroundColor: "#FFFFFF",
                            //   width:"50%"
                          }}
                          variant="inline"
                          autoOk={true}
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          label="End Date*"
                          minDate={startDate}
                          value={endDate}
                          onChange={handleEndDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          // helperText={
                          //   valiDateEndDate
                          //     ? endDateMsg
                          //     : "" || startDate !== null
                          //     ? endDateMsg
                          //     : ""
                          // }
                          helperText={
                            endDate === null
                              ? endDateMsg
                              : valiDateEndDate
                                ? endDateMsg
                                : "" || startDate !== null
                                  ? endDateMsg
                                  : ""
                          }
                          FormHelperTextProps={{
                            classes: {
                              root: classes.errorRoot,
                            },
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </TableCell>
                    {/* <DeligationDropeDown/> */}
                    <TableCell
                      align="right"
                      style={{ paddingRight: "10px", paddingBottom: "23px" }}
                    >
                      <Box style={{ display: "flex" }}>
                        <Button
                          className={classes.saveButton}
                          onClick={
                            editData !== ""
                              ? () => handleUpdate()
                              : () => handleSave()
                          }
                        >
                          <Typography
                            className={classes.buttonText}
                          // onClick={handleRowSave}
                          >
                            {"Save"}
                          </Typography>
                        </Button>
                        <div
                          style={{
                            marginTop: "8px",
                            paddingLeft: "12px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            style={{ color: "#0B1941" }}
                            onClick={handleRowDelete}
                            class="material-symbols-outlined"
                          >
                            {"delete"}
                          </span>
                        </div>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}

                {stableSort(deligationTableData, getComparator(order, orderBy))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <>
                        {toggleEditRow && index === currentIndex && (
                          <TableRow className={classes.inputTabeRow}>
                            <TableCell align="right">
                              {employeeDropeDownTableData && (
                                <Autocomplete
                                  className={classes.DropeDown}
                                  filterOptions={filterOptions}
                                  //   multiple
                                  disabled={editData !== "" ? true : false}
                                  id="checkboxes-tags-demo"
                                  options={employeeDropeDownTableData}
                                  value={personName}
                                  disableCloseOnSelect
                                  onChange={handleChange}
                                  getOptionLabel={(option) => option.empName}
                                  renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                      {handleList(option)}
                                    </React.Fragment>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Employee"
                                      style={{ backgroundColor: "white" }}
                                      placeholder="Search Employee"
                                    />
                                  )}
                                />
                              )}
                              <span>
                                <Typography
                                  variant="p"
                                  style={{ fontSize: "13px" }}
                                  className={classes.errorText}
                                >
                                  {(personName == "" &&
                                    typeof personName === "string") ||
                                    personName == null
                                    ? employeeMsg
                                    : ""}
                                </Typography>
                              </span>
                            </TableCell>
                            <TableCell align="right">
                              <Autocomplete
                                // multiple
                                disabled={editData !== "" ? true : false}
                                className={classes.DropeDown2}
                                filterOptions={filterOptions}
                                id="checkboxes-tags-demo2"
                                options={deligationDropeDownTableData}
                                value={deligationName}
                                disableCloseOnSelect
                                onChange={handleChange2}
                                getOptionLabel={(option) => option.empName}
                                renderOption={(option, { selected }) => (
                                  <React.Fragment>
                                    {handleList(option)}
                                  </React.Fragment>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Delegate"
                                    style={{ backgroundColor: "white" }}
                                    placeholder="Search Delegate"
                                  />
                                )}
                              />
                              <span>
                                <Typography
                                  variant="p"
                                  style={{ fontSize: "13px" }}
                                  className={classes.errorText}
                                >
                                  {(deligationName == "" &&
                                    typeof deligationName === "string") ||
                                    deligationName == null
                                    ? deligateMsg
                                    : deligationName?.empName ===
                                      personName?.empName
                                      ? "Delegate name shouldn't same as Employee"
                                      : ""}
                                </Typography>
                              </span>
                            </TableCell>
                            <TableCell style={{ paddingTop: "0px" }}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  disableToolbar
                                  disabled={editData !== "" ? true : false}
                                  inputVariant="outlined"
                                  className={classes.formControl}
                                  style={{
                                    backgroundColor: "#FFFFFF",
                                    //   width:"80px"
                                  }}
                                  variant="inline"
                                  autoOk={true}
                                  format="MM/dd/yyyy"
                                  margin="normal"
                                  id="date-picker-inline"
                                  label="Start Date*"
                                  value={startDate}
                                  onChange={handleStartDateChange}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                  helperText={
                                    startDate === null
                                      ? startDateMsg
                                      : valiDateDate
                                        ? startDateMsg
                                        : ""
                                  }
                                  FormHelperTextProps={{
                                    classes: {
                                      root: classes.errorRoot,
                                    },
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </TableCell>
                            <TableCell style={{ paddingTop: "0px" }}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  disableToolbar
                                  inputVariant="outlined"
                                  className={classes.formControl}
                                  style={{
                                    backgroundColor: "#FFFFFF",
                                    //   width:"50%"
                                  }}
                                  variant="inline"
                                  autoOk={true}
                                  format="MM/dd/yyyy"
                                  margin="normal"
                                  id="date-picker-inline"
                                  label="End Date"
                                  minDate={startDate}
                                  value={endDate}
                                  onChange={handleEndDateChange}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                  helperText={
                                    valiDateEndDate
                                      ? endDateMsg
                                      : "" || startDate !== null
                                        ? endDateMsg
                                        : ""
                                  }
                                  FormHelperTextProps={{
                                    classes: {
                                      root: classes.errorRoot,
                                    },
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </TableCell>
                            {/* <DeligationDropeDown/> */}
                            <TableCell
                              align="right"
                              style={{
                                paddingRight: "10px",
                                paddingBottom: "23px",
                              }}
                            >
                              <Box style={{ display: "flex" }}>
                                <Button
                                  className={classes.saveButton}
                                  onClick={
                                    editData !== ""
                                      ? () => handleUpdate()
                                      : () => handleSave()
                                  }
                                >
                                  <Typography
                                    className={classes.buttonText}
                                  // onClick={handleRowSave}
                                  >
                                    {"Update"}
                                  </Typography>
                                </Button>
                                <div
                                  style={{
                                    marginTop: "8px",
                                    paddingLeft: "12px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <span
                                    onClick={handleRowDelete}
                                    class="material-symbols-outlined"
                                  >
                                    {"close"}
                                  </span>
                                </div>
                              </Box>
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow
                          className={classes.tableRow}
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            align="left"
                            style={{ paddingLeft: "20px" }}
                          >
                            {displayEmployeeRow(row)}
                          </TableCell>
                          <TableCell align="left">
                            {displayDeligateRow(row)}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ paddingLeft: "35px" }}
                          >
                            <Typography
                              component="div"
                              className={classes.fullName}
                            >
                              {moment(row.startDate).format("DD/MM/YYYY")}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ paddingLeft: "35px" }}
                          >
                            <Typography
                              component="div"
                              className={classes.fullName}
                            >
                              {row.endDate !== null
                                ? moment(row.endDate).format("DD/MM/YYYY")
                                : ""}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Box onClick={() => handleEdit(row, index)}>
                              <span class="material-symbols-outlined">
                                edit
                              </span>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={deligationTableData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </div>
  );
}