import CryptoJS from "crypto-js";

const Encrypt = (key, ciphertextB64) => {
  try {
    // console.log(
    //   "getMyDelegateesData 1 encryptedData",
    //   ciphertextB64,
    //   "key",
    //   key
    // );

    var key = CryptoJS.enc.Utf8.parse(key); // Convert into WordArray (using Utf8)

    var iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]); // Use zero vector as IV

    var encrypted = CryptoJS.AES.encrypt(ciphertextB64, key, { iv: iv }); // By default: CBC, PKCS7
    //console.log("getMyDelegateesData 2 encrypted", encrypted);
    let res = encrypted.toString(); // Convert into string (using Utf8)
    return res;
  } catch (error) {
    //console.log("getMyDelegateesData 3 encryptedData error", error);
  }
};
export default Encrypt;
