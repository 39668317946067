import React from "react";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: "0px",
    top: "auto",
    right: "7px",
    bottom: "20px",
    left: "auto",
    position: "fixed",
    backgroundColor: " #0B1941",
  },
  fab1: {
    margin: "0px",
    marginRight:"20px",
    top: "auto",
    right: "7px",
    bottom: "20px",
    left: "auto",
    position: "fixed",
    backgroundColor: " #0B1941",
  },
}));

function MobileFloatingButton() {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const isTimesheetManagementPath =
    location.pathname === "/timesheet-management";

  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );
  const projectListTs = useSelector(
    (state) => state.timesheetData.getTimesheetPurposeList
  );
  const getMyTeamApprovalFlagData = useSelector(
    (state) => state.createExpenseData.getMyTeamApprovalPageFlag
  );
  // console.log("getMyTeamApprovalFlagData button responsive", getMyTeamApprovalFlagData);

  return (
    <>
      {isTimesheetManagementPath ? (
        <> <Fab color="primary" aria-label="add" className={classes.fab1}>
		<AddIcon
		  onClick={() => {
			// console.log("Hi", ssoEmpIdDetails.empId);
			history.push({
				pathname: "/create-Timesheet-Management",
				state: {
					ssoEmpId: ssoEmpIdDetails ? ssoEmpIdDetails.empId : " ",
					compName: "createTimesheet",
				  projListProp: projectListTs,
				},
			  });
		  }}
		/>
	  </Fab> </>
      ) : (
        <>
{/* {getMyTeamApprovalFlagData?.myExpenseTab === 1 && ( */}
          <Fab color="primary" aria-label="add" className={classes.fab}>
            <AddIcon
              onClick={() => {
                // console.log("Hi", ssoEmpIdDetails.empId);
                history.push({
                  pathname: "/create-Expense-Management",
                  state: {
                    ssoEmpId: ssoEmpIdDetails ? ssoEmpIdDetails.empId : " ",
                    compName: "createExpense",
                  },
                });
              }}
            />
          </Fab>
             {/* )} */}
        </>
      )}
    </>
  );
}

export default MobileFloatingButton;
