import React, { useEffect, useRef, useState, useReducer } from "react";
import PropTypes from "prop-types";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  Link,
  Chip,
  Checkbox,
  TableBody, TableCell, Table, TableHead, TableRow, TableContainer,
  Paper,
  Button,
} from "@material-ui/core";
import FormHelperText from '@mui/material/FormHelperText';
import clsx from "clsx";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import { useDispatch, useSelector } from "react-redux";
import { getTeamTimesheetData } from "../redux/TimeSheet/actionTs";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { CheckBox } from "@material-ui/icons";
import { pink } from "@mui/material/colors";
import GetAppIcon from "@material-ui/icons/GetApp";
import { CSVLink } from "react-csv";
import moment from "moment";
import { Divider } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import SearchIcon from '@material-ui/icons/Search';
import { TextField, Container, IconButton } from "@mui/material";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { startOfMonth, endOfMonth } from 'date-fns';
import dayjs from 'dayjs';
import LaunchIcon from "@material-ui/icons/Launch";
import ClearIcon from '@mui/icons-material/Clear';
import { textAlign } from "@material-ui/system";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  {
    id: "icon",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "60px",
  },

  {
    id: "employeeName",
    numeric: false,
    disablePadding: true,
    label: "Employee Name & ID",
    width: "200px",
  },
  {
    id: "billableHours",
    numeric: true,
    disablePadding: true,
    label: "Billable Hours",
    width: "135px",
  },
  {
    id: "nonBillableHours",
    numeric: true,
    disablePadding: true,
    label: "Non- Billable Hours",
    width: "245px",
  },
  {
    id: "approvedHours",
    numeric: true,
    disablePadding: true,
    label: "Approved Hours",
    width: "245px",
  },
  {
    id: "unapprovedHours",
    numeric: true,
    disablePadding: true,
    label: "Unapproved Hours",
    width: "245px",
    paddingRight: "20px",
  },
];

function TeamTimesheetTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    checked,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  // const [checked, setChecked] = React.useState(false);
  // const handleChange = (event) => {
  //   setChecked(event.target.checked);
  // };
  // console.log("event", checked);
  return (
    <TableHead style={{ backgroundColor: "#E3E8F7" }}>
      <TableRow style={{ height: "60px" }}>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell> */}
        {/* <TableCell
          component="th"
          scope="row"
          // component="td"
          padding="none"
          // scope="col-1"
          style={{
            width: "0px",
          }}
        /> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // component="th"
            scope="row"
            style={{ fontWeight: 600, padding: "0px", width: headCell.width ,paddingRight:headCell.paddingRight}}
            align={headCell.numeric ? "right" : "left"}
            // padding={headCell.disablePadding ? "none" : "normal"}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id === "icon" ? (
              //somecode
              <>
                <Checkbox
                  checked={checked}
                  style={{ paddingLeft: "12px" }}
                  checkedIcon={
                    <CheckBoxIcon
                      fontSize="small"
                      className={classes.checkBoxRoot}
                    />
                  }
                  //fontSize="small"
                  onChange={onSelectAllClick}
                  inputProps={{ "aria-label": "select all rows" }}
                  //className={classes.checkBoxRoot}
                  // style={{
                  //   background: "#0B1941",
                  //   color: "white",
                  //   "&$checked": {
                  //     background: "#0B1941",
                  //     color: "white",
                  //   },
                  // }}
                />
              </>
            ) : (
              //somecode
              <>{headCell.label}</>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

TeamTimesheetTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  // rowCount: PropTypes.number.isRequired,
  checked: PropTypes.bool.isRequired,
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    boxShadow: "none",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  statusStyle: {
    borderRadius: "100px",
    width: " 110px",
    height: "24px",
    flexShrink: 0,
  },
  statusStyleContent: {
    textAlign: "center",
    fontFamily: " Montserrat",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: " 0.5px",
    textTransform: "uppercase",
  },
  tooltip: {
    paddingLeft: "2px",
    color: "#9E9E9E",
    maxWidth: "20px",
    maxHeight: "20px",
  },

  tooltipBox: {
    minWidth: "120px",
    left: "auto!important",
    right: 0,
    marginTop: "-11px",
    // top:'-20px!important'
  },
  arrow: {
    left: "auto!important",

    right: 0,
  },
  statusName: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "8px",
    marginRight: "0px",
    // alignItems: "center",
    // paddingLeft: "10px",
    color: "rgba(255, 255, 255, 0.85)",
  },

  tableRow: {
    height: 58,
    "&.MuiTableRow-root.MuiTableRow-hover:hover": {
      backgroundColor: "#EFF2F8",
    },
    "&.MuiTableRow-root.Mui-selected": {
      background: "#EFF2F8",
    },
  },
  tableCell: {
    "&.MuiTableCell-root": {
      padding: "0px",
    },
  },
  checkBoxRoot: {
    background: "#0B1941",
    color: "white",
    "&:checked": {
      background: "#0B1941",
      color: "white",
    },
    auditHistory: {
      fontFamily: "Montserrat",
      fontWeight: 800,
      fontSize: "14px",
      color: "#008CE6",
      paddingTop: "20px",
      textDecoration: "underline",
    },
  },
  //checked: {},
}));
const TeamTimesheet = () => {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [toolAction, setToolAction] = React.useState("");
  const target = useRef(null);
  const [commentData, setCommentData] = React.useState([]);
  const [commentHeading, setCommentHeading] = React.useState("");
  const [currentIndex, setCurrentIndex] = React.useState();
  const history = useHistory();
  const [multiDownload, setMultiDownload] = React.useState([]);
  const [checked, setChecked] = React.useState(false);
  const [headerChecked, setHeaderChecked] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [tempFromDate, setTempFromDate] = useState(null);
  const [tempToDate, setTempToDate] = useState(null);
  const [datesSet, setDatesSet] = useState(false);
  const [employee, setEmployee] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [filteredTeamTimesheet, setFilteredTeamTimesheet] = useState([]);
  const [empSearch, setEmpSearch] = useState(false);
  const [empIdSearch, setEmpIdSearch] = useState(false);
  const [systemLiveDate, setSystemLiveDate] = useState(null);
  const [futureLimitDate, setFutureLimitDate] = useState(null);
  const [toDateError, setToDateError] = useState('');
  const [isLoader, isSetLoader] = useState(true);
  const [chips, setChips] = useState({
    employeeChip: [],
    employeeIdChip: []
  });
  const [tempChips, setTempChips] = useState({
    employeeChip: [],
    employeeIdChip: []
  });
  const [isNewChip, setIsNewChip] = useState(false);
  const [applyFilterClicked, setApplyFilterClicked] = useState(false);
  
  const inputValidation = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValidInput = /^[0-9]*$/.test(keyValue);

    if (!isValidInput) {
      event.preventDefault();
    }
  };

  
  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the default context menu behavior
  };
  
  const handleKeyDown = (e) => {
    if ((e.ctrlKey && e.shiftKey && e.key === 'I') || e.key === 'F12') {
      e.preventDefault(); // Prevent opening developer tools
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // const handleChange = (event) => {
  //   setChecked(event.target.checked);
  // };
  const headers = [
    {
      label: "Employee ID",
      key: "employeeID",
    },
    {
      label: "Employee Name",
      key: "employeeName",
    },
    {
      label: "Billable Hours",
      key: "billableHours",
    },
    {
      label: "Non- Billable Hours",
      key: "nonBillableHours",
    },
    {
      label: "Approved Hours",
      key: "approvedHours",
    },
    {
      label: "Unapproved Hours",
      key: "unapprovedHours",
    },
  ];
  const dispatch = useDispatch();
  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );
  const tsDelEmpIdRedux = useSelector(
    (state) => state?.timesheetData?.tsDelEmpId
  );

  const teamTimesheet = useSelector(
    (state) => state?.timesheetData?.getTeamTimesheets
  );

  useEffect(() => {
    if(teamTimesheet){
      isSetLoader(false);
    }
    else{
      isSetLoader(true);
    }
  }, [teamTimesheet]);

    useEffect(() => {
    if (teamTimesheet && teamTimesheet?.length > 0) {
      setSystemLiveDate(new Date(teamTimesheet[0]?.systemLiveDate));
      setFutureLimitDate(new Date(teamTimesheet[0]?.futureWeekLimitFromDate));

      if(tempFromDate === null){
        setTempFromDate((new Date(teamTimesheet[0]?.systemLiveDate)) < firstDayOfMonth
        ? firstDayOfMonth : (new Date(teamTimesheet[0]?.systemLiveDate)))
      }

      if(tempToDate === null){
        setTempToDate((new Date(teamTimesheet[0]?.systemLiveDate)) < firstDayOfMonth
        ? endOfMonth(new Date(firstDayOfMonth)) : endOfMonth(new Date(teamTimesheet[0]?.systemLiveDate)))
      }
    }
  }, [teamTimesheet])
  
  useEffect(() => {
    if (teamTimesheet && teamTimesheet?.length > 0) {
      setSystemLiveDate(new Date(teamTimesheet[0]?.systemLiveDate));
      setFutureLimitDate(new Date(teamTimesheet[0]?.futureWeekLimitFromDate));
    }
  }, [teamTimesheet])

  useEffect(() => {
    if (teamTimesheet && teamTimesheet?.length > 0) {
      setSystemLiveDate(new Date(teamTimesheet[0]?.systemLiveDate));
      setFutureLimitDate(new Date(teamTimesheet[0]?.futureWeekLimitFromDate));
      
      if(fromDate === null){
        setFromDate((new Date(teamTimesheet[0]?.systemLiveDate)) < firstDayOfMonth
        ? firstDayOfMonth : (new Date(teamTimesheet[0]?.systemLiveDate)))
      }

      if(toDate === null){
        setToDate((new Date(teamTimesheet[0]?.systemLiveDate)) < firstDayOfMonth
        ? endOfMonth(new Date(firstDayOfMonth)) : endOfMonth(new Date(teamTimesheet[0]?.systemLiveDate)))
      }

      if(tempFromDate === null){
        setTempFromDate((new Date(teamTimesheet[0]?.systemLiveDate)) < firstDayOfMonth
        ? firstDayOfMonth : (new Date(teamTimesheet[0]?.systemLiveDate)))
      }

      if(tempToDate === null){
        setTempToDate((new Date(teamTimesheet[0]?.systemLiveDate)) < firstDayOfMonth
        ? endOfMonth(new Date(firstDayOfMonth)) : endOfMonth(new Date(teamTimesheet[0]?.systemLiveDate)))
      }
    }
    else{
      setFromDate(firstDayOfMonth ? firstDayOfMonth : "")
      setToDate(lastDayOfMonth ? lastDayOfMonth : "")
    }
  }, []);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    // console.log("event", event);
    if (event.target.checked) {
      setChecked(true);
      // //console.log('pageNumber', page)
      let filterData = stableSort(
        teamTimesheet,
        getComparator(order, orderBy)
      );
      // console.log("filterData", filterData);
      const newSelecteds = filterData.map((s) => s.empId);
      setSelected(newSelecteds);
      //console.log("selected value", newSelecteds);
      setMultiDownload(filterData);

      return;
    } else {
      setChecked(false);
    }
    setMultiDownload([]);

    setSelected([]);
  };
  const handleClick = (event, name) => {
    //console.log("name", name);
    const selectedIndex = selected.indexOf(name.empId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.empId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      setChecked(false);
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      setChecked(false);
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      setChecked(false);
    }

    if (newSelected.length == teamTimesheet.length) setChecked(true);
    setSelected(newSelected);
    //console.log("selected value", selected);

    const objIndex = multiDownload.findIndex((el) => el.empId == name.empId);
    let newMultiDownload = [];
    if (objIndex >= 0) {
      newMultiDownload = [
        ...multiDownload.filter((el) => el.empId != name.empId),
      ];
    } else {
      newMultiDownload = [...multiDownload, name];
    }
    setMultiDownload(newMultiDownload);
  };
  // const handleDetailsClick=()=>{
  //   const text = "/timesheetviewpage/:id";
  //   const result = text.replace(":id", row.expId);
  //   history.push({
  //     pathname: result,
  //     state: {
  //       // response: row,
  //       // empId: 1,
  //       // compName: "myExpenseListing",
  //     },
  //   });
  // };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const csvLink = {
    headers: headers,
    data: multiDownload,
    filename: `MyTeamTimesheet_${moment(new Date()).format(
      "DDMMYYYY_HHmm"
    )}.csv`,
  };

  let currentDate = new Date();
  let firstDayOfMonth = startOfMonth(currentDate);
  let lastDayOfMonth = endOfMonth(currentDate);


  useEffect(() => {

    if(tempChips.employeeChip.length === 0 && tempChips.employeeIdChip.length === 0)
    {
    setApplyFilterClicked(false);

    let obj = {
    managerEmpId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
    fromDate: '',
    toDate: '',
    empName: [],
    empCode: [],
    };

    dispatch(getTeamTimesheetData(obj));
  }
  }, [ssoEmpIdDetails, tsDelEmpIdRedux, tempChips]);

  useEffect(() => {
    if (employee === '') {
      setEmpSearch(false);
    }
  }, [employee, empSearch, filteredTeamTimesheet, teamTimesheet]);

  
  useEffect(() => {
    if(isNewChip){
        setChips({
          employeeChip: [...tempChips.employeeChip],
          employeeIdChip: [...tempChips.employeeIdChip], 
        });
    }
  }, [isNewChip]);

  useEffect(() => {
    if (employeeId === '') {
      setEmpIdSearch(false);
    }
  }, [employeeId, empIdSearch, filteredTeamTimesheet, teamTimesheet]);
  
  const handleEmployee = (event) => {
    const selectedValue = event.target.value;
    const isDuplicate = tempChips.employeeChip.includes(selectedValue);
    
    const handleEmployeeSubmit = (shouldUpdateState) => {
      if (shouldUpdateState && !isDuplicate) {
        setEmployee(selectedValue);
        setTempChips((prevChips) => ({
          ...prevChips,
          employeeChip: [...prevChips.employeeChip, selectedValue]
        }));
        setIsNewChip(false);
      }
    };
  
    if ((event.key === 'Enter' && selectedValue.length > 0) || (event.type === 'blur' && selectedValue.length > 0)) {
      handleEmployeeSubmit(true);
    } else {
      setEmployee(selectedValue);
    }
  };
  
  const handleEmployeeId = (event) => {
    const selectedValue = event.target.value;
    const isDuplicate = tempChips.employeeIdChip.includes(selectedValue);
    
    const handleEmployeeID = (shouldUpdateState) => {
      if (shouldUpdateState && !isDuplicate) {
        setEmployeeId(selectedValue);
        setTempChips((prevChips) => ({
          ...prevChips,
          employeeIdChip: [...prevChips.employeeIdChip, selectedValue]
        }));
        setIsNewChip(false);
      }
    };
  
    if ((event.key === 'Enter' && selectedValue.length > 0) || (event.type === 'blur' && selectedValue.length > 0)) {
      handleEmployeeID(true);
    } else {
      setEmployeeId(selectedValue);
    }
  };

  

  const handleApplyFilter = () => {
    if(fromDate && toDate && fromDate <= toDate){
      isSetLoader(true);
      setTempFromDate(fromDate);
      setTempToDate(toDate);

      setApplyFilterClicked(true);
      setIsNewChip(true);
      let obj = {
        managerEmpId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
        fromDate: fromDate ? moment(fromDate).format("yyyy-MM-DD") : "",
        toDate: toDate ? moment(toDate).format("yyyy-MM-DD") : "",
        empName: tempChips.employeeChip ? tempChips.employeeChip  : [],
        empCode: tempChips.employeeIdChip ? tempChips.employeeIdChip : [],
      };

      dispatch(getTeamTimesheetData(obj));
    }
  };

  const handleChipDelete = (chipToDelete) => {
    isSetLoader(true);
    setTempChips((prevChips) => {
      const updatedChips = { ...prevChips };
  
      updatedChips.employeeChip = updatedChips.employeeChip.filter(chip => chip !== chipToDelete);
      updatedChips.employeeIdChip = updatedChips.employeeIdChip.filter(chip => chip !== chipToDelete);

      if(fromDate && toDate){
        const obj = {
          managerEmpId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
          fromDate: fromDate ? moment(fromDate).format("yyyy-MM-DD") : "",
          toDate: toDate ? moment(toDate).format("yyyy-MM-DD") : "",
          empName: updatedChips.employeeChip ? updatedChips.employeeChip  : [],
          empCode: updatedChips.employeeIdChip ? updatedChips.employeeIdChip : [],
        };
        dispatch(getTeamTimesheetData(obj));
      }
      return updatedChips;
    });

    setChips((prevChips) => {
      const updatedChips = { ...prevChips };
  
      updatedChips.employeeChip = updatedChips.employeeChip.filter(chip => chip !== chipToDelete);
      updatedChips.employeeIdChip = updatedChips.employeeIdChip.filter(chip => chip !== chipToDelete);

      return updatedChips;
    });

    if(employee === chipToDelete){
      setEmployee('');
    }
    if(employeeId === chipToDelete){
      setEmployeeId('');
    }
  };
  

  const handleClearAll = () => {
    isSetLoader(true);
    setApplyFilterClicked(false);
    firstDayOfMonth = startOfMonth(currentDate);
    lastDayOfMonth = endOfMonth(currentDate);

    setFromDate(firstDayOfMonth < systemLiveDate ? systemLiveDate : firstDayOfMonth)

    setToDate(firstDayOfMonth < systemLiveDate ? endOfMonth(new Date(systemLiveDate)) : endOfMonth(new Date(firstDayOfMonth)));

    setTempChips({
      employeeChip: [],
      employeeIdChip: []
    });

    setChips({
      employeeChip: [],
      employeeIdChip: []
    });
    setEmployee('');
    setEmployeeId('')

    if(firstDayOfMonth && lastDayOfMonth){
      const obj = {
        managerEmpId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
        fromDate: '',
        toDate: '',
        empName: [],
        empCode: [],
      };
      dispatch(getTeamTimesheetData(obj));
    }
  };

  return (
    <div className={classes.root} onContextMenu={handleContextMenu}>

      <Grid container spacing={1} 
        style={{
          display: "flex", 
          marginBottom: "15px",
          alignItems: "center",
          justifyContent: "space-between"
          }}>

        <Grid item xs={1} md={1} >
            <MuiPickersUtilsProvider utils={DateFnsUtils} style={{width:"136px"}}>
                <KeyboardDatePicker
                  disableToolbar
                  inputVariant="outlined"
                  fullWidth
                  style={{
                    marginLeft:"10px",
                    width:"156px",
                   
                  }}
                  variant="inline"
                  size="small"
                  format="MMM d, yy"
                  autoOk={true}
                  minDate={systemLiveDate}
                  maxDate={futureLimitDate}
                  id="date-picker-inline"
                  label={<span style={{color:"#000"}}>From Date*</span>}
                  value={fromDate}
                  onChange={(date) =>
                    setFromDate(date)
                  }
                  inputProps={
                    {
                      style:{fontSize:"14px" , fontWeight:"500"}
                    }
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                    style:{height:"16px" , width:"16px"}
                  }}
                  helperText={
                    toDate != "" && toDate < fromDate
                    ? "*To Date should be greater than From Date"
                    : ""
                  }
                  FormHelperTextProps={{
                    style:{
                      color: "red",
                      paddingTop: "5px",
                      marginLeft: "0px",
                      marginRight: "0px",
                      marginTop: 0,
                      marginBottom: "-24px",
                      width: "max-content"
                         }
                  }}
                  />
              </MuiPickersUtilsProvider>
        </Grid>      
        <Grid item xs={1} md={1} style={{marginLeft:"30px"}}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              inputVariant="outlined"
              fullWidth
              style={{
                marginLeft: "10px",
                width:"156px",
              
              }}
              variant="inline"
              minDate={systemLiveDate}
              maxDate={futureLimitDate}
              size="small"
              format="MMM d, yy"
              autoOk={true}
              id="date-picker-inline"
              label={<span style={{color:"#000"}}>To Date*</span>}
              value={toDate}
              onChange={(date) =>
                setToDate(date)
              }
              inputProps={
                {
                  style:{fontSize:"14px" , fontWeight:"500"}
                }
              }
              KeyboardButtonProps={{
                "aria-label": "change date",
                    style:{height:"16px" , width:"16px"}

              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={2} md={2} style={{marginLeft:"30px"}}>
          <FormControl variant="outlined" fullWidth style={{ marginLeft: "10px" , width:"210px"}}
            >
            <InputLabel
              htmlFor="search-input"
            style={{marginTop:"-5px", fontSize:"12px" , fontWeight:"600" ,  fontFamily: "Montserrat",}}
              
              
            >
              Search by Employee Name
            </InputLabel>
            <OutlinedInput
              id="search-input"
              size="small"
              style={{paddingRight:"5px", height:"40px"}}
              value={employee}
              onChange={handleEmployee}
              onKeyDown={handleEmployee}
              onBlur={handleEmployee}
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon style={{height:"20px" , width:"20px"}}/>
                </InputAdornment>
              }
              label="Search by Employee Name"
            />
          </FormControl>
        </Grid>

        <Grid item xs={2} md={2} style={{marginLeft:"-3px"}}>
        <FormControl variant="outlined" fullWidth style={{ width:"191px"}}>
            <InputLabel 
            htmlFor="search-input"
            style={{marginTop:"-5px", fontSize:"12px" , fontWeight:"600" ,  fontFamily: "Montserrat",}}
           
            >
              Search by Employee Id
            </InputLabel>
            <OutlinedInput
              id="search-input"
              size="small"
              style={{paddingRight:"0px", height:"40px"}}
              value={employeeId}
              onChange={handleEmployeeId}
              onKeyDown={handleEmployeeId}
              onBlur={handleEmployeeId}
              onKeyPress= {inputValidation}
              inputProps={{
                maxLength: 6,
              }}
              endAdornment={
                <InputAdornment position="start">
                    <SearchIcon style={{height:"20px" , width:"20px"}}/>
                </InputAdornment>
              }
              label="Search by Employee Id"
            />
          </FormControl>
        </Grid>
        <Grid item xs={1} md={1}>
          <Box style={{width:"100px" , marginLeft:"-40px"}}>
        <Link
          style={{
            color: "#008CE6",
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "24px",
            letterSpacing: "0px",
            textAlign: "left",
            cursor: "pointer",
           // marginLeft:"10px"
          }}
          underline="always"
          onClick={handleApplyFilter}
        >
          Apply Filter
        </Link>
        </Box>
        </Grid>  
        <Grid item xs={2} md={2}>
          <CSVLink
            {...csvLink}
            style={
              selected.length > 0
                ? {}
                : {
                    color: "transparent",
                    pointerEvents: "none",
                    cursor: "pointer",
                  }
            }
          >
            <Button
              variant="outlined"
              color="primary"
              disabled={selected.length > 0 ? false : true}
              style={{
                whiteSpace: "nowrap",
                //backgroundColor: selected.length > 0 ? "#0B1941" : "#979797",
                textTransform: "unset",
                //  color: "#0B1941",
                border: "1px solid #0B1941",
                color: selected.length > 0 ? "#0B1941" : "#979797",
                // border: "1px solid #979797",
                padding: "8px",
              }}
            >
              <LaunchIcon
                style={{
                  width: "16px",
                  height: "16px",
                  // color: "#FFFFFF",
                  color: selected.length > 0 ? "#043365" : "#979797",
                  //backgroundColor: selected.length > 0 ? "#0B1941" : "#979797",
                }}
              />
              &nbsp;Export Timesheet
              {/* <GetAppIcon
                style={{
                  width: "16px",
                  height: "16px",
                  color: "#FFFFFF",
                  backgroundColor: selected.length > 0 ? "#0B1941" : "#979797",
                }}
              /> */}
              {/* <Typography
                style={{
                  fontSize: "10px",
                  fontWeight: 500,
                  color: "#FFFFFF",
                  backgroundColor: selected.length > 0 ? "#0B1941" : "#979797",
                }}
              >
                Export Timesheet
              </Typography> */}
            </Button>
          </CSVLink>
        </Grid>
      </Grid>

      {applyFilterClicked && (chips.employeeChip.length > 0 || chips.employeeIdChip.length > 0) && 
        <Divider style={{marginTop:"10px"}}/>}
      {applyFilterClicked && <div style={{paddingTop:"10px", paddingBottom:"10px"}}>
        {Object.entries(chips).map(([chipType, chipValue], index) => (
          <React.Fragment key={index}>
            {Array.isArray(chipValue) ? (
              chipValue.map((value, valueIndex) => (
                <Chip
                  key={`${chipType}-${valueIndex}`}
                  label={value}
                  onDelete={() => handleChipDelete(value)}
                  style={{ margin: '5px', backgroundColor: "#fff", color:"#000", fontWeight: "600", border: "1px solid #000"}}
                  deleteIcon={<ClearIcon style={{ color: "#000" , height: "18px", width:"18px"}} />}
                />
              ))
            ) : (
              chipValue && (
                <Chip
                  key={`${chipType}-${chipValue}`}
                  label={chipValue}
                  onDelete={() => handleChipDelete(chipValue)}
                  style={{ margin: '5px', backgroundColor: "#fff", color:"#000", fontWeight: "600", border: "1px solid #000"}}
                  deleteIcon={<ClearIcon style={{ color: "#000" , height: "18px", width:"18px"}} />}
                />
              )
            )}
          </React.Fragment>
        ))}
        {Object.values(chips).some(chipValue => (Array.isArray(chipValue) ? chipValue.length > 0 : chipValue)) && (
          <Link
            style={{
              color: "#008CE6",
              fontFamily: "Montserrat",
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: "24px",
              letterSpacing: "0px",
              textAlign: "left",
              cursor: "pointer",
            }}
            underline="always"
            onClick={handleClearAll}
          >
            Clear All
          </Link>
        )}
      </div>}


      <Divider />
      <Paper className={classes.paper}>
        {/* <MyTimesheetTableToolbar numSelected={selected.length} /> */}
        <TableContainer style={{ overflowX: "clip" }}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <TeamTimesheetTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              checked={checked}
              //rowCount={rows.length}
            />

            <TableBody>
              {teamTimesheet?.length > 0 ? (
                stableSort(teamTimesheet, getComparator(order, orderBy))
                  //.slice(0, 3)
                  ?.map((row, index) => {
                    const isItemSelected = isSelected(row.empId);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        className={classes.tableRow}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.empId}
                        selected={isItemSelected}
                      >
                        <TableCell
                          padding="checkbox"
                          className={classes.tableCell}
                          style={{
                            position: "sticky",
                            left: 0,
                            background: "none",
                            zIndex: 800,
                            minWidth: "53px",
                            padding: "0px",
                            // color:"#EFF2F8",
                          }}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            style={{ paddingLeft: "12px" }}
                            checkedIcon={
                              <CheckBoxIcon
                                fontSize="small"
                                className={classes.checkBoxRoot}
                              />
                            }
                            onClick={(event) => handleClick(event, row)}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell
                          style={{ paddingLeft: "0px" }}
                          className={classes.tableCell}
                          onClick={() => {
                            // console.log("hi table row clicked!");
                            const text = "/timesheetviewpage/:id";
                            const result = text.replace(":id", row.empId);
                            history.push({
                              pathname: result,
                              state: {
                                empId: row.empId,
                                managerId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
                                delegateeId:ssoEmpIdDetails?.empId == tsDelEmpIdRedux ? null :ssoEmpIdDetails?.empId ,
                                fromDate: tempFromDate ? moment(tempFromDate).format("yyyy-MM-DD") : "",
                                toDate: tempToDate ? moment(tempToDate).format("yyyy-MM-DD") : "",
                                projId:"",
                                tab: "teamTimesheet",

                                compName: "DetailedView",
                              },
                            });
                          }}
                        >
                          <Grid container direction="column" spacing={1}>
                            <Grid item>
                              <Typography
                                variant="body1"
                                style={{ fontSize: "14px" }}
                              >
                                {row.employeeName}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="body1"
                                style={{ fontSize: "12px" }}
                              >
                                {row.employeeID}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell
                          style={{ paddingLeft: "40px", textAlign: "right",paddingRight:"16px" }}
                          className={classes.tableCell}
                          onClick={() => {
                            // console.log("hi table row clicked!");
                            const text = "/timesheetviewpage/:id";
                            const result = text.replace(":id", row.empId);
                            history.push({
                              pathname: result,
                              state: {
                                empId: row.empId,
                                managerId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
                                delegateeId:ssoEmpIdDetails?.empId == tsDelEmpIdRedux ? null :ssoEmpIdDetails?.empId ,
                                fromDate: tempFromDate ? moment(tempFromDate).format("yyyy-MM-DD") : "",
                                toDate: tempToDate ? moment(tempToDate).format("yyyy-MM-DD") : "",
                                projId:"",
                                tab: "teamTimesheet",

                                compName: "DetailedView",
                              },
                            });
                          }}
                        >
                          {row?.billableHours?.toFixed(2)}
                        </TableCell>
                        <TableCell
                          style={{ paddingLeft: "111px", textAlign: "right",paddingRight:"16px" }}
                          className={classes.tableCell}
                          onClick={() => {
                            // console.log("hi table row clicked!");
                            const text = "/timesheetviewpage/:id";
                            const result = text.replace(":id", row.empId);
                            history.push({
                              pathname: result,
                              state: {
                                empId: row.empId,
                                managerId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
                                delegateeId:ssoEmpIdDetails?.empId == tsDelEmpIdRedux ? null :ssoEmpIdDetails?.empId ,
                                fromDate: tempFromDate ? moment(tempFromDate).format("yyyy-MM-DD") : "",
                                toDate: tempToDate ? moment(tempToDate).format("yyyy-MM-DD") : "",
                                projId:"",
                                tab: "teamTimesheet",

                                compName: "DetailedView",
                              },
                            });
                          }}
                        >
                          {row?.nonBillableHours?.toFixed(2)}
                        </TableCell>
                        <TableCell
                          style={{ paddingLeft: "133px", textAlign: "right" ,paddingRight:"16px"}}
                          className={classes.tableCell}
                          onClick={() => {
                            // console.log("hi table row clicked!");
                            const text = "/timesheetviewpage/:id";
                            const result = text.replace(":id", row.empId);
                            history.push({
                              pathname: result,
                              state: {
                                empId: row.empId,
                                managerId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
                                delegateeId: ssoEmpIdDetails?.empId == tsDelEmpIdRedux ? null :ssoEmpIdDetails?.empId ,
                                fromDate: tempFromDate ? moment(tempFromDate).format("yyyy-MM-DD") : "",
                                toDate: tempToDate ? moment(tempToDate).format("yyyy-MM-DD") : "",
                                projId:"",
                                tab: "teamTimesheet",

                                compName: "DetailedView",
                              },
                            });
                          }}
                        >
                          {row?.approvedHours?.toFixed(2)}
                        </TableCell>
                        <TableCell
                          style={{ paddingLeft: "114px", textAlign: "right",paddingRight:"16px" }}
                          className={classes.tableCell}
                          onClick={() => {
                            // console.log("hi table row clicked!");
                            const text = "/timesheetviewpage/:id";
                            const result = text.replace(":id", row.empId);
                            history.push({
                              pathname: result,
                              state: {
                                empId: row.empId,
                                managerId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
                                delegateeId:ssoEmpIdDetails?.empId == tsDelEmpIdRedux ? null :ssoEmpIdDetails?.empId ,
                                fromDate: tempFromDate ? moment(tempFromDate).format("yyyy-MM-DD") : "",
                                toDate: tempToDate ? moment(tempToDate).format("yyyy-MM-DD") : "",
                                projId:"",
                                tab: "teamTimesheet",

                                compName: "DetailedView",
                              },
                            });
                          }}
                        >
                          {row?.unapprovedHours?.toFixed(2)}
                        </TableCell>
                        {/* <TableCell
                          onClick={() => {
                            console.log("hi table row clicked!");
                            const text = "/timesheetviewpage/:id";
                            const result = text.replace(":id", row.empId);
                            history.push({
                              pathname: result,
                              state: {
                                empId: row.empId,
                                managerId: ssoEmpIdDetails?.empId,
                                fromDate: fromDate ? moment(fromDate).format("yyyy-MM-DD") : "",
                                toDate: toDate ? moment(toDate).format("yyyy-MM-DD") : "",
                                tab: "teamTimesheet",

                                compName: "DetailedView",
                              },
                            });
                          }}
                        ></TableCell> */}
                      </TableRow>
                    );
                  })
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {teamTimesheet?.length === 0 ? 
        <Grid>
          <Typography
             style={{
              fontSize:"20px",
              lineHeight:"240px",
              textAlign:"center",
              fontWeight: "600",
              }}>
            No Timesheet entry exists!
          </Typography>
        </Grid> : <></>
        }

      <div>
        <Backdrop
          sx={{ color: "#092FED", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>    
    </div>
  );
};

export default TeamTimesheet;