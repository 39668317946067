import React from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import history from "../history";
import { useEffect } from "react";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Snackbars(props) {
  const classes = useStyles();
  //console.log("in snackbar, expenseStatus:==>", props.expenseStatus);
  // useEffect(() => {
  //   setTimeout(() => {
  //     history.push("/expense-Management");
  //   }, 4000);
  // }, []);
  return (
    <div className={classes.root}>
      <Snackbar
        open={props.open}
        autoHideDuration={1500}
        onClose={props.handleSnackbar}
      >
        {props.expenseStatus == "Draft" ? (
          <Alert onClose={props.handleSnackbar} severity="success">
            Expense {props.expenseCode} is saved as Draft !
          </Alert>
        ) : (
          <Alert onClose={props.handleSnackbar} severity="success">
            Expense {props.expenseCode} is created successfully !
          </Alert>
        )}
      </Snackbar>

      {/* <Alert severity="error">This is an error message!</Alert>
      <Alert severity="warning">This is a warning message!</Alert>
      <Alert severity="info">This is an information message!</Alert>  */}
      {/* {/* <Alert severity="success">This is a success message!</Alert> */}
    </div>
  );
}
