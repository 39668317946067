import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React from "react";

const DeleteDialog = (props) => {
  const handleDeleteInDialog = () => {
    props.handleDelete(props.currentExpense);
    props.handleCloseDeleteDialog();
  };

  const handleDeleteDialog = () => {
    handleDeleteInDialog();
    props.responsiveResult == "mobile"
      ? props.handleRemoveFilter()
      : props.handleFilterOff();
  };
  return (
    <div>
      <Dialog
        open={props.openDeleteDialog}
        onClose={props.handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "22px",
              fontWeight: 500,
            }}
          >
            Delete Expense
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are about to the follwing expense:
            <div>
              Expense ID: {props.currentExpense?.expCode}, Project ID:{" "}
              {props.currentExpense?.projectId}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>

          <Button
            onClick={
              handleDeleteDialog
              // handleDeleteInDialog();
              // props.handleFilterOff();
              // props.handleRemoveFilter();
              // console.log(props);
              //change filtershow state
            }
            color="primary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteDialog;
