import React, { useState, useEffect } from "react";
import InfoIcon from "@material-ui/icons/Info";
import { useForm } from "react-hook-form";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import { uploadFile } from "react-s3";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ShowMoreText from "react-show-more-text";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { isValid } from "date-fns";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  makeStyles,
  withStyles,
  Grid,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  TextField,
  Select,
  Link,
  Checkbox,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Popover,
  Slide,
  Snackbar,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DateFnsUtils from "@date-io/date-fns";
import ProjectId from "./multiSelectProject";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAlert from "@material-ui/lab/Alert";
// import NumberInput from "./numberInput";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import SplitBox from "./splitBox";
import DragDrope from "./dragDrope";
import DragDropeTravel from "./dragDropeTravel";
import { FieldValidator } from "./validation";
import {
  getPurposeData,
  getCategoryData,
  getCurrencyData,
  getEmployeeProjectsData,
  getAttendeesData,
  getValidateAttendeesData,
  getPaymentData,
  postCreateExpenseData,
  resetExpenseResponse,
  getMyDelegateesData,
  getEmpDetail,
  postFileUploadData,
  getDependentsData,
  getBillNumberExistenceData,
} from "../redux/Expense/action";
import {
  getRatingDescriptionData,
  postRatingReviewData,
} from "../redux/TimeSheet/actionTs";
import { useDispatch, useSelector } from "react-redux";
import Header from "./header";
import AttendeesBox from "./attendees";
import SnackBar from "./snackbar";
import history from "../history";
import { useLocation } from "react-router";
import { useMsal, useAccount } from "@azure/msal-react";
import { Errors } from "@azure/msal-common";
import DOMPurify, { clearConfig } from "dompurify";
import Encrypt from "../Utils/encryption";
import Divider from "@material-ui/core/Divider";
import { style } from "@material-ui/system";
import { validateDate } from "@mui/x-date-pickers/internals";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import Rating from "@mui/material/Rating";
import Column from "antd/es/table/Column";
window.Buffer = window.Buffer || require("buffer").Buffer;
// const isAnonymous = false;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F8F9FF",
    paddingLeft: "100px",
    paddingRight: "100px",
  },
  rootMobile: {
    backgroundColor: "#F8F9FF",
    paddingLeft: "20px",
    paddingRight: "0",

    overflow: "hidden",
  },
  formControl: {
    margin: "16px 0 8px 0",
    minWidth: "100%",
    borderRadius: 4,
    notchedOutline: {
      borderColor: "red",
      borderRadius: 4,
    },
    "& .MuiFormLabel-root": {
      // ["@media (max-width:600px)"]: { fontSize: "12px", fontWeight: "500" },
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "500",
      color: "#2B2D30",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiInputBase-root": {
      ["@media (max-width:600px)"]: { fontSize: "13px" },
      "&.Mui-focused fieldset": {
        border: "2px solid #162D6E",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#515961",
      },
      "& .MuiSelect-selectMenu": {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "500",
        color: "#2B2D30",
        ["@media (max-width:600px)"]: { fontSize: "13px" },
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#FFFFFF",
      },
    },
  },

  formControl12: {
    margin: "16px 0 8px 0",
    minWidth: "100%",

    borderRadius: 4,
    notchedOutline: {
      borderColor: "red",
      borderRadius: 4,
    },
    "& .MuiFormLabel-root": {
      //["@media (max-width:600px)"]: { fontSize: "13px" },
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "500",
      color: "#2B2D30",
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "2px solid #162D6E",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
      },
      "& .MuiSelect-selectMenu": {
        ["@media (max-width:600px)"]: { fontSize: "13px" },
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "500",
        color: "#2B2D30",
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#FFFFFF",
      },
      "& .MuiOutlinedInput-input": {
        ["@media (max-width:600px)"]: { fontSize: "13px" },
      },
    },
  },
  advancesField: {
    // width: "300px",
    "& .MuiOutlinedInput-input": {
      padding: "14.5px 16px",
    },
    "& .MuiInputLabel-formControl": {
      top: "-3px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -3px) scale(0.75)",
    },
    "& .Mui-disabled": {
      color: "#000000de",
    },
    "& .MuiSelect-icon.Mui-disabled": {
      color: "#0000008a",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dropdownStyle: {
    "& ul": {
      padding: 0,
      border: "0.5px solid #515961",
      boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
      borderRadius: 4,
    },
    "& li": {
      height: 47,
      fontSize: 14,
      color: "#515961",
      "&:hover": {
        backgroundColor: "rgba(217, 235, 247, 0.4);",
        color: "#439DD9",
      },
    },
  },
  selectRoot: {
    borderRadius: 4,
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "#439DD9",
      },
    },
    "&:focus": {
      backgroundColor: "transparent",
      borderRadius: 4,
    },
  },
  purpose: {
    display: "flex",
    justifyContent: "center",
  },
  expenseCategory: {
    display: "flex",
    justifyContent: "center",
  },
  ExpenseName: {
    display: "flex",
    justifyContent: "center",
    borderColor: "#CB444A",
  },
  expenseNameTextField: {
    backgroundColor: "#FCFDFF",
    borderRadius: 4,
  },
  invoiceReciept: {
    display: "flex",
    justifyContent: "center",
  },
  invoiceRecieptDateField: {
    backgroundColor: "#FCFDFF",
    borderRadius: 4,
  },
  projectId: {
    display: "flex",
    justifyContent: "center",
  },
  infoBox: {
    display: "flex",
    justifyContent: "center",
    "& .MuiSvgIcon-root": {
      fill: "#008CE6",
    },
  },
  infoBox1: {
    display: "flex",
    justifyContent: "center",
    "& .MuiSvgIcon-root": {
      fill: "#FFAA33",
    },
  },
  infoBoxMobile: {
    display: "flex",
    justifyContent: "center",
    // paddingRight: "27px",
    "& .MuiSvgIcon-root": {
      fill: "#008CE6",
    },
  },
  currency: {
    display: "flex",
    justifyContent: "center",
  },

  expenseHeading: {
    display: "inline-flex",
    margin: "30px 0px 30px 0px",
    width: "478px",
    height: "24px",
  },
  checkBox: {
    display: "flex",
    alignItems: "center",
    // marginLeft: "-10px",
    // marginTop: "-30px",
  },
  // checkBoxRoot: {
  //   "& .MuiSvgIcon-root": {
  //     color: "#0B1941",
  //   },
  // },
  checkBoxRoot: {
    background: "#0B1941",
    color: "white",
    "&$checked": {
      background: "#0B1941",
      color: "white",
    },
  },
  checkBoxRoot1: {
    "& .MuiSvgIcon-root": {
      color: "#0B1941",
      // "&$checked": {
      //   background: "#0B1941",
      //   color: "white",
      // },
    },
  },
  checkBoxRootMobile: {
    background: "#0B1941",
    color: "white",
    display: "flex",
    flexDirection: "column",
    "&$checked": {
      background: "#0B1941",
      color: "white",
    },
  },
  saveSubmitButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
    paddingBottom: "35px",
  },
  saveSubmitButtonMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "35px",
    paddingRight: "70px",
    paddingLeft: "57px",
  },
  rowContainer: {
    display: "flex",
    justifyContent: "center",
  },
  projectDetailsMainBox: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #9E9E9E",
    borderRadius: "5px",
    padding: "25px",
    // marginTop: "-20px",
  },
  rowContainerMobile: {
    justifyContent: "center",
    flexDirection: "column",
  },
  inputLabels: {
    backgroundColor: "#FFFFFF",
  },
  inputLabels1: {
    backgroundColor: "#FFFFFF",
    fontSize: "15px",
  },
  errorText: {
    color: "red",
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: 0,
    backgroundColor: "#F8F9FF",
    fontFamily: "Montserrat",
  },

  errorRoot: {
    color: "red",
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: 0,
    backgroundColor: "#F8F9FF",
    ["@media (max-width:600px)"]: { fontSize: "10px" },
  },
  projectDetailsText: {
    fontWeight: "600",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: "20px",
    lineHeight: "24.38px",
    letterSpacing: "0.3px",
    textAlign: "left",
    color: "#252733",
  },
  projectDetailsTextMobile: {
    fontWeight: "600",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "-0.16px",
    textAlign: "left",
    color: "#353535",
    display: "flex",
    flexDirection: "column",
  },
  customToolTip: {
    maxWidth: "265px",
    height: "300px",
    overflow: "auto",
  },
  successIcon: {
    color: theme.palette.success.main,
    fontSize: 40,
    minWidth: "100%",
  },
  thankYouText: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
    minWidth: "50px",
  },
  messageText: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    minWidth: "50px",
  },
  ExpandedAccordionTravel: {
    backgroundColor: "#F6F6F6",
    // border: "1px solid #979797",
    "&.MuiAccordionSummary-root": {
      minHeight: "55px",
      maxHeight: "55px",
      "&.Mui-expanded": {
        minHeight: "55px",
        maxHeight: "55px",
      },
    },
  },
}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitleDescDialog = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <HighlightOffIcon style={{ color: "#162D6E" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContentDescDialog = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActionsDescDialog = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ViewReward = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [purpose, setPurpose] = useState("");
  const [purposeError, setPurposeError] = useState("");
  const [expenseCategoryError, setExpenseCategoryError] = useState("");
  const [vendorNameError, setVendorNameError] = useState("");
  const [billNumberError, setBillNumberError] = useState("");
  const [gstError, setGstError] = useState("");
  const [expenseNameError, setExpenseNameError] = useState("");
  const [selectDateError, setSelectDateError] = useState("");
  const [paymentError, setPaymentError] = useState("");
  const [dependentsError, setDependentsError] = useState("");
  const [declarationError, setDeclarationError] = useState("");
  const [currencyError, setCurrencyError] = useState("");
  const [billAmountError, setBillAmountError] = useState("");
  const [claimAmountError, setClaimAmountError] = useState("");
  const [quantityError, setQuantityError] = useState("");

  const [projectError, setProjectError] = useState("");
  const [attendeesError, setAttendeesError] = useState("");
  const [dragDropFileError, setDragDropFileError] = useState("");
  const [projectListSplitBoxError, setProjectListSplitBoxError] = useState("");
  const { register, handleSubmit, watch, errors } = useForm();
  const [expenseCategory, setExpenseCategory] = useState("");
  const [vendorName, setVendorName] = useState(null);
  const [billNumber, setBillNumber] = useState(null);
  const [gst, setGST] = useState(null);
  const [expenseName, setExpenseName] = useState("");
  const [currency, setCurrency] = useState("");
  const [billAmount, setBillAmount] = useState("");
  const [claimAmount, setClaimAmount] = useState("");
  const [splitClaimAmount, setSplitClaimAmount] = useState("");
  const [expenseCategoryChanged, setExpenseCategoryChanged] = useState(false);
  const [payment, setPayment] = useState("");
  const [dependents, setDependents] = useState(0);
  const [quantity, setQuantity] = useState("");
  const [project, setproject] = useState([]);
  // const [isInvoiceDateDisabled, setIsInvoiceDateDisabled] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [validateQuarter, setValidateQuarter] = useState(false);
  const [validateJoiningDate, setValidateJoiningDate] = useState(false);
  const [lastDate, setLastDate] = useState(null);
  const [attendees, setAttendees] = useState([]);
  const [empAmounts, setEmpAmounts] = useState("");
  const [declarationCheckBox, setDeclarationCheckBox] = useState(false);
  const [valiDateDate, setValiDateDate] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([
    {
      projectName: { projectID: "", projName: "" },
      splitAmount: "",
      billable: 0,
    },
  ]);
  const [travelDestinationMsg, setTravelDestinationMsg] = useState("");
  const [travelstdtMsg, setTravelstdtMsg] = useState("");
  const [travelendtMsg, setTravelendtMsg] = useState("");
  const [travelTypeMsg, setTravelTypeMsg] = useState("");
  const [currencySB, setCurrencySB] = useState(null);

  const [validatonMsg, setValidationMsg] = useState([]);
  const [openToolTip, setOpenToolTip] = useState(false);
  const [expand, setExpand] = useState(false);
  const [uniqueArray, setUniqueArray] = useState([]);
  const [open, setOpen] = useState(true);
  const [openExceptionalDialog, setOpenExceptionalDialog] = useState(false);
  const [openTeamOutingExceedDialog, setOpenTeamOutingExceedDialog] =
    useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDescDialog, setOpenDescDialog] = React.useState(false);
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const [delEmpEntityId, setDelEmpEntityId] = useState(null);
  const [multipleAttendeesError, setMultipleAttendeesError] = useState("");
  const { instance, accounts, inProgress } = useMsal();
  //new changes 0001
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [totalClaimAmount, setTotalClaimAmount] = useState(0);
  const [projectError1, setProjectError1] = useState("");
  const [forMonth, setForMonth] = useState(null);
  const [fromLocation, setFromLocation] = useState(null);
  const [toLocation, setToLocation] = useState(null);
  const [inBetweenDistance, setInBetweenDistance] = useState(0);
  const [fromType, setFromType] = useState(null);
  const [toType, setToType] = useState(null);
  const [noOfMon, setNoOfMon] = useState(null);
  const [validateFromDate, setValidateFromDate] = useState(false);
  const [fromDateError, setFromDateError] = useState("");
  const [validateToDate, setValidateToDate] = useState(null);
  const [toDateError, setToDateError] = useState("");
  const [forMonthError, setForMonthError] = useState("");
  const [fromLocationError, setFromLocationError] = useState("");
  const [toLocationError, setToLocationError] = useState("");
  const [inBetweenDistanceError, setInBetweenDistanceError] = useState("");
  const [categoryArray, setCategoryArray] = useState([]);
  const [fileIndex, setFileIndex] = useState(0);
  const [expanded3, setExpanded3] = useState(true);
  const [expanded1, setExpanded1] = useState(true);
  const [expanded, setExpanded] = useState(0);
  const [expanded2, setExpanded2] = useState(true);
  const [selectedItem, setSelectedItem] = useState(true);
  const [projectIdList, setProjectIdList] = useState([]);
  const [finalSelectedCategory, setFinalSelectedCategory] = useState([]);
  const [snackbarAlert, setSnackbarAlert] = useState(false);
  const [checkErrEntries, setCheckErrEntries] = useState(false);
  const [finalFileList, setFinalFileList] = useState([]);
  const [billExistence, setBillExistence] = useState([false]);
  const [fileDisplayForDD, setFileDisplayForDD] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState([
    {
      categoryCode: "",
      categoryId: "",
      categoryName: "",
      currencyId: "",
      billamount: "",
      claimedamount: "",
      receiptdate: null,
      paymentmethodid: "",
      expenseattachements: [
        {
          filename: "",
          attachmentsize: "",
        },
      ],
    },
  ]);
  const [validationArray, setValidationArray] = useState([
    {
      categoryMsg: "",
      currencyMsg: "",
      billAmtMsg: "",
      claimAmtMsg: "",
      recieptMsg: "",
      paymentMsg: "",
      attachmentMsg: "",
    },
  ]);
  const traveltype = [
    {
      label: "International",
      value: "International",
    },
    {
      label: "Domestic",
      value: "Domestic",
    },
  ];
  const AdvancesType = [
    {
      label: "Travel Card",
      value: "Travel Card",
    },
    {
      label: "Cash",
      value: "Cash",
    },
  ];
  const [advancesList, setAdvancesList] = useState([
    {
      depositdate: null,
      currency: "",
      totaladvance: 0,
      advancetype: "Travel Card",
    },
  ]);
  const [advancesListTemp, setAdvancesListTemp] = useState([
    {
      depositdate: null,
      currency: "",
      totaladvance: 0,
      advancetype: "Travel Card",
    },
  ]);
  const [advancesJson, setAdvancesJson] = useState([{}]);
  const [travelDestination, setTravelDestination] = useState("");
  const [travelStartDate, setTravelStartDate] = useState(null);
  const [travelEndDate, setTravelEndDate] = useState(null);
  const [travelDetailType, setTravelDetailType] = useState("");
  const [travelCardAmount, setTravelCardAmount] = useState(0);
  const [cash, setCash] = useState(0);
  const [cashBalance, setCashBalance] = useState("");
  const [travelCardBalance, setTravelCardBalance] = useState("");
  const [actualsTravelCard, setActualsTravelCard] = useState(0);
  const [actualsCash, setActualsCash] = useState(0);
  const [actualsSelf, setActualsSelf] = useState(0);
  const [openRating, setOpenRating] = useState(false);
  const [thankYouRatingOpen, setThankYouRatingOpen] = React.useState(false);
  const [pageModule, setPageModule] = useState("");
  const [ratingHeading, setRatingHeading] = useState("");
  const [value, setValue] = React.useState(-1);
  const [hover, setHover] = React.useState(-1);
  const [review, setReview] = React.useState("");
  const [checkCategoryError, setCheckCategoryError] = useState(-1);
  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState(false);
  const [statusSnackbarOpen, setStatusSnackbarOpen] = useState(false);
  const [toggleProjectListDispatch, setToggleProjectListDispatch] =
    useState(false);
  const [toggleFirstProjectListDispatch, setToggleFirstProjectListDispatch] =
    useState(0);
  const account = useAccount(accounts[0] || {});
  const location = useLocation();
  const monthList = [
    { monthId: 1, monthName: "January" },
    { monthId: 1, monthName: "February" },
    { monthId: 1, monthName: "March" },
    { monthId: 1, monthName: "April" },
    { monthId: 1, monthName: "May" },
    { monthId: 1, monthName: "June" },
    { monthId: 1, monthName: "July" },
    { monthId: 1, monthName: "August" },
    { monthId: 1, monthName: "September" },
    { monthId: 1, monthName: "October" },
    { monthId: 1, monthName: "November" },
    { monthId: 1, monthName: "December" },
  ];

  const handleKeyDown = (e) => {
    if ((e.key === "-" || e.key === " ") && e.target.selectionStart === 0) {
      e.preventDefault();
    }
  };
  //console.log("forMonth : ", forMonth);
  const handleOpenDescDialog = () => {
    setOpenDescDialog(true);
  };
  const handleCloseDescDialog = () => {
    setOpenDescDialog(false);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopOver = Boolean(anchorEl);
  const handleCloseEDB = () => {
    setOpenExceptionalDialog(false);
  };
  const handleOpenEDB = () => {
    setOpenExceptionalDialog(true);
    //  isAnonymous=(true);
  };
  const handleCloseTOB = () => {
    setOpenTeamOutingExceedDialog(false);
  };
  const handleOpenTOB = () => {
    setOpenTeamOutingExceedDialog(true);
    //  isAnonymous=(true);
  };

  //Dialog update end
  const handleCheckBox = (e) => {
    //console.log("setDeclarationCheckBox", e.target.checked);
    setDeclarationCheckBox(e.target.checked);
  };
  const onExpandClick = () => {
    setExpand(!expand);
  };
  const handleFileList = (data) => {
    setFileList(data);
    setFinalFileList(data);
    //console.log("fileList: >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ", fileList);
  };
  const handleProjectList = (data) => {
    // console.log("setPROJECT1 ", data);
    setSelectedProjects(data);
  };
  const handleAccordionChange = (index, isExpanded) => (event) => {
    setExpanded(isExpanded ? null : index);
  };
  const handleAccordionChangeFroAdvances = (isExpanded) => (event) => {
    setExpanded1(isExpanded ? false : true);
  };
  const handleAccordionChangeForSummary = (isExpanded) => (event) => {
    setExpanded2(isExpanded ? false : true);
  };
  const handleAccordionChangeForTravelDetails = (isExpanded) => (event) => {
    setExpanded3(isExpanded ? false : true);
  };
  const handleAdvancesList = (e, index, kind) => {
    if (kind == "cardBal") {
      setTravelCardBalance(e.target.value);
    }
    if (kind == "cashBal") {
      setCashBalance(e.target.value);
    }
    let arr = [...advancesList];
    if (kind == "type") {
      arr[index].advancetype = e.target.value;
    }
    if (kind == "date" && isValid(e)) {
      arr[index].depositdate = e;
    }
    if (kind == "amt") {
      arr[index].totaladvance = Number(e.target.value.replaceAll(",", ""));
    }
    arr[index].currency = currency.currency;
    // console.log("target comsole", e, index, kind, arr);

    setAdvancesList(arr);
    let dataCard = 0;
    let dataCash = 0;
    arr.map((item, index) => {
      if (item.advancetype == "Travel Card") {
        dataCard = dataCard + item.totaladvance;
      } else {
        dataCash = dataCash + item.totaladvance;
      }
    });
    setTravelCardAmount(dataCard);
    setCash(dataCash);
  };

  const materialUITextFieldProps1 = {
    id: "filled-multiline-flexible",
    label: "Bill Amount*",
    multiline: true,
    maxRows: 4,
    variant: "outlined",
  };
  const materialUITextFieldProps2 = {
    id: "filled-multiline-flexible",
    label: "Claim Amount*",

    multiline: true,
    maxRows: 4,
    variant: "outlined",
  };

  //Adding the Redux Part
  const dispatch = useDispatch();
  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );

  const purposeList = useSelector(
    (state) => state.createExpenseData.getPurposeList
  );
  const CategoryList = useSelector(
    (state) => state.createExpenseData.getCategoryList
  );
  // console.log("category list", CategoryList);
  const employeeProjectsList = useSelector(
    (state) => state.createExpenseData.getEmployeeProjectsList
  );

  // No. of dependents
  const DependentsList = useSelector(
    (state) => state.createExpenseData.getDependentsList
  );

  const CurrencyList = useSelector(
    (state) => state.createExpenseData.getCurrencyList
  );
  const AttendeesList = useSelector(
    (state) => state.createExpenseData.getAttendeesList
  );
  const validateAttendeesList = useSelector(
    (state) => state.createExpenseData.getValidateAttendeesList
  );
  const paymentList = useSelector(
    (state) => state.createExpenseData.getPaymentList
  );
  const isCreatePostSuccess = useSelector(
    (state) => state.createExpenseData.isPostData
  );
  const postCreateExpenseFormData = useSelector(
    (state) => state.createExpenseData.postCreateExpenseList
  );
  const postEditDataList = useSelector(
    (state) => state.createExpenseData.postEditData
  );
  const delEmpIDRed = useSelector((state) => state.createExpenseData.delEmpId);
  const myDelegateesData = useSelector(
    (state) => state.createExpenseData.getMyDelegateesData
  );
  const updatedSSOEmpId = useSelector(
    (state) => state.createExpenseData.ssoEmpId
  );
  const refreshModuleToggle = useSelector(
    (state) => state.createExpenseData.reloadModuleToggle
  );
  const billNumberExistence = useSelector(
    (state) => state.createExpenseData.getBillNumberExistence
  );
  // console.log("billNumberExistence redux", billNumberExistence);
  useEffect(() => {
    dispatch(resetExpenseResponse());
  }, [postEditDataList]);
  useEffect(() => {
    // billNumber?.length > 0 && billNumber !== undefined
    if (purpose !== "") {
      const obj = {
        expId: 0,
        empId: ssoEmpIdDetails?.empId,
        categoryId: expenseCategory?.categoryId
          ? expenseCategory?.categoryId
          : CategoryList?.find((item) => item.categoryName == expenseCategory)
              ?.categoryId,
        billNumber: billNumber ? billNumber : null,
      };
      // console.log("bill number obj", obj, billNumber?.length);
      dispatch(getBillNumberExistenceData(obj));
    }
  }, [billNumber, purpose, ssoEmpIdDetails, expenseCategory]);

  useEffect(() => {
    if (window.performance && refreshModuleToggle == null) {
      //console.log("window.performance works fine on this browser RELOAD1");
      window.screen.availWidth > 600 && history.push("/expense-Management");
    }
  }, []);

  const ratingLabels = useSelector(
    (state) => state?.timesheetData?.getRatingDescriptionData
  );

  var path = window.location.href;
  var directories = path.split("/");
  var lastDirecotry = directories[directories.length - 1];
  var firstDirectory = directories[directories.length - 2];

  useEffect(() => {
    if (directories && directories?.length === 4) {
      if (
        lastDirecotry === "timesheet-management" ||
        lastDirecotry === "create-Timesheet-Management"
      ) {
        setPageModule("Timesheet");
        setRatingHeading("Timesheet Application");
      } else if (
        lastDirecotry === "expense-Management" ||
        lastDirecotry === "create-Expense-Management" ||
        lastDirecotry === "allExpenseReports"
      ) {
        setPageModule("Expense");
        setRatingHeading("Expense Application");
      } else if (
        lastDirecotry === "gmdashboard" ||
        lastDirecotry === "gmmobility"
      ) {
        setPageModule("GM");
        setRatingHeading("Global Mobility Application");
      }
    } else if (directories && directories?.length === 5) {
      if (firstDirectory === "timesheetviewpage") {
        setPageModule("Timesheet");
        setRatingHeading("Timesheet Application");
      } else if (
        firstDirectory === "viewExpenseDetails" ||
        firstDirectory === "editExpenseDetails" ||
        firstDirectory === "editTravelExpenseDetails" ||
        firstDirectory === "deligation"
      ) {
        setPageModule("Expense");
        setRatingHeading("Expense Application");
      } else if (
        firstDirectory === "gmbupage" ||
        firstDirectory === "gmmobility"
      ) {
        setPageModule("GM");
        setRatingHeading("Global Mobility Application");
      }
    }
  }, [directories]);

  useEffect(() => {
    if (value && value === -1) {
      setIsSubmitDisabled(true);
    } else {
      setIsSubmitDisabled(false);
    }
  }, [value]);
  function getLabelText(value) {
    return (
      ratingLabels &&
      `${value} Star${value !== 1 ? "s" : ""}, ${
        ratingLabels[value - 1]?.ratingDescription
      }`
    );
  }

  const handleRatingClose = async () => {
    setOpenRating(false);

    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    const ratingFormData = new FormData();

    ratingFormData.append(
      "EmpId",
      Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
    ratingFormData.append("ApplicationType", pageModule ? pageModule : "");
    ratingFormData.append("ApplicationDiscription", "");
    ratingFormData.append("Review", "");
    ratingFormData.append("Skippedflag", 1);

    try {
      const response = await dispatch(postRatingReviewData(ratingFormData));

      if (response.status === 200) {
        history.push("/expense-Management"); // Route to the gmdashboard location after 3 seconds
      } else {
        setThankYouRatingOpen(false);
      }
    } catch {
      setThankYouRatingOpen(false);
    }
  };

  const handleRatingSkipClose = async () => {
    setOpenRating(false);

    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    const ratingFormData = new FormData();

    ratingFormData.append(
      "EmpId",
      Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
    ratingFormData.append("ApplicationType", pageModule ? pageModule : "");
    ratingFormData.append("ApplicationDiscription", "");
    ratingFormData.append("Review", "");
    ratingFormData.append("Skippedflag", 1);

    try {
      const response = await dispatch(postRatingReviewData(ratingFormData));

      if (response.status === 200) {
        history.push("/expense-Management"); // Route to the gmdashboard location after 3 seconds
      } else {
        setThankYouRatingOpen(false);
      }
    } catch {
      setThankYouRatingOpen(false);
    }
  };

  const handleRatingSubmit = async () => {
    setOpenRating(false);
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    const ratingFormData = new FormData();

    ratingFormData.append(
      "EmpId",
      Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
    ratingFormData.append("ApplicationType", pageModule ? pageModule : "");
    ratingFormData.append(
      "ApplicationDiscription",
      ratingLabels && value && ratingLabels[value - 1]?.ratingDescription
    );
    ratingFormData.append("Review", review ? review : "");
    ratingFormData.append("Skippedflag", 0);

    try {
      const response = await dispatch(postRatingReviewData(ratingFormData));
      if (response.status === 200) {
        setTimeout(() => {
          setThankYouRatingOpen(true);
        }, 500);
        setTimeout(() => {
          setThankYouRatingOpen(false);
          history.push("/expense-Management");
        }, 5000);
      } else {
        setThankYouRatingOpen(false);
      }
    } catch {
      setThankYouRatingOpen(false);
    }
  };

  useEffect(() => {
    if (ssoEmpIdDetails) {
      dispatch(getRatingDescriptionData());
    }
  }, [ssoEmpIdDetails]);

  useEffect(() => {
    setDragDropFileError("");
  }, [fileList]);

  const handleSnackbar = () => {
    setOpen(false);
    if (
      pageModule &&
      ((ssoEmpIdDetails?.expenseRatingFlag === 1 && pageModule === "Expense") ||
        (ssoEmpIdDetails?.timesheetRatingFlag === 1 &&
          pageModule === "Timesheet") ||
        (ssoEmpIdDetails?.gmRatingFlag === 1 && pageModule === "GM"))
    ) {
      setOpenRating(true);
    } else {
      history.push("/expense-Management"); // Route to the gmdashboard location after 3 seconds
    }
  };

  const handleCloseSnackbarVali = () => {
    setStatusSnackbarOpen(false);
    setCheckCategoryError(-1);
    setCheckErrEntries(false);
    setDisableSubmit(false);
    setSnackbarAlert(true);
  };
  const handlecloseSnackbar = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (AttendeesList !== null) {
      //setAttendees([]);
      let optionList = [];
      selectedProjects.map((value) => {
        AttendeesList?.forEach((element) => {
          if (element.projCode === value.projectName.projectID) {
            optionList.push({
              ...element,
              color: "#" + Math.random().toString(16).substr(-6),
            });
          }
        });
      });
      //console.log("AttendeesList", AttendeesList);
      const uniqueArray = [
        ...new Map(optionList.map((item) => [item["empId"], item])).values(),
      ];

      setUniqueArray(uniqueArray);
    }
  }, [selectedProjects]);

  const [storedExpenseCategory, setStoredExpenseCategory] = useState([]);

  useEffect(() => {
    // console.log("storedExpenseCategory1", expenseCategory);

    const data = expenseCategory?.notes;
    const notesArray = data?.split("||");
    setStoredExpenseCategory(notesArray);
  }, [expenseCategory]);
  const addAdvancesLine = () => {
    let array = JSON.parse(JSON.stringify(advancesList));
    array.push({
      depositdate: null,
      currency: "",
      totaladvance: 0,
      advancetype: "Travel Card",
    });
    setAdvancesList(array);
  };

  const addCategoryLine = () => {
    //setToggle(false);

    let array = JSON.parse(JSON.stringify(selectedCategory));
    array.push({
      categoryCode: "",
      categoryId: "",
      categoryName: "",
      currencyId: "",
      billamount: "",
      claimedamount: "",
      receiptdate: null,
      paymentmethodid: "",
      expenseattachements: [
        {
          filename: "",
          attachmentsize: "",
        },
      ],
    });
    setSelectedCategory(array);
    setExpanded(selectedCategory.length);
    let array1 = [...validationArray];
    array1.push({
      categoryMsg: "",
      currencyMsg: "",
      billAmtMsg: "",
      claimAmtMsg: "",
      recieptMsg: "",
      paymentMsg: "",
      attachmentMsg: "",
    });
    setValidationArray(array1);
  };

  const Array = [
    {
      validationType: "purpose",
      value: purpose,
    },

    {
      validationType: "expense Category",
      value: expenseCategory,
    },
    {
      validationType: "expense Name",
      value: expenseName,
    },
    {
      validationType: "Reciept Date",
      value: selectedDate,
      claim: valiDateDate,
      validateList: validateQuarter,
      validateList2: lastDate,
      noOfMonths: ssoEmpIdDetails,
      validateJoiningDate: validateJoiningDate,
    },
    {
      validationType: "Drag Drop File",
      value: fileList,
    },
    {
      validationType: "Project And Split Box",
      value: selectedProjects,
      claim: claimAmount,
    },
    {
      validationType: "Declaration CheckBox",
      value: declarationCheckBox,
    },
    {
      validationType: "Currency",
      value: currency,
    },
    {
      validationType: "Bill Amount",
      value: billAmount,
    },

    {
      validationType: "Claim Amount",
      value: claimAmount,
      claim: billAmount,
      validateList: validateAttendeesList,
      validateList2: expenseCategory,
      noOfMonths: noOfMon,
    },
    {
      validationType: "Payment",
      value: payment,
    },
    ...(expenseCategory?.multipleAttendes == 1
      ? [
          {
            validationType: "Attendees",
            value: attendees,
            claim: claimAmount,
            validateList: validateAttendeesList,
          },
        ]
      : []),
    ...(expenseCategory?.companyExpenseFlag === 1
      ? [
          {
            validationType: "Vendor Name",
            value: vendorName,
          },
          {
            validationType: "Bill Number",
            value: billNumber,
          },
          {
            validationType: "GST",
            value: gst,
          },
        ]
      : []),
    ...(expenseCategory?.qtyEnabledfl === "1"
      ? [
          {
            validationType: "Quantity",
            value: quantity,
          },
          {
            validationType: "Bill Number",
            value: billNumber,
          },
        ]
      : []),
    ...(expenseCategory?.billingCycle == 1
      ? [
          {
            validationType: "From Date",
            value: fromDate,
          },
          {
            validationType: "To Date",
            value: toDate,
          },
          {
            validationType: "For Month",
            value: forMonth,
          },
        ]
      : []),
    ...(expenseCategory?.distance == 1
      ? [
          {
            validationType: "From Location",
            value: fromLocation,
          },
          {
            validationType: "To Location",
            value: toLocation,
          },
          {
            validationType: "Distance In Between",
            value: inBetweenDistance,
          },
        ]
      : []),
  ];
  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  function isLeapYear(date) {
    var year = date.getFullYear();
    return year % 400 === 0 || (year % 4 === 0 && year % 100 !== 0);
  }

  function getLastDayOfExpense(lastDay, type) {
    if (lastDay.getMonth() == 0) {
      if (isLeapYear(lastDay) == false) {
        setLastDate(lastDay.addDays(75));
        return lastDay.addDays(75);
      } else if (isLeapYear(lastDay) == true) {
        setLastDate(lastDay.addDays(76));
        return lastDay.addDays(76);
      }
    } else if (type.getMonth() == 1) {
      setLastDate(lastDay.addDays(47));
      return lastDay.addDays(47);
    } else if (type.getMonth() == 2) {
      //console.log("lastday", lastDay.addDays(16));
      setLastDate(lastDay.addDays(16));
      return lastDay.addDays(16);
    } else if (type.getMonth() == 3) {
      //console.log("lastday", lastDay.addDays(77));
      setLastDate(lastDay.addDays(77));
      return lastDay.addDays(77);
    } else if (type.getMonth() == 4) {
      //console.log("lastday", lastDay.addDays(46));
      setLastDate(lastDay.addDays(46));
      return lastDay.addDays(46);
    } else if (type.getMonth() == 5) {
      //console.log("lastday", lastDay.addDays(16));
      setLastDate(lastDay.addDays(16));
      return lastDay.addDays(16);
    } else if (type.getMonth() == 6) {
      //console.log("lastday", lastDay.addDays(77));
      setLastDate(lastDay.addDays(77));
      return lastDay.addDays(77);
    } else if (type.getMonth() == 7) {
      //console.log("lastday", lastDay.addDays(46));
      setLastDate(lastDay.addDays(46));
      return lastDay.addDays(46);
    } else if (type.getMonth() == 8) {
      //console.log("lastday", lastDay.addDays(16));
      setLastDate(lastDay.addDays(16));
      return lastDay.addDays(16);
    } else if (type.getMonth() == 9) {
      //console.log("lastday", lastDay.addDays(77));
      setLastDate(lastDay.addDays(77));
      return lastDay.addDays(77);
    } else if (type.getMonth() == 10) {
      //console.log("lastday", lastDay.addDays(47));
      setLastDate(lastDay.addDays(47));
      return lastDay.addDays(47);
    } else if (type.getMonth() == 11) {
      //console.log("lastday", lastDay.addDays(16));
      setLastDate(lastDay.addDays(16));
      return lastDay.addDays(16);
    }
  }

  const projectData = (e) => {
    // if (
    //   e.target.value.categoryId == 1 ||
    //   e.target.value.categoryId == 56 ||
    //   toggleProjectListDispatch == true ||
    //   toggleFirstProjectListDispatch == 0
    // )
    // {
    if (
      delEmpIDRed != null &&
      delEmpIDRed != "" &&
      delEmpIDRed != ssoEmpIdDetails?.empId
    ) {
      const obj = {
        empId: delEmpIDRed,
        catId: e.target.value.categoryId || null,
      };
      // console.log("check payload", obj, e);
      dispatch(getEmployeeProjectsData(obj));
      if (toggleFirstProjectListDispatch > 0) {
        setExpenseCategoryChanged(true);
      }
      setToggleProjectListDispatch(true);
      setToggleFirstProjectListDispatch(toggleFirstProjectListDispatch + 1);
    } else if (ssoEmpIdDetails != null) {
      const obj = {
        empId: ssoEmpIdDetails.empId,
        catId: e?.target?.value?.categoryId || null,
      };

      dispatch(getEmployeeProjectsData(obj));
      if (toggleFirstProjectListDispatch > 0) {
        setExpenseCategoryChanged(true);
      }
      setToggleProjectListDispatch(true);
      setToggleProjectListDispatch(true);
      setToggleFirstProjectListDispatch(toggleFirstProjectListDispatch + 1);
    }
    // }
  };

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };
  const preventMinus1 = (e) => {
    if (e.code === "Minus" || e.key === "-" || e.key == ".") {
      e.preventDefault();
    }
  };
  useEffect(() => {
    if (employeeProjectsList != null && employeeProjectsList != undefined) {
      setSelectedProjects([
        {
          projectName: { projectID: "", projName: "", projId: "" },
          splitAmount: "",
          billable: 0,
        },
      ]);
    }
  }, [employeeProjectsList]);

  useEffect(() => {
    // console.log("1 checking props.empProjectList data", props.empProjectList);
    if (employeeProjectsList !== null && employeeProjectsList !== undefined) {
      setProjectIdList(employeeProjectsList);
    }
    if (employeeProjectsList) {
      if (
        employeeProjectsList[0]?.categoryId != 0 &&
        selectedProjects?.length > 1
      ) {
        setSelectedProjects([
          {
            projectName: { projectID: "", projName: "", projId: "" },
            splitAmount: "",
            billable: 0,
          },
        ]);
      }
    }
  }, [employeeProjectsList]);
  useEffect(() => {
    //console.log("props.currency SB01 : ", props.currency);

    if (props.currency != null) {
      setCurrencySB(props.currency);
    }
  }, [props.currency]);

  function dragDropFileValidationFileType(fileValues) {
    // console.log("fileValues: <<<<<<<<<<< ", fileValues[0].type.split("/")[1]);
    for (let i = 0; i < fileValues.length; i++) {
      if (
        fileValues[i].filename.split(".")[1] !== "pdf" &&
        fileValues[i].filename.split(".")[1] !== "png" &&
        fileValues[i].filename.split(".")[1] !== "jpg" &&
        fileValues[i].filename.split(".")[1] !== "jpeg" &&
        fileValues[i].filename.split(".")[1] !== "xlsx" &&
        fileValues[i].filename.split(".")[1] !== "xls" &&
        fileValues[i].filename.split(".")[1] !== "vnd.ms-excel" &&
        fileValues[i].filename.split(".")[1] !==
          "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        // //console.log(
        //   "fileValues2: <<<<<<<<<<< ",
        //   fileValues[i].type.split("/")[1]
        // );
        return true;
      }
    }
    return false;
  }

  function dragDropFileValidationUniqueFiles(fileValues) {
    const uniqueValues = new Set(fileValues?.map((v) => v.filename));

    if (uniqueValues.size < fileValues.length) {
      //console.log("duplicates found");
      return true;
    } else return false;
  }
  function dragDropFileValidationTotalSize(fileValues) {
    if (
      fileValues?.reduce(
        (acc, curr) => (acc = acc + Number(curr.attachmentsize)),
        0
      ) > 10485760
    ) {
      //console.log("size>10mb");
      return true;
    } else return false;
  }
  function uploadFileNameSpecialCharacterValidation(fileValues) {
    let tempFileList = [];
    for (let i = 0; i < fileValues.length; i++) {
      tempFileList.push({ fileName: fileValues[i].filename });
    }
    if (typeof tempFileList !== "undefined" && tempFileList.length > 0) {
      var newArrayVal = [];

      for (var i = 0; i <= tempFileList.length; i++) {
        var fileCheck =
          tempFileList && tempFileList[i] !== undefined
            ? tempFileList[i].fileName
            : "";
        if (fileCheck?.substr(fileCheck?.length - 4) == (".png" || ".jpg")) {
          newArrayVal.push(fileCheck?.substring(0, fileCheck?.length - 4));
        } else if (
          fileCheck?.substr(fileCheck?.length - 5) == ".jpeg" ||
          ".xlsx"
        ) {
          newArrayVal.push(fileCheck?.substring(0, fileCheck?.length - 5));
        }
      }
      var format = /[`!@#$%^&*+\=\[\]{};':"\\|,.<>\/?~]/;
      var lname = newArrayVal?.map((obj) => {
        return format.test(obj);
      });
      if (lname && lname.includes(true)) {
        return true;
      } else return false;
    }
  }
  function uploadFileNameCharLengthValidation(fileValues) {
    let tempFileList = [];
    for (let i = 0; i < fileValues.length; i++) {
      tempFileList.push({ fileName: fileValues[i].filename });
    }
    if (typeof tempFileList !== "undefined" && tempFileList.length > 0) {
      //console.log("lkjdfs2", tempFileList);
      var newArrayVal = [];
      for (var i = 0; i <= tempFileList.length; i++) {
        var fileCheck =
          tempFileList && tempFileList[i] !== undefined
            ? tempFileList[i].fileName
            : "";
        //console.log("lkjdfs3", fileCheck);
        if (fileCheck?.substr(fileCheck?.length - 4) == (".png" || ".jpg")) {
          newArrayVal.push(fileCheck?.substring(0, fileCheck?.length - 4));
        } else if (
          fileCheck?.substr(fileCheck?.length - 5) == ".jpeg" ||
          ".xlsx"
        ) {
          newArrayVal.push(fileCheck?.substring(0, fileCheck?.length - 5));
        }
      }
      for (var i = 0; i <= newArrayVal.length; i++) {
        if (newArrayVal[i]?.length > 100) {
          return true;
        } else {
          return false;
        }
      }
    }
  }
  const handlePadding = (matches) => {
    if (selectedItem) {
      return {
        paddingLeft: matches ? "11px" : "",
        paddingTop: matches ? "6px" : "",
        paddingRight: matches ? "32px" : "",
        paddingBottom: matches ? "7px" : "",
      };
    }
    return {};
  };
  const handleSelectProject = (e, index) => {
    const { name, value, checked } = e.target;
    if (value) {
      setProjectListSplitBoxError("");
      setProjectError1("");
    }

    let array = JSON.parse(JSON.stringify(selectedProjects));

    const element = projectIdList.find((ele) => ele.projCode === value);

    let tempBillable = checked === true ? 1 : 0;

    if (name === "billable") {
      array[index].billable = tempBillable;
    } else if (name === "splitAmount") {
      array[index].splitAmount = Number(value.replaceAll(",", ""));
    }

    // Include the selected category information in the selected project
    //const selectedCategoryItem = selectedCategory[index];
    //array[index].categoryid = selectedCategoryItem.categoryid;
    //array[index].categoryname = selectedCategoryItem.categoryname;

    if (element) {
      array[index].projectName.projectID = value;
      array[index].projectName.projName = element.projName;
      array[index].projectName.projId = element.projId;
    }

    setSelectedItem(false);
    setSelectedProjects(array);

    // Assuming you have a handleProjectList function to update the parent component state with the selected projects
    handleProjectList(array);
    // console.log("updated selectedProjectsList: ", selectedProjects);
  };
  const handleDateChange = (type, date) => {
    // console.log("validation", type, isValid(date), typeof type);

    setSelectDateError("");
    setSelectedDate(null);
    setValiDateDate(false);
    setValidateQuarter(false);
    setValidateJoiningDate(false);
    const presentDate = new Date();
    if (type < presentDate) {
      const difference = presentDate - type;
      let TotalDays = Math.ceil(difference / (1000 * 3600 * 24) - 1);
      const slectedDate = moment(date).format("l");
      if (
        moment(type).toDate() < moment(ssoEmpIdDetails?.empJoiningDate).toDate()
      ) {
        //console.log("ty1pe2 validDate",validateJoiningDate, moment(selectedDate).toDate() < moment(ssoEmpIdDetails?.empJoiningDate).toDate());
        setSelectedDate(slectedDate);
        setValidateJoiningDate(true);
        setSelectDateError(
          `Expense invoice date cannot be before joining date which is ${moment(
            ssoEmpIdDetails?.empJoiningDate
          ).format("l")}`
        );
      } else if (expenseCategory?.quaterlyBilling != 1) {
        if (TotalDays <= expenseCategory?.claimWindow) {
          setSelectedDate(slectedDate);
        } else {
          setSelectedDate(slectedDate);
          setValiDateDate(true);
          setSelectDateError("Days limit is expired for this Category");
        }
      } else if (expenseCategory?.quaterlyBilling == 1) {
        let lastDayOfMonth = new Date(
          type.getFullYear(),
          type.getMonth() + 1,
          0
        );

        let lastDayOfQuarter = getLastDayOfExpense(lastDayOfMonth, type);
        //console.log("lastDayOfQuarter", lastDayOfQuarter);
        if (presentDate >= lastDayOfQuarter) {
          //console.log("presentDate >= lastDayOfQuarter", presentDate);
          setSelectedDate(slectedDate);
          setValidateQuarter(true);
          setSelectDateError(
            `Last date to submit the expense was ${moment(
              lastDayOfQuarter.setDate(lastDayOfQuarter.getDate() - 1)
            ).format("l")}`
          );
        } else setSelectedDate(slectedDate);
      }
    }
    // if (billNumber!==undefined) {
    // const obj = {
    //   empId: ssoEmpIdDetails?.empId,
    //   categoryId: expenseCategory?.categoryId,
    //   billNumber: billNumber?billNumber:"",
    //   expId:0,
    // };
    // console.log("bill number obj", obj);
    // dispatch(getBillNumberExistenceData(obj));
    // }
  };
  //new changes 0001
  const handleFromDate = (type, date) => {
    setFromDate(null);
    setValidateToDate(false);
    setFromDateError("");
    setToDateError("");
    if (toDate != null) {
      const diffInDates = (toType - type) / (1000 * 60 * 60 * 24);
      const noOfMonths = Math.round(diffInDates / 30);
      //console.log("diff2", diffInDates, noOfMonths);
      setNoOfMon(noOfMonths);
    }
    let tempFromDate = moment(date).format("l");
    //console.log("8881", moment(tempFromDate).toDate(), type);
    setFromDate(tempFromDate);
    setFromType(type);
  };

  const handleToDate = (type, date) => {
    setToDate(null);
    setValidateToDate(false);
    setToDateError("");
    let tempToDate = moment(date).format("l");
    const diffInDates = (type - fromType) / (1000 * 60 * 60 * 24);
    const noOfMonths = Math.round(diffInDates / 30);
    //console.log("diff", diffInDates, noOfMonths);

    if (fromDate != null) {
      setToDate(tempToDate);
      setToType(type);
      setNoOfMon(noOfMonths);
    } else {
      setValidateToDate(true);
      setToDate(tempToDate);
      setToType(type);
      setToDateError("First enter From Date");
    }
  };

  // }
  const handleExpCategory = (name, index) => {
    // console.log("name", id, name, index);
    let array = JSON.parse(JSON.stringify(selectedCategory));
    let id = CategoryList.find((item) => name == item.categoryName)?.categoryId;
    let catcode = CategoryList.find(
      (item) => name == item.categoryName
    )?.categoryCode;

    array.map((item, i) => {
      // console.log("OnePlus", catcode);
      if (i === index) {
        item.categoryId = id;
        item.categoryName = name;
        item.categoryCode = catcode;
      }
    });
    //setSelectedCategory(array);
    // console.log("selected123", selectedCategory);
    const filterdCurrency = paymentList?.filter(
      (value) => value.selected === 1
    );
    if (filterdCurrency?.length > 0) {
      setPayment(filterdCurrency[0]);
    }
    if (filterdCurrency?.length > 0) {
      array.map((item, i) => {
        if (i == index) {
          item.paymentmethodid = filterdCurrency[0]?.methodId;
        }
      });
    }
    setSelectedCategory(array);
  };
  //console.log("selected123" , selectedCategory)
  const handlePayment = (id, i) => {
    let array = JSON.parse(JSON.stringify(selectedCategory));
    // console.log("payments", id, paymentList);
    let oldMethodId = selectedCategory[i].paymentmethodid;
    array.map((item, index) => {
      if (index == i) {
        item.paymentmethodid = id;
        if (id == 34) {
          let x = Number(actualsSelf);
          x = x + Number(selectedCategory[i].claimedamount);
          setActualsSelf(x);
        }
        if (id == 3) {
          let x = Number(actualsTravelCard);
          x = x + Number(selectedCategory[i].claimedamount);
          setActualsTravelCard(x);
        }
        if (id == 35) {
          let x = Number(actualsCash);
          x = x + Number(selectedCategory[i].claimedamount);
          setActualsCash(x);
        }
      }
    });
    if (oldMethodId == 34) {
      let x = Number(actualsSelf);
      x = x - Number(selectedCategory[i].claimedamount);
      setActualsSelf(x);
    }
    if (oldMethodId == 3) {
      let x = Number(actualsTravelCard);
      x = x - Number(selectedCategory[i].claimedamount);
      setActualsTravelCard(x);
    }
    if (oldMethodId == 35) {
      let x = Number(actualsCash);
      x = x - Number(selectedCategory[i].claimedamount);
      setActualsCash(x);
    }
    setSelectedCategory(array);
  };

  const handleCurrency = (id, i) => {
    let array = JSON.parse(JSON.stringify(selectedCategory));
    array = array.map((item, index) => {
      if (index === i) {
        return {
          ...item,
          currencyId: id,
        };
      }
      return item;
    });
    setSelectedCategory(array);
    setCurrency(id);
  };

  const handleBillAmount = (name, i) => {
    validationArray[i].billAmtMsg = "";
    let array = JSON.parse(JSON.stringify(selectedCategory));
    if (selectedCategory[i].claimedamount != "") {
      const val1 = parseFloat(Number(selectedCategory[i].claimedamount));
      if (val1 > DOMPurify.sanitize(Number(name.replaceAll(",", "")))) {
        validationArray[i].claimAmtMsg =
          "Claim Amount is greater than Bill Amount";
      } else {
        validationArray[i].claimAmtMsg = "";
      }
    }
    array.map((item, index) => {
      if (index == i) {
        item.billamount = DOMPurify.sanitize(Number(name.replaceAll(",", "")));
      }
    });
    setSelectedCategory(array);
  };
  const handleClaimAmount1 = (name, i) => {
    setSplitClaimAmount(Number(name.replaceAll(",", "")));
    const val2 = parseFloat(
      selectedCategory[i]?.billamount.replaceAll(",", "")
    );
    const val = parseFloat(Number(name.replaceAll(",", "")));
    let array = JSON.parse(JSON.stringify(selectedCategory));

    // console.log("claim", val, val2, val2 < val);

    if (val2 < val) {
      validationArray[i].claimAmtMsg =
        "Claim Amount is greater than Bill Amount";
    } else {
      validationArray[i].claimAmtMsg = "";
    }
    array.map((item, index) => {
      if (index === i) {
        let old = selectedCategory[i].claimedamount;
        item.claimedamount = DOMPurify.sanitize(
          Number(name.replaceAll(",", ""))
        );
        if (item.paymentmethodid == 34) {
          let x = Number(actualsSelf);
          x = x - old + val;
          setActualsSelf(x);
        }
        if (item.paymentmethodid == 3) {
          let x = Number(actualsTravelCard);
          x = x - old + val;
          setActualsTravelCard(x);
        }
        if (item.paymentmethodid == 35) {
          let x = Number(actualsCash);
          x = x - old + val;
          setActualsCash(x);
        }
      }
    });

    setSelectedCategory(array);
    const oldClaimedAmount = parseFloat(
      Number(selectedCategory[i]?.claimedamount)
    );
    const newClaimedAmount = parseFloat(Number(name.replaceAll(",", "")));
    const newTotalClaimAmount = Number(
      totalClaimAmount - oldClaimedAmount + newClaimedAmount
    ).toFixed(2);
    setTotalClaimAmount(newTotalClaimAmount);
  };

  useEffect(() => {
    // console.log(
    //   "check cash travel self",
    //   actualsSelf,
    //   actualsCash,
    //   actualsTravelCard
    // );
  }, [selectedCategory]);

  const handleFileList1 = (fileList1, index) => {
    const updatedCategories = JSON.parse(JSON.stringify(selectedCategory));
    //const lastCategoryIndex = updatedCategories.length - 1;
    validationArray[index].attachmentMsg = "";
    const lastCategory = updatedCategories[index];
    lastCategory.expenseattachements = fileList1.map((file) => ({
      filename: file.name,
      attachmentsize: file.size,
    }));
    setSelectedCategory(updatedCategories);
    setFileList(fileList1);
    setFinalFileList(finalFileList.concat(fileList1));
    const updatedFileDisplayForDD = [...fileDisplayForDD];

    // Check if an array already exists at the ith index
    // console.log("yturygjfs" ,updatedFileDisplayForDD, fileList1, updatedCategories )
    if (updatedFileDisplayForDD[index]) {
      // If an array already exists, push fileList1 into it
      updatedFileDisplayForDD[index] = [...fileList1];
    } else {
      // If an array doesn't exist, create a new array and insert fileList1 into it
      updatedFileDisplayForDD[index] = fileList1;
      // .map((file) => ({
      //   name: file.name,
      //   size: file.size,
      //   type: file.type
      // }));
    }

    // Update the state of fileDisplayForDD
    setFileDisplayForDD(updatedFileDisplayForDD);
  };
  const [IndexForDate, setIndexForDate] = useState(0);

  const handleReceiptDate = (date, index, cat) => {
    if (isValid(date)) {
      let validationError = "";
      let array = [...selectedCategory];
      let catArray = CategoryList.find(
        (item) => item.categoryName == cat.categoryName
      );
      const presentDate = new Date();
      if (!date) {
        validationError = "Please fill in the invoice date";
      } else if (date < presentDate) {
        const difference = presentDate - date;
        const totalDays = Math.ceil(difference / (1000 * 3600 * 24) - 1);
        const selectedDate = moment(date).format("l");

        if (
          moment(date).toDate() <
          moment(ssoEmpIdDetails?.empJoiningDate).toDate()
        ) {
          array[index] = { ...array[index], receiptdate: selectedDate };
          setValidateJoiningDate(true);
          validationError = `Expense invoice date cannot be before joining date which is ${moment(
            ssoEmpIdDetails?.empJoiningDate
          ).format("l")}`;
        } else if (catArray?.quaterlyBilling !== 1) {
          if (totalDays <= catArray?.claimWindow) {
            array[index] = { ...array[index], receiptdate: selectedDate };
            validationError = "";
          } else {
            array[index] = { ...array[index], receiptdate: selectedDate };
            setValiDateDate(true);
            setSelectDateError("Days limit is expired for this Category");
            validationError = "Days limit is expired for this Category";
          }
        } else if (catArray?.quaterlyBilling === 1) {
          const lastDayOfMonth = new Date(
            date.getFullYear(),
            date.getMonth() + 1,
            0
          );

          const lastDayOfQuarter = getLastDayOfExpense(lastDayOfMonth, date);
          if (presentDate >= lastDayOfQuarter) {
            validationError = `Last date to submit the expense was ${moment(
              lastDayOfQuarter.setDate(lastDayOfQuarter.getDate() - 1)
            ).format("l")}`;
          }
        }
      }

      const newArr = [...validationArray];
      newArr.map((item, i) => {
        if (i == index) {
          item.recieptMsg = validationError;
        }
      });
      setValidationArray(newArr);
      setSelectedCategory(array);
    } else {
      const newArr = [...validationArray];
      newArr.map((item, i) => {
        if (i == index) {
          item.recieptMsg = "*Invalid date format.";
        }
      });
      setValidationArray(newArr);
    }
  };

  const handlePerson = (data) => {
    setproject(data);
  };
  const handleAttendees2 = (attendees, claimAmount, validateAttendeesList) => {
    let list = null;
    const perHead = Number(claimAmount / attendees?.length);
    let newArray = validateAttendeesList?.filter((array22) =>
      attendees.some((array11) => array22.empId === array11.empId)
    );
    list = newArray?.filter((check) => perHead > Number(check.remainingAmount));
    return list;
  };
  const handleClaimAmount = (value, i) => {
    //console.log("value in handleClaimAmount :====> ", value);
    setSplitClaimAmount(Number(value.replaceAll(",", "")));
    setClaimAmount(Number(value.replaceAll(",", "")));
    if (parseInt(Number(value.replaceAll(",", ""))) > parseInt(billAmount)) {
      setClaimAmountError("Claim Amount is greater than Bill Amount");
    }
  };
  const handleQuantity = (value, i) => {
    // console.log("value in handleQuantity :====> ", value);
    const cleanedValue = value.replace(/,/g, "");

    setQuantity(Number(cleanedValue));
    // if (value === 0 || value === null) {
    //   setQuantityError("Please enter quantity");
    // }
  };
  const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
  const REGION = "ap-south-1";
  const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
  const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
  const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  };
  const handleUpload = async (file) => {
    uploadFile(file, config)
      .then((data) => console.log(""))
      .catch((err) => console.error("handleError", err));
  };

  useEffect(() => {
    if (isCreatePostSuccess == true) {
      // console.log("final file list" , finalFileList);
      //const uniqueFilelist = removeDuplicatesUpload(finalFileList);
      for (let i = 0; i < finalFileList?.length; i++) {
        const mynewFile = new File(
          [finalFileList[i]],
          `${postCreateExpenseFormData.expId}_${finalFileList[
            i
          ].name.toLowerCase()}`
        );
        handleUpload(mynewFile);
      }
      setSnackbarAlert(true);
    }
  }, [isCreatePostSuccess, fileList]);

  let netPayable = 0;

  useEffect(() => {
    netPayable = Number(
      Number(cash) +
        Number(travelCardAmount) -
        (Number(actualsCash) +
          Number(actualsSelf) +
          Number(actualsTravelCard)) -
        (Number(travelCardBalance) + Number(cashBalance))
    );
    let arr = [...advancesJson];
    arr[0] = [
      {
        //advancesList:advancesList,
        // advcash: cash,
        // advtravelcard: travelCardAmount,
        // advtotal: Number(cash + travelCardAmount),
        // actcash: actualsCash,
        // actself: actualsSelf,
        // acttravelcard: actualsTravelCard,
        // acttotal: Number(actualsCash + actualsSelf + actualsTravelCard),

        destination: travelDestination,
        travelstartdate: travelStartDate,
        travelenddate: travelEndDate,
        //travelType:travelDetailType,
        travelcardbal: Number(travelCardBalance),
        cashbal: Number(cashBalance),
        refundreceived: Number(
          Number(travelCardBalance.replaceAll(",", "")) +
            Number(cashBalance.replaceAll(",", ""))
        ),
        netpayable:
          Number(Number(cash) + Number(travelCardAmount)) -
          Number(
            Number(actualsCash) +
              Number(actualsSelf) +
              Number(actualsTravelCard)
          ) -
          Number(Number(travelCardBalance) + Number(cashBalance)),
      },
    ];
    // console.log(
    //   "advances outside",
    //   advancesJson,
    //   advancesList,
    //   cash,
    //   travelDetailType,
    //   arr
    // );

    setAdvancesJson(arr);
  }, [
    advancesList,
    selectedCategory,
    travelCardBalance,
    cashBalance,
    travelDestination,
    travelStartDate,
    travelEndDate,
    travelDetailType,
  ]);

  useEffect(() => {
    setMultipleAttendeesError("");
    if (
      attendees != null &&
      attendees != "" &&
      claimAmount != null &&
      claimAmount != "" &&
      validateAttendeesList != null &&
      validateAttendeesList != "" &&
      expenseCategory?.multipleAttendes == 1 &&
      expenseCategory?.categoryId == 11
    ) {
      const tempError = handleAttendees2(
        attendees,
        claimAmount,
        validateAttendeesList
      );
      setMultipleAttendeesError(tempError);
    }
  }, [attendees, claimAmount, validateAttendeesList, expenseCategory]);
  useEffect(() => {
    setFinalSelectedCategory(selectedCategory);
  }, [selectedCategory]);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  let checkErrorInside = false;
  const handleClick = () => {
    // const obj = {
    //   expId: 0,
    //   empId: ssoEmpIdDetails?.empId,
    //   categoryId: expenseCategory?.categoryId,
    //   billNumber: billNumber ? billNumber : 0,
    // };
    // console.log("bill number obj", billNumber?.length,billNumberExistence);
    // dispatch(getBillNumberExistenceData(obj));
    if (expenseCategory?.qtyEnabledfl === "1") {
      if (billNumberExistence?.billExistFlag == 0) {
        if (purpose.purpose == "Travel") {
          if (!selectedProjects[0]?.projectName?.projectID) {
            setProjectError1("*Please select the Project");
            // return; // Stop the submission if there's an error
          } else {
            setProjectError1("");
          }
          if (expenseName == "") {
            setExpenseNameError("*Please enter expense name.");
          } else {
            setExpenseNameError("");
          }
          var key = "E546C8DF278CD5931069B522E695D4F2";
          const result = Array.map((feild) =>
            FieldValidator(
              feild.validationType,
              feild.value,
              feild.claim,
              feild.validateList,
              feild.validateList2,
              feild.noOfMonths,
              feild.validateJoiningDate
            )
          );
          let arr = result.map((i) => i.bool);
          let checker = arr.every((element) => element === true);
          setValidationMsg(result);
          if (travelDestination == "") {
            setTravelDestinationMsg("*Please enter travel destination.");
          } else {
            setTravelDestinationMsg("");
          }
          if (travelStartDate == null) {
            setTravelstdtMsg("*Please enter start date");
          } else {
            setTravelstdtMsg("");
          }
          if (travelEndDate == null) {
            setTravelendtMsg("*Please enter travel end date.");
          } else {
            setTravelendtMsg("");
          }
          // if(travelStartDate != null && travelEndDate != null && travelStartDate > travelEndDate){
          //   setTravelendtMsg("*End date should be greater than start date");
          // }
          // else{
          //   setTravelendtMsg("");
          // }
          if (travelDetailType == "") {
            setTravelTypeMsg("*Please select travel type");
          } else {
            setTravelTypeMsg("");
          }
          selectedCategory.forEach((item, index) => {
            let arr = [...validationArray];
            arr[index].categoryMsg =
              item.categoryId === "" ? "*Please enter category" : "";
            arr[index].recieptMsg = !item.receiptdate
              ? "*Please select Invoice date"
              : "";
            arr[index].paymentMsg =
              item.paymentmethodid === ""
                ? "*Please select Payment Method"
                : "";
            arr[index].billAmtMsg =
              item.billamount === "" ? "*Please enter Bill Amount" : "";
            arr[index].claimAmtMsg =
              item.claimedamount === "" || item.claimedamount == 0
                ? "*Please enter Claim Amount"
                : item.claimedamount > item?.billamount
                ? "Claim Amount is greater than Bill Amount"
                : "";

            arr[index].attachmentMsg = getAttachmentMessage(item);
            setValidationArray(arr);
          });
          function getAttachmentMessage(item) {
            if (
              !item.expenseattachements.length ||
              item.expenseattachements[0]?.filename === ""
            ) {
              return "*Please attach at least one receipt";
            }
            if (
              uploadFileNameSpecialCharacterValidation(item.expenseattachements)
            ) {
              return "*Please enter a valid file name (no special characters allowed other than (,),-,_ )";
            }
            if (uploadFileNameCharLengthValidation(item.expenseattachements)) {
              return "*Please enter a valid file name (file name cannot be greater than 100 characters)";
            }
            if (dragDropFileValidationFileType(item.expenseattachements)) {
              return "*Only JPEG, JPG, XLS, XLSX, PNG and PDF allowed";
            }
            if (dragDropFileValidationUniqueFiles(item.expenseattachements)) {
              return "*Duplicate files are not allowed";
            }
            if (dragDropFileValidationTotalSize(item.expenseattachements)) {
              return "*Total size of attached receipts has exceeded 10 mb. Please remove some files, or add compressed files.";
            }
            return "";
          }

          const filterAttendees = [];
          let fileList1 = [];

          const filterproject = [];
          if (selectedProjects?.length > 0) {
            selectedProjects?.map((value) =>
              filterproject.push({
                projid: Encrypt(key, JSON.stringify(value.projectName.projId)),
                projamount: value.splitAmount || 0,
                billable: Encrypt(key, JSON.stringify(value.billable)),
              })
            );
          }

          let checkError = false;
          if (
            projectError1 != "" ||
            expenseNameError != "" ||
            expenseName == "" ||
            travelDestination == "" ||
            travelStartDate == null ||
            travelEndDate == null ||
            travelDetailType == "" ||
            travelStartDate > travelEndDate
          ) {
            setCheckErrEntries(true);
            checkErrorInside = true;
          }

          let boxMsg = result?.find(
            (item) => item.type == "Declaration CheckBox"
          )?.bool;
          validationArray.forEach((item, index) => {
            if (checkError) {
              return;
            }
            const errorTypes = [
              "attachmentMsg",
              "billAmtMsg",
              "categoryMsg",
              "claimAmtMsg",
              "currencyMsg",
              "paymentMsg",
              "recieptMsg",
            ];

            const hasError = errorTypes.some(
              (errorType) => item?.[errorType] != ""
            );
            if (hasError) {
              setCheckCategoryError(index);
              checkError = true;
              return;
            }
          });

          let formData = new FormData();
          let currencyVal = currency.currencyId;
          formData.append("ExpName", expenseName);

          formData.append("PurposeId", purpose.purposeId);
          formData.append("Purpose", purpose.purpose);
          formData.append("categoryCode", selectedCategory[0]?.categoryCode);
          formData.append("categoryId", selectedCategory[0]?.categoryId);
          formData.append("categoryName", selectedCategory[0]?.categoryName);
          if (expenseCategory.autoApproval == 0) {
            formData.append("exceptionapplicationflag", 0);
            formData.append("autoapprovalflag", 0);
          } else if (
            expenseCategory.autoApproval == 1 &&
            claimAmount > expenseCategory?.policyLimit
          ) {
            formData.append("exceptionapplicationflag", 1);
            formData.append("autoapprovalflag", 0);
          } else if (
            expenseCategory.autoApproval == 1 &&
            claimAmount <= expenseCategory?.policyLimit
          ) {
            formData.append("exceptionapplicationflag", 0);
            formData.append("autoapprovalflag", 1);
          }
          formData.append("BillingCycleFromDate", fromDate || "");
          formData.append(
            "BillNumber",
            billNumber ? Encrypt(key, JSON.stringify(billNumber)) : null
          );
          formData.append(
            "VendorName",
            vendorName ? Encrypt(key, JSON.stringify(vendorName)) : null
          );
          formData.append(
            "GSTNumber",
            gst ? Encrypt(key, JSON.stringify(gst)) : null
          );
          formData.append("booksqty", quantity);

          formData.append("ExpStatus", "In Progress");
          formData.append("currencyId", currencyVal);
          formData.append("BillAmount", selectedCategory[0]?.billamount);
          formData.append("ClaimedAmount", selectedCategory[0]?.claimedamount);
          formData.append("BillingCycleToDate", toDate || "");
          formData.append("BillingCycleMonth", forMonth);
          formData.append("FromLocation", fromLocation);
          formData.append("ToLocation", toLocation);
          formData.append("Distance", 0);
          formData.append(
            "PaymentMethodId",
            selectedCategory[0]?.paymentmethodid
          );
          formData.append("ReceiptDate", selectedCategory[0]?.receiptdate);
          formData.append(
            "EmpEntityId",
            delEmpEntityId != null ? delEmpEntityId : ssoEmpIdDetails?.entityId
          );
          formData.append(
            "CreatedBy",
            Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
          );
          formData.append(
            "EmpId",
            delEmpIDRed != null
              ? Encrypt(key, JSON.stringify(delEmpIDRed))
              : Encrypt(key, JSON.stringify(location?.state?.ssoEmpId))
          );
          if (dependents) {
            formData.append("DependentId", dependents?.dependentId);
          }
          formData.append("expenseProjLinks", JSON.stringify(filterproject));
          formData.append(
            "expenseEmpLinks",
            JSON.stringify(filterAttendees ? filterAttendees : [])
          );
          let tempFileList = selectedCategory[0]?.expenseattachements;
          formData.append("files", JSON.stringify(tempFileList));
          formData.append("traveltype", travelDetailType);
          {
            selectedCategory.map((item) => {
              if (item.currencyId == "") {
                item.currencyId = currencyVal;
              }
            });
          }
          if (selectedCategory?.length == 1) {
            formData.append("childExpenses", JSON.stringify([]));
          } else {
            let arr123 = [...selectedCategory];
            arr123.splice(0, 1);

            formData.append("childExpenses", JSON.stringify(arr123));
          }
          formData.append(
            "expenseAdvancesLists",
            JSON.stringify(
              travelDetailType == "Domestic"
                ? []
                : advancesList[0]?.depositdate != null ||
                  advancesList[0]?.currency != "" ||
                  advancesList[0]?.totaladvance != 0 ||
                  advancesList[0]?.advancetype != ""
                ? advancesList
                : []
            )
          );
          formData.append(
            "expenseAdvancesDetails",
            JSON.stringify(advancesJson[0])
          );
          if (!checkError && !checkErrorInside && boxMsg) {
            dispatch(postCreateExpenseData(formData));
            handleCloseEDB();
            setDisableSubmit(true);
            checkError = false;
          } else {
            setStatusSnackbarOpen(true);
            setDisableSubmit(true);
            checkError = false;
          }
        }
        // travel expense form submission ends here
        else {
          if (purpose.purpose == "Medical Expenses") {
            if (dependents?.length == 0) {
              setDependentsError("*Please enter Relationship");
            } else {
              setDependentsError("");
            }
          }
          if (quantity === 0 || quantity === null || quantity === "") {
            setQuantityError("Please enter quantity");
          } else setQuantityError("");
          var key = "E546C8DF278CD5931069B522E695D4F2";
          const result = Array.map((feild) =>
            FieldValidator(
              feild.validationType,
              feild.value,
              feild.claim,
              feild.validateList,
              feild.validateList2,
              feild.noOfMonths,
              feild.validateJoiningDate
            )
          );
          setValidationMsg(result);
          let arr = result.map((i) => i.bool);
          let checker = arr.every((element) => element === true);
          if (dependentsError != "") {
            checker = false;
          }

          if (checker) {
            setDisableSubmit(true);
            const filterproject = [];
            const filterAttendees = [];
            let fileList1 = [];
            if (selectedProjects?.length > 0) {
              selectedProjects?.map((value) =>
                filterproject.push({
                  projid: Encrypt(
                    key,
                    JSON.stringify(value.projectName.projId)
                  ),
                  projamount: value.splitAmount,
                  billable: Encrypt(key, JSON.stringify(value.billable)),
                })
              );
            }

            if (attendees?.length > 0) {
              attendees?.map((value) =>
                filterAttendees.push({
                  empid: Encrypt(key, JSON.stringify(value.empId)),
                  // empid: "5",
                  empamount: claimAmount / attendees?.length,
                })
              );
            }
            let formData = new FormData();
            // console.log("FORM1",purpose.purpose,selectedCategory.categorycode)
            formData.append("ExpName", expenseName);
            formData.append("PurposeId", purpose.purposeId);
            formData.append("Purpose", purpose.purpose);
            formData.append("CategoryCode", expenseCategory.categoryCode);
            formData.append("CategoryId", expenseCategory.categoryId);

            formData.append("CategoryName", expenseCategory.categoryName);
            if (expenseCategory.autoApproval == 0) {
              formData.append("exceptionapplicationflag", 0);
              formData.append("autoapprovalflag", 0);
            } else if (
              expenseCategory.autoApproval == 1 &&
              claimAmount > expenseCategory?.policyLimit
            ) {
              formData.append("exceptionapplicationflag", 1);
              formData.append("autoapprovalflag", 0);
            } else if (
              expenseCategory.autoApproval == 1 &&
              claimAmount <= expenseCategory?.policyLimit
            ) {
              formData.append("exceptionapplicationflag", 0);
              formData.append("autoapprovalflag", 1);
            }
            // if(purpose.purpose=="Travel"){
            //   formData.append("TravelEXPENSES",selectedCategory)
            // }
            formData.append(
              "BillNumber",
              billNumber ? Encrypt(key, JSON.stringify(billNumber)) : null
            );
            formData.append(
              "VendorName",
              vendorName ? Encrypt(key, JSON.stringify(vendorName)) : null
            );
            formData.append(
              "GSTNumber",
              gst ? Encrypt(key, JSON.stringify(gst)) : null
            );
            formData.append("booksqty", quantity);

            formData.append("CurrencyId", currency.currencyId);
            formData.append("BillAmount", billAmount);
            formData.append("ClaimedAmount", claimAmount);
            formData.append("ExpStatus", "In Progress");
            formData.append(
              "EmpEntityId",
              delEmpEntityId != null
                ? delEmpEntityId
                : ssoEmpIdDetails?.entityId
            );

            formData.append(
              "CreatedBy",
              Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
            );
            formData.append(
              "EmpId",
              delEmpIDRed != null
                ? Encrypt(key, JSON.stringify(delEmpIDRed))
                : Encrypt(key, JSON.stringify(location?.state?.ssoEmpId))
            );
            formData.append("PaymentMethodId", payment?.methodId);
            if (dependents) {
              formData.append("DependentId", dependents?.dependentId);
            }
            formData.append("ReceiptDate", selectedDate);
            formData.append("BillingCycleFromDate", fromDate || "");
            formData.append("BillingCycleToDate", toDate || "");
            formData.append("BillingCycleMonth", forMonth);
            formData.append("FromLocation", fromLocation);
            formData.append("ToLocation", toLocation);
            formData.append("Distance", inBetweenDistance || 0);

            formData.append("expenseProjLinks", JSON.stringify(filterproject));
            formData.append("expenseEmpLinks", JSON.stringify(filterAttendees));
            let tempFileList = [];
            for (let i = 0; i < fileList?.length; i++) {
              tempFileList.push({
                fileName: fileList[i].name,
                attachmentsize: fileList[i].size,
              });
            }
            formData.append("files", JSON.stringify(tempFileList));
            formData.append("traveltype", JSON.stringify(""));

            // formData.append("travelexpenses", JSON.stringify(selectedCategory));
            if (purpose.purpose !== "Travel") {
              formData.append("childExpenses", JSON.stringify([]));
            } else {
              formData.append(
                "childExpenses",
                JSON.stringify(selectedCategory)
              );
            }
            formData.append("expenseAdvancesLists", JSON.stringify([]));
            formData.append("expenseAdvancesDetails", JSON.stringify([]));
            dispatch(postCreateExpenseData(formData));
          }
          handleCloseEDB();
        }
      } else {
        setBillNumberError("Please enter unique bill number");
      }
    }
    // if(expenseCategory?.qtyEnabledfl === "0" )
    else {
      if (purpose.purpose == "Travel") {
        if (!selectedProjects[0]?.projectName?.projectID) {
          setProjectError1("*Please select the Project");
          // return; // Stop the submission if there's an error
        } else {
          setProjectError1("");
        }
        if (expenseName == "") {
          setExpenseNameError("*Please enter expense name.");
        } else {
          setExpenseNameError("");
        }
        var key = "E546C8DF278CD5931069B522E695D4F2";
        const result = Array.map((feild) =>
          FieldValidator(
            feild.validationType,
            feild.value,
            feild.claim,
            feild.validateList,
            feild.validateList2,
            feild.noOfMonths,
            feild.validateJoiningDate
          )
        );
        let arr = result.map((i) => i.bool);
        let checker = arr.every((element) => element === true);
        setValidationMsg(result);
        if (travelDestination == "") {
          setTravelDestinationMsg("*Please enter travel destination.");
        } else {
          setTravelDestinationMsg("");
        }
        if (travelStartDate == null) {
          setTravelstdtMsg("*Please enter start date");
        } else {
          setTravelstdtMsg("");
        }
        if (travelEndDate == null) {
          setTravelendtMsg("*Please enter travel end date.");
        } else {
          setTravelendtMsg("");
        }
        // if(travelStartDate != null && travelEndDate != null && travelStartDate > travelEndDate){
        //   setTravelendtMsg("*End date should be greater than start date");
        // }
        // else{
        //   setTravelendtMsg("");
        // }
        if (travelDetailType == "") {
          setTravelTypeMsg("*Please select travel type");
        } else {
          setTravelTypeMsg("");
        }
        selectedCategory.forEach((item, index) => {
          let arr = [...validationArray];
          arr[index].categoryMsg =
            item.categoryId === "" ? "*Please enter category" : "";
          arr[index].recieptMsg = !item.receiptdate
            ? "*Please select Invoice date"
            : "";
          arr[index].paymentMsg =
            item.paymentmethodid === "" ? "*Please select Payment Method" : "";
          arr[index].billAmtMsg =
            item.billamount === "" ? "*Please enter Bill Amount" : "";
          arr[index].claimAmtMsg =
            item.claimedamount === "" || item.claimedamount == 0
              ? "*Please enter Claim Amount"
              : item.claimedamount > item?.billamount
              ? "Claim Amount is greater than Bill Amount"
              : "";

          arr[index].attachmentMsg = getAttachmentMessage(item);
          setValidationArray(arr);
        });
        function getAttachmentMessage(item) {
          if (
            !item.expenseattachements.length ||
            item.expenseattachements[0]?.filename === ""
          ) {
            return "*Please attach at least one receipt";
          }
          if (
            uploadFileNameSpecialCharacterValidation(item.expenseattachements)
          ) {
            return "*Please enter a valid file name (no special characters allowed other than (,),-,_ )";
          }
          if (uploadFileNameCharLengthValidation(item.expenseattachements)) {
            return "*Please enter a valid file name (file name cannot be greater than 100 characters)";
          }
          if (dragDropFileValidationFileType(item.expenseattachements)) {
            return "*Only JPEG, JPG, XLS, XLSX, PNG and PDF allowed";
          }
          if (dragDropFileValidationUniqueFiles(item.expenseattachements)) {
            return "*Duplicate files are not allowed";
          }
          if (dragDropFileValidationTotalSize(item.expenseattachements)) {
            return "*Total size of attached receipts has exceeded 10 mb. Please remove some files, or add compressed files.";
          }
          return "";
        }

        const filterAttendees = [];
        let fileList1 = [];

        const filterproject = [];
        if (selectedProjects?.length > 0) {
          selectedProjects?.map((value) =>
            filterproject.push({
              projid: Encrypt(key, JSON.stringify(value.projectName.projId)),
              projamount: value.splitAmount || 0,
              billable: Encrypt(key, JSON.stringify(value.billable)),
            })
          );
        }

        let checkError = false;
        if (
          projectError1 != "" ||
          expenseNameError != "" ||
          expenseName == "" ||
          travelDestination == "" ||
          travelStartDate == null ||
          travelEndDate == null ||
          travelDetailType == "" ||
          travelStartDate > travelEndDate
        ) {
          setCheckErrEntries(true);
          checkErrorInside = true;
        }

        let boxMsg = result?.find(
          (item) => item.type == "Declaration CheckBox"
        )?.bool;
        validationArray.forEach((item, index) => {
          if (checkError) {
            return;
          }
          const errorTypes = [
            "attachmentMsg",
            "billAmtMsg",
            "categoryMsg",
            "claimAmtMsg",
            "currencyMsg",
            "paymentMsg",
            "recieptMsg",
          ];

          const hasError = errorTypes.some(
            (errorType) => item?.[errorType] != ""
          );
          if (hasError) {
            setCheckCategoryError(index);
            checkError = true;
            return;
          }
        });

        let formData = new FormData();
        let currencyVal = currency.currencyId;
        formData.append("ExpName", expenseName);

        formData.append("PurposeId", purpose.purposeId);
        formData.append("Purpose", purpose.purpose);
        formData.append("categoryCode", selectedCategory[0]?.categoryCode);
        formData.append("categoryId", selectedCategory[0]?.categoryId);
        formData.append("categoryName", selectedCategory[0]?.categoryName);
        if (expenseCategory.autoApproval == 0) {
          formData.append("exceptionapplicationflag", 0);
          formData.append("autoapprovalflag", 0);
        } else if (
          expenseCategory.autoApproval == 1 &&
          claimAmount > expenseCategory?.policyLimit
        ) {
          formData.append("exceptionapplicationflag", 1);
          formData.append("autoapprovalflag", 0);
        } else if (
          expenseCategory.autoApproval == 1 &&
          claimAmount <= expenseCategory?.policyLimit
        ) {
          formData.append("exceptionapplicationflag", 0);
          formData.append("autoapprovalflag", 1);
        }
        formData.append("BillingCycleFromDate", fromDate || "");
        formData.append(
          "BillNumber",
          billNumber ? Encrypt(key, JSON.stringify(billNumber)) : null
        );
        formData.append(
          "VendorName",
          vendorName ? Encrypt(key, JSON.stringify(vendorName)) : null
        );
        formData.append(
          "GSTNumber",
          gst ? Encrypt(key, JSON.stringify(gst)) : null
        );
        formData.append("booksqty", quantity);

        formData.append("ExpStatus", "In Progress");
        formData.append("currencyId", currencyVal);
        formData.append("BillAmount", selectedCategory[0]?.billamount);
        formData.append("ClaimedAmount", selectedCategory[0]?.claimedamount);
        formData.append("BillingCycleToDate", toDate || "");
        formData.append("BillingCycleMonth", forMonth);
        formData.append("FromLocation", fromLocation);
        formData.append("ToLocation", toLocation);
        formData.append("Distance", 0);
        formData.append(
          "PaymentMethodId",
          selectedCategory[0]?.paymentmethodid
        );
        formData.append("ReceiptDate", selectedCategory[0]?.receiptdate);
        formData.append(
          "EmpEntityId",
          delEmpEntityId != null ? delEmpEntityId : ssoEmpIdDetails?.entityId
        );
        formData.append(
          "CreatedBy",
          Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
        );
        formData.append(
          "EmpId",
          delEmpIDRed != null
            ? Encrypt(key, JSON.stringify(delEmpIDRed))
            : Encrypt(key, JSON.stringify(location?.state?.ssoEmpId))
        );
        if (dependents) {
          formData.append("DependentId", dependents?.dependentId);
        }
        formData.append("expenseProjLinks", JSON.stringify(filterproject));
        formData.append(
          "expenseEmpLinks",
          JSON.stringify(filterAttendees ? filterAttendees : [])
        );
        let tempFileList = selectedCategory[0]?.expenseattachements;
        formData.append("files", JSON.stringify(tempFileList));
        formData.append("traveltype", travelDetailType);
        {
          selectedCategory.map((item) => {
            if (item.currencyId == "") {
              item.currencyId = currencyVal;
            }
          });
        }
        if (selectedCategory?.length == 1) {
          formData.append("childExpenses", JSON.stringify([]));
        } else {
          let arr123 = [...selectedCategory];
          arr123.splice(0, 1);

          formData.append("childExpenses", JSON.stringify(arr123));
        }
        formData.append(
          "expenseAdvancesLists",
          JSON.stringify(
            travelDetailType == "Domestic"
              ? []
              : advancesList[0]?.depositdate != null ||
                advancesList[0]?.currency != "" ||
                advancesList[0]?.totaladvance != 0 ||
                advancesList[0]?.advancetype != ""
              ? advancesList
              : []
          )
        );
        formData.append(
          "expenseAdvancesDetails",
          JSON.stringify(advancesJson[0])
        );
        if (!checkError && !checkErrorInside && boxMsg) {
          dispatch(postCreateExpenseData(formData));
          handleCloseEDB();
          setDisableSubmit(true);
          checkError = false;
        } else {
          setStatusSnackbarOpen(true);
          setDisableSubmit(true);
          checkError = false;
        }
      }
      // travel expense form submission ends here
      else {
        if (purpose.purpose == "Medical Expenses") {
          if (dependents?.length == 0) {
            setDependentsError("*Please enter Relationship");
          } else {
            setDependentsError("");
          }
        }
        if (quantity === 0 || quantity === null || quantity === "") {
          setQuantityError("Please enter quantity");
        } else setQuantityError("");
        var key = "E546C8DF278CD5931069B522E695D4F2";
        const result = Array.map((feild) =>
          FieldValidator(
            feild.validationType,
            feild.value,
            feild.claim,
            feild.validateList,
            feild.validateList2,
            feild.noOfMonths,
            feild.validateJoiningDate
          )
        );
        setValidationMsg(result);
        let arr = result.map((i) => i.bool);
        let checker = arr.every((element) => element === true);
        if (dependentsError != "") {
          checker = false;
        }

        if (checker) {
          setDisableSubmit(true);
          const filterproject = [];
          const filterAttendees = [];
          let fileList1 = [];
          if (selectedProjects?.length > 0) {
            selectedProjects?.map((value) =>
              filterproject.push({
                projid: Encrypt(key, JSON.stringify(value.projectName.projId)),
                projamount: value.splitAmount,
                billable: Encrypt(key, JSON.stringify(value.billable)),
              })
            );
          }

          if (attendees?.length > 0) {
            attendees?.map((value) =>
              filterAttendees.push({
                empid: Encrypt(key, JSON.stringify(value.empId)),
                // empid: "5",
                empamount: claimAmount / attendees?.length,
              })
            );
          }
          let formData = new FormData();
          // console.log("FORM1",purpose.purpose,selectedCategory.categorycode)
          formData.append("ExpName", expenseName);
          formData.append("PurposeId", purpose.purposeId);
          formData.append("Purpose", purpose.purpose);
          formData.append("CategoryCode", expenseCategory.categoryCode);
          formData.append("CategoryId", expenseCategory.categoryId);

          formData.append("CategoryName", expenseCategory.categoryName);
          if (expenseCategory.autoApproval == 0) {
            formData.append("exceptionapplicationflag", 0);
            formData.append("autoapprovalflag", 0);
          } else if (
            expenseCategory.autoApproval == 1 &&
            claimAmount > expenseCategory?.policyLimit
          ) {
            formData.append("exceptionapplicationflag", 1);
            formData.append("autoapprovalflag", 0);
          } else if (
            expenseCategory.autoApproval == 1 &&
            claimAmount <= expenseCategory?.policyLimit
          ) {
            formData.append("exceptionapplicationflag", 0);
            formData.append("autoapprovalflag", 1);
          }
          // if(purpose.purpose=="Travel"){
          //   formData.append("TravelEXPENSES",selectedCategory)
          // }
          formData.append(
            "BillNumber",
            billNumber ? Encrypt(key, JSON.stringify(billNumber)) : null
          );
          formData.append(
            "VendorName",
            vendorName ? Encrypt(key, JSON.stringify(vendorName)) : null
          );
          formData.append(
            "GSTNumber",
            gst ? Encrypt(key, JSON.stringify(gst)) : null
          );
          formData.append("booksqty", quantity);

          formData.append("CurrencyId", currency.currencyId);
          formData.append("BillAmount", billAmount);
          formData.append("ClaimedAmount", claimAmount);
          formData.append("ExpStatus", "In Progress");
          formData.append(
            "EmpEntityId",
            delEmpEntityId != null ? delEmpEntityId : ssoEmpIdDetails?.entityId
          );

          formData.append(
            "CreatedBy",
            Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
          );
          formData.append(
            "EmpId",
            delEmpIDRed != null
              ? Encrypt(key, JSON.stringify(delEmpIDRed))
              : Encrypt(key, JSON.stringify(location?.state?.ssoEmpId))
          );
          formData.append("PaymentMethodId", payment?.methodId);
          if (dependents) {
            formData.append("DependentId", dependents?.dependentId);
          }
          formData.append("ReceiptDate", selectedDate);
          formData.append("BillingCycleFromDate", fromDate || "");
          formData.append("BillingCycleToDate", toDate || "");
          formData.append("BillingCycleMonth", forMonth);
          formData.append("FromLocation", fromLocation);
          formData.append("ToLocation", toLocation);
          formData.append("Distance", inBetweenDistance || 0);

          formData.append("expenseProjLinks", JSON.stringify(filterproject));
          formData.append("expenseEmpLinks", JSON.stringify(filterAttendees));
          let tempFileList = [];
          for (let i = 0; i < fileList?.length; i++) {
            tempFileList.push({
              fileName: fileList[i].name,
              attachmentsize: fileList[i].size,
            });
          }
          formData.append("files", JSON.stringify(tempFileList));
          formData.append("traveltype", JSON.stringify(""));

          // formData.append("travelexpenses", JSON.stringify(selectedCategory));
          if (purpose.purpose !== "Travel") {
            formData.append("childExpenses", JSON.stringify([]));
          } else {
            formData.append("childExpenses", JSON.stringify(selectedCategory));
          }
          formData.append("expenseAdvancesLists", JSON.stringify([]));
          formData.append("expenseAdvancesDetails", JSON.stringify([]));
          dispatch(postCreateExpenseData(formData));
        }
        handleCloseEDB();
      }
    }

    // console.log("inside handleclick", billNumber);
  };

  const handleSave = () => {
    setBillExistence(true);

    // all the code from here is for travel expense form submission
    if (expenseCategory?.qtyEnabledfl === "1") {
      if (billNumberExistence?.billExistFlag == 0) {
        if (purpose.purpose == "Travel") {
          if (!selectedProjects[0]?.projectName?.projectID) {
            setProjectError1("*Please select the Project");
            // return; // Stop the submission if there's an error
          } else {
            setProjectError1("");
          }
          if (expenseName == "") {
            setExpenseNameError("*Please enter expense name.");
          } else {
            setExpenseNameError("");
          }
          if (quantity === 0 || quantity === null || quantity === "") {
            setQuantityError("Please enter quantity");
          } else setQuantityError("");
          var key = "E546C8DF278CD5931069B522E695D4F2";
          const result = Array.map((feild) =>
            FieldValidator(
              feild.validationType,
              feild.value,
              feild.claim,
              feild.validateList,
              feild.validateList2,
              feild.noOfMonths,
              feild.validateJoiningDate
            )
          );
          let arr = result.map((i) => i.bool);
          let checker = arr.every((element) => element === true);
          setValidationMsg(result);
          if (travelDestination == "") {
            setTravelDestinationMsg("*Please enter travel destination.");
          } else {
            setTravelDestinationMsg("");
          }
          if (travelStartDate == null) {
            setTravelstdtMsg("*Please enter start date");
          } else {
            setTravelstdtMsg("");
          }
          if (travelEndDate == null) {
            setTravelendtMsg("*Please enter travel end date.");
          } else {
            setTravelendtMsg("");
          }
          if (travelDetailType == "") {
            setTravelTypeMsg("*Please select travel type");
          } else {
            setTravelTypeMsg("");
          }
          selectedCategory.forEach((item, index) => {
            let arr = [...validationArray];
            arr[index].categoryMsg =
              item.categoryId === "" ? "*Please enter category" : "";
            arr[index].recieptMsg = !item.receiptdate
              ? "*Please select Invoice date"
              : "";
            arr[index].paymentMsg =
              item.paymentmethodid === ""
                ? "*Please select Payment Method"
                : "";
            arr[index].billAmtMsg =
              item.billamount === "" ? "*Please enter Bill Amount" : "";
            arr[index].claimAmtMsg =
              item.claimedamount === "" || item.claimedamount == 0
                ? "*Please enter Claim Amount"
                : item.claimedamount > item?.billamount
                ? "Claim Amount is greater than Bill Amount"
                : "";
            arr[index].attachmentMsg = getAttachmentMessage(item);
            setValidationArray(arr);
          });
          function getAttachmentMessage(item) {
            if (
              !item.expenseattachements.length ||
              item.expenseattachements[0]?.filename === ""
            ) {
              return "*Please attach at least one receipt";
            }
            if (
              uploadFileNameSpecialCharacterValidation(item.expenseattachements)
            ) {
              return "*Please enter a valid file name (no special characters allowed other than (,),-,_ )";
            }
            if (uploadFileNameCharLengthValidation(item.expenseattachements)) {
              return "*Please enter a valid file name (file name cannot be greater than 100 characters)";
            }
            if (dragDropFileValidationFileType(item.expenseattachements)) {
              return "*Only JPEG, JPG, XLS, XLSX, PNG and PDF allowed";
            }
            if (dragDropFileValidationUniqueFiles(item.expenseattachements)) {
              return "*Duplicate files are not allowed";
            }
            if (dragDropFileValidationTotalSize(item.expenseattachements)) {
              return "*Total size of attached receipts has exceeded 10 mb. Please remove some files, or add compressed files.";
            }
            return "";
          }
          const filterAttendees = [];
          let fileList1 = [];

          const filterproject = [];
          if (selectedProjects?.length > 0) {
            selectedProjects?.map((value) =>
              filterproject.push({
                projid: Encrypt(key, JSON.stringify(value.projectName.projId)),
                projamount: value.splitAmount || 0,
                billable: Encrypt(key, JSON.stringify(value.billable)),
              })
            );
          }

          let checkError = false;
          checkErrorInside = false;
          if (
            projectError1 != "" ||
            expenseNameError != "" ||
            expenseName == "" ||
            travelDestination == "" ||
            travelStartDate == null ||
            travelEndDate == null ||
            travelDetailType == ""
          ) {
            setCheckErrEntries(true);
            checkErrorInside = true;
          }

          let boxMsg = result?.find(
            (item) => item.type == "Declaration CheckBox"
          )?.bool;
          validationArray.forEach((item, index) => {
            if (checkError) {
              return;
            }
            const errorTypes = [
              "attachmentMsg",
              "billAmtMsg",
              "categoryMsg",
              "claimAmtMsg",
              "currencyMsg",
              "paymentMsg",
              "recieptMsg",
            ];

            const hasError = errorTypes.some(
              (errorType) => item?.[errorType] != ""
            );
            if (hasError) {
              setCheckCategoryError(index);
              checkError = true;
              return;
            }
          });
          let formData = new FormData();
          let currencyVal = currency.currencyId;
          formData.append("ExpName", expenseName);
          formData.append("PurposeId", purpose.purposeId);
          formData.append("Purpose", purpose.purpose);
          formData.append("categoryCode", selectedCategory[0]?.categoryCode);
          formData.append("categoryId", selectedCategory[0]?.categoryId);
          formData.append("categoryName", selectedCategory[0]?.categoryName);
          if (expenseCategory.autoApproval == 0) {
            formData.append("exceptionapplicationflag", 0);
            formData.append("autoapprovalflag", 0);
          } else if (
            expenseCategory.autoApproval == 1 &&
            claimAmount > expenseCategory?.policyLimit
          ) {
            formData.append("exceptionapplicationflag", 1);
            formData.append("autoapprovalflag", 0);
          } else if (
            expenseCategory.autoApproval == 1 &&
            claimAmount <= expenseCategory?.policyLimit
          ) {
            formData.append("exceptionapplicationflag", 0);
            formData.append("autoapprovalflag", 1);
          }
          formData.append("BillingCycleFromDate", fromDate || "");
          formData.append(
            "BillNumber",
            billNumber ? Encrypt(key, JSON.stringify(billNumber)) : null
          );
          formData.append(
            "VendorName",
            vendorName ? Encrypt(key, JSON.stringify(vendorName)) : null
          );
          formData.append(
            "GSTNumber",
            gst ? Encrypt(key, JSON.stringify(gst)) : null
          );
          formData.append("booksqty", quantity);

          formData.append("ExpStatus", "Draft");
          formData.append("currencyId", currencyVal);
          formData.append("BillAmount", selectedCategory[0]?.billamount);
          formData.append("ClaimedAmount", selectedCategory[0]?.claimedamount);
          formData.append("BillingCycleToDate", toDate || "");
          formData.append("BillingCycleMonth", forMonth);
          formData.append("FromLocation", fromLocation);
          formData.append("ToLocation", toLocation);
          formData.append("Distance", inBetweenDistance || 0);
          formData.append(
            "PaymentMethodId",
            selectedCategory[0]?.paymentmethodid
          );
          formData.append("ReceiptDate", selectedCategory[0]?.receiptdate);
          formData.append(
            "EmpEntityId",
            delEmpEntityId != null ? delEmpEntityId : ssoEmpIdDetails?.entityId
          );
          formData.append(
            "CreatedBy",
            Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
          );
          formData.append(
            "EmpId",
            delEmpIDRed != null
              ? Encrypt(key, JSON.stringify(delEmpIDRed))
              : Encrypt(key, JSON.stringify(location?.state?.ssoEmpId))
          );
          if (dependents) {
            formData.append("DependentId", dependents?.dependentId);
          }
          formData.append("expenseProjLinks", JSON.stringify(filterproject));
          formData.append(
            "expenseEmpLinks",
            JSON.stringify(filterAttendees ? filterAttendees : [])
          );
          let tempFileList = selectedCategory[0]?.expenseattachements;
          formData.append("files", JSON.stringify(tempFileList));
          formData.append("traveltype", travelDetailType);

          {
            selectedCategory.map((item) => {
              if (item.currencyId == "") {
                item.currencyId = currencyVal;
              }
            });
          }
          if (selectedCategory?.length == 1) {
            formData.append("childExpenses", JSON.stringify([]));
          } else {
            let arr123 = [...selectedCategory];
            arr123.splice(0, 1);

            formData.append("childExpenses", JSON.stringify(arr123));
          }
          formData.append(
            "expenseAdvancesLists",
            JSON.stringify(
              travelDetailType == "Domestic"
                ? []
                : advancesList[0]?.depositdate != null ||
                  advancesList[0]?.currency != "" ||
                  advancesList[0]?.totaladvance != 0 ||
                  advancesList[0]?.advancetype != ""
                ? advancesList
                : []
            )
          );

          formData.append(
            "expenseAdvancesDetails",
            JSON.stringify(advancesJson[0])
          );
          if (!checkError && !checkErrorInside && boxMsg) {
            dispatch(postCreateExpenseData(formData));
            handleCloseEDB();
            setDisableSubmit(true);
          } else {
            setStatusSnackbarOpen(true);
            setDisableSubmit(true);
          }
          // console.log("control is before dispatch1", formData);
        }
        // travel expense form submission ends here
        else {
          if (purpose.purpose == "Medical Expenses") {
            if (dependents?.length == 0) {
              setDependentsError("*Please enter Relationship");
            } else {
              setDependentsError("");
            }
          }
          var key = "E546C8DF278CD5931069B522E695D4F2";
          const result = Array.map((feild) =>
            FieldValidator(
              feild.validationType,
              feild.value,
              feild.claim,
              feild.validateList,
              feild.validateList2,
              feild.noOfMonths,
              feild.validateJoiningDate
            )
          );
          setValidationMsg(result);
          let arr = result.map((i) => i.bool);
          let checker = arr.every((element) => element === true);
          if (dependentsError != "") {
            checker = false;
          }

          if (checker) {
            setDisableSubmit(true);
            const filterproject = [];
            const filterAttendees = [];
            let fileList1 = [];
            if (selectedProjects?.length > 0) {
              selectedProjects?.map((value) =>
                filterproject.push({
                  projid: Encrypt(
                    key,
                    JSON.stringify(value.projectName.projId)
                  ),
                  projamount: value.splitAmount,
                  billable: Encrypt(key, JSON.stringify(value.billable)),
                })
              );
            }

            if (attendees?.length > 0) {
              attendees?.map((value) =>
                filterAttendees.push({
                  empid: Encrypt(key, JSON.stringify(value.empId)),
                  // empid: "5",
                  empamount: claimAmount / attendees?.length,
                })
              );
            }
            let formData = new FormData();
            // console.log("FORM1",purpose.purpose,selectedCategory.categorycode)
            formData.append("ExpName", expenseName);
            formData.append("PurposeId", purpose.purposeId);
            formData.append("Purpose", purpose.purpose);
            formData.append("CategoryCode", expenseCategory.categoryCode);
            formData.append("CategoryId", expenseCategory.categoryId);

            formData.append("CategoryName", expenseCategory.categoryName);
            if (expenseCategory.autoApproval == 0) {
              formData.append("exceptionapplicationflag", 0);
              formData.append("autoapprovalflag", 0);
            } else if (
              expenseCategory.autoApproval == 1 &&
              claimAmount > expenseCategory?.policyLimit
            ) {
              formData.append("exceptionapplicationflag", 1);
              formData.append("autoapprovalflag", 0);
            } else if (
              expenseCategory.autoApproval == 1 &&
              claimAmount <= expenseCategory?.policyLimit
            ) {
              formData.append("exceptionapplicationflag", 0);
              formData.append("autoapprovalflag", 1);
            }
            // if(purpose.purpose=="Travel"){
            //   formData.append("TravelEXPENSES",selectedCategory)
            // }
            formData.append(
              "BillNumber",
              billNumber ? Encrypt(key, JSON.stringify(billNumber)) : null
            );
            formData.append(
              "VendorName",
              vendorName ? Encrypt(key, JSON.stringify(vendorName)) : null
            );
            formData.append(
              "GSTNumber",
              gst ? Encrypt(key, JSON.stringify(gst)) : null
            );
            formData.append("booksqty", quantity);

            formData.append("CurrencyId", currency.currencyId);
            formData.append("BillAmount", billAmount);
            formData.append("ClaimedAmount", claimAmount);
            formData.append("ExpStatus", "Draft");
            formData.append(
              "EmpEntityId",
              delEmpEntityId != null
                ? delEmpEntityId
                : ssoEmpIdDetails?.entityId
            );

            formData.append(
              "CreatedBy",
              Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
            );
            formData.append(
              "EmpId",
              delEmpIDRed != null
                ? Encrypt(key, JSON.stringify(delEmpIDRed))
                : Encrypt(key, JSON.stringify(location?.state?.ssoEmpId))
            );
            formData.append("PaymentMethodId", payment?.methodId);
            if (dependents) {
              formData.append("DependentId", dependents?.dependentId);
            }
            formData.append("ReceiptDate", selectedDate);
            formData.append("BillingCycleFromDate", fromDate || "");
            formData.append("BillingCycleToDate", toDate || "");
            formData.append("BillingCycleMonth", forMonth);
            formData.append("FromLocation", fromLocation);
            formData.append("ToLocation", toLocation);
            formData.append("Distance", inBetweenDistance);

            formData.append("expenseProjLinks", JSON.stringify(filterproject));
            formData.append("expenseEmpLinks", JSON.stringify(filterAttendees));
            let tempFileList = [];
            for (let i = 0; i < fileList?.length; i++) {
              tempFileList.push({
                fileName: fileList[i].name,
                attachmentsize: fileList[i].size,
              });
            }
            formData.append("files", JSON.stringify(tempFileList));
            formData.append("traveltype", JSON.stringify(""));

            // formData.append("travelexpenses", JSON.stringify(selectedCategory));
            if (purpose.purpose !== "Travel") {
              formData.append("childExpenses", JSON.stringify([]));
            } else {
              formData.append(
                "childExpenses",
                JSON.stringify(selectedCategory)
              );
            }
            formData.append("expenseAdvancesLists", JSON.stringify([]));
            formData.append("expenseAdvancesDetails", JSON.stringify([]));

            dispatch(postCreateExpenseData(formData));
          }
          handleCloseEDB();
        }
      } else {
        setBillNumberError("Please enter unique bill number");
      }
    }
    // if(expenseCategory?.qtyEnabledfl === "0" )
    else {
      if (purpose.purpose == "Travel") {
        if (!selectedProjects[0]?.projectName?.projectID) {
          setProjectError1("*Please select the Project");
          // return; // Stop the submission if there's an error
        } else {
          setProjectError1("");
        }
        if (expenseName == "") {
          setExpenseNameError("*Please enter expense name.");
        } else {
          setExpenseNameError("");
        }
        if (quantity === 0 || quantity === null || quantity === "") {
          setQuantityError("Please enter quantity");
        } else setQuantityError("");
        var key = "E546C8DF278CD5931069B522E695D4F2";
        const result = Array.map((feild) =>
          FieldValidator(
            feild.validationType,
            feild.value,
            feild.claim,
            feild.validateList,
            feild.validateList2,
            feild.noOfMonths,
            feild.validateJoiningDate
          )
        );
        let arr = result.map((i) => i.bool);
        let checker = arr.every((element) => element === true);
        setValidationMsg(result);
        if (travelDestination == "") {
          setTravelDestinationMsg("*Please enter travel destination.");
        } else {
          setTravelDestinationMsg("");
        }
        if (travelStartDate == null) {
          setTravelstdtMsg("*Please enter start date");
        } else {
          setTravelstdtMsg("");
        }
        if (travelEndDate == null) {
          setTravelendtMsg("*Please enter travel end date.");
        } else {
          setTravelendtMsg("");
        }
        if (travelDetailType == "") {
          setTravelTypeMsg("*Please select travel type");
        } else {
          setTravelTypeMsg("");
        }
        selectedCategory.forEach((item, index) => {
          let arr = [...validationArray];
          arr[index].categoryMsg =
            item.categoryId === "" ? "*Please enter category" : "";
          arr[index].recieptMsg = !item.receiptdate
            ? "*Please select Invoice date"
            : "";
          arr[index].paymentMsg =
            item.paymentmethodid === "" ? "*Please select Payment Method" : "";
          arr[index].billAmtMsg =
            item.billamount === "" ? "*Please enter Bill Amount" : "";
          arr[index].claimAmtMsg =
            item.claimedamount === "" || item.claimedamount == 0
              ? "*Please enter Claim Amount"
              : item.claimedamount > item?.billamount
              ? "Claim Amount is greater than Bill Amount"
              : "";
          arr[index].attachmentMsg = getAttachmentMessage(item);
          setValidationArray(arr);
        });
        function getAttachmentMessage(item) {
          if (
            !item.expenseattachements.length ||
            item.expenseattachements[0]?.filename === ""
          ) {
            return "*Please attach at least one receipt";
          }
          if (
            uploadFileNameSpecialCharacterValidation(item.expenseattachements)
          ) {
            return "*Please enter a valid file name (no special characters allowed other than (,),-,_ )";
          }
          if (uploadFileNameCharLengthValidation(item.expenseattachements)) {
            return "*Please enter a valid file name (file name cannot be greater than 100 characters)";
          }
          if (dragDropFileValidationFileType(item.expenseattachements)) {
            return "*Only JPEG, JPG, XLS, XLSX, PNG and PDF allowed";
          }
          if (dragDropFileValidationUniqueFiles(item.expenseattachements)) {
            return "*Duplicate files are not allowed";
          }
          if (dragDropFileValidationTotalSize(item.expenseattachements)) {
            return "*Total size of attached receipts has exceeded 10 mb. Please remove some files, or add compressed files.";
          }
          return "";
        }
        const filterAttendees = [];
        let fileList1 = [];

        const filterproject = [];
        if (selectedProjects?.length > 0) {
          selectedProjects?.map((value) =>
            filterproject.push({
              projid: Encrypt(key, JSON.stringify(value.projectName.projId)),
              projamount: value.splitAmount || 0,
              billable: Encrypt(key, JSON.stringify(value.billable)),
            })
          );
        }

        let checkError = false;
        checkErrorInside = false;
        if (
          projectError1 != "" ||
          expenseNameError != "" ||
          expenseName == "" ||
          travelDestination == "" ||
          travelStartDate == null ||
          travelEndDate == null ||
          travelDetailType == ""
        ) {
          setCheckErrEntries(true);
          checkErrorInside = true;
        }

        let boxMsg = result?.find(
          (item) => item.type == "Declaration CheckBox"
        )?.bool;
        validationArray.forEach((item, index) => {
          if (checkError) {
            return;
          }
          const errorTypes = [
            "attachmentMsg",
            "billAmtMsg",
            "categoryMsg",
            "claimAmtMsg",
            "currencyMsg",
            "paymentMsg",
            "recieptMsg",
          ];

          const hasError = errorTypes.some(
            (errorType) => item?.[errorType] != ""
          );
          if (hasError) {
            setCheckCategoryError(index);
            checkError = true;
            return;
          }
        });
        let formData = new FormData();
        let currencyVal = currency.currencyId;
        formData.append("ExpName", expenseName);
        formData.append("PurposeId", purpose.purposeId);
        formData.append("Purpose", purpose.purpose);
        formData.append("categoryCode", selectedCategory[0]?.categoryCode);
        formData.append("categoryId", selectedCategory[0]?.categoryId);
        formData.append("categoryName", selectedCategory[0]?.categoryName);
        if (expenseCategory.autoApproval == 0) {
          formData.append("exceptionapplicationflag", 0);
          formData.append("autoapprovalflag", 0);
        } else if (
          expenseCategory.autoApproval == 1 &&
          claimAmount > expenseCategory?.policyLimit
        ) {
          formData.append("exceptionapplicationflag", 1);
          formData.append("autoapprovalflag", 0);
        } else if (
          expenseCategory.autoApproval == 1 &&
          claimAmount <= expenseCategory?.policyLimit
        ) {
          formData.append("exceptionapplicationflag", 0);
          formData.append("autoapprovalflag", 1);
        }
        formData.append("BillingCycleFromDate", fromDate || "");
        formData.append(
          "BillNumber",
          billNumber ? Encrypt(key, JSON.stringify(billNumber)) : null
        );
        formData.append(
          "VendorName",
          vendorName ? Encrypt(key, JSON.stringify(vendorName)) : null
        );
        formData.append(
          "GSTNumber",
          gst ? Encrypt(key, JSON.stringify(gst)) : null
        );
        formData.append("booksqty", quantity);

        formData.append("ExpStatus", "Draft");
        formData.append("currencyId", currencyVal);
        formData.append("BillAmount", selectedCategory[0]?.billamount);
        formData.append("ClaimedAmount", selectedCategory[0]?.claimedamount);
        formData.append("BillingCycleToDate", toDate || "");
        formData.append("BillingCycleMonth", forMonth);
        formData.append("FromLocation", fromLocation);
        formData.append("ToLocation", toLocation);
        formData.append("Distance", inBetweenDistance || 0);
        formData.append(
          "PaymentMethodId",
          selectedCategory[0]?.paymentmethodid
        );
        formData.append("ReceiptDate", selectedCategory[0]?.receiptdate);
        formData.append(
          "EmpEntityId",
          delEmpEntityId != null ? delEmpEntityId : ssoEmpIdDetails?.entityId
        );
        formData.append(
          "CreatedBy",
          Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
        );
        formData.append(
          "EmpId",
          delEmpIDRed != null
            ? Encrypt(key, JSON.stringify(delEmpIDRed))
            : Encrypt(key, JSON.stringify(location?.state?.ssoEmpId))
        );
        if (dependents) {
          formData.append("DependentId", dependents?.dependentId);
        }
        formData.append("expenseProjLinks", JSON.stringify(filterproject));
        formData.append(
          "expenseEmpLinks",
          JSON.stringify(filterAttendees ? filterAttendees : [])
        );
        let tempFileList = selectedCategory[0]?.expenseattachements;
        formData.append("files", JSON.stringify(tempFileList));
        formData.append("traveltype", travelDetailType);

        {
          selectedCategory.map((item) => {
            if (item.currencyId == "") {
              item.currencyId = currencyVal;
            }
          });
        }
        if (selectedCategory?.length == 1) {
          formData.append("childExpenses", JSON.stringify([]));
        } else {
          let arr123 = [...selectedCategory];
          arr123.splice(0, 1);

          formData.append("childExpenses", JSON.stringify(arr123));
        }
        formData.append(
          "expenseAdvancesLists",
          JSON.stringify(
            travelDetailType == "Domestic"
              ? []
              : advancesList[0]?.depositdate != null ||
                advancesList[0]?.currency != "" ||
                advancesList[0]?.totaladvance != 0 ||
                advancesList[0]?.advancetype != ""
              ? advancesList
              : []
          )
        );

        formData.append(
          "expenseAdvancesDetails",
          JSON.stringify(advancesJson[0])
        );
        if (!checkError && !checkErrorInside && boxMsg) {
          dispatch(postCreateExpenseData(formData));
          handleCloseEDB();
          setDisableSubmit(true);
        } else {
          setStatusSnackbarOpen(true);
          setDisableSubmit(true);
        }
        // console.log("control is before dispatch1", formData);
      }
      // travel expense form submission ends here
      else {
        if (purpose.purpose == "Medical Expenses") {
          if (dependents?.length == 0) {
            setDependentsError("*Please enter Relationship");
          } else {
            setDependentsError("");
          }
        }
        var key = "E546C8DF278CD5931069B522E695D4F2";
        const result = Array.map((feild) =>
          FieldValidator(
            feild.validationType,
            feild.value,
            feild.claim,
            feild.validateList,
            feild.validateList2,
            feild.noOfMonths,
            feild.validateJoiningDate
          )
        );
        setValidationMsg(result);
        let arr = result.map((i) => i.bool);
        let checker = arr.every((element) => element === true);
        if (dependentsError != "") {
          checker = false;
        }

        if (checker) {
          setDisableSubmit(true);
          const filterproject = [];
          const filterAttendees = [];
          let fileList1 = [];
          if (selectedProjects?.length > 0) {
            selectedProjects?.map((value) =>
              filterproject.push({
                projid: Encrypt(key, JSON.stringify(value.projectName.projId)),
                projamount: value.splitAmount,
                billable: Encrypt(key, JSON.stringify(value.billable)),
              })
            );
          }

          if (attendees?.length > 0) {
            attendees?.map((value) =>
              filterAttendees.push({
                empid: Encrypt(key, JSON.stringify(value.empId)),
                // empid: "5",
                empamount: claimAmount / attendees?.length,
              })
            );
          }
          let formData = new FormData();
          // console.log("FORM1",purpose.purpose,selectedCategory.categorycode)
          formData.append("ExpName", expenseName);
          formData.append("PurposeId", purpose.purposeId);
          formData.append("Purpose", purpose.purpose);
          formData.append("CategoryCode", expenseCategory.categoryCode);
          formData.append("CategoryId", expenseCategory.categoryId);

          formData.append("CategoryName", expenseCategory.categoryName);
          if (expenseCategory.autoApproval == 0) {
            formData.append("exceptionapplicationflag", 0);
            formData.append("autoapprovalflag", 0);
          } else if (
            expenseCategory.autoApproval == 1 &&
            claimAmount > expenseCategory?.policyLimit
          ) {
            formData.append("exceptionapplicationflag", 1);
            formData.append("autoapprovalflag", 0);
          } else if (
            expenseCategory.autoApproval == 1 &&
            claimAmount <= expenseCategory?.policyLimit
          ) {
            formData.append("exceptionapplicationflag", 0);
            formData.append("autoapprovalflag", 1);
          }
          // if(purpose.purpose=="Travel"){
          //   formData.append("TravelEXPENSES",selectedCategory)
          // }
          formData.append(
            "BillNumber",
            billNumber ? Encrypt(key, JSON.stringify(billNumber)) : null
          );
          formData.append(
            "VendorName",
            vendorName ? Encrypt(key, JSON.stringify(vendorName)) : null
          );
          formData.append(
            "GSTNumber",
            gst ? Encrypt(key, JSON.stringify(gst)) : null
          );
          formData.append("booksqty", quantity);

          formData.append("CurrencyId", currency.currencyId);
          formData.append("BillAmount", billAmount);
          formData.append("ClaimedAmount", claimAmount);
          formData.append("ExpStatus", "Draft");
          formData.append(
            "EmpEntityId",
            delEmpEntityId != null ? delEmpEntityId : ssoEmpIdDetails?.entityId
          );

          formData.append(
            "CreatedBy",
            Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
          );
          formData.append(
            "EmpId",
            delEmpIDRed != null
              ? Encrypt(key, JSON.stringify(delEmpIDRed))
              : Encrypt(key, JSON.stringify(location?.state?.ssoEmpId))
          );
          formData.append("PaymentMethodId", payment?.methodId);
          if (dependents) {
            formData.append("DependentId", dependents?.dependentId);
          }
          formData.append("ReceiptDate", selectedDate);
          formData.append("BillingCycleFromDate", fromDate || "");
          formData.append("BillingCycleToDate", toDate || "");
          formData.append("BillingCycleMonth", forMonth);
          formData.append("FromLocation", fromLocation);
          formData.append("ToLocation", toLocation);
          formData.append("Distance", inBetweenDistance);

          formData.append("expenseProjLinks", JSON.stringify(filterproject));
          formData.append("expenseEmpLinks", JSON.stringify(filterAttendees));
          let tempFileList = [];
          for (let i = 0; i < fileList?.length; i++) {
            tempFileList.push({
              fileName: fileList[i].name,
              attachmentsize: fileList[i].size,
            });
          }
          formData.append("files", JSON.stringify(tempFileList));
          formData.append("traveltype", JSON.stringify(""));

          // formData.append("travelexpenses", JSON.stringify(selectedCategory));
          if (purpose.purpose !== "Travel") {
            formData.append("childExpenses", JSON.stringify([]));
          } else {
            formData.append("childExpenses", JSON.stringify(selectedCategory));
          }
          formData.append("expenseAdvancesLists", JSON.stringify([]));
          formData.append("expenseAdvancesDetails", JSON.stringify([]));

          dispatch(postCreateExpenseData(formData));
        }
        handleCloseEDB();
      }
    }
  };
  useEffect(() => {
    // dispatch(getPurposeData());
    if (
      delEmpIDRed != null &&
      delEmpIDRed != "" &&
      delEmpIDRed != ssoEmpIdDetails?.empId &&
      ssoEmpIdDetails != null &&
      ssoEmpIdDetails != ""
    ) {
      let tempUser = myDelegateesData?.filter(
        (item) => item.empId == delEmpIDRed
      );
      let obj = {
        // pId: purpose.purposeId,
        entityId: tempUser[0]?.entityId,
      };
      dispatch(getPurposeData(obj));
    } else {
      let obj = {
        //pId: purpose.purposeId,
        entityId: ssoEmpIdDetails?.entityId,
      };
      dispatch(getPurposeData(obj));
      // }
    }
    dispatch(
      getCurrencyData(
        delEmpIDRed != null &&
          delEmpIDRed != "" &&
          delEmpIDRed != ssoEmpIdDetails?.empId
          ? delEmpIDRed
          : ssoEmpIdDetails?.empId
      )
    );
    dispatch(
      getAttendeesData(
        delEmpIDRed != null &&
          delEmpIDRed != "" &&
          delEmpIDRed != ssoEmpIdDetails?.empId
          ? delEmpIDRed
          : ssoEmpIdDetails?.empId
      )
    );
    dispatch(getMyDelegateesData(ssoEmpIdDetails?.empId));
    dispatch(getEmpDetail(account?.username));
    dispatch(getCategoryData());
  }, []);
  // console.log("trjv", account);
  useEffect(() => {
    if (myDelegateesData != null && myDelegateesData != undefined) {
      let tempDel = myDelegateesData.filter((del) => del.empId == delEmpIDRed);
      //console.log("tempDel : ", tempDel);
      setDelEmpEntityId(tempDel[0]?.entityId);
    }
  }, [delEmpIDRed, myDelegateesData]);

  const handleRemove = (i, cat) => {
    const list = JSON.parse(JSON.stringify(selectedCategory));

    if (list[i].paymentmethodid == 34) {
      let x = Number(actualsSelf);
      x = x - Number(selectedCategory[i].claimedamount);
      setActualsSelf(x);
    }
    if (list[i].paymentmethodid == 3) {
      let x = Number(actualsTravelCard);
      x = x - Number(selectedCategory[i].claimedamount);
      setActualsTravelCard(x);
    }
    if (list[i].paymentmethodid == 35) {
      let x = Number(actualsCash);
      x = x - Number(selectedCategory[i].claimedamount);
      setActualsCash(x);
    }
    list.splice(i, 1);
    setSelectedCategory(list);
    const list1 = JSON.parse(JSON.stringify(validationArray));
    list1.splice(i, 1);
    setValidationArray(list1);
    let list2 = [...fileDisplayForDD];
    list2.splice(i, 1);
    setFileDisplayForDD(list2);
    //props.handleProjectList(list);
  };
  const handleRemoveAdvances = (i) => {
    const list = JSON.parse(JSON.stringify(advancesList));
    // console.log("list", list);
    let advType = list[i].advancetype;
    if (advType == "Cash") {
      let x = cash;
      x = x - list[i].totaladvance;
      setCash(x);
    }
    if (advType == "Travel Card") {
      let x = travelCardAmount;
      x = x - list[i].totaladvance;
      setTravelCardAmount(x);
    }
    list.splice(i, 1);
    setAdvancesList(list);

    //props.handleProjectList(list);
  };
  useEffect(() => {
    if (purpose !== "") {
      if (
        delEmpIDRed != null &&
        delEmpIDRed != "" &&
        delEmpIDRed != ssoEmpIdDetails?.empId &&
        ssoEmpIdDetails != null &&
        ssoEmpIdDetails != ""
      ) {
        let tempUser = myDelegateesData?.filter(
          (item) => item.empId == delEmpIDRed
        );
        let obj = {
          pId: purpose.purposeId,
          entity: tempUser[0]?.entity,
          traveltype: null,
        };
        dispatch(getCategoryData(obj));
      } else {
        let obj = {
          pId: purpose.purposeId,
          entity: ssoEmpIdDetails?.entity,
          traveltype: null,
        };
        dispatch(getCategoryData(obj));
      }
    }
  }, [purpose, ssoEmpIdDetails]);

  useEffect(() => {
    if (CurrencyList !== null) {
      const filterdCurrency = CurrencyList?.filter(
        (value) => value.selected === 1
      );
      if (filterdCurrency?.length > 0) {
        setCurrency(filterdCurrency[0]);
        let array = JSON.parse(JSON.stringify(selectedCategory));
        array = array.map((item, index) => {
          return {
            ...item,
            currencyId: filterdCurrency[0].currencyId,
          };

          // return item;
        });
        setSelectedCategory(array);
      }
    }
  }, [CurrencyList]);

  useEffect(() => {
    if (paymentList !== null) {
      const filterdCurrency = paymentList?.filter(
        (value) => value.selected === 1
      );
      if (filterdCurrency?.length > 0) {
        setPayment(filterdCurrency[0]);
      }

      let array = [...selectedCategory];
      if (array.length == 1) {
        if (filterdCurrency?.length > 0) {
          array.map((item) => {
            item.paymentmethodid = filterdCurrency[0]?.methodId;
          });
        }
      }

      setSelectedCategory(array);
      //console.log("control is in this useeffct", array);
    }
  }, [paymentList]);
  useEffect(() => {
    if (validateAttendeesList) {
      setEmpAmounts(validateAttendeesList[0]);
    }
  }, [validateAttendeesList]);

  useEffect(() => {
    // console.log("expensecatefroyr", expenseCategory)

    if (expenseCategory !== null || expenseCategory != "") {
      // console.log("expensecatefroyr", expenseCategory,CategoryList?.find((item) =>item.categoryName == expenseCategory)?.categoryId,)
      const obj = {
        expenseID: expenseCategory?.categoryId
          ? expenseCategory?.categoryId
          : CategoryList?.find((item) => item.categoryName == expenseCategory)
              ?.categoryId,
        empId:
          delEmpIDRed != null &&
          delEmpIDRed != "" &&
          delEmpIDRed != ssoEmpIdDetails?.empId
            ? delEmpIDRed
            : ssoEmpIdDetails?.empId,
      };
      dispatch(getPaymentData(obj));
      dispatch(getDependentsData(obj.expenseID));
    }
  }, [expenseCategory]);
  //newlychanged
  useEffect(() => {
    if (expenseCategory !== null && selectedDate != null) {
      const obj = {
        expenseID: expenseCategory?.categoryId
          ? expenseCategory?.categoryId
          : CategoryList?.find(
              (item) => item.categoryName == expenseCategory.categoryName
            )?.categoryId,
        empId:
          delEmpIDRed != null &&
          delEmpIDRed != "" &&
          delEmpIDRed != ssoEmpIdDetails?.empId
            ? delEmpIDRed
            : ssoEmpIdDetails?.empId,
        expid: 0,
        recieptDate: selectedDate ? selectedDate : null,
      };
      dispatch(getValidateAttendeesData(obj));
    }
  }, [selectedDate]);
  useEffect(() => {
    validatonMsg.map((value) => {
      if (value.type === "purpose") {
        setPurposeError(value.message);
      } else if (value.type === "expense Category") {
        setExpenseCategoryError(value.message);
      } else if (value.type === "expense Name") {
        setExpenseNameError(value.message);
      } else if (value.type === "Reciept Date") {
        setSelectDateError(value.message);
      } else if (value.type === "Project Id") {
        setProjectError(value.message);
      } else if (value.type === "Currency") {
        setCurrencyError(value.message);
      } else if (value.type === "Bill Amount") {
        setBillAmountError(value.message);
      } else if (value.type === "Claim Amount") {
        setClaimAmountError(value.message);
      } else if (value.type === "Vendor Name") {
        setVendorNameError(value.message);
      } else if (value.type === "GST") {
        setGstError(value.message);
      } else if (value.type === "Bill Number") {
        setBillNumberError(value.message);
      } else if (value.type === "Attendees") {
        setAttendeesError(value.message);
      } else if (value.type === "Drag Drop File") {
        setDragDropFileError(value.message);
      } else if (value.type === "Project And Split Box") {
        setProjectListSplitBoxError(value.message);
      } else if (value.type === "Declaration CheckBox") {
        setDeclarationError(value.message);
      } else if (value.type === "Payment") {
        setPaymentError(value.message);
      } else if (value.type === "For Month") {
        setForMonthError(value.message);
      } else if (value.type === "From Date") {
        setFromDateError(value.message);
      } else if (value.type === "To Date") {
        setToDateError(value.message);
      } else if (value.type === "From Location") {
        setFromLocationError(value.message);
      } else if (value.type === "To Location") {
        setToLocationError(value.message);
      } else if (value.type === "Distance In Between") {
        setInBetweenDistanceError(value.message);
      } else if (value.type === "Quantity") {
        setQuantityError(value.message);
      }
    });
  }, [validatonMsg]);
  const handleAttendees = (value) => {
    setAttendeesError("");
    setAttendees(value);
  };
  function truncateString(str, num) {
    if (str?.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }
  const handleOpenToolTip = () => {
    setOpenToolTip(true);
  };
  const handleCloseToolTip = () => {
    setOpenToolTip(false);
  };
  const matches = useMediaQuery("(max-width:600px)");

  const handleAccChange = () => {
    setExpanded(!false);
  };
  const gridCond = (index) => {
    return (
      <Grid
        container
        // spacing={2}
      >
        {" "}
        <Grid item xs={6} sm={4} md={4} style={{ paddingRight: "0.5rem" }}>
          <Grid item>
            <NumericFormat
              style={{
                // margin: "16px 0 8px 0",
                backgroundColor: "#FFFFFF",
                // marginLeft: "12px",
              }}
              inputProps={{ maxLength: 12 }}
              decimalScale={2}
              id="outlined-basic"
              label="Bill Amount*"
              onKeyPress={preventMinus}
              variant="outlined"
              fullWidth
              className={
                billAmountError != ""
                  ? classes.formControl12
                  : classes.formControl
              }
              value={selectedCategory[index]?.billamount}
              onChange={(e) => {
                // setBillAmountError("");
                handleBillAmount(e.target.value, index);
                // if(selectedCategory[index]?.claimedamount != ""){
                //   handleClaimAmount1(selectedCategory[index]?.claimedamount, index);
                // }
                setBillAmount(
                  DOMPurify.sanitize(Number(e.target.value.replaceAll(",", "")))
                );
              }}
              helperText={
                selectedCategory[index]?.billamount === ""
                  ? validationArray[index]?.billAmtMsg
                  : ""
              }
              // thousandSeparator={true}
              customInput={TextField}
              FormHelperTextProps={{
                classes: {
                  root: classes.errorRoot,
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={6} sm={4} md={4} style={{ paddingLeft: "0.5rem" }}>
          <Grid item>
            <NumericFormat
              style={{
                // margin: "16px 29px 8px 29px",
                backgroundColor: "#FFFFFF",
              }}
              inputProps={{ maxLength: 12 }}
              decimalScale={2}
              id="outlined-basic"
              label="Claim Amount*"
              onKeyPress={preventMinus}
              variant="outlined"
              fullWidth
              className={
                validationArray[index].claimAmtMsg != ""
                  ? classes.formControl12
                  : classes.formControl
              }
              value={selectedCategory[index]?.claimedamount}
              onChange={(e) => {
                // setClaimAmountError("");
                handleClaimAmount1(e.target.value, index);
              }}
              helperText={
                validationArray[index].claimAmtMsg !== ""
                  ? validationArray[index]?.claimAmtMsg
                  : ""
              }
              customInput={TextField}
              // {...materialUITextFieldProps2}
              FormHelperTextProps={{
                classes: {
                  root: classes.errorRoot,
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const gridNormal = (index) => {
    return (
      <>
        {" "}
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          style={{ padding: purpose.purpose == "Travel" ? "0px 16px" : "" }}
        >
          <Grid item>
            <NumericFormat
              style={{
                margin: "16px 0 8px 0",
                backgroundColor: "#FFFFFF",
              }}
              inputProps={{ maxLength: 12 }}
              decimalScale={2}
              id="outlined-basic"
              label="Bill Amount*"
              variant="outlined"
              onKeyPress={preventMinus}
              fullWidth
              className={
                validationArray[index].billAmtMsg != ""
                  ? classes.formControl12
                  : classes.formControl
              }
              value={selectedCategory[index]?.billamount}
              onChange={(e) => {
                handleBillAmount(e.target.value, index);
                // setBillAmountError("");
                setBillAmount(
                  DOMPurify.sanitize(Number(e.target.value.replaceAll(",", "")))
                );
              }}
              helperText={
                selectedCategory[index]?.billamount === ""
                  ? validationArray[index]?.billAmtMsg
                  : ""
              }
              thousandSeparator={true}
              customInput={TextField}
              FormHelperTextProps={{
                classes: {
                  root: classes.errorRoot,
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          style={{ padding: purpose.purpose == "Travel" ? "0px 16px" : "" }}
        >
          <Grid item>
            <NumericFormat
              style={{
                margin: "16px 0 8px 0",
                backgroundColor: "#FFFFFF",
              }}
              inputProps={{ maxLength: 12 }}
              decimalScale={2}
              id="outlined-basic"
              label="Claim Amount*"
              onKeyPress={preventMinus}
              variant="outlined"
              fullWidth
              className={
                validationArray[index].claimAmtMsg != ""
                  ? classes.formControl12
                  : classes.formControl
              }
              value={selectedCategory[index]?.claimedamount}
              onChange={(e) => {
                handleClaimAmount1(e.target.value, index);
                // setClaimAmountError("");
                //handleClaimAmount(DOMPurify.sanitize(e.target.value));
              }}
              //helperText={claimAmountError !== "" ? claimAmountError : ""}
              helperText={
                // selectedCategory[index]?.claimedamount === "" ||
                validationArray[index].claimAmtMsg != ""
                  ? validationArray[index]?.claimAmtMsg
                  : ""
              }
              thousandSeparator={true}
              customInput={TextField}
              {...materialUITextFieldProps2}
              FormHelperTextProps={{
                classes: {
                  root: classes.errorRoot,
                },
              }}
            />
          </Grid>
        </Grid>{" "}
      </>
    );
  };
  const gridCond1 = (
    <Grid container spacing={2}>
      {" "}
      <Grid item xs={5} sm={4} md={4}>
        <Grid item>
          <NumericFormat
            style={{
              margin: "16px 0 8px 0",
              backgroundColor: "#FFFFFF",
              marginLeft: "12px",
            }}
            inputProps={{ maxLength: 12 }}
            decimalScale={2}
            id="outlined-basic"
            label="Bill Amount*"
            onKeyPress={preventMinus}
            variant="outlined"
            fullWidth
            className={
              billAmountError != ""
                ? classes.formControl12
                : classes.formControl
            }
            onChange={(e) => {
              setBillAmountError("");
              setBillAmount(
                DOMPurify.sanitize(Number(e.target.value.replaceAll(",", "")))
              );
            }}
            helperText={billAmountError !== "" ? billAmountError : ""}
            // thousandSeparator={true}
            customInput={TextField}
            FormHelperTextProps={{
              classes: {
                root: classes.errorRoot,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={5} sm={4} md={4}>
        <Grid item>
          <NumericFormat
            style={{
              margin: "16px 29px 8px 29px",
              backgroundColor: "#FFFFFF",
            }}
            inputProps={{ maxLength: 12 }}
            decimalScale={2}
            id="outlined-basic"
            label="Claim Amount*"
            onKeyPress={preventMinus}
            variant="outlined"
            fullWidth
            className={
              claimAmountError != ""
                ? classes.formControl12
                : classes.formControl
            }
            onChange={(e) => {
              setClaimAmountError("");
              handleClaimAmount(DOMPurify.sanitize(e.target.value));
            }}
            helperText={claimAmountError !== "" ? claimAmountError : ""}
            customInput={TextField}
            {...materialUITextFieldProps2}
            FormHelperTextProps={{
              classes: {
                root: classes.errorRoot,
              },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
  const gridNormal1 = (
    <>
      {" "}
      <Grid item xs={4} sm={4} md={4}>
        <Grid item>
          <NumericFormat
            style={{
              margin: "16px 0 8px 0",
              backgroundColor: "#FFFFFF",
            }}
            inputProps={{ maxLength: 12 }}
            decimalScale={2}
            id="outlined-basic"
            label="Bill Amount*"
            variant="outlined"
            onKeyPress={preventMinus}
            fullWidth
            className={
              billAmountError != ""
                ? classes.formControl12
                : classes.formControl
            }
            onChange={(e) => {
              setBillAmountError("");
              setBillAmount(
                // (DOMPurify.sanitize(e.target.value))
                DOMPurify.sanitize(Number(e.target.value.replaceAll(",", "")))
              );
            }}
            helperText={billAmountError !== "" ? billAmountError : ""}
            thousandSeparator={true}
            customInput={TextField}
            FormHelperTextProps={{
              classes: {
                root: classes.errorRoot,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={4} sm={4} md={4}>
        <Grid item>
          <NumericFormat
            style={{
              margin: "16px 0 8px 0",
              backgroundColor: "#FFFFFF",
            }}
            inputProps={{ maxLength: 12 }}
            decimalScale={2}
            id="outlined-basic"
            label="Claim Amount*"
            onKeyPress={preventMinus}
            variant="outlined"
            fullWidth
            className={
              claimAmountError != ""
                ? classes.formControl12
                : classes.formControl
            }
            onChange={(e) => {
              setClaimAmountError("");
              handleClaimAmount(DOMPurify.sanitize(e.target.value));
            }}
            helperText={claimAmountError !== "" ? claimAmountError : ""}
            thousandSeparator={true}
            customInput={TextField}
            {...materialUITextFieldProps2}
            FormHelperTextProps={{
              classes: {
                root: classes.errorRoot,
              },
            }}
          />
        </Grid>
      </Grid>{" "}
    </>
  );
  return (
    <>
      <Box>
        <Header
          heading={"Create Expense"}
          ssoEmpId={updatedSSOEmpId ? updatedSSOEmpId : null}
          disableAccOptions={1}
        />
      </Box>
      {matches ? (
        <Box
          style={{
            marginBottom: "-47px",
            backgroundColor: "#F8F9FF",
          }}
        >
          <IconButton>
            <ArrowBackIosIcon
              style={{ fontSize: "16px" }}
              onClick={() => {
                history.push("/expense-Management");
              }}
            />
          </IconButton>
        </Box>
      ) : (
        ""
      )}
      {matches ? (
        <Typography
          style={{
            color: "#081F32",
            fontSize: "16px",
            fontWeight: 600,
            fontFamily: "Montserrat",
            paddingLeft: "48px",
            backgroundColor: "#F8F9FF",
            lineHeight: "41px",
            paddingTop: "7px",
          }}
        >
          Create Expense
        </Typography>
      ) : (
        ""
      )}
      <Divider />

      {purpose.purpose == "Travel" ? (
        <>
          <Box className={!matches && classes.root}>
            {/* <h1>This is Travel Component</h1> */}
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                // marginLeft: matches ? "-9px" : "0px",
              }}
            >
              {matches ? (
                ""
              ) : (
                <Box
                  style={{
                    paddingRight: "20px",
                    marginLeft: "-70px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton>
                    <ArrowBackIosIcon
                      onClick={() => {
                        history.push("/expense-Management");
                      }}
                    />
                  </IconButton>
                </Box>
              )}

              <Box
                className={!matches && classes.expenseHeading}
                style={{
                  padding: matches && "0px 30px 0px 20px",
                  margin: matches && "20px 0px 8px 0px",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontSize: matches ? "14px" : "24px",
                    lineHeight: "24px",
                    letterSpacing: "0px",
                    textAlign: "left",
                    // paddingLeft: matches ? "14px" : "",
                    color: "#2B2D30",
                  }}
                >
                  New Expense Report
                </Typography>
                {snackbarAlert &&
                isCreatePostSuccess &&
                postCreateExpenseFormData?.expStatus == "Draft" ? (
                  <SnackBar
                    handleSnackbar={handleSnackbar}
                    handlecloseSnackbar={handlecloseSnackbar}
                    expenseStatus={postCreateExpenseFormData?.expStatus}
                    open={open}
                    expenseCode={postCreateExpenseFormData?.expenseCode}
                  />
                ) : snackbarAlert &&
                  isCreatePostSuccess &&
                  postCreateExpenseFormData?.expStatus == "In Progress" ? (
                  <SnackBar
                    handleSnackbar={handleSnackbar}
                    handlecloseSnackbar={handlecloseSnackbar}
                    expenseStatus={postCreateExpenseFormData?.expStatus}
                    open={open}
                    expenseCode={postCreateExpenseFormData?.expenseCode}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Box>
            <Box style={{ padding: matches && "0px 20px" }}>
              <Box>
                <Grid
                  container
                  spacing={matches ? 0 : 4}
                  className={!matches && classes.rowContainer}
                >
                  <Grid item xs={matches ? 12 : 6} sm={6} md={6}>
                    <Grid item>
                      <FormControl
                        variant="outlined"
                        className={
                          purposeError != ""
                            ? classes.formControl12
                            : classes.formControl
                        }
                        style={
                          {
                            // marginTop: matches ? "5px" : "",
                            // margin: "16px 0px 8px 0px",
                          }
                        }
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Purpose*
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={purpose}
                          className={
                            matches ? classes.inputLabels1 : classes.inputLabels
                          }
                          style={{ height: matches ? "52px" : "56px" }}
                          onChange={(e) => {
                            setExpenseCategory("");
                            setPurposeError("");
                            setPurpose(e.target.value);
                            setSelectedDate(null);
                            setSplitClaimAmount("");
                            e.target.value === "Travel" &&
                              setSelectedProjects([]);
                          }}
                          label="Purpose*"
                          MenuProps={{
                            getContentAnchorEl: null,
                          }}
                        >
                          {/* {console.log("purpose" ,purposeList )} */}
                          {purposeList?.map((item) => {
                            return (
                              <MenuItem
                                key={item.purposeId}
                                value={item}
                                style={{ fontSize: matches ? "13px" : "" }}
                              >
                                <Typography
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: matches ? "13px" : "",
                                    fontWeight: matches ? 500 : "",
                                    letterSpacing: matches ? "-1px" : "",
                                    textAlign: "left",
                                  }}
                                >
                                  {item.purpose}
                                </Typography>
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText className={classes.errorText}>
                          {purpose === "" ? purposeError : ""}
                        </FormHelperText>
                      </FormControl>{" "}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={matches ? 12 : 6}
                    sm={6}
                    md={6}
                    style={{ paddingRight: !matches && "17px" }}
                  >
                    <Grid item>
                      <TextField
                        id="outlined-basic"
                        label="Expense Name*"
                        variant="outlined"
                        inputProps={{ maxLength: 80 }}
                        style={{
                          backgroundColor: "#FFFFFF",
                          margin: matches ? "12px 0px 8px" : "",
                        }}
                        fullWidth
                        className={
                          expenseNameError != ""
                            ? classes.formControl12
                            : classes.formControl
                        }
                        onChange={(e) => {
                          setExpenseNameError("");
                          setSelectDateError("");
                          setExpenseName(
                            DOMPurify.sanitize(e.target.value.trimStart())
                          );
                        }}
                        helperText={
                          expenseNameError !== "" ? expenseNameError : ""
                        }
                        FormHelperTextProps={{
                          classes: {
                            root: classes.errorRoot,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box>
              <Grid
                container
                className={!matches && classes.rowContainer}
                style={{
                  marginTop: !matches && "15px",
                  // paddingRight: matches ? "12px" : "",
                  marginBottom: "10px",
                  padding: matches && "12px 20px 20px",
                }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Grid item>
                    <Box
                      style={{
                        height: matches ? " " : "100%",
                        backgroundColor: matches ? "" : "white",
                        border: !matches && "1px solid #9E9E9E",
                        // borderRadius: matches && "1.5rem 1.5rem 0px 0px",
                      }}
                    >
                      <Box
                        className={matches ? "" : classes.projectDetailsMainBox}
                        style={{
                          padding: matches
                            ? "5px 16px 10px"
                            : "10px 25px 4px 25px",
                          margin: matches && "0px 0px 5px",
                          border: matches && "1px solid #9E9E9E",
                          borderRadius: matches ? "" : "0px",
                        }}
                      >
                        <Grid
                          style={{
                            display: !matches && "flex",
                            alignItems: !matches && "center",
                          }}
                        >
                          {/* Project Name Form Control */}
                          <Grid
                            item
                            align="left"
                            style={{ width: !matches && "48.30%" }}
                          >
                            <FormControl
                              variant="outlined"
                              inputProps={{
                                style: handlePadding(matches),
                              }}
                              style={{
                                backgroundColor: matches ? "white" : "",
                              }}
                              className={
                                projectError1 !== ""
                                  ? classes.formControl12
                                  : classes.formControl
                              }
                            >
                              {matches ? (
                                <InputLabel
                                  id="project-id-name"
                                  style={{ fontSize: "13px" }}
                                >
                                  Project Name*
                                </InputLabel>
                              ) : (
                                <InputLabel id="project-id-name">
                                  Project Code/ Project Name*
                                </InputLabel>
                              )}
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                name="projectName"
                                style={{
                                  minWidth: matches ? "120px" : "412px",
                                  maxHeight: matches ? "200px" : "56px",
                                  color: "#008CE6",
                                  // "&.makeStyles-formControl-531.MuiInputBase-root .MuiSelect-selectMenu ":
                                  //   {
                                  //     padding: "13px 32px 2px 14px",
                                  //   },
                                }}
                                optionList={employeeProjectsList}
                                getOptionLabel={(option) =>
                                  `${option.projName} - ${option.projCode}`
                                }
                                value={
                                  selectedProjects[0]?.projectName?.projectID
                                }
                                onChange={(e) => handleSelectProject(e, 0)}
                                label="Project CodeProject Name*"
                                MenuProps={{
                                  getContentAnchorEl: null,
                                }}
                              >
                                {employeeProjectsList?.map((item) => (
                                  <MenuItem
                                    key={item.projCode}
                                    value={item.projCode}
                                  >
                                    <Box
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontSize: "14px",
                                          fontWeight: 500,
                                          lineHeight: "12px",
                                          letterSpacing: "0px",
                                          textAlign: "left",
                                          fontSize: matches ? "13px" : "",
                                          color: "#0B1941",
                                        }}
                                      >
                                        {item.projCode}
                                      </Typography>
                                      {!matches && (
                                        <Typography
                                          style={{
                                            fontFamily: "Montserrat",
                                            fontSize: "11px",
                                            fontWeight: 800,
                                            lineHeight: "18px",
                                            letterSpacing: "0px",
                                            textAlign: "left",
                                            color: "#0B1941",
                                          }}
                                        >
                                          {item.projName}
                                        </Typography>
                                      )}
                                    </Box>
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {projectError1 && (
                              <Typography variant="body2" color="error">
                                {projectError1}
                              </Typography>
                            )}
                          </Grid>
                          <Grid
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            {/* Client Billable Checkbox */}
                            <Grid
                              item
                              style={{ marginLeft: !matches && "24px" }}
                              align="center"
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  // flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "end",
                                }}
                              >
                                <Checkbox
                                  name="billable"
                                  checkedIcon={
                                    <CheckBoxIcon
                                      className={
                                        matches
                                          ? classes.checkBoxRootMobile
                                          : classes.checkBoxRoot
                                      }
                                    />
                                  }
                                  onChange={(e) => handleSelectProject(e, 0)}
                                  style={{
                                    padding: matches && "0px 5px 0px 0px",
                                  }}
                                />
                                <Typography
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: matches ? "12px" : "16px",
                                    fontWeight: 500,
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                    width: matches ? "" : "157px",
                                    marginTop: matches ? "" : "0px",
                                  }}
                                >
                                  Client Billable
                                </Typography>
                              </Box>
                            </Grid>

                            {/* Total Typography */}
                            <Grid
                              item
                              style={{ marginLeft: !matches && "98px" }}
                              align="right"
                            >
                              <Typography
                                style={{
                                  fontFamily: "Montserrat",
                                  fontSize: matches ? "12px" : "16px",
                                  fontWeight: matches ? "bolder" : "500",
                                  textAlign: "Right",
                                  lineHieght: "24px",
                                  paddingRight: !matches && "2px",
                                  width: matches ? "" : "150px",
                                }}
                              >
                                Total :{" "}
                                {currency?.currency + " " + totalClaimAmount}
                              </Typography>
                              <Typography
                                style={{
                                  fontFamily: "Montserrat",
                                  fontSize: matches ? "16px" : "16px",
                                  fontWeight: matches ? "bolder" : "500",
                                  textAlign: "Right",
                                  lineHieght: "24px",
                                  paddingRight: "2px",
                                }}
                              >
                                {currencySB != null ? currencySB : ""}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box>
                        <Typography
                          variant="p"
                          // variant="span"
                          style={{
                            fontSize: "13px",
                            color: "red",
                            marginRight: "14px",
                            marginTop: 4,
                            fontFamily: "Montserrat",
                          }}
                        >
                          {projectError1 ? projectError1 : ""}
                        </Typography>
                      </Box>

                      <Box>
                        <Accordion
                          key={0}
                          expanded={expanded3}
                          // expanded={expanded?.includes(index)}
                          // onChange={handleAccordionChange(indexForAdvances , )}
                          className={classes.projectDetailsMainBox}
                          style={{
                            marginTop: "-20px",
                            padding: "0px ",
                            marginBottom: "30px",
                            borderRadius: "0px",
                            backgroundColor: "#F8F9FF",
                            height:
                              expanded3 == true && !matches ? "160px" : "",
                            "&.MuiPaper-elevation1": {
                              boxShadow: "0 0 0 0",
                            },
                            "&.MuiAccordionSummary-content.Mui-expanded": {
                              margin: "7px 0px",
                              minHeight: "27px",
                            },
                            "&.MuiCollapse-wrapper": {
                              backgroundColor: "#F8F9FF",
                              height: "90px",
                            },
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon
                                style={{
                                  color: "#E3E8F7",
                                  backgroundColor: "#0B1941",
                                  borderRadius: "50%",
                                  width: "16.03px",
                                  height: "16.03px",
                                }}
                                isExpanded={expanded3}
                                onClick={handleAccordionChangeForTravelDetails(
                                  expanded3
                                )}
                              />
                            }
                            // id={index}
                            // key={index}
                            style={{
                              height: "10px",
                              boxShadow: "0px",
                              backgroundColor:
                                // selectedCategory[index].categoryName != ""
                                "#F6F6F6",

                              height: "10px",
                              "&.MuiAccordionSummary-root.Mui-expanded": {
                                minHeight: "27px",
                              },
                              ".MuiAccordionSummary-root": {
                                height: "38px",
                              },
                            }}
                          >
                            <Grid
                              container
                              justify="space-between"
                              alignItems="center"
                            >
                              <Grid item>
                                <Typography>
                                  {" "}
                                  <strong> Travel Details </strong>
                                </Typography>
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <Grid
                            container
                            spacing={4}
                            xs={12}
                            justifyContent="space-between"
                            alignItems="center"
                            style={{
                              marginBottom: matches ? "10px" : "40px",
                              marginLeft: "1.5px",
                            }}
                          >
                            <Grid
                              item
                              xs={matches ? 12 : 3}
                              style={{ padding: matches ? "2px 16px" : "16px" }}
                            >
                              <TextField
                                style={{
                                  margin: "16px 0 8px 0",
                                  backgroundColor: "#FFFFFF",
                                }}
                                inputProps={{ maxLength: 20 }}
                                id="outlined-basic"
                                label="Destination*"
                                variant="outlined"
                                fullWidth
                                className={
                                  travelDestinationMsg != ""
                                    ? classes.formControl12
                                    : classes.formControl
                                }
                                value={travelDestination}
                                onChange={(e) => {
                                  setTravelDestination(e.target.value);
                                  setTravelDestinationMsg("");
                                }}
                                thousandSeparator={true}
                                customInput={TextField}
                                helperText={
                                  travelDestinationMsg != "" &&
                                  travelDestination == ""
                                    ? travelDestinationMsg
                                    : ""
                                }
                                FormHelperTextProps={{
                                  classes: {
                                    root: classes.errorRoot,
                                  },
                                  style: {
                                    fontSize: "10px",
                                  },
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={matches ? 12 : 3}
                              style={{ padding: matches ? "2px 16px" : "16px" }}
                            >
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  disableToolbar
                                  inputVariant="outlined"
                                  fullWidth
                                  className={
                                    travelstdtMsg != ""
                                      ? classes.formControl12
                                      : classes.formControl
                                  }
                                  style={{
                                    backgroundColor: "#FFFFFF",
                                    //marginLeft: matches ? "-15px" : "0px",
                                  }}
                                  variant="inline"
                                  format="MM/dd/yyyy"
                                  margin="normal"
                                  autoOk={true}
                                  disableFuture={true}
                                  id="date-picker-inline"
                                  label="Travel Start Date"
                                  value={travelStartDate} // Use the receiptdate from the 'cat' object
                                  onChange={(e) => {
                                    // console.log("hytgyuh" , e, isValid(e), travelStartDate)
                                    if (isValid(e)) {
                                      setTravelStartDate(e);
                                      if (travelDetailType == "") {
                                        setTravelDetailType("Domestic");
                                        let obj = {
                                          pId: purpose.purposeId,
                                          entity: ssoEmpIdDetails?.entity,
                                          traveltype: "Domestic",
                                        };
                                        dispatch(getCategoryData(obj));
                                      }
                                      setTravelstdtMsg("");
                                    } else {
                                      setTravelStartDate(null);
                                      setTravelstdtMsg("*Invalid date format.");
                                    }
                                  }} // Pass the index here
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                    style: { paddingRight: "3px" },
                                  }}
                                  helperText={
                                    travelstdtMsg != "" &&
                                    travelStartDate == null
                                      ? travelstdtMsg
                                      : ""
                                  }
                                  FormHelperTextProps={{
                                    classes: {
                                      root: classes.errorRoot,
                                    },
                                    style: {
                                      fontSize: "10px",
                                    },
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid
                              item
                              xs={matches ? 12 : 3}
                              style={{ padding: matches ? "2px 16px" : "16px" }}
                            >
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  disableToolbar
                                  inputVariant="outlined"
                                  fullWidth
                                  className={
                                    travelendtMsg != ""
                                      ? classes.formControl12
                                      : classes.formControl
                                  }
                                  style={{
                                    backgroundColor: "#FFFFFF",
                                    //marginLeft: matches ? "-15px" : "0px",
                                  }}
                                  variant="inline"
                                  format="MM/dd/yyyy"
                                  margin="normal"
                                  autoOk={true}
                                  disableFuture={true}
                                  id="date-picker-inline"
                                  label="Travel End Date"
                                  value={travelEndDate || null} // Use the receiptdate from the 'cat' object
                                  onChange={(e) => {
                                    if (isValid(e)) {
                                      setTravelEndDate(e);
                                      setTravelendtMsg("");
                                    } else {
                                      setTravelEndDate(null);
                                      setTravelendtMsg("*Invalid date format.");
                                    }
                                  }} // Pass the index here
                                  // onChange={(date) => handleReceiptDate(selectedCategory[index], date, index)}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                    style: { paddingRight: "3px" },
                                  }}
                                  helperText={
                                    travelendtMsg != "" && travelEndDate == null
                                      ? travelendtMsg
                                      : travelStartDate != null &&
                                        travelEndDate != null &&
                                        travelStartDate > travelEndDate
                                      ? "*End date should be greater than start date."
                                      : ""
                                  }
                                  FormHelperTextProps={{
                                    classes: {
                                      root: classes.errorRoot,
                                    },
                                    style: {
                                      fontSize: "10px",
                                    },
                                  }}
                                  // disabled={isDatePickerDisabled}
                                />
                              </MuiPickersUtilsProvider>
                              {/* <FormHelperText>
                               {travelStartDate != null && travelEndDate != null && travelStartDate > travelEndDate
                                      ? "*End date should be greater than start date."
                                      : ""}
                              </FormHelperText> */}
                            </Grid>
                            <Grid
                              item
                              xs={matches ? 12 : 3}
                              style={{ padding: matches ? "2px 16px" : "16px" }}
                            >
                              <FormControl
                                variant="outlined"
                                className={
                                  travelTypeMsg != ""
                                    ? classes.formControl12
                                    : classes.formControl
                                }
                              >
                                <InputLabel id="demo-simple-select-outlined-label">
                                  Travel Type*
                                </InputLabel>
                                {/* {console.log("KUSH1111", currency)} */}
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  className={
                                    matches
                                      ? classes.inputLabels1
                                      : classes.inputLabels
                                  }
                                  value={travelDetailType}
                                  onChange={(e) => {
                                    let obj = {
                                      pId: purpose.purposeId,
                                      entity: ssoEmpIdDetails?.entity,
                                      traveltype: e.target.value,
                                    };
                                    dispatch(getCategoryData(obj));
                                    setSelectedCategory((prevState) =>
                                      prevState.map((category) => ({
                                        ...category,
                                        categoryCode: "",
                                        categoryId: "",
                                        categoryName: "",
                                      }))
                                    );
                                    setTravelDetailType(e.target.value);
                                    setAdvancesList(advancesListTemp);
                                    setTravelCardAmount(0);
                                    setCash(0);
                                    setTravelTypeMsg("");
                                  }}
                                  label="Travel Type*"
                                >
                                  {traveltype?.map((item) => {
                                    return (
                                      <MenuItem
                                        key={item.value}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>

                                <FormHelperText
                                  className={classes.errorText}
                                  style={{ fontSize: "10px" }}
                                >
                                  {travelTypeMsg != "" && travelDetailType == ""
                                    ? travelTypeMsg
                                    : ""}
                                </FormHelperText>
                              </FormControl>{" "}
                            </Grid>
                          </Grid>
                        </Accordion>
                      </Box>

                      {travelDetailType === "International" ? (
                        <Box>
                          <Accordion
                            key={0}
                            expanded={expanded1}
                            // expanded={expanded?.includes(index)}
                            // onChange={handleAccordionChange(indexForAdvances , )}
                            className={classes.projectDetailsMainBox}
                            style={{
                              marginTop: "-20px",
                              padding: "0px ",
                              marginBottom: "30px",
                              borderRadius: "0px",
                              backgroundColor: "#F8F9FF",
                              height: expanded1 == true ? "100%" : "",
                              "&.MuiPaper-elevation1": {
                                boxShadow: "0 0 0 0",
                              },
                              "&.MuiAccordionSummary-content.Mui-expanded": {
                                margin: "7px 0px",
                                minHeight: "27px",
                              },
                              "&.MuiCollapse-wrapper": {
                                backgroundColor: "#F8F9FF",
                                height: "90px",
                              },
                            }}
                          >
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon
                                  style={{
                                    color: "#E3E8F7",
                                    backgroundColor: "#0B1941",
                                    borderRadius: "50%",
                                    width: "16.03px",
                                    height: "16.03px",
                                  }}
                                  isExpanded={expanded1}
                                  onClick={handleAccordionChangeFroAdvances(
                                    expanded1
                                  )}
                                />
                              }
                              // id={index}
                              // key={index}
                              style={{
                                height: "10px",
                                boxShadow: "0px",
                                backgroundColor:
                                  // selectedCategory[index].categoryName != ""
                                  "#F6F6F6",

                                height: "10px",
                                "&.MuiAccordionSummary-root.Mui-expanded": {
                                  minHeight: "27px",
                                },
                                ".MuiAccordionSummary-root": {
                                  height: "38px",
                                },
                              }}
                            >
                              <Grid
                                container
                                justify="space-between"
                                alignItems="center"
                              >
                                <Grid item>
                                  <Typography>
                                    {" "}
                                    <strong> Advances </strong>
                                  </Typography>
                                </Grid>

                                <Grid item>
                                  <Typography
                                    style={{ marginRight: "30px" }}
                                    align="right"
                                  >
                                    Total Advances: {currency.currency}
                                    {(
                                      Number(cash) + Number(travelCardAmount)
                                    ).toFixed(2)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </AccordionSummary>
                            {advancesList.map((item, index) => {
                              return (
                                <Grid
                                  container
                                  spacing={5}
                                  xs={12}
                                  justifyContent="space-between"
                                  alignItems="center"
                                  style={{
                                    // marginBottom: "40px",
                                    marginLeft: "1.5px",
                                    marginBottom: matches ? "1px" : "0px",
                                    //height:advancesList.length>1 ? "86px" : ""
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={matches ? 6 : 3}
                                    style={{
                                      padding: matches ? "2px 16px" : "16px",
                                    }}
                                  >
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                    >
                                      <KeyboardDatePicker
                                        disableToolbar
                                        inputVariant="outlined"
                                        fullWidth
                                        className={classes.formControl}
                                        style={{
                                          backgroundColor: "#FFFFFF",
                                          //marginLeft: matches ? "-15px" : "0px",
                                        }}
                                        InputLabelProps={{
                                          style: { fontSize: "14px" },
                                        }}
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        autoOk={true}
                                        disableFuture={true}
                                        id="date-picker-inline"
                                        label={
                                          matches
                                            ? "Deposit Date"
                                            : "Advance Deposit Date"
                                        }
                                        value={item.depositdate} // Use the receiptdate from the 'cat' object
                                        onChange={(e) => {
                                          handleAdvancesList(e, index, "date");
                                        }} // Pass the index here
                                        KeyboardButtonProps={{
                                          "aria-label": "change date",
                                          style: {
                                            paddingRight: "3px",
                                            padding: matches ? "0px" : "3px",
                                            marginRight: matches ? "-10px" : "",
                                          },
                                        }}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={matches ? 6 : 3}
                                    style={{
                                      padding: matches ? "2px 16px" : "16px",
                                    }}
                                  >
                                    <FormControl
                                      variant="outlined"
                                      className={classes.formControl}
                                    >
                                      <InputLabel id="demo-simple-select-outlined-label">
                                        Currency*
                                      </InputLabel>
                                      {/* {console.log("KUSH1111", currency)} */}
                                      <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        className={
                                          matches
                                            ? classes.inputLabels1
                                            : classes.inputLabels
                                        }
                                        // value={selectedCategory?.currency}
                                        value={currency || item.currency}
                                        style={
                                          {
                                            //marginRight: matches ? "-6px" : "0px",
                                          }
                                        }
                                        onChange={(e) => {
                                          //  handleCurrency(e.target.value, index);
                                          setCurrency(
                                            e.target.value
                                              ? e.target.value
                                              : currency
                                          );
                                        }}
                                        label="Currency*"
                                        //disabled={index !== 0}
                                      >
                                        {CurrencyList?.map((item) => {
                                          return (
                                            <MenuItem
                                              key={item.currencyId}
                                              value={item}
                                            >
                                              {item.currencyCode}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>

                                      <FormHelperText
                                        className={classes.errorText}
                                      >
                                        {currency === "" ? currencyError : ""}
                                      </FormHelperText>
                                    </FormControl>{" "}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={matches ? 6 : 3}
                                    style={{
                                      padding: matches ? "2px 16px" : "16px",
                                    }}
                                  >
                                    <NumericFormat
                                      style={{
                                        margin: "16px 0 8px 0",
                                        backgroundColor: "#FFFFFF",
                                      }}
                                      inputProps={{ maxLength: 12 }}
                                      decimalScale={2}
                                      id="outlined-basic"
                                      label="Advances Amount"
                                      variant="outlined"
                                      onKeyPress={preventMinus}
                                      fullWidth
                                      className={classes.formControl}
                                      value={item.totaladvance}
                                      onChange={(e) => {
                                        handleAdvancesList(e, index, "amt");
                                      }}
                                      thousandSeparator={true}
                                      customInput={TextField}
                                      FormHelperTextProps={{
                                        classes: {
                                          root: classes.errorRoot,
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={matches ? 6 : 2}
                                    style={{
                                      padding: matches ? "2px 16px" : "16px",
                                    }}
                                  >
                                    <FormControl
                                      variant="outlined"
                                      className={classes.formControl}
                                      style={{
                                        width: matches ? "100%" : "107%",
                                      }}
                                    >
                                      <InputLabel
                                        style={{ fontSize: "14px" }}
                                        id="demo-simple-select-outlined-label"
                                      >
                                        Advances Type
                                      </InputLabel>
                                      {/* {console.log("KUSH1111", currency)} */}
                                      <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        className={
                                          matches
                                            ? classes.inputLabels1
                                            : classes.inputLabels
                                        }
                                        value={item.advancetype}
                                        onChange={(e) => {
                                          handleAdvancesList(e, index, "type");
                                        }}
                                        label="Advances Type"
                                      >
                                        {AdvancesType?.map((adv) => {
                                          return (
                                            <MenuItem
                                              key={adv.value}
                                              value={adv.value}
                                            >
                                              {adv.label}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>{" "}
                                  </Grid>
                                  {index !== 0 ? (
                                    <Box
                                      display="flex"
                                      justifyContent="flex-start"
                                    >
                                      <Button
                                        style={{ minWidth: "24px" }}
                                        onClick={() =>
                                          handleRemoveAdvances(index)
                                        }
                                      >
                                        <span class="material-symbols-outlined">
                                          delete
                                        </span>
                                      </Button>
                                    </Box>
                                  ) : (
                                    <Box
                                      display="flex"
                                      justifyContent="flex-start"
                                    >
                                      <Button
                                        style={{ minWidth: "42px" }}
                                        //onClick={() => handleRemoveAdvances(index)}
                                      >
                                        <span class="material-symbols-outlined">
                                          {" "}
                                        </span>
                                      </Button>
                                    </Box>
                                  )}
                                </Grid>
                              );
                            })}
                            <Box style={{ height: "33px" }}>
                              <Link
                                style={{
                                  color: "#008CE6",
                                  cursor: "pointer",
                                  fontFamily: "Montserrat",
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  lineHeight: "32px",
                                  letterSpacing: "0px",
                                  textAlign: "left",
                                  marginLeft: "10px",
                                  // padding:"25px"
                                }}
                                target="_blank"
                                underline="always"
                                onClick={() => {
                                  addAdvancesLine();
                                }}
                              >
                                + Add Advances
                              </Link>
                            </Box>
                          </Accordion>
                        </Box>
                      ) : (
                        ""
                      )}

                      <Grid item xs={12} sm={12} md={12}>
                        {selectedCategory.map((cat, index) => {
                          const payment = paymentList?.find(
                            (payment) =>
                              payment.methodId ===
                              parseInt(cat?.paymentmethodid)
                          );

                          return (
                            <>
                              <Accordion
                                key={index}
                                expanded={expanded === index}
                                // expanded={expanded?.includes(index)}
                                //onChange={handleAccordionChange(index)}
                                className={classes.projectDetailsMainBox}
                                style={{
                                  marginTop: "-20px",
                                  padding: "0px ",
                                  marginBottom: "30px",
                                  borderRadius: "0px",
                                  backgroundColor: "#F8F9FF",
                                  "&.MuiPaper-elevation1": {
                                    boxShadow: "0 0 0 0",
                                  },
                                  "&.MuiAccordionSummary-content.Mui-expanded":
                                    {
                                      margin: "7px 0px",
                                      minHeight: "27px",
                                    },
                                  "&.MuiCollapse-wrapper": {
                                    backgroundColor: "#F8F9FF",
                                  },
                                }}
                              >
                                <AccordionSummary
                                  className={
                                    matches && classes.ExpandedAccordionTravel
                                  }
                                  expandIcon={
                                    <ExpandMoreIcon
                                      style={{
                                        color: "#E3E8F7",
                                        backgroundColor: "#0B1941",
                                        borderRadius: "1rem",
                                        height: "1rem",
                                        width: "1rem",
                                      }}
                                      isExpanded={expanded === index}
                                      onClick={handleAccordionChange(
                                        index,
                                        expanded === index
                                      )}
                                    />
                                  }
                                  IconButtonProps={{
                                    style: {
                                      position: matches && "absolute",
                                      top: matches && "35px",
                                    },
                                  }}
                                  // id={index}
                                  key={index}
                                  style={{
                                    boxShadow: "0px",
                                    backgroundColor:
                                      selectedCategory[index].categoryName != ""
                                        ? "#F6F6F6"
                                        : "",
                                    height:
                                      selectedCategory[index].categoryName != ""
                                        ? ""
                                        : "10px",
                                    "&.MuiAccordionSummary-root.Mui-expanded": {
                                      minHeight: "27px",
                                    },
                                    padding: matches ? "0px 16px" : "0px 25px",
                                  }}
                                >
                                  <Grid
                                    container
                                    justify="space-between"
                                    alignItems="center"
                                  >
                                    <Grid
                                      item
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          fontSize: matches ? "12px" : "16px",
                                          textWrap: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          // width: matches && "290px",
                                          maxWidth: matches && "170px",
                                        }}
                                      >
                                        {" "}
                                        <strong> {cat?.categoryName}</strong>
                                      </Typography>
                                      {cat.categoryName != "" ? (
                                        <Box
                                          style={{
                                            height: "24px",
                                            paddingLeft: "1rem",
                                          }}
                                        >
                                          <InfoOutlinedIcon
                                            style={{
                                              // paddingRight: matches
                                              //   ? "14px"
                                              //   : "",
                                              color: "#9E9E9E",
                                            }}
                                            onClick={handlePopoverOpen}
                                          />
                                          <Popover
                                            id="mouse-over-popover"
                                            className={classes.popover}
                                            classes={{
                                              paper: classes.paper,
                                            }}
                                            open={openPopOver}
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "left",
                                            }}
                                            transformOrigin={{
                                              vertical: "top",
                                              horizontal: "left",
                                            }}
                                            onClose={handlePopoverClose}
                                            disableRestoreFocus
                                          >
                                            {expenseCategory.policyDescription ? (
                                              <Box
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  maxWidth: "325px",
                                                  maxHeight: "248px",
                                                }}
                                              >
                                                <Box
                                                  style={{
                                                    padding: "16px",
                                                  }}
                                                >
                                                  <Box>
                                                    <Typography
                                                      color="inherit"
                                                      style={{
                                                        fontFamily:
                                                          "Montserrat",
                                                        fontSize: "14px",
                                                        fontWeight: "600",
                                                        lineHeight: "20px",
                                                        letterSpacing: "0em",
                                                        textAlign: "left",
                                                      }}
                                                    >
                                                      POLICY DESCRIPTION
                                                    </Typography>
                                                  </Box>

                                                  <Box
                                                    style={{
                                                      paddingTop: "4px",
                                                    }}
                                                  >
                                                    <Typography
                                                      style={{
                                                        fontFamily:
                                                          "Montserrat",
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        lineHeight: "16px",
                                                        letterSpacing: "0em",
                                                        textAlign: "justify",
                                                      }}
                                                    >
                                                      {truncateString(
                                                        expenseCategory.policyDescription,
                                                        264
                                                      )}
                                                    </Typography>
                                                    <Box>
                                                      <Link
                                                        style={{
                                                          color: "#0B1941",
                                                          cursor: "pointer",
                                                          fontFamily:
                                                            "Montserrat",
                                                          fontSize: "16px",
                                                          fontWeight: "500",
                                                          lineHeight: "50px",
                                                          letterSpacing: "0px",
                                                          textAlign: "left",
                                                          paddingTop: "20px",
                                                        }}
                                                        target="_blank"
                                                        underline="always"
                                                        onClick={
                                                          handleOpenDescDialog
                                                        }
                                                      >
                                                        View more
                                                      </Link>
                                                      <div>
                                                        <Dialog
                                                          onClose={
                                                            handleCloseDescDialog
                                                          }
                                                          aria-labelledby="customized-dialog-title"
                                                          open={openDescDialog}
                                                        >
                                                          <DialogTitleDescDialog
                                                            id="customized-dialog-title"
                                                            onClose={
                                                              handleCloseDescDialog
                                                            }
                                                          >
                                                            Policy Description
                                                          </DialogTitleDescDialog>
                                                          <DialogContentDescDialog
                                                            dividers
                                                          >
                                                            <Typography
                                                              gutterBottom
                                                            >
                                                              {
                                                                expenseCategory.policyDescription
                                                              }
                                                            </Typography>
                                                          </DialogContentDescDialog>
                                                          <DialogActionsDescDialog>
                                                            <Button
                                                              autoFocus
                                                              onClick={
                                                                handleCloseDescDialog
                                                              }
                                                              color="primary"
                                                            >
                                                              Close
                                                            </Button>
                                                          </DialogActionsDescDialog>
                                                        </Dialog>
                                                      </div>
                                                    </Box>
                                                  </Box>
                                                </Box>
                                                <Box>
                                                  <CloseIcon
                                                    onClick={handlePopoverClose}
                                                  />
                                                </Box>
                                              </Box>
                                            ) : (
                                              ""
                                            )}
                                          </Popover>
                                        </Box>
                                      ) : (
                                        ""
                                      )}
                                    </Grid>

                                    <Grid item>
                                      <Typography
                                        style={{
                                          marginRight: !matches && "30px",
                                          fontSize: matches ? "12px" : "",
                                        }}
                                        align="right"
                                      >
                                        {/* {console.log("cat", cat)} */}
                                        <strong>
                                          {!matches && " Claimed amount :"}{" "}
                                          {cat?.claimedamount
                                            ? currency?.currency +
                                              cat?.claimedamount
                                            : ""}
                                        </strong>
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </AccordionSummary>

                                <Grid
                                  container
                                  // spacing={matches ? 2 : 2}
                                  className={
                                    !matches && classes.rowContainer
                                    // ? classes.rowContainerMobile
                                    // :
                                  }
                                  style={{
                                    padding: matches ? "0px 16px" : "0px 25px",
                                    height: !matches && "89px",
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={matches ? 12 : 6}
                                    sm={6}
                                    md={6}
                                    style={{
                                      paddingRight: matches ? "" : "17px",
                                    }}
                                  >
                                    <Grid
                                      item
                                      style={{
                                        // marginLeft: "-85px",
                                        width: "100%",
                                      }}
                                    >
                                      <FormControl
                                        fixed
                                        variant="outlined"
                                        className={
                                          validationArray[index].categoryMsg !=
                                          ""
                                            ? classes.formControl12
                                            : classes.formControl
                                        }
                                        style={{ width: "100%" }}
                                      >
                                        <InputLabel id="demo-simple-select-outlined-label">
                                          Expense Category*
                                        </InputLabel>
                                        <Select
                                          style={{ borderRadius: 4 }}
                                          className={
                                            matches
                                              ? classes.inputLabels1
                                              : classes.inputLabels
                                          }
                                          labelId="demo-simple-select-outlined-label"
                                          id="demo-simple-select-outlined"
                                          defaultValue=""
                                          label={"Expense Category*"}
                                          value={cat?.categoryName}
                                          //value = {CategoryList?.categoryName}
                                          // value = {CategoryList?.categoryName?.length > 40
                                          //   ? CategoryList?.categoryName.substring(0, 40) + "..."
                                          //   : CategoryList?.categoryName}
                                          onChange={(e) => {
                                            setSelectDateError("");
                                            validationArray[index].categoryMsg =
                                              "";
                                            setIndexForDate(index);
                                            handleExpCategory(
                                              // e.target.value.categoryId,
                                              // e.target.value.categoryName,
                                              // e.target.value.categoryCode,
                                              e.target.value,
                                              index
                                            );
                                            setExpenseCategory(e.target.value);
                                            setSelectedDate(null);
                                            // if (index === 0) {
                                            //   projectData(e);
                                            // }
                                          }}
                                        >
                                          {/* {console.log("cateruighk" , cat , CategoryList)} */}
                                          {travelDetailType != "" &&
                                            CategoryList?.map((item) => {
                                              const displayText =
                                                item.categoryName.length > 50
                                                  ? item.categoryName.substring(
                                                      0,
                                                      50
                                                    ) + "..."
                                                  : item.categoryName;
                                              return (
                                                <MenuItem
                                                  key={item.categoryId}
                                                  value={item.categoryName}
                                                >
                                                  <Typography
                                                    style={{
                                                      fontFamily: "Montserrat",
                                                      fontSize: matches
                                                        ? "12px"
                                                        : "",
                                                      fontWeight: matches
                                                        ? 500
                                                        : "",
                                                      letterSpacing: matches
                                                        ? "-1px"
                                                        : "",
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    {displayText}
                                                  </Typography>
                                                </MenuItem>
                                              );
                                            })}
                                        </Select>
                                        <FormHelperText
                                          className={classes.errorText}
                                        >
                                          {selectedCategory[index]
                                            .categoryName === ""
                                            ? validationArray[index].categoryMsg
                                            : ""}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                  <Popover
                                    id="mouse-over-popover"
                                    className={classes.popover}
                                    classes={{
                                      paper: classes.paper,
                                    }}
                                    open={openPopOver}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                    onClose={handlePopoverClose}
                                    disableRestoreFocus
                                  >
                                    {expenseCategory.policyDescription ? (
                                      <Box
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          maxWidth: "325px",
                                          maxHeight: "248px",
                                        }}
                                      >
                                        <Box
                                          style={{
                                            padding: "16px",
                                          }}
                                        >
                                          <Box>
                                            <Typography
                                              color="inherit"
                                              style={{
                                                fontFamily: "Montserrat",
                                                fontSize: "14px",
                                                fontWeight: "600",
                                                lineHeight: "20px",
                                                letterSpacing: "0em",
                                                textAlign: "left",
                                              }}
                                            >
                                              POLICY DESCRIPTION
                                            </Typography>
                                          </Box>

                                          <Box
                                            style={{
                                              paddingTop: "4px",
                                            }}
                                          >
                                            <Typography
                                              style={{
                                                fontFamily: "Montserrat",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                lineHeight: "16px",
                                                letterSpacing: "0em",
                                                textAlign: "justify",
                                              }}
                                            >
                                              {truncateString(
                                                expenseCategory.policyDescription,
                                                264
                                              )}
                                            </Typography>
                                            <Box>
                                              <Link
                                                style={{
                                                  color: "#0B1941",
                                                  cursor: "pointer",
                                                  fontFamily: "Montserrat",
                                                  fontSize: "16px",
                                                  fontWeight: "500",
                                                  lineHeight: "50px",
                                                  letterSpacing: "0px",
                                                  textAlign: "left",
                                                  paddingTop: "20px",
                                                }}
                                                target="_blank"
                                                underline="always"
                                                onClick={handleOpenDescDialog}
                                              >
                                                View more
                                              </Link>
                                              <div>
                                                <Dialog
                                                  onClose={
                                                    handleCloseDescDialog
                                                  }
                                                  aria-labelledby="customized-dialog-title"
                                                  open={openDescDialog}
                                                >
                                                  <DialogTitleDescDialog
                                                    id="customized-dialog-title"
                                                    onClose={
                                                      handleCloseDescDialog
                                                    }
                                                  >
                                                    Policy Description
                                                  </DialogTitleDescDialog>
                                                  <DialogContentDescDialog
                                                    dividers
                                                  >
                                                    <Typography gutterBottom>
                                                      {
                                                        expenseCategory.policyDescription
                                                      }
                                                    </Typography>
                                                  </DialogContentDescDialog>
                                                  <DialogActionsDescDialog>
                                                    <Button
                                                      autoFocus
                                                      onClick={
                                                        handleCloseDescDialog
                                                      }
                                                      color="primary"
                                                    >
                                                      Close
                                                    </Button>
                                                  </DialogActionsDescDialog>
                                                </Dialog>
                                              </div>
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box>
                                          <CloseIcon
                                            onClick={handlePopoverClose}
                                          />
                                        </Box>
                                      </Box>
                                    ) : (
                                      ""
                                    )}
                                  </Popover>
                                  <Grid
                                    item
                                    xs={matches ? 12 : 6}
                                    sm={6}
                                    md={6}
                                    style={{
                                      paddingLeft: matches ? "" : "17px",
                                    }}
                                  >
                                    <Grid
                                      item
                                      style={
                                        {
                                          //marginRight: "-85px"
                                        }
                                      }
                                    >
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <KeyboardDatePicker
                                          disableToolbar
                                          inputVariant="outlined"
                                          fullWidth
                                          // className={
                                          //   selectDateError != ""
                                          //     ? classes.formControl12
                                          //     : classes.formControl
                                          // }
                                          className={
                                            validationArray[index].recieptMsg !=
                                            ""
                                              ? classes.formControl12
                                              : classes.formControl
                                          }
                                          style={{
                                            backgroundColor: "#FFFFFF",
                                            // marginLeft: matches
                                            //   ? "-15px"
                                            //   : "0px",
                                          }}
                                          variant="inline"
                                          format="MM/dd/yyyy"
                                          margin="normal"
                                          autoOk={true}
                                          disableFuture={true}
                                          id="date-picker-inline"
                                          label="Invoice/Receipt Date*"
                                          value={cat?.receiptdate || null} // Use the receiptdate from the 'cat' object
                                          onChange={(date) =>
                                            handleReceiptDate(date, index, cat)
                                          } // Pass the index here
                                          // onChange={(date) => handleReceiptDate(selectedCategory[index], date, index)}
                                          KeyboardButtonProps={{
                                            "aria-label": "change date",
                                            style: { paddingRight: "3px" },
                                          }}
                                          helperText={
                                            // selectedCategory[index]
                                            //   .receiptdate === null
                                            //   ? validationArray[index]
                                            //       .recieptMsg
                                            //   : ""
                                            validationArray[index].recieptMsg !=
                                            ""
                                              ? validationArray[index]
                                                  .recieptMsg
                                              : ""
                                          }
                                          FormHelperTextProps={{
                                            classes: {
                                              root: classes.errorRoot,
                                            },
                                          }}
                                          // disabled={isDatePickerDisabled}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                {cat?.categoryName &&
                                cat?.receiptdate != null ? (
                                  expenseCategory?.policyApplicable == "Y" &&
                                  (expenseCategory.exceptionalApproval == 1 ||
                                    expenseCategory.frequency == "Monthly") ? (
                                    <Box>
                                      <Grid
                                        container
                                        className={classes.rowContainer}
                                        style={{
                                          marginTop: "15px",
                                          padding: matches && "0px 16px",
                                        }}
                                      >
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={12}
                                          className={
                                            matches
                                              ? classes.infoBoxMobile
                                              : classes.infoBox
                                          }
                                        >
                                          <Grid
                                            item
                                            xs={matches ? 12 : 9}
                                            sm={matches ? 11 : 9}
                                            md={matches ? 11 : 10}
                                          >
                                            <Box
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                background: "#FFFFFF",
                                                border: "1px solid #008CE6",
                                                borderRadius: "4px",
                                                padding: "8px",
                                              }}
                                            >
                                              <InfoIcon />
                                              <Typography
                                                style={{
                                                  color: "#008CE6",
                                                  paddingLeft: "15px",
                                                  fontFamily: "Montserrat",
                                                  fontWeight: "600",
                                                  lineHeight: "16px",
                                                  letterSpacing: "0px",
                                                  fontSize: matches
                                                    ? "9px"
                                                    : "12px",
                                                  textAlign: "left",
                                                }}
                                              >
                                                NOTE: Policy limit for this
                                                expense is{" "}
                                                {expenseCategory?.currency}
                                                {noOfMon !== null &&
                                                noOfMon != 0 &&
                                                noOfMon != 1
                                                  ? `${
                                                      noOfMon *
                                                      expenseCategory?.policyLimit
                                                    } ( ${noOfMon} months )`
                                                  : expenseCategory?.policyLimit}
                                                .
                                              </Typography>
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  ) : expenseCategory?.policyApplicable ==
                                      "Y" &&
                                    expenseCategory.categoryId !== 11 ? (
                                    <Box>
                                      <Grid
                                        container
                                        className={classes.rowContainer}
                                        style={{
                                          marginTop: "15px",
                                          padding: matches && "0px 16px",
                                        }}
                                      >
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={12}
                                          className={
                                            matches
                                              ? classes.infoBoxMobile
                                              : classes.infoBox
                                          }
                                        >
                                          <Grid
                                            xs={matches ? 12 : 9}
                                            sm={matches ? 11 : 9}
                                            md={matches ? 11 : 10}
                                          >
                                            <Box
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                background: "#FFFFFF",
                                                border: "1px solid #008CE6",
                                                borderRadius: "4px",
                                                padding: "8px",
                                              }}
                                            >
                                              <InfoIcon />
                                              <Typography
                                                style={{
                                                  color: "#008CE6",
                                                  paddingLeft: "15px",
                                                  fontSize: matches
                                                    ? "8px"
                                                    : "12px",
                                                  textAlign: matches
                                                    ? "left"
                                                    : "",
                                                }}
                                              >
                                                <strong>
                                                  {" "}
                                                  NOTE: Your eligible amount for
                                                  the Category is{" "}
                                                  {expenseCategory?.currency}
                                                  {
                                                    empAmounts?.remainingAmount
                                                  }{" "}
                                                  as you have availed{" "}
                                                  {expenseCategory?.currency}
                                                  {empAmounts?.totalAmount} from
                                                  the policy limit{" "}
                                                  {expenseCategory?.currency}
                                                  {
                                                    expenseCategory?.policyLimit
                                                  }{" "}
                                                  .
                                                </strong>
                                              </Typography>
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )}
                                <Grid
                                  container
                                  // spacing={matches ? 2 : 4}
                                  className={
                                    matches
                                      ? classes.rowContainerMobile
                                      : classes.rowContainer
                                  }
                                  style={{
                                    marginTop: "2px",
                                    flexWrap: "nowrap",
                                    padding: matches ? "5px 16px" : "5px 9px",
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={matches ? 12 : 4}
                                    sm={4}
                                    md={4}
                                    style={{ padding: !matches && "0px 16px" }}
                                  >
                                    <Grid item>
                                      <FormControl
                                        variant="outlined"
                                        className={classes.formControl}
                                      >
                                        <InputLabel id="demo-simple-select-outlined-label">
                                          Currency*
                                        </InputLabel>
                                        {/* {console.log("KUSH1111", currency)} */}
                                        <Select
                                          labelId="demo-simple-select-outlined-label"
                                          id="demo-simple-select-outlined"
                                          className={
                                            matches
                                              ? classes.inputLabels1
                                              : classes.inputLabels
                                          }
                                          // value={selectedCategory?.currency}
                                          value={currency}
                                          style={
                                            {
                                              // marginRight: matches
                                              //   ? "-6px"
                                              //   : "0px",
                                            }
                                          }
                                          onChange={(e) => {
                                            handleCurrency(
                                              e.target.value,
                                              index
                                            );
                                            setCurrency(
                                              e.target.value
                                                ? e.target.value
                                                : currency
                                            );
                                          }}
                                          label="Currency*"
                                          disabled={index !== 0}
                                        >
                                          {CurrencyList?.map((item) => {
                                            return (
                                              <MenuItem
                                                key={item.currencyId}
                                                value={item}
                                              >
                                                {item.currencyCode}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>

                                        <FormHelperText
                                          className={classes.errorText}
                                        >
                                          {currency === "" ? currencyError : ""}
                                        </FormHelperText>
                                      </FormControl>{" "}
                                    </Grid>
                                  </Grid>
                                  {matches
                                    ? gridCond(index)
                                    : gridNormal(index)}
                                  <Grid
                                    item
                                    xs={matches ? 12 : 4}
                                    sm={4}
                                    md={4}
                                    style={{ padding: !matches && "0px 16px" }}
                                  >
                                    <Grid item>
                                      <FormControl
                                        variant="outlined"
                                        className={
                                          validationArray[index].paymentMsg !=
                                          ""
                                            ? classes.formControl12
                                            : classes.formControl
                                        }
                                      >
                                        <InputLabel id="demo-simple-select-outlined-label">
                                          Payment Method*
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-outlined-label"
                                          id="demo-simple-select-outlined"
                                          className={
                                            matches
                                              ? classes.inputLabels1
                                              : classes.inputLabels
                                          }
                                          // value={paymentList?.methodName}
                                          value={
                                            paymentList?.find(
                                              (item) =>
                                                item?.methodId ===
                                                cat?.paymentmethodid
                                            ) || ""
                                          }
                                          onChange={(e) => {
                                            setPayment(e.target.value);
                                            handlePayment(
                                              e.target.value.methodId,
                                              index
                                            );
                                            validationArray[index].paymentMsg =
                                              "";
                                          }}
                                          label="Payment Method**"
                                          MenuProps={{
                                            getContentAnchorEl: null,
                                          }}
                                        >
                                          {paymentList?.map((item) => {
                                            return (
                                              <MenuItem
                                                key={item.categoryId}
                                                value={item}
                                              >
                                                {item.methodName}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                        <FormHelperText
                                          className={classes.errorText}
                                        >
                                          {/* {payment === "" ? paymentError : ""} */}
                                          {selectedCategory[index]
                                            .paymentmethodid === ""
                                            ? validationArray[index].paymentMsg
                                            : ""}
                                        </FormHelperText>
                                      </FormControl>{" "}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Box style={{ margin: "15px 0px" }}>
                                  <Grid
                                    container
                                    // spacing={matches ? 2 : 4}
                                    // className={classes.rowContainer}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      // style={{
                                      //   padding: matches
                                      //     ? "2px 20px 25px 20px"
                                      //     : "2px 25px 30px 25px",
                                      // }}
                                    >
                                      <Grid item>
                                        <Box>
                                          {/* <DragDrope
                                            handleFileList={handleFileList1}
                                          /> */}
                                          <DragDropeTravel
                                            handleFileList={handleFileList1}
                                            index={index}
                                            fileDisplayForDD={fileDisplayForDD}
                                            // setFileDisplayForDD = {setFileDisplayForDD}
                                          />
                                          {validationArray[index]
                                            .attachmentMsg != "" ? (
                                            <Box
                                              style={{
                                                paddingBottom: "20px",
                                                paddingLeft: "26px",
                                              }}
                                            >
                                              <Typography
                                                variant="p"
                                                style={{
                                                  fontSize: "13px",
                                                  color: "red",
                                                  marginRight: "14px",
                                                  marginTop: 4,
                                                  fontFamily: "Montserrat",
                                                }}
                                              >
                                                {/* {dragDropFileError ? dragDropFileError : ""} */}
                                                {/* {selectedCategory[index]
                                                ?.expenseattachements[0]
                                                ?.filename === ""
                                                ? validationArray[index]
                                                    ?.attachmentMsg
                                                : ""} */}
                                                {
                                                  validationArray[index]
                                                    ?.attachmentMsg
                                                }
                                              </Typography>
                                            </Box>
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Box>
                                {index !== 0 ? (
                                  <Box display="flex" justifyContent="flex-end">
                                    <Button
                                      style={{ minWidth: "24px" }}
                                      onClick={() => handleRemove(index, cat)}
                                    >
                                      <span class="material-symbols-outlined">
                                        delete
                                      </span>
                                    </Button>
                                  </Box>
                                ) : (
                                  ""
                                )}
                                {/* </Box> */}
                              </Accordion>
                              {index == selectedCategory.length - 1 ? (
                                <Box
                                // style={{ marginBottom: "-12px" }}
                                >
                                  {/* <Box
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      marginTop: "-64px",
                                      minWidth: "104%",
                                    }}
                                  ></Box> */}
                                  <Box
                                    style={{
                                      //padding: "25px 0px 0px 0px",
                                      marginTop: "-35px",
                                      height: "50px",
                                      paddingLeft: matches ? "" : "25px",
                                    }}
                                  >
                                    <Link
                                      style={{
                                        color: "#008CE6",
                                        cursor: "pointer",
                                        fontFamily: "Montserrat",
                                        fontSize: matches ? "12px" : "16px",
                                        fontWeight: "500",
                                        lineHeight: "58px",
                                        letterSpacing: "0px",
                                        textAlign: "left",
                                        // marginLeft: "10px",
                                        // padding:"25px"
                                      }}
                                      target="_blank"
                                      underline="always"
                                      onClick={() => {
                                        addCategoryLine();
                                        setSelectedDate(null);
                                        // setPayment("");
                                      }}
                                    >
                                      + Add Expense
                                    </Link>
                                  </Box>
                                </Box>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Accordion
              key={0}
              expanded={expanded2}
              // expanded={expanded?.includes(index)}
              // onChange={handleAccordionChange(indexForAdvances , )}
              className={classes.projectDetailsMainBox}
              style={{
                margin: "16px 0px",
                padding: "0px ",
                //marginBottom: "30px",
                borderRadius: "0px",
                backgroundColor: "#F8F9FF",
                height: expanded1 == true ? "100%px" : "",
                "&.MuiPaper-elevation1": {
                  boxShadow: "0 0 0 0",
                },
                "&.MuiAccordionSummary-content.Mui-expanded": {
                  margin: "7px 0px",
                  minHeight: "27px",
                },
                "&.MuiCollapse-wrapper": {
                  backgroundColor: "#F8F9FF",
                  height: "90px",
                },
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    style={{
                      color: "#E3E8F7",
                      backgroundColor: "#0B1941",
                      borderRadius: "50%",
                      width: "16.03px",
                      height: "16.03px",
                    }}
                    isExpanded={expanded2}
                    onClick={handleAccordionChangeForSummary(expanded2)}
                  />
                }
                // id={index}
                // key={index}
                style={{
                  height: "10px",
                  boxShadow: "0px",
                  backgroundColor:
                    // selectedCategory[index].categoryName != ""
                    "#F6F6F6",

                  height: "10px",
                  "&.MuiAccordionSummary-root.Mui-expanded": {
                    minHeight: "27px",
                  },
                  ".MuiAccordionSummary-root": {
                    height: "38px",
                  },
                }}
              >
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Typography>
                      {" "}
                      <strong> Summary </strong>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography style={{ marginRight: "30px" }} align="right">
                      {Number(
                        Number((cash || "").toString().replaceAll(",", "")) +
                          Number(
                            (travelCardAmount || "")
                              .toString()
                              .replaceAll(",", "")
                          ) -
                          (Number(
                            (actualsCash || "").toString().replaceAll(",", "")
                          ) +
                            Number(
                              (actualsSelf || "").toString().replaceAll(",", "")
                            ) +
                            Number(
                              (actualsTravelCard || "")
                                .toString()
                                .replaceAll(",", "")
                            )) -
                          (Number(
                            (travelCardBalance || "")
                              .toString()
                              .replaceAll(",", "")
                          ) +
                            Number(
                              (cashBalance || "").toString().replaceAll(",", "")
                            ))
                      ) > 0
                        ? " *Net Payable:"
                        : " *Net Receivable:"}{" "}
                      {currency.currency}
                      {Math.abs(
                        Number(
                          Number((cash || "").toString().replaceAll(",", "")) +
                            Number(
                              (travelCardAmount || "")
                                .toString()
                                .replaceAll(",", "")
                            ) -
                            (Number(
                              (actualsCash || "").toString().replaceAll(",", "")
                            ) +
                              Number(
                                (actualsSelf || "")
                                  .toString()
                                  .replaceAll(",", "")
                              ) +
                              Number(
                                (actualsTravelCard || "")
                                  .toString()
                                  .replaceAll(",", "")
                              )) -
                            (Number(
                              (travelCardBalance || "")
                                .toString()
                                .replaceAll(",", "")
                            ) +
                              Number(
                                (cashBalance || "")
                                  .toString()
                                  .replaceAll(",", "")
                              ))
                        )
                      ).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <Box
                style={{
                  //marginTop: "16px",
                  backgroundColor: "#fff",
                  border: "0.5px solid rgba(0,0,0,0.2)",
                  height: "100%",
                  padding: "0px 0px 10px 0px",
                }}
              >
                <Grid
                  container
                  spacing={3}
                  style={{ padding: "10px 0px 0px 16px" }}
                >
                  <Grid
                    style={{
                      display: "flex",
                      direction: "row",
                      justifyContent: "space-between",
                      paddingRight: matches ? "30px" : "100px",
                    }}
                    item
                    xs={6}
                  >
                    <Box>
                      <strong>Advances</strong>
                    </Box>
                    <Box>
                      <strong>
                        {" "}
                        {currency.currency}
                        {Number(
                          Number(travelCardAmount) + Number(cash)
                        ).toFixed(2) || 0}
                      </strong>
                    </Box>
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      direction: "row",
                      justifyContent: "space-between",
                      paddingRight: matches ? "30px" : "100px",
                    }}
                    item
                    xs={6}
                  >
                    <Box>
                      <strong>Actuals</strong>
                    </Box>
                    <Box>
                      <strong>
                        {" "}
                        {currency.currency}
                        {Number(
                          actualsSelf + actualsCash + actualsTravelCard
                        )?.toFixed(2) || 0}
                      </strong>
                    </Box>
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      direction: "row",
                      justifyContent: "space-between",
                      paddingRight: matches ? "30px" : "100px",
                    }}
                    item
                    xs={6}
                  >
                    <Box>Travel Card:</Box>
                    <Box>
                      {" "}
                      {currency.currency}
                      {Number(travelCardAmount)?.toFixed(2) || 0}
                    </Box>
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      direction: "row",
                      justifyContent: "space-between",
                      paddingRight: matches ? "30px" : "100px",
                    }}
                    item
                    xs={6}
                  >
                    <Box>Travel Card:</Box>
                    <Box>
                      {" "}
                      {currency?.currency}
                      {Number(actualsTravelCard)?.toFixed(2) || 0}
                    </Box>
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      direction: "row",
                      justifyContent: "space-between",
                      paddingRight: matches ? "30px" : "100px",
                    }}
                    item
                    xs={6}
                  >
                    <Box>Cash:</Box>
                    <Box>
                      {currency.currency}
                      {Number(cash)?.toFixed(2) || 0}
                    </Box>
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      direction: "row",
                      justifyContent: "space-between",
                      paddingRight: matches ? "30px" : "100px",
                    }}
                    item
                    xs={6}
                  >
                    <Box>Cash:</Box>
                    <Box>
                      {currency.currency}
                      {Number(actualsCash)?.toFixed(2) || 0}
                    </Box>
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      direction: "row",
                      justifyContent: "space-between",
                      paddingRight: matches ? "30px" : "100px",
                    }}
                    item
                    xs={6}
                  >
                    <Box>{""}</Box>
                    <Box>
                      {/* {currency.currency}
                      {actualsSelf || 0} */}{" "}
                    </Box>
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      direction: "row",
                      justifyContent: "space-between",
                      paddingRight: matches ? "30px" : "100px",
                    }}
                    item
                    xs={6}
                  >
                    <Box>Self:</Box>
                    <Box>
                      {currency.currency}
                      {Number(actualsSelf)?.toFixed(2) || 0}
                    </Box>
                  </Grid>
                  <Divider
                    style={{
                      width: "99.2%",
                      marginLeft: "-4px",
                      height: "1px",
                    }}
                  />
                  <Grid item xs={12}>
                    <Box>
                      <Typography style={{ fontSize: "14px" }}>
                        <u>To be filled by finance</u>
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid
                    style={{
                      display: "flex",
                      direction: "row",
                      justifyContent: "space-between",
                      paddingRight: matches ? "30px" : "100px",
                      marginTop: "7px",
                    }}
                    item
                    xs={6}
                  >
                    <Box>
                      <strong>Refund Received</strong>
                    </Box>
                    <Box>
                      <strong>
                        {currency.currency}
                        {Number(
                          Number(cashBalance.replaceAll(",", "")) +
                            Number(travelCardBalance.replaceAll(",", ""))
                        )}
                      </strong>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    {/* <strong>Per Diem</strong> */}
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      direction: "row",
                      justifyContent: "space-between",
                      paddingRight: matches ? "30px" : "100px",
                    }}
                    item
                    xs={6}
                  >
                    <Box>Travel Card Balance:</Box>
                    <Box>
                      {currency.currency}
                      {travelCardBalance || 0}
                    </Box>
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      direction: "row",
                      justifyContent: "space-between",
                      paddingRight: matches ? "30px" : "100px",
                    }}
                    item
                    xs={6}
                  >
                    <Box>{/* Per Diem with Bills - Non Taxable: */}</Box>
                    <Box></Box>
                  </Grid>

                  <Grid
                    style={{
                      display: "flex",
                      direction: "row",
                      justifyContent: "space-between",
                      paddingRight: matches ? "30px" : "100px",
                    }}
                    item
                    xs={6}
                  >
                    <Box>Cash Balance:</Box>
                    <Box>
                      {currency.currency}
                      {Number(cashBalance)}
                    </Box>
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      direction: "row",
                      justifyContent: "space-between",
                      paddingRight: matches ? "30px" : "100px",
                    }}
                    item
                    xs={6}
                  >
                    <Box>{/* Per Diem without Bills - Taxable: */}</Box>
                    <Box>
                      {/* <NumericFormat
                        variant="outlined"
                        id="outlined-basic"
                        label="Enter balance"
                        value={0}
                        style={{ width: "50%" }}
                        //onChange={}
                        onKeyPress={preventMinus}
                        thousandSeparator={true}
                        fullWidth
                        customInput={TextField}
                        className={classes.formControl}
                      /> */}
                    </Box>
                  </Grid>

                  <Grid
                    style={{
                      display: "flex",
                      direction: "row",
                      justifyContent: "space-between",
                      paddingRight: matches ? "30px" : "100px",
                    }}
                    item
                    xs={6}
                  >
                    <Box>{""}</Box>
                    <Box></Box>
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      direction: "row",
                      justifyContent: "space-between",
                      paddingRight: matches ? "30px" : "100px",
                    }}
                    item
                    xs={6}
                  >
                    {/* <Box>Travel Card Balance:</Box>
                    <Box>
                      <TextField
                        variant="outlined"
                        label="Enter Balance"
                        value={travelCardBalance}
                        onChange={(e) => setTravelCardBalance(e.target.value)}
                      />
                    </Box> */}
                  </Grid>

                  <Grid item xs={matches ? 12 : 6}>
                    <Box
                      style={{
                        color: "rgba(0, 0, 0, 0.7)",
                        fontSize: matches ? "12px" : "14px",
                      }}
                    >
                      *Net Payable: To be refunded to the company.{" "}
                    </Box>
                  </Grid>
                  <Grid item xs={matches ? 12 : 6}>
                    <Box
                      style={{
                        color: "rgba(0, 0, 0, 0.7)",
                        fontSize: matches ? "12px" : "14px",
                      }}
                    >
                      *Net Receivable: To be claimed from the company.{" "}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Accordion>

            <Box>
              <Grid
                container
                className={classes.rowContainer}
                style={{
                  // marginTop: "30px",
                  padding: matches && "0px 20px",
                }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Grid item>
                    <Box>
                      <div
                        className={classes.checkBox}
                        style={{
                          margin: matches
                            ? "10px 0px 30px 0px"
                            : "50px 0px 30px 0px",
                        }}
                      >
                        <Checkbox
                          style={{
                            // marginTop: "88px"
                            padding: "0px",
                          }}
                          // color="primary"
                          className={
                            declarationCheckBox !== false
                              ? classes.checkBoxRoot1
                              : ""
                          }
                          inputProps={{ "aria-label": "secondary checkbox" }}
                          onChange={(e) => {
                            handleCheckBox(e);
                          }}
                        />
                        <Typography
                          // className={classes.checkBoxText}
                          style={{
                            fontSize: matches ? "10px" : "",
                            lineHeight: matches ? "12px" : "",
                            paddingLeft: matches ? "8px" : "16px",
                            // paddingTop: "90px",
                          }}
                        >
                          I hereby declare that all information given above is
                          true as per my knowledge.
                        </Typography>
                      </div>
                      <Box>
                        <Typography
                          variant="p"
                          style={{
                            fontSize: "13px",
                            color: "red",
                            // marginLeft: "14px",
                            marginRight: "14px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {declarationError ? declarationError : ""}
                        </Typography>
                      </Box>
                      <Box
                        className={
                          matches
                            ? classes.saveSubmitButtonMobile
                            : classes.saveSubmitButton
                        }
                      >
                        <Box style={{ paddingRight: "10px" }}>
                          <Button
                            variant="outlined"
                            color="#0B1941"
                            style={{
                              minWidth: "140px",
                              minHeight: "36px",
                              border: "1px solid #0B1941",
                              marginRight: matches ? "15px" : "",
                            }}
                            disabled={disableSubmit}
                            onClick={() => {
                              handleSave();
                            }}
                          >
                            Save
                          </Button>
                        </Box>
                        {expenseCategory?.exceptionalApproval == 1 &&
                        claimAmount != "" &&
                        claimAmount > expenseCategory?.policyLimit ? (
                          <>
                            <Button
                              variant="outlined"
                              style={{
                                minWidth: "140px",
                                minHeight: "36px",
                                background: "#CCCCCC",
                                backgroundColor: "#043365",
                                color: "rgba(255, 255, 255, 0.9)",
                              }}
                              onClick={handleOpenEDB}
                            >
                              Submit
                            </Button>
                            {/* Dialog update start */}
                            <Dialog
                              open={openExceptionalDialog}
                              onClose={handleCloseEDB}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle id="alert-dialog-title">
                                <Typography
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "24px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Exceptional Approval
                                </Typography>
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                  This expense requires exceptional approval. It
                                  will go to PM for approval.
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={handleCloseEDB}
                                  color="primary"
                                >
                                  CANCEL
                                </Button>
                                <Button
                                  onClick={() => {
                                    handleClick();
                                  }}
                                  color="primary"
                                  autoFocus
                                >
                                  OK
                                </Button>
                              </DialogActions>
                            </Dialog>
                            {/* Dialog update end */}
                          </>
                        ) : (
                          <Button
                            variant="outlined"
                            style={{
                              minWidth: "140px",
                              minHeight: "36px",
                              background: "#CCCCCC",
                              backgroundColor: "#043365",
                              color: "rgba(255, 255, 255, 0.9)",
                            }}
                            disabled={disableSubmit}
                            onClick={() => {
                              handleClick();
                            }}
                          >
                            Submit
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>{" "}
        </>
      ) : (
        <>
          <Box className={matches ? classes.rootMobile : classes.root}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                // marginLeft: matches ? "-9px" : "0px",
              }}
            >
              {matches ? (
                ""
              ) : (
                <Box
                  style={{
                    paddingRight: "20px",
                    marginLeft: "-70px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton>
                    <ArrowBackIosIcon
                      onClick={() => {
                        history.push("/expense-Management");
                      }}
                    />
                  </IconButton>
                </Box>
              )}

              <Box className={classes.expenseHeading}>
                <Typography
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontSize: matches ? "14px" : "24px",
                    lineHeight: "24px",
                    letterSpacing: "0px",
                    textAlign: "left",
                    // paddingLeft: matches ? "14px" : "",
                    color: "#2B2D30",
                  }}
                >
                  New Expense Report
                </Typography>
                {isCreatePostSuccess &&
                postCreateExpenseFormData?.expStatus == "Draft" ? (
                  <SnackBar
                    handleSnackbar={handleSnackbar}
                    handlecloseSnackbar={handlecloseSnackbar}
                    expenseStatus={postCreateExpenseFormData?.expStatus}
                    open={open}
                    expenseCode={postCreateExpenseFormData?.expenseCode}
                  />
                ) : isCreatePostSuccess &&
                  postCreateExpenseFormData?.expStatus == "In Progress" ? (
                  <SnackBar
                    handleSnackbar={handleSnackbar}
                    handlecloseSnackbar={handlecloseSnackbar}
                    expenseStatus={postCreateExpenseFormData?.expStatus}
                    open={open}
                    expenseCode={postCreateExpenseFormData?.expenseCode}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Box>
            <Box>
              <Box>
                <Grid
                  container
                  spacing={matches ? 2 : 4}
                  className={
                    matches ? classes.rowContainerMobile : classes.rowContainer
                  }
                >
                  <Grid item xs={matches ? 11 : 6} sm={6} md={6}>
                    <Grid item>
                      <FormControl
                        variant="outlined"
                        className={
                          purposeError != ""
                            ? classes.formControl12
                            : classes.formControl
                        }
                        style={{ marginTop: matches ? "5px" : "" }}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Purpose*
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={purpose}
                          className={
                            matches ? classes.inputLabels1 : classes.inputLabels
                          }
                          onChange={(e) => {
                            setExpenseCategory("");
                            setPurposeError("");
                            setPurpose(e.target.value);
                            setSelectedDate(null);
                            setFromDate(null);
                            setToDate(null);
                            setForMonth("");
                            setFromLocation("");
                            setToLocation("");
                            setInBetweenDistance(0);
                            setVendorName(null);
                            setGST(null);
                            setBillNumber(null);
                            setDependents("");
                            setAttendees([]);
                            projectData(e);
                          }}
                          label="Purpose*"
                          MenuProps={{
                            getContentAnchorEl: null,
                          }}
                        >
                          {purposeList?.map((item) => {
                            return (
                              <MenuItem key={item.purposeId} value={item}>
                                <Typography
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: matches ? "13px" : "",
                                    fontWeight: matches ? 500 : "",
                                    letterSpacing: matches ? "-1px" : "",
                                    textAlign: "left",
                                  }}
                                >
                                  {item.purpose}
                                </Typography>
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText className={classes.errorText}>
                          {purpose === "" ? purposeError : ""}
                        </FormHelperText>
                      </FormControl>{" "}
                    </Grid>
                  </Grid>

                  <Grid item xs={matches ? 11 : 6} sm={6} md={6}>
                    <Grid item>
                      <FormControl
                        variant="outlined"
                        className={
                          expenseCategoryError != ""
                            ? classes.formControl12
                            : classes.formControl
                        }
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Expense Category*
                        </InputLabel>
                        <Select
                          style={{ borderRadius: 4 }}
                          className={
                            matches ? classes.inputLabels1 : classes.inputLabels
                          }
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          defaultValue=""
                          label={"Expense Category*"}
                          value={expenseCategory}
                          onChange={(e) => {
                            setSelectDateError("");
                            setExpenseCategoryError("");
                            setExpenseCategory(e.target.value);
                            setSelectedDate(null);
                            setFromDate(null);
                            setToDate(null);
                            setForMonth("");
                            setFromLocation("");
                            setToLocation("");
                            setInBetweenDistance(0);
                            setVendorName("");
                            setGST("");
                            setBillNumber("");
                            setDependents("");
                            projectData(e);
                          }}
                        >
                          {CategoryList?.map((item) => {
                            return (
                              <MenuItem key={item.categoryId} value={item}>
                                <Typography
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: matches ? "12px" : "",
                                    fontWeight: matches ? 500 : "",
                                    letterSpacing: matches ? "-1px" : "",
                                    textAlign: "left",
                                  }}
                                >
                                  {item.categoryName}
                                </Typography>
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText className={classes.errorText}>
                          {expenseCategory === "" ? expenseCategoryError : ""}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "-64px",
                  minWidth: "104%",
                }}
              >
                <Box>
                  <InfoOutlinedIcon
                    style={{ paddingRight: matches ? "14px" : "" }}
                    onClick={handlePopoverOpen}
                  />
                </Box>
                <Popover
                  id="mouse-over-popover"
                  className={classes.popover}
                  classes={{
                    paper: classes.paper,
                  }}
                  open={openPopOver}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  {expenseCategory.policyDescription ? (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        maxWidth: "325px",
                        maxHeight: "248px",
                      }}
                    >
                      <Box
                        style={{
                          padding: "16px",
                        }}
                      >
                        <Box>
                          <Typography
                            color="inherit"
                            style={{
                              fontFamily: "Montserrat",
                              fontSize: "14px",
                              fontWeight: "600",
                              lineHeight: "20px",
                              letterSpacing: "0em",
                              textAlign: "left",
                            }}
                          >
                            POLICY DESCRIPTION
                          </Typography>
                        </Box>

                        <Box
                          style={{
                            paddingTop: "4px",
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: "Montserrat",
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "16px",
                              letterSpacing: "0em",
                              textAlign: "justify",
                            }}
                          >
                            {truncateString(
                              expenseCategory.policyDescription,
                              264
                            )}
                            {/* {console.log(
                          "policy desc:====> ",
                          truncateString(expenseCategory.policyDescription, 8)
                        )} */}
                          </Typography>
                          <Box>
                            <Link
                              style={{
                                color: "#0B1941",
                                cursor: "pointer",
                                fontFamily: "Montserrat",
                                fontSize: "16px",
                                fontWeight: "500",
                                lineHeight: "50px",
                                letterSpacing: "0px",
                                textAlign: "left",
                                paddingTop: "20px",
                              }}
                              target="_blank"
                              underline="always"
                              onClick={handleOpenDescDialog}
                            >
                              View more
                            </Link>
                            <div>
                              <Dialog
                                onClose={handleCloseDescDialog}
                                aria-labelledby="customized-dialog-title"
                                open={openDescDialog}
                              >
                                <DialogTitleDescDialog
                                  id="customized-dialog-title"
                                  onClose={handleCloseDescDialog}
                                >
                                  Policy Description
                                </DialogTitleDescDialog>
                                <DialogContentDescDialog dividers>
                                  <Typography gutterBottom>
                                    {expenseCategory.policyDescription}
                                  </Typography>
                                </DialogContentDescDialog>
                                <DialogActionsDescDialog>
                                  <Button
                                    autoFocus
                                    onClick={handleCloseDescDialog}
                                    color="primary"
                                  >
                                    Close
                                  </Button>
                                </DialogActionsDescDialog>
                              </Dialog>
                            </div>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <CloseIcon onClick={handlePopoverClose} />
                      </Box>
                    </Box>
                  ) : (
                    ""
                  )}
                </Popover>
              </Box>
            </Box>
            {/* {expenseCategory?.categoryId == 2 && (
              <Box>
                <Grid
                  container
                  spacing={matches ? 2 : 4}
                  className={
                    matches ? classes.rowContainerMobile : classes.rowContainer
                  }
                  style={{ marginTop: "30px" }}//siddhant
                >
                  <Grid
                    item
                    xs={matches ? 12 : 6}
                    sm={6}
                    md={6}
                    style={{ paddingRight: !matches && "17px" }}
                  >
                    <Grid item>
                      <TextField
                        id="outlined-basic"
                        label="Expense Name*"
                        variant="outlined"
                        style={{
                          backgroundColor: "#FFFFFF",
                          margin: matches ? "12px 0px 8px" : "",
                        }}
                        fullWidth
                        className={
                          expenseNameError != ""
                            ? classes.formControl12
                            : classes.formControl
                        }
                        onChange={(e) => {
                          setExpenseNameError("");
                          setSelectDateError("");
                          setExpenseName(
                            DOMPurify.sanitize(e.target.value.trimStart())
                          );
                        }}
                        helperText={
                          expenseNameError !== "" ? expenseNameError : ""
                        }
                        FormHelperTextProps={{
                          classes: {
                            root: classes.errorRoot,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={matches ? 11 : 6} sm={6} md={3}>
                    <Grid
                      item
                      xs={matches ? 11 : 6}
                      sm={6}
                      md={6}
                      style={{ paddingLeft: matches ? "" : "17px" }}
                    >
                      <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            inputVariant="outlined"
                            fullWidth
                            className={
                              selectDateError != ""
                                ? classes.formControl12
                                : classes.formControl
                            }
                            style={{
                              backgroundColor: "#FFFFFF",
                              marginLeft: matches ? "-15px" : "0px",
                            }}
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            autoOk={true}
                            disableFuture={true}
                            id="date-picker-inline"
                            label="Invoice/Reciept Date*"
                            value={selectedDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            helperText={
                              selectedDate === null
                                ? selectDateError
                                : validateJoiningDate
                                ? selectDateError
                                : valiDateDate
                                ? selectDateError
                                : validateQuarter
                                ? selectDateError
                                : ""
                            }
                            FormHelperTextProps={{
                              classes: {
                                root: classes.errorRoot,
                              },
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={matches ? 11 : 6} sm={6} md={4}>
                    <Grid item>
                      <NumericFormat
                        style={{
                          margin: "16px 0 8px 0",
                          backgroundColor: "#FFFFFF",
                        }}
                        inputProps={{ maxLength: 12 }}
                        decimalScale={2}
                        id="outlined-basic"
                        label="Claim Amount*"
                        onKeyPress={preventMinus}
                        variant="outlined"
                        fullWidth
                        className={
                          claimAmountError != ""
                            ? classes.formControl12
                            : classes.formControl
                        }
                        onChange={(e) => {
                          setClaimAmountError("");
                          handleClaimAmount(DOMPurify.sanitize(e.target.value));
                        }}
                        helperText={
                          claimAmountError !== "" ? claimAmountError : ""
                        }
                        thousandSeparator={true}
                        customInput={TextField}
                        {...materialUITextFieldProps2}
                        FormHelperTextProps={{
                          classes: {
                            root: classes.errorRoot,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )} */}
            {expenseCategory?.qtyEnabledfl === "1" && (
              <Box>
                {/* {console.log("expenseCategory", expenseCategory)} */}
                <Grid
                  container
                  spacing={matches ? 2 : 4}
                  className={
                    matches ? classes.rowContainerMobile : classes.rowContainer
                  }
                  style={{ marginTop: "30px" }}
                >
                  <Grid
                    item
                    xs={matches ? 11 : 6}
                    sm={6}
                    md={6}
                    style={{ paddingLeft: matches ? "" : "17px" }}
                  >
                    <Grid item>
                      <TextField
                        style={{
                          margin: "16px 0 8px 0",
                          backgroundColor: "#FFFFFF",
                        }}
                        id="outlined-basic"
                        label="Bill Number*"
                        // onKeyPress={preventMinus}
                        onKeyDown={handleKeyDown}
                        variant="outlined"
                        fullWidth
                        className={
                          billNumberError != ""
                            ? classes.formControl12
                            : classes.formControl
                        }
                        onChange={(e) => {
                          const inputBillNumber = e.target.value.trimStart();

                          setBillNumberError("");
                          setBillNumber(DOMPurify.sanitize(inputBillNumber));
                        }}
                        helperText={
                          billNumberError !== "" ? billNumberError : ""
                        }
                        FormHelperTextProps={{
                          classes: {
                            root: classes.errorRoot,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  {/* <Grid
                    item
                    xs={matches ? 12 : 6}
                    sm={6}
                    md={6}
                    style={{ paddingRight: matches ? "38px" : "" }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Expense Name*"
                      variant="outlined"
                      style={{
                        backgroundColor: "#FFFFFF",
                        margin: matches ? "12px 0px 8px" : "",
                      }}
                      fullWidth
                      className={
                        expenseNameError != ""
                          ? classes.formControl12
                          : classes.formControl
                      }
                      onChange={(e) => {
                        setExpenseNameError("");
                        setSelectDateError("");
                        setExpenseName(
                          DOMPurify.sanitize(e.target.value.trimStart())
                        );
                      }}
                      helperText={
                        expenseNameError !== "" ? expenseNameError : ""
                      }
                      FormHelperTextProps={{
                        classes: {
                          root: classes.errorRoot,
                        },
                      }}
                    />
                  </Grid> */}
                  {/* <Grid
                    item
                    xs={matches ? 12 : 6}
                    sm={6}
                    md={3}
                    style={{ padding: matches ? "0px 23px 0px 23px" : "" }}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        inputVariant="outlined"
                        fullWidth
                        className={
                          selectDateError != ""
                            ? classes.formControl12
                            : classes.formControl
                        }
                        style={{
                          backgroundColor: "#FFFFFF",
                          marginLeft: matches ? "-15px" : "0px",
                        }}
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        autoOk={true}
                        disableFuture={true}
                        id="date-picker-inline"
                        label="Invoice/Receipt Date*"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        helperText={
                          selectedDate === null
                            ? selectDateError
                            : validateJoiningDate
                            ? selectDateError
                            : valiDateDate
                            ? selectDateError
                            : validateQuarter
                            ? selectDateError
                            : ""
                        }
                        FormHelperTextProps={{
                          classes: {
                            root: classes.errorRoot,
                          },
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid> */}
                  <Grid
                    item
                    xs={matches ? 11 : 6}
                    sm={6}
                    md={6}
                    //  style={{ paddingLeft: matches ? "" : "17px" }}
                    style={{ paddingRight: matches ? "7px" : "" }}
                  >
                    <NumericFormat
                      style={{
                        margin: "16px 0 8px 0",
                        backgroundColor: "#FFFFFF",
                      }}
                      inputProps={{ maxLength: 12 }}
                      decimalScale={2}
                      id="outlined-basic"
                      label="Quantity*"
                      onKeyPress={preventMinus}
                      onKeyDown={handleKeyDown}
                      variant="outlined"
                      fullWidth
                      className={
                        quantityError != ""
                          ? classes.formControl12
                          : classes.formControl
                      }
                      onChange={(e) => {
                        setQuantityError("");
                        handleQuantity(DOMPurify.sanitize(e.target.value));
                      }}
                      helperText={quantityError !== "" ? quantityError : ""}
                      thousandSeparator={true}
                      customInput={TextField}
                      // {...materialUITextFieldProps2}
                      FormHelperTextProps={{
                        classes: {
                          root: classes.errorRoot,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}

            {expenseCategory?.companyExpenseFlag === 1 && (
              <Box>
                <Grid
                  container
                  spacing={matches ? 2 : 4}
                  className={
                    matches ? classes.rowContainerMobile : classes.rowContainer
                  }
                  style={{ marginTop: "30px" }}
                >
                  <Grid item xs={matches ? 11 : 6} sm={6} md={4}>
                    <Grid item>
                      <TextField
                        style={{
                          margin: "16px 0 8px 0",
                          backgroundColor: "#FFFFFF",
                        }}
                        id="outlined-basic"
                        label="Vendor Name*"
                        variant="outlined"
                        fullWidth
                        className={
                          vendorNameError != ""
                            ? classes.formControl12
                            : classes.formControl
                        }
                        value={vendorName}
                        onChange={(e) => {
                          setVendorNameError("");
                          setVendorName(DOMPurify.sanitize(e.target.value));
                        }}
                        helperText={
                          vendorNameError !== "" ? vendorNameError : ""
                        }
                        FormHelperTextProps={{
                          classes: {
                            root: classes.errorRoot,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={matches ? 11 : 6} sm={6} md={4}>
                    <Grid item>
                      <TextField
                        style={{
                          margin: "16px 0 8px 0",
                          backgroundColor: "#FFFFFF",
                        }}
                        id="outlined-basic"
                        label="Bill Number*"
                        variant="outlined"
                        fullWidth
                        className={
                          billNumberError != ""
                            ? classes.formControl12
                            : classes.formControl
                        }
                        onChange={(e) => {
                          setBillNumberError("");
                          setBillNumber(DOMPurify.sanitize(e.target.value));
                        }}
                        helperText={
                          billNumberError !== "" ? billNumberError : ""
                        }
                        FormHelperTextProps={{
                          classes: {
                            root: classes.errorRoot,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={matches ? 11 : 6} sm={6} md={4}>
                    <Grid item>
                      <TextField
                        // style={{ marginLeft: "28px" }}
                        style={{
                          margin: "16px 0 8px 0",
                          backgroundColor: "#FFFFFF",
                        }}
                        id="outlined-basic"
                        label="GST*"
                        variant="outlined"
                        fullWidth
                        className={
                          gstError != ""
                            ? classes.formControl12
                            : classes.formControl
                        }
                        onChange={(e) => {
                          setGstError("");
                          setGST(DOMPurify.sanitize(e.target.value));
                        }}
                        helperText={gstError !== "" ? gstError : ""}
                        FormHelperTextProps={{
                          classes: {
                            root: classes.errorRoot,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}

            {expenseCategory?.billingCycle == 1 && (
              <Box>
                <Grid
                  container
                  spacing={matches ? 2 : 4}
                  className={
                    matches ? classes.rowContainerMobile : classes.rowContainer
                  }
                  style={{ marginTop: "30px" }}
                >
                  <Grid item xs={matches ? 11 : 6} sm={6} md={4}>
                    <Grid item>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          inputVariant="outlined"
                          fullWidth
                          className={
                            fromDateError != ""
                              ? classes.formControl12
                              : classes.formControl
                          }
                          style={{
                            backgroundColor: "#FFFFFF",
                          }}
                          variant="inline"
                          format="MM/dd/yyyy"
                          margin="normal"
                          autoOk={true}
                          id="date-picker-inline"
                          label="From Date*"
                          disableFuture={true}
                          value={fromDate}
                          onChange={handleFromDate}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          helperText={
                            fromDate === null || fromDate == ""
                              ? fromDateError
                              : validateFromDate
                              ? fromDateError
                              : ""
                          }
                          FormHelperTextProps={{
                            classes: {
                              root: classes.errorRoot,
                            },
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                  <Grid item xs={matches ? 11 : 6} sm={6} md={4}>
                    <Grid item>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          inputVariant="outlined"
                          fullWidth
                          className={
                            toDateError != ""
                              ? classes.formControl12
                              : classes.formControl
                          }
                          style={{
                            backgroundColor: "#FFFFFF",
                          }}
                          variant="inline"
                          format="MM/dd/yyyy"
                          margin="normal"
                          autoOk={true}
                          minDate={fromDate}
                          // disableFuture={true}
                          id="date-picker-inline"
                          label="To Date*"
                          value={toDate}
                          onChange={handleToDate}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          helperText={
                            toDate === null || toDate == ""
                              ? toDateError
                              : validateToDate
                              ? toDateError
                              : ""
                          }
                          FormHelperTextProps={{
                            classes: {
                              root: classes.errorRoot,
                            },
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                  <Grid item xs={matches ? 11 : 6} sm={6} md={4}>
                    <Grid item>
                      <FormControl
                        variant="outlined"
                        className={
                          forMonthError != ""
                            ? classes.formControl12
                            : classes.formControl
                        }
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Claim Month*
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={forMonth}
                          className={
                            matches ? classes.inputLabels1 : classes.inputLabels
                          }
                          onChange={(e) => {
                            setForMonth(e.target.value);
                            setForMonthError("");
                          }}
                          label="Claim Month*"
                          MenuProps={{
                            getContentAnchorEl: null,
                          }}
                        >
                          {monthList?.map((item) => {
                            return (
                              <MenuItem
                                key={item.monthId}
                                value={item.monthName}
                                style={{ fontSize: matches ? "13px" : "" }}
                              >
                                {item.monthName}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText className={classes.errorText}>
                          {forMonth === null ? forMonthError : ""}
                        </FormHelperText>
                      </FormControl>{" "}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
            {expenseCategory?.distance == 1 && (
              <Box>
                <Grid
                  container
                  spacing={matches ? 2 : 4}
                  className={
                    matches ? classes.rowContainerMobile : classes.rowContainer
                  }
                  style={{ marginTop: "30px" }}
                >
                  <Grid item xs={matches ? 11 : 6} sm={6} md={4}>
                    <Grid item>
                      <TextField
                        style={{
                          margin: "16px 0 8px 0",
                          backgroundColor: "#FFFFFF",
                        }}
                        id="outlined-basic"
                        label="From Location*"
                        variant="outlined"
                        fullWidth
                        className={
                          fromLocationError != ""
                            ? classes.formControl12
                            : classes.formControl
                        }
                        value={fromLocation}
                        onChange={(e) => {
                          setFromLocationError("");
                          setFromLocation(
                            DOMPurify.sanitize(e.target.value.trimStart())
                          );
                        }}
                        helperText={
                          fromLocationError !== "" ? fromLocationError : ""
                        }
                        FormHelperTextProps={{
                          classes: {
                            root: classes.errorRoot,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={matches ? 11 : 6} sm={6} md={4}>
                    <Grid item>
                      <TextField
                        style={{
                          margin: "16px 0 8px 0",
                          backgroundColor: "#FFFFFF",
                        }}
                        id="outlined-basic"
                        label="To Location*"
                        variant="outlined"
                        fullWidth
                        value={toLocation}
                        className={
                          toLocationError != ""
                            ? classes.formControl12
                            : classes.formControl
                        }
                        onChange={(e) => {
                          setToLocationError("");
                          setToLocation(
                            DOMPurify.sanitize(e.target.value.trimStart())
                          );
                        }}
                        helperText={
                          toLocationError !== "" ? toLocationError : ""
                        }
                        FormHelperTextProps={{
                          classes: {
                            root: classes.errorRoot,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={matches ? 11 : 6} sm={6} md={4}>
                    <Grid item>
                      <NumericFormat
                        // style={{ marginLeft: "28px" }}
                        style={{
                          margin: "16px 0 8px 0",
                          backgroundColor: "#FFFFFF",
                        }}
                        id="outlined-basic"
                        customInput={TextField}
                        label="Distance (Km/Miles)*"
                        variant="outlined"
                        min="0"
                        onKeyPress={preventMinus}
                        fullWidth
                        value={inBetweenDistance}
                        className={
                          inBetweenDistanceError != ""
                            ? classes.formControl12
                            : classes.formControl
                        }
                        onChange={(e) => {
                          setInBetweenDistanceError("");
                          setInBetweenDistance(
                            DOMPurify.sanitize(e.target.value)
                          );
                        }}
                        helperText={
                          inBetweenDistanceError !== ""
                            ? inBetweenDistanceError
                            : ""
                        }
                        FormHelperTextProps={{
                          classes: {
                            root: classes.errorRoot,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}

            {/* {expenseCategory?.qtyEnabledfl !== "1" && ( */}
            <>
              <Grid
                container
                spacing={matches ? 2 : 6}
                className={classes.rowContainer}
                style={{
                  marginTop:
                    purpose === 1 && expenseCategory.categoryId === 1
                      ? "5px"
                      : "25px",
                }}
              >
                {/* {expenseCategory?.qtyEnabledfl !== "1" && ( */}
                <>
                  {" "}
                  <Grid
                    item
                    xs={matches ? 11 : 6}
                    sm={6}
                    md={6}
                    style={{ paddingRight: matches ? "" : "17px" }}
                  >
                    <Grid item>
                      <TextField
                        id="outlined-basic"
                        label="Expense Name*"
                        variant="outlined"
                        style={{
                          backgroundColor: "#FFFFFF",
                          marginLeft: matches ? "-15px" : "0px",
                        }}
                        inputProps={{ maxLength: 80 }}
                        fullWidth
                        className={
                          expenseNameError != ""
                            ? classes.formControl12
                            : classes.formControl
                        }
                        onChange={(e) => {
                          setExpenseNameError("");
                          setSelectDateError("");
                          setExpenseName(
                            DOMPurify.sanitize(e.target.value.trimStart())
                          );
                        }}
                        helperText={
                          expenseNameError !== "" ? expenseNameError : ""
                        }
                        FormHelperTextProps={{
                          classes: {
                            root: classes.errorRoot,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
                {/* )} */}

                {DependentsList?.length > 0 && DependentsList[0] != " " ? (
                  <>
                    <Grid
                      item
                      xs={matches ? 11 : 3}
                      sm={3}
                      md={3}
                      style={{ paddingLeft: matches ? "" : "17px" }}
                    >
                      <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            inputVariant="outlined"
                            fullWidth
                            className={
                              selectDateError != ""
                                ? classes.formControl12
                                : classes.formControl
                            }
                            style={{
                              backgroundColor: "#FFFFFF",
                              marginLeft: matches ? "-15px" : "0px",
                            }}
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            autoOk={true}
                            disableFuture={true}
                            id="date-picker-inline"
                            label="Invoice/Receipt Date*"
                            value={selectedDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                              style: { paddingRight: "3px" },
                            }}
                            helperText={
                              selectedDate === null
                                ? selectDateError
                                : validateJoiningDate
                                ? selectDateError
                                : valiDateDate
                                ? selectDateError
                                : validateQuarter
                                ? selectDateError
                                : ""
                            }
                            FormHelperTextProps={{
                              classes: {
                                root: classes.errorRoot,
                              },
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={matches ? 11 : 3}
                      sm={3}
                      md={3}
                      style={{
                        paddingLeft: matches ? "" : "17px",
                        marginRight: matches ? "27px" : "",
                      }}
                    >
                      <Grid item>
                        <FormControl
                          variant="outlined"
                          className={
                            dependentsError != ""
                              ? classes.formControl12
                              : classes.formControl
                          }
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Relationship*
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            className={
                              matches
                                ? classes.inputLabels1
                                : classes.inputLabels
                            }
                            value={dependents}
                            onChange={(e) => {
                              setDependents(e.target.value);
                              setDependentsError("");
                            }}
                            label="Relationship*"
                            MenuProps={{
                              getContentAnchorEl: null,
                            }}
                          >
                            {DependentsList?.map((item, index) => {
                              //{console.log("ayuship" , item.dependent)}
                              return (
                                <MenuItem key={item.dependentId} value={item}>
                                  {item.dependent}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText className={classes.errorText}>
                            {dependents == "" ? dependentsError : ""}
                          </FormHelperText>
                        </FormControl>{" "}
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Grid
                    item
                    xs={matches ? 11 : 6}
                    sm={6}
                    md={6}
                    style={{ paddingLeft: matches ? "" : "17px" }}
                  >
                    {/* {expenseCategory?.qtyEnabledfl !== "1" && ( */}
                    <Grid item>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          inputVariant="outlined"
                          fullWidth
                          className={
                            selectDateError != ""
                              ? classes.formControl12
                              : classes.formControl
                          }
                          style={{
                            backgroundColor: "#FFFFFF",
                            marginLeft: matches ? "-15px" : "0px",
                          }}
                          variant="inline"
                          format="MM/dd/yyyy"
                          margin="normal"
                          autoOk={true}
                          disableFuture={true}
                          id="date-picker-inline"
                          label="Invoice/Receipt Date*"
                          value={selectedDate}
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          helperText={
                            selectedDate === null
                              ? selectDateError
                              : validateJoiningDate
                              ? selectDateError
                              : valiDateDate
                              ? selectDateError
                              : validateQuarter
                              ? selectDateError
                              : ""
                          }
                          FormHelperTextProps={{
                            classes: {
                              root: classes.errorRoot,
                            },
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    {/* )} */}
                  </Grid>
                )}
              </Grid>
            </>
            {/* )} */}
            <Box></Box>
            {expenseCategory && selectedDate != null ? (
              expenseCategory?.policyApplicable == "Y" &&
              (expenseCategory.exceptionalApproval == 1 ||
                expenseCategory.frequency == "Monthly") ? (
                <Box>
                  <Grid
                    container
                    className={classes.rowContainer}
                    style={{
                      marginTop: "15px",
                      paddingRight: matches && "27px",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={
                        matches ? classes.infoBoxMobile : classes.infoBox
                      }
                    >
                      <Grid
                        item
                        xs={matches ? 12 : 9}
                        sm={matches ? 11 : 9}
                        md={matches ? 11 : 10}
                      >
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            background: "#FFFFFF",
                            border: "1px solid #008CE6",
                            borderRadius: "4px",
                            padding: "8px",
                          }}
                        >
                          <InfoIcon />
                          <Typography
                            style={{
                              color: "#008CE6",
                              paddingLeft: "15px",
                              fontFamily: "Montserrat",
                              fontWeight: "600",
                              lineHeight: "16px",
                              letterSpacing: "0px",
                              fontSize: matches ? "9px" : "12px",
                              textAlign: "left",
                            }}
                          >
                            {/* {console.log(
                          "validateAttendeesList:---------------2:",
                          empAmounts
                        )} */}
                            NOTE: Policy limit for this expense is{" "}
                            {expenseCategory?.currency}
                            {noOfMon !== null && noOfMon != 0 && noOfMon != 1
                              ? `${
                                  noOfMon * expenseCategory?.policyLimit
                                } ( ${noOfMon} months )`
                              : expenseCategory?.policyLimit}
                            .
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              ) : expenseCategory?.policyApplicable == "Y" &&
                expenseCategory.categoryId !== 11 ? (
                <Box>
                  <Grid
                    container
                    className={classes.rowContainer}
                    style={{
                      marginTop: "15px",
                      paddingRight: matches && "27px",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={
                        matches ? classes.infoBoxMobile : classes.infoBox
                      }
                    >
                      <Grid
                        xs={matches ? 12 : 9}
                        sm={matches ? 11 : 9}
                        md={matches ? 11 : 10}
                      >
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            background: "#FFFFFF",
                            border: "1px solid #008CE6",
                            borderRadius: "4px",
                            padding: "8px",
                          }}
                        >
                          <InfoIcon />
                          <Typography
                            style={{
                              color: "#008CE6",
                              paddingLeft: "15px",
                              fontSize: matches ? "8px" : "12px",
                              textAlign: matches ? "left" : "",
                            }}
                          >
                            {/* {console.log(
                          "validateAttendeesList:---------------2:",
                          empAmounts
                        )} */}
                            <strong>
                              {" "}
                              NOTE: Your eligible amount for the Category is{" "}
                              {expenseCategory?.currency}
                              {empAmounts?.remainingAmount} as you have availed{" "}
                              {expenseCategory?.currency}
                              {empAmounts?.totalAmount} from the policy limit{" "}
                              {expenseCategory?.currency}
                              {expenseCategory?.policyLimit} .
                            </strong>
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                ""
              )
            ) : (
              ""
            )}

            <Box>
              <Grid
                container
                spacing={matches ? 2 : 4}
                className={
                  matches ? classes.rowContainerMobile : classes.rowContainer
                }
                style={{ marginTop: "10px", flexWrap: "nowrap" }}
              >
                <Grid item xs={matches ? 11 : 4} sm={4} md={4}>
                  <Grid item>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Currency*
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        className={
                          matches ? classes.inputLabels1 : classes.inputLabels
                        }
                        value={currency}
                        style={{ marginRight: matches ? "-6px" : "0px" }}
                        // disabled={true}
                        onChange={(e) => {
                          setCurrency(e.target.value);
                        }}
                        label="Currency*"
                      >
                        {/* <MenuItem value="">
                      <em>None</em>
                    </MenuItem> */}
                        {CurrencyList?.map((item) => {
                          return (
                            <MenuItem key={item.currencyId} value={item}>
                              {item.currencyCode}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText className={classes.errorText}>
                        {currency === "" ? currencyError : ""}
                      </FormHelperText>
                    </FormControl>{" "}
                  </Grid>
                </Grid>
                {matches ? gridCond1 : gridNormal1}
                {/* {console.log("SPLIT777777", gridNormal)} */}
                <Grid item xs={matches ? 11 : 4} sm={4} md={4}>
                  <Grid item>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Payment Method*
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        className={
                          matches ? classes.inputLabels1 : classes.inputLabels
                        }
                        value={payment}
                        onChange={(e) => {
                          setPayment(e.target.value);
                        }}
                        label="Payment Method**"
                        MenuProps={{
                          getContentAnchorEl: null,
                        }}
                      >
                        {paymentList?.map((item) => {
                          return (
                            <MenuItem key={item.categoryId} value={item}>
                              {item.methodName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText className={classes.errorText}>
                        {payment === "" ? paymentError : ""}
                      </FormHelperText>
                    </FormControl>{" "}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid
                container
                spacing={matches ? 2 : 4}
                className={classes.rowContainer}
                style={{
                  marginTop: "10px",
                }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Grid item xs={9} sm={9} md={11}>
                    <Typography
                      className={
                        matches
                          ? classes.projectDetailsTextMobile
                          : classes.projectDetailsText
                      }
                      style={{
                        fontFamily: matches ? "Montserrat" : "Montserrat",
                        fontSize: matches ? "14px" : "",
                        fontWeight: matches ? "600px" : "",
                      }}
                    >
                      Project Details
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid
                container
                className={classes.rowContainer}
                style={{
                  marginTop: "15px",
                  paddingRight: matches ? "12px" : "",
                }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Grid item>
                    <Box>
                      {/* {console.log("split1",formattedValue1)} */}
                      <SplitBox
                        empProjectList={employeeProjectsList}
                        handleProjectList={handleProjectList}
                        claimAmt={splitClaimAmount}
                        currency={currency?.currency}
                        projectListSplitBoxError={projectListSplitBoxError}
                        setProjectListSplitBoxError={
                          setProjectListSplitBoxError
                        }
                        expenseCategoryChanged={expenseCategoryChanged}
                        toggleExpenseCategoryChanged={setExpenseCategoryChanged}
                      />
                      <Box>
                        <Typography
                          variant="p"
                          style={{
                            fontSize: "13px",
                            color: "red",
                            // marginLeft: "14px",
                            marginRight: "14px",
                            marginTop: 4,
                            fontFamily: "Montserrat",
                          }}
                        >
                          {projectListSplitBoxError
                            ? projectListSplitBoxError
                            : ""}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            {storedExpenseCategory?.length > 0 &&
            storedExpenseCategory[0] != "" ? (
              <Box>
                <Grid
                  container
                  className={classes.rowContainer}
                  style={{
                    marginTop: "15px",
                    paddingRight: matches && "20px",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    className={
                      matches ? classes.infoBoxMobile : classes.infoBox
                    }
                  >
                    <Grid
                      item
                      xs={matches ? 12 : 8}
                      sm={matches ? 11 : 8}
                      md={matches ? 11 : 9}
                    >
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column", // Set flexDirection to "column"
                          alignItems: "flex-start",
                          //alignItems: "center",
                          background: "#FFFFFF",
                          border: "1px solid #ED8853",
                          borderRadius: "4px",
                          padding: "8px",
                          //color:"#ED8853"
                        }}
                      >
                        <InfoIcon
                          //className={classes.infoBox1}
                          style={{ fill: "#ED8853" }}
                        />
                        <Grid
                          style={{
                            color: "#ED8853",
                            marginLeft: "36px",
                            marginTop: "-26px",
                            fontFamily: "Montserrat",
                            fontWeight: "600",
                            lineHeight: "16px",
                            letterSpacing: "0px",
                            fontSize: matches ? "9px" : "12px",
                            textAlign: "left",
                          }}
                        >
                          {storedExpenseCategory[0]}
                        </Grid>
                        {storedExpenseCategory.slice(1).map((notes, index) => (
                          <Grid
                            item
                            key={index}
                            style={{
                              color: "#ED8853",
                              paddingLeft: "50px",
                              paddingTop: "5px",
                              fontFamily: "Montserrat",
                              fontWeight: "600",
                              lineHeight: "16px",
                              letterSpacing: "0px",
                              fontSize: matches ? "9px" : "12px",
                              textAlign: "left",
                            }}
                          >
                            . {notes}
                          </Grid>
                        ))}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              " "
            )}

            {expenseCategory?.multipleAttendes == 1 && uniqueArray !== null ? (
              <Box>
                <Grid
                  container
                  // className={classes.rowContainer}
                  // style={{
                  //   marginTop: "15px",
                  // }}
                >
                  <Grid
                    item
                    xs={matches ? 11 : 12}
                    style={{
                      justifyContent: "center",
                      //paddingRight:"20px",
                    }}
                  >
                    <Grid item>
                      <Box>
                        {expenseCategory?.multipleAttendes == 1 &&
                          uniqueArray?.length > 0 && (
                            <AttendeesBox
                              AttendeesList={uniqueArray}
                              selectedProjects={selectedProjects}
                              handleAttendees={handleAttendees}
                              attendeesError={attendeesError}
                            />
                          )}
                        <span>
                          <Typography
                            variant="p"
                            style={{ fontSize: "13px" }}
                            className={classes.errorText}
                          >
                            {attendees?.length === 0 &&
                            typeof attendeesError === "string"
                              ? attendeesError
                              : ""}
                          </Typography>
                        </span>
                        {attendeesError?.length > 0 &&
                        typeof attendeesError === "object" &&
                        attendeesError ? (
                          <span>
                            <Typography
                              variant="p"
                              style={{ fontSize: "13px" }}
                              className={classes.errorText}
                            >
                              Policy limit exceeded for:&nbsp;&nbsp;
                              {attendeesError.map((value, key) => {
                                return `${value.empName}(${
                                  value.remainingAmount
                                }) ${
                                  key + 1 < attendeesError?.length ? ", " : ""
                                } `;
                              })}
                            </Typography>
                          </span>
                        ) : (
                          ""
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              ""
            )}

            <Box style={{ marginTop: "15px" }}>
              <Grid
                container
                spacing={matches ? 2 : 4}
                className={classes.rowContainer}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Grid item>
                    <Box>
                      <DragDrope handleFileList={handleFileList} />
                      <Box>
                        <Typography
                          variant="p"
                          style={{
                            fontSize: "13px",
                            color: "red",
                            // marginLeft: "14px",
                            marginRight: "14px",
                            marginTop: 4,
                            fontFamily: "Montserrat",
                          }}
                        >
                          {dragDropFileError ? dragDropFileError : ""}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid
                container
                className={classes.rowContainer}
                style={
                  {
                    // marginTop: "30px",
                    // padding: matches && "0px 20px",
                  }
                }
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Grid item>
                    <Box>
                      <div
                        className={classes.checkBox}
                        style={{ margin: "50px 10px 30px 0px" }}
                      >
                        <Checkbox
                          // color="primary"
                          style={{
                            padding: "0px",
                          }}
                          className={
                            declarationCheckBox !== false
                              ? classes.checkBoxRoot1
                              : ""
                          }
                          inputProps={{ "aria-label": "secondary checkbox" }}
                          onChange={(e) => {
                            handleCheckBox(e);
                          }}
                        />
                        <Typography
                          // className={classes.checkBoxText}
                          style={{
                            fontSize: matches ? "10px" : "",
                            lineHeight: matches ? "12px" : "",
                            paddingLeft: matches ? "8px" : "16px",
                          }}
                        >
                          I hereby declare that all information given above is
                          true as per my knowledge.
                        </Typography>
                      </div>
                      <Box>
                        <Typography
                          variant="p"
                          style={{
                            fontSize: "13px",
                            color: "red",
                            // marginLeft: "14px",
                            marginRight: "14px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {declarationError ? declarationError : ""}
                        </Typography>
                      </Box>
                      <Box
                        className={
                          matches
                            ? classes.saveSubmitButtonMobile
                            : classes.saveSubmitButton
                        }
                      >
                        <Box style={{ paddingRight: "10px" }}>
                          <Button
                            variant="outlined"
                            color="#0B1941"
                            style={{
                              minWidth: "140px",
                              minHeight: "36px",
                              border: "1px solid #0B1941",
                              marginRight: matches ? "15px" : "",
                            }}
                            disabled={disableSubmit}
                            onClick={() => {
                              handleSave();
                            }}
                          >
                            Save
                          </Button>
                        </Box>
                        {expenseCategory?.exceptionalApproval == 1 &&
                        claimAmount != "" &&
                        claimAmount > expenseCategory?.policyLimit ? (
                          <>
                            <Button
                              variant="outlined"
                              style={{
                                minWidth: "140px",
                                minHeight: "36px",
                                background: "#CCCCCC",
                                backgroundColor: "#043365",
                                color: "rgba(255, 255, 255, 0.9)",
                              }}
                              onClick={handleOpenEDB}
                            >
                              Submit
                            </Button>
                            {/* Dialog update start */}
                            <Dialog
                              open={openExceptionalDialog}
                              onClose={handleCloseEDB}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle id="alert-dialog-title">
                                <Typography
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "24px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Exceptional Approval
                                </Typography>
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                  This expense requires exceptional approval. It
                                  will go to PM for approval.
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={handleCloseEDB}
                                  color="primary"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={() => {
                                    handleClick();
                                  }}
                                  color="primary"
                                  autoFocus
                                >
                                  OK
                                </Button>
                              </DialogActions>
                            </Dialog>
                            {/* Dialog update end */}
                          </>
                        ) : expenseCategory?.multipleAttendes == 1 &&
                          multipleAttendeesError != "" &&
                          multipleAttendeesError != null ? (
                          <>
                            <Button
                              variant="outlined"
                              style={{
                                minWidth: "140px",
                                minHeight: "36px",
                                background: "#CCCCCC",
                                backgroundColor: "#043365",
                                color: "rgba(255, 255, 255, 0.9)",
                              }}
                              onClick={handleOpenTOB}
                            >
                              Submit
                            </Button>
                            {/* Dialog update start */}
                            <Dialog
                              open={openTeamOutingExceedDialog}
                              onClose={handleCloseTOB}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle id="alert-dialog-title">
                                <Typography
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "24px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Team Outing Expense
                                </Typography>
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                  As per the entered expense, policy limit has
                                  been exceeded for the following:
                                  {/* {
                                attendeesError.map((value, key) => {
                            return `${value.empName}(${
                              value.remainingAmount
                            }) ${key + 1 < attendeesError?.length ? ", " : ""} `;
                          })
                              } */}
                                  {multipleAttendeesError
                                    ? multipleAttendeesError.map((att, key) => {
                                        return `${att.empName}(${
                                          att.remainingAmount
                                        }) ${
                                          key + 1 <
                                          multipleAttendeesError?.length
                                            ? ", "
                                            : ""
                                        }
                                  `;
                                      })
                                    : ""}
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={handleCloseTOB}
                                  color="primary"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={() => {
                                    handleClick();
                                    handleCloseTOB();
                                  }}
                                  color="primary"
                                  autoFocus
                                >
                                  OK
                                </Button>
                              </DialogActions>
                            </Dialog>
                            {/* Dialog update end */}
                          </>
                        ) : (
                          <Button
                            variant="outlined"
                            style={{
                              minWidth: "140px",
                              minHeight: "36px",
                              background: "#CCCCCC",
                              backgroundColor: "#043365",
                              color: "rgba(255, 255, 255, 0.9)",
                            }}
                            disabled={disableSubmit}
                            onClick={() => {
                              handleClick();
                            }}
                          >
                            Submit
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>{" "}
        </>
      )}

      <Box>
        <Dialog
          open={openRating}
          // TransitionComponent={Transition}
          keepMounted
          onClose={handleRatingClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Box style={{ width: "400px", height: "650px" }}>
            <DialogTitle>
              <Typography
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  marginLeft: "10px",
                  textAlignLast: "center",
                }}
              >
                Rate and Review <br></br> {ratingHeading}
              </Typography>
              <Divider
                style={{
                  backgroundColor: "#E1E1E1",
                  width: "100%",
                  height: "0.5px",
                  marginLeft: "2.5%",
                  marginTop: "10px",
                }}
                orientation="horizontal"
              />
            </DialogTitle>
            <DialogContent style={{ paddingTop: "12px" }}>
              <DialogContentText id="alert-dialog-slide-description">
                <Box>
                  <Box style={{ width: "300px", marginLeft: "20px" }}>
                    <Typography style={{ textAlign: "center", color: "#000" }}>
                      <span style={{ color: "#2CB0FE", fontWeight: "400" }}>
                        {ssoEmpIdDetails ? ssoEmpIdDetails?.empName : ""}
                      </span>
                      , we'd like to know your reviews on {ratingHeading}.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: 300,
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "40px",
                      textAlignLast: "center",
                    }}
                  >
                    <Box sx={{ width: 100 }}></Box>
                    <Box sx={{ width: 200 }}>
                      <Rating
                        name="hover-feedback"
                        value={value}
                        getLabelText={getLabelText}
                        size="large"
                        onChange={(event, newValue) => {
                          newValue === null ? setValue(-1) : setValue(newValue);
                        }}
                        onChangeActive={(event, newHover) => {
                          setHover(newHover);
                        }}
                        emptyIcon={
                          <StarOutlineIcon
                            style={{ opacity: 0.8, color: "#2CB0FE" }}
                            fontSize="inherit"
                          />
                        }
                        style={{ textAlignLast: "center" }}
                      />
                    </Box>
                    <Box sx={{ width: 50 }}></Box>
                  </Box>

                  {value !== null && ratingLabels && (
                    <Box
                      style={{
                        textAlignLast: "center",
                        paddingTop: "5px",
                        height: "20px",
                      }}
                    >
                      <Typography style={{ fontSize: "14px", color: "#000" }}>
                        {
                          ratingLabels[hover !== -1 ? hover - 1 : value - 1]
                            ?.ratingDescription
                        }
                      </Typography>
                    </Box>
                  )}
                </Box>

                <Box>
                  <Box style={{ textAlignLast: "center", paddingTop: "25px" }}>
                    <Typography style={{ fontWeight: "550", color: "#2CB0FE" }}>
                      Write a Review
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      paddingTop: "10px",
                      border: "0.5px solid #000",
                      borderRadius: "5px",
                    }}
                  >
                    <TextField
                      id="review"
                      value={review}
                      placeholder="What did you like or dislike? Write here your feedback about this application."
                      multiline
                      rows={3}
                      onChange={(e) => setReview(e.target.value.trim())}
                      style={{
                        width: "100%",
                      }}
                      InputProps={{
                        inputProps: {
                          maxLength: 200,
                        },
                      }}
                    ></TextField>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingTop: "6px",
                    }}
                  >
                    <Box style={{ width: "50px" }}></Box>
                    <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          width: "120px",
                          textTransform: "none",
                          backgroundColor: isSubmitDisabled
                            ? "#9E9E9E"
                            : "#043365",
                        }}
                        onClick={handleRatingSubmit}
                        disabled={isSubmitDisabled ? true : false}
                      >
                        Submit
                      </Button>
                    </Box>
                    <Box>
                      <Link underline="none" target="_blank">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            height: "30px",
                          }}
                        >
                          <Typography
                            onClick={handleRatingSkipClose}
                            style={{
                              cursor: "pointer",
                              fontWeight: "400",
                              fontSize: "14px",
                            }}
                          >
                            Skip {`>`}
                          </Typography>
                        </Box>
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </DialogContentText>
            </DialogContent>
          </Box>
        </Dialog>
      </Box>

      <Box>
        <Dialog
          open={thankYouRatingOpen}
          // TransitionComponent={Transition}
          keepMounted
          // onClose={handleThankyou}
          aria-describedby="alert-dialog-slide-description"
        >
          <Box style={{ width: "400px", height: "350px" }}>
            <DialogTitle>
              <CheckCircleIcon className={classes.successIcon} />
              <Typography
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  marginLeft: "10px",
                  textAlignLast: "center",
                }}
              >
                Thanks for <br></br>Rating and Review.
              </Typography>
            </DialogTitle>
            <DialogContent style={{ paddingTop: "12px" }}>
              <DialogContentText id="alert-dialog-slide-description">
                <Box>
                  <Box
                    sx={{
                      width: 300,
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "40px",
                      textAlignLast: "center",
                    }}
                  >
                    <Box sx={{ width: 100 }}></Box>
                    <Box sx={{ width: 200 }}>
                      <Rating
                        name="hover-feedback"
                        value={value}
                        getLabelText={getLabelText}
                        size="large"
                        emptyIcon={
                          <StarOutlineIcon
                            style={{ opacity: 0.8, color: "#2CB0FE" }}
                            fontSize="inherit"
                          />
                        }
                        style={{ textAlignLast: "center" }}
                      />
                    </Box>
                    <Box sx={{ width: 50 }}></Box>
                  </Box>
                </Box>
              </DialogContentText>
            </DialogContent>
          </Box>
        </Dialog>
      </Box>

      <Snackbar
        open={statusSnackbarOpen}
        onClose={() => handleCloseSnackbarVali(false)}
      >
        <Alert
          onClose={() => handleCloseSnackbarVali(false)}
          severity="error"
          sx={{ width: "100%", backgroundColor: "red", color: "white" }}
        >
          {checkErrorInside === true || checkCategoryError == -1
            ? "There might be some unfilled entries."
            : `There might be some unfilled entries in ${selectedCategory[checkCategoryError]?.categoryName} category.`}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ViewReward;