// import React, { useState, useEffect } from "react";
// import { Typography, Box } from "@material-ui/core";
// import SideBarDrawer from "../../components/navBar";
// import { useLocation } from "react-router";
// import { useDispatch, useSelector } from "react-redux";
// import { getEmpDetail, updateSsoEmpId } from "../../redux/Expense/action";
// import { useMsal, useAccount } from "@azure/msal-react";

// const Dashboard = () => {
//   const [ssoEmpId, setSsoEmpId] = useState(null);
//   const [delEmpId, setDelEmpId] = useState(null);
//   const dispatch = useDispatch();
//   const { instance, accounts, inProgress } = useMsal();
//   const account = useAccount(accounts[0] || {});
//   const location = useLocation();
//   //console.log("DASH1 : location in dashboard.js-->", location);
//   //useSelectors Start:
//   const ssoEmpIdDetails = useSelector(
//     (state) => state.createExpenseData.getEmpDetailsData
//   );
//   const updatedSSOEmpId = useSelector(
//     (state) => state.createExpenseData.ssoEmpId
//   );
//   //useSelectors End.

//   //useEffects start:
//   useEffect(() => {
//     dispatch(getEmpDetail(account?.username));
//   }, []);

//   useEffect(() => {
//     if (ssoEmpIdDetails?.empId != 0 && ssoEmpIdDetails != null) {
//       setSsoEmpId(ssoEmpIdDetails?.empId);
//       dispatch(updateSsoEmpId(ssoEmpIdDetails?.empId));
//     }
//   }, [ssoEmpIdDetails]);

//   //useEffects end.
//   //console.log("DASH1 : ssoEmpIdDetails in dashboard.js-->", ssoEmpIdDetails);
//   //console.log("DASH2 : updatedSSOEmpId in dashboard.js-->", updatedSSOEmpId);
//   //console.log("DASH3 : ssoEmpId in dashboard.js-->", ssoEmpId);
//   return (
//     <div style={{ position: "relative", minHeight: "100vh" }}>
//       {ssoEmpId !== null && (
//         <SideBarDrawer ssoEmpId={ssoEmpId} delEmpId={delEmpId} />
//       )}
//     </div>
//   );
// };

// export default Dashboard;
import React, { useState, useEffect } from "react";
import { Typography, Box } from "@material-ui/core";
import SideBarDrawer from "../../components/navBar";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmpDetail,
  resetAuditHistoryData,
  updateSsoEmpId,
} from "../../redux/Expense/action";
import { useMsal, useAccount } from "@azure/msal-react";
import Header from "../../components/header";

const Dashboard = (props) => {
  const [ssoEmpId, setSsoEmpId] = useState(null);
  const [delEmpId, setDelEmpId] = useState(null);
  const dispatch = useDispatch();
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const location = useLocation();
  //console.log("DASH1 : module", props.module);
  //useSelectors Start:
  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );
  const updatedSSOEmpId = useSelector(
    (state) => state.createExpenseData.ssoEmpId
  );
  //useSelectors End.

  //useEffects start:
  useEffect(() => {
    dispatch(getEmpDetail(account?.username));
    dispatch(resetAuditHistoryData());
  }, []);

  useEffect(() => {
    if (ssoEmpIdDetails?.empId != 0 && ssoEmpIdDetails != null) {
      setSsoEmpId(ssoEmpIdDetails?.empId);
      dispatch(updateSsoEmpId(ssoEmpIdDetails?.empId));
    }
  }, [ssoEmpIdDetails]);

  //useEffects end.
  //console.log("DASH1 : ssoEmpIdDetails in dashboard.js-->", ssoEmpIdDetails);
  //console.log("DASH2 : updatedSSOEmpId in dashboard.js-->", updatedSSOEmpId);
  //console.log("DASH3 : ssoEmpId in dashboard.js-->", ssoEmpId);
  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      {ssoEmpIdDetails?.empId == 0 && ssoEmpIdDetails != null ? (
        <>
          <Header
            heading={""}
            ssoEmpId={ssoEmpIdDetails?.empId}
            disableAccOptions={1}
          />

          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "132px",
            }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontSize: "32px",
                fontWeight: "600",
                lineHeight: "40px",
                letterSpacing: "0px",
                textAlign: "center",
              }}
            >
              {"Sorry!! You are not authorized for the application"}
            </Typography>
          </Box>
        </>
      ) : (
        ssoEmpId != null && (
          <SideBarDrawer
            ssoEmpId={ssoEmpId}
            delEmpId={delEmpId}
            module={props.module}
          />
        )
      )}
    </div>
  );
};

export default Dashboard;
