import { applyMiddleware, compose, createStore } from "redux";
import reducer from "./rootReducer";
import thunk from "redux-thunk";
import API from "../Utils/endpoints";
import axios from "axios";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, loginApiRequest } from "../Utils/authConfig";
import history from "../history";
import Encrypt from "../Utils/encryption";
import Decrypt from "../Utils/decryption";

const msalInstance = new PublicClientApplication(msalConfig);
const axiosInstance = axios.create({
  baseURL: API.baseUrl,
});

axiosInstance.interceptors.request.use(
  async (response) => {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    const accessToken = Decrypt(key, localStorage.getItem("token"));

    var email = "";

    //checking if accessToken exists

    if (accessToken) {
      //console.log("token", accessToken);
      response.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      const account = msalInstance.getAllAccounts()[0];
      const msalResponse = await msalInstance.acquireTokenSilent({
        ...loginApiRequest,
        account: account,
      });
      //console.log("msalResponse", msalResponse);
      // localStorage.setItem("token", msalResponse.accessToken);
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      localStorage.setItem(
        "token",
        Encrypt(key, JSON.stringify(msalResponse.accessToken))
      );
      response.headers.Authorization = `Bearer ${msalResponse.accessToken}`;
      email = account.username;
      //console.log("email,email", email);
    }
    //console.log("response", response);
    return response;
  },

  (err) => {
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.response.status === 401) {
      //console.log("in the response interceptor");
      localStorage.removeItem("token");
      sessionStorage.clear();
      history.push("/login");
    }
    return Promise.reject(error);
  }
);

const enhancer = compose(
  applyMiddleware(thunk.withExtraArgument(axiosInstance))
);

const store = createStore(reducer, enhancer);

export default store;
