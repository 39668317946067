import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  makeStyles,
  withStyles,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  TextField,
  TableHead,
  Link,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  InputLabel,
  Snackbar,
} from "@material-ui/core";
import SnackBar from "./snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import LinesEllipsis from "react-lines-ellipsis";
import { useTheme } from "@material-ui/core/styles";
import { NumericFormat } from "react-number-format";
import "../css/viewExpense.css";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import PhotoSizeSelectActualIcon from "@material-ui/icons/PhotoSizeSelectActual";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { useDispatch, useSelector } from "react-redux";
import Header from "./header";
import Stepper from "./stepper";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import { useLocation, useHistory } from "react-router-dom";
import {
  getAuditHistory,
  getCurrencyData,
  getEmpDetail,
  getMyViewExpenseData,
  postApproveRejectExpenseData,
  postFinancialApproveRejectExpenseData,
} from "../redux/Expense/action";
import axios from "axios";
import fileDownload from "js-file-download";
import ApprovedIcon from "../images/Approved_Icon.svg";
import InProgressIcon from "../images/Inprogress_icon.svg";
import RejectedIcon from "../images/Rejected_icon.svg";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import ApproveRejectDialog from "./approveRejectDialog";
import { useAccount, useMsal } from "@azure/msal-react";
import Encrypt from "../Utils/encryption";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MobileViewExpense from "./mobileViewExpense";
import AuditHistoryDialog from "./auditHistoryDialog";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Divider from "@mui/material/Divider";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F8F9FF",
    paddingLeft: "40px",
    paddingRight: "40px",
  },

  purpose: {
    display: "flex",
    justifyContent: "center",
  },
  expenseHeading: {
    display: "inline-flex",
    margin: "30px 0px 30px 0px",
    height: "24px",
    maxWidth: "2000px",
    overflowWrap: "anywhere",
  },
  flexBox: {
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: "30px",
    paddingTop: "20px",
    width: "392px",
    height: "1100px",
  },
  mainBox: {
    background: "#FFFFFF",
    border: "1px solid #979797",
    borderRadius: "8px",
    marginTop: "94px",
    // justifyContent: "center",
  },
  attachmentBox: {
    // background: "#FFFFFF",
    // border: "1px solid #979797",
    // borderRadius: "8px",
    marginTop: "20px",
    marginBottom: "20px",
  },
  expenseId: {
    fontFamily: "Montserrat",
    fontWeight: 800,
    fontSize: "14px",
    color: "#000000",
    paddingTop: "10px",
  },
  auditHistory: {
    fontFamily: "Montserrat",
    fontWeight: 800,
    fontSize: "14px",
    color: "#008CE6",
    paddingTop: "20px",
    textDecoration: "underline",
    cursor: "pointer",
  },
  expenseIdBillable: {
    fontFamily: "Montserrat",
    fontWeight: 800,
    fontSize: "14px",
    color: "#000000",
  },
  projectId: {
    fontFamily: "Montserrat",
    fontWeight: 800,
    fontSize: "14px",
    color: "#000000",
    paddingTop: "14px",
  },
  textConditional: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "16px",
    color: "#008CE6",
  },
  textConditionalSplitAmount: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "16px",
    color: "#008CE6",
    paddingTop: "5px",
  },
  expenseDetail: {
    fontFamily: "Montserrat",
    fontWeight: 800,
    fontSize: "24px",
    color: "#000000",
    paddingTop: "32px",
    paddingBottom: "12px",
    paddingLeft: "2px",
  },
  expenseIdHeading: {
    fontFamily: "Montserrat",
    fontWeight: 800,
    fontSize: "14px",
    color: " #77787F",
    paddingTop: "14px",
  },

  splitAmountHeading: {
    fontFamily: "Montserrat",
    fontWeight: 800,
    fontSize: "16px",
    color: "#000000",
    paddingTop: "12px",
  },
  attachmentHeading: {
    fontFamily: "Montserrat",
    fontWeight: 800,
    fontSize: "24px",
    color: "#000000",
    paddingTop: "12px",
  },
  projectInfo: {
    display: "flex",
    paddingTop: "31px",
  },
  expenseGrid: {
    borderRight: "1px solid #CCCCCC",
    // transform:" rotate(90deg)",
  },
  attachmentGrid: {
    // borderRight: "1px solid #CCCCCC",
    // marginLeft: "20px",
    // transform:" rotate(90deg)",
  },
  nOfAttachment: {
    fontFamily: "Montserrat",
    fontWeight: 800,
    fontSize: "10px",
    color: "#000000",
    paddingTop: "26px",
    paddingLeft: "8px",
  },
  tableCell: {
    "& .MuiTableCell-root": {
      borderBottom: "none",
      paddingTop: "2px",
      paddingLeft: "20px",
    },
  },
  attendeesPaper: {
    minHeight: "300px",
  },
  papaerRoot: {
    background: "#FFFFFF",
    border: "1px solid #979797",
    borderRadius: "8px",
  },
  paperElivation: {
    boxShadow: "none",
  },
  statusHeading: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "8px",
    color: "rgba(255, 255, 255, 0.9)",
  },
  statusName: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "10px",
    marginRight: "2px",

    color: "rgba(255, 255, 255, 0.85)",
  },
  statusProjectName: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "8px",
    marginTop: "2px",
    color: "rgba(255, 255, 255, 0.9)",
    opacity: "0.6",
  },
  statusMessage: {
    fontFamily: "Montserrat",
    fontStyle: "italic",
    fontWeight: 400,
    fontSize: "8px",
    color: "#FFFFFF",
  },
  tooltip: {
    paddingLeft: "10px",
    color: "#9E9E9E",
    height: "20px",
    marginTop: "10px",
  },
  fileName: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "16px",
    color: "#4C4C4C",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "no-wrap",
    width: "450px",
  },
  fileName1: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "16px",
    color: "#4C4C4C",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "no-wrap",
    width: "337px",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "end ",
    marginRight: "60px",
  },
  removeFilter: {
    marginLeft: "24px",
  },
  applyFilter: {
    marginLeft: "24px",
  },
  applyFilterText: {
    background: "#0B1941",
    color: "#FFFFFF",
    borderRadius: "4px",
    "&:hover": {
      background: "#0B1941",
      boxShadow:
        "0px -1px 5px rgba(89, 122, 105, 0.09), 0px 2px 2px rgba(44, 73, 59, 0.1), 0px 2px 4px rgba(138, 159, 149, 0.2), 0px 3px 5px rgba(89, 151, 118, 0.1)",
    },
  },
  approvedConditional: {
    background: "#BDBDBD",
    color: "#FFFFFF",
    borderRadius: "4px",
    "&:hover": {
      background: "#BDBDBD",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
  },
  removeFilterText: {
    color: "#0B1941",
  },
  labelChip: {
    textOverflow: "inherit",
  },
  headerName: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "700",
    color: "#77787F",
    alignItems: "center",
  },
  header: {
    background: "#F4F4F4",
    // borderRadius: "8px 8px 0px 0px",
    maxWidth: "100%", //1126
    maxHeight: "58px",
  },
  advancesField: {
    // width: "300px",
    "& .MuiOutlinedInput-input": {
      padding: "14.5px 16px",
    },
    "& .MuiInputLabel-formControl": {
      top: "-3px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -3px) scale(0.75)",
    },
    "& .Mui-disabled": {
      color: "#000000de",
    },
    "& .MuiSelect-icon.Mui-disabled": {
      color: "#0000008a",
    },
  },
  summaryField: {
    display: "flex",
    alignItems: "center",
    padding: "8px 24px",
    justifyContent: "space-between",
    paddingRight: "100px",
  },
  advancesDate: {
    margin: "0px",
    "& .MuiOutlinedInput-input": {
      padding: "14.5px 16px",
    },
    "& .Mui-disabled": {
      color: "#000000de",
    },
    "& .MuiIconButton-root.Mui-disabled": {
      color: "#0000008a",
    },
  },
  financeField: {
    "& .MuiOutlinedInput-input": {
      padding: "12.5px 16px",
    },
  },
  // divider:{
  //   "& .css-1puturl-MuiDivider-root::before, .css-1puturl-MuiDivider-root::after": {
  //     borderTop: '2px dashed rgba(0, 0, 0, 0.12) !important'
  // }
  // }
}));

const ViewDetails = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [purpose, setPurpose] = useState("");
  const [openBulkApproveDialog, setOpenBulkApproveDialog] = useState(false);
  const [approveOrReject, setApproveOrReject] = useState(1);
  const [singleExpense, setSingleExpense] = useState("");
  const [empId, setEmpId] = React.useState(null);
  const [comments, setComments] = React.useState("");
  const [veiwDialog, setViewDialog] = React.useState(false);
  const [viewFinApproval, setViewFinApproval] = React.useState(false);
  const [expStatus, setExpStatus] = React.useState("Approved");
  const [approvedAmount, setApprovedAmount] = React.useState(0);
  const [buttonState, setButtonState] = React.useState(true);
  const [noOfMonths, setNoOfMonths] = useState(1);
  const [openAuditHistDialog, setOpenAuditHistDialog] = React.useState(false);
  const [auditHistoryData, setAuditHistoryData] = useState([]);

  const [travelTotalApproved, setTravelTotalApproved] = useState(0);
  const [travelFieldValue, setTravelFieldValue] = useState([]);

  const [advancesList, setAdvancesList] = useState([
    {
      ExpId: null,
      DepositDate: null,
      Currency: "",
      TotalAdvance: 0,
      AdvanceType: "",
    },
  ]);
  const [currencyError, setCurrencyError] = useState("");
  const [currency, setCurrency] = useState("");
  const [travelCardAmount, setTravelCardAmount] = useState(0);
  const [cash, setCash] = useState(0);
  const [advSummaryJson, setAdvSummaryJson] = useState({});
  const AdvancesType = [
    {
      label: "Travel Card",
      value: "Travel Card",
    },
    {
      label: "Cash",
      value: "Cash",
    },
  ];

  const [actValue, setActValue] = useState([]);
  const [advValue, setAdvValue] = useState([]);
  const [totalAdv, setTotalAdv] = useState(0);
  const [totalAct, setTotalAct] = useState(0);
  const [systemErrorSnackbar, setSystemErrorSnackbar] = useState(false);
  // {
  //   methodName:'Card Travel',
  //   value:0,
  // },{
  //   methodName:'Cash',
  //   value:0
  // },{
  //   methodName:'Self',
  //   value:0
  // }
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width:600px)");
  const myExpenseList = useSelector(
    (state) => state.createExpenseData.getViewExpenseList
  );

  const delEmpIDRedux = useSelector(
    (state) => state.createExpenseData.delEmpId
  );
  const ssoEmpIdRedux = useSelector(
    (state) => state.createExpenseData.ssoEmpId
  );
  const createExpenseState = useSelector((state) => state.createExpenseData);
  //console.log("createExpenseData", createExpenseState, location.state);
  const refreshModuleToggle = useSelector(
    (state) => state.createExpenseData.reloadModuleToggle
  );
  const auditHistoryList = useSelector(
    (state) => state.createExpenseData.getAuditHistData
  );
  // console.log("auditHistory", auditHistoryList);
  //console.log("refreshModuleToggleVExp", refreshModuleToggle);

  const CurrencyList = useSelector(
    (state) => state.createExpenseData.getCurrencyList
  );

  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );
  const delEmpIDRed = useSelector((state) => state.createExpenseData.delEmpId);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //UseEffects start:

  useEffect(() => {
    dispatch(getEmpDetail(account?.username));
    if (window.performance && refreshModuleToggle == null) {
      //console.log("window.performance works fine on this browser RELOAD1");
      window.screen.availWidth > 600 && history.push("/expense-Management");
    }
    dispatch(
      getCurrencyData(
        delEmpIDRed != null &&
          delEmpIDRed != "" &&
          delEmpIDRed != ssoEmpIdDetails?.empId
          ? delEmpIDRed
          : ssoEmpIdDetails?.empId
      )
    );
  }, []);
  useEffect(() => {
    // console.log("diff2 1", myExpenseList ? myExpenseList : "null");
    if (myExpenseList != null) {
      //console.log("diff2 2", myExpenseList);
      const diffInDates =
        (moment(myExpenseList?.ExpenseDetail[0]?.BillingCycleToDate).toDate() -
          moment(
            myExpenseList?.ExpenseDetail[0]?.BillingCycleFromDate
          ).toDate()) /
        (1000 * 60 * 60 * 24);
      const noOfMonths = Math.round(diffInDates / 30);
      // console.log("diff2 3", diffInDates, noOfMonths);
      setNoOfMonths(noOfMonths);
      //dispatch(getAuditHistory(myExpenseList?.ExpenseDetail?.ExpId));
    }

    if (
      myExpenseList?.ExpFinalStatus == "Approved" ||
      myExpenseList?.ExpFinalStatus == "Rejected"
    ) {
      const travelTotal = (myExpenseList?.ExpenseDetail?.reduce((prev, list) => {
        return prev + list.ApprovedAmount;
      }, 0))?.toFixed(2);

      setTravelTotalApproved(travelTotal);
    } else {
      const travelTotal = (myExpenseList?.ExpenseDetail?.reduce((prev, list) => {
        return prev + list.ClaimedAmount;
      }, 0))?.toFixed(2);
      //console.log("travel total", travelTotal);
      setTravelTotalApproved(travelTotal);
    }
    setTravelFieldValue([]);
    myExpenseList?.ExpenseDetail?.map((val, index) => {
      // console.log("travelFieldValue", val.ExpId);
      setTravelFieldValue((prevVal) => [
        ...prevVal,
        {
          ExpId: val.ExpId,
          ApprovedAmount: val.ClaimedAmount,
          ProjAmount: 0,
          ClaimedAmount: val.ClaimedAmount,
        },
      ]);
    });

    if (myExpenseList?.AdvSummaryDetails !== null) {
      setAdvSummaryJson({
        ...myExpenseList?.AdvSummaryDetails[0],
        ExpId: myExpenseList?.ExpenseDetail[0]?.ExpId,
      });
    } else {
      setAdvSummaryJson({
        ExpId: myExpenseList?.ExpenseDetail[0]?.ExpId,
        RefundReceived: "0",
        NetPayable: "0",
        TravelCardBal: "0",
        CashBal: "0",
      });
    }
    setTotalAct(
      myExpenseList?.ActualDetails?.reduce(
        (acc, item) => acc + item.ActualsTypeAmt,
        0
      )
    );
    if (location?.state?.compName == "financeApprovalListing") {
      setActValue(
        myExpenseList?.ActualDetails?.map((item) => ({
          ...item,
          ActualsTypeAppAmt: item.ActualsTypeAmt,
        }))
      );
    } else {
      setActValue(myExpenseList?.ActualDetails);
    }
    if (
      myExpenseList?.AdvancesDetails != null &&
      myExpenseList?.AdvancesList != null
    ) {
      setAdvValue(myExpenseList?.AdvancesDetails);
      const tempAdvList=myExpenseList?.AdvancesList?.filter(
        (item) => item.TotalAdvance > 0
      );
      setAdvancesList(tempAdvList);
      setTotalAdv(
        myExpenseList?.AdvancesDetails?.reduce(
          (acc, item) => acc + item.AdvancesTypeAmt,
          0
        )
      );
    } else {
      setAdvValue([]);
      setAdvancesList([]);
      setTotalAdv(0);
    }
    // console.log("CurrencyList",CurrencyList)
    // if (CurrencyList != null) {
    //   setCurrency(CurrencyList?.filter((value) => value.CurrencyId == myExpenseList?.ExpenseDetail[0].CurrencyId)[0]);
    // }
  }, [myExpenseList]);

  // console.log("my expense List", myExpenseList);

  useEffect(() => {
    if (CurrencyList != null) {
      setCurrency(
        CurrencyList?.filter(
          (value) =>
            value.currencyId == myExpenseList?.ExpenseDetail[0]?.CurrencyId
        )[0]
      );
    }
  }, [CurrencyList, myExpenseList]);

  useEffect(() => {
    //console.log("postApproveExpenseData 1 ssoEmpIdRedux");
    if (delEmpIDRedux != null && delEmpIDRedux != ssoEmpIdRedux) {
      setEmpId(delEmpIDRedux);
    } else if (ssoEmpIdRedux != null) {
      setEmpId(ssoEmpIdRedux);
    }
  }, [ssoEmpIdRedux, delEmpIDRedux]);
  useEffect(() => {
    // console.log("location only", location);
    // console.log("location state", location?.state);

    //console.log("location.state.policyLimit", location.state.policyLimit);

    dispatch(getMyViewExpenseData(location?.state));
    dispatch(getAuditHistory(location?.state?.response?.expId));

    // console.log(
    //   "location?.state?.response?.claimAmount",
    //   location?.state?.response
    // );
    setApprovedAmount(location?.state?.response?.claimAmount);
    if (
      location?.state?.compName == "teamApprovalListing" &&
      (location?.state?.response?.expStatus == "Approved" ||
        location?.state?.response?.expStatus == "Rejected")
    ) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  }, [location]);

  useEffect(() => {
    if (auditHistoryList != null) {
      setAuditHistoryData(auditHistoryList);
    }
  }, [auditHistoryList]);

  const [cell1tarwidth, setCell1tarwidth] = useState(null);
  const [cell2tarwidth, setCell2tarwidth] = useState(null);
  // const [cell3tarwidth, setCell3tarwidth] = useState(null);

  useEffect(() => {
    const syncWidths = () => {
      // console.log("cellwidth1", cell1tar?.current?.style.maxWidth);
      const cell1ref = document.getElementById("cell1ref");
      const cell2ref = document.getElementById("cell2ref");
      // const cell3ref = document.getElementById("cell3ref");

      if (cell1ref) {
        const widthToSet = cell1ref.clientWidth;
        if (cell1tarwidth != widthToSet) {
          setCell1tarwidth(widthToSet);
        }
        // console.log("cellwidth", cell1tarwidth, widthToSet);
      }
      if (cell2ref) {
        const widthToSet = cell2ref.clientWidth;
        if (cell2tarwidth != widthToSet) {
          setCell2tarwidth(widthToSet);
        }
        // console.log("cellwidth2", cell2tarwidth, widthToSet);
      }
      // if (cell3ref) {
      //   const widthToSet = cell3ref.clientWidth;
      //   if (cell3tarwidth != widthToSet) {
      //     setCell3tarwidth(widthToSet);
      //   }
      // }
    };

    syncWidths();
  }, [myExpenseList]);

  //UseEffect end.
  const handleName = (value) => {
    if (value) {
      const initArray = value.split(" ");
      //console.log("initial array", initArray);
      let initial;
      if (initArray.length == 1) {
        initial = initArray[0][0];
      } else {
        //initial = initArray[0][0] + initArray[initArray.length - 1].slice(0, 1);
        initial = initArray[0][0];
      }
      return initial.toUpperCase();
    }
  };
  //new download update
  const AWS = require("aws-sdk");

  const s3 = new AWS.S3({
    region: "ap-south-1",
  });
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  });

  /**
   * @param {string} key - Object path
   * @param {('getObject'|'putObject'|'deleteObject')} operation - HTTP Method
   * @returns {string} - A Temporar URL Endpoint
   */
  const handleDownload = (value, fileName) => {
    // console.log("download", value, fileName);

    const s3 = new AWS.S3();
    const tempKey = `${
      value.ExpenseDetail[0].ExpId
    }_${fileName?.toLowerCase()}`;
    // console.log("tempKey", tempKey);
    //console.log("s3Bucket 0001",process.env.REACT_APP_S3_BUCKET,process.env.REACT_APP_ACCESS_KEY,process.env.REACT_APP_SECRET_ACCESS_KEY);
    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET,
      Key: tempKey,
    };
    // console.log("tempKey 2", params);
    s3.getObject(params, (err, data) => {
      if (err) {
        //console.log(err, err.stack);
      } else {
        //console.log("inHandleDownload0004", data);
        fileDownload(data.Body, fileName);
      }
    });
  };

  const setAmountNewValue = (value) => {
    setApprovedAmount(value);
  };

  const handleOpenAuditHistDialog = () => {
    //console.log("handleOpenAuditHistDialog");
    setOpenAuditHistDialog(true);
  };
  const handleCloseAuditHistDialog = () => {
    setOpenAuditHistDialog(false);
  };
  const handleToolTip = (data) => {
    //console.log("individaula tooltip", data);
    const string = data?.Comment?.split("|");
    //console.log("strijfn", string);
    return (
      <>
        <Box>
          <Typography
            className={classes.statusHeading}
            style={{ paddingTop: "5px" }}
          >
            {string[0]}
          </Typography>
        </Box>
        <>
          <Box
            style={{ display: "flex", paddingTop: "5px", paddingBottom: "5px" }}
          >
            <Typography className={classes.statusName}>
              {data.projectmanager}
            </Typography>
            {string[0] == "Rejected By " && (
              <Typography className={classes.statusName}> </Typography>
            )}
            <Typography
              className={classes.statusProjectName}
              style={{ paddingLeft: "5px" }}
            >
              {" "}
              {"|"}
              <span style={{ paddingLeft: "5px" }}> {data.ProjectId} </span>
            </Typography>{" "}
          </Box>
          <Typography className={classes.statusMessage}>
            {data.reason}
          </Typography>
        </>
      </>
    );
  };
  const handleComments = (data) => {
    //console.log("handleComments: comments:", data);
    setComments(data);
  };

  const handleOpenBulkApproveDialog = (row) => {
    //console.log("handleOpenBulkApproveDialog", row);
    setOpenBulkApproveDialog(true);
    setSingleExpense(row);
    setApproveOrReject(1);
    setExpStatus("Approved");
    setViewDialog(true);
    //console.log("approvedAmount", approvedAmount);
    // console.log(
    //   "location?.state?.response?.claimAmount",
    //   location?.state?.response?.claimAmount
    // );

    if (
      location?.state?.compName == "financeApprovalListing" &&
      (Number(approvedAmount) !=
        Number(location?.state?.response?.claimAmount) ||
        travelTotalApproved != myExpenseList?.totalamount?.ClaimedAmount)
    ) {
      // console.log("approvedAmount", travelTotalApproved);
      setViewFinApproval(true);
    }
  };
  const handleOpenRejectionDialog = (row) => {
    setOpenBulkApproveDialog(true);
    setSingleExpense(row);
    setApproveOrReject(0);
    setExpStatus("Rejected");
    setViewDialog(true);
    if (location?.state?.compName == "financeApprovalListing") {
      setViewFinApproval(true);
    }
  };
  const handleCloseBulkApproveDialog = () => {
    //console.log("openBulkDialog--- in fxn", openBulkApproveDialog);
    setOpenBulkApproveDialog(false);
    setViewDialog(false);
    setViewFinApproval(false);
    setComments("");
  };

  const isAppRejPostSuccess = useSelector(
    (state) => state.createExpenseData.isApprovedPost
  );

  const handleSingleApproveRejectExpense = (value) => {
    let array = [];
    var key = process.env.REACT_APP_ENCRYPTION_KEY;

    // console.log("postApproveExpenseData:  ", viewFinApproval);
    if (
      viewFinApproval == true &&
      location?.state?.compName == "financeApprovalListing"
    ) {
      location?.state?.response?.projdetails?.map((item) => {
        let tempElement = myExpenseList.ProjectDetails.find(
          (ele) => ele.ProjUniqueId == item.projectUniqueID
        );
        // console.log("tempElement:=>", tempElement);
        array.push({
          recid: Encrypt(key, JSON.stringify(Number(item.projectRecID))),
          expid: Encrypt(
            key,
            JSON.stringify(Number(location?.state?.response?.expId))
          ),
          projid: Encrypt(key, JSON.stringify(Number(item.projectUniqueID))),
          projamount: tempElement.ProjAmount,
          claimedamount: location?.state?.response?.claimAmount,
        });
      });

      const approvedAmountTravel = myExpenseList?.TravelFlag
        ? travelFieldValue
        : [
            {
              ExpId: myExpenseList?.ProjectDetails[0]?.ExpId,
              ApprovedAmount: Number(approvedAmount),
              ProjAmount: location?.state?.response?.claimAmount,
              ClaimedAmount: location?.state?.response?.claimAmount,
            },
          ];
      const filterAdvanceList = advancesList.filter(
        (item) => item.TotalAdvance > 0
      );

      let dataObject = {
        empId: Encrypt(key, JSON.stringify(empId)),
        expStatus: expStatus,
        comment: comments,
        Amount: approvedAmountTravel, //{"expid":"value"}
        projdetails: myExpenseList?.TravelFlag ? [] : array,
        expenseadvanceslist:
          myExpenseList?.ExpenseDetail[0]?.TravelType == "Domestic" ||
          !myExpenseList?.TravelFlag
            ? []
            : filterAdvanceList,
        expenseadvancesdetail: !myExpenseList?.TravelFlag
          ? []
          : [advSummaryJson],
      };
      // console.log("dataObject:  >  ", dataObject);
      //dispatch(postFinancialApproveRejectExpenseData(dataObject));
      // try {
      dispatch(postFinancialApproveRejectExpenseData(dataObject))
        .then((resp) => {
          if (resp.status == 200) {
            setComments("");
            setViewDialog(false);
            setViewFinApproval(false);
            history.push("/expense-Management");
          } else {
            setSystemErrorSnackbar(true);
          }
        })
        .catch((err) => {
          // console.log("response approval3", err);
          setSystemErrorSnackbar(true);
        });
      // } catch {
      //   // setSystemErrorSnackbar(true);
      // }
    } else if (
      viewFinApproval == false &&
      location?.state?.compName == "financeApprovalListing"
    ) {
      location?.state?.response?.projdetails?.map((item) => {
        let tempElement = myExpenseList.ProjectDetails.find(
          (ele) => ele.ProjUniqueId == item.projectUniqueID
        );
        //console.log("tempElement:=>VEJS1", tempElement);
        array.push({
          recid: Encrypt(key, JSON.stringify(Number(item.projectRecID))),
          expid: Encrypt(
            key,
            JSON.stringify(Number(location?.state?.response?.expId))
          ),
          projid: Encrypt(key, JSON.stringify(Number(item.projectUniqueID))),
          projamount: 0,
          claimedamount: location?.state?.response?.claimAmount,
        });
      });
      const approvedAmountTravel = myExpenseList?.TravelFlag
        ? travelFieldValue
        : [
            {
              ExpId: myExpenseList?.ProjectDetails[0]?.ExpId,
              ApprovedAmount: Number(location?.state?.response?.claimAmount),
              ProjAmount: 0,
              ClaimedAmount: Number(location?.state?.response?.claimAmount),
            },
          ];
      // console.log("approved Amount", approvedAmountTravel);
      // console.log("array:  >  ", advSummaryJson);
      const filterAdvanceList = advancesList?.filter(
        (item) => item.TotalAdvance > 0
      );

      let dataObject = {
        EmpId: Encrypt(key, JSON.stringify(empId)),
        ExpStatus: expStatus,
        Comment: comments,
        Amount: approvedAmountTravel,
        projdetails: myExpenseList?.TravelFlag ? [] : array,
        expenseadvanceslist:
          myExpenseList?.ExpenseDetail[0]?.TravelType == "Domestic" ||
          !myExpenseList?.TravelFlag
            ? []
            : filterAdvanceList,
        expenseadvancesdetail: !myExpenseList?.TravelFlag
          ? []
          : [advSummaryJson],
      };
      // console.log("dataObject2:  >  ", dataObject);
      // try {
      dispatch(postFinancialApproveRejectExpenseData(dataObject))
        .then((resp) => {
          if (resp.status == 200) {
            setComments("");
            setViewDialog(false);
            setViewFinApproval(false);
            history.push("/expense-Management");
          } else {
            setSystemErrorSnackbar(true);
          }
        })
        .catch((err) => {
          // console.log("response approval3", err);
          setSystemErrorSnackbar(true);
        });

      // if (response.status != 200) {
      //   setSystemErrorSnackbar(true);
      // }
      // } catch {
      //   // setSystemErrorSnackbar(true);
      // }
    } else if (location?.state?.compName == "teamApprovalListing") {
      //console.log("value in TAL compname:  >  ", value);
      //console.log("empId:  >  ", empId);
      //console.log("comments TAL2:  >  ", comments);
      //console.log("location?.state?.response: TAL1  ",location?.state?.response);

      let array = [];
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      array.push({
        recid: Encrypt(
          key,
          JSON.stringify(Number(location?.state?.response?.uniqueId))
        ),
        expid: Encrypt(
          key,
          JSON.stringify(Number(location?.state?.response?.expId))
        ),
        projid: Encrypt(
          key,
          JSON.stringify(Number(location?.state?.response?.projUniqueId))
        ),
      });
      // console.log("postApproveExpenseData:  >  ", empId);
      let dataObject = {
        empId: Encrypt(key, JSON.stringify(empId)),
        comments: comments,
        project: array,
      };
      // try {
      dispatch(postApproveRejectExpenseData(dataObject))
        .then((resp) => {
          if (resp.status == 200) {
            setComments("");
            setViewDialog(false);
            setViewFinApproval(false);
            history.push("/expense-Management");
          } else {
            setSystemErrorSnackbar(true);
          }
        })
        .catch((err) => {
          setSystemErrorSnackbar(true);
        });
      // const response = dispatch(postApproveRejectExpenseData(dataObject));
      // console.log("response approval", response);
      // if (response.status != 200) {
      //   setSystemErrorSnackbar(true);
      // } else {
      //   setComments("");
      //   setViewDialog(false);
      //   setViewFinApproval(false);
      //   history.push("/expense-Management");
      // }
      // } catch {
      //   setSystemErrorSnackbar(true);
      // }
    }
    // setComments("");
    // setViewDialog(false);
    // setViewFinApproval(false);
    // history.push("/expense-Management");
  };
  //console.log("single expense value: ---->  ", singleExpense);
  const preventMinus = (e) => {
    if (e.code == "Minus" || e.key == "-") {
      e.preventDefault();
    }
  };

  const addAdvancesLine = () => {
    let array = JSON.parse(JSON.stringify(advancesList));
    array.push({
      ExpId: myExpenseList?.ExpenseDetail[0].ExpId,
      DepositDate: null,
      Currency: currency?.currency,
      TotalAdvance: 0,
      AdvanceType: "Travel Card",
    });
    setAdvancesList(array);
  };

  const handleAdvancesList = (e, index, kind) => {
    // console.log("expense detail handleAdvancesList", e, e == "Invalid Date");
    let arr = [...advancesList];
    if (kind == "type") {
      arr[index].AdvanceType = e.target.value;
    }
    if (kind == "date") {
      arr[index].DepositDate = e;
      if (arr[index].AdvanceType == "") {
        arr[index].AdvanceType = "Travel Card";
      }
    }
    if (kind == "amt") {
      arr[index].TotalAdvance = Number(e.target.value.replaceAll(",", ""));
    }
    if (kind == "currency") {
      arr?.forEach((item, index) => {
        item.Currency = e.target.value.currency;
      });
    }
    // arr[index].Currency = currency.currency;
    // console.log("target comsole", e, index, kind, arr);

    setAdvancesList(arr);
    let dataCard = 0;
    let dataCash = 0;
    arr.map((item, index) => {
      if (item.AdvanceType == "Cash") {
        dataCash = dataCash + item.TotalAdvance;
      } else {
        dataCard = dataCard + item.TotalAdvance;
      }
    });
    setTravelCardAmount(dataCard);
    setCash(dataCash);

    setTotalAdv(dataCard + dataCash);

    let advDetail = [
      { AdvancesTypeAmt: 0, AdvanceType: "Travel Card" },
      { AdvancesTypeAmt: 0, AdvanceType: "Cash" },
    ];
    advDetail = advDetail.map((item) =>
      item.AdvanceType == "Cash"
        ? {
            ...item,
            AdvancesTypeAmt: Number(dataCash),
          }
        : {
            ...item,
            AdvancesTypeAmt: Number(dataCard),
          }
    );

    setAdvValue(advDetail);
    // console.log("total amount", dataCard, dataCash, arr, advDetail);

    // const advDetailJson = advSummaryJson;
    // advDetailJson.AdvCash = Number(dataCash);
    // advDetailJson.AdvTravelCard = Number(dataCard);
    // advDetailJson.AdvTotal = Number(dataCash + dataCard);
    // console.log("advJson1",advDetailJson);
    // setAdvancesJson(advDetailJson);
  };

  const handleRemoveAdvances = (i) => {
    const list = JSON.parse(JSON.stringify(advancesList));
    // console.log("list", list);let dataCard = 0;
    let advType = list[i].AdvanceType;

    list.splice(i, 1);

    let travelCard = 0;
    let travelCash = 0;
    list.map((item, index) => {
      if (item.AdvanceType == "Travel Card") {
        travelCard = travelCard + item.TotalAdvance;
      } else {
        travelCash = travelCash + item.TotalAdvance;
      }
    });

    setAdvancesList(list);

    setCash(travelCash);
    setTravelCardAmount(travelCard);

    setTotalAdv(travelCard + travelCash);

    const advDetail = advValue.map((item) =>
      item.AdvanceType == "Travel Card"
        ? {
            ...item,
            AdvancesTypeAmt: Number(travelCard),
          }
        : {
            ...item,
            AdvancesTypeAmt: Number(travelCash),
          }
    );

    setAdvValue(advDetail);
    // console.log("total amount2", travelCard, travelCash, advDetail, list);

    //props.handleProjectList(list);
  };

  const handleRefund = (e, type) => {
    // console.log("handleRefund", e.target.value, type, advSummaryJson);
    setAdvSummaryJson((prevVal) => {
      const advSummary = { ...prevVal };
      if (type == "Travel Card") {
        advSummary.TravelCardBal =
          e.target.value === "" ? "0" : e.target.value.replaceAll(",", "");
      } else if (type == "Cash") {
        advSummary.CashBal =
          e.target.value === "" ? "0" : e.target.value.replaceAll(",", "");
      }
      advSummary.RefundReceived = (
        Number(advSummary.CashBal) + Number(advSummary.TravelCardBal)
      ).toString();
      advSummary.NetPayable = ((
        Number(totalAdv) > 0
          ? totalAdv - travelTotalApproved - Number(advSummary?.RefundReceived)
          : travelTotalApproved - Number(advSummary?.RefundReceived)
      )?.toFixed(2)).toString();
      // console.log("refund travel", advSummary);
      return advSummary;
    });
  };

  return (
    <>
      {matches ? (
        <MobileViewExpense
          myExpenseList={myExpenseList}
          approvedAmount={approvedAmount}
          location={location}
          setAmountNewValue={setAmountNewValue}
          handleOpenBulkApproveDialog={handleOpenBulkApproveDialog}
          handleOpenRejectionDialog={handleOpenRejectionDialog}
        />
      ) : (
        <>
          <Box>
            <Header
              heading={"Expense Detail"}
              ssoEmpId={ssoEmpIdRedux ? ssoEmpIdRedux : null}
              disableAccOptions={1}
            />
          </Box>
          <Box className={classes.root}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                // marginBottom:"10px",
              }}
            >
              {/* {console.log("location",location)} */}
              <Box
                style={{
                  paddingRight: "20px",
                  paddingTop: "5px",
                }}
                onClick={() =>
                  location?.state?.compName == "allExpenseTable"
                    ? history.push({
                        pathname: "/allExpenseReports",
                        state: {
                          compName: "viewExpense",
                        },
                      })
                    : // history.push("/allExpenseReports")
                      history.push("/expense-Management")
                }
              >
                <ArrowBackIosIcon />
              </Box>
              <Box className={classes.expenseHeading}>
                <Typography
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontSize: "24px",
                    lineHeight: "24px",
                    letterSpacing: "0px",
                    textAlign: "left",
                    color: "#2B2D30",
                    paddingBottom: "10px",
                  }}
                >
                  {myExpenseList?.ExpenseDetail[0]?.ExpName}
                </Typography>
                <Chip
                  label={myExpenseList?.ExpFinalStatus}
                  classes={{ label: classes.labelChip }}
                  style={{
                    color: "white",
                    width: "96px",
                    height: "24px",
                    marginLeft: "18px",
                    // display: "block",
                    // clear: "left",
                    // float: "left",
                    background:
                      myExpenseList?.ExpFinalStatus == "In Progress"
                        ? "#E0B15C"
                        : myExpenseList?.ExpFinalStatus == "Approved"
                        ? " #349259"
                        : myExpenseList?.ExpFinalStatus == "Rejected"
                        ? "#D40606"
                        : myExpenseList?.ExpFinalStatus == "Paid"
                        ? "#406086"
                        : "#9E9E9E",
                  }}
                />
              </Box>
            </Box>

            {location?.state?.compName == "teamApprovalListing" ||
            location?.state?.compName == "financeApprovalListing" ? (
              <Box className={classes.buttonBox}>
                <Typography variant="p" className={classes.removeFilter}>
                  <Button
                    variant="outlined"
                    size="medium"
                    disabled={
                      (location?.state?.compName == "teamApprovalListing" &&
                        (myExpenseList?.ProjectDetails[0]?.ExpStatus ==
                          "Rejected" ||
                          myExpenseList?.ProjectDetails[0]?.ExpStatus ==
                            "Approved" ||
                          location?.state?.response?.expStatus == "Rejected" ||
                          location?.state?.response?.expStatus ==
                            "Approved")) ||
                      (!myExpenseList?.TravelFlag &&
                        approvedAmount >
                          myExpenseList?.ExpenseDetail[0]?.ClaimedAmount) ||
                      travelTotalApproved >
                        myExpenseList?.totalamount?.ClaimedAmount
                        ? true
                        : false
                    }
                    className={classes.removeFilterText}
                    onClick={() => handleOpenRejectionDialog(myExpenseList)}
                  >
                    Reject
                  </Button>{" "}
                </Typography>
                <Typography variant="p" className={classes.applyFilter}>
                  {/* {console.log("location" ,  location?.state , travelTotalApproved , myExpenseList?.totalamount?.ClaimedAmount )} */}
                  <Button
                    variant="outlined"
                    disabled={
                      (location?.state?.compName == "teamApprovalListing" &&
                        (myExpenseList?.ProjectDetails[0]?.ExpStatus ==
                          "Rejected" ||
                          myExpenseList?.ProjectDetails[0]?.ExpStatus ==
                            "Approved" ||
                          location?.state?.response?.expStatus == "Rejected" ||
                          location?.state?.response?.expStatus ==
                            "Approved")) ||
                      (!myExpenseList?.TravelFlag &&
                        approvedAmount >
                          myExpenseList?.ExpenseDetail[0]?.ClaimedAmount) ||
                      travelTotalApproved >
                        myExpenseList?.totalamount?.ClaimedAmount
                        ? true
                        : false
                    }
                    size="medium"
                    style={{
                      color: "#FFFFFF",
                      backgroundColor:
                        buttonState ||
                        (!myExpenseList?.TravelFlag &&
                          approvedAmount >
                            myExpenseList?.ExpenseDetail[0]?.ClaimedAmount) ||
                        travelTotalApproved >
                          myExpenseList?.totalamount?.ClaimedAmount
                          ? "#BDBDBD"
                          : "#0B1941",
                    }}
                    className={
                      myExpenseList?.ExpFinalStatus == "Rejected" ||
                      myExpenseList?.ExpFinalStatus == "Approved"
                        ? classes.approvedConditional
                        : classes.applyFilterText
                    }
                    onClick={() => handleOpenBulkApproveDialog(myExpenseList)}
                  >
                    Approve
                  </Button>{" "}
                </Typography>
                <ApproveRejectDialog
                  openBulkApproveDialog={openBulkApproveDialog}
                  singleExpense={singleExpense}
                  veiwDialog={veiwDialog}
                  approveOrReject={approveOrReject}
                  handleComments={handleComments}
                  handleSingleApproveRejectExpense={
                    handleSingleApproveRejectExpense
                  }
                  handleCloseBulkApproveDialog={handleCloseBulkApproveDialog}
                  viewFinApproval={viewFinApproval}
                />
              </Box>
            ) : (
              ""
            )}
            <Box className="stepper" style={{ marginTop: "22px" }}>
              <Stepper myExpenseList={myExpenseList} data={location.state} />
            </Box>
            {!myExpenseList?.TravelFlag ? (
              // non-travel code
              <>
                <Box className={classes.mainBox} style={{ marginTop: "94px" }}>
                  <Grid container>
                    <Grid item xs={6} md={6} className={classes.expenseGrid}>
                      <TableContainer>
                        <Table
                          aria-label="simple table"
                          classes={{ root: classes.tableCell }}
                        >
                          <TableBody>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography className={classes.expenseDetail}>
                                  {" "}
                                  Expense Detail
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography
                                  className={classes.auditHistory}
                                  onClick={handleOpenAuditHistDialog}
                                >
                                  Audit History
                                </Typography>
                                <AuditHistoryDialog
                                  open={openAuditHistDialog}
                                  handleClose={handleCloseAuditHistDialog}
                                  auditHistoryData={auditHistoryData}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography
                                  className={classes.expenseIdHeading}
                                >
                                  {" "}
                                  {"Expense ID:"}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography className={classes.expenseId}>
                                  {myExpenseList?.ExpenseDetail[0]?.ExpCode}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography
                                  className={classes.expenseIdHeading}
                                >
                                  {" "}
                                  {"Purpose:"}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography className={classes.expenseId}>
                                  {myExpenseList?.ExpenseDetail[0]?.Purpose}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography
                                  className={classes.expenseIdHeading}
                                >
                                  {" "}
                                  {"Expense Category:"}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography className={classes.expenseId}>
                                  {" "}
                                  {
                                    myExpenseList?.ExpenseDetail[0]
                                      ?.CategoryName
                                  }
                                </Typography>
                              </TableCell>
                            </TableRow>
                            {myExpenseList?.ExpenseDetail[0]?.BooksQty !=
                              null &&
                            myExpenseList?.ExpenseDetail[0]?.BooksQty !=
                              "null" &&
                            myExpenseList?.ExpenseDetail[0]?.BooksQty != "" ? (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  <Typography
                                    className={classes.expenseIdHeading}
                                  >
                                    {" "}
                                    {"Quantity"}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography className={classes.expenseId}>
                                    {" "}
                                    {myExpenseList?.ExpenseDetail[0]?.BooksQty}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ) : (
                              ""
                            )}
                            {/* {myExpenseList?.ExpenseDetail[0]?.BillNumber !=
                              null &&
                            myExpenseList?.ExpenseDetail[0]?.BillNumber !=
                              "null" &&
                            myExpenseList?.ExpenseDetail[0]?.BillNumber != "" ? (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  <Typography
                                    className={classes.expenseIdHeading}
                                  >
                                    {" "}
                                    {"Bill Number"}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography className={classes.expenseId}>
                                    {" "}
                                    {myExpenseList?.ExpenseDetail[0]?.BillNumber}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ) : (
                              ""
                            )} */}
                            {myExpenseList?.ExpenseDetail[0]?.VendorName !=
                              null &&
                            myExpenseList?.ExpenseDetail[0]?.VendorName !=
                              "null" &&
                            myExpenseList?.ExpenseDetail[0]?.VendorName !=
                              "" ? (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  <Typography
                                    className={classes.expenseIdHeading}
                                  >
                                    {" "}
                                    {"Vendor Name:"}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography className={classes.expenseId}>
                                    {" "}
                                    {
                                      myExpenseList?.ExpenseDetail[0]
                                        ?.VendorName
                                    }
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ) : (
                              ""
                            )}
                            {myExpenseList?.ExpenseDetail[0]?.BillNumber !=
                              null &&
                            myExpenseList?.ExpenseDetail[0]?.BillNumber !=
                              "null" &&
                            myExpenseList?.ExpenseDetail[0]?.BillNumber !=
                              "" ? (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  <Typography
                                    className={classes.expenseIdHeading}
                                  >
                                    {" "}
                                    {"Bill Number:"}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography className={classes.expenseId}>
                                    {" "}
                                    {
                                      myExpenseList?.ExpenseDetail[0]
                                        ?.BillNumber
                                    }
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ) : (
                              ""
                            )}
                            {myExpenseList?.ExpenseDetail[0]?.GSTNumber !=
                              null &&
                            myExpenseList?.ExpenseDetail[0]?.GSTNumber !=
                              "null" &&
                            myExpenseList?.ExpenseDetail[0]?.GSTNumber != "" ? (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  <Typography
                                    className={classes.expenseIdHeading}
                                  >
                                    {" "}
                                    {"GST Number:"}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography className={classes.expenseId}>
                                    {" "}
                                    {myExpenseList?.ExpenseDetail[0]?.GSTNumber}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ) : (
                              ""
                            )}
                            {myExpenseList?.ExpenseDetail[0]
                              ?.BillingCycleFromDate != null ? (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  <Typography
                                    className={classes.expenseIdHeading}
                                  >
                                    {" "}
                                    {"From Date:"}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography className={classes.expenseId}>
                                    {" "}
                                    {moment(
                                      myExpenseList?.ExpenseDetail[0]
                                        ?.BillingCycleFromDate
                                    ).format("DD/MM/YYYY")}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ) : (
                              ""
                            )}
                            {myExpenseList?.ExpenseDetail[0]
                              ?.BillingCycleToDate != null ? (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  <Typography
                                    className={classes.expenseIdHeading}
                                  >
                                    {" "}
                                    {"To Date:"}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography className={classes.expenseId}>
                                    {" "}
                                    {moment(
                                      myExpenseList?.ExpenseDetail[0]
                                        ?.BillingCycleToDate
                                    ).format("DD/MM/YYYY")}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ) : (
                              ""
                            )}
                            {myExpenseList?.ExpenseDetail[0]
                              ?.BillingCycleMonth != "null" &&
                            myExpenseList?.ExpenseDetail[0]
                              ?.BillingCycleMonth != "" &&
                            myExpenseList?.ExpenseDetail[0]
                              ?.BillingCycleMonth != null ? (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  <Typography
                                    className={classes.expenseIdHeading}
                                  >
                                    {" "}
                                    {"Claim Month:"}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography className={classes.expenseId}>
                                    {" "}
                                    {
                                      myExpenseList?.ExpenseDetail[0]
                                        ?.BillingCycleMonth
                                    }
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ) : (
                              ""
                            )}
                            {myExpenseList?.ExpenseDetail[0]?.FromLocation !=
                              "null" &&
                            myExpenseList?.ExpenseDetail[0]?.FromLocation !=
                              null ? (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  <Typography
                                    className={classes.expenseIdHeading}
                                  >
                                    {" "}
                                    {"From Location:"}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography className={classes.expenseId}>
                                    {" "}
                                    {
                                      myExpenseList?.ExpenseDetail[0]
                                        ?.FromLocation
                                    }
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ) : (
                              ""
                            )}
                            {myExpenseList?.ExpenseDetail[0]?.ToLocation !=
                              "null" &&
                            myExpenseList?.ExpenseDetail[0]?.ToLocation !=
                              null ? (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  <Typography
                                    className={classes.expenseIdHeading}
                                  >
                                    {" "}
                                    {"To Location:"}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography className={classes.expenseId}>
                                    {" "}
                                    {
                                      myExpenseList?.ExpenseDetail[0]
                                        ?.ToLocation
                                    }
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ) : (
                              ""
                            )}
                            {myExpenseList?.ExpenseDetail[0]?.Distance != 0 ? (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  <Typography
                                    className={classes.expenseIdHeading}
                                  >
                                    {" "}
                                    {"Distance (Km/Miles):"}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography className={classes.expenseId}>
                                    {" "}
                                    {myExpenseList?.ExpenseDetail[0]?.Distance}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ) : (
                              ""
                            )}

                            <TableRow>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{ verticalAlign: "initial" }}
                              >
                                <Typography
                                  className={classes.expenseIdHeading}
                                >
                                  {" "}
                                  {"Project Code:"}
                                </Typography>
                              </TableCell>
                              <Box>
                                {myExpenseList?.ProjectDetails?.map((value) => {
                                  return (
                                    <Box>
                                      <TableCell align="left">
                                        <Typography
                                          className={
                                            (location?.state?.compName ==
                                              "teamApprovalListing" ||
                                              location?.state?.compName ==
                                                "financeApprovalListing") &&
                                            value.ProjectId ==
                                              location.state.response.projectId
                                              ? classes.textConditional
                                              : classes.projectId
                                          }
                                        >
                                          {" "}
                                          {value.ProjectId} ({value.ProjectName}
                                          )
                                        </Typography>
                                      </TableCell>
                                    </Box>
                                  );
                                })}
                              </Box>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography
                                  className={classes.expenseIdHeading}
                                >
                                  {" "}
                                  {"Employee:"}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography className={classes.expenseId}>
                                  {" "}
                                  {myExpenseList?.ExpenseDetail[0]?.EmpName}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography
                                  className={classes.expenseIdHeading}
                                >
                                  {" "}
                                  {"Employee ID:"}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography className={classes.expenseId}>
                                  {" "}
                                  {myExpenseList?.ExpenseDetail[0]?.EmpCode}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography
                                  className={classes.expenseIdHeading}
                                >
                                  {" "}
                                  {"Invoice Date:"}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography className={classes.expenseId}>
                                  {" "}
                                  {moment(
                                    myExpenseList?.ExpenseDetail[0]?.ReceiptDate
                                  ).format("DD/MM/YYYY")}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            {myExpenseList?.ExpenseDetail[0]?.DependentId !=
                              0 &&
                            myExpenseList?.ExpenseDetail[0]?.Purpose ==
                              "Medical Expenses" ? (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  <Typography
                                    className={classes.expenseIdHeading}
                                  >
                                    {" "}
                                    {"Relationship:"}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography className={classes.expenseId}>
                                    {" "}
                                    {myExpenseList?.ExpenseDetail[0]?.Dependent}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ) : (
                              ""
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    <Grid item xs={6} md={6} className={classes.expenseGrid}>
                      <TableContainer>
                        <Table
                          aria-label="simple table"
                          classes={{ root: classes.tableCell }}
                        >
                          <TableBody>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography className={classes.expenseDetail}>
                                  {" "}
                                  {"Amount"}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography className={classes.expenseId}>
                                  {""}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography
                                  className={classes.expenseIdHeading}
                                >
                                  {" "}
                                  {"Payment Method:"}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography className={classes.expenseId}>
                                  {" "}
                                  {myExpenseList?.ExpenseDetail[0]?.MethodName}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography
                                  className={classes.expenseIdHeading}
                                >
                                  {" "}
                                  {"Bill Amount:"}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography className={classes.expenseId}>
                                  {myExpenseList?.ExpenseDetail[0]
                                    ?.CurrencyChanged == 1 ? (
                                    <Tooltip title="Default currency has been changed.">
                                      <span style={{ color: "red" }}>
                                        {
                                          myExpenseList?.ExpenseDetail[0]
                                            ?.CurrencySymbol
                                        }
                                        {myExpenseList?.ExpenseDetail[0]?.BillAmount.toString().replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )}
                                      </span>
                                    </Tooltip>
                                  ) : (
                                    `${
                                      myExpenseList?.ExpenseDetail[0]
                                        ?.CurrencySymbol
                                    }${myExpenseList?.ExpenseDetail[0]?.BillAmount.toString().replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}`
                                  )}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography
                                  className={classes.expenseIdHeading}
                                >
                                  {" "}
                                  {"Claim Amount:"}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography className={classes.expenseId}>
                                  {" "}
                                  <div>
                                    {myExpenseList?.ExpenseDetail[0]
                                      ?.CurrencyChanged == 1 ||
                                    (location?.state?.response?.policyLimit &&
                                      myExpenseList?.ExpenseDetail[0]
                                        ?.ClaimedAmount >
                                        noOfMonths *
                                          location?.state?.response
                                            ?.policyLimit &&
                                      location?.state?.response
                                        ?.exceptionalApproval == 1) ? (
                                      <Tooltip
                                        title={
                                          myExpenseList?.ExpenseDetail[0]
                                            ?.CurrencyChanged == 1 &&
                                          location?.state?.response
                                            ?.exceptionalApproval == 1 &&
                                          myExpenseList?.ExpenseDetail[0]
                                            ?.ClaimedAmount >
                                            noOfMonths *
                                              location?.state?.response
                                                ?.policyLimit &&
                                          location?.state?.response
                                            ?.exceptionalApproval == 1
                                            ? "Claim Amount Exceeds Policy limit and Default currency has been changed"
                                            : myExpenseList?.ExpenseDetail[0]
                                                ?.CurrencyChanged == 1
                                            ? "Default currency has been changed"
                                            : "Claim Amount exceeds policy limit"
                                        }
                                      >
                                        <span style={{ color: "red" }}>
                                          {
                                            myExpenseList?.ExpenseDetail[0]
                                              ?.CurrencySymbol
                                          }
                                          {myExpenseList?.ExpenseDetail[0]?.ClaimedAmount.toString().replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                        </span>
                                      </Tooltip>
                                    ) : (
                                      <div>
                                        {
                                          myExpenseList?.ExpenseDetail[0]
                                            ?.CurrencySymbol
                                        }
                                        {myExpenseList?.ExpenseDetail[0]?.ClaimedAmount.toString().replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </Typography>
                              </TableCell>
                            </TableRow>
                            {myExpenseList?.ExpFinalStatus == "Approved" ||
                            myExpenseList?.ExpFinalStatus == "Rejected" ? (
                              location?.state?.compName == "myExpenseListing" ||
                              location?.state?.compName ==
                                "teamApprovalListing" ? (
                                myExpenseList?.ExpenseDetail[0]
                                  ?.ApproverLevel1Flag == 1 ? (
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      <Typography
                                        className={classes.expenseIdHeading}
                                      >
                                        {" "}
                                        {"Approved Amount:"}
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                      <Typography className={classes.expenseId}>
                                        {" "}
                                        {myExpenseList?.ExpenseDetail[0]
                                          ?.CurrencyChanged == 1 ? (
                                          <Tooltip title="Default currency has been changed.">
                                            <span style={{ color: "red" }}>
                                              {
                                                myExpenseList?.ExpenseDetail[0]
                                                  ?.CurrencySymbol
                                              }
                                              {myExpenseList?.ExpenseDetail[0]?.ApprovedAmount.toString().replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}
                                            </span>
                                          </Tooltip>
                                        ) : (
                                          `${
                                            myExpenseList?.ExpenseDetail[0]
                                              ?.CurrencySymbol
                                          }${myExpenseList?.ExpenseDetail[0]?.ApprovedAmount.toString().replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}`
                                        )}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                            {(myExpenseList?.ExpFinalStatus == "Approved" ||
                              myExpenseList?.ExpFinalStatus == "Rejected") &&
                            (location?.state?.compName == "myExpenseListing" ||
                              location?.state?.compName ==
                                "teamApprovalListing") &&
                            myExpenseList?.ExpenseDetail[0]
                              ?.ApproverLevel1Flag == 1 ? (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  <Typography
                                    className={classes.expenseIdHeading}
                                  >
                                    {" "}
                                    {"Comments:"}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography className={classes.expenseId}>
                                    {" "}
                                    {`${myExpenseList?.ExpenseDetail[0]?.ApproverLevel2Comment}`}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ) : (
                              ""
                            )}
                            {location?.state?.compName ==
                            "financeApprovalListing" ? (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  <Typography
                                    className={classes.expenseIdHeading}
                                  >
                                    {" "}
                                    {"Approved Amount:"}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <NumericFormat
                                    id="outlined-basic-approved-amount"
                                    label="Approved amount"
                                    onKeyPress={preventMinus}
                                    variant="outlined"
                                    value={approvedAmount}
                                    inputProps={{ maxLength: 12 }}
                                    decimalScale={2}
                                    customInput={TextField}
                                    error={
                                      approvedAmount >
                                      myExpenseList?.ExpenseDetail[0]
                                        ?.ClaimedAmount
                                        ? true
                                        : false
                                    }
                                    onChange={(e) =>
                                      setApprovedAmount(e.target.value)
                                    }
                                  />

                                  {approvedAmount >
                                  myExpenseList?.ExpenseDetail[0]
                                    ?.ClaimedAmount ? (
                                    <span
                                      style={{
                                        color: "#E02020",
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      Amount must be equal to or less than Claim
                                      Amount.
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                              </TableRow>
                            ) : (
                              ""
                            )}
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography
                                  className={classes.splitAmountHeading}
                                >
                                  {" "}
                                  {"Split Amount"}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography
                                  className={classes.expenseId}
                                ></Typography>
                              </TableCell>
                            </TableRow>
                            {/* {console.log("expense detail data1", myExpenseList)} */}
                            {myExpenseList?.ProjectDetails?.map((value) => {
                              return (
                                <TableRow>
                                  <TableCell component="th" scope="row">
                                    <Typography
                                      className={classes.expenseIdHeading}
                                      style={{ paddingTop: "11px" }}
                                    >
                                      {" "}
                                      {value.ProjectId}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Box
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        className={
                                          (location?.state?.compName ==
                                            "teamApprovalListing" ||
                                            location?.state?.compName ==
                                              "financeApprovalListing") &&
                                          value.ProjectId ==
                                            location.state.response.projectId
                                            ? classes.textConditionalSplitAmount
                                            : classes.expenseIdBillable
                                        }
                                      >
                                        {" "}
                                        {value?.ApprovedAmount != null ? (
                                          //print Approved amount
                                          value?.ApprovedAmount !=
                                          value?.ProjAmount ? (
                                            <Box style={{ minWidth: "100px" }}>
                                              <Typography
                                                style={{
                                                  fontFamily: "Montserrat",
                                                  fontSize: "12px",
                                                  fontWeight: 500,
                                                  lineHeight: "10px",
                                                  letterSpacing: "0px",
                                                  textAlign: "left",
                                                }}
                                              >
                                                <del>
                                                  {value.CurrencySymbol}
                                                  {value.ProjAmount.toString().replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}
                                                </del>
                                              </Typography>
                                              <Typography
                                                style={{
                                                  fontFamily: "Montserrat",
                                                  fontSize: "16px",
                                                  fontWeight: 500,
                                                  lineHeight: "24px",
                                                  letterSpacing: "0px",
                                                  textAlign: "left",
                                                }}
                                              >
                                                {value.CurrencySymbol}
                                                {value.ApprovedAmount.toString().replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                )}
                                              </Typography>
                                            </Box>
                                          ) : (
                                            <Box style={{ minWidth: "100px" }}>
                                              <Typography
                                                style={{
                                                  fontFamily: "Montserrat",
                                                  fontSize: "16px",
                                                  fontWeight: 500,
                                                  lineHeight: "24px",
                                                  letterSpacing: "0px",
                                                  textAlign: "left",
                                                }}
                                              >
                                                {value.CurrencySymbol}{" "}
                                                {value.ApprovedAmount.toString().replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                )}
                                              </Typography>
                                            </Box>
                                          )
                                        ) : (
                                          //print ProjAmount as earlier
                                          <Box style={{ minWidth: "100px" }}>
                                            <Typography
                                              className={
                                                (location?.state?.compName ==
                                                  "teamApprovalListing" ||
                                                  location?.state?.compName ==
                                                    "financeApprovalListing") &&
                                                value.ProjectId ==
                                                  location.state.response
                                                    .projectId
                                                  ? classes.textConditionalSplitAmount
                                                  : classes.expenseId
                                              }
                                            >
                                              {value.CurrencySymbol}
                                              {value.ProjAmount.toString().replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}
                                            </Typography>
                                          </Box>
                                        )}
                                      </Typography>
                                      <Box>
                                        <Typography
                                          className={classes.expenseId}
                                          style={{
                                            backgroundColor: "#F0F6FB",
                                            marginLeft: "20px",
                                            marginRight: "20px",
                                            width: "110px",
                                            minHeight: "12px",
                                            borderRadius: "2px",
                                            display: "flex",
                                            justifyContent: "center",
                                            paddingBottom: "10px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          {" "}
                                          {value.Billable == 1
                                            ? "Billable"
                                            : "Non-Billable"}
                                        </Typography>
                                      </Box>

                                      <Typography className={classes.expenseId}>
                                        {" "}
                                        <img
                                          style={{ height: "36px" }}
                                          src={
                                            value?.ExpStatus == "Approved"
                                              ? ApprovedIcon
                                              : value?.ExpStatus ==
                                                "In Progress"
                                              ? InProgressIcon
                                              : value?.ExpStatus == "Rejected"
                                              ? RejectedIcon
                                              : null
                                          }
                                        />
                                      </Typography>
                                      <Box>
                                        {value?.ExpStatus != "Draft" && (
                                          <Tooltip
                                            title={handleToolTip(value)}
                                            arrow
                                          >
                                            <ErrorOutlineIcon
                                              className={classes.tooltip}
                                            />
                                          </Tooltip>
                                        )}
                                      </Box>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Box>
                {/* To be used according to the condition */}
                {myExpenseList?.EmployeeDetails == null && (
                  <Box className={classes.attachmentBox}>
                    <Grid container>
                      <Grid
                        item
                        xs={6}
                        md={12}
                        className={classes.attachmentGrid}
                      >
                        <Paper
                          className={classes.attendeesPaper}
                          classes={{
                            root: classes.papaerRoot,
                            elevation1: classes.paperElivation,
                          }}
                        >
                          <Box style={{ marginLeft: "20px" }}>
                            <Box style={{ display: "flex" }}>
                              <Typography className={classes.attachmentHeading}>
                                {"Attachments"}
                              </Typography>
                              <Typography className={classes.nOfAttachment}>
                                {`(${myExpenseList?.attachements?.length} Attachments)`}
                              </Typography>
                            </Box>
                            {/* {console.log("expense detail data2", myExpenseList)} */}
                            {myExpenseList?.attachements?.length > 0 &&
                              myExpenseList?.attachements?.map((value) => {
                                return (
                                  <Box
                                    style={{
                                      display: "flex",
                                      marginTop: "20px",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <Box
                                      style={{
                                        display: "flex",
                                        backgroundColor: "#F0F6FB",
                                        width: "540px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <Typography
                                        style={{ marginRight: "10px" }}
                                      >
                                        {value.FileName.includes(".pdf") ? (
                                          <InsertDriveFileIcon
                                            style={{ color: "#0B1941" }}
                                          />
                                        ) : (
                                          <PhotoSizeSelectActualIcon
                                            style={{ color: "#0B1941" }}
                                          />
                                        )}
                                      </Typography>
                                      <Typography className={classes.fileName}>
                                        {value.FileName}
                                      </Typography>
                                    </Box>
                                    <Typography
                                      style={{ marginLeft: "20px" }}
                                      onClick={() => {
                                        handleDownload(
                                          myExpenseList,
                                          value.FileName
                                        );
                                      }}
                                    >
                                      <GetAppOutlinedIcon />
                                    </Typography>
                                  </Box>
                                );
                              })}
                          </Box>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </>
            ) : (
              //travel expense detail
              <Box style={{ paddingBottom: "2rem" }}>
                <Grid
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "2rem",
                  }}
                >
                  <Typography
                    className={classes.expenseDetail}
                    style={{
                      // marginTop: "5rem", paddingTop: "0px"
                      paddingRight: "2rem",
                    }}
                  >
                    Expense Detail
                  </Typography>
                  <Grid align="left">
                    <Typography
                      className={classes.auditHistory}
                      onClick={handleOpenAuditHistDialog}
                    >
                      Audit History
                    </Typography>
                    <AuditHistoryDialog
                      open={openAuditHistDialog}
                      handleClose={handleCloseAuditHistDialog}
                      auditHistoryData={auditHistoryData}
                    />
                  </Grid>
                </Grid>

                <Box style={{ marginTop: "10px", borderRadius: "8px" }}>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    style={{
                      padding: "1rem 0px",
                      border: "1px solid #979797",
                      background: "#fff",
                    }}
                  >
                    <Grid
                      item
                      xs={6}
                      style={{ display: "flex", padding: "16px 24px" }}
                    >
                      <Typography
                        className={classes.headerName}
                        style={{ width: "160px" }}
                      >
                        Expense ID:
                      </Typography>
                      <Typography
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        {myExpenseList?.ExpenseDetail[0]?.ExpCode}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{ display: "flex", padding: "16px 24px" }}
                    >
                      <Typography
                        className={classes.headerName}
                        style={{ width: "160px" }}
                      >
                        Employee Name:
                      </Typography>
                      <Typography
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        {myExpenseList?.ExpenseDetail[0]?.EmpName}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{ display: "flex", padding: "16px 24px" }}
                    >
                      <Typography
                        className={classes.headerName}
                        style={{ width: "160px" }}
                      >
                        Purpose:
                      </Typography>
                      <Typography
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        {myExpenseList?.ExpenseDetail[0]?.Purpose}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{ display: "flex", padding: "16px 24px" }}
                    >
                      <Typography
                        className={classes.headerName}
                        style={{ width: "160px" }}
                      >
                        Employee ID:
                      </Typography>
                      <Typography
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        {myExpenseList?.ExpenseDetail[0]?.EmpCode}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    className={classes.header}
                    style={{
                      padding: "16px 24px",
                      marginBottom: "10px",
                      marginTop: "10px",
                      display: "flex",
                      border: "1px solid #979797",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        width: "260px",
                      }}
                    >
                      Project ID :{" "}
                      {myExpenseList?.ProjectDetails != null
                        ? myExpenseList?.ProjectDetails[0]?.ProjectId
                        : ""}
                    </Typography>
                    {"   "}
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      {myExpenseList?.ExpenseDetail?.length} Categories
                    </Typography>
                  </Grid>
                  {/* travel details  */}
                  <Grid
                    style={{ border: "1px solid #979797", background: "#fff" }}
                  >
                    <Typography
                      style={{
                        background: "#F4F4F4",
                        // borderRadius: "8px 8px 0px 0px",
                        maxWidth: "100%", //1126
                        maxHeight: "58px",
                        fontSize: "14px",
                        fontWeight: "600",
                        padding: "16px 24px",
                        borderBottom: "1px solid #e0e0e0",
                      }}
                    >
                      Travel Details
                    </Typography>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      // style={{ paddingTop: "1rem" }}
                    >
                      <Grid
                        item
                        xs={6}
                        style={{ display: "flex", padding: "16px 24px" }}
                      >
                        <Typography
                          className={classes.headerName}
                          style={{ width: "160px" }}
                        >
                          Destination:
                        </Typography>
                        {/* {console.log("advSummaryJson", advSummaryJson)} */}
                        <Typography
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          {advSummaryJson?.Destination || ""}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{ display: "flex", padding: "16px 24px" }}
                      >
                        <Typography
                          className={classes.headerName}
                          style={{ width: "160px" }}
                        >
                          Start:
                        </Typography>
                        <Typography
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          {myExpenseList?.AdvSummaryDetails != null
                            ? (myExpenseList?.AdvSummaryDetails[0]
                                ?.Destination !== null &&
                                moment(advSummaryJson?.TravelStartDate).format(
                                  "DD/MM/YYYY"
                                )) ||
                              ""
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{ display: "flex", padding: "16px 24px" }}
                      >
                        <Typography
                          className={classes.headerName}
                          style={{ width: "160px" }}
                        >
                          End:
                        </Typography>
                        <Typography
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          {myExpenseList?.AdvSummaryDetails != null
                            ? (myExpenseList?.AdvSummaryDetails[0]
                                ?.Destination !== null &&
                                moment(advSummaryJson?.TravelEndDate).format(
                                  "DD/MM/YYYY"
                                )) ||
                              ""
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{ display: "flex", padding: "16px 24px" }}
                      >
                        <Typography
                          className={classes.headerName}
                          style={{ width: "160px" }}
                        >
                          Travel Type:
                        </Typography>
                        <Typography
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          {myExpenseList?.ExpenseDetail[0]?.TravelType || ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Advances Details */}
                  {/* {console.log(
                    "advances detail",
                    myExpenseList?.ExpenseDetail[0]?.TravelType
                  )} */}
                  {myExpenseList?.ExpenseDetail[0]?.TravelType ==
                  "International" ? (
                    <>
                      {/* {console.log("advancesList", advancesList)} */}
                      {location?.state?.compName == "financeApprovalListing" ? (
                        <Grid
                          style={{
                            border: "1px solid #979797",
                            background: "#fff",
                            marginTop: "10px",
                          }}
                        >
                          <Typography
                            style={{
                              background: "#F4F4F4",
                              // borderRadius: "8px 8px 0px 0px",
                              maxWidth: "100%", //1126
                              maxHeight: "58px",
                              fontSize: "14px",
                              fontWeight: "600",
                              padding: "16px 24px",
                              borderBottom: "1px solid #e0e0e0",
                            }}
                          >
                            Advances
                          </Typography>
                          {advancesList?.map((item, index) => {
                            //console.log("AdvancesList", item);
                            return (
                              <Grid
                                container
                                // spacing={5}
                                xs={12}
                                justifyContent="space-between"
                                // alignItems="center"
                                style={{
                                  padding: "16px 24px",
                                }}
                              >
                                <Grid
                                  item
                                  xs={3}
                                  style={{
                                    paddingRight: "8px",
                                  }}
                                >
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                      disableToolbar
                                      inputVariant="outlined"
                                      fullWidth
                                      className={classes.advancesDate}
                                      variant="inline"
                                      format="dd/MM/yyyy"
                                      margin="normal"
                                      autoOk={true}
                                      disableFuture={true}
                                      id={`date-picker-inline-${index}`}
                                      label="Deposit Date"
                                      value={item.DepositDate} // Use the receiptdate from the 'cat' object
                                      onChange={(e) => {
                                        handleAdvancesList(e, index, "date");
                                      }} // Pass the index here
                                      // onChange={(date) => handleReceiptDate(selectedCategory[index], date, index)}
                                      KeyboardButtonProps={{
                                        "aria-label": "change date",
                                        style: { paddingRight: "3px" },
                                      }}
                                    />
                                  </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                  style={{ padding: "0px 8px" }}
                                >
                                  <FormControl
                                    variant="outlined"
                                    fullWidth
                                    className={classes.advancesField}
                                    disabled
                                  >
                                    <InputLabel id={`select-currency-${index}`}>
                                      Currency*
                                    </InputLabel>
                                    <Select
                                      labelId={`select-currency-${index}`}
                                      id={`select-currency-${index}`}
                                      className={
                                        matches
                                          ? classes.inputLabels1
                                          : classes.inputLabels
                                      }
                                      style={{
                                        marginRight: matches ? "-6px" : "0px",
                                      }}
                                      value={currency}
                                      onChange={(e) => {
                                        //  handleCurrency(e.target.value, index);
                                        // console.log("currency", e.target.value);
                                        setCurrency(
                                          e.target.value
                                            ? e.target.value
                                            : currency
                                        );
                                        // handleAdvancesList(e, index, "currency");
                                      }}
                                      label="Currency*"
                                    >
                                      {CurrencyList?.map((crItem) => {
                                        return (
                                          <MenuItem
                                            key={crItem.currencyId}
                                            value={crItem}
                                          >
                                            {crItem.currencyCode}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>

                                    <FormHelperText
                                      className={classes.errorText}
                                    >
                                      {currency === "" ? currencyError : ""}
                                    </FormHelperText>
                                  </FormControl>{" "}
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                  style={{ padding: "0px 8px" }}
                                >
                                  <NumericFormat
                                    decimalScale={2}
                                    inputProps={{ maxLength: 12 }}
                                    id={`advances-amount-${index}`}
                                    label="Advances Amount"
                                    variant="outlined"
                                    onKeyPress={preventMinus}
                                    fullWidth
                                    className={classes.advancesField}
                                    value={item.TotalAdvance}
                                    onChange={(e) => {
                                      handleAdvancesList(e, index, "amt");
                                    }}
                                    thousandSeparator={true}
                                    customInput={TextField}
                                    FormHelperTextProps={{
                                      classes: {
                                        root: classes.errorRoot,
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={2}
                                  style={{ paddingLeft: "8px" }}
                                >
                                  <FormControl
                                    variant="outlined"
                                    fullWidth
                                    className={classes.advancesField}
                                  >
                                    <InputLabel id={`select-label2-${index}`}>
                                      Advances Type
                                    </InputLabel>
                                    {/* {console.log("KUSH1111", currency)} */}
                                    <Select
                                      labelId={`select-label2-${index}`}
                                      id={`select-label2-${index}`}
                                      className={
                                        matches
                                          ? classes.inputLabels1
                                          : classes.inputLabels
                                      }
                                      value={item.AdvanceType}
                                      onChange={(e) => {
                                        handleAdvancesList(e, index, "type");
                                      }}
                                      label="Advances Type"
                                    >
                                      {AdvancesType?.map((adv) => {
                                        return (
                                          <MenuItem
                                            key={adv.value}
                                            value={adv.value}
                                          >
                                            {adv.label}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>{" "}
                                </Grid>
                                {location?.state?.compName ==
                                "financeApprovalListing" ? (
                                  index != 0 ? (
                                    <Box
                                      display="flex"
                                      justifyContent="flex-start"
                                    >
                                      <Button
                                        style={{ minWidth: "24px" }}
                                        onClick={() =>
                                          handleRemoveAdvances(index)
                                        }
                                      >
                                        <span class="material-symbols-outlined">
                                          delete
                                        </span>
                                      </Button>
                                    </Box>
                                  ) : (
                                    <Box
                                      display="flex"
                                      justifyContent="flex-start"
                                    >
                                      <Button
                                        style={{ minWidth: "42px" }}
                                        //onClick={() => handleRemoveAdvances(index)}
                                      >
                                        <span class="material-symbols-outlined">
                                          {" "}
                                        </span>
                                      </Button>
                                    </Box>
                                  )
                                ) : (
                                  <></>
                                )}
                              </Grid>
                            );
                          })}
                          <Box style={{ height: "33px", paddingLeft: "24px" }}>
                            <Link
                              style={{
                                color: "#008CE6",
                                cursor: "pointer",
                                fontFamily: "Montserrat",
                                fontSize: "16px",
                                fontWeight: "500",
                                lineHeight: "32px",
                                letterSpacing: "0px",
                                textAlign: "left",
                                // padding:"25px"
                              }}
                              target="_blank"
                              underline="always"
                              onClick={() => {
                                addAdvancesLine();
                              }}
                            >
                              + Add Advances
                            </Link>
                          </Box>
                        </Grid>
                      ) : (
                        advancesList?.length > 0 &&
                        advancesList[0]?.TotalAdvance != 0 && (
                          <Grid
                            style={{
                              border: "1px solid #979797",
                              background: "#fff",
                              marginTop: "10px",
                            }}
                          >
                            <Typography
                              style={{
                                background: "#F4F4F4",
                                // borderRadius: "8px 8px 0px 0px",
                                maxWidth: "100%", //1126
                                maxHeight: "58px",
                                fontSize: "14px",
                                fontWeight: "600",
                                padding: "16px 24px",
                                borderBottom: "1px solid #e0e0e0",
                              }}
                            >
                              Advances
                            </Typography>
                            <TableContainer>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <Box style={{ display: "flex" }}>
                                      <TableCell
                                        style={{
                                          fontWeight: "600",
                                          flex: 1,
                                          paddingLeft: "24px",
                                        }}
                                      >
                                        Deposit Date
                                      </TableCell>
                                      <TableCell
                                        style={{ fontWeight: "600", flex: 1 }}
                                      >
                                        Currency
                                      </TableCell>
                                      <TableCell
                                        style={{ fontWeight: "600", flex: 1 }}
                                      >
                                        Amount
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontWeight: "600",
                                          flex: 1,
                                          paddingRight: "24px",
                                        }}
                                      >
                                        Type
                                      </TableCell>
                                    </Box>
                                  </TableRow>
                                </TableHead>
                                {/* {console.log("advancesList", advancesList)} */}
                                <TableBody>
                                  {advancesList?.map((item, index) => (
                                    <>
                                      {item.TotalAdvance != 0 && (
                                        <TableRow>
                                          <Box
                                            style={{ display: "flex" }}
                                            key={index}
                                          >
                                            <TableCell
                                              style={{
                                                paddingLeft: "24px",
                                                flex: 1,
                                              }}
                                            >
                                              {(item.DepositDate !== null &&
                                                moment(item.DepositDate).format(
                                                  "DD/MM/YYYY"
                                                )) ||
                                                ""}
                                            </TableCell>
                                            <TableCell style={{ flex: 1 }}>
                                              {currency?.currencyCode}
                                            </TableCell>
                                            <TableCell style={{ flex: 1 }}>
                                              {item.TotalAdvance}
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                paddingRight: "24px",
                                                flex: 1,
                                              }}
                                            >
                                              {item.AdvanceType}
                                            </TableCell>
                                          </Box>{" "}
                                        </TableRow>
                                      )}
                                    </>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  <TableContainer
                    // className={classes.tableContainer}
                    style={{
                      border: "1px solid #979797",
                      background: "#fff",
                      marginTop: "10px",
                      boxSizing: "border-box",
                    }}
                  >
                    <Table
                      // className={classes.table}
                      aria-labelledby="tableTitle"
                      size="medium"
                      // size={dense ? "small" : "medium"}
                      aria-label="enhanced table"
                    >
                      <TableHead className={classes.header}>
                        <TableRow>
                          <TableCell
                            className={classes.headerName}
                            style={{
                              paddingLeft: "24px",
                              // width:
                              //   window.screen.availWidth == 1920
                              //     ? "640px"
                              //     : "450px",
                              maxWidth: "450px",
                              minWidth: "350px",
                              width: "425px",
                              color: "#000",
                            }}
                          >
                            Expense Category
                          </TableCell>
                          <TableCell
                            className={classes.headerName}
                            style={{ minWidth: "100px", color: "#000" }}
                          >
                            Invoice Date
                          </TableCell>
                          <TableCell
                            className={classes.headerName}
                            style={{ minWidth: "90px", color: "#000" }}
                            id="cell1ref"
                          >
                            Amount
                          </TableCell>
                          {((myExpenseList?.ExpFinalStatus == "Approved" ||
                            myExpenseList?.ExpFinalStatus == "Rejected") &&
                            (location?.state?.compName == "myExpenseListing" ||
                              location?.state?.compName ==
                                "teamApprovalListing")) ||
                          location?.state?.compName ==
                            "financeApprovalListing" ? (
                            <TableCell
                              className={classes.headerName}
                              style={{
                                minWidth: "140px",
                                color: "#000",

                                // width:
                                //   window.screen.availWidth == 1920
                                //     ? "290px"
                                //     : "170px",
                              }}
                              id="cell2ref"
                            >
                              Approved Amount
                            </TableCell>
                          ) : (
                            <></>
                          )}
                          <TableCell
                            className={classes.headerName}
                            style={{
                              width: "300px",
                              minWidth: "300px",
                              color: "#000",
                            }}
                          >
                            Receipts
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {myExpenseList?.ExpenseDetail?.map((cat, index) => {
                          return (
                            <TableRow
                              key={index}
                              style={{ verticalAlign: "top" }}
                            >
                              <TableCell style={{ paddingLeft: "24px" }}>
                                {cat.CategoryName}
                              </TableCell>
                              <TableCell>
                                {" "}
                                {moment(cat.ReceiptDate).format("DD/MM/YYYY")}
                              </TableCell>
                              <TableCell>
                                {currency?.currency}
                                {cat.ClaimedAmount.toString().replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                                <Typography
                                  style={{ fontSize: "12px", marginTop: "5px" }}
                                >
                                  {cat.MethodName}
                                </Typography>
                              </TableCell>

                              {myExpenseList?.ExpFinalStatus == "Approved" ||
                              myExpenseList?.ExpFinalStatus == "Rejected" ? (
                                location?.state?.compName ==
                                  "myExpenseListing" ||
                                location?.state?.compName ==
                                  "teamApprovalListing" ? (
                                  // myExpenseList?.ExpenseDetail[0]
                                  //   ?.ApproverLevel1Flag == 1 ? (
                                  <TableCell align="left">
                                    <Typography
                                      className={classes.expenseId}
                                      style={{ paddingTop: "0px" }}
                                    >
                                      {" "}
                                      {myExpenseList?.ExpenseDetail[0]
                                        ?.CurrencyChanged == 1 ? (
                                        <Tooltip title="Default currency has been changed.">
                                          <span style={{ color: "red" }}>
                                            {
                                              myExpenseList?.ExpenseDetail[0]
                                                ?.CurrencySymbol
                                            }
                                            {myExpenseList?.ExpenseDetail[
                                              index
                                            ]?.ApprovedAmount.toString().replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )}
                                          </span>
                                        </Tooltip>
                                      ) : (
                                        `${
                                          myExpenseList?.ExpenseDetail[0]
                                            ?.CurrencySymbol
                                        }${myExpenseList?.ExpenseDetail[
                                          index
                                        ]?.ApprovedAmount.toString().replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )}`
                                      )}
                                    </Typography>
                                  </TableCell>
                                ) : (
                                  // ) : (
                                  //   ""
                                  // )
                                  ""
                                )
                              ) : (
                                ""
                              )}
                              {/* {(myExpenseList?.ExpFinalStatus == "Approved" ||
                                myExpenseList?.ExpFinalStatus == "Rejected") &&
                              (location?.state?.compName ==
                                "myExpenseListing" ||
                                location?.state?.compName ==
                                  "teamApprovalListing") &&
                              myExpenseList?.ExpenseDetail[0]
                                ?.ApproverLevel1Flag == 1 ? (
                                <TableCell align="left">
                                  <Typography className={classes.expenseId}>
                                    {" "}
                                    {`${myExpenseList?.ExpenseDetail[0]?.ApproverLevel2Comment}`}
                                  </Typography>
                                </TableCell>
                              ) : (
                                ""
                              )} */}
                              {location?.state?.compName ==
                              "financeApprovalListing" ? (
                                <TableCell align="left">
                                  <NumericFormat
                                    decimalScale={2}
                                    inputProps={{ maxLength: 14 }}
                                    className={classes.financeField}
                                    id={`approved-amount-${index}`}
                                    // label="Approved amount"
                                    onKeyPress={preventMinus}
                                    variant="outlined"
                                    defaultValue={cat.ClaimedAmount.toString().replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                    thousandSeparator={true}
                                    customInput={TextField}
                                    error={
                                      travelFieldValue[index]?.ApprovedAmount >
                                      cat?.ClaimedAmount
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      setTravelTotalApproved(
                                        Number(
                                          Number(travelTotalApproved) -
                                            Number(
                                              travelFieldValue[index]
                                                .ApprovedAmount
                                            ) +
                                            Number(
                                              e.target.value.replaceAll(",", "")
                                            )
                                        )?.toFixed(2)
                                      );

                                      setActValue((prevVal) =>
                                        prevVal.map((item) =>
                                          item.PaymentMethodId ==
                                          cat.PaymentMethodId
                                            ? {
                                                ...item,
                                                ActualsTypeAppAmt: Number(
                                                  Number(
                                                    item.ActualsTypeAppAmt
                                                  ) -
                                                    Number(
                                                      travelFieldValue[index]
                                                        .ApprovedAmount
                                                    ) +
                                                    Number(
                                                      e.target.value.replaceAll(
                                                        ",",
                                                        ""
                                                      )
                                                    )
                                                ),
                                              }
                                            : item
                                        )
                                      );

                                      setTravelFieldValue((prevVal) =>
                                        prevVal.map((item) =>
                                          item.ExpId == cat.ExpId
                                            ? {
                                                ...item,
                                                ApprovedAmount: Number(
                                                  e.target.value.replaceAll(
                                                    ",",
                                                    ""
                                                  )
                                                ),
                                                ProjAmount:
                                                  Number(
                                                    e.target.value.replaceAll(
                                                      ",",
                                                      ""
                                                    )
                                                  ) != cat?.ClaimedAmount
                                                    ? cat?.ClaimedAmount
                                                    : 0,
                                              }
                                            : item
                                        )
                                      );
                                    }}
                                  />
                                  {/* {console.log(
                                    "travelFieldValue",
                                    travelFieldValue
                                  )} */}

                                  {travelFieldValue[index]?.ApprovedAmount >
                                  cat?.ClaimedAmount ? (
                                    <span
                                      style={{
                                        color: "#E02020",
                                        display: "flex",
                                        flexDirection: "column",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Amount must be equal to or less than Claim
                                      Amount.
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                              ) : (
                                ""
                              )}

                              <TableCell
                              // style={{ width: "320px", minWidth: "320px" }}
                              >
                                {/* Receipt */}
                                {myExpenseList?.attachements?.map(
                                  (attach, index) => {
                                    // {
                                    //   console.log(
                                    //     "expense detail data attach",
                                    //     attach
                                    //   );
                                    // }
                                    return (
                                      attach.ExpId == cat.ExpId && (
                                        <Box
                                          style={{
                                            display: "flex",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          <Box
                                            style={{
                                              display: "flex",
                                              backgroundColor: "#F0F6FB",
                                              width: "240px",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Typography
                                              style={{
                                                marginRight: "10px",
                                                display: "flex",
                                              }}
                                            >
                                              {attach.FileName.includes(
                                                ".pdf"
                                              ) ? (
                                                <InsertDriveFileIcon
                                                  style={{
                                                    color: "#0B1941",
                                                  }}
                                                />
                                              ) : (
                                                <PhotoSizeSelectActualIcon
                                                  style={{
                                                    color: "#0B1941",
                                                  }}
                                                />
                                              )}
                                            </Typography>
                                            <Typography
                                              className={classes.fileName}
                                              style={{
                                                width: "200px",
                                                fontSize: "14px",
                                              }}
                                            >
                                              {attach.FileName}
                                            </Typography>
                                          </Box>
                                          <Typography
                                            style={{
                                              //  cursor: 'pointer',
                                              marginLeft: "20px",
                                            }}
                                            onClick={() => {
                                              handleDownload(
                                                myExpenseList,
                                                attach.FileName
                                              );
                                            }}
                                          >
                                            <GetAppOutlinedIcon />
                                          </Typography>
                                        </Box>
                                      )
                                    );
                                  }
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Grid
                    style={{
                      padding: "1rem",
                      border: "1px solid #979797",
                      background: "#fff",
                      borderTop: "0px",
                      paddingLeft: "24px",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                      }}
                    >
                      {/* <Grid
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width:
                            location?.state?.compName ==
                              "financeApprovalListing" ||
                            myExpenseList?.ExpFinalStatus == "Approved" ||
                            myExpenseList?.ExpFinalStatus == "Rejected"
                              ? window.screen.availWidth == 1280
                                ? "410px"
                                : window.screen.availWidth == 1536
                                ? "239px"
                                : "592px"
                              : window.screen.availWidth == 1280
                              ? "530px"
                              : window.screen.availWidth == 1536
                              ? "239px"
                              : "858px",
                          justifyContent: "start",
                        }}
                      >
                        <Typography
                          style={{
                            fontWeight: "600",
                            fontSize: "14px",
                            paddingRight: "10px",
                          }}
                        >
                          Advance Taken:
                        </Typography>
                        <Typography
                          style={{ fontWeight: "600", fontSize: "14px" }}
                        >
                          {currency?.currency}
                          {advSummaryJson?.AdvTotal}
                        </Typography>
                      </Grid> */}
                      <Typography
                        style={{
                          fontWeight: "600",
                          fontSize: "14px",
                          width: "130px",
                        }}
                      >
                        Total Amount :{" "}
                      </Typography>
                      {/* <Typography
                        className={
                          (location?.state?.compName == "teamApprovalListing" ||
                            location?.state?.compName ==
                              "financeApprovalListing") &&
                          myExpenseList?.ProjectDetails[0]?.ProjectId ==
                            location.state.response.projectId
                            ? classes.textConditionalSplitAmount
                            : classes.expenseIdBillable
                        }
                        style={{ padding: "0px" }}
                      > */}
                      {myExpenseList?.ExpFinalStatus == "Approved" ||
                      myExpenseList?.ExpFinalStatus == "Rejected" ? (
                        // show total for approved/reject

                        <>
                          <Box
                            style={{
                              width: `${cell1tarwidth}px`,
                              // minWidth:
                              //   window.screen.availWidth == 1280
                              //     ? "133px"
                              //     : window.screen.availWidth == 1536
                              //     ? "239px"
                              //     : "235px",
                            }}
                          >
                            <Typography
                              className={
                                (location?.state?.compName ==
                                  "teamApprovalListing" ||
                                  location?.state?.compName ==
                                    "financeApprovalListing") &&
                                myExpenseList?.ProjectDetails[0]?.ProjectId ==
                                  location.state.response.projectId
                                  ? classes.textConditionalSplitAmount
                                  : classes.expenseId
                              }
                              style={{ paddingTop: "0px", fontSize: "14px" }}
                            >
                              {currency?.currency}
                              {myExpenseList?.totalamount?.ClaimedAmount?.toFixed(
                                2
                              )
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Typography>
                          </Box>{" "}
                          <>
                            {/* {myExpenseList?.ProjectDetails[0]?.ApprovedAmount !=
                            null ? ( */}
                            {/* print Approved amount */}
                            {/* {travelTotalApproved !=
                            myExpenseList?.totalamount?.ClaimedAmount ? ( */}
                            <Box
                              style={{
                                width: `${cell2tarwidth}px`,
                                // width:
                                //   window.screen.availWidth == 1280
                                //     ? "255px"
                                //     : window.screen.availWidth == 1536
                                //     ? "268px"
                                //     : "385px",
                              }}
                            >
                              {/* <Typography
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    lineHeight: "10px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                  }}
                                >
                                  <del>
                                    {
                                      myExpenseList?.ProjectDetails[0]
                                        ?.CurrencySymbol
                                    }
                                    {myExpenseList?.totalamount?.ClaimedAmount?.toFixed(
                                      2
                                    )
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </del>
                                </Typography> */}
                              <Typography
                                style={{
                                  fontFamily: "Montserrat",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  lineHeight: "24px",
                                  letterSpacing: "0px",
                                  textAlign: "left",
                                }}
                              >
                                {currency?.currency}
                                {travelTotalApproved
                                  ?.toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </Typography>
                            </Box>
                            {/* ) : (
                              <Box
                                style={{
                                  width: `${cell2tarwidth}px`,
                                  // width:
                                  //   window.screen.availWidth == 1280
                                  //     ? "255px"
                                  //     : window.screen.availWidth == 1536
                                  //     ? "268px"
                                  //     : "385px",
                                }}
                              >
                                <Typography
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                    lineHeight: "24px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                  }}
                                >
                                  {
                                    myExpenseList?.ProjectDetails[0]
                                      ?.CurrencySymbol
                                  }
                                  {myExpenseList?.totalamount?.ClaimedAmount?.toFixed(
                                    2
                                  )
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </Typography>
                              </Box>
                            )} */}
                            {/* ) : myExpenseList?.ExpenseDetail[0]
                                ?.ApproverLevel2Flag ? (
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  width: "249px",
                                }}
                              >
                                {
                                  myExpenseList?.ProjectDetails[0]
                                    ?.CurrencySymbol
                                }
                                {myExpenseList?.totalamount?.ClaimedAmount.toString().replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                              </Typography>
                            ) : (
                              <Typography>showing some errror</Typography>
                            )
                            } */}
                            {/* </Typography> */}
                          </>
                        </>
                      ) : (
                        // else without approved/rejected
                        <>
                          <Box
                            style={{
                              width: `${cell1tarwidth}px`,
                              // minWidth:
                              //   window.screen.availWidth == 1280
                              //     ? location?.state?.compName ==
                              //       "financeApprovalListing"
                              //       ? "135px"
                              //       : "280px"
                              //     : window.screen.availWidth == 1536
                              //     ? location?.state?.compName ==
                              //       "financeApprovalListing"
                              //       ? "245px"
                              //       : "406px"
                              //     : location?.state?.compName ==
                              //       "financeApprovalListing"
                              //     ? "237px"
                              //     : "458px",
                            }}
                          >
                            <Typography
                              className={
                                (location?.state?.compName ==
                                  "teamApprovalListing" ||
                                  location?.state?.compName ==
                                    "financeApprovalListing") &&
                                myExpenseList?.ProjectDetails[0]?.ProjectId ==
                                  location.state.response.projectId
                                  ? classes.textConditionalSplitAmount
                                  : classes.expenseId
                              }
                              style={{ paddingTop: "0px", fontSize: "14px" }}
                            >
                              {currency?.currency}
                              {myExpenseList?.totalamount?.ClaimedAmount.toFixed(
                                2
                              )
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Typography>
                          </Box>
                          {location?.state?.compName ==
                            "financeApprovalListing" && (
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                width: `${cell2tarwidth}px`,
                                paddingTop: "0px",
                                // width:
                                //   window.screen.availWidth == 1280
                                //     ? "247px"
                                //     : window.screen.availWidth == 1536
                                //     ? "262px"
                                //     : "382px",
                              }}
                              className={classes.textConditionalSplitAmount}
                            >
                              {currency?.currency}
                              {travelTotalApproved
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Typography>
                          )}
                          {/* <>
                            {myExpenseList?.ProjectDetails[0]?.ApprovedAmount !=
                            null ? (
                              //print Approved amount
                              myExpenseList?.ProjectDetails[0]
                                ?.ApprovedAmount !=
                              myExpenseList?.ProjectDetails[0]?.ProjAmount ? (
                                <Box
                                  style={{
                                    width:
                                      window.screen.availWidth == 1280
                                        ? "255px"
                                        : window.screen.availWidth == 1536
                                        ? "268px"
                                        : "290px",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontSize: "12px",
                                      fontWeight: 500,
                                      lineHeight: "10px",
                                      letterSpacing: "0px",
                                      textAlign: "left",
                                    }}
                                  >
                                    <del>
                                      {
                                        myExpenseList?.ProjectDetails[0]
                                          ?.CurrencySymbol
                                      }
                                      {myExpenseList?.ProjectDetails[0]?.ProjAmount.toString().replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                    </del>
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontSize: "14px",
                                      fontWeight: 600,
                                      lineHeight: "24px",
                                      letterSpacing: "0px",
                                      textAlign: "left",
                                    }}
                                  >
                                    {
                                      myExpenseList?.ProjectDetails[0]
                                        ?.CurrencySymbol
                                    }
                                    {myExpenseList?.ProjectDetails[0]?.ApprovedAmount.toString().replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </Typography>
                                </Box>
                              ) : (
                                <Box
                                  style={{
                                    width:
                                      window.screen.availWidth == 1280
                                        ? "255px"
                                        : window.screen.availWidth == 1536
                                        ? "268px"
                                        : "290px",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontSize: "14px",
                                      fontWeight: 600,
                                      lineHeight: "24px",
                                      letterSpacing: "0px",
                                      textAlign: "left",
                                    }}
                                  >
                                    {
                                      myExpenseList?.ProjectDetails[0]
                                        ?.CurrencySymbol
                                    }
                                    {myExpenseList?.ProjectDetails[0]?.ApprovedAmount.toString().replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </Typography>
                                </Box>
                              )
                            ) : (
                              myExpenseList?.ExpenseDetail[0]
                                ?.ApproverLevel2Flag==1 && (
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    width: "247px",
                                  }}
                                >
                                  {
                                    myExpenseList?.ProjectDetails[0]
                                      ?.CurrencySymbol
                                  }
                                  {approvedAmount}
                                </Typography>
                              )
                            )}
                          </> */}
                        </>
                      )}
                      {/* <Box
                      // style={{
                      //   minWidth:
                      //     ((myExpenseList?.ExpFinalStatus == "Approved" ||
                      //       myExpenseList?.ExpFinalStatus == "Rejected") &&
                      //       (location?.state?.compName ==
                      //         "myExpenseListing" ||
                      //         location?.state?.compName ==
                      //           "teamApprovalListing")) ||
                      //     location?.state?.compName ==
                      //       "financeApprovalListing"
                      //       ? (window.screen.availWidth == 1280
                      //       ? "135px"
                      //       : window.screen.availWidth == 1536
                      //       ? "239px":"300px")
                      //       : "278px"
                      // }}
                      >
                        <Typography
                          className={
                            (location?.state?.compName ==
                              "teamApprovalListing" ||
                              location?.state?.compName ==
                                "financeApprovalListing") &&
                            myExpenseList?.ProjectDetails[0]?.ProjectId ==
                              location.state.response.projectId
                              ? classes.textConditionalSplitAmount
                              : classes.expenseId
                          }
                          style={{ paddingTop: "0px", fontSize: "14px" }}
                        >
                          {myExpenseList?.ProjectDetails[0]?.CurrencySymbol}
                          {myExpenseList?.ProjectDetails[0]?.ProjAmount.toString().replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          )}
                        </Typography>
                      </Box>{" "} */}
                      {/* {myExpenseList?.ProjectDetails[0]?.ApprovedAmount !=
                        null ? (
                          //print Approved amount
                          myExpenseList?.ProjectDetails[0]?.ApprovedAmount !=
                          myExpenseList?.ProjectDetails[0]?.ProjAmount ? (
                            <Box style={{ width: "136px" }}>
                              <Typography
                                style={{
                                  fontFamily: "Montserrat",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  lineHeight: "10px",
                                  letterSpacing: "0px",
                                  textAlign: "left",
                                }}
                              >
                                <del>
                                  {
                                    myExpenseList?.ProjectDetails[0]
                                      ?.CurrencySymbol
                                  }
                                  {myExpenseList?.ProjectDetails[0]?.ProjAmount.toString().replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                                </del>
                              </Typography>
                              <Typography
                                style={{
                                  fontFamily: "Montserrat",
                                  fontSize: "16px",
                                  fontWeight: 500,
                                  lineHeight: "24px",
                                  letterSpacing: "0px",
                                  textAlign: "left",
                                }}
                              >
                                {
                                  myExpenseList?.ProjectDetails[0]
                                    ?.CurrencySymbol
                                }
                                {myExpenseList?.ProjectDetails[0]?.ApprovedAmount.toString().replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                              </Typography>
                            </Box>
                          ) : (
                            <Box style={{ minWidth: "100px" }}>
                              <Typography
                                style={{
                                  fontFamily: "Montserrat",
                                  fontSize: "16px",
                                  fontWeight: 500,
                                  lineHeight: "24px",
                                  letterSpacing: "0px",
                                  textAlign: "left",
                                }}
                              >
                                {
                                  myExpenseList?.ProjectDetails[0]
                                    ?.CurrencySymbol
                                }{" "}
                                {myExpenseList?.ProjectDetails[0]?.ApprovedAmount.toString().replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                              </Typography>
                            </Box>
                          )
                        ) : (
                          //print ProjAmount as earlier
                          <Box
                            style={{
                              minWidth:
                                ((myExpenseList?.ExpFinalStatus == "Approved" ||
                                  myExpenseList?.ExpFinalStatus ==
                                    "Rejected") &&
                                  (location?.state?.compName ==
                                    "myExpenseListing" ||
                                    location?.state?.compName ==
                                      "teamApprovalListing")) ||
                                location?.state?.compName ==
                                  "financeApprovalListing"
                                  ? "136px"
                                  : "278px",
                            }}
                          >
                            <Typography
                              className={
                                (location?.state?.compName ==
                                  "teamApprovalListing" ||
                                  location?.state?.compName ==
                                    "financeApprovalListing") &&
                                myExpenseList?.ProjectDetails[0]?.ProjectId ==
                                  location.state.response.projectId
                                  ? classes.textConditionalSplitAmount
                                  : classes.expenseId
                              }
                              style={{ paddingTop: "0px", fontSize: "14px" }}
                            >
                              {myExpenseList?.ProjectDetails[0]?.CurrencySymbol}
                              {myExpenseList?.ProjectDetails[0]?.ProjAmount.toString().replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )}
                            </Typography>
                          </Box>
                        )} */}
                      {/* </Typography> */}
                      {/* approved amount  for finance */}
                      {/* {((myExpenseList?.ExpFinalStatus == "Approved" ||
                        myExpenseList?.ExpFinalStatus == "Rejected") &&
                        (location?.state?.compName == "myExpenseListing" ||
                          location?.state?.compName ==
                            "teamApprovalListing")) ||
                      location?.state?.compName == "financeApprovalListing" ? (
                        // <Typography
                        //   // className={classes.headerName}
                        //   style={{ width: "247px" }}
                        // >
                        <>
                          {myExpenseList?.ProjectDetails[0]?.ApprovedAmount !=
                          null ? (
                            //print Approved amount
                            myExpenseList?.ProjectDetails[0]?.ApprovedAmount !=
                            myExpenseList?.ProjectDetails[0]?.ProjAmount ? (
                              <Box
                                style={{
                                  width:
                                    window.screen.availWidth == 1280
                                      ? "255px"
                                      : window.screen.availWidth == 1536
                                      ? "268px"
                                      : "290px",
                                }}
                              >
                                <Typography
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    lineHeight: "10px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                  }}
                                >
                                  <del>
                                    {
                                      myExpenseList?.ProjectDetails[0]
                                        ?.CurrencySymbol
                                    }
                                    {myExpenseList?.ProjectDetails[0]?.ProjAmount.toString().replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </del>
                                </Typography>
                                <Typography
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                    lineHeight: "24px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                  }}
                                >
                                  {
                                    myExpenseList?.ProjectDetails[0]
                                      ?.CurrencySymbol
                                  }
                                  {myExpenseList?.ProjectDetails[0]?.ApprovedAmount.toString().replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                                </Typography>
                              </Box>
                            ) : (
                              <Box
                                style={{
                                  width:
                                    window.screen.availWidth == 1280
                                      ? "255px"
                                      : window.screen.availWidth == 1536
                                      ? "268px"
                                      : "290px",
                                }}
                              >
                                <Typography
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                    lineHeight: "24px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                  }}
                                >
                                  {
                                    myExpenseList?.ProjectDetails[0]
                                      ?.CurrencySymbol
                                  }
                                  {myExpenseList?.ProjectDetails[0]?.ApprovedAmount.toString().replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                                </Typography>
                              </Box>
                            )
                          ) : (
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                width: "247px",
                              }}
                            >
                              {myExpenseList?.ProjectDetails[0]?.CurrencySymbol}
                              {approvedAmount}
                            </Typography>
                          )}
                        </>
                      ) : (
                        <></>
                      )} */}

                      {/* Billable section */}
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "300px",
                          justifyContent: "end",
                        }}
                      >
                        <Typography
                          className={classes.expenseId}
                          style={{
                            backgroundColor: "#F0F6FB",
                            marginLeft: "20px",
                            marginRight: "20px",
                            width: "110px",
                            minHeight: "12px",
                            borderRadius: "2px",
                            display: "flex",
                            justifyContent: "center",
                            paddingBottom: "10px",
                            paddingTop: "10px",
                          }}
                        >
                          {" "}
                          {myExpenseList?.ProjectDetails != null
                            ? myExpenseList?.ProjectDetails[0]?.Billable == 1
                              ? "Billable"
                              : "Non-Billable"
                            : ""}
                        </Typography>
                        <Typography
                          className={classes.expenseId}
                          style={{ paddingTop: "0px", display: "flex" }}
                        >
                          {" "}
                          <img
                            style={{ height: "36px" }}
                            src={
                              myExpenseList?.ProjectDetails != null
                                ? myExpenseList?.ProjectDetails[0]?.ExpStatus ==
                                  "Approved"
                                  ? ApprovedIcon
                                  : myExpenseList?.ProjectDetails[0]
                                      ?.ExpStatus == "In Progress"
                                  ? InProgressIcon
                                  : myExpenseList?.ProjectDetails[0]
                                      ?.ExpStatus == "Rejected"
                                  ? RejectedIcon
                                  : null
                                : null
                            }
                          />
                        </Typography>
                        <Box style={{ width: "70px", display: "flex" }}>
                          {myExpenseList?.ProjectDetails != null
                            ? myExpenseList?.ProjectDetails[0]?.ExpStatus !=
                                "Draft" && (
                                <Tooltip
                                  title={handleToolTip(
                                    myExpenseList?.ProjectDetails[0]
                                  )}
                                  arrow
                                >
                                  <ErrorOutlineIcon
                                    className={classes.tooltip}
                                    style={{ marginTop: "0px" }}
                                  />
                                </Tooltip>
                              )
                            : ""}
                        </Box>
                      </Box>
                      {/* <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "300px",
                          justifyContent: "end",
                          paddingRight: "8px",
                          boxSizing: "border-box",
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            // paddingRight: "54px",
                            // width: "184px",
                          }}
                        >
                          <Typography
                            style={{
                              fontWeight: "600",
                              fontSize: "14px",
                              paddingRight: "10px",
                            }}
                          >
                            Balances:
                          </Typography>
                          <Typography
                            style={{ fontWeight: "600", fontSize: "14px" }}
                          >
                            {currency?.currency}
                            {advSummaryJson?.AdvTotal - travelTotalApproved}
                          </Typography>
                        </Box>
                        <Box style={{ display: "flex" }}>
                          {myExpenseList?.ProjectDetails[0]?.ExpStatus !=
                            "Draft" && (
                            <Tooltip title={"amount to be payable"} arrow>
                              <ErrorOutlineIcon
                                className={classes.tooltip}
                                style={{ marginTop: "0px" }}
                              />
                            </Tooltip>
                          )}
                        </Box>
                      </Box> */}
                    </Box>
                  </Grid>
                  {/* Summary Section */}
                  <>
                    <Box
                      style={{
                        //marginTop: "16px",
                        border: "1px solid #979797",
                        background: "#fff",
                        height: "100%",
                        padding: "0px 0px 10px 0px",
                        marginTop: "10px",
                      }}
                    >
                      <Grid
                        style={{
                          background: "#F4F4F4",
                          maxWidth: "100%", //1126
                          maxHeight: "58px",
                          padding: "16px 24px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          paddingRight: "100px",
                          borderBottom: "1px solid #e0e0e0",
                        }}
                      >
                        <Typography
                          style={{
                            // borderRadius: "8px 8px 0px 0px",

                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          Summary
                        </Typography>
                        <Grid style={{ display: "flex" }}>
                          <Typography style={{ fontWeight: "600" }}>
                            {Number(totalAdv) -
                              (myExpenseList?.ExpFinalStatus === "Rejected"
                                ? Number(
                                    myExpenseList?.totalamount?.ClaimedAmount
                                  )
                                : Number(travelTotalApproved)) -
                              Number(advSummaryJson?.RefundReceived) >
                            0
                              ? "*Net Payable :"
                              : "*Net Receivable :"}{" "}
                          </Typography>
                          <Typography
                            align="right"
                            style={{ fontWeight: "600", paddingLeft: "10px" }}
                          >
                            {currency?.currency}
                            {Math.abs(
                              Number(totalAdv) > 0
                                ? Number(totalAdv) -
                                    (myExpenseList?.ExpFinalStatus ===
                                    "Rejected"
                                      ? Number(
                                          myExpenseList?.totalamount
                                            ?.ClaimedAmount
                                        )
                                      : Number(travelTotalApproved)) -
                                    Number(advSummaryJson?.RefundReceived)
                                : (myExpenseList?.ExpFinalStatus === "Rejected"
                                    ? Number(
                                        myExpenseList?.totalamount
                                          ?.ClaimedAmount
                                      )
                                    : Number(travelTotalApproved)) -
                                    Number(advSummaryJson?.RefundReceived)
                            )
                              .toFixed(2)
                              ?.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "0.00"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          paddingRight: "100px",
                          paddingTop: "10px",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          (Claimed Amount)
                        </Typography>
                        {(location?.state?.compName ==
                          "financeApprovalListing" ||
                          myExpenseList?.ExpFinalStatus == "Rejected" ||
                          myExpenseList?.ExpFinalStatus == "Approved") && (
                          <Typography
                            style={{
                              paddingLeft: "10px",
                              width: "140px",
                              textAlign: "right",
                              fontSize: "13px",
                            }}
                          >
                            (Approved Amount)
                          </Typography>
                        )}
                      </Grid>

                      <Grid
                        container
                        // style={{ padding: "10px 0px 0px 16px" }}
                      >
                        <Grid item xs={6} className={classes.summaryField}>
                          <strong>Advances</strong>
                          <strong>
                            <Box>
                              {currency?.currency}
                              {totalAdv
                                ?.toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                                "0.00"}
                            </Box>
                          </strong>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          className={classes.summaryField}
                          // style={{ paddingLeft: "0px" }}
                        >
                          <strong>Actuals</strong>
                          <Grid style={{ display: "flex" }}>
                            <Box>
                              <strong>
                                {currency?.currency}
                                {totalAct
                                  ?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </strong>
                            </Box>
                            {(location?.state?.compName ==
                              "financeApprovalListing" ||
                              myExpenseList?.ExpFinalStatus == "Rejected" ||
                              myExpenseList?.ExpFinalStatus == "Approved") && (
                              <Box
                                style={{
                                  paddingLeft: "10px",
                                  width: "140px",
                                  textAlign: "right",
                                }}
                              >
                                <strong>
                                  {currency?.currency}
                                  {travelTotalApproved
                                    ?.toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </strong>
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                        {/* {console.log("travel")} */}
                        <Grid className={classes.summaryField} item xs={6}>
                          <Box className={classes.headerName}>Travel Card</Box>
                          <Box>
                            {currency?.currency}
                            {advValue
                              ?.filter(
                                (item) => item.AdvanceType == "Travel Card"
                              )[0]
                              ?.AdvancesTypeAmt.toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "0.00"}
                            {/* {advSummaryJson?.AdvTravelCard?.toString().replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )} */}
                          </Box>
                        </Grid>
                        <Grid
                          className={classes.summaryField}
                          // style={{
                          //   paddingLeft: "0px",
                          // }}
                          item
                          xs={6}
                        >
                          <Box className={classes.headerName}>Travel Card</Box>
                          <Grid style={{ display: "flex" }}>
                            <Box>
                              {currency?.currency}
                              {myExpenseList?.ActualDetails?.filter(
                                (item) => item.PaymentMethodId == 3
                              )[0]
                                ?.ActualsTypeAmt?.toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                                "0.00"}
                              {/* {advSummaryJson?.ActTravelCard?.toString().replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )} */}
                            </Box>
                            {(location?.state?.compName ==
                              "financeApprovalListing" ||
                              myExpenseList?.ExpFinalStatus == "Rejected" ||
                              myExpenseList?.ExpFinalStatus == "Approved") && (
                              <Box
                                style={{
                                  paddingLeft: "10px",
                                  width: "140px",
                                  textAlign: "right",
                                }}
                              >
                                {currency?.currency}
                                {actValue
                                  ?.filter(
                                    (item) => item.PaymentMethodId == 3
                                  )[0]
                                  ?.ActualsTypeAppAmt?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                                  "0.00"}
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                        <Grid className={classes.summaryField} item xs={6}>
                          <Box className={classes.headerName}>Cash</Box>
                          <Box>
                            {currency?.currency}
                            {advValue
                              ?.filter((item) => item.AdvanceType == "Cash")[0]
                              ?.AdvancesTypeAmt?.toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "0.00"}
                            {/* {advSummaryJson?.AdvCash?.toString().replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )} */}
                          </Box>
                        </Grid>
                        <Grid
                          className={classes.summaryField}
                          // style={{
                          //   paddingLeft: "0px",
                          // }}
                          item
                          xs={6}
                        >
                          <Box className={classes.headerName}>Cash</Box>
                          <Grid style={{ display: "flex" }}>
                            <Box>
                              {currency?.currency}
                              {myExpenseList?.ActualDetails?.filter(
                                (item) => item.PaymentMethodId == 35
                              )[0]
                                ?.ActualsTypeAmt?.toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                                "0.00"}
                            </Box>
                            {(location?.state?.compName ==
                              "financeApprovalListing" ||
                              myExpenseList?.ExpFinalStatus == "Rejected" ||
                              myExpenseList?.ExpFinalStatus == "Approved") && (
                              <Box
                                style={{
                                  paddingLeft: "10px",
                                  width: "140px",
                                  textAlign: "right",
                                }}
                              >
                                {currency?.currency}
                                {actValue
                                  ?.filter(
                                    (item) => item.PaymentMethodId == 35
                                  )[0]
                                  ?.ActualsTypeAppAmt?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                                  "0.00"}
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          style={{
                            display: "flex",
                            direction: "row",
                            justifyContent: "space-between",
                            paddingRight: "150px",
                          }}
                          item
                          xs={6}
                        ></Grid>
                        <Grid
                          className={classes.summaryField}
                          // style={{
                          //   paddingLeft: "0px",
                          // }}
                          item
                          xs={6}
                        >
                          <Box className={classes.headerName}>Self</Box>
                          <Grid style={{ display: "flex" }}>
                            <Box>
                              {currency?.currency}
                              {myExpenseList?.ActualDetails?.filter(
                                (item) => item.PaymentMethodId == 34
                              )[0]
                                ?.ActualsTypeAmt?.toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                                "0.00"}
                            </Box>
                            {(location?.state?.compName ==
                              "financeApprovalListing" ||
                              myExpenseList?.ExpFinalStatus == "Rejected" ||
                              myExpenseList?.ExpFinalStatus == "Approved") && (
                              <Box
                                style={{
                                  paddingLeft: "10px",
                                  width: "140px",
                                  textAlign: "right",
                                }}
                              >
                                {currency?.currency}
                                {actValue
                                  ?.filter(
                                    (item) => item.PaymentMethodId == 34
                                  )[0]
                                  ?.ActualsTypeAppAmt?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                                  "0.00"}
                              </Box>
                            )}
                          </Grid>

                          {/* <NumericFormat
                            id="outlined-basic"
                            // label="Self"
                            onKeyPress={preventMinus}
                            variant="outlined"
                            defaultValue={0}
                            className={classes.advancesField}
                            // defaultValue={cat.ClaimedAmount.toString().replace(
                            //   /\B(?=(\d{3})+(?!\d))/g,
                            //   ","
                            // )}
                            // value="0"
                            customInput={TextField}
                            // error={
                            //   travelFieldValue[index]?.ApprovedAmount >
                            //   cat?.ClaimedAmount
                            //     ? true
                            //     : false
                            // }
                            onChange={(e) => {
                              // console.log("previous value", e);
                              // // const prev=
                              // setTravelTotalApproved(
                              //   travelTotalApproved -
                              //     travelFieldValue[index].ApprovedAmount +
                              //     Number(e.target.value)
                              // );
                              // // const arr = [...travelFieldValue];
                              // // arr[cat.ExpId] = Number(e.target.value);
                              // // setTravelFieldValue(arr);
                              // setTravelFieldValue((prevVal) =>
                              //   prevVal.map((item) =>
                              //     item.ExpId == cat.ExpId
                              //       ? {
                              //           ...item,
                              //           ApprovedAmount: Number(e.target.value),
                              //         }
                              //       : item
                              //   )
                              // );
                              // return (setTravelTotalApproved(()=>e.target.value));
                              // setApprovedAmount(
                              //   () =>
                              //     approvedAmount +
                              //     Number(e.target.value)
                              // )
                            }}
                          /> */}
                          {/* {console.log("travelFieldValue", travelFieldValue)} */}

                          {/* {travelFieldValue[index]?.ApprovedAmount >
                          cat?.ClaimedAmount ? (
                            <span
                              style={{
                                color: "#E02020",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              Amount must be equal to or less than Claim Amount.
                            </span>
                          ) : (
                            ""
                          )} */}
                          {/* <Box>
                            {currency.currency}
                            {actualsSelf || 0}
                          </Box> */}
                        </Grid>
                        <Divider
                          style={{
                            width: "100%",
                            height: "1px",
                            paddingTop: "4px",
                          }}
                        />
                        <Grid item xs={12}>
                          <Box style={{ padding: "12px 24px 26px" }}>
                            <Typography style={{ fontSize: "14px" }}>
                              <u>To be filled by finance</u>
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid container style={{ display: "block" }}>
                          {/* <Grid
                            item
                            xs={6}
                            style={{
                              padding: "8px 24px",
                              display: "flex",
                              justifyContent: "space-between",
                              paddingRight: "100px",
                            }}
                          >
                            <strong>Per Diem</strong>
                            <strong>
                              <Box>
                                {currency?.currency}0
                                {Number(advSummaryJson?.RefundReceived)
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </Box>
                            </strong>
                          </Grid> */}
                          <Grid
                            item
                            xs={6}
                            style={{
                              padding: "8px 24px",
                              display: "flex",
                              justifyContent: "space-between",
                              paddingRight: "100px",
                            }}
                          >
                            <strong>Refund Received</strong>
                            <strong>
                              {/* {console.log("refund travel1", advSummaryJson)} */}
                              <Box>
                                {currency?.currency}
                                {Number(advSummaryJson?.RefundReceived)
                                  ?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                                  "0.00"}
                              </Box>
                            </strong>
                          </Grid>
                          {/* <Grid
                            className={classes.summaryField}
                            // style={{
                            //   display: "flex",
                            //   alignItems: "center",
                            //   padding: "8px 24px",
                            //   justifyContent: "space-between",
                            //   paddingRight: " 260px",
                            // }}
                            item
                            xs={6}
                          >
                            <Box className={classes.headerName}>
                              Per Diem with Bills- Non Taxable
                            </Box>
                            {location?.state?.compName ==
                            "financeApprovalListing" ? (
                              <NumericFormat
                                id="outlined-basic-per-diem-non-taxable"
                                // label="Per Diem - Non Taxable"
                                onKeyPress={preventMinus}
                                variant="outlined"
                                defaultValue={0}
                                className={classes.advancesField}
                                customInput={TextField}
                                onChange={(e) => {}}
                              />
                            ) : (
                              <>
                                {currency?.currency}0
                                {Number(advSummaryJson?.RefundReceived)
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </>
                            )}
                          </Grid> */}

                          <Grid
                            // style={{
                            //   display: "flex",
                            //   alignItems: "center",
                            //   padding: "8px 0px",
                            // }}
                            className={classes.summaryField}
                            style={{
                              // paddingLeft: "0px",
                              paddingRight: "100px",
                            }}
                            item
                            xs={6}
                          >
                            <Box className={classes.headerName}>
                              Travel Card Balance
                            </Box>
                            {location?.state?.compName ==
                            "financeApprovalListing" ? (
                              <NumericFormat
                                id="outlined-basic-travel-card"
                                // label="Per Diem - Taxable"
                                onKeyPress={preventMinus}
                                variant="outlined"
                                defaultValue={0}
                                inputProps={{ maxLength: 12 }}
                                decimalScale={2}
                                className={classes.advancesField}
                                value={Number(advSummaryJson?.TravelCardBal)}
                                // defaultValue={cat.ClaimedAmount.toString().replace(
                                //   /\B(?=(\d{3})+(?!\d))/g,
                                //   ","
                                // )}
                                // value="0"
                                thousandSeparator={true}
                                customInput={TextField}
                                // error={
                                //   travelFieldValue[index]?.ApprovedAmount >
                                //   cat?.ClaimedAmount
                                //     ? true
                                //     : false
                                // }
                                onChange={(e) => {
                                  handleRefund(e, "Travel Card");
                                }}
                              />
                            ) : (
                              <>
                                {currency?.currency}
                                {Number(advSummaryJson?.TravelCardBal)
                                  ?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                                  "0.00"}
                              </>
                            )}
                          </Grid>
                          {/* <Grid
                            className={classes.summaryField}
                            // style={{
                            //   display: "flex",
                            //   alignItems: "center",
                            //   padding: "8px 24px",
                            //   justifyContent: "space-between",
                            //   paddingRight: " 260px",
                            // }}
                            item
                            xs={6}
                          >
                            <Box className={classes.headerName}>
                              Per Diem without Bills- Taxable
                            </Box>
                            {location?.state?.compName ==
                            "financeApprovalListing" ? (
                              <NumericFormat
                                id="outlined-basic-per-diem-taxable"
                                // label="Per Diem - Taxable"
                                onKeyPress={preventMinus}
                                variant="outlined"
                                defaultValue={0}
                                className={classes.advancesField}
                                // defaultValue={cat.ClaimedAmount.toString().replace(
                                //   /\B(?=(\d{3})+(?!\d))/g,
                                //   ","
                                // )}
                                // value="0"
                                customInput={TextField}
                                // error={
                                //   travelFieldValue[index]?.ApprovedAmount >
                                //   cat?.ClaimedAmount
                                //     ? true
                                //     : false
                                // }
                                onChange={(e) => {
                                  // console.log("previous value", e);
                                  // // const prev=
                                  // setTravelTotalApproved(
                                  //   travelTotalApproved -
                                  //     travelFieldValue[index].ApprovedAmount +
                                  //     Number(e.target.value)
                                  // );
                                  // // const arr = [...travelFieldValue];
                                  // // arr[cat.ExpId] = Number(e.target.value);
                                  // // setTravelFieldValue(arr);
                                  // setTravelFieldValue((prevVal) =>
                                  //   prevVal.map((item) =>
                                  //     item.ExpId == cat.ExpId
                                  //       ? {
                                  //           ...item,
                                  //           ApprovedAmount: Number(e.target.value),
                                  //         }
                                  //       : item
                                  //   )
                                  // );
                                  // return (setTravelTotalApproved(()=>e.target.value));
                                  // setApprovedAmount(
                                  //   () =>
                                  //     approvedAmount +
                                  //     Number(e.target.value)
                                  // )
                                }}
                              />
                            ) : (
                              <>
                                {currency?.currency}0
                                {Number(advSummaryJson?.TravelCardBal)
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </>
                            )}
                          </Grid> */}

                          <Grid
                            // style={{
                            //   display: "flex",
                            //   alignItems: "center",
                            //   padding: "8px 0px",
                            // }}
                            className={classes.summaryField}
                            style={{
                              // paddingLeft: "0px",
                              paddingRight: "100px",
                            }}
                            item
                            xs={6}
                          >
                            <Box className={classes.headerName}>
                              Cash Balance
                            </Box>
                            {location?.state?.compName ==
                            "financeApprovalListing" ? (
                              <NumericFormat
                                id="outlined-basic-cash-balance"
                                // label="Per Diem - Taxable"
                                onKeyPress={preventMinus}
                                variant="outlined"
                                defaultValue={0}
                                inputProps={{ maxLength: 12 }}
                                decimalScale={2}
                                className={classes.advancesField}
                                value={Number(advSummaryJson?.CashBal)}
                                // value={advSummaryJson?.CashBal}
                                // defaultValue={cat.ClaimedAmount.toString().replace(
                                //   /\B(?=(\d{3})+(?!\d))/g,
                                //   ","
                                // )}
                                // value="0"
                                thousandSeparator={true}
                                customInput={TextField}
                                // error={
                                //   travelFieldValue[index]?.ApprovedAmount >
                                //   cat?.ClaimedAmount
                                //     ? true
                                //     : false
                                // }
                                onChange={(e) => {
                                  handleRefund(e, "Cash");
                                }}
                              />
                            ) : (
                              <>
                                {currency?.currency}
                                {Number(advSummaryJson?.CashBal)
                                  ?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                                  "0.00"}
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        style={{
                          display: "flex",
                          padding: "16px 24px 4px 24px",
                        }}
                      >
                        <Grid item xs={6}>
                          <Box
                            style={{
                              color: "rgba(0, 0, 0, 0.7)",
                              fontSize: "14px",
                            }}
                          >
                            *Net Payable: To be refunded to the company.{" "}
                          </Box>
                        </Grid>
                        <Grid item xs={6} style={{ paddingLeft: "24px" }}>
                          <Box
                            style={{
                              color: "rgba(0, 0, 0, 0.7)",
                              fontSize: "14px",
                            }}
                          >
                            *Net Receivable: To be claimed from the company.{" "}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                </Box>
              </Box>
            )}
            {myExpenseList?.EmployeeDetails?.length > 0 && (
              <Box style={{ marginTop: "30px" }}>
                <Grid container spacing={3}>
                  <Grid item xs={6} md={7}>
                    <Paper
                      className={classes.attendeesPaper}
                      classes={{
                        root: classes.papaerRoot,
                        elevation1: classes.paperElivation,
                      }}
                    >
                      <Box style={{ marginLeft: "20px" }}>
                        <Box style={{ display: "flex" }}>
                          <Typography className={classes.attachmentHeading}>
                            {"Attendees Information "}
                          </Typography>
                          <Typography className={classes.nOfAttachment}>
                            {`(${myExpenseList?.EmployeeDetails?.length} Attendees)`}
                          </Typography>
                        </Box>
                        <Box style={{ marginTop: "20px" }}>
                          {myExpenseList?.EmployeeDetails?.map((value) => {
                            const colour = Math.random()
                              .toString(16)
                              .substr(-6);

                            return (
                              <>
                                <Chip
                                  avatar={
                                    <Avatar
                                      style={{
                                        backgroundColor: `#${colour}`,
                                        color: "white",
                                      }}
                                    >
                                      {handleName(value.EmpName)}
                                    </Avatar>
                                  }
                                  label={value.EmpName}
                                  style={{
                                    margin: 2,
                                    marginTop: "10px",
                                    marginBottom: "18px",
                                  }}
                                  className={classes.chip}
                                  clickable
                                />
                              </>
                            );
                          })}
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Paper
                      className={classes.attendeesPaper}
                      classes={{
                        root: classes.papaerRoot,
                        elevation1: classes.paperElivation,
                      }}
                    >
                      <Box style={{ marginLeft: "20px" }}>
                        <Box style={{ display: "flex" }}>
                          <Typography className={classes.attachmentHeading}>
                            {"Attachments"}
                          </Typography>
                          <Typography className={classes.nOfAttachment}>
                            {`(${myExpenseList?.attachements?.length} Attachments)`}
                          </Typography>
                        </Box>
                        {myExpenseList?.attachements?.length > 0 &&
                          myExpenseList?.attachements?.map((value) => {
                            return (
                              <Box
                                style={{
                                  display: "flex",
                                  marginTop: "20px",
                                  marginBottom: "20px",
                                }}
                              >
                                <Box
                                  style={{
                                    display: "flex",
                                    backgroundColor: "#F0F6FB",
                                    width: "380px",
                                    // overflowWrap: "anywhere"
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  <Typography style={{ marginRight: "10px" }}>
                                    {value.FileName.includes(".pdf") ? (
                                      <InsertDriveFileIcon
                                        style={{ color: "#0B1941" }}
                                      />
                                    ) : (
                                      <PhotoSizeSelectActualIcon
                                        style={{ color: "#0B1941" }}
                                      />
                                    )}
                                  </Typography>
                                  <Typography className={classes.fileName1}>
                                    {value.FileName}
                                  </Typography>
                                </Box>
                                <Typography
                                  style={{ marginLeft: "20px" }}
                                  onClick={() => {
                                    handleDownload(
                                      myExpenseList,
                                      value.FileName
                                    );
                                  }}
                                >
                                  <GetAppOutlinedIcon />
                                </Typography>
                              </Box>
                            );
                          })}
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </>
      )}
      <Snackbar
        open={systemErrorSnackbar}
        onClose={() => setSystemErrorSnackbar(false)}
      >
        <Alert
          onClose={() => setSystemErrorSnackbar(false)}
          severity="error"
          sx={{ width: "100%", backgroundColor: "red", color: "white" }}
        >
          There is some problem with the application. Please connect with System
          Admin.
        </Alert>
      </Snackbar>
    </>
  );
};

export default ViewDetails;
