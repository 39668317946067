import React from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import history from "../history";
import { useEffect } from "react";
import { Typography } from "@material-ui/core";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function DeleteSnackbar(props) {
  const classes = useStyles();
  //console.log("in snackbar, DS1 props.currentExpense?.expCode:==>");

  return (
    <div className={classes.root}>
      <Snackbar
        open={props.open}
        autoHideDuration={1500}
        onClose={props.handleCloseSnackbar}
      >
        <Alert onClose={props.handleCloseSnackbar} severity="success">
          {props.gmCalDash ? "Mobility Deleted Successfully !" : "Expense Deleted Successfully !"}
        </Alert>
      </Snackbar>
    </div>
  );
}
