export const FieldValidator = (
    validationType,
    value,
    type,
    validate,
    validate2
  ) => {
    let data = {};
    let result = null
    const handleEndDate = (date, startDate) => {
      if (startDate !== null) {
        var edy = date.split("/");
        // let TotalDays = Math.ceil((endDate-StartDate) / (1000 * 60 * 60 * 24));
        const eDate = new Date(edy[2], edy[0] - 1, edy[1]);
        var sdy = startDate.split("/");
        const sDate = new Date(sdy[2], sdy[0] - 1, sdy[1]);
        let result = Math.round((eDate - sDate) / (1000 * 60 * 60 * 24));
        //console.log("TOtalDayssss", result);
        return result;
      }
    };
    switch (validationType) {
      case "Employee":
        if (value === null) {
          data = {
            type: validationType,
            bool: false,
            message: "*Please select the Employee",
  
          };
        } else if (value?.length === 0) {
          data = {
            type: validationType,
            bool: false,
            message: "*Please select the Employee",
          };
        } else {
          data = { type: validationType, bool: true, message: "" };
        }
        break;
      case "Deligation":
        if (value === null) {
          data = {
            type: validationType,
            bool: false,
            message: "*Please select the Delegatee",
          };
        } else if (value?.length === 0) {
          data = {
            type: validationType,
            bool: false,
            message: "*Please select the Delegatee",
          };
        } else {
          data = { type: validationType, bool: true, message: "" };
        }
        break;
  
      case "StartDate":
        if (value === null) {
          data = {
            type: validationType,
            bool: false,
            message: "*Please Select the Date",
          };
        } else if (type) {
          //console.log("type", type);
          data = {
            type: validationType,
            bool: false,
            message: "Please Select the current Date or Future Date",
          };
        } else {
          data = { type: validationType, bool: true, message: "" };
        }
        break;
      case "EndDate":
        // console.log("value", value);
        // console.log("validate" , validate);
  
        if (value === null) {
          data = {
            type: validationType,
            bool: false,
            message: "*Please Select the Date",
          };
        }
        else if (type) {
          // const validEndDate = handleEndDate(value, type);
          data = {
            type: validationType,
            bool: false,
            message: "*Please Select the Bigger End Date than Start Date",
          };
        }
        //ayushi
        else if (validate !== null) {
          const validEndDate = handleEndDate(value, validate);
          // console.log("results", validEndDate);
          if (validEndDate < 0) {
            data = {
              type: validationType,
              bool: false,
              message: "*Please Select the Bigger End Date than Start Date",
            };
          }
          else {
            data = { type: validationType, bool: true, message: "" };
          }
        }
         else {
          data = { type: validationType, bool: true, message: "" };
        }
        break;
    }
  //console.log("data" , data);
    return data;
  };