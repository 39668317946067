import { Box, makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import MyTimesheet from "../../TimesheetComponents/myTimesheet";
import { useTheme } from "@material-ui/styles";
import Header from "../../components/header";
import TeamTimesheet from "../../TimesheetComponents/teamTimesheet";
import MyTeamApproval from "../../TimesheetComponents/myTeamApproval";
import { useSelector } from "react-redux";
import { allPMOChipsClearData, getTimesheetAccessFlagData } from "../../redux/TimeSheet/actionTs";
import { useDispatch } from "react-redux";
import { maintainState } from "../../redux/TimeSheet/actionTs";
import PmoAutomation from "../../TimesheetComponents/myPMOApproval";
import { getEmpDetail } from "../../redux/Expense/action";
import { useMsal, useAccount } from "@azure/msal-react";
import { useLocation, useHistory } from "react-router-dom";

//import timesheetNavbar from "../../TimesheetComponents/timeSheetNavbar";
//Styling Start:
const useStyles = makeStyles((theme) => ({
  // enhanceTable: {
  //   marginLeft: "-24px",
  //   marginTop: "-7px",

  // },
  enhanceTable1: {
    paddingTop: "20px",
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
  tabText: {
    fontWeight: "bold",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "24.38px",
    letterSpacing: "0.3px",
    textAlign: "left",
    color: "#252733",
    textTransform: "capitalize",
  },
}));

//Styling End.

const TimesheetLandingPage = (props) => {
  const [value, setValue] = React.useState(0);
  const [nDay, setNDay] = useState(30);
  const [indexing, setIndexing] = useState(3);
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  // const [currentUser, setCurrentUser] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const [isComingFromDetailView, setIsComingFromDetailView] = useState(false);
  //UseEffect Start:
  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );
  const tsDelEmpIdRedux = useSelector(
    (state) => state?.timesheetData?.tsDelEmpId
  );
  const timesheetAccessFlag = useSelector(
    (state) => state?.timesheetData?.getTimesheetFlag
  );
  // console.log("flag", timesheetAccessFlag);
  const maintainStateVar = useSelector(
    (state) => state.timesheetData.maintainStateVariable
  );
  const myTimesheetDelegateesList = useSelector(
    (state) => state?.timesheetData?.getMyTimesheetDelegateeData
  );

  const {loc}=location.state || {};

  var path = history.location;
  // var directories = path.split("/");
  // var lastDirecotry = directories[directories.length - 1];
  // var lastSecondDirectry = directories[directories.length - 2];
  // var lastSecondDirectory = directories[directories.length - 2];
  // var lastDirectoryBU = directories[directories.length - 2];
  // var firstDirectory = directories[directories.length - 2];
  //UseEffect End.
// console.log("path",path,loc)
  //Functions Start:
  const handleChange = (event, newValue) => {
    // console.log("PMO4", newValue);
    setValue(newValue);
    dispatch(maintainState(newValue));
    dispatch(allPMOChipsClearData());
  };
  //Functions End.
  // console.log(
  //   "account",
  //   account,
  //   account?.username,
  //   ssoEmpIdDetails,
  //   tsDelEmpIdRedux,
 
  // );
  // useEffect(() => {
  //   if (ssoEmpIdDetails || tsDelEmpIdRedux) {
  //     const obj = {
  //       employeeId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
  //     };
  //     // dispatch(getTimesheetAccessFlagData(obj));
  //   }
  // }, [ssoEmpIdDetails, tsDelEmpIdRedux]);

  // useEffect(() => {
  //   if(timesheetAccessFlag?.teamTimesheetFlag == 0 && timesheetAccessFlag?.invoicingTabFlag == 1){
  //     setIndexing(1);
  //  }
  // }, [])
  useEffect(() => {
    if (
      timesheetAccessFlag?.teamTimesheetFlag === 0 &&
      timesheetAccessFlag?.invoicingTabFlag === 1 &&
      indexing !== 1
    ) {
      setIndexing(1);
      // dispatch(getEmpDetail(account?.username));
    } else if (timesheetAccessFlag?.teamTimesheetFlag == 1 && indexing == 1) {
      setIndexing(3);
      // dispatch(getEmpDetail(account?.username));
    }
  }, [
    timesheetAccessFlag?.teamTimesheetFlag,
    timesheetAccessFlag?.invoicingTabFlag,
    indexing,
  ]);
  useEffect(() => {
    // Reset the tab value to 0 when switching users
    // console.log("PMO11>>>>", maintainStateVar, tsDelEmpIdRedux);
    if (
      myTimesheetDelegateesList?.length > 0 &&
      maintainStateVar != null &&
      maintainStateVar != undefined
    ) {
      // console.log("PMO14>>>>",value, maintainStateVar, tsDelEmpIdRedux);

      setValue(0);
      dispatch(maintainState(0));
    }
  }, [tsDelEmpIdRedux]);

  useEffect(() => {
    //  if (maintainStateVar != null && maintainStateVar != undefined) {
    //   if (maintainStateVar == 2) {
    //     setValue(2);
    //   } else {
    //     setValue(maintainStateVar);
    //   }
    // }
    // setCurrentUser(account?.name);
    // console.log("PMO12", value, maintainStateVar);
    if (maintainStateVar != null && maintainStateVar != undefined) {
      // console.log("PMO14", value, maintainStateVar);

      //console.log("abcdjkj3");
      // if (maintainStateVar == 3) {
      //       setValue(3);
      //     } else {
      setValue(maintainStateVar);
      dispatch(maintainState(maintainStateVar));
      // }
    }
  }, []);

  // useEffect(() => {
  //   // Listen for location changes
  //   return history.listen((location) => {
  //     if (location.pathname.includes('/timesheetviewpage')) {
  //       setIsComingFromDetailView(true);
  //     } else if (location.pathname === '/timesheet-management') {
  //       setIsComingFromDetailView(isComingFromDetailView);
  //     }
  //   });
  // }, [history, isComingFromDetailView]);

 

  // useEffect(() => {
  //   if (account && currentUser !== account.username) {
  //     setCurrentUser(account.username);
  //     setValue(0);  // Reset the tab to the first tab
  //   }
  // }, [account, currentUser]);
  // const teamTimesheetFlag = timesheetAccessFlag?.teamTimesheetFlag;
  return (
    <Box style={{ overflow: "hidden" }}>
      {/* {console.log("indexing", indexing)} */}
      {/* <Box>
          <Header
            heading={"Timesheet"}
            // ssoEmpId={ssoEmpIdDetails?.empId ? ssoEmpIdDetails?.empId : null}
            // disableAccOptions={1}
          />
        </Box> */}

      <Box>
        {/* <Tabs style={{ backgroundColor: "#E3E8F7" }}> */}
        {/* {console.log(
          "teamTimesheetFlag:",
          timesheetAccessFlag?.teamTimesheetFlag
        )} */}
        {timesheetAccessFlag?.teamTimesheetFlag == 1 && (
          <Tabs
            style={{ backgroundColor: "#E3E8F7" }}
            value={maintainStateVar}
            onChange={handleChange}
            // indicatorColor="primary"
            // textColor="primary"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#112664",
                marginLeft: "10px",
                // marginRight:"10px",
                paddingLeft: "10px",
              },
            }}
            aria-label="full width tabs example"
          >
            <Tab
              label={
                <Typography className={classes.tabText}>
                  {" "}
                  My Timesheet
                </Typography>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <Typography className={classes.tabText}>
                  {" "}
                  Team Timesheet
                </Typography>
              }
              {...a11yProps(1)}
            />
            <Tab
              label={
                <Typography className={classes.tabText}>
                  {" "}
                  My Team Approval
                </Typography>
              }
              {...a11yProps(2)}
            />
            {timesheetAccessFlag?.invoicingTabFlag == 1 && (
              <Tab
                label={
                  <Typography className={classes.tabText}>
                    {" "}
                    PMO Approval
                  </Typography>
                }
                {...a11yProps(3)}
              />
            )}
          </Tabs>
        )}
        {timesheetAccessFlag?.teamTimesheetFlag == 0 && (
          <Tabs
            style={{ backgroundColor: "#E3E8F7" }}
            value={maintainStateVar}
            onChange={handleChange}
            // indicatorColor="primary"
            // textColor="primary"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#112664",
                marginLeft: "10px",
                // marginRight:"10px",
                paddingLeft: "10px",
              },
            }}
            aria-label="full width tabs example"
          >
            <Tab
              label={
                <Typography className={classes.tabText}>
                  {" "}
                  My Timesheet
                </Typography>
              }
              {...a11yProps(0)}
            />
            {timesheetAccessFlag?.invoicingTabFlag == 1 && (
              <Tab
                label={
                  <Typography className={classes.tabText}>
                    {" "}
                    PMO Approval
                  </Typography>
                }
                {...a11yProps(1)}
              />
            )}
          </Tabs>
        )}

        {/* <Tabs
            value={value}
            onChange={handleChange}
            // indicatorColor="primary"
            // textColor="primary"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#112664",
                marginLeft: "10px",
                // marginRight:"10px",
                paddingLeft: "10px",
              },
            }}
            aria-label="full width tabs example"
          >
            <Tab
              label={
                <Typography className={classes.tabText}>
                  {" "}
                  Team Timesheet
                </Typography>
              }
              {...a11yProps(1)}
            />
          </Tabs> */}
        {/* </Tabs> */}

        <TabPanel
          value={maintainStateVar}
          index={0}
          dir={theme.direction}
          className={classes.enhanceTable1}
        >
          {/* {console.log("PMO11????", value, maintainStateVar, indexing)} */}
          <Box className={classes.enhanceTable}>
            <MyTimesheet
              //  getMyExpenseList={getMyExpenseList}
              nDay={nDay}
              ssoEmpId={props.ssoEmpId}
            />
          </Box>
        </TabPanel>
        <TabPanel
          value={maintainStateVar}
          index={indexing == 1 ? 4 : 1}
          dir={theme.direction}
          className={classes.enhanceTable1}
        >
          <Box className={classes.enhanceTable}>
            <TeamTimesheet
              //  getMyExpenseList={getMyExpenseList}
              nDay={nDay}
              ssoEmpId={props.ssoEmpId}
            />
          </Box>
        </TabPanel>
        <TabPanel
          value={maintainStateVar}
          index={2}
          dir={theme.direction}
          className={classes.enhanceTable1}
        >
          <Box className={classes.enhanceTable}>
            <MyTeamApproval
              //  getMyExpenseList={getMyExpenseList}
              nDay={nDay}
              ssoEmpId={props.ssoEmpId}
            />
          </Box>
        </TabPanel>
        <TabPanel
          value={maintainStateVar}
          index={indexing}
          dir={theme.direction}
          className={classes.enhanceTable1}
        >
          <Box className={classes.enhanceTable}>
            <PmoAutomation
              //  getMyExpenseList={getMyExpenseList}
              nDay={nDay}
              ssoEmpId={props.ssoEmpId}
            />
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );
};

export default TimesheetLandingPage;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const maintainStateVar = useSelector(
    (state) => state.timesheetData.maintainStateVariable
  );

  //console.log("children, value, index:=====> ", children);
  //console.log(", value in TabPanel:=====> test1 ", value);
  //console.log(", , index in TabPanel:=====> test1 ", index);
  //console.log("MAINTAIN_STATE_VARIABLE : test1 ", maintainStateVar);
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}