import React, { useState, useEffect, useReducer } from "react";
import {
  Typography,
  Box,
  makeStyles,
  Grid,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  TextField,
  Select,
  Button,
  Tabs,
  Tab,
  Badge,
  IconButton,
} from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import EnhancedTable from "../../components/expenseTable";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import TeamApprovalTable from "../../components/teamApprovalTable";
import MyExpenseReport from "../../components/myExpenseReport";
import WarningOutlinedIcon from "@material-ui/icons/WarningOutlined";

import {
  getEmpDetail,
  getMyExpenseData,
  getMyExpenseReportData,
  getMyTeamApprovalData,
  getMyTeamApprovalPageFlagData,
  maintainState,
  resetExpenseResponse,
} from "../../redux/Expense/action";
import { useDispatch, useSelector } from "react-redux";
import FinanceApprovalTable from "../../components/financeApprovalTable";
import DeleteSnackbar from "../../components/deleteSnackbar";
import { useMsal, useAccount } from "@azure/msal-react";
import ApproveRejectSnackbar from "../../components/approveRejectSnackbar";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: "16px 0 8px 0",
    minWidth: "124px",
    height: "24px",

    "& .MuiFormLabel-root": {
      top: "-4px",
      color: "#515961",
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "2px solid #439DD9",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#515961",
      },
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dropdownStyle: {
    "& ul": {
      padding: 0,
      border: "0.5px solid #515961",
      boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
      borderRadius: 5,
    },
    "& li": {
      height: 47,
      fontSize: 14,
      color: "#515961",
      "&:hover": {
        backgroundColor: "rgba(217, 235, 247, 0.4);",
        color: "#439DD9",
      },
    },
  },
  selectRoot: {
    borderRadius: 8,
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "#439DD9",
      },
    },
    "&:focus": {
      backgroundColor: "transparent",
      borderRadius: 8,
    },
  },

  addExpenseButton: {
    height: "42px",
    width: "180px",
    background: " #0B1941",
    boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    borderRadius: "5px",
    "&:hover": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
    "&:focus": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
  },
  paperZ150: {
    width: "1132px",
    border: "1px solid #E8E9EF",
    // borderRadius: "8px",
    marginBottom: "30px",
    backgroundColor: "#E8E9EF",
    padding: "6px",
    minHeight: "43px",
  },
  paperZ125: {
    width: "1390px",
    border: "1px solid #E8E9EF",
    // borderRadius: "8px",
    marginBottom: "30px",
    backgroundColor: "#E8E9EF",
    padding: "6px",
    minHeight: "43px",
  },
  paperZ100: {
    //width: "1117px",
    border: "1px solid #E8E9EF",
    // borderRadius: "8px",
    marginBottom: "30px",
    backgroundColor: "#E8E9EF",
    padding: "6px",
    minHeight: "43px",
  },
  buttonText: {
    color: "#FFFFFF",
    alignItems: "center",
  },
  buttonGrid: {
    display: "flex",
    justifyContent: "end",
    marginTop: "20px",
  },
  expenseCard: {
    width: "360px",
    height: "110px",
    background: "#FFFFFF",
    border: "1px solid #979797",
    borderRadius: " 8px",
  },
  approveText: {
    color: "#4C4C4C",
    alignTtems: "center",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    marginLeft: "76px",
  },
  approveCount: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "42px",
  },
  approveIcon: {
    background: "#ECFFFB",
  },
  pendingText: {
    background: " #FFF2DF",
  },

  rejectIcon: {
    background: "#FFE9ED",
  },
  table: {
    minWidth: 650,
  },
  tabText: {
    fontWeight: "600",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: "20px",
    lineHeight: "24.38px",
    letterSpacing: "0.3px",
    textAlign: "left",
    color: "#252733",
  },
  maincontent: {
    position: "relative",
    minHeight: "100vh",
    backgroundColor: "#F8F9FF",
  },
  enhanceTable: {
    marginLeft: "-24px",
    marginTop: "-7px",
  },
  enhanceTableContent: {
    padding: "0px",
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
  boxTop: {
    paddingTop: "48px",
  },
}));

const LandingPage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const history = useHistory();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [nDay, setNday] = React.useState(90);
  const [inProgressExpenses, setInProgressExpenses] = useState(0);
  const [inProgressFinancialExpenses, setInProgressFinancialExpenses] =
    useState(0);
  const [openSnackbar, setOpenSnackbar] = React.useState(true);
  const [openAppRejSB, setOpenAppRejSB] = useState(true);
  const [openFinAppRejSB, setOpenFinAppRejSB] = useState(true);
  //const [refreshLPTable, forceUpdate] = useReducer((x) => x + 1, 0);
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  //make refreshindexstate(null)
  // const[refreshIndex,setRefreshIndex]=useState(null);
  const matches = useMediaQuery("(max-width:600px)");

  //useSelectors start :
  const getMyTeamApprovalFlagData = useSelector(
    (state) => state.createExpenseData.getMyTeamApprovalPageFlag
  );
  //  console.log("getMyTeamApprovalFlagData",getMyTeamApprovalFlagData)
  const myExpenseReportList = useSelector(
    (state) => state.createExpenseData.getMyExpenseReportList
  );

  const delEmpIDRedux = useSelector(
    (state) => state.createExpenseData.delEmpId
  );

  const DeleteMyExpense = useSelector(
    (state) => state.createExpenseData.isDeleteMyExpense
  );

  const isAppRejPostSuccess = useSelector(
    (state) => state.createExpenseData.isApprovedPost
  );
  const AppRejPostResponse = useSelector(
    (state) => state.createExpenseData.postApproveData
  );
  const isFinAppRejPostSuccess = useSelector(
    (state) => state.createExpenseData.isFinancialApprovedRejectPost
  );
  const FinAppRejPostResponse = useSelector(
    (state) => state.createExpenseData.postFinancialApproveRejectData
  );
  const maintainStateVar = useSelector(
    (state) => state.createExpenseData.maintainStateVariable
  );

  //useSelectors end.
  //console.log("Del3-", props.ssoEmpId, props.delEmpId);
  //console.log("delEmpID in LPK1", delEmpIDRedux);
  //console.log("isFinAppRejPostSuccess in LP2", isFinAppRejPostSuccess);
  //console.log("FinAppRejPostResponse in LP2", FinAppRejPostResponse);

  useEffect(() => {
    //console.log("delEmpIdReduxuseeffect ", delEmpIDRedux, maintainStateVar);
    if (maintainStateVar != null && maintainStateVar != undefined) {
      //console.log("abcdjkj");
      setValue(maintainStateVar);
    }
    if (delEmpIDRedux != null) {
      //console.log("Del3-not null");
      let obj = {
        empId: delEmpIDRedux,
        nday: 90,
      };
      dispatch(getMyTeamApprovalPageFlagData(obj));
    } else {
      //console.log("Del3-null");
      if (props.ssoEmpId !== null) {
        let obj = {
          empId: props.ssoEmpId,
          nday: 90,
        };
        dispatch(getMyTeamApprovalPageFlagData(obj));
      }
    }
  }, [delEmpIDRedux]);
  useEffect(() => {
    if (isAppRejPostSuccess == true || isFinAppRejPostSuccess == true) {
      if (delEmpIDRedux != null) {
        //console.log("Del3-not null");
        let obj = {
          empId: delEmpIDRedux,
          nday: 90,
        };
        dispatch(getMyTeamApprovalPageFlagData(obj));
      } else {
        //console.log("Del3-null");
        if (props.ssoEmpId !== null) {
          let obj = {
            empId: props.ssoEmpId,
            nday: 90,
          };
          dispatch(getMyTeamApprovalPageFlagData(obj));
        }
      }
    }
  }, [isAppRejPostSuccess, isFinAppRejPostSuccess]);

  useEffect(() => {
   // dispatch(resetExpenseResponse()); //----------------------------------------
    dispatch(getEmpDetail(account?.username));
    // if (comingFromOtherModule == true) {
    //   setValue(maintainStateVar);
    // }
    // setRefreshIndex(0);
    //refreshindex
  }, []);

  // //console.log(
  //   "getMyTeamApprovalFlagData:-====> test42 ",
  //   getMyTeamApprovalFlagData
  // );
  //console.log("myExpenseReportList:-====> ", myExpenseReportList);
  useEffect(() => {
    if (getMyTeamApprovalFlagData != null) {
      if (
        getMyTeamApprovalFlagData?.level1Flag == 0 &&
        getMyTeamApprovalFlagData?.level2Flag == 0
      ) {
        setValue(0);
      } else if (
        getMyTeamApprovalFlagData?.level1Flag == 1 &&
        getMyTeamApprovalFlagData?.level2Flag == 0
      ) {
        setInProgressExpenses(getMyTeamApprovalFlagData?.level1PendingCount);
        if (
          maintainStateVar != null &&
          maintainStateVar != undefined &&
          maintainStateVar != 2
        ) {
          setValue(maintainStateVar);
        } else setValue(0);
      } else if (
        getMyTeamApprovalFlagData?.level1Flag == 0 &&
        getMyTeamApprovalFlagData?.level2Flag == 1
      ) {
        setInProgressFinancialExpenses(
          getMyTeamApprovalFlagData?.level2PendingCount
        );
        if (maintainStateVar != null && maintainStateVar != undefined) {
          if (maintainStateVar == 2) {
            setValue(0);
          } else {
            setValue(maintainStateVar);
          }
        } else setValue(0);
      } else if (
        getMyTeamApprovalFlagData?.level1Flag == 1 &&
        getMyTeamApprovalFlagData?.level2Flag == 1
      ) {
        setInProgressExpenses(getMyTeamApprovalFlagData?.level1PendingCount);
        setInProgressFinancialExpenses(
          getMyTeamApprovalFlagData?.level2PendingCount
        );
        //console.log("abcdjkj2");
        if (maintainStateVar != null && maintainStateVar != undefined) {
          //console.log("abcdjkj3");
          setValue(maintainStateVar);
        } else setValue(0);
      }
      // if (getMyTeamApprovalFlagData?.level1Flag == 1) {
      //   //console.log("12345", getMyTeamApprovalFlagData?.level1Flag);
      //   setInProgressExpenses(getMyTeamApprovalFlagData?.level1PendingCount);
      //   // if (maintainStateVar != null && maintainStateVar != undefined) {
      //   //   setValue(maintainStateVar);
      //   // }
      // }
      // // else if (getMyTeamApprovalFlagData?.level1Flag == 0) {
      // //   setValue(0);
      // // }
      // if (getMyTeamApprovalFlagData?.level2Flag == 1) {
      //   //console.log("123456", getMyTeamApprovalFlagData?.level2Flag);
      // setInProgressFinancialExpenses(
      //   getMyTeamApprovalFlagData?.level2PendingCount
      // );
      // }
      // if (
      //   getMyTeamApprovalFlagData?.level1Flag == 0 &&
      //   getMyTeamApprovalFlagData?.level2Flag == 0
      // ) {
      //   setValue(0);
      // }
    }
  }, [getMyTeamApprovalFlagData]);
  useEffect(() => {
    //console.log("LAND2 : props.ssoEmpId", props.ssoEmpId);
  }, [props.ssoEmpId]);
  //console.log("inProgressData: LAND3-====> ", inProgressExpenses);
  //  //console.log(
  //     "getMyTeamApprovalFlagData?.level1PendingCount LAND3:-====> ",
  //     getMyTeamApprovalFlagData?.level1PendingCount
  //   );

  const handleinProgressExpenses = () => {
    // //console.log(
    //   "handleinProgressExpenses====>",
    //   getMyTeamApprovalFlagData?.level1Flag,
    //   getMyTeamApprovalFlagData?.level2Flag
    // );
    if (getMyTeamApprovalFlagData?.level1Flag == 1) {
      //console.log("1", getMyTeamApprovalFlagData?.level1Flag);
      setInProgressExpenses(getMyTeamApprovalFlagData?.level1PendingCount);
    }
    if (getMyTeamApprovalFlagData?.level2Flag == 1) {
      //console.log("2", getMyTeamApprovalFlagData?.level2Flag);
      setInProgressFinancialExpenses(
        getMyTeamApprovalFlagData?.level2PendingCount
      );
    }
  };
  const handleDropeDown = (day, delEmpIDRed) => {
    setNday(day);

    if (delEmpIDRed != null) {
      //console.log("Del3-1 : ", delEmpIDRed, props.ssoEmpId);
      let obj = {
        empid: delEmpIDRed,
        nday: day,
      };
      dispatch(getMyExpenseReportData(obj));
      dispatch(getMyTeamApprovalPageFlagData(obj));
    } else {
      //console.log("Del3-2 : ", delEmpIDRed, props.ssoEmpId);
      if (props.ssoEmpId !== null) {
        let obj = {
          empid: props.ssoEmpId,
          nday: day,
        };
        dispatch(getMyExpenseReportData(obj));
        dispatch(getMyTeamApprovalPageFlagData(obj));
      }
    }
  };

  const handleChange = (event, newValue) => {
    // console.log("newValue in handleChange:========> test41", newValue);

    setValue(newValue);
    dispatch(maintainState(newValue));
  };

  //console.log("new value , test1", value);

  // const handleChangeIndex = (index) => {
  //   //console.log("index:========>", index);
  //   setValue(index);
  // };

  // const handleClick = () => {
  //   //console.log("in the landing page");
  //   history.push("/create-Expense-Management");
  // };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const handleCloseAppRejSB = () => {
    setOpenAppRejSB(false);
  };
  const handleCloseFinAppRejSB = () => {
    setOpenFinAppRejSB(false);
  };

  const StyledBadge = withStyles((theme) => ({
    badge: {
      right: -15,
      top: 10,
      // backgroundColor: "white",
      color: "#252733",
      border: "2px solid #252733",
      height: "32px",
      width: "32px",
      borderRadius: "20px",
      marginRight: "-10px",
    },
  }))(Badge);

  return (
    <div className={matches ? "" : classes.maincontent}>
      {!matches && (
        <Box
          className={
            window.screen.availWidth == 1280
              ? classes.paperZ150
              : window.screen.availWidth == 1536
              ? classes.paperZ125
              : classes.paperZ100
          }
          style={{
            marginRight: "20px",
            backgroundColor: "#ffd5cb",
            display: "flex",
          }}
        >
          {/* <IconButton style={{ padding: "0px", marginRight: "-6px" }}> */}
          <WarningOutlinedIcon
            style={{
              fontSize: "medium",
              padding: "0px",
              marginTop: "8px",
              marginRight: "8px",
              color: "#f4a78b",
            }}
          />
          {/* </IconButton>{" "} */}
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontSize: "11px",

              color: "#333333",
              fontWeight: "600",
              paddingTop: "9px",
              fontWeight: 500,
              lineHeight: "15px",
              letterSpacing: "0.20000000298023224px",
              textAlign: "left",
              // paddingLeft: "15px",
              // paddingRight: "15px",
            }}
          >
            {/* <IconButton style={{ padding: "0px", marginRight: "-6px" }}>
              <WarningOutlinedIcon
                style={{
                  fontSize: "medium",
                  padding: "0px",
                  marginTop: "-3px",
                  marginRight: "10px",
                  color: "#f4a78b",
                }}
              />
            </IconButton>{" "} */}
            For India, telephone and internet expenses are no longer supported as per policy after return to office notifications.{" "}
          </Typography>
        </Box>
      )}
      {!matches && (
        <Box>
          <MyExpenseReport
            handleDropeDown={handleDropeDown}
            myExpenseReportList={myExpenseReportList}
            ssoEmpId={props.ssoEmpId}
          />
        </Box>
      )}
      <Box className={matches ? classes.enhanceTableContent : classes.boxTop}>
        <Box>
          {!matches && (
            <Tabs
              value={value}
              onChange={handleChange}
              // indicatorColor="primary"
              // textColor="primary"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#112664",
                  marginLeft: "10px",
                  // marginRight:"10px",
                  paddingLeft: "10px",
                },
              }}
              aria-label="full width tabs example"
            >
              <Tab
                label={
                  <Typography className={classes.tabText}>
                    {" "}
                    My Expenses
                  </Typography>
                }
                {...a11yProps(0)}
              />
              {getMyTeamApprovalFlagData?.level1Flag == 1 ? (
                <Tab
                  style={{ minWidth: "290px" }}
                  label={
                    <StyledBadge
                      badgeContent={
                        <Typography
                          style={{
                            fontFamily: "Montserrat",
                            fontWeight: 600,
                          }}
                        >
                          {inProgressExpenses}
                        </Typography>
                      }
                    >
                      <Typography className={classes.tabText}>
                        Team Approvals
                      </Typography>
                    </StyledBadge>
                  }
                  {...a11yProps(1)}
                />
              ) : (
                ""
              )}
              {getMyTeamApprovalFlagData?.level2Flag == 1 ? (
                <Tab
                  style={{ minWidth: "320px" }}
                  label={
                    <StyledBadge
                      badgeContent={
                        <Typography
                          style={{
                            fontFamily: "Montserrat",
                            fontWeight: 600,
                          }}
                        >
                          {inProgressFinancialExpenses}
                        </Typography>
                      }
                    >
                      <Typography className={classes.tabText}>
                        Finance Approvals
                      </Typography>
                    </StyledBadge>
                  }
                  {...a11yProps(1)}
                />
              ) : (
                ""
              )}
            </Tabs>
          )}
          {/* removed SwipeableViews */}

          <TabPanel value={value} index={0} dir={theme.direction}>
            <Box
              className={
                matches ? classes.enhanceTableContent : classes.enhanceTable
              }
            >
              <EnhancedTable
                //  getMyExpenseList={getMyExpenseList}
                nDay={nDay}
                ssoEmpId={props.ssoEmpId}
              />
            </Box>
          </TabPanel>
          {!matches && getMyTeamApprovalFlagData?.level1Flag == 1 && (
            <TabPanel
              value={value}
              index={
                getMyTeamApprovalFlagData?.level1Flag == 1 &&
                getMyTeamApprovalFlagData?.level2Flag !== 0
                  ? 1
                  : getMyTeamApprovalFlagData?.level1Flag == 1 &&
                    getMyTeamApprovalFlagData?.level2Flag == 0
                  ? 1
                  : ""
              }
              dir={theme.direction}
            >
              <Box style={{ marginLeft: "-24px", marginTop: "-7px" }}>
                <TeamApprovalTable
                  ssoEmpId={props.ssoEmpId}
                  delEmpId={props.delEmpId}
                  nDay={nDay}
                />
              </Box>
            </TabPanel>
          )}
          {!matches && getMyTeamApprovalFlagData?.level2Flag !== 0 && (
            <TabPanel
              value={value}
              index={
                getMyTeamApprovalFlagData?.level1Flag == 1 &&
                getMyTeamApprovalFlagData?.level2Flag !== 0
                  ? 2
                  : getMyTeamApprovalFlagData?.level1Flag == 0 &&
                    getMyTeamApprovalFlagData?.level2Flag == 1
                  ? 1
                  : ""
              }
              dir={theme.direction}
            >
              <Box style={{ marginLeft: "-24px", marginTop: "-7px" }}>
                <FinanceApprovalTable ssoEmpId={props.ssoEmpId} nDay={nDay} />
              </Box>
            </TabPanel>
          )}
        </Box>
      </Box>
      {/* <Box sx={{p:,border:"1px solid #E8E9EF",marginBottom:"20px",backgroundColor:"#E8E9EF",width:"1130px"}}> */}
      {!matches && (
        <Box
          className={
            window.screen.availWidth == 1280
              ? classes.paperZ150
              : window.screen.availWidth == 1536
              ? classes.paperZ125
              : classes.paperZ100
          }
          style={{ marginRight: "20px" }}
        >
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontSize: "10px",
              color: "#333333",
              fontWeight: "600",
              paddingTop: "5px",
            }}
          >
            <strong>Note:</strong> The life cycle of expense includes five
            steps, starting with creating expense information up to its payment
            in your account.{" "}
          </Typography>
        </Box>
      )}
      {!matches && DeleteMyExpense ? (
        <DeleteSnackbar
          handleCloseSnackbar={handleCloseSnackbar}
          open={openSnackbar}
        />
      ) : (
        ""
      )}
      {/* {isAppRejPostSuccess && AppRejPostResponse.comments == "" ? (
        <ApproveRejectSnackbar
          handleCloseAppRejSB={handleCloseAppRejSB}
          openAppRejSB={openAppRejSB}
          approveOrReject={1}
        />
      ) : isAppRejPostSuccess && AppRejPostResponse.comments != "" ? (
        <ApproveRejectSnackbar
          handleCloseAppRejSB={handleCloseAppRejSB}
          openAppRejSB={openAppRejSB}
          approveOrReject={0}
        />
      ) : (
        ""
      )} */}
      {/* {!matches &&
      isFinAppRejPostSuccess &&
      FinAppRejPostResponse.expStatus == "Approved" ? (
        <ApproveRejectSnackbar
          handleCloseAppRejSB={handleCloseFinAppRejSB}
          openAppRejSB={openFinAppRejSB}
          approveOrReject={1}
        />
      ) : isFinAppRejPostSuccess &&
        FinAppRejPostResponse.expStatus == "Rejected" ? (
        <ApproveRejectSnackbar
          handleCloseAppRejSB={handleCloseFinAppRejSB}
          openAppRejSB={openFinAppRejSB}
          approveOrReject={0}
        />
      ) : (
        ""
      )} */}
    </div>
  );
};

export default LandingPage;
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const maintainStateVar = useSelector(
    (state) => state.createExpenseData.maintainStateVariable
  );

  //console.log("children, value, index:=====> ", children);
  //console.log(", value in TabPanel:=====> test1 ", value);
  //console.log(", , index in TabPanel:=====> test1 ", index);
  //console.log("MAINTAIN_STATE_VARIABLE : test1 ", maintainStateVar);
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}