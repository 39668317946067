import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  Box,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import Encrypt from "../Utils/encryption.js";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import {
  getMyTeamApprovalData,
  postApproveRejectExpenseData,
  resetExpenseResponse,
  postFinancialApproveRejectExpenseData,
} from "../redux/Expense/action";

import MuiAlert from "@material-ui/lab/Alert";
import MyTeamApproval from "./myTeamApproval.js";

const useStyles = makeStyles((theme) => ({
  mobileResH: {
    //width: "15.875rem",
    height: "108px",
  },
  mobileRes: {
    width: "18.875rem",
  },
  mobileText: {
    width: "14.875rem",
  },
  normalText: {
    width: "25rem",
  },
}));

const ApproveRejectByManager = (props) => {
  const [rejectionComments, setRejectionComments] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width:600px)");
  const [comments, setComments] = React.useState("");
  const { bulkDownload } = props;
  const history = useHistory();
  const dataValue = { comments };
  const [appValue, setAppValue] = React.useState(false);
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [isLoader, isSetLoader] = useState(false);

  const handleComments = (data) => {
    setComments(data);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function handleSingleExpense() {
    //console.log("postApproveExpenseData handleSingleExpense");
    props.handleSingleApproveRejectExpense(props.singleExpense);
    setRejectionComments("");
    props.handleCloseBulkApproveDialog();
  }
  const handleRejectionComments = (value) => {
    const trimmedInput = value.trimStart();
    setRejectionComments(trimmedInput);
    props.handleComment(value);
    // handleComments(value);
  };
  const handleSnackbar = () => {
    setOpen(false);
  };
  const handlecloseSnackbar = () => {
    setOpen(false);
  };
  // console.log("firsapprej", props);

  const handleAppRej = (stat) => {
//         console.log("inside rejection button")
// console.log("status",stat)
    props.loader(true);
    props.handleApproveRejectStatus(stat);
    stat == "Approved"
      ? props.handleApproveRejectByManager(props.row, props.firstAppRej, null)
      : props.handleApproveRejectByManager(
          props.row,
          props.firstAppRej,
          rejectionComments
        );

    // props.handleSnackbar();
  };

  useEffect(() => {
    rejectionComments != "" ? setBtnDisabled(false) : setBtnDisabled(true);
  }, [rejectionComments]);
  const width = props.firstAppRej === "Approved" ? "575px" : "";
  const height = props.firstAppRej === "Approved" ? "80px" : "";
  return (
    <div>
      <Dialog
        open={props.showApproveRejectDialog}
        onClose={props.handleCloseApproveRejectDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // style={{ width: "678px", flexShrink: 0, height: " 232px" }}
        fullWidth
        // maxWidth={props.firstAppRej === "Approved" ? "sm" : "sm"}
      >
        {" "}
        <Box
          display="flex"
          alignItems="center"
          style={{ justifyContent: "space-between", paddingRight: "14px" }}
        >
          <DialogTitle
            id="alert-dialog-title"
            // style={{ width: width, flexShrink: 0, height: height }}
          >
            {props.firstAppRej === "Approved" ? 
            // (
            //   <div>
            //     <Typography
            //       style={{
            //         display: "flex",
            //         justifyContent: "left",
            //         fontWeight: 600,
            //         color: "#252733",
            //         fontFamily: "Montserrat",
            //         fontSize: matches ? "14px" : "24px",
            //         fontStyle: "normal",
            //         lineHeight: "normal",
            //         letterSpacing: "0.3px",
            //         alignItems: "center", // This will center the content vertically
            //         justifyContent: "space-between",
            //       }}
            //     >
            //       Approve Timesheet
            //     </Typography>
            //     {/* <Snackbar
            //       handleSnackbar={handleSnackbar}
            //       handlecloseSnackbar={handlecloseSnackbar}
            //       expenseStatus={props.firstAppRej}
            //       open={open}
            //       // expenseCode={postCreateExpenseFormData?.expenseCode}
            //     /> */}
            //   </div>
            // ) 
            ""
            : props.firstAppRej === "Rejected" ? (
              <div>
                <Typography
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    fontSize: "22px",
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                    fontSize: matches ? "14px" : "24px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    letterSpacing: "0.3px",
                  }}
                >
                  Reject Timesheet
                </Typography>
                {/* <Snackbar
                  handleSnackbar={handleSnackbar}
                  handlecloseSnackbar={handlecloseSnackbar}
                  expenseStatus={props.firstAppRej}
                  open={open}
                  // anchorOrigin={("top", "right")}
                  // expenseCode={postCreateExpenseFormData?.expenseCode}
                /> */}
              </div>
            ) : null}
          </DialogTitle>
          <Box onClick={props.handleCloseApproveRejectDialog}>
            <div>
              <CloseOutlinedIcon />
            </div>
          </Box>
        </Box>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontWeight: 600, fontSize: matches ? "12px" : "16px" }}
          >
            {/* {matches ? "Are you sure you want to " : "Are you sure you want to"}{" "} */}
            {/* {props.bulkDownload?.length > 1 ? "bulk" : ""}{" "} */}
            {props.firstAppRej == "Approved"
              ? ""
              : "Reason for timesheet rejection."}
            {/* the{" "} timesheet.*/}
            {/* {props.viewDialog == true ? "following" : ""} */}
          </DialogContentText>
          {props.firstAppRej == "Rejected" ? (
            <Box style={{ width:matches? "15.5rem":"34.5rem" }}>
              <TextField
                margin="dense"
                id="comments"
                label={matches ? "Add Comments" : "Comments*"}
                variant="outlined"
                multiline
                inputProps={{
                  maxLength: matches ? 100 : 100,
                  style: { height: "90px",fontSize:"13px" },
                }}
                className={
                  window.screen.availWidth > 400
                    ? classes.mobileResH
                    : window.screen.availWidth > 400 &&
                      window.screen.availWidth < 600
                    ? classes.mobileRes
                    : matches
                    ? classes.mobileText
                    : classes.normalText
                }
                value={rejectionComments}
                //row={5}
                maxRows={3}
                // size="medium"
                fullWidth
                onChange={(e) => {
                  handleRejectionComments(e.target.value);
                }}
              />
            </Box>
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions>
          {/* <Button
            variant="outlined"
            style={{
              minWidth: "120px",
              minHeight: "36px",

              backgroundColor: "#FFFFFF",
              color: "#0B1941",
            }}
            onClick={props.handleCloseApproveRejectDialog}
          >
            Cancel
          </Button> */}
          {/* <Box
          onClick={props.handleCloseApproveRejectDialog}>
          <div>
            <CloseOutlinedIcon />
          </div>
          </Box> */}
          {props.firstAppRej == "Approved" ? 
          // (
          //   <Button
          //     variant="outlined"
          //     style={{
          //       minWidth: "120px",
          //       minHeight: "36px",

          //       backgroundColor: "#0B1941",
          //       color: "#FFFFFF",
          //       marginRight: "10px",
          //     }}
          //     onClick={() => handleAppRej("Approved")}
          //     autoFocus
          //   >
          //     Approve
          //   </Button>
          // ) 
          ""
          : (
            <Button
              style={{
                minWidth: "120px",
                minHeight: "36px",
                marginRight: "15px",
                backgroundColor: btnDisabled ? "#9E9E9E" : "#0B1941",
                color: "#FFFFFF",
              }}
              disabled={btnDisabled}
              autoFocus
              onClick={() => handleAppRej("Rejected")}
          >
              Reject
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ApproveRejectByManager;