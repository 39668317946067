import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Slide,
  Divider,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import FeaturedPlayListOutlinedIcon from "@material-ui/icons/FeaturedPlayListOutlined";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TempScreen from "./tempScreen.js";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import HomeIcon from "@material-ui/icons/Home";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
// import FeaturedPlayListIcon from "@material-ui/icons/FeaturedPlayList";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import Header from "./header";
import ExpenseManagement from "../pages/Expense/expenseManagement";
import { useHistory, useLocation } from "react-router";
import ExpenseLandingPage from "../pages/Expense/landingPage";
import { Typography } from "@material-ui/core";
import Encrypt from "../Utils/encryption";
import { useDispatch, useSelector } from "react-redux";
import {
  getTimesheetReportAccessData,
  getPendencyReportData,
  getRatingDescriptionData,
  postRatingReviewData,
} from "../redux/TimeSheet/actionTs";

import {
  getDownloadExpenseReportData,
  getEmpDetail,
} from "../redux/Expense/action";
import { CSVLink } from "react-csv";
import { setDayWithOptions } from "date-fns/fp";
import { style } from "@material-ui/system";
import Link from "@material-ui/core/Link";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import HelpIcon from "@mui/icons-material/Help";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import LinkIcon from "@mui/icons-material/Link";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import axios from "axios";
import moment from "moment";
import GMCalDashboard from "./gmCalDashboard.js";
import GMCalIcon from "../images/cal_icon.svg";
import { useMsal, useAccount } from "@azure/msal-react";
import TimesheetLandingPage from "../pages/Timesheet/timesheetLandingPage.js";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import StarIcon from "@mui/icons-material/Star";
import Rating from "@mui/material/Rating";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const drawerWidth = 100;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& .MuiPaper-outlined": {
      borderBottom: "1px solid #BDBDBD",
      border: "0px solid #BDBDBD",
    },
  },
  appBar: {
    backgroundColor: "white",
    paddingRight: "0px !important",
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    maxWidth:
      window.location.pathname === "/timesheet-management" ? "100px" : "90px",
    // maxWidth: "90px",
    backgroundColor: "#0B1941",
    color: "#FFFFFF",
    // margin: "0px",
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
  },
  successIcon: {
    color: theme.palette.success.main,
    fontSize: 40,
    minWidth: "100%",
  },
  thankYouText: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
    minWidth: "50px",
  },
  messageText: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    minWidth: "50px",
  },
}));
export default function ClippedDrawer(props) {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const [text, setText] = useState("All Expense");
  const [downloadCSV, setDownloadCSV] = useState(false);
  const [downloadCSVData, setDownloadCSVData] = useState(null);
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [open, setOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState("panel2");
  const [isLoader, setIsLoader] = useState(false);
  const [openSupportDoc, setOpenSupportDoc] = useState(false);
  const [openRating, setOpenRating] = useState(false);
  const [thankYouRatingOpen, setThankYouRatingOpen] = React.useState(false);
  const [pageModule, setPageModule] = useState("");
  const [ratingHeading, setRatingHeading] = useState("");
  const [value, setValue] = React.useState(-1);
  const [hover, setHover] = React.useState(-1);
  const [review, setReview] = React.useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width:600px)");
  //useSelectors:
  const timesheetReportAccessData = useSelector(
    (state) => state.timesheetData.getTimesheetAccessReportList
  );

  const ratingLabels = useSelector(
    (state) => state?.timesheetData?.getRatingDescriptionData
  );

  const PendencyReportData = useSelector(
    (state) => state.timesheetData.getPendencyReportData
  );
  const getDownloadExpenseReport = useSelector(
    (state) => state.createExpenseData.getDownloadAllExpensesReport
  );
  const delegationFlag = useSelector(
    (state) => state.createExpenseData.getDelegationFlag
  );
  const postCreateExpensData = useSelector(
    (state) => state.createExpenseData.postCreateExpenseList
  );
  const postEditDataData = useSelector(
    (state) => state.createExpenseData.postEditData
  );
  const getDownloadAllExpensesReportData = useSelector(
    (state) => state.createExpenseData.getDownloadAllExpensesReport
  );

  const getEmployeeDataList = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );

  const getEmployeeDataList1 = useSelector((state) => state.createExpenseData);

  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );

  // console.log(
  //   ">>>>>>delegationFlag",
  //   timesheetReportAccessData
  // );

  useEffect(() => {
    //dispatch(getEmpDetail(account?.username));

    if (getEmployeeDataList?.empId) {
      const obj = getEmployeeDataList?.empId;
      dispatch(getTimesheetReportAccessData(obj));
    }
  }, [getEmployeeDataList]);

  useEffects: useEffect(() => {
    //console.log("inside the first");
    dispatch(getEmpDetail(account?.username));
  }, []);

  useEffect(() => {
    if (ssoEmpIdDetails) {
      dispatch(getRatingDescriptionData());
    }
  }, [ssoEmpIdDetails]);

  var path = window.location.href;
  var directories = path.split("/");
  var lastDirecotry = directories[directories.length - 1];
  var firstDirectory = directories[directories.length - 2];

  useEffect(() => {
    if (directories && directories?.length === 4) {
      if (
        lastDirecotry === "timesheet-management" ||
        lastDirecotry === "create-Timesheet-Management"
      ) {
        setPageModule("Timesheet");
        setRatingHeading("Timesheet Application");
      } else if (
        lastDirecotry === "expense-Management" ||
        lastDirecotry === "create-Expense-Management" ||
        lastDirecotry === "allExpenseReports"
      ) {
        setPageModule("Expense");
        setRatingHeading("Expense Application");
      } else if (
        lastDirecotry === "gmdashboard" ||
        lastDirecotry === "gmmobility"
      ) {
        setPageModule("GM");
        setRatingHeading("Global Mobility Application");
      }
    } else if (directories && directories?.length === 5) {
      if (firstDirectory === "timesheetviewpage") {
        setPageModule("Timesheet");
        setRatingHeading("Timesheet Application");
      } else if (
        firstDirectory === "viewExpenseDetails" ||
        firstDirectory === "editExpenseDetails" ||
        firstDirectory === "editTravelExpenseDetails" ||
        firstDirectory === "deligation"
      ) {
        setPageModule("Expense");
        setRatingHeading("Expense Application");
      } else if (
        firstDirectory === "gmbupage" ||
        firstDirectory === "gmmobility"
      ) {
        setPageModule("GM");
        setRatingHeading("Global Mobility Application");
      }
    }
  }, [directories]);

  useEffect(() => {
    if (value && value === -1) {
      setIsSubmitDisabled(true);
    } else {
      setIsSubmitDisabled(false);
    }
  }, [value]);

  function getLabelText(value) {
    return (
      ratingLabels &&
      `${value} Star${value !== 1 ? "s" : ""}, ${
        ratingLabels[value - 1]?.ratingDescription
      }`
    );
  }

  const handleRating = () => {
    setOpenRating(true);
  };

  const handleRatingClose = async () => {
    setOpenRating(false);

    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    const ratingFormData = new FormData();

    ratingFormData.append(
      "EmpId",
      Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
    ratingFormData.append("ApplicationType", pageModule ? pageModule : "");
    ratingFormData.append("ApplicationDiscription", "");
    ratingFormData.append("Review", "");
    ratingFormData.append("Skippedflag", 1);

    try {
      const response = await dispatch(postRatingReviewData(ratingFormData));

      if (response.status === 200) {
        // Route to the gmdashboard location after 3 seconds
      } else {
        setThankYouRatingOpen(false);
      }
    } catch {
      setThankYouRatingOpen(false);
    }
  };

  const handleRatingSkipClose = async () => {
    setOpenRating(false);

    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    const ratingFormData = new FormData();

    ratingFormData.append(
      "EmpId",
      Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
    ratingFormData.append("ApplicationType", pageModule ? pageModule : "");
    ratingFormData.append("ApplicationDiscription", "");
    ratingFormData.append("Review", "");
    ratingFormData.append("Skippedflag", 1);

    try {
      const response = await dispatch(postRatingReviewData(ratingFormData));

      if (response.status === 200) {
        // Route to the gmdashboard location after 3 seconds
      } else {
        setThankYouRatingOpen(false);
      }
    } catch {
      setThankYouRatingOpen(false);
    }
  };

  const handleRatingSubmit = async () => {
    setOpenRating(false);
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    const ratingFormData = new FormData();

    ratingFormData.append(
      "EmpId",
      Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
    ratingFormData.append("ApplicationType", pageModule ? pageModule : "");
    ratingFormData.append(
      "ApplicationDiscription",
      ratingLabels && value && ratingLabels[value - 1]?.ratingDescription
    );
    ratingFormData.append("Review", review ? review : "");
    ratingFormData.append("Skippedflag", 0);

    try {
      const response = await dispatch(postRatingReviewData(ratingFormData));
      if (response.status === 200) {
        setTimeout(() => {
          setThankYouRatingOpen(true);
        }, 500);
        setTimeout(() => {
          setThankYouRatingOpen(false);
        }, 5000);
      } else {
        setThankYouRatingOpen(false);
      }
    } catch {
      setThankYouRatingOpen(false);
    }
  };

  // useEffect(() => {
  //   console.log('asdf', PendencyReportData);
  // }, [PendencyReportData]);

  // useEffect(() => {
  //   if(postEditDataData!==null){
  //     console.log("inside the useEffect",getDownloadAllExpensesReportData);
  //     dispatch(getDownloadExpenseReportData());
  //   }
  // }, [postEditDataData]);

  //console.log("in the navbar");

  //Functions:

  const handleSupportDoc = () => {
    setOpenSupportDoc(true);
  };

  const handleSupportDocClose = () => {
    setOpenSupportDoc(false);
  };

  const handlePendencyReport = (item) => {
    setIsLoader(true);
    const obj = {
      body: item,
    };
    dispatch(getPendencyReportData(obj, setIsLoader));

    //setIsLoader(false);
  };

  // const handleApprovalReport = () => {
  //   const obj = {
  //     body: "ApprovedReport",
  //   };
  //   dispatch(getPendencyReportData(obj));
  // };
  // const handleAuditReport = () => {
  //   const obj = {
  //     body: "AuditReport",
  //   };

  //   dispatch(getPendencyReportData(obj));
  // };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleClick = (text) => {
    //console.log("???", text);
    setText(text);
  };
  //console.log("Del2-navbar.js", props.ssoEmpId, props.delEmpId);
  const headers = [
    {
      label: "Expense Code",
      key: "expCode",
    },
    {
      label: "Purpose",
      key: "purpose",
    },
    {
      label: "Category Name",
      key: "categoryName",
    },
    {
      label: "To Location",
      key: "toLocation",
    },
    {
      label: "From Location",
      key: "fromLocation",
    },
    {
      label: "Distance (Km/Miles)",
      key: "distance",
    },
    {
      label: "From Date",
      key: "fromDate",
    },
    {
      label: "To Date",
      key: "toDate",
    },
    {
      label: "Claim Month",
      key: "forMonth",
    },
    {
      label: "Legal Entity",
      key: "empLegalEntity",
    },
    {
      label: "Employee ID",
      key: "empId",
    },
    {
      label: "Employee Name",
      key: "empName",
    },
    {
      label: "Vendor Name",
      key: "vendor",
    },
    {
      label: "Invoice Number",
      key: "invoice",
    },
    {
      label: "GST Number",
      key: "gstNumber",
    },
    {
      label: "Currency",
      key: "currency",
    },
    {
      label: "Bill Amount",
      key: "billAmount",
    },
    {
      label: "Claimed Amount",
      key: "claimedAmount",
    },
    {
      label: "Approved Amount",
      key: "approvedAmount",
    },
    {
      label: "Expense Name",
      key: "description",
    },
    {
      label: "Creation Date",
      key: "createdOn",
    },
    {
      label: "Expense Status",
      key: "status",
    },
    {
      label: "Approved By",
      key: "approvedBy",
    },
    {
      label: "Approved Date",
      key: "approvedOn",
    },
    {
      label: "Project ID",
      key: "projectId",
    },
    {
      label: "Comments",
      key: "pendingWith",
    },
    {
      label: "Created By",
      key: "createdBy",
    },
  ];
  const csvLink = {
    headers: headers,
    data: downloadCSVData || "",
    filename: `AllExpensesReport_${moment(new Date()).format(
      "DDMMYYYY_HHmm"
    )}.csv`,
  };

  const handleIconButton = async (value) => {
    if (value === "Home") {
      //console.log("home");
      history.push("/");
    } else if (value === "All Expense") {
      //console.log("all");
      history.push("/expense-Management");
    } else if (value === "Timesheet") {
      //console.log("all");
      history.push("/timesheet-management");
    } else if (value == "All Reports") {
      // console.log("All Reports", getDownloadExpenseReport);
      history.push("/allExpenseReports");

      // const url = `${process.env.REACT_APP_API_URL}Expense/DownloadExpenseReport`;
      // axios
      //   .get(url)
      //   .then((res) => {
      //     console.log("successfull", res);
      //     setDownloadCSVData(res.data);
      //     setTimeout(() => {
      //       setDownloadCSV(true);
      //     }, 2000);
      //   })
      //   .catch((e) => console.log(e));
    } else if (value === "Global Mobility") {
      //console.log("all");
      history.push("/gmdashboard");
    } else if (value == "All Timesheet Reports") {
      history.push("/allTimesheetReports");
      window.location.reload();
    }
  };

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          style={{ paddingRight: "0px" }}
          className={classes.appBar}
          elevation={0}
        >
          <Header heading={text} ssoEmpId={props.ssoEmpId} />
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          {lastDirecotry == "gmdashboard" ? (
            <div className={classes.drawerContainer}>
              <List>
                {["Home", "Global Mobility"].map((text, index) =>
                  delegationFlag?.allExpReportsFlag == 1 && index == 2 ? (
                    <ListItem
                      button
                      key={text}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                      onClick={(e) => {
                        handleIconButton(text);
                      }}
                    >
                      <ListItemIcon
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {index === 0 ? (
                          <div
                            className="Icon"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <HomeIcon style={{ color: "#9DA3B3" }} />
                          </div>
                        ) : index === 1 ? (
                          <div
                            className="Icon"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={GMCalIcon}
                              style={{ width: "26px", marginTop: "-3px" }}
                            />
                          </div>
                        ) : null}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            style={{
                              fontFamily: "Montserrat",
                              fontSize: "12px",
                              fontWeight: 500,
                              lineHeight: "16px",
                              textAlign:
                                text == "Global Mobility" ? "center" : "",
                            }}
                          >
                            {text}
                          </Typography>
                        }
                        style={{
                          minWidth: "70px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      />
                    </ListItem>
                  ) : (
                    <ListItem
                      button
                      key={text}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        paddingTop: "16px",
                      }}
                      onClick={(e) => {
                        handleIconButton(text);
                      }}
                    >
                      <ListItemIcon
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {index === 0 ? (
                          <div
                            className="Icon"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <HomeIcon style={{ color: "#9DA3B3" }} />
                          </div>
                        ) : index === 1 ? (
                          <div
                            className="Icon"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={GMCalIcon}
                              style={{ width: "26px", marginTop: "-3px" }}
                            />
                          </div>
                        ) : null}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            style={{
                              fontFamily: "Montserrat",
                              fontSize: "12px",
                              fontWeight: 500,
                              lineHeight: "16px",
                              color:
                                text === "Home" && "All Reports"
                                  ? "#9DA3B3"
                                  : "white",
                              textAlign:
                                text == "Global Mobility" ? "center" : "",
                            }}
                          >
                            {text}
                          </Typography>
                        }
                        style={{
                          minWidth: "70px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      />
                    </ListItem>
                  )
                )}
              </List>
            </div>
          ) : (
            <div className={classes.drawerContainer}>
              {/* {console.log("time-time", props.module)} */}
              {/* {console.log(
              "checking something",
              delegationFlag?.allExpReportsFlag == 1
            )} */}
              <List>
                {["Home"]
                  .concat(
                    props.module === "timesheetManagement"
                      ? ["Timesheet"]
                      : ["All Expense"],
                    props.module !== "timesheetManagement" &&
                      delegationFlag?.allExpReportsFlag === 1
                      ? ["All Reports"]
                      : [],
                    // props.module === "timesheetManagement"
                    //   ? timesheetReportAccessData
                    //       // .filter((item) => item.reportValue === 1) // Filter by reportValue condition
                    //       ?.map((item) => item.reportName)
                    //   : []
                    props.module == "timesheetManagement" &&
                      timesheetReportAccessData?.length > 0
                      ? ["Reports"]
                      : [],
                    // props.module == "timesheetManagement" &&
                    //   timesheetReportAccessData?.length > 0
                    //   ? ["All Timesheet Reports"]
                    //   : []
                  )
                  .map((text, index) =>
                    delegationFlag?.allExpReportsFlag == 1 &&
                    index == 3 &&
                    timesheetReportAccessData?.length == 0 ? (
                      <ListItem
                        button
                        key={text}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                        onClick={(e) => {
                          handleIconButton(text);
                        }}
                      >
                        <ListItemIcon
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {index === 0 ? (
                            <div
                              className="Icon"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <HomeIcon style={{ color: "#9DA3B3" }} />
                            </div>
                          ) : index === 1 ? (
                            <div
                              className="Icon"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {text == "Timesheet" ? (
                                <FeaturedPlayListOutlinedIcon
                                  style={{ color: "white" }}
                                />
                              ) : (
                                <AttachMoneyIcon style={{ color: "white" }} />
                              )}
                            </div>
                          ) : // ) : index === 2 ? (
                          //   <div
                          //     className="Icon"
                          //     style={{ display: "flex", justifyContent: "center" }}
                          //   >
                          //     <FeaturedPlayListIcon style={{ color: "white" }} />
                          //   </div>
                          delegationFlag?.allExpReportsFlag == 1 ? (
                            //&& props.module != "timesheetManagement"
                            <div
                              className="Icon"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <DescriptionOutlinedIcon
                                style={{ color: "white" }}
                              />
                            </div>
                          ) : // timesheetReportAccessData?.pendencyReportFlag == 1 ? (
                          //   <div
                          //     className="Icon"
                          //     style={{
                          //       display: "flex",
                          //       justifyContent: "center",
                          //     }}
                          //   >
                          //     <DescriptionOutlinedIcon
                          //       style={{ color: "white" }}
                          //       onClick={handlePendencyReport}
                          //     />
                          //   </div>
                          // ) :
                          null}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              style={{
                                fontFamily: "Montserrat",
                                fontSize: "12px",
                                fontWeight: 500,
                                lineHeight: "16px",
                                //alignContent:"center"
                              }}
                              onClick={
                                text === "Pendency Report"
                                  ? handlePendencyReport
                                  : ""
                              }
                              // onclick = {history.push("/")}
                            >
                              {text}
                            </Typography>
                          }
                          style={{
                            minWidth: "70px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        />
                      </ListItem>
                    ) : (
                      <ListItem
                        button
                        key={text}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          paddingTop: text == "" ? "0px" : "16px",
                          paddingBottom: text == "" ? "4px" : "8px",
                          padding:
                            text == "All Timesheet Reports"
                              ? "2px 1px 1px 1px"
                              : "",
                        }}
                        onClick={(e) => {
                          handleIconButton(text);
                        }}
                      >
                        <ListItemIcon
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {/* {console.log("check all item", text)} */}
                          {index === 0 ? (
                            <div
                              className="Icon"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <HomeIcon style={{ color: "#9DA3B3" }} />
                            </div>
                          ) : index === 1 ? (
                            <div
                              className="Icon"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {text == "Timesheet" ? (
                                <FeaturedPlayListOutlinedIcon
                                  style={{ color: "white" }}
                                />
                              ) : (
                                <AttachMoneyIcon style={{ color: "white" }} />
                              )}
                            </div>
                          ) : // ) : index === 2 ? (
                          //   <div
                          //     className="Icon"
                          //     style={{ display: "flex", justifyContent: "center" }}
                          //   >
                          //     <FeaturedPlayListIcon style={{ color: "white" }} />
                          //   </div>
                          delegationFlag?.allExpReportsFlag == 1 &&
                            props.module != "timesheetManagement" ? (
                            <div
                              className="Icon"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <DescriptionOutlinedIcon
                                style={{ color: "white" }}
                              />
                            </div>
                          ) : null}
                          {/* {console.log("inside first if" , timesheetReportAccessData)} */}
                          {props.module == "timesheetManagement" &&
                          text == "Reports" ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                className="Icon"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <DescriptionOutlinedIcon
                                  style={{ color: "white" }}
                                />
                              </div>
                              <div
                                style={{
                                  minHeight: "20px",
                                  backgroundColor: "#0B1941",
                                  display: "flex",
                                  justifyContent: "left",
                                }}
                              >
                                <Accordion
                                  expanded={expanded === "panel1"}
                                  onChange={handleChange("panel1")}
                                  style={{
                                    width: "80px",
                                    minHeight: "20px",
                                    backgroundColor: "#0B1941",

                                    elevation: 0,
                                    boxShadow: "none",
                                  }}
                                >
                                  <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                    style={{
                                      width: "80px",
                                      minHeight: "10px",
                                      backgroundColor: "#0B1941",
                                      height: "25px",
                                    }}
                                  >
                                    <ArrowRightIcon
                                      style={{
                                        fontSize: "18px",
                                        color: "white",
                                      }}
                                    />
                                    <Typography
                                      style={{
                                        fontSize: "12px",
                                        color: "white",
                                        backgroundColor: "#0B1941",
                                        marginRight: "22px",
                                      }}
                                    >
                                      Reports
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails
                                    style={{
                                      width: "80px",
                                      color: "white",
                                      padding: "8px 5px 16px 8px",
                                    }}
                                  >
                                    {timesheetReportAccessData?.map((item) => {
                                      return (
                                        <Box
                                          key={item.reportValue}
                                          style={{
                                            color: "white",
                                            width: "76px",
                                            fontSize: "10px",
                                            paddingBottom: "8px",
                                            display: "flex", // Use flex display to have items on the same line
                                            alignItems: "center",
                                          }}
                                          onClick={(e) => {
                                            setIsLoader(true);
                                            item.reportName ==
                                            "AllTimesheetReport"
                                              ? handleIconButton(
                                                  "All Timesheet Reports"
                                                )
                                              : handlePendencyReport(
                                                  item.reportName
                                                );
                                            //   item.reportName !== ""
                                            // ? handlePendencyReport
                                            // : item.reportName === "ApprovedReport"
                                            // ? handleApprovalReport
                                            // : item.reportName === "AuditReport"
                                            // ? handleAuditReport
                                            // : ""
                                          }}
                                        >
                                          <Box>{"-   "}</Box>
                                          <Box>
                                            &nbsp;{item.reportDisplayName}
                                          </Box>
                                          {/* {console.log("item" , item.reportName)} */}
                                        </Box>
                                      );
                                    })}
                                  </AccordionDetails>
                                </Accordion>
                              </div>
                            </div>
                          ) : null}
                          {/* {props.module == "timesheetManagement" &&
                          text == "All Timesheet Reports" ? (
                            <>
                              {" "}
                              <ListItem
                                button
                                key={text}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  padding: "1px",
                                }}
                                onClick={(e) => {
                                  handleIconButton(text);
                                  // handlePendencyReport(
                                  //   "alltimesheetreport"
                                  // );
                                }}
                              >
                                <ListItemIcon
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {index === 0 ? (
                                    <div
                                      className="Icon"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <HomeIcon style={{ color: "#9DA3B3" }} />
                                    </div>
                                  ) : index === 3 ? (
                                    <div
                                      className="Icon"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {text == "All Timesheet Reports" ? (
                                        <FeaturedPlayListOutlinedIcon
                                          style={{ color: "white" }}
                                        />
                                      ) : (
                                        <AttachMoneyIcon
                                          style={{ color: "white" }}
                                        />
                                      )}
                                    </div>
                                  ) : // ) : index === 2 ? (
                                  //   <div
                                  //     className="Icon"
                                  //     style={{ display: "flex", justifyContent: "center" }}
                                  //   >
                                  //     <FeaturedPlayListIcon style={{ color: "white" }} />
                                  //   </div>
                                  delegationFlag?.allExpReportsFlag == 1 ? (
                                    //&& props.module != "timesheetManagement"
                                    <div
                                      className="Icon"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <DescriptionOutlinedIcon
                                        style={{ color: "white" }}
                                      />
                                    </div>
                                  ) : // timesheetReportAccessData?.pendencyReportFlag == 1 ? (
                                  //   <div
                                  //     className="Icon"
                                  //     style={{
                                  //       display: "flex",
                                  //       justifyContent: "center",
                                  //     }}
                                  //   >
                                  //     <DescriptionOutlinedIcon
                                  //       style={{ color: "white" }}
                                  //       onClick={handlePendencyReport}
                                  //     />
                                  //   </div>
                                  // ) :
                                  null}
                                </ListItemIcon>
                              </ListItem>
                            </>
                          ) : null} */}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              style={{
                                fontFamily: "Montserrat",
                                fontSize: "12px",
                                fontWeight: 500,
                                lineHeight: "16px",
                                textAlign: "center",
                                color:
                                  text === "Home" && "All Reports"
                                    ? "#9DA3B3"
                                    : "white",
                              }}
                            >
                              {text != "Reports" ? text : ""}
                            </Typography>
                          }
                          style={{
                            minWidth: "70px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        />
                      </ListItem>
                    )
                  )}
              </List>
            </div>
          )}
          {/* <div
            // className={classes.drawerContainer}
            // style={{
            //   position: "fixed",
            //   bottom: "0px",
            // }}
          >
            <List>
              <ListItem
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginRight: "-18px",
                }}
              >
                <Link
                  href="https://outlook.office.com/?path=/mail/action/compose&to=IncedoInternalSystems@incedoinc.com&subject="
                  target="_blank"
                  underline="none"
                  // key={key}
                >
                  <ListItemIcon
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <HeadsetMicIcon style={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "12px",
                          fontWeight: 500,
                          lineHeight: "16px",
                          color:
                            text === "Home" && "All Reports"
                              ? "#9DA3B3"
                              : "white",
                        }}
                      >
                        {"Support Documents"}
                      </Typography>
                    }
                    style={{
                      minWidth: "70px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center"
                    }}
                  />
                </Link>
              </ListItem>
            </List>
          </div> */}

          <div
            className={classes.drawerContainer}
            style={{
              display: "flex",
              position: "fixed",
              bottom: "0px",
              maxWidth: "inherit",
              width: "100%",
            }}
          >
            <List style={{ width: "100%" }}>
              <ListItem
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: "0px",
                  paddingBottom: "8px",
                  // marginRight: "-54px",
                }}
              >
                <Link target="_blank" underline="none">
                  <ListItemIcon
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    <StarOutlineIcon
                      style={{ color: "white" }}
                      onClick={handleRating}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "12px",
                          fontWeight: 500,
                          lineHeight: "16px",
                          cursor: "pointer",
                          color:
                            text === "Home" && "All Reports"
                              ? "#9DA3B3"
                              : "white",
                        }}
                        onClick={handleRating}
                      >
                        {"Rate Us"}
                      </Typography>
                    }
                    style={{
                      minWidth: "70px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      paddingBottom: "10px",
                    }}
                  />
                </Link>
                {lastDirecotry === "expense-Management" ||
                lastDirecotry === "timesheet-management" ? (
                  <Link
                    target="_blank"
                    underline="none"
                    onClick={handleSupportDoc}
                    style={{
                      cursor: "pointer",
                      marginBottom: " 10px",
                    }}
                  >
                    <ListItemIcon
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        // cursor: "pointer",
                      }}
                    >
                      <FolderSharedIcon
                        style={{ color: "white" }}
                        // onClick={handleSupportDoc}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          style={{
                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            fontWeight: 500,
                            lineHeight: "16px",
                            cursor: "pointer",
                            color:
                              text === "Home" && "All Reports"
                                ? "#9DA3B3"
                                : "white",
                          }}
                          // onClick={handleSupportDoc}
                        >
                          {"Supporting Docs"}
                        </Typography>
                      }
                      style={{
                        minWidth: "70px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        // paddingBottom: "10px",
                      }}
                    />
                  </Link>
                ) : (
                  <></>
                )}
                <Link
                  href="https://outlook.office.com/?path=/mail/action/compose&to=IncedoInternalSystems@incedoinc.com&subject="
                  target="_blank"
                  underline="none"
                  // key={key}
                >
                  <ListItemIcon
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <MailOutlineIcon style={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "12px",
                          fontWeight: 500,
                          lineHeight: "16px",
                          color:
                            text === "Home" && "All Reports"
                              ? "#9DA3B3"
                              : "white",
                        }}
                      >
                        {"Support"}
                      </Typography>
                    }
                    style={{
                      minWidth: "70px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                </Link>
              </ListItem>
            </List>
          </div>
        </Drawer>
        <main className={classes.content}>
          <Toolbar />

          {location.pathname === "/expense-Management" ? (
            <>
              {matches ? (
                <TempScreen />
              ) : (
                <ExpenseLandingPage
                  ssoEmpId={props.ssoEmpId}
                  delEmpId={props.delEmpId}
                />
              )}
            </>
          ) : location.pathname === "/timesheet-management" ? (
            <>
              <TimesheetLandingPage ssoEmpId={props.ssoEmpId} />
            </>
          ) : location.pathname === "/gmdashboard" ? (
            <GMCalDashboard
              ssoEmpId={props.ssoEmpId}
              delEmpId={props.delEmpId}
            />
          ) : (
            ""
          )}
        </main>
      </div>
      <div>
        <Backdrop
          sx={{
            color: " rgb(227, 232, 247)",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <div>
        <Dialog
          open={openSupportDoc}
          // TransitionComponent={Transition}
          keepMounted
          onClose={handleSupportDocClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Box style={{ minWidth: "600px", minHeight: "450px" }}>
            <DialogTitle>
              <Typography
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  marginLeft: "10px",
                }}
              >
                Supporting Docs
              </Typography>
            </DialogTitle>
            <Divider
              style={{
                backgroundColor: "#0B1941",
                width: "95%",
                height: "1.5px",
                marginLeft: "2.5%",
              }}
              orientation="horizontal"
            />
            <DialogContent style={{ paddingTop: "20px" }}>
              <DialogContentText id="alert-dialog-slide-description">
                <Link
                  href={
                    lastDirecotry === "expense-Management"
                      ? "https://incedoin.sharepoint.com/sites/IncedoSystemsSupportTeam/Shared%20Documents/Forms/AllItems.aspx?ga=1&id=%2Fsites%2FIncedoSystemsSupportTeam%2FShared%20Documents%2FExpense%20Management%20Application%2FVSOPs&viewid=5c1baf72%2D8dfb%2D46a7%2Dbfde%2D57f12449da4a"
                      : "https://incedoin.sharepoint.com/sites/IncedoSystemsSupportTeam/Shared%20Documents/Forms/AllItems.aspx?ga=1&id=%2Fsites%2FIncedoSystemsSupportTeam%2FShared%20Documents%2FTimesheet%20Management%20Application%2FVSOPs&viewid=5c1baf72%2D8dfb%2D46a7%2Dbfde%2D57f12449da4a"
                  }
                  underline="none"
                  target="_blank"
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "30px",
                    }}
                  >
                    <IconButton>
                      <LinkIcon />
                    </IconButton>
                    <Typography>Video-SOP</Typography>
                  </Box>
                </Link>
                <Link
                  href={
                    lastDirecotry === "expense-Management"
                      ? "https://incedoin.sharepoint.com/sites/IncedoSystemsSupportTeam/Shared%20Documents/Forms/AllItems.aspx?ga=1&id=%2Fsites%2FIncedoSystemsSupportTeam%2FShared%20Documents%2FExpense%20Management%20Application%2FSOPs&viewid=5c1baf72%2D8dfb%2D46a7%2Dbfde%2D57f12449da4a"
                      : "https://incedoin.sharepoint.com/sites/IncedoSystemsSupportTeam/Shared%20Documents/Forms/AllItems.aspx?ga=1&id=%2Fsites%2FIncedoSystemsSupportTeam%2FShared%20Documents%2FTimesheet%20Management%20Application%2FSOPs&viewid=5c1baf72%2D8dfb%2D46a7%2Dbfde%2D57f12449da4a"
                  }
                  underline="none"
                  target="_blank"
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "30px",
                    }}
                  >
                    <IconButton>
                      <LinkIcon />
                    </IconButton>
                    <Typography>SOP</Typography>
                  </Box>
                </Link>
              </DialogContentText>
            </DialogContent>
          </Box>
        </Dialog>

        {!matches ? (
          <Box>
            <Dialog
              open={openRating}
              // TransitionComponent={Transition}
              keepMounted
              onClose={handleRatingClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <Box style={{ width: "400px", height: "650px" }}>
                <DialogTitle>
                  <Typography
                    style={{
                      fontWeight: "600",
                      fontSize: "20px",
                      marginLeft: "10px",
                      textAlignLast: "center",
                    }}
                  >
                    Rate and Review <br></br> {ratingHeading}
                  </Typography>
                  <Divider
                    style={{
                      backgroundColor: "#E1E1E1",
                      width: "100%",
                      height: "0.5px",
                      marginLeft: "2.5%",
                      marginTop: "10px",
                    }}
                    orientation="horizontal"
                  />
                </DialogTitle>
                <DialogContent style={{ paddingTop: "7px" }}>
                  <DialogContentText id="alert-dialog-slide-description">
                    <Box>
                      <Box style={{ width: "300px", marginLeft: "20px" }}>
                        <Typography
                          style={{ textAlign: "center", color: "#000" }}
                        >
                          <span style={{ color: "#2CB0FE", fontWeight: "400" }}>
                            {ssoEmpIdDetails ? ssoEmpIdDetails?.empName : ""}
                          </span>
                          , we'd like to know your reviews on {ratingHeading}.
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: 300,
                          display: "flex",
                          alignItems: "center",
                          paddingTop: "40px",
                          textAlignLast: "center",
                        }}
                      >
                        <Box sx={{ width: 100 }}></Box>
                        <Box sx={{ width: 200 }}>
                          <Rating
                            name="hover-feedback"
                            value={value}
                            getLabelText={getLabelText}
                            size="large"
                            onChange={(event, newValue) => {
                              newValue === null
                                ? setValue(-1)
                                : setValue(newValue);
                            }}
                            onChangeActive={(event, newHover) => {
                              setHover(newHover);
                            }}
                            emptyIcon={
                              <StarOutlineIcon
                                style={{ opacity: 0.8, color: "#2CB0FE" }}
                                fontSize="inherit"
                              />
                            }
                            style={{ textAlignLast: "center" }}
                          />
                        </Box>
                        <Box sx={{ width: 50 }}></Box>
                      </Box>

                      {value !== null && ratingLabels && (
                        <Box
                          style={{
                            textAlignLast: "center",
                            paddingTop: "5px",
                            height: "20px",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "14px", color: "#000" }}
                          >
                            {
                              ratingLabels[hover !== -1 ? hover - 1 : value - 1]
                                ?.ratingDescription
                            }
                          </Typography>
                        </Box>
                      )}
                    </Box>

                    <Box>
                      <Box
                        style={{ textAlignLast: "center", paddingTop: "25px" }}
                      >
                        <Typography
                          style={{ fontWeight: "550", color: "#2CB0FE" }}
                        >
                          Write a Review
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          paddingTop: "10px",
                        }}
                      >
                        <TextField
                          id="review"
                          value={review}
                          placeholder="What did you like or dislike? Write here your feedback about this application."
                          multiline
                          rows={3}
                          onChange={(e) => setReview(e.target.value)}
                          style={{
                            width: "100%",
                          }}
                          InputProps={{
                            inputProps: {
                              maxLength: 200,
                            },
                          }}
                        ></TextField>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "6px",
                        }}
                      >
                        <Box style={{ width: "50px" }}></Box>
                        <Box>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              width: "120px",
                              textTransform: "none",
                              height: "35px",
                              borderRadius: "5px",
                              color: "#FFF",
                              backgroundColor: isSubmitDisabled
                                ? "#9E9E9E"
                                : "#043365",
                            }}
                            onClick={handleRatingSubmit}
                            disabled={isSubmitDisabled ? true : false}
                          >
                            Submit
                          </Button>
                        </Box>
                        <Box>
                          <Link underline="none" target="_blank">
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                height: "30px",
                              }}
                            >
                              <Typography
                                onClick={handleRatingSkipClose}
                                style={{
                                  cursor: "pointer",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                }}
                              >
                                Skip {`>`}
                              </Typography>
                            </Box>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </DialogContentText>
                </DialogContent>
              </Box>
            </Dialog>
          </Box>
        ) : (
          <Box>
            <Dialog
              open={openRating}
              // TransitionComponent={Transition}
              keepMounted
              onClose={handleRatingClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <Box style={{ width: "300px", height: "500px" }}>
                <DialogTitle>
                  <Typography
                    style={{
                      fontWeight: "600",
                      fontSize: "20px",
                      marginLeft: "10px",
                      textAlignLast: "center",
                    }}
                  >
                    Rate and Review <br></br> {ratingHeading}
                  </Typography>
                  <Divider
                    style={{
                      backgroundColor: "#E1E1E1",
                      width: "100%",
                      height: "0.5px",
                      marginLeft: "2.5%",
                      marginTop: "10px",
                    }}
                    orientation="horizontal"
                  />
                </DialogTitle>
                <DialogContent style={{ paddingTop: "12px" }}>
                  <DialogContentText id="alert-dialog-slide-description">
                    <Box style={{ marginLeft: "-44px" }}>
                      <Box style={{ width: "300px", marginLeft: "20px" }}>
                        <Typography
                          style={{
                            textAlign: "center",
                            color: "#000",
                            width: "18rem",
                          }}
                        >
                          <span style={{ color: "#2CB0FE", fontWeight: "400" }}>
                            {ssoEmpIdDetails ? ssoEmpIdDetails?.empName : ""}
                          </span>
                          , we'd like to know your reviews on {ratingHeading}.
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: 300,
                          display: "flex",
                          alignItems: "center",
                          paddingTop: "40px",
                          textAlignLast: "center",
                        }}
                      >
                        <Box sx={{ width: 100 }}></Box>
                        <Box sx={{ width: 200 }}>
                          <Rating
                            name="hover-feedback"
                            value={value}
                            getLabelText={getLabelText}
                            size="large"
                            onChange={(event, newValue) => {
                              newValue === null
                                ? setValue(-1)
                                : setValue(newValue);
                            }}
                            onChangeActive={(event, newHover) => {
                              setHover(newHover);
                            }}
                            emptyIcon={
                              <StarOutlineIcon
                                style={{ opacity: 0.8, color: "#2CB0FE" }}
                                fontSize="inherit"
                              />
                            }
                            style={{ textAlignLast: "center" }}
                          />
                        </Box>
                        <Box sx={{ width: 50 }}></Box>
                      </Box>

                      {value !== null && ratingLabels && (
                        <Box
                          style={{
                            textAlignLast: "center",
                            paddingTop: "5px",
                            marginLeft: "3rem",
                            height: "1rem",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "14px", color: "#000" }}
                          >
                            {
                              ratingLabels[hover !== -1 ? hover - 1 : value - 1]
                                ?.ratingDescription
                            }
                          </Typography>
                        </Box>
                      )}
                    </Box>

                    <Box>
                      <Box
                        style={{ textAlignLast: "center", paddingTop: "25px" }}
                      >
                        <Typography
                          style={{ fontWeight: "550", color: "#2CB0FE" }}
                        >
                          Write a Review
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          paddingTop: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        <TextField
                          id="review"
                          value={review}
                          placeholder="What did you like or dislike? Write here your feedback about this application."
                          multiline
                          rows={3}
                          onChange={(e) => setReview(e.target.value)}
                          style={{
                            width: "100%",
                          }}
                          InputProps={{
                            inputProps: {
                              maxLength: 200,
                            },
                          }}
                        ></TextField>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "6px",
                        }}
                      >
                        <Box style={{ width: "50px" }}></Box>
                        <Box>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              width: "120px",
                              textTransform: "none",
                              backgroundColor: isSubmitDisabled
                                ? "#9E9E9E"
                                : "#043365",
                            }}
                            onClick={handleRatingSubmit}
                            disabled={isSubmitDisabled ? true : false}
                          >
                            Submit
                          </Button>
                        </Box>
                        <Box>
                          <Link underline="none" target="_blank">
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                height: "30px",
                              }}
                            >
                              <Typography
                                onClick={handleRatingSkipClose}
                                style={{
                                  cursor: "pointer",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                }}
                              >
                                Skip {`>`}
                              </Typography>
                            </Box>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </DialogContentText>
                </DialogContent>
              </Box>
            </Dialog>
          </Box>
        )}
        {!matches ? (
          <Box>
            <Dialog
              open={thankYouRatingOpen}
              // TransitionComponent={Transition}
              keepMounted
              // onClose={handleThankyou}
              aria-describedby="alert-dialog-slide-description"
            >
              <Box style={{ width: "400px", height: "350px" }}>
                <DialogTitle>
                  <CheckCircleIcon className={classes.successIcon} />
                  <Typography
                    style={{
                      fontWeight: "600",
                      fontSize: "20px",
                      marginLeft: "10px",
                      textAlignLast: "center",
                    }}
                  >
                    Thanks for <br></br>Rating and Review.
                  </Typography>
                </DialogTitle>
                <DialogContent style={{ paddingTop: "12px" }}>
                  <DialogContentText id="alert-dialog-slide-description">
                    <Box>
                      <Box
                        sx={{
                          width: 300,
                          display: "flex",
                          alignItems: "center",
                          paddingTop: "40px",
                          textAlignLast: "center",
                        }}
                      >
                        <Box sx={{ width: 100 }}></Box>
                        <Box sx={{ width: 200 }}>
                          <Rating
                            name="hover-feedback"
                            value={value}
                            getLabelText={getLabelText}
                            size="large"
                            emptyIcon={
                              <StarOutlineIcon
                                style={{ opacity: 0.8, color: "#2CB0FE" }}
                                fontSize="inherit"
                              />
                            }
                            style={{ textAlignLast: "center" }}
                          />
                        </Box>
                        <Box sx={{ width: 50 }}></Box>
                      </Box>
                    </Box>
                  </DialogContentText>
                </DialogContent>
              </Box>
            </Dialog>
          </Box>
        ) : (
          <Box>
            <Dialog
              open={thankYouRatingOpen}
              // TransitionComponent={Transition}
              keepMounted
              // onClose={handleThankyou}
              aria-describedby="alert-dialog-slide-description"
            >
              <Box style={{ width: "300px", height: "250px" }}>
                <DialogTitle>
                  <CheckCircleIcon className={classes.successIcon} />
                  <Typography
                    style={{
                      fontWeight: "600",
                      fontSize: "20px",
                      marginLeft: "10px",
                      textAlignLast: "center",
                    }}
                  >
                    Thanks for <br></br>Rating and Review.
                  </Typography>
                </DialogTitle>
                <DialogContent style={{ paddingTop: "12px" }}>
                  <DialogContentText id="alert-dialog-slide-description">
                    <Box style={{ marginLeft: "-44px" }}>
                      <Box
                        sx={{
                          width: 300,
                          display: "flex",
                          alignItems: "center",
                          paddingTop: "40px",
                          textAlignLast: "center",
                        }}
                      >
                        <Box sx={{ width: 100 }}></Box>
                        <Box sx={{ width: 200 }}>
                          <Rating
                            name="hover-feedback"
                            value={value}
                            getLabelText={getLabelText}
                            size="large"
                            emptyIcon={
                              <StarOutlineIcon
                                style={{ opacity: 0.8, color: "#2CB0FE" }}
                                fontSize="inherit"
                              />
                            }
                            style={{ textAlignLast: "center" }}
                          />
                        </Box>
                        <Box sx={{ width: 50 }}></Box>
                      </Box>
                    </Box>
                  </DialogContentText>
                </DialogContent>
              </Box>
            </Dialog>
          </Box>
        )}
      </div>
    </>
  );
}