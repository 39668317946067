import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
  colors,
  StepButton,
  MuiThemeProvider,
  Box,
  getMuiTheme,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Fade from "@mui/material/Fade";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    color: "green",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  fill: {
    border: "3px solid #349259",
    borderRadius: "29px",
    fill: "white",
  },
  steps: {
    position: "absolute",
    top: -4,
    left: -5,
    width: "120%",
    height: "100%",
    textAlign: "center",
    lineHeight: "24px",
    border: "3px solid #349259",
    borderRadius: "35px",
  },
  stepperInfo: {
    // position: "fixed",
    marginLeft: "-37px",
  },
  statusHeading: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "8px",
    color: "rgba(255, 255, 255, 0.9)",
    // opacity: "0.6",
  },
  statusName: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "10px",
    marginRight: "2px",
    // alignItems: "center",
    // paddingLeft: "10px",
    color: "rgba(255, 255, 255, 0.85)",
  },
  statusProjectName: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "8px",
    // alignItems: "center",
    // paddingLeft: "10px",
    marginTop: "2px",
    color: "rgba(255, 255, 255, 0.9)",
    opacity: "0.6",
  },
  statusMessage: {
    fontFamily: "Montserrat",
    fontStyle: "italic",
    fontWeight: 400,
    fontSize: "8px",
    color: "#FFFFFF",
  },
  lines: {
    // "& .MuiStepConnector-line":{

    // }
    borderColor: "#bdbdbdab",
  },
  rootStepper: {
    backgroundColor: " #F8F9FF",
  },
  rootStepper1: {
    backgroundColor: " #ffffff",
    padding: "10px",
    width: "600px",
  },
  chipLabel: {
    overflow: "visible",
  },
  statusHeading: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "8px",
    color: "rgba(255, 255, 255, 0.9)",
  },
  statusName: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "10px",
    marginRight: "2px",

    color: "rgba(255, 255, 255, 0.85)",
  },
  statusProjectName: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "8px",
    marginTop: "2px",
    color: "rgba(255, 255, 255, 0.9)",
    opacity: "0.6",
  },
  stepperResponsive: {
    fontFamily: "Montserrat",
    fontSize: "0.6rem",
    fontWeight: "600",
    lineHeight: "1rem",
    letterSpacing: "0rem",
    textAlign: "center",
  },
  stepperResponsiveTop: {
    fontFamily: "Montserrat",
    fontSize: "0.6rem",
    fontWeight: "600",
    lineHeight: "1rem",
    letterSpacing: "0rem",
    textAlign: "center",
    paddingBottom: "15px",
  },
}));

export default function StepperExpense({ myExpenseList, data }) {
  const classes = useStyles();
  const status = "Draft";
  const step = 1;
  ////console.log("data", data.response.comments);
  const matches = useMediaQuery("(max-width:600px)");

  const handleText = (value) => {
    // console.log("into hip", value);
    return (
      <>
        <Box>
          <Typography
            className={
              matches && value.date != null
                ? classes.stepperResponsive
                : matches
                ? classes.stepperResponsiveTop
                : ""
            }
          >
            {value.status}
          </Typography>
          <Typography className={matches ? classes.stepperResponsive : ""}>
            {value.date}
          </Typography>
        </Box>
      </>
    );
  };

  const LensIcon = (props) => {
    return (
      <svg style={{ width: "20px", height: "20px" }}>
        <path
          className={classes.fill}
          d="M 12 0 a 12 12 0 1 0 0 24 a 12 12 0 0 0 0 -24 Z m -2 17 l -5 -5 l 1.4 -1.4 l 3.6 3.6 l 7.6 -7.6 L 19 8 l -9 9 Z"
        />
      </svg>
    );
  };

  const handleToolTip = (value) => {
    const dummyArray = [];

    //console.log("value", value);
    //console.log("value in tooltip", value?.tooltip_msg?.split("##"));
    // const array = value?.tooltip_msg?.split(",");
    const array = value?.tooltip_msg?.split("##");
    //console.log("array123",array)
    if (array !== undefined) {
      array.map((item, key) => {
        //console.log("array1234", array[key]?.split("|"));
        const a = array[key]?.split("|");
        const obj2 = { ...a };
        //console.log("array12345 item obje", obj2,value.stage);
        if (value.stage != 3) {
          dummyArray.push({
            managerid: obj2["0"],
            projCode: obj2["1"],
            reason: obj2["2"],
          });
        } else {
          //console.log("array12341",dummyArray)
          dummyArray.push({
            managerid: obj2["0"],

            reason: obj2["1"],
          });
        }

        //console.log("array123456 dummy array", dummyArray);
      });
    }

    return (
      <>
        <Box>
          <Typography
            className={classes.statusHeading}
            style={{ textAlign: "left", paddingTop: "5px", marginLeft: "5px" }}
          >
            {value?.prefix_msg}
          </Typography>
        </Box>
        {dummyArray?.map((value) => {
          return (
            <>
              <Box style={{ display: "flex" }}>
                <Typography
                  className={classes.statusName}
                  style={{
                    display: "flex",
                    textAlign: "left",
                    paddingLeft: "5px",
                  }}
                >
                  {value.managerid}
                </Typography>
                {value.projCode !== undefined && (
                  <Typography className={classes.statusName}>{"|"} </Typography>
                )}
                <Typography className={classes.statusProjectName}>
                  <span style={{ paddingLeft: "5px" }}> {value.projCode} </span>
                </Typography>{" "}
              </Box>

              <Typography
                className={classes.statusMessage}
                style={{
                  paddingBottom: "5px",
                  marginLeft: "5px",
                  textAlign: "left",
                }}
              >
                {value.reason}
              </Typography>
            </>
          );
        })}
      </>
    );
  };

  const StepIcon = ({ label, value }) => {
    const [stepperOpen, setStepperOpen] = useState(false);

    const handleTooltipClose = () => {
      setStepperOpen(false);
    };

    const handleTooltipOpen = () => {
      setStepperOpen(true);
    };

    return (
      <div style={{ position: "relative" }}>
        <LensIcon />
        <div
          className={classes.steps}
          style={{
            border:
              label === parseInt(myExpenseList?.currentstage) &&
              myExpenseList?.ExpFinalStatus == "Rejected"
                ? "3px solid #D40606"
                : (label === parseInt(myExpenseList?.currentstage) &&
                    myExpenseList?.ExpFinalStatus == "In Progress") ||
                  (label === parseInt(myExpenseList?.currentstage) &&
                    myExpenseList?.ExpFinalStatus == "Approved")
                ? "3px solid #E0B15C"
                : label === parseInt(myExpenseList?.currentstage) &&
                  myExpenseList?.ExpFinalStatus == "Paid"
                ? "3px solid #349259"
                : label < parseInt(myExpenseList?.currentstage)
                ? "3px solid #349259"
                : "3px solid #bdbdbd",
          }}
        >
          {matches ? (
            <Tooltip
              open={stepperOpen}
              title={handleToolTip(value)}
              onClose={handleTooltipClose}
              disableHoverListener={
                label == 1 || label == 4 || label == 5 ? true : false
              }
              leaveDelay={2000}
              arrow
              onClick={() => handleTooltipOpen()}
            >
              <Typography
                style={{
                  color:
                    label === parseInt(myExpenseList?.currentstage) &&
                    myExpenseList?.ExpFinalStatus == "Rejected"
                      ? " #D40606"
                      : (label === parseInt(myExpenseList?.currentstage) &&
                          myExpenseList?.ExpFinalStatus == "In Progress") ||
                        (label === parseInt(myExpenseList?.currentstage) &&
                          myExpenseList?.ExpFinalStatus == "Approved")
                      ? " #E0B15C"
                      : label === parseInt(myExpenseList?.currentstage) &&
                        myExpenseList?.ExpFinalStatus == "Paid"
                      ? " #349259"
                      : label < parseInt(myExpenseList?.currentstage)
                      ? "#349259"
                      : "#bdbdbd",
                }}
              >
                {label}
              </Typography>
            </Tooltip>
          ) : (
            <Tooltip
              title={handleToolTip(value)}
              disableHoverListener={
                label == 1 || label == 4 || label == 5 ? true : false
              }
              // label === parseInt(myExpenseList?.currentstage) ? false : true
              arrow
            >
              <Typography
                style={{
                  color:
                    label === parseInt(myExpenseList?.currentstage) &&
                    myExpenseList?.ExpFinalStatus == "Rejected"
                      ? " #D40606"
                      : (label === parseInt(myExpenseList?.currentstage) &&
                          myExpenseList?.ExpFinalStatus == "In Progress") ||
                        (label === parseInt(myExpenseList?.currentstage) &&
                          myExpenseList?.ExpFinalStatus == "Approved")
                      ? " #E0B15C"
                      : label === parseInt(myExpenseList?.currentstage) &&
                        myExpenseList?.ExpFinalStatus == "Paid"
                      ? " #349259"
                      : label < parseInt(myExpenseList?.currentstage)
                      ? "#349259"
                      : "#bdbdbd",
                }}
              >
                {label}
              </Typography>
            </Tooltip>
          )}

          <Chip
            classes={{ label: classes.chipLabel }}
            label={handleText(value)}
            style={{
              backgroundColor: matches ? "#ffffff" : " #F8F9FF",
              marginTop: "12px",
              display: "flex",
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <Stepper
      activeStep={parseInt(myExpenseList?.currentstage) - 1}
      classes={{
        line: classes.lines,
        root: matches ? classes.rootStepper1 : classes.rootStepper,
      }}
      alternativeLabel
      linear={false}
      orientation="horizontal"
    >
      {myExpenseList?.stepperdetails?.map((value, key) => {
        // console.log("value of step", value);
        return (
          <Step key={1}>
            {/* <StepLabel>{value}</StepLabel> */}
            <StepButton
              style={{ fontSize: "0.54rem" }}
              icon={<StepIcon label={key + 1} value={value} />}
              onClick={() => console.log(" ")}
            />
          </Step>
        );
      })}
    </Stepper>
  );
}
