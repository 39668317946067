import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles, styled } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import { NumericFormat } from "react-number-format";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import TableCell from "@material-ui/core/TableCell";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Drawer from "@material-ui/core/Drawer";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import moment from "moment";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import Chip from "@material-ui/core/Chip";
import CloseIcon from "@material-ui/icons/Close";
import Avatar from "@material-ui/core/Avatar";
import { useDispatch, useSelector } from "react-redux";
import DeligationFilter from "./delegationFilterTs";
import Badge from "@material-ui/core/Badge";
import {
  Typography,
  Box,
  Grid,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  TextField,
  Select,
  Button,
  Tabs,
  Tab,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
    getTimesheetSearchEmployeeData,
    getTimesheetSearchDelegateData,
    getTimesheetViewDelegateData,
    postTimesheetAddDelegateData,
    postTimesheetEditDelegateData,
    ResetDeligatinSnakbarTs,
} from "../redux/TimeSheet/actionTs";

import { FieldValidator } from "./validateDelegationTs";
import DeligationSnakbar from "./delegationSnackbarTs.js";
import { useLocation } from "react-router";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import history from "../history";
import Encrypt from "../Utils/encryption";
import { style } from "@material-ui/system";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 13,
    top: 31,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
  { id: "Employee", numeric: true, disablePadding: false, label: "Employee" },
  { id: "Delegate", numeric: true, disablePadding: false, label: "Delegate" },
  {
    id: "StartDate",
    numeric: true,
    disablePadding: false,
    label: "Start Date",
  },
  { id: "EndDate", numeric: true, disablePadding: false, label: "End Date" },
  { id: "button", numeric: true, disablePadding: false, label: "" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    dropeDownList,
    deligationTableData,
    onFilterArray,
    deligationTableDataList,
  } = props;
  const [filterPopup, setFilterPopup] = React.useState("");
  const [filterAnchor, setFilterAnchor] = React.useState("");
  const [filtersetting, setFilterSetting] = React.useState(false);
  const [filterOffToggle, setFilterOffToggle] = React.useState("");
  const [rowVal, setRowVal] = React.useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState();
  const [currentIndex, setCurrentIndex] = React.useState();
  const [selected, setSelected] = React.useState([]);
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const handleFilterPopup = (event) => {
    setFilterSetting(!filtersetting);
    setFilterAnchor(event.currentTarget);
  };

  const handleFilterToggle = () => {
    setFilterSetting(false);
  };
  const handleClearFilter = () => {
    onFilterArray(deligationTableDataList);
  };
  const handleApplyFilter = (type, value1, value2) => {
    let dateArray = [];

    if (type == "Employee") {
      //console.log("in the Employee filter");

      dateArray = deligationTableData?.filter((item) =>
        item?.empName?.includes(value1?.empName)
      );
      //console.log("filtered array", dateArray);
    } else if (type == "Delegate") {
      //console.log("deligate value", value1);
      dateArray = deligationTableData?.filter((item) =>
        item?.delName?.includes(value1?.empName)
      );
    } else if (type == "Start Date") {
      const date = moment(value1).format();
      const date2 = date.split("+");
      //console.log("Start Date", date2[0]);
      dateArray = deligationTableData?.filter((item) =>
        item?.startDate?.includes(date2[0])
      );
    } else if (type == "End Date") {
      if (value1 !== null) {
        const date = moment(value1).format();
        const date2 = date.split("+");
        //console.log(" End Date", date2[0]);
        dateArray = deligationTableData?.filter((item) =>
          item?.endDate?.includes(date2[0])
        );
      }
    }

    //console.log("array", dateArray);
    onFilterArray(dateArray);
    setFilterOffToggle(type);
  };
  const handleFilterOff = () => {
    onFilterArray(deligationTableDataList);
    setFilterOffToggle(false);
  };
  const matches = useMediaQuery("(max-width:600px)");
  return (
    <TableHead className={classes.header}>
      <TableRow style={{ height: 10 }}>
        <TableCell style={{ padding: "0.4%", maxWidth: "90%" }}>
          &nbsp;
        </TableCell>
        {/* <TableCell style={{padding:"0.4%"}}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              
                              name="checkedB"
                              color="primary"
                            />
                          }
                          
                        />
                      </TableCell> */}
        {/* ayushi */}
        {/* {console.log("headcell", headCells)} */}

        {headCells.map((headCell) => (
          <TableCell
            style={{
              fontSize: "10px",
              padding: "0.5%",
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            {headCell.label}

            <Box>
              <Typography
                className={classes.headerName}
                style={{ display: "flex" }}
              ></Typography>
            </Box>
          </TableCell>
        ))}
        {filterPopup !== "" && (
          <DeligationFilter
            filtersetting={filtersetting}
            filterAnchor={filterAnchor}
            filterType={filterPopup}
            handleApplyFilter={handleApplyFilter}
            handleClearFilter={handleClearFilter}
            dropeDownList={dropeDownList}
            handleFilterToggle={handleFilterToggle}
          />
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "hidden",
  },
  paper: {
    width: "90%",
    border: "1px solid #979797",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    borderRadius: "8px",
    "&.MuiTableCell-root": {
      padding: "0px",
    },
  },
  table: {
    "&.MuiTableCell-root": {
      padding: "0px",
    },
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    width: "114px",
    // width: "170px",
  },
  formControl4: {
    width: "114px",
    marginRight: "5px",
    height: "50px",
    //marginTop:"5px",
  },
  formControl1: {
    width: "341px",
    //width:"60px",
    height: "70px",
    marginLeft: "10px",
    marginBottom: "-20px",
    "& .MuiFormLabel-root": {
      // ["@media (max-width:600px)"]: { fontSize: "12px", fontWeight: "500" },
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "500",
      color: "#2B2D30",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "2px solid #162D6E",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#515961",
        ["@media (max-width:600px)"]: { fontSize: "10px" },
      },
      "& .MuiSelect-selectMenu": {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "500",
        color: "#2B2D30",
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#FFFFFF",
        //["@media (max-width:600px)"]: { fontSize: "13px" }
      },
      "& .MuiOutlinedInput-input": {
        ["@media (max-width:600px)"]: { fontSize: "13px" },
      },
    },
    "& .MuiOutlinedInput-adornedEnd": {
      ["@media (max-width:600px)"]: { paddingRight: "0px" },
    },
  },
  formControl2: {
    width: "112%",
    height: "70px",
    marginLeft: "9px",
    "& .MuiFormLabel-root": {
      // ["@media (max-width:600px)"]: { fontSize: "12px", fontWeight: "500" },
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "500",
      color: "#2B2D30",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "2px solid #162D6E",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#515961",
        ["@media (max-width:600px)"]: { fontSize: "13px" },
      },
      "& .MuiSelect-selectMenu": {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "500",
        color: "#2B2D30",
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#FFFFFF",
        //["@media (max-width:600px)"]: { fontSize: "13px" }
      },
      "& .MuiOutlinedInput-input": {
        ["@media (max-width:600px)"]: { fontSize: "13px" },
      },
    },
    "& .MuiOutlinedInput-adornedEnd": {
      ["@media (max-width:600px)"]: { paddingRight: "0px" },
    },
  },
  formControl3: {
    width: "106%",
    height: "70px",
    //marginLeft: "9px",
    "& .MuiFormLabel-root": {
      // ["@media (max-width:600px)"]: { fontSize: "12px", fontWeight: "500" },
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "500",
      color: "#2B2D30",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "2px solid #162D6E",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#515961",
        ["@media (max-width:600px)"]: { fontSize: "13px" },
      },
      "& .MuiSelect-selectMenu": {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "500",
        color: "#2B2D30",
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#FFFFFF",
        //["@media (max-width:600px)"]: { fontSize: "13px" }
      },
      "& .MuiOutlinedInput-input": {
        ["@media (max-width:600px)"]: { fontSize: "13px" },
      },
    },
    "& .MuiOutlinedInput-adornedEnd": {
      ["@media (max-width:600px)"]: { paddingRight: "0px" },
    }, //siddhant
    "& .MuiFormHelperText-root": {
      // "& .Mui-error": {
      fontSize: "10px",
      lineHeight: "10px",
      // },
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  avatar: {
    maxWidth: theme.spacing(3),
    maxHeight: theme.spacing(3),
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  shortName: {
    borderRadius: "45px",
    background: "rgba(79, 163, 125, 0.2)",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    width: "29px",
    height: "23px",
    textAlign: "center",
    color: "#FFFFFF",
    marginLeft: "6px",
  },
  description: {
    marginLeft: "8px",
  },
  fullName: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "10px",
    color: "#000000",
    lineHeight: "12.19px",
  },
  designation: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "8px",
    color: "#000000",
    lineHeight: "9.75px",
  },
  addExpenseButton: {
    height: "27px",
    width: "106px",
    background: "#0B1941",
    boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    borderRadius: "5px",
    marginTop: "-20px",
    marginRight: "-129px",
    marginLeft: "40px",
    "&:hover": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
    "&:focus": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
  },
  saveButton: {
    height: "28px",
    width: "60px",
    background: " #0B1941",
    boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",

    //marginLeft: "252px",
    //marginTop: "5px",
    //marginBottom: "5px",
    "&:hover": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
    "&:focus": {
      background: " #0B1941",
      boxShadow: "0px 4px 8px -4px rgba(58, 53, 65, 0.42)",
    },
  },
  buttonText: {
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Montserrat",
    fontSize: "10px",
    fontWeight: 500,
  },
  addButtonGrid: {
    display: "flex",
    justifyContent: "end",
    //paddingRight: "60px",
    marginLeft: "142px",
    marginTop: "-29px",
  },
  addDelText: {
    color: "rgb(43, 45, 48)",
    display: "flex",
    marginTop: "5px",
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 600,
  },
  addDelText1: {
    color: "rgb(43, 45, 48)",
    display: "flex",
    marginTop: "5px",
    fontFamily: "Montserrat",
    marginLeft: "-15px",
    fontSize: "12px",
    fontWeight: 600,
  },
  buttonGrid: {
    display: "flex",
    justifyContent: "end",
    marginTop: "32px",
    paddingRight: "32px",
  },
  tableRow: {
    height: 58,
    "&.MuiTableRow-root.MuiTableRow-hover:hover": {
      backgroundColor: "#EFF2F8",
    },
    "&.MuiTableRow-root.Mui-selected": {
      background: "#EFF2F8",
    },
  },
  inputTabeRow: {
    background: "#F0F6FB",
    border: "2px solid #71BBE8",
    borderRadius: "0px",
    height: "58px",
    //transform: 'scale(0.8)',

    "&.MuiTableRow-root.MuiTableRow-hover:hover": {
      backgroundColor: "#EFF2F8",
    },
    "&.MuiTableRow-root.Mui-selected": {
      background: "#EFF2F8",
    },
  },

  // inputTableRow1: {
  //   background: "#F0F6FB",
  //   border: "2px solid #71BBE8",
  //   borderRadius: "0px",
  //   height: 58,
  //   maxWidth: "10%",
  //   "&.MuiTableRow-root.MuiTableRow-hover:hover": {
  //     backgroundColor: "#EFF2F8",
  //   },
  //   "&.MuiTableRow-root.Mui-selected": {
  //     background: "#EFF2F8",
  //   },
  // },

  header: {
    background: "#F4F4F4",
    borderRadius: "8px 8px 0px 0px",
    maxWidth: "1200px",
    maxHeight: "58px",
  },
  headerName: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "700",
    color: "rgba(9, 10, 11, 0.87)",
    alignItems: "center",
  },
  headerName1: {
    fontFamily: "Montserrat",
    fontSize: "10px",
    fontWeight: "700",
    color: "rgba(9, 10, 11, 0.87)",
    alignItems: "center",
  },
  nonSticky: {
    position: "sticky",
    left: 0,
    background: "#F4F4F4",
    zIndex: 800,
  },
  DropeDown: {
    //width: "284px",
    width: "114px",
  },
  DropeDown2: {
    //width: "284px",
    width: "114px",
  },
  errorText: {
    color: "#CB444A",
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: 0,
    backgroundColor: "#F8F9FF",
    textAlign: "left",
    width: "100%",
    //paddingRight: "99px",
    //marginRight: "100px",
  },
  errorRoot: {
    color: "#CB444A",
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: 0,
    backgroundColor: "#F8F9FF",
  },
}));

export default function EnhancedTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [personName, setPersonName] = React.useState("");
  const [deligationName, setDeligationName] = React.useState("");
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [toggleRow, setToggleRow] = React.useState(false);
  const [deligationTableData, setDeligationTableData] = React.useState([]);
  const [deligationDropeDownTableData, setDeligationDropeDownTableData] = React.useState([]);
  const [employeeDropeDownTableData, setEmployeeDropeDownTableData] = React.useState([]);
  const [validatonMsg, setValidationMsg] = React.useState([]);
  const [employeeMsg, setEmployeeMsg] = React.useState("");
  const [deligateMsg, setDeligateMsg] = React.useState("");
  const [startDateMsg, setStartDateMsg] = React.useState("");
  const [endDateMsg, setEndDateMsg] = React.useState("");

  const [valiDateDate, setValiDateDate] = React.useState(false);
  const [valiDateEndDate, setValiDateEndDate] = React.useState(false);
  const [filterShow, setFilterShow] = React.useState(false);
  const [countShow, setCountShow] = React.useState(0);
  const [editData, setEditData] = React.useState("");
  const [isSnakbar, setIsSnakbar] = React.useState(false);
  const [isSnakbarMsg, setIsSnakbarMsg] = React.useState("");
  const [currentIndex, setCurrentIndex] = React.useState();
  const [toggleEditRow, setToggleEditRow] = React.useState(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [employeeNamefilter, setEmployeeNamefilter] = useState("");
  const [delegateFilter, setDelegatefilter] = useState("");
  const [endDatefilter, setEndDatefilter] = useState(null);
  const [startDatefilter, setStartDatefilter] = useState(null);
  const [endDatefilter1, setEndDatefilter1] = useState(null);
  const [startDatefilter1, setStartDatefilter1] = useState(null);
  const [anchorFilter1, setAnchorFilter1] = useState("right");
  const [buttonState, setButtonState] = React.useState(true);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [selectedRowIndex, setSelectedrowindex] = useState(-1);
  const [saveVisiblebutton, setSavevisiblebutton] = React.useState(false);
  const [updateVisiblebutton, setUpdatevisiblebutton] = React.useState(false);
  const [firstIteration, setFirstIteration] = useState(true);
  const [editVisiblebutton, setEditvisiblebutton] = React.useState(false);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const handleEndDateChange1 = (type, date) => {
    setEndDatefilter(date);
    if (startDatefilter && date) {
      setButtonState(false);
    } else {
      setButtonState(true);
    }
  };
  const handleStartDateChange1 = (type, date) => {
    setStartDatefilter(date);
    if (endDatefilter && date) {
      setButtonState(false);
    } else {
      setButtonState(true);
    }
  };
  const handleEndDateChange2 = (type, date) => {
    setEndDatefilter1(date);
    if (startDatefilter1 && date) {
      setButtonState(false);
    } else {
      setButtonState(true);
    }
  };
  const handleStartDateChange2 = (type, date) => {
    setStartDatefilter1(date);
    if (endDatefilter1 && date) {
      setButtonState(false);
    } else {
      setButtonState(true);
    }
  };
  const handleRemoveFilter = () => {
    setDeligationTableData(getDeligation);
    setEmployeeNamefilter("");
    setDelegatefilter("");
    setStartDatefilter(null);
    setEndDatefilter(null);
    setStartDatefilter1(null);
    setEndDatefilter1(null);
    setFilterShow(false);
    setCountShow(0);
  };
  const handleApplyFilter = () => {
    let tempArray = JSON.parse(JSON.stringify(getDeligation));
    var countVal1 = 0,
      countVal2 = 0,
      countVal3 = 0,
      countVal4 = 0;
    if (employeeNamefilter != "") {
      tempArray = tempArray.filter(
        (data) => data.empName === employeeNamefilter
      );
      countVal1 = 1;
    }
    if (delegateFilter != "") {
      tempArray = tempArray.filter((data) => data.delName === delegateFilter);
      countVal2 = 1;
    }

    if (startDatefilter != null && endDatefilter != null) {
      tempArray = tempArray.filter(
        (data) =>
          moment(data.startDate).toDate() >= moment(startDatefilter).toDate() &&
          moment(data.startDate).toDate() <= moment(endDatefilter).toDate()
      );
      countVal3 = 1;
    }
    if (startDatefilter1 != null && endDatefilter1 != null) {
      tempArray = tempArray.filter(
        (data) =>
          moment(data.endDate).toDate() >= moment(startDatefilter1).toDate() &&
          moment(data.endDate).toDate() <= moment(endDatefilter1).toDate()
      );
      countVal4 = 1;
    }
    let tempvalcount = countVal1 + countVal2 + countVal3 + countVal4;
    setCountShow(tempvalcount);
    setDeligationTableData(tempArray);
    setButtonState(true);
    setFilterShow(true);
    setPage(0);
    setState({
      ...state,
      bottom: false,
      top: false,
      right: false,
      left: false,
    });
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <ArrowBackIosIcon
        onClick={toggleDrawer(anchorFilter1, false)}
        style={{ paddingLeft: "12px", marginTop: "19px" }}
      />
      <span
        style={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "18px",
            marginTop: "-25px",
            marginLeft: "-224px",
          }}
        >
          Filter
        </Typography>
      </span>
      <Divider style={{ marginTop: "6px" }} />
      <br />
      <Typography
        style={{
          paddingLeft: "11px",
          fontSize: "14px",
          fontWeight: 600,
          fontFamily: "Montserrat",
          lineHeight: "24px",
          color: "#353535",
          marginTop: "-8px",
        }}
      >
        By Employee And Delegate Name
      </Typography>
      <List>
        <ListItemIcon>
          <Grid container spacing={2}>
            <Grid item xs={11} sm={11} md={11}>
              <Grid item xs={11} sm={11} md={11}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl1}
                >
                  <InputLabel id="demo-simple-select-label">
                    Search Employee
                  </InputLabel>
                  <Select
                    value={employeeNamefilter}
                    labelId="demo-simple-select-label"
                    autosize={true}
                    id="demo-simple-select"
                    label="Search Employee Name"
                    onChange={(e) => {
                      if (e.target.value.length >= 1) {
                        setButtonState(false);
                      }
                      if (e.target.value.length == 0) {
                        setButtonState(true);
                      }
                      setEmployeeNamefilter(e.target.value);
                    }}
                  >
                    {[
                      ...new Map(
                        getDeligation?.map((item) => [item["empName"], item])
                      ).values(),
                    ].sort((a, b) => a.empName.localeCompare(b.empName)).map((value) => {
                      return (
                        <MenuItem value={value.empName}>
                          <Typography
                            style={{
                              fontFamily: "Montserrat",
                              fontSize: "13px",
                              fontWeight: 500,
                              letterSpacing: "-1px",
                              textAlign: "left",
                            }}
                          >
                            {value.empName}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={11} sm={11} md={11}>
              <Grid item xs={11} sm={11} md={11}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl1}
                >
                  <InputLabel id="demo-simple-select-label">
                    Search Delegate
                  </InputLabel>
                  <Select
                    value={delegateFilter}
                    labelId="demo-simple-select-label"
                    autosize={true}
                    id="demo-simple-select"
                    label="Search Delegate Name"
                    onChange={(e) => {
                      if (e.target.value.length >= 1) {
                        setButtonState(false);
                      }
                      if (e.target.value.length == 0) {
                        setButtonState(true);
                      }
                      setDelegatefilter(e.target.value);
                    }}
                  >
                    {[
                      ...new Map(
                        getDeligation?.map((item) => [item["delName"], item])
                      ).values(),
                    ].sort((a, b) => a.delName.localeCompare(b.delName)).map((value) => {
                      return (
                        <MenuItem value={value.delName}>
                          <Typography
                            style={{
                              fontFamily: "Montserrat",
                              fontSize: "13px",
                              fontWeight: 500,
                              letterSpacing: "-1px",
                              textAlign: "left",
                            }}
                          >
                            {value.delName}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </ListItemIcon>

        <Divider style={{ marginTop: "16px" }} />
        <br />
        <Typography
          style={{
            paddingLeft: "11px",
            marginTop: "-9px",
            fontSize: "14px",
            fontWeight: 600,
            fontFamily: "Montserrat",
            lineHeight: "24px",
            color: "#353535",
            marginBottom: "-14px",
          }}
        >
          By Start Date
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={5} sm={5} md={5}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                fullWidth
                className={classes.formControl2}
                variant="inline"
                format="MM/dd/yyyy"
                changeYear={true}
                changeMonth={true}
                // style={{marginBottom:"-10px",marginTop:"-10px"}}
                margin="normal"
                autoOk={true}
                id="date-picker-inline"
                label="Start Date"
                value={startDatefilter}
                onChange={handleStartDateChange1}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            &nbsp;
          </Grid>
          <Grid item xs={5} sm={5} md={5}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                fullWidth
                className={classes.formControl3}
                variant="inline"
                format="MM/dd/yyyy"
                changeYear={true}
                changeMonth={true}
                margin="normal"
                autoOk={true}
                id="date-picker-inline"
                label="Start Date"
                minDate={startDatefilter}
                value={endDatefilter}
                onChange={handleEndDateChange1}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Divider />
        <br />
        <Typography
          style={{
            paddingLeft: "11px",
            marginTop: "-9px",
            fontSize: "14px",
            fontWeight: 600,
            fontFamily: "Montserrat",
            lineHeight: "24px",
            color: "#353535",
            marginBottom: "-14px",
          }}
        >
          By End Date
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={5} sm={5} md={5}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                fullWidth
                className={classes.formControl2}
                variant="inline"
                format="MM/dd/yyyy"
                changeYear={true}
                changeMonth={true}
                margin="normal"
                autoOk={true}
                id="date-picker-inline"
                label="End Date"
                value={startDatefilter1}
                onChange={handleStartDateChange2}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            &nbsp;
          </Grid>
          <Grid item xs={5} sm={5} md={5}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                fullWidth
                className={classes.formControl3}
                variant="inline"
                format="MM/dd/yyyy"
                changeYear={true}
                changeMonth={true}
                margin="normal"
                autoOk={true}
                id="date-picker-inline"
                label="End Date"
                minDate={startDatefilter1}
                value={endDatefilter1}
                onChange={handleEndDateChange2}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </List>

      <Divider style={{ marginTop: "-8px" }} />
      <Box>
        <Button
          variant="outlined"
          size="small"
          style={{ marginLeft: "57px", width: "121px", fontSize: "14px" }}
          onClick={handleRemoveFilter}
        >
          Reset
        </Button>
        <Button
          variant="outlined"
          disabled={buttonState}
          style={{
            background: buttonState ? "#9E9E9E" : "#0B1941",
            marginLeft: "20px",
            marginBottom: "50px",
            marginTop: "50px",
            textTransform: "inherit",
          }}
          color="#fffff"
          size="small"
          onClick={handleApplyFilter}
        >
          <Typography style={{ color: "white", margin: 0, fontSize: "16px" }}>
            {" "}
            Apply Filter
          </Typography>
        </Button>
      </Box>
    </div>
  );
  const dispatch = useDispatch();
  const getDeligation = useSelector(
    (state) => state?.timesheetData?.getTimesheetViewDelegateData
  );
  const getDeligationDropeDown = useSelector(
    (state) => state?.timesheetData?.getTimesheetSearchDelegateData
  );

  const getEmployeeDropeDown = useSelector(
    (state) => state?.timesheetData?.getTimesheetSearchEmployeeData
  );

  const isPostTimesheetAddDelegateCheck = useSelector(
    (state) => state?.timesheetData?.isPostTimesheetAddDelegate
  );

  const isPostTimesheetEditDelegateListCheck = useSelector(
    (state) => state?.timesheetData?.isPostTimesheetEditDelegate
  );

  const location = useLocation();
  useEffect(() => {
    dispatch(ResetDeligatinSnakbarTs());
  }, []);
  useEffect(() => {
    if (
        isPostTimesheetAddDelegateCheck != true &&
      employeeMsg == "" &&
      deligateMsg == "" &&
      endDateMsg == "" &&
      startDateMsg == ""
    ) {
      setSavevisiblebutton(false);
    }
    if (isPostTimesheetAddDelegateCheck) {
      setIsSnakbar(true);
      setIsSnakbarMsg("Deligate added successfully");
      dispatch(ResetDeligatinSnakbarTs());
      dispatch(getTimesheetViewDelegateData());
    }
  }, [
    isPostTimesheetAddDelegateCheck,
    employeeMsg,
    deligateMsg,
    endDateMsg,
    endDateMsg,
  ]);

  useEffect(() => {
    if (isPostTimesheetEditDelegateListCheck) {
      setIsSnakbar(true);
      setIsSnakbarMsg("Deligate updated successfully");
      dispatch(ResetDeligatinSnakbarTs());
      dispatch(getTimesheetViewDelegateData());
      dispatch(getTimesheetSearchEmployeeData());
    }
  }, [isPostTimesheetEditDelegateListCheck]);

  //console.log("fropedoen data", getDeligationDropeDown);
  //console.log("getEmployeeDropeDown data", getEmployeeDropeDown);

  useEffect(() => {
    dispatch(getTimesheetSearchDelegateData());
    dispatch(getTimesheetViewDelegateData());
    dispatch(getTimesheetSearchEmployeeData());
  }, []);

  useEffect(() => {
    if (getDeligation !== null && getDeligation?.length > 0) {
      //console.log("in use effect");
      setDeligationTableData(getDeligation);
    }
  }, [getDeligation]);

  useEffect(() => {
    if (getDeligationDropeDown !== null) {
      setDeligationDropeDownTableData(getDeligationDropeDown);
    }
  }, [getDeligationDropeDown]);

  useEffect(() => {
    if (getEmployeeDropeDown !== null) {
      setEmployeeDropeDownTableData(getEmployeeDropeDown);
    }
  }, [getEmployeeDropeDown]);

  //console.log("checking the getDeligation", getDeligation);
  //console.log("state deligation", deligationTableData);
  //console.log("deligationDropeDownTableData", deligationDropeDownTableData);
  const handleStartDateChange = (type, date) => {
    setValiDateDate(false);
    const presentDate = new Date();
    const difference = presentDate - type;
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    if (TotalDays > 1) {
      setValiDateDate(true);
      setStartDate(date);
    } else {
      setStartDate(date);
    }
  };

  const handleEndDateChange = (type, date) => {
    //console.log("test", personName === deligationName);
    setValiDateEndDate(false);
    setEndDateMsg("");
    if (startDate !== null) {
      var edy = date.split("/");
      // let TotalDays = Math.ceil((endDate-StartDate) / (1000 * 60 * 60 * 24));
      const eDate = new Date(edy[2], edy[0] - 1, edy[1]);
      var sdy = startDate.split("/");
      const sDate = new Date(sdy[2], sdy[0] - 1, sdy[1]);
      let result = Math.round((eDate - sDate) / (1000 * 60 * 60 * 24));
      //console.log("TOtalDayssss", result);
      if (result < 0) {
        setValiDateEndDate(true);
        setEndDate(date);
      } else {
        setEndDate(date);
      }
    } else {
      setEndDate(date);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = deligationTableData?.map((n) => n.id);
      setSelected(...selected, newSelecteds);
      //console.log("selected value", newSelecteds);

      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const Array = [
    {
      validationType: "Employee",
      value: personName,
    },

    {
      validationType: "Deligation",
      value: deligationName,
    },
    {
      validationType: "StartDate",
      value: startDate,
      claim: valiDateDate,
    },
    {
      validationType: "EndDate",
      value: endDate,
      claim: valiDateEndDate,
      validateList: startDate,
    },
  ];

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, deligationTableData?.length - page * rowsPerPage);

  const handleChange = (event, value) => {
    //console.log("value", value);
    if (value == null) {
      setPersonName("");
    } else {
      setPersonName(value);
    }
  };

  const handleList = (option) => {
    //console.log("option", option);
    return (
      <>
        <Box style={{ display: "flex", marginTop: "16px" }}>
          <div className={classes.description}>
            <Typography component="div" className={classes.fullName}>
              {option.empName}
            </Typography>
            <Typography component="div" className={classes.designation}>
              {option.designation} | {option.empCode}
            </Typography>
            <Typography component="div" className={classes.designation}>
              {option.emailId}
            </Typography>
          </div>
        </Box>
      </>
    );
  };
  const handleDelete = (name) => {
    setPersonName("");
  };
  const handleChange2 = (event, value) => {
    //console.log("value", value);
    // setDeligationName(value);
    if (value == null) {
      setDeligationName("");
    } else {
      setDeligationName(value);
    }
  };

  const handleList2 = (option) => {
    //console.log("option", option);
    return (
      <>
        <Box style={{ display: "flex", marginTop: "16px" }}>
          <div className={classes.description}>
            <Typography component="div" className={classes.fullName}>
              {option.empName}
            </Typography>
            <Typography component="div" className={classes.designation}>
              {option.empCode} | {option.empDesignation} | RM :{" "}
              {option.reportingManager} | {option.horizontal}
            </Typography>
          </div>
        </Box>
      </>
    );
  };

  //console.log("delegatePerson", deligationName);
  //console.log("PersonName", personName);

  const handleRowDelete = () => {
    setToggleRow(false);
    setToggleEditRow(false);
    setPersonName("");
    setDeligationName("");
    setStartDate(null);
    setEndDate(null);
    setEndDateMsg("");
    setStartDateMsg("");
    setEmployeeMsg("");
    setDeligateMsg("");
  };

  const handleAddDeligation = () => {
    setToggleRow(true);
    setPersonName("");
    setDeligationName("");
    setStartDate(null);
    setEndDate(null);
    setEditData("");
    setToggleEditRow(false);
    setSavevisiblebutton(true);
    setUpdatevisiblebutton(false);
  };

  const displayEmployeeRow = (option) => {
    //console.log("option of employee row", option);
    return (
      <>
        <Box style={{ display: "flex", marginTop: "16px" }}>
          <div className={classes.description}>
            <Typography component="div" className={classes.fullName}>
              {option.empName}
            </Typography>
            <Typography component="div" className={classes.designation}>
              {option.empDesignation}| {option.empCode}
              {/* | RM :{" "}
              {option.reportingManager} | {option.horizontal} */}
            </Typography>
          </div>
        </Box>
      </>
    );
  };

  const displayDeligateRow = (option) => {
    //console.log("option of employee row", option);
    return (
      <>
        <Box style={{ display: "flex", marginTop: "16px" }}>
          <div className={classes.description}>
            <Typography component="div" className={classes.fullName}>
              {option.delName}
            </Typography>
            <Typography component="div" className={classes.designation}>
              {option.delDesignation}| {option.delCode}
            </Typography>
          </div>
        </Box>
      </>
    );
  };
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 400,
  });
  //console.log("location?.state?.empId: DT1  ", location?.state?.empId);
  useEffect(() => {
    validatonMsg.map((value) => {
      if (value.type === "Employee") {
        setEmployeeMsg(value.message);
      } else if (value.type === "Deligation") {
        setDeligateMsg(value.message);
      } else if (value.type === "StartDate") {
        setStartDateMsg(value.message);
      } else if (value.type === "EndDate") {
        setEndDateMsg(value.message);
      }
    });
  }, [validatonMsg]);

  const handleSave = () => {
    const result = Array?.map((feild) =>
      FieldValidator(
        feild.validationType,
        feild.value,
        feild.claim,
        feild.validateList,
        feild.validateList2
      )
    );

    setValidationMsg(result);
    let arr = result.map((i) => i.bool);
    let checker = arr.every((element) => element === true);
    const nameValidate = deligationName?.empName === personName?.empName;
    //console.log("nameValidate", nameValidate);
    if (checker && !nameValidate) {
      let formData = new FormData();
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      formData.append(
        "createdBy",
        Encrypt(key, JSON.stringify(location?.state?.empId))
      );
      formData.append(
        "delegateeId",
        Encrypt(key, JSON.stringify(deligationName?.empId))
      );
      formData.append("empId", Encrypt(key, JSON.stringify(personName?.empId)));
      if (endDate == null) {
        formData.append("endDate", "");
      } else {
        formData.append("endDate", endDate);
      }
      formData.append("startDate", startDate);
      dispatch(postTimesheetAddDelegateData(formData));
      setToggleRow(false);
      setToggleEditRow(false);
    }
    //setSavevisiblebutton(false);
  };

  const handleUpdate = () => {
    const result = Array.map((feild) =>
      FieldValidator(
        feild.validationType,
        feild.value,
        feild.claim,
        feild.validateList,
        feild.validateList2
      )
    );
    //console.log("validate result", result);

    setValidationMsg(result);
    let arr = result.map((i) => i.bool);
    let checker = arr.every((element) => element === true);
    if (checker) {
      let formData = new FormData();
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      //let encryptedPara1 = Encrypt(key, payload?.pId);

      let empDelUniqueId = Number(editData?.empDelUniqueId);
      // console.log(
      //   "diff2 EmpDelUniqueId",
      //   editData?.empDelUniqueId,
      //   location?.state?.empId,
      //   empDelUniqueId
      // );
      formData.append(
        "UpdatedBy",
        Encrypt(key, JSON.stringify(location?.state?.empId))
      );
      formData.append(
        "EmpDelUniqueId",
        Encrypt(key, JSON.stringify(empDelUniqueId))
      );
      if (endDate == null) {
        formData.append("endDate", "");
      } else {
        formData.append("endDate", endDate);
      }
      formData.append("startDate", startDate);
      dispatch(postTimesheetEditDelegateData(formData));
      setToggleRow(false);
      setToggleEditRow(false);
    }
    setUpdatevisiblebutton(false);
    setSelectedrowindex(-1);
  };

  const onFilterArray = (value) => {
    setDeligationTableData(value);
    setPage(0);
  };

  const handleEdit = (data, index) => {
    // console.log("row data", data);
    setToggleRow(false);
    setEditData(data);
    setToggleEditRow(true);
    setCurrentIndex(index);
    setSavevisiblebutton(false);
  };

  useEffect(() => {
    if (editData !== "" || firstIteration == false) {
      const employeEdit = deligationDropeDownTableData.filter(
        (val) => val.empName === editData.empName
      );
      //console.log("employeEdit", employeEdit);
      setPersonName(employeEdit[0]);
      const deligateEdit = deligationDropeDownTableData.filter(
        (val) => val.empName === editData.delName
      );
      //console.log("employeEdit", deligateEdit);
      setDeligationName(deligateEdit[0]);
      setStartDate(moment(editData?.startDate).format("l"));
      if (editData?.endDate == null) {
        setEndDate(editData?.endDate);
      } else {
        setEndDate(moment(editData?.endDate).format("l"));
      }
    }
  }, [toggleEditRow, editData, firstIteration]);

  const handleClick = (event, name) => {
    //console.log("name", name);
    const selectedIndex = selected.indexOf(name.empCode);
    let newSelected = [];

    if (selectedIndex === -1) {
      //newSelected = newSelected.concat(selected, name.empCode);
      newSelected.push(name.empCode);
    }
    // else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selected.slice(0, selectedIndex),
    //     selected.slice(selectedIndex + 1)
    //   );
    // }
    // console.log('selected value 1', newSelected);
    setSelected(newSelected);
    // console.log("selected value", selected);

    // const objIndex = multiDownload.findIndex((el) => el.expId == name.expId);
    // let newMultiDownload = [];
    // if (objIndex >= 0) {
    //   newMultiDownload = [
    //     ...multiDownload.filter((el) => el.expId != name.expId),
    //   ];
    // } else {
    //   newMultiDownload = [...multiDownload, name];
    // }
    // setMultiDownload(newMultiDownload);
  };

  const matches = useMediaQuery("(max-width:600px)");

  return (
    <div className={classes.root}>
      {/* {matches && ( */}
      <Grid container style={{ paddingTop: "40px" }}>
        <Grid item xs={12} sm={12} md={12}>
          <Box
            style={{ paddingLeft: "30px", display: "flex", marginTop: "-17px" }}
          >
            <IconButton>
              <ArrowBackIosIcon
                style={{
                  marginTop: "-11px",
                  marginLeft: "-25px",
                }}
                onClick={() => {
                  history.push("/timesheet-management");
                }}
              />
            </IconButton>
            <Typography className={classes.addDelText1}>
              Add Delegation
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} sm={6} md={6} className={classes.addButtonGrid}>
          <Box>
            <Button
              className={classes.addExpenseButton}
              onClick={() => {
                handleAddDeligation();
                //setSavevisiblebutton(true);
              }}
            >
              <Typography className={classes.buttonText}>
                + Add Delegate
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>

      {isSnakbar && (
        <Box>
          {" "}
          <DeligationSnakbar
            open={isSnakbar}
            msg={isSnakbarMsg}
            onClose={() => {
              setIsSnakbar(false);
              setIsSnakbarMsg("");
            }}
          />
        </Box>
      )}
      <TableContainer component={Paper} sx={{ width: "max-content" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {/* {console.log("selected",selected)} */}
                {/* {(selected.length > 0 ) && <>
                { (selected.length)} selected </>} */}
                {/* <Typography
                  style={{
                    // paddingTop: "10px",
                    //paddingLeft: "20px"
                  }}
                  align="left"

                >
                  {(selected.length > 0) && <>
                    {(selected.length)} selected
                  </>}
                </Typography> */}
              </TableCell>
              <TableCell align="right" style={{ padding: "0.4%" }}>
                {filterShow ? (
                  <IconButton
                    aria-label="cart"
                    style={{ marginTop: "-5px", marginBottom: "-5px" }}
                  >
                    <StyledBadge badgeContent={countShow} color="secondary">
                      <IconButton onClick={toggleDrawer(anchorFilter1, true)}>
                        <span class="material-symbols-outlined">
                          filter_alt
                        </span>
                      </IconButton>
                    </StyledBadge>
                  </IconButton>
                ) : (
                  <IconButton onClick={toggleDrawer(anchorFilter1, true)}>
                    <span class="material-symbols-outlined">filter_alt</span>
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              {selected.length > 0 && selected.length < 2 ? (
                <TableCell align="right" style={{ padding: "0.4%" }}>
                  {/* {console.log("selected",selected)} */}
                  {/* {(selected.length > 0 ) && <>
                { (selected.length)} selected </>} */}

                  <Typography
                    style={{
                      fontSize: "14px",
                      paddingLeft: "20px",
                    }}
                    align="left"
                  >
                    {/* {selected.length > 0 && <>{selected.length} selected</>} */}
                  </Typography>
                </TableCell>
              ) : (
                ""
              )}
              <TableCell align="right" style={{ padding: "0px" }}>
                <Box
                  onClick={() => {
                    handleEdit(selectedRow, selectedRowIndex);
                    setUpdatevisiblebutton(true);
                    //setEditData(null)
                  }}
                >
                  {selected.length > 0 && selected.length < 2 ? (
                    <span
                      style={{
                        paddingRight: "10px",
                      }}
                      class="material-symbols-outlined"
                    >
                      edit
                    </span>
                  ) : (
                    ""
                  )}
                </Box>

                <Drawer
                  anchor={anchorFilter1}
                  open={state[anchorFilter1]}
                  onClose={toggleDrawer(anchorFilter1, false)}
                >
                  {list(anchorFilter1)}
                </Drawer>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <Divider />

      {saveVisiblebutton && (
        <>
          {/* {console.log("editdata" , editData)} */}
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 4, sm: 4, md: 4 }}
            style={{ margin: "5px" }}
          >
            <Grid item xs={3}>
              &nbsp;
            </Grid>
            <Grid item xs={3}>
              &nbsp;
            </Grid>
            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className={classes.saveButton}
                onClick={
                  editData !== ""
                    ? () => handleUpdate()
                    : () =>
                        handleSave()(
                          employeeMsg != "" ||
                            deligateMsg != "" ||
                            endDateMsg != "" ||
                            startDateMsg != ""
                            ? setSavevisiblebutton(false)
                            : setSavevisiblebutton(true)
                        )
                }
              >
                <Typography className={classes.buttonText}>{"Save"}</Typography>
              </Button>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <span
                style={{ color: "#0B1941" }}
                onClick={() => {
                  handleRowDelete();

                  setSavevisiblebutton(false);
                }}
                class="material-symbols-outlined"
              >
                {"delete"}
              </span>
            </Grid>
          </Grid>
        </>
      )}
      {/* <Grid conatiner spacing={1}>
            <Grid style={{ margin: "0px" }} item xs={2}>
              
            </Grid>
            <Grid item style={{ margin: "0px" }} xs={2}>
              &nbsp;
            </Grid>
            <Grid item xs={6} style={{ margin: "0px" }}>

             
            </Grid>
            <Grid item xs={2} style={{ margin: "0px" }}>

              {/* <div
                style={{
                  // marginTop: "-32px",
                  // marginLeft: "331px",
                  // cursor: "pointer",
                }}
              > */}

      {/* </div> */}
      {/* </Grid> */}
      {/* </Grid> */}

      {updateVisiblebutton && (
        <>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 4, sm: 4, md: 4 }}
            style={{ margin: "5px" }}
          >
            <Grid item xs={3}>
              &nbsp;
            </Grid>
            <Grid item xs={3}>
              &nbsp;
            </Grid>
            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className={classes.saveButton}
                onClick={
                  editData !== "" ? () => handleUpdate() : () => handleSave()
                }
              >
                <Typography className={classes.buttonText}>
                  {"Update"}
                </Typography>
              </Button>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <span
                style={{ color: "#0B1941" }}
                onClick={() => {
                  handleRowDelete();
                  setUpdatevisiblebutton(false);
                  setSelectedrowindex(-1);
                  setSelectedRow(-1);
                  //console.log("setselectedrow", selectedRow, selectedRowIndex);
                }}
                class="material-symbols-outlined"
              >
                {"close"}
              </span>
            </Grid>
          </Grid>
          {/* <Button
            className={classes.saveButton}
            onClick={
              editData !== "" ? () => handleUpdate() : () => handleSave()
            }
          >
            <Typography className={classes.buttonText}>{"Update"}</Typography>
          </Button>
          <div
            style={{
              // marginTop: "-25px",
              // paddingLeft: "321px",
              // cursor: "pointer",
            }}
          >
            <span
              style={{ marginTop: "-7px", paddingLeft: "14px" }}
              onClick={() => {
                handleRowDelete();
                setUpdatevisiblebutton(false);
                setSelectedrowindex(-1);
                setSelectedRow(-1);
                console.log("setselectedrow", selectedRow, selectedRowIndex);
              }}
              class="material-symbols-outlined"
            >
              {"close"}
            </span>
          </div> */}
        </>
      )}

      <TableContainer component={Paper} sx={{ width: "max-content" }}>
        <Table>
          <EnhancedTableHead
            classes={classes}
            numSelected={selected?.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={deligationTableData?.length}
            dropeDownList={deligationDropeDownTableData}
            deligationTableData={deligationTableData}
            deligationTableDataList={getDeligation}
            onFilterArray={onFilterArray}
          />
          <TableBody>
            {toggleRow && (
              <TableRow
                className={
                  classes.inputTabeRow
                  //classes.tableRow
                }
              >
                <TableCell style={{ padding: "0.4%" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={""}
                        onChange={(e) => {
                          //setSelectedRow(row);
                          //setSelectedrowindex(index);
                          //handleClick(e, row);
                        }}
                        name="checkedB"
                        color="primary"
                        style={{ transform: "scale(0.8)" }}
                      />
                    }
                  />
                </TableCell>
                <TableCell
                  align="right"
                  style={{ padding: "0.4%", width: "12%" }}
                >
                  <Grid
                    container
                    spacing={1}
                    style={{
                      marginRight: "2px",
                      transform: "scale(0.8)",
                    }}
                  >
                    {/* <Grid item xs={6} sm={5} md={5}> */}
                    {employeeDropeDownTableData?.length > 0 && (
                      <Autocomplete
                        className={classes.DropeDown}
                        filterOptions={filterOptions}
                        //   multiple
                        //style={{ paddingBottom: "6px" }}
                        disabled={editData !== "" ? true : false}
                        id="checkboxes-tags-demo"
                        options={employeeDropeDownTableData}
                        value={personName}
                        debug
                        onChange={handleChange}
                        getOptionLabel={(option) => option.empName}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>{handleList(option)}</React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            className={classes.formControl}
                            inputProps={{
                              style: {
                                fontSize: "10px",
                              },
                            }}
                            {...params}
                            variant="outlined"
                            InputLabelProps={{
                              style: {
                                fontSize: "10px",
                              },
                            }}
                            label="Employee"
                            style={{
                              backgroundColor: "white",
                              //marginBottom:"20px",
                            }}
                            placeholder="Search Employee"
                            helperText={
                              (personName == "" &&
                                typeof personName === "string") ||
                              personName == null
                                ? employeeMsg
                                : ""
                            }
                            FormHelperTextProps={{
                              classes: {
                                root: classes.errorRoot,
                              },
                            }}
                          />
                        )}
                      />
                    )}
                    {/* </Grid> */}
                  </Grid>
                  {/* <div display="flex" alignItems="left">
                    <Typography
                      variant="p"
                      align="left"
                      style={{ fontSize: "10px" }}
                      className={classes.errorText}
                    >
                      {(personName == "" && typeof personName === "string") ||
                        personName == null
                        ? employeeMsg
                        : ""}
                    </Typography>
                  </div> */}
                </TableCell>
                <TableCell
                  align="right"
                  style={{ padding: "0.4%", width: "12%" }}
                >
                  <Grid
                    container
                    spacing={1}
                    style={{
                      margin: "0px",
                      transform: "scale(0.8)",
                    }}
                  >
                    {/* <Grid item xs={6} sm={5} md={5}> */}
                    <Autocomplete
                      // multiple
                      disabled={editData !== "" ? true : false}
                      className={classes.DropeDown}
                      filterOptions={filterOptions}
                      id="checkboxes-tags-demo2"
                      options={deligationDropeDownTableData}
                      value={deligationName}
                      debug
                      onChange={handleChange2}
                      getOptionLabel={(option) => option.empName}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>{handleList(option)}</React.Fragment>
                      )}
                      renderInput={(params) => (
                        // <Grid container>
                        //   {/* <Grid item xs={8} sm={4} md={4}> */}
                        //   <Grid item xs={6} sm={4} md={2}>
                        <TextField
                          className={classes.formControl}
                          inputProps={{
                            style: {
                              fontSize: "10px",
                            },
                          }}
                          {...params}
                          variant="outlined"
                          InputLabelProps={{ style: { fontSize: "10px" } }}
                          label="Delegate"
                          style={{ backgroundColor: "white" }}
                          placeholder="Search Delegate"
                          helperText={
                            (deligationName == "" &&
                              typeof deligationName === "string") ||
                            deligationName == null
                              ? deligateMsg
                              : deligationName.empName === personName.empName
                              ? "Delegate name should't same as Employee"
                              : ""
                          }
                          FormHelperTextProps={{
                            classes: {
                              root: classes.errorRoot,
                            },
                          }}
                        />
                      )}
                    />
                    {/* </Grid> */}
                  </Grid>
                  {/* <div display="flex" alignItems="left">
                    <Typography
                      variant="p"
                      align="left"
                      style={{ fontSize: "10px" }}
                      className={classes.errorText}
                    >
                      {(deligationName == "" &&
                        typeof deligationName === "string") ||
                        deligationName == null
                        ? deligateMsg
                        : deligationName.empName === personName.empName
                          ? "Delegate name should't same as Employee"
                          : ""}
                    </Typography>
                  </div> */}
                </TableCell>
                <TableCell
                  //style={{ paddingTop: "0px" }}
                  style={{ padding: "0.4%", width: "10%" }}
                >
                  <Grid
                    container
                    style={{ marginLeft: "10px", transform: "scale(0.8)" }}
                  >
                    <Grid item xs={5} sm={5} md={5}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          disabled={editData !== "" ? true : false}
                          inputVariant="outlined"
                          className={classes.formControl}
                          style={{
                            backgroundColor: "#FFFFFF",
                            //   width:"80px"
                            //fontSize: "10px",
                          }}
                          variant="inline"
                          autoOk={true}
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          InputLabelProps={{ style: { fontSize: "10px" } }}
                          label="Start Date*"
                          value={startDate}
                          onChange={handleStartDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                            style: {
                              width: "14px",
                              padding: "0px",
                            },
                          }}
                          helperText={
                            startDate === null
                              ? startDateMsg
                              : valiDateDate
                              ? startDateMsg
                              : ""
                          }
                          FormHelperTextProps={{
                            classes: {
                              root: classes.errorRoot,
                            },
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                </TableCell>

                <TableCell
                  //style={{ paddingTop: "0px" }}
                  style={{ padding: "0.4%", width: "10%" }}
                >
                  <Grid container style={{ transform: "scale(0.8)" }}>
                    <Grid item xs={5} sm={5} md={5}>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        //style={{transform: 'scale(0.8)'}}
                      >
                        <KeyboardDatePicker
                          disableToolbar
                          inputVariant="outlined"
                          className={classes.formControl}
                          style={{
                            backgroundColor: "#FFFFFF",
                            //   width:"50%"
                            fontSize: "10px",
                          }}
                          variant="inline"
                          autoOk={true}
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          InputLabelProps={{ style: { fontSize: "10px" } }}
                          label="End Date*"
                          minDate={startDate}
                          value={endDate}
                          onChange={handleEndDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                            style: {
                              width: "14px",
                              padding: "0px",
                              //transform: 'scale(0.8)'
                            },
                          }}
                          // helperText={
                          //   valiDateEndDate
                          //     ? endDateMsg
                          //     : "" || startDate !== null
                          //       ? endDateMsg
                          //       : ""
                          // }

                          helperText={
                            endDate === null
                              ? endDateMsg
                              : valiDateEndDate
                              ? endDateMsg
                              : "" || startDate !== null
                              ? endDateMsg
                              : ""
                          }
                          FormHelperTextProps={{
                            classes: {
                              root: classes.errorRoot,
                            },
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                </TableCell>
                {/* <DeligationDropeDown/> */}
                {/* <TableCell
                  align="right"
                  style={{ paddingRight: "10px", paddingBottom: "23px" }}
                ></TableCell> */}
              </TableRow>
            )}

            {stableSort(deligationTableData, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.empCode);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <>
                    {toggleEditRow && index === currentIndex && (
                      <TableRow className={classes.inputTabeRow}>
                        <TableCell style={{ padding: "0.4%" }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(e) => {
                                  setSelectedRow(row);
                                  setSelectedrowindex(index);
                                  handleClick(e, row);
                                }}
                                name="checkedB"
                                color="primary"
                                style={{ transform: "scale(0.8)" }}
                              />
                            }
                          />
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ padding: "0.4%", width: "12%" }}
                        >
                          <Grid
                            container
                            spacing={1}
                            style={{
                              marginRight: "2px",
                              transform: "scale(0.8)",
                            }}
                          >
                            {employeeDropeDownTableData?.length > 0 && (
                              <Autocomplete
                                className={classes.DropeDown}
                                filterOptions={filterOptions}
                                //   multiple
                                disabled={editData !== "" ? true : false}
                                id="checkboxes-tags-demo"
                                options={employeeDropeDownTableData}
                                value={personName}
                                disableCloseOnSelect
                                onChange={handleChange}
                                getOptionLabel={(option) => option.empName}
                                renderOption={(option, { selected }) => (
                                  <React.Fragment>
                                    {handleList(option)}
                                  </React.Fragment>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Employee"
                                    style={{ backgroundColor: "white" }}
                                    placeholder="Search Employee"
                                  />
                                )}
                              />
                            )}
                          </Grid>
                          <span>
                            <Typography
                              variant="p"
                              style={{ fontSize: "13px" }}
                              className={classes.errorText}
                            >
                              {(personName == "" &&
                                typeof personName === "string") ||
                              personName == null
                                ? employeeMsg
                                : ""}
                            </Typography>
                          </span>
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ padding: "0.4%", width: "12%" }}
                        >
                          <Grid
                            container
                            spacing={1}
                            style={{
                              margin: "0px",
                              transform: "scale(0.8)",
                            }}
                          >
                            <Autocomplete
                              // multiple
                              disabled={editData !== "" ? true : false}
                              className={classes.DropeDown2}
                              filterOptions={filterOptions}
                              id="checkboxes-tags-demo2"
                              options={deligationDropeDownTableData}
                              value={deligationName}
                              disableCloseOnSelect
                              onChange={handleChange2}
                              getOptionLabel={(option) => option.empName}
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  {handleList(option)}
                                </React.Fragment>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Delegate"
                                  style={{ backgroundColor: "white" }}
                                  placeholder="Search Delegate"
                                />
                              )}
                            />
                          </Grid>
                          <div display="flex" alignItems="left">
                            <Typography
                              variant="p"
                              style={{ fontSize: "13px" }}
                              className={classes.errorText}
                            >
                              {(deligationName == "" &&
                                typeof deligationName === "string") ||
                              deligationName == null
                                ? deligateMsg
                                : deligationName.empName === personName.empName
                                ? "Delegate name should't same as Employee"
                                : ""}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell style={{ padding: "0.4%", width: "10%" }}>
                          <Grid
                            container
                            style={{
                              marginLeft: "10px",
                              transform: "scale(0.9)",
                            }}
                          >
                            <Grid item xs={5} sm={5} md={5}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  disableToolbar
                                  disabled={editData !== "" ? true : false}
                                  inputVariant="outlined"
                                  className={classes.formControl}
                                  style={{
                                    backgroundColor: "#FFFFFF",
                                    //   width:"80px"
                                  }}
                                  InputProps={{
                                    style: { fontSize: "10px" },
                                  }}
                                  variant="inline"
                                  autoOk={true}
                                  format="MM/dd/yyyy"
                                  margin="normal"
                                  id="date-picker-inline"
                                  label="Start Date*"
                                  value={startDate}
                                  onChange={handleStartDateChange}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                    style: {
                                      width: "14px",
                                      padding: "0px",
                                    },
                                  }}
                                  helperText={
                                    startDate === null
                                      ? startDateMsg
                                      : valiDateDate
                                      ? startDateMsg
                                      : ""
                                  }
                                  FormHelperTextProps={{
                                    classes: {
                                      root: classes.errorRoot,
                                    },
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                          </Grid>
                        </TableCell>

                        <TableCell style={{ padding: "0.4%", width: "10%" }}>
                          <Grid container style={{ transform: "scale(0.9)" }}>
                            <Grid item xs={5} sm={5} md={5}>
                              {/* <Grid item xs={5} sm={5} md={5}> */}
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  disableToolbar
                                  inputVariant="outlined"
                                  className={classes.formControl}
                                  style={{
                                    backgroundColor: "#FFFFFF",
                                    //   width:"50%"
                                  }}
                                  InputProps={{
                                    style: { fontSize: "10px" },
                                  }}
                                  variant="inline"
                                  autoOk={true}
                                  format="MM/dd/yyyy"
                                  margin="normal"
                                  id="date-picker-inline"
                                  label="End Date*"
                                  minDate={startDate}
                                  value={endDate}
                                  onChange={handleEndDateChange}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                    style: {
                                      width: "14px",
                                      padding: "0px",
                                    },
                                  }}
                                  helperText={
                                    valiDateEndDate
                                      ? endDateMsg
                                      : "" || startDate !== null
                                      ? endDateMsg
                                      : ""
                                  }
                                  FormHelperTextProps={{
                                    classes: {
                                      root: classes.errorRoot,
                                    },
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                          </Grid>
                        </TableCell>
                        {/* <DeligationDropeDown/> */}
                        <TableCell
                          align="right"
                          style={{
                            paddingRight: "10px",
                            paddingBottom: "23px",
                          }}
                        ></TableCell>
                      </TableRow>
                    )}
                    <TableRow
                      className={classes.tableRow}
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.empCode}
                    >
                      <TableCell style={{ padding: "0.4%" }} align="center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(e) => {
                                setSelectedRow(row);
                                setSelectedrowindex(index);
                                handleClick(e, row);
                              }}
                              name="checkedB"
                              color="primary"
                              style={{
                                transform: "scale(0.8)",
                              }}
                            />
                          }
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        //align="center"
                        style={{ padding: "0.4%" }}
                      >
                        {displayEmployeeRow(row)}
                      </TableCell>
                      <TableCell
                        style={{ padding: "0.4%" }}

                        //align="center"
                      >
                        {displayDeligateRow(row)}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          //paddingLeft: "1px"
                          padding: "2px",
                        }}
                      >
                        <Typography
                          component="div"
                          className={classes.fullName}
                        >
                          {moment(row.startDate).format("DD/MM/YYYY")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          padding: "2px",
                          //paddingLeft: "1px"
                          width: "70px",
                        }}
                      >
                        <Typography
                          component="div"
                          className={classes.fullName}
                        >
                          {row.endDate !== null
                            ? moment(row.endDate).format("DD/MM/YYYY")
                            : ""}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        {/* </TableContainer> */}
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={deligationTableData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          align="center"
        />
        {/* </Paper> */}
        {/* </Box> */}
      </TableContainer>
    </div>
  );
}