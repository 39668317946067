import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Box, Avatar } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { CustomSwitch } from "./customSwitch";
const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  backdrop: {
    "& .MuiPaper-root": {
      width: "188px",
      height: "95px ",
      background: " #FFFFFF",
      border: "1px solid #B7B7B7",
      boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.03)",
      borderRadius: "10px",
      overflowY: "hidden",
      marginTop: "60px",
      marginLeft: "-42px",
    },
  },
  Attachments: {
    marginLeft: "10px",
  },
  projectId: {
    marginLeft: "10px",
  },
}));

const AccountOptions = ({ popUp, setting, handlePopup,closeSetting }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [attachment, setAttachment] = React.useState(null);
  const [projectId, setProjectid] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
    closeSetting();
  };

  useEffect(() => {
    //console.log("Boolean(popUp)", Boolean(popUp));
    setAnchorEl(popUp);
  }, [setting]);
  //console.log("anchorEl", Boolean(anchorEl));
  return (
    <Box component="span" m={1} style={{ alignItems: "center" }}>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={setting}
        onClose={handleClose}
        className={classes.backdrop}
      >
        <FormGroup>
          <FormControlLabel
            className={classes.Attachments}
            control={
              <CustomSwitch
                checked={attachment}
                onChange={(e) => {
                  setAttachment(e.target.checked);
                  handlePopup("attachment", e.target.checked);
                }}
              />
            }
            label="Attachments"
          />
          <FormControlLabel
            className={classes.projectId}
            control={
              <CustomSwitch
                checked={projectId}
                onChange={(e) => {
                  setProjectid(e.target.checked);
                  handlePopup("projectid", e.target.checked);
                }}
              />
            }
            label="Project Code"
          />
        </FormGroup>
      </Menu>
    </Box>
  );
};

export default AccountOptions;
