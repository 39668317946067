import * as types from "./actionType";
const initialState = {
  getDependentsList: null,
  getAccessTypeList: null,
  getPurposeList: null,
  isDataGet: false,
  getCategoryList: null,
  getCurrencyList: null,
  getAttendeesList: null,
  getValidateAttendeesList: null,
  getEmployeeProjectsList: null,
  getPaymentList: null,
  postCreateExpenseList: null,
  isPostData: false,
  postUploadAttachments: null,
  isUploadAttachments: false,
  getMyExpense: null,
  isgetMyExpense: false,
  getMyTeamApproval: null,
  isGetMyTeamApproval: false,
  isDeleteMyExpense: false,
  deleteMyExpense: null,
  getMyExpenseReportList: null,
  isMyExpenseReportList: false,
  postApproveData: null,
  isApprovedPost: false,
  getViewExpenseList: null,
  isViewExpenseList: false,
  getMyTeamApprovalPageFlag: null,
  isMyTeamApprovalPageFlag: false,
  postEditData: null,
  isEditdPost: false,
  getFinanceApprovalList: null,
  isGetFinanceApprovalList: false,
  postFinancialApproveRejectData: null,
  isFinancialApprovedRejectPost: false,
  getDownloadAllExpensesReport: null,
  isGetDownloadAllExpensesReport: false,
  getDownloadAllExpensesPageWise: null,
  isGetDownloadAllExpensesPageWise: false,
  getDeligationList: null,
  isDeligationList: false,
  getDeligationDropeDownList: null,
  isDeligationDropeDownList: false,
  getEmployeeDropeDownList: null,
  isEmployeeDropeDownList: false,
  postDeligateSaveList: null,
  ispostDeligateSaveList: false,
  postDeligateUpdateList: null,
  ispostDeligateUpdateList: false,
  getMyDelegateesData: null,
  isGetMyDelegatees: false,
  ssoEmpId: null,
  delEmpId: null,
  getEmpDetailsData: null,
  isEmpDetailsData: false,
  getDelegationFlag: null,
  isgetDelegationFlag: false,
  reloadModuleToggle: null,
  maintainStateVariable: null,
  comingFromOtherModule: false,
  getAuditHistData: null,
  isGetAuditHistData: false,
  getExpenseReportCountData: null,
  isGetExpenseReportCountData: false,
  setFullFinalAllExpenseData: null,

  isgetMyExpenseTravel: false,
  getMyExpenseTravel: [],
  isGetBillNumberExistence: false,
  getBillNumberExistence: null,
  isGetBillNumberExistence: false,
  getBillNumberExistence: null,
};
function ExpenseForm(state = initialState, action) {
  switch (action.type) {
    case types.GET_DEPENDENTS_LIST:
      return {
        ...state,
        isDataGet: false,
        getDependentsList: null,
      };
    case types.GET_DEPENDENTS_LIST_SUCCESS:
      return {
        ...state,
        isDataGet: true,
        getDependentsList: action.payload,
      };
    case types.GET_DEPENDENTS_LIST_FAILED:
      return {
        ...state,
        isDataGet: false,
        getDependentsList: action.payload,
      };
    case types.GET_ACCESSTYPE_LIST:
      return {
        ...state,
        isDataGet: false,
        getAccessTypeList: null,
      };
    case types.GET_ACCESSTYPE_LIST_SUCCESS:
      return {
        ...state,
        isDataGet: true,
        getAccessTypeList: action.payload,
      };
    case types.GET_ACCESSTYPE_LIST_FAILED:
      return {
        ...state,
        isDataGet: false,
        getAccessTypeList: action.payload,
      };
    case types.GET_PURPOSE_LIST:
      return {
        ...state,
        isDataGet: false,
        getPurposeList: null,
      };
    case types.GET_PURPOSE_LIST_SUCCESS:
      return {
        ...state,
        isDataGet: true,
        getPurposeList: action.payload,
      };
    case types.GET_PURPOSE_LIST_FAILED:
      return {
        ...state,
        isDataGet: false,
        getPurposeList: action.payload,
      };

    case types.GET_CATEGORY_LIST:
      return {
        ...state,
        isDataGet: false,
        getCategoryList: null,
      };
    case types.GET_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        isDataGet: true,
        getCategoryList: action.payload,
      };
    case types.GET_CATEGORY_LIST_FAILED:
      return {
        ...state,
        isDataGet: false,
        getCategoryList: action.payload,
      };

    case types.GET_CURRENCY_LIST:
      return {
        ...state,
        isDataGet: false,
        getCurrencyList: null,
      };
    case types.GET_CURRENCY_LIST_SUCCESS:
      return {
        ...state,
        isDataGet: true,
        getCurrencyList: action.payload,
      };
    case types.GET_CURRENCY_LIST_FAILED:
      return {
        ...state,
        isDataGet: false,
        getCurrencyList: action.payload,
      };

    case types.GET_EMPLOYEE_PROJECTS_LIST:
      return {
        ...state,
        isDataGet: false,
        getEmployeeProjectsList: null,
      };
    case types.GET_EMPLOYEE_PROJECTS_LIST_SUCCESS:
      return {
        ...state,
        isDataGet: true,
        getEmployeeProjectsList: action.payload,
      };
    case types.GET_EMPLOYEE_PROJECTS_LIST_FAILED:
      return {
        ...state,
        isDataGet: false,
        getEmployeeProjectsList: action.payload,
      };

    case types.GET_ATTENDEES_LIST:
      return {
        ...state,
        isDataGet: false,
        getAttendeesList: null,
      };
    case types.GET_ATTENDEES_LIST_SUCCESS:
      return {
        ...state,
        isDataGet: true,
        getAttendeesList: action.payload,
      };
    case types.GET_ATTENDEES_LIST_FAILED:
      return {
        ...state,
        isDataGet: false,
        getAttendeesList: action.payload,
      };

    case types.GET_VALIDATE_ATTENDEES_LIST:
      return {
        ...state,
        isDataGet: false,
        getValidateAttendeesList: null,
      };
    case types.GET_VALIDATE_ATTENDEES_LIST_SUCCESS:
      return {
        ...state,
        isDataGet: true,
        getValidateAttendeesList: action.payload,
      };
    case types.GET_VALIDATE_ATTENDEES_LIST_FAILED:
      return {
        ...state,
        isDataGet: false,
        getValidateAttendeesList: action.payload,
      };

    case types.GET_PAYMENT_LIST:
      return {
        ...state,
        isDataGet: false,
        getPaymentList: null,
      };
    case types.GET_PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        isDataGet: true,
        getPaymentList: action.payload,
      };
    case types.GET_PAYMENT_LIST_FAILED:
      return {
        ...state,
        isDataGet: false,
        getPaymentList: action.payload,
      };

    case types.POST_CREATE_EXPENSE:
      return {
        ...state,
        isPostData: false,
        postCreateExpenseList: null,
      };
    case types.POST_CREATE_EXPENSE_SUCCESS:
      return {
        ...state,
        isPostData: true,
        postCreateExpenseList: action.payload,
      };
    case types.POST_CREATE_EXPENSE_FAILED:
      return {
        ...state,
        isPostData: false,
        postCreateExpenseList: action.payload,
      };
    case types.POST_UPLOAD_ATTACHMENTS:
      return {
        ...state,
        isUploadAttachments: false,
        postUploadAttachments: null,
      };
    case types.POST_UPLOAD_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        isUploadAttachments: true,
        postUploadAttachments: action.payload,
      };
    case types.POST_UPLOAD_ATTACHMENTS_FAILED:
      return {
        ...state,
        isUploadAttachments: false,
        postUploadAttachments: action.payload,
      };

    case types.GET_MY_EXPENSE:
      return {
        ...state,
        isgetMyExpense: false,
        getMyExpense: null,
      };
    case types.GET_MY_EXPENSE_SUCCESS:
      return {
        ...state,
        isgetMyExpense: true,
        getMyExpense: action.payload,
      };
    case types.GET_MY_EXPENSE_FAILED:
      return {
        ...state,
        isgetMyExpense: false,
        getMyExpense: action.payload,
      };

    case types.GET_MY_EXPENSE_TRAVEL:
      return {
        ...state,
        isgetMyExpenseTravel: false,
        getMyExpenseTravel: [],
      };
    case types.GET_MY_EXPENSE_SUCCESS_TRAVEL:
      return {
        ...state,
        isgetMyExpenseTravel: true,
        getMyExpenseTravel: action.payload,
      };
    case types.GET_MY_EXPENSE_FAILED_TRAVEL:
      return {
        ...state,
        isgetMyExpenseTravel: false,
        getMyExpenseTravel: action.payload,
      };

    case types.GET_MY_TEAM_APPROVAL:
      return {
        ...state,
        isGetMyTeamApproval: false,
        getMyTeamApproval: null,
      };
    case types.GET_MY_TEAM_APPROVAL_SUCCESS:
      return {
        ...state,
        isGetMyTeamApproval: true,
        getMyTeamApproval: action.payload,
      };
    case types.GET_MY_TEAM_APPROVAL_FAILED:
      return {
        ...state,
        isGetMyTeamApproval: false,
        getMyTeamApproval: action.payload,
      };

    case types.DELETE_MY_EXPENSE:
      return {
        ...state,
        isDeleteMyExpense: false,
        deleteMyExpense: null,
      };
    case types.DELETE_MY_EXPENSE_SUCCESS:
      return {
        ...state,
        isDeleteMyExpense: true,
        deleteMyExpense: action.payload,
      };
    case types.DELETE_MY_EXPENSE_FAILED:
      return {
        ...state,
        isDeleteMyExpense: false,
        deleteMyExpense: action.payload,
      };

    case types.MY_EXPENSE_REPORT_DATA:
      return {
        ...state,
        isMyExpenseReportList: false,
        getMyExpenseReportList: null,
      };
    case types.MY_EXPENSE_REPORT_DATA_SUCCESS:
      return {
        ...state,
        isMyExpenseReportList: true,
        getMyExpenseReportList: action.payload,
      };
    case types.MY_EXPENSE_REPORT_DATA_FAILED:
      return {
        ...state,
        isMyExpenseReportList: false,
        getMyExpenseReportList: action.payload,
      };
    case types.POST_APPROVE_EXPENSE:
      return {
        ...state,
        isApprovedPost: false,
        postApproveData: null,
      };
    case types.POST_APPROVE_EXPENSE_SUCCESS:
      return {
        ...state,
        isApprovedPost: true,
        postApproveData: action.payload,
      };
    case types.POST_APPROVE_EXPENSE_FAILED:
      return {
        ...state,
        isApprovedPost: false,
        postApproveData: action.payload,
      };

    case types.MY_VIEW_EXPENSE_DATA:
      return {
        ...state,
        isViewExpenseList: false,
        getViewExpenseList: null,
      };
    case types.MY_VIEW_EXPENSE_DATA_SUCCESS:
      return {
        ...state,
        isViewExpenseList: true,
        getViewExpenseList: action.payload,
      };
    case types.MY_VIEW_EXPENSE_DATA_FAILED:
      return {
        ...state,
        isViewExpenseList: false,
        getViewExpenseList: action.payload,
      };

    case types.GET_MY_TEAM_APPROVAL_PAGE_FLAG:
      return {
        ...state,
        isDataGet: false,
        getMyTeamApprovalPageFlag: null,
      };
    case types.GET_MY_TEAM_APPROVAL_PAGE_FLAG_SUCCESS:
      return {
        ...state,
        isDataGet: true,
        getMyTeamApprovalPageFlag: action.payload,
      };
    case types.GET_MY_TEAM_APPROVAL_PAGE_FLAG_FAILED:
      return {
        ...state,
        isDataGet: false,
        getMyTeamApprovalPageFlag: action.payload,
      };

    case types.POST_EDIT_EXPENSE:
      return {
        ...state,
        isEditdPost: false,
        postEditData: null,
      };
    case types.POST_EDIT_EXPENSE_SUCCESS:
      return {
        ...state,
        isEditdPost: true,
        postEditData: action.payload,
      };
    case types.POST_EDIT_EXPENSE_FAILED:
      return {
        ...state,
        isEditdPost: false,
        postEditData: action.payload,
      };

    case types.GET_FINANCE_APPROVAL_LIST:
      return {
        ...state,
        isGetFinanceApprovalList: false,
        getFinanceApprovalList: null,
      };
    case types.GET_FINANCE_APPROVAL_LIST_SUCCESS:
      return {
        ...state,
        isGetFinanceApprovalList: true,
        getFinanceApprovalList: action.payload,
      };
    case types.GET_FINANCE_APPROVAL_LIST_FAILED:
      return {
        ...state,
        isGetFinanceApprovalList: false,
        getFinanceApprovalList: action.payload,
      };

    case types.POST_FINANCIAL_APPROVE_EXPENSE:
      return {
        ...state,
        postFinancialApproveRejectData: null,
        isFinancialApprovedRejectPost: false,
      };
    case types.POST_FINANCIAL_APPROVE_EXPENSE_SUCCESS:
      return {
        ...state,
        isFinancialApprovedRejectPost: true,
        postFinancialApproveRejectData: action.payload,
      };
    case types.POST_FINANCIAL_APPROVE_EXPENSE_FAILED:
      return {
        ...state,
        isFinancialApprovedRejectPost: false,
        postFinancialApproveRejectData: action.payload,
      };

    case types.RESET_EXPENSE_RESPONSE:
      return {
        ...state,
        isPostData: false,
        isEditdPost: false,
        isDeleteMyExpense: false,
        isApprovedPost: false,
        isFinancialApprovedRejectPost: false,
      };

    case types.GET_DOWNLOAD_ALL_EXPENSES_REPORT:
      return {
        ...state,
        isGetDownloadAllExpensesReport: false,
        getDownloadAllExpensesReport: null,
      };
    case types.GET_DOWNLOAD_ALL_EXPENSES_REPORT_SUCCESS:
      return {
        ...state,
        isGetDownloadAllExpensesReport: true,
        getDownloadAllExpensesReport: action.payload,
      };
    case types.GET_DOWNLOAD_ALL_EXPENSES_REPORT_FAILED:
      return {
        ...state,
        isGetDownloadAllExpensesReport: false,
        getDownloadAllExpensesReport: action.payload,
      };
    case types.GET_DOWNLOAD_ALL_EXPENSES_PAGEWISE:
      return {
        ...state,
        isGetDownloadAllExpensesPageWise: false,
        getDownloadAllExpensesPageWise: null,
      };
    case types.GET_DOWNLOAD_ALL_EXPENSES_PAGEWISE_SUCCESS:
      return {
        ...state,
        isGetDownloadAllExpensesPageWise: true,
        getDownloadAllExpensesPageWise: action.payload,
      };
    case types.GET_DOWNLOAD_ALL_EXPENSES_PAGEWISE_FAILED:
      return {
        ...state,
        isGetDownloadAllExpensesPageWise: false,
        getDownloadAllExpensesPageWise: action.payload,
      };

    case types.GET_DELEGATION_LIST:
      return {
        ...state,
        getDeligationList: null,
        isDeligationList: false,
      };

    case types.GET_DELEGATION_LIST_SUCCESS:
      return {
        ...state,
        isDeligationList: true,
        getDeligationList: action.payload,
      };

    case types.GET_DELEGATION_LIST_FAILED:
      return {
        ...state,
        isDeligationList: false,
        getDeligationList: action.payload,
      };

    case types.GET_DELIGATION_DROPEDOWN_LIST:
      return {
        ...state,
        getDeligationDropeDownList: null,
        isDeligationDropeDownList: false,
      };

    case types.GET_DELIGATION_DROPEDOWN_LIST_SUCCESS:
      return {
        ...state,
        isDeligationDropeDownList: true,
        getDeligationDropeDownList: action.payload,
      };

    case types.GET_DELIGATION_DROPEDOWN_LIST_FAILED:
      return {
        ...state,
        isDeligationDropeDownList: false,
        getDeligationDropeDownList: action.payload,
      };

    case types.GET_EMPLOYEE_DROPEDOWN_LIST:
      return {
        ...state,
        getEmployeeDropeDownList: null,
        isEmployeeDropeDownList: false,
      };

    case types.GET_EMPLOYEE_DROPEDOWN_LIST_SUCCESS:
      return {
        ...state,
        isEmployeeDropeDownList: true,
        getEmployeeDropeDownList: action.payload,
      };

    case types.GET_EMPLOYEE_DROPEDOWN_LIST_FAILED:
      return {
        ...state,
        isEmployeeDropeDownList: false,
        getEmployeeDropeDownList: action.payload,
      };

    case types.POST_DELIGATE_SAVE_LIST:
      return {
        ...state,
        postDeligateSaveList: null,
        ispostDeligateSaveList: false,
      };

    case types.POST_DELIGATE_SAVE_LIST_SUCCESS:
      return {
        ...state,
        ispostDeligateSaveList: true,
        postDeligateSaveList: action.payload,
      };

    case types.POST_DELIGATE_SAVE_LIST_FAILED:
      return {
        ...state,
        ispostDeligateSaveList: false,
        postDeligateSaveList: action.payload,
      };

    case types.POST_DELIGATE_UPDATE_LIST:
      return {
        ...state,
        postDeligateUpdateList: null,
        ispostDeligateUpdateList: false,
      };

    case types.POST_DELIGATE_UPDATE_LIST_SUCCESS:
      return {
        ...state,
        ispostDeligateUpdateList: true,
        postDeligateUpdateList: action.payload,
      };

    case types.POST_DELIGATE_UPDATE_LIST_FAILED:
      return {
        ...state,
        ispostDeligateUpdateList: false,
        postDeligateUpdateList: action.payload,
      };

    case types.RESET_DELIGATION:
      return {
        ...state,
        ispostDeligateSaveList: false,
        ispostDeligateUpdateList: false,
      };

    case types.MY_DELEGATEES_DATA:
      return {
        ...state,
        isGetMyDelegatees: false,
        getMyDelegateesData: null,
      };
    case types.MY_DELEGATEES_DATA_SUCCESS:
      return {
        ...state,
        isGetMyDelegatees: true,
        getMyDelegateesData: action.payload,
      };
    case types.MY_DELEGATEES_DATA_FAILED:
      return {
        ...state,
        isGetMyDelegatees: false,
        getMyDelegateesData: action.payload,
      };
    case types.UPDATED_SSO_EMP_ID:
      return {
        ...state,
        ssoEmpId: action.payload,
      };
    case types.UPDATE_DELEGATEE_EMP_ID:
      return {
        ...state,
        delEmpId: action.payload,
      };

    case types.GET_EMP_DETAILS_DATA:
      return {
        ...state,
        getEmpDetailsData: null,
        isEmpDetailsData: false,
      };
    case types.GET_EMP_DETAILS_DATA_SUCCESS:
      return {
        ...state,
        isEmpDetailsData: true,
        getEmpDetailsData: action.payload,
      };
    case types.GET_EMP_DETAILS_DATA_FAILED:
      return {
        ...state,
        isEmpDetailsData: false,
        getEmpDetailsData: action.payload,
      };

    case types.GET_DELEGATION_FLAG:
      return {
        ...state,
        getDelegationFlag: null,
        isgetDelegationFlag: false,
      };
    case types.GET_DELEGATION_FLAG_SUCCESS:
      return {
        ...state,
        isgetDelegationFlag: true,
        getDelegationFlag: action.payload,
      };
    case types.GET_DELEGATION_FLAG_FAILED:
      return {
        ...state,
        isgetDelegationFlag: false,
        getDelegationFlag: action.payload,
      };
    case types.GET_AUDIT_HISTORY:
      return {
        ...state,
        getAuditHistData: null,
        isgetAuditHistData: false,
      };
    case types.GET_AUDIT_HISTORY_SUCCESS:
      return {
        ...state,
        getAuditHistData: action.payload,
        isgetAuditHistData: true,
      };
    case types.GET_AUDIT_HISTORY_FAILED:
      return {
        ...state,
        getAuditHistData: action.payload,
        isgetAuditHistData: false,
      };
    case types.GET_EXPENSE_REPORT_COUNT:
      return {
        ...state,

        getExpenseReportCountData: null,
        isGetExpenseReportCountData: false,
      };
    case types.GET_EXPENSE_REPORT_COUNT_SUCCESS:
      return {
        ...state,
        getExpenseReportCountData: action.payload,
        isGetExpenseReportCountData: true,
      };
    case types.GET_EXPENSE_REPORT_COUNT_FAILED:
      return {
        ...state,
        getExpenseReportCountData: action.payload,
        isGetExpenseReportCountData: false,
      };
    case types.RESET_AUDIT_HISTORY_DATA:
      return {
        ...state,
        getAuditHistData: null,
        isgetAuditHistData: false,
        getDownloadAllExpensesPageWise: null,
        isGetDownloadAllExpensesPageWise: false,
        getExpenseReportCountData: null,
        isGetExpenseReportCountData: false,
      };
    case types.RELOAD_MODULE_TOGGLE:
      return {
        ...state,
        reloadModuleToggle: action.payload,
      };
    case types.MAINTAIN_STATE_VARIABLE:
      return {
        ...state,
        maintainStateVariable: action.payload,
      };
    case types.SET_FULL_FINAL_ALL_EXPENSE_DATA:
      return {
        ...state,
        setFullFinalAllExpenseData: action.payload,
      };
    case types.SET_FROM_OTHER_MODULE_TOGGLE:
      return {
        ...state,
        comingFromOtherModule: true,
      };
    case types.RESET_FROM_OTHER_MODULE_TOGGLE:
      return {
        ...state,
        comingFromOtherModule: false,
      };

    case types.GET_BILLNUMBER_EXISTENCE:
      return {
        ...state,

        getBillNumberExistence: null,
        isGetBillNumberExistence: false,
      };
    case types.GET_BILLNUMBER_EXISTENCE_SUCCESS:
      return {
        ...state,
        getBillNumberExistence: action.payload,
        isGetBillNumberExistence: true,
      };
    case types.GET_BILLNUMBER_EXISTENCE_FAILURE:
      return {
        ...state,
        getBillNumberExistence: action.payload,
        isGetBillNumberExistence: false,
      };
    default:
      return {
        ...state,
      };
  }
}

export default ExpenseForm;
