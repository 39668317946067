import React from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import history from "../history";
import { useEffect } from "react";
import { Typography } from "@material-ui/core";
import { resetExpenseResponse } from "../redux/Expense/action";
import { useDispatch } from "react-redux";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function ApproveRejectSnackbar(props) {
  const classes = useStyles();
  // console.log("in snackbar, ARS1 props.currentExpense?.expCode:==>",props.approveOrReject);
  const dispatch = useDispatch();

  // console.log("isFinAppRejPostSuccess2")
  return (
    <div className={classes.root}>
      <Snackbar
        open={props.openAppRejSB}
        autoHideDuration={1500}
        onClose={() => {
          props.handleCloseAppRejSB()
          dispatch(resetExpenseResponse());
        }}
      >
        {props.approveOrReject == 1 ? (
          <Alert onClose={props.handleCloseAppRejSB} severity="success">
            Expense Approved Successfully !
          </Alert>
        ) : (
          <Alert onClose={props.handleCloseAppRejSB} severity="success">
            Expense Rejected Successfully !
          </Alert>
        )}
      </Snackbar>
    </div>
  );
}
