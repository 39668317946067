import React, { useEffect, useRef, useState, useReducer } from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
//import Tooltip from "@material-ui/core/Tooltip";
import { FormControlLabel } from "@material-ui/core";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Box from "@mui/material/Box";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Checkbox, Chip, Link } from "@material-ui/core";
import ClearIcon from "@mui/icons-material/Clear";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Button, TextField } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getMyTeamApprovalData,
  updateStatusByManagerData,
  getFilterTeamTimesheetData,
  getMyTeamApprovalFilterDropDownData,
} from "../redux/TimeSheet/actionTs";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import Grid from "@material-ui/core/Grid";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Encrypt from "../Utils/encryption";
import ApproveRejectByManager from "./approveRejectByManager";
import { useHistory } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";
import { styled } from "@material-ui/core/styles";
import Card from "@mui/material/Card";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import moment from "moment";
import Drawer from "@material-ui/core/Drawer";
import Badge from "@material-ui/core/Badge";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { startOfMonth, endOfMonth } from "date-fns";
// import Autocomplete, {
//   createFilterOptions,
// } from "@material-ui/lab/Autocomplete";
import Autocomplete from "@mui/material/Autocomplete";
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  {
    id: "icon",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "3.75rem",
  },
  {
    id: "Date",
    numeric: false,
    disablePadding: true,
    label: "Date",
    width: "22%",
  },
  {
    id: "Employee Name & ID",
    numeric: false,
    disablePadding: true,
    label: "Employee Name & ID",
    width: "24%",
  },
  {
    id: "Type & Category",
    numeric: false,
    disablePadding: true,
    label: "Type & Category",
    width: "22%",
  },
  {
    id: "Project Name & ID",
    numeric: false,
    disablePadding: true,
    label: "Project Name & ID",
    width: "27%",
  },
  {
    id: "Hours",
    numeric: true,
    disablePadding: true,
    label: "Hours",
    width: "10%",
  },
  {
    id: "comments",
    numeric: false,
    disablePadding: true,
    label: "",
    width: "10%",
  },
  // {
  //   id: "action",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Action",
  //   width: "10%",
  // },
];

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
    //console.log("event1",event)
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootFooter} style={{ marginLeft: "10px" }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        style={{ padding: "0px" }}
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        style={{ padding: "0px" }}
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </div>
  );
}
function MyTeamApprovalTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    checked,
    rowsPerPage,
    pageNumber,
    rowValue,
    indexValue,
    multiDownload,
    handleApprove,
    handleApproveRejectDialog,
    handleApproveRejectByManager,
    handleComment,
    firstAppRej,
    handleApproveRejectStatus,
    showApproveRejectDialog,
    handleCloseApproveRejectDialog,
    currentIndex,
    isSetLoader,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead style={{ backgroundColor: "#E3E8F7" }}>
      <TableRow style={{ height: "2.813rem" }}>
        {headCells.map((headCell) => (
          <TableCell
            component="th"
            scope="row"
            className={
              headCell.id === "icon" ? classes.stickyLeft : classes.headerStyle
            }
            style={{
              background: "#E3E8F7",
              fontWeight: 600,
              padding: "0rem",
              width: headCell.width,
              paddingLeft:
                headCell.id == "Hours"
                  ? "1rem"
                  : headCell.id == "action"
                  ? "0.4rem"
                  : headCell.id == "icon" ||
                    headCell.id == "Date" ||
                    headCell.id == "comments"
                  ? "0rem"
                  : "0rem",
              transform: headCell.id === "icon" ? "scale(0.8)" : "",
            }}
            key={headCell.id}
          >
            {headCell.id === "icon" ? (
              <>
                <Checkbox
                  checked={checked}
                  style={{ paddingLeft: "12px" }}
                  checkedIcon={
                    <CheckBoxIcon
                      fontSize="small"
                      className={classes.checkBoxRoot}
                      style={{ transform: "scale(0.8)" }}
                    />
                  }
                  onChange={onSelectAllClick}
                  inputProps={{ "aria-label": "select all rows" }}
                />
              </>
            ) : (
              <>{headCell.label}</>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
MyTeamApprovalTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  //onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  //rowCount: PropTypes.number.isRequired,
  checked: PropTypes.bool,
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //overflow:"hidden",
  },
  rootFooter: {
    flexShrink: 0,
    marginLeft: "-2px",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    boxShadow: "none",
  },
  table: {
    minWidth: 750,
  },
  stickyLeft: {
    position: "sticky",
    left: 0,
    background: "white",
    zIndex: 800,
  },
  headerStyle: {
    color: " #424242",
    fontFamily: "Montserrat",
    fontSize: "0.625rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    height: "1.25rem",
  },
  iconStyle: {
    width: "2rem",
    height: "2rem",
    flexShrink: "0",
  },
  snackbarMessage: {
    color: "#333",
    fontFamily: "Montserrat",
    fontSize: "0.813rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "0.013rem",
  },
  snackbarMessage1: {
    color: "#252733",
    fontFamily: "Montserrat",
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "0.013rem",
    paddingLeft: "0.625rem",
  },
  anchorStyle: {
    "&.MuiSnackbar-anchorOriginTopRight": {
      top: "3.188rem",
      left: "auto",
      right: "3.25rem",
    },
  },
  stickyRight: {
    position: "sticky",
    right: 0,
    background: "white",
    zIndex: 800,
  },
  tooltip: {
    paddingRight: "0.313rem",
    color: "#9E9E9E",
    maxWidth: "1.25rem",
    maxHeight: "1.25rem",
    transform: " scale(1.2)",
    marginTop: "0.25rem",
  },
  tooltipBox: {
    minWidth: "18.5rem",
    left: "auto!important",
    //right: 0,
    marginTop: "-1.375rem",
    marginRight: "0.125rem",
    marginLeft: "-30px",
    // top:'-1.25rem!important'
  },
  statusName: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "0.5rem",
    marginRight: "0 rem",
    // alignItems: "center",
    // paddingLeft: "10px",
    color: "rgba(255, 255, 255, 0.85)",
  },
  checkBoxRoot: {
    background: "#0B1941",
    color: "white",
    "&$checked": {
      background: "#0B1941",
      color: "white",
    },
  },
  divider: {
    "&.muiDivider-root": {
      backgroundColor: "rgb(0 0 0 / 12%)",
      flexShrink: "0",
    },
  },
}));
function ResponsiveMyTeamApproval() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const classes = useStyles();
  const dispatch = useDispatch();
  const [snackBarData, setSnackBarData] = useState({
    name: "",
    id: "",
  });
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [approveRejectStatus, setApproveRejectStatus] = useState("");
  const [approveRejectComment, setApproveRejectComment] = useState(null);
  const [showApproveRejectDialog, setShowApproveRejectDialog] = useState(false);
  const [refreshTable, forceUpdate] = useReducer((x) => x + 1, 0);
  const [refreshTableToggle, setRefreshTableToggle] = useState(0);
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [opened, setOpened] = useState(false);
  const [rowValue, setRowValue] = React.useState();
  const [indexValue, setIndexValue] = React.useState();
  const [expStatus, setExpStatus] = React.useState("Approved");
  const [countShow, setCountShow] = React.useState(0);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [commentData, setCommentData] = React.useState([]);
  const [toolAction, setToolAction] = React.useState("");
  const [firstAppRej, setFirstAppRej] = useState("");
  const [isLoader, isSetLoader] = useState(true);
  const target = useRef(null);
  const [anchorFilter1, setAnchorFilter1] = useState("right");
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [systemLiveDate, setSystemLiveDate] = useState(null);
  const [futureLimitDate, setFutureLimitDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [employee, setEmployee] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [empCategory, setEmpCategory] = useState([]);
  const [buttonState, setButtonState] = React.useState(true);
  const [tempFromDate, setTempFromDate] = useState(null);
  const [tempToDate, setTempToDate] = useState(null);
  const [tempChips, setTempChips] = useState({
    employeeChip: [],
    employeeIdChip: [],
    fromDate: null,
    toDate: null,
    empType: "",
    empCat: [],
  });
  const [isNewChip, setIsNewChip] = useState(false);
  const [filteredTeamTimesheet, setFilteredTeamTimesheet] = useState([]);
  const [chips, setChips] = useState({
    employeeChip: [],
    employeeIdChip: [],
    fromDate: null,
    toDate: null,
    empType: "",
    empCat: [],
  });
  const [noDataInFilterFlag, setNoDataInFilterFlag] = useState(0);
  const [noTeamApprovalData, setnoTeamApprovalData] = useState(0);
  const [applyFilterClicked, setApplyFilterClicked] = useState(false);
  const [selected, setSelected] = React.useState([]);
  const [checked, setChecked] = React.useState(false);
  const [multiDownload, setMultiDownload] = React.useState([]);
  const [projectNameId, setProjectNameId] = useState([]);
  const [error, setError] = useState(false);
  const [employeeType, setEmployeeType] = useState([]);
  const inputValidation = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValidInput = /^[0-9]*$/.test(keyValue);

    if (!isValidInput) {
      event.preventDefault();
    }
  };
  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );
  const tsDelEmpIdRedux = useSelector(
    (state) => state?.timesheetData?.tsDelEmpId
  );
  var myTeamApproval = useSelector(
    (state) => state.timesheetData.getMyTeamsApproval
  );

  const updatedStatusByManager = useSelector(
    (state) => state.timesheetData.isUpdateStatusByManager
  );

  const teamTimesheet = useSelector(
    (state) => state.timesheetData.getTeamTimesheets
  );
  const filterDropDown = useSelector(
    (state) => state?.timesheetData?.getMyTeamApprovalFilterDropDownList
  );

  const filterDataJson = filterDropDown ? JSON.parse(filterDropDown) : [];
  //console.log("check team " , teamTimesheet)
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: 13,
      top: 31,
      border: `0.125rem solid ${theme.palette.background.paper}`,
      padding: "0 0.25rem",
    },
  }));

  useEffect(() => {
    if (
      myTeamApproval &&
      (myTeamApproval?.length > 0 || myTeamApproval?.length === 0)
    ) {
      isSetLoader(false);
      setSystemLiveDate(new Date(myTeamApproval[0]?.systemLiveDate));
      setFutureLimitDate(new Date(myTeamApproval[0]?.futureWeekLimitFromDate));
    } else {
      isSetLoader(true);
    }
    if (
      myTeamApproval &&
      myTeamApproval?.length == 1 &&
      myTeamApproval[0]?.timesheetDetailUniqueId == ""
    ) {
      //  console.log("team approval data ", myTeamApproval);
      setnoTeamApprovalData(1);
    } else {
      setnoTeamApprovalData(0);
    }
  }, [myTeamApproval]);

  useEffect(() => {
    // console.log(ssoEmpIdDetails);
    const obj = {
      managerEmpId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
      pagenumber: 1,
      pagesize: rowsPerPage,
    };
    // console.log("manager object", obj);
    // getMyTeamApprovalData
    dispatch(getMyTeamApprovalData(obj));
    dispatch(getMyTeamApprovalFilterDropDownData(obj));
    // console.log("team approval data ", myTeamApproval);
  }, [dispatch, ssoEmpIdDetails]);

  let IDs = [];

  const x = (chipList) => {
    return chipList.map((chip) => {
      const parts = chip.split("-");
      // Get the part after the third last '-'
      const id = parts.slice(-1)[0];
      return id;
    });
  };

  useEffect(() => {
    const obj = {
      managerEmpId: tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId,
      pagenumber: 1,
      pagesize: rowsPerPage,
    };
    // console.log("manager object", obj);
    // getMyTeamApprovalData
    dispatch(getMyTeamApprovalData(obj)); // Fetch or update your data here if needed
  }, [updatedStatusByManager]);

  useEffect(() => {
    if (
      teamTimesheet &&
      teamTimesheet?.length == 1 &&
      teamTimesheet[0]?.timesheetDetailUniqueId == ""
    ) {
      setNoDataInFilterFlag(1);
    } else {
      setNoDataInFilterFlag(0);
    }
  }, [teamTimesheet]);
  useEffect(() => {
    //console.log("team23456" , teamTimesheet , myTeamApproval)
    if (myTeamApproval || teamTimesheet) {
      isSetLoader(false);
    } else {
      isSetLoader(true);
    }
  }, [myTeamApproval, teamTimesheet]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setChecked(true);
      let filterData = stableSort(
        applyFilterClicked && teamTimesheet?.length > 0
          ? teamTimesheet
          : myTeamApproval,
        getComparator(order, orderBy)
      );
      // ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      const newSelecteds = filterData.map((s) => s.timesheetDetailUniqueId);
      setSelected(newSelecteds);
      setMultiDownload(filterData);
      return;
    } else {
      setChecked(false);
    }
    setMultiDownload([]);
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleFromDate = (event) => {
    const selectedValue = moment(event).format("yyyy-MM-DD");
    setFromDate(selectedValue);
    if (tempChips.toDate != null && tempChips.toDate < selectedValue) {
      setTempChips((prevChips) => ({
        ...prevChips,
        fromDate: selectedValue,
      }));
      setIsNewChip(false);
      setError(true);
      return;
    } else {
      setTempChips((prevChips) => ({
        ...prevChips,
        fromDate: selectedValue,
      }));
      setIsNewChip(false);
      setError(false);
    }
  };
  const handleToDate = (event) => {
    const selectedValue = moment(event).format("yyyy-MM-DD");
    setToDate(selectedValue);

    if (tempChips.fromDate != null && tempChips.fromDate > selectedValue) {
      setTempChips((prevChips) => ({
        ...prevChips,
        toDate: selectedValue,
      }));
      setIsNewChip(false);
      setError(true);
      return;
    } else {
      setTempChips((prevChips) => ({
        ...prevChips,
        toDate: selectedValue,
      }));
      setIsNewChip(false);
      setError(false);
    }
  };
  const handleEmpCatChange = (event, value) => {
    if (value) {
      setEmpCategory(value);
      setTempChips((prevChips) => ({
        ...prevChips,
        empCat: value,
      }));
      setIsNewChip(true);
    } else {
      setEmpCategory([]);

      setTempChips((prevChips) => ({
        ...prevChips,
        empCat: [],
      }));
    }
  };
  const handleEmpTypeChange = (event, value) => {
    // const selectedValue = event.target.textContent;
    const selectedValue = [value[value.length - 1]];

    const isDuplicate = tempChips.empType == selectedValue;
    // console.log("prevchips", value, tempChips);
    const handleEmployeeID = (shouldUpdateState) => {
      if (shouldUpdateState && !isDuplicate) {
        setEmployeeType(selectedValue);
        setTempChips((prevChips) => ({
          ...prevChips,
          empType: selectedValue[0] != undefined ? selectedValue : "",
        }));

        setIsNewChip(false);
      }
    };

    if (
      (event.key === "Enter" && selectedValue.length > 0) ||
      (event.type === "click" && selectedValue.length > 0)
    ) {
      // console.log("valueagain1", value, event.type, event.key, selectedValue);
      handleEmployeeID(true);
    } else {
      // console.log("valueagain2", value, event.type);
      setEmployeeType(selectedValue);
      setTempChips((prevChips) => ({
        empType: [],
      }));
    }
  };
  const handleProjectNameId = (event, value) => {
    const originalValues = value.map((x) =>
      x.replace(/@\$\@/g, "-")
    );
    // console.log("duplicate", tempChips, value, event, empCategory);
    if (value) {
      setProjectNameId(value);
      setTempChips((prevChips) => ({
        ...prevChips,
        employeeChip: originalValues,
      }));
      setIsNewChip(true);
    } else {
      setProjectNameId([]);
      setTempChips((prevChips) => ({
        ...prevChips,
        employeeChip: [],
      }));
    }
  };

  const handleEmployeeId = (event) => {
    const selectedValue = event.target.value;
    const isDuplicate = tempChips.employeeIdChip.includes(selectedValue);

    const handleEmployeeID = (shouldUpdateState) => {
      if (shouldUpdateState && !isDuplicate) {
        setEmployeeId(selectedValue);
        setTempChips((prevChips) => ({
          ...prevChips,
          employeeIdChip: [...prevChips.employeeIdChip, selectedValue],
        }));
        setIsNewChip(false);
      }
    };

    if (
      (event.key === "Enter" && selectedValue.length > 0) ||
      (event.type === "blur" && selectedValue.length > 0)
    ) {
      handleEmployeeID(true);
    } else {
      setEmployeeId(selectedValue);
    }
  };

  const handleChipDelete = (chipToDelete, chipType) => {
    // console.log("tyurhkef",projectNameId,chipToDelete,tempChips,chipType)

    setTempChips((prevChips) => {
      const updatedChips = { ...prevChips };
      if(chipType == "employeeChip"){
        let val = projectNameId.filter((value) => value.replace(/@\$\@/g,"-") !== chipToDelete);
        setProjectNameId(val);
      }
      updatedChips.employeeChip = updatedChips.employeeChip.filter(
        (chip) => chip !== chipToDelete
      );
      updatedChips.employeeIdChip = updatedChips.employeeIdChip.filter(
        (chip) => chip !== chipToDelete
      );
      updatedChips.empCat = updatedChips.empCat.filter(
        (chip) => chip !== chipToDelete
      );

      updatedChips.fromDate =
        updatedChips.fromDate == chipToDelete && chipType == "fromDate"
          ? null
          : updatedChips.fromDate;
      updatedChips.toDate =
        updatedChips.toDate == chipToDelete && chipType == "toDate"
          ? null
          : updatedChips.toDate;
      updatedChips.empType =
        updatedChips.empType == chipToDelete && chipType == "empType"
          ? null
          : updatedChips.empType;

      setEmpCategory(updatedChips?.empCat);
    

      var countVal1 = 0,
        countVal2 = 0,
        countVal3 = 0,
        countVal4 = 0,
        countVal5 = 0,
      countVal6 = 0;
      // console.log("updatedchips" , updatedChips)
      if (
        updatedChips.employeeChip ||
        updatedChips.employeeIdChip ||
        updatedChips.toDate ||
        updatedChips.fromDate ||
        updatedChips.empType || updatedChips.empCat
      ) {
        if (updatedChips.employeeChip.length > 0) {
          countVal1 = updatedChips.employeeChip.length;
        }
        if (updatedChips.employeeIdChip?.length > 0) {
          countVal2 = updatedChips.employeeIdChip?.length;
        }
        if (updatedChips.empCat?.length > 0) {
          countVal6 = updatedChips.empCat?.length;
        }
        if (updatedChips?.toDate != null) {
          countVal3 = 1;
        }
        if (updatedChips?.fromDate != null) {
          countVal4 = 1;
        }
        if (updatedChips?.empType != null) {
          countVal5 = 1;
        }
        let tempvalcount =
          countVal1 + countVal2 + countVal3 + countVal4 + countVal5 +countVal6;
        // console.log("count", countVal1, countVal2);
        if (tempvalcount == 0) {
          setApplyFilterClicked(false);
        }
        setCountShow(tempvalcount);
        if (tempChips?.employeeChip.length > 0) {
          IDs = x(tempChips?.employeeChip);
        }
        // console.log("updatedchips" , updatedChips)
        if (
          updatedChips.employeeChip ||
          updatedChips.employeeIdChip ||
          updatedChips.toDate ||
          updatedChips.fromDate ||
          updatedChips.empType ||
          updatedChips.empCat
        ) {
          const obj = {
            managerEmpId: tsDelEmpIdRedux
              ? tsDelEmpIdRedux
              : ssoEmpIdDetails?.empId,
            fromDate: updatedChips.fromDate ? updatedChips.fromDate : "",
            toDate: updatedChips.toDate ? updatedChips.toDate : "",
            empNameId: updatedChips.employeeIdChip
              ? updatedChips.employeeIdChip
              : [],
            projNameId: IDs ? updatedChips.employeeChip : [],
            empType: updatedChips?.empType ? updatedChips?.empType : "",
            empCat: updatedChips.empCat ? updatedChips.empCat : [],
            pagenumber: 1,
            pagesize: rowsPerPage,
          };
        
          dispatch(getFilterTeamTimesheetData(obj));
        } else {
          const obj = {
            managerEmpId: tsDelEmpIdRedux
              ? tsDelEmpIdRedux
              : ssoEmpIdDetails?.empId,
            fromDate: "",
            toDate: "",
            projNameId: [],
            empNameId: [],
            empType: "",
            empCat: [],
            pagenumber: 1,
            pagesize: rowsPerPage,
          };
          dispatch(getFilterTeamTimesheetData(obj));
        }
      return updatedChips;
    }});

    setChips((prevChips) => {
      const updatedChips = { ...prevChips };

      updatedChips.employeeChip = updatedChips.employeeChip.filter(
        (chip) => chip !== chipToDelete
      );
      updatedChips.employeeIdChip = updatedChips.employeeIdChip.filter(
        (chip) => chip !== chipToDelete
      );
      updatedChips.empCat = updatedChips.empCat.filter(
        (chip) => chip !== chipToDelete
      );
      updatedChips.fromDate =
        updatedChips.fromDate == chipToDelete && chipType == "fromDate"
          ? null
          : updatedChips.fromDate;
      updatedChips.toDate =
        updatedChips.toDate == chipToDelete && chipType == "toDate"
          ? null
          : updatedChips.toDate;
      updatedChips.empType =
        updatedChips.empType == chipToDelete && chipType == "empType"
          ? null
          : updatedChips.empType;

      return updatedChips;
    });

    if (projectNameId === chipToDelete) {
      setProjectNameId([]);
    }
    if (employeeId === chipToDelete) {
      setEmployeeId("");
    }
    if (employeeType == chipToDelete) {
      setEmployeeType([]);
    }
    // if (empCategory == chipToDelete) {
    //   setEmpCategory([]);
    // }
  };
  
  // const filterOptions = createFilterOptions({
  //   matchFrom: "any",
  //   limit: 400,
  // });
  useEffect(() => {
    if (
      isNewChip ||
      fromDate != null ||
      toDate != null ||
      employeeType != [] ||
      empCategory != [] ||
      projectNameId != []
    ) {
      setChips({
        employeeChip:
          tempChips?.employeeChip?.length > 0
            ? [...tempChips.employeeChip]
            : [],
        employeeIdChip:
          tempChips?.employeeIdChip?.length > 0
            ? [...tempChips.employeeIdChip]
            : [],
        fromDate: tempChips.fromDate,
        toDate: tempChips.toDate,
        empType: tempChips.empType,
        empCat: tempChips?.empCat?.length > 0 ? [...tempChips.empCat] : [],
      });
    }
   
  }, [isNewChip, fromDate, toDate, employeeType, empCategory, projectNameId]);

  useEffect(() => {
    

    setButtonState(
     ( toDate < fromDate ||
        toDate < tempChips?.fromDate ||
        tempChips?.toDate < fromDate) ||(fromDate == null &&
          toDate == null &&
          employeeId.length == 0 &&
          projectNameId.length == 0 &&
          employeeType.length == 0 &&
          empCategory.length == 0)
        ? true
        : false
    );
    
  }, [fromDate, toDate, employeeId, projectNameId, employeeType, empCategory,tempChips]);


  

  const handleApplyFilter = () => {
    setApplyFilterClicked(true);

    if (
      fromDate ||
      toDate ||
      projectNameId ||
      employeeId ||
      employeeType ||
      empCategory
    ) {
      isSetLoader(true);
      var countVal1 = 0,
        countVal2 = 0,
        countVal3 = 0,
        countVal4 = 0,
        countVal5 = 0,
        countVal6 = 0;
      setTempFromDate(fromDate ? fromDate : tempFromDate ? tempFromDate : null);
      setTempToDate(toDate ? toDate : tempToDate ? tempToDate : null);
      setIsNewChip(true);
      setFromDate(null);
      setToDate(null);
      // setProjectNameId([]);
      setEmployeeId("");
      // setEmployeeType([]);
      // setEmpCategory([]);
      if (tempChips?.employeeChip.length > 0) {
        IDs = x(tempChips?.employeeChip);
      }
      let obj = {
        managerEmpId: tsDelEmpIdRedux
          ? tsDelEmpIdRedux
          : ssoEmpIdDetails?.empId,
        fromDate: tempChips?.fromDate ? tempChips?.fromDate : "",
        toDate: tempChips?.toDate ? tempChips?.toDate : "",
        projNameId: tempChips.employeeChip ? IDs : [],
        empNameId: tempChips.employeeIdChip ? tempChips.employeeIdChip : [],
        empType: tempChips?.empType ? tempChips?.empType : "",
        empCat: tempChips?.empCat ? tempChips?.empCat : [],
        pagenumber: 1,
        pagesize: rowsPerPage,
      };

      
      dispatch(getFilterTeamTimesheetData(obj));
    }

    if (tempChips.employeeChip.length > 0) {
      countVal1 = tempChips.employeeChip.length;
    }
    if (tempChips.employeeIdChip?.length > 0) {
      countVal2 = tempChips.employeeIdChip?.length;
    }
    if (tempChips.empCat?.length > 0) {
      countVal6 = tempChips.empCat?.length;
    }
    if (tempChips?.toDate != null) {
      countVal3 = 1;
    }
    if (tempChips?.fromDate != null) {
      countVal4 = 1;
    }
    if (tempChips?.empType != null && tempChips?.empType != "") {
      countVal5 = 1;
    }
    let tempvalcount =
      countVal1 + countVal2 + countVal3 + countVal4 + countVal5 + countVal6;

    setCountShow(tempvalcount);
    setState({
      ...state,
      bottom: false,
      top: false,
      right: false,
      left: false,
    });
    if (
      (fromDate != null && toDate != null) ||
      projectNameId != [] ||
      employeeId != "" ||
      employeeType != [] ||
      empCategory != []
    ) {
      setButtonState(false);
    }
    setPage(0);
  };
  let currentDate = new Date();
  let firstDayOfMonth = startOfMonth(currentDate);
  let lastDayOfMonth = endOfMonth(currentDate);

  const handleClearAll = () => {
    setFromDate(null);
    setToDate(null);
    setTempFromDate(null);
    setTempToDate(null);
    setApplyFilterClicked(false);
    setTempChips({
      employeeChip: [],
      employeeIdChip: [],
      fromDate: null,
      toDate: null,
      empType: "",
      empCat: [],
    });

    setChips({
      employeeChip: [],
      employeeIdChip: [],
      fromDate: null,
      toDate: null,
      empType: "",
      empCat: [],
    });
    setProjectNameId([]);
    setEmployeeId("");
    setEmployeeType([]);
    setEmpCategory([]);
    setNoDataInFilterFlag(0);
    setCountShow(0);
    if (firstDayOfMonth && lastDayOfMonth) {
      const obj = {
        managerEmpId: tsDelEmpIdRedux
          ? tsDelEmpIdRedux
          : ssoEmpIdDetails?.empId,
        fromDate: "",
        toDate: "",
        projNameId: [],
        empNameId: [],
        empType: "",
        empCat: [],
        pagenumber: 1,
        pagesize: rowsPerPage,
      };
      dispatch(getFilterTeamTimesheetData(obj));
    }
  };

  const handleClick = (event, name) => {

    var selectedIndex = -1;
    if (selected == null) {
      selectedIndex = -1;
    } else {
      selectedIndex = selected.indexOf(name.timesheetDetailUniqueId);
    }
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.timesheetDetailUniqueId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      setChecked(false);
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      setChecked(false);
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      setChecked(false);
    }

    if (newSelected.length == myTeamApproval.length) setChecked(true);
    setSelected(newSelected);
   

    const objIndex = multiDownload.findIndex(
      (el) => el.timesheetDetailUniqueId == name.timesheetDetailUniqueId
    );
    let newMultiDownload = [];
    if (objIndex >= 0) {
      newMultiDownload = [
        ...multiDownload.filter(
          (el) => el.timesheetDetailUniqueId != name.timesheetDetailUniqueId
        ),
      ];
    } else {
      newMultiDownload = [...multiDownload, name];
    }
    setMultiDownload(newMultiDownload);
  };

  const handleApprove = (row, index, bulkVal) => {
    setChecked(false);
    isSetLoader(true);
    setApproveRejectStatus("Approved");
    setFirstAppRej("Approved");
    setCurrentIndex(index);
    handleApproveRejectByManager(row, "Approved", null, bulkVal);
    setSelected([]);
    setMultiDownload([]);
    setChecked(false);
  };
  const toggleDrawer = (anchor, open) => (event) => {
   
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const handleApproveRejectByManager = async (
    value,
    status,
    comment,
    bulkVal
  ) => {
   
    setChecked(false);

    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    let formData = new FormData();
    const tmdetails = [
      {
        tmdetailrecid: value?.timesheetDetailUniqueId,
        tmid: value?.timesheetID,
      },
    ];
    const tmdetails1 = multiDownload?.map((item) => ({
      tmdetailrecid: item.timesheetDetailUniqueId,
      tmid: item.timesheetID,
    }));
    // console.log("here10", tmdetails1);
    formData.append(
      "ManagerEmpId",
      Encrypt(
        key,
        JSON.stringify(
          tsDelEmpIdRedux ? tsDelEmpIdRedux : ssoEmpIdDetails?.empId
        )
      )
    );
    formData.append(
      "delegateeId",
      ssoEmpIdDetails?.empId === tsDelEmpIdRedux
        ? JSON.stringify(null)
        : Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
    formData.append("TmStatus", JSON.stringify(status));
    // formData.append(
    //   "delegateeId",
    //   Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    // );

    formData.append("Comment", JSON.stringify(comment));
    if (bulkVal) {
      formData.append("TmDetails", JSON.stringify(tmdetails1));
    } else {
      formData.append("TmDetails", JSON.stringify(tmdetails));
    }

    try {
      setShowApproveRejectDialog(false);
      const response = await dispatch(updateStatusByManagerData(formData));
      if (response.status == 200) {
        isSetLoader(false);
        setOpen(true);
        if (updatedStatusByManager) {
          myTeamApproval = myTeamApproval.filter(
            (item) =>
              item.timesheetDetailUniqueId != value?.timesheetDetailUniqueId
          );
        }

        setRefreshTableToggle(
          (prevRefreshTableToggle) => prevRefreshTableToggle + 1
        );

        setSnackBarData({
          name: value.employeeName,
          id: value.employeeID,
        });
      }
      isSetLoader(false);
    } catch (error) {
      // console.error("Post request failed:", error);
    }
    if (applyFilterClicked == true) {
      var countVal1 = 0,
        countVal2 = 0,
        countVal3 = 0,
        countVal4 = 0,
        countVal5 = 0,
        countVal6 = 0;
      if (tempChips.employeeChip.length > 0) {
        countVal1 = tempChips.employeeChip.length;
      }
      if (tempChips.employeeIdChip?.length > 0) {
        countVal2 = tempChips.employeeIdChip?.length;
      }
      if (tempChips.empCat?.length > 0) {
        countVal6 = tempChips.empCat?.length;
      }
      if (tempChips.empType != "" && tempChips.empType != null) {
        countVal5 = 1;
      }
      if (tempChips?.toDate != null) {
        countVal3 = 1;
      }
      if (tempChips?.fromDate != null) {
        countVal4 = 1;
      }
      let tempvalcount =
        countVal1 + countVal2 + countVal3 + countVal4 + countVal5 + countVal6;

      setCountShow(tempvalcount);
      if (tempChips?.employeeChip.length > 0) {
        IDs = x(tempChips?.employeeChip);
      }
      let obj = {
        managerEmpId: tsDelEmpIdRedux
          ? tsDelEmpIdRedux
          : ssoEmpIdDetails?.empId,
        fromDate: tempChips?.fromDate ? tempChips?.fromDate : "",
        toDate: tempChips?.toDate ? tempChips?.toDate : "",
        projNameId: tempChips.employeeChip ? IDs : [],
        empNameId: tempChips.employeeIdChip ? tempChips.employeeIdChip : [],
        empType: tempChips?.empType ? tempChips?.empType : "",
        empCat: tempChips?.empCat ? tempChips?.empCat : [],
        pagenumber: 1,
        pagesize: rowsPerPage,
      };

      //console.log("filterrr" , obj , employee);
      dispatch(getFilterTeamTimesheetData(obj));
    } else {
      const obj = {
        managerEmpId: tsDelEmpIdRedux
          ? tsDelEmpIdRedux
          : ssoEmpIdDetails?.empId,
        fromDate: "",
        toDate: "",
        empName: [],
        empCode: [],
        empType: "",
        empCat: [],
        pagenumber: 1,
        pagesize: rowsPerPage,
      };
      dispatch(getFilterTeamTimesheetData(obj));
    }
    setPage(0);

    // forceUpdate();
  };
  // useEffect(() => {
  //   console.log("show open useeffect", open);
  // }, [open]);

  const handleApproveRejectDialog = (index, status) => {
    setCurrentIndex(index);
    setFirstAppRej(status);
    setShowApproveRejectDialog(true);
    setSelected([]);
    setMultiDownload([]);
    setChecked(false);
  };

  const handleApproveRejectStatus = (stat) => {
    setApproveRejectStatus(stat);
  };

  const handleChangePage = (event, newPage) => {
    // console.log("props on change 0 ", page);
    isSetLoader(true);
    if (tempChips?.employeeChip.length > 0) {
      IDs = x(tempChips?.employeeChip);
    }
    if (applyFilterClicked) {
      let obj = {
        managerEmpId: tsDelEmpIdRedux
          ? tsDelEmpIdRedux
          : ssoEmpIdDetails?.empId,
        fromDate: tempChips?.fromDate ? tempChips?.fromDate : "",
        toDate: tempChips?.toDate ? tempChips?.toDate : "",
        projNameId: tempChips.employeeChip ? IDs : [],
        empNameId: tempChips.employeeIdChip ? tempChips.employeeIdChip : [],
        empType: tempChips?.empType ? tempChips?.empType : "",
        empCat: tempChips?.empCat ? tempChips?.empCat : [],
        pagenumber: newPage + 1,
        pagesize: rowsPerPage,
      };
      // dispatch(getMyTeamApproval());
      dispatch(getFilterTeamTimesheetData(obj)).then(() => isSetLoader(false));
    } else {
      dispatch(
        getMyTeamApprovalData({
          managerEmpId: tsDelEmpIdRedux
            ? tsDelEmpIdRedux
            : ssoEmpIdDetails?.empId,
          fromDate: tempChips?.fromDate ? tempChips?.fromDate : "",
          toDate: tempChips?.toDate ? tempChips?.toDate : "",
          projNameId: tempChips.employeeChip ? IDs : [],
          empNameId: tempChips.employeeIdChip ? tempChips.employeeIdChip : [],
          empType: tempChips?.empType ? tempChips?.empType : "",
          empCat: tempChips?.empCat ? tempChips?.empCat : [],
          pagenumber: newPage + 1,
          pagesize: rowsPerPage,
        })
      ).then(() => isSetLoader(false));
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    isSetLoader(true);
    if (applyFilterClicked) {
      let obj = {
        managerEmpId: tsDelEmpIdRedux
          ? tsDelEmpIdRedux
          : ssoEmpIdDetails?.empId,
        fromDate: tempChips?.fromDate ? tempChips?.fromDate : "",
        toDate: tempChips?.toDate ? tempChips?.toDate : "",
        projNameId: tempChips.employeeChip ? IDs : [],
        empNameId: tempChips.employeeIdChip ? tempChips.employeeIdChip : [],
        empType: tempChips?.empType ? tempChips?.empType : "",
        empCat: tempChips?.empCat ? tempChips?.empCat : [],
        pagenumber: 1,
        pagesize: parseInt(event.target.value, 10),
      };
      // dispatch(getMyTeamApproval());
      dispatch(getFilterTeamTimesheetData(obj)).then(() => isSetLoader(false));
    } else {
      dispatch(
        getMyTeamApprovalData({
          managerEmpId: tsDelEmpIdRedux
            ? tsDelEmpIdRedux
            : ssoEmpIdDetails?.empId,
          pagenumber: 1,
          pagesize: parseInt(event.target.value, 10),
        })
      ).then(() => isSetLoader(false));
    }
  };
  const handleComment = (data) => {
    setApproveRejectComment(data);
  };

  const handleCloseApproveRejectDialog = () => {
    setShowApproveRejectDialog(false);
    setSelected([]);
    setMultiDownload([]);
  };
  const handlecloseSnackbar = () => {
    setOpen(false);
  };
  const handleTooltipClose = () => {
    // console.log("close");
    setOpened(false);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleToolTip = (comment) => {
    // console.log("commentsss", comment);
    // console.log("aa gye hum yha");
    // const arr = comment.split("|");

    return (
      <>
        <Box>
          {comment?.map((value, index) => {
            return (
              <>
                {value == "" ? (
                  ""
                ) : (
                  <div>
                    <Typography
                      style={{ fontSize: "0.625rem", fontWeight: 600 }}
                    >
                      {index == 0 ? " System Comments:" : "Employee Comments:"}
                    </Typography>
                    <Typography
                      //key={index}
                      style={{ fontSize: "0.625rem" }}
                      className={classes.statusName}
                    >
                      {comment[index]}
                    </Typography>
                  </div>
                )}
              </>
            );
          })}
        </Box>
      </>
    );
  };
  const handlToolAction = (event) => {
    
    setToolAction(event.currentTarget);
  };
  const handleTooltipOpen = (sysComments, empComments, index) => {
  
    const comments = [sysComments, empComments];
    setOpened(true);
    setCommentData(comments);
    
    setCurrentIndex(index);
  };
  const handleParentToolTip = () => {
   
    return (
      <>
        <OutsideClickHandler
          onOutsideClick={() => {
            handleTooltipClose();
          }}
        >
          <div style={{ position: "relative" }}>
            <Tooltip
              PopperProps={{
                disablePortal: true,
                // anchorEl: { toolAction },
              }}
              arrow
              onClose={handleTooltipClose}
              open={opened}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={handleToolTip(commentData)}
              classes={{
                popper: classes.tooltipBox,
                arrow: classes.arrow,
              }}
              // placement="left"
              anchorEl={toolAction}
            >
              <span
                style={{ maxwidth: "1.25rem", minWidth: "0 rem", height: "0" }}
              ></span>
            </Tooltip>
          </div>
        </OutsideClickHandler>
      </>
    );
  };
  const list = (anchor) => (
    <div
      style={{ overflowX: "hidden", overflowY: "hidden" }}
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      {" "}
      <ArrowBackIosIcon
        onClick={toggleDrawer(anchorFilter1, false)}
        style={{ paddingLeft: "0.75rem", marginTop: "1.188rem" }}
      />
      <span
        style={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: 600,
            lineHeight: "1.125rem",
            marginTop: "-1.563rem",
            marginLeft: "-14rem",
            // display:"flex",
            // justifyContent:"center"
          }}
        >
          Filter
        </Typography>
      </span>
      <Divider />
      <br />
      <Grid
        container
        spacing={3}
        style={{
          display: "flex",
          //marginBottom: "15px",
          alignItems: "baseline",
          //justifyContent: "space-between",
          flexDirection: "column",
          margin: "0px",
        }}
      >
        <Grid item xs={1} md={1}>
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            style={{ width: "8.5rem", padding: "1.063rem" }}
          >
            <KeyboardDatePicker
              disableToolbar
              inputVariant="outlined"
              fullWidth
              style={{
                //marginLeft: "0.625rem",
                width: "18.75rem",
              }}
              variant="inline"
              size="small"
              format="MMM d, yy"
              autoOk={true}
              minDate={systemLiveDate}
              maxDate={futureLimitDate}
              id="date-picker-inline"
              label={
                <span
                  style={{
                    fontSize: "0.75rem",
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                  }}
                >
                  From Date
                </span>
              }
              value={fromDate}
              onChange={handleFromDate}
              inputProps={{
                style: { fontSize: "0.875rem", fontWeight: "500" },
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
                style: { height: "1rem", width: "1rem" },
              }}
              helperText={
                (toDate != null || tempChips.toDate != null) &&
                (toDate < fromDate ||
                  toDate < tempChips?.fromDate ||
                  tempChips?.toDate < fromDate)
                  ? "*To Date should be greater than From Date"
                  : ""
              }
              FormHelperTextProps={{
                style: {
                  color: "red",
                  paddingTop: "5px",
                  marginLeft: "0px",
                  marginRight: "0px",
                  marginTop: 0,
                  marginBottom: "-8px",
                  width: "max-content",
                },
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={1} md={1} style={{ marginLeft: "1.875rem" }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              inputVariant="outlined"
              fullWidth
              style={{
                //marginLeft: "0.625rem",
                width: "18.75rem",
              }}
              variant="inline"
              minDate={systemLiveDate}
              maxDate={futureLimitDate}
              size="small"
              format="MMM d, yy"
              autoOk={true}
              id="date-picker-inline"
              label={
                <span
                  style={{
                    fontSize: "0.75rem",
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                  }}
                >
                  To Date
                </span>
              }
              value={toDate}
              onChange={handleToDate}
              inputProps={{
                style: { fontSize: "0.875rem", fontWeight: "500" },
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
                style: { height: "1rem", width: "1rem" },
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid
          item
          xs={2}
          md={2}
          style={{ marginLeft: "1.875rem" }}
          //  style={{  }}
          className={classes.boxForEmpType}
        >
          <Autocomplete
              multiple
              options={
                Array.isArray(filterDataJson?.EntityCode)
                  ? filterDataJson?.EntityCode
                  : []
              }
              style={{ width: "300px" }}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  height: "44px",
                },
                ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                  top: "auto",
                },
                ".MuiIconButton-root": {
                  padding: "2px",
                  marginBottom: "14px",
                },
              }}
              value={employeeType}
              onChange={handleEmpTypeChange}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              getOptionSelected={(option, value) =>
                employeeType.indexOf(option) !== -1
              }
              renderOption={(props, option, { selected }) => (
                <li
                  style={{ paddingTop: "0px", paddingBottom: "0px" }}
                  {...props}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: selected ? "#0B1941" : "" }}
                        checked={selected}
                      />
                    }
                    label={<span style={{ fontSize: "12px" }}>{option}</span>}
                  />
                </li>
              )}
              renderTags={() => null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ height: "40px" }}
                  label="Employee Type"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{
                    style: {
                      fontSize: "12px",
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                    }, // Set the font size here
                  }}
                />
              )}
            />
        </Grid>
        <Grid
          item
          xs={2}
          md={2}
          style={{ marginLeft: "1.875rem" }}
          //  style={{  }}
          className={classes.boxForEmpType}
        >
          <Autocomplete
              multiple
              options={
                Array.isArray(filterDataJson?.Horizontal)
                  ? filterDataJson?.Horizontal
                  : []
              }
              style={{ width: "300px" }}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  height: "44px",
                },
                ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                  top: "auto",
                },
                ".MuiIconButton-root": {
                  padding: "2px",
                  marginBottom: "14px",
                },
              }}
              value={empCategory}
              onChange={handleEmpCatChange}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              getOptionSelected={(option, value) =>
                empCategory.indexOf(option) !== -1
              }
              renderOption={(props, option, { selected }) => (
                <li
                  style={{ paddingTop: "0px", paddingBottom: "0px" }}
                  {...props}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: selected ? "#0B1941" : "" }}
                        checked={selected}
                      />
                    }
                    label={<span style={{ fontSize: "12px" }}>{option}</span>}
                  />
                </li>
              )}
              renderTags={() => null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    style: {
                      fontSize: "12px",
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                    }, // Set the font size here
                  }}
                  label="Employee Category"
                  variant="outlined"
                  size="small"
                />
              )}
            />
        </Grid>
        <Grid item xs={2} md={2} style={{ marginLeft: "1.875rem" }}>
          <FormControl
            variant="outlined"
            fullWidth
            style={{
              //marginLeft: "0.625rem",
              width: "18.75rem",
            }}
          >
            <InputLabel
              htmlFor="search-input"
              style={{
                marginTop: "-0.313rem",
                fontSize: "0.75rem",
                fontWeight: "600",
                fontFamily: "Montserrat",
              }}
            >
              Search by Employee Name / Id
            </InputLabel>
            <OutlinedInput
              id="search-input"
              size="small"
              style={{ paddingRight: "0.313rem", height: "2.5rem" }}
              value={employeeId}
              onChange={handleEmployeeId}
              onKeyDown={handleEmployeeId}
              onBlur={handleEmployeeId}
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon style={{ height: "1.25rem", width: "1.25rem" }} />
                </InputAdornment>
              }
              label="Search by Employee Name"
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} md={2} style={{ marginLeft: "1.875rem" }}>
        <Autocomplete
              multiple
              options={
                Array.isArray(filterDataJson?.ProjectName)
                  ? filterDataJson?.ProjectName
                  : []
              }
              style={{ width: "300px" }}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  height: "44px",
                },
                ".css-1q60rmi-MuiAutocomplete-endAdornment": {
                  top: "auto",
                },
                ".MuiIconButton-root": {
                  padding: "2px",
                  marginBottom: "14px",
                },
              }}
              value={projectNameId}
              onChange={handleProjectNameId}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              getOptionSelected={(option, value) =>
                projectNameId.indexOf(option) !== -1
              }
              renderOption={(props, option, { selected }) => (
                <li
                  style={{ paddingTop: "0px", paddingBottom: "0px" }}
                  {...props}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: selected ? "#0B1941" : "" }}
                        checked={selected}
                      />
                    }
                    label={
                      <span style={{ fontSize: "10px" }}>
                        {option.replace(/@\$\@/g, "-")}
                      </span>
                    }
                  />
                </li>
              )}
              renderTags={() => null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    style: {
                      fontSize: "12px",
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                    }, // Set the font size here
                  }}
                  label="Project Name/Id"
                  variant="outlined"
                  size="small"
                />
              )}
            />
        </Grid>
      </Grid>
      <Divider
        style={{
          height: "0.01rem",
          color: "black",
          width: "100%",
          marginTop: "10px",
        }}
      />
      <Grid
        style={{
          paddingTop: "10px",
          paddingBottom: "10px",
          marginRight: "10px",
          marginLeft: "2rem",
        }}
      >
        {Object.entries(chips).map(([chipType, chipValue], index) => (
          <React.Fragment key={index} style={{ height: "25px" }}>
            {Array.isArray(chipValue)
              ? chipValue.map((value, valueIndex) => (
                  <Chip
                    key={`${chipType}-${valueIndex}`}
                    label={value}
                    onDelete={() => handleChipDelete(value, chipType)}
                    style={{
                      margin: "5px",
                      backgroundColor: "#fff",
                      color: "#000",
                      fontWeight: "600",
                      border: "1px solid #000",
                      fontSize: "10px",
                      height: "25px",
                    }}
                    deleteIcon={
                      <ClearIcon
                        style={{
                          color: "#000",
                          height: "16px",
                          width: "16px",
                        }}
                      />
                    }
                  />
                ))
              : chipValue && (
                  <Chip
                    key={`${chipType}-${chipValue}`}
                    label={
                      chipType == "fromDate"
                        ? `From Date : ${chipValue}`
                        : chipType == "toDate"
                        ? `To Date : ${chipValue}`
                        : chipType == "empType"
                        ? `${chipValue}`
                        : `${chipType}-${chipValue}`
                    }
                    onDelete={() => handleChipDelete(chipValue, chipType)}
                    style={{
                      margin: "5px",
                      backgroundColor: "#fff",
                      color: "#000",
                      fontWeight: "600",
                      border: "1px solid #000",
                      fontSize: "10px",
                      height: "25px",
                    }}
                    deleteIcon={
                      <ClearIcon
                        style={{
                          color: "#000",
                          height: "16px",
                          width: "16px",
                        }}
                      />
                    }
                  />
                )}
          </React.Fragment>
        ))}
      </Grid>
      {applyFilterClicked && (
        <Divider
          style={{
            height: "0.01rem",
            color: "black",
            width: "100%",
            margin: "0rem",
          }}
        />
      )}
      <Box>
        <Button
          variant="outlined"
          size="small"
          // style={{ marginLeft: "37px", width: "121px" }}
          style={{
            marginLeft: "3.438rem",
            width: "7.563rem",
            textTransform: "inherit",
            fontSize: "0.875rem",
          }}
          onClick={handleClearAll}
        >
          Reset
        </Button>
        <Button
          variant="outlined"
          disabled={buttonState}
          style={{
            background: buttonState ? "#9E9E9E" : "#0B1941",
            marginLeft: "1.25rem",
            marginBottom: "3.125rem",
            marginTop: "3.125rem",
            textTransform: "inherit",
            fontSize: "0.875rem",
          }}
          color="#fffff"
          size="small"
          onClick={() => handleApplyFilter()}
        >
          <Typography style={{ color: "white", margin: 0 }}>
            {" "}
            Apply Filter
          </Typography>
        </Button>
      </Box>
    </div>
  );

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handlecloseSnackbar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.anchorStyle}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          style={{
            background:
              approveRejectStatus === "Approved" ? "#F0FFF6" : "#FEF0F3",
          }}
        >
          {approveRejectStatus == "Approved" ? (
            <Grid style={{ height: "4.188rem", width: "18.75rem" }}>
              
              <div style={{ display: "flex" }}>
                <Grid
                  style={{
                    marginLeft: "2.688rem",
                    paddingTop: "0.563rem",
                    marginRight: "5.625rem",
                  }}
                  className={classes.snackbarMessage}
                >
                  {" "}
                  {"Timesheet Approved"}
                </Grid>
                <Grid
                  style={{ marginleft: "5.625rem", paddingTop: "0.313rem" }}
                >
                  <CloseOutlinedIcon onClick={handlecloseSnackbar} />
                </Grid>
              </div>
              <div style={{ display: "flex" }}>
                <Grid
                  style={{ marginLeft: "0.625rem", marginTop: "-1.563rem" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M15 2L20 7M5 22H19C19.2652 22 19.5196 21.8946 19.7071 21.7071C19.8946 21.5196 20 21.2652 20 21V7H15V2H5C4.73478 2 4.48043 2.10536 4.29289 2.29289C4.10536 2.48043 4 2.73478 4 3V21C4 21.2652 4.10536 21.5196 4.29289 21.7071C4.48043 21.8946 4.73478 22 5 22Z"
                      stroke="#349259"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.5 14.5L11.5 17L16 11.5"
                      stroke="#349259"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Grid>
                {snackBarData?.name == "" ||
                snackBarData?.name == undefined ||
                snackBarData?.id == " " ||
                snackBarData?.id == undefined ? (
                  <Grid className={classes.snackbarMessage1}>
                    {/* {" "}

                  {snackBarData?.name} {" | "}

                  ID : {snackBarData?.id} */}
                  </Grid>
                ) : (
                  <Grid className={classes.snackbarMessage1}>
                    {" "}
                    {snackBarData?.name} {" | "}
                    ID : {snackBarData?.id}
                  </Grid>
                )}
              </div>
            </Grid>
          ) : (
            ""
          )}
          {approveRejectStatus == "Rejected" ? (
            <Grid style={{ height: "4.188rem", width: "18.75rem" }}>
             
              <div style={{ display: "flex" }}>
                <Grid
                  style={{
                    marginLeft: "2.688rem",
                    paddingTop: "0.563rem",
                    marginRight: "5.625rem",
                  }}
                  className={classes.snackbarMessage}
                >
                  {" "}
                  {"Timesheet Rejected"}
                </Grid>
                <Grid
                  style={{ marginleft: "5.625rem", paddingTop: "0.313rem" }}
                >
                  <CloseOutlinedIcon onClick={handlecloseSnackbar} />
                </Grid>
              </div>
              <div style={{ display: "flex" }}>
                <Grid
                  style={{ marginLeft: "0.625rem", marginTop: "-1.563rem" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M15 2L20 7M5 22H19C19.2652 22 19.5196 21.8946 19.7071 21.7071C19.8946 21.5196 20 21.2652 20 21V7H15V2H5C4.73478 2 4.48043 2.10536 4.29289 2.29289C4.10536 2.48043 4 2.73478 4 3V21C4 21.2652 4.10536 21.5196 4.29289 21.7071C4.48043 21.8946 4.73478 22 5 22Z"
                      stroke="#D50606"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9 11L15 17"
                      stroke="#D50606"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15 11L9 17"
                      stroke="#D50606"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Grid>
                <Grid className={classes.snackbarMessage1}>
                  {snackBarData?.name} {" | "}
                  {snackBarData?.id}
                </Grid>
              </div>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Snackbar>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          //margin: "0.625rem",
        }}
      >
        {/* <IconButton> */}
        <StyledBadge badgeContent={countShow} color="secondary">
          <IconButton onClick={toggleDrawer(anchorFilter1, true)}>
            <span class="material-symbols-outlined">filter_alt</span>
          </IconButton>
        </StyledBadge>
        {/* </IconButton> */}
        {/* <FilterAltOutlinedIcon onClick={toggleDrawer(anchorFilter1, true)} /> */}
        <Drawer
          anchor={anchorFilter1}
          open={state[anchorFilter1]}
          onClose={toggleDrawer(anchorFilter1, false)}
          PaperProps={{
            style: { height: "100%", width: "100%" }, // This ensures the drawer covers the whole screen
          }}
        >
          {list(anchorFilter1)}
        </Drawer>
        {indexValue == currentIndex ? (
          <ApproveRejectByManager
            loader={isSetLoader}
            row={rowValue}
            handleApproveRejectByManager={handleApproveRejectByManager}
            handleComment={handleComment}
            //approveRejectStatus={approveRejectStatus}
            firstAppRej={firstAppRej}
            handleApproveRejectStatus={handleApproveRejectStatus}
            // approveRejectComment={approveRejectComment}
            showApproveRejectDialog={showApproveRejectDialog}
            handleCloseApproveRejectDialog={handleCloseApproveRejectDialog}
            // handleSnackbar={handleSnackbar}
          />
        ) : (
          ""
        )}
        {selected?.length == 1 && selected[0] !== "" ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "right",
              alignItems: "right",
            }}
          >
            <IconButton
              style={{
                maxHeight: "40px",
                maxWidth: "40px",
                padding: "5px",
              }}
              onClick={() => handleApprove(rowValue, indexValue)}
            >
              <CheckCircleOutlineIcon
                style={{
                  color: "#349259",
                  fontSize: "28px",
                }}
              />
            </IconButton>
            <IconButton
              style={{
                maxHeight: "40px",
                maxWidth: "40px",
                padding: "5px",
              }}
              onClick={() => handleApproveRejectDialog(indexValue, "Rejected")}
            >
              <CancelOutlinedIcon
                style={{
                  color: "#E02020",
                  fontSize: "28px",
                }}
              />
            </IconButton>
          </Box>
        ) : (
          ""
        )}
        {selected?.length > 1 && selected[0] !== "" ? (
          <Button
            variant="outlined"
            color="primary"
            style={{
              transform: "scale(0.8)",
              whiteSpace: "nowrap",
              textTransform: "unset",
              padding: "8px",
              backgroundColor: "#349259",
              color: "#043365",
              border: "1px solid #979797",
            }}
            onClick={() => handleApprove("", "", "Yes")}
          >
            <CheckCircleOutlineIcon
              style={{
                width: "16px",
                height: "16px",
                color: "#FFFFFF",
              }}
            />
            <Typography
              style={{
                //fontSize: "10px",
                //fontWeight: 500,
                color: "#FFFFFF",
              }}
            >
              &nbsp;Bulk Approval
            </Typography>
          </Button>
        ) : (
          ""
        )}
      </Grid>
      <Paper>
        <TableContainer style={{ overflowX: "clip" }}>
          <Table>
            <MyTeamApprovalTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowsPerPage={rowsPerPage}
              pageNumber={page}
              rowValue={rowValue}
              indexValue={indexValue}
              multiDownload={multiDownload}
              handleApprove={handleApprove}
              handleApproveRejectDialog={handleApproveRejectDialog}
              handleApproveRejectByManager={handleApproveRejectByManager}
              handleComment={handleComment}
              firstAppRej={firstAppRej}
              handleApproveRejectStatus={handleApproveRejectStatus}
              showApproveRejectDialog={showApproveRejectDialog}
              handleCloseApproveRejectDialog={handleCloseApproveRejectDialog}
              currentIndex={currentIndex}
              isSetLoader={isSetLoader}
              checked={checked}
            />
            <TableBody>
              {myTeamApproval?.length > 0 && !isLoader ? (
                // stableSort(
                (applyFilterClicked && teamTimesheet?.length > 0
                  ? teamTimesheet
                  : myTeamApproval
                )
                  // getComparator(order, orderBy)
                  // )
                  // ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    const isItemSelected = isSelected(
                      row.timesheetDetailUniqueId
                    );
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return row?.timesheetDetailUniqueId != "" ? (
                      <TableRow
                        hover
                        className={classes.tableRow}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.timesheetDetailUniqueId}
                        selected={isItemSelected}
                        backgroundColor={isItemSelected ? "" : ""}
                        style={{ height: "63px" }}
                      >
                        <TableCell
                          padding="checkbox"
                          className={classes.stickyLeft}
                          style={{
                            position: "sticky",
                            left: 0,
                            //background: "none",
                            backgroundColor: isItemSelected ? "" : "",
                            zIndex: 800,
                            minWidth: "53px",
                            padding: "0px",
                            // color:"#EFF2F8",
                          }}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            style={{
                              paddingLeft: "12px",
                              transform: "scale(0.8)",
                            }}
                            checkedIcon={
                              <CheckBoxIcon
                                fontSize="small"
                                className={classes.checkBoxRoot}
                                style={{ transform: "scale(0.8)" }}
                              />
                            }
                            onClick={(event) => {
                              handleClick(event, row);
                              setRowValue(row);
                              setIndexValue(index);
                            }}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell
                          style={{ padding: "0rem 0rem 0rem 0rem" }}
                          onClick={() => {
                            const text = "/timesheetviewpage/:id";
                            const result = text.replace(":id", row.empId);
                            history.push({
                              pathname: result,
                              state: {
                                empId: row.empId,
                                managerId: tsDelEmpIdRedux
                                  ? tsDelEmpIdRedux
                                  : ssoEmpIdDetails?.empId,
                                delegateeId:
                                  ssoEmpIdDetails?.empId == tsDelEmpIdRedux
                                    ? null
                                    : ssoEmpIdDetails?.empId,
                                fromDate: moment(row.fromDate).format(
                                  "yyyy-MM-DD"
                                ),
                                toDate: moment(row.toDate).format("yyyy-MM-DD"),
                                projId: "",
                                tab: "myTeamApproval",
                                compName: "DetailedView",
                              },
                            });
                          }}
                        >
                          <Grid
                            container
                            direction="column"
                            spacing={1}
                            style={{ width: "115%" }}
                          >
                            <Grid
                              item
                              style={{
                                paddingBottom: "0rem",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="body1"
                                style={{ fontSize: "0.625rem" }}
                              >
                                {row.date}
                              </Typography>{" "}
                            </Grid>

                            <Grid item style={{ paddingTop: "0rem" }}>
                              <Typography
                                variant="body1"
                                style={{ fontSize: "0.563rem" }}
                              >
                                {row.dayType}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "left",
                            padding: "0rem 0rem 0rem 0rem",
                          }}
                          onClick={() => {
                            // console.log("hi table row clicked!");
                            const text = "/timesheetviewpage/:id";
                            const result = text.replace(":id", row.empId);
                            history.push({
                              pathname: result,
                              state: {
                                empId: row.empId,
                                managerId: tsDelEmpIdRedux
                                  ? tsDelEmpIdRedux
                                  : ssoEmpIdDetails?.empId,
                                delegateeId:
                                  ssoEmpIdDetails?.empId == tsDelEmpIdRedux
                                    ? null
                                    : ssoEmpIdDetails?.empId,
                                fromDate: moment(row.fromDate).format(
                                  "yyyy-MM-DD"
                                ),
                                toDate: moment(row.toDate).format("yyyy-MM-DD"),
                                projId: "",
                                tab: "myTeamApproval",
                                compName: "DetailedView",
                              },
                            });
                          }}
                        >
                          {" "}
                          <Grid container direction="column">
                            <Grid item>
                              <Typography
                                variant="body1"
                                style={{
                                  fontSize: "0.625rem",
                                  margin: "4px 4px 0px 4px",
                                }}
                              >
                                {row.employeeName}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="body1"
                                style={{
                                  fontSize: "0.563rem",
                                  margin: "4px 4px 0px 4px",
                                }}
                              >
                                {row.employeeID}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "left",
                            padding: "0rem 0rem 0rem 0rem",
                          }}
                          onClick={() => {
                           
                            const text = "/timesheetviewpage/:id";
                            const result = text.replace(":id", row.empId);
                            history.push({
                              pathname: result,
                              state: {
                                empId: row.empId,
                                managerId: tsDelEmpIdRedux
                                  ? tsDelEmpIdRedux
                                  : ssoEmpIdDetails?.empId,
                                delegateeId:
                                  ssoEmpIdDetails?.empId == tsDelEmpIdRedux
                                    ? null
                                    : ssoEmpIdDetails?.empId,
                                fromDate: moment(row.fromDate).format(
                                  "yyyy-MM-DD"
                                ),
                                toDate: moment(row.toDate).format("yyyy-MM-DD"),
                                projId: "",
                                tab: "myTeamApproval",
                                compName: "DetailedView",
                              },
                            });
                          }}
                        >
                          {" "}
                          <Grid container direction="column">
                            <Grid item>
                              <Typography
                                variant="body1"
                                style={{
                                  fontSize: "0.625rem",
                                  margin: "4px 4px 0px 4px",
                                }}
                              >
                                {row.employeeType}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="body1"
                                style={{
                                  fontSize: "0.563rem",
                                  margin: "4px 4px 0px 4px",
                                }}
                              >
                                {row.employeeCategory}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>

                        <TableCell
                          style={{
                            textAlign: "left",
                            padding: "0rem 0rem 0rem 0rem",
                          }}
                          onClick={() => {
                            
                            const text = "/timesheetviewpage/:id";
                            const result = text.replace(":id", row.empId);
                            history.push({
                              pathname: result,
                              state: {
                                empId: row.empId,
                                managerId: tsDelEmpIdRedux
                                  ? tsDelEmpIdRedux
                                  : ssoEmpIdDetails?.empId,
                                delegateeId:
                                  ssoEmpIdDetails?.empId == tsDelEmpIdRedux
                                    ? null
                                    : ssoEmpIdDetails?.empId,
                                fromDate: moment(row.fromDate).format(
                                  "yyyy-MM-DD"
                                ),
                                toDate: moment(row.toDate).format("yyyy-MM-DD"),
                                projId: "",
                                tab: "myTeamApproval",
                                compName: "DetailedView",
                              },
                            });
                          }}
                        >
                          {" "}
                          <Grid container direction="column">
                            <Grid item>
                              <Typography
                                variant="body1"
                                style={{
                                  fontSize: "0.625rem",
                                  margin: "4px 4px 0px 4px",
                                }}
                              >
                                {row.projName}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="body1"
                                style={{
                                  fontSize: "0.563rem",
                                  margin: "4px 4px 0px 4px",
                                }}
                              >
                                {row.projectId}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>

                        <TableCell
                          style={{
                            textAlign: "left",
                            padding: "0rem 0rem 0rem 0rem",
                          }}
                          // className={classes.stickyRight}
                          onClick={() => {
                            
                            const text = "/timesheetviewpage/:id";
                            const result = text.replace(":id", row.empId);
                            history.push({
                              pathname: result,
                              state: {
                                empId: row.empId,
                                managerId: tsDelEmpIdRedux
                                  ? tsDelEmpIdRedux
                                  : ssoEmpIdDetails?.empId,
                                delegateeId:
                                  ssoEmpIdDetails?.empId == tsDelEmpIdRedux
                                    ? null
                                    : ssoEmpIdDetails?.empId,
                                fromDate: moment(row.fromDate).format(
                                  "yyyy-MM-DD"
                                ),
                                toDate: moment(row.toDate).format("yyyy-MM-DD"),
                                projId: "",
                                tab: "myTeamApproval",
                                compName: "DetailedView",
                              },
                            });
                          }}
                        >
                          <Grid
                            container
                            direction="column"
                            style={{ marginRight: "-3px", paddingLeft: "5px" }}
                          >
                            <Grid item>
                              <Typography
                                variant="body1"
                                style={{
                                  fontSize: "0.625rem",
                                  margin: "4px 4px 0px 4px",
                                  paddingLeft: "1.25rem",
                                }}
                              >
                                {row.dayHours}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="body1"
                                style={{
                                  backgroundColor: "#E3E8F7",
                                  width:
                                    // row.categoryName === "Billable"
                                    //   ?
                                    "3.25rem",
                                  //: "3.75rem",
                                  height: "1.188rem",
                                  paddingLeft:
                                    row.categoryName === "Billable"
                                      ? "0.813rem"
                                      : "1rem",
                                  height: " 0.938rem",
                                  fontSize: " 0.563rem",
                                }}
                              >
                                {row.categoryName == "Billable"
                                  ? row.categoryName
                                  : "Non-Bill"}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>

                        <TableCell style={{ padding: "0rem 0rem 0rem 0.5rem" }}>
                          <Grid
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              //padding: "0rem 0rem 0rem 1.4rem",
                              marginBottom: "4px",
                            }}
                          >
                            <div>
                              {/* <Tooltip placement="left"> */}
                              <InfoOutlinedIcon
                                ref={target}
                                onClick={(event) => {
                                  handleTooltipOpen(
                                    row.systemComments,
                                    row.employeeComments?.includes("#~#~")
                                      ? row.employeeComments.replace(
                                          /#~#~/g,
                                          " "
                                        )
                                      : row.employeeComments,
                                    index
                                  );
                                  handlToolAction(event);
                                }}
                                className={classes.tooltip}
                              />
                              {/* {console.log("hum first")} */}

                              {opened &&
                                index === currentIndex &&
                                handleParentToolTip()}
                              {/* </Tooltip> */}
                            </div>
                          </Grid>
                        </TableCell>
                        {/* <TableCell
                          style={{ padding: "0rem 0rem 0rem 0rem" }}
                        >
                          {" "}
                          <Grid
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              // padding: "0rem 0rem 0rem 0.625rem",
                            }}
                          >
                            <div
                              //className={classes.iconStyle}
                              style={{
                                color: "#349259",
                                marginTop: "0.125rem",
                                marginLeft:"-2px",
                                width: "1.5rem",
                                height: "2rem",
                                flexShrink: "0",
                              }}
                            >
                              <CheckCircleOutlinedIcon
                                onClick={
                                  () => handleApprove(row, index)
                                  // handleApproveRejectDialog(index, "Approved")
                                }
                              />
                            </div>
                            <div
                              className={classes.iconStyle}
                              style={{
                                color: "#e02020",
                                marginTop: "0.125rem",
                                marginRight:"12px",
                                marginLeft:"2px"
                              }}
                            >
                              <CancelOutlinedIcon
                                onClick={() =>
                                  handleApproveRejectDialog(index, "Rejected")
                                }
                              />
                            </div>
                          </Grid>
                        </TableCell> */}
                        {/* {index == currentIndex ? (
                          <ApproveRejectByManager
                            loader={isSetLoader}
                            row={row}
                            handleApproveRejectByManager={
                              handleApproveRejectByManager
                            }
                            handleComment={handleComment}
                            //approveRejectStatus={approveRejectStatus}
                            firstAppRej={firstAppRej}
                            handleApproveRejectStatus={
                              handleApproveRejectStatus
                            }
                            // approveRejectComment={approveRejectComment}
                            showApproveRejectDialog={showApproveRejectDialog}
                            handleCloseApproveRejectDialog={
                              handleCloseApproveRejectDialog
                            }
                            // handleSnackbar={handleSnackbar}
                          />
                        ) : (
                          <></>
                        )} */}
                      </TableRow>
                    ) : (
                      <></>
                    );
                  })
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {myTeamApproval?.length > 0 &&
        myTeamApproval[0]?.empId !== "" &&
        !isLoader ? (
          <Box
            style={{
              display: "flex",
              //marginLeft: "54px",
              //justifyContent: "space-between",
              //position: "fixed",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="Box"
              count={
                applyFilterClicked && noDataInFilterFlag == 1
                  ? teamTimesheet?.length - 1
                  : applyFilterClicked &&
                    noDataInFilterFlag == 0 &&
                    teamTimesheet
                  ? teamTimesheet[0]?.totalRecords
                  : myTeamApproval?.length == 1 && noTeamApprovalData == 1
                  ? myTeamApproval?.length - 1
                  : myTeamApproval
                  ? myTeamApproval[0]?.totalRecords
                  : 0
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            ></TablePagination>
          </Box>
        ) : (
          <></>
        )}
      </Paper>
      {myTeamApproval?.length === 1 && myTeamApproval[0]?.empId === "" ? (
        <Grid>
          <Typography
            style={{
              fontSize: "1rem",
              lineHeight: "6.25rem",
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            No Timesheet entry exists!
          </Typography>
        </Grid>
      ) : (
        <></>
      )}
      <div>
        <Backdrop
          sx={{ color: "#092FED", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </div>
  );
}

export default ResponsiveMyTeamApproval;