import * as types from "./actionType";
import API from "../../Utils/endpoints";
import config from "../../Utils/config";
import history from "../../history";
import Decrypt from "../../Utils/decryption";
import Encrypt from "../../Utils/encryption";
import Dec from "../../Utils/decryptString";
import axios from "axios";

//Accesstype action
export function accessTypeData() {
  return {
    type: types.GET_ACCESSTYPE_LIST,
  };
}

export function accessTypeDataSucess(payload) {
  return {
    type: types.GET_ACCESSTYPE_LIST_SUCCESS,
    payload,
  };
}

export function accessTypeDataFailed(payload) {
  return {
    type: types.GET_ACCESSTYPE_LIST_FAILED,
    payload,
  };
}
// Purpose Action
export function purposeData() {
  return {
    type: types.GET_PURPOSE_LIST,
  };
}

export function purposeDataSucess(payload) {
  return {
    type: types.GET_PURPOSE_LIST_SUCCESS,
    payload,
  };
}

export function purposeDataFailed(payload) {
  return {
    type: types.GET_PURPOSE_LIST_FAILED,
    payload,
  };
}

//Category Action
export function categoryData() {
  return {
    type: types.GET_CATEGORY_LIST,
  };
}

export function categoryDataSucess(payload) {
  return {
    type: types.GET_CATEGORY_LIST_SUCCESS,
    payload,
  };
}

export function categoryDataFailed(payload) {
  return {
    type: types.GET_CATEGORY_LIST_FAILED,
    payload,
  };
}

//No. of dependents action
export function dependentsData() {
  return {
    type: types.GET_DEPENDENTS_LIST,
  };
}

export function dependentsDataSuccess(data) {
  return {
    type: types.GET_DEPENDENTS_LIST_SUCCESS,
    payload: data,
  };
}

export function dependentsDataFailed() {
  return {
    type: types.GET_DEPENDENTS_LIST_FAILED,
  };
}

//Currency Action
export function currencyData() {
  return {
    type: types.GET_CURRENCY_LIST,
  };
}

export function currencyDataSucess(payload) {
  return {
    type: types.GET_CURRENCY_LIST_SUCCESS,
    payload,
  };
}

export function currencyDataFailed(payload) {
  return {
    type: types.GET_CURRENCY_LIST_FAILED,
    payload,
  };
}

//Employee projects actions:
export function employeeProjectsData() {
  return { type: types.GET_EMPLOYEE_PROJECTS_LIST };
}

export function employeeProjectsDataSuccess(payload) {
  return {
    type: types.GET_EMPLOYEE_PROJECTS_LIST_SUCCESS,
    payload,
  };
}

export function employeeProjectsDataFailed(payload) {
  return {
    type: types.GET_EMPLOYEE_PROJECTS_LIST_FAILED,
    payload,
  };
}

//Attendees Action
export function attendeesData() {
  return {
    type: types.GET_ATTENDEES_LIST,
  };
}

export function attendeesDataSucess(payload) {
  return {
    type: types.GET_ATTENDEES_LIST_SUCCESS,
    payload,
  };
}

export function attendeesDataFailed(payload) {
  return {
    type: types.GET_ATTENDEES_LIST_FAILED,
    payload,
  };
}

// Validate Attendees Action
export function validateAttendeesData() {
  return {
    type: types.GET_VALIDATE_ATTENDEES_LIST,
  };
}

export function validateAttendeesDataSucess(payload) {
  return {
    type: types.GET_VALIDATE_ATTENDEES_LIST_SUCCESS,
    payload,
  };
}

export function validateAttendeesDataFailed(payload) {
  return {
    type: types.GET_VALIDATE_ATTENDEES_LIST_FAILED,
    payload,
  };
}

// payment Action
export function paymentData() {
  return {
    type: types.GET_PAYMENT_LIST,
  };
}

export function paymentDataSucess(payload) {
  return {
    type: types.GET_PAYMENT_LIST_SUCCESS,
    payload,
  };
}

export function paymentDataFailed(payload) {
  return {
    type: types.GET_PAYMENT_LIST_FAILED,
    payload,
  };
}

// Post Create Expense Action
export function postCreateExpense() {
  return {
    type: types.POST_CREATE_EXPENSE,
  };
}

export function postCreateExpenseSucess(payload) {
  return {
    type: types.POST_CREATE_EXPENSE_SUCCESS,
    payload,
  };
}

export function postCreateExpenseFailed(payload) {
  return {
    type: types.POST_CREATE_EXPENSE_FAILED,
    payload,
  };
}
// Post Create Expense Action
export function postFileUpload() {
  return {
    type: types.POST_UPLOAD_ATTACHMENTS,
  };
}

export function postFileUploadSuccess(payload) {
  return {
    type: types.POST_UPLOAD_ATTACHMENTS_SUCCESS,
    payload,
  };
}

export function postFileUploadFailed(payload) {
  return {
    type: types.POST_UPLOAD_ATTACHMENTS_FAILED,
    payload,
  };
}

//  Get My Expense
export function getMyExpense() {
  return {
    type: types.GET_MY_EXPENSE,
  };
}

export function getMyExpenseSucess(payload) {
  return {
    type: types.GET_MY_EXPENSE_SUCCESS,
    payload,
  };
}

export function getMyExpenseFailed(payload) {
  return {
    type: types.GET_MY_EXPENSE_FAILED,
    payload,
  };
}

//Get My Team Approval API:
export function getMyTeamApproval(payload) {
  return {
    type: types.GET_MY_TEAM_APPROVAL,
    payload,
  };
}

export function getMyTeamApprovalSuccess(payload) {
  return {
    type: types.GET_MY_TEAM_APPROVAL_SUCCESS,
    payload,
  };
}

export function getMyTeamApprovalFailed(payload) {
  return {
    type: types.GET_MY_TEAM_APPROVAL_FAILED,
    payload,
  };
}

//Get Finance Approval List API:
export function getFinanceApprovalList(payload) {
  return {
    type: types.GET_FINANCE_APPROVAL_LIST,
    payload,
  };
}

export function getFinanceApprovalListSuccess(payload) {
  return {
    type: types.GET_FINANCE_APPROVAL_LIST_SUCCESS,
    payload,
  };
}

export function getFinanceApprovalListFailed(payload) {
  return {
    type: types.GET_FINANCE_APPROVAL_LIST_FAILED,
    payload,
  };
}

// Get MyExpense Data With Travel
export function getMyExpenseTravel() {
  return {
    type: types.GET_MY_EXPENSE_TRAVEL,
  };
}

export function getMyExpenseSucessTravel(payload) {
  return {
    type: types.GET_MY_EXPENSE_SUCCESS_TRAVEL,
    payload,
  };
}

export function getMyExpenseFailedTravel(payload) {
  return {
    type: types.GET_MY_EXPENSE_FAILED_TRAVEL,
    payload,
  };
}

//  Delete My Expense
export function deleteMyExpense() {
  return {
    type: types.DELETE_MY_EXPENSE,
  };
}

export function deleteMyExpenseSucess(payload) {
  return {
    type: types.DELETE_MY_EXPENSE_SUCCESS,
    payload,
  };
}

export function deleteMyExpenseFailed(payload) {
  return {
    type: types.DELETE_MY_EXPENSE_FAILED,
    payload,
  };
}

//  Post Download Files
export function postDownload() {
  return {
    type: types.POST_DOWNLOAD,
  };
}

export function postDownloadSucess(payload) {
  return {
    type: types.POST_DOWNLOAD_SUCCESS,
    payload,
  };
}

export function postDownloadFailed(payload) {
  return {
    type: types.POST_DOWNLOAD_FAILED,
    payload,
  };
}

//  Get MyExpenseReportData
export function myExpenseReportData() {
  return {
    type: types.MY_EXPENSE_REPORT_DATA,
  };
}

export function myExpenseReportDataSucess(payload) {
  return {
    type: types.MY_EXPENSE_REPORT_DATA_SUCCESS,
    payload,
  };
}

export function myExpenseReportDataFailed(payload) {
  return {
    type: types.MY_EXPENSE_REPORT_DATA_FAILED,
    payload,
  };
}

// Post Approve Expense Action
export function postApproveRejectExpense() {
  return {
    type: types.POST_APPROVE_EXPENSE,
  };
}

export function postApproveRejectExpenseSuccess(payload) {
  return {
    type: types.POST_APPROVE_EXPENSE_SUCCESS,
    payload,
  };
}

export function postApproveRejectExpenseFailed(payload) {
  return {
    type: types.POST_APPROVE_EXPENSE_FAILED,
    payload,
  };
}

// Post Approve Expense Action
export function postFinancialApproveRejectExpense() {
  return {
    type: types.POST_FINANCIAL_APPROVE_EXPENSE,
  };
}

export function postFinancialApproveRejectExpenseSuccess(payload) {
  return {
    type: types.POST_FINANCIAL_APPROVE_EXPENSE_SUCCESS,
    payload,
  };
}

export function postFinancialApproveRejectExpenseFailed(payload) {
  return {
    type: types.POST_FINANCIAL_APPROVE_EXPENSE_FAILED,
    payload,
  };
}

//  Get MyViewExpenseData
export function myViewExpenseData() {
  return {
    type: types.MY_VIEW_EXPENSE_DATA,
  };
}

export function myViewExpenseDataSucess(payload) {
  return {
    type: types.MY_VIEW_EXPENSE_DATA_SUCCESS,
    payload,
  };
}

export function myViewExpenseDataFailed(payload) {
  return {
    type: types.MY_VIEW_EXPENSE_DATA_FAILED,
    payload,
  };
}
//  Get MyTeamApprovalPageFlagData
export function MyTeamApprovalPageFlagData() {
  return {
    type: types.GET_MY_TEAM_APPROVAL_PAGE_FLAG,
  };
}

export function MyTeamApprovalPageFlagDataSuccess(payload) {
  return {
    type: types.GET_MY_TEAM_APPROVAL_PAGE_FLAG_SUCCESS,
    payload,
  };
}

export function MyTeamApprovalPageFlagDataFailed(payload) {
  return {
    type: types.GET_MY_TEAM_APPROVAL_PAGE_FLAG_FAILED,
    payload,
  };
}

// Post Edit Expense Action
export function postEditExpense() {
  return {
    type: types.POST_EDIT_EXPENSE,
  };
}

export function postEditExpenseSucess(payload) {
  return {
    type: types.POST_EDIT_EXPENSE_SUCCESS,
    payload,
  };
}

export function postEditExpenseFailed(payload) {
  return {
    type: types.POST_EDIT_EXPENSE_FAILED,
    payload,
  };
}

//RESET EXPENSE RESPONSE
export function resetExpResponse() {
  return {
    type: types.RESET_EXPENSE_RESPONSE,
  };
}
//RESET COMING FROM OTHER MODULE
export function resetFromOtherModuleFxn() {
  return {
    type: types.RESET_FROM_OTHER_MODULE_TOGGLE,
  };
}

//SET FROM OTHER MODULE TOGGLE
export function setFromOtherModuleFxn() {
  return {
    type: types.SET_FROM_OTHER_MODULE_TOGGLE,
  };
}
//RESET AUDIT HISTORY DATA
export function resetAuditHistData() {
  return {
    type: types.RESET_AUDIT_HISTORY_DATA,
  };
}
//  Get All Expenses Download Report
export function getDownloadExpenseReportList() {
  return {
    type: types.GET_DOWNLOAD_ALL_EXPENSES_REPORT,
  };
}

export function getDownloadExpenseReportListSuccess(payload) {
  return {
    type: types.GET_DOWNLOAD_ALL_EXPENSES_REPORT_SUCCESS,
    payload,
  };
}

export function getDownloadExpenseReportListFailed(payload) {
  return {
    type: types.GET_DOWNLOAD_ALL_EXPENSES_REPORT_FAILED,
    payload,
  };
}
//Get Download expense report page wise:
export function getDownloadExpenseReportPageWiseList() {
  return {
    type: types.GET_DOWNLOAD_ALL_EXPENSES_PAGEWISE,
  };
}

export function getDownloadExpenseReportPageWiseListSuccess(payload) {
  return {
    type: types.GET_DOWNLOAD_ALL_EXPENSES_PAGEWISE_SUCCESS,
    payload,
  };
}

export function getDownloadExpenseReportPageWiseListFailed(payload) {
  return {
    type: types.GET_DOWNLOAD_ALL_EXPENSES_PAGEWISE_FAILED,
    payload,
  };
}

// Get Deligation List Action
export function getDeligationList() {
  return {
    type: types.GET_DELEGATION_LIST,
  };
}

export function getDeligationListSucess(payload) {
  return {
    type: types.GET_DELEGATION_LIST_SUCCESS,
    payload,
  };
}

export function getDeligationListFailed(payload) {
  return {
    type: types.GET_DELEGATION_LIST_FAILED,
    payload,
  };
}

// Get DeligationDropeDown List Action
export function getDeligationDropeDownList() {
  return {
    type: types.GET_DELIGATION_DROPEDOWN_LIST,
  };
}

export function getDeligationDropeDownListSucess(payload) {
  return {
    type: types.GET_DELIGATION_DROPEDOWN_LIST_SUCCESS,
    payload,
  };
}

export function getDeligationDropeDownListFailed(payload) {
  return {
    type: types.GET_DELIGATION_DROPEDOWN_LIST_FAILED,
    payload,
  };
}

// Get EmployeeDropeDown List Action
export function getEmployeeDropeDownList() {
  return {
    type: types.GET_EMPLOYEE_DROPEDOWN_LIST,
  };
}

export function getEmployeeDropeDownListSucess(payload) {
  return {
    type: types.GET_EMPLOYEE_DROPEDOWN_LIST_SUCCESS,
    payload,
  };
}

export function getEmployeeDropeDownListFailed(payload) {
  return {
    type: types.GET_EMPLOYEE_DROPEDOWN_LIST_FAILED,
    payload,
  };
}

// post Deligate Save  Action
export function postDeligateSaveList() {
  return {
    type: types.POST_DELIGATE_SAVE_LIST,
  };
}

export function postDeligateSaveListSucess(payload) {
  return {
    type: types.POST_DELIGATE_SAVE_LIST_SUCCESS,
    payload,
  };
}

export function postDeligateSaveListFailed(payload) {
  return {
    type: types.POST_DELIGATE_SAVE_LIST_FAILED,
    payload,
  };
}

// post Deligate Save  Action
export function postDeligateUpdateList() {
  return {
    type: types.POST_DELIGATE_UPDATE_LIST,
  };
}

export function postDeligateUpdateListSucess(payload) {
  return {
    type: types.POST_DELIGATE_UPDATE_LIST_SUCCESS,
    payload,
  };
}

export function postDeligateUpdateListFailed(payload) {
  return {
    type: types.POST_DELIGATE_UPDATE_LIST_FAILED,
    payload,
  };
}

//ResetDeligationSnakbar Action
export function resetDeliationSnakbar() {
  return {
    type: types.RESET_DELIGATION,
  };
}

//  Get getMyDelegateesList
export function getMyDelegateesList() {
  return {
    type: types.MY_DELEGATEES_DATA,
  };
}

export function getMyDelegateesListSuccess(payload) {
  return {
    type: types.MY_DELEGATEES_DATA_SUCCESS,
    payload,
  };
}

export function getMyDelegateesListFailed(payload) {
  return {
    type: types.MY_DELEGATEES_DATA_FAILED,
    payload,
  };
}

// Get updated SSO ID:
export function updateSsoEmpId(payload) {
  return {
    type: types.UPDATED_SSO_EMP_ID,
    payload: payload,
  };
}

// Get updated delegatee ID:
export function updateDelEmpId(payload) {
  return {
    type: types.UPDATE_DELEGATEE_EMP_ID,
    payload: payload,
  };
}
// get reload toggle :
export function reloadToggleFxn(payload) {
  return {
    type: types.RELOAD_MODULE_TOGGLE,
    payload: payload,
  };
}
// get maintain State Variable Fxn :
export function maintainStateVariableFxn(payload) {
  return {
    type: types.MAINTAIN_STATE_VARIABLE,
    payload: payload,
  };
}
// get maintain State Variable Fxn :
export function setFullFinalAllExpenseFxn(payload) {
  return {
    type: types.SET_FULL_FINAL_ALL_EXPENSE_DATA,
    payload: payload,
  };
}

// Get ExmpDetals Action
export function getEmpDetailData() {
  return {
    type: types.GET_EMP_DETAILS_DATA,
  };
}

export function getEmpDetailDataSucess(payload) {
  return {
    type: types.GET_EMP_DETAILS_DATA_SUCCESS,
    payload,
  };
}

export function getEmpDetailDataFailed(payload) {
  return {
    type: types.GET_EMP_DETAILS_DATA_FAILED,
    payload,
  };
}

// Get GetDelegationFlag Action
export function getDelegataionFlagData() {
  return {
    type: types.GET_DELEGATION_FLAG,
  };
}

export function getDelegataionFlagDataSucess(payload) {
  return {
    type: types.GET_DELEGATION_FLAG_SUCCESS,
    payload,
  };
}

export function getDelegataionFlagDataFailed(payload) {
  return {
    type: types.GET_DELEGATION_FLAG_FAILED,
    payload,
  };
}
// getAuditHistoryData Action
export function getAuditHistoryData() {
  return {
    type: types.GET_AUDIT_HISTORY,
  };
}

export function getAuditHistoryDataSuccess(payload) {
  return {
    type: types.GET_AUDIT_HISTORY_SUCCESS,
    payload,
  };
}

export function getAuditHistoryDataFailed(payload) {
  return {
    type: types.GET_AUDIT_HISTORY_FAILED,
    payload,
  };
}
//webWorker action start:
export function getExpenseReportCount() {
  return {
    type: types.GET_EXPENSE_REPORT_COUNT,
  };
}

export function getExpenseReportCountSuccess(payload) {
  return {
    type: types.GET_EXPENSE_REPORT_COUNT_SUCCESS,
    payload,
  };
}

export function getExpenseReportCountFailed(payload) {
  return {
    type: types.GET_EXPENSE_REPORT_COUNT_FAILED,
    payload,
  };
}

export function getBillNumberExistence() {
  return {
    type: types.GET_BILLNUMBER_EXISTENCE,
  };
}

export function getBillNumberExistenceSucess(payload) {
  return {
    type: types.GET_BILLNUMBER_EXISTENCE_SUCCESS,
    payload,
  };
}

export function getBillNumberExistenceFailed(payload) {
  return {
    type: types.GET_BILLNUMBER_EXISTENCE_FAILURE,
    payload,
  };
}
//webWorker action end.

//API start:
//access type api
export const getAccessTypeData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(accessTypeData());
    try {
      let tempEmpName = payload?.split("@");
      //console.log("ayushi" ,tempEmpName[0]);
      let emailPara = tempEmpName[0];
      //let encryptedPara1 = Encrypt(key, JSON.stringify(tempEmpName[0]));
      const response = await api.get(
        `${config.apiUrl}Master/GetEmpDetail?name=${emailPara}`
      );
      if (response.status === 200) {
        var key = process.env.REACT_APP_ENCRYPTION_KEY;
        let decryptedResponse = Decrypt(key, response.data);
        dispatch(accessTypeDataSucess(decryptedResponse));
      }
    } catch (error) {
      dispatch(accessTypeDataFailed());
    }
  };
//Get Purpose API

export const getPurposeData = (payload) => async (dispatch, getState, api) => {
  dispatch(purposeData());
  try {
    let entityId = payload?.entityId;
    const response = await api.get(
      `${config.apiUrl}Master/GetPurposes?entityId=${entityId}`
    );
    //console.log("checking the response", response.data);
    if (response.status === 200) {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let decryptedResponse = Decrypt(key, response.data);
      dispatch(purposeDataSucess(decryptedResponse));
      //  console.log("checking the response123", decryptedResponse);
    }
  } catch (error) {
    dispatch(purposeDataFailed());
  }
};

// Get Category API

export const getCategoryData = (payload) => async (dispatch, getState, api) => {
  // console.log("payload value is", payload);
  dispatch(categoryData());
  try {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    let encryptedPara1 = Encrypt(key, JSON.stringify(payload?.pId));
    let encryptedPara2 = payload?.entity;
    let traveltype = payload?.traveltype;
    //console.log("getCategoryData1234", payload, encryptedPara1, encryptedPara2);
 
    const response = await api.get(
      `${config.apiUrl}Master/GetCategories?purposeId=${encryptedPara1}&entity=${encryptedPara2}&traveltype=${traveltype}`
    );
    // console.log("checking the response of getCategoryData1234", response.data);
    if (response.status === 200) {
      let decryptedResponse = Decrypt(key, response.data);
      //  console.log("decryptedResponse  getCategoryData1234", decryptedResponse);
      dispatch(categoryDataSucess(decryptedResponse));
    }
  } catch (error) {
    dispatch(categoryDataFailed());
  }
};
//Get Employee projects API
// export const getEmployeeProjectsData =
//   (payload) => async (dispatch, getState, api) => {
//     //console.log("payload value of emp projects is", payload);
//     dispatch(employeeProjectsData());
//     try {
//       var key = process.env.REACT_APP_ENCRYPTION_KEY;
//       let encryptedPara1 = Encrypt(key, JSON.stringify(payload));

//       const response = await api.get(
//         `${config.apiUrl}Master/GetEmployeeProjects?empId=${encryptedPara1}`
//       );
//       //console.log("response of getEmployeeProjectsList: ", response.data);
//       if (response.status === 200) {
//         let decryptedResponse = Decrypt(key, response.data);
//         dispatch(employeeProjectsDataSuccess(decryptedResponse));
//       }
//     } catch (error) {
//       dispatch(employeeProjectsDataFailed());
//     }
//   };

// Get Employee projects by empID & proj code API

export const getEmployeeProjectsData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(employeeProjectsData());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload.empId));
      let encryptedPara2 = Encrypt(key, JSON.stringify(payload.catId));

      // console.log(
      //   "payload projectData value of emp projects is",
      //   payload,
      //   encryptedPara1,
      //   encryptedPara2
      // );
      const response = await api.get(
        `${config.apiUrl}Master/GetCategoryEntityWiseProjList?empId=${encryptedPara1}&categoryId=${encryptedPara2}`
      );

      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        // console.log(
        //   "response of projectData getEmployeeProjectsList: ",
        //   decryptedResponse
        // );
        dispatch(employeeProjectsDataSuccess(decryptedResponse));
      }
    } catch (error) {
      dispatch(employeeProjectsDataFailed());
    }
  };

//Get no of dependents
export const getDependentsData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(dependentsData());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara = Encrypt(key, JSON.stringify(payload));
      const response = await api.get(
        //`https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/Master/GetDependentsList?categoryId=${encryptedPara}`
        `${config.apiUrl}Master/GetDependentsList?categoryId=${encryptedPara}`
      );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        dispatch(dependentsDataSuccess(decryptedResponse));
      }
    } catch (error) {
      dispatch(dependentsDataFailed());
    }
  };

//Get Currency API

export const getCurrencyData = (payload) => async (dispatch, getState, api) => {
  //console.log("payload value is", payload);
  dispatch(currencyData());
  try {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    let encryptedPara1 = Encrypt(key, JSON.stringify(payload));

    const response = await api.get(
      `${config.apiUrl}Master/GetCurrencies?empId=${encryptedPara1}`
    );
    //console.log("checking the response of currency", response.data);
    if (response.status === 200) {
      let decryptedResponse = Decrypt(key, response.data);

      dispatch(currencyDataSucess(decryptedResponse));
    }
  } catch (error) {
    dispatch(currencyDataFailed());
  }
};

//Get Attendees API

export const getAttendeesData =
  (payload) => async (dispatch, getState, api) => {
    //console.log("payload value is", payload);
    dispatch(attendeesData());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload));
      const response = await api.get(
        `${config.apiUrl}Master/GetAttendees?empId=${encryptedPara1}`
      );
      //console.log("checking the response of attendees", response.data);
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        dispatch(attendeesDataSucess(decryptedResponse));
      }
    } catch (error) {
      dispatch(attendeesDataFailed());
    }
  };

//Validate Attendees API

export const getValidateAttendeesData =
  (payload) => async (dispatch, getState, api) => {
    //console.log("payload getValidateAttendeesData is", payload);
    dispatch(validateAttendeesData());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload?.expenseID)); //category ID
      let encryptedPara2 = Encrypt(key, JSON.stringify(payload?.empId)); //Employee ID
      let encryptedPara3 = Encrypt(key, JSON.stringify(payload?.expid)); //Expense ID

      const response = await api.get(
        `${config.apiUrl}Master/GetEmpTotalAmount?categoryId=${encryptedPara1}&empId=${encryptedPara2}&expid=${encryptedPara3}&receiptDate=${payload?.recieptDate}`
        //?categoryId=4&empId=4&expid=8003&receiptDate=2022%2F12%2F01
      );

      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        // console.log(
        //   "checking the decryptedResponse of GetEmpTotalAmount",
        //   decryptedResponse
        // );
        dispatch(validateAttendeesDataSucess(decryptedResponse));
      }
    } catch (error) {
      dispatch(validateAttendeesDataFailed());
    }
  };

//Payment API

export const getPaymentData = (payload) => async (dispatch, getState, api) => {
  //console.log("payload getPaymentData is", payload.expenseID);
  dispatch(paymentData());
  try {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    let encryptedPara1 = Encrypt(key, JSON.stringify(payload?.expenseID));
    let encryptedPara2 = Encrypt(key, JSON.stringify(payload?.empId));

    const response = await api.get(
      `${config.apiUrl}Master/GetPayMethodCategoryWies?categoryId=${encryptedPara1}&empId=${encryptedPara2}`
    );
    //console.log("checking the response of getpaymentData", response.data);
    if (response.status === 200) {
      let decryptedResponse = Decrypt(key, response.data);
      //console.log("decryptionGetPayMethodCategoryWies : ", decryptedResponse);
      dispatch(paymentDataSucess(decryptedResponse));
    }
  } catch (error) {
    dispatch(paymentDataFailed());
  }
};

//Post Create Expense API

export const postCreateExpenseData =
  (payload) => async (dispatch, getState, api) => {
    //console.log("payload postCreateExpenseData is", payload.get("files"));
    dispatch(postCreateExpense());
    try {
      const response = await api.post(
        `${config.apiUrl}Expense/CreateExpense`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // //console.log(
      //   "checking the response of postCreateExpenseData",
      //   response.data
      // );
      if (response.status === 200) {
        dispatch(postCreateExpenseSucess(response.data));
      }
    } catch (error) {
      //console.log("error", error);
      dispatch(postCreateExpenseFailed());
    }
  };
//Post Create Expense API
// https://fileuploadbucketdotnet.s3.ap-south-1.amazonaws.com/filename.jpg
export const postFileUploadData =
  (payload) => async (dispatch, getState, api) => {
    let formData = new FormData();
    //console.log("payload postFileUploadData1002 is", payload);
    let payloadFormDate = formData.append("files", payload.file);
    dispatch(postFileUpload());
    try {
      const response = await api.put(
        //`https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/Expense/fileuploadbucketdotnet/${payload.expenseId}_${payload.file.name}`,
        `${config.apiUrl}Expense/fileuploadbucketdotnet/${payload.expenseId}_${payload.file.name}`,
        payloadFormDate,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      //console.log("checking the response of postFileUploadData", response.data);
      if (response.status === 200) {
        dispatch(postFileUploadSuccess(response.data));
      }
    } catch (error) {
      //console.log("error", error);
      dispatch(postFileUploadFailed());
    }
  };

//Get My Expense List
export const getMyExpenseData =
  (payload) => async (dispatch, getState, api) => {
    //console.log("payload getMyExpenseData is", payload);
    dispatch(getMyExpense());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload?.empid));
      let encryptedPara2 = Encrypt(key, JSON.stringify(payload?.nday));

      const response = await api.get(
        `${config.apiUrl}Expense/GetMyExpenses?empId=${encryptedPara1}&noofdays=${encryptedPara2}`
      );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);

        dispatch(getMyExpenseSucess(decryptedResponse));
      }
    } catch (error) {
      //console.log("error", error);
      dispatch(getMyExpenseFailed());
    }
  };

//Get My Team Approval List:
export const getMyTeamApprovalData =
  (payload) => async (dispatch, getState, api) => {
    //console.log("payload  of get getMyTeamApprovalData", payload);
    //console.log("api of getMyTeamApprovalData", api);
    dispatch(getMyTeamApproval());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(
        key,
        payload?.empId
          ? JSON.stringify(payload?.empId)
          : JSON.stringify(payload?.empid)
      );
      let encryptedPara2 = Encrypt(key, JSON.stringify(payload?.nday));

      const response = await api.get(
        `${config.apiUrl}Expense/GetTeamApprovalExpenses?empId=${encryptedPara1}&noofdays=${encryptedPara2}`
      );
      // //console.log(
      //   "checking the response of getMyTeamApprovalData",
      //   response.data
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);

        dispatch(getMyTeamApprovalSuccess(decryptedResponse));
      }
    } catch (error) {
      //console.log("error", error);
      dispatch(getMyTeamApprovalFailed());
    }
  };

//Get Finance Approval List:
export const getFinanceApprovalData =
  (payload) => async (dispatch, getState, api) => {
    //console.log("payload  of get getFinanceApprovalData : ----", payload);

    dispatch(getFinanceApprovalList());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(
        key,
        payload?.empId
          ? JSON.stringify(payload?.empId)
          : JSON.stringify(payload?.empid)
      );
      let encryptedPara2 = Encrypt(key, JSON.stringify(payload?.nday));
      const response = await api.get(
        `${config.apiUrl}Expense/GetFinanaceApprovalList?empId=${encryptedPara1}&noofdays=${encryptedPara2}`
      );
      // //console.log(
      //   "checking the response of getFinanceApprovalData",
      //   response.data
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        dispatch(getFinanceApprovalListSuccess(decryptedResponse));
      }
    } catch (error) {
      //console.log("error", error);
      dispatch(getFinanceApprovalListFailed());
    }
  };

//Get Expense Data With Travel
export const getMyExpenseDataTravel =
  (payload) => async (dispatch, getState, api) => {
    //console.log("payload getMyExpenseData is", payload);
    dispatch(getMyExpenseTravel());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload?.expId));
      let encryptedPara2 = Encrypt(key, JSON.stringify(payload?.nday));

      const response = await api.get(
        `${config.apiUrl}Master/GetExpensesTravel?expId=${encryptedPara1}&noOfDays=${payload?.nday}`
      );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        dispatch(getMyExpenseSucessTravel(decryptedResponse));
      }
    } catch (error) {
      //console.log("error", error);
      dispatch(getMyExpenseFailedTravel());
    }
  };

//Delete My Expense List

export const deleteMyExpenseData =
  (payload) => async (dispatch, getState, api) => {
    //console.log("payload postCreateExpenseData is", payload);
    dispatch(deleteMyExpense());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload?.expID));
      let encryptedPara2 = Encrypt(key, JSON.stringify(payload?.empId));

      const response = await api.get(
        `${config.apiUrl}Expense/DeleteExpense?expId=${encryptedPara1}&empId=${encryptedPara2}`
      );
      //console.log("checking the response of getMyExpenseData", response.data);
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);

        dispatch(deleteMyExpenseSucess(decryptedResponse));
      }
    } catch (error) {
      //console.log("error", error);
      dispatch(deleteMyExpenseFailed());
    }
  };

// Post Download Files

export const postDownloadFiles =
  (payload) => async (dispatch, getState, api) => {
    //console.log("payload Download is", payload);
    dispatch(postDownload());
    try {
      const response = await api.post(
        `${config.apiUrl}Expense/DownloadMyExpense`,
        payload
        // {
        //   headers: {
        //     "Content-Type": "application/json",
        //     "charset":"utf-8",
        //   },
        // }
      );
      //console.log("checking the response of getMyExpenseData", response.data);
      if (response.status === 200) {
        dispatch(postDownloadSucess(response.data));
      }
    } catch (error) {
      //console.log("error", error);
      dispatch(postDownloadFailed());
    }
  };

//Get MyExpenseReportData
export const getMyExpenseReportData =
  (payload) => async (dispatch, getState, api) => {
    //console.log("payload getMyExpenseReportData is", payload);
    dispatch(myExpenseReportData());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload?.empid));
      let encryptedPara2 = Encrypt(key, JSON.stringify(payload?.nday));

      const response = await api.get(
        `${config.apiUrl}Expense/GetExpenseStatus?empId=${encryptedPara1}&noofdays=${encryptedPara2}`
      );
      //console.log("checking the response of getMyExpenseData", response.data);
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);

        dispatch(myExpenseReportDataSucess(decryptedResponse));
      }
    } catch (error) {
      //console.log("error", error);
      dispatch(myExpenseReportDataFailed());
    }
  };

//Post Approve Expense API

export const postApproveRejectExpenseData =
  (payload) => async (dispatch, getState, api) => {
    //console.log("payload of postApproveExpenseData is: ====>  ", payload);
    dispatch(postApproveRejectExpense());
    try {
      const response = await api.post(
        `${config.apiUrl}Expense/UpdateRejectExpStatusByTeamManager`,
        payload
      );
      // //console.log(
      //   "checking the response of postApproveExpenseData",
      //   response.data
      // );
      if (response.status === 200) {
        // console.log("under api",response.data)
        dispatch(postApproveRejectExpenseSuccess(response.data));
      }
      return response;
    } catch (error) {
      //console.log("error", error);
      dispatch(postApproveRejectExpenseFailed());
      throw error;
      // return error;
    }
  };

//Post Financial Approve Expense API

export const postFinancialApproveRejectExpenseData =
  (payload) => async (dispatch, getState, api) => {
    //console.log("payload of postApproveExpenseData is: ====>  ", payload);
    dispatch(postFinancialApproveRejectExpense());
    try {
      const response = await api.post(
        `${config.apiUrl}Expense/UpdateFinanceExpStatus`,
        payload
      );
      // //console.log(
      //   "checking the response of postApproveExpenseData",
      //   response.data
      // );
      if (response.status === 200) {
        // console.log("over api", response.data);
        dispatch(postFinancialApproveRejectExpenseSuccess(response.data));
      }
      return response;
    } catch (error) {
      //console.log("error", error);
      dispatch(postFinancialApproveRejectExpenseFailed());
      // return error;
      throw error;
    }
  };

//Get ViewExpenseData
export const getMyViewExpenseData =
  (payload) => async (dispatch, getState, api) => {
    dispatch(myViewExpenseData());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(
        key,
        JSON.stringify(payload?.response?.expId)
      );
      let encryptedPara2 = Encrypt(key, JSON.stringify(payload?.empId));
      // console.log(
      //   "vExpTest 1 getMyViewExpenseData is",
      //   payload,
      //   encryptedPara1,
      //   encryptedPara2
      // );

      const response = await api.get(
        `${config.apiUrl}Expense/GetExpenseDetail?expId=${encryptedPara1}&empId=${encryptedPara2}`
      );
      //console.log("checking the response of getMyExpenseData", response.data);
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        // console.log("vExpTest 2 decryptedData", decryptedResponse);
        // console.log("vExpTest 2 response.data", response.data);

        dispatch(myViewExpenseDataSucess(decryptedResponse));
      }
    } catch (error) {
      //console.log("error", error);
      dispatch(myViewExpenseDataFailed());
    }
  };

//GET MyTeamApprovalPageFlagData

export const getMyTeamApprovalPageFlagData =
  (payload) => async (dispatch, getState, api) => {
    // console.log("payload of MyTeamApprovalPageFlagData is", payload);
    dispatch(MyTeamApprovalPageFlagData());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(
        key,
        payload?.empId
          ? JSON.stringify(payload?.empId)
          : JSON.stringify(payload?.empid)
      );
      let encryptedPara2 = Encrypt(key, JSON.stringify(payload?.nday));

      const response = await api.get(
        `${config.apiUrl}Expense/GetTeamApprovalPageFlag?empId=${encryptedPara1}&noofdays=${encryptedPara2}`
      );
      // //console.log(
      //   "checking the response of getMyTeamApprovalPageFlagData",
      //   response.data
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        // console.log(
        //   "checking the response of getMyTeamApprovalPageFlagData",
        //   decryptedResponse
        // );
        dispatch(MyTeamApprovalPageFlagDataSuccess(decryptedResponse));
      }
    } catch (error) {
      //console.log("error", error);
      dispatch(MyTeamApprovalPageFlagDataFailed());
    }
  };

//Post Create Expense API

export const postEditExpenseData =
  (payload) => async (dispatch, getState, api) => {
    //console.log("payload postEditExpenseData is", payload.get("ExpId"));
    dispatch(postEditExpense());
    try {
      const response = await api.post(
        `${config.apiUrl}Expense/UpdateExpense`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // //console.log(
      //   "checking the response of postEditExpenseData",
      //   response.data
      // );
      if (response.status === 200) {
        dispatch(postEditExpenseSucess(response.data));
      }
    } catch (error) {
      //console.log("error", error);
      dispatch(postEditExpenseFailed());
    }
  };

//RESET expense response

export const resetExpenseResponse = () => (dispatch, getState, api) => {
  //console.log("payload of resetExpenseResponse; AK1 ");
  dispatch(resetExpResponse());
};

//RESET coming from other module toggle
export const resetFromOtherModule = () => (dispatch, getState, api) => {
  //console.log("payload of resetFromOtherModule; AK1 ");
  dispatch(resetFromOtherModuleFxn());
};

//SET coming from other module toggle
export const setFromOtherModule = () => (dispatch, getState, api) => {
  //console.log("payload of setFromOtherModuleFxn; AK1 ");
  dispatch(setFromOtherModuleFxn());
};

//RESET expense response

export const resetAuditHistoryData = () => (dispatch, getState, api) => {
  //console.log("payload of resetAuditHistoryData; AK1 ");
  dispatch(resetAuditHistData());
};

//Get Download Expense Report:
export const getDownloadExpenseReportData =
  (payload) => async (dispatch, getState, api) => {
    //console.log("payload  of get getDownloadExpenseReportData : ----");

    dispatch(getDownloadExpenseReportList());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload?.empId));
      const response = await api.get(
        `${config.apiUrl}Expense/DownloadExpenseReport?empId=${encryptedPara1}`
      );
      // //console.log(
      //   "checking the response of getDownloadExpenseReportData",
      //   response.data
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);

        dispatch(getDownloadExpenseReportListSuccess(decryptedResponse));
      }
    } catch (error) {
      //console.log("error", error);
      dispatch(getDownloadExpenseReportListFailed());
    }
  };
//GET Download Expense Report pagewise:
//https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/Expense/DownloadExpenseReport?empId=2IZXKJ96bX7LO7zY5E52Sw==&pageno=4
export const getDownloadExpenseReportPageWise =
  (payload) => async (dispatch, getState, api) => {
    // console.log(
    //   "payload webWorker of get getDownloadExpenseReportPageWise : ----",
    //   payload
    // );

    dispatch(getDownloadExpenseReportPageWiseList());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload?.empId));
      const response = await api.get(
        `${config.apiUrl}Expense/DownloadExpenseReport?empId=${encryptedPara1}&pageno=${payload?.pageNo}`
      );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        // console.log(
        //   "Pagination getDownloadExpenseReportPageWise: ",
        //   decryptedResponse
        // );
        dispatch(
          getDownloadExpenseReportPageWiseListSuccess(decryptedResponse)
        );
      }
    } catch (error) {
      //console.log("error", error);
      dispatch(getDownloadExpenseReportPageWiseListFailed());
    }
  };
//GET Deligation Data

export const getDeligationData =
  (payload) => async (dispatch, getState, api) => {
    //console.log("payload of getDeligationData is", payload);
    dispatch(getDeligationList());
    // https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/Master/GetAllDelegateeList
    try {
      const response = await api.get(
        // `${config.apiUrl}Master/GetAllDelegateeList`
        `${config.apiUrl}Master/GetAllDelegateeList `
      );
      // //console.log(
      //   "checking the response of getgetDeligationList",
      //   response.data
      // );
      if (response.status === 200) {
        var key = process.env.REACT_APP_ENCRYPTION_KEY;

        let decryptedResponse = Decrypt(key, response.data);
        // console.log(
        //   "decryptedResponse of getDeligationData is",
        //   decryptedResponse
        // );
        dispatch(getDeligationListSucess(decryptedResponse));
      }
    } catch (error) {
      //console.log("error", error);
      dispatch(getDeligationListFailed());
    }
  };

//GET Deligation DropeDown Data

export const getDeligationDropeDownData =
  (payload) => async (dispatch, getState, api) => {
    //console.log("payload of MyTeamApprovalPageFlagData is", payload);
    dispatch(getDeligationDropeDownList());
    // https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/Master/GetAllDelegateeList
    try {
      const response = await api.get(
        // `${config.apiUrl}Master/GetAllEmployees`
        `${config.apiUrl}Master/GetAllDelegateSearch`
      );
      // //console.log(
      //   "checking the response of getgetDeligationList",
      //   response.data
      // );
      if (response.status === 200) {
        var key = process.env.REACT_APP_ENCRYPTION_KEY;

        let decryptedResponse = Decrypt(key, response.data);
        dispatch(getDeligationDropeDownListSucess(decryptedResponse));
      }
    } catch (error) {
      //console.log("error", error);
      dispatch(getDeligationDropeDownListFailed());
    }
  };

//GET Employee DropeDown Data

export const getEmployeeDropeDownData =
  (payload) => async (dispatch, getState, api) => {
    //console.log("payload of MyTeamApprovalPageFlagData is", payload);
    dispatch(getEmployeeDropeDownList());
    try {
      const response = await api.get(
        // `${config.apiUrl}Master/GetAllEmployees`
        `${config.apiUrl}Master/GetAllEmployeesSearch`
      );
      //console.log("checking the response of getgetEmployeeList", response.data);
      if (response.status === 200) {
        var key = process.env.REACT_APP_ENCRYPTION_KEY;

        let decryptedResponse = Decrypt(key, response.data);
        dispatch(getEmployeeDropeDownListSucess(decryptedResponse));
      }
    } catch (error) {
      //console.log("error", error);
      dispatch(getEmployeeDropeDownListFailed());
    }
  };

//POST Deligate Save Api

export const postDeligateSave =
  (payload) => async (dispatch, getState, api) => {
    //console.log("payload of MyTeamApprovalPageFlagData is", payload);
    dispatch(postDeligateSaveList());
    //${config.apiUrl}Expense/UpdateFinanceExpStatus
    try {
      // const response = await api.post(
      //   `https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/Master/AddDelegate`,
      //   payload
      // );
      const response = await api.post(
        `${config.apiUrl}Master/AddDelegate`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      //console.log("checking the response of getgetEmployeeList", response.data);
      if (response.status === 200) {
        dispatch(postDeligateSaveListSucess(response.data));
      }
    } catch (error) {
      //console.log("error", error);
      dispatch(postDeligateSaveListFailed());
    }
  };

//POST Deligate Udate Api

export const postDeligateUpdate =
  (payload) => async (dispatch, getState, api) => {
    //console.log("payload of postDeligateUpdateList is", payload);
    dispatch(postDeligateUpdateList());
    //${config.apiUrl}Expense/UpdateFinanceExpStatus
    try {
      // const response = await api.post(
      //   `https://29se79mmgf.execute-api.ap-south-1.amazonaws.com/Dev/api/Master/AddDelegate`,
      //   payload
      // );
      const response = await api.post(
        `${config.apiUrl}Master/UpdateDelegate`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(
      //   "checking the response of postDeligateUpdateList",
      //   response.data
      // );
      if (response.status === 200) {
        dispatch(postDeligateUpdateListSucess(response.data));
      }
    } catch (error) {
      //console.log("error", error);
      dispatch(postDeligateUpdateListFailed());
    }
  };

//Reset DeligationSnakbar

export const ResetDeligatinSnakbar =
  (payload) => async (dispatch, getState, api) => {
    dispatch(resetDeliationSnakbar());
  };

//Get My Delegatees Data:
export const getMyDelegateesData =
  (payload) => async (dispatch, getState, api) => {
    //console.log("payload  of get getMyDelegateesData : ----", payload);

    dispatch(getMyDelegateesList());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload));
      // console.log(
      //   "vExpTest data getMyDelegateesData: ",
      //   payload,
      //   encryptedPara1
      // );
      const response = await api.get(
        `${config.apiUrl}Master/GetUserDelegatee?empId=${encryptedPara1}`
      );

      if (response.status === 200) {
        //console.log("vExpTest data getMyDelegateesData: ", decryptedResponse);
        let decryptedResponse = Decrypt(key, response.data);
        dispatch(getMyDelegateesListSuccess(decryptedResponse));
      }
    } catch (error) {
      //console.log("error", error);
      dispatch(getMyDelegateesListFailed());
    }
  };

//Update SSO Employee Id:
export const updateSsoEmployeeId =
  (updatedSsoEmpId) => (dispatch, getState, api) => {
    //console.log("payload in updateSsoEmployeeEmpId: ", updatedSsoEmpId);
    dispatch(updateSsoEmpId(updatedSsoEmpId));
  };

//Update Delegatee Employee Id:
export const updateDelegateeEmpId =
  (updatedDelEmpId) => (dispatch, getState, api) => {
    //console.log("payload in updateDelegateeEmpId: ", updatedDelEmpId);
    dispatch(updateDelEmpId(updatedDelEmpId));
  };

//RELOAD_MODULE_TOGGLE:
export const reloadModuleToggleData =
  (toggleReload) => (dispatch, getState, api) => {
    //console.log("payload in reloadModuleToggleData: ", toggleReload);
    dispatch(reloadToggleFxn(toggleReload));
  };

//MAINTAIN_STATE_VARIABLE :
export const maintainState =
  (maintainStateVar) => (dispatch, getState, api) => {
    //console.log("payload in maintainState: ", maintainStateVar);
    dispatch(maintainStateVariableFxn(maintainStateVar));
  };

//SET_FULL_FINAL_ALL_EXPENSE_DATA :
export const setFullFinalAllExpenseData =
  (fullFinalData) => (dispatch, getState, api) => {
    //console.log("payload in maintainState: ", maintainStateVar);
    dispatch(setFullFinalAllExpenseFxn(fullFinalData));
  };

//GET EmpDetailData
export const getEmpDetail =
  (payload = "") =>
  async (dispatch, getState, api) => {
    let payloadRes = payload?.split("@");

    dispatch(getEmpDetailData());
    try {
      const response = await api.get(
        `${config.apiUrl}Master/GetEmpDetail?name=${payloadRes[0]}`
      );
      //Encrypt(key, JSON.stringify(location?.state?.empId))
      //console.log("checking the response of getEmpDetailData", response.data);
      if (response.status === 200) {
        var key = process.env.REACT_APP_ENCRYPTION_KEY;
        let decryptedResponse = Decrypt(key, response.data);
        // console.log(
        //   "vExpTest 2 decryptedResponse getEmpDetail: ",
        //   decryptedResponse
        // );
        //console.log("GetEmpDetailDecryptData", decryptedResponse);
        dispatch(getEmpDetailDataSucess(decryptedResponse));
      }
      return response;
    } catch (error) {
      //console.log("error", error);
      dispatch(getEmpDetailDataFailed());
    }
  };

//GET GetDelegationFlag
export const getDelegataionFlag =
  (payload) => async (dispatch, getState, api) => {
    //console.log("payload of getEmpDetailData is", payload);
    dispatch(getDelegataionFlagData());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload));
      // console.log(
      //   "vExpTest 1 DelExpReportDelUserAccess",
      //   payload,
      //   encryptedPara1
      // );
      const response = await api.get(
        `${config.apiUrl}Master/DelExpReportDelUserAccess?empId=${encryptedPara1}`
      );
      // console.log(
      //   "checking the response of getDelegataionFlagData",
      //   response.data
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data);
        // console.log("vExpTest 2 DelExpReportDelUserAccess", decryptedResponse);
        dispatch(getDelegataionFlagDataSucess(decryptedResponse));
      }
    } catch (error) {
      //console.log("error", error);
      dispatch(getDelegataionFlagDataFailed());
    }
  };

//GET GetDelegationFlag
export const getAuditHistory = (payload) => async (dispatch, getState, api) => {
  //console.log("payload of getEmpDetailData is", payload);
  dispatch(getAuditHistoryData());
  try {
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    let encryptedPara1 = Encrypt(key, JSON.stringify(payload));
    // console.log("getAuditHistory 1 encryptedPara1", encryptedPara1);
    const response = await api.get(
      `${config.apiUrl}Expense/GetAuditAction?expId=${encryptedPara1}`
    );
    //console.log("checking the response of getAuditHistory", response.data);
    if (response.status === 200) {
      let decryptedResponse = Decrypt(key, response.data);
      // console.log("getAuditHistory 2 decryptedResponse", decryptedResponse);
      dispatch(getAuditHistoryDataSuccess(decryptedResponse));
    }
  } catch (error) {
    //console.log("error", error);
    dispatch(getAuditHistoryDataFailed());
  }
};

//webWorker apis start:

//Expense/DownloadExpenseReport?empId=2IZXKJ96bX7LO7zY5E52Sw==&pageno=4
//Expense/GetExpenseReportPagSizeCount?empId=2IZXKJ96bX7LO7zY5E52Sw==  this is new api it return page size
export const getExpenseReportCountData =
  (payload) => async (dispatch, getState, api) => {
    //console.log("payload of GetExpenseReportPagSizeCount is", payload?.empId);
    dispatch(getExpenseReportCount());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload?.empId));
      // console.log(
      //   "GetExpenseReportPagSizeCount 1 encryptedPara1",
      //   encryptedPara1
      // );
      const response = await api.get(
        `${config.apiUrl}Expense/GetExpenseReportPagSizeCount?empId=${encryptedPara1}`
      );
      // console.log(
      //   "checking the response of GetExpenseReportPagSizeCount",
      //   response.data
      // );
      if (response.status === 200) {
        dispatch(getExpenseReportCountSuccess(response.data));
      }
    } catch (error) {
      //console.log("error", error);
      dispatch(getExpenseReportCountFailed());
    }
  };
export const getBillNumberExistenceData =
  (payload) => async (dispatch, getState, api) => {
    // console.log("payload of getBillNumberExistenceData is", payload);
    dispatch(getBillNumberExistence());
    try {
      var key = process.env.REACT_APP_ENCRYPTION_KEY;
      let encryptedPara0 = Encrypt(key, JSON.stringify(payload?.expId));
      let encryptedPara1 = Encrypt(key, JSON.stringify(payload?.empId));
      let encryptedPara2 = Encrypt(key, JSON.stringify(payload?.categoryId)); //category ID
      let billNumber = payload?.billNumber;
      // console.log(
      //   "getBillNumberExistenceData 1 encryptedPara1 2",
      //   encryptedPara1,encryptedPara2
      // );
      const response = await api.get(
        `${config.apiUrl}Expense/GetBillNumberExistence?&expId=${encryptedPara0}&empId=${encryptedPara1}&categoryId=${encryptedPara2}&billNumber=${billNumber}`
      );
      // console.log(
      //   "checking the response of GetExpenseReportPagSizeCount",
      //   response.data
      // );
      if (response.status === 200) {
        let decryptedResponse = Decrypt(key, response.data.result);
        // console.log("getBillNumberExistenceData decrypted response", decryptedResponse);

        dispatch(getBillNumberExistenceSucess(decryptedResponse));
      }
    } catch (error) {
      //console.log("error", error);
      dispatch(getBillNumberExistenceFailed());
    }
  };
//webWorker apis end.