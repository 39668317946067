import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { Box, TableFooter } from "@material-ui/core";
import { fontSize } from "@material-ui/system";
import moment from "moment";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    root: {
      "& .MuiPaper-root": {
        width: "100%",
        maxWidth: "100px", // Set your width here
      },
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  table1: {
    minWidth: 309,
  },
  activityDate: {
    maxWidth: "12px",
  },
  // activityDate1: {
  //   maxWidth: "33px",
  // },
  loggedBy: {
    maxWidth: "6px",
  },
  activity: {
    maxWidth: "30px",
  },
 
  dialogTitle: {
    fontFamily: "Montserrat",
    fontWeight: 800,
    fontSize: "22px",
    color: "#000000",
    paddingBottom: "12px",
  },
  dialogTitle1: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "14px",
    color: "#000000",
    // paddingBottom: "12px",
  },
  dialog: {
    ".MuiDialog-paperWidthSm": {
      maxWidth: "700px",
    },
  },
  tmCode: {
    fontSize: "16px",
    fontWeight: 600,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CancelOutlinedIcon
            style={{
              color: "#0B1941",
              fontSize: "28px",
            }}
          />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}))(MuiDialogContent);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

export default function AuditHistoryDialog(props) {
  const [auditHistory, setAuditHistory] = useState([]);
  const classes = useStyles();
  //useEffects start:
  useEffect(() => {
    // if (props.auditHistoryData != []) {
    //   let tempArray = props.auditHistoryData?.map((x, i) => ({ ...x, ID: i }));
    //   //console.log("tempArray audithistory", tempArray);
    //   setAuditHistory(tempArray);
    // }
    if (props.auditHistoryData && props.auditHistoryData.length > 0) {
      let tempArray = props.auditHistoryData.map((x, i) => ({ ...x, ID: i }));
      setAuditHistory(tempArray);
    } else {
      setAuditHistory([]);
    }
  }, [props.auditHistoryData]);
  const handleCloseDialog = () => {
    props.handleClose();
  };
//   console.log("auditHistory in dialog", auditHistory);
  // console.log("getAuditHistoryData in dialog", props.auditHistoryData);
  const matches = useMediaQuery("(max-width:600px)");
  return (
    <div>
      <Dialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        fullWidth
        maxWidth="md"
        BackdropProps={{ style: { backgroundColor: "#0B1941D4" } }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
          <Typography
            className={matches ? classes.dialogTitle1 : classes.dialogTitle}
          >
            Audit History :{" "}
            <span className={classes.tmCode}>{auditHistory[0]?.tmCode}</span>
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          {auditHistory.length != 0 ? (
            <TableContainer style={{ overflowX: "hidden" }} component={Paper}>
              <Table
                className={matches ? classes.table1 : classes.table}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow style={{ backgroundColor: "#F4F4F4" }}>
                    <TableCell
                      className={classes.activityDate}
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: matches ? "10px" : "12px",
                        fontWeight: matches ? 700 : 800,
                        lineHeight:"normal",
                      }}
                    >
                     Audit Creation Date
                    </TableCell>
                    <TableCell
                      className={classes.loggedBy}
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: matches ? "10px" : "12px",
                        fontWeight: matches ? 700 : 800,
                        lineHeight:"normal",

                      }}
                    >
                      Audit Created By
                    </TableCell>
                    <TableCell
                      className={classes.activity}
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: matches ? "10px" : "12px",
                        fontWeight: matches ? 700 : 800,
                      }}
                    >
                      Project
                    </TableCell>
                    <TableCell
                     
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: matches ? "10px" : "12px",
                        fontWeight: matches ? 700 : 800,
                      }}
                    >
                      Comments
                    </TableCell>
                    <TableCell
                     
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: matches ? "10px" : "12px",
                        fontWeight: matches ? 700 : 800,
                        width:"80px"
                      }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                     
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: matches ? "10px" : "12px",
                        fontWeight: matches ? 700 : 800,
                        maxWidth:"1px",
                      }}
                    >
                      Approver Manager
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {auditHistory?.map((row) => (
                    <TableRow key={row.ID}>
                      <TableCell
                        style={{
                          padding: matches ? "11.4px" : "",
                          fontSize: matches ? "10px" : "12px",
                        }}
                        className={classes.activityDate}
                      >
                        {moment(row.createdOn).format("DD-MM-yyyy")}
                      </TableCell>
                      <TableCell
                        style={{
                          padding: matches ? "11.4px" : "",
                          fontSize: matches ? "10px" : "12px",
                        }}
                        className={classes.loggedBy}
                      >
                        {row.createdBy}
                      </TableCell>
                      <TableCell
                        style={{
                          padding: matches ? "11.4px" : "",
                          fontSize: matches ? "10px" : "12px",
                        }}
                        className={classes.activity}
                      >
                        <Grid>
                          <Grid item style={{ paddingBottom: "0px" }}>
                            <Typography
                              variant="body1"
                              style={{ fontSize: "12px" }}
                            >
                              {row.projName}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            style={{
                              paddingTop: "0px",
                              paddingBottom: "2px",
                            }}
                          >
                            <Typography
                              variant="body1"
                              style={{ fontSize: "10px" }}
                            >
                              {row.projCode}
                            </Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell
                        style={{
                          padding: matches ? "11.4px" : "",
                          fontSize: matches ? "10px" : "12px",
                        }}
                        className={classes.activity}
                      >
                        {row.comment}
                      </TableCell>
                      <TableCell
                        style={{
                          padding: matches ? "11.4px" : "",
                          fontSize: matches ? "10px" : "12px",
                        }}
                        className={classes.activity}
                      >
                        {row.tmStatus}
                      </TableCell>
                      <TableCell
                        style={{
                          padding: matches ? "11.4px" : "",
                          fontSize: matches ? "10px" : "12px",
                        }}
                        className={classes.activity}
                      >
                        {row.projectManagerName}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <Box style={{ padding: "12px" }}>
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "12px",
                          fontWeight: 700,
                          lineHeight: "24px",
                          letterSpacing: "0px",
                          textAlign: "left",
                        }}
                      >
                        &nbsp;&nbsp;
                      </Typography>
                    </Box>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          ) : (
            <Box
              style={{
                padding: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography> No audit history found.</Typography>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}