import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Stepper,
  Step,
  StepButton,
  Chip,
  Tooltip,
  Typography,
  Box,
  Grid,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  rootStepper: {
    // backgroundColor: '#F8F9FF',
    padding: "20px 0", // Adjusted padding
    width: "90%",
    marginLeft: "40px",
  },

  steps: {
    width: "30px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    border: "2px solid #bdbdbd",
    position: "relative",
    // zIndex: '1',
    backgroundColor: "#FFF",
    marginLeft: "105px",
    // [theme.breakpoints.down('sm')]: {
    //   width: '36px',
    //   height: '36px',
    // },
    // alignSelf: 'flex-start', // Added alignment
  },

  steps1: {
    width: "30px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    border: "2px solid #bdbdbd",
    position: "relative",
    // zIndex: '1',
    backgroundColor: "#FFF",
    marginLeft: "45px",
    // [theme.breakpoints.down('sm')]: {
    //   width: '36px',
    //   height: '36px',
    // },
    // alignSelf: 'flex-start', // Added alignment
  },
  steps2: {
    width: "30px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    border: "2px solid #bdbdbd",
    position: "relative",
    // zIndex: '1',
    backgroundColor: "#FFF",
    marginLeft: "95px",
    // [theme.breakpoints.down('sm')]: {
    //   width: '36px',
    //   height: '36px',
    // },
    // alignSelf: 'flex-start', // Added alignment
  },
  steps3: {
    width: "30px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    border: "2px solid #bdbdbd",
    position: "relative",
    // zIndex: '1',
    backgroundColor: "#FFF",
    marginLeft: "47px",
    // [theme.breakpoints.down('sm')]: {
    //   width: '36px',
    //   height: '36px',
    // },
    // alignSelf: 'flex-start', // Added alignment
  },
  steps4: {
    width: "30px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    border: "2px solid #bdbdbd",
    position: "relative",
    // zIndex: '1',
    backgroundColor: "#FFF",
    marginLeft: "68px",
    // [theme.breakpoints.down('sm')]: {
    //   width: '36px',
    //   height: '36px',
    // },
    // alignSelf: 'flex-start', // Added alignment
  },

  chipLabel: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
    align: "center",
  },
  lines: {
    borderColor: "#bdbdbd",
  },
  statusHeading: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "8px",
    color: "rgba(255, 255, 255, 0.9)",
  },
  statusName: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "10px",
    marginRight: "2px",

    color: "rgba(255, 255, 255, 0.85)",
  },
  statusMessage: {
    // backgroundColor: '#F4F4F4',
    padding: "10px",
    borderRadius: "5px",
    textAlign: "left",
    fontSize: "14px",
    marginTop: "5px",
    marginLeft: "65px", // Adjusted marginLeft
  },
  statusMessage1: {
    // backgroundColor: '#F4F4F4',
    padding: "10px",
    borderRadius: "5px",
    textAlign: "left",
    fontSize: "14px",
    marginTop: "5px",
    //marginLeft: '65px', // Adjusted marginLeft
  },
  statusProjectName: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "8px",
    // alignItems: "center",
    // paddingLeft: "10px",
    marginTop: "2px",
    color: "rgba(255, 255, 255, 0.9)",
    opacity: "0.6",
  },
}));

function GMStepper({ gmStepper }) {
  const classes = useStyles();
  // console.log("stepper", gmStepper);
  // const handleToolTip = (value) => {
  //   const dummyArray = [];

  //   const array = value?.tooltip_msg?.split("~~");
  //   const array1 = value?.complete_msg?.split("-");
  //   //console.log("ayushi11", array, array1)
  //   if (array !== undefined) {
  //     array.map((item, key) => {
  //       const a = array[key]?.split("|");
  //       const obj2 = { ...a };

  //       // Assumed properties for gmStepper
  //       const tooltipProp1 = obj2[0]; // Assumed property 1
  //       const tooltipProp2 = obj2[1]; // Assumed property 2
  //       const tooltipProp3 = obj2[2]; // Assumed property 3
  //       // const tooltipProp4 = obj2["3"];
  //       // const tooltipProp5 = obj2["4"];

  //       dummyArray.push({
  //         tooltipProp1,
  //         tooltipProp2,
  //         tooltipProp3,
  //         // tooltipProp4,
  //         // tooltipProp5
  //       });
  //     });
  //   }

  //   return (
  //     <>
  //       <Box>
  //         <Typography
  //           className={classes.statusHeading}
  //           style={{ textAlign: "left", paddingTop: "5px", marginLeft: "5px" }}
  //         >
  //           {value?.prefix_msg}
  //         </Typography>
  //       </Box>
  //       {dummyArray?.map((value) => {
  //         return (
  //           <>
  //             <Box style={{ display: "flex" }}>
  //               <Typography
  //                 className={classes.statusName}
  //                 style={{
  //                   display: "flex",
  //                   textAlign: "left",
  //                   paddingLeft: "5px",
  //                 }}
  //               >
  //                 {console.log("value", value)}
  //                 {value.tooltipProp1} {/* Render tooltip property 1 */}
  //                 {console.log("tool1", value.tooltipProp1)}
  //               </Typography>
  //               {value.tooltipProp2 !== undefined && (
  //                 <Typography className={classes.statusName}>{"|"} </Typography>
  //               )}
  //               <Typography className={classes.statusProjectName}>
  //                 <span style={{ paddingLeft: "5px" }}>
  //                   {value.tooltipProp2} {/* Render tooltip property 2 */}
  //                   {console.log("tool2", value.tooltipProp2)}
  //                 </span>
  //               </Typography>{" "}
  //             </Box>

  //             <Typography
  //               className={classes.statusProjectName}
  //               style={{
  //                 paddingBottom: "5px",
  //                 marginLeft: "5px",
  //                 textAlign: "left",
  //               }}
  //             >
  //               {array1[1]}
  //               {console.log("first")}
  //               {/* Render tooltip property 3 */}
  //             </Typography>
  //           </>
  //         );
  //       })}
  //     </>
  //   );
  // };

 
  const handleToolTip = (value) => {
    const dummyArray = [];

    const array = value?.tooltip_msg?.split("~~");
    const array1 = value?.complete_msg?.split("-");
    // console.log("ayushi11", array, array1)
    if (array !== undefined) {
      array.map((item, key) => {
        const a = array[key]?.split("|");
        const obj2 = { ...a };
        // console.log("siddhant",a,obj2)
        // Assumed properties for gmStepper
        const tooltipProp1 = obj2["0"]; // Assumed property 1
        const tooltipProp2 = obj2["1"]; // Assumed property 2
        const tooltipProp3 = obj2["2"]; // Assumed property 3

        dummyArray.push({
          tooltipProp1,
          tooltipProp2,
          tooltipProp3,
        });
      });
    }

    return (
      <>
        <Box>
          <Typography
            className={classes.statusHeading}
            style={{ textAlign: "left", paddingTop: "5px", marginLeft: "5px" }}
          >
            {value?.prefix_msg}
          </Typography>
        </Box>
        {dummyArray?.map((value) => {
          return (
            <>
              <Box style={{ display: "flex" }}>
                <Typography
                  className={classes.statusName}
                  style={{
                    display: "flex",
                    textAlign: "left",
                    paddingLeft: "5px",
                  }}
                >
                  {value.tooltipProp1} {/* Render tooltip property 1 */}
                </Typography>
                {value.tooltipProp2 !== undefined && (
                  <Typography className={classes.statusName}>{"|"} </Typography>
                )}
                <Typography className={classes.statusProjectName}>
                  <span style={{ paddingLeft: "5px" }}>
                    {value.tooltipProp2} {/* Render tooltip property 2 */}
                  </span>
                </Typography>{" "}
              </Box>

              <Typography
                className={classes.statusProjectName}
                style={{
                  paddingBottom: "5px",
                  marginLeft: "5px",
                  textAlign: "left",
                }}
              >
                {array1[1]} {array1[2]?.length > 0 ? `-${array1[2]}` : ""}
                {/* Render tooltip property 3 */}
              </Typography>
              {/* <Typography
                className={classes.statusProjectName}
                style={{ paddingBottom: "5px", marginLeft: "5px", textAlign: "left" }}
              >
                {array1[2]}
                {/* Render tooltip property 3 */}
              {/* </Typography> */}
            </>
          );
        })}
      </>
    );
  };

  // const handleToolTip = (value) => {
  //   const dummyArray = [];

  //   const array = value?.tooltip_msg?.split("~~");
  //   const array1 = value?.complete_msg?.split("-");

  //   if (array !== undefined) {
  //     array.map((item, key) => {
  //       const a = array[key]?.split("|");
  //       const obj2 = { ...a };

  //       const tooltipProp1 = obj2["0"];
  //       const tooltipProp2 = obj2["1"];
  //       const tooltipProp3 = obj2["2"];
  //       const tooltipProp4 = obj2["3"];
  //       const tooltipProp5 = obj2["4"];

  //       dummyArray.push({
  //         tooltipProp1,
  //         tooltipProp2,
  //         tooltipProp3,
  //         tooltipProp4,
  //         tooltipProp5,
  //       });
  //     });
  //   }

  //   return (
  //     <>
  //       <Box>
  //         <Typography
  //           className={classes.statusHeading}
  //           style={{ textAlign: "left", paddingTop: "5px", marginLeft: "5px" }}
  //         >
  //           {value?.prefix_msg}
  //         </Typography>
  //       </Box>
  //       {dummyArray?.map((value) => {
  //         return (
  //           <>
  //             <Box style={{ display: "flex" }}>
  //               <Typography
  //                 className={classes.statusName}
  //                 style={{
  //                   display: "flex",
  //                   textAlign: "left",
  //                   paddingLeft: "5px",
  //                 }}
  //               >{console.log("value",value)}
  //                 {value.tooltipProp1}
  //                 {console.log("tool1",value.tooltipProp1)}
  //               </Typography>
  //               {value.tooltipProp2 !== undefined && (
  //                 <Typography className={classes.statusName}>{"|"} </Typography>
  //               )}
  //               <Typography className={classes.statusProjectName}>
  //                 <span style={{ paddingLeft: "5px" }}>
  //                   {value.tooltipProp2}
  //                   {console.log("tool2",value.tooltipProp2)}

  //                 </span>
  //               </Typography>
  //               {value.tooltipProp3 !== undefined && (
  //                 <Typography className={classes.statusName}>{"|"} </Typography>
  //               )}
  //               <Typography className={classes.statusProjectName}>
  //                 <span style={{ paddingLeft: "5px" }}>
  //                   {value.tooltipProp3}
  //                   {console.log("tool3",value.tooltipProp3)}

  //                 </span>
  //               </Typography>
  //               {value.tooltipProp4 !== undefined && (
  //                 <Typography className={classes.statusName}>{"|"} </Typography>
  //               )}
  //               <Typography className={classes.statusProjectName}>
  //                 <span style={{ paddingLeft: "5px" }}>
  //                   {value.tooltipProp4}
  //                   {console.log("tool4",value.tooltipProp4)}

  //                 </span>
  //               </Typography>
  //               {value.tooltipProp5 !== undefined && (
  //                 <Typography className={classes.statusName}>{"|"} </Typography>
  //               )}
  //               <Typography className={classes.statusProjectName}>
  //                 <span style={{ paddingLeft: "5px" }}>
  //                   {value.tooltipProp5}
  //                   {console.log("tool5",value.tooltipProp5)}

  //                 </span>
  //               </Typography>
  //             </Box>

  //             <Typography
  //               className={classes.statusProjectName}
  //               style={{
  //                 paddingBottom: "5px",
  //                 marginLeft: "5px",
  //                 textAlign: "left",
  //               }}
  //             >
  //               {array1[1]}
  //             </Typography>
  //           </>
  //         );
  //       })}
  //     </>
  //   );
  // };

  const handleText = (value) => {
    return (
      <Grid item>
        <div
          style={{ paddingLeft: value.status === "GM Initiated" ? "66px" : "" }}
        >
          {value.status}
        </div>
        {value.approvedDate ? (
          <div
            align="center"
            style={{
              paddingLeft: value.status === "GM Initiated" ? "66px" : "",
            }}
          >
            {value.approvedDate}
          </div>
        ) : (
          <div align="center">{value.rejectionDate}</div>
        )}
      </Grid>
    );
  };

  const StepIcon = ({ label, value }) => (
    <div style={{ position: "relative", align: "center" }}>
      <div
        className={
          value.status === "GM Submission"
            ? classes.steps1
            : value.status === "BU Head Approval"
            ? classes.steps4
            : value.status === "Business Finance Approval"
            ? classes.steps2
            : value.status === "CHRO Approval"
            ? classes.steps3
            : classes.steps
        }
        style={{
          // border:
          //   label === parseInt(gmStepper?.stage)
          //   && value.prefix_msg === 'Initiated By Mobility Team'
          //     ? '2px solid #349259'
          //     : (label === parseInt(gmStepper?.stage) &&
          //         value.status === 'GM Submission') ||
          //       (label === parseInt(gmStepper?.stage) &&
          //         value.status === 'Business Finance Approval')
          //     ? '2px solid #E0B15C'
          //     : label === parseInt(gmStepper?.stage)
          //     && value.status === 'CHRO Approval'
          //     ? '2px solid #349259'
          //     : label < parseInt(gmStepper?.stage)
          //     ? '2px solid #349259'
          //     : '2px solid #bdbdbd',

          //     marginLeft:

          //     (value.status === 'CHRO Approval')
          //      ? "79px"
          //      : ""
          // border:value.prefix_msg==='Approved By'||value.prefix_msg==="Initiated By Mobility Team"?'2px solid #349259':''

          // border:value.approvedDate!=null||value.approvedDate!=""?'2px solid #349259':value.prefix_msg==="Rejected By"||value.approvedDate==null||value.approvedDate?'2px solid #D40606':""
          border: value.approvedDate
            ? "2px solid #349259"
            : value.prefix_msg === "Rejected By"
            ? "2px solid #D40606"
            : value.prefix_msg === "Pending With BUHead"
            ? "2px solid #E0B15C"
            : value.prefix_msg === ""
            ? "2px solid #9e9e9e"
            : value.prefix_msg === "Pending With"
            ? "2px solid rgb(224, 177, 92)"
            : "",
        }}
      >
        {/* {console.log("testing", value.approvedDate, value.prefix_msg)} */}
        <Tooltip title={handleToolTip(value)}>
          <div
            style={{
              color:
                label === parseInt(gmStepper?.stage) &&
                value.status === "GM Initiated"
                  ? "#D40606"
                  : (label === parseInt(gmStepper?.stage) &&
                      value.status === "GM Submission") ||
                    (label === parseInt(gmStepper?.stage) &&
                      value.status === "Business Finance Approval") ||
                    (label === parseInt(gmStepper?.stage) &&
                      value.status === "BU Head Approval")
                  ? "#E0B15C"
                  : label === parseInt(gmStepper?.stage) &&
                    value.status === "CHRO Approval"
                  ? "#349259"
                  : label < parseInt(gmStepper?.stage)
                  ? "#349259"
                  : "#bdbdbd",
            }}
          >
            <div className={classes.chipLabel}>{label}</div>
          </div>
        </Tooltip>
      </div>
      <div className={classes.statusMessage1}>{handleText(value)}</div>
    </div>
  );

  return (
    <Stepper
      activeStep={parseInt(gmStepper?.stage) - 1}
      classes={{ root: classes.rootStepper }}
      alternativeLabel
      linear={false}
      orientation="horizontal"
    >
      {gmStepper?.map((value, key) => {
        return (
          <Step
            key={key}
            style={{
              paddingRight: key == 0 ? "50px" : "",
              paddingLeft: key == 2 ? "0px" : "",
            }}
          >
            <StepButton
              style={{
                fontSize: "0.54rem",
                paddingLeft: key == 2 || key == 3 ? "0px" : "",
              }}
              icon={<StepIcon label={key + 1} value={value} />}
              onClick={() => console.log("")}
            />
          </Step>
        );
      })}
    </Stepper>
  );
}

export default GMStepper;