import React, { useState, useEffect } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Avatar from "@material-ui/core/Avatar";
import Encrypt from "../Utils/encryption";
import { makeStyles, Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FeaturedPlayListOutlinedIcon from "@material-ui/icons/FeaturedPlayListOutlined";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import {
  Typography,
  Box,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  TextField,
  Select,
  Button,
  Tabs,
  Tab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Slide,
} from "@material-ui/core";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "@material-ui/icons/Home";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ListAltSharpIcon from "@material-ui/icons/ListAltSharp";
import { useHistory, useLocation } from "react-router";
import { useMsal } from "@azure/msal-react";
import Menu from "@material-ui/core/Menu";
import { useDispatch, useSelector } from "react-redux";
import AccountOptions from "./accountOptions";
import {
  getMyExpenseReportData,
  getMyDelegateesData,
} from "../redux/Expense/action";
import {
  getTimesheetReportAccessData,
  getPendencyReportData,
  getRatingDescriptionData,
  postRatingReviewData,
  getMyTimesheetDelegateeData,
} from "../redux/TimeSheet/actionTs";
import {getMyGlobalMobilityDelegateeData} from "../redux/GMCal/gmAction";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import Link from "@material-ui/core/Link";
import StarIcon from "@mui/icons-material/Star";
import Rating from "@mui/material/Rating";
const useStyles = makeStyles((theme) => ({
  root: {
    background: "#EDF2FF",
    flexDirection: "row",
  },
  formControlDrop: {
    // marginTop: "32px",

    // minWidth: "80px",

    "& .MuiFormLabel-root": {
      top: "-4px",

      color: "#515961",
    },

    "& .MuiSelect-select:focus": {
      backgroundColor: "#EDF2FF",
    },

    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "2px solid #C9D6FF",
      },

      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#C9D6FF",
      },

      "&.Mui-focused fieldset": {
        borderColor: "#C9D6FF",
      },
    },
  },
  formControl: {
    // marginTop: "32px",
    // minWidth: "80px",
    "& .MuiFormLabel-root": {
      top: "-4px",
      color: "#515961",
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        border: "2px solid #439DD9",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#515961",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0B1941",
      },
    },
  },
  timesheetHeader: {
    color: " #0B1941",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px" /* 125% */,
    letterSpacing: "-0.08px",
    margin: "5px",
  },
  successIcon: {
    color: theme.palette.success.main,
    fontSize: 40,
    minWidth: "100%",
  },
  thankYouText: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
    minWidth: "50px",
  },
  messageText: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    minWidth: "50px",
  },  
}));

export default function MobileViewNavbar({ change, ssoEmpId }) {
  const matches = useMediaQuery("(max-width:600px)");
  const [day, setDay] = React.useState(90);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const [value, setNewValue] = useState(90);
  const [employeeName, setEmployeeName] = useState("");
  const [delEmpName, setDelEmpName] = useState("");
  const [tsDelEmpName, setTsDelEmpName] = useState("");
  const [gMDelEmpName, setGMDelEmpName] =  useState("");
  const [employeeCode, setEmployeeCode] = useState("");
  const [expanded, setExpanded] = React.useState("panel2");
  const [isLoader, setIsLoader] = useState(false);
  const location = useLocation();
  const [openRating, setOpenRating] = useState(false);
  const [thankYouRatingOpen, setThankYouRatingOpen] = React.useState(false);
  const [pageModule, setPageModule] = useState("");
  const [ratingHeading, setRatingHeading] = useState("");
  const [rateValue, setRateValue] = React.useState(-1);
  const [hover, setHover] = React.useState(-1);
  const [review, setReview] = React.useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  // console.log("change", change);
  // Check if the current path is "/timesheet-management"
  const isTimesheetManagementPath =
    location.pathname === "/timesheet-management";

  function handleChange(event) {
    let value = event.target.value;
    setNewValue(value);
    setDay(value);
    change(value);
  }

  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );

  const myDelegateesList = useSelector(
    (state) => state.createExpenseData.getMyDelegateesData
  );

  const myTimesheetDelegateesList = useSelector(
    (state) => state?.timesheetData?.getMyTimesheetDelegateeData
  );

  const myGlobalMobilityDelegateesList = useSelector(
    (state) => state?.gmCalData?.getGlobalMobilityDelegateeData
  );

  const delEmpIdRedux = useSelector(
    (state) => state.createExpenseData.delEmpId
  );

  const tsDelEmpIdRedux = useSelector(
    (state) => state?.timesheetData?.tsDelEmpId
  );

  const GMDelEmpIdRedux = useSelector(
    (state) => state?.gmCalData?.GMDelEmpId
  );

  const delegationFlag = useSelector(
    (state) => state.createExpenseData.getDelegationFlag
  );
  const timesheetReportAccessData = useSelector(
    (state) => state.timesheetData.getTimesheetAccessReportList
  );

  const PendencyReportData = useSelector(
    (state) => state.timesheetData.getPendencyReportData
  );

  const ratingLabels = useSelector(
    (state) => state?.timesheetData?.getRatingDescriptionData
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = (event) => {
    setAnchorEl(event.currentTarget);
    localStorage.removeItem("token");
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleDropeDown = (day, delEmpIDRed) => {
    setDay(day);

    if (delEmpIDRed != null) {
      //console.log("Del3-1 : ", delEmpIDRed, props.ssoEmpId);
      let obj = {
        empid: delEmpIDRed,
        nday: day,
      };
      dispatch(getMyExpenseReportData(obj));
    } else {
      //console.log("Del3-2 : ", delEmpIDRed, props.ssoEmpId);
      if (ssoEmpId !== null) {
        let obj = {
          empid: ssoEmpId,
          nday: day,
        };
        dispatch(getMyExpenseReportData(obj));
      }
    }
  };

  // useEffect(() => {
  //   handleDropeDown(day, delEmpIDRedux);
  // }, [day, delEmpIDRedux]);

  useEffect(() => {
    if (ssoEmpId !== null && ssoEmpId !== undefined) {
      dispatch(getMyDelegateesData(ssoEmpId));
      dispatch(getMyTimesheetDelegateeData(ssoEmpId));
      dispatch(getMyGlobalMobilityDelegateeData(ssoEmpId));
      if (ssoEmpIdDetails?.empId != 0 && ssoEmpIdDetails != null) {
        setEmployeeName(ssoEmpIdDetails?.empName);
        setEmployeeCode(ssoEmpIdDetails?.empCode);
      }
    }
  }, [ssoEmpId]);

  useEffect(() => {
    if (ssoEmpIdDetails?.empId != 0 && ssoEmpIdDetails != null) {
      setEmployeeName(ssoEmpIdDetails?.empName);
      setEmployeeCode(ssoEmpIdDetails?.empCode);
    }
  }, [ssoEmpIdDetails]);

  useEffect(() => {
    const tempDel = myDelegateesList?.find(
      (delg) => delg.empId == delEmpIdRedux
    );
    setDelEmpName(tempDel?.empName);
  }, [delEmpIdRedux, myDelegateesList]);

  
  useEffect(() => {
    if(tsDelEmpIdRedux && myTimesheetDelegateesList){
      const tempDel = myTimesheetDelegateesList?.find(
        (delg) => delg?.empId == tsDelEmpIdRedux
      );
      setTsDelEmpName(tempDel?.empName);
    }
  }, [tsDelEmpIdRedux, myTimesheetDelegateesList]);

  useEffect(() => {
    if(GMDelEmpIdRedux && myGlobalMobilityDelegateesList){
      const tempDel = myGlobalMobilityDelegateesList?.find(
        (delg) => delg.empId == GMDelEmpIdRedux
      );
      setGMDelEmpName(tempDel?.empName)
    }
  }, [GMDelEmpIdRedux, myGlobalMobilityDelegateesList])

  useEffect(() => {
    //dispatch(getEmpDetail(account?.username));

    if (ssoEmpIdDetails?.empId) {
      const obj = ssoEmpIdDetails?.empId;
      dispatch(getTimesheetReportAccessData(obj));
    }
  }, [ssoEmpIdDetails]);

  const handlePendencyReport = (item) => {
    setIsLoader(true);
    const obj = {
      body: item,
    };
    dispatch(getPendencyReportData(obj , setIsLoader));
   
    //setIsLoader(false);
  };

  const handleChangeAccordian = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    toggleDrawer("left", true)
  };

  useEffect(() => {
    if (ssoEmpIdDetails) {
      dispatch(getRatingDescriptionData());
    }
  }, [ssoEmpIdDetails]);

  var path = window.location.href;
  var directories = path.split("/");
  var lastDirecotry = directories[directories.length - 1];
  var firstDirectory = directories[directories.length - 2];

  useEffect(() => {
    if (directories && directories?.length === 4) {
      if (
        lastDirecotry === "timesheet-management" ||
        lastDirecotry === "create-Timesheet-Management"
      ) {
        setPageModule("Timesheet");
        setRatingHeading("Timesheet Application");
      } else if (
        lastDirecotry === "expense-Management" ||
        lastDirecotry === "create-Expense-Management" ||
        lastDirecotry === "allExpenseReports"
      ) {
        setPageModule("Expense");
        setRatingHeading("Expense Application");
      } else if (
        lastDirecotry === "gmdashboard" ||
        lastDirecotry === "gmmobility"
      ) {
        setPageModule("GM");
        setRatingHeading("Global Mobility Application");
      }
    } else if (directories && directories?.length === 5) {
      if (firstDirectory === "timesheetviewpage") {
        setPageModule("Timesheet");
        setRatingHeading("Timesheet Application");
      } else if (
        firstDirectory === "viewExpenseDetails" ||
        firstDirectory === "editExpenseDetails" ||
        firstDirectory === "editTravelExpenseDetails" ||
        firstDirectory === "deligation"
      ) {
        setPageModule("Expense");
        setRatingHeading("Expense Application");
      } else if (
        firstDirectory === "gmbupage" ||
        firstDirectory === "gmmobility"
      ) {
        setPageModule("GM");
        setRatingHeading("Global Mobility Application");
      }
    }
  }, [directories]);

  useEffect(() => {
    if (rateValue && rateValue === -1) {
      setIsSubmitDisabled(true);
    } else {
      setIsSubmitDisabled(false);
    }
  }, [rateValue]);

  function getLabelText(rateValue) {
    return (
      ratingLabels &&
      `${rateValue} Star${rateValue !== 1 ? "s" : ""}, ${
        ratingLabels[rateValue - 1]?.ratingDescription
      }`
    );
  }

  const handleRating = () => {
    setOpenRating(true);
  };

  const handleRatingClose = async () => {
    setOpenRating(false);

    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    const ratingFormData = new FormData();

    ratingFormData.append(
      "EmpId",
      Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
    ratingFormData.append("ApplicationType", pageModule ? pageModule : "");
    ratingFormData.append("ApplicationDiscription", "");
    ratingFormData.append("Review", "");
    ratingFormData.append("Skippedflag", 1);

    try {
      const response = await dispatch(postRatingReviewData(ratingFormData));

      if (response.status === 200) {
        // Route to the gmdashboard location after 3 seconds
      } else {
        setThankYouRatingOpen(false);
      }
    } catch {
      setThankYouRatingOpen(false);
    }
  };

  const handleRatingSkipClose = async () => {
    setOpenRating(false);

    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    const ratingFormData = new FormData();

    ratingFormData.append(
      "EmpId",
      Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
    ratingFormData.append("ApplicationType", pageModule ? pageModule : "");
    ratingFormData.append("ApplicationDiscription", "");
    ratingFormData.append("Review", "");
    ratingFormData.append("Skippedflag", 1);

    try {
      const response = await dispatch(postRatingReviewData(ratingFormData));

      if (response.status === 200) {
        // Route to the gmdashboard location after 3 seconds
      } else {
        setThankYouRatingOpen(false);
      }
    } catch {
      setThankYouRatingOpen(false);
    }
  };

  const handleRatingSubmit = async () => {
    setOpenRating(false);
    var key = process.env.REACT_APP_ENCRYPTION_KEY;
    const ratingFormData = new FormData();

    ratingFormData.append(
      "EmpId",
      Encrypt(key, JSON.stringify(ssoEmpIdDetails?.empId))
    );
    ratingFormData.append("ApplicationType", pageModule ? pageModule : "");
    ratingFormData.append(
      "ApplicationDiscription",
      ratingLabels && rateValue && ratingLabels[rateValue - 1]?.ratingDescription
    );
    ratingFormData.append("Review", review ? review : "");
    ratingFormData.append("Skippedflag", 0);

    try {
      const response = await dispatch(postRatingReviewData(ratingFormData));
      if (response.status === 200) {
        setTimeout(() => {
          setThankYouRatingOpen(true);
        }, 500);
        setTimeout(() => {
          setThankYouRatingOpen(false);
        }, 5000);
      } else {
        setThankYouRatingOpen(false);
      }
    } catch {
      setThankYouRatingOpen(false);
    }
  };


  const list = (anchor) => (
    <div
      // className={clsx(classes.list, {
      //   [classes.fullList]: anchor === "top" || anchor === "bottom",
      // })}
      // role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
      style={{height:"100%"}}
    >
      <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      &nbsp;
      </div>
      <List>
        {" "}
        {[
          <a
            onClick={() => {
              history.push("/");
            }}
          >
            {"Home"}
          </a>,
          // "All Expense", "Timesheet",
          isTimesheetManagementPath ? "Timesheet" : "All Expense",
          delegationFlag?.allExpReportsFlag == 1  
           ?"All Reports"
           : "",
           isTimesheetManagementPath &&
           timesheetReportAccessData?.length > 0
           ? "Reports"
           : ""
        ].map((text, index) => (
          <ListItem button key={index}>
            <ListItemIcon>

              {index === 0 ? (
                <HomeIcon
                  onClick={() => {
                    history.push("/");
                  }}
                />
              ) : index === 1 ? (
                // <AttachMoneyIcon />
                <div
                  className="Icon"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {text == "Timesheet" ? (
                    <FeaturedPlayListOutlinedIcon
                      style={{ color: "rgba(0, 0, 0, 0.54)" }}
                      onClick={() => {
                        history.push("/timesheet-management");
                      }}
                    />
                  ) : (
                    <AttachMoneyIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                  )}
                </div>
              ) : delegationFlag?.allExpReportsFlag == 1 && !isTimesheetManagementPath && text === "All Reports" ? (
                <ListAltSharpIcon
                  onClick={() => {
                    history.push("/allExpenseReports");
                  }}
                />
              ) 
                  : isTimesheetManagementPath &&
                  text == "Reports" ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginLeft:"57px",
                        marginTop:"-30px",
                      }}
                    >
                      <div
                        className="Icon"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginLeft:"-55px",
                        }}
                      >
                        <DescriptionOutlinedIcon
                          style={{ color: "rgba(0, 0, 0, 0.54)" }}
                        />
                      </div>
                      <div
                        style={{
                          minHeight: "20px",
                          //backgroundColor: "#0B1941",
                          display: "flex",
                          justifyContent: "left",
                          marginLeft:"30px",
                        }}
                      >
                        <Accordion
                          expanded={expanded === "panel1"}
                          onChange={handleChangeAccordian("panel1")}
                          style={{
                            width: "87px",
                            minHeight: "20px",
                           // backgroundColor: "#0B1941",
                          
         elevation: 0,
         boxShadow: "none",
                          }}
                        
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            style={{
                              width: "80px",
                              minHeight: "10px",
                              //backgroundColor: "#0B1941",
                              height: "25px",
                              marginLeft:"10px",
                            }}
                            elavation={0}
                          >
                            <ArrowRightIcon
                              style={{
                                fontSize: "18px",
                                color: "rgba(0, 0, 0, 0.54)",
                                marginTop:"3px",
                              }}
                           
                            />
                            <Typography
                              style={{
                                fontSize: "12px",
                                color: "rgba(0, 0, 0, 0.87)",
                                //backgroundColor: "#0B1941",
                                marginRight: "49px",
                                fontWeight:"400px",
                                fontSize:"1rem",
                              }}
                            >
                              Reports
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{
                              width: "80px",
                              color: "rgba(0, 0, 0, 0.87)",
                              padding: "8px 16px 16px 8px",
                              marginLeft:"-10px"
                            }}
                          >
                            {timesheetReportAccessData?.map(
                              (item) => {
                                return (
                                  <Box
                                    key={item.reportValue}
                                    style={{
                                      color: "rgba(0, 0, 0, 0.87)",
                                      width: "22px",
                                      fontSize: "11px",
                                      paddingBottom: "8px",
                                      display: "flex", // Use flex display to have items on the same line
                                      alignItems: "center",
                                    }}
                                    onClick={(e) => {
                                      setIsLoader(true);
                                      handlePendencyReport(
                                        item.reportName
                                      );
                                      //   item.reportName !== ""
                                      // ? handlePendencyReport
                                      // : item.reportName === "ApprovedReport"
                                      // ? handleApprovalReport
                                      // : item.reportName === "AuditReport"
                                      // ? handleAuditReport
                                      // : ""
                                    }}
                                  >
                                    <Box>{"-   "}</Box>
                                    <Box>
                                      &nbsp;{item.reportDisplayName}
                                    </Box>
                                    {/* {console.log("item" , item.reportName)} */}
                                  </Box>
                                );
                              }
                            )}
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  ) : ""}
               
            </ListItemIcon>

            <ListItemText primary={
               text === "All Reports" ? !isTimesheetManagementPath ? text : " " : text != "Reports" ? text : ""
             }
             onClick={(e)=>{
              if (text === "All Reports") {
                history.push("/allExpenseReports");
              }
             }} />
          </ListItem>
        ))}
      </List>
        <div>
          <List>
            <ListItem>
              <ListItemIcon>
                <StarOutlineIcon
                  style={{ color: "rgba(0, 0, 0, 0.54)" }}
                />
              </ListItemIcon>
              <ListItemText  primary = {"Rate Us"}
                onClick = {handleRating}
              />
            </ListItem>
          </List>
        </div>
    </div>
  );
  return (
    <>
    <div className={classes.root}>
      <Grid container style={{ paddingTop: "4%" }}>
        <Grid item xs={2} style={{ paddingLeft: "4%" }}>
          <MenuIcon
            style={{ paddingTop: "6%" }}
            onClick={toggleDrawer("left", true)}
          />
          <Drawer
            style={{ width: "100%" }}
            anchor={"left"}
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
          >
            {" "}
            {list("left")}{" "}
          </Drawer>
        </Grid>
        <Grid item xs={4}>
          {/* <FormControl variant="outlined" className={classes.formControlDrop}>
            <Select
              // labelId="demo-simple-select-outlined-label"
              // id="demo-simple-select-outlined"
              style={{
                maxHeight: "29px",
                borderRadius: "24px",
                border: "2px solid #C9D6FF",
                fontSize:"10px",
              }}
              value={day}
              onChange={handleChange}
              MenuProps={{
                getContentAnchorEl: null,
                style: {
                  borderRadius: "24px",
                },
              }}
            >
              <MenuItem
                value={90}
                style={{ padding: "10px",}}
              >
                90 Days
              </MenuItem>
              <MenuItem value={180} style={{ padding: "10px" }}>
                180 Days
              </MenuItem>
              <MenuItem value={365} style={{ padding: "10px" }}>
                Year Till Date
              </MenuItem>
            </Select>
          </FormControl>{" "} */}
          {isTimesheetManagementPath ? (
            // Render nothing or any alternative content for /timesheet-management path
            <>
              {" "}
              <h2 className={classes.timesheetHeader}>Timesheet</h2>
            </>
          ) : (
            <FormControl variant="outlined" className={classes.formControlDrop}>
              <Select
                style={{
                  maxHeight: "29px",
                  borderRadius: "24px",
                  border: "2px solid #C9D6FF",
                  fontSize: "10px",
                }}
                value={day}
                onChange={handleChange}
                MenuProps={{
                  getContentAnchorEl: null,
                  style: {
                    borderRadius: "24px",
                  },
                }}
              >
                <MenuItem value={90} style={{ padding: "10px" }}>
                  90 Days
                </MenuItem>
                <MenuItem value={180} style={{ padding: "10px" }}>
                  180 Days
                </MenuItem>
                <MenuItem value={365} style={{ padding: "10px" }}>
                  Year Till Date
                </MenuItem>
              </Select>
            </FormControl>
          )}
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            marginTop: "-12px",
            marginLeft: "110px",
          }}
        >
          <AccountOptions
            employeeId={ssoEmpId}
            employeeCode={employeeCode}
            employeeName={employeeName}
            myDelegateesList={myDelegateesList}
            myTimesheetDelegateesList={myTimesheetDelegateesList}
            myGlobalMobilityDelegateesList={myGlobalMobilityDelegateesList}
            delEmpName={delEmpName ?? tsDelEmpName ?? gMDelEmpName}
          />
        </Grid>
      </Grid>
    </div>
    <div>
    <Backdrop
      sx={{ color: " rgb(227, 232, 247)", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoader}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  </div>
  <Box>
    <Dialog
      open={openRating}
      keepMounted
      onClose={handleRatingClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <Box style={{ width: "300px", height: "500px" }}>
        <DialogTitle>
          <Typography
            style={{
              fontWeight: "600",
              fontSize: "20px",
              marginLeft: "10px",
              textAlignLast: "center",
            }}
          >
            Rate and Review <br></br> {ratingHeading}
          </Typography>
          <Divider
            style={{
              backgroundColor: "#E1E1E1",
              width: "100%",
              height: "0.5px",
              marginLeft: "2.5%",
              marginTop: "10px",
            }}
            orientation="horizontal"
          />
        </DialogTitle>
        <DialogContent style={{ paddingTop: "12px" }}>
          <DialogContentText id="alert-dialog-slide-description">
            <Box style={{ marginLeft: "-44px" }}>
              <Box style={{ width: "300px", marginLeft: "20px" }}>
                <Typography
                  style={{ textAlign: "center", color: "#000", width: "18rem" }}
                >
                  <span style={{ color: "#2CB0FE", fontWeight: "400" }}>
                    {ssoEmpIdDetails ? ssoEmpIdDetails?.empName : ""}
                  </span>
                  , we'd like to know your reviews on {ratingHeading}.
                </Typography>
              </Box>
              <Box
                sx={{
                  width: 300,
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "40px",
                  textAlignLast: "center",
                }}
              >
                <Box sx={{ width: 100 }}></Box>
                <Box sx={{ width: 200 }}>
                  <Rating
                    name="hover-feedback"
                    value={rateValue}
                    getLabelText={getLabelText}
                    size="large"
                    onChange={(event, newValue) => {
                      newValue === null
                        ? setRateValue(-1)
                        : setRateValue(newValue);
                    }}
                    onChangeActive={(event, newHover) => {
                      setHover(newHover);
                    }}
                    emptyIcon={
                      <StarOutlineIcon
                        style={{opacity: 0.8, color: "#2CB0FE" }}
                        fontSize="inherit"
                      />
                    }
                    style={{ textAlignLast: "center" }}
                  />
                </Box>
                <Box sx={{ width: 50 }}></Box>
              </Box>

              {rateValue !== null && ratingLabels && (
                <Box
                  style={{
                    textAlignLast: "center",
                    paddingTop: "5px",
                    marginLeft: "3rem",
                    height: "1rem",
                  }}
                >
                  <Typography
                    style={{ fontSize: "14px", color: "#000" }}
                  >
                    {
                      ratingLabels[hover !== -1 ? hover - 1 : rateValue - 1]
                        ?.ratingDescription
                    }
                  </Typography>
                </Box>
              )}
            </Box>

            <Box>
              <Box
                style={{ textAlignLast: "center", paddingTop: "25px" }}
              >
                <Typography
                  style={{ fontWeight: "550", color: "#2CB0FE" }}
                >
                  Write a Review
                </Typography>
              </Box>
              <Box
                style={{
                  paddingTop: "10px",
                  marginLeft: "10px",
                }}
              >
                <TextField
                  id="review"
                  value={review}
                  placeholder="What did you like or dislike? Write here your feedback about this application."
                  multiline
                  rows={3}
                  onChange={(e) => setReview(e.target.value)}
                  style={{
                    width: "100%",
                    border: "1px solid #000",
                    borderRadius: "5px",
                  }}
                  InputProps={{
                    inputProps: {
                      maxLength: 200,
                    }
                  }}
                ></TextField>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: "6px",
                }}
              >
                <Box style={{ width: "50px" }}></Box>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      width: "120px",
                      textTransform: "none",
                      backgroundColor: isSubmitDisabled
                        ? "#9E9E9E"
                        : "#043365",
                    }}
                    onClick={handleRatingSubmit}
                    disabled={isSubmitDisabled ? true : false}
                  >
                    Submit
                  </Button>
                </Box>
                <Box>
                  <Link underline="none" target="_blank">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        height: "30px",
                      }}
                    >
                      <Typography
                        onClick={handleRatingSkipClose}
                        style={{
                          cursor: "pointer",
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                      >
                        Skip {`>`}
                      </Typography>
                    </Box>
                  </Link>
                </Box>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Box>
    </Dialog>
  </Box>
  <Box>
    <Dialog
      open={thankYouRatingOpen}
      // TransitionComponent={Transition}
      keepMounted
      // onClose={handleThankyou}
      aria-describedby="alert-dialog-slide-description"
    >
      <Box style={{ width: "300px", height: "250px" }}>
        <DialogTitle>
          <CheckCircleIcon className={classes.successIcon} />
          <Typography
            style={{
              fontWeight: "600",
              fontSize: "20px",
              marginLeft: "10px",
              textAlignLast: "center",
            }}
          >
            Thanks for <br></br>Rating and Review.
          </Typography>
        </DialogTitle>
        <DialogContent style={{ paddingTop: "12px" }}>
          <DialogContentText id="alert-dialog-slide-description">
            <Box style={{ marginLeft: "-44px" }}>
              <Box
                sx={{
                  width: 300,
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "40px",
                  textAlignLast: "center",
                }}
              >
                <Box sx={{ width: 100 }}></Box>
                <Box sx={{ width: 200 }}>
                  <Rating
                    name="hover-feedback"
                    value={rateValue}
                    getLabelText={getLabelText}
                    size="large"
                    emptyIcon={
                      <StarOutlineIcon
                        style={{opacity: 0.8, color: "#2CB0FE" }}
                        fontSize="inherit"
                      />
                    }
                    style={{ textAlignLast: "center" }}
                  />
                </Box>
                <Box sx={{ width: 50 }}></Box>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Box>
    </Dialog>
  </Box>
  </>
  );
}
