import React, { useEffect, useReducer, useRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { lighten, makeStyles, styled } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Drawer from "@material-ui/core/Drawer";
import Table from "@material-ui/core/Table";
import Divider from "@material-ui/core/Divider";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import ApproveRejectDialog from "./approveRejectDialog.js";
import Badge from "@material-ui/core/Badge";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { NumericFormat } from "react-number-format";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import rows from "../Utils/teamApprovalDummyData.json";
import Filterimg from "../images/filter.svg";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { useTheme } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormGroup,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Popover,
  Popper,
  Select,
  TextField,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import SettingsIcon from "@material-ui/icons/Settings";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Switch from "@material-ui/core/Switch";
import { Grid } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  getDownloadExpenseReportData,
  getFinanceApprovalData,
  getMyTeamApprovalData,
  postApproveRejectExpenseData,
  postFinancialApproveRejectExpenseData,
} from "../redux/Expense/action";
import moment from "moment";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import { CustomSwitch } from "./customSwitch.js";
import OutsideClickHandler from "react-outside-click-handler";
import useMediaQuery from "@material-ui/core/useMediaQuery";
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    //console.log("descendingComparator: -1");
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    //console.log("descendingComparator: 1");
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis?.map((el) => el[0]);
}

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
    //console.log("event1",event)
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  return (
    <div className={classes.rootFooter}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

function AllExpenseTableHead(props) {
  const [anchorEl0, setAnchorEl0] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const [anchorEl5, setAnchorEl5] = React.useState(null);
  const [anchorEl6, setAnchorEl6] = React.useState(null);
  const [anchorEl7, setAnchorEl7] = React.useState(null);
  const [anchorEl8, setAnchorEl8] = React.useState(null);
  const [anchorEl9, setAnchorEl9] = React.useState(null);
  const [anchorEl10, setAnchorEl10] = React.useState(null);
  const [anchorEl12, setAnchorEl12] = React.useState(null);
  const [anchorEl13, setAnchorEl13] = React.useState(null);

  const [criteriaClaimAmount, setCriteriaClaimAmount] = React.useState(0);
  const [operatorClaimAmount, setOperatorClaimAmount] = React.useState("<=");
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [teamAppAttachments, setTeamAppAttachments] = React.useState(false);
  const [teamAppProjectId, setTeamAppProjectId] = React.useState(false);
  const [openBulkApproveDialog, setOpenBulkApproveDialog] =
    React.useState(false);
  const [approveOrReject, setApproveOrReject] = React.useState(1);
  const [finAppToggle, setFinAppToggle] = React.useState(false);
  const [expenseId, setExpenseId] = React.useState(null);
  const [expenseCategory, setExpenseCategory] = React.useState(null);
  const [buttonState, setButtonState] = React.useState(true);
  const [filterOffToggle, setFilterOffToggle] = useState("");
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    pageNumber,
    onRequestSort,
    onClickFilter,
    rowsPerPage,
    getFinanceApprovalList,
    bulkDownload,
    empId,
    resetBulkDownload,
  } = props;
  const dispatch = useDispatch();
  // console.log("RESET1", getFinanceApprovalList);

  const headCells = [
    {
      id: "expenseCode",
      numeric: false,
      disablePadding: false,
      label: "Expense ID",
    },
    {
      id: "purpose",
      numeric: false,
      disablePadding: true,
      label: "Expense Purpose",
    },
    {
      id: "expenseCategory",
      numeric: false,
      disablePadding: true,
      label: "Expense Category",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: true,
      label: "Employee",
    },
    {
      id: "createdOn",
      numeric: false,
      disablePadding: true,
      label: "Creation Date",
    },
    {
      id: "managerApprovedOn",
      numeric: false,
      disablePadding: true,
      label: "Manager Approval Date",
    },
    {
      id: "claimAmount",
      numeric: true,
      disablePadding: true,
      label: "Claim Amount",
    },

    {
      id: "currentStatus",
      numeric: false,
      disablePadding: true,
      label: "Status",
    },

    {
      id: "projectId",
      numeric: false,
      disablePadding: true,
      label: "Project Code",
    },
  ];

  const headCells1 = [
    {
      id: "expenseCategory",
      numeric: false,
      disablePadding: false,
      label: "Expense Category",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Employee",
    },
    {
      id: "managerApprovedOn",
      numeric: false,
      disablePadding: false,
      label: "Manager Approval Date",
    },
    {
      id: "claimedAmount",
      numeric: true,
      disablePadding: false,
      label: "Claim Amount",
    },
  ];

  const headers = [
    {
      label: "Expense Code",
      key: "expenseCode",
    },
    {
      label: "Expense Category",
      key: "expenseCategory",
    },
    {
      label: "To Location",
      key: "toLocation",
    },
    {
      label: "From Location",
      key: "fromLocation",
    },
    {
      label: "Distance (Km/Miles)",
      key: "distance",
    },
    {
      label: "From Date",
      key: "fromDate",
    },
    {
      label: "To Date",
      key: "toDate",
    },
    {
      label: " Claim Month",
      key: "forMonth",
    },
    {
      label: "Manager Approval Date",
      key: "l1ApprovedDate",
    },
    {
      label: "Relationship",
      key: "dependents",
    },
    {
      label: "Currency",
      key: "currencyCode",
    },
    {
      label: "Claimed Amount",
      key: "claimAmount",
    },
    {
      label: "Policy Limit",
      key: "policyLimit",
    },

    {
      label: "Attachments",
      key: "attachments",
    },
    {
      label: "Project ID",
      key: "projectids",
    },
    {
      label: "Employee",
      key: "createdBy",
    },
  ];
  //console.log("checkdown", bulkDownload);
  const csvLink = {
    headers: headers,
    data: bulkDownload,
    filename: `FinanceApproval_${moment(new Date()).format(
      "DDMMYYYY_HHmm"
    )}.csv`,
  };
  //console.log("bulkdwlnd01", bulkDownload);
  const createSortHandler = (property) => (event) => {
    //console.log("event", event);
    //console.log("property", property);
  };
  const handleOpenBulkApproveDialog = () => {
    setOpenBulkApproveDialog(true);
    setFinAppToggle(true);
  };
  const handleCloseBulkApproveDialog = () => {
    //console.log("openBulkDialog--- in fxn", openBulkApproveDialog);
    setOpenBulkApproveDialog(false);
    setFinAppToggle(false);
  };

  const handleBulkApprove = () => {
    //console.log("empId:  ====>  ", empId);
    //console.log("bulkDownload: FAT123  ====>  ", bulkDownload);

    let array = [];
    bulkDownload?.map((value) =>
      value?.projdetails?.map((item) => {
        array.push({
          recid: Number(item.projectRecID),
          expid: Number(value.expId),
          projid: Number(item.projectUniqueID),
          projamount: 0,
          claimedamount: value.claimAmount,
        });
      })
    );
    //console.log("array:====> ", array);

    let dataObject = {
      empId: empId,
      expStatus: "Approved",
      comment: "",
      amount: 0,
      projdetails: array,
    };
    //console.log("dataObject:  ====>  ", dataObject);

    dispatch(postFinancialApproveRejectExpenseData(dataObject));
    resetBulkDownload();
    handleCloseBulkApproveDialog();
    // dispatch(getFinanceApprovalData({ empId: 13, nday: 90 }));
    // forceUpdate();
  };

  // //console.log("onClickFilter", onClickFilter);
  const handleEndDateChange = (date) => {
    setButtonState(!(startDate && date));
    setEndDate(date);
  };
  const handleStartDateChange = (date) => {
    setButtonState(!(endDate && date));
    setStartDate(date);
  };
  // console.log("bulkDownload: ---->  ", bulkDownload);
  // console.log("endDate", endDate);
  function handleFilterClaimAmount(name) {
    let tempFilterArray = [];
    if (name == "expenseCode") {
      tempFilterArray = getFinanceApprovalList?.filter(
        (item) =>
          item.expCode
            .toLowerCase()
            .includes(criteriaClaimAmount.toLowerCase()) == true
      );
      // console.log(
      //   "handleFilterClaimAmount tempFilterArray in 2 :----",
      //   tempFilterArray
      // );
    } else if (name == "claimAmount") {
      if (selectedOperatorClaimAmount == "gteq") {
        tempFilterArray = getFinanceApprovalList?.filter(
          (item) => item.claimedAmount >= criteriaClaimAmount
        );
      } else if (selectedOperatorClaimAmount == "lteq") {
        tempFilterArray = getFinanceApprovalList?.filter(
          (item) => item.claimedAmount <= criteriaClaimAmount
        );
      }
    } else if (name == "purpose") {
      tempFilterArray = getFinanceApprovalList?.filter(
        (item) => item.purpose === criteriaClaimAmount
      );
    } else if (name == "expenseCategory") {
      tempFilterArray = getFinanceApprovalList?.filter(
        (item) => item.categoryName === criteriaClaimAmount
      );
    } else if (name == "createdBy") {
      // tempFilterArray = getFinanceApprovalList?.filter(
      //   (item) => item.createdBy == criteriaClaimAmount
      // );
      // console.log("Filtering by Employee:", criteriaClaimAmount);
      tempFilterArray = getFinanceApprovalList?.filter(
        (item) => item.createdBy.toLowerCase().includes(criteriaClaimAmount.toLowerCase())
      );
      // console.log("Filtered Employee Array:", tempFilterArray);
    } else if (name == "createdOn") {
      var d1 = moment(startDate).toDate();
      var d2 = moment(endDate).toDate();
      d1.setHours(0);
      d1.setMinutes(0);
      d1.setSeconds(0);
      d1.setMilliseconds(0);
      d2.setHours(0);
      d2.setMinutes(0);
      d2.setSeconds(0);
      d2.setMilliseconds(0);

      tempFilterArray = getFinanceApprovalList?.filter(
        (item) =>
          moment(item.createdOn).toDate() >= d1 &&
          moment(item.createdOn).toDate() <= d2
      );
    } else if (name == "managerApprovedOn") {
      var d1 = moment(startDate).toDate();
      var d2 = moment(endDate).toDate();
      d1.setHours(0);
      d1.setMinutes(0);
      d1.setSeconds(0);
      d1.setMilliseconds(0);
      d2.setHours(0);
      d2.setMinutes(0);
      d2.setSeconds(0);
      d2.setMilliseconds(0);

      tempFilterArray = getFinanceApprovalList
        ?.filter((item) => item.managerApprovedOn != null)
        ?.filter(
          (item) =>
            moment(item.managerApprovedOn).toDate() >= d1 &&
            moment(item.managerApprovedOn).toDate() <= d2
        );
    } else if (name == "currentStatus") {
      tempFilterArray = getFinanceApprovalList?.filter(
        (item) =>
          item.status
            .toLowerCase()
            .includes(criteriaClaimAmount.toLowerCase()) == true
      );
    } else if (name == "projectId") {
      tempFilterArray = getFinanceApprovalList?.filter(
        (item) =>
          item.projectId
            .toLowerCase()
            .includes(criteriaClaimAmount.toLowerCase()) == true
      );
    }
    //WTD-1 : Add filter for ALL settings columns
    //console.log("tempFilterArray", tempFilterArray);
    onClickFilter(tempFilterArray);
    setFilterOffToggle(name);
    handleClosePopper();
  }
  const handleAddColumn = (name, value) => {
    //console.log("handleAddColumn : name :", name, " value: ", value);
    props.handleSettingsParent(name, value);

    if (name == "currentStatus") {
      setTeamAppAttachments(value);
    } else if (name == "projectId") {
      setTeamAppProjectId(value);
    }
  };
  const handleClosePopper = () => {
    setAnchorEl0(null);
    setAnchorEl1(null);
    setAnchorEl2(null);
    setAnchorEl3(null);
    setAnchorEl4(null);
    setAnchorEl5(null);
    setAnchorEl6(null);
    setAnchorEl7(null);
    setAnchorEl8(null);
    setAnchorEl9(null);
    setAnchorEl10(null);
    setAnchorEl12(null);
    setAnchorEl13(null);
    setButtonState(true);
    setExpenseId("");
    setExpenseCategory("");
    setCriteriaClaimAmount("");
    setOperatorClaimAmount("");
    setOpenBulkApproveDialog("");
    setStartDate(null);
    setEndDate(null);
  };
  const handleRemoveFilter = () => {
    onClickFilter(getFinanceApprovalList);
    handleClosePopper();
  };
  const handleFilterOff = () => {
    //console.log("handleFilterOff", getFinanceApprovalList);
    onClickFilter(getFinanceApprovalList);
    handleClosePopper();
    setFilterOffToggle(false);
  };
  const handleFilterPopper0 = (event) => {
    setAnchorEl0(anchorEl0 ? null : event.currentTarget);
  };
  const handleFilterPopper1 = (event) => {
    setAnchorEl1(anchorEl1 ? null : event.currentTarget);
  };
  const handleFilterPopper2 = (event) => {
    setAnchorEl2(anchorEl2 ? null : event.currentTarget);
  };
  const handleFilterPopper3 = (event) => {
    setAnchorEl3(anchorEl3 ? null : event.currentTarget);
  };
  const handleFilterPopper4 = (event) => {
    setAnchorEl4(anchorEl4 ? null : event.currentTarget);
  };
  const handleFilterPopper5 = (event) => {
    setAnchorEl5(anchorEl5 ? null : event.currentTarget);
  };
  const handleFilterPopper7 = (event) => {
    setAnchorEl7(anchorEl7 ? null : event.currentTarget);
  };
  const handleFilterPopper8 = (event) => {
    setAnchorEl8(anchorEl8 ? null : event.currentTarget);
  };
  const handleFilterPopper9 = (event) => {
    setAnchorEl9(anchorEl9 ? null : event.currentTarget);
  };
  const handleFilterPopper10 = (event) => {
    setAnchorEl10(anchorEl10 ? null : event.currentTarget);
  };
  const handleSettingPopper = (event) => {
    setAnchorEl6(anchorEl6 ? null : event.currentTarget);
  };
  const handleFilterPopper12 = (event) => {
    setAnchorEl12(anchorEl12 ? null : event.currentTarget);
  };
  const handleFilterPopper13 = (event) => {
    setAnchorEl13(anchorEl13 ? null : event.currentTarget);
  };
  const open0 = Boolean(anchorEl0);
  const id0 = open0 ? "simple-popper" : undefined;
  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "simple-popper" : undefined;
  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? "simple-popper" : undefined;
  const open3 = Boolean(anchorEl3);
  const id3 = open3 ? "simple-popper" : undefined;
  const open4 = Boolean(anchorEl4);
  const id4 = open4 ? "simple-popper" : undefined;
  const open5 = Boolean(anchorEl5);
  const id5 = open5 ? "simple-popper" : undefined;
  const open6 = Boolean(anchorEl6);
  const id6 = open6 ? "simple-popper" : undefined;
  const open7 = Boolean(anchorEl7);
  const id7 = open7 ? "simple-popper" : undefined;
  const open8 = Boolean(anchorEl8);
  const id8 = open8 ? "simple-popper" : undefined;
  const open9 = Boolean(anchorEl9);
  const id9 = open9 ? "simple-popper" : undefined;
  const open10 = Boolean(anchorEl10);
  const id10 = open10 ? "simple-popper" : undefined;
  const open12 = Boolean(anchorEl12);
  const id12 = open12 ? "simple-popper" : undefined;
  const open13 = Boolean(anchorEl13);
  const id13 = open13 ? "simple-popper" : undefined;

  //console.log("criteriaClaimAmount:----", criteriaClaimAmount);

  const options = [
    {
      label: ">=",

      value: ">=",
    },

    {
      label: "<=",

      value: "<=",
    },
  ];
  const [selectedOperatorClaimAmount, setselectedOperatorClaimAmount] =
    useState("lteq");

  const data = [
    {
      label: ">=",
      value: ">=",
    },
    {
      label: "<=",
      value: "<=",
    },
  ];
  const [selectedPolicyLimit, setselectedPolicyLimit] = useState("lteq");
  // console.log("rowsPerPageOptions",rowsPerPageOptions)
  const matches = useMediaQuery("(max-width:600px)");
  const headCellValue = matches ? headCells1 : headCells;
  const preventMinus = (e) => {
    if (e.code === "Minus" || e.key === "-") {
      e.preventDefault();
    }
  };
  return (
    <TableHead>
      {bulkDownload?.length == 0 && (
        <>
          {!matches ? (
            <TableRow style={{ height: 58 }}>
              {headCells?.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={"left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  style={{
                    // position:
                    //   headCell.id == "action" || headCell.id == "setting"
                    //     ? "sticky"
                    //     : "",
                    right:
                      headCell.id == "setting"
                        ? 0
                        : headCell.id == "action"
                        ? "55px"
                        : "",
                    // backgroundColor:
                    //   headCell.id == "action" || headCell.id == "setting"
                    //     ? "#FFFFFF"
                    //     : "#F4F4F4",

                    minWidth:
                      headCell.id == "setting"
                        ? "53px"
                        : headCell.id == "expenseCode" ||
                          headCell.id == "createdOn" ||
                          headCell.id == "managerApprovedOn"
                        ? "135px"
                        : headCell.id == "action"
                        ? "80px"
                        : headCell.id == "purpose"
                        ? "157px"
                        : headCell.id == "expenseCategory"
                        ? "173px"
                        : headCell.id == "createdBy"
                        ? "150px"
                        : headCell.id == "projectId"
                        ? "156px"
                        : "120px",
                    padding: headCell.id == "expenseCode" ? "0px" : "",
                    paddingLeft: headCell.id == "currentStatus" ? "10px" : "",
                  }}
                  className={
                    (headCell.id == "action" || headCell.id == "setting") &&
                    (teamAppAttachments == true || teamAppProjectId == true)
                      ? classes.stickyLeft
                      : classes.nonSticky
                  }
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent:
                        headCell.id == "expenseCode" ? "center" : "",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        lineHeight: "17px",
                        letterSpacing: "0em",
                        // textAlign: headCell.id == "claimAmount" || headCell.id =="policyLimit"?"right":"left",
                        paddingLeft:
                          headCell.id == "claimAmount"
                            ? "27px"
                            : headCell.id == "policyLimit"
                            ? "20px"
                            : headCell.id == "expenseCategory"
                            ? "12px"
                            : headCell.id == "createdBy"
                            ? "12px"
                            : "",
                      }}
                    >
                      {headCell.label}
                    </Typography>
                    {headCell.id === "expenseCode" ? (
                      filterOffToggle == "expenseCode" ? (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterOff}
                          style={{ color: "#BB271A", padding: "5px" }}
                        >
                          <span
                            class="material-symbols-outlined"
                            color="#BB271A"
                          >
                            filter_alt_off
                          </span>
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterPopper0}
                          style={{
                            color: "#666666",
                            padding: "5px",
                            size: "small",
                          }}
                        >
                          <span class="material-symbols-outlined">
                            filter_alt
                          </span>
                        </IconButton>
                      )
                    ) : headCell.id === "purpose" ? (
                      filterOffToggle == "purpose" ? (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterOff}
                          style={{ color: "#BB271A", padding: "12px" }}
                        >
                          <span
                            class="material-symbols-outlined"
                            color="#BB271A"
                          >
                            filter_alt_off
                          </span>
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterPopper13}
                          style={{
                            color: "#666666",
                            padding: "5px",
                            size: "small",
                          }}
                        >
                          <span class="material-symbols-outlined">
                            filter_alt
                          </span>
                        </IconButton>
                      )
                    ) : headCell.id === "expenseCategory" ? (
                      filterOffToggle == "expenseCategory" ? (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterOff}
                          style={{ color: "#BB271A", padding: "5px" }}
                        >
                          <span
                            class="material-symbols-outlined"
                            color="#BB271A"
                          >
                            filter_alt_off
                          </span>
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterPopper1}
                          style={{
                            color: "#666666",
                            padding: "5px",
                            size: "small",
                          }}
                        >
                          <span class="material-symbols-outlined">
                            filter_alt
                          </span>
                        </IconButton>
                      )
                    ) : headCell.id === "createdBy" ? (
                      filterOffToggle == "createdBy" ? (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterOff}
                          style={{ color: "#BB271A", padding: "5px" }}
                        >
                          <span
                            class="material-symbols-outlined"
                            color="#BB271A"
                          >
                            filter_alt_off
                          </span>
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterPopper2}
                          style={{
                            color: "#666666",
                            padding: "5px",
                            size: "small",
                          }}
                        >
                          <span class="material-symbols-outlined">
                            filter_alt
                          </span>
                        </IconButton>
                      )
                    ) : headCell.id === "claimAmount" ? (
                      filterOffToggle == "claimAmount" ? (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterOff}
                          style={{ color: "#BB271A", padding: "5px" }}
                        >
                          <span
                            class="material-symbols-outlined"
                            color="#BB271A"
                          >
                            filter_alt_off
                          </span>
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterPopper3}
                          style={{
                            color: "#666666",
                            padding: "5px",
                            size: "small",
                          }}
                        >
                          <span class="material-symbols-outlined">
                            filter_alt
                          </span>
                        </IconButton>
                      )
                    ) : headCell.id === "createdOn" ? (
                      filterOffToggle == "createdOn" ? (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterOff}
                          style={{ color: "#BB271A", padding: "5px" }}
                        >
                          <span
                            class="material-symbols-outlined"
                            color="#BB271A"
                          >
                            filter_alt_off
                          </span>
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterPopper8}
                          style={{
                            color: "#666666",
                            padding: "5px",
                            size: "small",
                          }}
                        >
                          <span class="material-symbols-outlined">
                            filter_alt
                          </span>
                        </IconButton>
                      )
                    ) : headCell.id === "managerApprovedOn" ? (
                      filterOffToggle == "managerApprovedOn" ? (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterOff}
                          style={{ color: "#BB271A", padding: "5px" }}
                        >
                          <span
                            class="material-symbols-outlined"
                            color="#BB271A"
                          >
                            filter_alt_off
                          </span>
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterPopper12}
                          style={{
                            color: "#666666",
                            padding: "5px",
                            size: "small",
                          }}
                        >
                          <span class="material-symbols-outlined">
                            filter_alt
                          </span>
                        </IconButton>
                      )
                    ) : headCell.id === "currentStatus" ? (
                      filterOffToggle == "currentStatus" ? (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterOff}
                          style={{ color: "#BB271A", padding: "5px" }}
                        >
                          <span
                            class="material-symbols-outlined"
                            color="#BB271A"
                          >
                            filter_alt_off
                          </span>
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterPopper9}
                          style={{
                            color: "#666666",
                            padding: "5px",
                            size: "small",
                          }}
                        >
                          <span class="material-symbols-outlined">
                            filter_alt
                          </span>
                        </IconButton>
                      )
                    ) : headCell.id === "projectId" ? (
                      filterOffToggle == "projectId" ? (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterOff}
                          style={{ color: "#BB271A", padding: "5px" }}
                        >
                          <span
                            class="material-symbols-outlined"
                            color="#BB271A"
                          >
                            filter_alt_off
                          </span>
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="filter list"
                          onClick={handleFilterPopper10}
                          style={{
                            color: "#666666",
                            padding: "5px",
                            size: "small",
                          }}
                        >
                          <span class="material-symbols-outlined">
                            filter_alt
                          </span>
                        </IconButton>
                      )
                    ) : headCell.id === "setting" ? (
                      <SettingsIcon onClick={handleSettingPopper} />
                    ) : (
                      ""
                    )}
                  </Box>
                  {/* {console.log("headcell.id: ", headCell.id)} */}
                  {headCell.id === "expenseCode" ? (
                    <Box>
                      <Popover
                        id={id0}
                        open={open0}
                        onClose={handleClosePopper}
                        anchorEl={anchorEl0}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Paper
                          style={{
                            width: "300px",
                            height: "277px",
                          }}
                        >
                          <Box>
                            <Box
                              boxShadow={1}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "15px",
                              }}
                            >
                              <Box>
                                {/* <FilterListIcon /> */}
                                {/* <img src={Filterimg} className={classes.filterImg} /> */}
                                <span class="material-symbols-outlined">
                                  filter_alt
                                </span>
                              </Box>
                              <Box>
                                <Typography
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    lineHeight: "28px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                    color: "#0B1941",
                                    paddingRight: "70px",
                                  }}
                                >
                                  Expense ID
                                </Typography>
                              </Box>
                              <Box>
                                <Button>
                                  <CloseIcon onClick={handleClosePopper} />
                                </Button>
                              </Box>
                            </Box>
                            {/* <Box>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "24px",
                              letterSpacing: "0px",
                              textAlign: "left",
                              marginLeft: "25px",
                              marginTop: "16px",
                              color: "#4C4C4C",
                            }}
                          >
                            Expense ID
                          </Typography>
                        </Box> */}
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "24px",
                              }}
                            >
                              <TextField
                                id="outlined-basic"
                                label="Search the ID"
                                variant="outlined"
                                style={{
                                  width: "244px",
                                  height: "52px",
                                  marginLeft: "16px",
                                  // marginTop: "8px",
                                  color: "#0B1941",
                                }}
                                onChange={(e) => {
                                  if (e.target.value.length >= 1) {
                                    setButtonState(false);
                                  }
                                  if (e.target.value.length == 0) {
                                    setButtonState(true);
                                  }
                                  setCriteriaClaimAmount(e.target.value);
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      style={{ marginRight: "-12px" }}
                                    >
                                      <IconButton>
                                        <SearchIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                marginTop: "37px",
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={handleRemoveFilter}
                                style={{
                                  background: "#FFFFFF",
                                  color: "#0B1941",
                                  borderRadius: "4px",
                                  border: "1px solid #0B1941",
                                }}
                              >
                                Remove Filter
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                disabled={buttonState}
                                style={{
                                  background: buttonState
                                    ? "#9E9E9E"
                                    : "#0B1941",
                                  color: "#FFFFFF",
                                  borderRadius: "4px",
                                }}
                                onClick={() =>
                                  handleFilterClaimAmount(headCell.id)
                                }
                              >
                                Apply Filter
                              </Button>
                            </Box>
                          </Box>
                        </Paper>
                      </Popover>
                    </Box>
                  ) : (
                    ""
                  )}{" "}
                  {headCell.id === "purpose" ? (
                    <Box>
                      <Popover
                        id={id13}
                        open={open13}
                        onClose={handleClosePopper}
                        anchorEl={anchorEl13}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Paper
                          style={{
                            width: "300px",
                            height: "277px",
                          }}
                        >
                          <Box>
                            <Box
                              boxShadow={1}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "15px",
                              }}
                            >
                              <Box>
                                <span class="material-symbols-outlined">
                                  filter_alt
                                </span>
                              </Box>
                              <Box>
                                <Typography
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    lineHeight: "28px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                  }}
                                >
                                  Expense Purpose
                                </Typography>
                              </Box>
                              <Box>
                                <Button>
                                  <CloseIcon onClick={handleClosePopper} />
                                </Button>
                              </Box>
                            </Box>
                            {/* <Box>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "24px",
                              letterSpacing: "0px",
                              textAlign: "left",
                              marginLeft: "25px",
                              marginTop: "16px",
                            }}
                          >
                            Expense Category
                          </Typography>
                        </Box> */}
                            <Box style={{ display: "grid", marginTop: "24px" }}>
                              <FormControl
                                variant="outlined"
                                className={classes.formControl}
                              >
                                <InputLabel id="demo-simple-select-outlined-label">
                                  Search the Purpose
                                </InputLabel>
                                <Select
                                  style={{ width: "82%" }}
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  label="Expense Category"
                                  onChange={(e) => {
                                    if (e.target.value.length >= 1) {
                                      setButtonState(false);
                                    }
                                    if (e.target.value.length == 0) {
                                      setButtonState(true);
                                    }

                                    setCriteriaClaimAmount(e.target.value);
                                  }}
                                >
                                  {[
                                    ...new Map(
                                      getFinanceApprovalList?.map((item) => [
                                        item["purpose"],
                                        item,
                                      ])
                                    ).values(),
                                  ]
                                    ?.sort((a, b) =>
                                      a.purpose.localeCompare(b.purpose)
                                    )
                                    .map((cat) => {
                                      return (
                                        <MenuItem value={cat.purpose}>
                                          {cat.purpose}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                marginTop: "37px",
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={handleRemoveFilter}
                                style={{
                                  background: "#FFFFFF",
                                  color: "#0B1941",
                                  borderRadius: "4px",
                                  border: "1px solid #0B1941",
                                }}
                              >
                                Remove Filter
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                disabled={buttonState}
                                style={{
                                  background: buttonState
                                    ? "#9E9E9E"
                                    : "#0B1941",
                                  color: "#FFFFFF",
                                  borderRadius: "4px",
                                }}
                                onClick={() =>
                                  handleFilterClaimAmount(headCell.id)
                                }
                              >
                                Apply Filter
                              </Button>
                            </Box>
                          </Box>
                        </Paper>
                      </Popover>
                    </Box>
                  ) : (
                    ""
                  )}
                  {headCell.id === "expenseCategory" ? (
                    <Box>
                      <Popover
                        id={id1}
                        open={open1}
                        onClose={handleClosePopper}
                        anchorEl={anchorEl1}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Paper
                          style={{
                            width: "300px",
                            height: "277px",
                          }}
                        >
                          <Box>
                            <Box
                              boxShadow={1}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "15px",
                              }}
                            >
                              <Box>
                                <span class="material-symbols-outlined">
                                  filter_alt
                                </span>
                              </Box>
                              <Box>
                                <Typography
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    lineHeight: "28px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                  }}
                                >
                                  Expense Category
                                </Typography>
                              </Box>
                              <Box>
                                <Button>
                                  <CloseIcon onClick={handleClosePopper} />
                                </Button>
                              </Box>
                            </Box>
                            {/* <Box>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "24px",
                              letterSpacing: "0px",
                              textAlign: "left",
                              marginLeft: "25px",
                              marginTop: "16px",
                            }}
                          >
                            Expense Category
                          </Typography>
                        </Box> */}
                            <Box style={{ display: "grid", marginTop: "24px" }}>
                              <FormControl
                                variant="outlined"
                                className={classes.formControl}
                              >
                                <InputLabel id="demo-simple-select-outlined-label">
                                  Search the Category
                                </InputLabel>
                                <Select
                                  style={{ width: "82%" }}
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  label="Expense Category"
                                  onChange={(e) => {
                                    if (e.target.value.length >= 1) {
                                      setButtonState(false);
                                    }
                                    if (e.target.value.length == 0) {
                                      setButtonState(true);
                                    }

                                    setCriteriaClaimAmount(e.target.value);
                                  }}
                                >
                                  {[
                                    ...new Map(
                                      getFinanceApprovalList?.map((item) => [
                                        item["categoryName"],
                                        item,
                                      ])
                                    ).values(),
                                  ]
                                    ?.sort((a, b) =>
                                      a.categoryName.localeCompare(
                                        b.categoryName
                                      )
                                    )
                                    .map((cat) => {
                                      return (
                                        <MenuItem value={cat.categoryName}>
                                          {cat.categoryName}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                marginTop: "37px",
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={handleRemoveFilter}
                                style={{
                                  background: "#FFFFFF",
                                  color: "#0B1941",
                                  borderRadius: "4px",
                                  border: "1px solid #0B1941",
                                }}
                              >
                                Remove Filter
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                disabled={buttonState}
                                style={{
                                  background: buttonState
                                    ? "#9E9E9E"
                                    : "#0B1941",
                                  color: "#FFFFFF",
                                  borderRadius: "4px",
                                }}
                                onClick={() =>
                                  handleFilterClaimAmount(headCell.id)
                                }
                              >
                                Apply Filter
                              </Button>
                            </Box>
                          </Box>
                        </Paper>
                      </Popover>
                    </Box>
                  ) : (
                    ""
                  )}
                  {headCell.id === "createdBy" ? (
                    <Box>
                      <Popover
                        id={id2}
                        open={open2}
                        onClose={handleClosePopper}
                        anchorEl={anchorEl2}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Paper
                          style={{
                            width: "300px",
                            height: "277px",
                          }}
                        >
                          <Box>
                            <Box
                              boxShadow={1}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "15px",
                              }}
                            >
                              <Box>
                                {/* <FilterListIcon /> */}
                                {/* <img src={Filterimg} className={classes.filterImg} /> */}
                                <span class="material-symbols-outlined">
                                  filter_alt
                                </span>
                              </Box>
                              <Box>
                                <Typography
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    lineHeight: "28px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                    paddingRight: "70px",
                                  }}
                                >
                                  Employee
                                </Typography>
                              </Box>
                              <Box>
                                <Button onClick={handleClosePopper}>
                                  <CloseIcon />
                                </Button>
                              </Box>
                            </Box>
                            {/* <Box>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "24px",
                              letterSpacing: "0px",
                              textAlign: "left",
                              marginLeft: "25px",
                              marginTop: "16px",
                            }}
                          >
                            Employee
                          </Typography>
                        </Box> */}
                            {/* <Box style={{ display: "grid", marginTop: "24px" }}>
                              <FormControl
                                variant="outlined"
                                className={classes.formControl}
                              >
                                <InputLabel id="demo-simple-select-outlined-label">
                                  Employee
                                </InputLabel>
                                <Select
                                  style={{ width: "82%" }}
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  label="Employee"
                                  onChange={(e) => {
                                    if (e.target.value.length >= 1) {
                                      setButtonState(false);
                                    }
                                    if (e.target.value.length == 0) {
                                      setButtonState(true);
                                    }

                                    setCriteriaClaimAmount(e.target.value);
                                  }}
                                >
                                  {[
                                    ...new Map(
                                      getFinanceApprovalList?.map((item) => [
                                        item[headCell.id],
                                        item,
                                      ])
                                    ).values(),
                                  ]
                                    ?.sort((a, b) =>
                                      a.createdBy.localeCompare(b.createdBy)
                                    )
                                    .map((cat) => {
                                      return (
                                        <MenuItem
                                          key={cat.id}
                                          value={cat.createdBy}
                                        >
                                          {cat.createdBy}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                            </Box> */}
                             <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "24px",
                              }}
                            >
                              <TextField
                                id="outlined-basic"
                                label="Search Employee"
                                variant="outlined"
                                style={{
                                  width: "244px",
                                  height: "52px",
                                  marginLeft: "16px",
                                  // marginTop: "8px",
                                  color: "#0B1941",
                                }}
                                onChange={(e) => {
                                  if (e.target.value.length >= 1) {
                                    setButtonState(false);
                                  }
                                  if (e.target.value.length == 0) {
                                    setButtonState(true);
                                  }
                                  setCriteriaClaimAmount(e.target.value);
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      style={{ marginRight: "-12px" }}
                                    >
                                      <IconButton>
                                        <SearchIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                marginTop: "37px",
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={handleRemoveFilter}
                                style={{
                                  background: "#FFFFFF",
                                  color: "#0B1941",
                                  borderRadius: "4px",
                                  border: "1px solid #0B1941",
                                }}
                              >
                                Remove Filter
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                disabled={buttonState}
                                style={{
                                  background: buttonState
                                    ? "#9E9E9E"
                                    : "#0B1941",
                                  color: "#FFFFFF",
                                  borderRadius: "4px",
                                }}
                                onClick={() =>
                                  handleFilterClaimAmount(headCell.id)
                                }
                              >
                                Apply Filter
                              </Button>
                            </Box>
                          </Box>
                        </Paper>
                      </Popover>
                    </Box>
                  ) : (
                    ""
                  )}
                  {headCell.id === "claimAmount" ? (
                    <Box>
                      <Popover
                        id={id3}
                        open={open3}
                        onClose={handleClosePopper}
                        anchorEl={anchorEl3}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Paper
                          style={{
                            width: "300px",
                            height: "277px",
                          }}
                        >
                          <Box>
                            <Box
                              boxShadow={1}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "15px",
                              }}
                            >
                              <Box>
                                {/* <FilterListIcon /> */}
                                {/* <img src={Filterimg} className={classes.filterImg} /> */}
                                <span class="material-symbols-outlined">
                                  filter_alt
                                </span>
                              </Box>
                              <Box>
                                <Typography
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    lineHeight: "28px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                    paddingRight: "42px",
                                  }}
                                >
                                  Claim Amount
                                </Typography>
                              </Box>
                              <Box>
                                <Button>
                                  <CloseIcon onClick={handleClosePopper} />
                                </Button>
                              </Box>
                            </Box>
                            {/* <Box>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "24px",
                              letterSpacing: "0px",
                              textAlign: "left",
                              marginLeft: "25px",
                              marginTop: "16px",
                            }}
                          >
                            Claim Amount
                          </Typography>
                        </Box> */}
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "24px",
                              }}
                            >
                              <FormControl
                                variant="outlined"
                                className={classes.formControlAmount}
                              >
                                <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  options={options}
                                  value={selectedOperatorClaimAmount}
                                  onChange={(e) => {
                                    setselectedOperatorClaimAmount(
                                      e.target.value
                                    );
                                  }}
                                >
                                  <MenuItem
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "500",
                                    }}
                                    value={"gteq"}
                                  >
                                    {">="}
                                  </MenuItem>
                                  <MenuItem
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "500",
                                    }}
                                    value={"lteq"}
                                  >
                                    {" "}
                                    {"<="}{" "}
                                  </MenuItem>
                                </Select>
                              </FormControl>
                              <NumericFormat
                                id="outlined-basic"
                                label="Enter Amount"
                                variant="outlined"
                                onKeyPress={preventMinus}
                                customInput={TextField}
                                style={{
                                  width: "153px",
                                  height: "52px",
                                  marginLeft: "16px",

                                  color: "#0B1941",
                                }}
                                onChange={(e) => {
                                  if (e.target.value.length >= 1) {
                                    setButtonState(false);
                                  }
                                  if (e.target.value.length == 0) {
                                    setButtonState(true);
                                  }

                                  setCriteriaClaimAmount(e.target.value);
                                }}
                              />
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                marginTop: "37px",
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={handleRemoveFilter}
                                style={{
                                  background: "#FFFFFF",
                                  color: "#0B1941",
                                  borderRadius: "4px",
                                  border: "1px solid #0B1941",
                                }}
                              >
                                Remove Filter
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                disabled={buttonState}
                                style={{
                                  background: buttonState
                                    ? "#9E9E9E"
                                    : "#0B1941",
                                  color: "#FFFFFF",
                                  borderRadius: "4px",
                                }}
                                onClick={() =>
                                  handleFilterClaimAmount(headCell.id)
                                }
                              >
                                Apply Filter
                              </Button>
                            </Box>
                          </Box>
                        </Paper>
                      </Popover>
                    </Box>
                  ) : (
                    ""
                  )}
                  {headCell.id === "createdOn" ? (
                    <Box>
                      <Popover
                        id={id8}
                        open={open8}
                        onClose={handleClosePopper}
                        anchorEl={anchorEl8}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Paper
                          style={{
                            width: "300px",
                            height: "334px",
                          }}
                        >
                          <Box>
                            <Box
                              boxShadow={1}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "15px",
                              }}
                            >
                              <Box>
                                {/* <FilterListIcon /> */}
                                {/* <img src={Filterimg} className={classes.filterImg} /> */}
                                <span class="material-symbols-outlined">
                                  filter_alt
                                </span>
                              </Box>
                              <Box>
                                <Typography
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    lineHeight: "28px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                  }}
                                >
                                  Creation Date
                                </Typography>
                              </Box>
                              <Box>
                                <Button>
                                  <CloseIcon onClick={handleClosePopper} />
                                </Button>
                              </Box>
                            </Box>
                            {/* <Box>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "24px",
                              letterSpacing: "0px",
                              textAlign: "left",
                              marginLeft: "25px",
                              marginTop: "16px",
                            }}
                          >
                            Creation Date
                          </Typography>
                        </Box> */}

                            <Box style={{ display: "grid", marginTop: "24px" }}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  disableToolbar
                                  inputVariant="outlined"
                                  style={{
                                    maxWidth: "251px",
                                    maxHeight: "53px",
                                  }}
                                  className={classes.formControl}
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  autoOk={true}
                                  changeYear={true}
                                  changeMonth={true}
                                  disableFuture={true}
                                  id="date-picker-inline"
                                  label="Choose Start Date"
                                  value={startDate}
                                  onChange={handleStartDateChange}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </Box>
                            <Box>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  disableToolbar
                                  inputVariant="outlined"
                                  style={{
                                    maxWidth: "251px",
                                    maxHeight: "53px",
                                  }}
                                  className={classes.formControl}
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  autoOk={true}
                                  changeYear={true}
                                  disableFuture={true}
                                  changeMonth={true}
                                  id="date-picker-inline"
                                  label="Choose End Date"
                                  value={endDate}
                                  minDate={startDate}
                                  onChange={handleEndDateChange}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                marginTop: "37px",
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={handleRemoveFilter}
                                style={{
                                  background: "#FFFFFF",
                                  color: "#0B1941",
                                  borderRadius: "4px",
                                  border: "1px solid #0B1941",
                                }}
                              >
                                Remove Filter
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                disabled={buttonState}
                                style={{
                                  background: buttonState
                                    ? "#9E9E9E"
                                    : "#0B1941",
                                  color: "#FFFFFF",
                                  borderRadius: "4px",
                                }}
                                onClick={() =>
                                  handleFilterClaimAmount(headCell.id)
                                }
                              >
                                Apply Filter
                              </Button>
                            </Box>
                          </Box>
                        </Paper>
                      </Popover>
                    </Box>
                  ) : (
                    ""
                  )}
                  {headCell.id === "managerApprovedOn" ? (
                    <Box>
                      <Popover
                        id={id12}
                        open={open12}
                        onClose={handleClosePopper}
                        anchorEl={anchorEl12}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Paper
                          style={{
                            width: "300px",
                            height: "334px",
                          }}
                        >
                          <Box>
                            <Box
                              boxShadow={1}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "15px",
                              }}
                            >
                              <Box>
                                {/* <FilterListIcon /> */}
                                {/* <img src={Filterimg} className={classes.filterImg} /> */}
                                <span class="material-symbols-outlined">
                                  filter_alt
                                </span>
                              </Box>
                              <Box>
                                <Typography
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    lineHeight: "28px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                  }}
                                >
                                  Manager Approval Date
                                </Typography>
                              </Box>
                              <Box>
                                <Button>
                                  <CloseIcon onClick={handleClosePopper} />
                                </Button>
                              </Box>
                            </Box>
                            {/* <Box>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "24px",
                              letterSpacing: "0px",
                              textAlign: "left",
                              marginLeft: "25px",
                              marginTop: "16px",
                            }}
                          >
                            Creation Date
                          </Typography>
                        </Box> */}

                            <Box style={{ display: "grid", marginTop: "24px" }}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  disableToolbar
                                  inputVariant="outlined"
                                  style={{
                                    maxWidth: "251px",
                                    maxHeight: "53px",
                                  }}
                                  className={classes.formControl}
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  autoOk={true}
                                  changeYear={true}
                                  changeMonth={true}
                                  id="date-picker-inline"
                                  label="Choose Start Date"
                                  value={startDate}
                                  disableFuture={true}
                                  onChange={handleStartDateChange}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </Box>
                            <Box>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  disableToolbar
                                  inputVariant="outlined"
                                  style={{
                                    maxWidth: "251px",
                                    maxHeight: "53px",
                                  }}
                                  className={classes.formControl}
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  autoOk={true}
                                  changeYear={true}
                                  changeMonth={true}
                                  id="date-picker-inline"
                                  label="Choose End Date"
                                  disableFuture={true}
                                  value={endDate}
                                  minDate={startDate}
                                  onChange={handleEndDateChange}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                marginTop: "37px",
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={handleRemoveFilter}
                                style={{
                                  background: "#FFFFFF",
                                  color: "#0B1941",
                                  borderRadius: "4px",
                                  border: "1px solid #0B1941",
                                }}
                              >
                                Remove Filter
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                disabled={buttonState}
                                style={{
                                  background: buttonState
                                    ? "#9E9E9E"
                                    : "#0B1941",
                                  color: "#FFFFFF",
                                  borderRadius: "4px",
                                }}
                                onClick={() =>
                                  handleFilterClaimAmount(headCell.id)
                                }
                              >
                                Apply Filter
                              </Button>
                            </Box>
                          </Box>
                        </Paper>
                      </Popover>
                    </Box>
                  ) : (
                    ""
                  )}
                  {headCell.id === "currentStatus" ? (
                    <Box>
                      <Popover
                        id={id9}
                        open={open9}
                        onClose={handleClosePopper}
                        anchorEl={anchorEl9}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Paper
                          style={{
                            width: "300px",
                            height: "277px",
                          }}
                        >
                          <Box>
                            <Box
                              boxShadow={1}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "15px",
                              }}
                            >
                              <Box>
                                <span class="material-symbols-outlined">
                                  filter_alt
                                </span>
                              </Box>
                              <Box>
                                <Typography
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    lineHeight: "28px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                    color: "#0B1941",
                                    paddingRight: "65px",
                                  }}
                                >
                                  Current Status
                                </Typography>
                              </Box>
                              <Box>
                                <Button>
                                  <CloseIcon onClick={handleClosePopper} />
                                </Button>
                              </Box>
                            </Box>
                            {/* <Box>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "24px",
                              letterSpacing: "0px",
                              textAlign: "left",
                              marginLeft: "25px",
                              marginTop: "16px",
                              color: "#4C4C4C",
                            }}
                          >
                            Current Status
                          </Typography>
                        </Box> */}
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "24px",
                              }}
                            >
                              <FormControl
                                variant="outlined"
                                className={classes.formControl}
                              >
                                <InputLabel id="demo-simple-select-outlined-label">
                                  Search Status
                                </InputLabel>
                                <Select
                                  style={{ width: "97%" }}
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  label="Expense Category"
                                  onChange={(e) => {
                                    if (e.target.value.length >= 1) {
                                      setButtonState(false);
                                    }
                                    if (e.target.value.length == 0) {
                                      setButtonState(true);
                                    }

                                    setCriteriaClaimAmount(e.target.value);
                                  }}
                                >
                                  {[
                                    ...new Map(
                                      getFinanceApprovalList?.map((item) => [
                                        item["status"],
                                        item,
                                      ])
                                    ).values(),
                                  ]
                                    ?.sort((a, b) =>
                                      a.status.localeCompare(b.status)
                                    )
                                    .map((cat) => {
                                      return (
                                        <MenuItem value={cat.status}>
                                          {cat.status}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                marginTop: "37px",
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={handleRemoveFilter}
                                style={{
                                  background: "#FFFFFF",
                                  color: "#0B1941",
                                  borderRadius: "4px",
                                  border: "1px solid #0B1941",
                                }}
                              >
                                Remove Filter
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                disabled={buttonState}
                                style={{
                                  background: buttonState
                                    ? "#9E9E9E"
                                    : "#0B1941",
                                  color: "#FFFFFF",
                                  borderRadius: "4px",
                                }}
                                onClick={() =>
                                  handleFilterClaimAmount(headCell.id)
                                }
                              >
                                Apply Filter
                              </Button>
                            </Box>
                          </Box>
                        </Paper>
                      </Popover>
                    </Box>
                  ) : (
                    ""
                  )}
                  {headCell.id === "projectId" ? (
                    <Box>
                      <Popover
                        id={id10}
                        open={open10}
                        onClose={handleClosePopper}
                        anchorEl={anchorEl10}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Paper
                          style={{
                            width: "300px",
                            height: "277px",
                          }}
                        >
                          <Box>
                            <Box
                              boxShadow={1}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "15px",
                              }}
                            >
                              <Box>
                                {/* <FilterListIcon /> */}
                                {/* <img src={Filterimg} className={classes.filterImg} /> */}
                                <span class="material-symbols-outlined">
                                  filter_alt
                                </span>
                              </Box>
                              <Box>
                                <Typography
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    lineHeight: "28px",
                                    letterSpacing: "0px",
                                    textAlign: "left",
                                    color: "#0B1941",
                                    paddingRight: "60px",
                                  }}
                                >
                                  Project Code
                                </Typography>
                              </Box>
                              <Box>
                                <Button>
                                  <CloseIcon onClick={handleClosePopper} />
                                </Button>
                              </Box>
                            </Box>
                            {/* <Box>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "24px",
                              letterSpacing: "0px",
                              textAlign: "left",
                              marginLeft: "25px",
                              marginTop: "16px",
                              color: "#4C4C4C",
                            }}
                          >
                            Project Code
                          </Typography>
                        </Box> */}
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "24px",
                              }}
                            >
                              <TextField
                                id="outlined-basic"
                                label="Project Code"
                                variant="outlined"
                                style={{
                                  width: "244px",
                                  height: "52px",
                                  marginLeft: "16px",
                                  // marginTop: "8px",
                                  color: "#0B1941",
                                }}
                                onChange={(e) => {
                                  if (e.target.value.length >= 1) {
                                    setButtonState(false);
                                  }
                                  if (e.target.value.length == 0) {
                                    setButtonState(true);
                                  }

                                  setCriteriaClaimAmount(e.target.value);
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      style={{ marginRight: "-12px" }}
                                    >
                                      <IconButton>
                                        <SearchIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                marginTop: "37px",
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={handleRemoveFilter}
                                style={{
                                  background: "#FFFFFF",
                                  color: "#0B1941",
                                  borderRadius: "4px",
                                  border: "1px solid #0B1941",
                                }}
                              >
                                Remove Filter
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                disabled={buttonState}
                                style={{
                                  background: buttonState
                                    ? "#9E9E9E"
                                    : "#0B1941",
                                  color: "#FFFFFF",
                                  borderRadius: "4px",
                                }}
                                onClick={() =>
                                  handleFilterClaimAmount(headCell.id)
                                }
                              >
                                Apply Filter
                              </Button>
                            </Box>
                          </Box>
                        </Paper>
                      </Popover>
                    </Box>
                  ) : (
                    ""
                  )}
                  {headCell.id === "setting" ? (
                    <Box style={{ borderRadius: "10px" }}>
                      <Popover
                        id={id6}
                        open={open6}
                        style={{ borderRadius: "20px", marginLeft: "-62px" }}
                        onClose={handleClosePopper}
                        anchorEl={anchorEl6}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Paper className={classes.settingsPaper}>
                          <Box>
                            <FormGroup>
                              <FormControlLabel
                                style={{ paddingLeft: "10px" }}
                                control={
                                  <CustomSwitch
                                    checked={teamAppAttachments}
                                    onChange={(e) => {
                                      setTeamAppAttachments(e.target.checked);
                                      handleAddColumn(
                                        "currentStatus",
                                        e.target.checked
                                      );
                                    }}
                                  />
                                }
                                label="Current Status"
                              />
                              <FormControlLabel
                                style={{ paddingLeft: "10px" }}
                                className={classes.switchRoot}
                                control={
                                  <CustomSwitch
                                    checked={teamAppProjectId}
                                    onChange={(e) => {
                                      setTeamAppProjectId(e.target.checked);
                                      handleAddColumn(
                                        "projectId",
                                        e.target.checked
                                      );
                                    }}
                                  />
                                }
                                label="Project Code"
                              />
                            </FormGroup>
                          </Box>
                        </Paper>
                      </Popover>
                    </Box>
                  ) : (
                    ""
                  )}
                </TableCell>
              ))}
            </TableRow>
          ) : (
            <TableRow style={{ backgroundColor: "#F4F4F4" }}>
              {headCellValue?.map((headCell) => (
                <TableCell
                  align="left"
                  style={{
                    padding: "0.4%",
                    paddingLeft: "5px",
                    fontSize: "10px", //siddhant
                    fontWeight: "600", //siddhant
                    lineHeight: "11px",
                  }}
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          )}
        </>
      )}
    </TableHead>
  );
}

if (window.screen.availWidth > 600) {
  AllExpenseTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    onClickFilter: PropTypes.func.isRequired,
    handleSettingsParent: PropTypes.func.isRequired,
  };
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const AllExpenseTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;
  //console.log("number selected", numSelected);

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          All Expenses
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

AllExpenseTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
  },
  rootFooter: {
    flexShrink: 0,

    marginLeft: theme.spacing(2.5),
    marginRight: "15px",
  },
  rootFooter: {
    flexShrink: 0,

    marginLeft: theme.spacing(2.5),
    marginRight: "15px",
  },
  rootFooter: {
    flexShrink: 0,

    marginLeft: theme.spacing(2.5),
    marginRight: "15px",
  },
  tableContainer: {
    //maxWidth: "1117px",
    minHeight: "100vh",
    borderRadius: "8px",
    "&.MuiTableCell-root": {
      padding: "0px",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
  },
  cell: {
    width: "120px",
  },
  settingsPaper: {
    width: "188px",
    height: "84px",
    border: "1px solid #B7B7B7",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.03)",
  },
  // formControl: {
  //   margin: theme.spacing(1),
  //   minWidth: "244px",
  //   height: "52px",
  //   marginLeft: "24px",
  //   marginTop: "8px",
  // },
  // formControl: {
  //   width: "341px",
  //   height: "70px",
  //   marginLeft: "10px",
  // },
  // formControl12: {
  //   width: "341px",
  //   height: "70px",
  //   marginLeft: "10px",
  // },
  // formControl1: {
  //   // width: "120%",
  //   // height: "70px",
  //   // marginLeft: "9px",
  //   width: "112%",
  //   height: "70px",
  //   marginLeft: "9px",
  // },
  // formControl2: {
  //   width: "100%",
  //   height: "70px",
  //   marginLeft: "9px",
  // },
  // formControl3: {
  //   width: "100%",
  //   height: "70px",
  //   marginLeft: "9px",
  //   marginRight: "-11px",
  // },
  formControl: {
    width: "341px",
    height: "70px",
    marginLeft: "10px",
    "& .MuiFormLabel-root": {
      //paddingLeft: "11px"
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#515961",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-input": {
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
  },
  formControl12: {
    width: "341px",
    height: "70px",
    marginLeft: "10px",
  },
  formControl1: {
    // width: "120%",
    // height: "70px",
    // marginLeft: "9px",
    width: "112%",
    ["@media (max-width:600px)"]: { width: "100%" },
    height: "70px",
    marginLeft: "9px",
    "& .MuiFormLabel-root": {
      //paddingLeft: "11px"
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#515961",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-input": {
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
  },
  formControl2: {
    width: "100%",
    height: "70px",
    marginLeft: "9px",
    "& .MuiFormLabel-root": {
      //paddingLeft: "11px"
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#515961",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-input": {
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
  },
  formControl3: {
    width: "100%",
    height: "70px",
    marginLeft: "9px",
    marginRight: "-11px",
    "& .MuiFormLabel-root": {
      //paddingLeft: "11px"
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#515961",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    "& .MuiOutlinedInput-input": {
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
  },
  formControlAmount: {
    margin: theme.spacing(1),
    minWidth: "75px",
    height: "52px",
    marginLeft: "24px",
    marginTop: "8px",
  },
  paper: {
    //width: "1117px",
    border: "1px solid #979797",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
  },
  paperZ150: {
    width: "1320px",
    border: "1px solid #979797",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
  },
  paperZ125: {
    width: "1443px",
    border: "1px solid #979797",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  paperZ100: {
    width: "1855px",
    border: "1px solid #979797",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  table: {
    //maxWidth: "1117px",
    "&.MuiTableCell-root": {
      padding: "0px",
      ["@media (max-width:600px)"]: { fontSize: "13px" },
    },
    filterImg: {
      width: "24px",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  inReviewStatus: {
    backgroundColor: "#E0B15C",
    color: "white",
    width: "110px",
    height: "24px",
  },
  approvedStatus: {
    backgroundColor: "#349259",
    color: "white",
    width: "110px",
    height: "24px",
  },
  rejectedStatus: {
    backgroundColor: "#D40606",
    color: "white",
    width: "110px",
    height: "24px",
  },
  checkBoxRoot: {
    background: "#0B1941",
    color: "white",
    "&$checked": {
      background: "#0B1941",
      color: "white",
    },
  },
  switchRoot: {
    ".MuiSwitch-colorSecondary.Mui-checked": {
      color: "#0B1941",
    },
  },
  errorRoot: {
    color: "red",
    marginLeft: "7px",
    marginRight: "0px",
    marginTop: 0,
    fontSize: "10px",
    lineHeight: "11px",
    ["@media (max-width:600px)"]: { fontSize: "10px" },
  },
  tableRow: {
    height: 58,
    "&.MuiTableRow-root.MuiTableRow-hover:hover": {
      backgroundColor: "#EFF2F8",
    },
    "&.MuiTableRow-root.Mui-selected": {
      background: "#EFF2F8",
    },
    "& .MuiTableCell-root": {
      ["@media (max-width:600px)"]: { fontSize: "10px" },
    },
  },
  stickyRight: {
    position: "sticky",
    right: 0,
    background: "white",
    zIndex: 800,
  },
  stickyLeft: {
    position: "sticky",
    left: 0,
    background: "white",
    zIndex: 800,
  },
  nonSticky: {
    position: "sticky",
    left: 0,
    background: "#F4F4F4",
    zIndex: 800,
  },
}));

export default function AllExpenseTable(props) {
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: 13,
      top: 31,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filteredRowsData, setFilteredRowsData] = React.useState([]);
  const [teamAppAttachments, setTeamAppAttachments] = React.useState(false);
  const [teamAppProjectId, setTeamAppProjectId] = React.useState(false);
  const [singleDownload, setSingleDownload] = useState([]);
  const [bulkDownload, setBulkDownload] = useState([]);
  const [empId, setEmpId] = React.useState(null);
  const [comments, setComments] = React.useState("");
  const [expStatus, setExpStatus] = React.useState("Approved");
  const [openBulkApproveDialog, setOpenBulkApproveDialog] = useState(false);
  const [singleExpense, setSingleExpense] = useState("");
  const [approveOrReject, setApproveOrReject] = useState(1);
  const [currentIndex, setCurrentIndex] = React.useState();
  const [refreshTableToggle, forceUpdate] = useReducer((x) => x + 1, 0);
  const [open, setOpen] = React.useState(false);
  const [commentData, setCommentData] = React.useState([]);
  const [commentHeading, setCommentHeading] = React.useState("");
  const [toolAction, setToolAction] = React.useState("");
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [employeeNamefilter, setEmployeeNamefilter] = useState("");
  const [expenseCategoryfilter, setExpenseCategoryfilter] = useState("");
  const [endDatefilter, setEndDatefilter] = useState(null);
  const [startDatefilter, setStartDatefilter] = useState(null);
  const [minClaimAmountfilter, setMinClaimAmountfilter] = useState("");
  const [maxClaimAmountfilter, setMaxClaimAmountfilter] = useState("");
  const [anchorFilter1, setAnchorFilter1] = useState("right");
  const [buttonState, setButtonState] = React.useState(true);
  const [filterShow, setFilterShow] = React.useState(false);
  const [countShow, setCountShow] = React.useState(0);
  const [startDateMsg, setStartDateMsg] = useState("");
  const [endDateMsg, setEndDateMsg] = useState("");
  const [minClaimAmountMsg, setMinClaimAmountMsg] = useState("");
  const [maxClaimAmountMsg, setMaxClaimAmountMsg] = useState("");
  const preventMinus = (e) => {
    if (e.code === "Minus" || e.key === "-") {
      e.preventDefault();
    }
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const handleFieldValue = () => {
    if (
      startDatefilter === null &&
      endDatefilter === null &&
      expenseCategoryfilter === "" &&
      employeeNamefilter === "" &&
      minClaimAmountfilter === "" &&
      maxClaimAmountfilter === ""
    ) {
      return true;
    } else return false;
  };

  // const handleEndDateChange = (type, date) => {
  //   setEndDatefilter(date);
  //   if (date === null) {
  //     setEndDateMsg("Please select a start date");
  //   } else {
  //     const dateFormatRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Format: MM/dd/yyyy

  //     if (!dateFormatRegex.test(date)) {
  //       setEndDateMsg("Invalid date format.");
  //     } else {
  //       const parsedDate = new Date(date);
  //       if (isNaN(parsedDate.getTime())) {
  //         setEndDateMsg("Invalid date. Please enter a valid date.");
  //       } else {
  //         setEndDateMsg(""); // Clear the error message if the date is valid
  //       }
  //     }
  //   }
  //   if (startDatefilter && date) {
  //     setButtonState(false);
  //   } else {
  //     setButtonState(true);
  //   }
  // };

  const handleEndDateChange = (type, date) => {
    setEndDatefilter(date);

    // if (date === null) {
    //   setEndDateMsg("Please select a start date");
    // } else {

    const dateFormatRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Format: MM/dd/yyyy
    const parsedDate = new Date(date);

    if (!dateFormatRegex.test(date)) {
      setEndDateMsg("Invalid date format.");
    } else if (isNaN(parsedDate.getTime())) {
      setEndDateMsg("Invalid date. Please enter a valid date.");
    } else if (date) {
      setEndDateMsg("");
      if (startDatefilter && startDateMsg.length == 0) {
        setEndDateMsg(""); // Clear the error message if the date is valid
        setStartDateMsg("");
        if (maxClaimAmountMsg.length == 0) {
          setButtonState(false);
        } else {
          setButtonState(true);
        }
      }
    }
    // else {
    // }
    //   }
    // }
    // }
  };
  // const handleStartDateChange = (type, date) => {
  //   setStartDatefilter(date);
  //   if (date === null) {
  //     setStartDateMsg("Please select a start date");
  //   } else {
  //     const dateFormatRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Format: MM/dd/yyyy

  //     if (!dateFormatRegex.test(date)) {
  //       setStartDateMsg("Invalid date format.");
  //     } else {
  //       const parsedDate = new Date(date);
  //       if (isNaN(parsedDate.getTime())) {
  //         setStartDateMsg("Invalid date. Please enter a valid date.");
  //       } else {
  //         setStartDateMsg(""); // Clear the error message if the date is valid
  //       }
  //     }
  //   }
  //   if (endDatefilter && date) {
  //     setButtonState(false);
  //   } else {
  //     setButtonState(true);
  //   }
  // };
  const handleStartDateChange = (type, date) => {
    setStartDatefilter(date);

    const dateFormatRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Format: MM/dd/yyyy

    const parsedDate = new Date(date);

    if (!dateFormatRegex.test(date)) {
      setStartDateMsg("Invalid date format.");
    } else if (isNaN(parsedDate.getTime())) {
      setStartDateMsg("Invalid date. Please enter a valid date.");
    } else if (date) {
      setStartDateMsg("");
      if (endDatefilter && endDateMsg.length == 0) {
        setStartDateMsg(""); // Clear the error message if the date is valid
        setEndDateMsg(""); // Clear the error message if the date is valid
        if (maxClaimAmountMsg.length == 0) setButtonState(false);
        else setButtonState(true);
      }
    }
    //  else {
    // }
    // }
    // }
    // }
    // if (endDatefilter && date) {
    //   setButtonState(false);
    // } else {
    //   setButtonState(true);
    // }
    // if (
    //   startDateMsg.length > 0 ||
    //   endDateMsg.length > 0 ||
    //   maxApprovedAmountMsg.length > 0 ||
    //   maxClaimAmountMsg.length > 0
    // ) {
    //   setButtonState(true);
    // } else {
    //   setButtonState(false);
    // }
  };

  const handleCategory = (type, data2) => {
    setExpenseCategoryfilter(data2);
    if (
      // (startDatefilter && endDatefilter) ||
      // (startDatefilter == null && endDatefilter == null)
      startDateMsg.length > 0 ||
      endDateMsg.length > 0 ||
      maxClaimAmountMsg.length > 0
    ) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  };
  const handleEmployeeName = (type, data3) => {
    setEmployeeNamefilter(data3);
    if (
      // (startDatefilter && endDatefilter) ||
      // (startDatefilter == null && endDatefilter == null)
      startDateMsg.length > 0 ||
      endDateMsg.length > 0 ||
      maxClaimAmountMsg.length > 0
    ) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  };

  const handleMinClaimAmount = (type, data3) => {
    const trimmedInput = data3.trimStart();
    setMinClaimAmountfilter(trimmedInput);
    // if (
    //   (startDatefilter && endDatefilter) ||
    //   (startDatefilter == null && endDatefilter == null)
    // ) {
    //   setButtonState(false);
    // } else {
    //   setButtonState(true);
    // }
    if (
      // (startDatefilter && endDatefilter) ||
      // (startDatefilter == null && endDatefilter == null)
      startDateMsg.length > 0 ||
      endDateMsg.length > 0 ||
      maxClaimAmountMsg.length > 0
    ) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  };
  const handleMaxClaimAmount = (type, data3) => {
    const trimmedInput = data3.trimStart();
    setMaxClaimAmountfilter(trimmedInput);
    // if (
    //   (startDatefilter && endDatefilter) ||
    //   (startDatefilter == null && endDatefilter == null)
    // ) {
    //   setButtonState(false);
    // } else {
    //   setButtonState(true);
    // }
    if (
      // (startDatefilter && endDatefilter) ||
      // (startDatefilter == null && endDatefilter == null)
      startDateMsg.length > 0 ||
      endDateMsg.length > 0 ||
      maxClaimAmountMsg.length > 0
    ) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  };

  const handleRemoveFilter1 = () => {
    //console.log("RESET", props.fullData);
    setFilteredRowsData(props.fullData);
    handleFilteredArray(props.fullData);
    // setState({
    //   ...state,
    //   bottom: false,
    //   top: false,
    //   right: false,
    //   left: false,
    // });
    setEmployeeNamefilter("");
    setExpenseCategoryfilter("");
    setStartDatefilter(null);
    setEndDatefilter(null);
    setMaxClaimAmountfilter("");
    setMinClaimAmountfilter("");
    setFilterShow(false);
    setCountShow(0);
    setStartDateMsg("");
    setEndDateMsg("");
    setMinClaimAmountMsg("");
    setMaxClaimAmountMsg("");
  };
  const handleApplyFilter = () => {
    var countVal1 = 0,
      countVal2 = 0,
      countVal3 = 0,
      countVal4 = 0,
      countVal5 = 0;
    let tempArray = JSON.parse(JSON.stringify(filteredRowsData));
    if (employeeNamefilter != "") {
      tempArray = tempArray.filter((data) =>
        data?.createdBy?.includes(employeeNamefilter)
      );
      countVal1 = 1;
    }
    if (expenseCategoryfilter != "") {
      tempArray = tempArray.filter((data) =>
        data?.categoryName?.includes(expenseCategoryfilter)
      );
      countVal2 = 1;
    }
    if (startDatefilter != null && endDatefilter != null) {
      // console.log(tempArray)
      tempArray = tempArray.filter(
        (data) =>
          data.managerApprovedOn >= startDatefilter &&
          data.managerApprovedOn <= endDatefilter
      );
      countVal3 = 1;
    }
    if (minClaimAmountfilter != "") {
      tempArray = tempArray.filter(
        (data) => data.claimedAmount >= minClaimAmountfilter
      );
      countVal4 = 1;
    }
    if (maxClaimAmountfilter != "") {
      tempArray = tempArray.filter(
        (data) => data.claimedAmount <= maxClaimAmountfilter
      );
      countVal5 = 1;
    }
    let tempvalcount =
      countVal1 + countVal2 + countVal3 + countVal4 + countVal5;
    setCountShow(tempvalcount);
    setFilterShow(true);
    setFilteredRowsData(tempArray);
    setPage(0);
    setButtonState(true);
    setState({
      ...state,
      bottom: false,
      top: false,
      right: false,
      left: false,
    });
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <ArrowBackIosIcon
        onClick={toggleDrawer(anchorFilter1, false)}
        style={{ paddingLeft: "12px", marginTop: "19px" }}
      />
      <span
        style={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "18px",
            marginTop: "-25px",
            marginLeft: "-224px",
          }}
        >
          Filter
        </Typography>
      </span>
      <Divider style={{ marginTop: matches ? "4px" : "" }} />
      <br />
      <Typography
        style={{
          paddingLeft: "11px",
          fontSize: "14px",
          fontWeight: 600,
          fontFamily: "Montserrat",
          lineHeight: "24px",
          color: "#353535",
          marginTop: matches ? "-10px" : "",
        }}
      >
        By Expense Information
      </Typography>

      <List>
        {/* <ListItemIcon> */}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          style={{
            maxWidth: matches ? "101.66%" : "",
            //marginBottom: matches ? "8px" : "",
          }}
        >
          {/* <Grid item xs={11} sm={11} md={11}> */}
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{
              width: "90.57%",
            }}
          >
            <InputLabel id="demo-simple-select-label">
              Select the Category
            </InputLabel>
            <Select
              value={expenseCategoryfilter}
              labelId="demo-simple-select-label"
              autosize={true}
              id="demo-simple-select"
              label="Select the Category"
              onChange={(e) => {
                handleCategory("", e.target.value);
              }}
            >
              {[
                ...new Map(
                  props.fullData?.map((item) => [item["categoryName"], item])
                ).values(),
              ]
                .sort((a, b) => a.categoryName.localeCompare(b.categoryName))
                .map((value) => {
                  return (
                    <MenuItem value={value.categoryName}>
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "13px",
                          fontWeight: 500,
                          // lineHeight: "24px",
                          letterSpacing: "-1px",
                          textAlign: "left",
                        }}
                      >
                        {value.categoryName}
                      </Typography>
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          {/* </Grid> */}
        </Grid>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{
              maxWidth: matches ? "101.66%" : "",
              marginBottom: matches ? "8px" : "",
            }}
          >
            <FormControl
              variant="outlined"
              className={classes.formControl}
              // style={{
              //   maxWidth: matches ? "91.66%" : "",
              //   //marginBottom: matches ? "8px" : "",
              // }}
              style={{
                width: "90.57%",
              }}
            >
              <InputLabel id="demo-simple-select-label">
                Search Employee Name or ID
              </InputLabel>
              <Select
                value={employeeNamefilter}
                labelId="demo-simple-select-label"
                autosize={true}
                id="demo-simple-select"
                label="Search Employee Name or ID"
                onChange={(e) => {
                  handleEmployeeName("", e.target.value);
                }}
              >
                {[
                  ...new Map(
                    props.fullData?.map((item) => [item["createdBy"], item])
                  ).values(),
                ]
                  .sort((a, b) => a.createdBy.localeCompare(b.createdBy))
                  .map((value) => {
                    return (
                      <MenuItem value={value.createdBy}>
                        <Typography
                          style={{
                            fontFamily: "Montserrat",
                            fontSize: "13px",
                            fontWeight: 500,
                            // lineHeight: "24px",
                            letterSpacing: "-1px",
                            textAlign: "left",
                          }}
                        >
                          {value.createdBy}
                        </Typography>
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {/* </ListItemIcon> */}

        {/* </Grid> */}
        {/* </ListItemIcon> */}

        <Divider style={{ marginTop: matches ? "-15px" : "" }} />
        <br />
        <Typography
          style={{
            paddingLeft: "11px",
            //marginTop: "-15px",
            fontSize: "14px",
            fontWeight: 600,
            fontFamily: "Montserrat",
            lineHeight: "24px",
            color: "#353535",
            marginBottom: matches ? "-14px" : "",
            marginTop: matches ? "-11px" : "",
          }}
        >
          By Manager Approval Date
        </Typography>
        <Grid
          container
          spacing={2}
          style={{
            padding: matches ? "8px" : "",
            paddingRight: matches ? "35px" : "",
            justifyContent: matches ? "space-between" : "", //siddhant
          }}
        >
          <Grid
            item
            xs={6} //siddhant
            sm={5}
            md={5}
            style={{
              /*siddhant */ paddingLeft: matches ? "0px" : "",
              paddingRight: matches ? "12px" : "",
            }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                fullWidth
                className={classes.formControl1}
                variant="inline"
                format="MM/dd/yyyy"
                changeYear={true}
                changeMonth={true}
                // style={{marginBottom:"-10px",marginTop:"-10px"}}
                margin="normal"
                autoOk={true}
                id="date-picker-inline"
                label="Start Date"
                value={startDatefilter}
                disableFuture={true}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                helperText={startDateMsg != "" ? startDateMsg : ""}
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorRoot,
                  },
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          {/* <Grid item xs={1} sm={1} md={1}>
            &nbsp;
          </Grid> */}
          <Grid
            item
            xs={6} //siddhant
            sm={5}
            md={5}
            style={{ paddingLeft: matches ? "4px" : "" }} //siddhant
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                fullWidth
                className={classes.formControl2}
                disableFuture={true}
                variant="inline"
                format="MM/dd/yyyy"
                changeYear={true}
                changeMonth={true}
                margin="normal"
                autoOk={true}
                id="date-picker-inline"
                label="End Date"
                minDate={startDatefilter}
                value={endDatefilter}
                onChange={handleEndDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                helperText={endDateMsg != "" ? endDateMsg : ""}
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorRoot,
                  },
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: matches ? "-13px" : "" }} />
        <br />
        <Typography
          style={{
            paddingLeft: "11px",
            // marginTop: "-20px",
            fontSize: "14px",
            fontWeight: 600,
            fontFamily: "Montserrat",
            lineHeight: "24px",
            color: "#353535",
            marginTop: matches ? "-13px" : "-20px", //siddhant
            marginBottom: matches ? "2px" : "", //siddhant
          }}
        >
          Claim Amount Range
        </Typography>
        <Grid
          container
          spacing={2}
          style={{
            padding: matches ? "8px 33px 8px 8px" : "",
            justifyContent: matches ? "space-between" : "",
          }} //siddhant
        >
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            style={{
              padding: matches ? "8px 0px" : "",
              paddingRight: matches ? "2px" : "",
            }} //siddhant
          >
            <ListItemIcon>
              <NumericFormat
                id="outlined-basic"
                label="Min Amount"
                inputProps={{ maxLength: 10 }}
                variant="outlined"
                value={minClaimAmountfilter}
                min="0"
                onKeyPress={preventMinus}
                customInput={TextField}
                className={classes.formControl1}
                onChange={(e) => {
                  handleMinClaimAmount("", e.target.value);
                  // if (e.target.value.length >= 1 && e.target.value > 0 && maxClaimAmountfilter != "") {
                  //   setButtonState(false);
                  // }
                  // if (e.target.value.length == 0) {
                  //   setButtonState(true);
                  // }

                  // setMinClaimAmountfilter(e.target.value);
                }}
                helperText={minClaimAmountMsg != "" ? minClaimAmountMsg : ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end"></InputAdornment>
                  ),
                }}
              />
            </ListItemIcon>
          </Grid>
          <Grid
            item
            xs={6}
            sm={5}
            md={5}
            style={{ paddingLeft: matches ? "6px" : "" }} //siddhant
          >
            <ListItemIcon>
              <NumericFormat
                id="outlined-basic"
                label="Max Amount"
                inputProps={{ maxLength: 10 }}
                variant="outlined"
                value={maxClaimAmountfilter}
                min="0"
                onKeyPress={preventMinus}
                customInput={TextField}
                className={classes.formControl3}
                onChange={(e) => {
                  handleMaxClaimAmount("", e.target.value);

                  // if (e.target.value.length >= 1 && e.target.value > 0 && minClaimAmountfilter != "") {
                  //   setButtonState(false);
                  // }
                  // if (e.target.value.length == 0) {
                  //   setButtonState(true);
                  // }

                  // setMaxClaimAmountfilter(e.target.value);
                }}
                helperText={maxClaimAmountMsg != "" ? maxClaimAmountMsg : ""}
                FormHelperTextProps={{
                  classes: {
                    root: classes.errorRoot,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end"></InputAdornment>
                  ),
                }}
              />
            </ListItemIcon>
          </Grid>
        </Grid>
      </List>

      {/* ayushi */}
      <Divider style={{ marginTop: matches ? "-13px" : "" }} />
      <Box>
        <Button
          variant="outlined"
          size="small"
          //style={{ marginLeft: "37px", width: "121px" }}
          style={{
            marginLeft: "56px",
            width: "121px",
            fontSize: matches ? "14px" : "",
            textTransform: "inherit",
          }}
          onClick={handleRemoveFilter1}
        >
          Reset
        </Button>
        <Button
          variant="outlined"
          disabled={buttonState}
          style={{
            background: buttonState ? "#9E9E9E" : "#0B1941",
            marginLeft: "20px",
            marginBottom: "50px",
            marginTop: "50px",
            fontSize: matches ? "10px" : "",
            textTransform: "inherit",
          }}
          color="#fffff"
          size="small"
          onClick={handleApplyFilter}
        >
          <Typography style={{ color: "white", margin: 0 }}>
            {" "}
            Apply Filter
          </Typography>
        </Button>
      </Box>
    </div>
  );
  const target = useRef(null);
  const dispatch = useDispatch();

  //useSelectors start:

  const getFinanceApprovalList = useSelector(
    (state) => state.createExpenseData.getFinanceApprovalList
  );
  const delEmpIDRedux = useSelector(
    (state) => state.createExpenseData.delEmpId
  );
  const getDownloadAllExpensesReportData = useSelector(
    (state) => state.createExpenseData.getDownloadAllExpensesReport
  );
  //console.log("checkdownload" ,getDownloadAllExpensesReportData);
  const ssoEmpIdDetails = useSelector(
    (state) => state.createExpenseData.getEmpDetailsData
  );
  const totalPages = useSelector(
    (state) => state.createExpenseData.getExpenseReportCountData
  );
  const pageWiseData = useSelector(
    (state) => state.createExpenseData.getDownloadAllExpensesPageWise
  );
  //console.log("Pagination 1 totalPages:", totalPages);
  //console.log("Pagination 2 pageWiseData:", pageWiseData);
  //useSelectors end.
  // console.log("allExpensesTest - delEmpID", delEmpIDRedux);
  // console.log(
  //   "allExpensesTest- 0 - getDownloadAllExpensesReportData:",
  //   getDownloadAllExpensesReportData
  // );
  // console.log("filteredRowsData:------ ", filteredRowsData);
  const headers = [
    {
      label: "Expense Code",
      key: "expenseCode",
    },
    {
      label: "Expense Category",
      key: "expenseCategory",
    },
    {
      label: "To Location",
      key: "toLocation",
    },
    {
      label: "From Location",
      key: "fromLocation",
    },
    {
      label: "Distance (Km/Miles)",
      key: "distance",
    },
    {
      label: "From Date",
      key: "fromDate",
    },
    {
      label: "To Date",
      key: "toDate",
    },
    {
      label: "Claim Month",
      key: "forMonth",
    },
    {
      label: "Manager Approval Date",
      key: "l1ApprovedDate",
    },
    {
      label: "Currency",
      key: "currencyCode",
    },
    {
      label: "Claimed Amount",
      key: "claimAmount",
    },
    {
      label: "Policy Limit",
      key: "policyLimit",
    },

    {
      label: "Attachments",
      key: "attachments",
    },
    {
      label: "Project ID",
      key: "projectId",
    },
    {
      label: "Created By",
      key: "createdBy",
    },
  ];
  const csvLink = {
    headers: headers,
    data: singleDownload,
    filename: `FinanceApproval_${moment(new Date()).format(
      "DDMMYYYY_HHmm"
    )}.csv`,
  };

  //useEffects:
  //   useEffect(() => {
  //     console.log("allExpensesTest 1 - in useEffect()");
  //     let obj = {
  //       empId: delEmpIDRedux,
  //     };
  //     dispatch(getDownloadExpenseReportData(obj));
  //   }, []);

  useEffect(() => {
    if (delEmpIDRedux !== undefined && delEmpIDRedux != null) {
      setEmpId(delEmpIDRedux);
      let obj = {
        empId: delEmpIDRedux,
        nday: props.nDay,
      };
      dispatch(getFinanceApprovalData(obj));
    } else {
      if (props.ssoEmpId !== undefined && props.ssoEmpId !== null) {
        setEmpId(props.ssoEmpId);
        let obj = {
          empId: props.ssoEmpId,
          nday: props.nDay,
        };
        dispatch(getFinanceApprovalData(obj));
      } else if (!matches) {
        setEmpId(ssoEmpIdDetails?.empId);
        let obj = {
          empid: ssoEmpIdDetails ? ssoEmpIdDetails.empId : 61332,
          nday: 90,
        };
        dispatch(getFinanceApprovalData(obj));
      }
    }
    //console.log("AppFin", empId);
    // let obj = {
    //   empId: 13,
    //   nday: 90,
    // };
    // dispatch(getFinanceApprovalData(obj));
  }, [props.nDay ? props.nDay : 90, refreshTableToggle, delEmpIDRedux]);
  useEffect(() => {
    if (startDatefilter == null && endDatefilter != null) {
      setStartDateMsg("Please enter start date ");
      setButtonState(true);
    } else if (startDatefilter != null && endDatefilter == null) {
      setEndDateMsg("Please enter end date");
      setButtonState(true);
    } else if (new Date(startDatefilter) > new Date(endDatefilter)) {
      setEndDateMsg("End date should be greater than start date ");
      setStartDateMsg("");
      setButtonState(true);
    } else if (startDatefilter == null && endDatefilter == null) {
      setStartDateMsg("");
      setEndDateMsg("");
    }
  }, [startDatefilter, endDatefilter]);

  useEffect(() => {
    // const value=validateFilter();

    if (
      (minClaimAmountfilter == "" && maxClaimAmountfilter != "") ||
      (minClaimAmountfilter != "" && maxClaimAmountfilter == "")
    ) {
      if (startDateMsg.length == 0 && endDateMsg.length == 0)
        setButtonState(false);
      setMaxClaimAmountMsg("");
    } else if (minClaimAmountfilter != "" && maxClaimAmountfilter != "") {
      if (Number(minClaimAmountfilter) > Number(maxClaimAmountfilter)) {
        setMaxClaimAmountMsg("Max Amount should be greater than Min Amount");
        setButtonState(true);
      } else if (Number(minClaimAmountfilter) <= Number(maxClaimAmountfilter)) {
        if (startDateMsg.length == 0 && endDateMsg.length == 0)
          setButtonState(false);
        setMaxClaimAmountMsg("");
        // if (!validateFilter()) setButtonState(false);
      }
    } else if (minClaimAmountfilter == "" && maxClaimAmountfilter == "") {
      setMaxClaimAmountMsg("");

      if (handleFieldValue()) setButtonState(true);
      else if (!handleFieldValue()) {
        if (startDateMsg.length == 0 && endDateMsg.length == 0)
          setButtonState(false);
        else setButtonState(true);
      }
      // if (!validateFilter()) setButtonState(true);
      // else setButtonState(false);
    }
  }, [minClaimAmountfilter, maxClaimAmountfilter]);

  useEffect(() => {
    setFilteredRowsData(props.fullData);
  }, [props.fullData]);
  const handleRequestSort = (event, property) => {
    //console.log("handleRequestSort:-----------event-1-:-- ", event);
    //console.log("handleRequestSort:-----------property-2-:-- ", property);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const resetBulkDownload = () => {
    setBulkDownload([]);
    setSelected([]);
  };

  //console.log("comments====>:", comments);

  const handleOpenBulkApproveDialog = (row, index) => {
    setCurrentIndex(index);
    //console.log("handleOpenBulkApproveDialog", row, index);
    setOpenBulkApproveDialog(true);
    setSingleExpense(row);
    setApproveOrReject(1);
  };
  const handleOpenRejectionDialog = (row, index) => {
    setCurrentIndex(index);
    //console.log("handleOpenRejectionDialog");
    setOpenBulkApproveDialog(true);
    setSingleExpense(row);
    setApproveOrReject(0);
    setExpStatus("Rejected");
  };
  const handleCloseBulkApproveDialog = () => {
    //console.log("openBulkDialog--- in fxn", openBulkApproveDialog);
    setOpenBulkApproveDialog(false);
  };
  const handleRejectionCommentsParent = () => {
    //console.log("handleRejectionCommentsParent");
  };
  const handleSelectAllClick = (event) => {
    //console.log("handleSelectAllClick----1", filteredRowsData);
    if (event.target.checked) {
      // //console.log('pageNumber', page)
      let filterData = stableSort(
        filteredRowsData,
        getComparator(order, orderBy)
      )?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      // //console.log('filterData', filterData)
      const newSelecteds = filterData?.map((s) => s.expId);
      setSelected(newSelecteds);
      //console.log("filterData001 value", filterData);
      let tempArray = [];
      tempArray = filterData?.map((x) => ({
        ...x,
        projectids: x.projdetails.reduce(
          (acc, curr, i) => (acc = acc + curr.projectId),
          ""
        ),
      }));
      //console.log("filterData002 value", tempArray);
      setBulkDownload(tempArray);

      //console.log("bulk download value", bulkDownload);
      //console.log("handleSelectAllClick----2", selected);

      return;
    }
    setBulkDownload([]);
    setSelected([]);
  };
  //console.log("selected:----------- ", selected);
  const handleClick = (event, name) => {
    //console.log("name", name);
    const selectedIndex = selected.indexOf(name.expId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.expId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    //console.log("selected value", selected);
    //Multiple download logic

    const objIndex = bulkDownload.findIndex((el) => el.expId == name.expId);
    let newBulkDownload = [];
    if (objIndex >= 0) {
      newBulkDownload = [
        ...bulkDownload.filter((el) => el.expId != name.expId),
      ];
    } else {
      newBulkDownload = [...bulkDownload, name];
    }
    //console.log("handle Click bulkDld03", newBulkDownload);
    let tempArray = [];
    tempArray = newBulkDownload?.map((x) => ({
      ...x,
      projectids: x.projdetails.reduce(
        (acc, curr, i) => (acc = acc + curr.projectId),
        ""
      ),
    }));
    //console.log("bulkDld04 value", tempArray);

    setBulkDownload(tempArray);
  };

  const handleFilteredArray = (data) => {
    setFilteredRowsData(data);
    setPage(0);
    ////console.log("data1",data)
  };
  const handleSettingsParent = (name, value) => {
    if (name == "currentStatus") {
      setTeamAppAttachments(value);
    } else if (name == "projectId") {
      setTeamAppProjectId(value);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    //console.log("newpage1",newPage)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //   const handleChangeDense = (event) => {
  //     setDense(event.target.checked);
  //   };
  ////console.log("approveOrReject:  ====>  ", approveOrReject);

  const handleDownload = (value) => {
    ////console.log("valueee in handleDownload: ---->  ", value);
    let tempProjectId = "";

    value.projdetails?.map((item) => {
      tempProjectId = tempProjectId + item.projectId;
    });
    //console.log("valueee in handleDownload: ---->  ", tempProjectId);
    value.projectId = tempProjectId;
    // let tempApprovedDate = moment(value.createdOn).format("DD/MM/YYYY");
    // value.createdOn = tempApprovedDate;
    // //console.log("final valueee in handleDownload: ---->  ", value);
    setSingleDownload([value]);
  };
  const handleSingleApproveRejectExpense = (value) => {
    // console.log("value:  ====>  ", value);
    // console.log("empId:  ====>  ", empId);
    // console.log("expStatus:  ====>  ", expStatus);
    // console.log("comments:  ====>  ", comments);
    // console.log("amount:  ====>  ", value?.claimAmount);
    // console.log("value?.projectdetails:  ====>  ", value?.projdetails);
    // console.log("approveOrReject:  ====>  ", approveOrReject);

    let array = [];
    value?.projdetails?.map((item) => {
      array.push({
        recid: Number(item.projectRecID),
        expid: Number(value.expId),
        projid: Number(item.projectUniqueID),
        projamount: 0,
        claimedamount: value?.claimAmount,
      });
    });

    //console.log("array:  ====>  ", array);
    //console.log("expStatusupdatedRejected:  ====>  ", expStatus);

    let dataObject = {
      empId: empId,
      expStatus: expStatus,
      comment: comments,
      amount: value?.claimAmount,
      projdetails: array,
    };
    //console.log("dataObject:  ====>  ", dataObject);

    dispatch(postFinancialApproveRejectExpenseData(dataObject));
    setComments("");
    dispatch(
      getFinanceApprovalData({
        empId: empId
          ? empId
          : ssoEmpIdDetails.empId
          ? ssoEmpIdDetails.empId
          : 61332,
        nday: props.nDay ? props.nDay : 90,
      })
    );
    forceUpdate();
  };
  //console.log("comments value: ---->  ", comments);
  //console.log("selected value: ---->  ", selected);

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredRowsData?.length - page * rowsPerPage);
  const handleComments = (data) => {
    //console.log("data", data);
    setComments(data);
  };
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handlToolAction = (event) => {
    //console.log(event.currentTarget);
    setToolAction(event.currentTarget);
  };
  const handleToolTip = (comment, heading) => {
    return (
      <>
        <Box>
          <Typography className={classes.statusHeading}>{heading}</Typography>
        </Box>
        {comment?.map((value) => {
          return (
            <>
              <Box style={{ display: "flex" }}>
                <Typography className={classes.statusName}>
                  {value.managerid}
                </Typography>
                {heading == "Rejected By " && (
                  <Typography className={classes.statusName}>{"|"}</Typography>
                )}
                <Typography className={classes.statusProjectName}>
                  {value.projCode}
                </Typography>{" "}
              </Box>
              <Typography className={classes.statusMessage}>
                {value.reason}
              </Typography>
            </>
          );
        })}
      </>
    );
  };

  const handleTooltipOpen = (comments, index) => {
    //console.log("comments:====> ", comments, "index", index);
    setOpen(true);
    setCommentData(comments);
    setCommentHeading("Rejected");
    setCurrentIndex(index);
  };
  //console.log("checking the coomments", commentData);
  //console.log("checking the nest action", commentHeading);
  const handleParentToolTip = (index) => {
    //console.log(":parent tool tip function", index);
    return (
      <>
        <OutsideClickHandler
          onOutsideClick={() => {
            handleTooltipClose();
          }}
        >
          <div style={{ position: "relative" }}>
            <Tooltip
              PopperProps={{
                disablePortal: true,
                anchorEl: { toolAction },
                // anchorOrigin:{
                //   vertical: "bottom",
                //   horizontal: "left",
                // },
                // transformOrigin:{
                //   vertical: "top",
                //   horizontal: "left",
                // }
              }}
              arrow
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={handleToolTip(commentData, commentHeading)}
              classes={{
                popper: classes.tooltipBox,
                arrow: classes.arrow,
              }}
            >
              {/* <ErrorOutlineIcon
              onClick={handleTooltipOpen}
              className={classes.tooltip}
              /> */}
              <span
                style={{ width: "0px", minWidth: "0px", height: "0" }}
              ></span>
            </Tooltip>
          </div>
        </OutsideClickHandler>
      </>
    );
  };
  const matches = useMediaQuery("(max-width:600px)");
  return (
    <>
      {matches ? (
        <TableContainer component={Paper} sx={{ width: "max-content" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="right" style={{ padding: "0.4%" }}>
                  &nbsp;
                </TableCell>
                <TableCell align="right" style={{ padding: "0.4%" }}>
                  &nbsp;
                </TableCell>
                <TableCell align="right" style={{ padding: "0.4%" }}>
                  {/* <IconButton

                    onClick={toggleDrawer(anchorFilter1, true)}
                  >
                    <span class="material-symbols-outlined">filter_alt</span>
                  </IconButton> */}
                  {filterShow ? (
                    <IconButton
                      aria-label="cart"
                      style={{ marginTop: "-5px", marginBottom: "-5px" }}
                    >
                      <StyledBadge badgeContent={countShow} color="secondary">
                        <IconButton onClick={toggleDrawer(anchorFilter1, true)}>
                          <span class="material-symbols-outlined">
                            filter_alt
                          </span>
                        </IconButton>
                      </StyledBadge>
                    </IconButton>
                  ) : (
                    <IconButton onClick={toggleDrawer(anchorFilter1, true)}>
                      <span class="material-symbols-outlined">filter_alt</span>
                    </IconButton>
                  )}
                  <Drawer
                    anchor={anchorFilter1}
                    open={state[anchorFilter1]}
                    onClose={toggleDrawer(anchorFilter1, false)}
                  >
                    {list(anchorFilter1)}
                  </Drawer>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      ) : (
        ""
      )}
      {!matches ? (
        <div className={classes.root}>
          <Paper
            elevation={0}
            className={
              window.screen.availWidth == 1280
                ? classes.paperZ150
                : window.screen.availWidth == 1536
                ? classes.paperZ125
                : classes.paperZ100
            }
          >
            <TableContainer className={classes.tableContainer}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                aria-label="enhanced table"
              >
                <AllExpenseTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  rowsPerPage={rowsPerPage}
                  pageNumber={page}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={filteredRowsData?.length}
                  onClickFilter={handleFilteredArray}
                  handleSettingsParent={handleSettingsParent}
                  getFinanceApprovalList={props.fullData}
                  bulkDownload={bulkDownload}
                  empId={empId}
                  resetBulkDownload={resetBulkDownload}
                />
                <TableBody>
                  {stableSort(filteredRowsData, getComparator(order, orderBy))
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row, index) => {
                      const isItemSelected = isSelected(row.expId);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      // console.log("allexpensesTest - row", row);
                      return (
                        <TableRow
                          className={classes.tableRow}
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.expId}
                          selected={isItemSelected}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            style={{
                              minWidth: "142px",
                              //  paddingLeft: "66px",
                              padding: "0px",
                              fontSize: "12px",
                              textAlign: "center",
                            }}
                            onClick={() => {
                              //console.log("hi table roe clicked!");
                              const text = "/viewExpenseDetails/:id";
                              const result = text.replace(":id", row.expId);
                              history.push({
                                pathname: result,
                                state: {
                                  response: row,
                                  empId: empId,
                                  compName: "allExpenseTable",
                                },
                              });
                            }}
                          >
                            {row.expCode}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              maxWidth: "1px",
                              padding: "0px",
                              fontSize: "12px",
                            }}
                            onClick={() => {
                              //console.log("hi table roe clicked!");
                              const text = "/viewExpenseDetails/:id";
                              const result = text.replace(":id", row.expId);
                              history.push({
                                pathname: result,
                                state: {
                                  response: row,
                                  empId: empId,
                                  compName: "allExpenseTable",
                                },
                              });
                            }}
                          >
                            {row.purpose}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              maxWidth: "1px",
                              padding: "0px",
                              fontSize: "12px",
                              paddingLeft: "13px",
                            }}
                            onClick={() => {
                              //console.log("hi table roe clicked!");
                              const text = "/viewExpenseDetails/:id";
                              const result = text.replace(":id", row.expId);
                              history.push({
                                pathname: result,
                                state: {
                                  response: row,
                                  empId: empId,
                                  compName: "allExpenseTable",
                                },
                              });
                            }}
                          >
                            {row.categoryName}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              minWidth: "126px",
                              padding: "0px",
                              fontSize: "12px",
                              paddingLeft: "13px",
                            }}
                            onClick={() => {
                              //console.log("hi table roe clicked!");
                              const text = "/viewExpenseDetails/:id";
                              const result = text.replace(":id", row.expId);
                              history.push({
                                pathname: result,
                                state: {
                                  response: row,
                                  empId: empId,
                                  compName: "allExpenseTable",
                                },
                              });
                            }}
                          >
                            {row.createdBy}
                            <br />
                            <span style={{ fontSize: "10px" }}> ID: </span>{" "}
                            <span style={{ fontSize: "10px" }}>
                              {row.empId}
                            </span>
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{
                              minWidth: "120px",
                              padding: "0px",
                              fontSize: "12px",
                            }}
                            onClick={() => {
                              //console.log("hi table roe clicked!");
                              const text = "/viewExpenseDetails/:id";
                              const result = text.replace(":id", row.expId);
                              history.push({
                                pathname: result,
                                state: {
                                  response: row,
                                  empId: empId,
                                  compName: "allExpenseTable",
                                },
                              });
                            }}
                          >
                            {" "}
                            {moment(row?.createdOn).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              minWidth: "120px",
                              padding: "0px",
                              fontSize: "12px",
                            }}
                            onClick={() => {
                              //console.log("hi table roe clicked!");
                              const text = "/viewExpenseDetails/:id";
                              const result = text.replace(":id", row.expId);
                              history.push({
                                pathname: result,
                                state: {
                                  response: row,
                                  empId: empId,
                                  compName: "allExpenseTable",
                                },
                              });
                            }}
                          >
                            {" "}
                            {row?.managerApprovedOn != null
                              ? moment(row?.managerApprovedOn).format(
                                  "DD/MM/YYYY"
                                )
                              : row?.managerApprovedOn}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              minWidth: "120px",
                              color: row?.currencyChanged ? "red" : "",
                              // paddingRight:
                              //   window.screen.availWidth == 1280
                              //     ? "28px"
                              //     : window.screen.availWidth == 1536
                              //     ? "52px"
                              //     : "125px",
                              padding: "0px",
                              fontSize: "12px",
                              textAlign: "center",
                            }}
                            onClick={() => {
                              //console.log("hi table roe clicked!");
                              const text = "/viewExpenseDetails/:id";
                              const result = text.replace(":id", row.expId);
                              history.push({
                                pathname: result,
                                state: {
                                  response: row,
                                  empId: empId,
                                  compName: "allExpenseTable",
                                },
                              });
                            }}
                          >
                            <div>
                              {row.currencyChanged == 1 ||
                              (row.policyLimit &&
                                row.claimedAmount > row.policyLimit) ? (
                                <Tooltip
                                  title={
                                    row.exceptionalApproval == 1
                                      ? row.currencyChanged == 1
                                        ? "Claim Amount Exceeds Policy limit and Default currency has been changed."
                                        : "Claim Amount exceeds policy limit"
                                      : "Default currency has been changed."
                                  }
                                >
                                  <span style={{ color: "red" }}>
                                    {row?.currency}&nbsp;
                                    {row?.claimedAmount
                                      ?.toString()
                                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </span>
                                </Tooltip>
                              ) : (
                                <div>
                                  {row?.currency}&nbsp;
                                  {row?.claimedAmount
                                    ?.toString()
                                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>
                              )}
                            </div>
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{
                              minWidth: "120px",
                              padding: "0px",
                              fontSize: "12px",
                              paddingLeft: "12px",
                            }}
                            onClick={() => {
                              //console.log("hi table roe clicked!");
                              const text = "/viewExpenseDetails/:id";
                              const result = text.replace(":id", row.expId);
                              history.push({
                                pathname: result,
                                state: {
                                  response: row,
                                  empId: empId,
                                  compName: "allExpenseTable",
                                },
                              });
                            }}
                          >
                            {" "}
                            {row.status}
                          </TableCell>

                          {/* <TableCell
                            align="left"
                            style={{ minWidth: "140px", padding: "0px" ,fontSize:"12px"}}
                            onClick={() => {
                              //console.log("hi table roe clicked!");
                              const text = "/viewExpenseDetails/:id";
                              const result = text.replace(":id", row.expId);
                              history.push({
                                pathname: result,
                                state: {
                                  response: row,
                                  empId: empId,
                                  compName: "allExpenseTable",
                                },
                              });
                            }}
                          >
                            {" "}
                            {row?.projectId}
                          </TableCell> */}
                          <TableCell
                            align="left"
                            style={{
                              minWidth: "140px",
                              padding: "0px",
                              fontSize: "12px",
                            }}
                            onClick={() => {
                              const text = "/viewExpenseDetails/:id";
                              const result = text.replace(":id", row.expId);
                              history.push({
                                pathname: result,
                                state: {
                                  response: row,
                                  empId: empId,
                                  compName: "allExpenseTable",
                                },
                              });
                            }}
                          >
                            <div>
                              {row?.projectId && (
                                <span>
                                  {row.projectId.split("-")[0]}-
                                  {row.projectId.split("-")[1]}-
                                  {row.projectId.split("-")[2]}
                                </span>
                              )}
                            </div>
                            <div style={{ fontSize: "10px" }}>
                              {row.projectId && row.projectId.split("-")[3]}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow className={classes.tableRow}>
                      <TableCell colSpan={12} style={{ minWidth: "120px" }} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="caption"
                  style={{
                    paddingLeft: "21px",
                  }}
                >
                  <InfoOutlinedIcon />
                </Typography>

                <Typography
                  variant="caption"
                  style={{
                    paddingLeft: "10px",
                  }}
                >
                  <b>Click on any row to view details</b>
                </Typography>
              </Box>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredRowsData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Box>
          </Paper>
        </div>
      ) : (
        <>
          <TableContainer component={Paper} sx={{ width: "max-content" }}>
            <Table>
              <AllExpenseTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                rowsPerPage={rowsPerPage}
                pageNumber={page}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={filteredRowsData?.length}
                onClickFilter={handleFilteredArray}
                handleSettingsParent={handleSettingsParent}
                getFinanceApprovalList={props.fullData}
                bulkDownload={bulkDownload}
                empId={empId}
                resetBulkDownload={resetBulkDownload}
              />
              <TableBody>
                {stableSort(filteredRowsData, getComparator(order, orderBy))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    const isItemSelected = isSelected(row.expId);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    // console.log("allexpensesTest - row", row);
                    return (
                      <TableRow
                        hover
                        className={classes.tableRow}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.expId}
                        selected={isItemSelected}
                      >
                        <TableCell
                          align="left"
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            maxWidth: "1px",
                          }}
                          onClick={() => {
                            //console.log("hi table roe clicked!");
                            const text = "/viewExpenseDetails/:id";
                            const result = text.replace(":id", row.expId);
                            history.push({
                              pathname: result,
                              state: {
                                response: row,
                                empId: empId,
                                compName: "allExpenseTable",
                              },
                            });
                          }}
                        >
                          {row.purpose}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ padding: "0.4%", paddingLeft: "5px" }}
                          onClick={() => {
                            //console.log("hi table roe clicked!");
                            const text = "/viewExpenseDetails/:id";
                            const result = text.replace(":id", row.expId);
                            history.push({
                              pathname: result,
                              state: {
                                response: row,
                                empId: empId,
                                compName: "allExpenseTable",
                              },
                            });
                          }}
                        >
                          {row.categoryName}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ padding: "0.4%" }}
                          onClick={() => {
                            //console.log("hi table roe clicked!");
                            const text = "/viewExpenseDetails/:id";
                            const result = text.replace(":id", row.expId);
                            history.push({
                              pathname: result,
                              state: {
                                response: row,
                                empId: empId,
                                compName: "allExpenseTable",
                              },
                            });
                          }}
                        >
                          {row.createdBy}
                          <br />
                          <span style={{ fontSize: "10px" }}> ID: </span>{" "}
                          <span style={{ fontSize: "10px" }}>{row.empId}</span>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ padding: "0.4%" }}
                          onClick={() => {
                            //console.log("hi table roe clicked!");
                            const text = "/viewExpenseDetails/:id";
                            const result = text.replace(":id", row.expId);
                            history.push({
                              pathname: result,
                              state: {
                                response: row,
                                empId: empId,
                                compName: "allExpenseTable",
                              },
                            });
                          }}
                        >
                          {" "}
                          {row?.managerApprovedOn != null
                            ? moment(row?.managerApprovedOn).format(
                                "DD/MM/YYYY"
                              )
                            : row?.managerApprovedOn}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ padding: "0.4%" }}
                          onClick={() => {
                            //console.log("hi table roe clicked!");
                            const text = "/viewExpenseDetails/:id";
                            const result = text.replace(":id", row.expId);
                            history.push({
                              pathname: result,
                              state: {
                                response: row,
                                empId: empId,
                                compName: "allExpenseTable",
                              },
                            });
                          }}
                        >
                          <div>
                            {row.currencyChanged == 1 ||
                            (row.policyLimit &&
                              row.claimedAmount > row.policyLimit) ? (
                              <Tooltip
                                title={
                                  row.exceptionalApproval == 1
                                    ? row.currencyChanged == 1
                                      ? "Claim Amount Exceeds Policy limit and Default currency has been changed."
                                      : "Claim Amount exceeds policy limit"
                                    : "Default currency has been changed."
                                }
                              >
                                <span style={{ color: "red" }}>
                                  {row?.currency}&nbsp;
                                  {row?.claimedAmount
                                    ?.toString()
                                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </span>
                              </Tooltip>
                            ) : (
                              <div>
                                {row?.currency}&nbsp;
                                {row?.claimedAmount
                                  ?.toString()
                                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </div>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow className={classes.tableRow}>
                    <TableCell colSpan={12} style={{ padding: "0.4%" }} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Box
              style={{
                display: "flex",
                marginLeft: "35%",
                justifyContent: "space-between",
              }}
            >
              <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={filteredRowsData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </TableContainer>
        </>
      )}
    </>
  );
}