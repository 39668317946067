import React from "react";
import "../css/style.css";
import Typography from "@material-ui/core/Typography";
import Multiselect from "../components/multiSelect";
import { Box, Button, Grid, makeStyles, Paper } from "@material-ui/core";
import loginImage from "../images/sso.png";
import Vector1 from "../images/Vector1.svg";
import incedoLogo from "../images/incedo-logo.svg";
import { useMsal, useAccount } from "@azure/msal-react";
import { loginApiRequest } from "../Utils/authConfig";
import "../css/login.css";
import { hover } from "@testing-library/user-event/dist/hover";

const useStyles = makeStyles((theme) => ({
  root: {},

  control: {
    padding: theme.spacing(2),
  },
  bgImage: {
    background: `url(${loginImage}) no-repeat center fixed`,
    backgroundSize: "cover",
  },
  logo100: {
    maxHeight: "75px",
  },
  logo125: {
    maxWidth: "200px",
    maxHeight: "53px",
  },
  logo150: {
    maxWidth: "200px",
    maxHeight: "53px",
  },
  box100: {
    minWidth: "700px",
    height: "570px",
  },
  box125: {
    maxWidth: "529px",
    height: "450px",
  },
  box150: {
    maxWidth: "529px",
    height: "450px",
  },
  title1_100: {
    fontFamily: "Montserrat",
    fontSize: "50px",
    fontWeight: "700",
    lineHeight: "75px",
    letterSpacing: "0px",
    textAlign: "center",
  },
  title1_125: {
    fontFamily: "Montserrat",
    fontSize: "32px",
    fontWeight: "600",
    lineHeight: "40px",
    letterSpacing: "0px",
    textAlign: "center",
  },
  title1_150: {
    fontFamily: "Montserrat",
    fontSize: "32px",
    fontWeight: "600",
    lineHeight: "40px",
    letterSpacing: "0px",
    textAlign: "center",
  },
  title2_100: {
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "20px",
    letterSpacing: "0.20000000298023224px",
    textAlign: "center",
  },
  title2_125: {
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "20px",
    letterSpacing: "0.20000000298023224px",
    textAlign: "center",
  },
  title2_150: {
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "20px",
    letterSpacing: "0.20000000298023224px",
    textAlign: "center",
  },
  button100: {
    backgroundColor: "#043365",
    minWidth: "650px",
    minHeight: "70px",
    '&:hover':{
      backgroundColor:"#043365"
    }
  },
  button125: {
    backgroundColor: "#043365",
    minWidth: "455px",
    minHeight: "58px",
    '&:hover':{
      backgroundColor:"#043365"
    }
  },
  button150: {
    backgroundColor: "#043365",
    minWidth: "455px",
    minHeight: "58px",
    '&:hover':{
      backgroundColor:"#043365"
    }
  },
  text12:{
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "18px",
    letterSpacing: "0.20000000298023224px",
    // textAlign: "center",
    color: "#252733",
  },
  text13:{
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "599",
    lineHeight: "18px",
    letterSpacing: "0.20000000298023224px",
    // textAlign: "center",
    color: "#252733",
  },
  // .click:hover{
  //   backgroundColor:"red"
  // },
}));

const Login = () => {
  const classes = useStyles();
  const { instance, accounts } = useMsal();

  const handleClick = () => {
    //console.log("button click");
    localStorage.removeItem("token");
    instance.loginRedirect(loginApiRequest).catch((e) => {
      //console.log(e);
    });
  };
  return (
    <div id="hero" className="hero">
      <div
        className={
          window.screen.availWidth == 1280
            ? "content150"
            : window.screen.availWidth == 1536
            ? "content125"
            : "content100"
        }
      >
        <Box
          className={
            window.screen.availWidth == 1280
              ? classes.box150
              : window.screen.availWidth == 1536
              ? classes.box125
              : classes.box100
          }
          // style={{
          //   maxWidth: "529px",
          //   height: "450px",
          //   boxShadow:
          //     "-1px 0px 8px rgba(0, 0, 0, 0.13), 0px 4px 8px rgba(0, 0, 0, 0.17)",
          //   borderRadius: "8px 8px 8px 7px",
          // }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "72px",
              paddingTop: "15px",
            }}
          >
            <img
              src={incedoLogo}
              className={
                window.screen.availWidth == 1280
                  ? classes.logo150
                  : window.screen.availWidth == 1536
                  ? classes.logo125
                  : classes.logo100
              }
              //style={{ maxWidth: "200px", maxHeight: "53px" }}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "72px",
            }}
          >
            <Typography
              className={
                window.screen.availWidth == 1280
                  ? classes.title1_150
                  : window.screen.availWidth == 1536
                  ? classes.title1_125
                  : classes.title1_100
              }
              // style={{
              //   fontFamily: "Montserrat",
              //   fontSize: "32px",
              //   fontWeight: "600",
              //   lineHeight: "40px",
              //   letterSpacing: "0px",
              //   textAlign: "center",
              // }}
            >
              Welcome to Expense and Timesheet Portal
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "24px",
            }}
          >
            <Typography
              className={
                window.screen.availWidth == 1280
                  ? classes.title2_150
                  : window.screen.availWidth == 1536
                  ? classes.title2_125
                  : classes.title2_100
              }
              // style={{
              //   fontFamily: "Montserrat",
              //   fontSize: "20px",
              //   fontWeight: "600",
              //   lineHeight: "20px",
              //   letterSpacing: "0.20000000298023224px",
              //   textAlign: "center",
              // }}
            >
              Sign In to Proceed
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "46px",
            }}
          >
            <Button
              className={
                window.screen.availWidth == 1280
                  ? classes.button150
                  : window.screen.availWidth == 1536
                  ? classes.button125
                  : classes.button100
              }
              onClick={() => handleClick()}            
            >
              <Typography
                style={{
                  color: "#FFFFFFE5",
                  fontFamily: "Montserrat",
                  fontSize:
                    window.screen.availWidth == 1280
                      ? "16px"
                      : window.screen.availWidth == 1536
                      ? "16px"
                      : "21px",
                  fontWeight: "600",
                  lineHeight: "20px",
                  letterSpacing: "-0.08399999886751175px",
                  textAlign: "center",
                }}
              >
                Sign In{" "}
              </Typography>{" "}
            </Button>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "12px",
              marginLeft:
                window.screen.availWidth == 1280
                  ? "22px"
                  : window.screen.availWidth == 1536
                  ? "22px"
                  : "0px",
            }}
          >
            <Typography
              className={
                window.screen.availWidth == 1792
                  ? classes.text13
                  : classes.text12}
            >
              In case of any technical issue, please reach out to support team
              (IncedoInternalSystems@incedoinc.com)
            </Typography>
          </Box>
        </Box>
      </div>
      <div
        className="overlay"
        style={{ background: `url(${loginImage})`, backgroundSize: "cover" }}
      />
    </div>
    // <Box className={classes.root}>
    //   <Box className="bg_image"></Box>;
    //   <Box
    //     style={{
    //       maxWidth: "529px",
    //       height: "490px",
    //       boxShadow:
    //         "-1px 0px 8px rgba(0, 0, 0, 0.13), 0px 4px 8px rgba(0, 0, 0, 0.17)",
    //       borderRadius: "8px 8px 8px 7px",
    //     }}
    //   >
    //     <Box
    //       style={{
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         marginTop: "72px",
    //       }}
    //     >
    //       <img
    //         src={incedoLogo}
    //         style={{ maxWidth: "200px", maxHeight: "53px" }}
    //       />
    //     </Box>
    //     <Box
    //       style={{
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         marginTop: "72px",
    //       }}
    //     >
    //       <Typography
    //         style={{
    //           fontFamily: "Montserrat",
    //           fontSize: "32px",
    //           fontWeight: "600",
    //           lineHeight: "40px",
    //           letterSpacing: "0px",
    //           textAlign: "center",
    //         }}
    //       >
    //         Welcome to Expense and Timesheet Portal
    //       </Typography>
    //     </Box>
    //     <Box
    //       style={{
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         marginTop: "24px",
    //       }}
    //     >
    //       <Typography
    //         style={{
    //           fontFamily: "Montserrat",
    //           fontSize: "20px",
    //           fontWeight: "500",
    //           lineHeight: "20px",
    //           letterSpacing: "0.20000000298023224px",
    //           textAlign: "left",
    //         }}
    //       >
    //         Sign In to Proceed
    //       </Typography>
    //     </Box>
    //     <Box
    //       style={{
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         marginTop: "46px",
    //       }}
    //     >
    //       <Button
    //         style={{
    //           backgroundColor: "#043365",
    //           minWidth: "455px",
    //           minHeight: "58px",
    //         }}
    //         onClick={() => handleClick()}
    //       >
    //         <Typography
    //           style={{
    //             color: "#FFFFFFE5",
    //             fontFamily: "Montserrat",
    //             fontSize: "16px",
    //             fontWeight: "600",
    //             lineHeight: "20px",
    //             letterSpacing: "-0.08399999886751175px",
    //             textAlign: "center",
    //           }}
    //         >
    //           Sign In{" "}
    //         </Typography>{" "}
    //       </Button>
    //     </Box>
    //   </Box>
    // </Box>
  );
};
export default Login;
{
  /* <Box>
        <Grid container justifyContent="center" spacing={0}>
          <Grid
            key={1}
            item
            xs={6}
            sm={6}
            md={6}
            style={{ display: "contents" }}
          >
            <Box role="img">
              <img src={loginImage} className={classes.image} />
            </Box>
          </Grid>
          <Grid
            key={2}
            item
            xs={6}
            sm={6}
            md={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                maxWidth: "529px",
                height: "490px",
                boxShadow:
                  "-1px 0px 8px rgba(0, 0, 0, 0.13), 0px 4px 8px rgba(0, 0, 0, 0.17)",
                borderRadius: "8px 8px 8px 7px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "72px",
                }}
              >
                <img
                  src={incedoLogo}
                  style={{ maxWidth: "200px", maxHeight: "53px" }}
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "72px",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "32px",
                    fontWeight: "600",
                    lineHeight: "40px",
                    letterSpacing: "0px",
                    textAlign: "center",
                  }}
                >
                  Welcome to Expense and Timesheet Portal
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "24px",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "20px",
                    fontWeight: "500",
                    lineHeight: "20px",
                    letterSpacing: "0.20000000298023224px",
                    textAlign: "left",
                  }}
                >
                  Sign In to Proceed
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "46px",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#043365",
                    minWidth: "455px",
                    minHeight: "58px",
                  }}
                  onClick={() => handleClick()}
                >
                  <Typography
                    style={{
                      color: "#FFFFFFE5",
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "20px",
                      letterSpacing: "-0.08399999886751175px",
                      textAlign: "center",
                    }}
                  >
                    Sign In{" "}
                  </Typography>{" "}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box> */
}
